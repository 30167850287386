import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

class ContactsUrban extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired
  }

  render() {
    const { instructor } = this.props;

    return (
      <div className='profile-hero-contacts-wrapper'>
        <div className='profile-hero-contacts-heading'>
          <span>C</span>
          <span>O</span>
          <span>N</span>
          <span>T</span>
          <span>A</span>
          <span>C</span>
          <span>T</span>
          <span>S</span>
        </div>
        <div className='profile-hero-contacts'>
          <div>
            { instructor.phone &&
              <div className='profile-contact-wrap'>
                <p className='profile-contact-heading'>Phone</p>
                <a
                    className='profile-contact'
                    href={`tel:${instructor.phone}`}>{instructor.phone}</a>
              </div>
            }
            { instructor.email &&
              <div className='profile-contact-wrap'>
                <p className='profile-contact-heading'>Email</p>
                <a
                    className='profile-contact'
                    href={`mailto:${instructor.email}`}>{instructor.email}</a>
              </div>
            }
          </div>
          <div>
            { instructor.website &&
              <div className='profile-contact-wrap'>
                <p className='profile-contact-heading'>Website</p>
                <a
                    className='profile-contact'
                    href={`//${instructor.website}`}>
                  {instructor.website}
                </a>
              </div>
            }
            { !isEmpty(instructor.socialProfiles) &&
              <div className='profile-contact-wrap social-links'>
                <p className='profile-contact-heading'>Social</p>
                { instructor.socialProfiles.facebook &&
                  <a
                      className='profile-contact'
                      href={`https://facebook.com/${instructor.socialProfiles.facebook}`}
                      rel='noopener noreferrer'
                      target='_blank'>
                    <Icon
                        className='mr-3'
                        fixedWidth
                        icon={['fab', 'facebook']}
                        size='lg' />
                  </a>
                }

                { instructor.socialProfiles.twitter &&
                  <a
                      className='profile-contact'
                      href={`https://twitter.com/${instructor.socialProfiles.twitter}`}
                      rel='noopener noreferrer'
                      target='_blank'>
                    <Icon
                        className='mr-3'
                        fixedWidth
                        icon={['fab', 'twitter']}
                        size='lg' />
                  </a>
                }

                { instructor.socialProfiles.instagram &&
                  <a
                      className='profile-contact'
                      href={`https://instagram.com/${instructor.socialProfiles.instagram}`}
                      rel='noopener noreferrer'
                      target='_blank'>
                    <Icon
                        className='mr-3'
                        fixedWidth
                        icon={['fab', 'instagram']}
                        size='lg' />
                  </a>
                }

                { instructor.socialProfiles.linkedin &&
                  <a
                      className='profile-contact'
                      href={`https://linkedin.com/in/${instructor.socialProfiles.linkedin }`}
                      rel='noopener noreferrer'
                      target='_blank'>
                    <Icon
                        className='mr-3'
                        fixedWidth
                        icon={['fab', 'linkedin']}
                        size='lg' />
                  </a>
                }
              </div>
            }
          </div>

        </div>
      </div>
    );
  }
}

export default ContactsUrban;
