import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { StudioActionTypes } from '../constants/actionTypes';

import {
  ErrorActions,
  LoaderActions,
  StudioActions
} from '../actions';

function* watchCreate() {
  yield takeLatest(StudioActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.studio.create, payload);

      yield put(StudioActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Studio created successfully');
    } catch (error) {
      yield put(StudioActions.CREATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(StudioActionTypes.CREATE_SUCCESS, function* () {
    yield put(StudioActions.CREATE_CLEAN());
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(StudioActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetch() {
  yield takeLatest(StudioActionTypes.FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.studio.get, payload);

      yield put(StudioActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(StudioActions.FETCH_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(StudioActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(StudioActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* studioSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure()
  ]);
}
