import { DonationClassesActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       [],
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case DonationClassesActionTypes.FETCH:
      return newState;

    case DonationClassesActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case DonationClassesActionTypes.FETCH_FAILURE:
      return newState;

    default: return state;
  }
};
