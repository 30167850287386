import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import classNames from 'classnames';

import { Loader } from '../../loader';

class Panel extends React.PureComponent {
  static propTypes = {
    children:  PropTypes.node.isRequired,
    className: PropTypes.string,
    isLoading: PropTypes.bool
  };

  static defaultProps = {
    className: '',
    isLoading: false
  };

  render () {
    const { isLoading, className, children } = this.props;

    return (
      <Card
          body
          className={classNames('card-panel', className)}>
        {children}
        <Loader
            animationTimeout={500}
            hasOpacity={false}
            isLoading={isLoading} />
      </Card>
    );
  }
}

export default Panel;