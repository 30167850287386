import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../../helpers';
import { SettingsHomeLandingPage } from '../../components/pages';
import {
  DonationTargetsActions,
  ProfileActions
} from '../../actions';

class SettingsHomeLandingContainer extends React.PureComponent {
  render() {
    return (
      <SettingsHomeLandingPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  donationClasses: state.donationClasses.data,
  donationTargets: state.donationTargets.data,
  instructor:      state.profile.data,
  locations:       state.locations.data,
  user:            state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createDonationTarget: (data) => dispatch(DonationTargetsActions.CREATE(data)),
  updateDonationTarget: (data) => dispatch(DonationTargetsActions.UPDATE(data)),
  updateProfile:        (data) => dispatch(ProfileActions.UPDATE(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(SettingsHomeLandingContainer);
