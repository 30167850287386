import { JoinActionTypes } from '../constants/actionTypes';

export default {
  JOIN_NOW: (payload) => ({
    payload,
    type: JoinActionTypes.JOIN_NOW
  }),

  START_PAGE: (payload) => ({
    payload,
    type: JoinActionTypes.START_PAGE
  })
};