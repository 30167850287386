import { connect } from 'react-redux';

import ClassPacksSummary from './classPacksSummary';

const mapStateToProps = (state) => ({
  isLoading: state.transactions.isLoading,
  summary:   state.transactions.summary.classPacks,
  user:      state.user.data
});

export default connect(mapStateToProps)(ClassPacksSummary);