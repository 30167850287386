import { connect } from 'react-redux';

import { MembershipsCouponsActions } from '../../actions';
import { MembershipCouponModal } from '../../components/modals/instructor';

const mapStateToProps = (state) => ({
  coupon:    state.membershipsCoupons.form,
  isLoading: state.loader.membershipsCoupons.isLoading,
  user:      state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createCoupon: (data) => dispatch(MembershipsCouponsActions.CREATE(data)),
  deleteCoupon: (data) => dispatch(MembershipsCouponsActions.DELETE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipCouponModal);
