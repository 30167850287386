import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';

import { Button } from '../../../forms';
import { Loader } from '../../../loader';
import { Alert } from '../../../errors';
import { AddReviewModal } from '../../../modals';
import { routes } from '../../../../lib';
import { Review } from './';


class Reviews extends React.PureComponent {
  static propTypes = {
    createReview: PropTypes.func.isRequired,
    goTo:         PropTypes.func.isRequired,
    loader:       PropTypes.object.isRequired,
    location:     PropTypes.object.isRequired,
    reviews:      PropTypes.object.isRequired,
    session:      PropTypes.object.isRequired
  }


  static getDerivedStateFromProps(props) {
    return {
      isLoading: props.loader.reviews.isLoading,
      owner:     props.location.data.name,
      reviews:   props.reviews.data
    };
  }

  state = {
    isLoading:         true,
    isReviewModalOpen: false,
    owner:             '',
    reviews:           []
  }

  renderReviews = () => {
    if (isEmpty(this.state.reviews)) {
      return (
        <Alert
            color='info'
            heading='Leave your comment!'
            icon='comments'>
          {`Be the first to leave a comment on ${this.state.owner}'s wall!`}
        </Alert>
      );
    } else {
      return this.state.reviews.map((review) => (
        <Review
            key={review.id}
            review={review} />
      ));
    }
  }

  handleReviewModal = () => {
    if (this.props.session.authenticated) {
      this.setState((prevState) => ({
        isReviewModalOpen: !prevState.isReviewModalOpen
      }));
    } else {
      this.props.goTo(routes.LOGIN);
    }
  }

  render() {
    const { location } = this.props;

    return (
      <React.Fragment>
        <div className='section-header'>
          <h3>
            Reviews
            <Button
                color='link'
                onClick={this.handleReviewModal}
                size={isMobile ? '' : 'lg'}>
              Leave a review
            </Button>
          </h3>
        </div>
        <div className='profile-comments'>
          <Loader
              hasOpacity={false}
              isLoading={this.state.isLoading}
              size='3rem'
              type='border' />
          {this.renderReviews()}
        </div>

        <AddReviewModal
            createReview={this.props.createReview}
            isOpen={this.state.isReviewModalOpen}
            isPersisted={false}
            location={location.data}
            onToggle={this.handleReviewModal} />
      </React.Fragment>
    );
  }
}

export default Reviews;
