import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { CommentsContainer, ReviewsContainer } from '../../../containers';

class Footer extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    type:     PropTypes.string.isRequired
  }

  static defaultProps = {
    instructor: {},
    location:   {}
  }

  render() {
    return (
      <div className='profile-footer no-gutters'>
        <Col
            lg={6}
            xl={5}>
          <LazyLoadComponent>
            { this.props.type === 'instructor' &&
              <CommentsContainer />
            }
            { this.props.type === 'location' &&
              <ReviewsContainer />
            }
          </LazyLoadComponent>
        </Col>
        <Col
            lg={6}
            xl={7}>
          <div className='profile-map-wrapper'>
            {this.props.children}
          </div>
        </Col>
      </div>
    );
  }
}

export default Footer;
