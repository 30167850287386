import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter, map } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { PrivateBookingsActionTypes } from '../constants/actionTypes';
import {
  ClassPacksActions,
  ErrorActions,
  PrivateBookingsActions,
  PrivateGroupClassesActions,
  SegmentActions
} from '../actions';

function* watchCreate() {
  yield takeLatest(PrivateBookingsActionTypes.CREATE, function* ({ payload }) {
    try {
      const booking             = payload.booking;
      const privateGroupClass   = booking.privateGroupClass;
      const privateGroupClasses = yield select((state) => state.privateGroupClasses.instructorData);
      const user                = yield select((state) => state.user.data);

      const data = map(privateGroupClasses, (groupClass) => (
        groupClass.id == privateGroupClass.id ?
          { ...groupClass, bookingsCount: groupClass.bookingsCount + 1, isBooked: true } : groupClass
      ));

      yield put(PrivateBookingsActions.CREATE_SUCCESS(booking));
      yield put(SegmentActions.NEW_CLASS_BOOK({ ...booking.privateGroupClass, type: 'Private Group Class', user }));
      toastr.success('Transaction Complete');

      if (booking.viaClassPack) {
        yield put(ClassPacksActions.SUBTRACT({ creditType: 'live', id: payload.classPack.id }));
      }

      yield put(PrivateGroupClassesActions.INSTRUCTORS_UPDATE_SUCCESS(data));
    } catch (error) {
      yield put(PrivateBookingsActions.CREATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDelete() {
  yield takeLatest(PrivateBookingsActionTypes.DELETE, function* ({ payload }) {
    try {
      yield call(api.privateGroupClasses.bookings.delete, payload);

      const privateBookings = yield select((state) => state.privateBookings);
      const data = filter(privateBookings.data, (privateBooking) => privateBooking.id != payload.booking.id);

      yield put(PrivateBookingsActions.DELETE_SUCCESS(data));
      toastr.success('Booking canceled');
    } catch (error) {
      yield put(PrivateBookingsActions.DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetch() {
  yield takeLatest(PrivateBookingsActionTypes.FETCH, function* () {
    try {
      const response = yield call(api.privateGroupClasses.bookings.fetch);

      yield put(PrivateBookingsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PrivateBookingsActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstuctorCreate() {
  yield takeLatest(PrivateBookingsActionTypes.INSTRUCTORS_CREATE, function* ({ payload }) {
      const privateGroupClasses = yield select((state) => state.privateGroupClasses.data);
      const data = map(privateGroupClasses, (groupClass) => (
        groupClass.id == payload.privateGroupClassId ?
          { ...groupClass,
            bookings:      [...groupClass.bookings, { ...payload, deletedAt: '' }],
            bookingsCount: groupClass.bookingsCount + 1 }
            : groupClass
      ));

      yield put(PrivateGroupClassesActions.UPDATE_SUCCESS(data));
    });
}

function* watchInstuctorDelete() {
  yield takeLatest(PrivateBookingsActionTypes.INSTRUCTORS_DELETE, function* ({ payload }) {
    try {
      yield call(api.instructor.privateGroupClasses.bookings.destroy, payload);

      const privateBookings = yield select((state) => state.privateBookings);
      const privateGroupClasses = yield select((state) => state.privateGroupClasses.data);

      const data = filter(privateBookings.instructorData, (privateBooking) => privateBooking.id != payload.id);

      const groupClasses = map(privateGroupClasses, (groupClass) => (
        groupClass.id == payload.privateGroupClassId ?
          { ...groupClass,
            bookings:      filter(groupClass.bookings, (booking) => booking.id != payload.id),
            bookingsCount: groupClass.bookingsCount - 1 }
            : groupClass
      ));

      yield put(PrivateGroupClassesActions.UPDATE_SUCCESS(groupClasses));

      yield put(PrivateBookingsActions.INSTRUCTORS_DELETE_SUCCESS(data));
      toastr.success('Client removed');
    } catch (error) {
      yield put(PrivateBookingsActions.INSTRUCTORS_DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsFetch() {
  yield takeLatest(PrivateBookingsActionTypes.INSTRUCTORS_FETCH, function* () {
    try {
      const response = yield call(api.instructor.privateGroupClasses.bookings.fetch);

      yield put(PrivateBookingsActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PrivateBookingsActions.INSTRUCTORS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* privateBookingsSaga() {
  yield all([
    watchCreate(),
    watchDelete(),
    watchFetch(),
    watchInstuctorCreate(),
    watchInstuctorDelete(),
    watchInstructorsFetch()
  ]);
}
