import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { routes } from '../../../../../lib';


import { ProfileAutomateBg } from '../../../../../images';
import { PanelImage } from '../../../../layout/pages';
import { Button } from '../../../../forms';
import { InstagramConnect, SpotifyConnect, StripeConnect, ZoomConnect } from '../../../../forms/profile/create';

class IntegrateStep extends React.PureComponent {
  static propTypes = {
    nextStep:        PropTypes.func,
    oAuth:           PropTypes.object.isRequired,
    onLogout:        PropTypes.func.isRequired,
    onSuccess:       PropTypes.func.isRequired,
    onSuccessStripe: PropTypes.func.isRequired,
    onSuccessZoom:   PropTypes.func.isRequired
  }

  static defaultProps = {
    nextStep: () => {}
  }

  static getDerivedStateFromProps(props) {
    const { oAuth } = props;

    return oAuth;
  }

  state = {}

  handleNext = () => {
    this.props.nextStep();
  }

  render() {
    return (
      <PanelImage
          id='profile-integration-info'
          imageSrc={ProfileAutomateBg}>
        <p className='text-muted font-italic text-sm mb-5'>
          Connect with our partners to automate your business.<br />
          You can also connect your account later.
        </p>

        <div className='profile-api stripe'>
          <h6>Get paid</h6>
          <p>Connect Stripe to enable online payment or donations to go directly into your account</p>

          <StripeConnect
              isConnected={this.state.stripe}
              onLogout={this.props.onLogout}
              onSuccess={this.props.onSuccessStripe} />
        </div>

        <div className='profile-api zoom'>
          <h6>Be Virtual</h6>
          <p>Connect Zoom so you can create a bookable virtual class automatically in just 1-click</p>

          <ZoomConnect
              isConnected={this.state.zoom}
              onLogout={this.props.onLogout}
              onSuccess={this.props.onSuccessZoom} />
        </div>

        <div className='profile-api social'>
          <h6>Share Social</h6>
          <p>Connect Spotify and Instagram to add a playlist and your IG content to you page</p>

          <InstagramConnect
              isConnected={this.state.instagram}
              onLogout={this.props.onLogout}
              onSuccess={this.props.onSuccess} />

          <SpotifyConnect
              isConnected={this.state.spotify}
              onLogout={this.props.onLogout}
              onSuccess={this.props.onSuccess} />
        </div>

        <div className='actions justify-content-end'>
          <Link to={routes.INSTRUCTOR_DASHBOARD}>
            <Button
                className='mt-5'
                isBlock={isMobile}
                onClick={this.handleNext}
                size='lg'>
              Next
            </Button>
          </Link>
        </div>
      </PanelImage>
    );
  }
}

export default IntegrateStep;
