import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, FormGroup, Label, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Validator, api, routes } from '../../../lib';
import { Button } from '../../forms';
import { Input } from '../../inputs';
import { PRIVATE_SESSION_STEPS } from '../../../constants';

class Guest extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired,
    onChange:   PropTypes.func.isRequired,
    onNext:     PropTypes.func.isRequired
  }

  state = {
    errors:    {},
    guest:     {},
    isLoading: false
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      guest: {
        ...prevState.guest,
        [inputName]: inputName === 'email' ? value.replace(' ', '') : value
      }
    }));
  }

  handleNext = () => {
    this.props.onNext(PRIVATE_SESSION_STEPS.details);
  }

  handleSubmit = () => {
    const { guest } = this.state;

    this.setState({ isLoading: true }, () => {
      this.handleValidate().then(() => (
        api.client.create({
          guest: {
            ...guest,
            instructorId: this.props.instructor.id,
            timeZone:     Intl.DateTimeFormat().resolvedOptions().timeZone
          }})
        ))
      .then((response) => (
        this.setState({ isLoading: false }, () => {
          this.props.onChange(response.data, 'guest', this.handleNext);
        })
      ))
      .catch((errors) => (
        this.setState({ errors, isLoading: false })
      ));
    });
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const { guest } = this.state;

    const errors = Validator.validate([
      ['email', Validator.concepts.emailRegex, [guest.email]],
      ['name',  Validator.concepts.isFilled,   [guest.name]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  render() {
    return (
      <ModalBody>
        <Form className='form-default'>
          <h5 className='mb-2'>Continue as guest without logging in</h5>

          <FormGroup>
            <Label for='input-name'>Full Name</Label>

            <Input
                id='name'
                isInvalid={!!this.state.errors.name}
                name='name'
                onChange={this.handleChange}
                value={this.state.guest.name} />
          </FormGroup>

          <FormGroup>
            <Label for='input-email'>Email</Label>

            <Input
                id='email'
                isInvalid={!!this.state.errors.email}
                name='email'
                onChange={this.handleChange}
                value={this.state.guest.email} />
          </FormGroup>

          <Button
              className='mt-4'
              color='primary'
              isBlock
              isLoading={this.state.isLoading}
              onClick={this.handleSubmit}
              size='lg'
              type='button'>
            Continue
          </Button>
        </Form>

        <hr
            className="my-3 hr-text letter-spacing-2"
            data-content="OR" />

        <Link to={routes.LOGIN}>
          <Button
              color='primary'
              isBlock
              isOutline
              size='lg'
              type='button'>
            Login
          </Button>
        </Link>

        <Link to={routes.SIGNUP}>
          <Button
              className='mt-4'
              color='muted'
              isBlock
              isOutline
              size='lg'
              type='button'>
            Sign Up
          </Button>
        </Link>
      </ModalBody>
    );
  }
}

export default Guest;
