/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { StartPage } from '../home';
import { Button } from '../forms';
import { routes } from '../../lib';
import {
  Automation,
  CentralizedPage,
  IconCheck,
  JessAvatar,
  Monetizing,
  PhoneShowcase,
  ScheduleImage
} from '../../images';
import { JoinNow } from './home';

class HomePage extends React.PureComponent {
  static propTypes = {
    joinNow:   PropTypes.func.isRequired,
    startPage: PropTypes.func.isRequired
  }

  handleStart = (username) => (
    this.props.startPage(username)
  );

  render() {
    return (
      <div className='inner-wrapper home-page'>
        <div className='broadcast'>
          <div className='title'>
            💙<span
                aria-label='Heart'
                role='img'> </span>
            <h4>Free for FitPros</h4>
            <span
                aria-label='Heart'
                role='img'> </span>💙
          </div>
          <p>We want to help FitPros get online and running their virtual fitness business</p>
        </div>
        <section
            className='hero-section'
            id='intro'>
          <div className='start-page'>
            <div className='title'>
              <h1 className='section-title'>Helping Fitness Pros_</h1>
              <h1 className='section-subtitle'>make more<br />money</h1>
            </div>

            <StartPage onSubmit={this.handleStart} />
          </div>

          <div className='virtual-classes'>
            <h3>Let clients easily view, book and pay for your virtual classes!</h3>
            <p>2020 has been a whirlwind... lets make your life a little easier with technology designed for you!</p>
          </div>

          <hr />

          <div className='payments'>
            <h2 className='section-title'>
              Bookings & payments fuel your business. We get that.
            </h2>
            <span className='section-subtitle'>
                That’s why everything we do here at onPodio is focused on helping you:
            </span>

            <div className='list'>
              <div className='item'>
                <img
                    alt='check'
                    src={IconCheck} />
                <span>Create your beautiful fitness page</span>
              </div>
              <div className='item'>
                <img
                    alt='check'
                    src={IconCheck} />
                <span>Add your virtual or in-person classes</span>
              </div>
              <div className='item'>
                <img
                    alt='check'
                    src={IconCheck} />
                <span>Automatically receive bookings 24/7</span>
              </div>
              <div className='item'>
                <img
                    alt='check'
                    src={IconCheck} />
                <span>Accept online payment or donations</span>
              </div>
              <div className='item'>
                <img
                    alt='check'
                    src={IconCheck} />
                <span>Get back to doing what you love</span>
              </div>
            </div>
          </div>

          <div className='mt-3 text-center'>
            <Link to={routes.INSTRUCTOR('demo')}>
              <Button color='success'>
                View a demo page
              </Button>
            </Link>
          </div>
        </section>

        <section
            className='hero-section'
            id='features'>
          <h1 className='section-title'>
            Book more clients, get paid to your bank account, and automate & organize with the right tools on your
            utility belt...free!
          </h1>

          <div className='features'>
            <div className='feature'>
              <div className='image'>
                <img
                    alt='Centralized page'
                    className='img-fluid'
                    src={CentralizedPage} />
              </div>
              <h3>Your centralized fitness page...</h3>
              <p>
                Create a custom fitness page with your classes, Instagram, music playlists, and  client reviews—all
                in one spot!
              </p>
            </div>
            <div className='feature'>
              <div className='image'>
                <img
                    alt='Monetizing'
                    className='img-fluid'
                    src={Monetizing} />
              </div>
              <h3>Monetizing everything you do...</h3>
              <p>
                Manage your entire calendar & fitness business on one platform including studio or virtual classes,
                private coaching and bootcamps
              </p>
            </div>
            <div className='feature'>
              <div className='image'>
                <img
                    alt='Automation'
                    className='img-fluid'
                    src={Automation} />
              </div>
              <h3>With simple automation</h3>
              <p>
                Receive bookings requests while you sleep with automated scheduling and simple online payment
                processing
              </p>
            </div>
          </div>
        </section>
        <section
            className='hero-section'
            id='schedule'>
          <div className='hero-text'>
            <h1 className='hero-title'>Eliminate scheduling pain & Venmo requests</h1>

            <p>
              Clients can simply view your group class schedule and your real-time private session availability to
              book—and  pay online and cancel—saving you hours & optimizing your schedule!
            </p>
          </div>
          <div className='hero-image'>
            <img
                alt='Schedule'
                className='img-fluid'
                src={ScheduleImage} />
          </div>
        </section>
        <section
            className='hero-section'
            id='business'>
          <div className='hero-image'>
            <img
                alt='Profile'
                className='img-fluid'
                src={PhoneShowcase} />
          </div>
          <div className='hero-text'>
            <h1 className='hero-title'>Gain an instant professional edge </h1>

            <p>
              onPodio isn’t just a online profile creator or scheduling tool—it’s a full-blown, beautiful, incredibly
              user-friendly system for running your fitness business end-to-end
            </p>
          </div>
        </section>
        <section
            className='hero-section'
            id='review'>
          <blockquote className='blockquote'>
            <p className='mb-0'>
              “This app is legit everything I need! My schedule is so inconsistent and I would always just tell
              people in class &ldquo;follow me on IG and I&apos;ll try to post my schedule to my story every
              Sunday&ldquo; and then of course sometimes I&apos;d forgot. This eliminates all that!”
            </p>
            <footer className='blockquote-footer'>
              <img
                  alt='Jess'
                  src={JessAvatar} />
              Jess, Barry’s Bootcamp
            </footer>
          </blockquote>
        </section>
        <section
            className='hero-section'
            id='join'>
          <div className='hero-text'>
            <h1 className='hero-title'>onPodio: the online platform & booking software for fitness professionals</h1>
            <p>
              onPodio has been designed to help make your life easier as a fitness professional. As your business
              grows, you might find that you have less and less time to do what you love: getting out there to train
              and interact with your customers. Utilizing our platform can help your get more bookings, save value
              time by automating your operations, enabling you to focus on the things that matter most to you.
            </p>
          </div>
          <JoinNow
              id='join-now'
              joinNow={this.props.joinNow}
              name='join-now' />
        </section>
      </div>
    );
  }
}

export default HomePage;
