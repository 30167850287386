import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Badge } from 'reactstrap';

import { CommentsContainer, InstagramContainer, PlaylistContainer } from '../../../../containers';
import Header from '../components/headerUrban';

class StyleElegant extends React.PureComponent {
  static propTypes = {
    follow:     PropTypes.func.isRequired,
    instructor: PropTypes.object.isRequired,
    playlist:   PropTypes.object.isRequired,
    unFollow:   PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const { instructor, playlist } = props;

    if (!state.isMounted && instructor.oauth) {
      return {
        integrations: instructor.oauth,
        isMounted:    true,
        playlist
      };
    }
  }

  state = {
    integrations: {},
    isMounted:    false,
    playlist:     {}
  }

  renderTags = () => (
    this.props.instructor.styleTags.map((tag, index) => (
      <Badge
          className='style__tag'
          color='light'
          key={index}
          pill>
        {tag}
      </Badge>
    ))
  )

  renderPrompts = () => (
    this.props.instructor.prompts.map((prompt) => (
      <li
          className='style-prompt'
          key={prompt.id}>
        {prompt.text}
      </li>
    ))
  )

  handleFollow = () => {
    this.props.instructor.isFollowing ? this.props.unFollow() : this.props.follow();
  };

  render() {
    const { integrations, playlist } = this.state;
    const { instructor } = this.props;
    const { prompts, styleTags } = instructor;
    const isLinkedSocial = integrations.instagram || integrations.spotify;

    return (
      <React.Fragment>
        <Header
            instructor={instructor}
            subtitle='Style' />

        <div className='style template-elegant'>
          <div className='style__body'>
            <div className='style__customer-choice '>
              <div className='style__tags'>
                {styleTags && this.renderTags()}
              </div>

              <ul className='style__prompts'>
                {prompts && this.renderPrompts()}
              </ul>

              <h3 className="page-title">Sneak peek</h3>
              <div className="style-body__info">
                { !isEmpty(instructor) && playlist && (
                  <React.Fragment>
                    { isLinkedSocial && (
                      <div className="style__social">
                        { integrations.instagram && (
                          <LazyLoadComponent threshold={100}>
                            <InstagramContainer />
                          </LazyLoadComponent>
                        )}

                        { integrations.spotify && (
                          <LazyLoadComponent threshold={100}>
                            <PlaylistContainer />
                          </LazyLoadComponent>
                        )}
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>

              <div className='style__comments'>
                <LazyLoadComponent threshold={100}>
                  <CommentsContainer />
                </LazyLoadComponent>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StyleElegant;
