import { ClassPacksActionTypes } from '../constants/actionTypes';

const initialState = {
  data:           [],
  form:           {},
  instructorData: [],
  lastAction:     '',
  userData:       []
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    /* regular data */
    case ClassPacksActionTypes.FETCH:
      return newState;

    case ClassPacksActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case ClassPacksActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case ClassPacksActionTypes.SUBTRACT:
      return newState;

    case ClassPacksActionTypes.SUBTRACT_SUCCESS:
      return { ...newState, userData: action.payload };


    /* instructors data */
    case ClassPacksActionTypes.INSTRUCTORS_CREATE:
      return newState;

    case ClassPacksActionTypes.INSTRUCTORS_CREATE_SUCCESS:
      return { ...newState, instructorData: [...state.instructorData, action.payload] };

    case ClassPacksActionTypes.INSTRUCTORS_CREATE_FAILURE:
      return newState;


    case ClassPacksActionTypes.INSTRUCTORS_DELETE:
      return newState;

    case ClassPacksActionTypes.INSTRUCTORS_DELETE_SUCCESS:
      return { ...newState, form: initialState.form, instructorData: action.payload };

    case ClassPacksActionTypes.INSTRUCTORS_DELETE_FAILURE:
      return newState;


    case ClassPacksActionTypes.INSTRUCTORS_FETCH:
      return newState;

    case ClassPacksActionTypes.INSTRUCTORS_FETCH_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case ClassPacksActionTypes.INSTRUCTORS_FETCH_FAILURE:
      return { ...newState, instructorData: initialState.instructorData };


    case ClassPacksActionTypes.INSTRUCTORS_GET:
      return newState;

    case ClassPacksActionTypes.INSTRUCTORS_GET_SUCCESS:
      return { ...newState, form: action.payload };

    case ClassPacksActionTypes.INSTRUCTORS_GET_FAILURE:
      return newState;


    case ClassPacksActionTypes.INSTRUCTORS_UPDATE:
      return newState;

    case ClassPacksActionTypes.INSTRUCTORS_UPDATE_SUCCESS:
      return { ...newState, form: initialState.form, instructorData: action.payload };

    case ClassPacksActionTypes.INSTRUCTORS_UPDATE_FAILURE:
      return newState;


    /* user data */
    case ClassPacksActionTypes.USERS_CLEAR:
      return { ...newState, userData: initialState.userData };


    case ClassPacksActionTypes.USERS_CREATE:
      return newState;

    case ClassPacksActionTypes.USERS_CREATE_SUCCESS:
      return { ...newState, userData: [...state.userData, action.payload] };

    case ClassPacksActionTypes.USERS_CREATE_FAILURE:
      return newState;


    case ClassPacksActionTypes.USERS_DELETE:
      return newState;

    case ClassPacksActionTypes.USERS_DELETE_SUCCESS:
      return { ...newState, userData: action.payload };

    case ClassPacksActionTypes.USERS_DELETE_FAILURE:
      return newState;


    case ClassPacksActionTypes.USERS_FETCH:
      return newState;

    case ClassPacksActionTypes.USERS_FETCH_SUCCESS:
      return { ...newState, userData: action.payload };

    case ClassPacksActionTypes.USERS_FETCH_FAILURE:
      return { ...newState, userData: initialState.userData };

    default: return state;
  }
};
