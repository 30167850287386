import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { profileMapTypes } from '../constants';
import { ProfileMap } from '../components/maps';

class ProfileMapContainer extends React.PureComponent {
  static propTypes = {
    type: PropTypes.oneOf([
      profileMapTypes.INSTRUCTOR,
      profileMapTypes.LOCATION
    ]).isRequired
  }

  render() {
    return (
      <ProfileMap
          {...this.props}
          className='h-100'
          id='detailMap' />
    );
  }
}

const mapStateToProps = (state) => ({
  instructor: state.instructor,
  location:   state.location
});

export default connect(mapStateToProps)(ProfileMapContainer);
