import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReduxToastr, { toastr } from 'react-redux-toastr';
import { isEmpty } from 'lodash';

class Toastr extends React.PureComponent {
  static propTypes = {
    error: PropTypes.shape({
      message: PropTypes.string.isRequired
    }).isRequired
  }

  componentDidUpdate(prevProps) {
    const { message } = this.props.error;

    if (message !== prevProps.error.message) {
      if (isEmpty(message)) return;

      if (typeof message == 'object') {
        toastr.error(message.join('\n'), { showCloseButton: false });
      } else {
        toastr.error(message, { showCloseButton: false });
      }
    }
  }

  render() {
    return (
      <ReduxToastr
          closeOnToastrClick={false}
          newestOnTop={false}
          position='top-center'
          preventDuplicates
          timeOut={1500}
          transitionIn='bounceIn'
          transitionOut='bounceOut' />
    );
  }
}

const mapStateToProps = (state) => ({
  error:   state.error,
  session: state.session,
  user:    state.user.data
});

export default connect(mapStateToProps)(Toastr);
