import { StrategyActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       {},
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case StrategyActionTypes.GET:
      return newState;

    case StrategyActionTypes.GET_SUCCESS:
      return { ...newState, data: action.payload };

    case StrategyActionTypes.GET_FAILURE:
      return { ...newState, data: initialState.data };


    case StrategyActionTypes.UPDATE:
      return newState;

    case StrategyActionTypes.UPDATE_SUCCESS:
      return { ...newState, data: action.payload };

    case StrategyActionTypes.UPDATE_FAILURE:
      return newState;


    default: return state;
  }
};
