export default {
  CREATE:         '@@groupClasses/CREATE',
  CREATE_FAILURE: '@@groupClasses/CREATE_FAILURE',
  CREATE_SUCCESS: '@@groupClasses/CREATE_SUCCESS',

  DELETE:         '@@groupClasses/DELETE',
  DELETE_FAILURE: '@@groupClasses/DELETE_FAILURE',
  DELETE_SUCCESS: '@@groupClasses/DELETE_SUCCESS',

  FETCH:         '@@groupClasses/FETCH',
  FETCH_FAILURE: '@@groupClasses/FETCH_FAILURE',
  FETCH_SUCCESS: '@@groupClasses/FETCH_SUCCESS',

  INSTRUCTORS_FETCH:         '@@groupClasses/INSTRUCTORS/FETCH',
  INSTRUCTORS_FETCH_FAILURE: '@@groupClasses/INSTRUCTORS/FETCH_FAILURE',
  INSTRUCTORS_FETCH_SUCCESS: '@@groupClasses/INSTRUCTORS/FETCH_SUCCESS',

  INSTRUCTORS_GET:         '@@groupClasses/INSTRUCTORS/GET',
  INSTRUCTORS_GET_FAILURE: '@@groupClasses/INSTRUCTORS/GET_FAILURE',
  INSTRUCTORS_GET_SUCCESS: '@@groupClasses/INSTRUCTORS/GET_SUCCESS',

  LOCATIONS_FETCH:         '@@groupClasses/LOCATIONS/FETCH',
  LOCATIONS_FETCH_FAILURE: '@@groupClasses/LOCATIONS/FETCH_FAILURE',
  LOCATIONS_FETCH_SUCCESS: '@@groupClasses/LOCATIONS/FETCH_SUCCESS',

  LOCATIONS_GET:         '@@groupClasses/LOCATIONS/GET',
  LOCATIONS_GET_FAILURE: '@@groupClasses/LOCATIONS/GET_FAILURE',
  LOCATIONS_GET_SUCCESS: '@@groupClasses/LOCATIONS/GET_SUCCESS',

  RECURRING_CREATE:         '@@groupClasses/RECURRING/CREATE',
  RECURRING_CREATE_FAILURE: '@@groupClasses/RECURRING/CREATE_FAILURE',
  RECURRING_CREATE_SUCCESS: '@@groupClasses/RECURRING/CREATE_SUCCESS',

  RECURRING_DELETE:         '@@groupClasses/RECURRING/DELETE',
  RECURRING_DELETE_FAILURE: '@@groupClasses/RECURRING/DELETE_FAILURE',
  RECURRING_DELETE_SUCCESS: '@@groupClasses/RECURRING/DELETE_SUCCESS',

  UPDATE:         '@@groupClasses/UPDATE',
  UPDATE_FAILURE: '@@groupClasses/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@groupClasses/UPDATE_SUCCESS'
};
