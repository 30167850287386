/* eslint-disable immutable/no-let */
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { api, toCamelCase } from '../lib';
import { AutocompleterActionTypes } from '../constants/actionTypes';
import { AutocompleterActions } from '../actions';

function* watchAutocomplete() {
  yield takeEvery(AutocompleterActionTypes.AUTOCOMPLETE, function* ({ payload }) {
    try {
      let response;
      if (payload.instructorUsername) {
        response = yield call(api.instructor.autocompleter.fetch, payload);
      } else if (payload.locationId) {
        response = yield call(api.location.autocompleter.fetch, payload);
      } else {
        response = yield call(api.autocompleter.fetch, payload);
      }

      return yield put(AutocompleterActions.AUTOCOMPLETE_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(AutocompleterActions.AUTOCOMPLETE_FAILURE());
    }
  });
}

export default function* autocompleterSaga() {
  yield all([
    watchAutocomplete()
  ]);
}
