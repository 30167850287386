import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { Button, Checkbox } from '../../forms';
import { Input } from '../../inputs';
import { InstructorNoAvatar } from '../../../images';
import { UserModalAvatar } from '../layouts';
import { PRIVATE_SESSION_STEPS } from '../../../constants';
import { Moment, PaymentMethod,  getTimeZone } from '../../../lib';
import { BookingTermsModal } from '..';

class Details extends React.PureComponent {
  static propTypes = {
    challenge:            PropTypes.object,
    classPack:            PropTypes.object,
    createPrivateSession: PropTypes.func.isRequired,
    guest:                PropTypes.object,
    instructor:           PropTypes.object.isRequired,
    isChallengeAvailable: PropTypes.bool.isRequired,
    isClassPackAvailable: PropTypes.bool.isRequired,
    onChange:             PropTypes.func.isRequired,
    onNext:               PropTypes.func,
    onToggle:             PropTypes.func.isRequired,
    privateSession:       PropTypes.object.isRequired,
    subtractCredit:       PropTypes.func.isRequired,
    user:                 PropTypes.object.isRequired
  }

  static defaultProps = {
    challenge: {},
    classPack: {},
    guest:     {},
    onNext:    () => {}
  }

  state = {
    isModalOpen:    false,
    isTermsChecked: false
  }

  handleBack = () => {
    this.props.onNext(PRIVATE_SESSION_STEPS.info);
  }

  handleNext = () => (
    this.props.onNext(PRIVATE_SESSION_STEPS.payment)
  )

  handleChange = (value, inputName) => (
    this.setState({ [inputName]: value })
  )

  handleModal = () => (
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }))
  )

  handleSubmit = () => (
    this.props.createPrivateSession({ privateSession: {
      ...this.props.privateSession,
      challengeId:  this.props.challenge.id,
      classPackId:  this.props.classPack.id,
      clientId:     this.props.guest.id,
      instructorId: this.props.instructor.id,
      method:       new PaymentMethod(
                      this.props.isClassPackAvailable,
                      false,
                      this.props.isChallengeAvailable).get(),
      timeZone: getTimeZone(this.props.user.timeZone)
    }}),
    this.props.onToggle(),
    this.props.subtractCredit({ creditType: 'privateSession', id: this.props.classPack.id })
  );

  renderPriceTag = () => {
    const { instructor, isChallengeAvailable, isClassPackAvailable, privateSession } = this.props;
    const { currency } = instructor;

    return (
      <div className={classNames({ 'text-strikethrough': isClassPackAvailable || isChallengeAvailable })}>
        Cost:&nbsp;<b>{privateSession.price.toLocaleString('en-GB', { currency, style: 'currency' })}</b>
      </div>
    );
  }

  render() {
    const {
      classPack,
      instructor,
      isChallengeAvailable,
      isClassPackAvailable,
      privateSession,
      user
    } = this.props;
    const photo = instructor.photo ? instructor.photo.sm : InstructorNoAvatar;

    return (
      <div className='new-modal'>
        <ModalBody>
          { instructor &&
            <div className='modal__header'>
              <UserModalAvatar
                  alt={instructor.name}
                  firstName={instructor.name}
                  src={photo} />
              <div className='modal__instructor-name'>
                <span className='modal__big-text'>{instructor.name}</span>
              </div>
            </div>
          }

          <div className='modal__event-name'>
            <span className='modal__big-text modal__big-text_black'>
              Private Session Request
            </span>
          </div>

          <div className='modal__time'>
            <div className='modal__default-text modal__default-text_bold font-weight-bold'>
              {privateSession.startsAt.format('ddd, MMMM DD')}
            </div>

            <div className='modal__default-text'>
              &nbsp;|&nbsp;{privateSession.startsAt.format('hh:mmA')} - {privateSession.duration} minutes
            </div>
          </div>

          <div className='modal__event-timezone modal__small-text'>
            {new Moment.tz(getTimeZone(user.timeZone)).format('z')} (GMT
            {new Moment.tz(getTimeZone(user.timeZone)).format('Z')} {getTimeZone(user.timeZone)})
          </div>

          <div className='text-muted modal__small-text'>
            Type: {privateSession.name}
          </div>

          <div className='mt-1 mb-2 text-muted modal__small-text'>
            Length: {privateSession.duration} mins
          </div>

          <div className='modal__event-cost modal__default-text modal__default-text_blue'>
            {this.renderPriceTag()}

            { isClassPackAvailable && !isChallengeAvailable &&
              <span className='modal__default-text modal__default-text_blue modal__default-text-bold'>
                Use one of your remaining {classPack.privateSession} credits
              </span>
            }

            { isChallengeAvailable &&
              <span className='modal__default-text modal__default-text_blue modal__default-text-bold'>
                Included in Challenge
              </span>
            }
          </div>

          <Form className='form-default'>
            <FormGroup>
              <Label for='input-note'>
                Leave additional note
              </Label>

              <Input
                  id='note'
                  name='note'
                  onChange={this.props.onChange}
                  placeholder='Type your information here...'
                  rows='4'
                  type='textarea'
                  value={privateSession.note} />
            </FormGroup>
          </Form>

        </ModalBody>

        <ModalFooter className='flex-column'>
          <div className='event-terms justify-content-start w-100'>
            <div className='custom-control custom-checkbox checkbox-md'>
              <Checkbox
                  className='custom-control-input'
                  id='isTermsChecked'
                  isChecked={this.state.isTermsChecked}
                  name='isTermsChecked'
                  onChange={this.handleChange} />
              <Label
                  className='custom-control-label text-muted'
                  for='input-isTermsChecked'>
                I have read and agree to the terms of the&nbsp;
                <Link
                    className='text-primary'
                    onClick={this.handleModal}
                    to='#'>
                  Waiver & Release
                </Link>
              </Label>
            </div>
          </div>

          { (!isClassPackAvailable && !isChallengeAvailable) &&
            <Button
                className='d-flex justify-content-center mt-4'
                color='blue'
                isBlock
                isDisabled={!this.state.isTermsChecked}
                onClick={this.handleNext}
                size='lg'>
              <span>Next Step</span>
            </Button>
          }

          { (isClassPackAvailable || isChallengeAvailable) &&
            <Button
                className='d-flex justify-content-center mt-4'
                color='blue'
                isBlock
                isDisabled={!this.state.isTermsChecked}
                onClick={this.handleSubmit}
                size='lg'>
              <span>Send Request</span>
            </Button>
          }

        </ModalFooter>

        <div className='modals'>
          { this.state.isModalOpen &&
            <BookingTermsModal
                instructor={instructor}
                isOpen={this.state.isModalOpen}
                onToggle={this.handleModal} />
          }
        </div>
      </div>
    );
  }
}

export default Details;
