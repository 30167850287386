export default {
  CREATE:         '@@clients/CREATE',
  CREATE_FAILURE: '@@clients/CREATE_FAILURE',
  CREATE_SUCCESS: '@@clients/CREATE_SUCCESS',

  DELETE:         '@@clients/DELETE',
  DELETE_FAILURE: '@@clients/DELETE_FAILURE',
  DELETE_SUCCESS: '@@clients/DELETE_SUCCESS',

  FETCH:         '@@clients/FETCH',
  FETCH_FAILURE: '@@clients/FETCH_FAILURE',
  FETCH_SUCCESS: '@@clients/FETCH_SUCCESS',

  GET:         '@@clients/GET',
  GET_FAILURE: '@@clients/GET_FAILURE',
  GET_SUCCESS: '@@clients/GET_SUCCESS',

  UPDATE:         '@@clients/UPDATE',
  UPDATE_FAILURE: '@@clients/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@clients/UPDATE_SUCCESS'
};
