import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError } from '../lib';
import { ValidationActionTypes } from '../constants/actionTypes';
import { ValidationActions } from '../actions';

function* watchValidate() {
  yield takeLatest(ValidationActionTypes.VALIDATE, function* ({ payload, meta }) {
    try {
      yield call(api.validation.create, payload, meta);

      yield put(ValidationActions.VALIDATE_SUCCESS(meta));
    } catch (error) {
      yield put(ValidationActions.VALIDATE_FAILURE(extractError(error), meta));
    }
  });
}

export default function* validationSaga() {
  yield all([
    watchValidate()
  ]);
}
