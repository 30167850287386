import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { MembershipForm } from '../../forms/instructor';

class MembershipModal extends React.PureComponent {
  static propTypes = {
    cancelMembership: PropTypes.func.isRequired,
    createMembership: PropTypes.func.isRequired,
    deleteSample:     PropTypes.func.isRequired,
    isLoading:        PropTypes.bool.isRequired,
    isNewRecord:      PropTypes.bool.isRequired,
    isOpen:           PropTypes.bool.isRequired,
    membership:       PropTypes.object.isRequired,
    onToggle:         PropTypes.func.isRequired,
    updateMembership: PropTypes.func.isRequired,
    user:             PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    const { isLoading, isNewRecord } = props;

    return {
      isLoading,
      isNewRecord: isNewRecord,
      membership:  isNewRecord ? {} : props.membership
    };
  }

  state = {
    isLoading:   false,
    isNewRecord: true,
    membership:  {}
  }

  handleSubmit = (membership) => {
    this.state.isNewRecord
        ? this.props.createMembership({ membership })
        : this.props.updateMembership({ membership });
  }

  render() {
    const { isOpen, onToggle } = this.props;
    const { isNewRecord, isLoading, membership } = this.state;

    const action = isNewRecord ? 'Create' : 'Update';

    return (
      <Modal
          centered
          className='membership-modal'
          isOpen={isOpen}
          toggle={onToggle}>
        <ModalHeader toggle={onToggle}>
          {action} <span className='text-primary'>Membership</span>
        </ModalHeader>

        <ModalBody>
          <MembershipForm
              cancelMembership={this.props.cancelMembership}
              data={membership}
              deleteSample={this.props.deleteSample}
              isLoading={isLoading}
              isNewRecord={isNewRecord}
              onSubmit={this.handleSubmit}
              onToggle={this.props.onToggle}
              user={this.props.user} />
        </ModalBody>
      </Modal>
    );
  }
}

export default MembershipModal;
