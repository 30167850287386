import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';

import { Link } from 'react-router-dom';
import {
  Activities,
  Contacts,
  Fundraiser,
  InstructorName,
  Photo
} from '../../../../components/instructor';
import OptionalSections from './optionalSections';

class HomeUrban extends React.PureComponent {
  static propTypes = {
    donationBookings: PropTypes.array.isRequired,
    donationTargets:  PropTypes.object.isRequired,
    instructor:       PropTypes.object.isRequired,
    match:            PropTypes.object.isRequired
  }

  render() {
    const { donationTargets, instructor, donationBookings, match } = this.props;

    const desktopPhoto = instructor.photo ? instructor.photo.lg : '';
    const mobilePhoto = instructor.mobilePhoto ? instructor.mobilePhoto.photo : '';
    const photo = isMobile && mobilePhoto ? mobilePhoto : desktopPhoto;

    return (
      <React.Fragment>
        <div className='profile-section template-urban d-lg-flex'>
          <div className='profile-hero order-lg-1'>
            <Photo src={photo} />
            <Contacts instructor={instructor} />
            <div className='instructor-tagline'>
              <span>{`"${instructor.tagline}"`}</span>
            </div>
          </div>

          <div className='profile-info order-lg-0'>
            <div />
            <div className='profile-info-mid'>
              <InstructorName
                  hasLocation
                  instructor={instructor}
                  isHome />
              {instructor.activities && <Activities instructor={instructor} />}
              <Link
                  className='btn btn-secondary'
                  to={`${match.params.username}/workout`}>
                BOOK NOW
              </Link>
            </div>

            <div className='profile-info-footer'>
              { instructor.donationsEnabled && !isEmpty(donationTargets.name) &&
                <Fundraiser
                    donationBookings={donationBookings}
                    donationTargets={donationTargets}
                    instructor={instructor} />
              }
              <div className='instructor-tagline'>
                <span>{instructor.tagline}</span>
              </div>
              {isMobile && <Contacts instructor={instructor} />}
            </div>
          </div>
        </div>
        <OptionalSections
            homeConfig={instructor.homeConfig}
            instructor={instructor}
            match={match} />
      </React.Fragment>
    );
  }
}

export default HomeUrban;
