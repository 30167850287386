import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import Avatar from 'react-avatar';
import { filter, find, isEmpty } from 'lodash';
import { withRouter } from 'react-router';
import { confirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';

import { CancelSubscription, ResetPasswordAlert } from '../../../components/alerts';
import { UserAvatar } from '../../../components/users';
import { Heading, Panel } from '../../../components/layout/pages';
import { Button } from '../../../components/forms';
import { ClientModalContainer } from '../../../containers/portal';
import { ClassPacks, Subscriptions } from '../../../components/pages/clients';
import PurchasesTable from '../../transactions/tables/purchasesTable/purchasesTable';

class Client extends React.PureComponent {
  static propTypes = {
    cancelSubscription: PropTypes.func.isRequired,
    client:             PropTypes.object.isRequired,
    coupons:            PropTypes.array.isRequired,
    fetchCoupons:       PropTypes.func.isRequired,
    fetchPurchases:     PropTypes.func.isRequired,
    getClient:          PropTypes.func.isRequired,
    isLoading:          PropTypes.bool.isRequired,
    match:              PropTypes.object.isRequired,
    profile:            PropTypes.object.isRequired,
    recoverPassword:    PropTypes.func.isRequired,
    transactions:       PropTypes.array.isRequired,
    updateClassPack:    PropTypes.func.isRequired,
    updatePurchase:     PropTypes.func.isRequired,
    user:               PropTypes.object.isRequired
  };

  componentDidMount () {
    const { id } = this.props.match.params;

    this.props.getClient({ id });
  }

  static getDerivedStateFromProps(props, state) {
    const { profile } = props;

    const subscriptions = filter(props.client.subscriptions, ['status', 'active']);
    const subscription = find(subscriptions, ['membership.instructorId', props.profile.id]);
    const classPacks = filter(props.client.userClassPacks, ['instructorId', profile.id]);

    if (!state.isMounted) {
      return {
        classPacks,
        subscription
      };
    }
  }

  state = {
    classPacks:        {},
    creditsCount:      0,
    isClientModalOpen: false,
    isMounted:         false,
    isNewRecord:       false,
    subscription:      {},
    updateCredits:     false

  };

  renderAvatar = (client) => (
    client.photo ?
      <UserAvatar
          alt='avatar'
          size={isMobile ? 'sm' : 'pre-lg'}
          src={client.photo} /> :
      <Avatar
          color='#BCDCFF'
          name={client.name.charAt(0)}
          round="26px"
          size={isMobile ? '40' : '56'} />
  )

  handleResetPassword = (email) => () => {
    const resetOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <ResetPasswordAlert
            email={email}
            onClose={onClose}
            resetPassword={this.props.recoverPassword} />
      )};
    confirmAlert(resetOptions);
  }

  handleCancelAlert = () => {
    const resetOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <CancelSubscription
            cancelSubscription={this.onCancelSubsription}
            onClose={onClose}
            subscription={this.state.subscription} />
      )};
    confirmAlert(resetOptions);
  }

  onCancelSubsription = () => (
    this.setState((prevState) => ({
      ...prevState,
      isMounted:    true,
      subscription: {
        ...prevState.subscription,
        status: 'canceled'
      }
    }), this.cancelSubscription())
  )

  cancelSubscription = () => {
    const { subscription } = this.state;

    this.props.cancelSubscription({ subscription });
  }

  handleOpenModal = () => (
    this.setState((prevState) => ({
      ...prevState,
      isClientModalOpen: !prevState.isClientModalOpen
    }))
  )

  fetchPurchases = () => {
    const { id } = this.props.match.params;

    this.props.fetchPurchases({ id });
  }

  render() {

    const { client, user } = this.props;
    const { classPacks, subscription } = this.state;

    const totalClasses = client.bookingsCount + client.onDemandsCount;

    return (
      <div className='inner-wrapper portal locations'>
        <Helmet>
          <title>My Client</title>
        </Helmet>

        <div className='inner-wrapper portal client'>
          <Container fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='My Clients' />
            <Panel>
              <div className='info'>
                <div className='d-flex flex-direction-row'>
                  {!isEmpty(client) && this.renderAvatar(client)}
                  <div className='details'>
                    <span className='name'>{client.name}</span>
                    <span className='type'>{client.userId ? 'User' : 'Guest'}</span>
                  </div>
                </div>

                <div className={classNames('d-flex', { 'flex-direction-row': !isMobile})}>
                  <Button
                      className='button margin'
                      color='primary'
                      isOutline
                      onClick={this.handleOpenModal}
                      size='sm'>
                    <span>Edit Member</span>
                  </Button>

                  { client.userId &&
                    <Button
                        className='button margin ml-2'
                        isOutline
                        onClick={this.handleResetPassword(client.email)}
                        size='sm'>
                      <span>Password Reset</span>
                    </Button>
                  }
                </div>
              </div>

              <div className='content'>
                <div className='main-row'>
                  <span className='title'>Client Details</span>
                </div>

                <div className='row'>
                  <span className='column'>
                    Name:
                    <span className='value'>{client.name}</span>
                  </span>

                  <span className='column'>
                    Email:
                    <span className='value'>{client.email}</span>
                  </span>

                  <span className='column'>
                    Phone:
                    <span className='value'>{client.phone ? client.phone : 'n/a'}</span>
                  </span>
                </div>

                { client.userId &&
                  <Subscriptions
                      onCancelSubscription={this.handleCancelAlert}
                      subscription={subscription}
                      user={user}
                      />
                }


                { client.userId &&
                  <ClassPacks
                      classPacks={classPacks}
                      updateClassPack={this.props.updateClassPack} />
                }
              </div>

              <div className='transaction-info'>

                <span className='title'>Transaction History</span>

                <div className='details'>
                  <div className='row'>
                    <span className='column'>Live Classes</span>
                    <div className='badgeer blue'>
                      <span>{client.bookingsCount}</span>
                    </div>
                  </div>

                  <div className='row'>
                    <span className='column'>On Demand</span>
                    <div className='badgeer gray'>
                      {client.onDemandsCount}
                    </div>
                  </div>

                  <div className='row'>
                    <span className='column'>Total Classes</span>
                    <div className='badgeer yellow'>
                      {totalClasses}
                    </div>
                  </div>
                </div>
              </div>

              <div className='transactions-summary'>
                <PurchasesTable
                    coupons={this.props.coupons}
                    fetchCoupons={this.props.fetchCoupons}
                    fetchPurchases={this.fetchPurchases}
                    isLoading={this.props.isLoading}
                    transactions={this.props.transactions}
                    updatePurchase={this.props.updatePurchase} />
              </div>
            </Panel>
          </Container>
        </div>

        <ClientModalContainer
            isNewRecord={this.state.isNewRecord}
            isOpen={this.state.isClientModalOpen}
            onToggle={this.handleOpenModal} />
      </div>
    );
  }
}

export default withRouter(Client);
