/* global process */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { Switch, withRouter } from 'react-router-dom';
import {
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarToggler
} from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import axios from 'axios';
import { find, isEmpty } from 'lodash';

import { Link } from '../../inputs';
import { routes } from '../../../lib';
import { Logo } from '../../../images';
import HeaderButtonContext from '../../../contexts/header-button';
import { SignMenu, UserMenu } from './menu';
import SearchBar from './searchBar';

/*const path = '/(settings|account|profile|calendar|dashboard|search|planner|coupons|v'
              + '|schedule/story/new|on_demand|widgets|support|purchases)';*/
const { API_URL } = process.env;
/*const { HOST } = process.env;*/

class Header extends React.PureComponent {
  static propTypes = {
    getDomain:      PropTypes.func.isRequired,
    isSearchOpen:   PropTypes.bool.isRequired,
    logOut:         PropTypes.func.isRequired,
    onSearchToggle: PropTypes.func.isRequired,
    profile:        PropTypes.object.isRequired,
    session:        PropTypes.object.isRequired,
    toggleSidebar:  PropTypes.func.isRequired,
    user:           PropTypes.object.isRequired
  }

  static defaultProps = {
    onMenuToggle: () => {}
  }

  static getDerivedStateFromProps(props) {
    return {
      hasProfile:      !isEmpty(props.profile),
      isAuthenticated: props.session.authenticated,
      user:            props.user
    };
  }

  static contextType = HeaderButtonContext

  state = {
    hasProfile:      false,
    isAuthenticated: false,
    isMenuOpen:      false,
    isUserMenuOpen:  false,
    user:            {}
  };

  async getUsernameFromHost() {
    const { host } = window.location;

    const response = await axios.get(`${API_URL}/api/domains`);

    const domain = await find(response.data, (domain) => {
      return domain.host === host;
    });

    return domain.username;
  }

  componentDidMount () {
    const { profile } = this.props;

    this.props.getDomain({ username: profile.username });
  }

  handleMenuToggle = () => {
    if (this.state.isAuthenticated) {
      /*this.props.onMenuToggle();*/
      this.props.toggleSidebar();
    } else {
      this.setState((prevState) => ({
        isMenuOpen: !prevState.isMenuOpen
      }));
    }
  };

  handleUserMenu = () => {
    this.setState((prevState) => ({
      isUserMenuOpen: !prevState.isUserMenuOpen
    }));
  };

  renderSearchBar = () => {
    const searchToggleClass = classNames('navbar-search', { 'open': this.props.isSearchOpen });

    return (
      <React.Fragment>
        <NavbarToggler
            className={searchToggleClass}
            onClick={this.props.onSearchToggle}>
          <Icon
              icon={['fas', this.props.isSearchOpen ? 'times' : 'search']} />
        </NavbarToggler>

        <Collapse
            className='navbar-search'
            isOpen={this.props.isSearchOpen}
            navbar>

          <SearchBar />

        </Collapse>
      </React.Fragment>
    );
  }

  render() {
    const { isSearchOpen } = this.props;
    const { isAuthenticated, user, isUserMenuOpen } = this.state;
    const navbarRadius = classNames({ 'navbar-radius': isSearchOpen || this.state.isMenuOpen });
    /*const hasCustomDomain = window.location.host !== HOST;*/

    return (
      <header className='header'>
        <Navbar
            className={navbarRadius}
            expand='lg'
            light>
          <Container>
            <NavbarToggler onClick={this.handleMenuToggle} />
            <Switch>
              <Route path={routes.ADD_PROFILE}>
                <Link
                    className='navbar-brand'
                    to={routes.HOME}>
                  <img
                      alt='onPodio'
                      src={Logo} />
                </Link>
              </Route>

              <Route path='*'>
                <React.Fragment>
                  <Link
                      className='navbar-brand'
                      to={routes.HOME}>
                    <img
                        alt='onPodio'
                        src={Logo} />
                  </Link>
                </React.Fragment>
              </Route>
            </Switch>

            <Collapse
                className='navbar-user-menu'
                isOpen={this.state.isMenuOpen}
                navbar>
              <Nav
                  className='ml-auto align-items-lg-center'
                  navbar>
                { isAuthenticated ?
                  <UserMenu
                      hasProfile={this.state.hasProfile}
                      isOpen={isUserMenuOpen}
                      logOut={this.props.logOut}
                      onToggle={this.handleUserMenu}
                      user={user} /> : <SignMenu />
                }
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default withRouter(Header);
