import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { folder } from 'react-icons-kit/feather/folder';

const BrowseIcon = ({ size }) => (
  <Icon
      icon={folder}
      size={size} />
);

BrowseIcon.propTypes = {
  size: PropTypes.number
};

BrowseIcon.defaultProps = {
  size: 32
};

export default BrowseIcon;