export default {
  CREATE:         '@@domain/CREATE',
  CREATE_FAILURE: '@@domain/CREATE_FAILURE',
  CREATE_SUCCESS: '@@domain/CREATE_SUCCESS',
  
  DELETE:         '@@domain/DELETE',
  DELETE_FAILURE: '@@domain/DELETE_FAILURE',
  DELETE_SUCCESS: '@@domain/DELETE_SUCCESS',

  FETCH:         '@@domain/FETCH',
  FETCH_FAILURE: '@@domain/FETCH_FAILURE',
  FETCH_SUCCESS: '@@domain/FETCH_SUCCESS',
  
  GET:         '@@domain/GET',
  GET_FAILURE: '@@domain/GET_FAILURE',
  GET_SUCCESS: '@@domain/GET_SUCCESS'
};