import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import { InstructorCard, StudioCard } from '../follows';

class FollowsPage extends React.PureComponent {
  static propTypes = {
    createBooking:      PropTypes.func.isRequired,
    createComment:      PropTypes.func.isRequired,
    createReview:       PropTypes.func.isRequired,
    followInstructor:   PropTypes.func.isRequired,
    followStudio:       PropTypes.func.isRequired,
    follows:            PropTypes.object.isRequired,
    profileData:        PropTypes.object.isRequired,
    unfollowInstructor: PropTypes.func.isRequired,
    unfollowStudio:     PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      instructors: props.follows.instructors,
      studios:     props.follows.studios
    };
  }

  state = {
    instructors: [],
    studios:     []
  }

  renderInstructorFollows = () => (
    this.state.instructors.map((instructor) => (
      <InstructorCard
          createBooking={this.props.createBooking}
          createComment={this.props.createComment}
          followInstructor={this.props.followInstructor}
          instructor={instructor}
          key={instructor.id}
          profileData={this.props.profileData}
          unfollowInstructor={this.props.unfollowInstructor} />
    ))
  )

  renderStudioFollows = () => (
    this.state.studios.map((studio) => (
      <StudioCard
          createBooking={this.props.createBooking}
          createReview={this.props.createReview}
          followStudio={this.props.followStudio}
          key={studio.id}
          studio={studio}
          unfollowStudio={this.props.unfollowStudio} />
    ))
  )

  renderNoResults = () => (
    <div>
      <div className='text-muted'>No results!</div>
      <div className='text-muted'>Follow your favorite profiles to view their schedule in your fitness planner</div>
    </div>
  )

  render() {
    return (
      <Container fluid>
        <p className='subtitle text-primary'>My Fitness Planner</p>

        <div className='mb-5'>
          <h2 className='hero-heading mb-3'>
            Your Instructors
          </h2>

          {this.state.instructors &&
          this.state.instructors.length ? this.renderInstructorFollows() : this.renderNoResults()}
        </div>

        <div>
          <h2 className='hero-heading mb-3'>
            Your Studios
          </h2>
        </div>
        {this.state.studios &&
        this.state.studios.length ? this.renderStudioFollows() : this.renderNoResults()}
      </Container>
    );
  }
}

export default FollowsPage;
