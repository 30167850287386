import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { grid_2 } from 'react-icons-kit/ikons/grid_2';
import { calendar } from 'react-icons-kit/ikons/calendar';
import { users } from 'react-icons-kit/fa/users';
import { cog } from 'react-icons-kit/fa/cog';
import { logout } from 'react-icons-kit/iconic/logout';
import { search } from 'react-icons-kit/icomoon/search';
import { creditCardAlt } from 'react-icons-kit/fa/creditCardAlt';
import { edit } from 'react-icons-kit/fa/edit';
import { play } from 'react-icons-kit/ikons/play';
import { archive } from 'react-icons-kit/fa/archive';
import { gift } from 'react-icons-kit/feather/gift';
import { percent } from 'react-icons-kit/fa/percent';
import { paintBrush } from 'react-icons-kit/fa/paintBrush';
import { star } from 'react-icons-kit/fa/star';
import { questionCircleO as question } from 'react-icons-kit/fa/questionCircleO';
import { shoppingCart } from 'react-icons-kit/fa/shoppingCart';
import { heart } from 'react-icons-kit/fa/heart';
import { arrowUp } from 'react-icons-kit/icomoon/arrowUp';
import { slideshare } from 'react-icons-kit/fa/slideshare';
import { envelope } from 'react-icons-kit/fa/envelope';

class SidebarIcon extends React.PureComponent {
  static propTypes = {
    iconName: PropTypes.string.isRequired
  };

  state = {
    icons: {
      archive,
      arrowUp,
      calendar,
      cog,
      creditCardAlt,
      edit,
      envelope,
      gift,
      grid_2,
      heart,
      logout,
      paintBrush,
      percent,
      play,
      question,
      search,
      shoppingCart,
      slideshare,
      star,
      users
    }
  }

  render () {
    const icon = this.state.icons[this.props.iconName];

    return (
      <Icon
          className='mr-2'
          icon={icon} />
    );
  }
}

export default SidebarIcon;
