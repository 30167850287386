import { EmailTemplatesActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: EmailTemplatesActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: EmailTemplatesActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: EmailTemplatesActionTypes.CREATE_SUCCESS
  }),


  FETCH: () => ({
    type: EmailTemplatesActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: EmailTemplatesActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: EmailTemplatesActionTypes.FETCH_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: EmailTemplatesActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: EmailTemplatesActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: EmailTemplatesActionTypes.UPDATE_SUCCESS
  })
};
