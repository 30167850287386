import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { FileUploader, Input } from '../inputs';
import { Button } from '../forms';

class EmailSettingsModal extends React.PureComponent {
  static propTypes = {
    emailSetting:        PropTypes.object.isRequired,
    isLoading:           PropTypes.bool,
    isOpen:              PropTypes.bool,
    onToggle:            PropTypes.func,
    updateEmailSettings: PropTypes.func.isRequired
  }

  static defaultProps = {
    isLoading: false,
    isOpen:    false,
    onToggle:  () => {}
  }

  componentDidMount() {
    const { emailSetting } = this.props;

    this.setState({ emailSetting });
  }

  state = {
    emailSetting: {
      banner: '',
      sender: ''
    }
  }

  handleChange = (value, inputName) => (
    this.setState((prevState) => ({
      emailSetting: {
        ...prevState.emailSetting,
        [inputName]: value
      }
    }))
  )

  handleClear = () => (
    this.setState({ emailSetting: { banner: '' } })
  )

  handleSubmit = () => (
    this.props.updateEmailSettings(this.state),
    this.props.onToggle()
  );

  render() {
    const { banner } = this.state.emailSetting;

    return (
      <Modal
          className='modal-refer'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          Update <span className='text-primary'>Email Settings</span>
        </ModalHeader>

        <ModalBody>
          <div className='description'>
            <div>
              <small>
                <b>Sender Name:</b> emails will always come from {'<notifications@onpodio.com>'} but the
                sender name can be changed to your business name.
              </small>
            </div>

            <div className='pt-2'>
              <small>
                <b>Email Header/Banner Image:</b> this will be displayed at the top of all emails to your clients.
              </small>
            </div>
          </div>

          <Form className='form-default pt-2'>
            <Row form>
              <Col>
                <FormGroup>
                  <Label for='sender'>
                    Sender Name
                  </Label>

                  <Input
                      id='sender'
                      name='sender'
                      onChange={this.handleChange}
                      placeholder='Kal’s Virtual Studio'
                      value={this.state.emailSetting.sender} />
                </FormGroup>
              </Col>
            </Row>

            <Row
                className='align-iterms-stretch'
                form>
              <Col>
                <FormGroup className='h-100'>
                  <Label for='banner'>
                    Email Header/Banner Image
                  </Label>

                  <FileUploader
                      aspectRatio={600 / 120}
                      className='flex-fill'
                      fileTypes={['image/jpeg', 'image/png']}
                      hasCropper
                      id='banner'
                      maxFileSize='1MB'
                      name='banner'
                      onChange={this.handleChange}
                      onDelete={this.handleClear}
                      placeholder='Drop image here'
                      size='lg'
                      value={banner && banner.attachmentId && banner} />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button
              className='mt-4'
              color='primary'
              isBlock
              isLoading={this.props.isLoading}
              onClick={this.handleSubmit}
              size='lg'
              type='button'>
            Update Settings
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EmailSettingsModal;
