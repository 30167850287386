import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { difference, filter, findIndex, isInteger} from 'lodash';
import confetti from 'canvas-confetti';
import { map } from 'lodash';

import { Button, Select } from '../forms';

class FormatOnDemandModal extends React.PureComponent {
  static propTypes = {
    createFormats: PropTypes.func.isRequired,
    formats:       PropTypes.array.isRequired,
    isOpen:        PropTypes.bool,
    onDemands:     PropTypes.array.isRequired,
    onToggle:      PropTypes.func
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  static getDerivedStateFromProps(props) {
    const blankClasses = filter(props.onDemands, (onDemand) => (
      !isInteger(onDemand.formatId)
    ));

    const formats = map(props.formats, (format) => (
      { label: format.title, value: format.id }
    ));

    return { blankClasses, formats };
  }

  state = {
    blankClasses: [],
    formats:      [],
    options:      []
  }

  componentDidMount() {
    new confetti({
      angle:         270,
      decay:         0.9,
      origin:        { y: 0 },
      particleCount: 300,
      spread:        360,
      startVelocity: 40,
      zIndex:        100
    });
  }

  handleSelect = (instructorOnDemandId) => (formatId) => {
    const option = { formatId, instructorOnDemandId };

    this.setState((prevState) => {
      const index = findIndex(prevState.options, { instructorOnDemandId });

      if (index > -1) {
        return { options: [...prevState.options.slice(0, index), option, ...prevState.options.slice(index + 1)] };
      } else {
        return { options: [...prevState.options, option] };
      }
    });
  }

  renderFormat = (onDemand) => (
    <Row key={onDemand.id}>
      <Col
          className='my-auto'
          md='4'>
        {onDemand.name}:
      </Col>

      <Col md='8'>
        <FormGroup>
          <Label
              className='required'
              for='input-format'>
            Class Format
          </Label>

          <Select
              hasInitialValue
              id='format'
              name='formatId'
              onChange={this.handleSelect(onDemand.id)}
              options={this.state.formats}
              placeholder='Select class format' />
        </FormGroup>
      </Col>
    </Row>
  )

  handleCreate = () => {
    const { options } = this.state;

    this.props.onToggle();
    this.props.createFormats({ options });
  }

  render() {
    return (
      <Modal
          className='modal-refer'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          Update your classes
        </ModalHeader>

        <ModalBody>
          <div className='description'>
            We&apos;re proud to present our integration with&nbsp;
            <a
                href='https://recess.tv'
                rel='noopener noreferrer'
                target='_blank'>
              Recess.tv
            </a>
            <br />
            In compliance with their search algorithm, please update formats of following classes:
          </div>

          <Form className='form-default pt-2'>
            {this.state.blankClasses.map(this.renderFormat)}
          </Form>

          <Button
              className='w-100 mt-2'
              color='success'
              isDisabled={!difference(this.state.options, []).length}
              onClick={this.handleCreate}>
            Save
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}

export default FormatOnDemandModal;
