import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, FormText, Row } from 'reactstrap';

import { Input } from '../../inputs';

class About extends React.PureComponent {
  static propTypes = {
    children:  PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    onChange:  PropTypes.func.isRequired,
    profile:   PropTypes.object.isRequired
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.children}

        <Row>
          <Col>
            <FormText>
              Share a bit about your background with a bio...
            </FormText>
            <FormGroup>
              <Input
                  maxLength={2000}
                  name='bio'
                  onChange={this.props.onChange}
                  placeholder='Before being a fitness professional I played...'
                  rows='6'
                  type='textarea'
                  value={this.props.profile.bio} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default About;
