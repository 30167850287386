export default {
  CREATE:         '@@emailTemplates/CREATE',
  CREATE_FAILURE: '@@emailTemplates/CREATE_FAILURE',
  CREATE_SUCCESS: '@@emailTemplates/CREATE_SUCCESS',

  FETCH:         '@@emailTemplates/FETCH',
  FETCH_FAILURE: '@@emailTemplates/FTECH_FAILURE',
  FETCH_SUCCESS: '@@emailTemplates/FETCH_SUCCESS',

  UPDATE:         '@@emailTemplates/UPDATE',
  UPDATE_FAILURE: '@@emailTemplates/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@emailTemplates/UPDATE_SUCCESS'
};
