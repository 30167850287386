import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PlaylistActions } from '../../actions';
import { SpotifyPlaylist } from '../../components/pages/profile/instructor';

class PlaylistContainer extends React.PureComponent {
  static propTypes = {
    get:        PropTypes.func.isRequired,
    instructor: PropTypes.object.isRequired
  }
  componentDidMount () {
    const { username } = this.props.instructor.data ;

    this.props.get({ username });
  }

  render () {
    return <SpotifyPlaylist {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  error:      state.error.api,
  instructor: state.instructor,
  loader:     state.loader,
  playlist:   state.playlist.data
});

const mapDispatchToProps = (dispatch) => ({
  get: (data) =>  dispatch(PlaylistActions.GET(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistContainer);