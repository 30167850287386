import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Calendr, Moment } from '../../../lib';

class SelectedMonth extends React.PureComponent {
  static propTypes = {
    days:         PropTypes.array.isRequired,
    onChange:     PropTypes.func,
    onToggle:     PropTypes.func,
    selectedDate: PropTypes.object.isRequired,
    today:        PropTypes.object.isRequired
  }

  static defaultProps = {
    groupClasses:        {},
    onChange:            () => {},
    onToggle:            () => {},
    privateGroupClasses: {},
    privateSessions:     {}
  };

  handleChange = (day) => () => {
    this.props.onChange(new Moment(day), 'selectedDate', () => {
      this.props.onToggle();
    });
  };


  renderDay = (day, index) => {
    const isToday = Calendr.isToday(day, this.props.today);
    const currentMonth = Calendr.currentMonth(day, this.props.selectedDate);
    const dayClass = classNames('month-day', { 'today': isToday }, { 'disabled': !currentMonth });

    return (
      <div
          className={dayClass}
          key={index}
          onClick={this.handleChange(day)}
          onKeyPress={this.handleChange(day)}
          role='button'
          tabIndex={0}>
        <div className='day-number'>
          <span>{day.format('D')}</span>
        </div>

        <div className='events'>

        </div>
      </div>
    );
  };

  render () {
    return (
      <div className='month-events'>
        { this.props.days.map((day, index) => (
          this.renderDay(day, index)
        ))}
      </div>
    );
  }
}

export default SelectedMonth;
