/* eslint-disable max-len, react/prop-types */
import React from 'react';

const ElegantWallet = (props) => (
  <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg">
    <path
        d="M35.0104 9.96754H5.01043C4.5539 9.91324 4.09769 10.0713 3.7726 10.3964C3.44752 10.7215 3.28946 11.1777 3.34376 11.6342V34.9675C3.28946 35.4241 3.44752 35.8803 3.7726 36.2054C4.09769 36.5305 4.5539 36.6885 5.01043 36.6342H35.0104C35.4669 36.6885 35.9232 36.5305 36.2482 36.2054C36.5733 35.8803 36.7314 35.4241 36.6771 34.9675V28.3009H25.0104C24.5539 28.3552 24.0977 28.1971 23.7726 27.872C23.4475 27.547 23.2895 27.0907 23.3438 26.6342V19.9675C23.2895 19.511 23.4475 19.0548 23.7726 18.7297C24.0977 18.4046 24.5539 18.2466 25.0104 18.3009H36.6771V11.6342C36.7314 11.1777 36.5733 10.7215 36.2482 10.3964C35.9232 10.0713 35.4669 9.91324 35.0104 9.96754Z"
        fill={props.color || '#5E7D46'} />
    <g opacity="0.4">
      <path
          d="M25.0104 18.301C24.5539 18.2467 24.0977 18.4048 23.7726 18.7299C23.4475 19.055 23.2895 19.5112 23.3438 19.9677V26.6344C23.2895 27.0909 23.4475 27.5471 23.7726 27.8722C24.0977 28.1973 24.5539 28.3553 25.0104 28.301H36.6771V18.301H25.0104Z"
          fill={props.color || '#5E7D46'} />
      <path
          d="M25.1771 3.96754L13.3438 9.96754H30.0104L27.3437 4.80087C26.9655 3.9831 26.0058 3.614 25.1771 3.96754Z"
          fill={props.color || '#5E7D46'} />
    </g>
  </svg>
);

export default ElegantWallet;
