import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class Header extends React.PureComponent {
  static propTypes = {
    children:    PropTypes.node.isRequired,
    currentStep: PropTypes.number
  }

  static defaultProps = {
    currentStep: 1
  }

  render() {
    const lastStep = this.props.currentStep === 6;
    const textClass = classNames('subtitle', { 'text-primary': !lastStep, 'text-white': lastStep });

    return (
      <div className='step-header'>
        <p className={textClass}>
          Create instructor profile
        </p>

        <h1 className="subheader h2 mb-1">
          {this.props.children}
        </h1>
      </div>
    );
  }
}

export default Header;
