import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export default (xlsData, fileName) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const exportToXLS = () => {
    const ws = XLSX.utils.json_to_sheet(xlsData);
    const wb = { SheetNames: ['data'], Sheets: { 'data': ws } };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return exportToXLS();
};
