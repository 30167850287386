import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter, map } from 'lodash';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';

import { api, extractError, routes, toCamelCase } from '../lib';
import { CouponsActionTypes } from '../constants/actionTypes';
import { CouponsActions, ErrorActions, LoaderActions } from '../actions';

function* watchCreate() {
  yield takeLatest(CouponsActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.coupon.create, payload);

      yield put(CouponsActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Coupon added');
    } catch (error) {
      yield put(CouponsActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(CouponsActionTypes.CREATE_SUCCESS, function* () {
    yield put(push(routes.COUPON));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(CouponsActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDelete() {
  yield takeLatest(CouponsActionTypes.DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.coupon.delete, payload);

      const coupons = yield select((state) => state.coupons.data);
      const data = filter(coupons, (coupon) => (coupon.id != payload.coupon.id));

      yield put(CouponsActions.DELETE_SUCCESS(data));
      toastr.success('Coupon deleted');
    } catch (error) {
      yield put(CouponsActions.DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDeleteSuccess() {
  yield takeLatest(CouponsActionTypes.DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDeleteFailure() {
  yield takeLatest(CouponsActionTypes.DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetch() {
  yield takeLatest(CouponsActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.coupon.fetch);

      yield put(CouponsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(CouponsActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(CouponsActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(CouponsActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdate() {
  yield takeLatest(CouponsActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.coupon.update, payload);
      const coupons = yield select((state) => state.coupons.data);
      const data = map(coupons, (coupon) => (
        coupon.id == payload.coupon.id ? toCamelCase(response.data) : coupon
      ));

      yield put(CouponsActions.UPDATE_SUCCESS(data));
      toastr.success('Coupon updated');
    } catch (error) {
      yield put(CouponsActions.UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(CouponsActionTypes.UPDATE_SUCCESS, function* () {
    yield put(push(routes.COUPON));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateFailure() {
  yield takeLatest(CouponsActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchGet() {
  yield takeLatest(CouponsActionTypes.GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('coupons'));
      const response = yield call(api.coupon.get, payload);

      yield put(CouponsActions.GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(CouponsActions.GET_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchGetSuccess() {
  yield takeLatest(CouponsActionTypes.GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('coupons'));
  });
}

function* watchGetFailure() {
  yield takeLatest(CouponsActionTypes.GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('coupons'));
  });
}

export default function* couponsSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),
    watchDelete(),
    watchDeleteSuccess(),
    watchDeleteFailure(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure(),
    watchGet(),
    watchGetSuccess(),
    watchGetFailure()
  ]);
}
