import React from 'react';
import PropTypes from 'prop-types';
import { Container, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';

import { routes } from '../../../lib';
import { Heading, Panel } from '../../layout/pages';
import { BackButton } from '../../buttons';
import { Integrations } from '../editProfile';

class HomeInstagramPage extends React.PureComponent {
  static propTypes = {
    fetchUser: PropTypes.func.isRequired,
    user:      PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const { user } = props;

    if (!state.isMounted) {
      return {
        isMounted: true,
        oauth:     user.oauth
      };
    }

    return {
      ...state,
      oauth: user.oauth
    };
  }

  state = {
    isMounted: false,
    oauth:     {}
  }

  render() {
    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS_HOME} />}

        <Helmet>
          <title>Home Testimonials</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container
              className='settings-page'
              fluid>
            <Heading
                color='secondary'
                subtitle='Home'
                title='Instagram Gallery' />
            <Panel className='settings-menu'>
              <p className='text-danger mx-3 mb-3'>
                Warning: If you disconnect Your Instagram account here
                it is also will be disconnected throughout the site.
              </p>
              <p className='text-muted mx-3'>
                Note: This section will show a collection of your most recent Instagram posts
                based on the account you connect(-ed) below.
              </p>
              <Media className='menu-link d-block settings-instagram'>
                <Media body>
                  <Integrations
                      fetchUser={this.props.fetchUser}
                      isOnlyInstagram
                      oauth={this.state.oauth} />
                </Media>
              </Media>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default HomeInstagramPage;
