import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError, toCamelCase } from '../lib';
import { RequestedClassesActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions, RequestedClassesActions } from '../actions';

function* watchInstructorsFetch() {
  yield takeLatest(RequestedClassesActionTypes.INSTRUCTORS_FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.requestedClass.fetch, payload);

      yield put(RequestedClassesActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(RequestedClassesActions.INSTRUCTORS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchInstructorSuccess() {
  yield takeLatest(RequestedClassesActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchInFailure() {
  yield takeLatest(RequestedClassesActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* requestedClassesSaga() {
  yield all([
    watchInstructorsFetch(),
    watchFetchInstructorSuccess(),
    watchFetchInFailure()
  ]);
}
