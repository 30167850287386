import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { ClassPackForm } from '../../forms/instructor';

class ClassPackModal extends React.PureComponent {
  static propTypes = {
    cancelClassPack: PropTypes.func.isRequired,
    classPack:       PropTypes.object.isRequired,
    createClassPack: PropTypes.func.isRequired,
    deleteSample:    PropTypes.func.isRequired,
    isLoading:       PropTypes.bool.isRequired,
    isNewRecord:     PropTypes.bool.isRequired,
    isOpen:          PropTypes.bool.isRequired,
    onToggle:        PropTypes.func.isRequired,
    updateClassPack: PropTypes.func.isRequired,
    user:            PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    const { isLoading, isNewRecord } = props;

    return {
      classPack: isNewRecord ? {} : props.classPack,
      isLoading,
      isNewRecord
    };
  }

  state = {
    classPack:   {},
    isLoading:   false,
    isNewRecord: true
  }

  handleSubmit = (classPack) => {
    this.state.isNewRecord
        ? this.props.createClassPack({ classPack })
        : this.props.updateClassPack({ classPack });
  }

  render() {
    const { isOpen, onToggle } = this.props;
    const { classPack, isNewRecord, isLoading } = this.state;

    const action = isNewRecord ? 'Create' : 'Update';

    return (
      <Modal
          centered
          className='membership-modal'
          isOpen={isOpen}
          toggle={onToggle}>
        <ModalHeader toggle={onToggle}>
          {action} <span className='text-primary'>Class Pack</span>
        </ModalHeader>

        <ModalBody>
          <ClassPackForm
              cancelClassPack={this.props.cancelClassPack}
              data={classPack}
              deleteSample={this.props.deleteSample}
              isLoading={isLoading}
              isNewRecord={isNewRecord}
              onSubmit={this.handleSubmit}
              onToggle={this.props.onToggle}
              user={this.props.user} />
        </ModalBody>
      </Modal>
    );
  }
}

export default ClassPackModal;
