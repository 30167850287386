import { FormatsActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       [],
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case FormatsActionTypes.FETCH:
      return newState;

    case FormatsActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case FormatsActionTypes.FETCH_FAILURE:
      return newState;

    default: return state;
  }
};
