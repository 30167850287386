import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api, extractError, toCamelCase } from '../lib';

import { ReviewsActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions,ReviewsActions } from '../actions';

function* watchCreate() {
  yield takeLatest(ReviewsActionTypes.CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.location.reviews.create, payload);

      yield put(ReviewsActions.CREATE_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ReviewsActions.CREATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetch() {
  yield takeLatest(ReviewsActionTypes.FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('reviews'));
      const response = yield call(api.location.reviews.fetch, payload);

      yield put(ReviewsActions.FETCH_SUCCESS(toCamelCase(response.data)));
      yield put(LoaderActions.FINISH_FIELD_LOADING('reviews'));
    } catch (error) {
      yield put(ReviewsActions.FETCH_FAILURE());
      yield put(LoaderActions.FINISH_FIELD_LOADING('reviews'));
    }
  });
}

export default function* reviewsSaga() {
  yield all([
    watchCreate(),
    watchFetch()
  ]);
}
