import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

class MainButton extends React.PureComponent {
  static propTypes = {
    isDisabled: PropTypes.bool,
    onClick:    PropTypes.func
  };

  static defaultProps = {
    isDisabled: false,
    onClick:    () => {}
  };

  render () {
    return (
      <button
          className='btn-float'
          disabled={this.props.isDisabled}
          onClick={this.props.onClick}>
        <Icon
            fixedWidth
            icon='plus' />
      </button>
    );
  }
}

export default MainButton;
