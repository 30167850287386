import React from 'react';
import PropTypes from 'prop-types';
import BackgroundImageOnLoad from 'background-image-on-load';

import { Loader } from '../../loader';

class Photo extends React.PureComponent{
  static propTypes = {
    src: PropTypes.string
  };

  static defaultProps = {
    src: ''
  };

  state = {
    isLoading: !!this.props.src
  };

  handleLoad = () => {
    this.setState({ isLoading: false });
  };

  render () {
    return (
      <div
          className='photo-bg'
          style={{ backgroundImage: `url(${this.props.src})` }}>
        { this.state.isLoading &&
          <Loader
              color='warning'
              hasOpacity={false}
              isLoading={this.state.isLoading}
              size='4.5rem'
              type='border' />
        }

        { this.props.src &&
          <BackgroundImageOnLoad
              onError={this.handleLoad}
              onLoadBg={this.handleLoad}
              src={this.props.src} />
        }
      </div>
    );
  }
}

export default Photo;
