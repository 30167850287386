import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { Container, Form, FormText, Label, Media } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Button, Checkbox } from '../../forms';
import { Heading, Panel } from '../../layout/pages';
import { Integrations } from '../editProfile';
import { PromptInput } from '../../forms/profile';
import { BackButton } from '../../buttons';
import { instructorRoutes, routes } from '../../../lib';

class SettingsStylePage extends React.PureComponent {
  static propTypes = {
    fetchUser:     PropTypes.func.isRequired,
    instructor:    PropTypes.object.isRequired,
    oAuthLogout:   PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired,
    user:          PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const { instructor, user } = props;

    if (!state.isMounted) {
      return {
        instructor,
        integrations: user.oauth,
        isMounted:    true,
        playlist:     instructor.playlist
      };
    }

    return {
      ...state,
      integrations: user.oauth,
      playlist:     instructor.playlist
    };
  }

  state = {
    instructor:   {},
    integrations: {},
    playlist:     {}
  };


  handleSubmit = () => (
    this.handleValidate().then(() => (
      this.setState((prevState) => ({
        ...prevState,
        errors: {}
      }), this.props.updateProfile(this.state.instructor))
    ))
  )

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      instructor: {
        ...prevState.instructor,
        [inputName]: value
      }
    }));
  }

  handleUpdateProfile = () => {
    const { instructor } = this.state;

    this.props.updateProfile(instructor);
  }

  render() {
    const { instructor } = this.state;

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Edit your profile</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Style' />
            <Panel className='settings-menu'>
              <Media className='menu-link d-block'>
                <Media body>
                  <div className='custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='style'
                        isChecked={instructor.stylePageEnabled}
                        name='stylePageEnabled'
                        onChange={this.handleChange} />
                    <Label
                        className='custom-control-label d-flex'
                        htmlFor='input-style'>
                      Show the&nbsp;
                      <Link to={routes.INSTRUCTOR(instructor.username) + instructorRoutes.STYLE.path}>
                        <div className='text-primary'>Style</div>
                      </Link>
                      &nbsp;page on your site
                    </Label>
                  </div>
                </Media>
              </Media>
              <span className='menu-title'>Style Prompts</span>

              <Media className='menu-link d-block'>
                <Form className='form-default'>
                  <FormText>
                    Let clients know more about you / your style..
                  </FormText>

                  <fieldset>
                    <PromptInput
                        index={0}
                        onChange={this.handleChange}
                        placeholder='Despite being in fitness I...'
                        profile={instructor} />

                    <PromptInput
                        index={1}
                        onChange={this.handleChange}
                        placeholder='My simple pleasure is a great book..'
                        profile={instructor} />

                    <PromptInput
                        index={2}
                        onChange={this.handleChange}
                        placeholder='My favorite spot in NYC is...'
                        profile={instructor} />
                  </fieldset>
                </Form>

                <Button
                    className='save-changes-btn'
                    color='success'
                    isBlock={isMobile}
                    onClick={this.handleUpdateProfile}
                    onKeyPress={this.handleSubmit}>
                  Save Changes
                </Button>
              </Media>

              <Integrations
                  fetchUser={this.props.fetchUser}
                  logout={this.props.oAuthLogout}
                  oauth={this.state.integrations}
                  playlist={this.state.playlist} />
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SettingsStylePage;
