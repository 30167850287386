import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Alert as AlertBootstrap, Media } from 'reactstrap';
import classNames from 'classnames';

class Alert extends React.PureComponent {
  static propTypes = {
    children:      PropTypes.node.isRequired,
    className:     PropTypes.string,
    color:         PropTypes.string,
    heading:       PropTypes.string,
    icon:          PropTypes.string,
    iconSize:      PropTypes.string,
    id:            PropTypes.string,
    onClick:       PropTypes.func,
    onKeyPress:    PropTypes.func,
    textClassName: PropTypes.string
  }

  static defaultProps = {
    className:     '',
    color:         'primary',
    heading:       '',
    icon:          null,
    iconSize:      '4x',
    id:            '',
    onClick:       () => {},
    onKeyPress:    () => {},
    textClassName: ''
  }

  render() {
    const { textClassName } = this.props;

    const textClass = classNames('message', textClassName);

    return (
      <AlertBootstrap
          className={this.props.className}
          color={this.props.color}
          id={this.props.id}
          onClick={this.props.onClick}
          onKeyPress={this.props.onKeyPress}>
        <Media className='align-items-center h-100'>
          { this.props.icon &&
            <Media
                className='pl-lg-1 pr-lg-3'
                left>
              <Icon
                  icon={['fas', this.props.icon]}
                  size={this.props.iconSize} />
            </Media>
          }
          <Media
              body
              className={textClass}>
            { this.props.heading &&
              <Media
                  className='alert-heading'
                  heading>
                {this.props.heading}
              </Media>
            }
            <span className='message'>
              {this.props.children}
            </span>
          </Media>
        </Media>
      </AlertBootstrap>
    );
  }
}

export default Alert;