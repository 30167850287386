import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { ClientForm } from '../../forms/instructor';

class ClientModal extends React.PureComponent {
  static propTypes = {
    client:       PropTypes.object.isRequired,
    createClient: PropTypes.func.isRequired,
    deleteClient: PropTypes.func.isRequired,
    isLoading:    PropTypes.bool.isRequired,
    isNewRecord:  PropTypes.bool.isRequired,
    isOpen:       PropTypes.bool.isRequired,
    onToggle:     PropTypes.func.isRequired,
    updateClient: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props) {
    const { isNewRecord, isLoading } = props;

    return {
      client:      isNewRecord ? {} : props.client,
      isLoading:   isLoading,
      isNewRecord: isNewRecord
    };
  }

  state = {
    client:      {},
    isLoading:   false,
    isNewRecord: true
  };

  handleSubmit = (form) => {
    const params = { client: form };

    this.state.isNewRecord ? this.props.createClient(params) : this.props.updateClient(params);
    this.props.onToggle();
  };

  handleDelete = (params) => {
    this.props.deleteClient(params);
    this.props.onToggle();
  };

  render () {
    const { isOpen, onToggle } = this.props;
    const { client, isNewRecord, isLoading } = this.state;

    const title = `${isNewRecord ? 'Add' : 'Edit'} Client Profile`;

    return (
      <Modal
          centered
          className='client-modal'
          isOpen={isOpen}
          toggle={onToggle}>

        <ModalHeader toggle={onToggle}>
          {title}
        </ModalHeader>

        <ModalBody>
          <ClientForm
              data={client}
              isLoading={isLoading}
              isNewRecord={isNewRecord}
              onDelete={this.handleDelete}
              onSubmit={this.handleSubmit} />
        </ModalBody>
      </Modal>
    );
  }
}

export default ClientModal;
