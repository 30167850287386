import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { ErrorActionTypes } from '../constants/actionTypes';
import { SessionActions } from '../actions';
import { routes } from '../lib';

const UNAUTHORIZED = 401;

function* watchNewError() {
  yield takeLatest(ErrorActionTypes.NEW, function* ({ payload }) {
    if (payload.status == UNAUTHORIZED) {
      yield put(SessionActions.LOGOUT_SUCCESS());
      yield put(push(routes.LOGIN));
    }
  });
}

export default function* routerSaga() {
  yield all([
    watchNewError()
  ]);
}
