
class ModalSaver {
  _append = (hash) => (
    this.searchParams.set('modalID', hash),
    this._replaceURL()
  )

  _detach = () => (
    this.searchParams.delete('modalID'),
    this._replaceURL()
  )

  _read = () => (
    this.searchParams.get('modalID')
  )

  _replaceURL = () => {
    const path = this._generateURL();
    window.history.pushState({ path }, '', path);
  }

  _generateURL = () => {
    const params = this.searchParams.toString();
    const url = params ? this.oldUrl + '?' + params : this.oldUrl;

    return url;
  }

  /* private */

  searchParams = new URLSearchParams(window.location.search);
  oldUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
}

export default new ModalSaver;
