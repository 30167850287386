import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../helpers';
import { AvailabilityPage } from '../components/pages';
import { ProfileActions } from '../actions';

class AvailabilityContainer extends React.PureComponent {
  render() {
    return (
      <AvailabilityPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  instructor: state.profile.data
});

const mapDispatchToProps = (dispatch) => ({
  updateAvailability: (data) => dispatch(ProfileActions.UPDATE_AVAILABILITY(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(AvailabilityContainer);
