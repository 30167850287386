import { FormatsActionTypes } from '../constants/actionTypes';

export default {
  CREATE_ON_DEMAND_FORMAT: (payload) => ({
    payload, type: FormatsActionTypes.CREATE_ON_DEMAND_FORMAT
  }),

  CREATE_ON_DEMAND_FORMAT_FAILURE: () => ({
    type: FormatsActionTypes.CREATE_ON_DEMAND_FORMAT_FAILURE
  }),

  CREATE_ON_DEMAND_FORMAT_SUCCESS: (payload) => ({
    payload, type: FormatsActionTypes.CREATE_ON_DEMAND_FORMAT_SUCCESS
  }),

  CREATE_PGC_FORMAT: (payload) => ({
    payload, type: FormatsActionTypes.CREATE_PGC_FORMAT
  }),

  CREATE_PGC_FORMAT_FAILURE: () => ({
    type: FormatsActionTypes.CREATE_PGC_FORMAT_FAILURE
  }),

  CREATE_PGC_FORMAT_SUCCESS: (payload) => ({
    payload, type: FormatsActionTypes.CREATE_PGC_FORMAT_SUCCESS
  }),

  FETCH: () => ({
    type: FormatsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: FormatsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: FormatsActionTypes.FETCH_SUCCESS
  })
};
