/* eslint-disable immutable/no-mutation, immutable/no-let */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AutocompleterActions } from '../actions';

class AutocompleterContainer extends React.PureComponent {
  static propTypes = {
    autocompleter:  PropTypes.object.isRequired,
    children:       PropTypes.node.isRequired,
    doAutocomplete: PropTypes.func.isRequired
  };

  render() {
    const { autocompleter, doAutocomplete } = this.props;

    return React.cloneElement(this.props.children, { autocompleter, doAutocomplete });
  }
}

const mapStateToProps = (state) => ({
    autocompleter: state.autocompleter
  });

const mapDispatchToProps = (dispatch) => ({
    doAutocomplete: (data) => dispatch(AutocompleterActions.AUTOCOMPLETE(data))
  });

export default connect(mapStateToProps, mapDispatchToProps)(AutocompleterContainer);
