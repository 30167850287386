/* eslint-disable max-len, immutable/no-let */
import { templates } from '../constants';
import { changeColor } from './customizationUtils';

export const selectStyle = {
  option: (provided, state) => ({
    ...provided,
    fontFamily:  `${state.data.value}, sans-serif`,
    marginLeft:  0,
    paddingLeft: 12
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: `${state.data.value}, sans-serif`,
    marginLeft: 0
  })
};

export const allFontsLink =
  'https://fonts.googleapis.com/css2?family=Anton&family=Architects+Daughter&family=Balsamiq+Sans&family=Dancing+Script&family=Dosis&family=Indie+Flower&family=Merriweather&family=Open+Sans&family=PT+Serif&family=Pacifico&family=Patrick+Hand&family=Quicksand&family=Roboto&family=Rubik&family=Satisfy&family=Source+Code+Pro&family=Ubuntu&display=swap';

export const fontOptions = [
  { label: 'Anton', value: 'Anton, sans-serif' },
  { label: 'Architects Daughter', value: 'Architects Daughter, cursive' },
  { label: 'Balsamiq Sans', value: 'Balsamiq Sans, cursive' },
  { label: 'Bebas Neue', value: 'Bebas Neue, sans-serif'},
  { label: 'Dancing Script', value: 'Dancing Script, cursive' },
  { label: 'Dosis', value: 'Dosis, sans-serif' },
  { label: 'Indie Flower', value: 'Indie Flower, cursive' },
  { label: 'Inter', value: 'Inter, sans-serif' },
  { label: 'Merriweather', value: 'Merriweather, serif' },
  { label: 'Open Sans', value: 'Open Sans, sans-serif' },
  { label: 'Pacifico', value: 'Pacifico, cursive' },
  { label: 'Patrick Hand', value: 'Patrick Hand, cursive' },
  { label: 'Poppins', value: 'Poppins, sans-serif' },
  { label: 'PT Serif', value: 'PT Serif, serif' },
  { label: 'Quicksand', value: 'Quicksand, sans-serif' },
  { label: 'Roboto', value: 'Roboto, sans-serif' },
  { label: 'Rubik', value: 'Rubik, sans-serif' },
  { label: 'Satisfy', value: 'Satisfy, cursive' },
  { label: 'Source Code Pro', value: 'Source Code Pro, monospace' },
  { label: 'Ubuntu', value: 'Ubuntu, sans-serif' }
];

export const templateOptions = [
  { label: 'Stanton', value: templates.DEFAULT },
  /*{ label: capitalize(templates.URBAN), value: templates.URBAN },*/
  { label: 'Paloma', value: templates.ELEGANT }
];

export const customStyles = (customization) => {
  const {
    bodyFontColor,
    bodyFontFamily,
    buttonsBackgroundColor,
    buttonsFontColor,
    headingsFontFamily,
    pageBackgroundColor,
    primaryAccentsBackgroundColor,
    primaryAccentsFontColor,
    secondaryAccentsBackgroundColor,
    secondaryAccentsFontColor,
    siteTemplate
  } = customization;

  const defaultStyles = `
    * {
      font-weight: 400 !important;
    }
    body {
      color: ${bodyFontColor};
    }
    .navbar-instructor-menu .navbar-nav .nav-link {
      background-color: transparent;
      border: none;
    }
    .inner-wrapper.instructor-profile {
      background-color: ${pageBackgroundColor};
      font-family: ${bodyFontFamily};
    }

    .about-body__section,
    .nav-link,
    .workout__date,
    .workout__section-headspan,
    .instructor-name .full-name,
    .instructor-name .instructor-subtitle,
    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
      font-family: ${headingsFontFamily};
    }

    .workout__section-headspan,
    .group-classes__view-all,
    .private-sessions__arrow,
    .instructor-subtitle,
    .comment-author {
      color: ${primaryAccentsFontColor};
    }

    .instructor-name .full-name,
    .instructor-name .instructor-subtitle {
      color: ${primaryAccentsBackgroundColor}
    }

    .page-title,
    .style__title, {
      color: ${secondaryAccentsFontColor};
    }

    .workout__section-header {
      background-color: ${primaryAccentsBackgroundColor};
    }
    .workout__date-header {
      background-color: ${secondaryAccentsBackgroundColor};
    }
    .workout__date {
      color: ${secondaryAccentsFontColor};
    }
    .workout {
      font-family: ${bodyFontFamily};
    }
    .about-body__section {
      background-color: ${secondaryAccentsBackgroundColor};
      color: ${secondaryAccentsFontColor};
      font-family: ${headingsFontFamily};
    }
    .profile-private-sessions .time-slots .availability-block .slots .btn-sm,
    .profile-private-sessions .time-slots .availability-block .slots .btn-group-sm > .btn {
      background-color: ${buttonsBackgroundColor};
      color: ${buttonsFontColor};
    }
    .optional-sections .btn-primary {
      background-color: ${secondaryAccentsBackgroundColor};
      border-color: ${secondaryAccentsBackgroundColor};
      color: ${secondaryAccentsFontColor};
    }
    .optional-sections .btn-secondary {
      background-color: ${buttonsBackgroundColor};
      border-color: ${buttonsBackgroundColor};
      color: ${buttonsFontColor};
    }
    .btn-primary:focus, .btn-primary.focus,
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem ${changeColor(secondaryAccentsBackgroundColor, -25)};
    }
    .btn-secondary:focus, .btn-secondary.focus,
    .workout .group-class .btn-primary.focus, .workout .group-class .btn-primary:focus,
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem ${changeColor(buttonsBackgroundColor, -25)};
    }
    .btn-primary:not(:disabled):not(.disabled):active,
    .btn-primary:not(:disabled):not(.disabled).active,
    .show > .btn-primary.dropdown-toggle,
    .btn-primary:hover {
      background-color: ${changeColor(secondaryAccentsBackgroundColor, -50)} !important;
      color: ${changeColor(secondaryAccentsFontColor, 50)} !important;
    }
    .btn-secondary:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle,
    .workout .group-class .btn-primary:hover,
    .btn-secondary:hover {
      background-color: ${changeColor(buttonsBackgroundColor, -50)} !important;
      color: ${changeColor(buttonsFontColor, 50)} !important;
    }
    .btn-primary:hover {
      border-color: ${secondaryAccentsBackgroundColor};
    }
    .btn-secondary:hover {
      border-color: ${buttonsBackgroundColor};
    }
    .optional-sections .testimonials-section {
      background: linear-gradient(
        90deg,
        white 0%,
        white 50%,
        ${secondaryAccentsBackgroundColor} 50%,
        ${secondaryAccentsBackgroundColor} 100%
      );
    }
    .optional-sections .image-with-text-section {
      background-color: ${secondaryAccentsBackgroundColor};
      color: ${secondaryAccentsFontColor} !important;
    }
    .optional-sections .section-heading,
    .optional-sections .image-with-text-section .iwt-container .iwt-content .iwt-text,
    .optional-sections .testimonials-section .testimonials-container .carousel .carousel-item .testimonial-body,
    .optional-sections .testimonials-section .testimonials-container .carousel .carousel-item .testimonial-author {
      color: ${secondaryAccentsFontColor};
    }
    .optional-sections .image-with-text-section .iwt-container .iwt-content .iwt-section-buttons .btn {
      background-color: ${buttonsBackgroundColor};
      color: ${buttonsFontColor};
    }
    .workout .group-class .btn-primary, .workout .card-event .btn-primary {
      background-color: ${buttonsBackgroundColor};
      color: ${buttonsFontColor};
      border-color: ${buttonsBackgroundColor};
    }
    .workout .btn-toolbar .btn-primary {
      background-color: ${primaryAccentsBackgroundColor};
      color: ${primaryAccentsFontColor};
      border-color: ${primaryAccentsBackgroundColor};
    }
    .workout .btn-toolbar .btn-primary:hover {
      background-color: ${changeColor(primaryAccentsBackgroundColor, 25)} !important;
      color: ${changeColor(primaryAccentsFontColor, 25)} !important;
      border-color: ${changeColor(primaryAccentsBackgroundColor, 25)} !important;
    }
    .workout .btn-toolbar .btn-secondary {
      background-color: ${secondaryAccentsBackgroundColor};
      color: ${secondaryAccentsFontColor};
      border-color: ${secondaryAccentsFontColor};
    }
    .workout .btn-toolbar .btn-secondary:hover {
      background-color: ${changeColor(secondaryAccentsBackgroundColor, 25)} !important;
      color: ${changeColor(secondaryAccentsFontColor, 25)} !important;
      border-color: ${changeColor(secondaryAccentsFontColor, 25)} !important;
    }
    .template-elegant.workout .btn-toolbar .btn-secondary {
      background-color: ${secondaryAccentsBackgroundColor};
      color: ${secondaryAccentsFontColor};
      border-color: ${secondaryAccentsBackgroundColor};
    }
    .template-elegant.workout .btn-toolbar .btn-secondary:hover {
      background-color: ${changeColor(secondaryAccentsBackgroundColor, 25)} !important;
      color: ${changeColor(secondaryAccentsFontColor, 25)} !important;
      border-color: ${changeColor(secondaryAccentsBackgroundColor, 25)} !important;
    }

     @media (max-width: 991.98px) {
       .optional-sections .testimonials-section {
         background: linear-gradient(
           white 0%,
           white 50%,
           ${secondaryAccentsBackgroundColor} 50%,
           ${secondaryAccentsBackgroundColor} 100%
         );
       }
     }
  `;

  const urbanStyles = `
    .page-title {
      color: ${primaryAccentsFontColor};
    }

    .workout__section-header {
      background-color: ${secondaryAccentsBackgroundColor};
    }
    .private-sessions__arrow,
    .workout__section-headspan,
    .group-classes__view-all {
      color: ${secondaryAccentsFontColor};
    }

    .instructor-name .full-name,
    .instructor-name .instructor-subtitle {
      color: ${primaryAccentsFontColor};
    }
    .template-urban-navbar {
      width: 230px;
      height: 100vh;
      position: fixed;
      flex-direction: column;
    }
    .inner-wrapper.instructor-profile {
      margin-left: 230px;
    }
    .template-urban-navbar .navbar-instructor-menu .navbar-nav .nav-link {
      margin: 30px auto;
    }
    .template-urban-navbar .dropdown-menu-right {
      top: -460px;
      left: 160px;
      background-color: ${secondaryAccentsBackgroundColor};
    }

    .navbar-light .navbar-nav .nav-link,
    .template-urban-navbar .dropdown-item {
      color: ${secondaryAccentsFontColor};
    }

    .template-urban-navbar .dropdown-item:hover,
    .template-urban-navbar .dropdown-item:focus {
      color: ${primaryAccentsBackgroundColor};
      background-color: transparent;
    }
    .template-urban-navbar.navbar .dropdown-menu.dropdown-menu-right::before,
    .template-urban-navbar.navbar .dropdown-menu.dropdown-menu-right::after {
      display: none;
    }
    .template-urban-navbar .dropdown-toggle::after {
      transform: rotate(-90deg);
    }
    .dropup, .dropright, .dropdown, .dropleft {
      cursor: pointer;
    }
    .template-urban-navbar .dropup:hover .dropdown-toggle::after,
    .template-urban-navbar .dropright:hover .dropdown-toggle::after,
    .template-urban-navbar .dropdown:hover .dropdown-toggle::after,
    .template-urban-navbar .dropleft:hover .dropdown-toggle::after {
      border-top-color: ${primaryAccentsBackgroundColor};
    }
    .template-urban-navbar .navbar-nav {
      text-align: center;
    }
    .navbar .nav-link {
      color: ${secondaryAccentsFontColor};
      font-family: ${headingsFontFamily};
    }
    .navbar .nav-link.active,
    .navbar .nav-link:hover {
      color: ${primaryAccentsBackgroundColor};
    }
    .navbar {
      background-color: ${secondaryAccentsBackgroundColor};
    }
    .navbar-instructor-menu .navbar-nav .nav-link {
      color: ${secondaryAccentsFontColor};
    }
    .navbar-instructor-menu .navbar-nav .nav-link.active,
    .navbar-instructor-menu .navbar-nav .nav-link:hover {
      color: ${primaryAccentsBackgroundColor};
    }
    .template-urban-navbar .dropdown-toggle::after {
      border-top-color: ${secondaryAccentsFontColor};
    }
    .navbar-light .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23${secondaryAccentsFontColor.replace(
        '#',
        ''
      )}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
    .template-urban .profile-hero {
      background-color: ${pageBackgroundColor} !important;
    }

    .template-urban .instructor-tagline {
      background-color: ${primaryAccentsBackgroundColor};
    }

    .progress-bar.bg-success {
      background-color: ${primaryAccentsBackgroundColor} !important;
    }

    .instructor-name .full-name {
      background-color: ${primaryAccentsBackgroundColor};
    }

    .btn-secondary {
      color: ${buttonsFontColor};
      background-color: ${buttonsBackgroundColor};
      border-color: ${buttonsBackgroundColor};
      border-radius: 3.783px;
    }
    .profile-hero-contacts-heading {
      color: ${secondaryAccentsBackgroundColor};
      font-family: ${headingsFontFamily};
    }
    .profile-hero-contacts {
      color: ${secondaryAccentsFontColor};
      background-color: ${secondaryAccentsBackgroundColor};
    }
    .profile-contact {
      color: ${secondaryAccentsFontColor};
    }

    .workout__date-header {
      background-color: white;
    }
    .workout__date {
      color: black;
      font-family: ${bodyFontFamily};
    }
    .body-class__name,
    .event-location,
    .template-urban.profile-section .profile-info-mid .instructor-activities,
    .body-class__requestedName,
    .card-package .card-title,
    .card-package .card-price .price {
      font-family: ${headingsFontFamily};
    }
    a:hover {
      color: ${primaryAccentsBackgroundColor};
    }
    .optional-sections .testimonials-section .testimonials-container .carousel .carousel-indicators li {
      background-color: ${secondaryAccentsFontColor};
    }
    .optional-sections .testimonials-section .testimonials-container .carousel .carousel-indicators li.active {
      background-color: ${primaryAccentsBackgroundColor};
    }
    @media (max-width: 991.98px) {
      .inner-wrapper.instructor-profile {
        margin-left: 0;
      }
    }
  `;

  const elegantStyles = `
    .navbar-instructor-menu {
      height: 83%;
    }
    .navbar-instructor-menu .navbar-nav .nav-link.active,
    .navbar-instructor-menu .navbar-nav .nav-link:hover {
      border-color: ${primaryAccentsBackgroundColor};
    }
    .template-elegant-navbar .navbar-nav .nav-item.active:after {
      background-color: ${secondaryAccentsBackgroundColor};
    }
    .navbar-instructor-menu .navbar-nav .nav-link,
    .template-elegant-navbar-right .navbar-nav .nav-link {
      color: ${primaryAccentsFontColor};
    }
    .mobile-menu .navbar-nav .nav-link {
      font-size: 1.3rem;
    }
    .navbar-instructor-menu .navbar-nav .nav-linkcd:hover,
    .template-elegant-navbar-right .navbar-nav .nav-link:hover {
      text-decoration: none;
      color: ${primaryAccentsBackgroundColor};
    }
    .instructor-name .full-name,
    .instructor-name .instructor-subtitle,
    .instructor-name .location {
      color: white;
    }
    .template-elegant .profile-contacts-footer .profile-contact {
      color: ${primaryAccentsBackgroundColor};
    }
    .template-elegant .profile-contacts-footer .social-links .profile-contact-icon,
    .template-elegant-navbar .mobile-menu .social-links .profile-contact-icon {
      background-color: ${primaryAccentsBackgroundColor};
    }
    .template-elegant .profile-contacts-footer .social-links .profile-contact-icon svg,
    .template-elegant-navbar .mobile-menu .social-links .profile-contact-icon svg {
      color: ${primaryAccentsFontColor};
    }
    .template-elegant-navbar .mobile-menu.show {
      background-color: rgba(0, 0, 0, .75);
    }
    .btn-primary {
      color: ${secondaryAccentsFontColor};
      background-color: ${secondaryAccentsBackgroundColor};
    }
    .btn-secondary {
      color: ${buttonsFontColor};
      background-color: ${buttonsBackgroundColor};
    }
    .btn {
      border: none;
    }
    .workout__section-header,
    .workout__date-header {
      background-color: ${pageBackgroundColor};
    }
    .workout__section-headspan,
    .group-classes__view-all,
    .private-sessions__arrow {
      color: ${primaryAccentsBackgroundColor};
    }
    .workout__date-header:before {
      background-color: ${primaryAccentsBackgroundColor};
    }
    .template-elegant.workout .btn-link,
    .template-elegant.workout .body-class-icon,
    .template-elegant.memberships .profile-packages.class-packs .card-package .btn,
    .template-elegant.style .style__body .style__comments-header .btn {
      color: ${primaryAccentsBackgroundColor};
    }
    .profile-private-sessions .workout__date,
    .template-elegant.style .style__body .page-title {
      color: ${primaryAccentsBackgroundColor};
    }

    .profile-private-sessions .time-slots .availability-block .slots .btn-sm,
    .profile-private-sessions .time-slots .availability-block .slots .btn-group-sm > .btn {
      color: ${secondaryAccentsFontColor};
      background-color: ${secondaryAccentsBackgroundColor};
    }

    .optional-sections .testimonials-section {
      background: linear-gradient(
        90deg,
        ${secondaryAccentsBackgroundColor} 0%,
        ${secondaryAccentsBackgroundColor} 50%,
        ${primaryAccentsBackgroundColor} 50%,
        ${primaryAccentsBackgroundColor} 100%
      );
    }
    .optional-sections .image-with-text-section {
      background-color: ${primaryAccentsBackgroundColor};
      color: ${primaryAccentsFontColor} !important;
    }
    .optional-sections .upcoming-classes-section {
      background-color: ${secondaryAccentsBackgroundColor};
    }
    .optional-sections .testimonials-section .testimonials-container .carousel .carousel-indicators li {
      border-radius: 100%;
      background-color: rgba(0,0,0,.3);
    }
    .optional-sections .testimonials-section .testimonials-container .carousel .carousel-indicators li.active {
      border-radius: 100%;
      background-color: white;
    }
    .optional-sections .section-heading,
    .optional-sections .image-with-text-section .iwt-container .iwt-content .iwt-text,
    .optional-sections .testimonials-section .testimonials-container .carousel .carousel-item .testimonial-body,
    .optional-sections .testimonials-section .testimonials-container .carousel .carousel-item .testimonial-author {
      color: ${primaryAccentsFontColor};
    }

    .optional-sections .upcoming-classes-section .section-heading,
    .optional-sections .testimonials-section .testimonials-container .testimonials-heading-wrapper .testimonials-heading {
      color: ${secondaryAccentsFontColor};
    }

    .optional-sections .upcoming-classes-section .btn-primary {
      background-color: white;
      border: none;
      color: black;
    }
    .optional-sections .upcoming-classes-section .btn-secondary {
      background-color: ${secondaryAccentsBackgroundColor};
      border-color: ${secondaryAccentsBackgroundColor};
      color: ${secondaryAccentsFontColor};
    }

    @media (max-width: 991.98px) {
      .optional-sections .testimonials-section {
        background: linear-gradient(
          ${secondaryAccentsBackgroundColor} 0%,
          ${secondaryAccentsBackgroundColor} 25%,
          ${primaryAccentsBackgroundColor} 25%,
          ${primaryAccentsBackgroundColor} 100%
        );
      }
    }

    @media (max-width: 767.98px) {
      .modal-private-session .modal-body .profile-private-sessions .time-slots .availability-block .slots .btn-sm,
      .modal-private-session .modal-body .profile-private-sessions .time-slots .availability-block .slots .btn-group-sm > .btn {
        width: 84px;
        height: 32px;
        margin-right: 10px;
        margin-bottom: 10px;
        border-radius: 0;
      }
    }
  `;

  switch (siteTemplate) {
    case templates.ELEGANT:
      return defaultStyles + elegantStyles;

    case templates.URBAN:
      return defaultStyles + urbanStyles;

    default:
      return defaultStyles;
  }
};
