import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { userO } from 'react-icons-kit/fa/userO';

const UserIcon = ({ className, size }) => (
  <Icon
      className={className}
      icon={userO}
      size={size} />
);

UserIcon.propTypes = {
  className: PropTypes.string,
  size:      PropTypes.number
};

UserIcon.defaultProps = {
  className: '',
  size:      32
};

export default UserIcon;