import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty, omit } from 'lodash';
import { InvalidNameservers } from '../../errors';

import { Input } from '../../inputs';
import { Button } from '../';
import { Validator } from '../../../lib';
import { ProBadge } from '../../badges';

class DomainForm extends React.PureComponent {
  static propTypes = {
    data:       PropTypes.object.isRequired,
    hasProPlan: PropTypes.bool,
    isLoading:  PropTypes.bool.isRequired,
    onCreate:   PropTypes.func.isRequired,
    onDelete:   PropTypes.func.isRequired
  }

  static defaultProps = {
    hasProPlan: false
  }

  static getDerivedStateFromProps(props) {
    const hasDomain = !isEmpty(props.data);

    return { hasDomain };
  }

  state = {
    errors:    {},
    hasDomain: false,
    host:      ''
  }

  handleChange = (value, inputName) => {
    this.setState({
      errors:      omit(this.state.errors, inputName),
      [inputName]: value
    });
  }

  handleCreate = () => {
    const { host } = this.state;

    return this.handleValidate().then(() => {
      return this.props.onCreate(host);
    });
  }

  handleDelete = () => {
    this.props.onDelete();
    this.setState({
      host: ''
    });
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { host } = this.state;

    const errors = Validator.validate([
      ['host',  Validator.concepts.domainValidator, [host]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      this.setState({ errors }, () => reject(errors));
    }
  });

  componentDidUpdate (prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        host: isEmpty(this.props.data) ?  '' : this.props.data.host
      });
    }
  }

  render () {
    const { host, hasDomain, errors } = this.state;
    const { isLoading, data, hasProPlan } = this.props;

    return (
      <Form className='form-default'>
        <Row className='align-items-end'>
          <Col
              lg={5}
              xs={12}>
            <FormGroup>
              <Label>
                <span className='menu-title p-0 text-dark'>
                  Add your custom domain {!hasProPlan && <ProBadge />}
                </span>
              </Label>
              <Input
                  id='host'
                  isDisabled={hasDomain || !hasProPlan}
                  isInvalid={!!errors.host}
                  name='host'
                  onChange={this.handleChange}
                  placeholder='example.com'
                  value={host} />
              <FormFeedback tooltip>{errors.host}</FormFeedback>
            </FormGroup>
          </Col>
          <Col
              lg={7}
              xs={12}>
            <FormGroup>
              <Button
                  className='mr-1'
                  color={hasDomain ? 'danger' : 'success'}
                  isDisabled={!hasProPlan}
                  isLoading={isLoading}
                  onClick={hasDomain ? this.handleDelete : this.handleCreate}
                  type='button'>
                { hasDomain ? 'Remove domain' : 'Add domain'}
              </Button>
            </FormGroup>
          </Col>
        </Row>

        { !isEmpty(data) && !data.properDns && data.parsed &&
          <InvalidNameservers nameservers={data.nameservers} />
        }
      </Form>
    );
  }
}

export default DomainForm;
