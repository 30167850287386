import { ProvidersActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       [],
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case ProvidersActionTypes.FETCH:
      return newState;

    case ProvidersActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case ProvidersActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    default: return state;
  }
};
