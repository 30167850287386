export default {
    BTN_ICON_DOWN:  'chevron-down',
    BTN_ICON_RIGHT: 'chevron-right',

    DRAG_THRESHOLD: 15,

    EVENT_HEIGHT_LG: 215,
    EVENT_HEIGHT_SM: 143,

    EVENT_WIDTH_LG: 277,
    EVENT_WIDTH_SM: 375,

    HORIZONTAL: 'horizontal',

    INSTRUCTORS_HEIGHT: 332,
    INSTRUCTORS_WIDTH:  1019,

    ITEM_HEIGHT: 130,

    ITEM_WIDTH_LG: 150,
    ITEM_WIDTH_SM: 130,

    VERTICAL: 'vertical',

    VISIBLE_ITEMS: 4
};