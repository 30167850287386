/* global process */
import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem, Nav, NavItem, NavLink } from 'reactstrap';
import { compact, first, includes, replace, upperCase } from 'lodash';
import classNames from 'classnames';
import { FontAwesomeIcon as AwesomeIcon } from '@fortawesome/react-fontawesome';
import { withRouter } from 'react-router-dom';
import { isEmpty, startsWith } from 'lodash';

import { routes } from '../../../lib';
import { layoutOptions } from '../../../constants';
import { UserAvatar } from '../../users';
import { InstructorFilters } from '../../pages/scheduleFilters';
import { ListCollapse } from '../../lists';
import { SidebarIcon } from '../../icons';
import { Link } from '../../inputs';

const { ORIGIN_URL } = process.env;

class Sidebar extends React.PureComponent {
  static propTypes = {
    domain:          PropTypes.object.isRequired,
    handleSiteShare: PropTypes.func.isRequired,
    hasCustomDomain: PropTypes.bool.isRequired,
    instructors:     PropTypes.array,
    isOpen:          PropTypes.bool.isRequired,
    location:        PropTypes.object.isRequired,
    locations:       PropTypes.array,
    logOut:          PropTypes.func.isRequired,
    match:           PropTypes.object.isRequired,
    onFiltersChange: PropTypes.func,
    onFiltersReset:  PropTypes.func,
    onSearchToggle:  PropTypes.func.isRequired,
    onToggle:        PropTypes.func,
    profile:         PropTypes.object.isRequired,
    user:            PropTypes.object.isRequired
  }

  static defaultProps = {
    group:           '',
    instructors:     [],
    isSearchOpen:    false,
    locations:       [],
    onFiltersChange: () => {},
    onFiltersReset:  () => {},
    onToggle:        () => {}
  }

  static getDerivedStateFromProps(props) {
    return {
      hasProfile: !isEmpty(props.profile),
      user:       props.user
    };
  }

  state = {
    collection: '',
    user:       {}
  }

  _calcCollection = () => (
    first(compact(Object.keys(layoutOptions.SIDEBAR_GROUP_ROUTES).map((k) => {
      if (includes(layoutOptions.SIDEBAR_GROUP_ROUTES[k], this.props.match.path)) {
        return layoutOptions.SIDEBAR_GROUPS[k];
      }
    })))
  )

  componentDidMount() {
    const collection = this._calcCollection(this.props);

    this.setState({ collection });
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.path != prevProps.match.path) {
      this.setState({ collection: this._calcCollection() });
    }
  }

  renderLink = () => (
    this.props.domain.host
        ?
          <a
              className='nav-link'
              href={`https://${this.props.domain.host}`}>{this.props.domain.host}</a>
        :
          <Link
              className='nav-link'
              onClick={this.props.handleSiteShare}
              to={routes.INSTRUCTOR(this.props.profile.username)}>
            {`me.onPodiо.com/${this.props.profile.username}`}
          </Link>
  )

  renderTitle = (title) =>  {
    const groupLabel = replace(upperCase(title), /\s+/g, '_');
    const active = includes(layoutOptions.SIDEBAR_GROUP_ROUTES[groupLabel], this.props.match.path);
    const style = classNames('group-label text-primary', { 'text-secondary': active });

    return (
      <h6 className={style}>
        {title}
      </h6>
    );
  }

  handleSearchToggle = () => {
    this.props.onSearchToggle();
  }

  handleChangeCollection = (collection) => () => (
    this.setState({ collection })
  )

  handleLogOut = () => {
    this.props.onToggle();
    this.props.logOut();
  }

  render() {
    const { user, collection } = this.state;
    const { location, hasCustomDomain, profile } = this.props;

    const barClass = classNames('sidebar', { 'is-open': this.props.isOpen });
    const userFullName = [user.firstName, user.lastName].join(' ');

    const isGroupOpen = (title) => {
      return collection === title;
    };

    const combineRoute = (route) => {
      return hasCustomDomain ? ORIGIN_URL + route : route;
    };

    const britProfile = profile.id == 3385;

    return (
      <aside
          className={barClass}
          id='account-menu'>

        <AwesomeIcon
            className='sidebar-icon-close d-lg-none'
            icon={['fas', 'times']}
            onClick={this.props.onToggle} />
        <div className='sidebar-user'>
          <div className='user-avatar'>
            <UserAvatar
                alt={userFullName}
                className='mb-3'
                size='lg'
                src={user.photo} />

            <h5>{userFullName}</h5>

            {profile.id && !britProfile && this.renderLink()}
          </div>
        </div>

        { !isEmpty(this.props.profile) &&
          <ListCollapse
              isOpen={isGroupOpen(layoutOptions.SIDEBAR_GROUPS.CREATOR_PORTAL)}
              onClick={this.handleChangeCollection(layoutOptions.SIDEBAR_GROUPS.CREATOR_PORTAL)}
              title={this.renderTitle('Creator Portal')}>
            <div className='sidebar-block py-2'>
              <Nav vertical>
                <NavItem active={location.pathname === routes.INSTRUCTOR_DASHBOARD}>
                  <Link
                      className='nav-link'
                      to={combineRoute(routes.INSTRUCTOR_DASHBOARD)}>
                    <SidebarIcon iconName='grid_2' />
                    Dashboard
                  </Link>
                </NavItem>

                <NavItem active={location.pathname === routes.CALENDAR}>
                  <Link
                      className='nav-link'
                      to={combineRoute(routes.CALENDAR)}>
                    <SidebarIcon iconName='calendar' />
                    My Class Schedule
                  </Link>
                </NavItem>

                <NavItem active={startsWith(location.pathname, routes.ON_DEMAND)}>
                  <Link
                      className='nav-link'
                      to={combineRoute(routes.ON_DEMAND)}>
                    <SidebarIcon iconName='play' />
                    On-Demand
                  </Link>
                </NavItem>

                <NavItem active={startsWith(location.pathname, routes.PRIVATE_SESSIONS)}>
                  <Link
                      className='nav-link'
                      to={combineRoute(routes.PRIVATE_SESSIONS)}>
                    <SidebarIcon iconName='slideshare' />
                    Private Sessions
                  </Link>
                </NavItem>

                <NavItem active={location.pathname === routes.PACKAGES}>
                  <Link
                      className='nav-link'
                      to={combineRoute(routes.PACKAGES)}>
                    <SidebarIcon iconName='archive' />
                    Packages
                  </Link>
                </NavItem>

                <NavItem active={location.pathname === routes.COUPONS}>
                  <Link
                      className='nav-link'
                      to={combineRoute(routes.COUPONS)}>
                    <SidebarIcon iconName='percent' />
                    Coupons
                  </Link>
                </NavItem>

                <NavItem active={location.pathname === routes.TRANSACTIONS}>
                  <Link
                      className='nav-link'
                      to={combineRoute(routes.TRANSACTIONS)}>
                    <SidebarIcon iconName='creditCardAlt' />
                    Transactions
                  </Link>
                </NavItem>

                <NavItem active={location.pathname === routes.MARKETING}>
                  <Link
                      className='nav-link'
                      to={combineRoute(routes.MARKETING)}>
                    <SidebarIcon iconName='envelope' />
                    Marketing
                  </Link>
                </NavItem>

                <NavItem active={location.pathname === routes.CLIENTS}>
                  <Link
                      className='nav-link'
                      to={combineRoute(routes.CLIENTS)}>
                    <SidebarIcon iconName='users' />
                    Clients
                  </Link>
                </NavItem>

                <NavItem active={includes(layoutOptions.SETTINGS_GROUP, location.pathname)}>
                  <Link
                      className='nav-link'
                      to={combineRoute(routes.SETTINGS)}>
                    <SidebarIcon iconName='cog' />
                    Settings
                  </Link>
                </NavItem>

                <DropdownItem divider />

                { !user.pro &&
                  <NavItem>
                    <Link
                        className='nav-link text-success'
                        to={combineRoute(routes.PLANS_AND_BILLING)}>
                      <SidebarIcon iconName='arrowUp' />
                      Upgrade to <strong>PRO</strong>
                    </Link>
                  </NavItem>
                }

                <NavItem active={location.pathname === routes.SUPPORT}>
                  <Link
                      className='nav-link'
                      to={combineRoute(routes.SUPPORT)}>
                    <SidebarIcon iconName='question' />
                    Help & Support
                  </Link>
                </NavItem>
              </Nav>
            </div>
          </ListCollapse>
        }

        <ListCollapse
            isOpen={isGroupOpen(layoutOptions.SIDEBAR_GROUPS.CLIENT_PORTAL)}
            onClick={this.handleChangeCollection(layoutOptions.SIDEBAR_GROUPS.CLIENT_PORTAL)}
            title={this.renderTitle('Client Portal')}>
          <div className='sidebar-block'>
            <Nav vertical>
              <NavItem active={location.pathname === routes.DASHBOARD}>
                <Link
                    className='nav-link'
                    to={combineRoute(routes.DASHBOARD)}>
                  <SidebarIcon iconName='grid_2' />
                  Dashboard
                </Link>
              </NavItem>

              <NavItem active={location.pathname === routes.PURCHASES}>
                <Link
                    className='nav-link'
                    to={combineRoute(routes.PURCHASES)}>
                  <SidebarIcon iconName='shoppingCart' />
                  Booking History
                </Link>
              </NavItem>

              <NavItem active={location.pathname === routes.FAVORITE_ON_DEMANDS}>
                <Link
                    className='nav-link'
                    to={combineRoute(routes.FAVORITE_ON_DEMANDS)}>
                  <SidebarIcon iconName='heart' />
                  Favorite On Demand
                </Link>
              </NavItem>

              <NavItem active={location.pathname === routes.INSTRUCTOR_CLASS_PLANNER}>
                <Link
                    className='nav-link'
                    to={combineRoute(routes.INSTRUCTOR_CLASS_PLANNER)}>
                  <SidebarIcon iconName='calendar' />
                  Favorite Instructor Schedule
                </Link>
              </NavItem>

              { location.pathname === routes.INSTRUCTOR_CLASS_PLANNER &&
                <InstructorFilters
                    instructors={this.props.instructors}
                    locations={this.props.locations}
                    onFiltersChange={this.props.onFiltersChange}
                    onFiltersReset={this.props.onFiltersReset} />
              }
            </Nav>
          </div>
        </ListCollapse>

        <ListCollapse
            isOpen={isGroupOpen(layoutOptions.SIDEBAR_GROUPS.ACCOUNT)}
            onClick={this.handleChangeCollection(layoutOptions.SIDEBAR_GROUPS.ACCOUNT)}
            title={this.renderTitle('Account')}>
          <div className='sidebar-block'>
            <Nav vertical>
              <NavItem active={location.pathname === routes.EDIT_ACCOUNT}>
                <Link
                    className='nav-link'
                    to={combineRoute(routes.EDIT_ACCOUNT)}>
                  <SidebarIcon iconName='cog' />
                  User Settings
                </Link>
              </NavItem>

              <NavItem>
                <NavLink
                    className='pointer'
                    onClick={this.handleLogOut}>
                  <SidebarIcon iconName='logout' />
                  Logout
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </ListCollapse>
      </aside>
    );
  }
}

export default withRouter(Sidebar);
