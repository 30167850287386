import { LoaderActionTypes } from '../constants/actionTypes';

export default {
  FINISH_FIELD_LOADING: (payload) => ({
    payload,
    type: LoaderActionTypes.LOADING_FIELD_FINISHED
  }),

  FINISH_LOADING: () => ({
    type: LoaderActionTypes.LOADING_FINISHED
  }),

  START_FIELD_LOADING: (payload) => ({
    payload,
    type: LoaderActionTypes.LOADING_FIELD_STARTED
  }),

  START_LOADING: () => ({
    type: LoaderActionTypes.LOADING_STARTED
  })
};
