import { LocationsActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       [],
  lastAction: '',
  selected:   {}
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case LocationsActionTypes.CREATE:
      return newState;

    case LocationsActionTypes.CREATE_SUCCESS:
      return { ...newState, data: [...state.data, action.payload] };

    case LocationsActionTypes.CREATE_FAILURE:
      return newState;


    case LocationsActionTypes.DELETE:
      return newState;

    case LocationsActionTypes.DELETE_SUCCESS:
      return { ...newState, data: action.payload };

    case LocationsActionTypes.DELETE_FAILURE:
      return newState;


    case LocationsActionTypes.FETCH:
      return newState;

    case LocationsActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case LocationsActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case LocationsActionTypes.UPDATE:
      return newState;

    case LocationsActionTypes.UPDATE_SUCCESS:
      return { ...newState, data: action.payload, selected: initialState.selected };

    case LocationsActionTypes.UPDATE_FAILURE:
      return newState;


    case LocationsActionTypes.GET:
      return newState;

    case LocationsActionTypes.GET_SUCCESS:
      return { ...newState, selected: action.payload };

    case LocationsActionTypes.GET_FAILURE:
      return newState;


    default: return state;
  }
};
