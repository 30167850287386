import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Validator } from '../../../../lib';
import { Button, Form, Select } from '../../';
import { Input } from '../../../inputs';
import { classesPerWeek, expectedClasses, socialNetworks } from '../../../../constants';

class AdditionalInfoForm extends React.PureComponent {
  static propTypes = {
    onCreate: PropTypes.func.isRequired,
    refferal: PropTypes.string.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.isMounted) {
      return {
        additionalInformation: {
          ...state.additionalInformation,
          refferal: props.refferal
        },
        isMounted: true
      };
    }
  }

  state = {
    additionalInformation: {
      classesPerWeek:  '',
      expectedClasses: '',
      heardFrom:       '',
      phone:           '',
      refferal:        ''
    },
    errors:    {},
    isMounted: false
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
        additionalInformation: {
          ...prevState.additionalInformation,
          [inputName]: value
        },
        errors: {
          ...prevState.errors,
          [inputName]: ''
        }
    }));
  };

  handleChangePhone = (value) => {
    this.setState((prevState) => ({
      additionalInformation: {
        ...prevState.additionalInformation,
        phone: value
      },
      errors: {
        ...prevState.errors,
        phone: ''
      }
    }));
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { additionalInformation } = this.state;

    const errors = Validator.validate([
      ['classesPerWeek',   Validator.concepts.isFilled, [additionalInformation.classesPerWeek]],
      ['expectedClasses',  Validator.concepts.isFilled, [additionalInformation.expectedClasses]],
      ['phone',            Validator.concepts.isFilled, [additionalInformation.phone]],
      ['heardFrom',        Validator.concepts.isFilled, [additionalInformation.heardFrom]]
    ]);

    Validator.clear();
    if (isEmpty(errors)) {
      resolve();
    } else {
      this.setState({ errors }, () => reject(errors));
    }
  })

  handleSubmit = () => {
    const { additionalInformation } = this.state;

    return this.handleValidate().then(() => (
      this.props.onCreate({ additionalInformation })
    ));
  }

  render () {
    const { additionalInformation, errors } = this.state;

    return (
      <Form className='form-default'>
        <p className='orange-title mb-2'>We’d love to learn about your fitness business</p>
        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label
                  className='required'
                  for='input-classesPerWeek'>
                How many classes do you teach?
              </Label>

              <Select
                  id='classesPerWeek'
                  isInvalid={!!errors.classesPerWeek}
                  name='classesPerWeek'
                  onChange={this.handleChange}
                  options={classesPerWeek}
                  value={additionalInformation.classesPerWeek} />
              {!!errors.classesPerWeek &&
                <p className='error'>{errors.classesPerWeek}</p>
              }
            </FormGroup>
          </Col>

          <Col sm={6}>
            <FormGroup>
              <Label
                  className='required'
                  for='select-expectedClasses'>
                How many bookings do you expect?
              </Label>

              <Select
                  id='expectedClasses'
                  name='expectedClasses'
                  onChange={this.handleChange}
                  options={expectedClasses}
                  value={additionalInformation.expectedClasses} />
              {!!errors.expectedClasses &&
                <p className='error'>{errors.expectedClasses}</p>
              }
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col>
            <FormGroup>
              <Label
                  className='required'
                  for='input-phone'>
                Contact number
              </Label>

              <PhoneInput
                  onChange={this.handleChangePhone}
                  value={additionalInformation.phone} />
              {!!errors.phone &&
                <p className='error'>{errors.phone}</p>
              }

            </FormGroup>
          </Col>
          <small className='note ml-1'>Note: Your phone number will no be publicly visible</small>
        </Row>

        <p className='orange-title mt-4 mb-2'>How did you hear about onPodio?</p>

        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label
                  className='required'
                  for='select-heardFrom'>
                How did you hear about onPodio?
              </Label>

              <Select
                  id='heardFrom'
                  name='heardFrom'
                  onChange={this.handleChange}
                  options={socialNetworks}
                  value={this.state.heardFrom} />
              {!!errors.heardFrom &&
                <p className='error'>{errors.heardFrom}</p>
              }
            </FormGroup>

          </Col>

          <Col sm={6}>
            <FormGroup>
              <Label for='input-refferal'>Referral Code / Name:</Label>

              <Input
                  id='refferal'
                  maxLength={60}
                  name='refferal'
                  onChange={this.handleChange}
                  value={additionalInformation.refferal} />
            </FormGroup>
          </Col>
        </Row>

        <div className='actions justify-content-end'>
          <Button
              className='mt-5'
              isBlock={isMobile}
              onClick={this.handleSubmit}
              onKeyPress={this.handleSubmit}
              size='lg'
              type='button'>
            Next
          </Button>
        </div>
      </Form>
    );
  }
}

export default AdditionalInfoForm;
