import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Card, CardText, CardTitle } from 'reactstrap';
import classNames from 'classnames';

import { ClassPackModal } from '../../../modals';
import { Button } from '../../../forms';

class ClassPacks extends React.PureComponent {
  static propTypes = {
    classPacks:      PropTypes.array.isRequired,
    createClassPack: PropTypes.func.isRequired,
    createDiscount:  PropTypes.func.isRequired,
    discount:        PropTypes.object.isRequired,
    instructor:      PropTypes.object.isRequired,
    restoreDiscount: PropTypes.func.isRequired,
    theme:           PropTypes.string,
    user:            PropTypes.object.isRequired
  }

  static defaultProps = {
    theme: 'default'
  }

  state = {
    classPack:   {},
    isModalOpen: false
  }

  handleToggle = (classPack = {}) => () => (
    this.setState((prevState) => ({
      classPack,
      isModalOpen: !prevState.isModalOpen
    })),
    this.props.discount && this.props.restoreDiscount()
  )

  renderClassPacks = (classPack) => {
    if (!this.props.instructor.customization) return null;

    const { currency, customization } =  this.props.instructor;

    return (
      <Card
          body
          className={classNames('card-package', { 'elegant': this.props.theme === 'elegant'})}
          key={classPack.id}>
        <div className='card-top'>
          <div className='card-info'>
            <CardTitle>{classPack.name}</CardTitle>
          </div>
          { this.props.theme === 'elegant' &&
            <div className='card-price'>
              <span className='price'>
                {classPack.cost.toLocaleString('en-GB', { currency, style: 'currency' })}
              </span>
              {classPack.number}x classes
            </div>
          }
        </div>
        <CardText className='mb-3'>
          {classPack.description}
        </CardText>

        { this.props.theme === 'default' &&
          <div className='card-price'>
            <span className='price'>
              {classPack.cost.toLocaleString('en-GB', { currency, style: 'currency' })}
            </span>
            {classPack.number}x classes
          </div>
        }

        <Button
            customization={customization}
            onClick={this.handleToggle(classPack)}
            size='sm'>
          Sign Up
        </Button>
      </Card>
    );
  }
  render() {
    return (
      <React.Fragment>
        <h4 className='page-title pb-2'>Class Packs</h4>

        <div className='profile-packages'>
          {this.props.classPacks.map(this.renderClassPacks)}
        </div>

        { this.state.isModalOpen &&
          <ClassPackModal
              classPack={this.state.classPack}
              createClassPack={this.props.createClassPack}
              createDiscount={this.props.createDiscount}
              discount={this.props.discount}
              instructor={this.props.instructor}
              isOpen={this.state.isModalOpen}
              onToggle={this.handleToggle()}
              user={this.props.user} />
        }
      </React.Fragment>
    );
  }
}

export default withRouter(ClassPacks);
