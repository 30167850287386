import { RecordingsActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       [],
  form:       {},
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case RecordingsActionTypes.CREATE:
      return newState;

    case RecordingsActionTypes.CREATE_SUCCESS:
      return { ...newState, form: action.payload };

    case RecordingsActionTypes.CREATE_FAILURE:
      return { ...newState, form: initialState.form };


    case RecordingsActionTypes.FETCH:
      return { ...newState, form: initialState.form };

    case RecordingsActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case RecordingsActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    default: return state;
  }
};
