/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ReactCrop from 'react-image-crop';
import { isMobile } from 'react-device-detect';
import 'react-image-crop/dist/ReactCrop.css';
import { filter } from 'lodash';

import { Button } from '../forms';
import { Uploader } from '../../lib';

class MobileCropModal extends React.PureComponent {
  static propTypes = {
    isOpen:          PropTypes.bool.isRequired,
    onChange:        PropTypes.func.isRequired,
    onToggle:        PropTypes.func.isRequired,
    onUpdateProfile: PropTypes.func,
    photoName:       PropTypes.string.isRequired,
    profile:         PropTypes.object,
    src:             PropTypes.string.isRequired
  }

  static defaultProps = {
    onUpdateProfile: () => {},
    profile:         {}
  }

  state = {
    blob: {},
    crop: {
      aspect: 9 / 16,
      unit:   '%',
      width:  50
    },
    maxFileSizeBytes: '5MB',
    uploads:          []
  }


  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      await this.getCroppedImg(
        this.imageRef,
        crop,
        this.props.photoName
      );
    }
  }

  getCroppedImg(image, crop, fileName) {
    const newImage = this.imageRef;
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width*scaleX);
    canvas.height = Math.ceil(crop.height*scaleY);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      newImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width*scaleX,
      crop.height*scaleY,
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
        this.setState({ blob });
      }, 'image/jpeg');
    });
  }

  handleUploaderCallback = (fileUpload) => {
     this.setState({ uploads: [fileUpload] }, () => {
      if (fileUpload.state === 'finished') {
        this.props.onChange(fileUpload.signedId, 'mobilePhoto');
      }
    });
  }

  handleValidateSize = (file) => {
    const { maxFileSizeBytes } = this.state;

    if (file.size > maxFileSizeBytes) {
      this.setState({
        error:     'Image is too big!',
        isInvalid: true
      });
    } else {
      this.setState({
        isHovering: false,
        isInvalid:  false
      }, this.handleStartUploads);
    }
  }

  renderMessage = () => {
    const { isInvalid, isHovering, error } = this.state;

    switch (true) {
      case isHovering:
        return 'Drop file right here!';

      case isInvalid:
        return error;

      default:
        return 'Drop A Photo';
    }
  }

  handleStartUploads = () => {
    const uploads = filter(this.state.uploads, 'start');

    uploads.map((upload) => {
      this.handleUpload(upload);
    });
  }

  handleUpload = async (file) => {
    await file.start();
    await this.props.onUpdateProfile && this.props.onUpdateProfile(this.props.profile);
    await this.props.onToggle();
  }

  handleSave = () => {
    const upload = new Uploader(this.state.blob, { onChangeFile: this.handleUploaderCallback });

    this.setState((prevState) => ({
      uploads: [...prevState.uploads, upload]
    }), () => {
      this.handleValidateSize(upload);
    });
  }


  render() {
    const { uploads } = this.state;
    const isLoading = 'waiting' || 'uploading';

    return (
      <Modal
          centered
          isOpen={this.props.isOpen}
          size='lg'
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          Crop a photo
        </ModalHeader>

        <ModalBody>
          <ReactCrop
              crop={this.state.crop}
              onChange={this.onCropChange}
              onComplete={this.onCropComplete}
              onImageLoaded={this.onImageLoaded}
              ruleOfThirds
              src={this.props.src} />
          <Button
              className='save-changes-btn mt-4'
              color='success'
              isBlock={isMobile}
              isLoading={uploads[0] ? uploads[0].state == isLoading : false}
              onClick={this.handleSave}>
            Save
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}

export default MobileCropModal;
