import React from 'react';
import PropTypes from 'prop-types';
import SpotifyPlayer from 'react-spotify-player';
import { Col, Row } from 'reactstrap';
import { toastr } from 'react-redux-toastr';

import { Button } from '../forms';

class PlaylistsPage extends React.PureComponent {
  static propTypes = {
    spotify:       PropTypes.object.isRequired,
    updateSpotify: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      playlists: props.spotify.playlists
    };
  }

  state = {
    playlists: [],

    spotify: {
      playlistId: ''
    }
  }

  handleSubmit = () => {
    this.props.updateSpotify({ spotify: this.state.spotify });
  }

  renderPlaylists = () => {
    const handleSelect = (id) => () => {
      this.setState((prevState) => ({
        spotify: {
          ...prevState.spotify,
          playlistId: id
        }
      }), () => {
        toastr.success('Selected');
        this.handleSubmit();
      });
    };

    if (this.state.playlists.length) {
      return this.state.playlists.map((playlist) => (
        <Row
            className='m-1'
            key={playlist.id}
            md='3'>
          <Col md='10'>
            <SpotifyPlayer
                size='large'
                uri={playlist.uri}
                view='coverart' />
          </Col>
          <Col md='2'>
            <Button
                onClick={handleSelect(playlist.id)}
                type='button'>
              Select
            </Button>
          </Col>
        </Row>
      ));
    } else {
      return (
        <div className='m-3'>
          It appears you don&apos;t have any <strong>public</strong> playlists.
          Make sure your playlists are public in order to select and connect it to your profile.
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        <div className='text-muted m-3'>
          <h3>Select a Spotify playlist!</h3>
          <small>Viewers of your profile will be able to play your tracks and taste your music style.
          NOTE: playlists must be public on Spotify in order to select and connect it to your profile.</small>
        </div>

        <div className='playlists'>
          {this.renderPlaylists()}
        </div>
      </div>
    );
  }
}

export default PlaylistsPage;
