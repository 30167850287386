import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';
import { Button } from '../../forms';

class RecurringUpdateAlert extends React.PureComponent {
  static propTypes = {
    onClose:     PropTypes.func.isRequired,
    onUpdate:    PropTypes.func.isRequired,
    onUpdateAll: PropTypes.func.isRequired
  }

  handleUpdate = () => {
    this.props.onUpdate();
    this.props.onClose();
  }

  handleUpdateAll = () => {
    this.props.onUpdateAll();
    this.props.onClose();
  }

  render () {
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            This is part of a recurring event series, would you like to <span className='text-primary'>update</span>
            <span>&nbsp;</span>just <span className='text-primary'>this instance</span> of the event or
            <span className='text-primary'> this instance and all future</span> events in the series?
          </CardText>
          <div className='actions'>
            <Button
                color='link'
                onClick={this.handleUpdate}>
              This Event
            </Button>

            <Button onClick={this.handleUpdateAll}>
              This & Future Events
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default RecurringUpdateAlert;
