import { connect } from 'react-redux';

import { ApplicationActions, SessionActions } from '../../../actions';
import Sidebar from './sidebar';

const mapStateToProps = (state) => ({
  domain:          state.domain.data,
  hasCustomDomain: state.app.hasCustomDomain,
  isOpen:          state.app.isSidebarOpen,
  profile:         state.profile.data,
  session:         state.session,
  user:            state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  logOut:   () => dispatch(SessionActions.LOGOUT()),
  onToggle: () => dispatch(ApplicationActions.SIDEBAR_TOGGLE())
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);