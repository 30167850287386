import { connect } from 'react-redux';
import { TransactionsActions } from '../../../../actions';

import AllTimeSummary from './allTimeSummary';

const mapStateToProps = (state) => ({
  isLoading: state.transactions.isLoading,
  summary:   state.transactions.summary.allTime,
  user:      state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchSummary: () => dispatch(TransactionsActions.SUMMARY_FETCH())
});

export default connect(mapStateToProps, mapDispatchToProps)(AllTimeSummary);