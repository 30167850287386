import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import { Alert } from '../../errors';

import { Heading, Panel } from '../../layout/pages';
import { Button, Select } from '../../forms';
import { ColorPicker } from '../../inputs';
import { selectStyle } from '../../../lib/customizationValues';
import { getDiff } from '../../../lib';
import { templateDefaultValues } from '../../../constants';
import { selectFieldStyle } from '../../../constants';
import { PalomaPreview, StantonPreview } from '../../../images/settings/appearance';
import { MenuTitle } from '../../shared';

class SettingsCustomizationPage extends PureComponent {
  static propTypes = {
    customization:   PropTypes.object.isRequired,
    fontOptions:     PropTypes.array.isRequired,
    handleSubmit:    PropTypes.func.isRequired,
    subscription:    PropTypes.object.isRequired,
    templateOptions: PropTypes.array.isRequired
  };

  state = { ...this.props.customization };

  handleColorChange = (color, inputName) => {
    this.setState({ [inputName]: color });
  };

  handleSelectChange = (tgt) => (option) => {
    this.setState({ [tgt]: option });

    if (tgt === 'siteTemplate') {
      this.setState({ ...templateDefaultValues[option]});
    }
  };

  handleTemplate = (value) => () => {
    this.setState({
      siteTemplate: value
    }, () => {
      this.setState({ ...templateDefaultValues[value]});
    });
  }

  handleSubmit = () => {
    const customization = getDiff(this.state, this.props.customization);

    this.props.handleSubmit({ customization });
  }

  render() {
    const {
      primaryAccentsFontColor,
      primaryAccentsBackgroundColor,
      secondaryAccentsFontColor,
      secondaryAccentsBackgroundColor,
      buttonsBackgroundColor,
      buttonsFontColor,
      bodyFontColor,

      pageBackgroundColor,
      bodyFontFamily,
      headingsFontFamily,
      siteTemplate
    } = this.state;

    const { subscription, templateOptions } = this.props;

    const isDisabled = !getDiff(this.state, this.props.customization);

    const buttonStyle = {
      backgroundColor: buttonsBackgroundColor,
      borderColor:     buttonsBackgroundColor,
      boxShadow:       'none',
      color:           buttonsFontColor,
      margin:          '0 auto'
    };

    const fontSelectStyle = {
      ...selectFieldStyle.default,
      ...selectStyle
    };

    const templatePreview = {
      default: StantonPreview,
      elegant: PalomaPreview
    };

    const templateSelectClass = (template) => {
      return classNames('template-wrapper', {
        'disabled': !subscription.websiteTemplates && !(template.value === templateOptions[0].value)
      });
    };

    return (
      <div className='inner-wrapper instructor-settings'>
        <Container fluid>
          <Heading
              color='secondary'
              subtitle='Creator Portal'
              title='Customization' />

          <Panel className='customization-settings'>
            { !(subscription.websiteTemplates || subscription.customColors) &&
              <Row className='justify-content-center pt-3'>
                <Col
                    lg={3}
                    xs={12}>
                  <Alert
                      color='info'
                      textClassName='text-center'>
                    This is only available on the Pro Plan.<br />
                    Upgrade your plan
                  </Alert>
                </Col>
              </Row>
            }
            
            <MenuTitle>
              Pick Template
            </MenuTitle>

            <div className='templates-select'>
              { templateOptions.map((template) => (
                <div
                    className={templateSelectClass(template)}
                    key={template.value}>
                  <h6>{template.label}</h6>
                  <div
                      className={classNames('template', { 'selected': siteTemplate === template.value })}
                      onClick={this.handleTemplate(template.value)}>
                    <div
                        className='template-preview'
                        style={{ backgroundImage: `url(${templatePreview[template.value]})`}} />
                  </div>
                </div>
              )) }
            </div>

            <MenuTitle>
              Customize Fonts
            </MenuTitle>

            <Row>
              <Col
                  md={6}
                  xs={12}>
                <FormGroup className='form-default'>
                  <Label>Headings</Label>

                  <Select
                      isDisabled={!subscription.customColors}
                      onChange={this.handleSelectChange('headingsFontFamily')}
                      options={this.props.fontOptions}
                      styles={fontSelectStyle}
                      value={headingsFontFamily} />
                </FormGroup>
              </Col>
              
              <Col
                  md={6}
                  xs={12}>
                <FormGroup className='form-default'>
                  <Label>Body</Label>

                  <Select
                      isDisabled={!subscription.customColors}
                      onChange={this.handleSelectChange('bodyFontFamily')}
                      options={this.props.fontOptions}
                      styles={fontSelectStyle}
                      value={bodyFontFamily} />
                </FormGroup>
              </Col>
            </Row>

            <MenuTitle>
              Customize Palette
            </MenuTitle>

            <Row className='justify-content-between'>
              <Col
                  lg={2}
                  md={6}
                  xs={12}>
                <p>Primary Accents</p>
                <div className='color-palette'>
                  <div className='palette-inputs'>
                    <FormGroup
                        className='form-default'
                        row>
                      <Label
                          size='sm'
                          sm={5}>
                        Background
                      </Label>
                      <Col sm={{ offset: 1, size: 6 }}>
                        <ColorPicker
                            isDisabled={!subscription.customColors}
                            name='primaryAccentsBackgroundColor'
                            onChange={this.handleColorChange}
                            value={primaryAccentsBackgroundColor} />
                      </Col>
                    </FormGroup>

                    <FormGroup
                        className='form-default'
                        row>
                      <Label
                          size='sm'
                          sm={5}>
                        Font
                      </Label>
                      <Col sm={{ offset: 1, size: 6 }}>
                        <ColorPicker
                            isDisabled={!subscription.customColors}
                            name='primaryAccentsFontColor'
                            onChange={this.handleColorChange}
                            value={primaryAccentsFontColor} />
                      </Col>
                    </FormGroup>
                  </div>

                  <hr />

                  <div
                      className='palette-preview'
                      style={{
                        backgroundColor: primaryAccentsBackgroundColor,
                        color:           primaryAccentsFontColor,
                        fontFamily:      headingsFontFamily
                      }}>
                    Heading 1
                  </div>
                </div>
              </Col>

              <Col
                  lg={2}
                  md={6}
                  xs={12}>
                <p>Secondary Accents</p>
                <div className='color-palette'>
                  <div className='palette-inputs'>
                    <FormGroup
                        className='form-default'
                        row>
                      <Label
                          size='sm'
                          xs={5}>
                        Background
                      </Label>
                      <Col sm={{ offset: 1, size: 6 }}>
                        <ColorPicker
                            isDisabled={!subscription.customColors}
                            name="secondaryAccentsBackgroundColor"
                            onChange={this.handleColorChange}
                            value={secondaryAccentsBackgroundColor} />
                      </Col>
                    </FormGroup>

                    <FormGroup
                        className='form-default'
                        row>
                      <Label
                          size='sm'
                          xs={5}>
                        Font
                      </Label>
                      <Col sm={{ offset: 1, size: 6 }}>
                        <ColorPicker
                            isDisabled={!subscription.customColors}
                            name='secondaryAccentsFontColor'
                            onChange={this.handleColorChange}
                            value={secondaryAccentsFontColor} />
                      </Col>
                    </FormGroup>
                  </div>

                  <hr />

                  <div
                      className='palette-preview'
                      style={{
                        backgroundColor: secondaryAccentsBackgroundColor,
                        color:           secondaryAccentsFontColor,
                        fontFamily:      headingsFontFamily
                      }}>
                    Heading 2
                  </div>
                </div>
              </Col>

              <Col
                  lg={2}
                  md={6}
                  xs={12}>
                <p>Primary Buttons</p>
                <div className='color-palette'>
                  <div className='palette-inputs'>
                    <FormGroup
                        className='form-default'
                        row>
                      <Label
                          size='sm'
                          xs={5}>
                        Background
                      </Label>
                      <Col sm={{ offset: 1, size: 6 }}>
                        <ColorPicker
                            isDisabled={!subscription.customColors}
                            name='buttonsBackgroundColor'
                            onChange={this.handleColorChange}
                            value={buttonsBackgroundColor} />
                      </Col>
                    </FormGroup>
                    
                    <FormGroup
                        className='form-default'
                        row>
                      <Label
                          size='sm'
                          xs={5}>
                        Font
                      </Label>
                      <Col sm={{ offset: 1, size: 6 }}>
                        <ColorPicker
                            isDisabled={!subscription.customColors}
                            name='buttonsFontColor'
                            onChange={this.handleColorChange}
                            value={buttonsFontColor} />
                      </Col>
                    </FormGroup>
                  </div>

                  <hr />

                  <Button style={buttonStyle}>
                    Button
                  </Button>
                </div>
              </Col>

              <Col
                  lg={2}
                  md={6}
                  xs={12}>
                <p>Background and Body Font</p>
                <div className='color-palette'>
                  <div className='palette-inputs'>
                    <FormGroup
                        className='form-default'
                        row>
                      <Label
                          size='sm'
                          xs={5}>
                        Background
                      </Label>
                      <Col sm={{ offset: 1, size: 6 }}>
                        <ColorPicker
                            isDisabled={!subscription.customColors}
                            name='pageBackgroundColor'
                            onChange={this.handleColorChange}
                            value={pageBackgroundColor} />
                      </Col>
                    </FormGroup>
                    
                    <FormGroup
                        className='form-default'
                        row>
                      <Label
                          size='sm'
                          xs={5}>
                        Font
                      </Label>
                      <Col sm={{ offset: 1, size: 6 }}>
                        <ColorPicker
                            isDisabled={!subscription.customColors}
                            name='bodyFontColor'
                            onChange={this.handleColorChange}
                            value={bodyFontColor} />
                      </Col>
                    </FormGroup>
                  </div>

                  <hr />

                  <div
                      className='palette-preview'
                      style={{
                        backgroundColor: pageBackgroundColor,
                        color:           bodyFontColor,
                        fontFamily:      bodyFontFamily,
                        fontWeight:      'normal'
                      }}>
                    Page Font
                  </div>
                </div>
              </Col>
            </Row>

            <div className="mb-5 mb-sm-3 d-flex justify-content-center">
              <Button
                  isDisabled={isDisabled}
                  onClick={this.handleSubmit}>
                Submit
              </Button>
            </div>
          </Panel>
        </Container>
      </div>
    );
  }
}

export default SettingsCustomizationPage;
