import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, ModalBody } from 'reactstrap';
import { isEmpty } from 'lodash';

import { Alert, Error } from '../../errors';
import { Loader } from '../../loader';
import { UserModalAvatar } from '../layouts';
import { Autocompleter, Radio } from '../../forms';
import { Button } from '../../forms';
import { DateSelect } from '../../calendar';
import { Moment, Validator } from '../../../lib';
import { AutocompleterContainer } from '../../../containers';
import { InstructorNoAvatar } from '../../../images';
import { PRIVATE_SESSION_STEPS } from '../../../constants';

class Info extends React.PureComponent {
  static propTypes = {
    availabilities: PropTypes.object.isRequired,
    instructor:     PropTypes.object.isRequired,
    isLoading:      PropTypes.bool.isRequired,
    onChange:       PropTypes.func.isRequired,
    onDateChange:   PropTypes.func.isRequired,
    onNext:         PropTypes.func,
    onSelect:       PropTypes.func.isRequired,
    privateSession: PropTypes.object.isRequired,
    selectedDate:   PropTypes.object.isRequired,
    user:           PropTypes.object.isRequired
  }

  static defaultProps = {
    onNext: () => {}
  }

  state = {
    errors: {}
  }

  _calcStep = () => {
    if (!this.props.user.id) return PRIVATE_SESSION_STEPS.guest;

    return PRIVATE_SESSION_STEPS.details;
  }

  handleChange = (value, inputName) => {
    this.props.onChange(value, inputName);
  }

  handleNext = (time) => () => {
    this.props.onChange(time, 'startsAt', () => (
      this.handleValidate().then(() => (
        this.props.onNext(this._calcStep())
      )).catch((errors) => (
        this.setState({ errors })
      ))
    ));
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { privateSession } = this.props;

    const errors = Validator.validate([
      ['privateSessionType', Validator.concepts.isFilled, [privateSession.privateSessionTypeId]],
      ['location',           Validator.concepts.isFilled, [privateSession.locationId], privateSession.locationType != 0]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  renderControls = () => (
    <DateSelect
        hasDaySelect
        onChange={this.props.onDateChange}
        selectedDate={this.props.selectedDate}
        selectedView='day'
        today={new Moment()} />
  );

  renderTags = (tags) => {
    const isOwn = !window.location.origin.includes('localhost') &&
                  this.props.instructor.isOwner;
    return (
      <div className='slots'>
        {tags.map((tag) => (
          <Button
              color='light'
              isDisabled={isOwn}
              key={tag.format('h:mm')}
              onClick={this.handleNext(tag)}
              onKeyPress={this.handleNext(tag)}
              size='sm'>
            {tag.format('h:mmA')}
          </Button>
      ))}
      </div>
    );
  };


  render() {
    const { availabilities, instructor, privateSession } = this.props;
    const { errors } = this.state;
    const photo = instructor.photo ? instructor.photo.sm : InstructorNoAvatar;
    const { currency } = instructor;

    return (
      <div className='new-modal'>
        <ModalBody>
          { instructor &&
            <div className='modal__header'>
              <UserModalAvatar
                  alt={instructor.name}
                  firstName={instructor.name}
                  src={photo} />
              <div className='modal__instructor-name'>
                <span className='modal__big-text'>{instructor.name}</span>
              </div>
            </div>

          }
          { (!!errors.privateSessionType || !!errors.location) &&
            <div className='errors mt-2'>
              <Error>Please select details first</Error>
            </div>
          }
          <Form className='form-default'>
            <FormGroup>
              <Label for='input-privateSessionType'>
                Select session type
              </Label>

              <AutocompleterContainer>
                <Autocompleter
                    hasInitialValue
                    instructor={instructor}
                    isInvalid={!!errors.privateSessionType}
                    isPersisted
                    name='privateSessionType'
                    onChange={this.handleChange}
                    onSelect={this.props.onSelect('privateSessionTypeId', true)}
                    placeholder='Select Session Type'
                    subject='privateSessionTypes'
                    value={this.props.privateSession.privateSessionType} />
              </AutocompleterContainer>
              {privateSession.privateSessionType &&
                <div className='new-modal mt-3'>
                  <div className='text-muted modal__small-text'>
                    Type: {privateSession.name}
                  </div>

                  <div className='mt-1 mb-2 text-muted modal__small-text'>
                    Length: {privateSession.duration} mins
                  </div>
                  {privateSession.price && 
                    <div className='mt-2 modal__default-text modal__default-text_blue modal__default-text_bold'>
                      Cost:&nbsp;<b>{privateSession.price.toLocaleString('en-GB', { currency, style: 'currency' })}</b>
                    </div>
                  }
                </div>
              }
            </FormGroup>

            <FormGroup>
              <Label
                  className='required'
                  for='input-fee'>
                Location Type
              </Label>

              <div className='d-flex'>
                <div className='custom-control custom-radio w-50'>
                  <Radio
                      className='custom-control-input'
                      id='offline'
                      isChecked={privateSession.locationType == 0}
                      name='locationType'
                      onChange={this.handleChange}
                      value={0} />
                  <Label
                      className='custom-control-label'
                      htmlFor='input-offline'>
                    In Person
                  </Label>
                </div>

                <div className='custom-control custom-radio w-50'>
                  <Radio
                      className='custom-control-input'
                      id='online'
                      isChecked={privateSession.locationType == 1}
                      name='locationType'
                      onChange={this.handleChange}
                      value={1} />
                  <Label
                      className='custom-control-label'
                      htmlFor='input-online'>
                    Virtual
                  </Label>
                </div>
              </div>
            </FormGroup>

            { privateSession.locationType == 0 &&
              <FormGroup>
                <Label for='input-location'>
                  Select location
                </Label>

                <AutocompleterContainer>
                  <Autocompleter
                      hasInitialValue
                      instructor={instructor}
                      isInvalid={!!this.state.errors.location}
                      isPersisted
                      name='location'
                      onChange={this.handleChange}
                      onSelect={this.props.onSelect('locationId')}
                      placeholder='Select studio location'
                      subject='locations'
                      value={this.props.privateSession.location} />
                </AutocompleterContainer>
              </FormGroup>
            }

            { privateSession.locationType == 1 &&
              <Alert color='info'>
                <p className='m-0 text-center'>
                  Instructor will sent you link via email after accept
                </p>
              </Alert>
            }
          </Form>

          {this.props.isLoading && <Loader isLoading />}

          <div className='profile-private-sessions mt-4 modal-properties'>
            {this.renderControls()}

            <div className='time-slots'>
              <div className='availability-block'>
                <h6 className='availability-title'>Morning</h6>

                { availabilities.morning.length
                  ? this.renderTags(availabilities.morning)
                  : <div className='text-muted'>Not Available</div>}
              </div>

              <div className='availability-block'>
                <h6 className='availability-title'>Afternoon</h6>

                { availabilities.afternoon.length
                  ? this.renderTags(availabilities.afternoon)
                  : <div className='text-muted'>Not Available</div>}
              </div>
            </div>
          </div>
        </ModalBody>
      </div>
    );
  }
}

export default Info;
