import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { SettingsCustomizationPage } from '../../components/pages';
import { CustomizationActions } from '../../actions';
import { allFontsLink, fontOptions, templateOptions } from '../../lib/customizationValues';
import { Loader } from '../../components/loader';

class SettingsCustomizationContainer extends React.PureComponent {
  static propTypes = {
    customization:           PropTypes.object.isRequired,
    getCustomizationData:    PropTypes.func.isRequired,
    subscription:            PropTypes.object.isRequired,
    updateCustomizationData: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.getCustomizationData();
  }

  onSubmit = (data) => {
    this.props.updateCustomizationData(data);
  };

  render() {
    if (!this.props.customization.bodyFontColor) return (
      <Loader
          isFullscreen
          isLoading />
    );

    return (
      <React.Fragment>
        <Helmet>
          <title>Customization</title>
          <link
              href="https://fonts.gstatic.com"
              rel="preconnect" />
          <link
              href={allFontsLink}
              rel="stylesheet" />
        </Helmet>
        <SettingsCustomizationPage
            customization={this.props.customization}
            fontOptions={fontOptions}
            handleSubmit={this.onSubmit}
            subscription={this.props.subscription}
            templateOptions={templateOptions} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  customization: state.customization.data,
  subscription:  state.plans.userData
});

const mapDispatchToProps = (dispatch) => ({
  getCustomizationData:    () =>     dispatch(CustomizationActions.GET_CUSTOMIZATION()),
  updateCustomizationData: (data) => dispatch(CustomizationActions.UPDATE_CUSTOMIZATION(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsCustomizationContainer);
