import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Form } from '../../index';
import { Activities, ContactInformation, General, InstructorServices, Locations, Referral, Social } from '../index';

class InfoForm extends React.PureComponent {
  static propTypes = {
    activities:     PropTypes.object.isRequired,
    createLocation: PropTypes.func,
    errors:         PropTypes.object,
    isEditable:     PropTypes.bool,
    location:       PropTypes.object.isRequired,
    onChange:       PropTypes.func.isRequired,
    profile:        PropTypes.object.isRequired,
    profileData:    PropTypes.object.isRequired
  };

  static defaultProps = {
    createLocation: () => {},
    errors:         {},
    isEditable:     false
  };

  render() {
    const { isEditable } = this.props;
    const blockClass = classNames('text-block');
    const labelClass = classNames({ h5: !isEditable, h6: isEditable });

    return (
      <Form className='form-default'>
        <General
            errors={this.props.errors}
            isEditable={isEditable}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <span className='menu-title p-0'>
            {isEditable ? 'General info' : 'Your name and profile pic'}
          </span>
        </General>

        <Locations
            className={blockClass}
            createLocation={this.props.createLocation}
            errors={this.props.errors}
            location={this.props.location}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <span className='menu-title p-0'>
            Add the studios and gyms you teach at
          </span>
        </Locations>

        <Activities
            activities={this.props.activities}
            className={blockClass}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <span className='menu-title p-0'>
            {isEditable ? 'Classes' : 'Select the disciplines you teach'}
          </span>
        </Activities>

        <InstructorServices
            onChange={this.props.onChange}
            profile={this.props.profile}
            profileData={this.props.profileData}>
          <h5 className={labelClass}>
            Other coaching / services
          </h5>
          <div className='text-muted'>
            Adding private coaching to your profile will allow potential clients
            to easily enquire about booking these services via your profile page
          </div>
        </InstructorServices>

        <ContactInformation
            className={blockClass}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <h5 className={labelClass}>Contact information</h5>
        </ContactInformation>

        <Social
            className={blockClass}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <h5 className={labelClass}>Social media links</h5>
        </Social>

        <Referral
            className={blockClass}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <h5 className={labelClass}>Did someone refer you?</h5>
        </Referral>
      </Form>
    );
  }
}

export default InfoForm;
