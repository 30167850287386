import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withCookies } from 'react-cookie';
import { push } from 'connected-react-router';

import { avoidsAuth } from '../helpers';
import { SessionActions } from '../actions';
import { AuthenticationPage } from '../components/pages';


class AuthenticationContainer extends React.PureComponent {
  render() {
    return <AuthenticationPage {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  hasCustomDomain: state.app.hasCustomDomain,
  isLoading:       state.loader.signUp.isLoading,
  router:          state.router,
  session:         state.session,
  visits:          state.visits
});

const mapDispatchToProps = (dispatch) => ({
  goTo:          (route) => dispatch(push(route)),
  signInSuccess: ()      => dispatch(SessionActions.SIGN_IN_SUCCESS()),
  signInUser:    (data)  => dispatch(SessionActions.SIGN_IN(data)),
  signUpUser:    (data)  => dispatch(SessionActions.SIGN_UP(data))
});

export default compose(
  avoidsAuth,
  withCookies,
  connect(mapStateToProps, mapDispatchToProps)
)(AuthenticationContainer);
