import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export default (sign = '$') => createNumberMask({
  allowDecimal:              true,
  allowLeadingZeroes:        false,
  allowNegative:             false,
  decimalLimit:              2,
  decimalSymbol:             '.',
  includeThousandsSeparator: false,
  prefix:                    sign,
  suffix:                    ''
});
