/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import { filter, groupBy, sortBy } from 'lodash';

import { LogoWhite } from '../../images';
import { Moment, StoryMaker as Painter, getTimeZone } from '../../lib';
import { Canvas, Controls, FontDefs, Image, Schedule, SizeInputs } from './';


class StoryMaker extends React.PureComponent {
  static propTypes = {
    base64:       PropTypes.string.isRequired,
    events:       PropTypes.array.isRequired,
    onFinish:     PropTypes.func.isRequired,
    originalSize: PropTypes.object.isRequired,
    user:         PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    const timeZone = getTimeZone(props.user.timeZone);

    const startDate = Moment.tz(timeZone).startOf('isoWeek');
    const range = Moment.range(startDate, Moment.tz(startDate, timeZone).add(7, 'days'));

    const nextEvents = sortBy(filter(props.events, (groupClass) => (
      range.contains(Moment.utc(groupClass.startsAt, 'YYYY-MM-DDhh:mm:ss').tz(props.user.timeZone))
    )), 'startsAt');

    return {
       events: groupBy(nextEvents, (event) => (
        Moment.utc(event.startsAt, 'YYYY-MM-DDhh:mm:ss').tz(timeZone).format('dddd')
      ))
    };
  }

  state = {
    ...Painter._getInitialState(),
    events: []
  }

  componentDidMount() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      this.setState({ bounds: Painter._getBounds() }, () => {
        const newVh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${newVh}px`);
      });
    });
  }

  handleChange = (nextState) => {
    this.setState(nextState);
  }

  handleDownload = () => {
    const { onFinish, originalSize } = this.props;

    const svgData = new XMLSerializer().serializeToString(this.svgRef);

    const canvas = document.createElement('canvas');
    canvas.setAttribute('id', 'canvas');

    const converted = Painter._fitRatio(originalSize.height, originalSize.width);

    const height = converted.height;
    const width  = converted.width;

    canvas.width = width;
    canvas.height = height;

    const img = document.createElement('img');
    const base64 = window.btoa(svgData);
    img.setAttribute('src', 'data:image/svg+xml;base64,' + base64);

    img.onload = function(e) {
      e.preventDefault();

      const logo = document.createElement('img');
      logo.setAttribute('src', LogoWhite);

      logo.onload = function(e) {
        e.preventDefault();
        const context = canvas.getContext('2d');
        context.fillStyle = '#FFF';
        context.fillRect(0, 0, canvas.width, canvas.height);

        context.drawImage(img, 0, 0, width, height);
        /* draw logo over image */

        const logoHeight = height * 0.0335;
        const logoWidth  = width * 0.201;

        const paddingBottom = height * 0.01;
        const paddingRight  = width * 0.01;

        context.drawImage(
          logo,
          width - (logoWidth + paddingRight),
          height - (logoHeight + paddingBottom),
          logoWidth,
          logoHeight
        );

        const dataURI = canvas.toDataURL('image/jpeg', 0.40);

        const array = Painter._toBinary(dataURI);
        const blob = new Blob([array], { type: 'image/jpeg' });

        onFinish(URL.createObjectURL(blob));
      };
    };
  }

  handleZoom = (zoomValue, isIncreased) => {
    this.setState((prevState) => ({
      dimensions: Painter._zoom(prevState.dimensions, zoomValue, isIncreased)
    }));
  }

  render() {
    return (
      <div className='story-maker-wrapper'>
        <style>
          {'body { touch-action: none; }'}
        </style>

        { this.props.base64 && !!this.props.events.length &&
          <Canvas
              bounds={this.state.bounds}
              onRef={(el) => this.svgRef = el}>
            <FontDefs />

            <Image
                dimensions={this.state.dimensions}
                onRef={(el) => this.imageRef = el}
                src={this.props.base64} />

            <Schedule
                color={this.state.color}
                dimensions={this.state.dimensions}
                events={this.state.events}
                font={this.state.font}
                hasLocation={this.state.hasLocation}
                hasName={this.state.hasName}
                isBold={this.state.bold}
                isItalic={this.state.italic}
                schedule={this.state.schedule}
                size={this.state.size}
                user={this.props.user} />
          </Canvas>
        }

        <SizeInputs
            onChange={this.handleChange}
            onZoom={this.handleZoom} />

        <Controls
            color={this.state.color}
            font={this.state.font}
            hasLocation={this.state.hasLocation}
            hasName={this.state.hasName}
            isBold={this.state.bold}
            isItalic={this.state.italic}
            onChange={this.handleChange}
            onDownload={this.handleDownload}
            schedule={this.state.schedule}
            size={this.state.size} />
      </div>
    );
  }
}

export default StoryMaker;
