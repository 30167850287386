import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'react-icons-kit';
import { alertCircle } from 'react-icons-kit/feather/alertCircle';
import { basic_elaboration_cloud_upload } from 'react-icons-kit/linea/basic_elaboration_cloud_upload';
import { Progress } from 'reactstrap';
import filesizeParser from 'filesize-parser';
import prettyBytes from 'pretty-bytes';
import { filter, includes, isEmpty } from 'lodash';

import { Uploader, api } from '../../lib';
import { Cropper } from '../shared';

class FileUploader extends React.PureComponent {
  static propTypes = {
    aspectRatio: PropTypes.number,
    className:   PropTypes.string,
    error:       PropTypes.string,
    fileTypes:   PropTypes.array,
    hasCropper:  PropTypes.bool,
    isInvalid:   PropTypes.bool,
    isMultiple:  PropTypes.bool,
    maxFileSize: PropTypes.string,
    name:        PropTypes.string,
    onChange:    PropTypes.func,
    onDelete:    PropTypes.func,
    placeholder: PropTypes.string,
    size:        PropTypes.string,
    value:       PropTypes.oneOfType([PropTypes.array, PropTypes.object])
  }

  static defaultProps = {
    aspectRatio: 16 / 9,
    className:   '',
    error:       '',
    fileTypes:   ['image/jpeg'],
    hasCropper:  false,
    isInvalid:   false,
    isMultiple:  false,
    maxFileSize: '5MB',
    name:        '',
    onChange:    () => {},
    onDelete:    () => {},
    placeholder: 'Drop A Photo',
    size:        'md',
    value:       {}
  }

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.value) && !state.isMounted) {
      const uploads = [{ ...props.value, state: 'finished' }];

      return {
        ...state,
        isFilesReady: true,
        isMounted:    true,
        uploads:      uploads
      };
    }

    return null;
  }

  dropRef = React.createRef();
  inputRef = React.createRef();

  state = {
    error:         '',
    files:         [],
    isCropperOpen: false,
    isFilesReady:  false,
    isHovering:    false,
    isInvalid:     false,
    isMounted:     false,
    uploads:       []
  }

  handleDragStart = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isHovering: true, isInvalid: false });
  }

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!this.props.isMultiple) this.handleClearUploads();

    const files = e.dataTransfer.files;

    if (files && files.length > 0) {
      this.setState({
        isHovering: false
      }, () => (
        setTimeout(() => (
          this.handleSelectFiles(files)
        ), 100)
      ));
    }
  }

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isHovering: true, isInvalid: false });
  }

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isHovering: false, isInvalid: false });
  }

  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();

    //this.setState({ isHovering: true, isInvalid: false });
  }

  handleClick = () => {
    this.setState({ files: [], isHovering: false, isInvalid: false });

    if (!this.state.isFilesReady) {
      this.inputRef.current.click();
    }
  }

  handleInputChange = (e) => {
    this.handleClearUploads();

    if (e.target.files.length > 0) {
      this.handleSelectFiles(e.target.files);
    }
  }

  handleUploaderCallback = (fileUpload) => {
    this.setState({ uploads: [fileUpload] }, () => {
      if (fileUpload.state === 'finished') {
        this.props.onChange(fileUpload.signedId, this.props.name);
      }
    });
  }

  handleClearUploads = () => {
    this.setState({ files: [], isFilesReady: false, uploads: [] });
  }

  handleSelectFiles = (files) => {
    const { isMultiple, hasCropper } = this.props;

    Array.from(files).forEach((file) => {
      if (this.handleValidation(file)) {
        this.setState((prevState) => ({
          files: isMultiple ? [...prevState.files, file] : [file]
        }), () => {
          if (!isMultiple && hasCropper) {
            this.handleCropper();
          }
        });
      }
    });
  }

  handlePrepareUploads = () => {
    const { files } = this.state;
    const { isMultiple } = this.props;

    Array.from(files).forEach((file) => {
      const upload = new Uploader(file, { onChangeFile: this.handleUploaderCallback });

      this.setState((prevState) => ({
        ...prevState,
        files:        [],
        isFilesReady: true,
        uploads:      isMultiple ? [...prevState.uploads, upload] : [upload]
      }), this.handleStartUploads);
    });
  }

  handleValidation = (file) => {
    return this.handleValidateFileType(file) && this.handleValidateSize(file);
  }

  handleValidateSize = (file) => {
    const { maxFileSize } = this.props;
    const allowedSize = filesizeParser(maxFileSize);

    const isValid = file.size <= allowedSize;

    if (!isValid) {
      this.setState({
        error:     'Image is too big!',
        isInvalid: true
      });
    }

    return isValid;
  }

  handleValidateFileType = (dataTransferItem) => {
    const { fileTypes } = this.props;

    const isValid = includes(fileTypes, dataTransferItem.type);

    if (!isValid) {
      this.setState({
        error:     'Invalid file type!',
        isInvalid: true
      });
    }

    return isValid;
  }

  handleStartUploads = () => {
    const uploads = filter(this.state.uploads, 'start');

    uploads.map((upload) => {
      this.handleUpload(upload);
    });
  }

  handleUpload = async (file) => {
    await file.start();
  }

  handleDelete = (id) => (e) => {
    e.preventDefault();
    const params = { id: id };

    api.attachments.delete(params).then(() => {
      this.props.onDelete();
      return this.handleClearUploads();
    }).catch(() => {
      console.log('Attachment delete error!');
    });
  }

  handleCropper = () => {
    this.setState({
      isCropperOpen: !this.state.isCropperOpen
    });
  }

  handleCroppedImage = (image) => {
    this.setState((prevState) => ({
      ...prevState,
      files: [image]
    }), () => {
      this.handlePrepareUploads();
      this.handleCropper();
    });
  }

  renderIcon = () => {
    const { isInvalid } = this.state;

    switch (true) {
      case isInvalid:
        return alertCircle;

      default:
        return basic_elaboration_cloud_upload;
    }
  }

  renderMessage = () => {
    const { isInvalid, isHovering, error } = this.state;
    const { placeholder } = this.props;

    switch (true) {
      case isHovering:
        return 'Drop file right here!';

      case isInvalid:
        return error;

      default:
        return placeholder;
    }
  }

  renderLabel = () => {
    const { maxFileSize, size } = this.props;

    const icon = this.renderIcon();
    const message = this.renderMessage();

    const iconSize = {
      lg: 64,
      md: 64,
      sm: 48
    };

    return (
      <div className='message'>
        <Icon
            icon={icon}
            size={iconSize[size]} />
        <div className='label'>
          <span className='title'>
            {message}
          </span>
          <span className='max-size'>
            Max allowed size: <b>{maxFileSize}</b>
          </span>
        </div>
      </div>
    );
  }

  renderPreview = () => {
    const { name, fileTypes, isMultiple } = this.props;
    const { uploads } = this.state;

    const acceptedFormats = fileTypes.join(', ');

    return (
      <div className='d-flex flex-column file'>
        <div
            className='preview'
            style={{ backgroundImage: `url(${uploads[0].url && uploads[0].url})`}}>
          { !(uploads[0].state === 'finished') &&
            <Progress
                animated
                value={uploads[0].progress} />
          }

          <div className='info'>
            <span className='name'>
              {uploads[0].name}
            </span>
            <span className='size'>
              {prettyBytes(uploads[0].size)}
            </span>
          </div>
        </div>
        { uploads.length > 0 && (
          <label className='custom-label'>
            <input
                accept={acceptedFormats}
                id={name}
                multiple={isMultiple}
                name={name}
                onChange={this.handleInputChange}
                ref={this.inputRef}
                style={{ display: 'none' }}
                type='file' />
            Change image
          </label>
        )}
      </div>
    );
  }

  componentDidMount() {
    const ref = this.dropRef.current;

    ref.addEventListener('dragstart', this.handleDragStart);
    ref.addEventListener('dragenter', this.handleDragIn);
    ref.addEventListener('dragleave', this.handleDragOut);
    ref.addEventListener('dragover', this.handleDragOver);
    ref.addEventListener('drop', this.handleDrop);
    ref.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    const ref = this.dropRef.current;

    ref.removeEventListener('dragstart', this.handleDragStart);
    ref.removeEventListener('dragenter', this.handleDragIn);
    ref.removeEventListener('dragleave', this.handleDragOut);
    ref.removeEventListener('dragover', this.handleDragOver);
    ref.removeEventListener('drop', this.handleDrop);
    ref.removeEventListener('click', this.handleClick);
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.isInvalid !== prevProps.isInvalid) {
      this.setState({
        error:     this.props.error,
        isInvalid: this.props.isInvalid
      });
    }
    if (prevState.files !== this.state.files && !this.props.hasCropper) {
      this.handlePrepareUploads();
    }
  }

  render () {
    const { isMultiple, name, size, className, aspectRatio, fileTypes } = this.props;
    const { isFilesReady, isHovering, isInvalid, isCropperOpen, files } = this.state;

    const acceptedFormats = fileTypes.join(', ');

    const wrapperClass = classNames('custom-image-input', `custom-input-${size}`, {
        'is-invalid': isInvalid
      }, {
        'is-hovering': isHovering
      }, {
        'is-ready': isFilesReady
      }, className);

    return (
      <div
          className={wrapperClass}
          ref={this.dropRef}>
        <div className='drop-region'>
          { isFilesReady ? this.renderPreview() : this.renderLabel() }
        </div>

        <input
            accept={acceptedFormats}
            id={name}
            multiple={isMultiple}
            name={name}
            onChange={this.handleInputChange}
            ref={this.inputRef}
            style={{display: 'none'}}
            type='file' />

        <Cropper
            aspectRatio={aspectRatio}
            isOpen={isCropperOpen}
            onSave={this.handleCroppedImage}
            onToggle={this.handleCropper}
            src={files[0]} />
      </div>
    );
  }
}

export default FileUploader;
