import { FollowsActionTypes } from '../constants/actionTypes';

export default {
  FETCH_INSTRUCTORS: () => ({
    type: FollowsActionTypes.FETCH_INSTRUCTORS
  }),

  FETCH_INSTRUCTORS_FAILURE: () => ({
    type: FollowsActionTypes.FETCH_INSTRUCTORS_FAILURE
  }),

  FETCH_INSTRUCTORS_SUCCESS: (payload) => ({
    payload, type: FollowsActionTypes.FETCH_INSTRUCTORS_SUCCESS
  }),


  FETCH_STUDIOS: () => ({
    type: FollowsActionTypes.FETCH_STUDIOS
  }),

  FETCH_STUDIOS_FAILURE: () => ({
    type: FollowsActionTypes.FETCH_STUDIOS_FAILURE
  }),

  FETCH_STUDIOS_SUCCESS: (payload) => ({
    payload, type: FollowsActionTypes.FETCH_STUDIOS_SUCCESS
  }),


  FOLLOW_INSTRUCTOR: (payload) => ({
    payload, type: FollowsActionTypes.FOLLOW_INSTRUCTOR
  }),

  FOLLOW_INSTRUCTOR_FAILURE: () => ({
    type: FollowsActionTypes.FOLLOW_INSTRUCTOR_FAILURE
  }),

  FOLLOW_INSTRUCTOR_SUCCESS: (payload) => ({
    payload, type: FollowsActionTypes.FOLLOW_INSTRUCTOR_SUCCESS
  }),


  FOLLOW_STUDIO: (payload) => ({
    payload, type: FollowsActionTypes.FOLLOW_STUDIO
  }),

  FOLLOW_STUDIO_FAILURE: () => ({
    type: FollowsActionTypes.FOLLOW_STUDIO_FAILURE
  }),

  FOLLOW_STUDIO_SUCCESS: (payload) => ({
    payload, type: FollowsActionTypes.FOLLOW_STUDIO_SUCCESS
  }),


  UNFOLLOW_INSTRUCTOR: (payload) => ({
    payload, type: FollowsActionTypes.UNFOLLOW_INSTRUCTOR
  }),

  UNFOLLOW_INSTRUCTOR_FAILURE: () => ({
    type: FollowsActionTypes.UNFOLLOW_INSTRUCTOR_FAILURE
  }),

  UNFOLLOW_INSTRUCTOR_SUCCESS: (payload) => ({
    payload, type: FollowsActionTypes.UNFOLLOW_INSTRUCTOR_SUCCESS
  }),


  UNFOLLOW_STUDIO: (payload) => ({
    payload, type: FollowsActionTypes.UNFOLLOW_STUDIO
  }),

  UNFOLLOW_STUDIO_FAILURE: () => ({
    type: FollowsActionTypes.UNFOLLOW_STUDIO_FAILURE
  }),

  UNFOLLOW_STUDIO_SUCCESS: (payload) => ({
    payload, type: FollowsActionTypes.UNFOLLOW_STUDIO_SUCCESS
  })
};
