export default {
  INSTRUCTORS_FETCH:         '@@purchasedClasses/INSTRUCTORS/FETCH',
  INSTRUCTORS_FETCH_FAILURE: '@@purchasedClasses/INSTRUCTORS/FETCH_FAILURE',
  INSTRUCTORS_FETCH_SUCCESS: '@@purchasedClasses/INSTRUCTORS/FETCH_SUCCESS',

  USERS_GET:         '@@purchasedClasses/USERS/GET',
  USERS_GET_FAILURE: '@@purchasedClasses/USERS/GET_FAILURE',
  USERS_GET_SUCCESS: '@@purchasedClasses/USERS/GET_SUCCESS',

  USER_FETCH:         '@@purchasedClasses/USER/FETCH',
  USER_FETCH_FAILURE: '@@purchasedClasses/USER/FETCH_FAILURE',
  USER_FETCH_SUCCESS: '@@purchasedClasses/USER/FETCH_SUCCESS',

  USER_UPDATE:         '@@purchasedClasses/USER/UPDATE',
  USER_UPDATE_FAILURE: '@@purchasedClasses/USER/UPDATE_FAILURE',
  USER_UPDATE_SUCCESS: '@@purchasedClasses/USER/UPDATE_SUCCESS'
};
