import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { ReviewsActions } from '../../actions';
import { Reviews } from '../../components/pages/profile/location';

class ReviewsContainer extends React.PureComponent {
  static propTypes = {
    fetchReviews: PropTypes.func.isRequired,
    location:     PropTypes.object.isRequired
  }

  componentDidMount () {
    const { id } = this.props.location.data;

    this.props.fetchReviews({ id });
  }

  render () {
    return <Reviews {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  loader:   state.loader,
  location: state.location,
  reviews:  state.reviews,
  session:  state.session
});

const mapDispatchToProps = (dispatch) => ({
  createReview: (data) =>  dispatch(ReviewsActions.CREATE(data)),
  fetchReviews: (data) =>  dispatch(ReviewsActions.FETCH(data)),
  goTo:         (route) => dispatch(push(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReviewsContainer);
