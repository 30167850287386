import React from 'react';
import PropTypes from 'prop-types';
import { Form as FormBootstrap } from 'reactstrap';

class Form extends React.PureComponent {
  static propTypes = {
    action:    PropTypes.string,
    children:  PropTypes.node,
    className: PropTypes.string,
    isInline:  PropTypes.bool,
    onSubmit:  PropTypes.func
  };

  static defaultProps = {
    action:    '',
    children:  null,
    className: '',
    isInline:  false,
    onSubmit:  () => {}
  };

  render() {
    return (
      <FormBootstrap
          action={this.props.action}
          className={this.props.className}
          inline={this.props.isInline}
          onSubmit={this.props.onSubmit}>
        {this.props.children}
      </FormBootstrap>
    );
  }
}

export default Form;
