import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faBuilding,
  faCalendarAlt,
  faCalendarDay,
  faCalendarTimes,
  faCalendarWeek,
  faCaretLeft,
  faCaretRight,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCoins,
  faComments,
  faCreditCard,
  faCrosshairs,
  faDonate,
  faDownload,
  faEnvelope,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEyeSlash,
  faFileDownload,
  faHeart,
  faListAlt,
  faMapMarkerAlt,
  faPen,
  faPenFancy,
  faPhone,
  faPlus,
  faPlusCircle,
  faSearch,
  faStopwatch,
  faSyncAlt,
  faTimes,
  faTimesCircle,
  faUndoAlt,
  faUser,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import {
  faCheckCircle as faCheckCircleRegular,
  faCircle,
  faClock,
  faEye,
  faHeart as faHeartRegular
} from '@fortawesome/free-regular-svg-icons';
import {
  faFacebook,
  faFacebookF,
  faGoogle,
  faInstagram,
  faLinkedin,
  faLinkedinIn,
  faSpotify,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';

const Icons = library.add(
  faFacebookF,
  faFacebook,
  faGoogle,
  faCircle,
  faCrosshairs,
  faCheckCircle,
  faCheckCircleRegular,
  faDonate,
  faDownload,
  faEye,
  faEyeSlash,
  faSearch,
  faTwitter,
  faLinkedinIn,
  faInstagram,
  faAngleDown,
  faPhone,
  faTimes,
  faTimesCircle,
  faHeart,
  faHeartRegular,
  faListAlt,
  faPlus,
  faEnvelope,
  faPen,
  faPenFancy,
  faPlusCircle,
  faMapMarkerAlt,
  faChevronDown,
  faChevronRight,
  faSyncAlt,
  faUndoAlt,
  faBuilding,
  faUsers,
  faCalendarAlt,
  faCalendarTimes,
  faComments,
  faLinkedin,
  faAngleLeft,
  faAngleRight,
  faExternalLinkAlt,
  faChevronLeft,
  faCaretLeft,
  faCaretRight,
  faFileDownload,
  faSpotify,
  faExclamationCircle,
  faCalendarDay,
  faCalendarWeek,
  faCalendarAlt,
  faUser,
  faCoins,
  faStopwatch,
  faClock,
  faCreditCard,
  faChevronUp,
  faExclamationTriangle
);

export default Icons;
