import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { find, isEmpty } from 'lodash';

import { Input } from '../../inputs';
import { Button, Checkbox } from '../../forms';
import { EMAIL_TEMPLATES } from '../../../constants';
import { Validator } from '../../../lib';

class WelcomeEmailModal extends React.PureComponent {
  static propTypes = {
    createEmailTemplate: PropTypes.func.isRequired,
    emailTemplates:      PropTypes.array.isRequired,
    isLoading:           PropTypes.bool,
    isOpen:              PropTypes.bool,
    onToggle:            PropTypes.func,
    updateEmailTemplate: PropTypes.func.isRequired
  }

  static defaultProps = {
    isLoading: false,
    isOpen:    false,
    onToggle:  () => {}
  }

  componentDidMount() {
    const emailTemplate = find(this.props.emailTemplates, ['name', EMAIL_TEMPLATES.welcomeEmail]);

    if (emailTemplate) {
      this.setState({ emailTemplate: { ...emailTemplate, name: EMAIL_TEMPLATES.welcomeEmail }, isNew: false });
    }
  }

  state = {
    emailTemplate: {
      body:    '',
      deliver: true,
      name:    EMAIL_TEMPLATES.welcomeEmail,
      subject: ''
    },
    errors: {},
    isNew:  true
  }

  handleChange = (value, inputName) => (
    this.setState((prevState) => ({
      emailTemplate: {
        ...prevState.emailTemplate,
        [inputName]: value
      }
    }))
  )

  handleSubmit = () => {
    this.handleValidate().then(() => {
      return (
        this.state.isNew
            ? this.props.createEmailTemplate(this.state)
            : this.props.updateEmailTemplate(this.state),
        this.props.onToggle()
      );
    }).catch((errors) => (
      this.setState({ errors })
    ));
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { emailTemplate } = this.state;

    const errors = Validator.validate([
      ['body',    Validator.concepts.isFilled, [emailTemplate.body]],
      ['subject', Validator.concepts.isFilled, [emailTemplate.subject]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  render() {
    const { emailTemplate, errors } = this.state;

    return (
      <Modal
          className='modal-refer'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          Update <span className='text-primary'>Welcome Email</span>
        </ModalHeader>

        <ModalBody>
          <div className='description mb-2'>
            <small>
              A welcome email will be sent to all clients after they make their&nbsp;
              <strong><u>first class booking</u></strong> (live or on-demand) with you.
            </small>
          </div>

          <Form className='form-default'>
            <FormGroup>
              <div className='custom-control custom-checkbox checkbox-md'>
                <Checkbox
                    className='custom-control-input'
                    id='deliver'
                    isChecked={emailTemplate.deliver}
                    name='deliver'
                    onChange={this.handleChange} />
                <Label
                    className='custom-control-label'
                    htmlFor='input-deliver'>
                  Send Welcome Email
                </Label>
              </div>
            </FormGroup>

            <div className='text-primary mb-2'>Email Template</div>

            <FormGroup>
              <Label
                  className='required'
                  for='subject'>
                Subject Line
              </Label>

              <Input
                  id='subject'
                  isDisabled={!emailTemplate.deliver}
                  isInvalid={!!errors.subject}
                  name='subject'
                  onChange={this.handleChange}
                  placeholder='Subject...'
                  value={emailTemplate.subject} />
            </FormGroup>

            <FormGroup>
              <Label
                  className='required'
                  for='body'>
                Email Body
              </Label>

              <Input
                  id='body'
                  isDisabled={!emailTemplate.deliver}
                  isInvalid={!!errors.body}
                  name='body'
                  onChange={this.handleChange}
                  placeholder='Hey {{name}},&#10;I’ve been missing you...'
                  rows='4'
                  type='textarea'
                  value={emailTemplate.body} />
            </FormGroup>
          </Form>

          <div className='text-small text-muted'>
            Insert {'{{name}}'} to include the client’s name
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
              className='mt-4'
              color='primary'
              isBlock
              isLoading={this.props.isLoading}
              onClick={this.handleSubmit}
              size='lg'
              type='button'>
            Update Email
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default WelcomeEmailModal;
