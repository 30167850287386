import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../components/forms';

class Coupon extends React.PureComponent {
  static propTypes = {
    coupon: PropTypes.object.isRequired,
    onEdit: PropTypes.func.isRequired
  }

  renderType = () => {
    const { coupon } = this.props;

    switch (coupon.duration) {
      case 'forever':
        return 'forever';

      case 'once':
        return 'once';

      case 'repeating':
        return `for ${coupon.numberOfMonths} months`;

      default:
        return '';
    }
  };

  render() {
    const { coupon } = this.props;
    const isFree = coupon.discountType == 'free';
    const discount = isFree ? 'Free' : `${coupon.percent}% off`;

    return (
      <div className='row'>
        <span className='column'>
          Coupon Name:
          <span className='value'>
            {coupon.name}
          </span>
        </span>

        <span className='column'>
          Coupon Code:
          <span className='coupon-code'>
            <span className='class-pack-name'>
              {coupon.code}
            </span>
          </span>
        </span>

        <span className='column'>
          Discount:
          <span className='value'>
            {discount + ' ' + this.renderType()}
          </span>
        </span>

        <div className='column button-wrapper'>
          <Button
              className='button'
              color='primary'
              isOutline
              onClick={this.props.onEdit(coupon.id)}
              size='sm'>
            Edit Coupon
          </Button>
        </div>
      </div>
    );
  }
}

export default Coupon;
