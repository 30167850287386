import { RequestedClassesActionTypes } from '../constants/actionTypes';

export default {
  FETCH: () => ({
    type: RequestedClassesActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: RequestedClassesActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: RequestedClassesActionTypes.FETCH_SUCCESS
  }),


  INSTRUCTORS_FETCH: (payload) => ({
    payload, type: RequestedClassesActionTypes.INSTRUCTORS_FETCH
  }),

  INSTRUCTORS_FETCH_FAILURE: () => ({
    type: RequestedClassesActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_FETCH_SUCCESS: (payload) => ({
    payload, type: RequestedClassesActionTypes.INSTRUCTORS_FETCH_SUCCESS
  })
};
