import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Row } from 'reactstrap';

import { Input } from '../../inputs';

class Referral extends React.PureComponent {
  static propTypes = {
    children:  PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    onChange:  PropTypes.func.isRequired,
    profile:   PropTypes.object.isRequired
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.children}

        <Row>
          <Col>
            <FormGroup>
              <Input
                  maxLength={60}
                  name='referral'
                  onChange={this.props.onChange}
                  value={this.props.profile.referral} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Referral;
