import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { api, extractError } from '../lib';
import { FeedbackActionTypes } from '../constants/actionTypes';
import { ErrorActions, FeedbackActions, LoaderActions } from '../actions';

function* watchCreate() {
  yield takeLatest(FeedbackActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.feedback.create, payload);

      yield put(FeedbackActions.CREATE_SUCCESS());
      toastr.success('Thank you for your feedback!');
    } catch (error) {
      yield put(FeedbackActions.CREATE_FAILURE());

      /* error */
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(FeedbackActionTypes.CREATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(FeedbackActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* feedbackSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure()
  ]);
}
