import React from 'react';
import PropTypes from 'prop-types';
import { Col, Label, ModalBody, ModalFooter, Row, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { chain, chunk, filter, isEmpty } from 'lodash';

import { Moment, getTimeZone, instructorRoutes, routes } from '../../../../lib';
import { Button, Checkbox, Radio } from '../../../forms';
import { BookingTermsModal } from '../../index';
import { BOOKING_STEPS, FEE_TYPES } from '../../../../constants';
import { UserModalAvatar } from '../../layouts';

class Info extends React.PureComponent {
  static propTypes = {
    booking:              PropTypes.object,
    bookingType:          PropTypes.string.isRequired,
    classPack:            PropTypes.object,
    date:                 PropTypes.object.isRequired,
    fetchClassPacks:      PropTypes.func.isRequired,
    fetchMemberships:     PropTypes.func.isRequired,
    handleSwitch:         PropTypes.func.isRequired,
    instructor:           PropTypes.object.isRequired,
    instructorPacks:      PropTypes.array.isRequired,
    isChallengeAvailable: PropTypes.bool.isRequired,
    isClassPackAvailable: PropTypes.bool.isRequired,
    isLoading:            PropTypes.bool.isRequired,
    isPeriodEnds:         PropTypes.bool.isRequired,
    isSubscribed:         PropTypes.bool.isRequired,
    isVaccinated:         PropTypes.bool.isRequired,
    loader:               PropTypes.object.isRequired,
    memberships:          PropTypes.array.isRequired,
    onBook:               PropTypes.func,
    onLoading:            PropTypes.func.isRequired,
    onNext:               PropTypes.func,
    privateGroupClass:    PropTypes.object.isRequired,
    subscription:         PropTypes.object,
    user:                 PropTypes.object
  };

  static defaultProps = {
    booking:      {},
    classPack:    {},
    onBook:       () => {},
    onNext:       () => {},
    subscription: {},
    user:         {}
  }

  static getDerivedStateFromProps(props) {
    const filteredBookings = filter(props.privateGroupClass.bookings, ['deletedAt', '']);
    const bookings = chain([...filteredBookings, props.booking]).filter('id').uniqBy('id').value();
    const inPersonBookings = filter(bookings, (booking) => (booking.bookingType == 'offline' || '' ));
    const isHybridClass = props.privateGroupClass.eventType == 'hybrid';

    return {
      bookings:        isHybridClass ? inPersonBookings : bookings,
      isBooked:        props.privateGroupClass.isBooked || !!props.booking.id,
      virtualBookings: filter(bookings, ['bookingType', 'online'])
    };
  }

  componentDidMount() {
    const isPackagesOnly = FEE_TYPES[this.props.privateGroupClass.feeType] == FEE_TYPES.package;
    const { isClassPackAvailable, isSubscribed, instructor } = this.props;
    const { username } = instructor;

    const hasSpots = !!(this.props.privateGroupClass.spots - this.state.bookings.length);
    !hasSpots && this.props.handleSwitch('bookingType', 'online')();

    if (isPackagesOnly && !isClassPackAvailable && !isSubscribed) {
      this.props.fetchClassPacks({ username });
      this.props.fetchMemberships({ username });
    }
  }

  state = {
    isBookingTermsOpen: false,
    isTermsChecked:     false
  }

  _calcStep = () => {
    const { isChallengeAvailable, isClassPackAvailable, isSubscribed, privateGroupClass } = this.props;

    if (!this.props.user.id) return BOOKING_STEPS.guest;

    switch (FEE_TYPES[privateGroupClass.feeType]) {
      case FEE_TYPES.free:
        return BOOKING_STEPS.success;

      case FEE_TYPES.package:
        return BOOKING_STEPS.success;

      case FEE_TYPES.paid:
        if (isClassPackAvailable || isSubscribed || isChallengeAvailable) {
          return BOOKING_STEPS.success;
        } else {
          return BOOKING_STEPS.charge;
        }

      case FEE_TYPES.donation:
        return BOOKING_STEPS.donation;

      default:
        return BOOKING_STEPS.info;
    }
  }

  handleChange = (value, inputName) => (
    this.setState({ [inputName]: value })
  )

  handleModal = (modal) => (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ [modal]: !prevState[modal] }));
  }

  handleNext = () => {
    const nextStep = this._calcStep();


    this.props.onLoading(true, () => {
      if (nextStep == BOOKING_STEPS.success) {
        this.props.onBook();
      } else {
        this.props.onNext(nextStep);
      }
    });
  }

  renderPriceTag = () => {
    const { instructor, isChallengeAvailable, isClassPackAvailable, isSubscribed, privateGroupClass } = this.props;
    const { currency } = instructor;

    switch (FEE_TYPES[privateGroupClass.feeType]) {
      case FEE_TYPES.free:
        return 'Free!';

      case FEE_TYPES.paid:
        return (
          <div
              className={classNames({ 'text-strikethrough': isClassPackAvailable ||
                                                            isSubscribed ||
                                                            isChallengeAvailable })}>
            Cost:&nbsp;<b>{privateGroupClass.fee.toLocaleString('en-GB', { currency, style: 'currency' })}</b>
          </div>
        );

      case FEE_TYPES.donation:
        return (
          <span className='modal__default-text modal__default-text_blue modal__default-text_bold'>
            Donation Based
          </span>
        );

      default: return '';
    }
  }

  renderPackageOnly = () => {
    const { loader, instructor, isChallengeAvailable, isClassPackAvailable, isSubscribed } = this.props;

    const instructorPacks = filter(this.props.instructorPacks, ['premium.live', 'true']);
    const memberships     = filter(this.props.memberships, ['premium.live', 'true']);

    const isLoading = loader.memberships.isLoading || loader.classPacks.isLoading;

    return (
      <div>
        <span className='modal__default-text modal__default-text_blue modal__default-text_bold'>
          Package Access Only
        </span>

        { !isChallengeAvailable && !isClassPackAvailable && !isSubscribed &&
          <div>
            <small>
              You must first purchase one of these packages to access this class:
            </small>

            { isLoading && this.renderSpinner()}

            { !isLoading &&
              <React.Fragment>
                {chunk(instructorPacks, 2).map((packsChunk, index) => (
                  <Row key={index}>
                    {packsChunk.map((classPack) => (
                      <Col
                          key={classPack.id}
                          xs={6}>
                        <Link to={routes.INSTRUCTOR(instructor.username + instructorRoutes.PACKAGES.path)}>
                          <small className='pl-3 text-primary'>- {classPack.name}</small>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                ))}

                {chunk(memberships, 2).map((membershipChunk, index) => (
                  <Row key={index}>
                    {membershipChunk.map((membership) => (
                      <Col
                          key={membership.id}
                          xs={6}>
                        <Link to={routes.INSTRUCTOR(instructor.username + instructorRoutes.PACKAGES.path)}>
                          <small className='pl-3 text-primary'>- {membership.name}</small>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                ))}
              </React.Fragment>
            }

          </div>
        }
      </div>
    );
  }

  renderSpinner = () => (
    <div className='d-flex justify-content-center mt-2'>
      <Spinner color='muted' />
    </div>
  )

  render() {
    const { bookings, virtualBookings, isBooked } = this.state;
    const { bookingType, classPack, date, isLoading, privateGroupClass, user, subscription,
            isChallengeAvailable, isClassPackAvailable, isSubscribed, isVaccinated } = this.props;
    const { instructor, location, playlist, description } = privateGroupClass;

    const hasSpots = !!(privateGroupClass.spots - bookings.length);

    const inPersonSpots = privateGroupClass.spots - bookings.length;
    const virtaulSpots = privateGroupClass.virtualSpots - virtualBookings.length;
    const hasHybridSpots = !!(inPersonSpots + virtaulSpots);

    const renewalDate = !isEmpty(subscription) &&
                        new Moment.utc(subscription.endsAt, 'YYYY-MM-DDhh:mm:ssUTC')
                                  .subtract(3, 'days')
                                  .format('MMMM DD');

    const isPackagesOnly = FEE_TYPES[privateGroupClass.feeType] == FEE_TYPES.package;
    const isOwn = !window.location.origin.includes('localhost') &&
                  this.props.instructor.isOwner;
    const isDisabled = !this.state.isTermsChecked ||
                        (isPackagesOnly && !isChallengeAvailable && !isClassPackAvailable && !isSubscribed ||
                        (this.props.bookingType != 'offline' && this.props.bookingType != 'online')) || isOwn;
    const isHybridClass = privateGroupClass.eventType === 'hybrid';

    return (
      <div className='new-modal'>
        <ModalBody>
          { instructor &&
            <div className='modal__header'>
              <UserModalAvatar
                  alt={instructor.name}
                  firstName={instructor.name}
                  src={instructor.photo} />
              <div className='modal__instructor-name'>
                <span className='modal__big-text'>{instructor.name}</span>
              </div>
            </div>
          }

          <div className='modal__event-name'>
            <span className='modal__big-text modal__big-text_black'>{privateGroupClass.name}</span>
          </div>

          <div className='modal__time'>
            <div className='modal__default-text modal__default-text_bold'>
              {date.format('dddd, MMMM DD')}
            </div>

            <div className='modal__default-text'>
              &nbsp;|&nbsp;{date.format('hh:mmA')} - {privateGroupClass.duration} Minutes
            </div>
          </div>


          <div className='modal__event-timezone modal__small-text'>
            {new Moment.tz(getTimeZone(user.timeZone)).format('z')} (GMT
            {new Moment.tz(getTimeZone(user.timeZone)).format('Z')} {getTimeZone(user.timeZone)})
          </div>

          { description &&
            <div className='event-description mt-2'>
              <span className='modal__small-text'>About this class</span>
              <p className='modal__small-text modal__small-text_grey'>{description}</p>
            </div>
          }

          { playlist &&
            <div className='modal__event-playlist'>
              <a
                  className='playlist-link'
                  href={`https://open.spotify.com/playlist/${playlist.playlistId}`}
                  rel='noopener noreferrer'
                  target='_blank'>
                <Icon
                    className='mr-2'
                    fixedWidth
                    icon={['fab', 'spotify']}
                    size='lg' />
                <span>{playlist.name}</span>
              </a>
            </div>
          }

          <div className='modal__event-cost modal__default-text modal__default-text_blue'>
            {isPackagesOnly && this.renderPackageOnly()}

            {this.renderPriceTag()}

            { isSubscribed && !isChallengeAvailable &&
              <span className='modal__default-text modal__default-text_blue modal__default-text_bold'>
                Included in Membership
              </span>
            }

            { privateGroupClass.feeType == 'paid' && this.props.isPeriodEnds && !isChallengeAvailable &&
              <span className='modal__default-text modal__default-text_blue mt-2'>
                NOTE: You will be charged if you continue as this class is beyond your membership renewal date.
                Wait until your renewal on {renewalDate} to book this class.
              </span>
            }

            { isClassPackAvailable && !isSubscribed && !isChallengeAvailable &&
              <span className='modal__default-text modal__default-text_blue modal__default-text-bold'>
                Use one of your remaining {classPack.live + classPack.combined} credits
              </span>
            }

            { isChallengeAvailable &&
              <span className='modal__default-text modal__default-text_blue modal__default-text-bold'>
                Included in Challenge
              </span>
            }
          </div>

          <div className={classNames('mt-3', { 'pb-2': isHybridClass })}>
            { isHybridClass &&
              <div className='form-default custom-control custom-radio'>
                <Radio
                    className='custom-control-input'
                    id='offline'
                    isChecked={bookingType == 'offline'}
                    isDisabled={!inPersonSpots}
                    name='offline'
                    onChange={this.props.handleSwitch('bookingType', 'offline')}
                    value={false} />
                <Label
                    className='custom-control-label'
                    htmlFor='input-offline'>
                  Book an in-person spot
                </Label>
              </div>
            }

            <div className='modal__event-spots modal__small-text'>
              {privateGroupClass.spots - bookings.length} spots available
            </div>

            { location &&
              <div className='modal__event-location modal__small-text'>
                {location.name}
              </div>
            }

            { privateGroupClass.eventType !== 'online' && privateGroupClass.askAboutVaccination &&
              <div className='mt-2'>
                <div className='form-default'>
                  <Label>
                    Are you vaccinated for COVID-19?
                  </Label>
                </div>

                <div className='d-flex'>
                  <div className='form-default custom-control custom-radio mr-5'>
                    <Radio
                        className='custom-control-input'
                        id='no'
                        isChecked={!isVaccinated}
                        isDisabled={bookingType == 'online'}
                        name='isVaccinated'
                        onChange={this.props.handleSwitch('vaccinated', false)} />
                    <Label
                        className='custom-control-label'
                        htmlFor='input-no'>
                      No
                    </Label>
                  </div>

                  <div className='form-default custom-control custom-radio'>
                    <Radio
                        className='custom-control-input'
                        id='yes'
                        isChecked={isVaccinated}
                        isDisabled={bookingType == 'online'}
                        name='isVaccinated'
                        onChange={this.props.handleSwitch('vaccinated', true)} />
                    <Label
                        className='custom-control-label'
                        htmlFor='input-yes'>
                      Yes
                    </Label>
                  </div>
                </div>
              </div>
            }
          </div>

          { isHybridClass &&
            <div className='pt-3 mb-2 modal_border'>
              <div className='form-default custom-control custom-radio'>
                <Radio
                    className='custom-control-input'
                    id='online'
                    isChecked={bookingType == 'online'}
                    isDisabled={!virtaulSpots}
                    name='online'
                    onChange={this.props.handleSwitch('bookingType', 'online')}
                    value={false} />
                <Label
                    className='custom-control-label'
                    htmlFor='input-online'>
                  Book a virtual spot
                </Label>
              </div>

              <div className='modal__event-spots modal__small-text'>
                {privateGroupClass.virtualSpots - virtualBookings.length} spots available
              </div>

              <div className='modal__event-location modal__small-text'>
                Virtual
              </div>
            </div>
          }
        </ModalBody>

        <ModalFooter>

          <div className='event-terms'>
            <div className='custom-control custom-checkbox checkbox-md'>
              <Checkbox
                  className='custom-control-input'
                  id='isTermsChecked'
                  isChecked={this.state.isTermsChecked}
                  name='isTermsChecked'
                  onChange={this.handleChange} />
              <Label
                  className='custom-control-label text-left'
                  for='input-isTermsChecked'>
                I have read and agree to the terms of the&nbsp;
                <Link
                    className='text-primary'
                    onClick={this.handleModal('isBookingTermsOpen')}
                    to='#'>
                  Waiver & Release
                </Link>
              </Label>
            </div>
          </div>

          { !isBooked && hasSpots && !isHybridClass &&
            <Button
                color='blue'
                isBlock
                isDisabled={isDisabled}
                isLoading={isLoading}
                onClick={this.handleNext}
                size='lg'>
              Reserve your spot
            </Button>
          }

          { !isBooked && hasHybridSpots && isHybridClass &&
            <Button
                color='blue'
                isBlock
                isDisabled={isDisabled}
                isLoading={isLoading}
                onClick={this.handleNext}
                size='lg'>
              Reserve your spot
            </Button>
          }

          { isBooked &&
            <React.Fragment>
              <Button
                  color='dark'
                  isBlock
                  isDisabled
                  size='lg'>
                You&apos;ve already booked this class
              </Button>

              <Button
                  color='blue'
                  isBlock
                  isDisabled={isDisabled}
                  isLoading={isLoading}
                  onClick={this.handleNext}
                  size='lg'>
                Reserve another spot
              </Button>
            </React.Fragment>
          }

          { !hasSpots && !isHybridClass &&
            <Button
                color='dark'
                isBlock
                isDisabled
                size='lg'>
              Full
            </Button>
          }

          { !hasHybridSpots && isHybridClass &&
            <Button
                color='dark'
                isBlock
                isDisabled
                size='lg'>
              Full
            </Button>
          }
        </ModalFooter>

        <div className='modals'>
          { this.state.isBookingTermsOpen &&
            <BookingTermsModal
                instructor={instructor}
                isOpen={this.state.isBookingTermsOpen}
                onToggle={this.handleModal('isBookingTermsOpen')} />
          }
        </div>
      </div>
    );
  }
}

export default Info;
