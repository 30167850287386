import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';

import { Button, Form } from '../';
import { Input } from '../../inputs';
import { Scroller, Validator } from '../../../lib';

class ClientForm extends React.PureComponent {
  static propTypes = {
    data:        PropTypes.object,
    isLoading:   PropTypes.bool,
    isNewRecord: PropTypes.bool,
    onDelete:    PropTypes.func,
    onSubmit:    PropTypes.func
  };

  static defaultProps = {
    data: {
      email: '',
      name:  '',
      phone: ''
    },
    isLoading:   true,
    isNewRecord: true,
    onDelete:    () => {},
    onSubmit:    () => {}
  };

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(state.client)) {
      return state;
    }

    if (!props.isLoading) {
      return {
        client:      props.data,
        isNewRecord: props.isNewRecord
      };
    }

    return null;
  }

  state = {
    client: {},

    errors: {}
  };

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      client: {
        ...prevState.client,
        [inputName]: value
      }
    }));
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const { client } = this.state;

    const errors = Validator.validate([
      ['name',  Validator.concepts.isFilled, [client.name]],
      ['email', Validator.concepts.isFilled, [client.email]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  });

  handleSubmit = () => {
    return this.handleValidate().then(() => {
      return this.props.onSubmit(this.state.client);
    });
  };

  handleDelete = () => {
    const params = { client: { id: this.state.client.id } };
    this.props.onDelete(params);
  };

  render () {
    const { isNewRecord } = this.props;
    const { client, errors } = this.state;

    return (
      <Form className='form-default'>
        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Name
          </Label>

          <Input
              id='name'
              isInvalid={!!errors.name}
              name='name'
              onChange={this.handleChange}
              type='text'
              value={client.name} />
          <FormFeedback tooltip>{errors.name}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label
              className='required'
              for='input-email'>
            E-mail
          </Label>

          <Input
              id='email'
              isInvalid={!!errors.email}
              name='email'
              onChange={this.handleChange}
              type='email'
              value={client.email} />
          <FormFeedback tooltip>{errors.email}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for='input-phone'>Phone number</Label>
          <Input
              id='phone'
              isInvalid={!!errors.phone}
              name='phone'
              onChange={this.handleChange}
              type='text'
              value={client.phone} />
        </FormGroup>

        { isNewRecord &&
          <Button
              className='mt-5'
              isBlock
              onClick={this.handleSubmit}
              size='lg'
              type='button'>
            Create & Add
          </Button>
        }
        { !isNewRecord &&
          <Row className='mt-5'>
            <Col className='mb-2'>
              <Button
                  color='danger'
                  isBlock
                  isOutline
                  onClick={this.handleDelete}
                  size='lg'
                  type='button'>
                Delete
              </Button>
            </Col>
            <Col>
              <Button
                  isBlock
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Update
              </Button>
            </Col>
          </Row>
        }
      </Form>
    );
  }
}

export default ClientForm;
