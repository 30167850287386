import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { Button } from '../forms';
import { VisibilityIcon } from '../icons';

class SettingsOption extends React.PureComponent {
  static propTypes = {
    buttonTitle: PropTypes.string,
    className:   PropTypes.string,
    icon:        PropTypes.node,
    onToggle:    PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    route:       PropTypes.string.isRequired,
    title:       PropTypes.string.isRequired,
    // eslint-disable-next-line react/boolean-prop-naming
    toggleState: PropTypes.bool
  }

  static defaultProps = {
    buttonTitle: 'Edit',
    className:   '',
    // eslint-disable-next-line react/jsx-max-props-per-line
    icon:        <Icon fixedWidth icon='pen' />,
    onToggle:    false,
    toggleState: false
  }

  render() {
    return (
      <Media className={`menu-link ${this.props.className}`}>
        <Media left>
          {this.props.icon}
        </Media>
        <Media body>
          {this.props.title}
        </Media>
        <Media
            className='d-flex'
            right>
          { this.props.onToggle &&
            <Button
                className={`settings-option-toggle ${this.props.toggleState}`}
                color='link'
                onClick={this.props.onToggle}>
              <VisibilityIcon
                  id={this.props.route}
                  isActive={this.props.toggleState}
                  size={20}
                  title='section' />
            </Button>
          }
          
          <div style={{ minWidth: '127px'}}>
            <Button
                color='primary'
                isBlock
                size='sm'
                tag={Link}
                to={this.props.route}>
              {this.props.buttonTitle}
            </Button>
          </div>
        </Media>
      </Media>
    );
  }
}

export default SettingsOption;
