import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter, includes, map, reject } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { TimeBlocksActionTypes } from '../constants/actionTypes';
import { ErrorActions, SegmentActions, TimeBlocksActions } from '../actions';
import { api, extractError, toCamelCase } from '../lib';

export function* watchFetch() {
  yield takeLatest(TimeBlocksActionTypes.FETCH, function* ({ payload }) {
    try {
      const response = yield call(api.instructor.timeBlocks.fetch, payload);

      yield put(TimeBlocksActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(TimeBlocksActions.FETCH_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export function* watchCreate() {
  yield takeLatest(TimeBlocksActionTypes.CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.instructor.timeBlocks.create, payload);
      const data = toCamelCase(response.data);

      yield put(TimeBlocksActions.CREATE_SUCCESS(data));
      yield put(SegmentActions.CREATE_TIME_BLOCK(data));

      toastr.success('Event added');
    } catch (error) {
      yield put(TimeBlocksActions.CREATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export function* watchRecurringCreate() {
  yield takeLatest(TimeBlocksActionTypes.RECURRING_CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.recurrences.create, payload);

      yield put(TimeBlocksActions.RECURRING_CREATE_SUCCESS());
      yield put(SegmentActions.CREATE_TIME_BLOCK(toCamelCase(response.data.recurrings[0])));

      const profile = yield select((state) => state.profile.data);
      yield put(TimeBlocksActions.FETCH({ username: profile.username }));
      toastr.success('Event added');
    } catch (error) {
      yield put(TimeBlocksActions.RECURRING_CREATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export function* watchDelete() {
  yield takeLatest(TimeBlocksActionTypes.DELETE, function* ({ payload }) {
    try {
      yield call(api.instructor.timeBlocks.destroy, payload);

      const timeBlocks = yield select((state) => state.timeBlocks);
      const data = filter(timeBlocks.data, (timeBlock) => timeBlock.id !== payload.id);

      yield put(TimeBlocksActions.DELETE_SUCCESS(data));
      toastr.success('Event deleted');
    } catch (error) {
      yield put(TimeBlocksActions.DELETE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export function* watchRecurringDelete() {
  yield takeLatest(TimeBlocksActionTypes.RECURRING_DELETE, function* ({ payload }) {
    try {
      const response = yield call(api.recurrences.destroy, payload);

      const timeBlocks = yield select((state) => state.timeBlocks);
      const deletedIds = response.data;

      const data = reject(timeBlocks.data, (timeBlock) => includes(deletedIds, timeBlock.id));

      yield put(TimeBlocksActions.RECURRING_DELETE_SUCCESS(data));
      toastr.success('Event deleted');
    } catch (error) {
      yield put(TimeBlocksActions.RECURRING_DELETE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdate() {
  yield takeLatest(TimeBlocksActionTypes.UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.instructor.timeBlocks.update, payload);

      const timeBlocks = yield select((state) => state.timeBlocks.data);
      const data = map(timeBlocks, (timeBlock) =>
        (timeBlock.id === payload.id ? toCamelCase(response.data) : timeBlock));

      yield put(TimeBlocksActions.UPDATE_SUCCESS(data));
      toastr.success('Event updated');
    } catch (error) {
      yield put(TimeBlocksActions.UPDATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* timeBlocksSaga() {
  yield all([
    watchFetch(),
    watchCreate(),
    watchRecurringCreate(),
    watchDelete(),
    watchRecurringDelete(),
    watchUpdate()
  ]);
}
