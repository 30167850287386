import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';

import { Input } from '../../inputs';

class ContactInformation extends React.PureComponent {
  static propTypes = {
    children:  PropTypes.node,
    className: PropTypes.string,
    onChange:  PropTypes.func.isRequired,
    profile:   PropTypes.object.isRequired
  }

  static defaultProps = {
    children:  null,
    className: ''
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.children}

        <FormGroup row>
          <Label
              htmlFor='email'
              sm={2}>
            E-mail
          </Label>
          <Col sm={10}>
            <Input
                id='email'
                name='email'
                onChange={this.props.onChange}
                placeholder='E-mail address'
                type='email'
                value={this.props.profile.email} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label
              htmlFor='phone'
              sm={2}>
            Phone
          </Label>
          <Col sm={10}>
            <Input
                id='phone'
                name='phone'
                onChange={this.props.onChange}
                placeholder='Phone number'
                type='text'
                value={this.props.profile.phone} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label
              htmlFor='website'
              sm={2}>
            Website
          </Label>
          <Col sm={10}>
            <Input
                id='website'
                name='website'
                onChange={this.props.onChange}
                placeholder='Website'
                type='url'
                value={this.props.profile.website} />
          </Col>
        </FormGroup>
      </div>
    );
  }
}

export default ContactInformation;
