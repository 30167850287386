import React from 'react';
import PropTypes from 'prop-types';

class Required extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    style:    PropTypes.object
  }

  static defaultProps = {
    style: {}
  }

  render() {
    return (
      <span
          required
          style={this.props.style}>
        {this.props.children}
      </span>
    );
  }
}

export default Required;
