import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { ProfileFormatter, api, extractError, toCamelCase } from '../lib';
import { ProfileActionTypes } from '../constants/actionTypes';
import { SessionActions, UserActions } from '../actions';

import {
  ErrorActions,
  LoaderActions,
  ProfileActions,
  SegmentActions,
  SynchronizationActions
} from '../actions';

function* watchCreate() {
  yield takeLatest(ProfileActionTypes.CREATE, function* ({ payload }) {
    try {
      const user = yield(select((state) => state.user.data));

      const response = yield call(api.profile.create, ProfileFormatter._toApi(payload, user));
      const userData = { ...user, instructor: toCamelCase(response.data), photo: response.data.photo.sm };

      yield put(ProfileActions.CREATE_SUCCESS(toCamelCase(response.data)));
      yield put(UserActions.UPDATE_SUCCESS(userData));

      yield put(SegmentActions.CREATE_PROFILE(toCamelCase(response.data)));
      yield put(SynchronizationActions.FETCH());
    } catch (error) {
      yield put(ProfileActions.CREATE_FAILURE());
    }
  });
}

function* watchFetch() {
  yield takeLatest(ProfileActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('profile'));

      const response = yield call(api.profile.get);
      const user = yield(select((state) => state.user.data));

      yield put(ProfileActions.FETCH_SUCCESS(ProfileFormatter._toClient(toCamelCase(response.data), user)));
    } catch (_error) {
      yield put(ProfileActions.FETCH_FAILURE());
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(ProfileActionTypes.FETCH_SUCCESS, function* () {
    yield put(SessionActions.RESTORE_SESSION_SUCCESS());
    yield put(LoaderActions.FINISH_FIELD_LOADING('profile'));
  });
}

function* watchFetchFailure() {
  yield takeLatest(ProfileActionTypes.FETCH_FAILURE, function* () {
    yield put(SessionActions.RESTORE_SESSION_SUCCESS());
    yield put(LoaderActions.FINISH_FIELD_LOADING('profile'));
  });
}

function* watchUpdate() {
  yield takeLatest(ProfileActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const user = yield(select((state) => state.user.data));
      const response = yield call(api.profile.update, ProfileFormatter._toApi(payload, user));

      yield put(ProfileActions.UPDATE_SUCCESS(ProfileFormatter._toClient(toCamelCase(response.data), user)));
      toastr.success('Profile updated');
    } catch (error) {
      yield put(ProfileActions.UPDATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateAvailability() {
  yield takeLatest(ProfileActionTypes.UPDATE_AVAILABILITY, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const user = yield(select((state) => state.user.data));
      const response = yield call(api.instructor.availability.update, ProfileFormatter._toApi(payload, user));

      yield put(ProfileActions.UPDATE_SUCCESS(ProfileFormatter._toClient(toCamelCase(response.data), user)));
      toastr.success('Availability updated');
    } catch (error) {
      yield put(ProfileActions.UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(ProfileActionTypes.UPDATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateFailure() {
  yield takeLatest(ProfileActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* profileSaga() {
  yield all([
    watchCreate(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchUpdate(),
    watchUpdateAvailability(),
    watchUpdateSuccess(),
    watchUpdateFailure()
  ]);
}
