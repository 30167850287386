import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { Col, Collapse, Form, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';

import { Select } from '../../forms';
import { Input } from '../../inputs';
import { Button, Checkbox, MaskedInput } from '../../forms';
import { dayMask } from '../../../constants/masks';


class AddClassPackModal extends React.PureComponent {
  static propTypes = {
    classPacks:      PropTypes.array.isRequired,
    client:          PropTypes.object.isRequired,
    createClassPack: PropTypes.func.isRequired,
    fetchClassPacks: PropTypes.func.isRequired,
    isLoading:       PropTypes.bool.isRequired,
    isOpen:          PropTypes.bool,
    onToggle:        PropTypes.func
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  componentDidMount() {
    this.props.fetchClassPacks();
  }

  static getDerivedStateFromProps(props, state) {
    const options = map(props.classPacks, (classPack) => (
      { label: classPack.name, value: classPack }
    ));

    const classPack = { clientId: props.client.id };

    if (!state.isMounted) {
      return { classPack, options };
    }
  }

  state = {
    classPack:     {},
    isMounted:     false,
    isSectionOpen: false,
    options:       []
  }

  handleSelect = (classPack) => (
    this.setState((prevState) => ({
      classPack: {
        ...prevState.classPack,
        ...classPack
      },
      isMounted: true
    }))
  )


  handleToggle = () => (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      isSectionOpen: !prevState.isSectionOpen
    }));
  }

  handleChange = (value, inputName) => (
    this.setState((prevState) => ({
      classPack: {
        ...prevState.classPack,
        [inputName]: value
      },
      isMounted: true
    }))
  )

  handleSubmit = () => {
    const { classPack } = this.state;

    this.props.createClassPack({ classPack });
    this.props.onToggle();
  }

  render() {
    const { isOpen, onToggle, isLoading } = this.props;
    const { classPack, options, isSectionOpen } = this.state;

    return (
      <Modal
          centered
          className='membership-modal'
          isOpen={isOpen}
          toggle={onToggle}>
        <ModalHeader toggle={onToggle}>
          Add <span className='text-primary'>Class Pack</span>
        </ModalHeader>

        <ModalBody>
          <Form className='form-default'>
            <FormGroup>
              <Label
                  className='required'
                  for='input-name'>
                Select Class Pack
              </Label>

              <Select
                  isLoading={isLoading}
                  name='class-pack-id'
                  onChange={this.handleSelect}
                  options={options}
                  placeholder='Add class pack' />
            </FormGroup>

            <a
                className='form-collapse-toggle dropdown-toggle'
                href='#'
                onClick={this.handleToggle()}>
              Adjust Class Pack
            </a>

            <Collapse isOpen={isSectionOpen}>
              <FormGroup>
                <Row className='justify-content-between'>
                  <Col sm={3}>
                    <FormGroup>
                      <Label for='input-period'># of Classes</Label>
                      <Input
                          id='number'
                          name='number'
                          onChange={this.handleChange}
                          placeholder='5'
                          type='text'
                          value={classPack.number} />
                    </FormGroup>
                  </Col>

                  <Row>
                    <FormGroup>
                      <Label for='input-can-expire'>
                        Add an Expiration?
                      </Label>

                      <div className='d-flex'>
                        <div className='custom-control custom-checkbox checkbox-lg'>
                          <Checkbox
                              className='custom-control-input'
                              id='can-expire'
                              isChecked={classPack.canExpire}
                              name='canExpire'
                              onChange={this.handleChange} />
                          <Label
                              className='custom-control-label'
                              htmlFor='input-can-expire' />
                        </div>

                        <MaskedInput
                            className='ml-2 w-50'
                            id='expiry'
                            inputMode='number'
                            isDisabled={!classPack.canExpire}
                            mask={dayMask}
                            name='expiry'
                            onChange={this.handleChange}
                            placeholder=' days'
                            type='number'
                            value={String(classPack.expiry)} />
                      </div>
                    </FormGroup>
                  </Row>
                </Row>
              </FormGroup>
            </Collapse>

            <Col>
              <Button
                  isBlock
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Create Class Pack
              </Button>
            </Col>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export default AddClassPackModal;
