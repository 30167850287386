import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormGroup } from 'reactstrap';

import { DesktopFileUploader, MobileFileUploader } from '../../../inputs';
import { CropModal, MobileCropModal } from '../../../modals';

import { Form } from '../../index';
import { ContactInformation, General, Referral, Social } from '../index';
import {
  Activities,
  InstructorServices,
  Tagline
} from '../index';

class InfoForm extends React.PureComponent {
  static propTypes = {
    activities:      PropTypes.object.isRequired,
    errors:          PropTypes.object.isRequired,
    isEditable:      PropTypes.bool,
    onChange:        PropTypes.func.isRequired,
    onUpdateProfile: PropTypes.func.isRequired,
    profile:         PropTypes.object.isRequired,
    profileData:     PropTypes.object.isRequired
  };

  static defaultProps = {
    createLocation: () => {},
    isEditable:     false
  };

  state = {
    cropModalOpen:       false,
    mobileCropModalOpen: false,
    photoName:           '',
    src:                 ''
  }

  handleModal = () => {
    this.setState((prevState) => ({ cropModalOpen: !prevState.cropModalOpen }));
  }

  handleMobileModal = () => {
    this.setState((prevState) => ({ mobileCropModalOpen: !prevState.mobileCropModalOpen }));
  }

  handleSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ photoName: e.target.files[0].name });
      this.handleModal();
    }
  };

  handleMobileSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ photoName: e.target.files[0].name });
      this.handleMobileModal();
    }
  };

  render() {
    const { isEditable } = this.props;
    const blockClass = classNames('text-block');

    return (
      <Form className='form-default'>
        <General
            errors={this.props.errors}
            isEditable={isEditable}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <span className='menu-title p-0'>General info</span>
        </General>

        <div className='text-block'>
          <FormGroup>
            <span className='menu-title p-0'>Profile Picture</span>
            <DesktopFileUploader
                error={this.props.errors.photo}
                fileTypes={['image/png', 'image/jpeg']}
                isInvalid={!!this.props.errors.photo}
                maxFileSize='5MB'
                name='photo'
                onChange={this.props.onChange}
                onSelectFile={this.handleSelectFile}
                onUpdateProfile={this.props.onUpdateProfile}
                profile={this.props.profile}
                size='lg' />

            <MobileFileUploader
                error={this.props.errors.photo}
                fileTypes={['image/png', 'image/jpeg']}
                isInvalid={!!this.props.errors.photo}
                maxFileSize='5MB'
                name='mobile_photo'
                onChange={this.props.onChange}
                onSelectFile={this.handleMobileSelectFile}
                photoName={this.state.photoName}
                profile={this.props.profile}
                size='lg' />
          </FormGroup>
        </div>

        <Tagline
            errors={this.props.errors}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <span className='required menu-title p-0'>Tagline</span>
        </Tagline>

        <Activities
            activities={this.props.activities}
            className={blockClass}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <span className='required menu-title p-0'>
            Classes
          </span>
        </Activities>

        <ContactInformation
            className={blockClass}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <span className='menu-title p-0'>Contact information</span>
        </ContactInformation>

        <Social
            className={blockClass}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <span className='menu-title p-0'>Social media links</span>
        </Social>

        { !isEditable &&
          <Referral
              className={blockClass}
              onChange={this.props.onChange}
              profile={this.props.profile}>
            <span className='menu-title p-0'>Did someone refer you?</span>
          </Referral>
        }

        <InstructorServices
            onChange={this.props.onChange}
            profile={this.props.profile}
            profileData={this.props.profileData}>
          <span className='menu-title p-0'>
            Other coaching / services
          </span>
        </InstructorServices>

        {this.state.cropModalOpen && this.state.src &&
          <CropModal
              isMobileCrop
              isOpen={this.state.cropModalOpen}
              onChange={this.props.onChange}
              onToggle={this.handleModal}
              onUpdateProfile={this.props.onUpdateProfile}
              photoName={this.state.photoName}
              profile={this.props.profile}
              src={this.state.src}
          />
        }

        {this.state.mobileCropModalOpen && this.state.src &&
          <MobileCropModal
              isMobileCrop
              isOpen={this.state.mobileCropModalOpen}
              onChange={this.props.onChange}
              onToggle={this.handleMobileModal}
              onUpdateProfile={this.props.onUpdateProfile}
              photoName={this.state.photoName}
              profile={this.props.profile}
              src={this.state.src}
          />
        }
      </Form>
    );
  }
}

export default InfoForm;
