import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody } from 'reactstrap';
import AddToCalendar from '@culturehq/add-to-calendar';
import '@culturehq/add-to-calendar/dist/styles.css';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { UserModalAvatar } from '../../layouts/';

import { Moment } from '../../../../lib';
import { TimeZoneInfo } from './';

class Success extends React.PureComponent {
  static propTypes = {
    booking:     PropTypes.object,
    bookingType: PropTypes.string.isRequired,
    date:        PropTypes.object.isRequired,
    user:        PropTypes.object.isRequired
  }

  static defaultProps = {
    booking: {}
  }

  static getDerivedStateFromProps(props) {
    return {
      booking: props.booking
    };
  }

  state = {
    booking: {}
  }

  render() {
    const { privateGroupClass } = this.state.booking;
    const { instructor } = privateGroupClass;
    const { bookingType, date, user } = this.props;
    const isOnline = bookingType == 'online';

    const location = isOnline ? privateGroupClass.digitalLink : privateGroupClass.location.name;

   const description = isOnline
                        ? `${privateGroupClass.digitalLink}\n \n${privateGroupClass.description}`
                        :  privateGroupClass.description;



    return (
      <div className='new-modal'>
        { privateGroupClass &&
          <ModalBody>
            <div>
              <span className='modal__default-text modal__default-text_blue modal__default-text_bold'>
                BOOKING CONFIRMED
              </span>
            </div>

            <div className='modal__success-text'>
              <span className='modal__small-text'>
                You’re all set! You will receve an email with the details below shortly!
              </span>
            </div>

            { instructor &&
              <div className='modal__header'>
                <UserModalAvatar
                    alt={instructor.name}
                    firstName={instructor.name}
                    src={instructor.photo} />
                <div className='modal__instructor-name'>
                  <span className='modal__big-text'>{instructor.name}</span>
                </div>
              </div>
            }

            { privateGroupClass && privateGroupClass.id &&
              <div>
                <div className='modal__event-name'>
                  <span className='modal__big-text modal__big-text_black'>{privateGroupClass.name}</span>
                </div>

                <div className='modal__time'>
                  <div className='modal__default-text modal__default-text_bold'>
                    {date.format('dddd, MMMM DD')}
                  </div>

                  <div className='modal__default-text'>
                    &nbsp;|&nbsp;{date.format('hh:mmA')} - {privateGroupClass.duration} Minutes
                  </div>
                </div>

                <TimeZoneInfo timeZone={user.timeZone} />

                { !isOnline &&
                  <div className='modal__mb-5'>
                    <span className='modal__small-text modal__small-text_grey modal__mb-5'>
                      {privateGroupClass.location.name}
                    </span>
                  </div>
                }

                { isOnline &&
                  <a
                      className='d-block text-truncate modal__small-text'
                      href={privateGroupClass.digitalLink}
                      rel='noopener noreferrer'
                      target='_blank'>
                    {privateGroupClass.digitalLink}
                  </a>
                }

                { (privateGroupClass.meetingUid || privateGroupClass.passcode) &&
                  <small class-name='d-block modal__small-text modal__digital-link'>
                    { privateGroupClass.meetingUid &&
                      <span>
                        Meeting Id: {privateGroupClass.meetingUid}
                      </span>
                    }

                    {privateGroupClass.meetingUid && privateGroupClass.passcode &&
                      <span>&nbsp;|&nbsp;</span>
                    }

                    { privateGroupClass.passcode &&
                      <span>
                        Passcode: {privateGroupClass.passcode}
                      </span>
                    }
                  </small>
                }

                { privateGroupClass.description &&
                  <div className='event-description'>
                    <span className='modal__small-text'>About this class</span>
                    <p className='modal__small-text modal__small-text_grey'>{privateGroupClass.description}</p>
                  </div>
                }

                { privateGroupClass.playlist &&
                  <div className='modal__event-playlist'>
                    <a
                        className='playlist-link'
                        href={`https://open.spotify.com/playlist/${privateGroupClass.playlist.playlistId}`}
                        rel='noopener noreferrer'
                        target='_blank'>
                      <Icon
                          className='mr-2'
                          fixedWidth
                          icon={['fab', 'spotify']}
                          size='lg' />
                      <span>{privateGroupClass.playlist.name}</span>
                    </a>
                  </div>
                }

                <div className='addToCalendar'>
                  <AddToCalendar
                      event={{
                        details:  description,
                        endsAt:   new Moment(date).add(privateGroupClass.duration, 'minutes').toISOString(),
                        location: location,
                        name:     privateGroupClass.name,
                        startsAt: date.toISOString()
                      }}
                  />
                </div>
              </div>
            }
          </ModalBody>
        }
      </div>
    );
  }
}

export default Success;
