import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError } from '../lib';
import { InvitesActionTypes } from '../constants/actionTypes';
import { ErrorActions, InvitesActions, LoaderActions } from '../actions';

function* watchCreate() {
  yield takeLatest(InvitesActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.invites.create, payload);

      yield put(InvitesActions.CREATE_SUCCESS());
    } catch (error) {
      yield put(InvitesActions.CREATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(InvitesActionTypes.CREATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(InvitesActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* invitesSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure()
  ]);
}
