import { DonationTargetsActionTypes } from '../constants/actionTypes';

const initialState = {
  data:           {},
  instructorData: {},
  lastAction:     ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case DonationTargetsActionTypes.CREATE:
      return newState;

    case DonationTargetsActionTypes.CREATE_SUCCESS:
      return { ...newState, data: [action.payload] };

    case DonationTargetsActionTypes.CREATE_FAILURE:
      return { ...newState, data: initialState.data };

    case DonationTargetsActionTypes.FETCH:
      return newState;

    case DonationTargetsActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case DonationTargetsActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };

    case DonationTargetsActionTypes.INSTRUCTORS_FETCH:
      return newState;

    case DonationTargetsActionTypes.INSTRUCTORS_FETCH_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case DonationTargetsActionTypes.INSTRUCTORS_FETCH_FAILURE:
      return { ...newState, instructorData: initialState.instructorData };

    case DonationTargetsActionTypes.UPDATE:
      return newState;

    case DonationTargetsActionTypes.UPDATE_SUCCESS:
      return { ...newState, data: action.payload, form: {} };

    case DonationTargetsActionTypes.UPDATE_FAILURE:
      return newState;

    default: return state;
  }
};
