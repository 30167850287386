/* eslint-disable immutable/no-mutation */

import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { RecessBanner } from '../../images';
import { RECESS_COMMUNITY, SUPPORT_ARTICLE } from '../../constants';

class RecessMigrationModal extends React.PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.isMounted) {
      return {
        isModalOpen: props.isOpen,
        isMounted:   true
      };
    }
  }

  state = {
    isModalOpen: false,
    isMounted:   false
  }

  handleClose = () => {
    this.setState({ isModalOpen: false });
  }

  render() {
    return (
      <Modal
          centered
          className='text-sm'
          isOpen={this.state.isModalOpen}
          size='lg'
          toggle={this.handleClose}>
        <ModalHeader toggle={this.handleClose}>
          <img
              alt=''
              className='mw-100'
              src={RecessBanner} />
        </ModalHeader>

        <ModalBody>
          <p>
            <strong>On November 19th</strong>, onPodio is officially becoming Recess.tv!
            We will communicate with you every step of the way to ensure this transition is seamless.
          </p>

          <p>
            We ensure that your information from onPodio will be accessible through your new account on Recess.tv.
            Your client information will also be transitioned over.
          </p>

          <p>
            <strong>If you want to learn more about Recess and this process:</strong>
            <p>Join an
              <a
                  className='text-dark'
                  href={RECESS_COMMUNITY}
                  rel='noopener noreferrer'
                  target='_blank'>
                <strong> <u>OnPodio Onboarding Q&A</u> </strong>
              </a>
              live zoom
            </p>

            <p>Check out
              <a
                  className='text-dark'
                  href={SUPPORT_ARTICLE}
                  rel='noopener noreferrer'
                  target='_blank'>
                <strong> <u>this support article</u> </strong>
              </a>
            for answers to frequently asked questions</p>
          </p>

          <p>
            <strong>If you haven’t already:</strong>
            <p>
              Email <strong>partners@recess.tv</strong> letting us know if you are a studio or individual trainer
            </p>

            <span>
              If you have a website outside of onPodio, please email <strong>partners@recess.tv </strong>
              with the domain name
            </span>
          </p>

          <p>Please check your email <strong>weekly</strong> for updates from <strong>partners@recess.tv</strong></p>

        </ModalBody>
      </Modal>
    );
  }
}

export default RecessMigrationModal;
