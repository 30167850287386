/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ReactPlayer from 'react-player';

const VideoPlayer = ({ isOpen, onToggle, name, videoUrl, poster }) => (
  <Modal
      isOpen={isOpen}
      size='lg'
      toggle={onToggle}>
    <ModalHeader toggle={onToggle}>
      {name}
    </ModalHeader>

    <ModalBody>
      <ReactPlayer
          config={{
            file: {
              attributes: {
                controlsList:  'nodownload',
                crossOrigin:   'anonymous',
                onContextMenu: (e) => e.preventDefault()
              }
            }
          }}
          controls
          light={poster}
          url={videoUrl}
          width='100%' />
    </ModalBody>
  </Modal>
);

VideoPlayer.propTypes = {
  isOpen:   PropTypes.bool.isRequired,
  name:     PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
  poster:   PropTypes.string.isRequired,
  videoUrl: PropTypes.string.isRequired
};

export default VideoPlayer;
