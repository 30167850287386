import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresAuth } from '../helpers';
import { sidebarItems } from '../constants';
import { SchedulePage } from '../components/pages';
import {
  BookingsActions,
  DiscountActions,
  GroupClassesActions,
  PrivateBookingsActions,
  PrivateSessionsActions
} from '../actions';

class LocationScheduleContainer extends React.PureComponent {
  static propTypes = {
    fetchBookings:     PropTypes.func.isRequired,
    fetchGroupClasses: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.fetchBookings();
    this.props.fetchGroupClasses();
  }

  render() {
    return (
      <SchedulePage
          {...this.props}
          subject={sidebarItems.LOCATION_CLASS_PLANNER} />
    );
  }
}

const mapStateToProps = (state) => ({
  bookings:        state.bookings.data,
  discount:        state.discount.data,
  groupClasses:    state.groupClasses.locationData,
  privateBookings: state.privateBookings.data,
  privateSessions: state.privateSessions.data,
  user:            state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createBooking:        (data) => dispatch(BookingsActions.CREATE(data)),
  createDiscount:       (data) => dispatch(DiscountActions.CREATE(data)),
  deleteBooking:        (data) => dispatch(BookingsActions.DELETE(data)),
  deletePrivateBooking: (data) => dispatch(PrivateBookingsActions.DELETE(data)),
  deletePrivateSession: (data) => dispatch(PrivateSessionsActions.DELETE(data)),
  fetchBookings:        () =>     dispatch(BookingsActions.FETCH()),
  fetchGroupClasses:    () =>     dispatch(GroupClassesActions.LOCATIONS_FETCH())
});

export default compose(
  requiresAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(LocationScheduleContainer);
