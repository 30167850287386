import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'reactstrap';
import { isEmpty } from 'lodash';

import { InstructorMenu, SignMenu, UserMenu } from '../../../../components/layout/header/menu';

class NavigationUrban extends React.PureComponent {
  static propTypes = {
    getDomain:  PropTypes.func.isRequired,
    instructor: PropTypes.object.isRequired,
    logOut:     PropTypes.func.isRequired,
    profile:    PropTypes.object.isRequired,
    session:    PropTypes.object.isRequired,
    user:       PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      hasProfile:      !isEmpty(props.profile),
      isAuthenticated: props.session.authenticated,
      user:            props.user
    };
  }

  state = {
    isUserMenuOpen: false
  };

  componentDidMount() {
    const { profile } = this.props;

    this.props.getDomain({ username: profile.username });
  }

  handleUserMenu = () => {
    this.setState((prevState) => ({
      isUserMenuOpen: !prevState.isUserMenuOpen
    }));
  };

  render() {
    const { hasProfile, isAuthenticated, user, isUserMenuOpen } = this.state;

    return (
      <Navbar className="template-urban-navbar">
        <div />
        <InstructorMenu instructor={this.props.instructor} />
        { isAuthenticated &&
          <UserMenu
              direction='right'
              hasProfile={hasProfile}
              isOpen={isUserMenuOpen}
              logOut={this.props.logOut}
              onToggle={this.handleUserMenu}
              user={user} />
        }
        {!isAuthenticated && <SignMenu />}
      </Navbar>
    );
  }
}

export default NavigationUrban;
