/* eslint-disable max-len */

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router';
import { includes, isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';

import { restoreSession } from '../../helpers';
import { PoweredByBadge } from '../../components/badges';
import { AuthenticationContainer, RecoverPasswordContainer, ResetPasswordContainer } from '../../containers';
import { getTemplate, instructorRoutes, routes } from '../../lib';
import Layout from '../../components/layout';
import { About, CustomNavigation, Home, Packages, Style, Workout } from './sections';
import CustomizationHelmet from './customizationHelmet';

class InstructorPage extends React.PureComponent {
  static propTypes = {
    fetchChallenges:        PropTypes.func.isRequired,
    fetchClassPacks:        PropTypes.func.isRequired,
    fetchFavoriteOnDemands: PropTypes.func.isRequired,
    fetchMemberships:       PropTypes.func.isRequired,
    fetchRequestedClasses:  PropTypes.func.isRequired,
    fetchSubscriptions:     PropTypes.func.isRequired,
    fetchUserChallenges:    PropTypes.func.isRequired,
    fetchUserClassPacks:    PropTypes.func.isRequired,
    getInstructor:          PropTypes.func.isRequired,
    getPrivateGroupClasses: PropTypes.func.isRequired,
    hasAuth:                PropTypes.bool,
    hasCustomDomain:        PropTypes.bool.isRequired,
    instructor:             PropTypes.object.isRequired,
    location:               PropTypes.object.isRequired,
    match:                  PropTypes.object.isRequired,
    setInstructor:          PropTypes.func.isRequired,
    user:                   PropTypes.object.isRequired,
    username:               PropTypes.string.isRequired
  };

  static defaultProps = {
    hasAuth: false
  }

  static getDerivedStateFromProps(props, state) {
    const { username, instructor } = props;

    const template = getTemplate(instructor) || state.template;
    return { template, username };
  }

  state = {
    isMounted:    false,
    isRedirected: false,
    template:     'default',
    username:     ''
  };

  componentDidMount () {
    const { username, match, hasCustomDomain } = this.props;

    this.props.setInstructor(hasCustomDomain ? username : match.params.username);
  }

  componentDidUpdate(prevProps, prevState) {
    const { user, match, username, instructor, hasCustomDomain } = this.props;

    if (instructor.migratedToRecess && !this.state.isRedirected) {
      this.setState({ isRedirected: true });
      window.location.replace(`https://recess.tv/redirect-from-onpodio?id=3358&username=${username}&url=https%3A%2F%2Fme.onpodio.com%2F${username}`);
    }

    if (prevProps.match.params.username !== match.params.username && !hasCustomDomain) {

      this.props.setInstructor(match.params.username);
    }

    if (prevState.username !== this.state.username) {
      this.props.getInstructor({ username: username });
      this.props.fetchChallenges({ username: username });
      this.props.fetchClassPacks({ username: username });
      this.props.fetchMemberships({ username: username });
      this.props.fetchRequestedClasses({ username: username });
      this.props.getPrivateGroupClasses({ limit: 35, username });
    }

    if (user.id && !this.state.isMounted) {
      this.props.fetchUserClassPacks();
      this.props.fetchUserChallenges();
      this.props.fetchSubscriptions();
      this.props.fetchFavoriteOnDemands();
      this.setState({ isMounted: true });
    }
  }

  render() {
    const { match, location, instructor, hasAuth } = this.props;
    const { template } = this.state;

    const pageRoute = (route) => {
      const rootPath = match.url === '/';

      return rootPath ? route : match.path + route;
    };

    const hideNavbar = includes([
      routes.LOGIN,
      routes.SIGNUP,
      routes.RECOVER_PASSWORD,
      routes.RESET_PASSWORD
    ], location.pathname);

    return (
      <Fragment>
        <PoweredByBadge className={`template-${template}-badge`} />

        { !hideNavbar &&
          <CustomNavigation template={template} />
        }

        { !isEmpty(instructor) && !hideNavbar &&
          <CustomizationHelmet instructor={instructor} />
        }

        <Switch location={location}>
          <Route
              exact
              path={pageRoute(instructorRoutes.HOME.path)}>
            <Layout
                content={
                  <Home
                      siteTemplate={template}
                      {...this.props} />
                }
                hasHeader={false}
                hasSidebar={isMobile} />
          </Route>

          <Route
              exact
              path={pageRoute(instructorRoutes.WORKOUT.path)}>
            <Layout
                content={
                  <Workout
                      siteTemplate={template}
                      {...this.props} />
                }
                hasHeader={false}
                hasSidebar={isMobile} />
          </Route>

          <Route
              exact
              path={pageRoute(instructorRoutes.PACKAGES.path)}>
            <Layout
                content={
                  <Packages
                      siteTemplate={template}
                      {...this.props} />
                }
                hasHeader={false}
                hasSidebar={isMobile} />
          </Route>

          <Route
              exact
              path={pageRoute(instructorRoutes.STYLE.path)}>
            <Layout
                content={
                  <Style
                      siteTemplate={template}
                      {...this.props} />
                }
                hasHeader={false}
                hasSidebar={isMobile} />
          </Route>

          <Route
              exact
              path={pageRoute(instructorRoutes.ABOUT.path)}>
            <Layout
                content={
                  <About
                      siteTemplate={template}
                      {...this.props} />
                }
                hasHeader={false}
                hasSidebar={isMobile} />
          </Route>

          { hasAuth &&
            <Route
                exact
                path={routes.LOGIN}>
              <Layout
                  content={<AuthenticationContainer />}
                  hasHeader={false}
                  hasSidebar={false} />
            </Route>
          }

          { hasAuth &&
            <Route
                exact
                path={routes.SIGNUP}>
              <Layout
                  content={<AuthenticationContainer />}
                  hasHeader={false}
                  hasSidebar={false} />
            </Route>
          }

          { hasAuth &&
            <Route
                exact
                path={routes.RECOVER_PASSWORD}>
              <Layout
                  content={<RecoverPasswordContainer />}
                  hasHeader={false}
                  hasSidebar={false} />
            </Route>
          }

          { hasAuth &&
            <Route
                exact
                path={routes.RESET_PASSWORD}>
              <Layout
                  content={<ResetPasswordContainer />}
                  hasHeader={false}
                  hasSidebar={false} />
            </Route>
          }
        </Switch>
      </Fragment>
    );
  }
}

export default withRouter(restoreSession(InstructorPage));
