import { TransactionsActionTypes } from '../constants/actionTypes';

export default {
  CHALLENGES_FETCH: () => ({
    type: TransactionsActionTypes.CHALLENGES_FETCH
  }),

  CHALLENGES_FETCH_FAILURE: (payload) => ({
    payload,
    type: TransactionsActionTypes.CHALLENGES_FETCH_FAILURE
  }),

  CHALLENGES_FETCH_SUCCESS: (payload) => ({
    payload,
    type: TransactionsActionTypes.CHALLENGES_FETCH_SUCCESS
  }),


  CHALLENGE_UPDATE: (payload) => ({
    payload,
    type: TransactionsActionTypes.CHALLENGE_UPDATE
  }),

  CHALLENGE_UPDATE_FAILURE: () => ({
    type: TransactionsActionTypes.CHALLENGE_UPDATE_FAILURE
  }),

  CHALLENGE_UPDATE_SUCCESS: (payload) => ({
    payload,
    type: TransactionsActionTypes.CHALLENGE_UPDATE_SUCCESS
  }),

  CLASS_PACKS_FETCH: () => ({
    type: TransactionsActionTypes.CLASS_PACKS_FETCH
  }),

  CLASS_PACKS_FETCH_FAILURE: (payload) => ({
    payload,
    type: TransactionsActionTypes.CLASS_PACKS_FETCH_FAILURE
  }),

  CLASS_PACKS_FETCH_SUCCESS: (payload) => ({
    payload,
    type: TransactionsActionTypes.CLASS_PACKS_FETCH_SUCCESS
  }),


  CLASS_PACK_UPDATE: (payload) => ({
    payload,
    type: TransactionsActionTypes.CLASS_PACK_UPDATE
  }),

  CLASS_PACK_UPDATE_FAILURE: () => ({
    type: TransactionsActionTypes.CLASS_PACK_UPDATE_FAILURE
  }),

  CLASS_PACK_UPDATE_SUCCESS: (payload) => ({
    payload,
    type: TransactionsActionTypes.CLASS_PACK_UPDATE_SUCCESS
  }),


  CLIENT_PURCHASES_FETCH: (payload) => ({
    payload,
    type: TransactionsActionTypes.CLIENT_PURCHASES_FETCH
  }),

  CLIENT_PURCHASES_FETCH_FAILURE: (payload) => ({
    payload,
    type: TransactionsActionTypes.CLIENT_PURCHASES_FETCH_FAILURE
  }),

  CLIENT_PURCHASES_FETCH_SUCCESS: (payload) => ({
    payload,
    type: TransactionsActionTypes.CLIENT_PURCHASES_FETCH_SUCCESS
  }),


  MEMBERSHIPS_FETCH: () => ({
    type: TransactionsActionTypes.MEMBERSHIPS_FETCH
  }),

  MEMBERSHIPS_FETCH_FAILURE: (payload) => ({
    payload,
    type: TransactionsActionTypes.MEMBERSHIPS_FETCH_FAILURE
  }),

  MEMBERSHIPS_FETCH_SUCCESS: (payload) => ({
    payload,
    type: TransactionsActionTypes.MEMBERSHIPS_FETCH_SUCCESS
  }),


  MEMBERSHIP_UPDATE: (payload) => ({
    payload,
    type: TransactionsActionTypes.MEMBERSHIP_UPDATE
  }),

  MEMBERSHIP_UPDATE_FAILURE: () => ({
    type: TransactionsActionTypes.MEMBERSHIP_UPDATE_FAILURE
  }),

  MEMBERSHIP_UPDATE_SUCCESS: (payload) => ({
    payload,
    type: TransactionsActionTypes.MEMBERSHIP_UPDATE_SUCCESS
  }),


  PURCHASES_FETCH: () => ({
    type: TransactionsActionTypes.PURCHASES_FETCH
  }),

  PURCHASES_FETCH_FAILURE: (payload) => ({
    payload,
    type: TransactionsActionTypes.PURCHASES_FETCH_FAILURE
  }),

  PURCHASES_FETCH_SUCCESS: (payload) => ({
    payload,
    type: TransactionsActionTypes.PURCHASES_FETCH_SUCCESS
  }),


  PURCHASE_UPDATE: (payload) => ({
    payload,
    type: TransactionsActionTypes.PURCHASE_UPDATE
  }),

  PURCHASE_UPDATE_FAILURE: () => ({
    type: TransactionsActionTypes.PURCHASE_UPDATE_FAILURE
  }),

  PURCHASE_UPDATE_SUCCESS: (payload) => ({
    payload,
    type: TransactionsActionTypes.PURCHASE_UPDATE_SUCCESS
  }),


  SUMMARY_FETCH: () => ({
    type: TransactionsActionTypes.SUMMARY_FETCH
  }),

  SUMMARY_FETCH_FAILURE: (payload) => ({
    payload,
    type: TransactionsActionTypes.SUMMARY_FETCH_FAILURE
  }),

  SUMMARY_FETCH_SUCCESS: (payload) => ({
    payload,
    type: TransactionsActionTypes.SUMMARY_FETCH_SUCCESS
  })
};
