import React from 'react';
import { textFilter } from 'react-bootstrap-table2-filter';
import { Link } from 'react-router-dom';
import Avatar from 'react-avatar';

import { clientsRoutes, routes } from '../../lib';
import { Button } from '../../components/forms';
import { UserAvatar } from '../../components/users';

const filterFieldOptions = {
  className: 'form-control-sm'
};

const emailFormatter = (cell) => (
  <a
      className='body-table__info-text'
      href={`mailto:${cell}`}>
    {cell}
  </a>
);

const membershipFormatter = (cell) => (
  cell ? (
    <div className='body-table__subscription-badge'>
      <span className='title'>{cell}</span>
    </div>
  ) : <div className='body-table__no-access-badge'>No Access</div>
);

const creditsFormatter = (cell) => (
  cell
    ? <div className='body-table__credits-badge'>{cell} Credits</div>
    : <div className='body-table__no-access-badge'>0 Credits</div>
);

const bookingsFormatter = (cell) => (
  <div className='body-table__badges-block m-0'>
    <div className='d-flex flex-direction-row'>
      <div
          className='badgeer blue'
          title='Live Classes'>
        <span>{cell.bookingsCount}</span>
      </div>

      <div
          className='ml-2 badgeer gray'
          title='On-Demand Classes'>
        {cell.onDemandsCount}
      </div>
    </div>

    <div
        className='ml-3 badgeer yellow'
        title='Total Classes'>
      {cell.totalCount}
    </div>
  </div>
);

const buttonFormatter = (cell) => (
  <Button
      isOutline
      size='sm'
      tag={Link}
      to={routes.CLIENTS + clientsRoutes.CLIENT(cell)}>
    Edit
  </Button>
);

const photoFormatter = (cell) => (
  cell ?
    <UserAvatar
        alt='avatar'
        size='sm'
        src={cell} /> :
    <Avatar
        color='#BCDCFF'
        round="26px"
        size='40' />
);

const titleFormatter = (cell) => (
  <div className='body-table__title'>
    {cell}
  </div>
);

const clientsColumns = () => ([
  {
    dataField: 'photo',
    formatter: photoFormatter,
    text:      ''
  },
  {
    align:     'left',
    dataField: 'name',
    filter:    textFilter(filterFieldOptions),
    formatter: titleFormatter,
    sort:      true,
    text:      'Name',
    type:      'string'
  },
  {
    dataField: 'email',
    filter:    textFilter(filterFieldOptions),
    formatter: emailFormatter,
    sort:      true,
    text:      'Email'
  },
  {
    dataField: 'subscription.membership.name',
    filter:    textFilter(filterFieldOptions),
    formatter: membershipFormatter,
    sort:      true,
    text:      'Membership'
  },
  {
    dataField: 'creditsCount',
    formatter: creditsFormatter,
    sort:      true,
    text:      'Credits'
  },
  {
    dataField: 'bookings',
    formatter: bookingsFormatter,
    text:      'Bookings'
  },
  {
    dataField: 'id',
    formatter: buttonFormatter,
    text:      ''
  }
]);

export default () => ({
  CLIENTS: clientsColumns(),

  PAGINATION: {
    hidePageListOnlyOnePage: true,
    hideSizePerPage:         true,
    showTotal:               true,
    sizePerPage:             25
  }
});
