import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';

import { Button, Form } from '../';
import { Scroller, Validator } from '../../../lib';
import { TimePicker } from '../../inputs';

class AvailabilityForm extends React.PureComponent {
  static propTypes = {
    data:     PropTypes.array,
    onSubmit: PropTypes.func
  };
  
  static defaultProps = {
    data:     ['', ''],
    day:      '',
    onSubmit: () => {}
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.isMounted) {
      const dailyAvailability = props.data;

      return {
        dailyAvailability: {
          from: dailyAvailability[0],
          to:   dailyAvailability[1]
        },
        isMounted: true
      };
    }

    return state;
  }
  
  
  state = {
    dailyAvailability: {
      from: '',
      to:   ''
    },
    
    errors:    {},
    isMounted: false
  };

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      dailyAvailability: {
        ...prevState.dailyAvailability,
        [inputName]: value
      }
    }));
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const errors = Validator.validate([
      ['from', Validator.concepts.isFilled, [this.state.dailyAvailability.from]],
      ['to',   Validator.concepts.isFilled, [this.state.dailyAvailability.to]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  });

  handleSubmit = () => {
    const { dailyAvailability } = this.state;
    const availability = Object.values(dailyAvailability);

    return this.props.onSubmit(availability);
  };

  render () {
    const { dailyAvailability, errors } = this.state;

    return (
      <Form className='form-default'>
        <Row>
          <Col>
            <FormGroup>
              <Label
                  className='required'
                  htmlFor='input-from'>
                Start time
              </Label>
              <TimePicker
                  id='from'
                  isInvalid={!!errors.from}
                  name='from'
                  onChange={this.handleChange}
                  value={dailyAvailability.from} />
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label
                  className='required'
                  htmlFor='input-to'>
                End time
              </Label>
              <TimePicker
                  id='to'
                  isInvalid={!!errors.to}
                  name='to'
                  onChange={this.handleChange}
                  value={dailyAvailability.to} />
            </FormGroup>
          </Col>
        </Row>
        <Button
            className='mt-5'
            isBlock
            onClick={this.handleSubmit}
            size='lg'
            type='button'>
          Update
        </Button>
      </Form>
    );
  }
}

export default AvailabilityForm;