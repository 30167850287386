import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { InstructorName } from '../../../../components/instructor';
import { FollowButton } from '../../../../components/buttons';

class AboutDefault extends React.PureComponent {
  static propTypes = {
    follow:     PropTypes.func.isRequired,
    instructor: PropTypes.object.isRequired,
    unFollow:   PropTypes.func.isRequired
  }

  handleFollow = () => {
    this.props.instructor.isFollowing ? this.props.unFollow() : this.props.follow();
  };

  render() {
    const { instructor } = this.props;

    return (
      <div className='about'>
        <div className='about__header'>
          <InstructorName
              instructor={instructor}
              subtitle='About' />
          <FollowButton
              followersCount={instructor.followersCount}
              isDisabled={!!instructor.isOwner}
              isFollowing={!!instructor.isFollowing}
              onClick={this.handleFollow} />
        </div>
        <div className='about__bio'>
          <span>{instructor.bio}</span>
        </div>

        <div className='about__body'>
          { !isEmpty(instructor.experiences) &&
            <div className='about-body__wrapper'>
              <span className='about-body__section'>work experience</span>
              <div className='about-body__block'>
                { instructor.experiences.map((experience) => (
                  <span
                      className='about-body__text'
                      key={experience.id}>
                    {experience.from} - {experience.isCurrent ? 'Current' : experience.to}, {experience.company}
                  </span>
                ))}
              </div>
            </div>
          }
          { !isEmpty(instructor.educations) &&
            <div className='about-body__wrapper'>
              <span className='about-body__section'>education</span>
              <div className='about-body__block'>
                { instructor.educations.map((education) => (
                  <span
                      className='about-body__text'
                      key={education.id}>
                    {education.name}
                  </span>
                ))}
              </div>
            </div>
          }
          { !isEmpty(instructor.certifications) &&
            <div className='about-body__wrapper'>
              <span className='about-body__section'>accreditation</span>
              <div className='about-body__block about-body__block_row'>
                { instructor.certifications.map((accreditation, index) => (
                  <span
                      className='about-body__text'
                      key={accreditation.id}>
                    {accreditation.title}{index < instructor.certifications.length-1 ? ',' : null}
                  </span>
                ))}
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default AboutDefault;
