import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Form } from '../../index';
import {
  About,
  Certifications,
  Educations,
  Experiences
} from '../index';

class BioForm extends React.PureComponent {
  static propTypes = {
    educations:  PropTypes.array.isRequired,
    errors:      PropTypes.object,
    isEditable:  PropTypes.bool,
    onChange:    PropTypes.func.isRequired,
    profile:     PropTypes.object.isRequired,
    profileData: PropTypes.object.isRequired
  }

  static defaultProps = {
    errors:     {},
    isEditable: false
  }

  render() {
    const { isEditable } = this.props;
    const blockClass = classNames('text-block', { 'pt-3': isEditable });

    return (
      <Form className='form-default'>
        <About
            className={blockClass}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <span className='menu-title p-0'>
            {isEditable ? 'Biography' : 'Add your biography'}
          </span>
        </About>

        <Educations
            className={blockClass}
            educations={this.props.educations}
            onChange={this.props.onChange}>
          <span className='menu-title p-0'>
            {isEditable ? 'Education' : 'Add your education'}
          </span>
        </Educations>

        <Experiences
            errors={this.props.errors}
            onChange={this.props.onChange}
            profile={this.props.profile}>
          <span className='menu-title p-0 mt-2'>
            {isEditable ? 'Experience' : 'Add your work experience'}
          </span>
        </Experiences>

        <Certifications
            className={blockClass}
            onChange={this.props.onChange}
            profile={this.props.profile}
            profileData={this.props.profileData}>
          <span className='menu-title p-0 mt-2'>
            {isEditable ? 'Certifications' : 'Add your certification'}
          </span>
        </Certifications>
      </Form>
    );
  }
}

export default BioForm;
