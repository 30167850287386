/* global process */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Redirect } from 'react-router';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { CLASS_PACK_STEPS } from '../../constants';
import { routes } from '../../lib';
import { UserModalAvatar } from './layouts';
import { Info, Payment, SignUp, Success } from './classPack';

class ClassPackModal extends React.PureComponent {
  static propTypes = {
    classPack:       PropTypes.object.isRequired,
    createClassPack: PropTypes.func.isRequired,
    createDiscount:  PropTypes.func.isRequired,
    discount:        PropTypes.object.isRequired,
    instructor:      PropTypes.object.isRequired,
    isOpen:          PropTypes.bool,
    onToggle:        PropTypes.func,
    user:            PropTypes.object.isRequired
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  state = {
    isLoading: false,
    step:      CLASS_PACK_STEPS.info
  }

  handleLoading = (isLoading, callback) => (
    this.setState({ isLoading }, callback)
  )

  handleStep = (step) => (
    this.handleLoading(true, () => (
      this.setState({ isLoading: false, step })
    ))
  )

  handleSignUp = () => (
    this.handleStep(CLASS_PACK_STEPS.payment)
  )

  handleToggle = () => {
    if (this.state.isLoading) return;
    this.props.onToggle();
  }

  renderContent() {
    const { instructor } = this.props;

    const stripePromise = () => (
      loadStripe(process.env.STRIPE_API_KEY, { stripeAccount: instructor.stripeId })
    );

    switch (this.state.step) {
      case CLASS_PACK_STEPS.info:
        return (
          <Info
              classPack={this.props.classPack}
              instructor={instructor} />
        );

      case CLASS_PACK_STEPS.payment:
        return (
          <Elements stripe={stripePromise()}>
            <Payment
                classPack={this.props.classPack}
                createClassPack={this.props.createClassPack}
                createDiscount={this.props.createDiscount}
                discount={this.props.discount}
                instructor={instructor}
                isLoading={this.state.isLoading}
                onChangeStep={this.handleStep}
                onLoading={this.handleLoading}
                user={this.props.user} />
          </Elements>
        );

      case CLASS_PACK_STEPS.success:
        return (
          <Success
              classPack={this.props.classPack}
              instructor={instructor} />
        );

      default: return null;
    }
  }

  renderFooter() {
    switch (this.state.step) {
      case CLASS_PACK_STEPS.info:
        return (
          <SignUp
              instructor={this.props.instructor}
              isLoading={this.state.isLoading}
              onClick={this.handleSignUp} />
        );

        default: return null;
    }
 }

  render() {
    const { classPack, instructor, user } = this.props;

    if (!user.id) {
      return <Redirect to={routes.LOGIN} />;
    }

    return (
      <Modal
          className='modal-booking'
          isOpen={this.props.isOpen}
          toggle={this.handleToggle}>
        <ModalHeader toggle={this.handleToggle} />

        <div className='new-modal'>
          <ModalBody>
            { this.state.step != CLASS_PACK_STEPS.success &&
              <React.Fragment>
                { instructor &&
                  <div className='modal__header'>
                    <UserModalAvatar
                        alt={instructor.name}
                        firstName={instructor.name}
                        src={instructor.photo.sm} />
                    <div className='modal__instructor-name'>
                      <span className='modal__big-text'>{instructor.name}</span>
                    </div>
                  </div>
                }

                <div className='modal__event-name'>
                  <span className='modal__big-text modal__big-text_black'>{classPack.name}</span>
                </div>

                <div className='modal-description'>
                  <span className='modal__small-text'>About this package</span>

                  <p className='modal__small-text modal__small-text_grey'>{classPack.description}</p>
                </div>

                { classPack.canExpire &&
                  <div className='modal__small-text modal__small-text_grey mb-2'>
                    Expires: <u>{classPack.expiry} days after purchase</u>
                  </div>
                }
              </React.Fragment>
            }
            {this.renderContent()}
          </ModalBody>
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

export default ClassPackModal;
