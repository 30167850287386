import React from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import { filter, find, isEmpty, map, omit, sumBy } from 'lodash';

import { tableOptions } from '../../../constants/clients';

class ClientsTable extends React.PureComponent {
  static propTypes = {
    clients:   PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    profile:   PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    const data = map(props.clients, (client) => {
      const { bookingsCount, onDemandsCount, userClassPacks } = client;

      const subscriptions = filter(client.subscriptions, ['status', 'active']);
      const subscription = find(subscriptions, ['membership.instructorId', props.profile.id]);
      const isActiveSubscription = !isEmpty(subscription) && subscription.status === 'active';

      const classPacks = filter(userClassPacks, ['instructorId', props.profile.id]);
      const creditsCount = sumBy(classPacks, (booking) => booking.live +
                                                          booking.onDemand +
                                                          booking.combined +
                                                          booking.privateSession);

      const totalCount = bookingsCount + onDemandsCount;
      const bookings = { bookingsCount, onDemandsCount, totalCount };

      return {
        ...omit(client, ['subscriptions', 'userClassPacks', 'bookingsCount', 'onDemandsCount']),
        bookings,
        creditsCount,
        isActiveSubscription,
        subscription
      };
    });

    return { data };
  }


  state = {
    data: []
  }

  render() {
    const { isLoading } = this.props;
    const { data } = this.state;
    const options = tableOptions();

    const nameAsc = [{
      dataField: 'name',
      order:     'asc'
    }];

    return (
      <BootstrapTable
          bootstrap4
          columns={options.CLIENTS}
          data={data}
          defaultSorted={nameAsc}
          filter={filterFactory()}
          filterPosition="top"
          hover
          keyField='id'
          loading={isLoading}
          pagination={paginationFactory(options.PAGINATION)}
          wrapperClasses='table-responsive' />
    );
  }
}

export default ClientsTable;
