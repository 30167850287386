import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { instructorDashboardSections } from '../../constants';

import { AdvancedPrivateSessionModal, DeclineBookingRequestModal } from '../modals';
import { ListCollapse } from '../lists';
import { BookingRequest } from './';

class BookingRequests extends React.PureComponent {
  static propTypes = {
    acceptBookingRequest:  PropTypes.func.isRequired,
    bookingRequests:       PropTypes.array.isRequired,
    changeSection:         PropTypes.func.isRequired,
    declineBookingRequest: PropTypes.func.isRequired,
    instructor:            PropTypes.object.isRequired,
    section:               PropTypes.string.isRequired,
    updatePrivateSession:  PropTypes.func.isRequired,
    user:                  PropTypes.object.isRequired
  }

  state = {
    bookingRequest:     {},
    isSetLinkModal:     false,
    isSetLinkModalOpen: false
  }

  handleAccept = (bookingRequest) => () => (
    this.props.acceptBookingRequest({ bookingRequest })
  )

  handleDecline = (bookingRequest, note) => (
    this.props.declineBookingRequest({ bookingRequest, note })
  )

  handleModal = (bookingRequest = {}, fieldName) => () => (
    this.setState((prevState) => ({ bookingRequest, [fieldName]: !prevState[fieldName] }))
  )

  renderRequests = () => (
    this.props.bookingRequests.map((bookingRequest) => (
      <BookingRequest
          bookingRequest={bookingRequest}
          key={bookingRequest.id}
          onApprove={this.handleAccept(bookingRequest)}
          onDecline={this.handleModal(bookingRequest, 'isDeclineModalOpen')}
          onUpdateLink={this.handleModal(bookingRequest, 'isSetLinkModalOpen')}
          user={this.props.user} />
    ))
  );

  renderTitle = (length) => (
    <React.Fragment>
      <span className='d-flex'>
        <span
            aria-label="donut"
            role="img" >
          &#128075;
        </span>&nbsp;
        Private Session Requests
      </span>
      <small>{length} new {pluralize('request', length)}</small>
    </React.Fragment>
  );

  render() {
    const { bookingRequests } = this.props;

    return (
      <React.Fragment>
        { bookingRequests.length > 0 &&
          <ListCollapse
              className='booking-requests mb-2'
              color='secondary'
              isOpen={this.props.section === instructorDashboardSections.PRIVATE_SESSION_REQUESTS}
              onClick={() => this.props.changeSection(instructorDashboardSections.PRIVATE_SESSION_REQUESTS)}
              title={this.renderTitle(bookingRequests.length)}>
            {bookingRequests.length > 0 && this.renderRequests()}
          </ListCollapse>
        }

        { this.state.isDeclineModalOpen &&
        <DeclineBookingRequestModal
            bookingRequest={this.state.bookingRequest}
            isOpen={this.state.isDeclineModalOpen}
            onDecline={this.handleDecline}
            onToggle={this.handleModal({}, 'isDeclineModalOpen')} />
        }

        { this.state.isSetLinkModalOpen &&
          <AdvancedPrivateSessionModal
              data={this.state.bookingRequest}
              instructor={this.props.instructor}
              isOpen={this.state.isSetLinkModalOpen}
              onApprove={this.handleAccept}
              onToggle={this.handleModal({}, 'isSetLinkModalOpen')}
              updatePrivateSession={this.props.updatePrivateSession}
              user={this.props.user} />
        }


      </React.Fragment>
    );
  }
}

export default BookingRequests;
