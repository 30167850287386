/* global process */
/* eslint-disable import/order */
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';

import createSagaMiddleware from 'redux-saga';
import { createRootReducer, sagas } from './';

/* connected-react-router */
import { createBrowserHistory } from 'history';

/* redux-logger */
import { createLogger } from 'redux-logger';

/* redux-persist */
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { migrations } from '../../config/migrations';

export const history = createBrowserHistory();


export default function configureStore(preloadedState) {
  /* middleware */
  const sagaMiddleware   = createSagaMiddleware();
  const loggerMiddleware = createLogger({
    collapsed: true
  });
  const routerMiddleware = createRouterMiddleware(history);

  /* persist state */
  const persistConfig = {
    blacklist: [
      'router', 'loader', 'autocompleter', 'validation', 'instagram', 'spotify',
      'autocompleter', 'groupClasses', 'toastr', 'visits', 'comments', 'error', 'privateSessionType',
      'locations', 'playlist', 'join', 'purchasedClasses', 'domain', 'discount', 'app', 'transactions'
    ],
    key:     'root',
    migrate: createMigrate(migrations, { debug: false }),
    storage,
    version: 4
  };

  const rootReducer = createRootReducer(history);
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const predicateEnv = process.env.REACT_ENV != 'production';

  const middleware = [
    sagaMiddleware,
    predicateEnv && loggerMiddleware,
    routerMiddleware
  ].filter(Boolean);

  /* store */
  const store = createStore(
    persistedReducer,
    preloadedState,
    compose(applyMiddleware(...middleware))
  );

  const persistor = persistStore(store);

  sagaMiddleware.run(sagas);

  return { persistor, store };
}
