import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isEmpty } from 'lodash';

import { PlaylistModal } from '../../../modals';
import { Loader } from '../../../loader';
import { GoIcon } from '../../../../images';

class SpotifyPlaylist extends React.PureComponent {
  static propTypes = {
    error:    PropTypes.object.isRequired,
    loader:   PropTypes.object.isRequired,
    playlist: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      isLoading: props.loader.playlist.isLoading,
      playlist:  props.playlist
    };
  }

  state = {
    isLoading:  true,
    isOpen:     false,
    playlist:   {},
    trackTitle: '',
    tracks:     []
  }

  handleToggle = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  }

  handleTrackTitle = (trackTitle) => () => {
    this.setState((prevState) => ({
      ...prevState,
      trackTitle: trackTitle
    }));
  }

  handleTitleRemove = () => () => {
    this.setState((prevState) => ({
      ...prevState,
      trackTitle: ''
    }));
  }

  renderTracks = () => (
    this.state.playlist.tracks.slice(0, 10).map((track, i) => (
      <button
          className='style__image-btn'
          disabled
          key={i}
          onBlur={this.handleTitleRemove()}
          onFocus={this.handleTrackTitle(track.title)}
          onMouseOut={this.handleTitleRemove()}
          onMouseOver={this.handleTrackTitle(track.title)}>
        <img
            alt={track.title}
            className='item'
            src={track.albumArt} />
      </button>
    ))
  );

  render() {
    const { trackTitle, isLoading, playlist } = this.state;
    const { error } = this.props;
    const isHidden = !isEmpty(error.spotify);

    const blockClass = classNames('profile-integration', 'spotify', { 'd-none': isHidden });
    const titleClass = classNames('title', { 'hidden': isEmpty(this.state.playlist.tracks) });

    return (
      <div className={blockClass}>
        <div className={titleClass}>
          <span className='label'>{trackTitle ? trackTitle: 'My Spotify'}</span>
          <div>
            <a
                className='style__go-link'
                href='#playlist'
                onClick={this.handleToggle}>
              Play
            </a>
            <img
                alt='Icon'
                src={GoIcon} />
          </div>
        </div>
        <div className='content'>
          <Loader
              hasOpacity={false}
              isLoading={isLoading}
              isTransparent
              size='2rem'
              type='border' />
          { !isEmpty(playlist) &&
            this.renderTracks()
          }
        </div>

        { !isEmpty(playlist) &&
          <PlaylistModal
              isOpen={this.state.isOpen}
              onToggle={this.handleToggle}
              playlistId={this.state.playlist.id} />
        }
      </div>
    );
  }
}

export default SpotifyPlaylist;
