import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { filter, isEmpty } from 'lodash';

import { Button, Form, Select } from '../forms';

class SynchronizationModal extends React.PureComponent {
  static propTypes = {
    createSync:      PropTypes.func.isRequired,
    isOpen:          PropTypes.bool.isRequired,
    isSynced:        PropTypes.bool.isRequired,
    onToggle:        PropTypes.func.isRequired,
    synchronization: PropTypes.array.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(props.synchronization)) {
      const newInstructorIds = Array.from({ length: props.synchronization.length });

      return {
        scrapperInstructorIds: isEmpty(state.scrapperInstructorIds) ? newInstructorIds : state.scrapperInstructorIds,
        synchronization:       props.synchronization
      };
    }
    return null;
  }

  state = {
    formOpened:            false,
    scrapperInstructorIds: [],
    synchronization:       []
  };

  handleFormOpen = () => {
    this.setState((prevState) => ({
      ...prevState,
      formOpened: true
    }));
  };

  toggleModalSize = () => {
    return this.state.formOpened ? 'md' : 'md';
  };

  handleChange = (index) => (selectedOption) => {
    this.setState((prevState) => ({
      ...prevState,
      scrapperInstructorIds: Array.from(prevState.scrapperInstructorIds,
        (id, i) => (i === index ? selectedOption.value : id))
    }));
  };

  handleCreateSync = () => {
    const { scrapperInstructorIds } = this.state;
    this.props.createSync({ scrapperInstructorIds });
    this.props.onToggle();
  };

  render() {
    const { formOpened, scrapperInstructorIds } = this.state;

    return (
      <Modal
          centered
          isOpen={this.props.isOpen}
          size={this.toggleModalSize()}
          toggle={this.props.onToggle}>
        <ModalHeader
            className='text-center'
            toggle={this.props.onToggle}>
          Sync Class Schedule
        </ModalHeader>
        <ModalBody className='modal-synchronization'>
          {!formOpened && <div className='sync-info'>
            <h2 className='text-info mb-4 text-center'>
              Woo! Auto-Sync is available for one of your studios
            </h2>
           
            <div className='sync-hint mt-5'>
              <h5 className='text-muted'>What does that mean?</h5>
              <p className='text-muted'>
                It means you can sync your profile to our class database and let us automatically import your class
                schedule for this studio(s) to your profile! Set and forget it!
              </p>
            </div>
            <div className='sync-hint mt-4'>
              <h5 className='text-muted'>Do I need to do anything after it&apos;s synced?</h5>
              <p className='text-muted'>
                Nope, we regularly update the class schedule so you won&apos;t need to update anything :)
              </p>
            </div>
            <Button
                className='mt-4'
                color='primary'
                isBlock
                onClick={this.handleFormOpen}
                size='lg'>
                Let&apos;s do it!
            </Button>
          </div>}
          {formOpened &&
            <div className='sync-form'>
              <h2 className='text-info text-center mb-4'>
                {this.props.isSynced ?
                  'You are all synced!' : 'Find and select your name & click sync. Simple as that!'}
              </h2>
              {!this.props.isSynced &&
                <p className='text-muted mt-4'>
                  To find your name try searching for the name or nickname that is typically used by your studio
                </p>
              }
              <Form>
                {this.state.synchronization.map((studio, index) => (
                  <FormGroup
                      key={index}
                      row>
                    <Label sm={4}>{studio.name}</Label>
                    <Col sm='8'>
                      {!studio.synced &&
                        <Select
                            className='w-100'
                            onChange={this.handleChange(index)}
                            options={studio.instructors}
                            placeholder='Select instructor'
                            value={filter(studio.instructors, { value: scrapperInstructorIds[index] })[0]} />
                      }
                      {studio.synced &&
                        <Select
                            className='w-100'
                            isDisabled
                            value={studio.instructor} />
                      }
                    </Col>
                  </FormGroup>
                  ))
                }
              </Form>
              {!this.props.isSynced &&
                <Button
                    className='mt-4'
                    color='primary'
                    isBlock
                    onClick={this.handleCreateSync}
                    size='lg'>
                  Sync now
                </Button>
              }
              {this.props.isSynced &&
                <Button
                    className='mt-4'
                    color='primary'
                    isBlock
                    onClick={this.props.onToggle}
                    size='lg'>
                  Close window
                </Button>
              }
            </div>
          }
        </ModalBody>
      </Modal>
    );
  }
}

export default SynchronizationModal;
