import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { capitalize, padStart } from 'lodash';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';

import { StripeBadge } from '../../badges';
import { Input } from '../../inputs';
import { Button, ChargeForm, PaymentIcon, Radio } from '../../forms';
import { StripeHelper, api, routes, toCamelCase } from '../../../lib';
import { FEES, SUBSCRIPTION_STEPS } from '../../../constants';

class Payment extends React.PureComponent {
  static propTypes = {
    createDiscount:     PropTypes.func.isRequired,
    createSubscription: PropTypes.func.isRequired,
    customer:           PropTypes.object.isRequired,
    discount:           PropTypes.object.isRequired,
    instructor:         PropTypes.object.isRequired,
    isLoading:          PropTypes.bool.isRequired,
    membership:         PropTypes.object.isRequired,
    onChangeStep:       PropTypes.func.isRequired,
    onLoading:          PropTypes.func.isRequired,
    user:               PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      amount:     props.membership.cost,
      clientName: props.user.firstName +  ' ' + props.user.lastName,
      fee:        FEES.membership(props.membership.cost)
    };
  }

  state = {
    amount:       '',
    cards:        [],
    clientName:   '',
    code:         '',
    data:         {},
    error:        {},
    fee:          '',
    openInput:    false,
    selectedCard: ''
  }

  componentDidMount() {
    const { instructor, onLoading } = this.props;

    onLoading(true, () => (
      api.card.get({ username: instructor.username })
        .then((response) => (
          this.setState({
            cards:        response.data,
            selectedCard: response.data[0] || 'new'
          }, () => onLoading(false))
        ))
        .catch(() => (
          this.setState({ selectedCard: 'new' }, onLoading(false))
        ))
    ));
  }

  handleOption = (card) => () => (
    this.setState({ selectedCard: card })
  )

  handleSubmit = (elements, stripe) => async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const { clientName } = this.state;
    const { customer, instructor, membership, onLoading, user, discount } = this.props;

    const description = `${clientName} subscribed to ${membership.name} membership`;
    const card        = elements.getElement(CardElement);
    const updateState = this.setState.bind(this);

    if (this.state.selectedCard == 'new') {
      StripeHelper.createCustomer(instructor, onLoading, updateState, (customer) => (
        StripeHelper.createPaymentMethod(stripe, card, user, onLoading, updateState, (paymentMethod) => {
          const params = {
            customerId:      customer.stripe_id,
            description,
            discountCode:    !isEmpty(discount) ? discount.coupon.code : '',
            hold:            false,
            membershipId:    membership.id,
            method:          'token',
            paymentMethodId: paymentMethod.id
          };

          StripeHelper.createSubscription(params, onLoading, updateState, () => {
            this.props.createSubscription(toCamelCase(this.state.data));
            this.props.onChangeStep(SUBSCRIPTION_STEPS.success);
          });
        })
      ));

    } else {
      const params = {
        customerId:   customer.stripeId,
        description,
        discountCode: !isEmpty(discount) ? discount.coupon.code : '',
        hold:         false,
        membershipId: membership.id,
        method:       'default'
      };

      StripeHelper.createSubscription(params, onLoading, updateState, () => {
        this.props.createSubscription(toCamelCase(this.state.data));
        this.props.onChangeStep(SUBSCRIPTION_STEPS.success);
      });
    }
  }

  handleToggle = () => (
    this.setState((prevState) => ({ openInput: !prevState.openInput }))
  )

  handleApply = () => {
    const { code } = this.state;
    const { instructor, membership } = this.props;

    this.props.createDiscount({ discount: {
      code,
      instructorId: instructor.id,
      membershipId: membership.id
    }});

    this.setState({ code: '', openInput: false });
  }

  handleChangeInput = (value) => (
    this.setState({ code: value })
  )

  renderOptions = () => (
    <React.Fragment>
      <div className='text-muted text-left mt-2 mb-2 modal__default-text'>
        I&apos;d like to pay with
      </div>

      <div className='text-left'>
        {this.state.cards.map((card) => (
          <div
              className='custom-control custom-radio'
              key={card.id}>
            <Radio
                className='custom-control-input'
                id={card.id}
                isChecked={this.state.selectedCard.id == card.id}
                name='selectedCard'
                onChange={this.handleOption(card)}
                value={false} />
            <Label
                className='custom-control-label pb-1'
                htmlFor={'input-' + card.id}>
              <span className='mr-3'>
                <PaymentIcon icon={card.card.brand} />
              </span>

              <span className='mr-3'>
                {capitalize(card.card.brand)} ****{card.card.last4}&nbsp;
              </span>

              {padStart(card.card.exp_month)}/{String(card.card.exp_year).slice(-2)}
            </Label>
          </div>
        ))}

        {this.state.selectedCard != 'new' && <StripeBadge />}
      </div>
    </React.Fragment>
  )

  renderCost = () => {
    const { amount, fee } = this.state;
    const { discount } = this.props;
    const { currency } = this.props.instructor;
    const { coupon } = discount;

    const isDiscounted = discount.id;
    const isFree = isDiscounted && coupon.discountType == 'free';

    const discountPercent = isDiscounted && coupon.percent * 0.01;
    const discountAmount = amount * discountPercent;

    const amountWithDiscount = amount - discountAmount;


    const discountFee = isDiscounted ? amountWithDiscount * 0.03 + 0.49 : fee;
    const price = isDiscounted ? amountWithDiscount : amount;

    const renderPrice = () => (
      <span>
        {parseFloat(price + discountFee, 10).toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
        <span className='modal__event-fee text-muted'>
          (incl. {parseFloat(discountFee, 10).toLocaleString('en-GB', { currency, style: 'currency' })} fee)
        </span>
        &nbsp;{renderMessage()}
      </span>
    );

    const cost =  parseFloat(amount + fee, 10).toLocaleString('en-GB', { currency, style: 'currency' });

    const renderMessage = () => {
      switch (coupon && coupon.duration) {
        case 'forever':
          return (
            'forever'
          );

        case 'once':
          return (
            `for 1st payment then ${cost}`
          );

        case 'repeating':
          return (
            `for ${coupon.numberOfMonths} months then ${cost}`
          );

        default:
          return null;
      }
    };

    return (
      <div
          className='modal__event-cost
                     modal__default-text
                     modal__default-text_blue
                     modal__default-text_bold
                     d-inline'>
        Total Cost:&nbsp;
        <React.Fragment>
          {!isDiscounted && renderPrice()}

          { isDiscounted &&
            <span>
              &nbsp;
              {isFree && 'Free' + ' ' + renderMessage()}
              {!isFree && renderPrice()}
            </span>
          }
        </React.Fragment>
      </div>
    );
  }

  render() {
    const { membership, instructor, isLoading } = this.props;
    const { cards } = this.state;

    return (
      <ElementsConsumer>
        {({elements, stripe}) => (
          <React.Fragment>
            <div className='mt-3'>
              <div
                  className='modal__event-cost
                             modal__default-text
                             modal__default-text_blue
                             modal__default-text_bold d-inline'>
                { membership.trial &&
                  <span className='d-flex modal__default-text font-weight-bold mb-2 mt-2'>
                    Free trial for {membership.trialDays} days
                  </span>
                }

                <div className='mb-1'>
                  <div
                      className='modal__event-cost
                                modal__default-text_blue
                                modal__default-text
                                m-0 pb-1 pointer'
                      onClick={this.handleToggle}
                      onKeyPress={this.handleToggle}
                      role='button'
                      tabIndex={0}>
                    Apply a Coupon Code?
                  </div>

                  {this.state.openInput &&
                    <React.Fragment>
                      <div className='d-flex'>
                        <Input
                            className='modal__code-input'
                            id='name'
                            name='name'
                            onChange={this.handleChangeInput}
                            placeholder='Code'
                            type='text'
                            value={this.state.code} />

                        <Button
                            className='modal__apply-button'
                            color='success'
                            onClick={this.handleApply}>
                          Apply
                        </Button>
                      </div>

                      {this.state.couponError && <div className='text-danger text-sm'>{this.state.couponError}</div>}
                    </React.Fragment>
                  }
                </div>
                {this.renderCost()}
              </div>


              { this.state.selectedCard == 'new' &&
                <ChargeForm
                    elements={elements}
                    error={this.state.error.message}
                    stripe={stripe} />
              }

              {!!cards.length && this.renderOptions()}
            </div>

            <div className='mt-5'>
              { membership.trial &&
                <small className='modal__small-text modal__small-text_grey text-left d-block pb-2'>
                  Your card <span className='font-weight-bold'>will not be charged</span> until the free trial ends.
                  You can cancel anytime during the free trial.
                </small>
              }

              <small className='modal__small-text modal__small-text_grey text-left d-block pb-2'>
                Renews automatically each {membership.period}.
                You can cancel auto-renew per the memberships requirements.
              </small>

              {!membership.sample && isEmpty(instructor.oauth.stripe) &&
                <div className='modal__default-text text-danger mt-4 mb-3'>
                  Instructor has disabled stripe payments. For more information contact 
                  <a href={`mailto:${instructor.email}`}>&nbsp;{instructor.email}</a>
                </div>
              }

              {!membership.sample &&
                <Button
                    color='blue'
                    isBlock
                    isDisabled={!stripe || isEmpty(instructor.oauth.stripe)}
                    isLoading={isLoading}
                    onClick={this.handleSubmit(elements, stripe)}
                    size='lg'>
                  Pay & Subscribe
                </Button>
              }

              {membership.sample && isEmpty(instructor.oauth.stripe) &&
                <Link to={routes.INTEGRATIONS}>
                  <Button
                      color='blue'
                      isBlock
                      size='lg'>
                    Connect Stripe
                  </Button>
                </Link>
              }

              {membership.sample && !isEmpty(instructor.oauth.stripe) &&
                <Button
                    color='blue'
                    isBlock
                    isDisabled={!stripe}
                    isLoading={isLoading}
                    onClick={this.handleSubmit(elements, stripe)}
                    size='lg'>
                  Pay & Subscribe
                </Button>
              }
            </div>
          </React.Fragment>
        )}
      </ElementsConsumer>
    );
  }
}

export default Payment;
