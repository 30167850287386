import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';

import { Moment, getTimeZone, withHttp } from '../../lib';
import { Button } from '../forms';
import { Input } from '../inputs';
import { DeletePrivateSessionAlert } from '../alerts';

class InstructorPrivateSessionModal extends React.PureComponent {
  static propTypes = {
    deletePrivateSession:          PropTypes.func.isRequired,
    deleteRecurringPrivateSession: PropTypes.func.isRequired,
    isOpen:                        PropTypes.bool,
    onToggle:                      PropTypes.func,
    privateSession:                PropTypes.object.isRequired,
    user:                          PropTypes.object.isRequired
  };

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  };

  state = {
    date:        '',
    isCancel:    false,
    isModalOpen: false,
    note:        ''
  };

  static getDerivedStateFromProps(props) {
    return {
      date: new Moment.utc(props.privateSession.startsAt, 'YYYY-MM-DDhh:mm:ssUTC').tz(getTimeZone(props.user.timeZone))

    };
  }

  handleDelete = () => {
    const { privateSession } = this.props;
    const { note } = this.state;

    const deleteOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => {
        return (
          <DeletePrivateSessionAlert
              deletePrivateSession={this.props.deletePrivateSession}
              deleteRecurringPrivateSession={this.props.deleteRecurringPrivateSession}
              onClose={onClose}
              onSuccess={this.props.onToggle}
              privateSession={this.props.privateSession} />
        );
      }
    };

    if (this.props.privateSession.recurring) {
      confirmAlert(deleteOptions);
    } else {
      this.props.deletePrivateSession({ note, privateSession });
      this.props.onToggle();
    }
  };

  handleDeleteModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen
    }));
  };

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value });
  };

  handleCancel = () => {
    this.setState((prevState) => ({
      isCancel: !prevState.isCancel
    }));
  };

  renderNote = () => (
    <React.Fragment>
      <div className='text-danger text-sm mt-4 mb-4'>
        Cancelling the session will remove the booking from both your calendar and your clients. You client will also
        receive an email to which you can add a special note if you would like.
      </div>

      <Form className='form-default'>
        <FormGroup>
          <Label
              for='input-note'>
            Add special note to attendee email
          </Label>

          <Input
              id='note'
              name='note'
              onChange={this.handleChange}
              placeholder='Type your information here...'
              rows='4'
              type='textarea'
              value={this.state.note} />
        </FormGroup>
      </Form>
    </React.Fragment>
  );

  render() {
    const { privateSession } = this.props;
    const { date } = this.state;

    const isOnline = privateSession.locationType == 'online';

    return (
      <Modal
          className='modal-event'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>

        <ModalHeader toggle={this.props.onToggle}>
          {this.state.isCancel ? 'Cancel Private Session' : 'View Private Session'}
        </ModalHeader>

        <ModalBody>
          <h6 className='title-details'>
            Confirmed Details
          </h6>

          <div className='event-details'>
            <div className='name'>
              {privateSession.client.name} ({privateSession.location.name})
            </div>

            <div className='date'>
              {date.format('dddd, MMMM D YYYY')}
            </div>

            <div className='time'>
              {date.format('h:mm A')} -&nbsp;
              {privateSession.privateSessionType.duration} minutes ({privateSession.privateSessionType.name})
            </div>

            { isOnline &&
              <React.Fragment>
                <div className='link'>
                  <a
                      className='d-block text-truncate'
                      href={withHttp(privateSession.digitalLink)}
                      rel='noopener noreferrer external'
                      target='_blank'>
                    {privateSession.digitalLink}
                  </a>
                </div>

                <div className='meettingId'>
                  { privateSession.meetingUid &&
                    <div className=''>
                      <strong>MeetingId:</strong> {privateSession.meetingUid}
                    </div>
                  }

                  { privateSession.passcode &&
                    <div>
                      <strong>Passcode:</strong> {privateSession.passcode}
                    </div>
                  }
                </div>
              </React.Fragment>
            }


            <div className='sendEmail mt-4 mb-4'>
              <Icon
                  className='mr-2'
                  icon='envelope'
                  size='2x' />
              <a
                  href={`mailto:${privateSession.client.email}`}
                  rel='noopener noreferrer'
                  target='_blank'>
                <span>
                  Send an
                  <span className='emailText'>&nbsp;Email&nbsp;</span>
                  to client
                </span>
              </a>
            </div>

            {this.state.isCancel && this.renderNote()}
          </div>
        </ModalBody>

        <ModalFooter>
          { !this.state.isCancel &&
            <Row>
              <Col>
                <p className='text-sm'>A confirmed private session cannot be edited but can be cancelled.</p>
              </Col>
            </Row>
          }
          <Row>
            <Col
                lg={6}
                md={12}>
              { this.state.isCancel &&
                <Button
                    color='danger'
                    isBlock
                    isOutline
                    onClick={this.handleDelete}
                    size='lg'>
                  Confirm
                </Button>
              }

              { !this.state.isCancel &&
                <Button
                    color='danger'
                    isBlock
                    isOutline
                    onClick={this.handleCancel}
                    size='lg'>
                  Cancel
                </Button>
              }
            </Col>
            <Col
                lg={6}
                md={12}>
              <Button
                  color='primary'
                  isBlock
                  onClick={this.props.onToggle}
                  size='lg'>
                Close
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default InstructorPrivateSessionModal;
