import React from 'react';
import PropTypes from 'prop-types';

const MenuTitle = ({ children, color }) => {
  return (
    <h4 className={`menu-title text-${color}`}>
      {children}
    </h4>
  );
};

MenuTitle.propTypes = {
  children: PropTypes.node.isRequired,
  color:    PropTypes.string
};

MenuTitle.defaultProps = {
  color: 'secondary'
};

export default MenuTitle;