import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

import { Scroller, Validator, instructorRoutes, routes } from '../../../lib';
import { BioForm } from '../../forms/profile/create';
import { Button, Checkbox } from '../../forms';
import { BackButton } from '../../buttons';
import { Heading, Panel } from '../../layout/pages';

class SettingsAboutPage extends React.PureComponent {
  static propTypes = {
    instructor:    PropTypes.object.isRequired,
    profileData:   PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const { instructor } = props;

    delete instructor.locations;

    const initialExperience = { company: '', delete: false, from: '', isCurrent: false, to: '' };
    const initialEducation =  { delete: false, name: '' };

    if (instructor.id && !state.isMounted) {
      return {
        aboutPageEnabled: instructor.aboutPageEnabled,
        instructor:       {
                            ...instructor,
                            educations:  [...instructor.educations, initialEducation],
                            experiences: [...instructor.experiences, initialExperience],
                            photo:       ''
                          },
        isMounted: true
      };
    }

    return {
      ...state,
      educations: [...instructor.educations, initialEducation]
    };
  }

  state = {
    educations: [],
    errors:     {},
    instructor: {},
    isEditable: false
  };

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      instructor: {
        ...prevState.instructor,
        [inputName]: value
      }
    }));
  }

  handleSubmit = () => (
    this.handleValidate().then(() => (
      this.setState((prevState) => ({
        ...prevState,
        errors: {}
      }), () => {
        this.props.updateProfile(this.state.instructor);
      })
    ))
  )

  handleValidate = () => new Promise((resolve, reject) => {
    const { instructor } = this.state;

    const errors = Validator.validate([
      ['username',        Validator.concepts.isReserved,           [instructor.username]],
      ['username',        Validator.concepts.usernameRegex,        [instructor.username]],
      ['username',        Validator.concepts.isFilled,             [instructor.username]],
      ['username',        Validator.concepts.isLongerThan,         [3, instructor.username, 'Username']],
      ['firstName',       Validator.concepts.isFilled,             [instructor.firstName]],
      ['lastName',        Validator.concepts.isFilled,             [instructor.lastName]],
      ['tagline',         Validator.concepts.isFilled,             [instructor.tagline]],
      ['workExperiences', Validator.concepts.experiencesValidator, [instructor.experiences]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  })

  render() {
    const { instructor } = this.state;

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Edit your profile</title>
        </Helmet>
        <div className='inner-wrapper instructor-settings'>
          <Container
              className='settings-page'
              fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='About' />
            <Panel className='settings-menu'>
              <Media className='menu-link d-block'>
                <Media body>
                  <div className='custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='about'
                        isChecked={instructor.aboutPageEnabled}
                        name='aboutPageEnabled'
                        onChange={this.handleChange} />
                    <Label
                        className='custom-control-label d-flex'
                        htmlFor='input-about'>
                      Show the&nbsp;
                      <Link to={routes.INSTRUCTOR(instructor.username) + instructorRoutes.ABOUT.path}>
                        <div className='text-primary'>About</div>
                      </Link>
                      &nbsp;page on your site
                    </Label>
                  </div>
                </Media>
              </Media>

              <Media className='menu-link d-block'>
                <BioForm
                    educations={this.state.educations}
                    error={this.state.errors}
                    isEditable
                    onChange={this.handleChange}
                    profile={instructor}
                    profileData={this.props.profileData} />
                <Button
                    className='update-btn mt-2'
                    isOutline
                    onClick={this.handleSubmit}
                    onKeyPress={this.handleSubmit}
                    size='lg'>
                 Update
                </Button>
              </Media>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SettingsAboutPage;
