import React from 'react';
import PropTypes from 'prop-types';
import TimeInputPolyfill from 'react-time-input-polyfill';
import classNames from 'classnames';

class TimePicker extends React.PureComponent {
  static propTypes = {
    id:         PropTypes.string,
    isDisabled: PropTypes.bool,
    isInvalid:  PropTypes.bool,
    name:       PropTypes.string,
    onChange:   PropTypes.func,
    value:      PropTypes.string
  };

  static defaultProps = {
    id:         '',
    isDisabled: false,
    isInvalid:  false,
    name:       '',
    onChange:   () => {},
    value:      ''
  };

  handleChange = ({ value }) => {
     if (!this.props.isDisabled) this.props.onChange(value, this.props.name);
  }

  render () {
    const { value } = this.props;

    const id = 'input-' + this.props.id;

    return (
      <TimeInputPolyfill
          className={classNames('form-control form-control-md red',
                                { 'is-invalid': this.props.isInvalid },
                                { 'disabled-time-input': this.props.isDisabled })}
          forcePolyfill={false}
          id={id}
          onChange={this.handleChange}
          value={value} />
    );
  }
}

export default TimePicker;
