import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';

import { Moment, getTimeZone } from '../../lib';
import { TimeBlockForm } from '../forms/calendar';
import { TimeBlockDeleteAlert } from '../alerts';

class TimeBlockModal extends React.PureComponent {
  static propTypes = {
    createRecurringTimeBlock: PropTypes.func,
    createTimeBlock:          PropTypes.func,
    deleteRecurringTimeBlock: PropTypes.func,
    deleteTimeBlock:          PropTypes.func,
    isOpen:                   PropTypes.bool.isRequired,
    onToggle:                 PropTypes.func.isRequired,
    timeBlock:                PropTypes.object,
    updateTimeBlock:          PropTypes.func,
    user:                     PropTypes.object.isRequired
  };

  static defaultProps = {
    createRecurringTimeBlock: () => {},
    createTimeBlock:          () => {},
    deleteRecurringTimeBlock: () => {},
    deleteTimeBlock:          () => {},
    timeBlock:                {
      duration:   60,
      name:       '',
      recurrence: {
        entity:   'instructor/time_block',
        interval: 'week',
        startsAt: '',
        total:    2
      },
      startsAt: new Date()
    },
    updateTimeBlock: () => {}
  };

  static getDerivedStateFromProps(props) {
    return {
      timeBlock: props.timeBlock
    };
  }

  state = {
    timeBlock: {}
  };

  handleCreate = (formData) => {
    const { createTimeBlock, createRecurringTimeBlock, user } = this.props;
    const { recurring, date, time, name, duration, weeks } = formData;

    const stamp = [date, time].join('');
    const startsAt = new Moment.tz(stamp, 'YYYY-MM-DDhh:mm:ssUTC', getTimeZone(user.timeZone)).format();

    const timeBlock = {
      instructorTimeBlock: { duration, name, startsAt }
    };

    const recurrence = {
      recurrence: {
        ...this.state.recurrence,
        entity:          'instructor/time_block',
        interval:        'week',
        name,
        recurringParams: timeBlock.instructorTimeBlock,
        startsAt,
        total:           weeks
      }
    };

    recurring ? createRecurringTimeBlock(recurrence) : createTimeBlock(timeBlock);
    this.props.onToggle();
  };

  handleDestroy = () => {
    const { timeBlock } = this.state;
    const deleteOptions = {
      closeOnClickOutside: false,
      customUI:            ({onClose}) => {
        return (
          <TimeBlockDeleteAlert
              onClose={onClose}
              onDelete={() => this.handleSingleDelete(timeBlock.id)}
              onDeleteAll={() => this.handleRecurringDelete(timeBlock.recurrence.id)} />
        );
      }
    };

    if (timeBlock.recurring) {
      confirmAlert(deleteOptions);
    } else {
      this.props.deleteTimeBlock(timeBlock);
      this.props.onToggle();
    }
  };

  handleRecurringDelete = (id) => {
    this.props.deleteRecurringTimeBlock({ id: id });
    this.props.onToggle();
  };

  handleSingleDelete = (id) => {
    this.props.deleteTimeBlock({ id: id });
    this.props.onToggle();
  };

  handleUpdate = (formData) => {
    const { user } = this.props;
    const { recurring, date, time, name, duration } = formData;
    const id = this.state.timeBlock.id;
    const recurrenceId = recurring ? this.state.timeBlock.recurrence.id : '';
    const startsAt = new Moment([date,time].join(''), 'YYYY-MM-DDhh:mm:ssUTC').tz(getTimeZone(user.timeZone)).format();

    const timeBlock = {
      id:                  id,
      instructorTimeBlock: { duration, id, name, recurringId: recurrenceId, startsAt }
    };

    this.props.updateTimeBlock(timeBlock);
    this.props.onToggle();
  };

  render () {
    const newRecord = !this.state.timeBlock.id;

    return (
      <Modal
          className='modal-time-block'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>

        <ModalHeader toggle={this.props.onToggle}>
          Block Off Time
        </ModalHeader>

        <ModalBody>
          <TimeBlockForm
              data={this.state.timeBlock}
              isNewRecord={newRecord}
              onCreate={this.handleCreate}
              onDestroy={this.handleDestroy}
              onUpdate={this.handleUpdate}
              user={this.props.user} />
        </ModalBody>
      </Modal>
    );
  }
}

export default TimeBlockModal;
