import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';

import { DateHelper, Moment, getTimeZone, withHttp } from '../../lib';
import { Button } from '../forms';
import { UserCancelPrivateBooking } from '../alerts';
import { UserModalAvatar } from './layouts';

class UserPrivateGroupClassModal extends React.PureComponent {
  static propTypes = {
    booking:              PropTypes.object,
    deletePrivateBooking: PropTypes.func.isRequired,
    isOpen:               PropTypes.bool,
    onToggle:             PropTypes.func,
    user:                 PropTypes.object.isRequired
  }

  static defaultProps = {
    booking:  {},
    isOpen:   false,
    onToggle: () => {}
  }

  state = {
    date:              '',
    privateGroupClass: {}
  }

  static getDerivedStateFromProps(props) {
    const privateGroupClass = props.booking.event || {};
    const date = DateHelper.summerTimeCheck(privateGroupClass, props.user.timeZone);

    return {
      date: new Moment.utc(date, 'YYYY-MM-DDhh:mm:ssUTC').tz(getTimeZone(props.user.timeZone)),
      privateGroupClass
    };
  }

  handleDelete = () => (
    confirmAlert({
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <UserCancelPrivateBooking
            booking={this.props.booking}
            deletePrivateBooking={this.props.deletePrivateBooking}
            onClose={onClose}
            onSuccess={this.props.onToggle} />
    )})
  )

  renderPolicy = (strategy) => {
    const { booking } = this.props;
    const { privateGroupClass } = this.state;
    const { instructor } = privateGroupClass;

    const email = () => (
      <a href={`mailto:${instructor.email}`}>{instructor.email}</a>
    );

    const refund = () => {
      const refundType = booking.viaClassPack ? 'recredit' : 'refund';

      if (strategy.allowRefund && !booking.viaSubscription) {
        return <strong>for a full {refundType}</strong>;
      } else {
        return <strong>but you WILL NOT be refunded</strong>;
      }
    };

    if (!strategy.allowCancel) {
      return (
        <span>
          This class cannot be canceled. If you have any questions please reach out to {email()}.;
        </span>
      );
    }

      if (strategy.allowCancel) {
        if (strategy.cancelDeadline) {
          return (
            <span>
              This class can be cancelled up to {strategy.cancelDeadline} hours before the start time {refund()}.
              If you have any questions please reach out to {email()}.
            </span>
          );
        } else {
          return (
            <span>
              This class can be cancelled up until the start time {refund()}.
              If you have any questions please reach out to {email()}.
            </span>
          );
        }
      }
  }

  render() {
    const { user } = this.props;
    const { date, privateGroupClass } = this.state;

    const { instructor, playlist, description } = privateGroupClass;
    const { strategy } = instructor;

    const deadline = new Moment().add(strategy.cancelDeadline, 'hours')
                                 .tz(getTimeZone(user.timeZone));

    const isExpired = deadline > date;

    const isHybridClass = privateGroupClass.eventType == 'hybrid';
    const isOnlineBooking = privateGroupClass.bookingType == 'online';

    return (
      <Modal
          className='modal-booking'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle} />

        <div className='new-modal'>
          <ModalBody>
            { instructor &&
              <div className='modal__header'>
                <UserModalAvatar
                    alt={instructor.name}
                    firstName={instructor.name}
                    src={instructor.photo} />
                <div className='modal__instructor-name'>
                  <span className='modal__big-text'>{instructor.name}</span>
                </div>
              </div>
            }

            <div className='modal__event-name'>
              { !isHybridClass &&
                <span className='modal__big-text modal__big-text_black'>
                  {privateGroupClass.name} ({privateGroupClass.location.name})
                </span>
              }

              { isHybridClass && isOnlineBooking &&
                <span className='modal__big-text modal__big-text_black'>
                  {privateGroupClass.name} (Virtual)
                </span>
              }

              { isHybridClass && !isOnlineBooking &&
                <span className='modal__big-text modal__big-text_black'>
                  {privateGroupClass.name} ({privateGroupClass.location.name})
                </span>
              }
            </div>

            <div className='modal__time'>
              <div className='modal__default-text modal__default-text_bold'>
                {date.format('dddd, MMMM DD')}
              </div>

              <div className='modal__default-text'>
                &nbsp;|&nbsp;{date.format('hh:mmA')} - {privateGroupClass.duration} Minutes
              </div>
            </div>


            <div className='modal__event-timezone modal__small-text'>
              {new Moment.tz(getTimeZone(user.timeZone)).format('z')} (GMT
              {new Moment.tz(getTimeZone(user.timeZone)).format('Z')} {getTimeZone(user.timeZone)})
            </div>

            { description &&
              <div className='event-description mt-2'>
                <span className='modal__small-text'>About this class</span>
                <p className='modal__small-text modal__small-text_grey'>{description}</p>
              </div>
            }

            { privateGroupClass.digitalLink && !isHybridClass &&
              <div className='modal__default-text'>
                <a
                    className='d-block text-truncate'
                    href={withHttp(privateGroupClass.digitalLink)}
                    rel='noopener noreferrer external'
                    target='_blank'>
                  {privateGroupClass.digitalLink}
                </a>
              </div>
            }

            { isHybridClass && isOnlineBooking &&
              <div className='modal__default-text'>
                <a
                    className='d-block text-truncate'
                    href={withHttp(privateGroupClass.digitalLink)}
                    rel='noopener noreferrer external'
                    target='_blank'>
                  {privateGroupClass.digitalLink}
                </a>
              </div>
            }

            { (privateGroupClass.meetingUid || privateGroupClass.passcode) && isHybridClass && isOnlineBooking &&
              <div>
                { privateGroupClass.meetingUid &&
                  <div className='modal__default-text mr-2'>
                    <strong>MeetingId:</strong> {privateGroupClass.meetingUid}
                  </div>
                }

                { privateGroupClass.passcode &&
                  <div className='modal__default-text'>
                    <strong>Passcode:</strong> {privateGroupClass.passcode}
                  </div>
                }
              </div>
            }

            { (privateGroupClass.meetingUid || privateGroupClass.passcode) && !isHybridClass &&
              <div>
                { privateGroupClass.meetingUid &&
                  <div className='modal__default-text mr-2'>
                    <strong>MeetingId:</strong> {privateGroupClass.meetingUid}
                  </div>
                }

                { privateGroupClass.passcode &&
                  <div className='modal__default-text'>
                    <strong>Passcode:</strong> {privateGroupClass.passcode}
                  </div>
                }
              </div>
            }

            { playlist &&
              <div className='modal__event-playlist'>
                <a
                    className='playlist-link'
                    href={`https://open.spotify.com/playlist/${playlist.playlistId}`}
                    rel='noopener noreferrer'
                    target='_blank'>
                  <Icon
                      className='mr-2'
                      fixedWidth
                      icon={['fab', 'spotify']}
                      size='lg' />
                  <span>{playlist.name}</span>
                </a>
              </div>
            }

            <div className='event-description mt-2'>
              <span className='modal__small-text'>Cancellation Policy</span>
              <p className='modal__small-text modal__small-text_grey'>
                {this.renderPolicy(strategy)}
              </p>
            </div>

            { isExpired &&
              <small className='text-danger'>
                You can no longer cancel this class
              </small>
            }

            { strategy.allowCancel &&
              <Button
                  color='danger'
                  isBlock
                  isDisabled={isExpired}
                  isOutline
                  onClick={this.handleDelete}>
                Cancel
              </Button>
            }
          </ModalBody>
        </div>
      </Modal>
    );
  }
}

export default UserPrivateGroupClassModal;
