import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { VideoPlayerPage } from '../components/pages';
import { PurchasedClassesActions } from '../actions';


class VideoPlayerContainer extends React.PureComponent {
  static propTypes = {
    getPurchasedClass: PropTypes.func.isRequired,
    match:             PropTypes.object.isRequired
  };

  componentDidMount() {
    const { uuid } = this.props.match.params;

    this.props.getPurchasedClass({ uuid });
  }

  render() {
    return (
      <VideoPlayerPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  loader:         state.loader,
  purchasedClass: state.purchasedClasses.form
});

const mapDispatchToProps = (dispatch) => ({
  getPurchasedClass:    (data) => dispatch(PurchasedClassesActions.USERS_GET(data)),
  updatePurchasedClass: (data) => dispatch(PurchasedClassesActions.USER_UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(VideoPlayerContainer));
