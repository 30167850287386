import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { chevronDown } from 'react-icons-kit/fa/chevronDown';
import classNames from 'classnames';

const CollapseIcon = ({ size, className, isOpen }) => (
  <Icon
      className={classNames(className, { 'opened': isOpen })}
      icon={chevronDown}
      size={size} />
);

CollapseIcon.propTypes = {
  className: PropTypes.string,
  isOpen:    PropTypes.bool,
  size:      PropTypes.number
};

CollapseIcon.defaultProps = {
  className: '',
  isOpen:    false,
  size:      32
};

export default CollapseIcon;