import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../../forms';

class EmailNotificationAlert extends React.PureComponent {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSend:  PropTypes.func.isRequired,
    onSkip:  PropTypes.func.isRequired
  }

  handleSkip = () => {
    this.props.onSkip();
    this.props.onClose();
  }

  handleSend = () => {
    this.props.onSend();
    this.props.onClose();
  }
  
  render () {
    return (
      <div className='confirmation confirmation-email'>
        <Card body>
          <CardText>
            Do you want to <span className='text-primary'>send an email to all existing booked clients</span> with
            updated class information?
          </CardText>
          <div className='actions'>
            <Button
                color='link'
                onClick={this.handleSkip}>
              Skip
            </Button>
            <Button onClick={this.handleSend}>
              Send
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default EmailNotificationAlert;