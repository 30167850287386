import { MembershipsCouponsActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: MembershipsCouponsActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: MembershipsCouponsActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: MembershipsCouponsActionTypes.CREATE_SUCCESS
  }),


  DELETE: (payload) => ({
    payload, type: MembershipsCouponsActionTypes.DELETE
  }),

  DELETE_FAILURE: () => ({
    type: MembershipsCouponsActionTypes.DELETE_FAILURE
  }),

  DELETE_SUCCESS: (payload) => ({
    payload, type: MembershipsCouponsActionTypes.DELETE_SUCCESS
  }),


  FETCH: () => ({
    type: MembershipsCouponsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: MembershipsCouponsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: MembershipsCouponsActionTypes.FETCH_SUCCESS
  }),


  GET: (payload) => ({
    payload, type: MembershipsCouponsActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: MembershipsCouponsActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload, type: MembershipsCouponsActionTypes.GET_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: MembershipsCouponsActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: MembershipsCouponsActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: MembershipsCouponsActionTypes.UPDATE_SUCCESS
  })
};
