import React from 'react';
import PropTypes from 'prop-types';

import { DateHelper, getTemplate } from '../../../../lib';
import { Donation, Free, Paid } from '../../../../images';
import { templates } from '../../../../constants';
import GroupClassItemUrban from './urban/groupClassItem';
import GroupClassItemDefault from './default/groupClassItem';
import GroupClassItemElegant from './elegant/groupClassItem';

class GroupClassItemContainer extends React.PureComponent {
  static propTypes = {
    groupClass:     PropTypes.object.isRequired,
    instructor:     PropTypes.object.isRequired,
    onHandleToggle: PropTypes.func.isRequired,
    user:           PropTypes.object.isRequired
  }

  renderLocationInfo = (groupClass) => (
    <React.Fragment>
      {groupClass.type == 'groupClass' &&
        <div className='event-location'>
          {groupClass.location ? groupClass.location.name : 'No location'}
        </div>
      }

      {groupClass.type == 'privateGroupClass' &&
        <div className='event-location'>
          {groupClass.eventType == 'online' && 'Virtual'}
          {groupClass.eventType == 'offline' && groupClass.location.name}
          {groupClass.eventType == 'hybrid' && 'Virtual + In-Person' }
        </div>
      }
    </React.Fragment>
  );

  renderIcon = (type) => {
    if (!this.props.instructor.customization) return null;

    const { customization } = this.props.instructor;
    const { secondaryButtonsBackgroundColor } = customization;

    switch (type) {
      case 'free':
        return <Free fill={secondaryButtonsBackgroundColor} />;

      case 'donation':
        return <Donation fill={secondaryButtonsBackgroundColor} />;

      case 'paid':
        return <Paid fill={secondaryButtonsBackgroundColor} />;

      default:
        return <Paid fill={secondaryButtonsBackgroundColor} />;
    }
  }

  render() {
    const { groupClass, instructor, onHandleToggle, user } = this.props;
    const { currency, customization } = instructor;

    const siteTemplate = getTemplate(instructor);
    const date = DateHelper.summerTimeCheck(groupClass, user.timeZone);
    const time = DateHelper.getDate(date, this.props.user.timeZone).format('h:mmA');

    switch (siteTemplate) {
      case templates.ELEGANT: return (
        <GroupClassItemElegant
            currency={currency}
            customization={customization}
            groupClass={groupClass}
            onHandleToggle={onHandleToggle}
            renderIcon={this.renderIcon}
            renderLocationInfo={this.renderLocationInfo}
            time={time} />
      );

      case templates.URBAN: return (
        <GroupClassItemUrban
            currency={currency}
            customization={customization}
            groupClass={groupClass}
            onHandleToggle={onHandleToggle}
            renderIcon={this.renderIcon}
            renderLocationInfo={this.renderLocationInfo}
            time={time} />
      );

      default: return (
        <GroupClassItemDefault
            customization={customization}
            groupClass={groupClass}
            onHandleToggle={onHandleToggle}
            renderIcon={this.renderIcon}
            renderLocationInfo={this.renderLocationInfo}
            time={time} />
      );
    }
  }
}

export default GroupClassItemContainer;
