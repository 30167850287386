import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { MembershipCouponForm } from '../../forms/instructor';

class MembershipCouponModal extends React.PureComponent {
  static propTypes = {
    coupon:       PropTypes.object.isRequired,
    createCoupon: PropTypes.func.isRequired,
    deleteCoupon: PropTypes.func.isRequired,
    isLoading:    PropTypes.bool.isRequired,
    isNewRecord:  PropTypes.bool.isRequired,
    isOpen:       PropTypes.bool.isRequired,
    onToggle:     PropTypes.func.isRequired,
    user:         PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props) {
    const { isNewRecord, isLoading } = props;

    return {
      coupon:      isNewRecord ? {} : props.coupon,
      isLoading:   isLoading,
      isNewRecord: isNewRecord
    };
  }

  state = {
    coupon:      {},
    isLoading:   false,
    isNewRecord: true
  };

  render () {
    const { isOpen, onToggle } = this.props;
    const { coupon, isNewRecord, isLoading } = this.state;

    const action = isNewRecord ? 'Create' : 'Delete';

    return (
      <Modal
          centered
          className='client-modal'
          isOpen={isOpen}
          toggle={onToggle}>

        <ModalHeader toggle={onToggle}>
          {action} <span className='text-primary'> Coupon for Memberships</span>
        </ModalHeader>

        <ModalBody>
          <MembershipCouponForm
              createCoupon={this.props.createCoupon}
              data={coupon}
              deleteCoupon={this.props.deleteCoupon}
              isLoading={isLoading}
              isNewRecord={isNewRecord}
              onToggle={this.props.onToggle}
              user={this.props.user} />
        </ModalBody>
      </Modal>
    );
  }
}

export default MembershipCouponModal;
