import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter } from 'lodash';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';

import { api, extractError, routes, toCamelCase } from '../lib';
import { ClientsActionTypes } from '../constants/actionTypes';
import { ClientsActions, ErrorActions, LoaderActions } from '../actions';

function* watchCreate() {
  yield takeLatest(ClientsActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.instructor.client.create, payload);

      yield put(ClientsActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Client added');
    } catch (error) {
      yield put(ClientsActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(ClientsActionTypes.CREATE_SUCCESS, function* () {
    yield put(push(routes.CLIENTS));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(ClientsActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDelete() {
  yield takeLatest(ClientsActionTypes.DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.instructor.client.delete, payload);

      const clients = yield select((state) => state.clients.data);
      const data = filter(clients, (client) => (client.id != payload.client.id));

      yield put(ClientsActions.DELETE_SUCCESS(data));
      yield put(push(routes.CLIENTS));
      toastr.success('Client deleted');
    } catch (error) {
      yield put(ClientsActions.DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDeleteSuccess() {
  yield takeLatest(ClientsActionTypes.DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDeleteFailure() {
  yield takeLatest(ClientsActionTypes.DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetch() {
  yield takeLatest(ClientsActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('clients'));
      const response = yield call(api.instructor.client.fetch);

      yield put(ClientsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ClientsActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(ClientsActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('clients'));
  });
}

function* watchFetchFailure() {
  yield takeLatest(ClientsActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING('clients'));
  });
}

function* watchUpdate() {
  yield takeLatest(ClientsActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.client.update, payload);

      yield put(ClientsActions.UPDATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Client updated');
    } catch (error) {
      yield put(ClientsActions.UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(ClientsActionTypes.UPDATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateFailure() {
  yield takeLatest(ClientsActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchGet() {
  yield takeLatest(ClientsActionTypes.GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('clients'));
      const response = yield call(api.instructor.client.get, payload);

      yield put(ClientsActions.GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ClientsActions.GET_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchGetSuccess() {
  yield takeLatest(ClientsActionTypes.GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('clients'));
  });
}

function* watchGetFailure() {
  yield takeLatest(ClientsActionTypes.GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('clients'));
  });
}

export default function* clientsSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),
    watchDelete(),
    watchDeleteSuccess(),
    watchDeleteFailure(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure(),
    watchGet(),
    watchGetSuccess(),
    watchGetFailure()
  ]);
}
