import { GroupClassesActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: GroupClassesActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: GroupClassesActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: GroupClassesActionTypes.CREATE_SUCCESS
  }),


  DELETE: (payload) => ({
    payload, type: GroupClassesActionTypes.DELETE
  }),

  DELETE_FAILURE: () => ({
    type: GroupClassesActionTypes.DELETE_FAILURE
  }),

  DELETE_SUCCESS: (payload) => ({
    payload, type: GroupClassesActionTypes.DELETE_SUCCESS
  }),


  FETCH: () => ({
    type: GroupClassesActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: GroupClassesActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: GroupClassesActionTypes.FETCH_SUCCESS
  }),


  INSTRUCTORS_FETCH: () => ({
    type: GroupClassesActionTypes.INSTRUCTORS_FETCH
  }),

  INSTRUCTORS_FETCH_FAILURE: () => ({
    type: GroupClassesActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_FETCH_SUCCESS: (payload) => ({
    payload, type: GroupClassesActionTypes.INSTRUCTORS_FETCH_SUCCESS
  }),


  INSTRUCTORS_GET: (payload) => ({
    payload, type: GroupClassesActionTypes.INSTRUCTORS_GET
  }),

  INSTRUCTORS_GET_FAILURE: () => ({
    type: GroupClassesActionTypes.INSTRUCTORS_GET_FAILURE
  }),

  INSTRUCTORS_GET_SUCCESS: (payload) => ({
    payload, type: GroupClassesActionTypes.INSTRUCTORS_GET_SUCCESS
  }),


  LOCATIONS_FETCH: () => ({
    type: GroupClassesActionTypes.LOCATIONS_FETCH
  }),

  LOCATIONS_FETCH_FAILURE: () => ({
    type: GroupClassesActionTypes.LOCATIONS_FETCH_FAILURE
  }),

  LOCATIONS_FETCH_SUCCESS: (payload) => ({
    payload, type: GroupClassesActionTypes.LOCATIONS_FETCH_SUCCESS
  }),


  LOCATIONS_GET: (payload) => ({
    payload, type: GroupClassesActionTypes.LOCATIONS_GET
  }),

  LOCATIONS_GET_FAILURE: () => ({
    type: GroupClassesActionTypes.LOCATIONS_GET_FAILURE
  }),

  LOCATIONS_GET_SUCCESS: (payload) => ({
    payload, type: GroupClassesActionTypes.LOCATIONS_GET_SUCCESS
  }),


  RECURRING_CREATE: (payload) => ({
    payload, type: GroupClassesActionTypes.RECURRING_CREATE
  }),

  RECURRING_CREATE_FAILURE: () => ({
    type: GroupClassesActionTypes.RECURRING_CREATE_FAILURE
  }),

  RECURRING_CREATE_SUCCESS: (payload) => ({
    payload, type: GroupClassesActionTypes.RECURRING_CREATE_SUCCESS
  }),


  RECURRING_DELETE: (payload) => ({
    payload, type: GroupClassesActionTypes.RECURRING_DELETE
  }),

  RECURRING_DELETE_FAILURE: () => ({
    type: GroupClassesActionTypes.RECURRING_DELETE_FAILURE
  }),

  RECURRING_DELETE_SUCCESS: (payload) => ({
    payload, type: GroupClassesActionTypes.RECURRING_DELETE_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: GroupClassesActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: GroupClassesActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: GroupClassesActionTypes.UPDATE_SUCCESS
  })
};
