import React from 'react';
import PropTypes from 'prop-types';
import { sumBy } from 'lodash';

import { templates } from '../../constants';
import { getTemplate } from '../../lib';
import FundraiserUrban from './fundraiserComponent/urban';
import FundraiserDefault from './fundraiserComponent/default';

class FundraiserContainer extends React.PureComponent {
  static propTypes = {
    donationBookings: PropTypes.array.isRequired,
    donationTargets:  PropTypes.object.isRequired,
    instructor:       PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const { donationBookings, donationTargets, instructor } = props;
    const totalSales = sumBy(donationBookings, (booking) => booking.charge ? booking.charge.amount / 100 : 0);
    const calcByProcent = (totalSales / 100) * donationTargets.contributionAmount;
    const calcByMoney = donationBookings.length * donationTargets.contributionAmount;
    const totalDonations = donationTargets.contributionType === 'procent' ? calcByProcent : calcByMoney;
    const progressValue = totalDonations / donationTargets.target * 100;

    if (!state.isMounted) {
      return {
        currency:  instructor.currency,
        isMounted: true,
        progressValue,
        totalDonations
      };
    }
  }

  state = {
    currency:       '',
    isMounted:      false,
    progressValue:  0,
    totalDonations: 0
  }

  render = () => {
    const { donationTargets, instructor } = this.props;
    const { currency, totalDonations, progressValue } = this.state;
    const siteTemplate = getTemplate(instructor);

    switch (siteTemplate) {
      case templates.URBAN: return (
        <FundraiserUrban
            currency={currency}
            donationTargets={donationTargets}
            progressValue={progressValue}
            totalDonations={totalDonations} />
      );

      default: return (
        <FundraiserDefault
            currency={currency}
            donationTargets={donationTargets}
            progressValue={progressValue}
            totalDonations={totalDonations} />
      );
    }
  }
}

export default FundraiserContainer;
