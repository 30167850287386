/* eslint-disable immutable/no-let */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { isEmpty } from 'lodash';

import { Autocompleter, Button, Required } from '../forms';
import { Input } from '../inputs';
import { UserAvatar } from '../users';
import { Validator } from '../../lib';
import { AutocompleterContainer } from '../../containers';

class AddReviewModal extends React.PureComponent {
  static propTypes = {
    createReview: PropTypes.func.isRequired,
    isOpen:       PropTypes.bool,
    location:     PropTypes.object.isRequired,
    onToggle:     PropTypes.func
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  initialReview = {
    activity:      '',
    activityId:    '',
    publicComment: ''
  };

  state = {
    errors: {},

    review: this.initialReview
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      review: {
        ...prevState.review,
        [inputName]: value
      }
    }));
  };

  handleSelect = (inputName) => (option) => {
    this.setState((prevState) => ({
      review: {
        ...prevState.review,
        [inputName]: option.value
      }
    }));
  }

  handleSubmit = () => {
    const { review } = this.state;
    const { id } = this.props.location;

    this.handleValidate().then(() => {
      this.props.createReview({ review: {
          activityId: review.activityId,
          body:       review.publicComment,
          locationId: id
      }});

      return this.handleToggle();
    }).catch((errors) => {
      return this.setState({ errors });
    });
  };

  handleToggle = () => {
    this.setState({ errors: {} }, () => {
      Validator.clear();
      this.props.onToggle();
    });
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const { review } = this.state;

    const errors = Validator.validate([
      ['activities',    Validator.concepts.isFilled, [review.activityId]],
      ['publicComment', Validator.concepts.isFilled, [review.publicComment]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  render() {
    const { location } = this.props;

    return (
      <Modal
          centered
          className='review-modal'
          isOpen={this.props.isOpen}
          toggle={this.handleToggle}>
        <ModalHeader
            toggle={this.handleToggle} />
        <ModalBody>
          <Container fluid>
            <Row className='mb-lg-5'>
              <Col md={4}>
                <div className='profile-avatar'>
                  <UserAvatar
                      alt={location.name}
                      className='mb-3'
                      effect='blur'
                      size='xxl'
                      src='' />
                </div>
              </Col>
              <Col md={8}>
                <h4 className='mt-2'>{location.name}</h4>

                <FormGroup>
                  <Required>
                    <AutocompleterContainer>
                      <Autocompleter
                          className='mt-3'
                          hasInitialValue
                          isInvalid={!!this.state.errors.activity}
                          location={location}
                          name='activity'
                          onChange={this.handleChange}
                          onSelect={this.handleSelect('activityId')}
                          placeholder='Select class type'
                          subject='activities'
                          value={this.state.review.activity} />
                    </AutocompleterContainer>
                  </Required>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form>
                  <FormGroup>
                    <Label
                        className='font-weight-bold'
                        for='publicComment'>
                      Leave a comment for {location.name}
                    </Label>

                    <Required>
                      <Input
                          id='publicComment'
                          isInvalid={!!this.state.errors.publicComment}
                          maxLength={250}
                          name='publicComment'
                          onChange={this.handleChange}
                          placeholder='Type your review here...'
                          rows='5'
                          type='textarea'
                          value={this.state.review.publicComment} />
                    </Required>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
              color='primary'
              isBlock
              onClick={this.handleSubmit}
              size='lg'>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddReviewModal;
