import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

class Heading extends React.PureComponent {
  static propTypes = {
    isOpen:  PropTypes.bool,
    onClick: PropTypes.func,
    title:   PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
  };

  static defaultProps = {
    isOpen:   true,
    isSticky: false,
    onClick:  () => {}
  }

  render () {
    return (
      <div
          className={className('calendar-heading', { 'open': this.props.isOpen })}
          onClick={this.props.onClick}
          onKeyPress={this.props.onClick}
          role="button"
          tabIndex={0}>
        <span>{this.props.title}</span>
      </div>
    );
  }
}

export default Heading;
