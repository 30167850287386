import React from 'react';
import { connect } from 'react-redux';

class CloseContainer extends React.PureComponent {
  componentDidMount() {
    window.close();
  }

  render() {
    return null;
  }
}

export default connect()(CloseContainer);
