/* eslint-disable immutable/no-mutation */
/* global moment */
import { findIndex, keyBy, map, mapKeys, omit, pickBy } from 'lodash';
import { getTimeZone } from './';

class ProfileFormatter {
  _toApi = (profile, user) => {
    const {
      activities,
      certifications,
      educations,
      experiences,
      prompts,
      services,
      locations,
      membershipReasons
    } = profile;

    function format(attributes) {
      return keyBy(attributes, (o) => String(findIndex(attributes, o)));
    }

    function ids(attributes) {
      return map(attributes, 'id');
    }

    function omitLocation(location) {
      const newLocation = ['studioName'];
      const existingLocation = ['name', 'studioName', 'latitude', 'longitude', 'tier'];

      const omitFields = location.id ? existingLocation : newLocation;

      return omit(location, omitFields);
    }

    const availability = {};
    mapKeys(profile.availability, (value, key) => {
      availability[key] =
        map(value, (time) => new moment.tz(time, 'HH:mm', getTimeZone(user.timeZone)).utc().format('HH:mm'));
    });

    const instructor = {
      ...profile,
      activityIds:                           ids(activities),
      availability,
      instructorCertificationIds:            ids(certifications),
      instructorEducationsAttributes:        pickBy(format(educations), 'name'),
      instructorExperiencesAttributes:       pickBy(format(experiences), 'company'),
      instructorMembershipReasonsAttributes: format(membershipReasons),
      instructorPromptsAttributes:           format(prompts),
      instructorServiceIds:                  ids(services),
      locationsAttributes:                   locations
                                               ? format(locations.map((location) => omitLocation(location)))
                                               : []
    };

    return { instructor };
  };

  _toClient = (profile, user) => {
    const availability = {};
    mapKeys(profile.availability, (value, key) => {
      availability[key] =
        map(value, (time) => new moment.utc(time, 'HH:mm').tz(getTimeZone(user.timeZone)).format('HH:mm'));
    });

    return {
      ...profile,
      availability
    };
  };
}

export default new ProfileFormatter;
