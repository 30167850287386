import { PurchasedClassesActionTypes } from '../constants/actionTypes';

const initialState = {
  form:           {},
  instructorData: [],
  lastAction:     '',
  userData:       []
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case PurchasedClassesActionTypes.INSTRUCTORS_FETCH:
      return newState;

    case PurchasedClassesActionTypes.INSTRUCTORS_FETCH_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case PurchasedClassesActionTypes.INSTRUCTORS_FETCH_FAILURE:
      return newState;


    case PurchasedClassesActionTypes.USERS_GET:
      return newState;

    case PurchasedClassesActionTypes.USERS_GET_SUCCESS:
      return { ...newState, form: action.payload };

    case PurchasedClassesActionTypes.USERS_GET_FAILURE:
      return { ...newState, form: initialState.form };


    case PurchasedClassesActionTypes.USER_FETCH:
      return newState;

    case PurchasedClassesActionTypes.USER_FETCH_SUCCESS:
      return { ...newState, userData: action.payload };

    case PurchasedClassesActionTypes.USER_FETCH_FAILURE:
      return newState;

    default: return state;
  }
};
