import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresAuth } from '../helpers';
import { sidebarItems } from '../constants';
import { SchedulePage } from '../components/pages';
import {
  BookingsActions,
  ClassPacksActions,
  DiscountActions,
  GroupClassesActions,
  PrivateBookingsActions,
  PrivateGroupClassesActions,
  PrivateSessionsActions,
  SubscriptionsActions
} from '../actions';

class InstructorScheduleContainer extends React.PureComponent {
  static propTypes = {
    fetchBookings:            PropTypes.func.isRequired,
    fetchClassPacks:          PropTypes.func.isRequired,
    fetchGroupClasses:        PropTypes.func.isRequired,
    fetchPrivateBookings:     PropTypes.func.isRequired,
    fetchPrivateGroupClasses: PropTypes.func.isRequired,
    fetchPrivateSessions:     PropTypes.func.isRequired,
    fetchSubscriptions:       PropTypes.func.isRequired,
    user:                     PropTypes.object.isRequired
  }

  componentDidMount() {
    this.props.fetchBookings();
    this.props.fetchPrivateBookings();
    this.props.fetchGroupClasses();
    this.props.fetchPrivateGroupClasses();
    this.props.fetchPrivateSessions();
    this.props.fetchClassPacks();
    this.props.fetchSubscriptions();
  }

  render() {
    return (
      <SchedulePage
          {...this.props}
          subject={sidebarItems.INSTRUCTOR_CLASS_PLANNER} />
    );
  }
}

const mapStateToProps = (state) => ({
  bookings:            state.bookings.data,
  classPacks:          state.classPacks.userData,
  discount:            state.discount.data,
  groupClasses:        state.groupClasses.data,
  privateBookings:     state.privateBookings.data,
  privateGroupClasses: state.privateGroupClasses.instructorData,
  privateSessions:     state.privateSessions.data,
  subscriptions:       state.subscriptions.data,
  user:                state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createBooking:            (data) => dispatch(BookingsActions.CREATE(data)),
  createDiscount:           (data) => dispatch(DiscountActions.CREATE(data)),
  createPrivateBooking:     (data) => dispatch(PrivateBookingsActions.CREATE(data)),
  deleteBooking:            (data) => dispatch(BookingsActions.DELETE(data)),
  deletePrivateBooking:     (data) => dispatch(PrivateBookingsActions.DELETE(data)),
  deletePrivateSession:     (data) => dispatch(PrivateSessionsActions.DELETE(data)),
  fetchBookings:            () =>     dispatch(BookingsActions.FETCH()),
  fetchClassPacks:          ()      => dispatch(ClassPacksActions.USERS_FETCH()),
  fetchGroupClasses:        () =>     dispatch(GroupClassesActions.INSTRUCTORS_FETCH()),
  fetchPrivateBookings:     (data) => dispatch(PrivateBookingsActions.FETCH(data)),
  fetchPrivateGroupClasses: () =>     dispatch(PrivateGroupClassesActions.INSTRUCTORS_FETCH()),
  fetchPrivateSessions:     () =>     dispatch(PrivateSessionsActions.FETCH()),
  fetchSubscriptions:       ()      => dispatch(SubscriptionsActions.FETCH()),
  restoreDiscount:          ()      => dispatch(DiscountActions.RESTORE())
});

export default compose(
  requiresAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(InstructorScheduleContainer);
