import React from 'react';
import { ByStripe } from '../../images';

class StripeBadge extends React.PureComponent {
  render() {
    return (
      <a
          className='by-stripe'
          href='https://stripe.com'
          rel='noopener noreferrer'
          target='_blank'>
        <img
            alt='Powered by Stripe'
            className='img-fluid'
            src={ByStripe} />
      </a>
    );
  }
}

export default StripeBadge;
