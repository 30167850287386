import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';

class PanelImage extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    id:       PropTypes.string,
    imageSrc: PropTypes.string
  }

  static defaultProps = {
    id:       '',
    imageSrc: ''
  }

  render () {
    const { children, imageSrc, id } = this.props;

    return (
      <Card
          className='card-panel-image'
          id={id}>
        <CardBody>
          {children}
        </CardBody>
        <div
            className='card-image-bg'
            style={{ backgroundImage: `url(${imageSrc})` }} />
      </Card>
    );
  }
}

export default PanelImage;