/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Cookies, withCookies } from 'react-cookie';

class OAuthWindow extends React.PureComponent {
  static propTypes = {
    children:  PropTypes.node.isRequired,
    className: PropTypes.string,
    cookies:   PropTypes.instanceOf(Cookies).isRequired,
    height:    PropTypes.number,
    onSuccess: PropTypes.func,
    title:     PropTypes.string,
    url:       PropTypes.string.isRequired,
    width:     PropTypes.number
  };

  static defaultProps = {
    className: '',
    height:    560,
    onClose:   () => {},
    onSuccess: () => {},
    title:     'OAuth Window',
    width:     500
  };

  externalWindow;
  animationFrameId;

  handleCreateWindow = () => {
    const { url, title, width, height } = this.props;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;

    this.externalWindow = window.open(
      '',
      title,
      `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes,location=yes,status=yes`
    );

    if (!this.externalWindow) return;
    this.externalWindow.location.assign(url);
    this.animationFrameId = requestAnimationFrame(this.tick);
  };

  handleSuccess = () => {
    this.props.cookies.remove('oauth', {path: '/' });
    setTimeout(() => this.props.onSuccess(), 600);
  }

  tick = () => {
    const { cookies } = this.props;

    try {
      const oauth = cookies.get('oauth');

      if (oauth === 'success') {
        this.handleSuccess();
      }
    } catch (e) {
      console.log(e.toString());
    }
    finally {
      if (!this.externalWindow.closed) {
        requestAnimationFrame(this.tick);
      }
    }
  };

  render () {
    return (
      <div
          className={classNames('oauth-wrapper', this.props.className)}
          onClick={this.handleCreateWindow}
          onKeyPress={this.handleCreateWindow}
          role='button'
          tabIndex={0}>
        {this.props.children}
      </div>
    );
  }
}

export default withCookies(OAuthWindow);
