import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router';

import { onDemandRoutes } from '../../lib';
import Layout from '../../components/layout';
import { Collection, CollectionsList } from './pages';

class OnDemandsPage extends PureComponent {
  static propTypes = {
    match: PropTypes.object.isRequired
  }

  render () {
    const { match } = this.props;

    const combineRoute = (route) => {
      return match.path + route;
    };

    return (
      <Switch>
        <Route
            exact
            path={combineRoute(onDemandRoutes.INDEX)}>
          <Layout content={<CollectionsList {...this.props} />} />
        </Route>

        <Route
            path={combineRoute(onDemandRoutes.COLLECTION(':id'))}>
          <Layout content={<Collection {...this.props} />} />
        </Route>
      </Switch>
    );
  }
}

export default withRouter(OnDemandsPage);
