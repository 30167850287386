import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { api, toCamelCase } from '../lib';
import { AvailabilityActionTypes } from '../constants/actionTypes';
import { AvailabilityActions } from '../actions';

function* watchGet() {
  yield takeLatest(AvailabilityActionTypes.GET, function* ({ payload }) {
    try {
      const instructor = yield select((state) => state.instructor.data);
      const date = payload.selectedDate.format('DD/MM/YYYY');
      const { privateSessionTypeId } = payload;

      const params = { date, privateSessionTypeId, username: instructor.username };
      const response = yield call(api.instructor.availability.get, params);

      yield put(AvailabilityActions.GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(AvailabilityActions.GET_FAILURE());
    }
  });
}

export default function* availabilitySaga() {
  yield all([
    watchGet()
  ]);
}
