/* eslint-disable react/jsx-props-no-spreading, immutable/no-let, immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

const ValueContainer = ({ children, ...rest }) => {
  const selectedCount = rest.getValue().length;
  const conditional = (selectedCount < rest.selectProps.truncate);

  let firstChild = [];
  if (!conditional) {
    firstChild = [children[0].shift(), children[1]];
  }

  return (
    <components.ValueContainer {...rest}>
      {conditional ? children : firstChild}
      {!conditional && ` + ${selectedCount - 1}`}
    </components.ValueContainer>
  );
};

ValueContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export default ValueContainer;
