import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Row
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Autocompleter } from '../../../forms';
import { Input } from '../../../inputs';
import { SEARCH_TYPES } from '../../../../constants';
import { Persistor } from '../../../../lib';
import { AutocompleterContainer } from '../../../../containers';

class SearchBar extends React.PureComponent {
  static propTypes = {
    performSearch: PropTypes.func.isRequired,
    search:        PropTypes.object.isRequired
  }

  persistor = new Persistor();

  state = {
    isMenuOpen: false,
    isOpen:     false,

    search: {
      location: this.props.search.location || '',
      model:    this.props.search.model || SEARCH_TYPES[0],
      query:    this.props.search.query || ''
    }
  }

  componentWillUnmount() {
    const { location, query } = this.state.search;

    if (location) this.persistor._save('lastLocation', location);
    if (query)    this.persistor._save('lastQuery',    query);
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [inputName]: value
      }
    }));
  }

  handleChangeSearchType = (model) => () => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        model
      }
    }));
  }

  handleSelect = (option) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        location: option.city
      }
    }));
  }

  handleSearch = (e) => {
    e.preventDefault();

    this.props.performSearch(this.state);
  }

  handleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  }

  render() {
    const icon = this.state.search.model === 'instructor' ? 'user'  : 'building';
    const placeholder = this.state.search.model === 'instructor'
      ? 'Find instructor by name or class type'
      : 'Find studio by name or class type';
    return (
      <div className='search-bar search-bar-header p-3 p-lg-1'>
        <form
            action="#"
            onSubmit={this.handleSearch}>
          <Row className='justify-content-between'>
            <Col
                className='search-selector'
                lg={2}>
              <Dropdown
                  className='h-100'
                  isOpen={this.state.isOpen}
                  /* eslint-disable-next-line react/jsx-handler-names */
                  toggle={this.handleDropdown}>
                <DropdownToggle
                    caret
                    className='rounded-xl btn-block h-100'
                    color='default'>
                  <FontAwesomeIcon
                      className='mr-2'
                      fixedWidth
                      icon={icon} />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                      active={this.state.search.model === SEARCH_TYPES[0]}
                      onClick={this.handleChangeSearchType(SEARCH_TYPES[0])}>
                    <FontAwesomeIcon
                        className='mr-2'
                        fixedWidth
                        icon='user' />
                    Instructor
                  </DropdownItem>
                  <DropdownItem
                      active={this.state.search.model === SEARCH_TYPES[1]}
                      onClick={this.handleChangeSearchType(SEARCH_TYPES[1])}>
                    <FontAwesomeIcon
                        className='mr-2'
                        fixedWidth
                        icon='building' />
                    Studio
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
            <FormGroup className='col-lg-5 d-flex align-items-center pl-lg-0 mb-0 mt-2 mt-lg-0'>
              <Input
                  className='border-0 shadow-0 pl-lg-0'
                  name='query'
                  onChange={this.handleChange}
                  placeholder={placeholder}
                  type='text'
                  value={this.state.search.query} />
            </FormGroup>
            <FormGroup className='col-lg-3 d-flex align-items-center no-divider mt-1 mt-lg-0 mb-2 mb-lg-0'>
              <div className='input-label-absolute input-label-absolute-left w-100'>
                <FontAwesomeIcon
                    className='label-absolute'
                    icon='map-marker-alt' />
                <AutocompleterContainer>
                  <Autocompleter
                      className='h-100'
                      components={{ DropdownIndicator: null }}
                      isBordered={false}
                      isPersisted
                      name='location'
                      onChange={this.handleChange}
                      onSelect={this.handleSelect}
                      placeholder='City, neighborhood'
                      subject='geocodeds'
                      value={this.state.search.location}
                      wrapperClassName='form-control form-control-md border-0 shadow-0' />
                </AutocompleterContainer>
              </div>
            </FormGroup>
            <Col
                className='search-btn'
                lg='2'>
              <Button
                  block
                  className='rounded-xl'
                  color='primary'
                  type='submit'>
                <FontAwesomeIcon icon='search' />
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

export default SearchBar;
