export default {
  BIG_STONE:     '#112233',
  BLACK:         '#000000',
  CONCRETE:      '#f2f2f2',
  DEFAULT:       '#EDF1F4',
  DINGLEY:       '#5e7d46',
  FRENCH_PASS:   '#bcdcff',
  GREEN:         '#00ff00',
  RANGOON_GREEN: '#080705',
  SUNSET_ORANGE: '#F85B44',
  WHITE:         '#ffffff'
};
