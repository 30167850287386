import { slice } from 'lodash';

import { IRREGULAR_NOUNS } from '../constants';

export default (plural) => {
  /* eslint-disable-next-line immutable/no-let */
  let singular;

  IRREGULAR_NOUNS.forEach((wordGroup) => {
    if (wordGroup[0] == plural) singular = wordGroup[1];
  });

  if (!singular) {
    return slice(plural, 0, plural.length - 1).join('');
  } else {
    return singular;
  }
};
