import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Row } from 'reactstrap';
import DateTime from 'react-datetime';

import { Moment } from '../../lib';
import { Input } from '../inputs';

class MonthRangePicker extends React.PureComponent {
  static propTypes = {
    formatDate: PropTypes.string,
    isInvalid:  PropTypes.shape({
      from: PropTypes.string,
      to:   PropTypes.string
    }),
    isRanged: PropTypes.bool,
    name:     PropTypes.shape({
      from: PropTypes.string,
      to:   PropTypes.string
    }),
    onChange:    PropTypes.func,
    placeholder: PropTypes.shape({
      from: PropTypes.string,
      to:   PropTypes.string
    }),
    value: PropTypes.shape({
      from: PropTypes.string,
      to:   PropTypes.string
    })
  }

  static defaultProps = {
    formatDate:  'MMM YYYY',
    isInvalid:   { from: false, to: false },
    isRanged:    true,
    name:        { from: 'from', to: 'to'},
    onChange:    () => {},
    placeholder: { from: 'From', to: 'To' },
    value:       { from: '', to: '' }
  }

  state = {
    viewMode: 'months'
  }

  handleClear = (e) => {
    if (e.keyCode === 8) {
      this.props.onChange('', e.target.name);
    }
  }

  renderFromInput = (props) => {
    return (
      <React.Fragment>
        <Input
            {...props}
            onKeyDown={this.handleClear} />
        <FormFeedback tooltip>{this.props.isInvalid.from}</FormFeedback>
      </React.Fragment>
    );
  }

  renderToInput = (props) => {
    return (
      <React.Fragment>
        <Input
            {...props}
            onKeyDown={this.handleClear} />
        <FormFeedback tooltip>{this.props.isInvalid.to}</FormFeedback>
      </React.Fragment>
    );
  }
  
  handleChange = (name) => (value) => {
    const formattedValue = value.isValid() ? value.format('YYYY-MM-DD') : '';

    this.props.onChange(formattedValue, name);
  }

  render () {
    const { isRanged, formatDate, value, name, placeholder, isInvalid } = this.props;
    const { viewMode } = this.state;

    const formattedValue = (value) => {
      const moment = new Moment(value);

      return moment.isValid() ? moment.format(formatDate) : '';
    };

    const inputFromProps = { 
      autoComplete: 'off', 
      isInvalid:    !!isInvalid.from,
      name:         name.from, 
      placeholder:  placeholder.from 
    };

    const inputToProps = { 
      autoComplete: 'off', 
      isDisabled:   !isRanged,
      isInvalid:    !!isInvalid.to,
      name:         name.to, 
      placeholder:  placeholder.to
    };

    const isValidDate = (current) => {
      const pastDate = new Moment(value.from, 'YYYY-MM-DD');

      return current.isAfter(pastDate);
    };

    return (
      <Row
          className='align-items-center'
          form>
        <Col>
          <FormGroup>
            <DateTime
                closeOnSelect
                closeOnTab
                dateFormat={formatDate}
                inputProps={inputFromProps}
                onChange={this.handleChange(name.from)}
                renderInput={this.renderFromInput}
                timeFormat={false}
                value={formattedValue(value.from)}
                viewMode={viewMode} />
          </FormGroup>
        </Col>
        <span
            className='text-muted'
            style={{ marginBottom: 8}}>
          -
        </span>
        <Col>
          <FormGroup>
            <DateTime
                closeOnSelect
                closeOnTab
                dateFormat={formatDate}
                inputProps={inputToProps}
                isValidDate={isValidDate}
                onChange={this.handleChange(name.to)}
                renderInput={this.renderToInput}
                timeFormat={false}
                value={formattedValue(value.to)}
                viewMode={viewMode} />
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export default MonthRangePicker;