/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

class Image extends React.PureComponent {
  static propTypes = {
    dimensions: PropTypes.shape({
      height: PropTypes.number,
      width:  PropTypes.number
    }).isRequired,

    onRef: PropTypes.func.isRequired,
    src:   PropTypes.string.isRequired
  }

  handleRef = (image) => {
    this.props.onRef(image);
  }

  render() {
    return (
      <Draggable>
        <image
            alt=''
            height={this.props.dimensions.height}
            id='#story-maker-image'
            ref={this.handleRef}
            width={this.props.dimensions.width}
            xlinkHref={this.props.src} />
      </Draggable>
    );
  }
}

export default Image;


