import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../helpers';
import { CouponsPage } from '../components/pages';
import { CouponsActions, MembershipsCouponsActions } from '../actions';

class CouponsContainer extends React.PureComponent {
  static propTypes = {
    fetchCoupons:           PropTypes.func.isRequired,
    fetchMemberhipsCoupons: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.fetchCoupons();
    this.props.fetchMemberhipsCoupons();
  }

  render() {
    return (
      <CouponsPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  coupons:            state.coupons.data,
  membershipsCoupons: state.membershipsCoupons.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchCoupons:           ()     => dispatch(CouponsActions.FETCH()),
  fetchMemberhipsCoupons: ()     => dispatch(MembershipsCouponsActions.FETCH()),
  getCoupon:              (data) => dispatch(CouponsActions.GET(data)),
  getMembershipCoupon:    (data) => dispatch(MembershipsCouponsActions.GET(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(CouponsContainer);
