import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { templates } from '../../../../constants';
import { Loader } from '../../../../components/loader';

import PackagesDefault from './default';
import PackagesUrban from './urban';
import PackagesElegant from './elegant';

class PackagesContainer extends React.PureComponent {
  static propTypes = {
    instructor:   PropTypes.object.isRequired,
    siteTemplate: PropTypes.string.isRequired
  }

  renderTemplate = () => {
    const { siteTemplate } = this.props;

    switch (siteTemplate) {
      case templates.ELEGANT: return <PackagesElegant {...this.props} />;

      case templates.URBAN: return <PackagesUrban {...this.props} />;

      default: return <PackagesDefault {...this.props} />;
    }
  }

  render() {
    if (!this.props.instructor.id) return <Loader isLoading />;


    return (
      <div className='inner-wrapper instructor-profile'>
        {this.renderTemplate()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  instructor: state.instructor.data
});

export default connect(mapStateToProps)(PackagesContainer);
