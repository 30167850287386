import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Button } from '../forms';

class BookingTermsModal extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired,
    isOpen:     PropTypes.bool,
    onToggle:   PropTypes.func
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  render() {
    const { instructor } = this.props;

    return (
      <Modal
          className='modal-terms'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          Waiver & Release
        </ModalHeader>

        <ModalBody>
          <ol>
            <li>
              I am voluntarily participating in a class or classes provided by <strong>{instructor.name}</strong>. I
              will be receiving instruction and information concerning fitness and wellness techniques, which may
              include weight training and other physical activities. I represent and warrant that I have no physical or
              mental health condition that would prevent my safe participation in these classes. I agree that if I am
              pregnant, or have a known cardiac arrhythmia (including very slow heart rate), a history of heart block,
              or if I am taking antipsychotic medications that may result in an adverse reaction in connection with
              physical activities, I will consult with and obtain the permission of a physician prior to engaging in
              any weight training or other physical activities in connection with these classes.
            </li>

            <li>
              I am willingly and voluntarily assuming any risks, injuries or damages, known and unknown, which I might
              incur as a result of participating in these classes, and agree that neither onPodio
              nor <strong>{instructor.name}</strong> will have any liability for such injuries or damages, to the
              maximum extent allowed by applicable law.
            </li>

            <li>
              I acknowledge and agree that onPodio and <strong>{instructor.name}</strong> are not medical professionals
              and do not provide any medical diagnoses or treatments. I agree that if I have any medical condition, I
              will seek the help of a medical professional.
            </li>

            <li>
              To the maximum extent permitted by applicable law, I hereby (a) waive and release any claims, known or
              unknown, I may have against <strong>{instructor.name}</strong> and onPodio, including its instructors,
              officers, directors and employees and agents, arising from or in connection with the services provided
              by <strong>{instructor.name}</strong> (“Claims”), (b) agree to indemnify and
              hold harmless <strong>{instructor.name}</strong> and onPodio, including its instructors, officers, 
              directors and employees and agents, from and against any and all Claims.
            </li>

            <li>
              I expressly waive all rights afforded by any statute which limits the effect of a release with respect
              to unknown claims.
            </li>
          </ol>
        </ModalBody>

        <ModalFooter>
          <Button
              isBlock
              onClick={this.props.onToggle}
              size='lg'>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default BookingTermsModal;
