import React from 'react';
import { isMobile } from 'react-device-detect';

import PropTypes from 'prop-types';

import { ProfileBadge } from '../badges';

class Locations extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired
  }

  render () {
    const { instructor } = this.props;

    return (
      <div className='instructor-locations'>
        { isMobile &&
          <div className='heading'>
            <span>Locations</span>
          </div>
        }
        <ul>
          { instructor.locations.map((location) => (
            <li key={location.id}>
              <ProfileBadge
                  title={location.name}
                  type='location' />
            </li>
          ))
          }
        </ul>
      </div>
    );
  }
}

export default Locations;
