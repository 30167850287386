import { connect } from 'react-redux';

import { MembershipsActions, SamplesActions } from '../../actions';
import { MembershipModal } from '../../components/modals/instructor';

const mapStateToProps = (state) => ({
  isLoading:  state.loader.memberships.isLoading,
  membership: state.memberships.form,
  user:       state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  cancelMembership: (data) => dispatch(MembershipsActions.CANCEL(data)),
  createMembership: (data) => dispatch(MembershipsActions.CREATE(data)),
  deleteSample:     (data) => dispatch(SamplesActions.DELETE_MEMBERSHIP(data)),
  updateMembership: (data) => dispatch(MembershipsActions.UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipModal);
