import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { eq, filter, find, includes, map, noop, reject, uniq } from 'lodash';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Select } from '../forms';
import { MembershipsActions } from '../../actions';

class MembershipsSelect extends React.PureComponent {
  static propTypes = {
    fetchMemberships: PropTypes.func.isRequired,
    for:              PropTypes.string.isRequired,
    id:               PropTypes.string,
    isDisabled:       PropTypes.bool.isRequired,
    isLoading:        PropTypes.bool.isRequired,
    isUpdate:         PropTypes.bool.isRequired,
    memberships:      PropTypes.array.isRequired,
    name:             PropTypes.string,
    onChange:         PropTypes.func.isRequired,
    selectedIds:      PropTypes.array
  }

  static defaultProps = {
    id:          '',
    name:        '',
    options:     [],
    selectedIds: []
  }

  static getDerivedStateFromProps(props, state) {
    const memberships = filter(props.memberships, (membership) => (
      eq(membership.premium[props.for], 'true')
    ));

    const allOptions = map(memberships, (membership) => (
      { label: membership.name, value: membership.id }
    ));

    const options = reject(allOptions, (option) => (
      includes(state.selectedIds, option.value)
    ));

    return { memberships, options };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading != this.props.isLoading && !this.props.isLoading) {
      this._updateSelectedIds();
    }
  }

  componentDidMount() {
    this.props.fetchMemberships();
    this._updateSelectedIds();
  }

  _updateSelectedIds = () => {
    const selectedIds = this.props.isUpdate
        ? this.props.selectedIds
        : map(this.state.memberships, 'id');

    this.setState({ selectedIds }, () => (
      this.props.onChange(map(this.state.options, 'value'), this.props.name)
    ));
  }

  state = {
    memberships: [],
    options:     [],
    selectedIds: []
  }

  handleSelect = (membershipId) => (
    this.setState((prevState) => ({
      selectedIds: uniq([...prevState.selectedIds, membershipId])
    }), () => (
      this.props.onChange(map(this.state.options, 'value'), this.props.name)
    ))
  )

  renderSelectedOption = (membershipId) => {
    const membership = find(this.props.memberships, ['id', membershipId]);

    if (!membership) return;

    const handleRemove = (membershipId) => () => (
      this.setState((prevState) => ({
        selectedIds: reject(prevState.selectedIds, (id) => eq(id, membershipId))
      }), () => (
        this.props.onChange(map(this.state.options, 'value'), this.props.name)
      ))
    );

    const { isDisabled } = this.props;

    return (
      <Row key={membershipId}>
        <Col
            md={1}
            xs={1}>
          <Icon
              className={classNames({ 'pointer': !isDisabled })}
              color={isDisabled ? 'gray' : 'red'}
              fixedWidth
              icon={['fas', 'times']}
              onClick={isDisabled ? noop : handleRemove(membershipId)} />
        </Col>

        <Col
            md={11}
            xs={11}>
          {membership.name}
        </Col>
      </Row>
    );
  }


  render() {
    const { id, isDisabled, isLoading, name } = this.props;
    const { options, selectedIds } = this.state;

    return (
      <React.Fragment>
        <div className='d-flex justify-content-between'>
          <Select
              className='w-100'
              id={id}
              isDisabled={isDisabled}
              isLoading={isLoading}
              name={name}
              onChange={this.handleSelect}
              options={options}
              placeholder='Add membership' />
        </div>

        <div className='packages-pro selected-ids mt-2'>
          {map(selectedIds, this.renderSelectedOption)}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading:   state.loader.memberships.isLoading,
  memberships: state.memberships.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchMemberships: () => dispatch(MembershipsActions.FETCH())
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipsSelect);
