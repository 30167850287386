import { connect } from 'react-redux';

import { MarketingPage } from '../components/pages';
import { EmailSettingsActions, EmailTemplatesActions } from '../actions';

const mapStateToProps = (state) => ({
  emailSettings:  state.emailSettings.data,
  emailTemplates: state.emailTemplates.data,

  isLoading: {
    emailSettings:  state.loader.emailSettings.isLoading,
    emailTemplates: state.loader.emailTemplates.isLoading
  }
});

const mapDispatchToProps = (dispatch) => ({
  createEmailTemplate: (data) => dispatch(EmailTemplatesActions.CREATE(data)),
  fetchEmailTemplates: ()     => dispatch(EmailTemplatesActions.FETCH()),
  getEmailSettings:    ()     => dispatch(EmailSettingsActions.GET()),
  updateEmailSettings: (data) => dispatch(EmailSettingsActions.UPDATE(data)),
  updateEmailTemplate: (data) => dispatch(EmailTemplatesActions.UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MarketingPage);


