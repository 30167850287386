import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';

import { BackButton } from '../../../components/buttons';
import { Button } from '../../../components/forms';
import { Heading, Panel } from '../../../components/layout/pages';
import { tutorialLinks } from '../../../constants';
import { routes } from '../../../lib';
import { Collections, NewCollection, StorageLimit } from '../../../components/onDemands';
import { requiresProfile } from '../../../helpers';

class CollectionsList extends PureComponent {
  static propTypes = {
    collections:         PropTypes.array.isRequired,
    createCollection:    PropTypes.func.isRequired,
    fetchCollections:    PropTypes.func.isRequired,
    getSubscription:     PropTypes.func.isRequired,
    isCollectionLoading: PropTypes.bool,
    subscription:        PropTypes.object.isRequired,
    updateCollection:    PropTypes.func.isRequired
  }

  static defaultProps = {
    isCollectionLoading: false
  }

  static getDerivedStateFromProps(props) {
    const { collections } = props;

    return {
      collections: collections
    };
  }

  componentDidMount () {
    this.props.getSubscription();
    this.props.fetchCollections();
  }

  state = {
    collections:     [],
    isNewRecordOpen: false
  }

  handleToggle = () => {
    this.setState({
      isNewRecordOpen: !this.state.isNewRecordOpen
    });
  }

  handleSubmit = (collection) => {
    this.props.createCollection({ collection });
  }

  handleUpdate = (collection) => {
    this.props.updateCollection(collection);
  }

  renderEmpty = () => (
    <div className='message-empty'>
      <p>You have not yet created any collections. </p>

      <p>
        You can add a collection by using
        the <span className='text-primary'>+ New Collection</span> button
      </p>
    </div>
  )

  render () {
    const { isNewRecordOpen, collections } = this.state;

    return (
      <div className='inner-wrapper portal'>
        <Helmet>
          <title>On Demand Library</title>
        </Helmet>

        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Container fluid>
          <Heading
              color='secondary'
              hasTutorial
              link={tutorialLinks.ON_DEMAND}
              subtitle='Creator Portal'
              title='On Demand Library' />

          <Panel isLoading={this.props.isCollectionLoading}>
            <div className='panel-content'>
              <StorageLimit />
              <div className='collection-header'>
                <span className='menu-title mt-0 pl-2'>Collections</span>

                <Button
                    color='light'
                    onClick={this.handleToggle}
                    size='sm'>
                  + New collection
                </Button>
              </div>

              <div className='collections-table-wrapper'>
                {!collections.length && this.renderEmpty()}
                {!!collections.length &&
                  <Collections
                      collections={collections}
                      onUpdate={this.handleUpdate}
                      updateCollection={this.props.updateCollection} />
                }
              </div>
            </div>
          </Panel>
        </Container>

        <NewCollection
            isOpen={isNewRecordOpen}
            onSubmit={this.handleSubmit}
            onToggle={this.handleToggle}
            subscription={this.props.subscription} />
      </div>
    );
  }
}

export default requiresProfile(CollectionsList);
