import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { WidgetsPage } from '../components/pages';


class WidgetsContainer extends React.PureComponent {
  render() {
    return (
      <WidgetsPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  profile: state.profile.data
});

export default connect(mapStateToProps, null)(withRouter(WidgetsContainer));
