import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  ClassPacksActions,
  ClientsActions,
  CouponsActions,
  MembershipsActions,
  PasswordActions,
  SubscriptionsActions,
  TransactionsActions
} from '../../actions';

import { requiresAuth } from '../../helpers';
import ClientsPage from './clientsPage';

const mapStateToProps = (state) => ({
  classPacks:   state.transactions.classPacks,
  client:       state.clients.form,
  clients:      state.clients.data,
  coupons:      state.coupons.data,
  isLoading:    state.transactions.isLoading,
  profile:      state.profile.data,
  transactions: state.transactions.сlientPurchases,
  user:         state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  cancelSubscription: (data) => dispatch(SubscriptionsActions.INSTRUCTOR_DELETE(data)),
  fetchClients:       ()     => dispatch(ClientsActions.FETCH()),
  fetchCoupons:       () =>     dispatch(CouponsActions.FETCH()),
  fetchMemberships:   ()     => dispatch(MembershipsActions.FETCH()),
  fetchPurchases:     (data) => dispatch(TransactionsActions.CLIENT_PURCHASES_FETCH(data)),
  getClient:          (data) => dispatch(ClientsActions.GET(data)),
  recoverPassword:    (data) => dispatch(PasswordActions.RECOVER(data)),
  updateClassPack:    (data) => dispatch(ClassPacksActions.USERS_UPDATE(data)),
  updatePurchase:     (data) => dispatch(TransactionsActions.PURCHASE_UPDATE(data))
});

export default compose(
  requiresAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(ClientsPage);
