import { ActivityActionTypes } from '../constants/actionTypes';

export default {
  GET_ALL: () => ({
    type: ActivityActionTypes.GET_ALL
  }),

  GET_ALL_FAILURE: () => ({
    type: ActivityActionTypes.GET_ALL_FAILURE
  }),

  GET_ALL_SUCCESS: (payload) => ({
    payload,
    type: ActivityActionTypes.GET_ALL_SUCCESS
  })
};