import { connect } from 'react-redux';
import { TransactionsActions } from '../../../../actions';

import ChallengesTable from './challengesTable';

const mapStateToProps = (state) => ({
  coupons:      state.coupons.data,
  isLoading:    state.transactions.isLoading,
  transactions: state.transactions.challenges
});

const mapDispatchToProps = (dispatch) => ({
  fetchChallenges: ()     => dispatch(TransactionsActions.CHALLENGES_FETCH()),
  updateChallenge: (data) => dispatch(TransactionsActions.CHALLENGE_UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengesTable);
