import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  DonationBookingsActions,
  DonationTargetsActions,
  InstructorActions
} from '../../../../actions';
import { templates } from '../../../../constants';
import HomeElegant from './elegant';
import HomeUrban from './urban';
import HomeDefault from './default';

class HomeContainer extends React.PureComponent {
  static propTypes = {
    fetchDonationBookings: PropTypes.func.isRequired,
    fetchDonationTarget:   PropTypes.func.isRequired,
    getInstructor:         PropTypes.func.isRequired,
    instructor:            PropTypes.object.isRequired,
    match:                 PropTypes.object.isRequired,
    siteTemplate:          PropTypes.string.isRequired,
    username:              PropTypes.string.isRequired
  }

  componentDidUpdate (prevProps) {
    const { username } = this.props;

    if (prevProps.username !== username) {
      this.props.fetchDonationTarget({ username });
      this.props.fetchDonationBookings({ username });
    }
  }

  renderTemplate = () => {
    const { siteTemplate } = this.props;

    switch (siteTemplate) {
      case templates.ELEGANT: return <HomeElegant {...this.props} />;

      case templates.URBAN: return <HomeUrban {...this.props} />;

      default: return <HomeDefault {...this.props} />;
    }
  }

  render() {
    return (
      <div className='inner-wrapper instructor-profile'>
        {this.renderTemplate()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  donationBookings: state.donationBookings.instructorData,
  donationTargets:  state.donationTargets.instructorData,
  hasCustomDomain:  state.app.hasCustomDomain,
  instructor:       state.instructor.data,
  username:         state.app.currentUsername
});

const mapDispatchToProps = (dispatch) => ({
  fetchDonationBookings: (data) => dispatch(DonationBookingsActions.INSTRUCTORS_FETCH(data)),
  fetchDonationTarget:   (data) => dispatch(DonationTargetsActions.INSTRUCTORS_FETCH(data)),
  getInstructor:         (data) => dispatch(InstructorActions.GET(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
