import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { isMobile } from 'react-device-detect';

import { Button, Form } from '../forms';
import { Input } from '../inputs';

const _defaultMessage = (instructor) => (
  'Hey, you should checkout onPodio.com.\n' +
  '\n' +
  'I just created my page which lets me create virtual classes, receive class bookings, automatically share class ' +
  'links and accept online payment or donations.\n' +
  '\n' +
  `If you sign up include my profile code in the referral field: ${instructor.username.toUpperCase()}`
);

class InvitesModal extends React.PureComponent {
  static propTypes = {
    createInvites: PropTypes.func.isRequired,
    isOpen:        PropTypes.bool.isRequired,
    onSubmit:      PropTypes.func.isRequired,
    profile:       PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    if (!props.isOpen) {
      return {
        body: _defaultMessage(props.profile)
      };
    }
  }

  state = {
    body:       _defaultMessage(this.props.profile),
    recipients: []
  }

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value });
  }

  handleDeliver = () => {
    this.props.createInvites(this.state);
    this.handleSubmit();
  }

  handleRecipient = (index) => (value) => {
    this.setState((prevState) => ({
      recipients: Object.assign([], prevState.recipients, { [index]: value })
    }));
  }

  handleSubmit = () => {
    this.props.onSubmit();
  }

  render() {
    return (
      <Modal
          className='modal-invites'
          isOpen={this.props.isOpen}
          size='lg'
          toggle={this.handleSubmit}>
        <ModalHeader toggle={this.handleSubmit}>
          Refer and earn!
          <small>Terms & Conditions in Help Center</small>
        </ModalHeader>

        <ModalBody>
          <p className='mt-3 desc'>
            <span className='text-primary'>Refer FitPros and earn $5</span> once they receive their first paid booking!
          </p>

          <Form className='form-default'>
            <FormGroup>
              <Label for='body'>Your message</Label>

              <Input
                  maxLength={500}
                  name='body'
                  onChange={this.handleChange}
                  rows='7'
                  type='textarea'
                  value={this.state.body} />
            </FormGroup>
          </Form>
          
          <Row className='mt-5'>
            <Col
                md={6}
                xs={12}>
              <Input
                  onChange={this.handleRecipient(0)}
                  placeholder='Email address'
                  value={this.state.recipients[0]} />

              <Input
                  className='mt-3'
                  onChange={this.handleRecipient(1)}
                  placeholder='Email address'
                  value={this.state.recipients[1]} />

              <Input
                  className='mt-3'
                  onChange={this.handleRecipient(2)}
                  placeholder='Email address'
                  value={this.state.recipients[2]} />
            </Col>

            <Col
                md={6}
                xs={12}>
              <Input
                  className='mt-3 mt-md-0'
                  onChange={this.handleRecipient(3)}
                  placeholder='Email address'
                  value={this.state.recipients[3]} />

              <Input
                  className='mt-3'
                  onChange={this.handleRecipient(4)}
                  placeholder='Email address'
                  value={this.state.recipients[4]} />

              <Input
                  className='mt-3'
                  onChange={this.handleRecipient(5)}
                  placeholder='Email address'
                  value={this.state.recipients[5]} />
            </Col>
          </Row>

          <div className='actions mt-5'>
            <Button
                className='mx-auto'
                color='primary'
                isBlock={isMobile}
                onClick={this.handleDeliver}
                size='lg'>
              Send email
            </Button>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default InvitesModal;
