export default {
  CLEAR:    '@@session/CLEAR',
  JOIN_NOW: '@@session/JOIN_NOW',

  LOGOUT:         '@@session/LOGOUT',
  LOGOUT_FAILURE: '@@session/LOGOUT_FAILURE',
  LOGOUT_SUCCESS: '@@session/LOGOUT_SUCCESS',

  RESTORE_SESSION:         '@@session/RESTORE',
  RESTORE_SESSION_FAILURE: '@@session/RESTORE_FAILURE',
  RESTORE_SESSION_SUCCESS: '@@session/RESTORE_SUCCESS',

  SIGN_IN:         '@@session/SIGN_IN',
  SIGN_IN_FAILURE: '@@session/SIGN_IN_FAILURE',
  SIGN_IN_SUCCESS: '@@session/SIGN_IN_SUCCESS',

  SIGN_UP:         '@@session/SIGN_UP',
  SIGN_UP_FAILURE: '@@session/SIGN_UP_FAILURE',
  SIGN_UP_SUCCESS: '@@session/SIGN_UP_SUCCESS'
};
