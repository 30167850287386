import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import { Moment, getTimeZone } from '../../lib';
import { Button } from '../forms';
import { ListCollapse } from '../lists';
import { dashboardSection } from '../../constants';

class OutcomingBookingRequests extends React.PureComponent {
  static propTypes = {
    bookingRequests:      PropTypes.array.isRequired,
    cancelBookingRequest: PropTypes.func.isRequired,
    changeSection:        PropTypes.func.isRequired,
    section:              PropTypes.string.isRequired,
    user:                 PropTypes.object.isRequired
  }

  state = {
    isOpen: false
  }

  handleCancel = (bookingRequest) => () => {
    this.props.cancelBookingRequest({ bookingRequest });
  }

  handleToggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  renderRequests = () => {
    const { bookingRequests, user } = this.props;

    return (
      bookingRequests.map((bookingRequest) => (
        <div
            className='request'
            key={bookingRequest.id}>
          <div className='user-info'>
            <span className='name'>{bookingRequest.client.name}</span>

            <span className='date'>
              {
                new Moment.utc(bookingRequest.startsAt, 'YYYY-MM-DDhh:mm:ss')
                          .tz(getTimeZone(user.timeZone)).format('ddd, MMM D YYYY')
              }
            </span>

            <span className='time'>
              {
                new Moment.utc(bookingRequest.startsAt, 'YYYY-MM-DDhh:mm:ss')
                          .tz(getTimeZone(user.timeZone)).format('h:mm A')
              } -&nbsp;
              {bookingRequest.privateSessionType.duration}m ({bookingRequest.privateSessionType.name})
            </span>
          </div>

          <div className='float-right d-flex align-items-center'>
            <Button
                color='link'
                onClick={this.handleCancel(bookingRequest)}
                size='sm'>
              Cancel
            </Button>
          </div>
        </div>
      ))
    );
  }

  renderTitle = (length) => (
    <React.Fragment>
      <span className='d-flex'>
        <span
            aria-label="donut"
            role="img" >
          &#128075;
        </span>&nbsp;
        Booking Requests
      </span>
      <small>{length} {pluralize('request', length)} outstanding</small>
    </React.Fragment>
  );



  render() {
    const { bookingRequests } = this.props;

    return (
      <React.Fragment>
        { bookingRequests.length > 0 &&
          <ListCollapse
              className='booking-requests'
              color='secondary'
              isOpen={this.props.section === dashboardSection.BOOKINGS}
              onClick={() => this.props.changeSection(dashboardSection.BOOKINGS)}
              title={this.renderTitle(bookingRequests.length)}>
            {this.renderRequests()}
          </ListCollapse>
        }
      </React.Fragment>
    );
  }
}

export default OutcomingBookingRequests;
