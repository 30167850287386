import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { FEES } from '../../../constants';

import { Button, Checkbox, Form, MaskedInput, Radio } from '../';
import { DatePicker, Input } from '../../inputs';
import { currencyMask, dayMask } from '../../../constants/masks';
import { currencies } from '../../../constants';
import { Moment, Scroller,  Validator, routes} from '../../../lib';
import { InstructorCancelChallenge, InstructorHideChallenge } from '../../alerts';

class ChallengeForm extends React.PureComponent {
  static propTypes = {
    cancelChallenge: PropTypes.func.isRequired,
    data:            PropTypes.object,
    deleteSample:    PropTypes.func.isRequired,
    isLoading:       PropTypes.bool,
    isNewRecord:     PropTypes.bool,
    onSubmit:        PropTypes.func,
    onToggle:        PropTypes.func.isRequired,
    user:            PropTypes.object.isRequired
  }

  static defaultProps = {
    data: {
      challengeFor: 'groupClass',
      cost:         '',
      description:  '',
      expireType:   'days',
      expiryDate:   '',
      expiryDays:   '',
      name:         '',

      premium: {
        live:           true,
        onDemand:       true,
        privateSession: true
      }
    },
    isLoading:   true,
    isNewRecord: true,
    onSubmit:    () => {}
  };

  static getDerivedStateFromProps(props, state) {
    const { isNewRecord, isLoading } = props;

    if (!isEmpty(state.challenge)) return state;

    if (!isLoading) {
      const challenge = isNewRecord
        ? ChallengeForm.defaultProps.data
        : {
          ...props.data,
          premium: {
            live:     props.data.premium.live           == 'true',
            onDemand: props.data.premium.onDemand       == 'true'
          }
        };

      return {
        challenge,
        isNewRecord,
        visible: props.data.visible
      };
    }

    return null;
  }

  state = {
    challenge: {},
    errors:    {}
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      challenge: {
        ...prevState.challenge,
        [inputName]: value
      }
    }));
  };

  handleToggle = (value, inputName) => {
    this.setState((prevState) => ({
      challenge: {
        ...prevState.challenge,
        premium: {
          ...prevState.challenge.premium,
          [inputName]: value
        }
      }
    }));
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { challenge } = this.state;

    const errors = Validator.validate([
      ['name',        Validator.concepts.isFilled, [challenge.name]],
      ['cost',        Validator.concepts.isFilled, [challenge.cost]],
      ['description', Validator.concepts.isFilled, [challenge.description]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  })

  handleSubmit = () => {
    const { challenge } = this.state;
    const { user } = this.props;
    const sign = currencies[user.currency].symbol;

    const expiryDate = new Moment.tz(challenge.expiryDate, 'YYYY-MM-DDhh:mm:ssUTC', user.timeZone).format();
    const cost  = parseFloat(challenge.cost.toString().replace(sign, ''));

    const data = { ...challenge, cost, expiryDate };

    return this.handleValidate().then(() => {
      this.props.onSubmit(data);
      return this.props.onToggle();
    });
  }

  handleCancel = () => {
    const deleteOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <InstructorCancelChallenge
            cancelChallenge={this.props.cancelChallenge}
            challenge={this.state.challenge}
            deleteSample={this.props.deleteSample}
            onClose={onClose}
            onSuccess={this.props.onToggle} />
    )};
    confirmAlert(deleteOptions);
  }


  handleTypeSwitch = (type) => () => (
    this.setState((prevState) => ({
      challenge: {
        ...prevState.challenge,
        challengeFor: type,
        premium:      {
          live:           type == 'groupClass',
          onDemand:       type == 'groupClass',
          privateSession: type === 'privateSession'
        }
      }
    }))
  )

  handleExpireSwitch = (value) => () => (
    this.setState((prevState) => ({
      challenge: {
        ...prevState.challenge,
        expireType: value
      }
    }))
  )

  handleHide = () => {
    const hideOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <InstructorHideChallenge
            challenge={this.state.challenge}
            isVisible={this.state.visible}
            onClose={onClose}
            onHideChallenge={this.handleSubmit}
            onSuccess={this.props.onToggle} />
    )};
    this.setState((prevState) => ({
      challenge: {...prevState.challenge, visible: !prevState.challenge.visible}
    }), () => {
      confirmAlert(hideOptions);
    });
  }


  render() {
    const { isNewRecord, user } = this.props;
    const { challenge, errors, visible } = this.state;
    const { currency } = user;

    const sign = currencies[user.currency].symbol;

    const amount = challenge.cost && parseFloat(challenge.cost.toString().replace(sign, '')) || 0;
    const fee = FEES.charge(amount);

    return (
      <Form className='form-default'>
        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Challenge Name
          </Label>
          <Input
              id='name'
              isInvalid={!!errors.name}
              name='name'
              onChange={this.handleChange}
              placeholder='Summer Challenge'
              type='text'
              value={challenge.name} />
          <FormFeedback tooltip>
            {errors.name}
          </FormFeedback>
        </FormGroup>

        <Row form>
          <Col sm={6}>
            <FormGroup>
              <Label
                  className='required'
                  for='input-cost'>
                Cost
              </Label>
              <MaskedInput
                  id='cost'
                  inputMode='number'
                  isInvalid={!!errors.cost}
                  mask={currencyMask(sign)}
                  name='cost'
                  onChange={this.handleChange}
                  placeholder={sign + 10}
                  type='number'
                  value={challenge.cost} />
              <FormFeedback tooltip>
                {errors.cost}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <small className='text-small text-muted'>
          Client pays {parseFloat(amount + fee, 10).toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
          (incl. {parseFloat(fee, 10).toLocaleString('en-GB', { currency, style: 'currency' })} fee)
        </small>

        <FormGroup className='mt-2'>
          <Label
              className='required mt-2'
              for='input-fee'>
            Add an expiration
          </Label>

          <div className='d-flex'>
            <div className='custom-control custom-radio w-50 mb-0'>
              <Radio
                  className='custom-control-input'
                  id='days'
                  isChecked={challenge.expireType == 'days'}
                  isDisabled={!isNewRecord}
                  name='days'
                  onChange={this.handleExpireSwitch('days')}
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-days'>
                # of Days
              </Label>
            </div>

            <div className='custom-control custom-radio w-50 mb-0'>
              <Radio
                  className='custom-control-input'
                  id='date'
                  isChecked={challenge.expireType == 'date'}
                  isDisabled={!isNewRecord}
                  name='date'
                  onChange={this.handleExpireSwitch('date')} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-date'>
                Set Date
              </Label>
            </div>
          </div>
        </FormGroup>

        <Row
            className='mb-2'
            form>
          <Col xs={6}>
            { challenge.expireType == 'days' &&
              <MaskedInput
                  id='expiry'
                  inputMode='number'
                  isDisabled={!isNewRecord}
                  isInvalid={!!errors.expiry}
                  mask={dayMask}
                  name='expiryDays'
                  onChange={this.handleChange}
                  placeholder=' days'
                  type='number'
                  value={String(challenge.expiryDays)} />
            }

            { challenge.expireType == 'date' &&
              <DatePicker
                  id='date'
                  isDisabled={!isNewRecord}
                  isInvalid={!!errors.expiryDate}
                  name='expiryDate'
                  onChange={this.handleChange}
                  placeholder='date'
                  value={challenge.expiryDate} />
            }
          </Col>
        </Row>

        <FormGroup>
          <Label
              className='required'
              for='input-description'>
            Challenge Description
          </Label>
          <Input
              id='description'
              isInvalid={!!errors.description}
              name='description'
              onChange={this.handleChange}
              placeholder='Buy a 5 pack for $25 (vs. $30 usual price)'
              rows='4'
              type='textarea'
              value={challenge.description} />
          <FormFeedback tooltip>
            {errors.description}
          </FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label
              className='required'
              for='input-fee'>
            Credits can be used for
          </Label>

          <div>
            <div className='custom-control custom-radio'>
              <Radio
                  className='custom-control-input'
                  id='groupClass'
                  isChecked={challenge.challengeFor === 'groupClass'}
                  name='groupClass'
                  onChange={this.handleTypeSwitch('groupClass')}
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-groupClass'>
                Group Classes
              </Label>
            </div>

            { challenge.challengeFor == 'groupClass' &&
            <React.Fragment>
              <div className='custom-control custom-checkbox checkbox-sm'>
                <Checkbox
                    className='custom-control-input'
                    id='premium-live'
                    isChecked={challenge.premium && challenge.premium.live}
                    name='live'
                    onChange={this.handleToggle} />
                <Label
                    className='custom-control-label'
                    htmlFor='input-premium-live'>
                  Live Classes
                </Label>
              </div>

              <div className='custom-control custom-checkbox checkbox-sm'>
                <Checkbox
                    className='custom-control-input'
                    id='premium-on-demand'
                    isChecked={challenge.premium && challenge.premium.onDemand}
                    name='onDemand'
                    onChange={this.handleToggle} />
                <Label
                    className='custom-control-label'
                    htmlFor='input-premium-on-demand'>
                  On Demand Classes
                </Label>
              </div>
            </React.Fragment>

            }

            <div className='custom-control custom-radio'>
              <Radio
                  className='custom-control-input'
                  id='privateSession'
                  isChecked={challenge.challengeFor === 'privateSession'}
                  name='privateSession'
                  onChange={this.handleTypeSwitch('privateSession')}
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-privateSession'>
                Private Sessions
              </Label>
            </div>
          </div>
        </FormGroup>

        <div className='text-small text-muted pb-3'>
          Check the box to allow this challenge to be used for live and / or on-demand class.
        </div>

        <div className='text-small text-muted'>
          <strong>
            If checked, this challenge will by default be able to access all of those classes.&nbsp;
          </strong>
          To limit access from an individual live class or on-demand collection you can do
          this on the class or collection settings via&nbsp;
          <Link to={routes.CALENDAR}>
            My Class Schedule
          </Link>
          &nbsp;and&nbsp;
          <Link to={routes.ON_DEMAND}>
            On-Demand
          </Link>
          &nbsp;pages respectively.
        </div>


        { !user.hasStripe &&
          <div className='text-warning text-sm'>
            Note: Your Stripe account is not connected, in order to create a Challenge connect
            your Stripe account by going to Instructor Settings &gt;&nbsp;
            <Link to={routes.INTEGRATIONS}>
              Integrations
            </Link>
          </div>
        }

        { isNewRecord && user.hasStripe && !user.stripeUpgraded &&
          <div className='text-warning text-sm'>
            Note to use the Challenge feature you will need to reauthorize your Stripe account.
            Please contact us at&nbsp;
            <a href='mailto:kal@onpodio.com'>kal@onpodio.com</a>&nbsp;
            to assist you with this.
          </div>
        }

        <Row className={isNewRecord ? 'mt-4' : 'mt-2'}>
          { isNewRecord &&
            <Col>
              <Button
                  isBlock
                  isDisabled={!user.hasStripe || (user.hasStripe && !user.stripeUpgraded)}
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Create & Add
              </Button>
            </Col>
          }

          { !isNewRecord &&
            <React.Fragment>
              <Button
                  isBlock
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Update Challenge
              </Button>

              <div className='w-100 mt-3'>
                <p className='text-small text-muted'>
                  Hide a challenge to remove the package from your website.
                </p>
                <Button
                    color='muted'
                    isBlock
                    isOutline
                    onClick={this.handleHide}
                    size='lg'
                    type='button'>
                  { visible ? 'Hide' : 'Show' }&nbsp;
                  Challenge
                </Button>
              </div>

              <div className='w-100 mt-3'>
                <p className='text-small text-danger'>
                  Cancel a challenge to remove the package from your website. This will
                  prevent new clients purchasing the pack <strong>AND WILL</strong> invalidate
                  existing unused credits held by clients.
                </p>
                <Button
                    color='danger'
                    isBlock
                    isOutline
                    onClick={this.handleCancel}
                    size='lg'
                    type='button'>
                  Cancel Challenge
                </Button>
              </div>
            </React.Fragment>
          }
        </Row>
      </Form>
    );
  }
}

export default ChallengeForm;
