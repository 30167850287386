import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { EmailSettingsActionTypes } from '../constants/actionTypes';
import { EmailSettingsActions, ErrorActions, LoaderActions } from '../actions';


function* watchGet() {
  yield takeLatest(EmailSettingsActionTypes.GET, function* () {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('emailSettings'));
      const response = yield call(api.email.setting.get);

      yield put(EmailSettingsActions.GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(EmailSettingsActions.GET_FAILURE());
    }
  });
}

function* watchGetSuccess() {
  yield takeLatest(EmailSettingsActionTypes.GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('emailSettings'));
  });
}

function* watchGetFailure() {
  yield takeLatest(EmailSettingsActionTypes.GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('emailSettings'));
  });
}


function* watchUpdate() {
  yield takeLatest(EmailSettingsActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('emailSettings'));
      const response = yield call(api.email.setting.update, payload);

      yield put(EmailSettingsActions.UPDATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Setting updated!');
    } catch (error) {
      yield put(ErrorActions.NEW(extractError(error)));
      yield put(EmailSettingsActions.UPDATE_FAILURE());
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(EmailSettingsActionTypes.UPDATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('emailSettings'));
  });
}

function* watchUpdateFailure() {
  yield takeLatest(EmailSettingsActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('emailSettings'));
  });
}

export default function* emailSettingsSaga() {
  yield all([
    watchGet(),
    watchGetSuccess(),
    watchGetFailure(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure()
  ]);
}
