import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { routes } from '../lib';
import { Loader } from '../components/loader';

export default function (ComposedComponent) {
  class Profile extends React.Component {
    static propTypes = {
      profile:  PropTypes.object.isRequired,
      redirect: PropTypes.func.isRequired,
      session:  PropTypes.object.isRequired
    }

    componentDidMount() {
      this._check();
    }

    /*shouldComponentUpdate(nextProps) {
      return nextProps.profile.id            !== this.props.profile.id ||
             nextProps.session.authenticated !== this.props.session.authenticated ||
             nextProps.session.restored      !== this.props.session.restored;
    }*/

    componentDidUpdate() {
      this._check();
    }

    _check = () => {
      if (!this.props.profile.id) {
        if (this.props.session.authenticated) {
          this.props.redirect(routes.DASHBOARD);
        } else {
          if (this.props.session.restored) this.props.redirect(routes.LOGIN);
        }
      }
    }

    render() {
      if (this.props.profile.id && this.props.session.authenticated && this.props.session.restored) {
        return <ComposedComponent {...this.props} />;
      } else {
        return <Loader isLoading />;
      }
    }
  }

  const mapStateToProps = (state) => ({
    profile: state.profile.data,
    session: state.session
  });

  const mapDispatchToProps = (dispatch) => ({
    redirect: (route) => dispatch(push(route))
  });

  return connect(mapStateToProps, mapDispatchToProps)(Profile);
}
