import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../../helpers';
import { SettingsPage } from '../../components/pages';
import {
  ActivityActions,
  DonationClassesActions,
  DonationTargetsActions,
  LocationsActions,
  PlansActions,
  PrivateSessionTypesActions,
  ProfileActions,
  ProfileDataActions
} from '../../actions';

class SettingsContainer extends React.PureComponent {
  static propTypes = {
    fetchDonationClasses: PropTypes.func.isRequired,
    fetchDonationTarget:  PropTypes.func.isRequired,
    fetchLocations:       PropTypes.func.isRequired,
    fetchSessionTypes:    PropTypes.func.isRequired,
    getActivities:        PropTypes.func.isRequired,
    getProfileData:       PropTypes.func.isRequired,
    getSubscription:      PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.fetchLocations();
    this.props.fetchSessionTypes();
    this.props.fetchDonationClasses();
    this.props.fetchDonationTarget();
    this.props.getActivities();
    this.props.getProfileData();
    this.props.getSubscription();
  }

  render() {
    return (
      <SettingsPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  subscription: state.plans.userData
});

const mapDispatchToProps = (dispatch) => ({
  createDonationTarget: (data) => dispatch(DonationTargetsActions.CREATE(data)),
  fetchDonationClasses: ()     => dispatch(DonationClassesActions.FETCH()),
  fetchDonationTarget:  ()     => dispatch(DonationTargetsActions.FETCH()),
  fetchLocations:       ()     => dispatch(LocationsActions.FETCH()),
  fetchSessionTypes:    ()     => dispatch(PrivateSessionTypesActions.FETCH()),
  getActivities:        ()     => dispatch(ActivityActions.GET_ALL()),
  getProfileData:       ()     => dispatch(ProfileDataActions.GET()),
  getSubscription:      ()     => dispatch(PlansActions.SUBSCRIPTION_GET()),
  updateDonationTarget: (data) => dispatch(DonationTargetsActions.UPDATE(data)),
  updateProfile:        (data) => dispatch(ProfileActions.UPDATE(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(SettingsContainer);
