import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { SessionTypeForm } from '../../forms/instructor';

class SessionTypeModal extends React.PureComponent {
  static propTypes = {
    createSessionType: PropTypes.func.isRequired,
    deleteSessionType: PropTypes.func.isRequired,
    isLoading:         PropTypes.bool.isRequired,
    isNewRecord:       PropTypes.bool.isRequired,
    isOpen:            PropTypes.bool.isRequired,
    onToggle:          PropTypes.func.isRequired,
    sessionType:       PropTypes.object.isRequired,
    updateSessionType: PropTypes.func.isRequired,
    user:              PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props) {
    const isNewRecord = props.isNewRecord;
    const isLoading = props.isLoading;

    return {
      isLoading:   isLoading,
      isNewRecord: isNewRecord,
      sessionType: isNewRecord ? {} : props.sessionType
    };
  }

  state = {
    isLoading:   false,
    isNewRecord: true,
    sessionType: {}
  };

  handleSubmit = (form) => {
    const params = { privateSessionType: form };

    this.state.isNewRecord ? this.props.createSessionType(params) : this.props.updateSessionType(params);
    this.props.onToggle();
  };

  handleDelete = (params) => {
    this.props.deleteSessionType(params);
    this.props.onToggle();
  };

  render () {
    const { isOpen, onToggle } = this.props;
    const { sessionType, isNewRecord, isLoading } = this.state;

    const title = `${isNewRecord ? 'Add' : 'Edit'} Private Session Type`;

    return (
      <Modal
          centered
          className='session-type-modal'
          isOpen={isOpen}
          toggle={onToggle}>
        <ModalHeader toggle={onToggle}>
          {title}
        </ModalHeader>

        <ModalBody>
          <SessionTypeForm
              data={sessionType}
              isLoading={isLoading}
              isNewRecord={isNewRecord}
              onDelete={this.handleDelete}
              onSubmit={this.handleSubmit}
              user={this.props.user} />
        </ModalBody>
      </Modal>
    );
  }
}

export default SessionTypeModal;
