import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError, toCamelCase } from '../lib';
import { ErrorActions, LoaderActions, RecordingsActions } from '../actions';
import { RecordingsActionTypes } from '../constants/actionTypes';


function* watchFetch() {
  yield takeLatest(RecordingsActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('recordings'));
      const response = yield call(api.recording.fetch);

      yield put(RecordingsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (_error) {
      yield put(RecordingsActions.FETCH_FAILURE());
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(RecordingsActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('recordings'));
  });
}

function* watchFetchFailure() {
  yield takeLatest(RecordingsActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('recordings'));
  });
}


function* watchCreate() {
  yield takeLatest(RecordingsActionTypes.CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.recording.create, payload);

      yield put(RecordingsActions.CREATE_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(RecordingsActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* recordingsSaga() {
  yield all([
    watchCreate(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure()
  ]);
}
