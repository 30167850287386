import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup, Col, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { Alert } from '../../components/errors';
import { Button, Radio } from '../forms';
import { onDemandContentTypes, onDemandUploadTypes } from '../../constants';
import { BrowseIcon, RemoveIcon } from '../icons';
import { RecordingList } from '../onDemands';
import { Uploader, Validator, api } from '../../lib';
import { Input } from './';

class OnDemandUploader extends PureComponent {
  static propTypes = {
    allowedTypes:        PropTypes.array,
    contentType:         PropTypes.string,
    createRecording:     PropTypes.func.isRequired,
    fetchRecordings:     PropTypes.func.isRequired,
    id:                  PropTypes.string,
    isInvalid:           PropTypes.bool,
    isLoading:           PropTypes.bool,
    isNewRecord:         PropTypes.bool.isRequired,
    maxFileSize:         PropTypes.string,
    name:                PropTypes.string,
    onContentTypeChange: PropTypes.func,
    onDelete:            PropTypes.func,
    onUpload:            PropTypes.func,
    onUploadTypeChange:  PropTypes.func,
    onValueChange:       PropTypes.func,
    recording:           PropTypes.object.isRequired,
    recordings:          PropTypes.array.isRequired,
    selectedUuid:        PropTypes.string,
    uploadType:          PropTypes.string,
    user:                PropTypes.object.isRequired,
    value:               PropTypes.oneOfType([PropTypes.object, PropTypes.string])
  }

  static defaultProps = {
    allowedTypes:        ['video/mp4', 'video/webm'],
    contentType:         onDemandContentTypes.FILE,
    id:                  '',
    isInvalid:           false,
    isLoading:           {},
    maxFileSize:         '1GB',
    name:                '',
    onContentTypeChange: () => {},
    onDelete:            () => {},
    onUpload:            () => {},
    onUploadTypeChange:  () => {},
    onValueChange:       () => {},
    selectedUuid:        '',
    uploadType:          onDemandUploadTypes.device,
    value:               {}
  }

  inputRef = React.createRef();
  fileRef = React.createRef();

  static getDerivedStateFromProps(props) {
    if (!isEmpty(props.value)) {
      switch (typeof props.value) {
        case 'string':
          return {
            inputName:  'url',
            inputValue: props.value
          };

        case 'object':
          return {
            fileName: props.value.name || '',
            upload:   { ...props.value, state: 'finished' }
          };

        default:
          return null;
      }
    }

    return { inputValue: props.value };
  }

  state = {
    errors:      {},
    fileName:    '',
    inputName:   '',
    inputValue:  '',
    isUploading: false,
    upload:      {}
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.state.errors !== prevState.errors) {
      Object.values(this.state.errors).map((error) => {
        toastr.error(error, { timeOut: 3000 });
      });
    }
  }

  handleChangeContentType = (type) => (e) => {
    e.preventDefault();

    this.setState({ errors: {} }, () => (
      this.props.onContentTypeChange(type)
    ));
  }

  handleChangeUploadType = (type) => () => (
    this.setState({ errors: {} }, () => this.props.onUploadTypeChange(type))
  )

  handleInputChange = (value) => {
    const { contentType } = this.props;

    if (contentType === onDemandContentTypes.URL) {
      this.props.onValueChange(value, 'url');
    }
  }

  handleBrowse = (e) => {
    e.preventDefault();

    const { contentType } = this.props;

    if (contentType === onDemandContentTypes.FILE) {
      this.fileRef.current.click();
    }
  }

  handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      return (
        this.handleValidate(file).then(() => (
          this.handleCreateUpload(file)
        )).catch((errors) => (
          this.setState({ errors })
        ))
      );
    }
  }

  handleUploaderCallback = (fileUpload) => {
    this.setState({ upload: fileUpload }, () => {
      if (fileUpload.state === 'finished') {
        this.setState({
          isUploading: false
        }, () => {
          this.props.onUpload(fileUpload, 'video');
        });
      }
    });
  }

  handleCreateUpload = (file) => {
    const upload = new Uploader(file, { onChangeFile: this.handleUploaderCallback });

    this.setState({
      fileName: file.name,
      upload:   upload
    }, this.handleStartUpload);
  }

  handleStartUpload = () => {
    this.setState({
      isUploading: true
    }, () => {
      this.handleUpload(this.state.upload);
    });
  }

  handleUpload = async (file) => {
    await file.start();
  }

  handleClearUpload = () => {
    this.props.onDelete();
    this.setState({
      fileName: '',
      upload:   {}
    });
  }

  handleDelete = (id) => (e) => {
    e.preventDefault();
    const params = { id: id };

    api.attachments.delete(params).then(() => (
      this.handleClearUpload()
    )).catch((error) => (console.log('Attachment delete error!', error)));
  }

  handleValidate = (file) => new Promise((resolve, reject) => {
    const { allowedTypes, maxFileSize } = this.props;
    const errors = Validator.validate([
      ['type', Validator.concepts.fileTypeValidator, [file, allowedTypes]],
      ['size', Validator.concepts.fileSizeValidator, [file, maxFileSize]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  renderPlaceHolder = () => {
    const { contentType } = this.props;

    switch (true) {
      case contentType === onDemandContentTypes.FILE:
        return 'Upload your video';

      default:
        return 'Add URL to Zoom, YouTube, etc.';
    }
  }

  inputValue = () => {
    const { contentType } = this.props;

    switch (true) {
      case contentType === onDemandContentTypes.FILE && this.state.isUploading:
        return this.state.upload.progress
            ? this.state.upload.progress + '%'
            : 'Starting upload (this may take 90secs)...';

      case contentType === onDemandContentTypes.FILE:
        return this.state.fileName;

      default:
        return this.state.inputValue;
    }
  }

  render () {
    const { contentType, id, isInvalid, isNewRecord, uploadType } = this.props;
    const { inputName, upload, isUploading, errors } = this.state;
    const uploadStates = Uploader.getStatuses;

    const isVideo = contentType === onDemandContentTypes.FILE;
    const isUrl   = contentType === onDemandContentTypes.URL;

    const isDevice = uploadType === onDemandUploadTypes.DEVICE;
    const isCloud  = uploadType === onDemandUploadTypes.CLOUD;

    const isUploaded = !isEmpty(upload) && upload.state === uploadStates.FINISHED;

    return (
      <Fragment>
        <input
            className='d-none'
            onChange={this.handleFileChange}
            ref={this.fileRef}
            type='file' />

        <ButtonGroup
            className='d-block mb-2'
            size='sm'>
          <Button
              isOutline={!isVideo}
              onClick={this.handleChangeContentType(onDemandContentTypes.FILE)}>
            Upload
          </Button>

          <Button
              isOutline={!isUrl}
              onClick={this.handleChangeContentType(onDemandContentTypes.URL)}>
            Add URL
          </Button>
        </ButtonGroup>

        { !isUrl && isNewRecord &&
          <>
            <Alert color='info'>
              <p className='m-0 text-center'>
                640x400px or larger is recommended<br />5000MB max size
              </p>
            </Alert>

            <Row className='mt-2'>
              <Col md='6'>
                <div className='custom-control custom-radio'>
                  <Radio
                      className='custom-control-input'
                      id='device'
                      isChecked={isDevice}
                      name='uploadType'
                      onChange={this.handleChangeUploadType(onDemandUploadTypes.DEVICE)}
                      value='device' />
                  <Label
                      className='custom-control-label'
                      for='input-device'>
                    From device
                  </Label>
                </div>
              </Col>

              <Col md='6'>
                <div className='custom-control custom-radio'>
                  <Radio
                      className='custom-control-input'
                      id='cloud'
                      isChecked={isCloud}
                      isDisabled={!this.props.user.oauth.zoom}
                      name='uploadType'
                      onChange={this.handleChangeUploadType(onDemandUploadTypes.CLOUD)}
                      value='cloud' />
                  <Label
                      className='custom-control-label'
                      for='input-cloud'>
                    From Zoom Cloud
                  </Label>
                </div>
              </Col>
            </Row>
          </>
        }

        { !isUrl && isCloud &&
          <RecordingList
              createRecording={this.props.createRecording}
              fetchRecordings={this.props.fetchRecordings}
              isInvalid={isInvalid}
              isLoading={this.props.isLoading}
              recording={this.props.recording}
              recordings={this.props.recordings}
              selectedUuid={this.props.selectedUuid} />
        }

        { !isCloud &&
          <InputGroup className={classNames('custom-video-input', { 'is-invalid': isInvalid })}>
            <Input
                autoComplete={isVideo ? 'off' : 'on'}
                className={classNames({'text-center': isVideo})}
                id={id}
                isInvalid={isInvalid || !isEmpty(errors)}
                name={inputName}
                onChange={this.handleInputChange}
                onRef={(inputRef) => this.inputRef = inputRef}
                placeholder={this.renderPlaceHolder()}
                type='text'
                value={this.inputValue()} />
            { isVideo &&
              <InputGroupAddon addonType='append'>
                { !isUploaded &&
                  <Button
                      color={isInvalid ? 'danger' : 'primary'}
                      isDisabled={isUploading}
                      isOutline
                      onClick={this.handleBrowse}>
                    <BrowseIcon size={20} />
                  </Button>
                }
                { isUploaded &&
                  <Button
                      color='danger'
                      isOutline
                      onClick={this.handleDelete(upload.signedId)}>
                    <RemoveIcon size={20} />
                  </Button>
                }
              </InputGroupAddon>
            }
          </InputGroup>
        }
      </Fragment>
    );
  }
}

export default OnDemandUploader;
