/* global process */
/* eslint-disable xss/no-mixed-html */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { Container } from 'reactstrap';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toastr } from 'react-redux-toastr';
import classNames from 'classnames';

import { Heading, Panel } from '../layout/pages';
import { Input } from '../inputs';
import { routes } from '../../lib';
import { BackButton } from '../buttons';
import { Button } from '../forms';
import { tutorialLinks } from '../../constants';

const { EMBED_URL } = process.env;

class WidgetsPage extends React.PureComponent {
  static propTypes = {
    profile: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    const { profile } =  props;
    const { username } = profile;

    const workoutsSnippet = '<iframe id="op-workout" src="' + EMBED_URL + username + '?widget=workout"' +
                            ' scrolling="no" frameborder="0" style="width: 1px; min-width: 100%"></iframe>\n' + '\n' +
                            '<script type="text/javascript" src="' + EMBED_URL + 'embed.js"></script>';

   const packagesSnippet = '<iframe id="op-packages" src="' + EMBED_URL + username + '?widget=packages"' +
                           ' scrolling="no" frameborder="0" style="width: 1px; min-width: 100%"></iframe>\n' + '\n' +
                           '<script type="text/javascript" src="' + EMBED_URL + 'embed.js"></script>';

    return {
      packagesSnippet,
      workoutsSnippet
    };
  }

  state = {
    packagesSnippet: '',
    workoutsSnippet: ''
  }

  handleCopy = () => toastr.success('Copied')

  render() {
    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Home</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container
              className='settings-page'
              fluid>
            <Heading
                color='secondary'
                hasTutorial
                link={tutorialLinks.WIDGETS}
                subtitle='Creator Portal'
                title='Add onPodio to Your Site' />
            <Panel className={classNames({ 'mr-4 ml-4': isMobile })}>

              <span className='menu-title p-0 mb-2'>How to add onPodio to an existing site?</span>

              <small className='text-muted'>
              You can add onPodio to your existing website.
              </small>

              <small className='text-muted mt-2'>
                All you need to do is copy and paste the relevant line of code anywhere on your website.
                We have two separate code snippets:
              </small>

              <small className='text-muted mt-2'>
                1. Your Workouts snippet. This will display your live class schedule and on-demand video library.
              </small>

              <small className='text-muted mt-2'>
                2. Your Packages snippet. This will display your memberships and class packages for purchase.
              </small>

              <span className='menu-title p-0 mb-2'>Workouts Snippet</span>

              <Input
                  rows='7'
                  type='textarea'
                  value={this.state.workoutsSnippet} />
              <CopyToClipboard
                  onCopy={this.handleCopy}
                  text={this.state.workoutsSnippet}>
                <Button
                    className='save-changes-btn mt-2'
                    color='success'
                    isBlock={isMobile}>
                  Copy Snippet
                </Button>
              </CopyToClipboard>

              <span className='menu-title p-0 mb-2'>Packages Snippet</span>

              <Input
                  rows='7'
                  type='textarea'
                  value={this.state.packagesSnippet} />
              <CopyToClipboard
                  onCopy={this.handleCopy}
                  text={this.state.packagesSnippet}>
                <Button
                    className='save-changes-btn mt-2'
                    color='success'
                    isBlock={isMobile}>
                  Copy Snippet
                </Button>
              </CopyToClipboard>

            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default WidgetsPage;
