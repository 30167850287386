/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  'USD': {
    'name':   'US Dollar',
    'symbol': 'US$'
  },
  'AUD': {
    'name':   'Australian Dollar',
    'symbol': 'AU$'
  },
  'CAD': {
    'name':   'Canadian Dollar',
    'symbol': 'CA$'
  },
  'EUR': {
    'name':   'Euro',
    'symbol': '€'
  },
  'GBP': {
    'name':   'British Pound Sterling',
    'symbol': '£'
  },
  'SGD': {
    'name':   'Singapore Dollar',
    'symbol': 'S$'
  },
  'INR': {
    'name':   'Indian Rupee',
    'symbol': '₹'
  },
  'CHF': {
    'name':   'Swiss Franc',
    'symbol': 'CHf'
  },
  'NZD': {
    'name':   'New Zealand Dollar',
    'symbol': 'NZ$'
  }
};
