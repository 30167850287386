import { StudioActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: StudioActionTypes.CREATE
  }),

  CREATE_CLEAN: () => ({
    type: StudioActionTypes.CREATE_CLEAN
  }),

  CREATE_FAILURE: () => ({
    type: StudioActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: StudioActionTypes.CREATE_SUCCESS
  }),

  FETCH: (payload) => ({
    payload, type: StudioActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: StudioActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: StudioActionTypes.FETCH_SUCCESS
  })
};
