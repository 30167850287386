import React from 'react';
import PropTypes from 'prop-types';
import { filter, map } from 'lodash';

import { Moment, getTimeZone } from '../../../../lib';
import { Loader } from '../../../loader';
import { DateSelect } from '../../../calendar';
import { RequestPrivateSessionModal } from '../../../modals';
import { Button } from '../../../forms';
import { TimeZone } from '../';

const SPLIT_AFTERNOON = 12;

class PrivateSessionsCarousel extends React.PureComponent {
  static propTypes = {
    availability:         PropTypes.object.isRequired,
    challenges:           PropTypes.array.isRequired,
    classPacks:           PropTypes.array.isRequired,
    createDiscount:       PropTypes.func.isRequired,
    createPrivateSession: PropTypes.func.isRequired,
    discount:             PropTypes.object,
    getAvailability:      PropTypes.func,
    instructor:           PropTypes.object.isRequired,
    subtractCredit:       PropTypes.func.isRequired,
    user:                 PropTypes.object.isRequired
  };

  static defaultProps = {
    discount:        {},
    getAvailability: () => {}
  };

  state = {
    availabilities: {},
    selectedDate:   new Moment()
  };

  handleChange = (value, name) => {
    this.setState({ [name]: value });
  };

  static getDerivedStateFromProps(props) {
    const availabilities = map(props.availability.data, (tag) => (
      new Moment.utc(tag.date, 'YYYY-MM-DDhh:mm:ss').tz(getTimeZone(props.user.timeZone))
    ));

    const morning = filter(availabilities, (tag) => (
      parseFloat(tag.format('HH')) < SPLIT_AFTERNOON
    ));

    const afternoon = filter(availabilities, (tag) => (
      parseFloat(tag.format('HH')) >= SPLIT_AFTERNOON
    ));

    return {
      availabilities: { afternoon, morning },
      isLoading:      props.availability.isLoading
    };
  }

  componentDidMount() {
    this.props.getAvailability({ selectedDate: new Moment() });
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedDate } = this.state;

    if (!this.state.isModalOpen) {
      if (this.state.selectedDate !== prevState.selectedDate) {
        this.props.getAvailability({ selectedDate });
      }
    }
  }

  handleModal = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen
    }));
  };

  renderControls = () => (
    <DateSelect
        dateFormat='dddd | MMMM DD'
        hasDaySelect
        hasPast={false}
        isOnProfile
        onChange={this.handleChange}
        selectedDate={this.state.selectedDate}
        selectedView='day'
        today={new Moment()} />
  );

  renderTags = (tags) => (
    <div className='slots'>
      {tags.map((tag) => (
        <Button
            color='blue'
            key={tag.format('h:mm')}
            onClick={this.handleModal}
            onKeyPress={this.handleModal}
            size='sm'>
          {tag.format('h:mmA')}
        </Button>
      ))}
    </div>
  );

  render() {
    const { availabilities, isLoading } = this.state;
    return (
      <div className='profile-private-sessions' >
        <TimeZone timeZone={this.props.user.timeZone} />
        <div className='private-session'>
          { this.renderControls() }
          { isLoading && <Loader isLoading /> }
          <div className='time-slots'>
            <div className='availability-block'>
              <h6 className='availability-title'>Morning</h6>

              { availabilities.morning.length
                ? this.renderTags(availabilities.morning)
                : <div className='text-muted'>Not Available</div>}
            </div>

            <div className='availability-block'>
              <h6 className='availability-title'>Afternoon</h6>

              { availabilities.afternoon.length
                ? this.renderTags(availabilities.afternoon)
                : <div className='text-muted'>Not Available</div>}
            </div>
          </div>
        </div>

        { this.state.isModalOpen &&
          <RequestPrivateSessionModal
              availabilities={availabilities}
              challenges={this.props.challenges}
              classPacks={this.props.classPacks}
              createDiscount={this.props.createDiscount}
              createPrivateSession={this.props.createPrivateSession}
              discount={this.props.discount}
              getAvailability={this.props.getAvailability}
              instructor={this.props.instructor}
              isLoading={isLoading}
              isOpen={this.state.isModalOpen}
              onDateChange={this.handleChange}
              onToggle={this.handleModal}
              selectedDate={this.state.selectedDate}
              subtractCredit={this.props.subtractCredit}
              user={this.props.user} />
        }
      </div>
    );
  }
}

export default PrivateSessionsCarousel;
