import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { androidOpen } from 'react-icons-kit/ionicons/androidOpen';

const ExternalIcon = ({ size, className }) => (
  <Icon
      className={className}
      icon={androidOpen}
      size={size} />
);

ExternalIcon.propTypes = {
  className: PropTypes.string,
  size:      PropTypes.number
};

ExternalIcon.defaultProps = {
  className: '',
  size:      32
};

export default ExternalIcon;