import { all, call, put, takeLatest } from 'redux-saga/effects';

import { OAuthActionTypes } from '../constants/actionTypes';
import { ErrorActions, OAuthActions, UserActions } from '../actions';
import { api, extractError } from '../lib';

function* watchLogout() {
  yield takeLatest(OAuthActionTypes.LOGOUT, function* ({ payload }) {
    try {
      yield call(api.oauth.destroy, payload);

      yield put(OAuthActions.LOGOUT_SUCCESS());
      yield put(UserActions.FETCH());
    } catch (error) {
      yield put(OAuthActions.LOGOUT_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* oauthSaga() {
  yield all([
    watchLogout()
  ]);
}
