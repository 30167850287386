/* eslint-disable max-len */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

class Terms extends React.PureComponent {
  render() {
    return (
      <section className='py-4'>
        <Container>
          <Row>
            <Col
                className='mx-auto'
                lg={10}
                md={12}
                xl={8}>
              <div className='text-content'>
                <h1 className='text-center'>Terms of Use</h1>
                <h6>Effective date: August 31, 2019</h6>
                <p>These onPodio Terms of Use apply when you access, use or visit the onPodio website located at <a href='https://onpodio.com'>www.onpodio.com</a> (the “Site”), and the services provided through the Site (the Site and these services constituting the “Service”).  The Service is provided to you by RhythmicX Inc. d/b/a onPodio (referred to in these Terms of Use as “onPodio” “we,” “us” and “our”). You must agree to these Terms of Use in order to use the Service. If you do not agree with these Terms of Use at any time, please cease use of the Service.</p>
                <p>Notice Regarding Dispute Resolution: These Terms of Use contain provisions that govern how claims you and we may have against each other are resolved (see Section 11 below), including an agreement and obligation to arbitrate disputes, which will, subject to limited exceptions, require you to submit claims you have against us to binding arbitration, unless you opt-out in accordance with Section 11(E). Unless you opt-out of arbitration: (a) you will only be permitted to pursue claims against us on an individual basis, not as part of any class or representative action or proceeding and (b) you will only be permitted to seek relief (including monetary, injunctive, and declaratory relief) on an individual basis.</p>

                <h3>1. Updates to these Terms of Use</h3>
                <p>We may modify these Terms of Use from time to time. We will notify you of material changes to these Terms of Use by posting the amended terms on the Service at least thirty (30) days before the effective date of the changes. If you do not agree with the proposed changes, you should discontinue your use of the Service prior to the time the new Terms of Use take effect.  If you continue using the Service after the new terms take effect, you will be bound by the modified Terms of Use.</p>

                <h3>2. Privacy Policy</h3>
                <p>In connection with your use of the Service, please review our Privacy Policy, located at <a href='https://onpodio.com/privacy'>onpodio.com/privacy</a> to understand how we use information we collect from you when you access, visit or use the Service.   The Privacy Policy is part of and is governed by these Terms of Use and by agreeing to these Terms of Use, you agree to be bound by the terms of the Privacy Policy and agree that we may use information collected from you in accordance with its terms.</p>

                <h3>3. Affirmative Representations Regarding Your Use of the Service</h3>
                <p>When you use the Service, you represent that: (a) the information you submit is truthful and accurate; (b) your use of the Service does not violate any applicable laws or regulations; (c) you are of sufficient legal age or otherwise have legal capacity to legally enter into these Terms of Use.</p>

                <h3>4. User Registration and Accounts</h3>
                <p>You can visit and browse the Service without becoming a registered user of the Service, but you will not be able to sign up for or receive emails through the Service, unless you are a registered user of the Service. You can sign up to become a registered user of the Service by completing the registration process on the Site.  If you sign up to become a registered user of the Service, you agree: (i) to provide true, accurate, current and complete information about yourself as prompted by the Service’s registration form (the “Registration Data”); and (ii) to maintain and promptly update the Registration Data to keep it true, accurate, current and complete.</p>

                <h3>5. Prohibited Activities; Social Media Guidelines</h3>
                <h5>A. Prohibited Activities.</h5>
                <p>You agree that in connection with your use of the Service, you will not:</p>
                <ol style={{listStyleType: 'upper-roman'}}>
                  <li>use the onPodio name, logo, trademark or branding in a way that confuses people about your affiliation or relationship with onPodio;</li>
                  <li>use the Service for any unauthorized purpose including collecting usernames and/or email addresses of other users by electronic or other means for the purpose of sending unsolicited email or other electronic communications, or engaging in unauthorized framing of, or linking to, the Service without our express written consent;</li>
                  <li>transmit chain letters, bulk or junk email or interfere with, disrupt, or create an undue burden on the Service or the networks or services connected to the Service, including without limitation, hacking into the Service, or using the system to send unsolicited or commercial emails, bulletins, comments or other communications;</li>
                  <li>impersonate any other person or entity, provide false or misleading identification or address information, or invade the privacy, or violate the personal or proprietary right, of any person or entity;</li>
                  <li>use the Service for purposes of advertisements or solicitations for jobs or employment, or otherwise use the Service to hire any person to perform work, including, without limitation, posting advertisements or solicitations for modeling jobs or talent or talent scouting positions on the Service;</li>
                  <li>use the Service in connection with any franchise, pyramid scheme, “club membership,” distributor ship or sales representative agency arrangement or other business opportunity which requires an up-front or periodic payment, pays commissions, or requires recruitment of other members, sub-distributors or sub-agents;</li>
                  <li>decompile, disassemble, modify, translate, adapt, reverse engineer, create derivative works from or sublicense the Service, or any portion thereof; or</li>
                  <li>circumvent, disable or otherwise interfere with security related features of the Service or features that prevent or restrict use or copying of any Materials (as defined in Section 6) or enforce limitations on use of the Service or the Materials on the Service.</li>
                </ol>

                <h5>B. Social Media Guidelines.</h5>
                <p>If you access or use any of the onPodio social media pages on Facebook, Pinterest, Twitter, Instagram or YouTube, or post any reviews or comments regarding your use of the Service on these social media services or other third party websites, we ask you to follow the following guidelines:</p>
                <ol style={{listStyleType: 'upper-roman'}}>
                  <li>Please be polite and courteous. Excessive name calling, profanity, fighting words, discriminatory epithets, sexual harassment, bullying, gruesome language or the like, are not acceptable.</li>
                  <li>All postings should come from a real person. We will delete any postings from our social media pages that we believe have come from fake or anonymous profiles.</li>
                  <li>Please verify that all information submitted is accurate and factual.  Negative comments and complaints posted by you could be construed as claims about onPodio or an individual and may be subject to libel laws and other legal claims.</li>
                  <li>We would like to hear about your complaints or concerns regarding the Service before you share them publicly with others so that we can help resolve them for you. If you are a user and have a customer service comment, complaint, concern or idea, please email us at info@onpodio.com.</li>
                </ol>

                <h3>6. Our Intellectual Property Rights</h3>
                <p>All of the content on the Service (“Materials”) and the trademarks, service marks, and logos contained on the Service, are owned by or licensed to us and are subject to copyright and other intellectual property rights under United States and foreign laws and international conventions.  The Service and the Materials are for your information and personal use only and not for commercial exploitation. We reserve all rights in and to the Service and the Materials. If you download or print a copy of the Materials for your own personal use, you must retain all trademark, copyright and other proprietary notices contained in and on the Materials.</p>

                <h3>7. Our Management of the Service; User Misconduct</h3>
                <h5>A. Our Right to Manage the Service.  </h5>
                <p>We reserve the right, but do not undertake the obligation to: (i) monitor or review the Service for violations of these Terms of Use and for compliance with our policies; (ii) report to law enforcement authorities and/or take legal action against anyone who violates these Terms of Use; (iii) manage the Service in a manner designed to protect our and third parties’ rights and property or to facilitate the proper functioning of the Service; (iv) screen our users, or attempt to verify the statements of our users and/or (v) monitor disputes between you and other users or to terminate or block you and other users from using the Service.</p>

                <h5>B. Interactions with other Users.  </h5>
                <p>Please choose carefully the information you share through the Service and that you give to other users of the Service. You assume all risks associated with dealing with other users with whom you come in contact through the Service.</p>

                <h5>C. Our Right to Terminate Users. </h5>
                <p>Without limiting any other provision of these Terms of Use, we reserve the right to, in our sole discretion, and without notice or liability, deny access to and use of the Service to any person for any reason or for no reason at all, as permitted by applicable law, including, without limitation, for breach of any representation, warranty or covenant contained in these Terms of Use, or any applicable law or regulation.</p>

                <h3>8. Third Party Sites</h3>
                <p>The Service may contain links to websites operated by third parties (“Third Party Sites”), and you may be able to share information with Third Party Sites through links on the Service; however, we do not own or operate the Third Party Sites, and we have not reviewed, and cannot review, all of the material, including goods or services, made available through Third Party Sites. The availability of these links on the Service does not represent, warrant or imply that we endorse any Third Party Sites or any materials, opinions, goods or services available on them. Third party materials accessed through or used by means of the Third Party Sites may also be protected by copyright and other intellectual property laws. <strong>These Terms of Use do not apply to Third Party Sites.  Before visiting a Third Party Site through links or other means provided on or through the Service, you should review the Third Party Site’s terms and conditions and privacy policy, and inform yourself of the regulations, policies and practices of these Third Party Sites.</strong></p>

                <h3>9. Warranty Disclaimer; Limitation on Liability</h3>
                <h5>A. Disclaimer of Warranties</h5>
                <ol style={{listStyleType: 'upper-roman'}}>
                  <li>To the extent permitted by applicable law, all material or items provided through the Service are provided “as is” and “as available,” without warranty or conditions of any kind.  By operating the Service, we do not represent or imply that we endorse any materials or items available on or linked to by the Service, including, without limitation, content hosted on Third Party Sites, or that we believe any materials or items to be accurate, useful or non-harmful.  We cannot guarantee and do not promise any specific results from use of the Service. No advice or information, whether oral or written, obtained by you from us shall create any warranty not expressly stated in these Terms of Use. You agree that your use of the Service will be at your sole risk.  To the fullest extent permitted by law, we and each of our advertisers, licensors, suppliers, officers, directors, investors, managers, members, partners, affiliates, employees, agents, service providers and other contractors disclaim all warranties, express or implied, in connection with the Service and your use thereof.</li>
                  <li>To the extent permitted by applicable law, we make no warranties or representations about the accuracy, reliability, timeliness or completeness of the Service’s content, the content of any site linked to the Service, or information or any other items or materials on the Service or linked to by the Service.  We assume no liability or responsibility for any (a) errors, mistakes or inaccuracies of content and materials, (b) personal injury or property damage, of any nature whatsoever, resulting from your access to and use of the Service, (c) any unauthorized access to or use of our secure servers and/or any and all personal information stored therein, (d) any interruption or cessation of transmission to or from the Service, (e) any bugs, viruses, Trojan horses, or the like, which may be transmitted to or through the Service by any third party, and/or (f) any errors or omissions in any content and materials or for any loss or damage of any kind incurred as a result of the use of any content posted, transmitted or otherwise made available through the Service.</li>
                </ol>

                <h5>B. Limited Liability</h5>
                <p>To the extent permitted by applicable law, in no event shall we be liable to you or any third party for any indirect, consequential, incidental, special or punitive damages, including lost profit damages arising from your use of the Service, materials or any other content therein. Notwithstanding anything to the contrary contained in these Terms of Use, our liability to you in respect of any loss or damage suffered by you and arising out of or in connection with these Terms of Use, whether in contract, tort, or for breach of statutory duty or in any other way shall not exceed $50.</p>

                <h5>C. Exceptions to Disclaimers and Liability Limitations  </h5>
                <p>Some states or jurisdictions do not allow the limitation or exclusion of certain warranties, or the exclusion or limitation of certain damages.  If you reside in one of these states or jurisdictions, the limitation or exclusions in Sections 9(A) and 9(B) may not apply to you.</p>

                <h3>10. Indemnity</h3>
                <p>You hereby agree, at your expense, to indemnify, defend and hold harmless, onPodio, its officers, directors, investors, members, managers, partners, affiliates, employees, agents, service providers and other contractors from and against any loss, cost, damages, liability and/or expense, including reasonable attorney fees, arising out of or relating to third party claims, actions or allegations brought against onPodio arising out of your use of the Service. <strong>You will not be required to indemnify and hold onPodio harmless from and against any claims, liabilities, damages, losses, or expenses resulting from onPodio’s own negligent conduct.</strong></p>

                <h3>11. Legal Disputes and Arbitration Agreement</h3>
                <h6 className='text-danger'>Please Read This Following Clause Carefully – It May Significantly Affect Your Legal Rights, Including Your Right to File a Lawsuit in Court</h6>

                <h5>A. Initial Dispute Resolution</h5>
                <p>We are available by email at info@onpodio.com to address any concerns you may have regarding your use of the Service. Most concerns may be quickly resolved in this manner. Each of you and onPodio agree to use best efforts to settle any dispute, claim, question, or disagreement directly through consultation and good faith negotiations which shall be a precondition to either party initiating a lawsuit or arbitration.</p>

                <h5>B. Agreement to Binding Arbitration</h5>
                <p>If we do not reach an agreed upon solution within a period of thirty (30) days from the time informal dispute resolution is pursued pursuant to Section 11(A) above, then either party may initiate binding arbitration.  All claims arising out of or relating to these Terms of Use (including their formation, performance and breach), the parties’ relationship with each other and/or your use of the Service shall be finally settled by binding arbitration administered on a confidential basis by JAMS, in accordance with the JAMS Streamlined Arbitration Rules and Procedures, excluding any rules or procedures governing or permitting class actions.  Each party will have the right to use legal counsel in connection with arbitration at its own expense. The parties shall select a single neutral arbitrator in accordance with the JAMS Streamlined Arbitration Rules and Procedures.  The arbitrator, and not any federal, state or local court or agency, shall have exclusive authority to resolve all disputes arising out of or relating to the interpretation, applicability, enforceability or formation of these Terms of Use, including, but not limited to, any claim that all or any part of these Terms of Use is void or voidable.  The arbitrator shall be empowered to grant whatever relief would be available in a court under law or in equity.  The arbitrator’s award shall be in writing and provide a statement of the essential findings and conclusions, shall be binding on the parties and may be entered as a judgment in any court of competent jurisdiction. The interpretation and enforcement of these Terms of Use shall be subject to the Federal Arbitration Act.</p>
                <p>The JAMS rules governing the arbitration may be accessed at https://www.jamsadr.com/adr-rules-procedures.  If you initiate arbitration, to the extent the filing fee for the arbitration exceeds Two Hundred and Fifty U.S. Dollars ($250.00), we will pay the additional cost.  If we are required to pay the additional cost of the filing fees, you should submit a request for payment of fees to JAMS along with your form for initiating the arbitration, and we will make arrangements to pay all necessary fees directly to JAMS.  We will also be responsible for paying all other arbitration costs arising in connection with the arbitration other than costs incurred by you for legal counsel, travel and other out-of-pocket costs and expenses not constituting fees or amounts payable to JAMS.  You will not be required to pay fees and costs incurred by onPodio if you do not prevail in arbitration.</p>
                <p>The parties understand that, absent this mandatory provision, they would have the right to sue in court and have a jury trial.  They further understand that, in some instances, the costs of arbitration could exceed the costs of litigation and the right to discovery may be more limited in arbitration than in court.</p>

                <h5>C. Class Action and Class Arbitration Waiver</h5>
                <p>You and onPodio each further agree that any arbitration shall be conducted in your respective individual capacities only and not as a class action or other representative action, and you and onPodio each expressly waive your respective right to file a class action or seek relief on a class basis.  If any court or arbitrator determines that the class action waiver set forth in this paragraph is void or unenforceable for any reason or that an arbitration can proceed on a class basis, then the arbitration provision set forth above in Section 11(B) shall be deemed null and void in its entirety and the parties shall be deemed to have not agreed to arbitrate disputes.</p>

                <h5>D. Exception – Small Claims Court Claims</h5>
                <p>Notwithstanding the parties’ agreement to resolve all disputes through arbitration, either party may seek relief in a small claims court for disputes or claims within the scope of that court’s jurisdiction.</p>

                <h5>E. 30 Day Right to Opt Out</h5>
                <p>You have the right to opt-out and not be bound by the arbitration and class action waiver provisions set forth in Sections 11(B), 11(C), and 11(D) by sending written notice of your decision to opt-out to the following email: <strong>info@onpodio.com.</strong> The notice must be sent within thirty (30) days of registering to use the Service, otherwise you shall be bound to arbitrate disputes in accordance with the terms of those sections.  If you opt-out of these arbitration provisions, we also will not be bound by them.</p>

                <h5>F. Exclusive Venue for Litigation</h5>
                <p>To the extent that the arbitration provisions set forth in Section 11(B) do not apply, of if you want to pursue any legal remedies to which you would otherwise be entitled but that are not available to you pursuant to this Section 11, the parties agree that any litigation between them shall be filed exclusively in state or federal courts located in San Francisco County, California (except for small claims court actions which may be brought in the county where you reside). The parties expressly consent to exclusive jurisdiction in California for any litigation other than small claims court actions.  In the event of litigation relating to these Terms of Use or the Service, the parties agree to waive, to the maximum extent permitted by law, any right to a jury trial.</p>

                <h5>G. Applicable Law </h5>
                <p>You agree that federal laws and the laws of the State of California, without regard to principles of conflict of laws, will govern these Terms of Use and any claim or dispute that has arisen or may arise between you and onPodio.</p>

                <h3>12. Notice to New Jersey Users</h3>
                <p>Notwithstanding any terms set forth in these Terms of Use, if any of the provisions set forth in Sections 9, 10 or 11 are held unenforceable, void or inapplicable under New Jersey law, then any such provision shall not apply to you but the rest of these Terms of Use shall remain binding on you and onPodio.  In addition, for New Jersey residents, the limitation on liability is inapplicable where attorneys’ fees, court costs, or other damages are mandated by statute.  Notwithstanding any provision in these Terms of Use, nothing in these Terms of Use is intended to, nor shall it be deemed or construed to, limit any rights available to you under the Truth-in-Consumer Contract, Warranty and Notice Act.</p>

                <h3>13. Notice to California Users</h3>
                <p>Under California Civil Code Section 1789.3, users located in California are entitled to the following consumer rights notice: We are located at 149 New Montgomery Street, San Francisco, CA 94105. If a user has a question or complaint regarding the Service, please send an email to <a href='mailto:info@onpodio.com'>info@onpodio.com</a>. California residents may reach the Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs by mail at 1625 North Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.</p>

                <h3>14. Notice to International Users</h3>
                <p>The Service is controlled and operated by onPodio from its offices in the United States.  We do not make any representations that the Service or any Materials are available or appropriate for use in your location.  You agree to comply with all local rules applicable to you regarding user conduct on the Internet and acceptable content.  You also agree to comply with all applicable laws regarding the transmission of technical data exported from the United States or the country in which you reside.</p>

                <h3>15. No Modifications by Our Employees</h3>
                <p>If any of our employees offers to modify the terms of these Terms of Use, he or she is not acting as an agent for us or speaking on our behalf.  You may not rely, and should not act in reliance on, any statement or communication from our employees or anyone else purporting to act on our behalf.</p>

                <h3>16. Independent Contractors</h3>
                <p>Nothing in these Terms of Use shall be deemed to create an agency, partnership, joint venture, employer-employee or franchisor-franchisee relationship of any kind between us and any user.</p>

                <h3>17. Non-Waiver</h3>
                <p>Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of the applicable right or provision.</p>

                <h3>18. Severability</h3>
                <p>These Terms of Use operate to the fullest extent permissible by law. If any provision or part of a provision of these Terms of Use is unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Terms of Use and shall not affect the validity and enforceability of any remaining provisions.</p>

                <h3>19. Assignment</h3>
                <p>We may assign our rights under these Terms of Use without your approval.</p>

                <h3>20. Entire Agreement</h3>
                <p>These Terms of Use constitute the complete and exclusive understanding and agreement of the parties relating to the subject matter hereof and supersede all prior understandings, proposals, agreements, negotiations, and discussions between the parties, whether written or oral.</p>

                <h3>21. Contact Information</h3>
                <p>If you have any questions about the Service or these Terms of Use, you can contact onPodio by email at <a href='mailto:info@onpodio.com'>info@onpodio.com</a></p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Terms;
