import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { calendarOptions } from '../../../constants';
import { Select } from '../../forms';

class SelectView extends React.PureComponent {
  static propTypes = {
    onSelect:     PropTypes.func.isRequired,
    selectedView: PropTypes.string.isRequired
  }
  
  static getDerivedStateFromProps(props) {
    return {
      selectedView: props.selectedView
    };
  }
  
  state = {
    selectedView: ''
  }
  
  render () {
    const formatOptionLabel = ({ label, icon }) => (
      <span>
        <Icon
            className='mr-2'
            fixedWidth
            icon={icon} />
        {label}
      </span>
    );
    
    return (
      <Select
          className='select-view'
          formatOptionLabel={formatOptionLabel}
          isOptionDisabled={(option) => option.disabled}
          onChange={this.props.onSelect}
          options={calendarOptions.VIEWS}
          styles={calendarOptions.SELECT_STYLE}
          value={this.state.selectedView} />
    );
  }
}

export default SelectView;