import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';

import { SearchBar } from '../../search';

class Intro extends React.PureComponent {
  static propTypes = {
    performSearch: PropTypes.func.isRequired
  }

  render() {
    return (
      <section
          className='hero-home'
          id='home-search'>

        <Container className='py-6 py-md-7 text-white'>
          <div className='home-box'>
            <h2 className='mb-4 home-box-title'>
              FIND YOUR PERFECT FITNESS INSTRUCTOR
            </h2>

            <SearchBar performSearch={this.props.performSearch} />
          </div>
        </Container>
      </section>
    );
  }
}

export default Intro;
