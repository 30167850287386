import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Col, Container, Row } from 'reactstrap';

import { Validator } from '../../../lib';
import { Header } from './';

class Biography extends React.PureComponent {
  static propTypes = {
    children:    PropTypes.node.isRequired,
    currentStep: PropTypes.number.isRequired,

    nextStep:     PropTypes.func,
    previousStep: PropTypes.func,
    profile:      PropTypes.object.isRequired
  }

  static defaultProps = {
    nextStep:     () => {},
    previousStep: () => {}
  }

  static getDerivedStateFromProps(props) {
    return {
      isTouched: !!props.profile.bio ||
                 !!props.profile.experiences[0].company ||
                 !!props.profile.experiences[0].name ||
                 !!props.profile.experiences.length

    };
  }

  state = {
    errors: {}
  }

  handleBack = () => {
    this.props.previousStep();
  }

  handleNext = () => {
    return this.handleValidate().then(() => {
      return this.props.nextStep();
    });
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { profile } = this.props;

    const errors = Validator.validate([
      ['workExperiences', Validator.concepts.experiencesValidator, [profile.experiences]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      this.setState({ errors }, () => reject(errors));
    }
  });

  render() {
    const { errors } = this.state;

    return (
      <section className='py-5'>
        <Container>
          <Row className='justify-content-center'>
            <Col lg='6'>
              <Header currentStep={this.props.currentStep}>
                <span
                    aria-label="memo"
                    role="img">
                  📝
                </span>
                &ensp;Tell us your story and experience
              </Header>
              <p className='text-muted mb-4'>
                Optional fields / inputs. You can also add this to your profile later.
              </p>

              {React.cloneElement(this.props.children, { errors })}
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Biography;
