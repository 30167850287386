import React from 'react';
import PropTypes from 'prop-types';

class Message extends React.PureComponent {
  static propTypes = {
    children:  PropTypes.node.isRequired,
    className: PropTypes.string
  }

  static defaultProps = {
    className: ''
  }

  render() {
    return (
      <div className={'error ' + this.props.className}>
        {this.props.children}
      </div>
    );
  }
}

export default Message;
