import { toastr } from 'react-redux-toastr';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { api, errorWithStatus, extractError, toCamelCase } from '../lib';

import { PlaylistActionTypes } from '../constants/actionTypes';

import {
  ErrorActions,
  LoaderActions,
  PlaylistActions,
  ProfileActions
} from '../actions';

function* watchFetch() {
  yield takeLatest(PlaylistActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('playlists'));
      const response = yield call(api.playlist.fetch);

      yield put(PlaylistActions.FETCH_SUCCESS(toCamelCase(response.data)));
      yield put(LoaderActions.FINISH_FIELD_LOADING('playlists'));
    } catch (_error) {
      yield put(PlaylistActions.FETCH_FAILURE());
      yield put(LoaderActions.FINISH_FIELD_LOADING('playlists'));
      /* no error dispatch */
    }
  });
}

function* watchGet() {
  yield takeLatest(PlaylistActionTypes.GET, function* ({ payload }) {
    try {
      yield put(ErrorActions.CLEAR_API('spotify'));
      yield put(LoaderActions.START_FIELD_LOADING('playlist'));
      const response = yield call(api.instructor.playlist.get, payload);

      yield put(PlaylistActions.GET_SUCCESS(toCamelCase(response.data)));
      yield put(LoaderActions.FINISH_FIELD_LOADING('playlist'));
    } catch (error) {
      yield put(PlaylistActions.GET_FAILURE());
      yield put(ErrorActions.NEW_API({ error: errorWithStatus(error), name: 'spotify' }));

      yield put(LoaderActions.FINISH_FIELD_LOADING('playlist'));
    }
  });
}

function* watchUpdate() {
  yield takeLatest(PlaylistActionTypes.UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.playlist.update, payload);
      const profile = yield select((state) => state.profile.data);

      yield put(ProfileActions.UPDATE_SUCCESS(({...profile, playlist: response.data })));
      yield put(PlaylistActions.UPDATE_SUCCESS(toCamelCase(response.data)));

      toastr.success('Playlist updated successfully');
    } catch (error) {
      yield put(PlaylistActions.UPDATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* spotifySaga() {
  yield all([
    watchFetch(),
    watchGet(),
    watchUpdate()
  ]);
}
