import { JoinActionTypes } from '../constants/actionTypes';

const initialState = {
  email:      '',
  lastAction: '',
  username:   ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case JoinActionTypes.START_PAGE:
      return {
        ...newState,
        username: action.payload
      };

    case JoinActionTypes.JOIN_NOW:
      return {
        ...newState,
        email: action.payload
      };

    default:
      return state;
  }
};