/* global process */
import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import { profileMapTypes } from '../../constants';
import { InstructorMarkers, LocationMarkers } from './';

const { GMAPS_API_KEY } = process.env;

class ProfileMap extends React.PureComponent {
  static propTypes = {
    center:     PropTypes.object,
    className:  PropTypes.string,
    id:         PropTypes.string,
    instructor: PropTypes.object.isRequired,
    location:   PropTypes.object.isRequired,
    type:       PropTypes.string,
    zoom:       PropTypes.number
  }

  static defaultProps = {
    center: {
      lat: 37.77,
      lng: -122.43
    },
    className: '',
    id:        '',
    type:      '',
    zoom:      8
  }

  static getDerivedStateFromProps(props) {
    return {
      instructor: props.instructor.data,
      location:   props.location.data
    };
  }

  state = {
    google:     {},
    isApiReady: false,
    location:   {}
  }

  handleApiLoaded = (google) => {
    this.setState({
      google,
      isApiReady: true
    }, () => {
      google.map.setOptions({
        gestureHandling: 'none'
      });
    });
  }

  renderMarker = () => {
    const { google, instructor, location } = this.state;

    if (this.props.type == profileMapTypes.INSTRUCTOR) {
      if (instructor.locations && instructor.locations.length) {
        return (
          <InstructorMarkers
              google={google}
              instructors={[instructor]} />
        );
      }
    } else {
      return (
        <LocationMarkers
            google={google}
            locations={[location]} />
      );
    }
  }

  render() {
    return (
      <div
          className={this.props.className}
          id={this.props.id}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: GMAPS_API_KEY }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            onGoogleApiLoaded={this.handleApiLoaded}
            yesIWantToUseGoogleMapApiInternals>
        </GoogleMapReact>
        {this.state.isApiReady && this.renderMarker()}
      </div>
    );
  }
}

export default ProfileMap;
