import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../../forms';

class OnDemandSendEmail extends PureComponent {
  static propTypes = {
    onChange:    PropTypes.func.isRequired,
    onClose:     PropTypes.func.isRequired,
    onCreate:    PropTypes.func.isRequired,
    onOpenModal: PropTypes.func.isRequired,
    onToggle:    PropTypes.func.isRequired
  };

  handleCancel = () => {
    this.props.onChange(false, 'sendEmail');
    this.props.onClose();
    this.props.onToggle();
    this.props.onCreate();
  };

  handleSubmit = () => {
    this.props.onClose();
    this.props.onOpenModal();
    this.props.onChange(true, 'sendEmail');
    this.props.onToggle();
  }

  render() {
    return (
      <React.Fragment>
        <div className='confirmation confirmation-recurring'>
          <Card body>
            <CardText>
              <span>
                Do you want to email access to this
                on-demand video to prior class
                attendees or existing clients?
              </span>
            </CardText>

            <div className='actions'>
              <Button
                  color='primary'
                  isOutline
                  onClick={this.handleCancel}>
                No
              </Button>

              <Button
                  color='primary'
                  onClick={this.handleSubmit}>
                Yes
              </Button>
            </div>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

export default OnDemandSendEmail;
