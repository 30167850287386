import { all, call, put, takeLatest } from 'redux-saga/effects';
import { find } from 'lodash';

import { api, extractError, toCamelCase } from '../lib';
import { ApplicationActionTypes, DomainActionTypes } from '../constants/actionTypes';
import { ApplicationActions, DomainActions, ErrorActions } from '../actions';

function* watchGet() {
  yield takeLatest(DomainActionTypes.GET, function* ({ payload }) {
    try {
      const response = yield call(api.instructor.domain.get, payload);

      yield put(DomainActions.GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(DomainActions.GET_FAILURE());
    }
  });
}

function* watchCreate() {
  yield takeLatest(DomainActionTypes.CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.instructor.domain.create, payload);

      yield put(DomainActions.CREATE_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(DomainActions.CREATE_FAILURE(error));
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDelete() {
  yield takeLatest(DomainActionTypes.DELETE, function* ({ payload }) {
    try {
      yield call(api.instructor.domain.destroy, payload);

      yield put(DomainActions.DELETE_SUCCESS());
    } catch (error) {
      yield put(DomainActions.DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchSetDomain() {
  yield takeLatest(ApplicationActionTypes.SET_DOMAIN, function* () {
    try {
      const customHost = window.location.hostname;
      const response = yield call(api.domains.fetch);

      const currentInstructor = find(response.data, (domain) => (
        domain.host === customHost
      ));

      yield put(ApplicationActions.SET_INSTRUCTOR(currentInstructor.username));
    } catch (error) {
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* domainSaga() {
  yield all([
    watchGet(),
    watchCreate(),
    watchDelete(),
    watchSetDomain()
  ]);
}