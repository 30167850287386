import React from 'react';

import { Privacy } from '../components/legal';

class PrivacyContainer extends React.PureComponent {
  render() {
    return <Privacy />;
  }
}

export default PrivacyContainer;
