import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Row } from 'reactstrap';
import { last } from 'lodash';

import { Creatable } from '../';
import { AddLocationModal } from '../../modals';

class Locations extends React.PureComponent {
  static propTypes = {
    children:  PropTypes.node.isRequired,
    className: PropTypes.string.isRequired,
    errors:    PropTypes.object.isRequired,
    onChange:  PropTypes.func.isRequired,
    profile:   PropTypes.object.isRequired
  };

  state = {
    createModalOpen: false,
    newLocation:     {
      createdByUser: true
    },
    selectedOptions: []
  };

  static getDerivedStateFromProps(props) {
    const { locations } = props.profile;

    const selectedOptions = locations.map((location) => ({
      label: location.studioName ? `${location.studioName} (${location.name})` : location.name,
      value: location.id
    }));

    return { selectedOptions };
  }

  handleCreateModal = (options) => {
    const name = last(options).label;

    this.handleChange(name, 'name');
    this.handleToggleModal();
  };

  handleSelect = (options) => {
    const locations = options.map((s) => (
      s.createdByUser ? { createdByUser: true, name: s.label } : { id: s.value, name: s.label }
    ));

    this.props.onChange(locations, 'locations');
  };

  handleToggleModal = () => {
    this.setState(() => ({
      createModalOpen: !this.state.createModalOpen
    }));
  };

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      newLocation: {
        ...prevState.newLocation,
        [inputName]: value
      }
    }));
  };

  handleAddressChange = (value) => {
    this.setState((prevState) => ({
      newLocation: {
        ...prevState.newLocation,
        address: value.address,
        city:    value.city,
        country: value.country,
        state:   value.state,
        zipCode: value.zipCode
      }
    }));
  };

  handleStudioSelect = (value) => {
    this.setState((prevState) => ({
      newLocation: {
        ...prevState.newLocation,
        studioId:   value.id,
        studioName: value.label
      }
    }));
  };

  handleLocationCreate = () => {
    const { newLocation } = this.state;

    this.props.onChange([...this.props.profile.locations, newLocation], 'locations');
    this.handleToggleModal();
  };

  render() {
    return (
      <React.Fragment>
        <div className={this.props.className}>
          {this.props.children}

          <Row>
            <Col>
              <FormGroup>
                <Creatable
                    isInvalid={!!this.props.errors.locations}
                    isMulti
                    onCreate={this.handleCreateModal}
                    onSelect={this.handleSelect}
                    subject='locations'
                    value={this.state.selectedOptions}>
                  <FormFeedback>{this.props.errors.locations}</FormFeedback>
                </Creatable>
              </FormGroup>
            </Col>
          </Row>
        </div>

        <AddLocationModal
            isOpen={this.state.createModalOpen}
            isProfileCreate
            location={this.state.newLocation}
            onAddressChange={this.handleAddressChange}
            onChange={this.handleChange}
            onStudioSelect={this.handleChange}
            onSubmit={this.handleLocationCreate}
            onToggle={this.handleToggleModal} />
      </React.Fragment>
    );
  }
}

export default Locations;
