import React from 'react';
import PropTypes from 'prop-types';
import { includes, reject } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { routes } from '../lib';

export default function (ComposedComponent) {
  class Authenticate extends React.Component {
    static propTypes = {
      goTo:    PropTypes.func.isRequired,
      profile: PropTypes.object.isRequired,
      session: PropTypes.object.isRequired,
      user:    PropTypes.object.isRequired,
      visits:  PropTypes.array.isRequired
    };

    componentDidMount() {
      this.checkAndRedirect();
    }

    shouldComponentUpdate(nextProps) {
      return nextProps.session.authenticated !== this.props.session.authenticated ||
             nextProps.session.restored      !== this.props.session.restored ||
             nextProps.profile.id            !== this.props.profile.id;
    }

    componentDidUpdate() {
      this.checkAndRedirect();
    }

    checkAndRedirect = () => {
      if (this.props.session.authenticated && this.props.session.restored) {
        const blacklist = [routes.HOME, routes.LOGIN, routes.SIGNUP,
                           routes.LOGOUT, routes.NOT_FOUND, routes.RESET_PASSWORD];
        const history = reject(this.props.visits, (route) => includes(blacklist, route.location.pathname));

        if (history[0]) {
          const { pathname, search } = history[0].location;
          this.props.goTo(pathname + search);
        } else {

          if (this.props.profile.id) {
            this.props.goTo(routes.INSTRUCTOR_DASHBOARD);
          } else {
            this.props.goTo(routes.DASHBOARD);
          }
        }
      }
    }

    render() {
      if (!this.props.session.authenticated) {
        return <ComposedComponent {...this.props} />;
      } else {
        return null;
      }
    }
  }

  const mapStateToProps = (state) => ({
    profile: state.profile.data,
    session: state.session,
    user:    state.user.data,
    visits:  state.visits
  });

  const mapDispatchToProps = (dispatch) => ({
    goTo: (route) => dispatch(push(route))
  });

  return connect(mapStateToProps, mapDispatchToProps)(Authenticate);
}
