import { connect } from 'react-redux';

import { TimeBlocksActions } from '../../actions';
import { TimeBlockModal } from '../../components/modals';

const mapStateToProps = (state) => ({
  instructor: state.profile.data,
  timeBlocks: state.timeBlocks.data,
  user:       state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createRecurringTimeBlock: (data) => dispatch(TimeBlocksActions.RECURRING_CREATE(data)),
  createTimeBlock:          (data) => dispatch(TimeBlocksActions.CREATE(data)),
  deleteRecurringTimeBlock: (data) => dispatch(TimeBlocksActions.RECURRING_DELETE(data)),
  deleteTimeBlock:          (data) => dispatch(TimeBlocksActions.DELETE(data)),
  updateTimeBlock:          (data) => dispatch(TimeBlocksActions.UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(TimeBlockModal);