import { StyleTagsActionTypes } from '../constants/actionTypes';

export default {
  FETCH: () => ({
    type: StyleTagsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: StyleTagsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload,
    type: StyleTagsActionTypes.FETCH_SUCCESS
  })
};