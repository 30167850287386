import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Badge } from 'reactstrap';

import { CommentsContainer, InstagramContainer, PlaylistContainer } from '../../../../containers';
import { InstructorName } from '../../../../components/instructor';
import Header from '../components/headerUrban';
import { Union } from '../../../../images';

class StyleUrban extends React.PureComponent {
  static propTypes = {
    follow:     PropTypes.func.isRequired,
    instructor: PropTypes.object.isRequired,
    playlist:   PropTypes.object.isRequired,
    unFollow:   PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const { instructor, playlist } = props;

    if (!state.isMounted && instructor.oauth) {
      return {
        integrations: instructor.oauth,
        isMounted:    true,
        playlist
      };
    }
  }

  state = {
    integrations: {},
    isMounted:    false,
    playlist:     {}
  }

  renderTags = () => (
    this.props.instructor.styleTags.map((tag, index) => (
      <Badge
          className='style__tag'
          color='light'
          key={index}
          pill>
        {tag}
      </Badge>
    ))
  )

  renderPrompts = () => (
    this.props.instructor.prompts.map((prompt) => (
      <div
          className='style-prompt'
          key={prompt.id}>
        <img
            alt='+'
            className='style-prompt__delimiter'
            height='22'
            onDragStart={(e) => e.preventDefault()}
            src={Union}
            width='22' />
        <span className='style-prompt__title'>{prompt.text}</span>
      </div>
    ))
  )

  handleFollow = () => {
    this.props.instructor.isFollowing ? this.props.unFollow() : this.props.follow();
  };

  render() {
    const { integrations, playlist } = this.state;
    const { instructor } = this.props;
    const { prompts, styleTags } = instructor;
    const isLinkedSocial = integrations.instagram || integrations.spotify;

    return (
      <React.Fragment>
        <Header
            instructor={instructor}
            subtitle='Style' />

        <div className='style template-urban'>
          <div className='style__body'>
            { isMobile &&
              <InstructorName
                  instructor={instructor}
                  subtitle='Style' />
            }
            <div className='style__customer-choice '>
              <h3 className='page-title'>CUSTOMER&#39;S CHOICE</h3>
              <div className='style__tags'>
                {styleTags && this.renderTags()}
              </div>

              <div className='style__prompts'>
                {prompts && this.renderPrompts()}
              </div>

              <div className='style__comments'>
                <LazyLoadComponent threshold={100}>
                  <CommentsContainer />
                </LazyLoadComponent>
              </div>
            </div>
            <div className="style-body__info">
              { !isEmpty(instructor) && playlist && (
                <React.Fragment>
                  <h3 className="page-title">Sneak peek</h3>
                  { isLinkedSocial && (
                    <div className="style__social">
                      { integrations.instagram && (
                        <LazyLoadComponent threshold={100}>
                          <InstagramContainer />
                        </LazyLoadComponent>
                      )}

                      { integrations.spotify && (
                        <LazyLoadComponent threshold={100}>
                          <PlaylistContainer />
                        </LazyLoadComponent>
                      )}
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default StyleUrban;
