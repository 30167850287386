import React from 'react';
import { connect } from 'react-redux';

import { HomePage } from '../components/pages';
import { JoinActions, SearchActions, SessionActions } from '../actions';

class HomeContainer extends React.PureComponent {
  render() {
    return <HomePage {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  search: state.search
});

const mapDispatchToProps = (dispatch) => ({
  joinNow:       (data) => dispatch(SessionActions.JOIN_NOW(data)),
  performSearch: (data) => dispatch(SearchActions.SEARCH(data)),
  startPage:     (data) => dispatch(JoinActions.START_PAGE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
