import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Spinner } from 'reactstrap';
import classNames from 'classnames';

import { Moment, getSize } from '../../lib';
import { CircleCheckmark } from '../../images';

class RecordingList extends React.PureComponent {
  static propTypes = {
    createRecording: PropTypes.func.isRequired,
    fetchRecordings: PropTypes.func.isRequired,
    isInvalid:       PropTypes.bool,
    isLoading:       PropTypes.bool.isRequired,
    recording:       PropTypes.object.isRequired,
    recordings:      PropTypes.array.isRequired,
    selectedUuid:    PropTypes.string.isRequired
  }

  static defaultProps = {
    isInvalid: false
  }

  static getDerivedStateFromProps(props, state) {
    return {
      hasSelected:  !!props.recording.id || !!props.selectedUuid,
      isEdit:       !!props.selectedUuid,
      selectedUuid: props.selectedUuid ? props.selectedUuid : state.selectedUuid
    };
  }

  state = {
    hasSelected:  false,
    isEdit:       false,
    selectedUuid: ''
  }

  componentDidMount() {
    this.props.fetchRecordings();
  }

  handleClick = (e) => {
    e.preventDefault();

    window.open('https://zoom.us/recording', '_blank');
  }

  handleCreate = (recording) => () => {
    if (this.state.isEdit) return;

    this.setState({ selectedUuid: recording.uuid }, () => (
      this.props.createRecording({ id: recording.id, uuid: recording.uuid })
    ));
  }

  renderRecording = (recording) => {
    const { isInvalid } = this.props;
    const { hasSelected, isEdit } = this.state;

    const startTime = new Moment.utc(recording.startTime);
    const size = getSize(recording.totalSize);

    const isFocused  = this.state.selectedUuid == recording.uuid;
    const isSelected = this.props.recording.uuid == recording.uuid || isEdit && isFocused;

    return (
      <div
          className={classNames('d-block px-3 pt-1 recording', {
                                'is-invalid':          isInvalid && !hasSelected,
                                'pointer':             !isEdit,
                                'recording__selected': isSelected })}
          key={recording.uuid}
          onClick={this.handleCreate(recording)}>
        <Row>
          <Col
              md='1'
              xs='2'>
            { isSelected &&
              <img
                  alt='Icon'
                  className='icon ml-1 mt-1'
                  src={CircleCheckmark} />
            }

            {isFocused && !isSelected && this.renderSpinner()}
          </Col>

          <Col
              md='11'
              xs='10'>
            <div className='topic'>{recording.topic}</div>

            <div className='text-muted text-small'>
              <span>{startTime.format('MMM D YYYY')} at {startTime.format('h:mm A')} | {size}</span>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  renderSpinner = () => (
    <div className='d-flex justify-content-center'>
      <Spinner color='muted' />
    </div>
  )

  render() {
    const { isInvalid, isLoading, recordings } = this.props;
    const { hasSelected, isEdit } = this.state;

    if (isLoading) return this.renderSpinner();

    if (!recordings.length) return (
      <div className='mx-3 text-muted'>
        Recordings not found. You can learn more&nbsp;
        <a
            href='https://zoom.us/recording'
            onClick={this.handleClick}
            rel='noopener noreferrer'
            target='_blank'>
          here
        </a>
      </div>
    );

    return (
      <React.Fragment>
        <div className={classNames('recordings', { 'is-invalid': isInvalid && !hasSelected })}>
          {this.props.recordings.map(this.renderRecording)}
        </div>

        { isInvalid && !hasSelected &&
          <div className='invalid-tooltip d-block'>
            Required
          </div>
        }

        { hasSelected && !isEdit &&
          <small className='text-success'>
            You will get an email notification when recording is ready
          </small>
        }
      </React.Fragment>
    );
  }
}

export default RecordingList;
