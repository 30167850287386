import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { Loader } from '../../../loader';
import { InstagramPhotoModal } from '../../../modals';
import { GoIcon } from '../../../../images';

class InstagramFeed extends React.PureComponent {
  static propTypes = {
    error:       PropTypes.object.isRequired,
    hasTitle:    PropTypes.bool,
    imagesCount: PropTypes.number,
    instagram:   PropTypes.object.isRequired,
    instructor:  PropTypes.object.isRequired,
    loader:      PropTypes.object.isRequired
  }

  static defaultProps = {
    hasTitle:    true,
    imagesCount: 10
  }

  static getDerivedStateFromProps(props) {
    return {
      images:          props.instagram.data,
      isLoading:       props.loader.instagram.isLoading,
      socialsProfiles: props.instructor.data.socialProfiles
    };
  }

  state = {
    currentPhoto:     0,
    images:           [],
    isLoading:        true,
    isPhotoModalOpen: false,
    notFound:         false,
    socialsProfiles:  ''
  }

  handleToggle = (i = this.state.currentPhoto) => () => {
    this.setState((prevState) => ({
      ...prevState,
      currentPhoto:     i,
      isPhotoModalOpen: !prevState.isPhotoModalOpen
    }));
  };

  handlePrev = () => {
    const index = this.state.currentPhoto - 1;

    this.setState((prevState) => ({
      ...prevState,
      currentPhoto: index > 0 ? index : 0
    }));
  };

  handleNext = () => {
    const maxIndex = this.state.images.length - 1;
    const index = this.state.currentPhoto + 1;

    this.setState((prevState) => ({
      ...prevState,
      currentPhoto: index > maxIndex ? maxIndex : index
    }));
  };

  renderImages = () => (
    this.state.images.slice(0, this.props.imagesCount).map((image, i) => (
      <button
          className='style__image-btn'
          key={i}
          onClick={this.handleToggle(i)}>
        <img
            alt={image.id}
            className='item'
            key={i}
            src={image.url} />
      </button>
    ))
  );


  render () {
    const { isLoading, socialsProfiles, images } = this.state;
    const { error, hasTitle } = this.props;
    const isHidden = !isEmpty(error.instagram);

    const blockClass = classNames('profile-integration', 'instagram', 'mb-lg-5', { 'd-none': isHidden });
    const titleClass = classNames('title', { 'hidden': isEmpty(this.state.images) });

    return (
      <div className={blockClass}>
        { hasTitle &&
          <div className={titleClass}>
            <span className='label'>My instagram</span>
            { socialsProfiles &&
            <div>
              <a
                  className='style__go-link'
                  href={`https://instagram.com/${socialsProfiles.instagram}`}
                  rel='noopener noreferrer'
                  target='_blank'>
                View
              </a>
              <img
                  alt='Icon'
                  src={GoIcon} />
            </div>
            }
          </div>
        }
        <div className='content'>
          <Loader
              hasOpacity={false}
              isLoading={isLoading}
              isTransparent
              size='2rem'
              type='border' />
          {!isEmpty(images) && this.renderImages()}
        </div>

        { !isEmpty(images) && socialsProfiles &&
          <InstagramPhotoModal
              currentPhoto={this.state.currentPhoto}
              images={this.state.images}
              instructorName={this.props.instructor.data.firstName}
              isOpen={this.state.isPhotoModalOpen}
              onNext={this.handleNext}
              onPrev={this.handlePrev}
              onToggle={this.handleToggle}
              username={socialsProfiles.instagram} />
        }
      </div>
    );
  }
}

export default InstagramFeed;
