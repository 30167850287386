import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import momentTZ from 'moment-timezone';

import { Moment, Validator } from '../../../lib';
import { days } from '../../../constants';

import { Button, Form, Select } from '../../forms';
import { Input } from '../../inputs';

const MONTHS = new Moment.months().map((month) => ({ label: month, value: month }));

class SignUp extends React.PureComponent {
  static propTypes = {
    joinEmail: PropTypes.string,
    onSubmit:  PropTypes.func
  }

  static defaultProps = {
    joinEmail: '',
    onSubmit:  () => {}
  }

  state = {
    errors: {},

    user: {
      day:                  '',
      email:                this.props.joinEmail,
      firstName:            '',
      lastName:             '',
      month:                '',
      password:             '',
      passwordConfirmation: '',
      timeZone:             momentTZ.tz.guess()
    }
  }

  validate = () => new Promise((resolve, reject) => {
    const { user } = this.state;

    const errors = Validator.validate([
      ['email',     Validator.concepts.emailRegex, [user.email]],
      ['firstName', Validator.concepts.isFilled, [user.firstName]],
      ['lastName',  Validator.concepts.isFilled, [user.lastName]],
      ['password',  Validator.concepts.isLongerThan, [8, user.password, 'Password']],
      ['password',  Validator.concepts.passwordRegex, [user.password]],
      ['passwordConfirmation',  Validator.concepts.isMatching, [user.password, user.passwordConfirmation]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [inputName]: value
      }
    }));
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.validate().then(() => (
      this.setState({ errors: {} }, () => (
        this.props.onSubmit({
          user: {
            ...this.state.user,
            birthday: this.state.user.day + ' ' + this.state.user.month
          }
        })
      ))
    )).catch((errors) => (
      this.setState({ errors })
    ));
  }

  render() {
    const { errors } = this.state;

    return (
      <Form className='form-default'>

        <Row>
          <Col>
            <FormGroup>
              <Label
                  className='required'
                  for='firstName'>
                First Name
              </Label>
              <Input
                  isInvalid={!!errors.firstName}
                  name='firstName'
                  onChange={this.handleChange}
                  value={this.state.user.firstName} />
              <FormFeedback tooltip>{errors.firstName}</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label
                  className='required'
                  for='lastName'>
                Last Name
              </Label>
              <Input
                  isInvalid={!!errors.lastName}
                  name='lastName'
                  onChange={this.handleChange}
                  value={this.state.user.lastName} />
              <FormFeedback tooltip>{errors.lastName}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label
              className='required'
              for='email'>
            E-mail address
          </Label>
          <Input
              isInvalid={!!errors.email}
              name='email'
              onBlur={this.handleTrimSpaces}
              onChange={this.handleChange}
              value={this.state.user.email} />

          <FormFeedback tooltip>{errors.email}</FormFeedback>
        </FormGroup>
        <Row>
          <Col>
            <FormGroup>
              <Label
                  className='required'
                  for='password'>
                Password
              </Label>
              <Input
                  isInvalid={!!errors.password}
                  name='password'
                  onChange={this.handleChange}
                  type='password'
                  value={this.state.user.password} />

              <FormFeedback tooltip>{errors.password}</FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label
                  className='required'
                  for='passwordConfirmation'>
                Confirm password
              </Label>
              <Input
                  isInvalid={!!errors.passwordConfirmation}
                  name='passwordConfirmation'
                  onChange={this.handleChange}
                  type='password'
                  value={this.state.user.passwordConfirmation} />

              <FormFeedback tooltip>{errors.passwordConfirmation}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <small className="text-muted text-left w-100">Password must be a minimum of 8 characters</small>
            </FormGroup>
          </Col>
        </Row>
        <Label for='day'>Birthday</Label>
        <Row>
          <Col md='3'>
            <FormGroup>
              <Select
                  name='day'
                  onChange={this.handleChange}
                  options={days}
                  placeholder='Day'
                  value={this.state.user.day} />
            </FormGroup>
          </Col>

          <Col md='9'>
            <Select
                name='month'
                onChange={this.handleChange}
                options={MONTHS}
                placeholder='Month'
                value={this.state.user.month} />
          </Col>
        </Row>


        <Button
            color='primary'
            isBlock
            onClick={this.handleSubmit}
            size='lg'
            type='submit'>
          Sign up
        </Button>
      </Form>
    );
  }
}

export default SignUp;
