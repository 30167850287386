import { PrivateBookingsActionTypes } from '../constants/actionTypes';

export default {
  CLEAR: (payload) => ({
    payload, type: PrivateBookingsActionTypes.CLEAR
  }),


  CREATE: (payload) => ({
    payload, type: PrivateBookingsActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: PrivateBookingsActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: PrivateBookingsActionTypes.CREATE_SUCCESS
  }),


  DELETE: (payload) => ({
    payload, type: PrivateBookingsActionTypes.DELETE
  }),

  DELETE_FAILURE: () => ({
    type: PrivateBookingsActionTypes.DELETE_FAILURE
  }),

  DELETE_SUCCESS: (payload) => ({
    payload, type: PrivateBookingsActionTypes.DELETE_SUCCESS
  }),


  FETCH: () => ({
    type: PrivateBookingsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: PrivateBookingsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: PrivateBookingsActionTypes.FETCH_SUCCESS
  }),


  INSTRUCTORS_CREATE: (payload) => ({
    payload, type: PrivateBookingsActionTypes.INSTRUCTORS_CREATE
  }),

  INSTRUCTORS_CREATE_FAILURE: () => ({
    type: PrivateBookingsActionTypes.INSTRUCTORS_CREATE_FAILURE
  }),

  INSTRUCTORS_CREATE_SUCCESS: (payload) => ({
    payload, type: PrivateBookingsActionTypes.INSTRUCTORS_CREATE_SUCCESS
  }),


  INSTRUCTORS_DELETE: (payload) => ({
    payload, type: PrivateBookingsActionTypes.INSTRUCTORS_DELETE
  }),

  INSTRUCTORS_DELETE_FAILURE: () => ({
    type: PrivateBookingsActionTypes.INSTRUCTORS_DELETE_FAILURE
  }),

  INSTRUCTORS_DELETE_SUCCESS: (payload) => ({
    payload, type: PrivateBookingsActionTypes.INSTRUCTORS_DELETE_SUCCESS
  }),

  INSTRUCTORS_FETCH: () => ({
    type: PrivateBookingsActionTypes.INSTRUCTORS_FETCH
  }),

  INSTRUCTORS_FETCH_FAILURE: () => ({
    type: PrivateBookingsActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_FETCH_SUCCESS: (payload) => ({
    payload, type: PrivateBookingsActionTypes.INSTRUCTORS_FETCH_SUCCESS
  })
};
