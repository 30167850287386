export default {
  CLEAR: '@@privateSessionType/CLEAR',
  
  CREATE:         '@@privateSessionTypes/CREATE',
  CREATE_FAILURE: '@@privateSessionTypes/CREATE_FAILURE',
  CREATE_SUCCESS: '@@privateSessionTypes/CREATE_SUCCESS',
  
  DELETE:         '@@privateSessionTypes/DELETE',
  DELETE_FAILURE: '@@privateSessionTypes/DELETE_FAILURE',
  DELETE_SUCCESS: '@@privateSessionTypes/DELETE_SUCCESS',
  
  FETCH:         '@@privateSessionTypes/FETCH',
  FETCH_FAILURE: '@@privateSessionTypes/FETCH_FAILURE',
  FETCH_SUCCESS: '@@privateSessionTypes/FETCH_SUCCESS',
  
  GET:         '@@privateSessionTypes/GET',
  GET_FAILURE: '@@privateSessionTypes/GET_FAILURE',
  GET_SUCCESS: '@@privateSessionTypes/GET_SUCCESS',

  UPDATE:         '@@privateSessionTypes/UPDATE',
  UPDATE_FAILURE: '@@privateSessionTypes/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@privateSessionTypes/UPDATE_SUCCESS'
};
