import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../../helpers';
import { SettingsHomeInstagramPage } from '../../components/pages';
import { UserActions } from '../../actions';

class SettingsHomeInstagramContainer extends React.PureComponent {
  render() {
    return (
      <SettingsHomeInstagramPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser: ()     => dispatch(UserActions.FETCH())
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(SettingsHomeInstagramContainer);
