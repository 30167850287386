import { connect } from 'react-redux';

import { ClassPacksActions, UserClassPacksActions } from '../../actions';
import { AddClassPackModal } from '../../components/modals/instructor';

const mapStateToProps = (state) => ({
  classPacks: state.classPacks.instructorData,
  client:     state.clients.form,
  isLoading:  state.loader.classPacks.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  createClassPack: (data) => dispatch(UserClassPacksActions.INSTRUCTORS_CREATE(data)),
  fetchClassPacks: ()     => dispatch(ClassPacksActions.INSTRUCTORS_FETCH())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddClassPackModal);
