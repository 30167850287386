/* global process */
/* eslint-disable immutable/no-mutation */

const { HOST } = process.env;

import React from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import { Cookies, withCookies } from 'react-cookie';

import { SemVer } from '../lib';

class CacheBusterProvider extends React.PureComponent {
  static propTypes = {
    children:      PropTypes.node.isRequired,
    cookies:       instanceOf(Cookies).isRequired,
    isStoreLoaded: PropTypes.bool.isRequired
  }

  state = {
    isLatestVersion: false
  }

  refreshCacheAndReload = () => {
    console.log('Clearing cache and hard reloading...');

    window.persistor.purge()
      .then(() => (
        window.location.reload(true)
      ))
      .catch((error) => (
        console.error(error)
      ));
  }

  setExpiryDate = (years=5) => {
    const today = new Date();

    return new Date(today.getFullYear() + years, today.getMonth(), today.getDate());
  }

  componentDidMount() {
   if (!this.props.cookies.get('version', { domain: HOST })) {
      const latestVersion = document.getElementsByName('version')[0].getAttribute('content');
      this.props.cookies.set('version', latestVersion, { expires: this.setExpiryDate(), path: '/', sameSite: 'none' });
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.isStoreLoaded && !prevProps.isStoreLoaded) {
      const latestVersion = document.getElementsByName('version')[0].getAttribute('content');
      const currentVersion = this.props.cookies.get('version');

      const shouldForceRefresh = SemVer.greaterThan(latestVersion, currentVersion);

      if (shouldForceRefresh) {
        console.warn(`We have a new version - ${latestVersion}. Should force refresh`);
        this.refreshCacheAndReload();
        this.props.cookies.set('version', latestVersion, { expires: this.setExpiryDate(), path: '/' });
      } else {
        console.info(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
      }

      this.setState({ isLatestVersion: true });
    }
  }

  render() {
    const { isLatestVersion } = this.state;

    return isLatestVersion ? this.props.children : null;
  }
}

export default withCookies(CacheBusterProvider);
