import { ProfileDataActionTypes } from '../constants/actionTypes';

export default {
  GET: () => ({
    type: ProfileDataActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: ProfileDataActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload,
    type: ProfileDataActionTypes.GET_SUCCESS
  })
};
