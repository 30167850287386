import { DomainActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       {},
  isLoading:  false,
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case DomainActionTypes.GET:
      return newState;

    case DomainActionTypes.GET_SUCCESS:
      return { ...newState, data: action.payload };

    case DomainActionTypes.GET_FAILURE:
      return { ...newState, data: initialState.data };


    case DomainActionTypes.CREATE:
      return { ...newState, isLoading: true };

    case DomainActionTypes.CREATE_SUCCESS:
      return { ...newState, data: action.payload, isLoading: false };

    case DomainActionTypes.CREATE_FAILURE:
      return { ...newState, isLoading: false };


    case DomainActionTypes.DELETE:
      return { ...newState, isLoading: true };

    case DomainActionTypes.DELETE_SUCCESS:
      return { ...newState, data: initialState.data, isLoading: false };

    case DomainActionTypes.DELETE_FAILURE:
      return { ...newState, isLoading: false };

    default: return state;
  }
};