import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { find, includes, reject } from 'lodash';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';

import { Button } from '../forms';
import { Loader } from '../loader';
import { Link } from '../inputs';
import { OAuthWindow } from '../oauth';
import { Logo } from '../../images';
import { routes } from '../../lib';

import {
  SignIn,
  SignUp
} from './authentication';

const tabs = {
  SIGN_IN: '/login',
  SIGN_UP: '/signup'
};


class AuthenticationPage extends PureComponent {
  static propTypes = {
    cookies:         PropTypes.object.isRequired,
    goTo:            PropTypes.func.isRequired,
    hasCustomDomain: PropTypes.bool.isRequired,
    isLoading:       PropTypes.bool.isRequired,
    router:          PropTypes.object.isRequired,
    session:         PropTypes.object.isRequired,
    signInSuccess:   PropTypes.func.isRequired,
    signInUser:      PropTypes.func.isRequired,
    signUpUser:      PropTypes.func.isRequired,
    visits:          PropTypes.array.isRequired
  }

  componentDidMount () {
    const { location } = this.props.router;

    if (location.pathname === routes.LOGIN) {
      this._setCookies();
    } else {
      const query = new URLSearchParams(location.search);
      this.props.cookies.set('redirectRoute', query.get('redirect'));
    }
  }

  handleGoBack = () => {
    const { visits, router } = this.props;

    const visit = find(visits, (visit) => visit.location.pathname != router.location.pathname);
    const route = visit ? visit.location.pathname : routes.HOME;
    this.props.goTo(route);
  }

  handleSignIn = (data) => {
    this.props.signInUser(data);
  }

  handleSignUp = (data) => {
    this.props.signUpUser(data);
  }

  renderSwitch = () => {
    const { location } = this.props.router;

    switch (location.pathname) {
      case tabs.SIGN_IN:
        return (
          <SignIn onSubmit={this.handleSignIn} />
        );

      case tabs.SIGN_UP:
        return (
          <SignUp
              joinEmail={this.props.session.email}
              onSubmit={this.handleSignUp} />
        );

      default:
        return (
          <SignIn onSubmit={this.handleSignIn} />
        );
    }
  };

  handleOauthSuccess = () => {
    this.props.signInSuccess();
  };

  _setCookies = () => {
    const blacklist = [routes.LOGIN, routes.SIGNUP, routes.LOGOUT, routes.NOT_FOUND];
    const history = reject(this.props.visits, (route) => includes(blacklist, route.location.pathname));

    const route = history[0] ? history[0].location.pathname : routes.HOME;
    this.props.cookies.set('redirectRoute', route);
  };

  render() {
    const { hasCustomDomain, router } = this.props;
    const { location } = router;
    const path = location.pathname;

    return (
      <Container
          className='px-3'
          fluid>
        <Helmet>
          <title>{path === tabs.SIGN_IN ? 'Login' : 'Sign up'}</title>
        </Helmet>

        <Row className='min-vh-100'>
          <Col
              className='d-flex align-items-center'
              lg='6'
              md='8'
              xl='5'>
            <div className='w-100 py-5 px-md-5 px-xl-6 position-relative'>
              {this.props.isLoading && <Loader isLoading />}

              <Link
                  className='mb-4'
                  to={routes.HOME}>
                <img
                    alt='Logo'
                    className='img-fluid auth-logo'
                    src={Logo} />
                {path === tabs.SIGN_UP && <h2>Sign Up</h2>}
              </Link>

              <div id="form-container">
                {this.renderSwitch()}

                <Link
                    className='close-absolute mr-md-5 mr-xl-6 pt-5'
                    onClick={this.handleGoBack}
                    to='#'>
                  <svg className='svg-icon w-3rem h-3rem'>
                    <path
                        d="M41.999 20.002l-22 22m22 0L20 20"
                        data-name="layer1"
                        fill="none"
                        stroke="#202020"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        style={{ stroke: 'var(--layer1, #202020)' }} />
                  </svg>
                </Link>
              </div>
              { !hasCustomDomain &&
                <Fragment>
                  <hr
                      className='my-3 hr-text letter-spacing-2'
                      data-content='OR' />
                  <OAuthWindow
                      onSuccess={this.handleOauthSuccess}
                      url={routes.FACEBOOK_AUTH}>
                    <Button
                        className='btn-social'
                        color='primary'
                        isBlock
                        isOutline>
                      <Icon
                          className='btn-social-icon'
                          icon={['fab', 'facebook-f']}
                          size='2x' />
                      Connect <span className='d-none d-sm-inline'>with Facebook</span>
                    </Button>
                  </OAuthWindow>
                  <OAuthWindow
                      onSuccess={this.handleOauthSuccess}
                      url={routes.GOOGLE_AUTH}>
                    <Button
                        className='btn-social'
                        color='muted'
                        isBlock
                        isOutline>
                      <Icon
                          className='btn-social-icon'
                          icon={['fab', 'google']}
                          size='2x' />
                      Connect <span className='d-none d-sm-inline'>with Google</span>
                    </Button>
                  </OAuthWindow>
                </Fragment>
              }
              <hr className="my-4" />
              { path === tabs.SIGN_IN &&
                <p className="text-center">
                  <small className="text-muted text-center">
                    Don&apos;t have an account yet?&nbsp;
                    <Link to={routes.SIGNUP}>Sign Up</Link>
                  </small>
                </p>
              }

              { path === tabs.SIGN_UP &&
                <p className="text-center">
                  <small className="text-muted text-center">
                    Already have an account?&nbsp;
                    <Link to={routes.LOGIN}>Log In</Link>
                  </small>
                </p>
              }
            </div>
          </Col>
          <Col
              className='d-none d-md-block'
              lg='6'
              md='4'
              xl='7'>
            <div
                className='bg-cover h-100 mr-n3'
                id='auth-bg' />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default AuthenticationPage;
