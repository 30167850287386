import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { ChallengeForm } from '../../forms/instructor';

class ChallengeModal extends React.PureComponent {
  static propTypes = {
    cancelChallenge: PropTypes.func.isRequired,
    challenge:       PropTypes.object.isRequired,
    createChallenge: PropTypes.func.isRequired,
    deleteSample:    PropTypes.func.isRequired,
    isLoading:       PropTypes.bool.isRequired,
    isNewRecord:     PropTypes.bool.isRequired,
    isOpen:          PropTypes.bool.isRequired,
    onToggle:        PropTypes.func.isRequired,
    updateChallenge: PropTypes.func.isRequired,
    user:            PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    const { isLoading, isNewRecord } = props;

    return {
      challenge: isNewRecord ? {} : props.challenge,
      isLoading,
      isNewRecord
    };
  }

  state = {
    challenge:   {},
    isLoading:   false,
    isNewRecord: true
  }

  handleSubmit = (challenge) => {
    this.state.isNewRecord
        ? this.props.createChallenge({ challenge })
        : this.props.updateChallenge({ challenge });
  }

  render() {
    const { isOpen, onToggle } = this.props;
    const { challenge, isNewRecord, isLoading } = this.state;

    const action = isNewRecord ? 'Create' : 'Update';

    return (
      <Modal
          centered
          className='membership-modal'
          isOpen={isOpen}
          toggle={onToggle}>
        <ModalHeader toggle={onToggle}>
          {action} <span className='text-primary'>Challenge</span>
        </ModalHeader>

        <ModalBody>
          <ChallengeForm
              cancelChallenge={this.props.cancelChallenge}
              data={challenge}
              deleteSample={this.props.deleteSample}
              isLoading={isLoading}
              isNewRecord={isNewRecord}
              onSubmit={this.handleSubmit}
              onToggle={this.props.onToggle}
              user={this.props.user} />
        </ModalBody>
      </Modal>
    );
  }
}

export default ChallengeModal;
