import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { home } from 'react-icons-kit/ikons/home';

const HomeIcon = ({ className, size }) => (
  <Icon
      className={className}
      icon={home}
      size={size} />
);

HomeIcon.propTypes = {
  className: PropTypes.string,
  size:      PropTypes.number
};

HomeIcon.defaultProps = {
  className: '',
  size:      32
};

export default HomeIcon;