import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class CustomLink extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node,
    to:       PropTypes.string.isRequired
  }

  static defaultProps = {
    children: null
  }

  static getDerivedStateFromProps(props) {
    const isExternal = props.to.startsWith('http://') || props.to.startsWith('https://');

    return { isExternal };
  }

  state = {
    isExternal: false
  }

  render() {
    if (this.state.isExternal) {
      return (
        <a
            {...this.props}
            href={this.props.to}>
          {this.props.children}
        </a>
      );
    } else {
      return (
        <Link {...this.props}>
          {this.props.children}
        </Link>
      );
    }
  }
}

export default CustomLink;
