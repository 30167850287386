/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import { filter, map } from 'lodash';
import Draggable from 'react-draggable';

import { Moment, getTimeZone } from '../../lib';
import { DAYS_OF_WEEK } from '../../constants';

class Schedule extends React.PureComponent {
  static propTypes = {
    color: PropTypes.string.isRequired,

    dimensions: PropTypes.shape({
      height: PropTypes.number,
      width:  PropTypes.number
    }).isRequired,

    events: PropTypes.shape({
      Friday:    PropTypes.array,
      Monday:    PropTypes.array,
      Saturday:  PropTypes.array,
      Sunday:    PropTypes.array,
      Thursday:  PropTypes.array,
      Tuesday:   PropTypes.array,
      Wednesday: PropTypes.array
    }).isRequired,

    font:        PropTypes.string.isRequired,
    hasLocation: PropTypes.bool,
    hasName:     PropTypes.bool,

    isBold:   PropTypes.bool.isRequired,
    isItalic: PropTypes.bool.isRequired,
    schedule: PropTypes.shape({
      friday:    PropTypes.bool,
      monday:    PropTypes.bool,
      saturday:  PropTypes.bool,
      sunday:    PropTypes.bool,
      thursday:  PropTypes.bool,
      tuesday:   PropTypes.bool,
      wednesday: PropTypes.bool
    }).isRequired,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    user: PropTypes.object.isRequired
  }

  static defaultProps = {
    hasLocation: false,
    hasName:     false
  }

  static getDerivedStateFromProps(props) {
    const selectedDays = filter(DAYS_OF_WEEK, (week) => props.schedule[week.toLowerCase()]);

    return { selectedDays };
  }

  state = {
    selectedDays: []
  }

  renderWeekday = (event) => {
    const time = Moment.utc(event.startsAt, 'YYYY-MM-DDhh:mm:ss').tz(getTimeZone(this.props.user.timeZone));
    const location = event.location ? event.location.name : '';
    const name = event.name;

    return (
      <tspan
          className='font-weight-normal'
          dy={this.props.size}
          key={event.id + event.type}
          x='10%'>
        {time.format('h:mm') + time.format('A').substring(0, 1) + ' '}
        {this.props.hasName && name}
        {this.props.hasName && this.props.hasLocation && ' - '}
        {this.props.hasLocation && location}
      </tspan>
    );
  };

  render() {
    const textStyle = {
      cursor:        'pointer',
      fill:          this.props.color,
      fontFamily:    this.props.font,
      fontSize:      this.props.size + 'px',
      fontStyle:     this.props.isItalic ? 'italic' : 'normal',
      fontWeight:    this.props.isBold ? '700' : '400',
      textTransform: 'uppercase',
      userSelect:    'none',
      zIndex:        5
    };

    return (
      <Draggable>
        <text
            dominantBaseline='middle'
            style={textStyle}
            textAnchor='start'
            x='10%'
            y='10%'>
          {map(this.state.selectedDays, (week, index) => (
            <React.Fragment key={week}>
              <tspan
                  dy={index ? (this.props.size * 2) : 0}
                  x='10%'>
                {week.substring(0, 3)}
              </tspan>

              {map(this.props.events[week], this.renderWeekday)}
            </React.Fragment>
          ))}
        </text>
      </Draggable>
    );
  }
}

export default Schedule;
