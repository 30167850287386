import { LocationActionTypes } from '../constants/actionTypes';

export default {
  FOLLOW: () => ({
    type: LocationActionTypes.FOLLOW
  }),

  FOLLOW_FAILURE: () => ({
    type: LocationActionTypes.FOLLOW_FAILURE
  }),

  FOLLOW_SUCCESS: () => ({
    type: LocationActionTypes.FOLLOW_SUCCESS
  }),


  GET: (payload) => ({
    payload, type: LocationActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: LocationActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload, type: LocationActionTypes.GET_SUCCESS
  }),


  UNFOLLOW: () => ({
    type: LocationActionTypes.UNFOLLOW
  }),

  UNFOLLOW_FAILURE: () => ({
    type: LocationActionTypes.UNFOLLOW_FAILURE
  }),

  UNFOLLOW_SUCCESS: () => ({
    type: LocationActionTypes.UNFOLLOW_SUCCESS
  }),


  UPDATE_SUCCESS: (payload) => ({
    payload, type: LocationActionTypes.UPDATE_SUCCESS
  })
};
