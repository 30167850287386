import React from 'react';
import PropTypes from 'prop-types';
import { Col, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Input } from '../../inputs';

class SocialField extends React.PureComponent {
  static propTypes = {
    icon:        PropTypes.string.isRequired,
    onChange:    PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    profile:     PropTypes.object.isRequired,
    subject:     PropTypes.string.isRequired
  }

  static defaultProps = {
    placeholder: ''
  }

  render() {
    const { subject } = this.props;

    return (
      <React.Fragment>
        <Label
            htmlFor={subject}
            sm={2}>{subject}</Label>
        <Col sm={10}>
          <InputGroup
              className='input-group-social'
              id={subject}>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText>
                <Icon
                    fixedWidth
                    icon={['fab', this.props.icon]}
                    size='lg' />
              </InputGroupText>
            </InputGroupAddon>
            <Input
                name={subject}
                onChange={this.props.onChange}
                placeholder={this.props.placeholder}
                type='text'
                value={this.props.profile.socialProfiles[subject]} />
          </InputGroup>
        </Col>
      </React.Fragment>
    );
  }
}

export default SocialField;
