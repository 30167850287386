import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { filter, isEmpty, map, sortBy } from 'lodash';

import { Autocompleter, Button, Radio, Select } from '../forms';
import { Moment, Validator } from '../../lib';
import { AutocompleterContainer } from '../../containers';

class SendEmailModal extends React.PureComponent {
  static propTypes = {
    classId:             PropTypes.number,
    instructor:          PropTypes.object.isRequired,
    isCreateOnDeamnd:    PropTypes.bool,
    isOpen:              PropTypes.bool.isRequired,
    onCreate:            PropTypes.func,
    onToggle:            PropTypes.func.isRequired,
    privateGroupClasses: PropTypes.array.isRequired,
    sendEmail:           PropTypes.func.isRequired,
    user:                PropTypes.object.isRequired
  }

  static defaultProps = {
    classId:          null,
    isCreateOnDeamnd: false,
    onCreate:         () => {}
  }

  static getDerivedStateFromProps(props, state) {
    const { user } = props;
    const privateGroupClasses = filter(props.privateGroupClasses, (pgc) => (
                                pgc.startsAt < new Moment.utc().format('YYYY-MM-DDhh:mm:ssUTC')
                              ));
    const sortClasses = sortBy(privateGroupClasses, 'startsAt').reverse();

    const date = (date) => new Moment(date, 'YYYY-MM-DDhh:mm:ss').tz(user.timeZone).format('DD/MM/YY');

    return {
      classesOptions: map(sortClasses, (pgc) => ({
                          id:   pgc.id,
                          name: pgc.name + ' ' + `(${date(pgc.startsAt)})`
                      })),
      emailParams: {
        ...state.emailParams,
        onDemandId: props.classId
      }
    };
  }

  state = {
    classesOptions: [],
    emailParams:    {
      clientId:            '',
      privateGroupClassId: '',
      toClient:            false
    },
    errors: []
  }

  handleSwitch = (value) => () => {
    this.setState((prevState) => ({ emailParams: {...prevState.emailParams, toClient: value }}));
  }

  handleSubmit = () => {
    const { emailParams } = this.state;
    const { sendEmail, isCreateOnDeamnd, onCreate } = this.props;

    this.handleValidate()
      .then(() => (
        isCreateOnDeamnd ? onCreate(emailParams) : sendEmail(emailParams),
        this.props.onToggle()
      ))
    .catch((errors) => (
      this.setState({ errors })
    ));
  }

  handleSelect = (inputName) => (option) => {
    const value = option.id ? option.id : option;

    this.setState((prevState) => ({ emailParams: { ...prevState.emailParams, [inputName]: value }}));
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { emailParams } = this.state;

    const errors = Validator.validate([
      ['privateGroupClassId', Validator.concepts.isFilled, [emailParams.privateGroupClassId], emailParams.toClient],
      ['clientId', Validator.concepts.isFilled, [emailParams.clientId], !emailParams.toClient]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      this.setState({ errors }, () => reject(errors));
    }
  })

  render() {
    const { instructor, isOpen, onToggle } = this.props;
    const { classesOptions, emailParams, errors } = this.state;
    const { clientId, toClient, privateGroupClassId } = emailParams;

    return (
      <Modal
          centered
          className='membership-modal'
          isOpen={isOpen}
          toggle={onToggle}>
        <ModalHeader toggle={onToggle}>
          Send <span className='text-primary'>On Demand Video </span>
        </ModalHeader>

        <ModalBody>
          <div className='text-muted small'>
            Send to class or client
          </div>
          <Form className='form-default'>
            <FormGroup>
              <div className='d-flex mb-0'>
                <div className='custom-control custom-radio w-50 d-flex align-items-center'>
                  <Radio
                      className='custom-control-input'
                      id='class'
                      isChecked={!toClient}
                      name='toClient'
                      onChange={this.handleSwitch(false)}
                      value={false} />
                  <Label
                      className='custom-control-label'
                      htmlFor='input-class'>
                    Class Attendees
                  </Label>
                </div>

                <div className='custom-control custom-radio w-50 d-flex align-items-center'>
                  <Radio
                      className='custom-control-input'
                      id='client'
                      isChecked={toClient}
                      name='toClient'
                      onChange={this.handleSwitch(true)}
                      value={false} />
                  <Label
                      className='custom-control-label'
                      htmlFor='input-client'>
                    Individual Client
                  </Label>
                </div>
              </div>
            </FormGroup>

            { !toClient &&
              <FormGroup>
                <Label className='small text-muted'>
                  Select class attendees
                </Label>

                <Select
                    disabledOptionType='tier'
                    disabledOptionValues={['tier_1', 'tier_2']}
                    isSearchable
                    labelName='name'
                    name='privateGroupClassId'
                    onChange={this.handleSelect('privateGroupClassId')}
                    options={classesOptions}
                    placeholder='Select class'
                    value={privateGroupClassId}
                    valueName='id' />

                  {!!errors.privateGroupClassId &&
                    <p className='error'>{errors.privateGroupClassId}</p>
                  }
              </FormGroup>
            }

            { toClient &&
              <FormGroup>
                <Label
                    className='small text-muted'
                    for='clients-autocomplete'>
                  Select existing client
                </Label>

                <AutocompleterContainer>
                  <Autocompleter
                      hasInitialValue
                      id='clients-autocompleter'
                      instructor={instructor}
                      isInvalid={!!errors.clientId}
                      name='clients'
                      onChange={this.handleChange}
                      onSelect={this.handleSelect('clientId')}
                      placeholder='Start typing client name'
                      subject='clients'
                      value={clientId} />
                </AutocompleterContainer>

                { !!this.state.errors.clientId &&
                  <p className='error'>{this.state.errors.clientId}</p>
                }
              </FormGroup>
            }

            <div className='d-flex text-muted small'>
              Note: An email will be sent to your clients with
              access to this on-demand video
            </div>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button
              className='mt-2 mt-lg-0'
              color='primary'
              isBlock
              onClick={this.handleSubmit}
              size='lg'>
            Send Email
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SendEmailModal;
