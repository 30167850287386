import { InstructorActionTypes } from '../constants/actionTypes';

export default {
  CLAIM: (payload) => ({
    payload, type: InstructorActionTypes.CLAIM
  }),

  CLAIM_FAILURE: () => ({
    type: InstructorActionTypes.CLAIM_FAILURE
  }),

  CLAIM_SUCCESS: (payload) => ({
    payload, type: InstructorActionTypes.CLAIM_SUCCESS
  }),


  DISCONNECT_INSTAGRAM: () => ({
   type: InstructorActionTypes.DISCONNECT_INSTAGRAM
  }),


  FOLLOW: () => ({
    type: InstructorActionTypes.FOLLOW
  }),

  FOLLOW_FAILURE: () => ({
    type: InstructorActionTypes.FOLLOW_FAILURE
  }),

  FOLLOW_SUCCESS: () => ({
    type: InstructorActionTypes.FOLLOW_SUCCESS
  }),


  GET: (payload) => ({
    payload, type: InstructorActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: InstructorActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload, type: InstructorActionTypes.GET_SUCCESS
  }),


  UNFOLLOW: () => ({
    type: InstructorActionTypes.UNFOLLOW
  }),

  UNFOLLOW_FAILURE: () => ({
    type: InstructorActionTypes.UNFOLLOW_FAILURE
  }),

  UNFOLLOW_SUCCESS: () => ({
    type: InstructorActionTypes.UNFOLLOW_SUCCESS
  })
};
