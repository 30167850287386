import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ProfileBadge extends React.PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    type:  PropTypes.string
  }

  static defaultProps = {
    type: 'service'
  }

  render () {
    return (
      <div className={classNames('badge-profile', `badge-profile__${this.props.type}`)}>
        <span className='badge-profile__span'>{this.props.title}</span>
      </div>
    );
  }
}

export default ProfileBadge;
