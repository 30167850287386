import { DiscountActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: DiscountActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: DiscountActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: DiscountActionTypes.CREATE_SUCCESS
  }),

  RESTORE: () => ({
    type: DiscountActionTypes.RESTORE
  })
};
