import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';

import { Button } from '../';
import { Input } from '../../inputs';

class PromptDescription extends React.PureComponent {
  static propTypes = {
    onSelect:       PropTypes.func,
    selectedPrompt: PropTypes.object
  }

  static defaultProps = {
    onSelect:       () => {},
    selectedPrompt: {}
  }

  state = {
    text: ''
  }

  componentDidMount() {
    this.setState({
      text: this.props.selectedPrompt.text || ''
    });
  }

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value });
  }

  handleSubmit = () => {
    const newPrompt = { ...this.props.selectedPrompt, text: this.state.text };

    this.props.onSelect(newPrompt);
  }

  render() {
    return (
      <form
          action='#'
          className='prompt-description'>
        <FormGroup>
          <Label>{this.props.selectedPrompt.title}</Label>

          <Input
              maxLength={120}
              name='text'
              onChange={this.handleChange}
              placeholder='Tell us...'
              rows='5'
              type='textarea'
              value={this.state.text} />
        </FormGroup>

        <Button
            onClick={this.handleSubmit}
            type='button'>
          Submit
        </Button>
      </form>
    );
  }
}

export default PromptDescription;
