import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';
import { map } from 'lodash';
import Select from 'react-select';

import { ResultCard } from '../../search';
import { selectFieldStyle } from '../../../constants';

class Results extends React.PureComponent {
  static propTypes = {
    currentPage: PropTypes.number.isRequired,
    instructors: PropTypes.object.isRequired,
    locations:   PropTypes.object.isRequired,
    onChange:    PropTypes.func.isRequired,
    subject:     PropTypes.string.isRequired
  }

  static getDerivedStateFromProps(props) {
    const byFollowers = props.subject == 'instructors' ? 'follows_count' : 'locations.follows_count';
    const byName      = props.subject == 'instructors' ? 'first_name' : 'studios.name';

    const filterOptions = [
      { direction: 'desc', label: 'By Followers', value: byFollowers },
      { direction: 'asc',  label: 'By Name',      value: byName }
    ];

    return {
      instructors:      props.instructors.data,
      instructorsCount: props.instructors.data.length,
      locations:        props.locations.data,
      locationsCount:   props.locations.data.length,
      options:          filterOptions,
      perPage:          props.instructors.meta.perPage || 0,
      totalRecords:     props.instructors.meta.totalRecords || 0
    };
  }

  state = {
    instructors:      [],
    instructorsCount: 0,
    locations:        [],
    locationsCount:   0,
    perPage:          0,
    totalRecords:     0
  }

  renderInstructors = () => (
    map(this.state.instructors, (instructor) => (
      <ResultCard
          instructor={instructor}
          key={instructor.id}
          type={this.props.subject} />
    ))
  )

  renderLocations = () => (
    map(this.state.locations, (location) => (
      <ResultCard
          key={location.id}
          location={location}
          type={this.props.subject} />
    ))
  )

  renderNoResults = () => (
    <Col md='12'>No Results...</Col>
  )

  renderCounter = () => {
    const { perPage, totalRecords, instructorsCount, locationsCount } = this.state;

    const recordsOnPage = (this.props.currentPage * perPage) - perPage + 1;

    if (this.state.instructorsCount) {
      return (
        <span>
          <strong>
            Showing {recordsOnPage}-{recordsOnPage + instructorsCount - 1} of {totalRecords}
          </strong> results
        </span>
      );
    } else {
      return (
        <span>
          <strong>
            Showing {recordsOnPage}-{recordsOnPage + locationsCount - 1} of {totalRecords}
          </strong> results
        </span>
      );
    }
  }

  handleSelectSort = (option) => {
    this.props.onChange({
      direction: option.direction,
      sortBy:    option.value
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className='results-header'>
          <div className='results-counter'>
            {this.renderCounter()}
          </div>
          <div className='results-sort'>
            <FormGroup row>
              <Label
                  className='form-label'
                  sm={4}>
                Sort by
              </Label>
              <Col sm={8}>
                <Select
                    className='sort-select'
                    defaultValue={this.state.options[0]}
                    isSearchable={false}
                    onChange={this.handleSelectSort}
                    options={this.state.options}
                    styles={selectFieldStyle.default} />
              </Col>
            </FormGroup>
          </div>
        </div>
        {this.state.instructorsCount
            ? this.renderInstructors()
            : this.state.locationsCount
                ? this.renderLocations()
                : this.renderNoResults()}
      </React.Fragment>
    );
  }
}

export default Results;
