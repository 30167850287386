import { ProfileActionTypes } from '../constants/actionTypes';

export default {
  CLEAR: (payload) => ({
    payload, type: ProfileActionTypes.CLEAR
  }),


  CREATE: (payload) => ({
    payload, type: ProfileActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: ProfileActionTypes.CREATE_FAILURE
  }),

  CREATE_FINISH: () => ({
    type: ProfileActionTypes.CREATE_FINISH
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: ProfileActionTypes.CREATE_SUCCESS
  }),


  FETCH: () => ({
    type: ProfileActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: ProfileActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: ProfileActionTypes.FETCH_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: ProfileActionTypes.UPDATE
  }),

  UPDATE_AVAILABILITY: (payload) => ({
    payload, type: ProfileActionTypes.UPDATE_AVAILABILITY
  }),

  UPDATE_FAILURE: () => ({
    type: ProfileActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: ProfileActionTypes.UPDATE_SUCCESS
  })
};
