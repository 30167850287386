import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';
import { Button } from '../forms';

class OnDemandCollectionDelete extends PureComponent {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired
  };

  handleCancel = () => {
    this.props.onCancel();
  };

  handleDelete = () => {
    this.props.onDelete();
    this.handleCancel();
  }

  render() {
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            <span>
              Removing the collection will also <span className='text-danger'>delete ALL the videos</span>
            </span>
          </CardText>

          <div className='actions'>
            <Button
                color='danger'
                isOutline
                onClick={this.handleCancel}
                size='sm'>
              No, cancel
            </Button>

            <Button
                color='danger'
                onClick={this.handleDelete}
                size='sm'>
              Yes, delete
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default OnDemandCollectionDelete;