import { connect } from 'react-redux';

import { PlansActions } from '../../../actions';
import StorageLimit from './storageLimit';

const mapStateToProps = (state) => ({
  plan:         state.plans.userData,
  storageLimit: state.plans.userData.storageLimit,
  usedStorage:  state.profile.data.storageUsed
});

const mapDispatchToProps = (dispatch) => ({
  getSubscription: ()     => dispatch(PlansActions.SUBSCRIPTION_GET())
});

export default connect(mapStateToProps, mapDispatchToProps)(StorageLimit);