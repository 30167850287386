/* global process */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { capitalize, noop, padStart } from 'lodash';

import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { StripeHelper } from '../../lib';
import { Button, ChargeForm, PaymentIcon } from '../forms';


class UpdateCardModal extends React.PureComponent {
  static propTypes = {
    instructor:    PropTypes.object.isRequired,
    isOpen:        PropTypes.bool,
    onToggle:      PropTypes.func,
    paymentMethod: PropTypes.object.isRequired,
    updateCard:    PropTypes.func.isRequired,
    user:          PropTypes.object.isRequired
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  state = {
    error: ''
  }

  componentDidUpdate(prevProps) {
    if (prevProps.paymentMethod.id != this.props.paymentMethod.id) this.props.onToggle();
  }

  handleUpdate = (stripe, elements) => async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const { instructor, user } = this.props;
    const card = elements.getElement(CardElement);

    StripeHelper.createPaymentMethod(stripe, card, user, noop, this.setState.bind(this), (paymentMethod) => (
      this.props.updateCard({
        cardId:          this.props.paymentMethod.id,
        paymentMethodId: paymentMethod.id,
        username:        instructor.username
      })
    ));
  }

  renderCard = () => (
    <div className='mt-3'>
      <span className='mr-3'>
        <PaymentIcon icon={this.props.paymentMethod.card.brand} />
      </span>

      <span className='mr-3'>
        {capitalize(this.props.paymentMethod.card.brand)} ****{this.props.paymentMethod.card.last4}&nbsp;
      </span>

      {padStart(this.props.paymentMethod.card.expMonth)}/{String(this.props.paymentMethod.card.expYear).slice(-2)}
    </div>
  );

  renderForm = () => {
    const { instructor } = this.props;

    const stripePromise = () => (
      loadStripe(process.env.STRIPE_API_KEY, { stripeAccount: instructor.stripeId })
    );

    return (
      <Elements stripe={stripePromise()}>
        <ElementsConsumer>
          {({elements, stripe}) => (
            <React.Fragment>

              <Row>
                {this.renderCard()}
              </Row>

              <Row>
                <ChargeForm
                    className='w-100'
                    elements={elements}
                    error={this.state.error}
                    stripe={stripe} />
              </Row>

              <Button
                  onClick={this.handleUpdate(stripe, elements)}
                  onKeyPress={this.handleUpdate(stripe, elements)}
                  type='button'>
                Update Card
              </Button>
            </React.Fragment>
          )}
        </ElementsConsumer>
      </Elements>
    );
  }

  render() {
    return (
      <Modal
          centered
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          Update Card
        </ModalHeader>

        <ModalBody>
          {this.renderForm()}
        </ModalBody>
      </Modal>
    );
  }
}

export default UpdateCardModal;
