import React from 'react';
import PropTypes from 'prop-types';

import { CustomInput } from 'reactstrap';

class CustomRange extends React.PureComponent {
  static propTypes = {
    id:            PropTypes.string,
    initialHeight: PropTypes.number,
    max:           PropTypes.string,
    min:           PropTypes.string,
    name:          PropTypes.string,
    onChange:      PropTypes.func,
    options:       PropTypes.array.isRequired,
    step:          PropTypes.number.isRequired,
    value:         PropTypes.string
  }

  static defaultProps = {
    id:            null,
    initialHeight: 3,
    max:           null,
    min:           null,
    name:          null,
    onChange:      () => {},
    value:         ''
  }

  handleChange = (e) => {
    this.props.onChange(e.target.value);
  }
  
  render () {
    const { options, initialHeight, step, min, max, id, name } = this.props;
    
    const minValue = options[0];
    const maxValue = options.slice(-1)[0];

    return (
      <div className='custom-range-slider'>
        <div className='options'>
          { options.map((option, index) => (
            <span
                className='label'
                key={option}
                style={{ height: initialHeight + index }} />
            ))
          }
        </div>
        <CustomInput
            id={id}
            max={max ? max : maxValue}
            min={min ? min : minValue}
            name={name}
            onChange={this.handleChange}
            step={step}
            type='range'  
            value={this.props.value} />
      </div>
    );
  }
}

export default CustomRange;