import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import {
  activityReducer,
  applicationReducer,
  autocompleterReducer,
  availabilityReducer,
  bookingRequestsReducer,
  bookingsReducer,
  cardsReducer,
  challengesReducer,
  chargesReducer,
  classPacksReducer,
  clientsReducer,
  collectionReducer,
  commentsReducer,
  couponsReducer,
  customizationReducer,
  discountReducer,
  domainReducer,
  donationBookingsReducer,
  donationClassesReducer,
  donationTargetsReducer,
  emailSettingsReducer,
  emailTemplatesReducer,
  errorReducer,
  favoriteOnDemandsReducer,
  feedbackReducer,
  filterReducer,
  followsReducer,
  formatsReducer,
  groupClassesReducer,
  instagramReducer,
  instructorReducer,
  instructorsReducer,
  joinReducer,
  loaderReducer,
  locationReducer,
  locationsReducer,
  membershipsCouponsReducer,
  membershipsDiscountReducer,
  membershipsReducer,
  milestonesReducer,
  partnersReducer,
  passwordReducer,
  plansReducer,
  playlistReducer,
  playlistsReducer,
  privateBookingsReducer,
  privateGroupClassesReducer,
  privateSessionTypesReducer,
  privateSessionsReducer,
  profileDataReducer,
  profileReducer,
  providersReducer,
  purchasedClassesReducer,
  recordingsReducer,
  requestedClassesReducer,
  reviewsReducer,
  samplesResucer,
  searchReducer,
  sessionReducer,
  strategyReducer,
  studioReducer,
  studiosReducer,
  styleTagsReducer,
  subscriptionsReducer,
  synchronizationReducer,
  timeBlocksReducer,
  transactionsReducer,
  userReducer,
  validationReducer,
  visitsReducer
} from '../reducers';

export default (history) => combineReducers({
  activities:          activityReducer,
  app:                 applicationReducer,
  autocompleter:       autocompleterReducer,
  availability:        availabilityReducer,
  bookingRequests:     bookingRequestsReducer,
  bookings:            bookingsReducer,
  cards:               cardsReducer,
  challenges:          challengesReducer,
  charges:             chargesReducer,
  classPacks:          classPacksReducer,
  clients:             clientsReducer,
  collections:         collectionReducer,
  comments:            commentsReducer,
  coupons:             couponsReducer,
  customization:       customizationReducer,
  discount:            discountReducer,
  domain:              domainReducer,
  donationBookings:    donationBookingsReducer,
  donationClasses:     donationClassesReducer,
  donationTargets:     donationTargetsReducer,
  emailSettings:       emailSettingsReducer,
  emailTemplates:      emailTemplatesReducer,
  error:               errorReducer,
  favoriteOnDemands:   favoriteOnDemandsReducer,
  feedback:            feedbackReducer,
  filter:              filterReducer,
  follows:             followsReducer,
  formats:             formatsReducer,
  groupClasses:        groupClassesReducer,
  instagram:           instagramReducer,
  instructor:          instructorReducer,
  instructors:         instructorsReducer,
  join:                joinReducer,
  loader:              loaderReducer,
  location:            locationReducer,
  locations:           locationsReducer,
  memberships:         membershipsReducer,
  membershipsCoupons:  membershipsCouponsReducer,
  membershipsDiscount: membershipsDiscountReducer,
  milestones:          milestonesReducer,
  partners:            partnersReducer,
  password:            passwordReducer,
  plans:               plansReducer,
  playlist:            playlistReducer,
  playlists:           playlistsReducer,
  privateBookings:     privateBookingsReducer,
  privateGroupClasses: privateGroupClassesReducer,
  privateSessionTypes: privateSessionTypesReducer,
  privateSessions:     privateSessionsReducer,
  profile:             profileReducer,
  profileData:         profileDataReducer,
  providers:           providersReducer,
  purchasedClasses:    purchasedClassesReducer,
  recordings:          recordingsReducer,
  requestedClasses:    requestedClassesReducer,
  reviews:             reviewsReducer,
  router:              connectRouter(history),
  samples:             samplesResucer,
  search:              searchReducer,
  session:             sessionReducer,
  strategy:            strategyReducer,
  studio:              studioReducer,
  studios:             studiosReducer,
  styleTags:           styleTagsReducer,
  subscriptions:       subscriptionsReducer,
  synchronization:     synchronizationReducer,
  timeBlocks:          timeBlocksReducer,
  toastr:              toastrReducer,
  transactions:        transactionsReducer,
  user:                userReducer,
  validation:          validationReducer,
  visits:              visitsReducer
});
