import React from 'react';
import PropTypes from 'prop-types';

import { SelectedMonth, WeekDays } from '../index';
import { Calendr } from '../../../lib';

class MonthView extends React.PureComponent {
  static propTypes = {
    selectedDate: PropTypes.object.isRequired,
    today:        PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props) {
    return {
      monthDays: Calendr.getMonthArray(props.selectedDate)
    };
  }

  state = {
    groupClasses:        [],
    monthDays:           [],
    privateGroupClasses: {},
    privateSessions:     {}
  };

  render () {
    return (
      <div className='calendar-wrapper month-view'>
        <WeekDays
            hasDays={false}
            today={this.props.today} />
        <SelectedMonth
            days={this.state.monthDays}
            groupClasses={this.state.groupClasses}
            privateGroupClasses={this.state.privateGroupClasses}
            privateSessions={this.state.privateSessions}
            selectedDate={this.props.selectedDate}
            today={this.props.today} />
      </div>
    );
  }
}

export default MonthView;
