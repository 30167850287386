import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { FEES } from '../../../constants';

import { Button, Checkbox, Form, MaskedInput, Radio } from '../';
import { Input } from '../../inputs';
import { currencyMask, dayMask } from '../../../constants/masks';
import { currencies } from '../../../constants';
import { Scroller, Validator, routes } from '../../../lib';
import { InstructorCancelClassPack, InstructorHideClassPack } from '../../alerts';

class ClassPackForm extends React.PureComponent {
  static propTypes = {
    cancelClassPack: PropTypes.func.isRequired,
    data:            PropTypes.object,
    deleteSample:    PropTypes.func.isRequired,
    isLoading:       PropTypes.bool,
    isNewRecord:     PropTypes.bool,
    onSubmit:        PropTypes.func,
    onToggle:        PropTypes.func.isRequired,
    user:            PropTypes.object.isRequired
  }

  static defaultProps = {
    data: {
      cost:        '',
      creditsType: 'groupClass',
      description: '',
      name:        '',
      number:      '',

      premium: {
        live:           true,
        onDemand:       true,
        privateSession: true
      }
    },
    isLoading:   true,
    isNewRecord: true,
    onSubmit:    () => {}
  };

  static getDerivedStateFromProps(props, state) {
    const { isNewRecord, isLoading } = props;

    if (!isEmpty(state.classPack)) return state;

    if (!isLoading) {
      const classPack = isNewRecord
        ? ClassPackForm.defaultProps.data
        : {
          ...props.data,
          premium: {
            live:     props.data.premium.live           == 'true',
            onDemand: props.data.premium.onDemand       == 'true'
          }
        };

      return {
        classPack,
        isNewRecord,
        visible: props.data.visible
      };
    }

    return null;
  }

  state = {
    classPack: {},
    errors:    {}
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      classPack: {
        ...prevState.classPack,
        [inputName]: value
      }
    }));
  };

  handleToggle = (value, inputName) => {
    this.setState((prevState) => ({
      classPack: {
        ...prevState.classPack,
        premium: {
          ...prevState.classPack.premium,
          [inputName]: value
        }
      }
    }));
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { classPack } = this.state;

    const errors = Validator.validate([
      ['name',        Validator.concepts.isFilled, [classPack.name]],
      ['cost',        Validator.concepts.isFilled, [classPack.cost]],
      ['number',      Validator.concepts.isFilled, [classPack.number]],
      ['description', Validator.concepts.isFilled, [classPack.description]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  })

  handleSubmit = () => {
    const { classPack } = this.state;
    const { user } = this.props;
    const sign = currencies[user.currency].symbol;
    const data = { ...classPack, cost: parseFloat(classPack.cost.toString().replace(sign, '')) };

    return this.handleValidate().then(() => {
      this.props.onSubmit(data);
      return this.props.onToggle();
    });
  }

  handleHide = () => {
    const hideOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <InstructorHideClassPack
            classPack={this.state.classPack}
            isVisible={this.state.visible}
            onClose={onClose}
            onHideClassPack={this.handleSubmit}
            onSuccess={this.props.onToggle} />
    )};
    this.setState((prevState) => ({
      classPack: {...prevState.classPack, visible: !prevState.classPack.visible}
    }), () => {
      confirmAlert(hideOptions);
    });
  }

  handleCancel = () => {
    const deleteOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <InstructorCancelClassPack
            cancelClassPack={this.props.cancelClassPack}
            classPack={this.state.classPack}
            deleteSample={this.props.deleteSample}
            onClose={onClose}
            onSuccess={this.props.onToggle} />
    )};
    confirmAlert(deleteOptions);
  }


  handleTypeSwitch = (type) => () => (
    this.setState((prevState) => ({
      classPack: {
        ...prevState.classPack,
        creditsType: type,
        premium:     {
          live:           type == 'groupClass',
          onDemand:       type == 'groupClass',
          privateSession: type === 'privateSession'
        }
      }
    }))
  )


  render() {
    const { isNewRecord, user } = this.props;
    const { visible, classPack, errors } = this.state;
    const { currency } = user;

    const sign = currencies[user.currency].symbol;

    const amount = classPack.cost && parseFloat(classPack.cost.toString().replace(sign, '')) || 0;
    const fee = FEES.charge(amount);

    return (
      <Form className='form-default'>
        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Class Pack Name
          </Label>
          <Input
              id='name'
              isInvalid={!!errors.name}
              name='name'
              onChange={this.handleChange}
              placeholder='5x Pack'
              type='text'
              value={classPack.name} />
          <FormFeedback tooltip>
            {errors.name}
          </FormFeedback>
        </FormGroup>

        <Row form>
          <Col>
            <FormGroup>
              <Label
                  className='required'
                  for='input-cost'>
                Cost
              </Label>
              <MaskedInput
                  id='cost'
                  inputMode='number'
                  isInvalid={!!errors.cost}
                  mask={currencyMask(sign)}
                  name='cost'
                  onChange={this.handleChange}
                  placeholder={sign + 10}
                  type='number'
                  value={classPack.cost} />
              <FormFeedback tooltip>
                {errors.cost}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for='input-period'># of Classes</Label>
              <Input
                  id='number'
                  isDisabled={!isNewRecord}
                  isInvalid={!!errors.name}
                  name='number'
                  onChange={this.handleChange}
                  placeholder='5'
                  type='text'
                  value={classPack.number} />
            </FormGroup>
          </Col>
        </Row>

        <small className='text-small text-muted'>
          Client pays {parseFloat(amount + fee, 10).toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
          (incl. {parseFloat(fee, 10).toLocaleString('en-GB', { currency, style: 'currency' })} fee)
        </small>

        <Row form>
          <Col>
            <FormGroup>
              <Label for='input-can-expire'>
                Add an Expiration?
              </Label>

              <div className='d-flex'>
                <div className='custom-control custom-checkbox checkbox-lg'>
                  <Checkbox
                      className='custom-control-input'
                      id='can-expire'
                      isChecked={classPack.canExpire}
                      name='canExpire'
                      onChange={this.handleChange} />
                  <Label
                      className='custom-control-label'
                      htmlFor='input-can-expire' />
                </div>

                <MaskedInput
                    className='ml-2 w-50'
                    id='expiry'
                    inputMode='number'
                    isDisabled={!classPack.canExpire}
                    isInvalid={!!errors.expiry}
                    mask={dayMask}
                    name='expiry'
                    onChange={this.handleChange}
                    placeholder=' days'
                    type='number'
                    value={String(classPack.expiry)} />
              </div>
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label
              className='required'
              for='input-description'>
            Class Pack Description
          </Label>
          <Input
              id='description'
              isInvalid={!!errors.description}
              name='description'
              onChange={this.handleChange}
              placeholder='Buy a 5 pack for $25 (vs. $30 usual price)'
              rows='4'
              type='textarea'
              value={classPack.description} />
          <FormFeedback tooltip>
            {errors.description}
          </FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label
              className='required'
              for='input-fee'>
            Credits can be used for
          </Label>

          <div>
            <div className='custom-control custom-radio'>
              <Radio
                  className='custom-control-input'
                  id='groupClass'
                  isChecked={classPack.creditsType === 'groupClass'}
                  name='groupClass'
                  onChange={this.handleTypeSwitch('groupClass')}
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-groupClass'>
                Group Classes
              </Label>
            </div>

            { classPack.creditsType == 'groupClass' &&
            <React.Fragment>
              <div className='custom-control custom-checkbox checkbox-sm'>
                <Checkbox
                    className='custom-control-input'
                    id='premium-live'
                    isChecked={classPack.premium && classPack.premium.live}
                    name='live'
                    onChange={this.handleToggle} />
                <Label
                    className='custom-control-label'
                    htmlFor='input-premium-live'>
                  Live Classes
                </Label>
              </div>

              <div className='custom-control custom-checkbox checkbox-sm'>
                <Checkbox
                    className='custom-control-input'
                    id='premium-on-demand'
                    isChecked={classPack.premium && classPack.premium.onDemand}
                    name='onDemand'
                    onChange={this.handleToggle} />
                <Label
                    className='custom-control-label'
                    htmlFor='input-premium-on-demand'>
                  On Demand Classes
                </Label>
              </div>
            </React.Fragment>

            }

            <div className='custom-control custom-radio'>
              <Radio
                  className='custom-control-input'
                  id='privateSession'
                  isChecked={classPack.creditsType === 'privateSession'}
                  name='privateSession'
                  onChange={this.handleTypeSwitch('privateSession')}
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-privateSession'>
                Private Sessions
              </Label>
            </div>
          </div>
        </FormGroup>

        <div className='text-small text-muted pb-3'>
          Check the box to allow this class pack to be used for live and / or on-demand class.
        </div>

        <div className='text-small text-muted'>
          <strong>
            If checked, this class pack will by default be able to access all of those classes.&nbsp;
          </strong>
          To limit access from an individual live class or on-demand collection you can do
          this on the class or collection settings via&nbsp;
          <Link to={routes.CALENDAR}>
            My Class Schedule
          </Link>
          &nbsp;and&nbsp;
          <Link to={routes.ON_DEMAND}>
            On-Demand
          </Link>
          &nbsp;pages respectively.
        </div>


        { !user.hasStripe &&
          <div className='text-warning text-sm'>
            Note: Your Stripe account is not connected, in order to create a Class Pack connect
            your Stripe account by going to Instructor Settings &gt;&nbsp;
            <Link to={routes.INTEGRATIONS}>
              Integrations
            </Link>
          </div>
        }

        { isNewRecord && user.hasStripe && !user.stripeUpgraded &&
          <div className='text-warning text-sm'>
            Note to use the Class Pack feature you will need to reauthorize your Stripe account.
            Please contact us at&nbsp;
            <a href='mailto:kal@onpodio.com'>kal@onpodio.com</a>&nbsp;
            to assist you with this.
          </div>
        }

        <Row className={isNewRecord ? 'mt-4' : 'mt-2'}>
          { isNewRecord &&
            <Col>
              <Button
                  isBlock
                  isDisabled={!user.hasStripe || (user.hasStripe && !user.stripeUpgraded)}
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Create & Add
              </Button>
            </Col>
          }

          { !isNewRecord &&
            <React.Fragment>
              <Button
                  isBlock
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Update Class Pack
              </Button>
              <div className='w-100 mt-3'>
                <p className='text-small text-muted'>
                  Hide a class pack to remove the package from your website. This will
                  prevent new clients purchasing the pack <strong>BUT WILL NOT</strong> invalidate
                  existing unused credits held by clients.
                </p>
                <Button
                    color='muted'
                    isBlock
                    isOutline
                    onClick={this.handleHide}
                    size='lg'
                    type='button'>
                  { visible ? 'Hide' : 'Show' }&nbsp;
                  Class Pack
                </Button>
              </div>

              <div className='w-100 mt-3'>
                <p className='text-small text-danger'>
                  Cancel a class pack to remove the package from your website. This will
                  prevent new clients purchasing the pack <strong>AND WILL</strong> invalidate
                  existing unused credits held by clients.
                </p>
                <Button
                    color='danger'
                    isBlock
                    isOutline
                    onClick={this.handleCancel}
                    size='lg'
                    type='button'>
                  Cancel Class Pack
                </Button>
              </div>
            </React.Fragment>
          }
        </Row>
      </Form>
    );
  }
}

export default ClassPackForm;
