import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import classNames from 'classnames';

const MAX_CHARACTERS = 524288;

class Mask extends React.PureComponent {
  static propTypes = {
    className:   PropTypes.string,
    id:          PropTypes.string,
    isDisabled:  PropTypes.bool,
    isInvalid:   PropTypes.bool,
    mask:        PropTypes.func,
    maxLength:   PropTypes.number,
    name:        PropTypes.string,
    onChange:    PropTypes.func,
    onRef:       PropTypes.func,
    placeholder: PropTypes.string,
    size:        PropTypes.string,
    value:       PropTypes.string
  }

  static defaultProps = {
    className:   '',
    id:          '',
    isDisabled:  false,
    isInvalid:   false,
    mask:        '',
    maxLength:   MAX_CHARACTERS,
    name:        '',
    onChange:    () => {},
    onRef:       () => {},
    placeholder: '',
    size:        'md',
    value:       ''
  }

  handleChange = (e) => {
    e.preventDefault();
    e.persist();

    const value = e.target.value.match(/(\d+)/);
    const parsedValue = value ? value[0] : '';

    this.props.onChange(parsedValue, this.props.name);
  }

  renderCharactersLeft = () => (
    <small className='text-muted float-right'>
      {this.props.maxLength - String(this.props.value).length} characters left
    </small>
  )

  render() {
    const { className, size } = this.props;

    const id = 'input-' + this.props.id;
    const sizeClass = `form-control-${size}`;
    const inputClass = classNames('form-control', sizeClass, className, { 'is-invalid': this.props.isInvalid });

    return (
      <React.Fragment>
        <MaskedInput
            className={inputClass}
            disabled={this.props.isDisabled}
            id={id}
            mask={this.props.mask}
            name={this.props.name}
            onChange={this.handleChange}
            placeholder={this.props.placeholder}
            ref={this.props.onRef}
            value={this.props.value} />
        {this.props.maxLength !== MAX_CHARACTERS && this.renderCharactersLeft()}
      </React.Fragment>
    );
  }
}

export default Mask;
