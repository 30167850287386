import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { find, map } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { FormatsActionTypes } from '../constants/actionTypes';
import {
  CollectionActions,
  ErrorActions,
  FormatsActions,
  PrivateGroupClassesActions
} from '../actions';

function* watchFormatsPgcCreate() {
  yield takeLatest(FormatsActionTypes.CREATE_PGC_FORMAT, function* ({ payload }) {
    try {
      yield call(api.privateGroupClasses.formats.create, payload);

      const privateGroupClasses = yield select((state) => state.privateGroupClasses.data);

      const data = map(privateGroupClasses, (pgc) => {
        const option = find(payload.options, ['privateGroupClassId', pgc.id]);

        return option ? { ...pgc, formatId: option.formatId } : pgc;
      });

      yield put(PrivateGroupClassesActions.UPDATE_SUCCESS(data));
      toastr.success('Updated');
    } catch (error) {
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchOnDemandFormatsCreate() {
  yield takeLatest(FormatsActionTypes.CREATE_ON_DEMAND_FORMAT, function* ({ payload }) {
    try {
      yield call(api.instructor.requestedClass.formats.create, payload);

      const requestedClasses = yield select((state) => state.collections.onDemands);

      const data = map(requestedClasses, (requestedClass) => {
        const option = find(payload.options, ['instructorOnDemandId', requestedClass.id]);

        return option ? { ...requestedClass, formatId: option.formatId } : requestedClass;
      });

      yield put(CollectionActions.ON_DEMAND_UPDATE_SUCCESS({ data }));

      toastr.success('Updated');
    } catch (error) {
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}


function* watchFormatsFetch() {
  yield takeLatest(FormatsActionTypes.FETCH, function* () {
    try {
      const response = yield call(api.formats.fetch);

      yield put(FormatsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(FormatsActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* privateGroupClassesSaga() {
  yield all([
    watchFormatsPgcCreate(),
    watchOnDemandFormatsCreate(),
    watchFormatsFetch()
  ]);
}
