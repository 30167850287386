/* global moment */
import React from 'react';
import PropTypes from 'prop-types';

class PrivateGroupClass extends React.PureComponent {
  static propTypes = {
    onClick:           PropTypes.func.isRequired,
    privateGroupClass: PropTypes.object.isRequired
  }

  handleClick = () => {
    this.props.onClick(this.props.privateGroupClass);
  }

  render() {
    const { privateGroupClass } = this.props;

    return (
      <div
          className='private-group-class-info p-3 m-1'
          onClick={this.handleClick}
          onKeyPress={this.handleClick}
          role='button'
          tabIndex={0}>
        <div className='d-inline-block'>
          <strong>{privateGroupClass.name}</strong>

          <div className='text-muted text-sm'>
            {privateGroupClass.location.name}
          </div>
        </div>

        <div className='float-right'>
          <strong>
            {new moment(privateGroupClass.startsAt, 'YYYY-MM-DDhh:mm:ss').format('h:mm A')}
          </strong>
        </div>
      </div>
    );
  }
}

export default PrivateGroupClass;
