import React from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/async';

import { selectFieldStyle } from '../../constants';

class AsyncSelect extends React.PureComponent {
  static propTypes = {
    formatOptionLabel: PropTypes.func,
    hasDefaultOptions: PropTypes.bool,
    isDisabled:        PropTypes.bool,
    isSearchable:      PropTypes.bool,
    labelName:         PropTypes.string,
    loadOptions:       PropTypes.func.isRequired,
    name:              PropTypes.string,
    onChange:          PropTypes.func,
    styles:            PropTypes.object,
    value:             PropTypes.oneOfType([PropTypes.object, PropTypes.number, PropTypes.string]),
    valueName:         PropTypes.string
  }

  static defaultProps = {
    formatOptionLabel: null,
    hasDefaultOptions: false,
    inputValue:        '',
    isClearable:       false,
    isDisabled:        false,
    isSearchable:      false,
    labelName:         'label',
    name:              '',
    onChange:          () => {},
    styles:            {},
    value:             '',
    valueName:         'value'
  }
  
  state = {
    inputValue:    '',
    menuIsOpen:    false,
    options:       [],
    selectedValue: ''
  }

 loadOptions = (inputValue, callback) => {
    const { loadOptions } = this.props;
    const options = loadOptions(inputValue);

    setTimeout(() => {
      this.setState({
        menuIsOpen: true
      }, () => {
        callback(options);
      });
    }, 1000);
  }

  handleChange = (option) => {
    this.setState((prevState) => ({
      ...prevState,
      menuIsOpen:    false,
      selectedValue: option
    }), () => {
      this.props.onChange(option);
    });
  }

  handleInputChange = (value) => {
    this.setState({
      inputValue: value
    });
  }

  render () {
    const {
            name,
            labelName,
            valueName,
            formatOptionLabel,
            styles,
            value,
            isDisabled,
            isSearchable,
            hasDefaultOptions
    } = this.props;
    const { selectedValue, menuIsOpen, inputValue } = this.state;

    const getOptionLabel = (option) => option[labelName];
    const getOptionValue = (option) => option[valueName];

    const combineStyles = { ...selectFieldStyle.default, ...styles };

    return (
      <Async
          blurInputOnSelect
          cacheOptions
          classNamePrefix='form-select'
          closeMenuOnSelect
          defaultOptions={hasDefaultOptions}
          formatOptionLabel={formatOptionLabel}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          inputValue={inputValue}
          isDisabled={isDisabled}
          isSearchable={isSearchable}
          loadOptions={this.loadOptions}
          {...isSearchable && { menuIsOpen: menuIsOpen }}
          name={name}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          styles={combineStyles}
          value={selectedValue ? selectedValue : value} />
    );
  }
}

export default AsyncSelect;