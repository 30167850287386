import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { Uploader, api } from '../../lib';

const TYPE_ERROR = 'Invalid file type!';
const SIZE_ERROR = 'File is too big!';

class Upload extends React.PureComponent {
  static propTypes = {
    allowedTypes: PropTypes.array,
    forwardRef:   PropTypes.object,
    maxSizeBytes: PropTypes.number,
    name:         PropTypes.string.isRequired,
    onFinish:     PropTypes.func.isRequired,
    onProgress:   PropTypes.func,
    onStart:      PropTypes.func
  }

  static defaultProps = {
    allowedTypes: ['image/png', 'image/jpeg'],
    forwardRef:   React.createRef(),
    isCrop:       true,
    maxSizeBytes: 5e+6, // 5MB
    onProgress:   () => {},
    onStart:      () => {}
  }

  _validateSize = (file) => new Promise((resolve, reject) => {
    const { maxSizeBytes } = this.props;

    if (file.size < maxSizeBytes) {
      resolve(file);
    } else {
      reject(SIZE_ERROR);
    }
  })

  _validateType = (file) => new Promise((resolve, reject) => {
    const { allowedTypes } = this.props;

    if (includes(allowedTypes, file.type)) {
      resolve(file);
    } else {
      reject(TYPE_ERROR);
    }
  })

  handleCallback = (upload) => {
    this.props.onProgress(upload.progress, this.props.name);
    if (upload.state != 'finished') return;

    this.props.onFinish(upload, this.props.name);
    /*this.handleUpload({ item: {
      name:  upload.name,
      size:  upload.size,
      video: upload.url
    }});*/
  }

  handleSelect = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];

      this._validateType(file)
        .then((file) => this._validateSize(file))
        .then((file) => {
            const upload = new Uploader(file, { onChangeFile: this.handleCallback });

            this.props.onStart();
            return this.handleStart(upload);
        })
        .catch((error) => {
          console.error(error);
          toastr.error(error);
        });
    }
  };

  handleUpload = (payload) => (
    api.item.create(payload)
        .then((response) => this.props.onFinish(response.data, this.props.name))
        .catch((error) => console.error(error))
  )

  handleStart = async (file) => {
    await file.start();
  }

  render() {
    return (
      <React.Fragment>
        <input
            accept={this.props.allowedTypes.join(',')}
            className='d-none'
            multiple={false}
            name={this.props.name}
            onChange={this.handleSelect}
            ref={this.props.forwardRef}
            type='file' />
      </React.Fragment>
    );
  }
}

export default Upload;
