export default {
  ACCEPT:         '@@bookingRequests/ACCEPT',
  ACCEPT_FAILURE: '@@bookingRequests/ACCEPT_FAILURE',
  ACCEPT_SUCCESS: '@@bookingRequests/ACCEPT_SUCCESS',

  CANCEL:         '@@bookingRequests/CANCEL',
  CANCEL_FAILURE: '@@bookingRequests/CANCEL_FAILURE',
  CANCEL_SUCCESS: '@@bookingRequests/CANCEL_SUCCESS',

  DECLINE:         '@@bookingRequests/DECLINE',
  DECLINE_FAILURE: '@@bookingRequests/DECLINE_FAILURE',
  DECLINE_SUCCESS: '@@bookingRequests/DECLINE_SUCCESS',

  FETCH:         '@@bookingRequests/FETCH',
  FETCH_FAILURE: '@@bookingRequests/FETCH_FAILURE',
  FETCH_SUCCESS: '@@bookingRequests/FETCH_SUCCESS',

  INSTRUCTORS_FETCH:         '@@bookingRequests/INSTRUCTORS/FETCH',
  INSTRUCTORS_FETCH_FAILURE: '@@bookingRequests/INSTRUCTORS/FETCH_FAILURE',
  INSTRUCTORS_FETCH_SUCCESS: '@@bookingRequests/INSTRUCTORS/FETCH_SUCCESS',

  INSTRUCTORS_UPDATE:         '@@bookingRequests/INSTRUCTORS/UPDATE',
  INSTRUCTORS_UPDATE_FAILURE: '@@bookingRequests/INSTRUCTORS/UPDATE_FAILURE',
  INSTRUCTORS_UPDATE_SUCCESS: '@@bookingRequests/INSTRUCTORS/UPDATE_SUCCESS'
};
