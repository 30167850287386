import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { map } from 'lodash';

import { api, extractError, toCamelCase } from '../lib';
import { EmailTemplatesActionTypes } from '../constants/actionTypes';
import { EmailTemplatesActions, ErrorActions, LoaderActions } from '../actions';


function* watchFetch() {
  yield takeLatest(EmailTemplatesActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('emailTemplates'));
      const response = yield call(api.email.template.fetch);

      yield put(EmailTemplatesActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(EmailTemplatesActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(EmailTemplatesActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('emailTemplates'));
  });
}

function* watchFetchFailure() {
  yield takeLatest(EmailTemplatesActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('emailTemplates'));
  });
}


function* watchCreate() {
  yield takeLatest(EmailTemplatesActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('emailTemplates'));
      const response = yield call(api.email.template.create, payload);

      yield put(EmailTemplatesActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Template updated!');
    } catch (error) {
      yield put(EmailTemplatesActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(EmailTemplatesActionTypes.CREATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('emailTemplates'));
  });
}

function* watchCreateFailure() {
  yield takeLatest(EmailTemplatesActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('emailTemplates'));
  });
}


function* watchUpdate() {
  yield takeLatest(EmailTemplatesActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('emailTemplates'));
      const response = yield call(api.email.template.update, payload);
      const emailTemplates = yield select((state) => state.emailTemplates.data);

      const data = map(emailTemplates, (template) => (
        template.id === payload.emailTemplate.id ? toCamelCase(response.data) : template
      ));

      yield put(EmailTemplatesActions.UPDATE_SUCCESS(data));
      toastr.success('Template updated!');
    } catch (error) {
      yield put(EmailTemplatesActions.UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(EmailTemplatesActionTypes.UPDATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('emailTemplates'));
  });
}

function* watchUpdateFailure() {
  yield takeLatest(EmailTemplatesActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('emailTemplates'));
  });
}

export default function* emailTemplatesSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure()
  ]);
}
