export default (amount, currency) => {
  const options = {
    currency:    currency,
    style:       'currency',
    useGrouping: true
  };

  const partsArray = new Intl.NumberFormat('en-US', options).formatToParts(amount / 100 || 0);
  partsArray.splice(1, 0, { type: 'space', value: ' '});

  return partsArray.map((part) => {
    return part.value;
  }).join('');
};