/* eslint-disable promise/catch-or-return */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { filter, isEmpty, map} from 'lodash';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Button, Form, MaskedInputInteger, Radio, Select } from '../';
import { Input } from '../../inputs';
import { currencyMaskInteger, procentMask } from '../../../constants/masks';
import { currencies } from '../../../constants';
import { Moment, Scroller, Validator } from '../../../lib';

class DonationTargetForm extends React.PureComponent {
  static propTypes = {
    createDonationTarget: PropTypes.func.isRequired,
    donationClasses:      PropTypes.array.isRequired,
    donationTargets:      PropTypes.array.isRequired,
    updateDonationTarget: PropTypes.func.isRequired,
    user:                 PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const { donationTargets } = props;
    const { privateGroupClasses } = props.donationTargets;

    if (!state.isMounted && donationTargets.id) {
      return {
        donationTarget: donationTargets.id ?
        {
          ...donationTargets,
          privateGroupClassIds: map(privateGroupClasses, (privateGroupClass) => (privateGroupClass.id)
        )} : state.donationTarget,
        errors:                  state.errors,
        isMounted:               true,
        selectedDonationClasses: privateGroupClasses
      };
    }
  }


  state = {
    donationClasses: map(this.props.donationClasses,
                    (donationClass) => ({
                      id:   donationClass.id,
                      name: donationClass.name +
                      ` (${new Moment(donationClass.startsAt, 'YYYY-MM-DDhh:mm:ss').tz(this.props.user.timeZone)
                                                                                   .format('DD/MM/YY')})`
                    })),
    donationTarget: {
      contributionAmount:   '',
      contributionType:     'procent',
      name:                 '',
      privateGroupClassIds: [],
      target:               ''
    },
    errors:                  {},
    isMounted:               false,
    selectedDonationClasses: []
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      donationTarget: {
        ...prevState.donationTarget,
        [inputName]: value
      }
    }));
  };

  handleSelect = (option) => {
    const donationClass = filter(this.props.donationClasses, (donationClass) => (donationClass.id === option));

    this.setState((prevState) => ({
      donationTarget: {
        ...prevState.donationTarget,
        privateGroupClassIds: [ ...prevState.donationTarget.privateGroupClassIds, option ]
      },
      selectedDonationClasses: [...prevState.selectedDonationClasses, donationClass[0]]
    }));
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const { donationTarget } = this.state;

    const isProcent = donationTarget.contributionType == 'procent';

    const errors = Validator.validate([
      ['name', Validator.concepts.isFilled, [donationTarget.name]],
      ['target', Validator.concepts.isFilled, [donationTarget.target]],
      ['contributionAmount',   Validator.concepts.isFilled, [donationTarget.contributionAmount]],
      ['contributionAmount', Validator.concepts.moreThanHundred, [donationTarget.contributionAmount], !isProcent]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  })

  handleSubmit = () => {
    const { donationTarget } = this.state;
    const { updateDonationTarget, createDonationTarget } = this.props;

    this.handleValidate().then(() => (
      donationTarget.id ? updateDonationTarget({ donationTarget}) : createDonationTarget({ donationTarget })
    ));
  };

  handleContributeTypeSwitch = (contributionType) => () => (
    this.setState((prevState) => ({
      donationTarget: {
        ...prevState.donationTarget,
        contributionAmount: '',
        contributionType
      }
    }))
  )

  renderAmountInput = () => {
    const sign = currencies[this.props.user.currency].symbol;
    const { donationTarget, errors } = this.state;

    if (donationTarget.contributionType === 'procent') {
      return (
        <Row form>
          <Col sm={3}>
            <MaskedInputInteger
                id='contributionAmount'
                inputMode='number'
                isInvalid={!!errors.contributionAmount}
                mask={procentMask}
                name='contributionAmount'
                onChange={this.handleChange}
                placeholder='20%'
                type='contributionAmount'
                value={String(donationTarget.contributionAmount)} />
          </Col>

          <Col sm={9}>
            <Label className='text-lowercase'>
              of class bookings
            </Label>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row form>
          <Col sm={3}>
            <MaskedInputInteger
                id='contributionAmount'
                inputMode='number'
                isInvalid={!!errors.contributionAmount}
                mask={currencyMaskInteger(sign)}
                name='contributionAmount'
                onChange={this.handleChange}
                placeholder={sign + 2}
                type='contributionAmount'
                value={String(donationTarget.contributionAmount)} />
          </Col>

          <Col sm={9}>
            <Label className='text-lowercase'>
              per class bookings
            </Label>
          </Col>
        </Row>
      );
    }
  }

  renderSelectedDonationClasses = (selectedClass) => (
    <div
        className='selectedClass mb-1, mt-1'
        key={selectedClass.id}>
      <span className='name'>
        {selectedClass.name}
        &nbsp;({new Moment(selectedClass.startsAt).tz(this.props.user.timeZone).format('DD/MM/YY')})
      </span>
      <Icon
          className='mt-1'
          fixedWidth
          icon='times'
          onClick={() => this.handleCancelSelectedClasses(selectedClass.id)}
        />
    </div>
  )

  handleCancelSelectedClasses = (id) => (
    this.setState((prevState) => ({
      donationTarget: {
        ...prevState.donationTarget,
        privateGroupClassIds: filter(prevState.donationTarget.privateGroupClassIds,
                                    (privateGroupClassId) => (privateGroupClassId !== id))
      },
      selectedDonationClasses: filter(prevState.selectedDonationClasses,
                                     (selectedDonationClass) => (selectedDonationClass.id !== id))
    }))
  )

  render() {
    const { donationTarget, donationClasses, errors } = this.state;
    const sign = currencies[this.props.user.currency].symbol;

    return (
      <Form className='form-default'>
        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Donation Name
          </Label>

          <Input
              id='name'
              isInvalid={!!errors.name}
              name='name'
              onChange={this.handleChange}
              placeholder='Summer Fundrainser'
              value={donationTarget.name} />
          <FormFeedback tooltip>
            {errors.name}
          </FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label
              className='required'
              for='input-cost'>
            Donation Target
          </Label>

          <MaskedInputInteger
              id='target'
              inputMode='number'
              isInvalid={!!errors.target}
              mask={currencyMaskInteger(sign)}
              name='target'
              onChange={this.handleChange}
              placeholder={sign + 2000}
              type='number'
              value={String(donationTarget.target)} />
          <FormFeedback tooltip>
            {errors.target}
          </FormFeedback>
        </FormGroup>

        <FormGroup>
          <h6 className='modal-title mt-3'>
            How to calculate the donation contribution?
          </h6>

          <Label for='input-fee'>
            I will donate a...
          </Label>

          <div className='mt-2 custom-control custom-radio w-100'>
            <Radio
                className='custom-control-input'
                id='online'
                isChecked={donationTarget.contributionType === 'procent'}
                onChange={this.handleContributeTypeSwitch('procent')}
                value={false} />
            <Label
                className='custom-control-label text-lowercase'
                htmlFor='input-online'>
              % of class bookings
            </Label>
          </div>

          <div className='custom-control custom-radio w-10'>
            <Radio
                className='custom-control-input'
                id='offline'
                isChecked={donationTarget.contributionType === 'money'}
                onChange={this.handleContributeTypeSwitch('money')}
                value={false} />
            <Label
                className='custom-control-label text-lowercase'
                htmlFor='input-offline'>
              $ per class bookings
            </Label>
          </div>
        </FormGroup>

        <FormGroup>
          <Label
              className='required'
              for='input-cost'>
            Equal to...
          </Label>

          {this.renderAmountInput()}

          <FormGroup>
            <Label
                className='required'
                for='input-session-type'>
              From the following classes..
            </Label>

            <Select
                disabledOptionType='tier'
                disabledOptionValues={['tier_1', 'tier_2']}
                labelName='name'
                name='locationId'
                onChange={this.handleSelect}
                options={donationClasses}
                placeholder='Select class'
                valueName='id' />
          </FormGroup>
        </FormGroup>

        {this.state.selectedDonationClasses.map(this.renderSelectedDonationClasses)}

        <Row className='mt-4'>
          <Col>
            <Button
                isBlock
                onClick={this.handleSubmit}
                size='lg'
                type='button'>
              {donationTarget.id ? 'Update Donation Target' : 'Set Donation Target'}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  }
}

export default DonationTargetForm;
