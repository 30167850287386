import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { AddProfilePage } from '../components/pages';
import {
  ActivityActions,
  AdditionalInformationActions,
  InvitesActions,
  LocationsActions,
  OAuthActions,
  PrivateGroupClassesActions,
  ProfileActions,
  ProfileDataActions,
  SegmentActions,
  UserActions,
  ValidationActions
} from '../actions';

class AddProfileContainer extends React.PureComponent {
  static propTypes = {
    getActivities:  PropTypes.func.isRequired,
    getProfileData: PropTypes.func.isRequired,
    location:       PropTypes.object.isRequired,
    profile:        PropTypes.object.isRequired,
    user:           PropTypes.object.isRequired
  }

  componentDidMount() {
    this.props.getProfileData();
    this.props.getActivities();
  }

  render() {
    return <AddProfilePage {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  activities:          state.activities.data,
  join:                state.join,
  location:            state.location,
  privateGroupClasses: state.privateGroupClasses.data,
  profile:             state.profile,
  profileData:         state.profileData,
  user:                state.user.data,
  validation:          state.validation
});

const mapDispatchToProps = (dispatch) => ({
  createAdditionalnfo:      (data) => dispatch(AdditionalInformationActions.CREATE(data)),
  createInvites:            (data) => dispatch(InvitesActions.CREATE(data)),
  createLocation:           (data) => dispatch(LocationsActions.CREATE(data)),
  createProfile:            (data) => dispatch(ProfileActions.CREATE(data)),
  fetchPrivateGroupClasses: () =>     dispatch(PrivateGroupClassesActions.FETCH()),
  fetchUser:                () =>     dispatch(UserActions.FETCH()),
  finishCreate:             () =>     dispatch(ProfileActions.CREATE_FINISH()),
  getActivities:            () =>     dispatch(ActivityActions.GET_ALL()),
  getProfileData:           () =>     dispatch(ProfileDataActions.GET()),
  oAuthLogout:              (data) => dispatch(OAuthActions.LOGOUT(data)),
  resetValidation:          () =>     dispatch(ValidationActions.RESET({ subject: 'instructor' })),
  segmentStripe:            (data) => dispatch(SegmentActions.STRIPE_CONNECT(data)),
  segmentZoom:              (data) => dispatch(SegmentActions.ZOOM_CONNECT(data)),
  updateProfile:            (data) => dispatch(ProfileActions.UPDATE(data)),
  validateUsername:         (data) => dispatch(ValidationActions.VALIDATE(data, { subject: 'instructor' }))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProfileContainer);
