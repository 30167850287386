export default {
  CREATE:         '@@donationTargets/CREATE',
  CREATE_FAILURE: '@@donationTargets/CREATE_FAILURE',
  CREATE_SUCCESS: '@@donationTargets/CREATE_SUCCESS',

  FETCH:         '@@donationTargets/FETCH',
  FETCH_FAILURE: '@@donationTargets/FETCH_FAILURE',
  FETCH_SUCCESS: '@@donationTargets/FETCH_SUCCESS',

  INSTRUCTORS_FETCH:         '@@donationTargets/INSTRUCTORS/FETCH',
  INSTRUCTORS_FETCH_FAILURE: '@@donationTargets/INSTRUCTORS/FETCH_FAILURE',
  INSTRUCTORS_FETCH_SUCCESS: '@@donationTargets/INSTRUCTORS/FETCH_SUCCESS',

  UPDATE:         '@@donationTargets/UPDATE',
  UPDATE_FAILURE: '@@donationTargets/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@donationTargets/UPDATE_SUCCESS'
};
