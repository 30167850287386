import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Moment, getTimeZone } from '../../lib';

import { Controls, DayView, MonthView, TimeZone, WeekView } from './';

class Calendar extends React.PureComponent {
  static propTypes = {
    children:           PropTypes.node,
    events:             PropTypes.array,
    hasViewSelect:      PropTypes.bool,
    isLocationSchedule: PropTypes.bool,
    isPlanner:          PropTypes.bool,
    onEventClick:       PropTypes.func,
    user:               PropTypes.object.isRequired
  };

  static defaultProps = {
    children:           null,
    events:             [],
    hasViewSelect:      true,
    isLocationSchedule: false,
    isPlanner:          false,
    onEventClick:       () => {}
  };

  static getDerivedStateFromProps(props) {
    const { events } = props;

    return { events };
  }

  componentDidMount () {
    const today = new Moment.tz(getTimeZone(this.props.user.timeZone)).format();

    this.setState({
      selectedDate: Moment.tz(today, getTimeZone(this.props.user.timeZone)).startOf('day'),
      todayDate:    Moment.tz(today, getTimeZone(this.props.user.timeZone))
    });
  }


  state = {
    events:         [],
    isCalendarOpen: true,
    selectedDate:   new Moment().startOf('day'),
    selectedView:   isMobile ? 'day' : 'week',
    todayDate:      new Moment()
  };

  renderView = () => {
    const { events, selectedDate, todayDate, isCalendarOpen } = this.state;

    const plannerTitle = this.props.isLocationSchedule ? 'My Studios classes' : 'My Instructor\'s classes';

    switch (this.state.selectedView) {
      case 'day':
        return (
          <DayView
              events={events}
              isCalendarOpen={this.state.isCalendarOpen}
              isMobile={isMobile}
              isPlanner={this.props.isPlanner}
              onCalendarToggle={this.handleCalendarCollapse}
              onEventClick={this.props.onEventClick}
              plannerTitle={plannerTitle}
              selectedDate={this.state.selectedDate}
              user={this.props.user}>
            {this.props.children}
          </DayView>
        );

      case 'week':
        return (
          <WeekView
              events={events}
              isCalendarOpen={isCalendarOpen}
              isMobile={isMobile}
              isPlanner={this.props.isPlanner}
              onCalendarToggle={this.handleCalendarCollapse}
              onEventClick={this.props.onEventClick}
              plannerTitle={plannerTitle}
              selectedDate={selectedDate}
              todayDate={todayDate}
              user={this.props.user} >
            {this.props.children}
          </WeekView>
        );

      case 'month':
        return (
          <MonthView
              selectedDate={this.state.selectedDate}
              today={this.state.today} />
        );

      default:
        return null;
    }
  };

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value });
  };

  handleViewChange = (option) => {
    this.setState({ selectedView: option });
  };

  handleCalendarCollapse = () => {
    this.setState((prevState) => ({ isCalendarOpen: !prevState.isCalendarOpen }));
  };

  render() {
    const style = isMobile ?  'calendar-control' : null;
    return (
      <div className='calendar'>
        <div className={style}>
          <Controls
              hasViewSelect={this.props.hasViewSelect}
              isMobile={isMobile}
              onDateChange={this.handleChange}
              onViewChange={this.handleViewChange}
              selectedDate={this.state.selectedDate}
              selectedView={this.state.selectedView}
              today={this.state.todayDate} />
          <TimeZone timeZone={this.props.user.timeZone} />
        </div>
        <div className='content'>
          {this.renderView()}
        </div>
      </div>
    );
  }
}

export default Calendar;
