import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import { isEmpty, omit } from 'lodash';

import { DateHelper, Moment, getTimeZone } from '../../../lib';
import { PrivateGroupClassForm } from '../../forms/calendar';
import { EmailNotificationAlert, RecurringUpdateAlert } from '../../alerts';

class PrivateGroupClassModal extends React.PureComponent {
  static propTypes = {
    createClass:       PropTypes.func.isRequired,
    createRecurring:   PropTypes.func.isRequired,
    fetchPlaylists:    PropTypes.func.isRequired,
    formats:           PropTypes.array.isRequired,
    instructor:        PropTypes.object.isRequired,
    isOpen:            PropTypes.bool.isRequired,
    onToggle:          PropTypes.func.isRequired,
    playlists:         PropTypes.array.isRequired,
    privateGroupClass: PropTypes.object,
    subscription:      PropTypes.object.isRequired,
    updateClass:       PropTypes.func.isRequired,
    updateRecurring:   PropTypes.func.isRequired,
    user:              PropTypes.object.isRequired
  }

  static defaultProps = {
    privateGroupClass: {
      date:          '',
      description:   '',
      digitalLink:   '',
      duration:      '',
      eventType:     1,
      fee:           '',
      feeType:       1,
      imageType:     'photo',
      intensityType: 0,
      locationId:    '',
      meetingId:     '',
      name:          '',
      numberOfWeeks: '',
      partnerId:     '',
      recurring:     false,
      spots:         '',
      time:          ''
    }
  }

  static getDerivedStateFromProps(props, state) {
    const defaultFeeType = props.user.oauth.stripe ? 1 : 0;
    const feeType = props.privateGroupClass.id ? props.privateGroupClass.feeType : defaultFeeType;

    if (isEmpty(state.privateGroupClass)) {
      return {
        ...state,
        privateGroupClass: { ...props.privateGroupClass, feeType }
      };
    }

    return null;
  }

  state = {
    formData:          {},
    privateGroupClass: {},
    sendEmail:         false
  }

  handleUpdateOne = () => {
    const { sendEmail, formData } = this.state;
    const { user } = this.props;
    const timeZone = getTimeZone(user.timeZone);

    const privateGroupClass = {
      ...omit(formData.privateGroupClass, ['date', 'time', 'recurring', 'numberOfWeeks'])
    };

    const startsAt = new Moment.tz(formData.privateGroupClass.startsAt, 'YYYY-MM-DDhh:mm:ss', timeZone).format();

    if (formData.privateGroupClass.recurring) {
      const recurrenceParams = {
        entity:   'private_group_class',
        interval: 'week',
        name:     privateGroupClass.name,
        startsAt,
        total:    formData.privateGroupClass.numberOfWeeks
      };

      this.props.updateClass({ privateGroupClass: {
        ...privateGroupClass,
        recurrenceParams,
        sendEmail,
        startsAt
      }});
    } else {
      this.props.updateClass({ privateGroupClass: {
        ...privateGroupClass,
        sendEmail,
        startsAt
      }});
    }
  }

  handleUpdateRecurring = () => {
    const { user } = this.props;
    const { formData } = this.state;
    const timeZone = getTimeZone(user.timeZone);

    const privateGroupClass = {
      ...omit(formData.privateGroupClass, ['id', 'date', 'time', 'recurring', 'numberOfWeeks', 'startsAt'])
    };

    const date = DateHelper.summerTimeCheck(this.state.privateGroupClass, timeZone);

    const oldDate = Moment.utc(date, 'YYYY-MM-DDhh:mm:ss').tz(timeZone);

    const diff = Moment(formData.privateGroupClass.startsAt).diff(oldDate, 'seconds');
    const id = this.state.privateGroupClass.recurrenceId;

    const recurrence = {
      diff:  diff,
      id:    id,
      name:  formData.privateGroupClass.name,
      total: formData.privateGroupClass.numberOfWeeks
    };

    this.props.updateRecurring({ recurrence: { ...recurrence, recurringParams: privateGroupClass }});
  }

  handleUpdate = () => {
    const { privateGroupClass } = this.state;

    const updateOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => {
        return (
          <RecurringUpdateAlert
              onClose={onClose}
              onUpdate={this.handleUpdateOne}
              onUpdateAll={this.handleUpdateRecurring} />
        );
      }
    };

    privateGroupClass.recurring
      ? confirmAlert(updateOptions)
      : this.handleUpdateOne();

    this.props.onToggle();
  }

  handleEmail = (sendEmail) => () => {
    this.setState({ sendEmail }, this.handleUpdate);
  }

  showEmailConfirmation = () => {
    const { privateGroupClass } = this.state;

    const emailConfirmOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => {
        return (
          <EmailNotificationAlert
              onClose={onClose}
              onSend={this.handleEmail(true)}
              onSkip={this.handleEmail(false)} />
        );
      }
    };

    isEmpty(privateGroupClass.bookings)
      ? this.handleUpdate()
      : confirmAlert(emailConfirmOptions);
  }

  handleCreate = () => {
    const { formData } = this.state;

    formData.privateGroupClass.recurring
      ? this.handleCreateRecurring()
      : this.handleCreateOne();

    this.props.onToggle();
  }

  handleCreateOne = () => {
    const { formData } = this.state;

    this.props.createClass({ privateGroupClass: {
      ...omit(formData.privateGroupClass, ['date', 'time', 'recurring', 'numberOfWeeks'])
    }});
  }

  handleCreateRecurring = () => {
    const { formData } = this.state;

    this.props.createRecurring({ recurrence: {
      entity:   'private_group_class',
      interval: 'week',
      name:     formData.privateGroupClass.name,

      recurringParams: {
        ...omit(formData.privateGroupClass, ['date', 'time', 'recurring', 'numberOfWeeks', 'startsAt'])
      },

      startsAt: formData.privateGroupClass.startsAt,
      total:    formData.privateGroupClass.numberOfWeeks
    }});
  }

  handleSubmit = (formData) => {
    this.setState({ formData }, () => {
      const isNewRecord = !this.state.formData.privateGroupClass.id;

      isNewRecord
        ? this.handleCreate()
        : this.showEmailConfirmation();
    });
  }

  componentDidMount () {
    this.props.fetchPlaylists();
  }

  render() {
    const { instructor, user, playlists } = this.props;
    const { privateGroupClass } = this.state;

    return (
      <Modal
          className='modal-private-group-class'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          {privateGroupClass.id ? 'Update': 'New'} In-Person / Virtual Group Class
        </ModalHeader>

        <ModalBody>
          <PrivateGroupClassForm
              data={privateGroupClass}
              fetchPlaylists={this.props.fetchPlaylists}
              formats={this.props.formats}
              instructor={instructor}
              onSubmit={this.handleSubmit}
              playlists={playlists}
              subscription={this.props.subscription}
              user={user} />
        </ModalBody>
      </Modal>
    );
  }
}

export default PrivateGroupClassModal;
