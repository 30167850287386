import { AvailabilityActionTypes } from '../constants/actionTypes';

export default {
  GET: (payload) => ({
    payload, type: AvailabilityActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: AvailabilityActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload, type: AvailabilityActionTypes.GET_SUCCESS
  })
};
