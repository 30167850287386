import { CollectionActionTypes } from '../constants/actionTypes';

const initialState = {
  allOnDemands: [],
  collection:   {},
  data:         [],
  folders:      [],
  form:         {},
  isLoading:    true,
  lastAction:   '',
  onDemands:    []
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case CollectionActionTypes.FOLDER_FETCH:
      return { ...newState, isLoading: true };
  
    case CollectionActionTypes.FOLDER_FETCH_SUCCESS:
      return { ...newState, folders: action.payload, isLoading: false };
  
    case CollectionActionTypes.FOLDER_FETCH_FAILURE:
      return { ...newState, isLoading: false };
  
  
    case CollectionActionTypes.COLLECTION_FETCH:
      // NOTE: drag&drop does not work correctly without force clearing onDemands array
      return { ...newState, data: [], isLoading: true };

    case CollectionActionTypes.COLLECTION_FETCH_SUCCESS:
      return { ...newState, data: action.payload, isLoading: false };

    case CollectionActionTypes.COLLECTION_FETCH_FAILURE:
      return { ...newState, isLoading: false };


    case CollectionActionTypes.COLLECTION_CREATE:
      return { ...newState, isLoading: true };

    case CollectionActionTypes.COLLECTION_CREATE_SUCCESS:
      return { ...newState, data: [action.payload, ...state.data], isLoading: false };

    case CollectionActionTypes.COLLECTION_CREATE_FAILURE:
      return { ...newState, isLoading: false };


    case CollectionActionTypes.COLLECTION_GET:
      return newState;

    case CollectionActionTypes.COLLECTION_GET_SUCCESS:
      return { ...newState, collection: action.payload };

    case CollectionActionTypes.COLLECTION_GET_FAILURE:
      return newState;


    case CollectionActionTypes.COLLECTION_UPDATE:
      return newState;

    case CollectionActionTypes.COLLECTION_UPDATE_SUCCESS:
      return { ...newState, collection: action.payload };

    case CollectionActionTypes.COLLECTION_UPDATE_FAILURE:
      return newState;


    case CollectionActionTypes.COLLECTION_DELETE:
      return { ...newState, isLoading: true };

    case CollectionActionTypes.COLLECTION_DELETE_SUCCESS:
      return { ...newState, data: action.payload, isLoading: false };

    case CollectionActionTypes.COLLECTION_DELETE_FAILURE:
      return { ...newState, isLoading: false };


    case CollectionActionTypes.ON_DEMAND_CREATE:
      return { ...newState, isLoading: true };
    
    case CollectionActionTypes.ON_DEMAND_CREATE_SUCCESS:
      return { ...newState, isLoading: false, onDemands: [action.payload, ...state.onDemands] };
    
    case CollectionActionTypes.ON_DEMANDS_CREATE_SUCCESS:
      return { ...newState, allOnDemands: [action.payload, ...state.allOnDemands], isLoading: false };
    
    case CollectionActionTypes.ON_DEMAND_CREATE_FAILURE:
      return { ...newState, isLoading: false };

  
    case CollectionActionTypes.ON_DEMAND_DELETE:
      return newState;

    case CollectionActionTypes.ON_DEMAND_DELETE_SUCCESS:
      return { ...newState, form: {}, onDemands: action.payload };

    case CollectionActionTypes.ON_DEMAND_DELETE_FAILURE:
      return newState;


    case CollectionActionTypes.COLLECTION_ON_DEMANDS_FETCH:
      return { ...newState, isLoading: true, onDemands: [] };
    
    case CollectionActionTypes.COLLECTION_ON_DEMANDS_FETCH_SUCCESS:
      return { ...newState, isLoading: false, onDemands: action.payload };
    
    case CollectionActionTypes.COLLECTION_ON_DEMANDS_FETCH_FAILURE:
      return { ...newState, isLoading: false };


    case CollectionActionTypes.ON_DEMAND_UPDATE:
      return { ...newState, isLoading: true };
    
    case CollectionActionTypes.ON_DEMAND_UPDATE_SUCCESS:
      return { ...newState, form: action.payload.form, isLoading: false, onDemands: action.payload.data };
    
    case CollectionActionTypes.ON_DEMAND_UPDATE_FAILURE:
      return { ...newState, isLoading: false, onDemands: initialState.onDemands };


    case CollectionActionTypes.ON_DEMANDS_FETCH:
      return { ...newState, allOnDemands: [], isLoading: true };

    case CollectionActionTypes.ON_DEMANDS_FETCH_SUCCESS:
      return { ...newState, allOnDemands: action.payload, isLoading: false };

    case CollectionActionTypes.ON_DEMANDS_FETCH_FAILURE:
      return { ...newState, allOnDemands: initialState.data, isLoading: false };
  
    default: return state;
  }
};
