import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { filter, map, reject } from 'lodash';
import { push } from 'connected-react-router';

import { api, extractError, routes, toCamelCase } from '../lib';
import { CollectionActionTypes } from '../constants/actionTypes';
import { CollectionActions, ErrorActions, LoaderActions } from '../actions';

function* watchFoldersFetch() {
  yield takeLatest(CollectionActionTypes.FOLDER_FETCH, function* ({ payload }) {
    try {
      const response = yield call(api.collection.folder.fetch, payload);

      yield put(CollectionActions.FOLDER_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(CollectionActions.FOLDER_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCollectionsFetch() {
  yield takeLatest(CollectionActionTypes.COLLECTION_FETCH, function* () {
    try {
      const response = yield call(api.collection.fetch);

      yield put(CollectionActions.COLLECTION_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(CollectionActions.COLLECTION_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCollectionsCreate() {
  yield takeLatest(CollectionActionTypes.COLLECTION_CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.collection.create, payload);

      yield put(CollectionActions.COLLECTION_CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Collection created!');
    } catch (error) {
      yield put(CollectionActions.COLLECTION_CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCollectionGet() {
  yield takeLatest(CollectionActionTypes.COLLECTION_GET, function* ({ payload }) {
    try {
      const response = yield call(api.collection.get, payload);

      yield put(CollectionActions.COLLECTION_GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(CollectionActions.COLLECTION_GET_FAILURE(error));
    }
  });
}

function* watchCollectionsGetFailure() {
  yield takeLatest(CollectionActionTypes.COLLECTION_GET_FAILURE, function* ({ payload }) {
    if (payload.response.status === 403) {
      yield put(push(routes.ON_DEMAND));
    }
    yield put(ErrorActions.NEW(extractError(payload)));
  });
}

function* watchCollectionsUpdate() {
  yield takeLatest(CollectionActionTypes.COLLECTION_UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.collection.update, payload);

      yield put(CollectionActions.COLLECTION_UPDATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Collection updated!');
    } catch (error) {
      yield put(CollectionActions.COLLECTION_UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCollectionsDelete() {
  yield takeLatest(CollectionActionTypes.COLLECTION_DELETE, function* ({ payload }) {
    try {
      yield call(api.collection.delete, payload);
      const collections = yield select((state) => state.collections.data);

      const data = reject(collections, ['id', payload.id]);

      yield put(CollectionActions.COLLECTION_DELETE_SUCCESS(data));
      yield put(push(routes.ON_DEMAND));
      toastr.success('Collection deleted!');
    } catch (error) {
      yield put(CollectionActions.COLLECTION_DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchOnDemandsFetch() {
  yield takeLatest(CollectionActionTypes.ON_DEMANDS_FETCH, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.requestedClass.fetch);

      yield put(CollectionActions.ON_DEMANDS_FETCH_SUCCESS(toCamelCase(response.data)));
      yield put(LoaderActions.FINISH_LOADING());
    } catch (error) {
      yield put(CollectionActions.ON_DEMANDS_FETCH_FAILURE());
      yield put(LoaderActions.FINISH_LOADING());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCollectionOnDemandsFetch() {
  yield takeLatest(CollectionActionTypes.COLLECTION_ON_DEMANDS_FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.collection.onDemand.fetch, payload);

      yield put(CollectionActions.COLLECTION_ON_DEMANDS_FETCH_SUCCESS(toCamelCase(response.data)));
      yield put(LoaderActions.FINISH_LOADING());
    } catch (error) {
      yield put(CollectionActions.COLLECTION_ON_DEMANDS_FETCH_FAILURE());
      yield put(LoaderActions.FINISH_LOADING());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchOnDemandCreate() {
  yield takeLatest(CollectionActionTypes.ON_DEMAND_CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.instructor.requestedClass.create, payload);

      yield put(CollectionActions.ON_DEMAND_CREATE_SUCCESS(toCamelCase(response.data)));
      yield put(CollectionActions.ON_DEMANDS_CREATE_SUCCESS(toCamelCase(response.data)));
      yield put(LoaderActions.FINISH_LOADING());
      toastr.success('Class on demand added');
    } catch (error) {
      yield put(CollectionActions.ON_DEMAND_CREATE_FAILURE());
      yield put(LoaderActions.FINISH_LOADING());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchOnDemandUpdate() {
  yield takeLatest(CollectionActionTypes.ON_DEMAND_UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.requestedClass.update, payload);
      const onDemands = yield select((state) => state.collections.onDemands);
      const collection = yield select((state) => state.collections.collection);

      const form = toCamelCase(response.data);
      const interimData = map(onDemands, (onDemand) => (
        onDemand.id == payload.id ? form : onDemand
      ));

      const data = filter(interimData, ['collectionId', collection.id]);

      yield put(CollectionActions.ON_DEMAND_UPDATE_SUCCESS(toCamelCase({ data, form })));
      yield put(LoaderActions.FINISH_LOADING());
      toastr.success('Class on demand updated');
    } catch (error) {
      yield put(CollectionActions.ON_DEMAND_UPDATE_FAILURE());
      yield put(LoaderActions.FINISH_LOADING());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchOnDemandDelete() {
  yield takeLatest(CollectionActionTypes.ON_DEMAND_DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.instructor.requestedClass.delete, payload);

      const onDemands = yield select((state) => state.collections.onDemands);
      const data = filter(onDemands, (onDemand) => (onDemand.id != payload.id));
      
      yield put(CollectionActions.ON_DEMAND_DELETE_SUCCESS(data));
      yield put(LoaderActions.FINISH_LOADING());
      toastr.success('Class on demand deleted');
    } catch (error) {
      yield put(CollectionActions.ON_DEMAND_DELETE_FAILURE());
      yield put(LoaderActions.FINISH_LOADING());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchSendEmail() {
  yield takeLatest(CollectionActionTypes.SEND_EMAIL, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.user.onDemands.bulk.create, payload);

      yield put(CollectionActions.SEND_EMAIL_SUCCESS(toCamelCase(response.data)));
      yield put(LoaderActions.FINISH_LOADING());
      toastr.success('Email sent');
    } catch (error) {
      yield put(CollectionActions.SEND_EMAIL_FAILURE());
      yield put(LoaderActions.FINISH_LOADING());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* collectionsSaga() {
  yield all([
    watchFoldersFetch(),
    watchCollectionsFetch(),
    watchCollectionsCreate(),
    watchCollectionGet(),
    watchCollectionsUpdate(),
    watchCollectionsDelete(),
    watchCollectionsGetFailure(),
    watchCollectionOnDemandsFetch(),
    watchOnDemandsFetch(),
    watchOnDemandCreate(),
    watchOnDemandUpdate(),
    watchOnDemandDelete(),
    watchSendEmail()
  ]);
}
