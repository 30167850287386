import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';
import classNames from 'classnames';
import { filter, groupBy, map, sortBy, take, uniqBy } from 'lodash';

import { RequestedClass, RequestedClassElegant } from '../pages/profile';
import { CollapseIcon } from '../icons';
import { Button } from '../forms';

class CollectionCollapse extends PureComponent {
  static propTypes = {
    challenges:       PropTypes.array.isRequired,
    classPacks:       PropTypes.array.isRequired,
    collection:       PropTypes.object.isRequired,
    createDiscount:   PropTypes.func.isRequired,
    discount:         PropTypes.object.isRequired,
    favorites:        PropTypes.array.isRequired,
    fetchClassPacks:  PropTypes.func.isRequired,
    fetchMemberships: PropTypes.func.isRequired,
    instructor:       PropTypes.object.isRequired,
    instructorPacks:  PropTypes.array.isRequired,
    like:             PropTypes.func.isRequired,
    loader:           PropTypes.object.isRequired,
    memberships:      PropTypes.array.isRequired,
    restoreDiscount:  PropTypes.func.isRequired,
    subscriptions:    PropTypes.array.isRequired,
    subtractCredit:   PropTypes.func.isRequired,
    template:         PropTypes.string,
    unLike:           PropTypes.func.isRequired,
    user:             PropTypes.object.isRequired
  }

  static defaultProps = {
    template: 'default'
  }

  static getDerivedStateFromProps(props, state) {
    const withoutDeleted = filter(props.collection.requestedClasses, ['deletedAt', '']);
    const previewClasses = take(withoutDeleted, 6);

    if (state.isAllShown) {
      return {
        requestedClasses: withoutDeleted
      };
    }

    return {
      requestedClasses: previewClasses
    };
  }

  state = {
    isAllShown:       false,
    isOpen:           true,
    requestedClasses: []
  }

  handleToggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleShowAll = () => {
    this.setState({
      isAllShown: true
    });
  }

  render () {
    const { challenges, collection, template, instructor, user, subscriptions, classPacks } = this.props;
    const { isOpen, requestedClasses, isAllShown } = this.state;

    const isBig = filter(collection.requestedClasses, ['deletedAt', '']).length > 6;

    const groupByFolder = groupBy(requestedClasses, 'folder.name');
    const folders = map(requestedClasses, 'folder');
    const sortFoldersByPosition = sortBy(folders, 'position');
    const uniqFolders = uniqBy(sortFoldersByPosition, 'name');

    const hasFolders = uniqFolders.length > 1;

    return (
      <div
          className='group-classes mt-3'
          key={collection.id}>
        <div
            className='workout__section-header'
            onClick={this.handleToggle}>
          <h6 className='workout__section-headspan'>
            {collection.name}
            <CollapseIcon
                className='ml-3'
                isOpen={isOpen}
                size={12} />
          </h6>
        </div>
        { hasFolders &&
          <Collapse isOpen={isOpen}>
            <div
                className={classNames('d-flex flex-wrap flex-direction-row',
                                      {'bg-white': template !== 'elegant'})}>
              { requestedClasses && uniqFolders.map((folder, i) => {
                return (
                  <React.Fragment key={i}>
                    <span
                        className={classNames('w-100 form-collapse-toggle')}
                        href='#'>
                      <span className='ml-4 text-primary'>{folder.name ? folder.name : 'General Folder'}</span>
                    </span>
                    { groupByFolder[folder.name].map((requestedClass) => (
                      <RequestedClass
                          challenges={challenges}
                          classPacks={classPacks}
                          createDiscount={this.props.createDiscount}
                          discount={this.props.discount}
                          favorites={this.props.favorites}
                          fetchClassPacks={this.props.fetchClassPacks}
                          fetchMemberships={this.props.fetchMemberships}
                          instructor={instructor}
                          instructorPacks={this.props.instructorPacks}
                          key={requestedClass.id}
                          like={this.props.like}
                          loader={this.props.loader}
                          memberships={this.props.memberships}
                          requestedClass={{
                            ...requestedClass,
                            challengeIds:  collection.challengeIds,
                            classPackIds:  collection.classPackIds,
                            membershipIds: collection.membershipIds
                          }}
                          restoreDiscount={this.props.restoreDiscount}
                          subscriptions={subscriptions}
                          subtractCredit={this.props.subtractCredit}
                          unLike={this.props.unLike}
                          user={user} />
                      ))}
                  </React.Fragment>
                );
              })}

              { !isAllShown && isBig &&
                <div className={'group-class__buttons w-100 ' + template}>
                  <Button
                      className={'btn-show-all'}
                      color='dark'
                      onClick={this.handleShowAll}>
                    Show All
                  </Button>
                </div>
              }
            </div>
          </Collapse>
        }

        { !hasFolders &&
          <Collapse isOpen={isOpen}>
            <div
                className={classNames('d-flex flex-wrap flex-direction-row',
                                      {'bg-white': template !== 'elegant'})}>
              { requestedClasses && requestedClasses.map((requestedClass) => {
                if (template === 'elegant') {
                  return (
                    <RequestedClassElegant
                        challenges={challenges}
                        classPacks={classPacks}
                        createDiscount={this.props.createDiscount}
                        discount={this.props.discount}
                        favorites={this.props.favorites}
                        fetchClassPacks={this.props.fetchClassPacks}
                        fetchMemberships={this.props.fetchMemberships}
                        instructor={instructor}
                        instructorPacks={this.props.instructorPacks}
                        key={requestedClass.id}
                        like={this.props.like}
                        loader={this.props.loader}
                        memberships={this.props.memberships}
                        requestedClass={{
                          ...requestedClass,
                          challengeIds:  collection.challengeIds,
                          classPackIds:  collection.classPackIds,
                          membershipIds: collection.membershipIds
                        }}
                        restoreDiscount={this.props.restoreDiscount}
                        subscriptions={subscriptions}
                        subtractCredit={this.props.subtractCredit}
                        unLike={this.props.unLike}
                        user={user} />
                  );
                }

                return (
                  <RequestedClass
                      challenges={challenges}
                      classPacks={classPacks}
                      createDiscount={this.props.createDiscount}
                      discount={this.props.discount}
                      favorites={this.props.favorites}
                      fetchClassPacks={this.props.fetchClassPacks}
                      fetchMemberships={this.props.fetchMemberships}
                      instructor={instructor}
                      instructorPacks={this.props.instructorPacks}
                      key={requestedClass.id}
                      like={this.props.like}
                      loader={this.props.loader}
                      memberships={this.props.memberships}
                      requestedClass={{
                        ...requestedClass,
                        challengeIds:  collection.challengeIds,
                        classPackIds:  collection.classPackIds,
                        membershipIds: collection.membershipIds
                      }}
                      restoreDiscount={this.props.restoreDiscount}
                      subscriptions={subscriptions}
                      subtractCredit={this.props.subtractCredit}
                      unLike={this.props.unLike}
                      user={user} />
                );
              })}

              { !isAllShown && isBig &&
                <div className={'group-class__buttons ' + template}>
                  <Button
                      className={'btn-show-all'}
                      color='dark'
                      onClick={this.handleShowAll}>
                    Show All
                  </Button>
                </div>
              }
            </div>
          </Collapse>
        }

      </div>
    );
  }
}

export default CollectionCollapse;
