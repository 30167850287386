/* global process */
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { ReactReduxContext } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { withOrientationChange } from 'react-device-detect';

import { history } from '../store';
import { SubscriptionsChannel } from '../channels';
import { LoaderContainer } from '../containers';
import { ApplicationActions } from '../actions';
import { InstructorPage } from '../views';
import { Router } from './router';

const { HOST } = process.env;

class App extends PureComponent {
  static propTypes = {
    hasCustomDomain: PropTypes.bool.isRequired,
    setDomain:       PropTypes.func.isRequired
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize);

    if (window.location.host !== HOST) {
      this.props.setDomain(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    console.log('Resizing');
    this.forceUpdate();
  }

  renderInstructorRouter = () => (
    <InstructorPage
        hasAuth
        history={history} />
  );

  render () {
    const { hasCustomDomain } = this.props;

    return (
      <Fragment>
        <Helmet
            defaultTitle='onPodio'
            titleTemplate='%s | onPodio' />

        <SubscriptionsChannel />
        <ConnectedRouter
            context={ReactReduxContext}
            history={history}>
          <LoaderContainer />

          { hasCustomDomain ? this.renderInstructorRouter() : <Router history={history} /> }
        </ConnectedRouter>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  hasCustomDomain: state.app.hasCustomDomain
});

const mapDispatchToProps = (dispatch) => ({
  setDomain: (data) => dispatch(ApplicationActions.SET_DOMAIN(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOrientationChange(App));
