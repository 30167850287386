import React from 'react';
import { Badge } from 'reactstrap';
import { capitalize } from 'lodash';
import { selectFilter, textFilter } from 'react-bootstrap-table2-filter';
import { Type } from 'react-bootstrap-table2-editor';

import { Moment } from '../../lib';
import { ExternalIcon } from '../../components/icons';
import { clientsRoutes, currencyFormat, routes } from '../../lib';

import { Link } from '../../components/inputs';

const alignIfBlank = (cell) => {
  return cell ? 'left' : 'center';
};

const stripeFormatter = (cell) => {
  if (!cell) {
    return null;
  }

  return (
    <a
        href={cell}
        rel='noopener noreferrer'
        target='_blank'>
      Open
      <ExternalIcon
          className='ml-1'
          size={16} />
    </a>
  );
};

const feeBadgeColor = (feeType) => {
  switch (true) {
    case feeType === 'free':
      return 'success';

    case feeType === 'donation':
      return 'info';

    default:
      return 'danger';
  }
};

const feeTypeFormatter = (cell) => {
  if (!cell) {
    return null;
  }

  return (
    <Badge
        className={'badge-fee ' + cell}
        color={feeBadgeColor(cell)}>
      {capitalize(cell)}
    </Badge>
  );
};

const amountFormatter = (cell, row) => {
  if (!cell) {
    return null;
  }

  if (row.viaClassPack) {
    return <Badge color='success'>Credit</Badge>;
  }
  if (row.viaSubscription) {
    return <Badge color='success'>Member</Badge>;
  }
  return currencyFormat(cell, row.currency);
};

const dateFormatter = (cell) => {
  if (!cell) {
    return null;
  }
  const date = new Moment(cell, 'YYYY-MM-DDhh:mm:ssUTC');

  return date.format('MMM DD, YYYY  hh:mm A');
};

const typeFormatter = (cell) => {
  return (
    <Badge
        className='badge-type'
        color='light'>
      {cell}
    </Badge>
  );
};

const clientFormatter = (cell, row) => (
  <Link
      className='text-decoration-none'
      to={routes.CLIENTS + clientsRoutes.CLIENT(row.clientId)}>
    {cell}
  </Link>
);


const couponeFormatter = (cell) => {
  if (!cell) {
    return null;
  }

  return <strong>{cell}</strong>;
};

const statusColor = (cell) => {
  switch (true) {
    case cell === 'active':
      return 'success';

    case cell === 'inactive':
      return 'warning';

    default:
      return 'danger';
  }
};

const statusFormatter = (cell) => {
  return (
    <Badge
        className={'badge-status ' + cell}
        color={statusColor(cell)}>
      {capitalize(cell)}
    </Badge>
  );
};

const creditsFormatter = (cell) => {
  const color = cell === 0 ? 'danger' : 'success';

  return <Badge color={color}>{cell}</Badge>;
};

const typeOptions = {
  'Live Class': 'Live Class',
  'On Demand':  'On Demand'
};

const feeTypeOptions = {
  'donation': 'Donation',
  'free':     'Free',
  'paid':     'Paid'
};

const filterFieldOptions = {
  className: 'form-control-sm'
};

const noteStyle = {
  maxWidth:     '200px',
  overflow:     'hidden',
  textOverflow: 'ellipsis'
};

const purchasesColumns = (couponOptions = []) => ([
  {
    dataField: 'id',
    editable:  false,
    text:      'ID',
    type:      'string'
  }, {
    align:     'center',
    dataField: 'purchaseType',
    editable:  false,
    filter:    selectFilter({
      options: typeOptions,
      ...filterFieldOptions
    }),
    formatter: typeFormatter,
    sort:      true,
    text:      'Type',
    type:      'string'
  }, {
    dataField: 'createdAt',
    editable:  false,
    formatter: dateFormatter,
    sort:      true,
    text:      'Booking Date',
    type:      'date'
  }, {
    dataField: 'clientName',
    editable:  false,
    filter:    textFilter({
      ...filterFieldOptions
    }),
    formatter: clientFormatter,
    sort:      true,
    text:      'Client'
  }, {
    dataField: 'bookable.name',
    editable:  false,
    filter:    textFilter({
      ...filterFieldOptions
    }),
    sort: true,
    text: 'Name'
  }, {
    align:     alignIfBlank,
    dataField: 'bookable.startsAt',
    editable:  false,
    formatter: dateFormatter,
    sort:      true,
    text:      'Class Date',
    type:      'date'
  }, {
    align:     'center',
    dataField: 'bookable.feeType',
    editable:  false,
    filter:    selectFilter({
      options: feeTypeOptions,
      ...filterFieldOptions
    }),
    formatter: feeTypeFormatter,
    sort:      true,
    text:      'Fee Type'
  }, {
    align:     'center',
    dataField: 'charge.discount.code',
    editable:  false,
    filter:    selectFilter({
      options: couponOptions,
      ...filterFieldOptions
    }),
    formatter: couponeFormatter,
    text:      'Coupon'
  }, {
    align:     'center',
    dataField: 'charge.amount',
    editable:  false,
    formatter: amountFormatter,
    sort:      true,
    text:      'Amount'
  }, {
    classes:     'pointer',
    dataField:   'note',
    editor:      { type: Type.TEXTAREA },
    headerStyle: { minWidth: '200px' },
    style:       noteStyle,
    text:        'Note'
  }, {
    align:     'center',
    dataField: 'stripeUrl',
    editable:  false,
    formatter: stripeFormatter,
    text:      'Stripe'
  }
]);

const membershipsColumns = [
  {
    dataField: 'id',
    editable:  false,
    text:      'ID',
    type:      'string'
  }, {
    dataField: 'createdAt',
    editable:  false,
    formatter: dateFormatter,
    sort:      true,
    text:      'Joined'
  }, {
    dataField: 'user.fullName',
    editable:  false,
    filter:    textFilter({
      ...filterFieldOptions
    }),
    formatter: clientFormatter,
    sort:      true,
    text:      'Client'
  }, {
    dataField: 'name',
    editable:  false,
    filter:    textFilter({
      ...filterFieldOptions
    }),
    sort: true,
    text: 'Package'
  }, {
    align:     'center',
    dataField: 'membership.amount',
    editable:  false,
    text:      'Amount'
  }, {
    align:     'center',
    dataField: 'status',
    editable:  false,
    formatter: statusFormatter,
    sort:      true,
    text:      'Status'
  }, {
    align:     alignIfBlank,
    dataField: 'endsAt',
    editable:  false,
    formatter: dateFormatter,
    sort:      true,
    text:      'Next Renewal'
  }, {
    classes:     'pointer',
    dataField:   'note',
    editor:      { type: Type.TEXTAREA },
    headerStyle: { minWidth: '200px' },
    style:       noteStyle,
    text:        'Note'
  }, {
    align:     'center',
    dataField: 'stripeUrl',
    editable:  false,
    formatter: stripeFormatter,
    text:      'Stripe'
  }
];

const challengesColumns = (couponOptions) => ([
  {
    dataField: 'id',
    editable:  false,
    text:      'ID',
    type:      'string'
  }, {
    dataField: 'user.fullName',
    editable:  false,
    filter:    textFilter({
      ...filterFieldOptions
    }),
    formatter: clientFormatter,
    sort:      true,
    text:      'Client',
    type:      'string'
  }, {
    dataField: 'name',
    editable:  false,
    filter:    textFilter({
      ...filterFieldOptions
    }),
    sort: true,
    text: 'Package',
    type: 'string'
  }, {
    align:     'center',
    dataField: 'charge.discount.code',
    editable:  false,
    filter:    selectFilter({
      options: couponOptions,
      ...filterFieldOptions
    }),
    formatter: couponeFormatter,
    text:      'Coupon'
  }, {
    align:     'center',
    dataField: 'charge.amount',
    editable:  false,
    formatter: amountFormatter,
    sort:      true,
    text:      'Amount'
  }, {
    align:     'center',
    dataField: 'endsAt',
    editable:  false,
    formatter: dateFormatter,
    sort:      true,
    text:      'Ends At',
    type:      'date'
  }, {
    classes:     'pointer',
    dataField:   'note',
    editor:      { type: Type.TEXTAREA },
    headerStyle: { minWidth: '200px' },
    style:       noteStyle,
    text:        'Note'
  }, {
    align:     'center',
    dataField: 'stripeUrl',
    editable:  false,
    formatter: stripeFormatter,
    text:      'Stripe'
  }
]);

const classPacksColumns = (couponOptions) => ([
  {
    dataField: 'id',
    editable:  false,
    text:      'ID',
    type:      'string'
  }, {
    dataField: 'createdAt',
    editable:  false,
    formatter: dateFormatter,
    sort:      true,
    text:      'Purchase Date',
    type:      'date'
  }, {
    dataField: 'user.fullName',
    editable:  false,
    filter:    textFilter({
      ...filterFieldOptions
    }),
    formatter: clientFormatter,
    sort:      true,
    text:      'Client',
    type:      'string'
  }, {
    dataField: 'name',
    editable:  false,
    filter:    textFilter({
      ...filterFieldOptions
    }),
    sort: true,
    text: 'Package',
    type: 'string'
  }, {
    align:     'center',
    dataField: 'charge.discount.code',
    editable:  false,
    filter:    selectFilter({
      options: couponOptions,
      ...filterFieldOptions
    }),
    formatter: couponeFormatter,
    text:      'Coupon'
  }, {
    align:     'center',
    dataField: 'charge.amount',
    editable:  false,
    formatter: amountFormatter,
    sort:      true,
    text:      'Amount'
  }, {
    align:     'center',
    dataField: 'credits',
    editable:  false,
    formatter: creditsFormatter,
    sort:      true,
    text:      'Unused credits',
    type:      'number'
  }, {
    classes:     'pointer',
    dataField:   'note',
    editor:      { type: Type.TEXTAREA },
    headerStyle: { minWidth: '200px' },
    style:       noteStyle,
    text:        'Note'
  }, {
    align:     'center',
    dataField: 'stripeUrl',
    editable:  false,
    formatter: stripeFormatter,
    text:      'Stripe'
  }
]);

export default (coupons = []) => {
  return {
    CHALLENGES:  challengesColumns(coupons),
    CLASS_PACKS: classPacksColumns(coupons),
    MEMBERSHIPS: membershipsColumns,

    PAGINATION: {
      hidePageListOnlyOnePage: true,
      hideSizePerPage:         true,
      showTotal:               false,
      sizePerPage:             25
    },
    PURCHASES: purchasesColumns(coupons)
  };
};
