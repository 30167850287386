import React from 'react';
import PropTypes from 'prop-types';
import { compact, filter, map, slice, sortBy } from 'lodash';
import { ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Moment } from '../../../../lib';
import { ClassBox } from '../';
import { Button } from '../../../forms';
import { Alert } from '../../../errors';
import { profileCarouselOptions } from '../../../../constants';

class GroupClassesCarousel extends React.PureComponent {
  static propTypes = {
    createBooking:        PropTypes.func.isRequired,
    createDiscount:       PropTypes.func.isRequired,
    createPrivateBooking: PropTypes.func.isRequired,
    discount:             PropTypes.object.isRequired,
    groupClasses:         PropTypes.array.isRequired,
    hasLocation:          PropTypes.bool,
    instructor:           PropTypes.object.isRequired,
    privateGroupClasses:  PropTypes.array.isRequired,
    user:                 PropTypes.object.isRequired
  };

  static defaultProps = {
    hasLocation: false
  };

  static getDerivedStateFromProps(props) {
    const today = new Moment();

    const combinedClasses = filter(sortBy(
      [
        ...map(props.groupClasses, (groupClass) => ({ ...groupClass, isPrivate: false })),
        ...map(props.privateGroupClasses, (privateGroupClass) => ({ ...privateGroupClass, isPrivate: true }))
      ],
      (combinedClass) => combinedClass.startsAt
    ), (combinedClass) => (
      Moment(combinedClass.startsAt, 'YYYY-MM-DD hh:mm:ss').isAfter(today)
    ));

    return {
      groupClasses: compact(slice(combinedClasses, 0, 5))
    };
  }

  state = {
    groupClasses: []
  };

  renderClassess = () => {
    const orientation = isMobile ? profileCarouselOptions.VERTICAL        : profileCarouselOptions.HORIZONTAL;
    const slideHeight = isMobile ? profileCarouselOptions.EVENT_HEIGHT_SM : profileCarouselOptions.EVENT_HEIGHT_LG;
    const slideWidth  = isMobile ? profileCarouselOptions.EVENT_WIDTH_SM  : profileCarouselOptions.EVENT_WIDTH_LG;
    const nextButton  = isMobile ? profileCarouselOptions.BTN_ICON_DOWN   : profileCarouselOptions.BTN_ICON_RIGHT;

    if (this.state.groupClasses.length) {
      return (
        <CarouselProvider
            naturalSlideHeight={slideHeight}
            naturalSlideWidth={slideWidth}
            orientation={orientation}
            totalSlides={this.state.groupClasses.length}
            touchEnabled
            visibleSlides={3}>
          <Slider moveThreshold={0.9}>
            {this.state.groupClasses.map((groupClass, index) => (
              <Slide
                  className='event-container'
                  index={index}
                  key={groupClass.id}>
                <ClassBox
                    createBooking={this.props.createBooking}
                    createDiscount={this.props.createDiscount}
                    createPrivateBooking={this.props.createPrivateBooking}
                    discount={this.props.discount}
                    groupClass={groupClass}
                    hasLocation={this.props.hasLocation}
                    instructor={this.props.instructor}
                    user={this.props.user} />
              </Slide>
            ))}
          </Slider>
          <ButtonNext className='btn-carousel next primary'>
            <Icon
                fixedWidth
                icon={['fas', nextButton]} />
          </ButtonNext>
        </CarouselProvider>
      );
    } else {
      return (
        <div className='no-events'>
          <Alert
              className='mx-auto border-warning'
              color='warning'
              heading='No upcoming events'
              icon='calendar-times'>
            The instructor has not added class data yet. Follow their page to be informed about new classes.
          </Alert>
          <Button
              className='btn-carousel next'
              color='primary'
              isDisabled>
            <Icon
                fixedWidth
                icon={['fas', nextButton]} />
          </Button>
        </div>
      );
    }
  }

  render() {
    return (
      <div className='profile-group-classes'>
        {this.renderClassess()}
      </div>
    );
  }
}

export default GroupClassesCarousel;
