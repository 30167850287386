import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Collapse } from 'reactstrap';

class ListCollapse extends React.PureComponent {
  static propTypes = {
    children:  PropTypes.node,
    className: PropTypes.string,
    color:     PropTypes.string,
    icon:      PropTypes.node,
    isOpen:    PropTypes.bool.isRequired,
    onClick:   PropTypes.func.isRequired,
    title:     PropTypes.oneOfType([PropTypes.string,PropTypes.node]).isRequired
  }

  static defaultProps = {
    children:  null,
    className: '',
    color:     'primary',
    icon:      ''
  }

  static getDerivedStateFromProps(props) {
    return {
      isOpen: props.isOpen
    };
  }

  state = {
    isOpen: ''
  }

  render() {
   const { color, className, icon, title, children, onClick } = this.props;

    return (
      <div className={classNames('list-collapse', className)}>
        <div
            className={classNames('list-header', { 'open': this.state.isOpen })}
            onClick={onClick}
            onKeyPress={this.handleToggle}
            role='button'
            tabIndex={0}>
          { icon &&
            <div className={classNames('list-icon', `bg-${color}`)}>
              {icon}
            </div>
          }
          <div className='list-title'>
            {title}
          </div>
          { !!children &&
            <div className='list-indicator'>
              <Icon icon='chevron-right' />
            </div>
          }
        </div>
        <Collapse isOpen={this.state.isOpen}>
          {children}
        </Collapse>
      </div>
    );
  }
}

export default ListCollapse;
