import React from 'react';
import { Container } from 'reactstrap';

import { Link } from '../inputs';
import { routes } from '../../lib';

class NotFound extends React.PureComponent {
  render() {
    return (
      <Container className='not-found-container'>
        <div className='not-found'>
          <h1>404</h1>

          <h2>Oops, The Page you are looking for can&apos;t be found!</h2>

          <div className='mt-3'>
            <span className='arrow' />
            <Link to={routes.HOME}>Return To Homepage</Link>
          </div>
        </div>
      </Container>
    );
  }
}

export default NotFound;
