import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';
import { filter, flatMap, omit } from 'lodash';
import { Link } from 'react-router-dom';
import { Cookies, withCookies } from 'react-cookie';

import { Introduction } from '../dashboard';
import { Calendr } from '../../lib';
import { SidebarIcon } from '../icons';
import { BookingRequests, Transactions, UpcomingMilestones } from '../dashboard';
import { UpcomingEvents } from '../dashboard';
import { Heading, Panel } from '../layout/pages';

import {
  EditGroupClassModal,
  InstructorPrivateGroupClassModal,
  InstructorPrivateSessionModal,
  RecessMigrationModal,
  ReferModal } from '../modals';
import { PrivateGroupClassModal, TimeBlockModal } from '../../containers/events';

class InstructorDashboardPage extends React.PureComponent {
  static propTypes = {
    acceptBookingRequest:             PropTypes.func.isRequired,
    bookingRequests:                  PropTypes.array.isRequired,
    classPacks:                       PropTypes.array.isRequired,
    cookies:                          PropTypes.instanceOf(Cookies).isRequired,
    createPrivateBookings:            PropTypes.func.isRequired,
    declineBookingRequest:            PropTypes.func.isRequired,
    deleteGroupClass:                 PropTypes.func.isRequired,
    deletePrivateBooking:             PropTypes.func.isRequired,
    deletePrivateGroupClass:          PropTypes.func.isRequired,
    deletePrivateSession:             PropTypes.func.isRequired,
    deleteRecurringGroupClass:        PropTypes.func.isRequired,
    deleteRecurringPrivateGroupClass: PropTypes.func.isRequired,
    deleteRecurringPrivateSession:    PropTypes.func.isRequired,
    fetchPlaylists:                   PropTypes.func.isRequired,
    fetchUser:                        PropTypes.func.isRequired,
    folders:                          PropTypes.array.isRequired,
    formats:                          PropTypes.array.isRequired,
    groupClasses:                     PropTypes.array.isRequired,
    instructor:                       PropTypes.object.isRequired,
    isLoading:                        PropTypes.object.isRequired,
    memberships:                      PropTypes.array.isRequired,
    milestones:                       PropTypes.array.isRequired,
    playlists:                        PropTypes.array.isRequired,
    privateBookings:                  PropTypes.array.isRequired,
    privateGroupClasses:              PropTypes.array.isRequired,
    privateSessions:                  PropTypes.array.isRequired,
    requestedClasses:                 PropTypes.array.isRequired,
    segmentSiteShare:                 PropTypes.func.isRequired,
    segmentStripe:                    PropTypes.func.isRequired,
    segmentZoom:                      PropTypes.func.isRequired,
    summary:                          PropTypes.object.isRequired,
    timeBlocks:                       PropTypes.array.isRequired,
    updateGroupClass:                 PropTypes.func.isRequired,
    updatePrivateSession:             PropTypes.func.isRequired,
    updateProfile:                    PropTypes.func.isRequired,
    user:                             PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props) {
    const { groupClasses, instructor, privateGroupClasses, privateSessions, timeBlocks } = props;

    const purchasedClasses = flatMap(props.requestedClasses, (requestedClass) => (
      flatMap(requestedClass.userOnDemands, (purchasedClass) => ({
        ...purchasedClass,
        requestedClass: omit(requestedClass, 'userOnDemands')
      }))
    ));

    return {
      events:           Calendr.combineClasses({ groupClasses, privateGroupClasses, privateSessions, timeBlocks }),
      oauth:            props.user.oauth,
      purchasedClasses,
      skipIntroduction: instructor.skipIntroduction
    };
  }

  state = {
    events:                           [],
    groupClass:                       {},
    isGroupClassModalOpen:            false,
    isPrivateGroupClassModalOpen:     false,
    isPrivateGroupClassViewModalOpen: false,
    isPrivateSessionModalOpen:        false,
    isRecessMigrationModalOpen:       false,
    isReferModalOpen:                 false,
    isTimeBlockModalOpen:             false,
    isValueSet:                       false,
    privateGroupClass:                {},
    privateSession:                   {},
    section:                          '',
    skipIntroduction:                 false,
    timeBlock:                        {}
  };

  handleGroupClassClick = (groupClass = {}) => {
    this.setState((prevState) => ({
      groupClass,
      isGroupClassModalOpen: !prevState.isGroupClassModalOpen
    }));
  }

  handlePrivateGroupClassViewClick = (privateGroupClass = {}) => {
    this.setState((prevState) => ({
      isPrivateGroupClassViewModalOpen: !prevState.isPrivateGroupClassViewModalOpen,
      privateGroupClass
    }));
  }

  handlePrivateGroupClassClick = () => {
    this.setState((prevState) => ({
      isPrivateGroupClassModalOpen: !prevState.isPrivateGroupClassModalOpen
    }));
  }

  handlePrivateSessionClick = (privateSession = {}) => {
    this.setState((prevState) => ({
      isPrivateSessionModalOpen: !prevState.isPrivateSessionModalOpen,
      privateSession
    }));
  }

  handleReferClick = (event) => {
    event.preventDefault();

    this.setState((prevState) => ({
      isReferModalOpen: !prevState.isReferModalOpen
    }));
  }

  handleTimeBlockClick = (timeBlock = {}) => {
    this.setState((prevState) => ({
      isTimeBlockModalOpen: !prevState.isTimeBlockModalOpen,
      timeBlock
    }));
  }

  changeSection = (section) => {
    this.setState((prevState) => ({ section: prevState.section !== section ? section : '' }));
  }

  handleEventClick = (event) => {
    switch (event.type) {
      case 'groupClass':
        return this.handleGroupClassClick(event);

      case 'privateGroupClass':
        return this.handlePrivateGroupClassViewClick(event);

      case 'privateSession':
        return this.handlePrivateSessionClick(event);

      case 'timeBlock':
        return this.handleTimeBlockClick(event);

      default:
        null;
    }
  };

  handleCancelClientBooking = (client) => {
    this.props.deletePrivateBooking(client);

    this.setState((prevState) => ({
      privateGroupClass: {
        ...prevState.privateGroupClass,
        bookings:      filter(prevState.privateGroupClass.bookings, (booking) => booking.id != client.id),
        bookingsCount: prevState.privateGroupClass.bookingsCount - 1
      }
    }));
  }

  handleAddClient  = (booking) => {
    this.props.createPrivateBookings(booking);

    this.setState((prevState) => ({
      privateGroupClass: {
        ...prevState.privateGroupClass,
        bookings:      [...prevState.privateGroupClass.bookings, { ...booking, deletedAt: '' }],
        bookingsCount: prevState.privateGroupClass.bookingsCount + 1
      }
    }));
  }

  handleSetModalState = (value) => {
    this.setState({ isRecessMigrationModalOpen: value !== 'true', isValueSet: true });
  }

  render() {
    const { cookies, instructor, privateBookings, summary } = this.props;
    !this.state.isValueSet && this.handleSetModalState(cookies.get('modal_opened'));

    return (
      <React.Fragment>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>
        <div className='inner-wrapper portal dashboard dashboard-instructor'>
          <Container fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Dashboard' />
            <Panel>
              <div className='dashboard'>

                <h5 className='title-greeting'>
                  Howdy, {instructor.firstName}!
                </h5>

                { !privateBookings.length && !this.state.skipIntroduction &&
                  <Introduction
                      classPacks={this.props.classPacks}
                      fetchUser={this.props.fetchUser}
                      folders={this.props.folders}
                      formats={this.props.formats}
                      instructor={instructor}
                      memberships={this.props.memberships}
                      oAuth={this.state.oauth}
                      privateGroupClasses={this.props.privateGroupClasses}
                      profile={instructor}
                      requestedClasses={this.props.requestedClasses}
                      segmentSiteShare={this.props.segmentSiteShare}
                      segmentStripe={this.props.segmentStripe}
                      segmentZoom={this.props.segmentZoom}
                      updateProfile={this.props.updateProfile}
                      user={this.props.user} />
                }

                { (!!privateBookings.length || this.state.skipIntroduction) &&
                  <React.Fragment>
                    <div className='refer-link'>
                      <Link
                          className='link'
                          onClick={this.handleReferClick}
                          to='#'>
                        <SidebarIcon iconName='gift' />

                        Make $50, Give $50
                      </Link>
                    </div>

                    <Transactions
                        isLoading={this.props.isLoading.summary}
                        summary={summary}
                        user={this.props.user} />

                    <BookingRequests
                        acceptBookingRequest={this.props.acceptBookingRequest}
                        bookingRequests={this.props.bookingRequests}
                        changeSection={this.changeSection}
                        declineBookingRequest={this.props.declineBookingRequest}
                        instructor={this.props.instructor}
                        section={this.state.section}
                        updatePrivateSession={this.props.updatePrivateSession}
                        user={this.props.user} />

                    <UpcomingMilestones
                        isLoading={this.props.isLoading.milestones}
                        milestones={this.props.milestones}
                        user={this.props.user} />

                    <UpcomingEvents
                        events={this.state.events}
                        onEventClick={this.handleEventClick}
                        user={this.props.user} />
                  </React.Fragment>
                }
              </div>
            </Panel>
          </Container>
        </div>

        { this.state.isGroupClassModalOpen &&
          <EditGroupClassModal
              deleteGroupClass={this.props.deleteGroupClass}
              deleteRecurringGroupClass={this.props.deleteRecurringGroupClass}
              fetchPlaylists={this.props.fetchPlaylists}
              groupClass={this.state.groupClass}
              instructor={this.props.instructor}
              isOpen={this.state.isGroupClassModalOpen}
              onToggle={this.handleGroupClassClick}
              playlists={this.props.playlists}
              updateGroupClass={this.props.updateGroupClass}
              user={this.props.user} />
        }

        { this.state.isPrivateSessionModalOpen &&
          <InstructorPrivateSessionModal
              deletePrivateSession={this.props.deletePrivateSession}
              deleteRecurringPrivateSession={this.props.deleteRecurringPrivateSession}
              isOpen={this.state.isPrivateSessionModalOpen}
              onToggle={this.handlePrivateSessionClick}
              privateSession={this.state.privateSession}
              user={this.props.user} />
        }

        { this.state.isPrivateGroupClassViewModalOpen &&
          <InstructorPrivateGroupClassModal
              deletePrivateGroupClass={this.props.deletePrivateGroupClass}
              deleteRecurringPrivateGroupClass={this.props.deleteRecurringPrivateGroupClass}
              isOpen={this.state.isPrivateGroupClassViewModalOpen}
              onHandleAddClient={this.handleAddClient}
              onHandleCancelClientBooking={this.handleCancelClientBooking}
              onToggle={this.handlePrivateGroupClassViewClick}
              onUpdateClick={this.handlePrivateGroupClassClick}
              privateGroupClass={this.state.privateGroupClass}
              user={this.props.user} />
        }

        { this.state.isReferModalOpen &&
          <ReferModal
              instructor={this.props.instructor}
              isOpen={this.state.isReferModalOpen}
              onToggle={this.handleReferClick} />
        }

        { this.state.isTimeBlockModalOpen &&
          <TimeBlockModal
              isOpen={this.state.isTimeBlockModalOpen}
              onToggle={this.handleTimeBlockClick}
              timeBlock={this.state.timeBlock} />
        }

        { this.state.isPrivateGroupClassModalOpen &&
          <PrivateGroupClassModal
              isOpen={this.state.isPrivateGroupClassModalOpen}
              onToggle={this.handlePrivateGroupClassClick}
              privateGroupClass={this.state.privateGroupClass} />
        }

        { this.state.isRecessMigrationModalOpen &&
          <RecessMigrationModal
              isOpen={this.state.isRecessMigrationModalOpen} />
        }
      </React.Fragment>
    );
  }
}

export default withCookies(InstructorDashboardPage);
