import React from 'react';
import PropTypes from 'prop-types';

import { UserModalAvatar } from '../layouts';

class Success extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired,
    membership: PropTypes.object.isRequired
  }

  render() {
    const { instructor, membership } = this.props;
    const { currency } = instructor;

    return (
      <div>
        <div>
          <span className='modal__default-text modal__default-text_blue modal__default-text_bold'>
            MEMBERSHIP CONFIRMED
          </span>
        </div>

        <div className='modal__success-text'>
          <span className='modal__small-text'>
            You’re all set! You will receve an email with the details below shortly!
          </span>
        </div>

        { instructor &&
          <div className='modal__header'>
            <UserModalAvatar
                alt={instructor.name}
                firstName={instructor.name}
                src={instructor.photo.sm} />
            <div className='modal__instructor-name'>
              <span className='modal__big-text'>{instructor.name}</span>
            </div>
          </div>
        }

        <div className='modal__event-name'>
          <span className='modal__big-text modal__big-text_black'>{membership.name}</span>
        </div>

        <div className='text-muted'>
          {membership.cost.toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
            per {membership.period}
        </div>

        { membership.description &&
          <div className='event-description'>
            <span className='modal__small-text'>About this membership</span>
            <p className='modal__small-text modal__small-text_grey'>{membership.description}</p>
          </div>
        }
      </div>
    );
  }
}

export default Success;
