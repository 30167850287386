import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { MeetingActions, PlansActions, PlaylistActions, PrivateGroupClassesActions } from '../../actions';
import { PrivateGroupClassModal } from '../../components/modals/events';

class PrivateGroupClassModalContainer extends React.PureComponent {
  static propTypes = {
    getSubscription: PropTypes.func.isRequired
  }

  render() {
    return <PrivateGroupClassModal {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  instructor:   state.profile.data,
  playlists:    state.playlists.data,
  subscription: state.plans.userData,
  user:         state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createClass:     (data) => dispatch(PrivateGroupClassesActions.CREATE(data)),
  createRecurring: (data) => dispatch(PrivateGroupClassesActions.RECURRING_CREATE(data)),
  deleteMeeting:   (data) => dispatch(MeetingActions.DELETE(data)),
  fetchPlaylists:  ()     => dispatch(PlaylistActions.FETCH()),
  getSubscription: ()     => dispatch(PlansActions.SUBSCRIPTION_GET()),
  updateClass:     (data) => dispatch(PrivateGroupClassesActions.UPDATE(data)),
  updateRecurring: (data) => dispatch(PrivateGroupClassesActions.RECURRING_UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PrivateGroupClassModalContainer);
