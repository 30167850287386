import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';

import { Button, Form, MaskedInput } from '../index';
import { Input } from '../../inputs';
import { currencyMask, minuteMask } from '../../../constants/masks';
import { currencies } from '../../../constants';
import { Scroller, Validator } from '../../../lib';

class SessionTypeForm extends React.PureComponent {
  static propTypes = {
    data:        PropTypes.object,
    isLoading:   PropTypes.bool,
    isNewRecord: PropTypes.bool,
    onDelete:    PropTypes.func,
    onSubmit:    PropTypes.func,
    user:        PropTypes.object.isRequired
  };

  static defaultProps = {
    data: {
      duration: '',
      name:     '',
      price:    ''
    },
    isLoading:   true,
    isNewRecord: true,
    onDelete:    () => {},
    onSubmit:    () => {}
  };

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(state.sessionType)) {
      return state;
    }

    if (!props.isLoading) {
      return {
        isNewRecord: props.isNewRecord,
        sessionType: props.data
      };
    }

    return null;
  }

  state = {
    errors: {},

    sessionType: {}
  };

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      sessionType: {
        ...prevState.sessionType,
        [inputName]: value
      }
    }));
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const { sessionType } = this.state;

    const errors = Validator.validate([
      ['name',     Validator.concepts.isFilled, [sessionType.name]],
      ['price',   Validator.concepts.isFilled, [sessionType.price]],
      ['duration', Validator.concepts.isFilled, [sessionType.duration]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  });

  handleSubmit = () => {
    const { currency } = this.props.user;
    const { sessionType } = this.state;

    const sign = currencies[currency].symbol;
    const price = sessionType.price && parseFloat(sessionType.price.toString().replace(sign, ''));

    return this.handleValidate().then(() => {
      return this.props.onSubmit({...sessionType, price });
    });
  };

  handleDelete = () => {
    const params = { privateSessionType: { id: this.state.sessionType.id } };
    this.props.onDelete(params);
  };

  render () {
    const { isNewRecord, user } = this.props;
    const { sessionType, errors } = this.state;

    const { currency } = user;
    const sign = currencies[currency].symbol;

    return (
      <Form className='form-default'>
        <FormGroup>
          <Label
              className='required'
              for='input-name'>Name</Label>
          <Input
              id='name'
              isInvalid={!!errors.name}
              name='name'
              onChange={this.handleChange}
              placeholder='Name'
              type='text'
              value={sessionType.name} />
        </FormGroup>

        <Row form>
          <Col xs={6}>
            <FormGroup>
              <Label
                  className='required'
                  for='input-duration'>
                Duration
              </Label>

              <MaskedInput
                  id='duration'
                  isInvalid={!!errors.duration}
                  mask={minuteMask}
                  name='duration'
                  onChange={this.handleChange}
                  placeholder=' mins'
                  value={String(sessionType.duration)} />
            </FormGroup>
          </Col>

          <Col xs={6}>
            <FormGroup>
              <Label
                  className='required'
                  for='input-price'>
                Cost
              </Label>
              <MaskedInput
                  id='price'
                  inputMode='number'
                  isInvalid={!!errors.price}
                  mask={currencyMask(sign)}
                  name='price'
                  onChange={this.handleChange}
                  placeholder={sign + 10}
                  type='number'
                  value={sessionType.price} />
              <FormFeedback tooltip>
                {errors.price}
              </FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        { isNewRecord &&
          <Button
              className='mt-5'
              isBlock
              onClick={this.handleSubmit}
              size='lg'
              type='button'>
            Create & Add
          </Button>
        }

        { !isNewRecord &&
          <Row className='mt-5'>
            <Col
                lg={6}
                md={12}>
              <Button
                  color='danger'
                  isBlock
                  isOutline
                  onClick={this.handleDelete}
                  size='lg'
                  type='button'>
                Delete
              </Button>
            </Col>
            <Col
                className='mt-2 mt-lg-0'
                lg={6}
                md={12}>
              <Button
                  isBlock
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Update
              </Button>
            </Col>
          </Row>
        }
      </Form>
    );
  }
}

export default SessionTypeForm;
