import { UserClassPacksActionTypes } from '../constants/actionTypes';

export default {
  /* actions made by instructor */
  INSTRUCTORS_CREATE: (payload) => ({
    payload, type: UserClassPacksActionTypes.INSTRUCTORS_CREATE
  }),

  INSTRUCTORS_CREATE_FAILURE: () => ({
    type: UserClassPacksActionTypes.INSTRUCTORS_CREATE_FAILURE
  }),

  INSTRUCTORS_CREATE_SUCCESS: (payload) => ({
    payload, type: UserClassPacksActionTypes.INSTRUCTORS_CREATE_SUCCESS
  })
};
