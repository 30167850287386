import Moment from 'moment';
import { extendMoment } from 'moment-range';

const moment = extendMoment(Moment);

moment.updateLocale('en', {
  weekdaysMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S']
}, {
  week: {
    dow: 0, // First day of week is Sunday
    doy: 6  // First week of year must contain 1 January (7 + 0 - 1)
  }
});

export default moment;
