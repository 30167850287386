import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { BookingsActionTypes } from '../constants/actionTypes';
import { BookingsActions, ErrorActions, SegmentActions } from '../actions';

function* watchCreate() {
  yield takeLatest(BookingsActionTypes.CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.groupClasses.bookings.create, payload);
      const user = yield select((state) => state.user.data);

      const data = toCamelCase(response.data);
      yield put(BookingsActions.CREATE_SUCCESS(data));
      yield put(SegmentActions.NEW_CLASS_BOOK({...data.groupClass, type: 'group class', user}));
      toastr.success('Session added');
    } catch (error) {
      yield put(BookingsActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDelete() {
  yield takeLatest(BookingsActionTypes.DELETE, function* ({ payload }) {
    try {
      yield call(api.groupClasses.bookings.delete, payload);

      const bookings = yield select((state) => state.bookings);
      const data = (filter(bookings.data, (booking) => booking.id != payload.groupClass.bookingId));

      yield put(BookingsActions.DELETE_SUCCESS(data));
      toastr.success('Class removed!');
    } catch (error) {
      yield put(BookingsActions.DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetch() {
  yield takeLatest(BookingsActionTypes.FETCH, function* () {
    try {
      const response = yield call(api.groupClasses.bookings.fetch);

      yield put(BookingsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(BookingsActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsFetch() {
  yield takeLatest(BookingsActionTypes.INSTRUCTORS_FETCH, function* () {
    try {
      const response = yield call(api.instructor.groupClasses.bookings.fetch);

      yield put(BookingsActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(BookingsActions.INSTRUCTORS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* bookingsSaga() {
  yield all([
    watchCreate(),
    watchDelete(),
    watchFetch(),
    watchInstructorsFetch()
  ]);
}
