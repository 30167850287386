import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';

class FundraiserUrban extends React.PureComponent {
  static propTypes = {
    currency:        PropTypes.string.isRequired,
    donationTargets: PropTypes.object.isRequired,
    progressValue:   PropTypes.string.isRequired,
    totalDonations:  PropTypes.string.isRequired
  };

  render() {
    const { donationTargets, currency, totalDonations, progressValue } = this.props;

    return (
      <React.Fragment>
        <div className='fundraiser-text'>
          <h6>{donationTargets.name}</h6>
          <div className='goal'>
            <h3>
              {totalDonations.toLocaleString('en-US',
                { currency, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency' })}
            </h3>
            raised of {donationTargets.target.toLocaleString('en-US',
              { currency,  maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency' })}
            &nbsp;goal
          </div>
        </div>
        <Progress
            color='success'
            value={progressValue} />
      </React.Fragment>
    );
  }
}

export default FundraiserUrban;
