import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';

import { Button } from '../forms';
import { CouponModalContainer, MembershipCouponModalContainer } from '../../containers/portal';
import { Heading, Panel } from '../layout/pages';
import { tutorialLinks } from '../../constants';
import { Coupon } from './coupons';

class CouponsPage extends React.PureComponent {
  static propTypes = {
    coupons:             PropTypes.array.isRequired,
    getCoupon:           PropTypes.func.isRequired,
    getMembershipCoupon: PropTypes.func.isRequired,
    membershipsCoupons:  PropTypes.array.isRequired
  };

  state = {
    isModalCouponOpen:           false,
    isModalMembershipCouponOpen: false,
    isNewRecord:                 true
  };

  handleToggle = (modalName) => () => {
    this.setState((prevState) => ({
      isNewRecord: true,
      [modalName]: !prevState[modalName]
    }));
  };

  renderEmpty = (type) => (
    <div className='message-empty'>
      <p>Create your first {type} coupon. </p>
    </div>
  );

  handleCouponEdit = (id) => () => {
    this.setState((prevState) => ({
      isModalCouponOpen: !prevState.isModalCouponOpen,
      isNewRecord:       false
    }));
    this.props.getCoupon({ id });
  }

  handleMembershipCouponEdit = (id) => () => {
    this.setState((prevState) => ({
      isModalMembershipCouponOpen: !prevState.isModalMembershipCouponOpen,
      isNewRecord:                 false
    }));
    this.props.getMembershipCoupon({ id });
  }

  render() {
    const { membershipsCoupons, coupons } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>My Coupons</title>
        </Helmet>

        <div className='inner-wrapper portal coupons'>
          <Container fluid>
            <Heading
                color='secondary'
                hasTutorial
                link={tutorialLinks.COUPONS}
                subtitle='Creator Portal'
                title='Coupons' />
            <Panel>
              <div className='title-row'>
                <span className='title'>Membership Coupons</span>

                <Button
                    className='button'
                    color='primary'
                    onClick={this.handleToggle('isModalMembershipCouponOpen')}
                    size='sm'>
                  <span>Add Coupon</span>
                </Button>
              </div>

              {!!membershipsCoupons.length && membershipsCoupons.map((coupon) => (
                <Coupon
                    coupon={coupon}
                    key={coupon.id}
                    onEdit={this.handleMembershipCouponEdit} />
              ))}

              {!membershipsCoupons.length && this.renderEmpty('Memberhips')}

              <div className='title-row'>
                <span className='title'>All Other Coupons (Live, On Demand, Challenge, Class Pack)</span>

                <Button
                    className='button'
                    color='primary'
                    onClick={this.handleToggle('isModalCouponOpen')}
                    size='sm'>
                  <span>Add Coupon</span>
                </Button>
              </div>

              {!!coupons.length && coupons.map((coupon) => (
                <Coupon
                    coupon={coupon}
                    key={coupon.id}
                    onEdit={this.handleCouponEdit} />
              ))}

              {!coupons.length && this.renderEmpty()}
            </Panel>
          </Container>
        </div>

        <MembershipCouponModalContainer
            isNewRecord={this.state.isNewRecord}
            isOpen={this.state.isModalMembershipCouponOpen}
            onToggle={this.handleToggle('isModalMembershipCouponOpen')} />

        <CouponModalContainer
            isNewRecord={this.state.isNewRecord}
            isOpen={this.state.isModalCouponOpen}
            onToggle={this.handleToggle('isModalCouponOpen')} />
      </React.Fragment>
    );
  }
}

export default CouponsPage;
