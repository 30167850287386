import { AdditionalInformationTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload,
    type: AdditionalInformationTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: AdditionalInformationTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload,
    type: AdditionalInformationTypes.CREATE_SUCCESS
  })
};
