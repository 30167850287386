import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { CommentsActions, StyleTagsActions } from '../../actions';
import { Comments } from '../../components/pages/profile/instructor';

class CommentsContainer extends React.PureComponent {
  static propTypes = {
    fetchComments:  PropTypes.func.isRequired,
    fetchStyleTags: PropTypes.func.isRequired,
    instructor:     PropTypes.object.isRequired
  }

  componentDidMount () {
    const { username } = this.props.instructor.data;

    this.props.fetchComments({ username });
    this.props.fetchStyleTags();
  }

  render () {
    return <Comments {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  comments:   state.comments,
  instructor: state.instructor,
  loader:     state.loader,
  session:    state.session,
  styleTags:  state.styleTags
});

const mapDispatchToProps = (dispatch) => ({
  createComment:  (data) =>  dispatch(CommentsActions.CREATE(data)),
  fetchComments:  (data) =>  dispatch(CommentsActions.FETCH(data)),
  fetchStyleTags: ()     =>  dispatch(StyleTagsActions.FETCH()),
  goTo:           (route) => dispatch(push(route))
});

export default connect(mapStateToProps, mapDispatchToProps)(CommentsContainer);
