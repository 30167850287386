import React from 'react';
import PropTypes from 'prop-types';
import { Container, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom';

import { BackButton } from '../buttons';
import { Button } from '../forms';
import { Heading, Panel } from '../layout/pages';
import { EmailSettingsModal } from '../modals';
import { BookingConfirmationModal, WelcomeEmailModal } from '../modals/emails';
import { routes } from '../../lib';

class MarketingPage extends React.PureComponent {
  static propTypes = {
    createEmailTemplate: PropTypes.func.isRequired,
    emailSettings:       PropTypes.object.isRequired,
    emailTemplates:      PropTypes.array.isRequired,
    fetchEmailTemplates: PropTypes.func.isRequired,
    getEmailSettings:    PropTypes.func.isRequired,
    isLoading:           PropTypes.object.isRequired,
    updateEmailSettings: PropTypes.func.isRequired,
    updateEmailTemplate: PropTypes.func.isRequired
  }

  state = {
    bookingConfirmationModal: false,
    emailSettingsModal:       false,
    welcomeEmailModal:        false
  }

  componentDidMount() {
    this.props.getEmailSettings();
    this.props.fetchEmailTemplates();
  }

  handleModal = (modal) => () => (
    this.setState((prevState) => ({ [modal]: !prevState[modal] }))
  )

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>My Marketing</title>
        </Helmet>

        {isMobile && <BackButton to={routes.SETTINGS} />}

        <div className='inner-wrapper portal marketing'>
          <Container fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Marketing' />

            <Panel className='settings-menu'>
              <div className='description text-muted'>
                Setup your email settings including  customizing the email sender name and the email header image.
              </div>

              <div className='row pt-2'>
                <Button
                    color='primary'
                    isOutline
                    onClick={this.handleModal('emailSettingsModal')}
                    size='sm'>
                  Edit Settings
                </Button>
              </div>

              <h4 className='menu-title'>
                Emails
              </h4>

              <Media className='menu-link'>
                <Media body>
                  Welcome Email
                </Media>

                <Media right>
                  <Button
                      isOutline
                      onClick={this.handleModal('welcomeEmailModal')}
                      size='sm'>
                    Edit
                  </Button>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media body>
                  Booking Confirmation Email
                </Media>

                <Media right>
                  <Button
                      isOutline
                      onClick={this.handleModal('bookingConfirmationModal')}
                      size='sm'>
                    Edit
                  </Button>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media body>
                  Win-Back Email Sequence
                </Media>

                <Media right>
                  <Link to={routes.WIN_BACK}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

            </Panel>
          </Container>
        </div>

        { this.state.emailSettingsModal &&
          <EmailSettingsModal
              emailSetting={this.props.emailSettings}
              isLoading={this.props.isLoading.emailSettings}
              isOpen={this.state.emailSettingsModal}
              onToggle={this.handleModal('emailSettingsModal')}
              updateEmailSettings={this.props.updateEmailSettings} />
        }

        { this.state.welcomeEmailModal &&
          <WelcomeEmailModal
              createEmailTemplate={this.props.createEmailTemplate}
              emailTemplates={this.props.emailTemplates}
              isLoading={this.props.isLoading.emailTemplates}
              isOpen={this.state.welcomeEmailModal}
              onToggle={this.handleModal('welcomeEmailModal')}
              updateEmailTemplate={this.props.updateEmailTemplate} />
        }

        { this.state.bookingConfirmationModal &&
          <BookingConfirmationModal
              createEmailTemplate={this.props.createEmailTemplate}
              emailTemplates={this.props.emailTemplates}
              isLoading={this.props.isLoading.emailTemplates}
              isOpen={this.state.bookingConfirmationModal}
              onToggle={this.handleModal('bookingConfirmationModal')}
              updateEmailTemplate={this.props.updateEmailTemplate} />
        }
      </React.Fragment>
    );
  }
}

export default MarketingPage;
