/* eslint-disable immutable/no-let */
import React from 'react';
import PropTypes from 'prop-types';
import { chain, compact, find, head } from 'lodash';
import { Helmet } from 'react-helmet';

import { sidebarItems } from '../../constants';
import { BookingModal, PrivateBookingModal } from '../modals';
import { Bookings, Calendar } from '../calendar';
import { Calendr } from '../../lib';
import { Heading } from '../layout/pages';

class SchedulePage extends React.PureComponent {
  static propTypes = {
    bookings:             PropTypes.array.isRequired,
    classPacks:           PropTypes.array.isRequired,
    createBooking:        PropTypes.func.isRequired,
    createDiscount:       PropTypes.func.isRequired,
    createPrivateBooking: PropTypes.func,
    deleteBooking:        PropTypes.func.isRequired,
    deletePrivateBooking: PropTypes.func.isRequired,
    deletePrivateSession: PropTypes.func.isRequired,
    discount:             PropTypes.object.isRequired,
    groupClasses:         PropTypes.array.isRequired,
    privateBookings:      PropTypes.array.isRequired,
    privateGroupClasses:  PropTypes.array,
    privateSessions:      PropTypes.array.isRequired,
    restoreDiscount:      PropTypes.func.isRequired,
    subject:              PropTypes.oneOf([
      sidebarItems.INSTRUCTOR_CLASS_PLANNER,
      sidebarItems.LOCATION_CLASS_PLANNER
    ]).isRequired,
    subscriptions: PropTypes.array.isRequired,
    user:          PropTypes.object.isRequired
  };

  static defaultProps = {
    createPrivateBooking: () => {},
    privateGroupClasses:  []
  }

  static getDerivedStateFromProps(props, state) {
    const { groupClasses, privateGroupClasses } = props;

    const isViewByInstructor = props.subject == sidebarItems.INSTRUCTOR_CLASS_PLANNER;
    const isViewByLocation   = props.subject == sidebarItems.LOCATION_CLASS_PLANNER;

    const instructors = Calendr.getInstructors(groupClasses, privateGroupClasses);
    const locations   = Calendr.getLocations(groupClasses, privateGroupClasses);

    const filteredInstructors = isViewByInstructor ? [head(instructors)] : instructors;
    const filteredLocations   = isViewByLocation   ? [head(locations)]   : locations;

    const instructorFilter = state.mounted ? state.instructorFilter : compact(filteredInstructors);
    const locationFilter   = state.mounted ? state.locationFilter   : compact(filteredLocations);

    const combined = props.subject.includes('Location') ? { groupClasses } : { groupClasses, privateGroupClasses };
    const events = Calendr.combineClasses(combined);

    const filteredEvents = chain(events).filter((event) => (
      !event.instructor || !!find(instructorFilter, ['id', event.instructor.id])
    )).filter((event) => (
      !event.location || !!find(locationFilter, ['id', event.location.id])
    )).value();

    const bookings = Calendr.combineBookings(props.bookings, props.privateBookings, props.privateSessions);

    return {
      bookings,
      events:  filteredEvents,
      instructorFilter,
      instructors,
      locationFilter,
      locations,
      mounted: state.mounted || !!groupClasses.length
    };
  }

  state = {
    bookings:                  [],
    events:                    [],
    groupClass:                {},
    instructorFilter:          [],
    isBookingModalOpen:        false,
    isBookingsOpen:            true,
    isPrivateBookingModalOpen: false,
    locationFilter:            [],
    mounted:                   false,
    privateGroupClass:         {}
  };

  handleDeleteBooking = (booking) => {
    this.props.deleteBooking(booking);
  };

  handleFilter = (value, inputName) => {
    this.setState({ [inputName]: value });
  };

  handleModal = (event = {}) => {
    const modal = event.type === 'privateGroupClass' ? 'isPrivateBookingModalOpen' : 'isBookingModalOpen';

    this.setState((prevState) => ({
      [event.type]: event,
      [modal]:      !prevState[modal]
    }));
  };

  handleCloseModals = () => {
    this.setState({
      isBookingModalOpen:        false,
      isPrivateBookingModalOpen: false
    });
  };

  handleReset = () => {
    this.setState({ instructorFilter: [], locationFilter: [] });
  };

  handleToggle = () => {
    this.setState((prevState) => ({ isBookingsOpen: !prevState.isBookingsOpen }));
  };

  render() {
    const type = this.props.subject.includes('Location');
    const subHeader = type ? 'View by Followed Studio' : 'View Favorited Instructor Schedule\'s';


    return (
      <div className='inner-wrapper portal'>
        <Helmet>
          <title>{subHeader}</title>
        </Helmet>

        <Heading
            subtitle='Client Portal'
            title={subHeader} />

        <Calendar
            events={this.state.events}
            hasViewSelect={false}
            isLocationSchedule={type}
            isPlanner
            onEventClick={this.handleModal}
            user={this.props.user} >
          <Bookings
              bookings={this.state.bookings}
              deleteBooking={this.props.deleteBooking}
              deletePrivateBooking={this.props.deletePrivateBooking}
              deletePrivateSession={this.props.deletePrivateSession}
              isOpen={this.state.isBookingsOpen}
              onToggle={this.handleToggle}
              user={this.props.user} />
        </Calendar>

        { this.state.isBookingModalOpen &&
          <BookingModal
              createBooking={this.props.createBooking}
              groupClass={this.state.groupClass}
              isOpen={this.state.isBookingModalOpen}
              onToggle={this.handleCloseModals}
              user={this.props.user} />
        }
        { this.state.isPrivateBookingModalOpen &&
          <PrivateBookingModal
              classPacks={this.props.classPacks}
              createBooking={this.props.createPrivateBooking}
              createDiscount={this.props.createDiscount}
              discount={this.props.discount}
              instructor={this.state.privateGroupClass.instructor}
              isOpen={this.state.isPrivateBookingModalOpen}
              onBook={this.handleBook}
              onToggle={this.handleCloseModals}
              privateBookings={this.props.privateBookings}
              privateGroupClass={this.state.privateGroupClass}
              restoreDiscount={this.props.restoreDiscount}
              subscriptions={this.props.subscriptions}
              user={this.props.user} />
        }
      </div>
    );
  }
}

export default SchedulePage;
