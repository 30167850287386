import { PrivateSessionTypesActionTypes } from '../constants/actionTypes';

export default {
  CLEAR: () => ({
    type: PrivateSessionTypesActionTypes.CLEAR
  }),


  CREATE: (payload) => ({
    payload, type: PrivateSessionTypesActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: PrivateSessionTypesActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionTypesActionTypes.CREATE_SUCCESS
  }),
  

  DELETE: (payload) => ({
    payload, type: PrivateSessionTypesActionTypes.DELETE
  }),

  DELETE_FAILURE: () => ({
    type: PrivateSessionTypesActionTypes.DELETE_FAILURE
  }),
  
  DELETE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionTypesActionTypes.DELETE_SUCCESS
  }),
  

  FETCH: () => ({
    type: PrivateSessionTypesActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: PrivateSessionTypesActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: PrivateSessionTypesActionTypes.FETCH_SUCCESS
  }),
  

  GET: (payload) => ({
    payload, type: PrivateSessionTypesActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: PrivateSessionTypesActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload, type: PrivateSessionTypesActionTypes.GET_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: PrivateSessionTypesActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: PrivateSessionTypesActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionTypesActionTypes.UPDATE_SUCCESS
  })
};
