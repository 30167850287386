export default {
  FETCH:         '@@challenges/FETCH',
  FETCH_FAILURE: '@@challenges/FETCH_FAILURE',
  FETCH_SUCCESS: '@@challenges/FETCH_SUCCESS',

  INSTRUCTORS_CREATE:         '@@challenges/INSTRUCTORS/CREATE',
  INSTRUCTORS_CREATE_FAILURE: '@@challenges/INSTRUCTORS/CREATE_FAILURE',
  INSTRUCTORS_CREATE_SUCCESS: '@@challenges/INSTRUCTORS/CREATE_SUCCESS',

  INSTRUCTORS_DELETE:         '@@challenges/INSTRUCTORS/CANCEL',
  INSTRUCTORS_DELETE_FAILURE: '@@challenges/INSTRUCTORS/CANCEL_FAILURE',
  INSTRUCTORS_DELETE_SUCCESS: '@@challenges/INSTRUCTORS/CANCEL_SUCCESS',

  INSTRUCTORS_FETCH:         '@@challenges/INSTRUCTORS/FETCH',
  INSTRUCTORS_FETCH_FAILURE: '@@challenges/INSTRUCTORS/FETCH_FAILURE',
  INSTRUCTORS_FETCH_SUCCESS: '@@challenges/INSTRUCTORS/FETCH_SUCCESS',

  INSTRUCTORS_GET:         '@@challenges/INSTRUCTORS/GET',
  INSTRUCTORS_GET_FAILURE: '@@challenges/INSTRUCTORS/GET_FAILURE',
  INSTRUCTORS_GET_SUCCESS: '@@challenges/INSTRUCTORS/GET_SUCCESS',

  INSTRUCTORS_UPDATE:         '@@challenges/INSTRUCTORS/UPDATE',
  INSTRUCTORS_UPDATE_FAILURE: '@@challenges/INSTRUCTORS/UPDATE_FAILURE',
  INSTRUCTORS_UPDATE_SUCCESS: '@@challenges/INSTRUCTORS/UPDATE_SUCCESS',


  USERS_CREATE:         '@@challenges/USERS/CREATE',
  USERS_CREATE_FAILURE: '@@challenges/USERS/CREATE_FAILURE',
  USERS_CREATE_SUCCESS: '@@challenges/USERS/CREATE_SUCCESS',

  USERS_DELETE:         '@@challenges/USERS/DELETE',
  USERS_DELETE_FAILURE: '@@challenges/USERS/DELETE_FAILURE',
  USERS_DELETE_SUCCESS: '@@challenges/USERS/DELETE_SUCCESS',

  USERS_FETCH:         '@@challenges/USERS/FETCH',
  USERS_FETCH_FAILURE: '@@challenges/USERS/FETCH_FAILURE',
  USERS_FETCH_SUCCESS: '@@challenges/USERS/FETCH_SUCCESS',

  USERS_UPDATE:         '@@challenges/USERS/UPDATE',
  USERS_UPDATE_FAILURE: '@@challenges/USERS/UPDATE_FAILURE',
  USERS_UPDATE_SUCCESS: '@@challenges/USERS/UPDATE_SUCCESS'
};
