import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { eye } from 'react-icons-kit/entypo/eye';
import { withLine } from 'react-icons-kit/entypo/withLine';
import { replace } from 'lodash';

const VisibilityIcon = ({ className, size, isActive, id, title }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const titleText = `${isActive ? 'Hide' : 'Show'} ${title}`;
  const properId = replace(id, /\//g, '-');

  return (
    <Fragment>
      <Icon
          className={className}
          icon={isActive ? eye : withLine}
          id={`toggle-${properId}`}
          size={size} />
      <Tooltip
          isOpen={tooltipOpen}
          placement='bottom'
          target={`toggle-${properId}`}
          toggle={toggle}
          trigger='hover'>
        {titleText}
      </Tooltip>
    </Fragment>
  );
};

VisibilityIcon.propTypes = {
  className: PropTypes.string,
  id:        PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isActive:  PropTypes.bool,
  size:      PropTypes.number,
  title:     PropTypes.string
};

VisibilityIcon.defaultProps = {
  className: '',
  id:        0,
  isActive:  false,
  size:      32,
  title:     ''
};

export default VisibilityIcon;