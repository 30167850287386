import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Button } from '../forms';

class ImageDownload extends React.PureComponent {
  static propTypes = {
    base64: PropTypes.string.isRequired,
    onBack: PropTypes.func.isRequired
  }

  render () {
    return (
      <Container
          className='px-3'
          fluid>
        <h3 className='text-primary text-uppercase text-center my-4'>
          Your story is here!
        </h3>
        <p className='text-muted'>
          Tap and hold the image below to save the image
        </p>
        <Row className='justify-content-center'>
          <Col xs={9}>
            <img
                alt='Your Instagram schedule'
                className='img-fluid border border-primary rounded'
                src={this.props.base64} />
            <Row className='mt-2'>
              <Col>
                <Button
                    color='danger'
                    isBlock
                    onClick={this.props.onBack}
                    size='lg'>
                  <Icon
                      icon='undo-alt'
                      size='lg' />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ImageDownload;
