/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { includes, isEmpty, map, orderBy } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';

import { Button, Checkbox, Form, MaskedInput, Radio, Select } from '../forms';
import { FileUploader, Input, OnDemandUploader } from '../inputs';
import { OnDemandSendEmail } from '../alerts';
import { Scroller, Validator } from '../../lib';
import { FEES, PURCHASE_TYPES, currencies } from '../../constants';
import { onDemandContentTypes, onDemandUploadTypes } from '../../constants';
import { currencyMask, dayMask } from '../../constants/masks';
import { SendEmailContainer } from '../../containers';
import { VideoPreview } from './';

const COMPATIBLE_TYPES = ['video/mp4', 'video/webm', 'video/quicktime', 'video/opgg'];

class RequestedClassForm extends React.PureComponent {
  static propTypes = {
    collectionId:    PropTypes.number.isRequired,
    collections:     PropTypes.array.isRequired,
    createRecording: PropTypes.func.isRequired,
    currency:        PropTypes.string.isRequired,
    data:            PropTypes.object,
    fetchRecordings: PropTypes.func.isRequired,
    folders:         PropTypes.array.isRequired,
    formats:         PropTypes.array.isRequired,
    instructor:      PropTypes.object,
    isLoading:       PropTypes.object,
    isNewRecord:     PropTypes.bool,
    onDelete:        PropTypes.func,
    onSubmit:        PropTypes.func,
    onToggle:        PropTypes.func.isRequired,
    recording:       PropTypes.object.isRequired,
    recordings:      PropTypes.array.isRequired,
    user:            PropTypes.object.isRequired
  }

  static defaultProps = {
    data: {
      contentType:  'file',
      cost:         '',
      description:  '',
      image:        '',
      name:         '',
      posterType:   'photo',
      purchaseType: 0,
      uploadType:   'device',
      url:          ''
    },
    instructor:  {},
    isLoading:   {},
    isNewRecord: true,
    onDelete:    () => {},
    onSubmit:    () => {}
  };

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(state.requestedClass)) return state;

    if (!props.isLoading.requestedClasses) {
      const { data } = props;
      const collections = props.collections.map((collection) => {
        return { label: collection.name, value: collection.id };
      });

      const folders = props.folders.map((folder) => {
        return { label: folder.name, value: folder.id };
      });

      const formats = map(props.formats, (format) => (
        { label: format.title, value: format.id }
      ));

      return {
        collections: collections,
        contentType: props.isNewRecord ? onDemandContentTypes.FILE : data.contentType,
        folders:     folders,
        formats,
        hasPreview:  !isEmpty(data.image),

        requestedClass: {
          canExpire:     data.canExpire ? data.canExpire : false,
          collectionId:  props.isNewRecord ? props.collectionId : data.collectionId,
          contentType:   data.contentType,
          cost:          data.cost,
          description:   data.description,
          expiry:        data.expiry ? data.expiry : '',
          folderId:      data.folderId,
          format:        data.formatName,
          formatId:      data.formatId,
          image:         data.image ? data.image.signedId : '',
          name:          data.name,
          posterType:    data.posterType ? data.posterType : 'photo',
          purchaseType:  data.purchaseType ? PURCHASE_TYPES[data.purchaseType].value : 0,
          recordingUuid: data.recordingUuid,
          sendEmail:     false,
          uploadType:    data.uploadType,
          url:           data.url ? data.url : ''
        },

        uploadType: props.isNewRecord ? onDemandUploadTypes.DEVICE : data.uploadType,

        uploads: {
          image: { ...data.image && data.image },
          video: { ...data.video }
        }
      };
    }

    return state;
  }

  componentDidUpdate (prevProps) {
    if (prevProps.data.id !== this.props.data.id) {
      this.setState({
        requestedClass: {},
        uploads:        {}
      });
    }
  }

  state = {
    collections:    [],
    contentType:    '',
    cropModalOpen:  false,
    errors:         {},
    folders:        [],
    hasPreview:     false,
    isModalOpen:    false,
    progress:       {},
    requestedClass: '',
    src:            '',
    uploadType:     '',
    uploads:        {}
  }

  inputRef = React.createRef();
  videoRef = React.createRef();

  handleChange = (value, inputName) => {
    const { errors } = this.state;

    delete errors[inputName];

    this.setState((prevState) => ({
      errors,
      requestedClass: {
        ...prevState.requestedClass,
        [inputName]: value
      }
    }));
  };

   handleSelect = (inputName) => (option) => (
      this.setState((prevState) => ({
        requestedClass: {
          ...prevState.requestedClass,
          [inputName]: option.id
        }
      }))
    )

  handleValidate = () => new Promise((resolve, reject) => {
    const { contentType, requestedClass, uploadType, uploads } = this.state;
    const { isNewRecord, recording } = this.props;
    const { cost } = requestedClass;

    const isUrl    = contentType === 'url';
    const isCloud  = uploadType === 'cloud';
    const isDevice = uploadType === 'device';
    const isPaid   = requestedClass.purchaseType == 0;

    const { currency } = this.props;
    const sign = currencies[currency].symbol;

    const errors = Validator.validate([
      ['name',        Validator.concepts.isFilled,  [requestedClass.name]],
      ['video',       Validator.concepts.isFilled,  [uploads.video.signedId], !isDevice || isUrl],
      ['url',         Validator.concepts.isFilled,  [requestedClass.url], !isUrl],
      ['url',         Validator.concepts.isUrl,     [requestedClass.url], !isUrl],
      ['cloud',       Validator.concepts.isFilled,  [recording.id], !isCloud || !isNewRecord],
      ['description', Validator.concepts.isFilled,  [requestedClass.description]],
      ['cost',        Validator.concepts.isFilled,  [requestedClass.cost], !isPaid],
      ['cost',        Validator.concepts.zeroCheck, [cost && cost.toString().replace(sign, '')], !isPaid],
      ['expiry',      Validator.concepts.isFilled,  [requestedClass.expiry], !requestedClass.canExpire],
      ['image',       Validator.concepts.isFilled,  [requestedClass.image], requestedClass.posterType !== 'image'],
      ['format',      Validator.concepts.isFilled,  [requestedClass.formatId]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  handleSubmit = () => {
    const { isNewRecord } = this.props;

    return (
      this.handleValidate()
        .then(() => (
          this.setState({ errors: {} }, () => (
            isNewRecord ? this.handleOpenAlert() : this.handleUpdate()
          ))
        ))
      .catch((errors) => (
        this.setState({ errors }, () => Scroller._scroll('smooth'))
      ))
    );
  }

  handleOpenAlert = () => {
    const options = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <OnDemandSendEmail
            onChange={this.handleChange}
            onClose={onClose}
            onCreate={this.handleCreate}
            onOpenModal={this.handleOpenModal}
            onToggle={() => this.props.onToggle} />
      )
    };
    confirmAlert(options);
  };

  handleCreate = (emailParams) => {
    const { requestedClass, uploads, contentType, uploadType } = this.state;
    const { recording } = this.props;

    const canExpire = contentType == 'url' ? false : requestedClass.canExpire;
    const isCloud = uploadType == 'cloud';

    const video = contentType == 'file' ? {
      recordingId: recording.id,
      video:       uploads.video && uploads.video.signedId,
      videoSize:   uploads.video && uploads.video.size
    } : {
      url: requestedClass.url
    };

    this.props.onSubmit({
      ...requestedClass,
      contentType: contentType,
      emailParams,
      expiry:      canExpire ? parseInt(String(requestedClass.expiry)) : 0,
      visible:     isCloud ? false : true,
      ...video
    });
  }

  handleUpdate = () => {
    const { requestedClass, uploads, contentType } = this.state;

    const video = contentType == 'file' ? {
      video:     uploads.video && uploads.video.signedId,
      videoSize: uploads.video && uploads.video.size
    } : {
      url: requestedClass.url
    };

    this.props.onSubmit({
      ...requestedClass,
      contentType: contentType,
      ...video
    });
  }

  handleDelete = () => (
    this.props.onDelete({ id: this.state.requestedClass.id })
  )

  handleVideoDelete = () => (
    this.setState({ uploads: { video: {} }})
  )

  handlePhotoSwitch = (value, inputName) => (
    this.setState((prevState) => ({
      requestedClass: {
        ...prevState.requestedClass,
        [inputName]: value
      }
    }))
  )

  handleUpload = (item, name) => (
    this.setState((prevState) => ({ uploads: { ...prevState.uploads, [name]: item } }))
  )

  handleContentTypeSwitch = (contentType) => {
    const { FILE, URL } = onDemandContentTypes;
    const { errors } = this.state;

    delete errors[contentType === FILE ? 'url' : 'video'];

    const posterType = (prevType, contentType) => {
      if (contentType === URL && prevType === 'preview') {
        return 'photo';
      }

      return prevType;
    };

    this.setState((prevState) => ({
      contentType,
      errors:         errors,
      requestedClass: {
        ...prevState.requestedClass,
        posterType: posterType(prevState.requestedClass.posterType, contentType)
      }
    }));
  }

  handleUploadTypeSwitch = (uploadType) => (
    this.setState((prevState) => ({ requestedClass: { ...prevState.requestedClass, uploadType }, uploadType }))
  )

  handleSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ photoName: e.target.files[0].name });
      this.handleModal();
    }
  };

  handleModal = () => (
    this.setState((prevState) => ({ cropModalOpen: !prevState.cropModalOpen }))
  )

  handleDeleteImage = () => (
    this.setState((prevState) => ({
      ...prevState,
      uploads: {
        ...prevState.uploads,
        image: {}
      }
    }))
  )

  handlePreviewLoad = () => (
    this.setState({ hasPreview: true })
  )

  handleToggle = (section) => (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      isImageSectionOpen: false,

      [section]: !prevState[section]
    }));
  }

  renderFee = () => {
    const { currency } = this.props;
    const { requestedClass } = this.state;
    const sign = currencies[currency].symbol;
    const amount = requestedClass.cost && parseFloat(requestedClass.cost.toString().replace(sign, '')) || 0;
    const fee = FEES.charge(amount);

    if (requestedClass.purchaseType == 0) {
      return (
        <small className='text-small text-muted mb-2 ml-2'>
          Client pays {parseFloat(amount + fee, 10).toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
          (incl. {parseFloat(fee, 10).toLocaleString('en-GB', { currency, style: 'currency' })} fee)
        </small>
      );
    }

    if (requestedClass.purchaseType == 1) {
      return (
        <small className='text-small text-muted mb-2 ml-2'>
          This will limit access to clients with a package (i.e. no drop-ins)
        </small>
      );
    }
  }

  handleOpenModal = () => (
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen
    }))
  )

  render() {
    const { isNewRecord, currency, instructor, isLoading, user } = this.props;
    const { requestedClass, errors, uploads, contentType, collections, folders, hasPreview, uploadType } = this.state;

    const sign = currencies[currency].symbol;
    const isUrl = contentType == 'url';
    const isCompatible = includes(COMPATIBLE_TYPES, uploads.video.type);

    const purchaseSelectOptions = orderBy(Object.values(PURCHASE_TYPES), ['value'], ['asc']);

    return (
      <Form className='form-default'>
        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Class Name
          </Label>

          <Input
              id='name'
              isInvalid={!!errors.name}
              name='name'
              onChange={this.handleChange}
              placeholder='Upper Body Workout'
              type='text'
              value={requestedClass.name} />
          <FormFeedback tooltip>{errors.name}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label
              className='required'
              for='input-format'>
            Class Format
          </Label>

          <Select
              hasInitialValue
              id='format'
              isInvalid={!!errors.format}
              name='formatId'
              onChange={this.handleChange}
              onSelect={this.handleChange}
              options={this.state.formats}
              placeholder='Select class format'
              value={requestedClass.formatId} />
        </FormGroup>

        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Collection
          </Label>

          <Select
              name='collectionId'
              onChange={this.handleChange}
              options={collections}
              value={requestedClass.collectionId} />
        </FormGroup>

        {folders.length > 0 &&
          <FormGroup>
            <Label
                className='required'
                for='input-name'>
              Folder
            </Label>

            <Select
                name='folderId'
                onChange={this.handleChange}
                options={folders}
                value={requestedClass.folderId} />
          </FormGroup>
        }

        <FormGroup>
          <Label
              className='required'
              for='input-video'>
            Video
          </Label>

          <OnDemandUploader
              allowedTypes={['video/mp4', 'video/webm', 'video/quicktime', 'video/mkv']}
              contentType={contentType}
              createRecording={this.props.createRecording}
              fetchRecordings={this.props.fetchRecordings}
              id='video'
              isInvalid={!!(errors.video || errors.url || errors.cloud)}
              isLoading={isLoading.recordings}
              isNewRecord={isNewRecord}
              maxFileSize='5GB'
              name='video'
              onContentTypeChange={this.handleContentTypeSwitch}
              onDelete={this.handleVideoDelete}
              onUpload={this.handleUpload}
              onUploadTypeChange={this.handleUploadTypeSwitch}
              onValueChange={this.handleChange}
              recording={this.props.recording}
              recordings={this.props.recordings}
              selectedUuid={requestedClass.recordingUuid}
              uploadType={uploadType}
              user={user}
              value={contentType === onDemandContentTypes.FILE ? uploads.video : requestedClass.url} />
          <FormFeedback tooltip>{errors.video || errors.url}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for='input-thumbnail'>
            Class Thumbnail
          </Label>

          <Row form>
            { !isCompatible && !isEmpty(uploads.video.type) &&
              <small className='text-danger w-100 ml-1'>
                Preview cannot be generated for this video
              </small>
            }

            <Col xs={5}>
              <div className='custom-control custom-radio mt-2'>
                <Radio
                    className='custom-control-input'
                    id='preview'
                    isChecked={requestedClass.posterType === 'preview'}
                    isDisabled={contentType === 'url' || uploadType == 'cloud' || !isCompatible}
                    name='posterType'
                    onChange={this.handlePhotoSwitch}
                    value='preview' />
                <Label
                    className='custom-control-label'
                    for='input-preview'>
                  Generate preview from video
                </Label>
              </div>

              <div className='custom-control custom-radio'>
                <Radio
                    className='custom-control-input'
                    id='photo'
                    isChecked={requestedClass.posterType === 'photo'}
                    name='posterType'
                    onChange={this.handlePhotoSwitch}
                    value='photo' />
                <Label
                    className='custom-control-label'
                    for='input-photo'>
                  Use my profile picture
                </Label>
              </div>

              <div className='custom-control custom-radio'>
                <Radio
                    className='custom-control-input'
                    id='image'
                    isChecked={requestedClass.posterType === 'image'}
                    name='posterType'
                    onChange={this.handlePhotoSwitch}
                    value='image' />
                <Label
                    className='custom-control-label'
                    for='input-image'>
                  Upload an image
                </Label>
              </div>
            </Col>
            <Col xs={7}>
              <div className='poster-preview'>
                { requestedClass.posterType === 'preview' &&
                  <VideoPreview
                      fileName={uploads.video.name}
                      isPreviewLoaded={hasPreview}
                      isVideoUploaded={!isEmpty(uploads.video)}
                      onChange={this.handleChange}
                      onLoad={this.handlePreviewLoad}
                      preview={uploads.image}
                      snapshotAtTime={8}
                      videoUrl={uploads.video.serviceUrl}
                  />
                }
                { requestedClass.posterType === 'image' &&
                  <FileUploader
                      error={errors.image}
                      fileTypes={['image/jpeg', 'image/png']}
                      hasCropper
                      isInvalid={!!errors.image}
                      maxFileSize='5MB'
                      name='image'
                      onChange={this.handleChange}
                      onDelete={this.handleDeleteImage}
                      placeholder='Drop image'
                      size='sm'
                      value={uploads.image} />
                }
                { requestedClass.posterType === 'photo' && instructor.photo &&
                  <img
                      alt={instructor.firstName}
                      src={instructor.photo.lg} />
                }
              </div>
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <Label
              className='required'
              for='input-description'>
            Description
          </Label>

          <Input
              id='description'
              isInvalid={!!errors.description}
              name='description'
              onChange={this.handleChange}
              rows='3'
              type='textarea'
              value={requestedClass.description} />
          <FormFeedback tooltip>{errors.description}</FormFeedback>
        </FormGroup>

        <Row form>
          <Col xs={6}>
            <FormGroup>
              <Label for='input-purchaseType'>
                Type
              </Label>

              <Select
                  disabledOptionType='value'
                  disabledOptionValues={user.oauth.stripe ? [] : [0, 1]}
                  name='purchaseType'
                  onChange={this.handleChange}
                  options={purchaseSelectOptions}
                  value={requestedClass.purchaseType} />
            </FormGroup>
          </Col>

          { requestedClass.purchaseType == 0 &&
            <Col xs={6}>
              <FormGroup>
                <Label
                    className='required'
                    for='input-cost'>
                  Cost
                </Label>

                <MaskedInput
                    id='cost'
                    inputMode='number'
                    isInvalid={!!errors.cost}
                    mask={currencyMask(sign)}
                    name='cost'
                    onChange={this.handleChange}
                    placeholder={sign + 10}
                    type='number'
                    value={requestedClass.cost} />
                <FormFeedback tooltip>{errors.cost}</FormFeedback>
              </FormGroup>
            </Col>
          }

          {this.renderFee()}
        </Row>

        <FormGroup className='w-50'>
          <Label for='input-can-expire'>Limit access after purchase?</Label>

          <div className='d-flex'>
            <div className='custom-control custom-checkbox checkbox-lg'>
              <Checkbox
                  className='custom-control-input'
                  id='can-expire'
                  isChecked={requestedClass.canExpire}
                  isDisabled={isUrl}
                  name='canExpire'
                  onChange={this.handleChange} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-can-expire' />
            </div>

            <MaskedInput
                className='ml-2'
                id='expiry'
                inputMode='number'
                isDisabled={!requestedClass.canExpire}
                isInvalid={!!errors.expiry}
                mask={dayMask}
                name='expiry'
                onChange={this.handleChange}
                placeholder=' days'
                type='number'
                value={String(requestedClass.expiry)} />
          </div>
        </FormGroup>

        { isNewRecord &&
          <Button
              className='mt-5'
              isBlock
              isDisabled={requestedClass.posterType === 'preview' && !hasPreview}
              isLoading={isLoading.requestedClasses}
              onClick={this.handleSubmit}
              size='lg'
              type='button'>
            Add Class
          </Button>
        }

        { !isNewRecord &&
          <Row className='mt-5'>
            <Col className='mb-2'>
              <Button
                  color='danger'
                  isBlock
                  isOutline
                  onClick={this.handleDelete}
                  size='lg'
                  type='button'>
                Delete
              </Button>
            </Col>

            <Col>
              <Button
                  isBlock
                  isDisabled={requestedClass.posterType === 'preview' && !hasPreview}
                  isLoading={isLoading.requestedClasses}
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Update
              </Button>
            </Col>
          </Row>
        }

        <SendEmailContainer
            isCreateOnDeamnd
            isOpen={this.state.isModalOpen}
            onCreate={this.handleCreate}
            onToggle={this.handleOpenModal} />
      </Form>
    );
  }
}

export default RequestedClassForm;
