import { connect } from 'react-redux';

import { ClassPacksActions, DiscountActions, FavoriteOnDemandsActions, MembershipsActions } from '../../actions';
import { RequestedClassesCarousel } from '../../components/pages/profile';

const mapStateToProps = (state) => ({
  challenges:       state.challenges.userData,
  classPacks:       state.classPacks.userData,
  discount:         state.discount.data,
  favorites:        state.favoriteOnDemands.data,
  instructor:       state.instructor.data,
  instructorPacks:  state.classPacks.data,
  loader:           state.loader,
  memberships:      state.memberships.instructorData,
  requestedClasses: state.requestedClasses.instructorData,
  subscriptions:    state.subscriptions.data,
  user:             state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createDiscount:   (data) => dispatch(DiscountActions.CREATE(data)),
  fetchClassPacks:  (data) => dispatch(ClassPacksActions.FETCH(data)),
  fetchMemberships: (data) => dispatch(MembershipsActions.INSTRUCTORS_FETCH(data)),
  like:             (data) => dispatch(FavoriteOnDemandsActions.CREATE(data)),
  restoreDiscount:  ()     => dispatch(DiscountActions.RESTORE()),
  subtractCredit:   (data) => dispatch(ClassPacksActions.SUBTRACT(data)),
  unLike:           (data) => dispatch(FavoriteOnDemandsActions.DELETE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestedClassesCarousel);
