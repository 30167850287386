import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Button, Form } from '../../forms';
import { Input } from '../../inputs';
import { routes } from '../../../lib';

class SignIn extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func
  }

  static defaultProps = {
    onSubmit: () => {}
  }

  state = {
    session: {
      email:    '',
      password: ''
    }
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      session: {
        ...prevState.session,
        [inputName]: value
      }
    }));
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.onSubmit(this.state);
  }

  handleTrimSpaces = (e) => {
    e.persist();
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [e.target.name]: e.target.value.replace(/\s/g, '')
      }
    }));
  }

  render() {
    return (
      <Form
          className='form-validate'
          onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for='email'>E-mail address</Label>
          <Input
              id='email'
              name='email'
              onBlur={this.handleTrimSpaces}
              onChange={this.handleChange}
              placeholder='name@address.com'
              type='email'
              value={this.state.session.email} />
        </FormGroup>

        <FormGroup>
          <Row>
            <Col>
              <Label for='password'>Password</Label>
            </Col>
            <div className="col-auto">
              <Link
                  className="form-text small"
                  to={routes.RECOVER_PASSWORD}>
                Forgot password?
              </Link>
            </div>
          </Row>
          <Input
              id='password'
              name='password'
              onChange={this.handleChange}
              type='password'
              value={this.state.session.password} />
        </FormGroup>
        <Button
            color='primary'
            isBlock
            size='lg'
            type='submit'>
          Sign in
        </Button>
      </Form>
    );
  }
}

export default SignIn;
