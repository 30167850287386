import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../../helpers';
import { SettingsMembershipPage } from '../../components/pages';
import { ProfileActions } from '../../actions';

class SettingsMembershipContainer extends React.PureComponent {
  render() {
    return (
      <SettingsMembershipPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  instructor: state.profile.data
});

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (data) => dispatch(ProfileActions.UPDATE(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(SettingsMembershipContainer);
