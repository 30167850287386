import { PlaylistActionTypes } from '../constants/actionTypes';

export default {
  FETCH: () => ({
    type: PlaylistActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: PlaylistActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload,
    type: PlaylistActionTypes.FETCH_SUCCESS
  }),


  GET: (payload) => ({
    payload,
    type: PlaylistActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: PlaylistActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload,
    type: PlaylistActionTypes.GET_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload,
    type: PlaylistActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: PlaylistActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload,
    type: PlaylistActionTypes.UPDATE_SUCCESS
  })
};
