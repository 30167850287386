import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../forms';

class ResetPassword extends React.PureComponent {
  static propTypes = {
    email:         PropTypes.string.isRequired,
    onClose:       PropTypes.func.isRequired,
    resetPassword: PropTypes.func.isRequired
  };

  handleReset = () => {
    const { email } =  this.props;

    this.props.onClose();
    this.props.resetPassword({ email });
  };

  render() {
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            <span>
              The client will be sent instructions <br />
              to reset their password via email.<br />
              Are you sure?
            </span>
          </CardText>

          <div className='text-center'>
            <Button
                color='primary'
                isOutline
                onClick={this.props.onClose}>
              Cancel
            </Button>

            <Button
                className='ml-2'
                color='primary'
                onClick={this.handleReset}>
              OK
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default ResetPassword;
