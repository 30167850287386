import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, FormGroup, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Validator, routes } from '../../lib';
import { Button } from '../forms';
import { Input } from '../inputs';


class GuestModal extends React.PureComponent {
  static propTypes = {
    createGuest: PropTypes.func.isRequired,
    instructor:  PropTypes.object.isRequired,
    isOpen:      PropTypes.bool,
    onSuccess:   PropTypes.func,
    onToggle:    PropTypes.func
  }

  static defaultProps = {
    isOpen:    false,
    onSuccess: () => {},
    onToggle:  () => {}
  }

  state = {
    errors: {},
    guest:  {}
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      guest: {
        ...prevState.guest,
        [inputName]: inputName === 'email' ? value.replace(' ', '') : value
      }
    }));
  }

  handleSubmit = () => {
    const { guest } = this.state;

    this.handleValidate().then(() => {
      return this.props.createGuest({
        guest: {
          ...guest,
          instructorId: this.props.instructor.id,
          timeZone:     Intl.DateTimeFormat().resolvedOptions().timeZone
        } });
    })
    .then(() => {
      return this.props.onSuccess();
    })
    .catch((errors) => {
      return this.setState({ errors });
    });
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const { guest } = this.state;

    const errors = Validator.validate([
      ['email', Validator.concepts.emailRegex, [guest.email]],
      ['name',  Validator.concepts.isFilled,   [guest.name]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  render() {
    return (
      <Modal
          className='event-modal'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          <strong>Continue as guest without logging in</strong>
        </ModalHeader>

        <ModalBody>
          <Form className='form-default'>
            <FormGroup>
              <Label for='input-name'>Full Name</Label>

              <Input
                  id='name'
                  isInvalid={!!this.state.errors.name}
                  name='name'
                  onChange={this.handleChange}
                  value={this.state.guest.name} />
            </FormGroup>

            <FormGroup>
              <Label for='input-email'>Email</Label>

              <Input
                  id='email'
                  isInvalid={!!this.state.errors.email}
                  name='email'
                  onChange={this.handleChange}
                  value={this.state.guest.email} />
            </FormGroup>

            <Button
                className='mt-4'
                color='primary'
                isBlock
                onClick={this.handleSubmit}
                size='lg'
                type='button'>
              Continue
            </Button>
          </Form>

          <hr
              className="my-3 hr-text letter-spacing-2"
              data-content="OR" />

          <Link to={routes.LOGIN}>
            <Button
                color='primary'
                isBlock
                isOutline
                size='lg'
                type='button'>
              Login
            </Button>
          </Link>

          <Link to={routes.SIGNUP}>
            <Button
                className='mt-4'
                color='muted'
                isBlock
                isOutline
                size='lg'
                type='button'>
              Sign Up
            </Button>
          </Link>
        </ModalBody>
      </Modal>
    );
  }
}

export default GuestModal;
