import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

import { routes } from '../../lib';

class LocationBadge extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    hasLink:   PropTypes.bool,
    location:  PropTypes.object.isRequired
  }

  static defaultProps = {
    className: '',
    hasLink:   true
  }

  render() {
    const { location, hasLink } = this.props;

    const className = 'badge-studio ' + this.props.className;
    const href = location.createdByUser ? '#' : routes.LOCATION(location.id);
    const name = location.studioName ? `${location.studioName} (${location.name})` : location.name;

    return (
      <React.Fragment>
        { hasLink &&
          <Badge
              className={className}
              color='light'
              pill>
            <Link
                title={name}
                to={href}>
              {name}
            </Link>
          </Badge>
        }
        { !hasLink &&
          <Badge
              className={className}
              color='light'
              pill>
            <span title={name}>{name}</span>
          </Badge>
        }
      </React.Fragment>
    );
  }
}

export default LocationBadge;
