import React from 'react';
import PropTypes from 'prop-types';
import StepWizard from 'react-step-wizard';
import confetti from 'canvas-confetti';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';
import { Redirect } from 'react-router';

import { Scroller } from '../../lib';
import { routes } from '../../lib';
import { Heading, StepsProgress } from '../layout/pages';
import { AdditionalInfoStep, BasicStep, IntegrateStep } from './profile/create';

class AddProfilePage extends React.PureComponent {
  static propTypes = {
    activities:          PropTypes.array.isRequired,
    createAdditionalnfo: PropTypes.func.isRequired,
    createProfile:       PropTypes.func.isRequired,
    fetchUser:           PropTypes.func.isRequired,
    finishCreate:        PropTypes.func.isRequired,
    join:                PropTypes.object.isRequired,
    oAuthLogout:         PropTypes.func.isRequired,
    resetValidation:     PropTypes.func.isRequired,
    segmentStripe:       PropTypes.func.isRequired,
    segmentZoom:         PropTypes.func.isRequired,  
    user:                PropTypes.object.isRequired,
    validateUsername:    PropTypes.func.isRequired,
    validation:          PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props) {
    if (!isEmpty(props.user)) {
      return {
        oauth: props.user.oauth
      };
    }

    return null;
  }

  state = {
    currentStep: 1,

    oauth: {
      instagram: false,
      spotify:   false
    },

    profile: {
      activities: [],
      firstName:  '',
      lastName:   '',
      photo:      null,
      tagline:    '',
      username:   ''
    },
    referral: new URLSearchParams(window.location.search).get('referral') || ''
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        [inputName]: value
      }
    }));
  }

  handleCreate = () => {
    this.props.createProfile(this.state.profile);

    new confetti({
      angle:         270,
      decay:         0.9,
      origin:        { y: 0 },
      particleCount: 300,
      spread:        360,
      startVelocity: 40,
      zIndex:        100
    });
  }

  handleStepChange = (step) => {
    this.props.resetValidation();

    this.setState({
      currentStep: step.activeStep
    });
    Scroller._scroll('smooth');
  }

  handleSubmit = () => {
    this.props.finishCreate();
  }

  handlePopulateFields = () => {
    const { username } = this.props.join;
    const { profile } = this.state;
    const currentUser = this.props.user;

    this.setState((prevState) => ({
      profile: {
        ...prevState.profile,
        firstName: profile.firstName || currentUser.firstName,
        lastName:  profile.lastName || currentUser.lastName,
        username:  username
      }
    }));
  }

  handleOAuthSuccess = () => {
    this.props.fetchUser();
  };

  handleStripeSuccess = () => {
    this.props.segmentStripe(this.props.user);
    this.props.fetchUser();
  }

  handleStripeZoom = () => {
    this.props.segmentZoom(this.props.user);
    this.props.fetchUser();
  }

  handleOAuthLogout = (provider) => {
    this.props.oAuthLogout(provider);
  }

  componentDidMount () {
    this.handlePopulateFields();
  }

  renderStepTitle = () => {
    switch (this.state.currentStep) {
      case 1:
        return 'Setup your basic page';

      case 2:
        return 'Additional information';

      case 3:
        return 'Integrate to automate';

      default:
        return null;
    }
  }

  render() {
    const { oauth, currentStep } = this.state;

    if (isEmpty(this.props.user)) {
      return <Redirect to={routes.SIGNUP} />;
    }

    return (
      <div className='inner-wrapper profile-create-page'>
        <Helmet>
          <title>Create your profile</title>
        </Helmet>

        <StepsProgress
            currentStep={currentStep}
            totalSteps={3} />

        <div className='content'>
          <Heading
              subtitle='Getting started'
              title={this.renderStepTitle()} />

          <StepWizard
              isLazyMount
              onStepChange={this.handleStepChange}>

            <BasicStep
                activities={this.props.activities}
                onChange={this.handleChange}
                onCreate={this.handleCreate}
                profile={this.state.profile}
                validateUsername={this.props.validateUsername}
                validation={this.props.validation} />

            <AdditionalInfoStep
                createAdditionalnfo={this.props.createAdditionalnfo}
                refferal={this.state.referral} />


            <IntegrateStep
                oAuth={oauth}
                onLogout={this.handleOAuthLogout}
                onSuccess={this.handleOAuthSuccess}
                onSuccessStripe={this.handleStripeSuccess}
                onSuccessZoom={this.handleZoonSuccess} />
          </StepWizard>
        </div>
      </div>
    );
  }
}

export default AddProfilePage;
