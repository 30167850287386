import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { customStyles } from '../../lib/customizationValues';

class CustomizationHelmet extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired
  }

  fontsToDownload = () => {
    const { instructor } = this.props;
    const { customization } = instructor;
    const font1 = customization.bodyFontFamily.split(',')[0].replace(/\s/g, '+');
    const font2 = customization.headingsFontFamily.split(',')[0].replace(/\s/g, '+');

    return `https://fonts.googleapis.com/css2?family=${font1}&family=${font2}&display=swap`;
  };

  render() {
    const { instructor } = this.props;
    const { customization, photo } = instructor;

    return (
      <Helmet>
        <title>{instructor.name}</title>
        <meta
            content={instructor.name}
            property="og:title" />
        <meta
            content={instructor.tagline}
            property="og:description" />
        <meta
            content={photo && photo.lg}
            property="og:image" />

        <link
            href="https://fonts.gstatic.com"
            rel="preconnect" />
        <link
            href={this.fontsToDownload()}
            rel="stylesheet" />
        <style type="text/css">
          {customStyles(customization)}
        </style>
      </Helmet>
    );
  }
}

export default CustomizationHelmet;
