import React from 'react';
import { connect } from 'react-redux';

import { ContactPage } from '../components/pages';
import { FeedbackActions } from '../actions';

class ContactContainer extends React.PureComponent {
  render() {
    return <ContactPage {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  feedback: state.feedback
});

const mapDispatchToProps = (dispatch) => ({
  sendFeedback: (data) => dispatch(FeedbackActions.CREATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer);