import React from 'react';
import PropTypes from 'prop-types';
import { Container, FormGroup, Label, Media, Spinner } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { debounce } from 'lodash';

import { Checkbox, MaskedInput } from '../../forms';
import { Heading, Panel } from '../../layout/pages';
import { BackButton } from '../../buttons';
import { routes } from '../../../lib';
import { hourMask } from '../../../constants/masks';

class CancellationPage extends React.PureComponent {
  static propTypes = {
    getStrategy:    PropTypes.func.isRequired,
    isLoading:      PropTypes.bool.isRequired,
    strategy:       PropTypes.object.isRequired,
    updateStrategy: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const { strategy } = props;

    if (strategy.id && !state.isMounted) {
      return {
        isMounted: true,
        strategy:  strategy ? { ...state.strategy, ...strategy } : state.strategy
      };
    }

    return state;
  }

  componentDidMount() {
    this.props.getStrategy();
  }

  state = {
    isMounted: false,

    strategy: {
      allowCancel:    false,
      allowRefund:    false,
      cancelDeadline: 0,
      name:           'cancellation policy'
    }
  }

  handleChange = (setting) => (value) => (
    this.setState((prevState) => ({
      strategy: { ...prevState.strategy, [setting]: value || 0 }
    }), this.handleUpdate)
  )

  handleUpdate = () => {
    const { strategy } = this.state;

    this.props.updateStrategy({ strategy });
  }

  renderSpinner = () => (
    <div className='d-flex justify-content-center my-5'>
      <Spinner color='muted' />
    </div>
  )

  render() {
    const { allowCancel, allowRefund, cancelDeadline } = this.state.strategy;

    if (this.props.isLoading && isMobile) return this.renderSpinner();

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Cancellation Policy</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Cancellation Policy' />

            <Panel className='settings-menu'>
              <h4 className='menu-title'>Cancellation Policy</h4>

              <Media className='menu-link d-block'>
                <Media body>
                  <div className='text-medium text-muted'>
                    Setup your cancellation policy for clients.&nbsp;
                    Note that the policy will only apply to clients who reserve as a user and&nbsp;
                    <strong>will not</strong> apply to clients who reserve as a guest.
                  </div>

                  <div className='my-3 custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='cancel'
                        isChecked={allowCancel}
                        name='allowCancellation'
                        onChange={this.handleChange('allowCancel')} />

                    <Label
                        className='custom-control-label d-flex'
                        htmlFor='input-cancel'>
                      Allow clients to cancel classes
                    </Label>
                  </div>

                  <FormGroup>
                    <Label for='input-deadline'>
                      How long before the class starts can they cancel?
                    </Label>

                    <MaskedInput
                        id='deadline'
                        inputMode='number'
                        isDisabled={!allowCancel}
                        mask={hourMask}
                        name='cancelDeadline'
                        onChange={debounce(this.handleChange('cancelDeadline'), 500)}
                        placeholder=' hours'
                        type='number'
                        value={cancelDeadline} />
                  </FormGroup>

                  <div className='text-medium text-muted'>
                    Leave as 0 if you want clients to be able to cancel up until the class start time
                  </div>
                </Media>
              </Media>

              <Media className='menu-link d-block'>
                <Media body>
                  <div className='mb-3 custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='refund'
                        isChecked={allowRefund}
                        onChange={this.handleChange('allowRefund')} />

                    <Label
                        className='custom-control-label d-flex'
                        htmlFor='input-refund'>
                      Refund or recredit a client who cancels
                    </Label>
                  </div>

                  <div className='text-medium text-muted mb-2'>
                    <strong>Card:</strong>&nbsp;
                    If the client reserved and paid by card then they will be refunded automatically.
                  </div>

                  <div className='text-medium text-muted mb-2'>
                    <strong>Class Pack:</strong>&nbsp;
                    If the client reserved and used a class pack then they will be recredited automatically.
                  </div>

                  <div className='text-medium text-muted mb-2'>
                    <strong>Membership:</strong>&nbsp;
                    If the client reserved and used a membership then they will not receive a refund or recreit.
                  </div>
                </Media>
              </Media>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default CancellationPage;
