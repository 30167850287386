import { connect } from 'react-redux';

import MembershipsSummary from './membershipsSummary';

const mapStateToProps = (state) => ({
  isLoading: state.transactions.isLoading,
  summary:   state.transactions.summary.memberships,
  user:      state.user.data
});

export default connect(mapStateToProps)(MembershipsSummary);