import { connect } from 'react-redux';

import { ClassPacksActions, SamplesActions } from '../../actions';
import { ClassPackModal } from '../../components/modals/instructor';

const mapStateToProps = (state) => ({
  classPack: state.classPacks.form,
  isLoading: state.loader.classPacks.isLoading,
  user:      state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  cancelClassPack: (data) => dispatch(ClassPacksActions.INSTRUCTORS_DELETE(data)),
  createClassPack: (data) => dispatch(ClassPacksActions.INSTRUCTORS_CREATE(data)),
  deleteSample:    (data) => dispatch(SamplesActions.DELETE_CLASS_PACK(data)),
  updateClassPack: (data) => dispatch(ClassPacksActions.INSTRUCTORS_UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassPackModal);
