import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../helpers';
import { CalendarPage } from '../components/pages';
import {
  FormatsActions,
  GroupClassesActions,
  LocationsActions,
  MeetingActions,
  PlansActions,
  PlaylistActions,
  PrivateBookingsActions,
  PrivateGroupClassesActions,
  PrivateSessionsActions,
  SamplesActions,
  SynchronizationActions,
  TimeBlocksActions
} from '../actions';

class CalendarContainer extends React.PureComponent {
  static propTypes = {
    fetchClassesSamples:      PropTypes.func.isRequired,
    fetchFormats:             PropTypes.func.isRequired,
    fetchGroupClasses:        PropTypes.func.isRequired,
    fetchLocations:           PropTypes.func.isRequired,
    fetchPrivateGroupClasses: PropTypes.func.isRequired,
    fetchPrivateSessions:     PropTypes.func.isRequired,
    fetchSync:                PropTypes.func.isRequired,
    fetchTimeBlocks:          PropTypes.func.isRequired,
    getSubscription:          PropTypes.func.isRequired,
    instructor:               PropTypes.object.isRequired,
    user:                     PropTypes.object.isRequired
  }

  componentDidMount() {
    const { username } = this.props.instructor;

    this.props.fetchGroupClasses();
    this.props.fetchPrivateSessions();
    this.props.fetchPrivateGroupClasses();
    this.props.fetchTimeBlocks({ username });
    this.props.fetchClassesSamples();
    this.props.fetchLocations();
    this.props.getSubscription();
    this.props.fetchFormats();
  }

  render() {
    return (
      <CalendarPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  formats:             state.formats.data,
  groupClasses:        state.groupClasses.data,
  instructor:          state.profile.data,
  locations:           state.locations.data,
  playlists:           state.playlists.data,
  privateGroupClasses: state.privateGroupClasses.data,
  privateSessions:     state.privateSessions.instructorData,
  sampleClasses:       state.samples.privateGroupClasses,
  synchronization:     state.synchronization,
  timeBlocks:          state.timeBlocks.data,
  user:                state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createFormats:                    (data) => dispatch(FormatsActions.CREATE_PGC_FORMAT(data)),
  createGroupClass:                 (data) => dispatch(GroupClassesActions.CREATE(data)),
  createPrivateBookings:            (data) => dispatch(PrivateBookingsActions.INSTRUCTORS_CREATE(data)),
  createPrivateGroupClass:          (data) => dispatch(PrivateGroupClassesActions.CREATE(data)),
  createPrivateSession:             (data) => dispatch(PrivateSessionsActions.INSTRUCTORS_CREATE(data)),
  createRecurringGroupClass:        (data) => dispatch(GroupClassesActions.RECURRING_CREATE(data)),
  createRecurringPrivateGroupClass: (data) => dispatch(PrivateGroupClassesActions.RECURRING_CREATE(data)),
  createRecurringPrivateSession:    (data) => dispatch(PrivateSessionsActions.RECURRING_CREATE(data)),
  createRecurringTimeBlock:         (data) => dispatch(TimeBlocksActions.RECURRING_CREATE(data)),
  createSync:                       (data) => dispatch(SynchronizationActions.CREATE(data)),
  createTimeBlock:                  (data) => dispatch(TimeBlocksActions.CREATE(data)),
  deleteGroupClass:                 (data) => dispatch(GroupClassesActions.DELETE(data)),
  deleteMeeting:                    (data) => dispatch(MeetingActions.DELETE(data)),
  deletePrivateBooking:             (data) => dispatch(PrivateBookingsActions.INSTRUCTORS_DELETE(data)),
  deletePrivateGroupClass:          (data) => dispatch(PrivateGroupClassesActions.DELETE(data)),
  deletePrivateSession:             (data) => dispatch(PrivateSessionsActions.INSTRUCTORS_DELETE(data)),
  deleteRecurringGroupClass:        (data) => dispatch(GroupClassesActions.RECURRING_DELETE(data)),
  deleteRecurringPrivateGroupClass: (data) => dispatch(PrivateGroupClassesActions.RECURRING_DELETE(data)),
  deleteRecurringPrivateSession:    (data) => dispatch(PrivateSessionsActions.RECURRING_DELETE(data)),
  deleteRecurringTimeBlock:         (data) => dispatch(TimeBlocksActions.RECURRING_DELETE(data)),
  deleteTimeBlock:                  (data) => dispatch(TimeBlocksActions.DELETE(data)),
  fetchClassesSamples:              () =>     dispatch(SamplesActions.FETCH_PRIVATE_GROUP_CLASSES()),
  fetchFormats:                     () =>     dispatch(FormatsActions.FETCH()),
  fetchGroupClasses:                () =>     dispatch(GroupClassesActions.FETCH()),
  fetchLocations:                   () =>     dispatch(LocationsActions.FETCH()),
  fetchPlaylists:                   () =>     dispatch(PlaylistActions.FETCH()),
  fetchPrivateGroupClasses:         () =>     dispatch(PrivateGroupClassesActions.FETCH()),
  fetchPrivateSessions:             () =>     dispatch(PrivateSessionsActions.INSTRUCTORS_FETCH()),
  fetchSync:                        () =>     dispatch(SynchronizationActions.FETCH()),
  fetchTimeBlocks:                  (data) => dispatch(TimeBlocksActions.FETCH(data)),
  getSubscription:                  ()     => dispatch(PlansActions.SUBSCRIPTION_GET()),
  updateGroupClass:                 (data) => dispatch(GroupClassesActions.UPDATE(data)),
  updateTimeBlock:                  (data) => dispatch(TimeBlocksActions.UPDATE(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(CalendarContainer);
