import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { templates } from '../../../../constants';
import StyleDefault from './default';
import StyleUrban from './urban';
import StyleElegant from './elegant';

class StyleContainer extends React.PureComponent {
  static propTypes = {
    instructor:   PropTypes.object.isRequired,
    siteTemplate: PropTypes.string.isRequired
  }

  renderTemplate = () => {
    const { siteTemplate } = this.props;

    switch (siteTemplate) {
      case templates.ELEGANT: return <StyleElegant {...this.props} />;

      case templates.URBAN: return <StyleUrban {...this.props} />;

      default: return <StyleDefault {...this.props} />;
    }
  }

  render() {
    return (
      <div className='inner-wrapper instructor-profile'>
        {this.renderTemplate()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  instructor: state.instructor.data,
  playlist:   state.playlist.data
});

export default connect(mapStateToProps)(StyleContainer);
