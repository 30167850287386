import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../forms';

class InsrtuctorCancelMembership extends React.PureComponent {
  static propTypes = {
    cancelMembership: PropTypes.func.isRequired,
    deleteSample:     PropTypes.func.isRequired,
    membership:       PropTypes.object.isRequired,
    onClose:          PropTypes.func.isRequired,
    onSuccess:        PropTypes.func.isRequired
  };

  handleKeep = () => {
    this.props.onClose();
  };

  handleCancel = () => {
    const { cancelMembership, deleteSample, membership } = this.props;
    const params = { membership: { id: membership.id }};

    membership.sample ? deleteSample(params) : cancelMembership(params);
    this.props.onClose();
    this.props.onSuccess();
  };

  render() {
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            <span>
              Are you sure you want to <span className='text-danger'>cancel</span> your<br />
              <span className='text-primary'> {this.props.membership.name} membership?</span>
            </span>

            <div className='mt-4'>
              <small className='text-muted'>
                Note: this will cancel the membership for all<br />
                active members and stop all future charges.
              </small>
            </div>
          </CardText>

          <div className='actions'>
            <Button
                color='danger'
                isOutline
                onClick={this.handleCancel}>
              Yes-Cancel
            </Button>

            <Button
                color='primary'
                onClick={this.handleKeep}>
              No-Keep
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default InsrtuctorCancelMembership;
