import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { push } from 'connected-react-router';

import { LocationPage } from '../components/pages';
import {
  BookingsActions,
  GroupClassesActions,
  LocationActions
} from '../actions';

class LocationContainer extends React.PureComponent {
  static propTypes = {
    getGroupClasses: PropTypes.func.isRequired,
    getLocation:     PropTypes.func.isRequired,
    location:        PropTypes.object.isRequired,
    match:           PropTypes.object.isRequired
  }

  componentDidMount() {
    const { locationId } = this.props.match.params;

    this.props.getLocation({ id: locationId });
    this.props.getGroupClasses({ id: locationId });
  }

  render() {
    if (!isEmpty(this.props.location.data)) {
      return <LocationPage {...this.props} />;
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  groupClasses: state.groupClasses,
  location:     state.location,
  session:      state.session
});

const mapDispatchToProps = (dispatch) => ({
  createBooking:    (data)  => dispatch(BookingsActions.CREATE(data)),
  followLocation:   () =>      dispatch(LocationActions.FOLLOW()),
  getGroupClasses:  (data)  => dispatch(GroupClassesActions.LOCATIONS_GET(data)),
  getLocation:      (data)  => dispatch(LocationActions.GET(data)),
  goTo:             (route) => dispatch(push(route)),
  unfollowLocation: () =>      dispatch(LocationActions.UNFOLLOW())
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationContainer);
