import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, FormGroup, Label, ModalBody } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Validator, api, routes } from '../../../../lib';
import { Button } from '../../../forms';
import { Input } from '../../../inputs';
import { BOOKING_STEPS, FEE_TYPES } from '../../../../constants';

class Guest extends React.PureComponent {
  static propTypes = {
    instructor:        PropTypes.object.isRequired,
    isLoading:         PropTypes.bool.isRequired,
    onBook:            PropTypes.func.isRequired,
    onChange:          PropTypes.func,
    onLoading:         PropTypes.func.isRequired,
    onNext:            PropTypes.func,
    privateGroupClass: PropTypes.object.isRequired
  }

  static defaultProps = {
    onChange: () => {},
    onNext:   () => {}
  }

  state = {
    errors: {},
    guest:  {}
  }

  _calcStep = () => {
    const { privateGroupClass } = this.props;

    switch (FEE_TYPES[privateGroupClass.feeType]) {
      case FEE_TYPES.free:
        return BOOKING_STEPS.success;

      case FEE_TYPES.paid:
        return BOOKING_STEPS.charge;

      case FEE_TYPES.donation:
        return BOOKING_STEPS.donation;

      default:
        return BOOKING_STEPS.info;
    }
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      guest: {
        ...prevState.guest,
        [inputName]: inputName === 'email' ? value.replace(' ', '') : value
      }
    }));
  }

  handleNext = () => {
    const nextStep = this._calcStep();

    if (nextStep == BOOKING_STEPS.success) {
      this.props.onBook();
    } else {
      this.props.onNext(nextStep);
    }
  }

  handleSubmit = () => {
    const { onLoading } = this.props;
    const { guest } = this.state;

    onLoading(true, () => (
      this.handleValidate().then(() => (
        api.client.create({
          guest: {
            ...guest,
            instructorId: this.props.instructor.id,
            timeZone:     Intl.DateTimeFormat().resolvedOptions().timeZone
          }})
        ))
      .then((response) => (
        onLoading(false, () => (
          this.props.onChange(response.data, 'guest', this.handleNext)
        ))
      ))
      .catch((errors) => (
        this.setState({ errors }, () => onLoading(false))
      ))
    ));
  };


  handleValidate = () => new Promise((resolve, reject) => {
    const { guest } = this.state;

    const errors = Validator.validate([
      ['email', Validator.concepts.emailRegex, [guest.email]],
      ['name',  Validator.concepts.isFilled,   [guest.name]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  render() {
    return (
      <ModalBody>
        <Form className='form-default'>
          <h5 className='mb-2'>Continue as guest without logging in</h5>

          <FormGroup>
            <Label for='input-name'>Full Name</Label>

            <Input
                id='name'
                isInvalid={!!this.state.errors.name}
                name='name'
                onChange={this.handleChange}
                value={this.state.guest.name} />
          </FormGroup>

          <FormGroup>
            <Label for='input-email'>Email</Label>

            <Input
                id='email'
                isInvalid={!!this.state.errors.email}
                name='email'
                onChange={this.handleChange}
                value={this.state.guest.email} />
          </FormGroup>

          <Button
              className='mt-4'
              color='primary'
              isBlock
              isLoading={this.props.isLoading}
              onClick={this.handleSubmit}
              size='lg'
              type='button'>
            Continue
          </Button>
        </Form>

        <hr
            className="my-3 hr-text letter-spacing-2"
            data-content="OR" />

        <Link to={routes.LOGIN}>
          <Button
              color='primary'
              isBlock
              isOutline
              size='lg'
              type='button'>
            Login
          </Button>
        </Link>

        <Link to={routes.SIGNUP}>
          <Button
              className='mt-4'
              color='muted'
              isBlock
              isOutline
              size='lg'
              type='button'>
            Sign Up
          </Button>
        </Link>
      </ModalBody>
    );
  }
}

export default Guest;
