import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, FormGroup } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Button } from '../';
import { Input } from '../../inputs';

class Educations extends React.PureComponent {
  static propTypes = {
    children:   PropTypes.node.isRequired,
    className:  PropTypes.string.isRequired,
    educations: PropTypes.array.isRequired,
    onChange:   PropTypes.func.isRequired
  };

  state = {
    educations: []
  }

  componentDidUpdate (prevProps) {
    if (prevProps.educations.length !== this.props.educations.length) {
      this.setState(() => ({
        educations: this.props.educations
      }), () => {
        this.props.onChange(this.state.educations, 'educations');
      });
    }
  }

  handleAdd = () => {
    this.setState((prevState) => ({
      educations: [...prevState.educations, { delete: false, name: '' }]
    }), () => {
      this.props.onChange(this.state.educations, 'educations');
    });
  }

  handleChange = (index) => (value, inputName) => {
    this.setState((prevState) => {
      const newEducations = Array.from(prevState.educations);

      newEducations.splice(index, 1, { ...newEducations[index], [inputName]: value });
      return {
        educations: newEducations
      };
    }, () => {
      this.props.onChange(this.state.educations, 'educations');
    });
  }

  handleRemove = (index) => (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      const newEducations = Array.from(prevState.educations);
      const targetEdu = newEducations[index];

      targetEdu.id ? newEducations.splice(index, 1, {...targetEdu, delete: true}) : newEducations.splice(index, 1);

      return {
        educations: newEducations
      };
    }, () => {
      this.props.onChange(this.state.educations, 'educations');
    });
  }

  render() {
    const { educations } = this.state;
    const lastIndex = this.state.educations.length - 1;

    return (
      <div className={this.props.className}>
        {this.props.children}

        { !!educations.length && educations.map((education, index) => (
          <FormGroup
              className={classNames('align-items-end', { 'd-none': education.delete })}
              key={index}
              row>
            <Col
                lg={11}
                xs={10}>
              <Input
                  name='name'
                  onChange={this.handleChange(index)}
                  placeholder='Name of institution'
                  type='text'
                  value={education.name} />
            </Col>

            <Col
                lg={1}
                xs={2}>
              { index === lastIndex &&
                <Button
                    color='success'
                    onClick={this.handleAdd}
                    onKeyPress={this.handleAdd}
                    role='button'
                    size='icon'
                    tabIndex={0}>
                  <Icon
                      fixedWidth
                      icon={['fas', 'plus']} />
                </Button>
              }

              { index !== lastIndex &&
                <Button
                    color='danger'
                    onClick={this.handleRemove(index)}
                    onKeyPress={this.handleRemove(index)}
                    role='button'
                    size='icon'
                    tabIndex={0}>
                  <Icon
                      fixedWidth
                      icon={['fas', 'times']} />
                </Button>
              }
            </Col>
          </FormGroup>
        ))}
      </div>
    );
  }
}

export default Educations;
