/* eslint-disable immutable/no-let */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { map } from 'lodash';
import { Button } from 'reactstrap';

class Tags extends React.PureComponent {
  static propTypes = {
    className:   PropTypes.string,
    color:       PropTypes.string,
    isInvalid:   PropTypes.bool,
    name:        PropTypes.string,
    onChange:    PropTypes.func,
    options:     PropTypes.arrayOf(PropTypes.object),
    selectedIds: PropTypes.array.isRequired
  }

  static defaultProps = {
    className: '',
    color:     'info',
    isInvalid: false,
    isMulti:   true,
    name:      '',
    onChange:  () => {},
    options:   [{ id: 1, title: 'Tag 1' }, { id: 2, title: 'Tag 2' }]
  }

  static getDerivedStateFromProps(props) {
    return {
      selectedIds: props.selectedIds
    };
  }

  state = {
    selectedIds: []
  }

  renderTags = () => {
    return (
      !!this.props.options.length &&
       map(this.props.options, (option) => (
         <Button
             className='btn-tag '
             color={this.props.color}
             key={option.id}
             onClick={this.handleTagClick(option)}
             outline={!this.state.selectedIds.includes(option.id)}
             size='sm'>
           {option.title}
         </Button>
    )
  ));
};

  handleTagClick = (option) => () => {
    this.props.onChange(option, this.props.name);
  }

  render() {
    return (
      <div className={classNames('custom-tag-select', this.props.className, { 'is-invalid': this.props.isInvalid })}>
        {this.renderTags()}
      </div>
    );
  }
}

export default Tags;
