import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../../forms';

class TimeBlockDeleteAlert extends React.PureComponent {
  static propTypes = {
    onClose:     PropTypes.func.isRequired,
    onDelete:    PropTypes.func.isRequired,
    onDeleteAll: PropTypes.func.isRequired
  }

  handleDelete = () => {
    this.props.onDelete();
    this.props.onClose();
  }

  handleDeleteAll = () => {
    this.props.onDeleteAll();
    this.props.onClose();
  }

  render () {
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            You want delete only this time block or all recurring too?
          </CardText>
          <div className='actions'>
            <Button
                color='danger'
                isOutline
                onClick={this.handleDelete}>
              Delete this
            </Button>
            <Button
                color='danger'
                onClick={this.handleDeleteAll}>
              Delete all
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default TimeBlockDeleteAlert;