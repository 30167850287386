import { connect } from 'react-redux';

import { ApplicationActions, DomainActions, SessionActions } from '../../../actions';
import Header from './header';

const mapStateToProps = (state) => ({
  domain:     state.domain.data,
  instructor: state.instructor.data,
  profile:    state.profile.data,
  session:    state.session,
  user:       state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  getDomain:     (data) => dispatch(DomainActions.GET(data)),
  logOut:        () => dispatch(SessionActions.LOGOUT()),
  toggleSidebar: () => dispatch(ApplicationActions.SIDEBAR_TOGGLE())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
