import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'react-icons-kit';
import { isMobile } from 'react-device-detect';
import { record } from 'react-icons-kit/ionicons/record';
import { isEmpty } from 'lodash';
import Header from '../components/headerUrban';

import { InstructorName } from '../../../../components/instructor';
import { ClassPacks, Memberships } from '../../../../components/pages/profile/instructor';

class PackagesUrban extends React.PureComponent {

  static propTypes = {
    classPacks:         PropTypes.array.isRequired,
    createClassPack:    PropTypes.func.isRequired,
    createDiscount:     PropTypes.func.isRequired,
    createSubscription: PropTypes.func.isRequired,
    discount:           PropTypes.object.isRequired,
    follow:             PropTypes.func.isRequired,
    instructor:         PropTypes.object.isRequired,
    memberships:        PropTypes.array.isRequired,
    restoreDiscount:    PropTypes.func.isRequired,
    subscriptions:      PropTypes.array.isRequired,
    unFollow:           PropTypes.func.isRequired,
    user:               PropTypes.object.isRequired
  }

  handleFollow = () => {
    this.props.instructor.isFollowing ? this.props.unFollow() : this.props.follow();
  };

  renderMembershipReason = (reason) => {
    if (!isEmpty(reason.text)) {
      return (
        <div
            className='reason mb-1 mt-1'
            key={reason.id}>
          <Icon
              className='mr-2'
              icon={record}
              size={isMobile? '12' : '16'} />
          <span className='membership-reason'>{reason.text}</span>
        </div>
      );
    }
  }

  render() {
    const { classPacks, instructor, user, memberships, subscriptions } = this.props;

    return (
      <div className='profile-section'>
        <Header
            instructor={instructor}
            subtitle='Packages' />

        <div className='memberships template-urban'>
          <div className='content'>
            { isMobile &&
              <InstructorName
                  instructor={instructor}
                  subtitle='Membership' />
            }
            <div className='mb-2'>
              <h3 className='page-title'>Purchase a package!</h3>
              { !isEmpty(instructor.membershipReasons) &&
                <div className='mb-4 mt-2'>
                  {instructor.membershipReasons.map(this.renderMembershipReason)}
                </div>
              }
            </div>

            { !isEmpty(instructor) && memberships.length &&
              <React.Fragment>
                <Memberships
                    createSubscription={this.props.createSubscription}
                    instructor={instructor}
                    memberships={memberships}
                    subscriptions={subscriptions}
                    user={user} />
              </React.Fragment>
            }

            { !isEmpty(instructor) && classPacks.length &&
              <div className='mt-4'>
                <ClassPacks
                    classPacks={classPacks}
                    createClassPack={this.props.createClassPack}
                    createDiscount={this.props.createDiscount}
                    discount={this.props.discount}
                    instructor={instructor}
                    restoreDiscount={this.props.restoreDiscount}
                    user={user} />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default PackagesUrban;
