export default {
  ACCOUNT:                  'account',
  CALENDAR:                 'calendar',
  CLIENTS:                  'clients',
  DASHBOARD:                'dashboard',
  FOLLOWS:                  'follows',
  INSTRUCTOR_CLASS_PLANNER: 'classPlannerInstructor',
  INSTRUCTOR_DASHBOARD:     'instructorDashboard',
  LOCATION_CLASS_PLANNER:   'classPlannerLocation',
  MEMBERSHIPS:              'memberships',
  PROFILE:                  'profile',
  SETTINGS:                 'settings',
  STORY_MAKER:              'storyMaker',
  TRANSACTIONS:             'transactions'
};
