import { GroupClassesActionTypes } from '../constants/actionTypes';

const initialState = {
  data:           [],
  instructorData: [],
  lastAction:     '',
  locationData:   []
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case GroupClassesActionTypes.CREATE:
      return newState;

    case GroupClassesActionTypes.CREATE_SUCCESS:
      return { ...newState, data: [...state.data, action.payload] };

    case GroupClassesActionTypes.CREATE_FAILURE:
      return newState;


    case GroupClassesActionTypes.DELETE:
      return newState;

    case GroupClassesActionTypes.DELETE_SUCCESS:
      return { ...newState, data: action.payload };

    case GroupClassesActionTypes.DELETE_FAILURE:
      return newState;


    case GroupClassesActionTypes.FETCH:
      return newState;

    case GroupClassesActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case GroupClassesActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case GroupClassesActionTypes.INSTRUCTORS_FETCH:
      return newState;

    case GroupClassesActionTypes.INSTRUCTORS_FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case GroupClassesActionTypes.INSTRUCTORS_FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case GroupClassesActionTypes.INSTRUCTORS_GET:
      return newState;

    case GroupClassesActionTypes.INSTRUCTORS_GET_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case GroupClassesActionTypes.INSTRUCTORS_GET_FAILURE:
      return { ...newState, instructorData: initialState.instructorData };


    case GroupClassesActionTypes.LOCATIONS_FETCH:
      return newState;

    case GroupClassesActionTypes.LOCATIONS_FETCH_SUCCESS:
      return { ...newState, locationData: action.payload };

    case GroupClassesActionTypes.LOCATIONS_FETCH_FAILURE:
      return { ...newState, locationData: initialState.locationData };


    case GroupClassesActionTypes.LOCATIONS_GET:
      return newState;

    case GroupClassesActionTypes.LOCATIONS_GET_SUCCESS:
      return { ...newState, locationData: action.payload };

    case GroupClassesActionTypes.LOCATIONS_GET_FAILURE:
      return { ...newState, locationData: initialState.locationData };


    case GroupClassesActionTypes.RECURRING_CREATE:
      return newState;

    case GroupClassesActionTypes.RECURRING_CREATE_SUCCESS:
      return { ...newState, data: [...state.data, ...action.payload] };

    case GroupClassesActionTypes.RECURRING_CREATE_FAILURE:
      return newState;


    case GroupClassesActionTypes.RECURRING_DELETE:
      return newState;

    case GroupClassesActionTypes.RECURRING_DELETE_SUCCESS:
      return { ...newState, data: action.payload };

    case GroupClassesActionTypes.RECURRING_DELETE_FAILURE:
      return newState;


    case GroupClassesActionTypes.UPDATE:
      return newState;

    case GroupClassesActionTypes.UPDATE_SUCCESS:
      return { ...newState, data: action.payload };

    case GroupClassesActionTypes.UPDATE_FAILURE:
      return newState;

    default: return state;
  }
};
