import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError, toCamelCase } from '../lib';
import { SynchronizationActionTypes } from '../constants/actionTypes';
import { ErrorActions, GroupClassesActions, SynchronizationActions } from '../actions';

function* watchFetch() {
  yield takeLatest(SynchronizationActionTypes.FETCH, function* () {
    try {
      const response = yield call(api.synchronization.fetch);

      yield put(SynchronizationActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(SynchronizationActions.FETCH_FAILURE());

      /* error */
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreate() {
  yield takeLatest(SynchronizationActionTypes.CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.synchronization.create, payload);

      yield put(SynchronizationActions.CREATE_SUCCESS(toCamelCase(response.data)));
      yield put(GroupClassesActions.FETCH());
    } catch (error) {
      yield put(SynchronizationActions.CREATE_FAILURE());

      /* error */
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* synchronizationSaga() {
  yield all([
    watchFetch(),
    watchCreate()
  ]);
}
