import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { StrategyActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions, StrategyActions } from '../actions';

function* watchGet() {
  yield takeLatest(StrategyActionTypes.GET, function* () {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('strategy'));
      const response = yield call(api.instructor.strategy.get);

      yield put(StrategyActions.GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(StrategyActions.GET_FAILURE());
    }
  });
}

function* watchGetSuccess() {
  yield takeLatest(StrategyActionTypes.GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('strategy'));
  });
}

function* watchGetFailure() {
  yield takeLatest(StrategyActionTypes.GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('strategy'));
  });
}


function* watchUpdate() {
  yield takeLatest(StrategyActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('strategy'));
      const response = yield call(api.instructor.strategy.update, payload);

      yield put(StrategyActions.UPDATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Cancellation policy updated');
    } catch (error) {
      yield put(StrategyActions.UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(StrategyActionTypes.UPDATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('strategy'));
  });
}


function* watchUpdateFailure() {
  yield takeLatest(StrategyActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('strategy'));
  });
}

export default function* strategySaga() {
  yield all([
    watchGet(),
    watchGetSuccess(),
    watchGetFailure(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure()
  ]);
}
