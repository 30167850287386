import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { MembershipsDiscountActionTypes } from '../constants/actionTypes';
import { ErrorActions, MembershipsDiscountActions } from '../actions';

function* watchCreate() {
  yield takeLatest(MembershipsDiscountActionTypes.CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.membership.discount.create, payload);

      yield put(MembershipsDiscountActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Coupon applied');
    } catch (error) {
      yield put(MembershipsDiscountActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* membershipsDiscountSaga() {
  yield all([
    watchCreate()
  ]);
}
