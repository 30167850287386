import { DonationBookingsActionTypes } from '../constants/actionTypes';

export default {
  INSTRUCTORS_FETCH: (payload) => ({
    payload, type: DonationBookingsActionTypes.INSTRUCTORS_FETCH
  }),

  INSTRUCTORS_FETCH_FAILURE: () => ({
    type: DonationBookingsActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_FETCH_SUCCESS: (payload) => ({
    payload, type: DonationBookingsActionTypes.INSTRUCTORS_FETCH_SUCCESS
  })
};
