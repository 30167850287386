import React from 'react';
import PropTypes from 'prop-types';

import { ProfileBadge } from '../badges';

class Activities extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired
  }

  render () {
    const { instructor } = this.props;

    return (
      <div className='instructor-activities'>
        { instructor.activities.map((activity) => (
          <ProfileBadge
              key={activity.id}
              title={activity.title}
              type='activity' />
            ))
          }
      </div>
    );
  }
}

export default Activities;
