import { connect } from 'react-redux';

import { CouponsActions } from '../../actions';
import { CouponModal } from '../../components/modals/instructor';

const mapStateToProps = (state) => ({
  coupon:    state.coupons.form,
  isLoading: state.loader.coupons.isLoading,
  user:      state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createCoupon: (data) => dispatch(CouponsActions.CREATE(data)),
  deleteCoupon: (data) => dispatch(CouponsActions.DELETE(data)),
  updateCoupon: (data) => dispatch(CouponsActions.UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CouponModal);
