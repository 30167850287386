export default {
  CHALLENGES_FETCH:         '@@transactions/CHALLENGES/FETCH',
  CHALLENGES_FETCH_FAILURE: '@@transactions/CHALLENGES/FETCH_FAILURE',
  CHALLENGES_FETCH_SUCCESS: '@@transactions/CHALLENGES/FETCH_SUCCESS',

  CHALLENGE_UPDATE:         '@@transactions/CHALLENGES/UPDATE',
  CHALLENGE_UPDATE_FAILURE: '@@transactions/CHALLENGES/UPDATE_FAILURE',
  CHALLENGE_UPDATE_SUCCESS: '@@transactions/CHALLENGES/UPDATE_SUCCESS',

  CLASS_PACKS_FETCH:         '@@transactions/CLASS_PACKS/FETCH',
  CLASS_PACKS_FETCH_FAILURE: '@@transactions/CLASS_PACKS/FETCH_FAILURE',
  CLASS_PACKS_FETCH_SUCCESS: '@@transactions/CLASS_PACKS/FETCH_SUCCESS',

  CLASS_PACK_UPDATE:         '@@transactions/CLASS_PACKS/UPDATE',
  CLASS_PACK_UPDATE_FAILURE: '@@transactions/CLASS_PACKS/UPDATE_FAILURE',
  CLASS_PACK_UPDATE_SUCCESS: '@@transactions/CLASS_PACKS/UPDATE_SUCCESS',

  CLIENT_PURCHASES_FETCH:         '@@transactions/CLIENT/PURCHASES/FETCH',
  CLIENT_PURCHASES_FETCH_FAILURE: '@@transactions/CLIENT/PURCHASES/FETCH_FAILURE',
  CLIENT_PURCHASES_FETCH_SUCCESS: '@@transactions/CLIENT/PURCHASES/FETCH_SUCCESS',

  MEMBERSHIPS_FETCH:         '@@transactions/MEMBERSHIPS/FETCH',
  MEMBERSHIPS_FETCH_FAILURE: '@@transactions/MEMBERSHIPS/FETCH_FAILURE',
  MEMBERSHIPS_FETCH_SUCCESS: '@@transactions/MEMBERSHIPS/FETCH_SUCCESS',

  MEMBERSHIP_UPDATE:         '@@transactions/MEMBERSHIPS/UPDATE',
  MEMBERSHIP_UPDATE_FAILURE: '@@transactions/MEMBERSHIPS/UPDATE_FAILURE',
  MEMBERSHIP_UPDATE_SUCCESS: '@@transactions/MEMBERSHIPS/UPDATE_SUCCESS',

  PURCHASES_FETCH:         '@@transactions/PURCHASES/FETCH',
  PURCHASES_FETCH_FAILURE: '@@transactions/PURCHASES/FETCH_FAILURE',
  PURCHASES_FETCH_SUCCESS: '@@transactions/PURCHASES/FETCH_SUCCESS',

  PURCHASE_UPDATE:         '@@transactions/PURCHASES/UPDATE',
  PURCHASE_UPDATE_FAILURE: '@@transactions/PURCHASES/UPDATE_FAILURE',
  PURCHASE_UPDATE_SUCCESS: '@@transactions/PURCHASES/UPDATE_SUCCESS',

  SUMMARY_FETCH:         '@@transactions/SUMMARY/FETCH',
  SUMMARY_FETCH_FAILURE: '@@transactions/SUMMARY/FETCH_FAILURE',
  SUMMARY_FETCH_SUCCESS: '@@transactions/SUMMARY/FETCH_SUCCESS'
};
