import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback, FormGroup, Label } from 'reactstrap';
import { CardElement } from '@stripe/react-stripe-js';

import { StripeBadge } from '../badges';
import { Form } from './';

class ChargeForm extends React.PureComponent {
  static propTypes = {
    children:  PropTypes.node,
    className: PropTypes.string,
    error:     PropTypes.string
  }

  static defaultProps = {
    children:  null,
    className: '',
    error:     ''
  }

  render () {
    const options = {
      classes: {
        base:     'form-control form-control-md form-stripe',
        complete: 'is-valid',
        invalid:  'is-invalid'
      },
      hidePostalCode: false,
      iconStyle:      'solid',
      style:          {
        base: {
          '::placeholder': {
            fontFamily: '"Poppins", "Century Gothic", Arial, sans-serif',
            lineHeight: '1.6'
          },
          fontFamily: '"Poppins", "Century Gothic", Arial, sans-serif',
          fontSize:   '14px',
          lineHeight: '1.6'
        }
      }
    };

    return (
      <Form className={'form-default form-charge ' + this.props.className}>
        <FormGroup>
          <Label htmlFor='card'>Enter your card info</Label>
          <CardElement options={options} />
        </FormGroup>
        <StripeBadge />

        <div className='float-left'>
          {this.props.children}
          <FormFeedback className='d-block'>{this.props.error}</FormFeedback>
        </div>
      </Form>
    );
  }
}

export default ChargeForm;
