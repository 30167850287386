import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { routes } from '../lib';
import { Loader } from '../components/loader';

export default function (ComposedComponent) {
  class Authenticate extends React.Component {
    static propTypes = {
      redirect: PropTypes.func.isRequired,
      session:  PropTypes.object.isRequired
    };

    componentDidMount() {
      this.checkAndRedirect();
    }

    shouldComponentUpdate(nextProps) {
      return nextProps.session.authenticated !== this.props.session.authenticated ||
             nextProps.session.restored      !== this.props.session.restored;
    }

    componentDidUpdate() {
      this.checkAndRedirect();
    }

    checkAndRedirect = () => {
      if (!this.props.session.authenticated && this.props.session.restored) this.props.redirect();
    }

    render() {
      if (this.props.session.authenticated && this.props.session.restored) {
        return <ComposedComponent {...this.props} />;
      } else {
        return <Loader isLoading />;
      }
    }
  }

  const mapStateToProps = (state) => ({
    session: state.session
  });

  const mapDispatchToProps = (dispatch) => ({
    redirect: () => dispatch(push(routes.LOGIN))
  });

  return connect(mapStateToProps, mapDispatchToProps)(Authenticate);
}
