import { all,  put, select, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { isEmpty } from 'lodash';
import { push } from 'connected-react-router';

import { JoinActionTypes } from '../constants/actionTypes';
import { ValidationActions } from '../actions';
import { routes } from '../lib';

function* watchStartPage() {
  yield takeLatest(JoinActionTypes.START_PAGE, function* ({ payload }) {
    const isAuthenticated = yield select((state) => state.session.authenticated);
    const isInstructor = yield select((state) => !isEmpty(state.profile.data));

    if (isAuthenticated) {
      if (isInstructor) {
        toastr.warning('You already have instructor profile');
      } else {
        yield put(ValidationActions.RESET({ subject: 'instructor' }));
        yield put(ValidationActions.VALIDATE({ instructor: { username: payload } }, { subject: 'instructor' }));
        yield put(window.location.assign(routes.TYPE_FORM));
      }
    } else {
      yield put(push(routes.SIGNUP));
    }
  });
}

export default function* joinSaga() {
  yield all([
    watchStartPage()
  ]);
}
