import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';

import { Button } from '../forms';
import { BackButton } from '../buttons';
import { routes } from '../../lib';
import { ChallengeModalContainer, ClassPackModalContainer,  MembershipModalContainer } from '../../containers/portal';
import { Heading, Panel } from '../layout/pages';
import { tutorialLinks } from '../../constants';
import { Challenge, ClassPack, Membership } from './packages';

class PackagesPage extends React.PureComponent {
  static propTypes = {
    challenges:        PropTypes.array.isRequired,
    classPacks:        PropTypes.array.isRequired,
    getChallenge:      PropTypes.func.isRequired,
    getClassPack:      PropTypes.func.isRequired,
    getMembership:     PropTypes.func.isRequired,
    memberships:       PropTypes.array.isRequired,
    sampleChallenges:  PropTypes.func.isRequired,
    sampleClassPacks:  PropTypes.array.isRequired,
    sampleMemberships: PropTypes.array.isRequired,
    user:              PropTypes.object.isRequired
  }

  state = {
    isChallengeModalOpen:  false,
    isClassPackModalOpen:  false,
    isMembershipModalOpen: false,
    isNewRecord:           true
  }

  handleToggle = (modalName, isNewRecord, modalState) => () => (
    this.setState({
      isNewRecord,
      [modalName]: modalState
    })
  )

  handleEdit = (id) => () => {
    this.props.getMembership({ id });
    const toggle = this.handleToggle('isMembershipModalOpen', false, true);
    toggle();
  }

  handleEditClassPack = (id) => () => {
    this.props.getClassPack({ id });
    const toggle = this.handleToggle('isClassPackModalOpen', false, true);
    toggle();
  }

  handleEditChallenge = (id) => () => {
    this.props.getChallenge({ id });
    const toggle = this.handleToggle('isChallengeModalOpen', false, true);
    toggle();
  }

  renderEmpty = (packageType) => (
    <div className='message-empty'>
      <p>Create your first {packageType} to get started.</p>
    </div>
  );

  render() {
    const {
      challenges, sampleChallenges,
      classPacks, sampleClassPacks,
      memberships, sampleMemberships
    } = this.props;

    const combinedChallenges = [ ...challenges, ...sampleChallenges];
    const combinedClassPacks = [ ...classPacks, ...sampleClassPacks];
    const combinedMemberships = [...memberships, ...sampleMemberships];

    return (
      <div className='inner-wrapper'>
        {isMobile && <BackButton to={routes.SETTINGS_PACKAGES} />}

        <Helmet>
          <title>Packages</title>
        </Helmet>

        <div className='inner-wrapper portal packages'>
          <Container fluid>
            <Heading
                color='secondary'
                hasTutorial
                link={tutorialLinks.PACKAGES}
                subtitle='Creator Portal'
                title='Packages' />
            <Panel>

              <div className='title-row'>
                <span className='title'>Memberships</span>

                <Button
                    className='button'
                    color='primary'
                    onClick={this.handleToggle('isMembershipModalOpen', true, true)}
                    size='sm'>
                  <span>Add Membership</span>
                </Button>
              </div>

              {!!combinedMemberships.length && combinedMemberships.map((membership) => (
                <Membership
                    key={membership.id}
                    membership={membership}
                    onEdit={this.handleEdit}
                    user={this.props.user} />
              ))}

              {!memberships.length && this.renderEmpty('Membership')}

              <div className='title-row'>
                <span className='title'>Class Packs</span>

                <Button
                    className='button'
                    color='primary'
                    onClick={this.handleToggle('isClassPackModalOpen', true, true)}
                    size='sm'>
                  <span>Add Class Pack</span>
                </Button>
              </div>

              {!!combinedClassPacks.length && combinedClassPacks.map((classPack) => (
                <ClassPack
                    classPack={classPack}
                    key={classPack.id}
                    onEdit={this.handleEditClassPack}
                    user={this.props.user} />
              ))}

              {!classPacks.length && this.renderEmpty('Class Pack')}

              <div className='title-row'>
                <span className='title'>Challenges</span>

                <Button
                    className='button'
                    color='primary'
                    onClick={this.handleToggle('isChallengeModalOpen', true, true)}
                    size='sm'>
                  <span>Add Challenge</span>
                </Button>
              </div>

              {!!combinedChallenges.length && combinedChallenges.map((challenges) => (
                <Challenge
                    challenge={challenges}
                    key={challenges.id}
                    onEdit={this.handleEditChallenge}
                    user={this.props.user} />
              ))}

              {!combinedChallenges.length && this.renderEmpty('Challenge')}
            </Panel>
          </Container>
        </div>

        <MembershipModalContainer
            isNewRecord={this.state.isNewRecord}
            isOpen={this.state.isMembershipModalOpen}
            onToggle={this.handleToggle('isMembershipModalOpen', false, false)} />

        <ClassPackModalContainer
            isNewRecord={this.state.isNewRecord}
            isOpen={this.state.isClassPackModalOpen}
            onToggle={this.handleToggle('isClassPackModalOpen', false, false)} />

        <ChallengeModalContainer
            isNewRecord={this.state.isNewRecord}
            isOpen={this.state.isChallengeModalOpen}
            onToggle={this.handleToggle('isChallengeModalOpen', false, false)} />
      </div>
    );
  }
}

export default PackagesPage;
