import { LocationActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       {},
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case LocationActionTypes.FOLLOW:
      return newState;

    case LocationActionTypes.FOLLOW_SUCCESS:
      return { ...newState, data: {
        ...state.data,
        followersCount: state.data.followersCount + 1,
        isFollowing:    true
      } };

    case LocationActionTypes.FOLLOW_FAILURE:
      return newState;


    case LocationActionTypes.GET:
      return { ...newState, data: initialState.data };

    case LocationActionTypes.GET_SUCCESS:
      return { ...newState, data: action.payload };

    case LocationActionTypes.GET_FAILURE:
      return { ...newState, data: initialState.data };


    case LocationActionTypes.UNFOLLOW:
      return newState;

    case LocationActionTypes.UNFOLLOW_SUCCESS:
      return { ...newState, data: {
        ...state.data,
        followersCount: state.data.followersCount - 1,
        isFollowing:    false
      } };

    case LocationActionTypes.UNFOLLOW_FAILURE:
      return newState;


    case LocationActionTypes.UPDATE_SUCCESS:
      return { ...newState, data: action.payload };

    default: return state;
  }
};
