import { CardsActionTypes } from '../constants/actionTypes';

export default {
  FETCH: () => ({
    type: CardsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: CardsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: CardsActionTypes.FETCH_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: CardsActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: CardsActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: CardsActionTypes.UPDATE_SUCCESS
  })
};
