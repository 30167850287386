import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

class PlaylistModal extends React.PureComponent {
  static propTypes = {
    isOpen:     PropTypes.bool.isRequired,
    onToggle:   PropTypes.func.isRequired,
    playlistId: PropTypes.string.isRequired
  }

  render() {
    return (
      <Modal
          centered
          className='playlist-modal'
          isOpen={this.props.isOpen}
          size='sm'
          toggle={this.props.onToggle}>

        <ModalHeader toggle={this.props.onToggle}>
          My Playlist
        </ModalHeader>

        <ModalBody className='mt-3'>
          <iframe
              allow='encrypted-media'
              allowTransparency='true'
              frameBorder='0'
              height='380'
              src={'https://open.spotify.com/embed/playlist/' + this.props.playlistId}
              title='MY SPOTIFY'
              width='100%' />
        </ModalBody>
      </Modal>
    );
  }
}

export default PlaylistModal;
