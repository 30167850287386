export default {
  CREATE:         '@@bookings/CREATE',
  CREATE_FAILURE: '@@bookings/CREATE_FAILURE',
  CREATE_SUCCESS: '@@bookings/CREATE_SUCCESS',

  DELETE:         '@@bookings/DELETE',
  DELETE_FAILURE: '@@bookings/DELETE_FAILURE',
  DELETE_SUCCESS: '@@bookings/DELETE_SUCCESS',

  FETCH:         '@@bookings/FETCH',
  FETCH_FAILURE: '@@bookings/FETCH_FAILURE',
  FETCH_SUCCESS: '@@bookings/FETCH_SUCCESS',

  INSTRUCTORS_FETCH:         '@@bookings/INSTRUCTORS/FETCH',
  INSTRUCTORS_FETCH_FAILURE: '@@bookings/INSTRUCTORS/FETCH_FAILURE',
  INSTRUCTORS_FETCH_SUCCESS: '@@bookings/INSTRUCTORS/FETCH_SUCCESS'
};
