import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';

import { Loader } from '../loader';

class VideoPlayerPage extends React.Component {
  static propTypes = {
    loader:               PropTypes.object.isRequired,
    purchasedClass:       PropTypes.object.isRequired,
    updatePurchasedClass: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props) {
    const isLoading = props.loader.purchasedClass.isLoading;

    return { isLoading };
  }

  state = {
    isLoading: true
  };

  playerRef = React.createRef();

  componentDidMount() {
    window.addEventListener('beforeunload', (e) => {
      e.preventDefault();

      this.updatePurchase();
    });
  }

  componentWillUnmount () {
    this.updatePurchase();
  }

  updatePurchase = () => {
    const { purchasedClass } = this.props;
    const videoPosition = this.playerRef.current.getCurrentTime();

    const onDemand = { uuid: purchasedClass.uuid, videoPosition };

    if (videoPosition) {
      return this.props.updatePurchasedClass(onDemand);
    }
  }

  render () {
    const { isLoading } = this.state;
    const { purchasedClass } = this.props;
    const { onDemand, videoPosition } = purchasedClass;

    if (isLoading) return <Loader />;
    if (!purchasedClass.id) return (
      <div className='access-error-container'>
        <div className='access-error'>
          Unfortunately your access to this video has now expired.
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div className='pt-4 pl-4 pr-4 text-center'>
          <span className='name text-uppercase'>{onDemand.instructor.name}</span>:&nbsp;
          <span className='name'>{onDemand.name}</span>
        </div>

        <div className='video-player mb-2 mt-2'>
          <ReactPlayer
              className='react-player'
              config={{
                file: {
                  attributes: {
                    controlsList:  'nodownload',
                    crossOrigin:   'anonymous',
                    onContextMenu: (e) => e.preventDefault()
                  }
                }
              }}
              controls
              height='100%'
              light={onDemand.poster}
              pip
              ref={this.playerRef}
              url={`${onDemand.videoUrl}#t=${videoPosition}`}
              width='100%'
            />
        </div>

        <div className='info pl-4 pr-4'>
          <span className='name d-flex mt-2'>{onDemand.name}</span>
          <span className='description d-flex'>{onDemand.description}</span>
        </div>
      </React.Fragment>
    );
  }
}

export default VideoPlayerPage;
