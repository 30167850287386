import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresAuth } from '../helpers';
import { DashboardPage } from '../components/pages';
import {
  BookingRequestsActions,
  BookingsActions,
  CardsActions,
  ChallengesActions,
  ClassPacksActions,
  FollowsActions,
  PrivateBookingsActions,
  PrivateSessionsActions,
  ProvidersActions,
  PurchasedClassesActions,
  SubscriptionsActions
} from '../actions';

class DashboardContainer extends React.PureComponent {
  static propTypes = {
    fetchBookingRequests:  PropTypes.func.isRequired,
    fetchBookings:         PropTypes.func.isRequired,
    fetchCards:            PropTypes.func.isRequired,
    fetchChallenges:       PropTypes.func.isRequired,
    fetchClassPacks:       PropTypes.func.isRequired,
    fetchFollows:          PropTypes.func.isRequired,
    fetchPrivateBookings:  PropTypes.func.isRequired,
    fetchPrivateSessions:  PropTypes.func.isRequired,
    fetchProviders:        PropTypes.func.isRequired,
    fetchPurchasesClasses: PropTypes.func.isRequired,
    fetchSubscriptions:    PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.fetchBookingRequests();
    this.props.fetchBookings();
    this.props.fetchCards();
    this.props.fetchChallenges();
    this.props.fetchClassPacks();
    this.props.fetchFollows();
    this.props.fetchPrivateBookings();
    this.props.fetchPrivateSessions();
    this.props.fetchProviders();
    this.props.fetchPurchasesClasses();
    this.props.fetchSubscriptions();
  }

  render() {
    return <DashboardPage {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  bookingRequests:  state.bookingRequests.data,
  bookings:         state.bookings.data,
  cards:            state.cards.data,
  challenges:       state.challenges.userData,
  classPacks:       state.classPacks.userData,
  follows:          state.follows.instructors,
  privateBookings:  state.privateBookings.data,
  privateSessions:  state.privateSessions.data,
  providers:        state.providers.data,
  purchasedClasses: state.purchasedClasses.userData,
  subscriptions:    state.subscriptions.data,
  user:             state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  cancelBookingRequest:  (data) => dispatch(BookingRequestsActions.CANCEL(data)),
  deleteBooking:         (data) => dispatch(BookingsActions.DELETE(data)),
  deletePrivateBooking:  (data) => dispatch(PrivateBookingsActions.DELETE(data)),
  deletePrivateSession:  (data) => dispatch(PrivateSessionsActions.DELETE(data)),
  deleteSubscription:    (data) => dispatch(SubscriptionsActions.DELETE(data)),
  fetchBookingRequests:  ()     => dispatch(BookingRequestsActions.FETCH()),
  fetchBookings:         ()     => dispatch(BookingsActions.FETCH()),
  fetchCards:            ()     => dispatch(CardsActions.FETCH()),
  fetchChallenges:       ()     => dispatch(ChallengesActions.USERS_FETCH()),
  fetchClassPacks:       ()     => dispatch(ClassPacksActions.USERS_FETCH()),
  fetchFollows:          ()     => dispatch(FollowsActions.FETCH_INSTRUCTORS()),
  fetchPrivateBookings:  ()     => dispatch(PrivateBookingsActions.FETCH()),
  fetchPrivateSessions:  ()     => dispatch(PrivateSessionsActions.FETCH()),
  fetchProviders:        ()     => dispatch(ProvidersActions.FETCH()),
  fetchPurchasesClasses: ()     => dispatch(PurchasedClassesActions.USER_FETCH()),
  fetchSubscriptions:    ()     => dispatch(SubscriptionsActions.FETCH()),
  updateCard:            (data) => dispatch(CardsActions.UPDATE(data))
});

export default compose(
  requiresAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(DashboardContainer);
