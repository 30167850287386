import React from 'react';
import PropTypes from 'prop-types';
import { Container, Label, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';

import { getDiff, routes } from '../../../lib';
import { Heading, Panel } from '../../layout/pages';
import { BackButton } from '../../buttons';
import { Button, Checkbox } from '../../forms';
import { Input } from '../../inputs';

class HomeTestimonialsPage extends React.PureComponent {
  static propTypes = {
    instructor:    PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    if (props.instructor.homeConfig.id && !state.isMounted) {
      const {
        showTestimonialsSection,
        testimonialsName,
        testimonialsOverride,
        testimonialText
      } = props.instructor.homeConfig;

      return {
        isMounted: true,
        showTestimonialsSection,
        testimonialText,
        testimonialsName,
        testimonialsOverride
      };
    }

    return state;
  }

  state = {
    isMounted:               false,
    showTestimonialsSection: false,
    testimonialText:         '',
    testimonialsName:        '',
    testimonialsOverride:    false

  }

  handleUpdate = () => {
    const {
      testimonialsName,
      testimonialsOverride,
      testimonialText
    } = this.state;

    const dataToUpdate = {
      testimonialText,
      testimonialsName,
      testimonialsOverride
    };

    this.props.updateProfile({
      instructorHomeConfigAttributes: getDiff(dataToUpdate, this.props.instructor.homeConfig)
    });
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      testimonialsOverride: !prevState.testimonialsOverride
    }));
  };


  handleChange = (value, inputName) => {
    this.setState({
        [inputName]: value
    });
  }

  render() {
    const {
      testimonialText,
      testimonialsName,
      testimonialsOverride
    } = this.state;

    const updatedData = {
      testimonialText,
      testimonialsName,
      testimonialsOverride
    };
    const buttonIsDisabled = !getDiff(updatedData, this.props.instructor.homeConfig);

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS_HOME} />}

        <Helmet>
          <title>Home Testimonials</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container
              className='settings-page'
              fluid>
            <Heading
                color='secondary'
                subtitle='Home'
                title='Testimonials' />
            <Panel className='settings-menu'>
              <p className='text-muted mx-3'>
                Note: This section will show the most recent testimonials you have received via your Style page with
                onPodio, unless you manually override it by checking the box below.
              </p>
              <Media className='menu-link d-block'>
                <Media body>
                  <div className='custom-control custom-checkbox mb-3'>
                    <Checkbox
                        className='custom-control-input'
                        id='testimonials-override'
                        isChecked={this.state.testimonialsOverride}
                        isDisabled={!this.state.showTestimonialsSection}
                        name='testimonials-override'
                        onChange={this.handleToggle} />
                    <Label
                        className='custom-control-label'
                        htmlFor='input-testimonials-override'>
                      Manually override with custom testimonials
                    </Label>
                  </div>

                  <Label
                      className='custom-label'
                      htmlFor='testimonial-client-name'>
                    Client Name
                  </Label>
                  <Input
                      id='testimonial-client-name'
                      isDisabled={!this.state.testimonialsOverride}
                      name='testimonialsName'
                      onChange={this.handleChange}
                      value={this.state.testimonialsName} />

                  <Label
                      className='custom-label'
                      htmlFor='testimonial-text'>
                    Testimonial
                  </Label>
                  <Input
                      id='testimonial-text'
                      isDisabled={!this.state.testimonialsOverride}
                      name='testimonialText'
                      onChange={this.handleChange}
                      type='textarea'
                      value={this.state.testimonialText} />
                </Media>
              </Media>

              <div>
                <Button
                    className='mx-3 mt-3'
                    color='success'
                    isDisabled={buttonIsDisabled}
                    onClick={this.handleUpdate}>
                  Save Changes
                </Button>
              </div>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default HomeTestimonialsPage;
