import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { DonationTargetForm } from '../forms/instructor';


class DonationTarget extends React.PureComponent {
  static propTypes = {
    createDonationTarget: PropTypes.func.isRequired,
    donationClasses:      PropTypes.array.isRequired,
    donationTargets:      PropTypes.array.isRequired,
    isOpen:               PropTypes.bool.isRequired,
    onToggle:             PropTypes.func.isRequired,
    updateDonationTarget: PropTypes.func.isRequired,
    user:                 PropTypes.object.isRequired
  }

  render() {
    return (
      <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          <strong>Set a <span className='text-primary'>Donation Target</span></strong>
        </ModalHeader>

        <ModalBody>
          <DonationTargetForm
              createDonationTarget={this.props.createDonationTarget}
              donationClasses={this.props.donationClasses}
              donationTargets={this.props.donationTargets}
              onToggle={this.props.onToggle}
              updateDonationTarget={this.props.updateDonationTarget}
              user={this.props.user} />
        </ModalBody>
      </Modal>
    );
  }
}

export default DonationTarget;
