import React from 'react';
import PropTypes from 'prop-types';

import { Moment, getTimeZone } from '../../../lib';
import { Calendr } from '../../../lib';
import { Event } from '../';

class Day extends React.PureComponent {
  static propTypes = {
    bookings:     PropTypes.array,
    date:         PropTypes.instanceOf(Moment).isRequired,
    events:       PropTypes.array,
    hasBookings:  PropTypes.bool,
    onEventClick: PropTypes.func,
    user:         PropTypes.object.isRequired
  };

  static defaultProps = {
    bookings:     [],
    events:       [],
    hasBookings:  false,
    onEventClick: () => {}
  };

  static getDerivedStateFromProps(props) {
    const localDate = props.date.tz(getTimeZone(props.user.timeZone));

    const range = localDate.range('day');

    return {
      bookings: Calendr.filter(props.bookings, props.user.timeZone, range, true),
      events:   Calendr.filter(props.events, props.user.timeZone, range),
      hours:    Array.from(range.by('hour', { step: 1 }))
    };
  }

  state = {
    bookings: [],
    events:   [],
    hours:    []
  };

  renderEvents = (time) => {
    const range = time.range('hour');
    const events = Calendr.filter(this.state.events, this.props.user.timeZone, range);

    return events.map((event, i) => (
      <Event
          event={event}
          eventsLength={events.length}
          index={i}
          key={event.id}
          onClick={this.props.onEventClick}
          user={this.props.user} />
    ));
  };

  renderBookings = () => {
    return this.state.bookings.map((booking) => (
      <Event
          event={booking.event}
          isBooking
          key={booking.id}
          onClick={this.props.onEventClick}
          user={this.props.user} />
    ));
  };

  render () {
    const { hasBookings } = this.props;

    return (
      <div className='day-col'>
        { !hasBookings && this.state.hours.map((time) => (
          <div
              className='time-cell'
              key={time.format('HH_mm/DD')}>
            {this.renderEvents(time)}
          </div>
        )) }

        { hasBookings &&
          <div className='time-cell'>
            {this.renderBookings()}
          </div>
        }
      </div>
    );
  }
}

export default Day;
