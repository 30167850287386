import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';

import { Input } from '../../inputs';

class MembershipReasonInput extends React.PureComponent {
  static propTypes = {
    index:       PropTypes.number.isRequired,
    onChange:    PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    profile:     PropTypes.object.isRequired
  }

  static defaultProps = {
    placeholder: ''
  }

  static getDerivedStateFromProps(props) {
    return {
      membershipReason: props.profile.membershipReasons[props.index] || { text: '' }
    };
  }

  state = {
    membershipReason: { text: '' }
  }

  handleChange = (value, name) => {
    const { index, profile } = this.props;

    const membershipReason = profile.membershipReasons[index];

    this.props.onChange(
      Object.assign([], profile.membershipReasons, { [index]: { ...membershipReason, text: value } }),
      name
    );
  }

  render() {
    const { membershipReason } = this.state;

    return (
      <FormGroup>
        <Input
            maxLength={100}
            name='membershipReasons'
            onChange={this.handleChange}
            placeholder={this.props.placeholder}
            value={membershipReason.text} />
      </FormGroup>
    );
  }
}

export default MembershipReasonInput;
