///* global analytics */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import momentTZ from 'moment-timezone';

import { api, extractError, routes, toCamelCase } from '../lib';
import { SessionActionTypes } from '../constants/actionTypes';

import {
  ClassPacksActions,
  ErrorActions,
  LoaderActions,
  PrivateBookingsActions,
  ProfileActions,
  SegmentActions,
  SessionActions,
  SubscriptionsActions,
  UserActions
} from '../actions';

function* watchSignIn() {
  yield takeLatest(SessionActionTypes.SIGN_IN, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.session.create, payload);

      const user = yield call(api.session.get);

      yield put(SessionActions.SIGN_IN_SUCCESS());
      yield put(SegmentActions.SIGN_IN(user.data));
    } catch (error) {
      yield put(SessionActions.SIGN_IN_FAILURE());

      /* error */
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchSignUp() {
  yield takeLatest(SessionActionTypes.SIGN_UP, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('signUp'));
      const response = yield call(api.user.create, payload);

      yield put(SessionActions.SIGN_UP_SUCCESS(response.data));
      yield put(SegmentActions.SIGN_UP(payload.user));
    } catch (error) {
      yield put(SessionActions.SIGN_UP_FAILURE());

      /* error */
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchRestore() {
  yield takeLatest(SessionActionTypes.RESTORE_SESSION, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.session.get);

      const user = toCamelCase(response.data);

      if (!response.data.time_zone) {
        const user = { id: response.data.id, timeZone: momentTZ.tz.guess() };
        yield put(UserActions.UPDATE({ user }));
      }

      yield put(UserActions.FETCH_SUCCESS(user));
      yield put(ProfileActions.FETCH());
    } catch (error) {
      yield put(SessionActions.RESTORE_SESSION_FAILURE(error));
      yield put(UserActions.FETCH_FAILURE());
    }
  });
}

function* watchRestoreSuccess() {
  yield takeLatest(SessionActionTypes.RESTORE_SESSION_SUCCESS, function* () {
    // TODO: move this thing
    const user = yield(select((state) => state.user.data));

    yield put(SegmentActions.IDENTIFY(user));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchRestoreFailure() {
  yield takeLatest(SessionActionTypes.RESTORE_SESSION_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchLogout() {
  yield takeLatest(SessionActionTypes.LOGOUT, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.session.destroy);

      yield put(SessionActions.LOGOUT_SUCCESS());
    } catch (error) {
      yield put(SessionActions.LOGOUT_FAILURE());

      /* error */
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchSignInSuccess() {
  yield takeLatest(SessionActionTypes.SIGN_IN_SUCCESS, function* () {
    yield put(SessionActions.RESTORE_SESSION());
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchSignInFailure() {
  yield takeLatest(SessionActionTypes.SIGN_IN_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchSignUpSuccess() {
  yield takeLatest(SessionActionTypes.SIGN_UP_SUCCESS, function* () {
    toastr.success('Your account was successfully created!');

    const session = yield(select((state) => state.session));
    const username = yield(select((state) => state.join.username));
    if (session.isJoining) yield put(window.location.assign(routes.TYPE_FORM));
    if (username) yield put(window.location.assign(routes.TYPE_FORM));

    yield put(SessionActions.RESTORE_SESSION());
  });
}

function* watchSignUpFailure() {
  yield takeLatest(SessionActionTypes.SIGN_UP_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('signUp'));
  });
}

function* watchLogoutSuccess() {
  yield takeLatest(SessionActionTypes.LOGOUT_SUCCESS, function* () {
    yield put(PrivateBookingsActions.CLEAR());
    yield put(SubscriptionsActions.CLEAR());
    yield put(ClassPacksActions.USERS_CLEAR());
    yield put(ProfileActions.CLEAR());
    yield put(LoaderActions.FINISH_LOADING());
    yield put(LoaderActions.FINISH_FIELD_LOADING('signUp'));
  });
}

function* watchLogoutFailure() {
  yield takeLatest(SessionActionTypes.LOGOUT_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchJoinNow() {
  yield takeLatest(SessionActionTypes.JOIN_NOW, function* () {
    yield put(push(routes.SIGNUP));
  });
}

export default function* sessionSaga() {
  yield all([
    watchLogout(),
    watchLogoutSuccess(),
    watchLogoutFailure(),
    watchRestore(),
    watchRestoreSuccess(),
    watchRestoreFailure(),
    watchSignIn(),
    watchSignInSuccess(),
    watchSignInFailure(),
    watchSignUp(),
    watchSignUpSuccess(),
    watchSignUpFailure(),
    watchJoinNow()
  ]);
}
