import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';

import { Button } from '../../../forms';
import { Loader } from '../../../loader';
import { Alert } from '../../../errors';
import { AddCommentModal } from '../../../modals';
import { routes } from '../../../../lib';
import { Comment } from './';


class Comments extends React.PureComponent {
  static propTypes = {
    comments:      PropTypes.object.isRequired,
    createComment: PropTypes.func.isRequired,
    goTo:          PropTypes.func.isRequired,
    instructor:    PropTypes.object.isRequired,
    loader:        PropTypes.object.isRequired,
    session:       PropTypes.object.isRequired,
    styleTags:     PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      comments:  props.comments.data,
      isLoading: props.loader.comments.isLoading,
      owner:     props.instructor.data.firstName
    };
  }

  state = {
    comments:           [],
    isCommentModalOpen: false,
    isLoading:          true,
    owner:              ''
  }

  renderComments = () => {
    if (isEmpty(this.state.comments)) {
      return (
        <Alert
            color='info'
            heading='Leave your comment!'
            icon='comments'>
          {`Be the first to leave a comment on ${this.state.owner}'s wall!`}
        </Alert>
      );
    } else {
      return (
        <React.Fragment>
          {this.state.comments.map((comment) => (
            <Comment
                comment={comment}
                key={comment.id} />
          ))}
          {!!(this.state.comments.length % 3) && <div className='media comment-blank'></div>}
        </React.Fragment>
      );
    }
  }

  handleCommentModal = () => {
    if (this.props.session.authenticated) {
      this.setState((prevState) => ({
        isCommentModalOpen: !prevState.isCommentModalOpen
      }));
    } else {
      this.props.goTo(routes.LOGIN);
    }
  }

  render() {
    const { instructor } = this.props;

    return (
      <React.Fragment>
        <div className='style__comments-header'>
          <h3 className='style__title style__title_no-mrg'>Testimonials</h3>
          <Button
              className='style__comments-btn'
              color='white'
              isDisabled={!!instructor.data.isOwner}
              onClick={this.handleCommentModal}
              size={isMobile ? '' : 'lg'}>
            write a testimonial
          </Button>
        </div>
        <div className='profile-comments'>
          <Loader
              hasOpacity={false}
              isLoading={this.state.isLoading}
              isTransparent
              size='3rem'
              type='border' />
          {this.renderComments()}
        </div>

        <AddCommentModal
            createComment={this.props.createComment}
            instructor={instructor.data}
            isOpen={this.state.isCommentModalOpen}
            isPersisted={false}
            onToggle={this.handleCommentModal}
            styleTags={this.props.styleTags.data} />
      </React.Fragment>
    );
  }
}

export default Comments;
