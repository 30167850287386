/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';

import { Input } from '../inputs';
import { Button } from '../forms';

class ReferModal extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired,
    isOpen:     PropTypes.bool.isRequired,
    onToggle:   PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props) {
    const body = 'Hey, have you checked out onPodio.com?\n' +
                  '\n' +
                  'I started using it to automate my class bookings, payments, link sharing and also so I can offer ' +
                  'on-demand recordings, memberships and class packs to my clients.\n' +
                  '\n' +
                  'Use my referral link when you sign up to get $50 after your 50th booking:\n' +
                  'https://onpodio.com/profile/create?referral=' + props.instructor.username;

    return {
      body,
      referralLink: 'https://onpodio.com/profile/create?referral=' + props.instructor.username
    };
  }

  state = {
    body:         '',
    recipients:   [{ email: '' }],
    referralLink: ''
  }

  handleAdd = () => (
    this.setState((prevState) => ({
      recipients: [...prevState.recipients, { email: '' }]
    }))
  );

  handleChange = (index) => (value, inputName) => (
    this.setState((prevState) => {
      const recipients = Array.from(prevState.recipients);

      recipients.splice(index, 1, { ...recipients[index], [inputName]: value });

      return { recipients };
    })
  )

  handleCopy = () => {
    this.copyArea.focus();
    this.copyArea.select();
    this.copyArea.setSelectionRange(0, 99999);

    document.execCommand('copy');
    toastr.success('Copied to clipboard!');
  }

  handleRemove = (index) => (e) => {
    e.preventDefault();

    this.setState((prevState) => {
      const recipients = Array.from(prevState.recipients);
      recipients.splice(index, 1);

      return { recipients };
    });
  }

  render() {
    const subject = encodeURIComponent('Check out onPodio');
    const body = encodeURIComponent(this.state.body);
    const recipients = map(this.state.recipients, 'email').join(';');

    const mailto = 'mailto:' + recipients + '?subject=' + subject + '&body=' + body;

    const lastIndex = this.state.recipients.length - 1;

    return (
      <Modal
          className='modal-refer'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          Refer & <span className='text-primary'>Earn!</span>
        </ModalHeader>

        <ModalBody>
          <div className='description'>
            <span className='text-primary'>Make $50 and gift $50 cash</span>
            &nbsp;when your instructor friends receive their 50th paid booking with onPodio.
          </div>

          <h5 className='mb-3 mt-3'>
            Share your Link
          </h5>

          <small className='text-muted'>
            Share your unique link with friends to use when they sign-up!
          </small>

          <Input
              onRef={(inputRef) => this.copyArea = inputRef}
              value={this.state.referralLink} />

          <Button
              className='mt-4'
              color='blue'
              isBlock
              onClick={this.handleCopy}
              size='lg'>
            Copy Link
          </Button>

          <hr
              className="my-3 hr-text letter-spacing-2"
              data-content="OR" />

          <h5 className='mb-3 mt-3'>
            Send an Email
          </h5>

          <small className='text-muted'>
            Your message
          </small>

          <Input
              id='body'
              onChange={this.handleChange}
              rows='7'
              type='textarea'
              value={this.state.body} />

          <FormGroup
              className='mt-2 align-items-end'
              row>
            { this.state.recipients.map((recipient, index) => (
              <React.Fragment key={index}>
                <Col
                    lg={11}
                    xs={10}>
                  <Input
                      className='mt-2'
                      name='email'
                      onChange={this.handleChange(index)}
                      placeholder='Email Address'
                      type='text'
                      value={recipient.email} />
                </Col>

                <Col
                    lg={1}
                    xs={2}>
                  { index === lastIndex &&
                    <Button
                        color='white'
                        isOutline
                        onClick={this.handleAdd}
                        size='icon'>
                      <Icon
                          fixedWidth
                          icon={['fas', 'plus']} />
                    </Button>
                  }

                  { index !== lastIndex &&
                    <Button
                        color='danger'
                        isOutline
                        onClick={this.handleRemove(index)}
                        size='icon'>
                      <Icon
                          fixedWidth
                          icon={['fas', 'times']} />
                    </Button>
                  }
                </Col>
              </React.Fragment>
            ))}
          </FormGroup>

          <a href={mailto}>
            <Button
                className='mt-4'
                color='blue'
                isBlock
                size='lg'>
              Send Email
            </Button>
          </a>
        </ModalBody>
      </Modal>
    );
  }
}

export default ReferModal;
