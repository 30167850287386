import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Card, Media } from 'reactstrap';
import { Link } from 'react-router-dom';
import TruncateMarkup from 'react-truncate-markup';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { map } from 'lodash';
import { BrowserView, MobileView, isMobile } from 'react-device-detect';
import BackgroundImageOnLoad from 'background-image-on-load';

import { routes } from '../../lib';
import { InstructorNoAvatar, StudioDemo } from '../../images';
import { LocationBadge } from '../badges';
import { Loader } from '../loader';

class ResultCard extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object,
    location:   PropTypes.object,
    type:       PropTypes.string.isRequired
  }

  static defaultProps = {
    instructor: null,
    location:   null
  }

  state = {
    activitiesCount: isMobile ? 2 : 3,
    hasPhoto:        false,
    isPhotoLoaded:   false,
    locationsCount:  isMobile ? 2 : 3
  }

  renderTooltipData = () => {
    const { instructor, location } = this.props;
    const object = instructor ? instructor : location;

    const activities = object.activities.slice(
        this.state.activitiesCount, object.activities.length
    );
    return map(activities, 'title');
  }

  renderLocationMobile = () => (
    <div className='card-studios-sm'>
      {this.props.instructor.locations.slice(0, this.state.locationsCount).map((location, i) => (
        <span
            key={location.id}>
          {location.name + (i !== this.state.locationsCount - 1 ? ', ' : '')}
        </span>
      ))}
      {this.props.instructor.locations.length > this.state.locationsCount &&
        <span>
          , + {this.props.instructor.locations.length - this.state.locationsCount}
        </span>
      }
    </div>
  )

  handlePhotoLoad = () => {
    this.setState((prevState) => ({
      ...prevState,
      isPhotoLoaded: true
    }));
  };

  render() {
    const { instructor, location } = this.props;
    const { isPhotoLoaded, activitiesCount } = this.state;
    const href = instructor ? routes.INSTRUCTOR(instructor.username) : routes.LOCATION(location.id);
    const object = instructor ? instructor : location;
    const emptyPhoto = instructor ? InstructorNoAvatar : StudioDemo;

    return (
      <Card
          body
          className={'card-result ' + this.props.type}>
        <Media>
          <Media left>
            <Link
                className='card-photo'
                style={{backgroundImage: `url(${object.photo ? object.photo : emptyPhoto})`}}
                to={href}>
              { !!object.photo &&
                <React.Fragment>
                  <Loader
                      hasOpacity={false}
                      isLoading={!isPhotoLoaded}
                      size='1.5rem'
                      type='border' />
                  <BackgroundImageOnLoad
                      onError={this.handlePhotoLoad}
                      onLoadBg={this.handlePhotoLoad}
                      src={object.photo} />
                </React.Fragment>
              }
            </Link>
          </Media>
          <Media
              body
              className='px-4'>
            <Media
                className='card-name'
                heading>
              <Link to={href}>
                {instructor ? [instructor.firstName, instructor.lastName].join(' ') : location.name}
              </Link>
              { instructor && instructor.styleTags &&
                <div className='card-style-tags'>
                  <span title={instructor.styleTags[0]}>{instructor.styleTags[0].substring(0, 2)}</span>
                </div>
              }
              <div className='card-followers'>
                <Icon
                    className='mr-1'
                    icon={['fas', 'heart']} />
                <span>{object.followersCount}</span>
              </div>
            </Media>
            <div className='card-activities'>
              {object.activities.slice(0, activitiesCount).map((activity) => (
                <Badge
                    className='badge-activity badge-sm mr-1'
                    color='light'
                    key={activity.id}
                    pill>
                  {activity.title}
                </Badge>
              ))}
              { object.activities.length > activitiesCount &&
                <Badge
                    className='badge-activity badge-sm'
                    color='light'
                    pill>
                  <span
                      data-toggle='tooltip'
                      title={this.renderTooltipData()}>
                    + {object.activities.length - activitiesCount} {!isMobile && 'more'}
                  </span>
                </Badge>
              }
            </div>
            <BrowserView>
              <TruncateMarkup lines={3}>
                <div className='card-bio'>
                  {object.bio}
                </div>
              </TruncateMarkup>
            </BrowserView>
            <MobileView>
              { instructor && instructor.locations && this.renderLocationMobile() }
            </MobileView>
          </Media>
          <BrowserView>
            { instructor && instructor.locations &&
              <Media
                  className='card-studios'
                  right>
                { instructor.locations.length <= 3 && instructor.locations.map((location) => (
                  <LocationBadge
                      hasLink={!location.createdByUser}
                      key={location.id}
                      location={location} />
                ))}
                { instructor.locations.length >= 4 &&
                <React.Fragment>
                  { instructor.locations.slice(0, 2).map((location) => (
                    <LocationBadge
                        hasLink={!location.createdByUser}
                        key={location.id}
                        location={location} />
                  ))}
                  <Badge
                      className='badge-studio'
                      color='light'
                      pill>
                    <Link to={routes.INSTRUCTOR(instructor.username)}>
                      + {instructor.locations.length - 2} other studios
                    </Link>
                  </Badge>
                </React.Fragment>
                }
              </Media>
            }
          </BrowserView>
        </Media>
      </Card>
    );
  }
}

export default ResultCard;
