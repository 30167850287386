import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';

import { InstructorName } from '../../../../components/instructor';
import Header from '../components/headerUrban';

class AboutUrban extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired
  }

  render() {
    const { instructor } = this.props;

    return (
      <React.Fragment>
        <Header
            instructor={instructor}
            subtitle='about' />
        <div className='about template-urban'>
          { isMobile &&
            <InstructorName
                instructor={instructor}
                subtitle='about' />
          }
          <div className='about__bio-wrapper'>
            <h3 className='page-title'>Biography</h3>
            <div className='about__bio'>
              <span>{instructor.bio}</span>
            </div>
          </div>

          <div className='about__body'>
            { !isEmpty(instructor.experiences) &&
              <div className='about-body__wrapper'>
                <span className='about-body__section'>work experience</span>
                <div className='about-body__block'>
                  { instructor.experiences.map((experience) => (
                    <span
                        className='about-body__text'
                        key={experience.id}>
                      {experience.from} - {experience.isCurrent ? 'Current' : experience.to}, {experience.company}
                    </span>
                  ))}
                </div>
              </div>
            }
            { !isEmpty(instructor.educations) &&
              <div className='about-body__wrapper'>
                <span className='about-body__section'>education</span>
                <div className='about-body__block'>
                  { instructor.educations.map((education) => (
                    <span
                        className='about-body__text'
                        key={education.id}>
                      {education.name}
                    </span>
                  ))}
                </div>
              </div>
            }
            { !isEmpty(instructor.certifications) &&
              <div className='about-body__wrapper'>
                <span className='about-body__section'>accreditation</span>
                <div className='about-body__block about-body__block_row'>
                  { instructor.certifications.map((accreditation, index) => (
                    <span
                        className='about-body__text'
                        key={accreditation.id}>
                      {accreditation.title}{index < instructor.certifications.length-1 ? ',' : null}
                    </span>
                  ))}
                </div>
              </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AboutUrban;
