import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { findIndex, head, includes, map } from 'lodash';

import { Checkbox } from '../../forms';

class Locations extends React.PureComponent {
  static propTypes = {
    children:  PropTypes.node.isRequired,
    hasOne:    PropTypes.bool,
    locations: PropTypes.array.isRequired,
    onChange:  PropTypes.func.isRequired
  }

  static defaultProps = {
    hasOne: false
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.mounted) {
      if (props.locations.length) {
        return {
          mounted:  true,
          selected: props.hasOne ? [head(props.locations)] : props.locations
        };
      }
    } else {
      return state;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.locations.length != prevProps.locations.length) {
      this.setState({ mounted: false });
    }
  }

  state = {
    mounted:  false,
    selected: []
  }

  handleFilter = () => {
    this.props.onChange(this.state.selected, 'locationFilter');
  }

  handleToggle = (location, isChecked = true) => () => {
    if (isChecked) {
      this.setState((prevState) => {
        const { selected } = prevState;
        const index = findIndex(selected, ['id', location.id]);

        return {
          selected: [...selected.slice(0, index), ...selected.slice(index + 1)]
        };
      }, this.handleFilter);
    } else {
      this.setState((prevState) => ({
        selected: [...prevState.selected, location]
      }), this.handleFilter);
    }
  }

  renderOptions = () => (
    this.props.locations.map((location) => {
      const isChecked = includes(map(this.state.selected, 'id'), location.id);

      return (
        <li key={location.id}>
          <Checkbox
              isChecked={isChecked}
              onChange={this.handleToggle(location, isChecked)} />

          {location.name}
        </li>
      );
    })
  )

  render() {
    return (
      <React.Fragment>
        {this.props.children}

        <Container className='mb-4 p-0'>
          <ul>
            {this.renderOptions()}
          </ul>
        </Container>
      </React.Fragment>
    );
  }
}

export default Locations;