import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';

import { api, extractError, routes } from '../lib';
import { PasswordActionTypes } from '../constants/actionTypes';

import { ErrorActions, LoaderActions, PasswordActions } from '../actions';

function* watchRecover() {
  yield takeLatest(PasswordActionTypes.RECOVER, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.password.get, payload);

      yield put(PasswordActions.RECOVER_SUCCESS());
      toastr.success('Email with instructions has been sent');
    } catch (error) {
      yield put(PasswordActions.RECOVER_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchRecoverSuccess() {
  yield takeLatest(PasswordActionTypes.RECOVER_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchRecoverFailure() {
  yield takeLatest(PasswordActionTypes.RECOVER_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchReset() {
  yield takeLatest(PasswordActionTypes.RESET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.password.update, payload);

      yield put(PasswordActions.RESET_SUCCESS());
      toastr.success('Your password has been reset!');
    } catch (error) {
      yield put(PasswordActions.RESET_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchResetSuccess() {
  yield takeLatest(PasswordActionTypes.RESET_SUCCESS, function* () {
    yield put(push(routes.LOGIN));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchResetFailure() {
  yield takeLatest(PasswordActionTypes.RESET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* passwordSaga() {
  yield all([
    watchRecover(),
    watchRecoverSuccess(),
    watchRecoverFailure(),
    watchReset(),
    watchResetSuccess(),
    watchResetFailure()
  ]);
}
