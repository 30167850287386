import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from '../forms';

class BackButton extends React.PureComponent {
  static propTypes = {
    to: PropTypes.string.isRequired
  };

  render () {
    return (
      <Link
          className='back-btn'
          to={this.props.to}>
        <Icon
            fixedWidth
            icon='chevron-left' />
        <Button
            className='btn'
            size='sm'>
          Back
        </Button>
      </Link>
    );
  }
}

export default BackButton;
