import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap';

import { Calendr, Moment } from '../../../lib';
import { DateSelect } from '../../calendar';
import { ClassBox, TimeZone } from '../../pages/profile';

class GroupClassesScheduleModal extends React.PureComponent {
  static propTypes = {
    challenges:             PropTypes.object.isRequired,
    classPacks:             PropTypes.array.isRequired,
    classes:                PropTypes.array.isRequired,
    createBooking:          PropTypes.func.isRequired,
    createDiscount:         PropTypes.func.isRequired,
    createPrivateBooking:   PropTypes.func.isRequired,
    discount:               PropTypes.object.isRequired,
    fetchClassPacks:        PropTypes.func.isRequired,
    fetchMemberships:       PropTypes.func.isRequired,
    getPrivateGroupClasses: PropTypes.func.isRequired,
    instructor:             PropTypes.object.isRequired,
    instructorPacks:        PropTypes.array.isRequired,
    isOpen:                 PropTypes.bool.isRequired,
    loader:                 PropTypes.object.isRequired,
    memberships:            PropTypes.array.isRequired,
    onToggle:               PropTypes.func.isRequired,
    privateBookings:        PropTypes.array.isRequired,
    restoreDiscount:        PropTypes.func.isRequired,
    subscriptions:          PropTypes.array.isRequired,
    user:                   PropTypes.object.isRequired,
    username:               PropTypes.string.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const range = Calendr.getRange(state.selectedDate, props.user.timeZone, 'week');
    const events = Calendr.eventsInRange(props.classes, props.user.timeZone, range);
    const isLoading = props.loader.groupClasses.isLoading || props.loader.privateGroupClasses.isLoading;

    return {
      classes: Calendr.eventsGroupBy(events, props.user.timeZone, 'YYYY-MM-DD'),
      isLoading
    };
  }

  componentDidMount() {
    const { username } = this.props;

    this.props.getPrivateGroupClasses({ username });
  }

  state = {
    classes:      [],
    isLoading:    false,
    selectedDate: new Moment()
  }

  handleChange = (value) => {
    this.setState({ selectedDate: value });
  }

  renderSpinner = () => (
    <div className='d-flex justify-content-center my-3'>
      <Spinner color='muted' />
    </div>
  )

  render () {
    const { isOpen, onToggle, createBooking, createPrivateBooking, user } = this.props;
    const { selectedDate, classes, isLoading } = this.state;

    const days = Object.keys(this.state.classes);

    return (
      <Modal
          className='modal-class-schedule'
          isOpen={isOpen}
          toggle={onToggle}>
        <ModalHeader toggle={onToggle}>
          Group Classes Schedule
        </ModalHeader>
        <ModalBody>
          <DateSelect
              hasDaySelect={false}
              hasPast={false}
              onChange={this.handleChange}
              selectedDate={selectedDate}
              selectedView='week' />
          { !isEmpty(classes) &&
            <TimeZone
                className='mr-2'
                timeZone={user.timeZone} />
          }

          <div className='classes-list'>
            {isLoading && this.renderSpinner()}

            { isEmpty(classes) && !isLoading &&
              <div className='group-classes__empty'>
                <span className='group-classes__emptyspan'>No upcoming classes</span>
              </div>
            }

            { !isLoading && days.map((day) => (
              <React.Fragment key={day}>
                <div className='date'>
                  {new Moment(day, 'YYYY-MM-DDhh:mm:ss').format('DD MMM | dddd')}
                </div>
                { classes[day].map((event) => (
                  <ClassBox
                      challenges={this.props.challenges}
                      classPacks={this.props.classPacks}
                      createBooking={createBooking}
                      createDiscount={this.props.createDiscount}
                      createPrivateBooking={createPrivateBooking}
                      discount={this.props.discount}
                      fetchClassPacks={this.props.fetchClassPacks}
                      fetchMemberships={this.props.fetchMemberships}
                      groupClass={event}
                      hasDate={false}
                      instructor={this.props.instructor}
                      instructorPacks={this.props.instructorPacks}
                      key={event.type + event.id}
                      loader={this.props.loader}
                      memberships={this.props.memberships}
                      privateBookings={this.props.privateBookings}
                      restoreDiscount={this.props.restoreDiscount}
                      subscriptions={this.props.subscriptions}
                      user={this.props.user} />
                ))}
              </React.Fragment>
            )) }
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default GroupClassesScheduleModal;
