import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';

import { Button } from '../forms';
import { BackButton } from '../buttons';
import { routes } from '../../lib';
import { SessionTypeModalContainer } from '../../containers/portal';
import { Heading, Panel } from '../layout/pages';
import { SessionType } from './sessionTypes';

class SessionTypesPage extends React.PureComponent {
  static propTypes = {
    getSessionType: PropTypes.func.isRequired,
    sessionTypes:   PropTypes.array.isRequired,
    user:           PropTypes.object.isRequired
  };

  state = {
    isModalOpen: false,
    isNewRecord: true
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen
    }));
  };

  handleNew = () => {
    this.setState({ isNewRecord: true }, this.handleToggle());
  };

  handleEdit = (id) => () => {
    this.props.getSessionType({ id });
    this.setState({ isNewRecord: false }, this.handleToggle());
  };

  renderEmpty = () => (
    <div className='message-empty'>
      <p>Create your first Private Session Type.</p>

      <p>You will need to create the Private Session Types you offer (e.g. 60min Power Hour) in order to receive
        Private Session booking requests</p>
    </div>
  );

  render() {
    const { sessionTypes } = this.props;

    return (
      <div className='inner-wrapper portal session-types'>
        {isMobile && <BackButton to={routes.SETTINGS_WORKOUT} />}

        <Helmet>
          <title>Private Session Types</title>
        </Helmet>

        <Container
            className='session-types-page'
            fluid>
          <Heading
              color='secondary'
              subtitle='Creator Portal'
              title='Private Session Types' />
          <Panel>
            <div className='title-row'>
              <span className='title'>Private Session Types</span>

              <Button
                  className='button'
                  color='primary'
                  onClick={this.handleNew}
                  size='sm'>
                <span>Add New</span>
              </Button>
            </div>

            {!!sessionTypes.length && sessionTypes.map((sessionType) => (
              <SessionType
                  key={sessionType.id}
                  onEdit={this.handleEdit}
                  sessionType={sessionType}
                  user={this.props.user} />
            ))}

            {!sessionTypes.length && this.renderEmpty()}
          </Panel>
        </Container>

        <SessionTypeModalContainer
            isNewRecord={this.state.isNewRecord}
            isOpen={this.state.isModalOpen}
            onToggle={this.handleToggle} />
      </div>
    );
  }
}

export default SessionTypesPage;
