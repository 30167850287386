import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';
import { debounce } from 'lodash';

import { singularize } from '../../lib';
import { selectFieldStyle } from '../../constants';
import { ValueContainer } from './';

class Autocompleter extends React.PureComponent {
  static propTypes = {
    autocompleter:        PropTypes.object,
    children:             PropTypes.node,
    className:            PropTypes.string,
    components:           PropTypes.object,
    dataType:             PropTypes.string,
    disabledOptionType:   PropTypes.string,
    disabledOptionValues: PropTypes.array,
    doAutocomplete:       PropTypes.func,
    hasInitialValue:      PropTypes.bool,
    instructor:           PropTypes.object,
    isBordered:           PropTypes.bool,
    isDisabled:           PropTypes.bool,
    isInvalid:            PropTypes.bool,
    isMulti:              PropTypes.bool,
    isPersisted:          PropTypes.bool,
    location:             PropTypes.object,
    name:                 PropTypes.string,
    onChange:             PropTypes.func,
    onSelect:             PropTypes.func,
    placeholder:          PropTypes.string,
    styles:               PropTypes.object,
    subject:              PropTypes.string.isRequired,
    truncate:             PropTypes.number,
    value:                PropTypes.string,
    wrapperClassName:     PropTypes.string
  }

  static defaultProps = {
    autocompleter:        {},
    children:             null,
    className:            '',
    components:           {},
    dataType:             '',
    disabledOptionType:   null,
    disabledOptionValues: [],
    doAutocomplete:       () => {},
    hasInitialValue:      false,
    instructor:           {},
    isBordered:           true,
    isDisabled:           false,
    isInvalid:            false,
    isMulti:              false,
    isPersisted:          false,
    location:             {},
    name:                 '',
    onChange:             () => {},
    onCreate:             () => {},
    onSelect:             () => {},
    option:               {},
    placeholder:          'Select...',
    styles:               {},
    truncate:             10,
    value:                '',
    wrapperClassName:     'custom-input-group'
  }

  static getDerivedStateFromProps(props, state) {
    const options = props.autocompleter.data[props.dataType || props.subject] || [];
    const subject = singularize(props.subject);

    return {
      options: options.map((option) => {
        const label = option.type ? (option.name + ' (' + option.type + ')') : option.name;
        return { label, tier: option.tier, ...option, value: option.id };
      }),

      params: {
        ...state.params,
        instructorUsername: props.instructor.username,
        locationId:         props.location.id,
        subject
      }
    };
  }

  state = {
    option:  null,
    options: [],

    params: {
      query:   '',
      subject: ''
    }
  }

  componentDidMount() {
    if (this.props.hasInitialValue) {
      this.props.doAutocomplete(this.state.params);
    }
  }

  doAutocomplete = debounce(this.props.doAutocomplete, 300);

  handleSelect = (option) => {
    const query = option ? option.label : '';

    this.setState((prevState) => ({
      option,
      params: {
        ...prevState.params,
        query
      }
    }), () => {
      this.props.onChange(query, this.props.name);
      this.props.onSelect(option, this.props.name);
    });
  }

  handleChange = (query, meta) => {
    if (!this.props.isPersisted || meta.action == 'input-change') {
      this.setState((prevState) => ({
        params: {
          ...prevState.params,
          query
        }
      }), () => {
        this.props.onChange(query, this.props.name, meta);
        this.doAutocomplete(this.state.params);
      });
    }
  }

  handleDisabledOption = (attrName, attrValues) => (option) => {
    return attrValues.includes(option[attrName]);
  };

  render() {
    const { isBordered, disabledOptionType, disabledOptionValues, styles } = this.props;
    const fieldStyles = { ...(isBordered ? selectFieldStyle.default : selectFieldStyle.borderless), ...styles };

    return (
      <div className={classNames(this.props.wrapperClassName, { 'is-invalid': this.props.isInvalid })}>
        <Select
            blurInputOnSelect
            className={'autocompleter ' + this.props.className}
            components={{ ...this.props.components, ValueContainer }}
            defaultInputValue={this.props.hasInitialValue ? this.props.value : ''}
            inputValue={this.props.value.label}
            isDisabled={this.props.isDisabled}
            isMulti={this.props.isMulti}
            isOptionDisabled={this.handleDisabledOption(disabledOptionType, disabledOptionValues)}
            name={this.props.name}
            noOptionsMessage={() => 'Start typing...'}
            onChange={this.handleSelect}
            onInputChange={this.handleChange}
            options={this.state.options}
            placeholder={this.props.placeholder}
            styles={fieldStyles}
            truncate={this.props.truncate}
            value={this.state.option ? this.state.option : this.props.value} />
        {this.props.children}
      </div>
    );
  }
}

export default Autocompleter;
