import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { Browser, Button, Form, Select } from '../../forms';
import { Input } from '../../inputs';
import { UpdatePasswordModal } from '../../modals';

import { Moment } from '../../../lib';
import { days, timeZones } from '../../../constants/';

const MONTHS = new Moment.months().map((month) => ({ label: month, value: month }));

class EditAccountForm extends React.PureComponent {
  static propTypes = {
    onChange:         PropTypes.func.isRequired,
    onPasswordChange: PropTypes.func.isRequired,
    onPasswordUpdate: PropTypes.func.isRequired,
    onPhotoChange:    PropTypes.func.isRequired,
    onSubmit:         PropTypes.func.isRequired,
    updatePassword:   PropTypes.object.isRequired,
    user:             PropTypes.object.isRequired
  }

  state = {
    isPasswordModalOpen: false,
    photo:               {}
  }

  handlePasswordModal = () => {
    this.setState((prevState) => ({
      isPasswordModalOpen: !prevState.isPasswordModalOpen
    }));
  };

  handlePhotoUpload = (blob) => {
    this.setState((prevState) => ({
      ...prevState,
      photo: blob
    }), () => this.props.onPhotoChange(blob.signed_id));
  };

  handleChange = (value, inputName) => {
    this.props.onChange(value, inputName);
  }

  render() {
    const { user } = this.props;

    return (
      <Form className='form-default'>
        <Row form>
          <Col
              md={6}
              sm={12}>
            <FormGroup>
              <Label for='firstName'>First Name</Label>
              <Input
                  id='firstName'
                  name='firstName'
                  onChange={this.props.onChange}
                  type='text'
                  value={user.firstName} />
            </FormGroup>
          </Col>
          <Col
              md={6}
              sm={12}>
            <FormGroup>
              <Label for='lastName'>Last Name</Label>
              <Input
                  id='lastName'
                  name='lastName'
                  onChange={this.props.onChange}
                  type='text'
                  value={user.lastName} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col
              md={6}
              sm={12}>
            <FormGroup>
              <Label for='email'>E-mail</Label>
              <Input
                  id='email'
                  name='email'
                  onChange={this.props.onChange}
                  type='email'
                  value={user.email} />
            </FormGroup>
          </Col>
        </Row>
        <Label for='day'>Birthday</Label>
        <Row>
          <Col md='3'>
            <FormGroup>
              <Select
                  name='day'
                  onChange={this.handleChange}
                  options={days}
                  placeholder='Day'
                  value={user.day} />
            </FormGroup>
          </Col>

          <Col md='9'>
            <Select
                name='month'
                onChange={this.handleChange}
                options={MONTHS}
                placeholder='Month'
                value={user.month} />
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <Label for='email'>Photo</Label>
              <Browser
                  fileTypes={['image/png', 'image/jpeg']}
                  id='photo'
                  maxFileSize='5MB'
                  name='photo'
                  onChange={this.props.onChange}
                  size='lg' />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <Label for='timeZone'>Time Zone</Label>
              <Select
                  id='timeZone'
                  isSearchable
                  name='timeZone'
                  onChange={this.handleChange}
                  options={timeZones}
                  type='select'
                  value={user.timeZone} />
            </FormGroup>
          </Col>
        </Row>

        <Button
            className='mb-3 mt-2'
            onClick={this.handlePasswordModal}
            onKeyPress={this.handlePasswordModal}
            type='button'>
          Update Password
        </Button>

        <div>
          <Button
              className='mr-1'
              color='success'
              onClick={this.props.onSubmit}
              onKeyPress={this.props.onSubmit}
              type='button'>
            Save changes
          </Button>
        </div>

        <UpdatePasswordModal
            isOpen={this.state.isPasswordModalOpen}
            onChange={this.props.onPasswordChange}
            onSubmit={this.props.onPasswordUpdate}
            onToggle={this.handlePasswordModal}
            updatePassword={this.props.updatePassword} />
      </Form>
    );
  }
}

export default EditAccountForm;
