import { ChallengesActionTypes } from '../constants/actionTypes';

export default {
  /* regular actions */
  FETCH: (payload) => ({
    payload, type: ChallengesActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: ChallengesActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: ChallengesActionTypes.FETCH_SUCCESS
  }),


  /* actions made by instructor */
  INSTRUCTORS_CREATE: (payload) => ({
    payload, type: ChallengesActionTypes.INSTRUCTORS_CREATE
  }),

  INSTRUCTORS_CREATE_FAILURE: () => ({
    type: ChallengesActionTypes.INSTRUCTORS_CREATE_FAILURE
  }),

  INSTRUCTORS_CREATE_SUCCESS: (payload) => ({
    payload, type: ChallengesActionTypes.INSTRUCTORS_CREATE_SUCCESS
  }),


  INSTRUCTORS_DELETE: (payload) => ({
    payload, type: ChallengesActionTypes.INSTRUCTORS_DELETE
  }),

  INSTRUCTORS_DELETE_FAILURE: () => ({
    type: ChallengesActionTypes.INSTRUCTORS_DELETE_FAILURE
  }),

  INSTRUCTORS_DELETE_SUCCESS: (payload) => ({
    payload, type: ChallengesActionTypes.INSTRUCTORS_DELETE_SUCCESS
  }),


  INSTRUCTORS_FETCH: () => ({
    type: ChallengesActionTypes.INSTRUCTORS_FETCH
  }),

  INSTRUCTORS_FETCH_FAILURE: () => ({
    type: ChallengesActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_FETCH_SUCCESS: (payload) => ({
    payload, type: ChallengesActionTypes.INSTRUCTORS_FETCH_SUCCESS
  }),


  INSTRUCTORS_GET: (payload) => ({
    payload, type: ChallengesActionTypes.INSTRUCTORS_GET
  }),

  INSTRUCTORS_GET_FAILURE: () => ({
    type: ChallengesActionTypes.INSTRUCTORS_GET_FAILURE
  }),

  INSTRUCTORS_GET_SUCCESS: (payload) => ({
    payload, type: ChallengesActionTypes.INSTRUCTORS_GET_SUCCESS
  }),


  INSTRUCTORS_UPDATE: (payload) => ({
    payload, type: ChallengesActionTypes.INSTRUCTORS_UPDATE
  }),

  INSTRUCTORS_UPDATE_FAILURE: () => ({
    type: ChallengesActionTypes.INSTRUCTORS_UPDATE_FAILURE
  }),

  INSTRUCTORS_UPDATE_SUCCESS: (payload) => ({
    payload, type: ChallengesActionTypes.INSTRUCTORS_UPDATE_SUCCESS
  }),


  /* actions made by user */
  USERS_CLEAR: () => ({
    type: ChallengesActionTypes.USERS_CLEAR
  }),


  USERS_CREATE: (payload) => ({
    payload, type: ChallengesActionTypes.USERS_CREATE
  }),

  USERS_CREATE_FAILURE: () => ({
    type: ChallengesActionTypes.USERS_CREATE_FAILURE
  }),

  USERS_CREATE_SUCCESS: (payload) => ({
    payload, type: ChallengesActionTypes.USERS_CREATE_SUCCESS
  }),


  USERS_DELETE: (payload) => ({
    payload, type: ChallengesActionTypes.USERS_DELETE
  }),

  USERS_DELETE_FAILURE: () => ({
    type: ChallengesActionTypes.USERS_DELETE_FAILURE
  }),

  USERS_DELETE_SUCCESS: (payload) => ({
    payload, type: ChallengesActionTypes.USERS_CREATE_SUCCESS
  }),


  USERS_FETCH: (payload) => ({
    payload, type: ChallengesActionTypes.USERS_FETCH
  }),

  USERS_FETCH_FAILURE: () => ({
    type: ChallengesActionTypes.USERS_FETCH_FAILURE
  }),

  USERS_FETCH_SUCCESS: (payload) => ({
    payload, type: ChallengesActionTypes.USERS_FETCH_SUCCESS
  }),


  USERS_UPDATE: (payload) => ({
    payload, type: ChallengesActionTypes.USERS_UPDATE
  }),

  USERS_UPDATE_FAILURE: () => ({
    type: ChallengesActionTypes.USERS_UPDATE_FAILURE
  }),

  USERS_UPDATE_SUCCESS: (payload) => ({
    payload, type: ChallengesActionTypes.USERS_UPDATE_SUCCESS
  })
};
