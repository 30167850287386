import { BookingsActionTypes } from '../constants/actionTypes';

const initialState = {
  data:           [],
  instructorData: [],
  lastAction:     ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case BookingsActionTypes.CREATE:
      return newState;

    case BookingsActionTypes.CREATE_SUCCESS:
      return { ...newState, data: [...state.data, action.payload] };

    case BookingsActionTypes.CREATE_FAILURE:
      return newState;


    case BookingsActionTypes.DELETE:
      return newState;

    case BookingsActionTypes.DELETE_SUCCESS:
      return { ...newState, data: action.payload };

    case BookingsActionTypes.DELETE_FAILURE:
      return newState;


    case BookingsActionTypes.FETCH:
      return newState;

    case BookingsActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case BookingsActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case BookingsActionTypes.INSTRUCTORS_FETCH:
      return newState;

    case BookingsActionTypes.INSTRUCTORS_FETCH_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case BookingsActionTypes.INSTRUCTORS_FETCH_FAILURE:
      return { ...newState, instructorData: initialState.instructorData };


    default: return state;
  }
};
