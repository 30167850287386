import { PlansActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       [],
  lastAction: '',
  userData:   {}
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case PlansActionTypes.FETCH:
      return newState;

    case PlansActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case PlansActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case PlansActionTypes.SUBSCRIPTION_CREATE:
      return newState;

    case PlansActionTypes.SUBSCRIPTION_CREATE_SUCCESS:
      return { ...newState, userData: action.payload };

    case PlansActionTypes.SUBSCRIPTION_CREATE_FAILURE:
      return newState;


    case PlansActionTypes.SUBSCRIPTION_GET:
      return newState;

    case PlansActionTypes.SUBSCRIPTION_GET_SUCCESS:
      return { ...newState, userData: action.payload };

    case PlansActionTypes.SUBSCRIPTION_GET_FAILURE:
      return { ...newState, userData: initialState.userData };

    default: return state;
  }
};
