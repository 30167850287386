class Persistor {
  _save = (tag, data) => {
    localStorage.setItem(tag, data);
  }

  _load = (tag) => {
    return localStorage.getItem(tag);
  }
}

export default Persistor;
