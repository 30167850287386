/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { filter } from 'lodash';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { DateHelper, Moment, getTimeZone } from '../../lib';
import { calendarOptions } from '../../constants';

class Event extends React.PureComponent {
  static propTypes = {
    booking:      PropTypes.object,
    event:        PropTypes.object.isRequired,
    eventsLength: PropTypes.number,
    index:        PropTypes.number,
    isBooking:    PropTypes.bool,
    isDashboard:  PropTypes.bool,
    layout:       PropTypes.oneOf(['default', 'dashboard', 'instructorDashboard']),
    onClick:      PropTypes.func,
    user:         PropTypes.object.isRequired
  };

  static defaultProps = {
    booking:      {},
    eventsLength: 1,
    index:        0,
    isBooking:    false,
    isDashboard:  false,
    layout:       'default',
    onClick:      () => {}
  };

  static getDerivedStateFromProps(props) {
    const { event, eventsLength, index } = props;
    const isFirst = index === 0;
    const isOne = eventsLength === 1;
    const isPrivateSession = event.type === 'privateSession';
    const duration = isPrivateSession ? event.privateSessionType.duration : event.duration;
    const heightMinute = calendarOptions.DAY_VIEW.eventHeight / 60;
    const partWidth = 100 / eventsLength;

    const date = DateHelper.summerTimeCheck(event, props.user.timeZone);

    return {
      date:   Moment.tz(date, getTimeZone(props.user.timeZone)),
      height: `${heightMinute * duration}px`,
      left:   `${isFirst ? '0' : (partWidth * index)}%`,
      top:    heightMinute * new Moment.utc(props.event.startsAt, 'YYYY-MM-DDhh:mm:ss')
                            .tz(getTimeZone(props.user.timeZone)).format('mm'),
      width:  isOne ? '95%' : `${isFirst ? (eventsLength === 2 ? 85 : (partWidth * 1.5)) : (partWidth)}%`,
      zIndex: index + 1
    };
  }

  state = {
    date:   '',
    height: '',
    left:   '',
    top:    '',
    width:  '',
    zIndex: 1
  };

  handleClick = (event) => () => this.props.onClick(event);

  renderEventInfo = (event) => {
    const isPrivateSession = event.type === 'privateSession';
    const isPrivateGroupClass = event.type === 'privateGroupClass';
    const duration = isPrivateSession ? event.privateSessionType.duration : event.duration;
    const { date } = this.state;
    const bookings = filter(event.bookings, ['deletedAt', '']);

    const isHybridClass = isPrivateGroupClass && event.eventType == 'hybrid';
    const hybridLocation = isPrivateGroupClass && event.bookingType == 'online'
                             ? 'Virtual'
                             : event.location && event.location.name;
    const location = isHybridClass ? hybridLocation : event.location && event.location.name;

    switch (this.props.layout) {
      case 'default':
        return (
          <div className='event-info'>
            <div className='name'>
              { isPrivateSession ? [event.client.name, event.privateSessionType.name].join(' - ') : event.name  }
            </div>

            { event.location &&
              <div className='location'>
                {event.location && event.location.name}
              </div>
            }

            <div className='time'>
              {date.format('h:mm A')} - {duration}m
            </div>
          </div>
        );

      case 'dashboard':
        return (
          <div className='event-info event-dashboard'>
            { event.instructor &&
              <div className='instructor ml-3'>
                <div className='name font-weight-normal'>
                  <span className='event-name font-weight-normal'>
                    { isPrivateSession
                      ? `${event.privateSessionType.name} (${event.location.name})`
                      : `${event.name} (${location})`
                    }
                  </span>
                  <div className='d-flex'>
                    <div className='font-weight-bold'>
                      {date.format('ddd, MMM DD')}
                    </div>

                    <div>
                      &nbsp;|&nbsp;{date.format('hh:mmA')} - {duration} Minutes
                    </div>
                  </div>
                </div>
              </div>
            }

            { event.type == 'timeBlock' &&
              <div className='instructor'>
                <div className='name'>
                  <span className='event-name'>
                    {event.name}
                  </span>
                </div>
              </div>
            }
            <div className='small text-muted d-flex align-items-center pointer'>
              <span>View Booking</span>
              <Icon
                  className='ml-1'
                  icon='chevron-right' />
            </div>
          </div>
        );

      case 'instructorDashboard':
        return (
          <div className='event-info event-dashboard'>
            <div className='name font-weight-normal ml-3'>
              <span className='event-name font-weight-normal'>
                {isPrivateSession
                  ? event &&  `${event.privateSessionType.name} (${event.location.name})`
                  : event && `${event.name}`
                }
              </span>
              <div className='d-flex'>
                <div className='font-weight-bold'>
                  {date.format('ddd, MMM DD')}
                </div>

                <div>
                  &nbsp;|&nbsp;{date.format('hh:mmA')} - {duration} Minutes
                </div>
              </div>
              { event.type == 'privateGroupClass' &&
                <div className='small text-muted'>
                  {bookings.length} of {event.spots + event.virtualSpots} spots booked
                </div>
              }
            </div>

            <div className='small text-muted d-flex align-items-center pointer'>
              <span>More</span>
              <Icon
                  className='ml-1'
                  icon='chevron-right' />
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  render() {
    const { booking, event, isBooking, isDashboard } = this.props;
    const { left, height, zIndex, top, width } = this.state;

    const combinedStyle = { height, left, top, width, zIndex };

    const typeClass = event.type.split(/(?=[A-Z])/).join('-').toLowerCase();

    return (
      <div
          className={classNames(
            'event w-100',
            { 'booking': isBooking, 'dashboard-event-background': isDashboard },
            isDashboard ? 'default-color' : typeClass
          )}
          onClick={this.handleClick(booking.id ? booking : event)}
          onKeyPress={this.handleClick(booking.id ? booking : event)}
          role='button'
          {...!isBooking && {style: combinedStyle}}
          tabIndex={0}>
        {this.renderEventInfo(event)}
      </div>
    );
  }
}

export default Event;
