import React from 'react';
import PropTypes from 'prop-types';
import { ModalBody } from 'reactstrap';

import { Button } from '../../forms';
import { Link } from '../../inputs';
import { UserModalAvatar } from '../layouts';
import { Moment, getTimeZone, routes } from '../../../lib';

class Success extends React.PureComponent {
  static propTypes = {
    instructor:     PropTypes.object.isRequired,
    purchasedClass: PropTypes.object,
    user:           PropTypes.object.isRequired
  }

  static defaultProps = {
    purchasedClass: {}
  }

  handleClickLink = (link) => (e) => {
    e.preventDefault();

    const hasProtocol = link.startsWith('http://') || link.startsWith('https://');
    const externalLink = hasProtocol ? link : 'https://'.concat(link);

    window.open(externalLink, '_blank');
  }

  render() {
    const { instructor, purchasedClass, user } = this.props;
    const requestedClass = purchasedClass.onDemand;

    const createdAt = new Moment.utc(requestedClass.createdAt, 'YYYY-MM-DDhh:mm:ssUTC')
                                .tz(getTimeZone(user.timeZone));

    return (
      <div className='new-modal'>
        { requestedClass &&
          <ModalBody>
            <div>
              <span className='modal__default-text modal__default-text_blue modal__default-text_bold'>
                PURCHASE CONFIRMED
              </span>
            </div>

            <div className='modal__success-text'>
              <span className='modal__small-text'>
                  You’re all set! You will receve an email with the details below shortly!
              </span>
            </div>

            { instructor &&
              <div className='modal__header'>
                <UserModalAvatar
                    alt={instructor.name}
                    firstName={instructor.name}
                    src={instructor.photo.sm} />
                <div className='modal__instructor-name'>
                  <span className='modal__big-text'>{instructor.name}</span>
                </div>
              </div>
            }

            { requestedClass.id &&
              <div>
                <div className='modal__event-name'>
                  <span className='modal__big-text modal__big-text_black'>{requestedClass.name}</span>
                </div>

                <div>
                  <span className='modal__default-text modal__default-text_bold'>Uploaded | </span>
                  {createdAt.format('MMM, DD, YYYY')} at {createdAt.format('hh:mma')}
                </div>

                <div className='modal__event-timezone modal__small-text'>
                  {new Moment.tz(getTimeZone(user.timeZone)).format('z')} (GMT
                  {new Moment.tz(getTimeZone(user.timeZone)).format('Z')} {getTimeZone(user.timeZone)})
                </div>

                <div className='modal__event-location modal__small-text'>
                  Virtual
                </div>
                <a
                    className='d-block text-truncate modal__small-text modal__digital-link'
                    href={requestedClass.url}
                    onClick={this.handleClickLink(requestedClass.url)}
                    rel='noopener noreferrer'
                    target='_blank'>
                  {requestedClass.url}
                </a>
              </div>
            }

            { requestedClass.canExpire &&
              <div className='modal__default-text modal__default-text_bold mb-3'>
                Note: Video access will expire after {requestedClass.expiry} days
              </div>
            }

            { requestedClass.url &&
              <a
                  href={requestedClass.url}
                  onClick={this.handleClickLink(requestedClass.url)}
                  rel='noopener noreferrer'
                  target='_blank'>
                <Button
                    color='blue'
                    isBlock
                    size='lg'>
                  Watch video
                </Button>
              </a>
            }

            { !requestedClass.url && purchasedClass.uuid &&
              <Link
                  target='_blank'
                  to={routes.VIDEO_PLAYER(purchasedClass.uuid)}>
                <Button
                    color='blue'
                    isBlock
                    size='lg'>
                  Watch video
                </Button>
              </Link>
            }
          </ModalBody>
        }
      </div>
    );
  }
}

export default Success;
