import React from 'react';
import PropTypes from 'prop-types';
import { includes, keys, map } from 'lodash';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { FormGroup, Label } from 'reactstrap';
import { isMobile } from 'react-device-detect';

import { currencies } from '../../constants';
import { routes } from '../../lib';
import { Cable } from '../cable';
import { Select } from '../forms';
import { StripeConnect, ZoomConnect } from '../forms/profile/update';
import { Heading, Panel } from '../layout/pages';
import { BackButton } from '../buttons';

const selectOptions = map(keys(currencies), (currency) => ({ label: currency, value: currency }));

class PaymentsPage extends React.PureComponent {
  static propTypes = {
    fetchUser:     PropTypes.func.isRequired,
    oAuthLogout:   PropTypes.func.isRequired,
    segmentStripe: PropTypes.func.isRequired,
    segmentZoom:   PropTypes.func.isRequired,
    updateUser:    PropTypes.func.isRequired,
    user:          PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      hasStripe:        props.user.hasStripe,
      selectedCurrency: props.user.currency,
      user:             props.user,
      zoom:             props.user.oauth.zoom
    };
  }

  state = {
    hasStripe:        false,
    selectedCurrency: { label: 'USD', value: 'USD' },
    user:             {},
    zoom:             false
  }

  handleActivate = (data) => {
    this.setState(data);
  }

  handleReceive = (isConnected) => {
    this.setState({ hasStripe: isConnected });
  }

  handleSelect = (selectedCurrency) => {
    this.props.updateUser({
      user: {
        currency: selectedCurrency,
        id:       this.state.user.id
      }
    });
  };

  handleOAuthLogout = (provider) => {
    this.props.oAuthLogout(provider);
  }

  handleSuccessStripe = () => (
    this.props.segmentStripe(this.props.user),
    this.props.fetchUser()
  )

  handleSuccessZoom = () => (
    this.props.segmentZoom(this.props.user),
    this.props.fetchUser()
  )

  render() {
    const { user } = this.props;
    const currencyOptionSelected = (value, options) => {
      includes(options, value);
    };

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Integrations</title>
        </Helmet>

        <Cable
            channel={{ channel: 'StripeChannel', user_id: user.id }}
            onReceived={this.handleReceive} />

        <Cable
            channel="IntegrationsChannel"
            onReceived={this.handleActivate} />

        <div className='inner-wrapper instructor-settings'>
          <Container fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Integrations' />

            <Panel>
              <div>
                <FormGroup className='w-100'>
                  <StripeConnect
                      isConnected={this.state.hasStripe}
                      onLogout={this.handleOAuthLogout}
                      onSuccess={this.handleSuccessStripe} />
                  <small className='text-muted ml-3 d-block'>
                    Stripe charges a credit card processing fee that varies by country.
                    See <a href='https://stripe.com/pricing'>Stripe pricing</a> for more details.
                  </small>
                </FormGroup>

                <FormGroup className='w-50 mt-2'>
                  <Label
                      className='text-sm ml-3'
                      for='currency'>
                    Currency
                  </Label>

                  <Select
                      className='currency-selector mb-3 ml-3'
                      isOptionSelected={currencyOptionSelected}
                      onChange={this.handleSelect}
                      options={selectOptions}
                      value={this.state.selectedCurrency} />
                </FormGroup>
              </div>

              <div>
                <FormGroup className='w-100'>
                  <ZoomConnect
                      isConnected={this.state.zoom}
                      onLogout={this.handleOAuthLogout}
                      onSuccess={this.handleSuccessZoom} />
                </FormGroup>
              </div>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default PaymentsPage;
