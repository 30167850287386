import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { map } from 'lodash';
import { api, extractError, toCamelCase } from '../lib';
import { FollowsActionTypes } from '../constants/actionTypes';

import {
  ErrorActions,
  FollowsActions
} from '../actions';

function* watchInstructorsFetch() {
  yield takeLatest(FollowsActionTypes.FETCH_INSTRUCTORS, function* () {
    try {
      const response = yield call(api.instructor.follow.fetch);

      yield put(FollowsActions.FETCH_INSTRUCTORS_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(FollowsActions.FETCH_INSTRUCTORS_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchStudiosFetch() {
  yield takeLatest(FollowsActionTypes.FETCH_STUDIOS, function* () {
    try {
      const response = yield call(api.follow.studios.fetch);

      yield put(FollowsActions.FETCH_STUDIOS_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(FollowsActions.FETCH_STUDIOS_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorFollow() {
  yield takeLatest(FollowsActionTypes.FOLLOW_INSTRUCTOR, function* ({ payload }) {
    try {
      yield call(api.instructor.follow.create, payload);

      const instructors = yield select((state) => state.follows.instructors);
      const data = map(instructors, (instructor) => {
        if (instructor.id == payload.id) {
          return { ...instructor, isUnfollowed: false };
        } else {
          return instructor;
        }
      });

      yield put(FollowsActions.FOLLOW_INSTRUCTOR_SUCCESS(data));
    } catch (error) {
      yield put(FollowsActions.FOLLOW_INSTRUCTOR_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}


function* watchStudioFollow() {
  yield takeLatest(FollowsActionTypes.FOLLOW_STUDIO, function* ({ payload }) {
    try {
      yield call(api.follow.studios.create, payload);

      const studios = yield select((state) => state.follows.studios);
      const data = map(studios, (studio) => {
        if (studio.id == payload.id) {
          return { ...studio, isUnfollowed: false };
        } else {
          return studio;
        }
      });

      yield put(FollowsActions.FOLLOW_STUDIO_SUCCESS(data));
    } catch (error) {
      yield put(FollowsActions.FOLLOW_STUDIO_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}



function* watchInstructorUnfollow() {
  yield takeLatest(FollowsActionTypes.UNFOLLOW_INSTRUCTOR, function* ({ payload }) {
    try {
      yield call(api.instructor.follow.destroy, payload);

      const instructors = yield select((state) => state.follows.instructors);
      const data = map(instructors, (instructor) => {
        if (instructor.id == payload.id) {
          return { ...instructor, isUnfollowed: true };
        } else {
          return instructor;
        }
      });

      yield put(FollowsActions.UNFOLLOW_INSTRUCTOR_SUCCESS(data));
    } catch (error) {
      yield put(FollowsActions.UNFOLLOW_INSTRUCTOR_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchStudioUnfollow() {
  yield takeLatest(FollowsActionTypes.UNFOLLOW_STUDIO, function* ({ payload }) {
    try {
      yield call(api.follow.studios.destroy, payload);

      const studios = yield select((state) => state.follows.studios);
      const data = map(studios, (studio) => {
        if (studio.id == payload.id) {
          return { ...studio, isUnfollowed: true };
        } else {
          return studio;
        }
      });

      yield put(FollowsActions.UNFOLLOW_STUDIO_SUCCESS(data));
    } catch (error) {
      yield put(FollowsActions.UNFOLLOW_STUDIO_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}


export default function* followsSaga() {
  yield all([
    watchInstructorsFetch(),
    watchStudiosFetch(),
    watchInstructorFollow(),
    watchStudioFollow(),
    watchInstructorUnfollow(),
    watchStudioUnfollow()
  ]);
}
