import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { map } from 'lodash';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';

import { api, extractError, routes, toCamelCase } from '../lib';
import { DonationTargetsActionTypes } from '../constants/actionTypes';
import { DonationTargetsActions, ErrorActions, LoaderActions } from '../actions';

function* watchCreate() {
  yield takeLatest(DonationTargetsActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.instructor.donationTargets.create, payload);

      yield put(DonationTargetsActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Donation Target setted');
    } catch (error) {
      yield put(DonationTargetsActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(DonationTargetsActionTypes.CREATE_SUCCESS, function* () {
    yield put(push(routes.SETTINGS));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(DonationTargetsActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetch() {
  yield takeLatest(DonationTargetsActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.donationTarget.fetch);

      yield put(DonationTargetsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(DonationTargetsActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(DonationTargetsActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(DonationTargetsActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsFetch() {
  yield takeLatest(DonationTargetsActionTypes.INSTRUCTORS_FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.donationTargets.fetch, payload);

      yield put(DonationTargetsActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(DonationTargetsActions.INSTRUCTORS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsFetchSuccess() {
  yield takeLatest(DonationTargetsActionTypes.INSTRUCTORS_FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsFetchFailure() {
  yield takeLatest(DonationTargetsActionTypes.INSTRUCTORS_FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdate() {
  yield takeLatest(DonationTargetsActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.donationTargets.update, payload);
      const donationTargets = yield select((state) => state.donationTargets.data);
      const data = map(donationTargets, (donationTarget) => (
        donationTarget.id === payload.donationTarget.id ? toCamelCase(response.data) : donationTarget
      ));

      yield put(DonationTargetsActions.UPDATE_SUCCESS(data));
      toastr.success('Donation Target updated');
    } catch (error) {
      yield put(DonationTargetsActions.UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(DonationTargetsActionTypes.UPDATE_SUCCESS, function* () {
    yield put(push(routes.HOME_SETTINGS));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateFailure() {
  yield takeLatest(DonationTargetsActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* donationTargetsSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchInstructorsFetch(),
    watchInstructorsFetchSuccess(),
    watchInstructorsFetchFailure(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure()
  ]);
}
