import { connect } from 'react-redux';
import { CouponsActions, TransactionsActions } from '../../../../actions';

import PurchasesTable from './purchasesTable';

const mapStateToProps = (state) => ({
  coupons:      state.coupons.data,
  isLoading:    state.transactions.isLoading,
  transactions: state.transactions.purchases
});

const mapDispatchToProps = (dispatch) => ({
  fetchCoupons:   () =>     dispatch(CouponsActions.FETCH()),
  fetchPurchases: () =>     dispatch(TransactionsActions.PURCHASES_FETCH()),
  updatePurchase: (data) => dispatch(TransactionsActions.PURCHASE_UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchasesTable);
