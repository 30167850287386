import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';
import { Container, Label, Media } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { routes } from '../../lib';
import { Heading, Panel } from '../layout/pages';
import { BackButton } from '../buttons';
import { Button, Checkbox } from '../forms';
import { RequirementsModal } from '../modals';

class PrivateSessionsPage extends React.PureComponent {
  static propTypes = {
    instructor:          PropTypes.object.isRequired,
    locations:           PropTypes.array.isRequired,
    privateSessionTypes: PropTypes.array.isRequired,
    updateProfile:       PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const { instructor } = props;

    if (instructor.id && !state.isMounted) {
      return {
        availability: instructor.availability || state.availability,
        instructor,
        isMounted:    true
      };
    }

    return state;
  }

  state = {
    availability: {},
    isModalOpen:  false
  }

  handleModal = () => (
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen
    }))
  )

  handleChange = (setting) => () => {
    this.setState((prevState) => ({
      instructor: {
        ...prevState.instructor,
        [setting]: !prevState.instructor[setting]
      },
      [setting]: !prevState[setting]
    }), () => {
      const { availability, privateSessionsEnabled } = this.state;
      const { locations, privateSessionTypes } = this.props;

      if (isEmpty(availability) || !locations.length || !privateSessionTypes.length) {
        if (privateSessionsEnabled) this.setState({ isRequirementsModalOpen: true });
      }

      this.props.updateProfile(this.state.instructor);
    });
  };

  render() {
    const { availability, instructor } = this.state;
    const { locations, privateSessionTypes } = this.props;

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Private Sessions</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Private Sessions' />

            <Panel className='settings-menu'>
              <Media className='menu-link d-block'>
                <Media body>
                  <div className='custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='private-session'
                        isChecked={instructor.privateSessionsEnabled}
                        name='private-session'
                        onChange={this.handleChange('privateSessionsEnabled')} />
                    <Label
                        className='custom-control-label'
                        htmlFor='input-private-session'>
                      Show the Private Session module on your page
                    </Label>
                  </div>
                </Media>

                <div className='small d-flex text-muted font-italic mt-1'>
                  Setup:&nbsp;
                  {locations && (isEmpty(availability) || !locations.length || !privateSessionTypes.length) &&
                    <React.Fragment>
                      <div className='text-danger'>Incomplete&nbsp;</div>

                      <Link
                          onClick={this.handleModal}
                          to='#'>
                        <div className='text-primary'>(how come?)</div>
                      </Link>
                    </React.Fragment>
                  }

                  {locations && (!isEmpty(availability) && !!locations.length && !!privateSessionTypes.length) &&
                    <div className='text-success'>Complete</div>
                  }
                </div>
              </Media>

              <Media className='menu-link'>
                <Media left>
                  <Icon
                      fixedWidth
                      icon='calendar-alt' />
                </Media>
                <Media body>
                  Weekly Availability
                </Media>
                <Media right>
                  <Link to={routes.AVAILABILITY}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media left>
                  <Icon
                      fixedWidth
                      icon='list-alt' />
                </Media>
                <Media body>
                  Private Session Types
                </Media>
                <Media right>
                  <Link to={routes.SESSION_TYPES}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>
            </Panel>
          </Container>
        </div>
        <div className='modals'>
          { this.state.isModalOpen &&
            <RequirementsModal
                availability={availability}
                isOpen={this.state.isModalOpen}
                locations={locations}
                onToggle={this.handleModal}
                privateSessionTypes={privateSessionTypes} />
          }
        </div>
      </React.Fragment>
    );
  }
}

export default PrivateSessionsPage;
