import React from 'react';

import { NotFound } from '../components/errors';

class NotFoundContainer extends React.PureComponent {
  render() {
    return <NotFound />;
  }
}

export default NotFoundContainer;
