import moment from 'moment-timezone';

const renderGMT = (timezone) => (
  moment().tz(timezone).format('Z')
);

export default [
  { label: `(GMT${renderGMT('Pacific/Pago_Pago')}) Pago - Pago`, value: 'Pacific/Pago_Pago' },
  { label: `(GMT${renderGMT('Pacific/Honolulu')}) Hawaii Time`, value: 'Pacific/Honolulu' },
  { label: `(GMT${renderGMT('America/Los_Angeles')}) Pacific Time`, value: 'America/Los_Angeles' },
  { label: `(GMT${renderGMT('America/Tijuana')}) Pacific Time - Tijuana`, value: 'America/Tijuana' },
  { label: `(GMT${renderGMT('America/Denver')}) Mountain Time`, value: 'America/Denver' },
  { label: `(GMT${renderGMT('America/Phoenix')}) Mountain Time - Arizona`, value: 'America/Phoenix' },
  { label: `(GMT${renderGMT('America/Mazatlan')}) Mountain Time - Chihuahua, Mazatlan`, value: 'America/Mazatlan' },
  { label: `(GMT${renderGMT('America/Chicago')}) Central Time`, value: 'America/Chicago' },
  { label: `(GMT${renderGMT('America/Mexico_City')}) Central Time - Mexico City`, value: 'America/Mexico_City' },
  { label: `(GMT${renderGMT('America/Regina')}) Central Time - Regina`, value: 'America/Regina' },
  { label: `(GMT${renderGMT('America/Guatemala')}) Guatemala`, value: 'America/Guatemala' },
  { label: `(GMT${renderGMT('America/Bogota')}) Bogota`, value: 'America/Bogota' },
  { label: `(GMT${renderGMT('America/New_York')}) Eastern Time`, value: 'America/New_York' },
  { label: `(GMT${renderGMT('America/Lima')}) Lima`, value: 'America/Lima' },
  { label: `(GMT${renderGMT('America/Caracas')}) Caracas`, value: 'America/Caracas' },
  { label: `(GMT${renderGMT('America/Halifax')}) Atlantic Time - Halifax`, value: 'America/Halifax' },
  { label: `(GMT${renderGMT('America/Guyana')}) Guyana`, value: 'America/Guyana' },
  { label: `(GMT${renderGMT('America/La_Paz')}) La Paz`, value: 'America/La_Paz' },
  { label: `(GMT${renderGMT('America/Argentina/Buenos_Aires')}) Buenos Aires`,
    value: 'America/Argentina/Buenos_Aires' },
  { label: `(GMT${renderGMT('America/Godthab')}) Godthab`, value: 'America/Godthab' },
  { label: `(GMT${renderGMT('America/Montevideo')}) Montevideo`, value: 'America/Montevideo' },
  { label: `(GMT${renderGMT('America/St_Johns')}) Newfoundland Time - St. Johns`, value: 'America/St_Johns' },
  { label: `(GMT${renderGMT('America/Santiago')}) Santiago`, value: 'America/Santiago' },
  { label: `(GMT${renderGMT('America/Sao_Paulo')}) Sao Paulo`, value: 'America/Sao_Paulo' },
  { label: `(GMT${renderGMT('Atlantic/South_Georgia')}) South Georgia`, value: 'Atlantic/South_Georgia' },
  { label: `(GMT${renderGMT('Atlantic/Azores')}) Azores`, value: 'Atlantic/Azores' },
  { label: `(GMT${renderGMT('Atlantic/Cape_Verde')}) Cape Verde`, value: 'Atlantic/Cape_Verde' },
  { label: `(GMT${renderGMT('Africa/Casablanca')}) Casablanca`, value: 'Africa/Casablanca' },
  { label: `(GMT${renderGMT('Europe/Dublin')}) Dublin`, value: 'Europe/Dublin' },
  { label: `(GMT${renderGMT('Europe/Lisbon')}) Lisbon`, value: 'Europe/Lisbon' },
  { label: `(GMT${renderGMT('Europe/London')}) London`, value: 'Europe/London' },
  { label: `(GMT${renderGMT('Africa/Monrovia')}) Monrovia`, value: 'Africa/Monrovia' },
  { label: `(GMT${renderGMT('Africa/Algiers')}) Algiers`, value: 'Africa/Algiers' },
  { label: `(GMT${renderGMT('Europe/Amsterdam')}) Amsterdam`, value: 'Europe/Amsterdam' },
  { label: `(GMT${renderGMT('Europe/Berlin')}) Berlin`, value: 'Europe/Berlin' },
  { label: `(GMT${renderGMT('Europe/Brussels')}) Brussels`, value: 'Europe/Brussels' },
  { label: `(GMT${renderGMT('Europe/Budapest')}) Budapest`, value: 'Europe/Budapest' },
  { label: `(GMT${renderGMT('Europe/Belgrade')}) Central European Time - Belgrade`,value: 'Europe/Belgrade' },
  { label: `(GMT${renderGMT('Europe/Prague')}) Central European Time - Prague`, value: 'Europe/Prague' },
  { label: `(GMT${renderGMT('Europe/Copenhagen')}) Copenhagen`, value: 'Europe/Copenhagen' },
  { label: `(GMT${renderGMT('Europe/Madrid')}) Madrid`, value: 'Europe/Madrid' },
  { label: `(GMT${renderGMT('Europe/Paris')}) Paris`, value: 'Europe/Paris' },
  { label: `(GMT${renderGMT('Europe/Rome')}) Rome`, value: 'Europe/Rome' },
  { label: `(GMT${renderGMT('Europe/Stockholm')}) Stockholm`, value: 'Europe/Stockholm' },
  { label: `(GMT${renderGMT('Europe/Vienna')}) Vienna`, value: 'Europe/Vienna' },
  { label: `(GMT${renderGMT('Europe/Warsaw')}) Warsaw`, value: 'Europe/Warsaw' },
  { label: `(GMT${renderGMT('Europe/Athens')}) Athens`, value: 'Europe/Athens' },
  { label: `(GMT${renderGMT('Europe/Bucharest')}) Bucharest`, value: 'Europe/Bucharest' },
  { label: `(GMT${renderGMT('Africa/Cairo')}) Cairo`, value: 'Africa/Cairo' },
  { label: `(GMT${renderGMT('Asia/Jerusalem')}) Jerusalem`, value: 'Asia/Jerusalem' },
  { label: `(GMT${renderGMT('Africa/Johannesburg')}) Johannesburg`, value: 'Africa/Johannesburg' },
  { label: `(GMT${renderGMT('Europe/Helsinki')}) Helsinki`, value: 'Europe/Helsinki' },
  { label: `(GMT${renderGMT('Europe/Kiev')}) Kiev`, value: 'Europe/Kiev' },
  { label: `(GMT${renderGMT('Europe/Kaliningrad')}) Moscow-01 - Kaliningrad`, value: 'Europe/Kaliningrad' },
  { label: `(GMT${renderGMT('Europe/Riga')}) Riga`, value: 'Europe/Riga' },
  { label: `(GMT${renderGMT('Europe/Sofia')}) Sofia`, value: 'Europe/Sofia' },
  { label: `(GMT${renderGMT('Europe/Tallinn')}) Tallinn`, value: 'Europe/Tallinn' },
  { label: `(GMT${renderGMT('Europe/Vilnius')}) Vilnius`, value: 'Europe/Vilnius' },
  { label: `(GMT${renderGMT('Europe/Istanbul')}) Istanbul`, value: 'Europe/Istanbul' },
  { label: `(GMT${renderGMT('Asia/Baghdad')}) Baghdad`, value: 'Asia/Baghdad' },
  { label: `(GMT${renderGMT('Africa/Nairobi')}) Nairobi`, value: 'Africa/Nairobi' },
  { label: `(GMT${renderGMT('Europe/Minsk')}) Minsk`, value: 'Europe/Minsk' },
  { label: `(GMT${renderGMT('Asia/Riyadh')}) Riyadh`, value: 'Asia/Riyadh' },
  { label: `(GMT${renderGMT('Europe/Moscow')}) Moscow+00 - Moscow`, value: 'Europe/Moscow' },
  { label: `(GMT${renderGMT('Asia/Tehran')}) Tehran`, value: 'Asia/Tehran' },
  { label: `(GMT${renderGMT('Asia/Baku')}) Baku`, value: 'Asia/Baku' },
  { label: `(GMT${renderGMT('Europe/Samara')}) Moscow+01 - Samara`, value: 'Europe/Samara' },
  { label: `(GMT${renderGMT('Asia/Tbilisi')}) Tbilisi`, value: 'Asia/Tbilisi' },
  { label: `(GMT${renderGMT('Asia/Yerevan')}) Yerevan`, value: 'Asia/Yerevan' },
  { label: `(GMT${renderGMT('Asia/Kabul')}) Kabul`, value: 'Asia/Kabul' },
  { label: `(GMT${renderGMT('Asia/Karachi')}) Karachi`, value: 'Asia/Karachi' },
  { label: `(GMT${renderGMT('Asia/Yekaterinburg')}) Moscow+02 - Yekaterinburg`, value: 'Asia/Yekaterinburg' },
  { label: `(GMT${renderGMT('Asia/Tashkent')}) Tashkent`, value: 'Asia/Tashkent' },
  { label: `(GMT${renderGMT('Asia/Colombo')}) Colombo`, value: 'Asia/Colombo' },
  { label: `(GMT${renderGMT('Asia/Almaty')}) Almaty`, value: 'Asia/Almaty' },
  { label: `(GMT${renderGMT('Asia/Dhaka')}) Dhaka`, value: 'Asia/Dhaka' },
  { label: `(GMT${renderGMT('Asia/Rangoon')}) Rangoon`, value: 'Asia/Rangoon' },
  { label: `(GMT${renderGMT('Asia/Bangkok')}) Bangkok`, value: 'Asia/Bangkok' },
  { label: `(GMT${renderGMT('Asia/Jakarta')}) Jakarta`, value: 'Asia/Jakarta' },
  { label: `(GMT${renderGMT('Asia/Krasnoyarsk')}) Moscow+04 - Krasnoyarsk`, value: 'Asia/Krasnoyarsk' },
  { label: `(GMT${renderGMT('Asia/Shanghai')}) China Time - Beijing`, value: 'Asia/Shanghai' },
  { label: `(GMT${renderGMT('Asia/Hong_Kong')}) Hong Kong`, value: 'Asia/Hong_Kong' },
  { label: `(GMT${renderGMT('Asia/Kuala_Lumpur')}) Kuala Lumpur`, value: 'Asia/Kuala_Lumpur' },
  { label: `(GMT${renderGMT('Asia/Irkutsk')}) Moscow+05 - Irkutsk`, value: 'Asia/Irkutsk' },
  { label: `(GMT${renderGMT('Asia/Singapore')}) Singapore`, value: 'Asia/Singapore' },
  { label: `(GMT${renderGMT('Asia/Taipei')}) Taipei`, value: 'Asia/Taipei' },
  { label: `(GMT${renderGMT('Asia/Ulaanbaatar')}) Ulaanbaatar`, value: 'Asia/Ulaanbaatar' },
  { label: `(GMT${renderGMT('Australia/Perth')}) Western Time - Perth`, value: 'Australia/Perth' },
  { label: `(GMT${renderGMT('Asia/Yakutsk')}) Moscow+06 - Yakutsk`, value: 'Asia/Yakutsk' },
  { label: `(GMT${renderGMT('Asia/Seoul')}) Seoul`, value: 'Asia/Seoul' },
  { label: `(GMT${renderGMT('Asia/Tokyo')}) Tokyo`, value: 'Asia/Tokyo' },
  { label: `(GMT${renderGMT('Australia/Darwin')}) Central Time - Darwin`, value: 'Australia/Darwin' },
  { label: `(GMT${renderGMT('Australia/Brisbane')}) Eastern Time - Brisbane`, value: 'Australia/Brisbane' },
  { label: `(GMT${renderGMT('Pacific/Guam')}) Guam`, value: 'Pacific/Guam' },
  { label: `(GMT${renderGMT('Asia/Magadan')}) Moscow+07 - Magadan`, value: 'Asia/Magadan' },
  { label: `(GMT${renderGMT('Asia/Vladivostok')}) Moscow+07 - Yuzhno-Sakhalinsk`, value: 'Asia/Vladivostok' },
  { label: `(GMT${renderGMT('Pacific/Port_Moresby')}) Port Moresby`, value: 'Pacific/Port_Moresby' },
  { label: `(GMT${renderGMT('Australia/Adelaide')}) Central Time - Adelaide`, value: 'Australia/Adelaide' },
  { label: `(GMT${renderGMT('Australia/Hobart')}) Eastern Time - Hobart`, value: 'Australia/Hobart' },
  { label: `(GMT${renderGMT('Australia/Sydney')}) Eastern Time - Melbourne, Sydney`, value: 'Australia/Sydney' },
  { label: `(GMT${renderGMT('Pacific/Guadalcanal')}) Guadalcanal`, value: 'Pacific/Guadalcanal' },
  { label: `(GMT${renderGMT('Pacific/Noumea')}) Noumea`, value: 'Pacific/Noumea' },
  { label: `(GMT${renderGMT('Pacific/Majuro')} Majuro`, value: 'Pacific/Majuro' },
  { label: `(GMT${renderGMT('Asia/Kamchatka')}) Moscow+09 - Petropavlovsk-Kamchatskiy`, value: 'Asia/Kamchatka' },
  { label: `(GMT${renderGMT('Pacific/Auckland')}) Auckland`, value: 'Pacific/Auckland' },
  { label: `(GMT${renderGMT('Pacific/Fakaofo')}) Fakaofo`, value: 'Pacific/Fakaofo' },
  { label: `(GMT${renderGMT('Pacific/Fiji')}) Fiji`, value: 'Pacific/Fiji' },
  { label: `(GMT${renderGMT('Pacific/Tongatapu')}) Tongatapu`, value: 'Pacific/Tongatapu' },
  { label: `(GMT${renderGMT('Pacific/Apia')}) Apia`, value: 'Pacific/Apia' }
];
