import React from 'react';
import PropTypes from 'prop-types';
import { Badge, Media } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import Avatar from 'react-avatar';

import { UserAvatar } from '../../../users';

class Comment extends React.PureComponent {
  static propTypes = {
    comment: PropTypes.object.isRequired
  }

  render() {
    const { comment } = this.props;

    return (
      <Media className='mb-lg-5 mb-3'>
        <Media left>
          { comment.author.photo &&
            <UserAvatar
                alt={comment.author.fullName}
                className='mr-4'
                size={isMobile ? 'md' : 'lg'}
                src={comment.author.photo} />
          }
          { !comment.author.photo &&
            <Avatar
                color='#BCDCFF'
                name={comment.author.fullName ? comment.author.fullName.charAt(0) : 'User'}
                round={isMobile ? '26px' : '40px'}
                size={isMobile ? '56' : '80'}
                style={{marginRight: 20}} />
          }
        </Media>
        <Media body>
          <Media
              className='mt-lg-1'
              heading>
            <span className='comment-author'>{comment.author.fullName ? comment.author.fullName : 'User'}</span>
            <span className='comment-date ml-3'>
              {comment.createdAt}
            </span>
          </Media>
          <div className='comment-body'>
            <p className='mb-2'>{comment.body}</p>
          </div>
          { comment.styleTag &&
            <Badge
                className='px-3 py-2 mt-2 badge-style-tag  btn-style-tags'
                color='light'
                pill>
              {comment.styleTag.name}
            </Badge>
          }
        </Media>
      </Media>
    );
  }
}

export default Comment;
