import React from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  FormFeedback,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row } from 'reactstrap';
import { compact } from 'lodash';
import classNames from 'classnames';

import { AsyncSelect, Input } from '../../inputs';
import { PlacesAutocomplete } from '../../../lib';

class General extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    errors:   PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    profile:  PropTypes.object.isRequired
  }

  static defaultProps = {
    isEditable: false
  }

  state = {
    location: ''
  }

  placesAutocomplete = new PlacesAutocomplete('(cities)', 'cities');

  componentDidMount() {
    const { location } = this.props.profile;

    this.setState({
      location: compact([location.city, location.state, location.country]).join(', ')
    });
  }

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value });
  }

  handleSelect = (option) => {
    const geocode = this.placesAutocomplete.geocodeRequest(option.value, false);

    geocode.then((repsonse) => {
      const location = repsonse.data.geocoders[0];

      return this.props.onChange({
        city:    location.city,
        country: location.country,
        state:   location.state
      }, 'location');
    }).catch((e) => { throw e; });
  }

  loadLocationOption = (inputValue) => {
    return this.placesAutocomplete.getFormattedOptions(inputValue);
  }

  render() {
    const selectedLocation = { label: this.state.location, value: this.state.location };

    return (
      <div className='text-block'>
        {this.props.children}

        <Row>
          <Col>
            <FormGroup>
              <Label
                  className='required'
                  htmlFor='firstName'>
                First name
              </Label>
              <Input
                  id='firstName'
                  isInvalid={!!this.props.errors.firstName}
                  name='firstName'
                  onChange={this.props.onChange}
                  placeholder='First name'
                  type='text'
                  value={this.props.profile.firstName} />
              <FormFeedback tooltip>{this.props.errors.firstName}</FormFeedback>
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label
                  className='required'
                  htmlFor='lastName'>Last name</Label>
              <Input
                  id='lastName'
                  isInvalid={!!this.props.errors.lastName}
                  name='lastName'
                  onChange={this.props.onChange}
                  placeholder='Last name'
                  type='text'
                  value={this.props.profile.lastName} />
              <FormFeedback tooltip>{this.props.errors.lastName}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label className='required'>Username</Label>
          <InputGroup className={classNames({'is-invalid': !!this.props.errors.username})}>
            <InputGroupAddon addonType='prepend'>
              <InputGroupText className='bg-info text-white'>onpodio.com/</InputGroupText>
            </InputGroupAddon>
            <Input
                id='username'
                name='username'
                onChange={this.props.onChange}
                type='text'
                value={this.props.profile.username} />
          </InputGroup>
          <FormFeedback tooltip>{this.props.errors.username}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label for='location'>
            Location
          </Label>
          <AsyncSelect
              isClearable={false}
              isInvalid={!!this.props.errors.location}
              isSearchable
              loadOptions={this.loadLocationOption}
              name='location'
              onChange={this.handleSelect}
              value={selectedLocation} />
          <FormFeedback>{this.props.errors.location}</FormFeedback>
        </FormGroup>
      </div>
    );
  }
}

export default General;
