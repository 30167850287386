import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

class PromptsModal extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    isOpen:   PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired
  }

  render() {
    return (
      <Modal
          centered
          isOpen={this.props.isOpen}
          size='lg'
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          Select a prompt
        </ModalHeader>

        <ModalBody>
          {this.props.children}
        </ModalBody>
      </Modal>
    );
  }
}

export default PromptsModal;
