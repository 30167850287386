import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import prettyBytes from 'pretty-bytes';
import classNames from 'classnames';
import BackgroundImageOnLoad from 'background-image-on-load';

import { Moment, getTimeZone } from '../../lib';
import { Button } from '../forms';
import { VideoFallback } from '../../images';
import { SendEmailContainer } from '../../containers';
import { PlayIcon } from '../../images';
import { RequestedClass, VideoPlayer } from './';

class RequestedVideo extends PureComponent {
  static propTypes = {
    currency: PropTypes.string.isRequired,
    data:     PropTypes.object.isRequired,
    folders:  PropTypes.array,
    formats:  PropTypes.array.isRequired,
    timeZone: PropTypes.string.isRequired
  };

  static defaultProps = {
    folders: []
  };

  state = {
    isEditOpen:        false,
    isPosterLoaded:    false,
    isSendEmailOpen:   false,
    isVideoPlayerOpen: false
  }

  handleTogglePlayer = () => {
    if (this.props.data.contentType === 'file') {
      this.setState({
        isVideoPlayerOpen: !this.state.isVideoPlayerOpen
      });
    }
  }

  handleToggleEdit = () => {
    this.setState({
      isEditOpen: !this.state.isEditOpen
    });
  }

  handleOpenModal = () => {
    this.setState({
      isSendEmailOpen: !this.state.isSendEmailOpen
    });
  }

  render() {
    const { data, timeZone, currency, formats, folders } = this.props;
    const { isVideoPlayerOpen, isEditOpen, isSendEmailOpen, isPosterLoaded } = this.state;

    const createdAt = new Moment.utc(data.createdAt, 'YYYY-MM-DDhh:mm:ssUTC')
      .tz(getTimeZone(timeZone));
    const uploadedAt = `Uploaded on ${createdAt.format('MMM DD YYYY')} at ${createdAt.format('hh:mma')}`;
    const isFile = data.contentType === 'file';


    return (
      <Fragment>
        <div className='requested-video'>
          <div
              className={classNames('video-poster', { 'pointer': isFile })}
              onClick={this.handleTogglePlayer}
              onKeyPress={this.handleTogglePlayer}
              role='button'
              style={{ backgroundImage: `url(${isPosterLoaded ? data.poster : VideoFallback})` }}
              tabIndex={-1} >
            <BackgroundImageOnLoad
                onError={(err) => console.log('error load', err)}
                onLoadBg={() => this.setState({ isPosterLoaded: true })}
                src={data.poster} />
            <div>
              { isFile &&
                <img
                    alt='Icon'
                    className='pointer'
                    src={PlayIcon} />
              }
            </div>
          </div>
          <div className='video-info'>
            <span className='name'>{data.name}</span>
            <span className='time'>
              {uploadedAt} {data.size && `| ${prettyBytes(data.size)}`}
            </span>

            <div className='buttons'>
              <Button
                  className='button'
                  isOutline
                  onClick={this.handleToggleEdit}
                  size='sm'>
                Edit
              </Button>

              <Button
                  className='button ml-1'
                  isOutline
                  onClick={this.handleOpenModal}
                  size='sm'>
                Send
              </Button>
            </div>
          </div>
        </div>

        <VideoPlayer
            isOpen={isVideoPlayerOpen}
            name={data.name}
            onToggle={this.handleTogglePlayer}
            poster={data.poster}
            videoUrl={data.videoUrl} />

        { isEditOpen &&
          <RequestedClass
              classId={data.id}
              currency={currency}
              folders={folders}
              formats={formats}
              isNewRecord={false}
              isOpen={isEditOpen}
              onToggle={this.handleToggleEdit} />
        }

        { isSendEmailOpen &&
          <SendEmailContainer
              classId={data.id}
              isOpen={isSendEmailOpen}
              onToggle={this.handleOpenModal} />
        }
      </Fragment>
    );
  }
}

export default RequestedVideo;
