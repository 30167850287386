import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { groupBy, isEmpty, uniqBy } from 'lodash';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { routes } from '../../lib';
import { UserAvatar } from '../users';
import { Heading, Panel } from '../layout/pages';
import { FavoriteOnDemand } from './favorites';

class FavoriteOnDemandsPage extends React.PureComponent {
  static propTypes = {
    classPacks:        PropTypes.array.isRequired,
    createDiscount:    PropTypes.func.isRequired,
    discount:          PropTypes.object.isRequired,
    favoriteOnDemands: PropTypes.object.isRequired,
    fetchClassPacks:   PropTypes.func.isRequired,
    fetchMemberships:  PropTypes.func.isRequired,
    instructor:        PropTypes.object.isRequired,
    instructorPacks:   PropTypes.array.isRequired,
    loader:            PropTypes.object.isRequired,
    memberships:       PropTypes.array.isRequired,
    restoreDiscount:   PropTypes.func.isRequired,
    subscriptions:     PropTypes.array.isRequired,
    subtractCredit:    PropTypes.func.isRequired,
    unLike:            PropTypes.func.isRequired,
    user:              PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    const onDemandsInstructors = props.favoriteOnDemands.map((favorite) => favorite.instructor);

    return {
      favoritesByInstructor: groupBy(props.favoriteOnDemands, 'instructor.id'),
      instructors:           uniqBy(onDemandsInstructors, 'id')
    };
  }

  state = {
    favoritesByInstructor: [],
    instructors:           []
  }

  renderOnDemand = (instructor) => (
    <div key={instructor.id}>
      <div className='follow'>
        <div>
          <UserAvatar
              className='avatar-border-white ml-4 mr-2'
              size='sm'
              src={instructor.photo && instructor.photo.sm} />

          <span className='name'>{instructor.firstName + instructor.lastName}</span>
        </div>

        <span className='viewProfile mr-2 pointer'>

          {!isEmpty(instructor.domain) ?
            <a
                className='link d-flex align-items-center'
                href={`https://${instructor.domain.host}`}>
              <span>Book Class</span>
              <Icon icon='chevron-right' />
            </a> :
            <Link
                className='link d-flex align-items-center'
                to={routes.INSTRUCTOR(instructor.username)}>
              <span>Book Class</span>
              <Icon icon='chevron-right' />
            </Link>
          }
        </span>
      </div>

      <div className={classNames('d-flex flex-wrap', { 'flex-column': isMobile })}>
        { this.state.favoritesByInstructor[instructor.id].map((requestedClass) => (
          <FavoriteOnDemand
              classPacks={this.props.classPacks}
              createDiscount={this.props.createDiscount}
              discount={this.props.discount}
              fetchClassPacks={this.props.fetchClassPacks}
              fetchMemberships={this.props.fetchMemberships}
              instructor={this.props.instructor}
              instructorPacks={this.props.instructorPacks}
              key={requestedClass.id}
              loader={this.props.loader}
              memberships={this.props.memberships}
              requestedClass={requestedClass}
              restoreDiscount={this.props.restoreDiscount}
              subscriptions={this.props.subscriptions}
              subtractCredit={this.props.subtractCredit}
              unLike={this.props.unLike}
              user={this.props.user} />
          ))}
      </div>
    </div>
  )

  renderEmpty = () => (
    <div className='message-empty'>
      <p>Favorite your first on-demand video to access it here.</p>
    </div>
  );


  render() {
    const { instructors } = this.state;

    return (
      <div className='inner-wrapper'>
        <Container fluid>
          <Helmet>
            <title>Favorite On Demand</title>
          </Helmet>

          <Heading
              color='secondary'
              subtitle='Client Portal'
              title='Favorite On Demand' />

          <Panel>
            {!!instructors.length && instructors.map(this.renderOnDemand)}

            {!instructors.length && this.renderEmpty()}
          </Panel>
        </Container>
      </div>
    );
  }
}

export default FavoriteOnDemandsPage;
