import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { CSVLink } from 'react-csv';

import { tableOptions } from '../../../../constants/transactions';
import { ExportTransactions } from '../../../../lib';

class MembershipsTable extends PureComponent {
  static propTypes = {
    fetchMemberships: PropTypes.func.isRequired,
    isLoading:        PropTypes.bool.isRequired,
    transactions:     PropTypes.array.isRequired,
    updateMembership: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props) {
    return { data: props.transactions };
  }

  componentDidMount () {
    this.props.fetchMemberships();
  }

  state = {
    data: []
  }

  handleSave = (_, note, row) => {
    const id = parseInt(row.id.replace( /^\D+/g, ''));

    this.props.updateMembership({ id, note });
  }

  render () {
    const { isLoading } = this.props;
    const { data } = this.state;

    const options = tableOptions();

    const defaultSorted = [{
      dataField: 'createdAt',
      order:     'desc'
    }];

    const cellEdit = cellEditFactory({
      beforeSaveCell: this.handleSave,
      blurToSave:     true,
      mode:           'click'
    });

    return (
      <React.Fragment>
        <CSVLink
            className='mb-2 text-decoration-none'
            data={ExportTransactions.getMemberships(data)}
            filename='my-membership-sales.csv'>
          Export
        </CSVLink>

        <BootstrapTable
            bootstrap4
            cellEdit={cellEdit}
            columns={options.MEMBERSHIPS}
            data={data}
            defaultSorted={defaultSorted}
            filter={filterFactory()}
            filterPosition='top'
            hover
            keyField='id'
            loading={isLoading}
            pagination={paginationFactory(options.PAGINATION)}
            wrapperClasses='table-responsive' />
      </React.Fragment>
    );
  }
}

export default MembershipsTable;
