import { ProvidersActionTypes } from '../constants/actionTypes';

export default {
  FETCH: () => ({
    type: ProvidersActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: ProvidersActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: ProvidersActionTypes.FETCH_SUCCESS
  })
};
