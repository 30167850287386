export default {
  FETCH:         '@@classPacks/FETCH',
  FETCH_FAILURE: '@@classPacks/FETCH_FAILURE',
  FETCH_SUCCESS: '@@classPacks/FETCH_SUCCESS',

  INSTRUCTORS_CREATE:         '@@classPacks/INSTRUCTORS/CREATE',
  INSTRUCTORS_CREATE_FAILURE: '@@classPacks/INSTRUCTORS/CREATE_FAILURE',
  INSTRUCTORS_CREATE_SUCCESS: '@@classPacks/INSTRUCTORS/CREATE_SUCCESS',

  INSTRUCTORS_DELETE:         '@@classPacks/INSTRUCTORS/CANCEL',
  INSTRUCTORS_DELETE_FAILURE: '@@classPacks/INSTRUCTORS/CANCEL_FAILURE',
  INSTRUCTORS_DELETE_SUCCESS: '@@classPacks/INSTRUCTORS/CANCEL_SUCCESS',

  INSTRUCTORS_FETCH:         '@@classPacks/INSTRUCTORS/FETCH',
  INSTRUCTORS_FETCH_FAILURE: '@@classPacks/INSTRUCTORS/FETCH_FAILURE',
  INSTRUCTORS_FETCH_SUCCESS: '@@classPacks/INSTRUCTORS/FETCH_SUCCESS',

  INSTRUCTORS_GET:         '@@classPacks/INSTRUCTORS/GET',
  INSTRUCTORS_GET_FAILURE: '@@classPacks/INSTRUCTORS/GET_FAILURE',
  INSTRUCTORS_GET_SUCCESS: '@@classPacks/INSTRUCTORS/GET_SUCCESS',

  INSTRUCTORS_UPDATE:         '@@classPacks/INSTRUCTORS/UPDATE',
  INSTRUCTORS_UPDATE_FAILURE: '@@classPacks/INSTRUCTORS/UPDATE_FAILURE',
  INSTRUCTORS_UPDATE_SUCCESS: '@@classPacks/INSTRUCTORS/UPDATE_SUCCESS',

  SUBTRACT:         '@@classPacks/SUBTRACT',
  SUBTRACT_SUCCESS: '@@classPacks/SUBTRACT_SUCCESS',

  USERS_CLEAR: '@@classPacks/USERS/CLEAR',

  USERS_CREATE:         '@@classPacks/USERS/CREATE',
  USERS_CREATE_FAILURE: '@@classPacks/USERS/CREATE_FAILURE',
  USERS_CREATE_SUCCESS: '@@classPacks/USERS/CREATE_SUCCESS',

  USERS_DELETE:         '@@classPacks/USERS/DELETE',
  USERS_DELETE_FAILURE: '@@classPacks/USERS/DELETE_FAILURE',
  USERS_DELETE_SUCCESS: '@@classPacks/USERS/DELETE_SUCCESS',

  USERS_FETCH:         '@@classPacks/USERS/FETCH',
  USERS_FETCH_FAILURE: '@@classPacks/USERS/FETCH_FAILURE',
  USERS_FETCH_SUCCESS: '@@classPacks/USERS/FETCH_SUCCESS',

  USERS_UPDATE:         '@@classPacks/USERS/UPDATE',
  USERS_UPDATE_FAILURE: '@@classPacks/USERS/UPDATE_FAILURE',
  USERS_UPDATE_SUCCESS: '@@classPacks/USERS/UPDATE_SUCCESS'
};
