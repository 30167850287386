import { connect } from 'react-redux';

import { LocationsActions } from '../../actions';
import { LocationModal } from '../../components/modals/instructor';

const mapStateToProps = (state) => ({
  isLoading: state.loader.locations.isLoading,
  location:  state.locations.selected
});

const mapDispatchToProps = (dispatch) => ({
  createLocation: (data) => dispatch(LocationsActions.CREATE(data)),
  deleteLocation: (data) => dispatch(LocationsActions.DELETE(data)),
  updateLocation: (data) => dispatch(LocationsActions.UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationModal);