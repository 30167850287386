/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';

import { routes } from '../../lib';
import { UserAvatar } from '../users';
import { Event } from '../calendar';
import { UpdateCardModal } from '../modals';

class InstructorFragment extends React.PureComponent {
  static propTypes = {
    bookings:         PropTypes.array,
    challenges:       PropTypes.array,
    classPacks:       PropTypes.array,
    instructor:       PropTypes.object.isRequired,
    onModal:          PropTypes.func.isRequired,
    paymentMethod:    PropTypes.object,
    purchasedClasses: PropTypes.array,
    subscriptions:    PropTypes.array,
    updateCard:       PropTypes.func.isRequired,
    user:             PropTypes.object.isRequired
  }

  static defaultProps = {
    bookings:         [],
    challenges:       [],
    classPacks:       [],
    paymentMethod:    {},
    purchasedClasses: [],
    subscriptions:    []
  }

  state = {
    isModalOpen: false
  }

  handleToggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  }

  renderBookings = () => (
    this.props.bookings.map((booking) => (
      <Event
          booking={booking}
          event={booking.event}
          isBooking
          isDashboard
          key={booking.event.type + booking.id}
          layout='dashboard'
          onClick={this.props.onModal}
          user={this.props.user} />
    ))
  )

  renderEmptyBookings = () => (
    <div className='emptyBookings'>
      <p className='text-muted mb-0'>No upcoming classes booked</p>
    </div>
  )

  renderBookLink = () => (
    !isEmpty(this.props.instructor.domain) ?
      <a
          className='link d-flex align-items-center'
          href={`https://${this.props.instructor.domain.host}`}>
        <span>Book Class</span>
        <Icon icon='chevron-right' />
      </a> :
      <Link
          className='link d-flex align-items-center'
          to={routes.INSTRUCTOR(this.props.instructor.username)}>
        <span>Book Class</span>
        <Icon icon='chevron-right' />
      </Link>
  )

  render() {
    const { bookings, classPacks, challenges, instructor, purchasedClasses, subscriptions } = this.props;

    const fullName = instructor.firstName + ' ' + instructor.lastName;
    const hasOnDemands = !!purchasedClasses.length;

    const britProfile = instructor.id == 3385;

    return (
      <React.Fragment>
        <div className='follow'>
          <div className='d-flex align-items-center'>
            <div>
              <UserAvatar
                  className='avatar-border-white ml-4 mr-2'
                  size='sm'
                  src={instructor.photo} />
            </div>

            <div>
              <span className='name'>{fullName}</span>
              <div className='d-flex flex-row flex-wrap'>
                { subscriptions.map((subscription) => (
                  <div
                      className='member-badge mr-2 mb-1'
                      key={subscription.id}
                      onClick={() => this.props.onModal({ ...subscription, type: 'subscription' })}
                      onKeyPress={() => this.props.onModal({ ...subscription, type: 'subscription' })}
                      role='button'
                      tabIndex={0}>
                    Member
                  </div>
                ))}

                { challenges.map((challenge) => (
                  <div
                      className='challenge-badge mr-2 mb-1'
                      key={challenge.id}
                      onClick={() => this.props.onModal({ ...challenge, type: 'challenge' })}
                      onKeyPress={() => this.props.onModal({ ...challenge, type: 'challenge' })}
                      role='button'
                      tabIndex={0}>
                    Challenge
                  </div>
                ))}

                { classPacks.map((classPack) => (
                  <div
                      className='class-pack-badge mr-2 mb-1'
                      key={classPack.id}
                      onClick={() => this.props.onModal({ ...classPack, type: 'classPack' })}
                      onKeyPress={() => this.props.onModal({ ...classPack, type: 'classPack' })}
                      role='button'
                      tabIndex={0}>
                    {classPack.combined + classPack.live + classPack.onDemand + classPack.privateSession} Credits
                  </div>
                ))}

                <div
                    className='bookings-badge mr-2'
                    title='Live Class Bookings'>
                  {instructor.bookingsCount ? instructor.bookingsCount : 0}
                </div>

                <div
                    className='onDemands-badge mr-2'
                    title='On Demand Purchases'>
                  <span>{hasOnDemands ? purchasedClasses.length : 0}</span>
                </div>


                { !isEmpty(this.props.paymentMethod) &&
                  <a
                      href='#'
                      onClick={this.handleToggle}>
                    <small>Update Card</small>
                  </a>
                }
              </div>
            </div>
          </div>

          <span className='viewProfile mr-2 pointer'>
            {!britProfile && this.renderBookLink()}
          </span>
        </div>

        <div>
          {bookings.length ? this.renderBookings() : this.renderEmptyBookings()}
        </div>

        { this.state.isModalOpen &&
          <UpdateCardModal
              instructor={this.props.instructor}
              isOpen={this.state.isModalOpen}
              onToggle={this.handleToggle}
              paymentMethod={this.props.paymentMethod}
              updateCard={this.props.updateCard}
              user={this.props.user} />

        }
      </React.Fragment>
    );
  }
}

export default InstructorFragment;
