import { MembershipsActionTypes } from '../constants/actionTypes';

const initialState = {
  data:           [],
  deletedData:    [],
  form:           {},
  instructorData: [],
  lastAction:     ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case MembershipsActionTypes.CREATE:
      return newState;

    case MembershipsActionTypes.CREATE_SUCCESS:
      return { ...newState, data: [...state.data, action.payload] };

    case MembershipsActionTypes.CREATE_FAILURE:
      return newState;


    case MembershipsActionTypes.CANCEL:
      return newState;

    case MembershipsActionTypes.CANCEL_SUCCESS:
      return { ...newState, data: action.payload, form: {} };

    case MembershipsActionTypes.CANCEL_FAILURE:
      return newState;


    case MembershipsActionTypes.FETCH:
      return newState;

    case MembershipsActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case MembershipsActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case MembershipsActionTypes.DELETED_FETCH:
      return newState;

    case MembershipsActionTypes.DELETED_FETCH_SUCCESS:
      return { ...newState, deletedData: action.payload };

    case MembershipsActionTypes.DELETED_FETCH_FAILURE:
      return { ...newState, deletedData: initialState.data };


    case MembershipsActionTypes.INSTRUCTORS_FETCH:
      return newState;

    case MembershipsActionTypes.INSTRUCTORS_FETCH_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case MembershipsActionTypes.INSTRUCTORS_FETCH_FAILURE:
      return { ...newState, instructorData: initialState.instructorData };


    case MembershipsActionTypes.UPDATE:
      return newState;

    case MembershipsActionTypes.UPDATE_SUCCESS:
      return { ...newState, data: action.payload, form: {} };

    case MembershipsActionTypes.UPDATE_FAILURE:
      return newState;


    case MembershipsActionTypes.INSTRUCTORS_GET:
      return newState;

    case MembershipsActionTypes.INSTRUCTORS_GET_SUCCESS:
      return { ...newState, form: action.payload };

    case MembershipsActionTypes.INSTRUCTORS_GET_FAILURE:
      return newState;


    default: return state;
  }
};
