import React from 'react';
import PropTypes from 'prop-types';

import { templates } from '../../constants';
import ContactsUrban from './contactsComponent/urban';
import ContactsDefault from './contactsComponent/default';
import ContactsElegant from './contactsComponent/elegant';

class ContactsContainer extends React.PureComponent {
  static propTypes = {
    instructor:   PropTypes.object.isRequired,
    siteTemplate: PropTypes.string
  }

  static defaultProps = {
    siteTemplate: 'default'
  }

  render() {
    const { instructor, siteTemplate } = this.props;

    switch (siteTemplate) {
      case templates.ELEGANT: return <ContactsElegant instructor={instructor} />;

      case templates.URBAN: return <ContactsUrban instructor={instructor} />;

      default: return <ContactsDefault instructor={instructor} />;
    }
  }
}

export default ContactsContainer;
