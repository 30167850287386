import React from 'react';
import PropTypes from 'prop-types';
import DateTime from 'react-datetime';

import { Moment } from '../../lib';
import { Input } from '../inputs';

class DatePicker extends React.PureComponent {
  static propTypes = {
    formatDate:  PropTypes.string,
    isDisabled:  PropTypes.bool,
    isInvalid:   PropTypes.bool,
    isValidDate: PropTypes.func,
    name:        PropTypes.string,
    onChange:    PropTypes.func,
    placeholder: PropTypes.string,
    timeZone:    PropTypes.string,
    value:       PropTypes.string
  };

  static defaultProps = {
    formatDate:  'dddd, MMMM D, YYYY',
    isDisabled:  false,
    isInvalid:   false,
    isValidDate: () => {},
    name:        '',
    onChange:    () => {},
    placeholder: '',
    timeZone:    '',
    value:       ''
  };

  handleChange = (value) => {
    const { name } = this.props;
    const formattedValue = new Moment(value).format('YYYY-MM-DD');

    this.props.onChange(formattedValue, name);
  };

  renderInput = (props) => {
    return (
      <Input {...props} />
    );
  }

  render () {
    const { formatDate, name, placeholder, value, isDisabled, isInvalid, timeZone } = this.props;
    const inputProps = { autoComplete: 'off', id: name, isDisabled, isInvalid, name, placeholder };
    const isValidDate = (current) => {
      const yesterday = new Moment().utc().subtract( 1, 'day' );

      return current.isAfter(yesterday);
    };

    return (
      <DateTime
          closeOnSelect
          dateFormat={formatDate}
          displayTimeZone={timeZone}
          inputProps={inputProps}
          isValidDate={isValidDate}
          onChange={this.handleChange}
          renderInput={this.renderInput}
          strictParsing
          timeFormat={false}
          value={new Moment(value)} />
    );
  }
}

export default DatePicker;
