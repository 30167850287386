/* global process */
/* eslint-disable immutable/no-mutation */

import axios from 'axios';
import qs from 'qs';
import { toSnakeCase } from './';

const { API_URL } = process.env;
const { HOST } = process.env;

const hasCustomDomain = window.location.host !== HOST;

const instance = axios.create({
  baseURL:         hasCustomDomain ? '/' : API_URL,
  withCredentials: true
});

export default {
  delete: (path, params = {}) => instance.delete(path, {
                                   params:           toSnakeCase(params),
                                   paramsSerializer: (query) => qs.stringify(query, { arrayFormat: 'comma' })
                                 }),
  get: (path, params = {}) => instance.get(path, {
                                params:           toSnakeCase(params),
                                paramsSerializer: (query) => qs.stringify(query, { arrayFormat: 'comma' })
                              }),
  post: (path, params = {}) => instance.post(path, toSnakeCase(params)),
  put:  (path, params = {}) => instance.put(path, toSnakeCase(params))
};
