/* eslint-disable immutable/no-mutation, immutable/no-let */

import { filter, includes, isEmpty, isNaN } from 'lodash';
import filesizeParser from 'filesize-parser';
import isValidDomain from 'is-valid-domain';
import isURL from 'validator/lib/isURL';

import { RESERVED_NAMES } from '../constants';

class Validator {
  constructor() {
    this.errors = {};
  }

  clear() {
    this.errors = {};
  }

  validate = (validationList = []) => {
    validationList.map((validationItem) => {
      const field = validationItem[0];
      const concept = validationItem[1];
      const args = validationItem[2];
      const shouldSkip = validationItem[3];

      if (shouldSkip) return;

      const error = concept(...args);
      if (error) this.errors[field] = error;
    });

    return this.errors;
  }

  concepts = {
    domainValidator: (domain) => {
      if (!isValidDomain(domain, { subdomain: false })) {
        return 'Not valid domain';
      }
    },

    emailRegex: (email = '') => {
      if (!/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(email)) {
        return 'Invalid email';
      }
    },

    experiencesValidator: (experiences = '') => {
      let errors = [];

      experiences.forEach((experience, index) => {
        let error = {};

        if (experience.id || !isEmpty(experience.from) || !isEmpty(experience.to) || !isEmpty(experience.company)) {
          if (!experience.isCurrent && isEmpty(experience.to)) error = { ...error, to: 'Required' };
          if (isEmpty(experience.from))                        error = { ...error, from: 'Required' };
          if (isEmpty(experience.company))                     error = { ...error, company: 'Required' };

          if (Object.keys(error).length) errors = [...errors, { ...error, index }];
        }
      });

      if (errors.length) return errors;
    },

    feeValidator: (fee, feeType) => {
      if (feeType == 1 && isEmpty(fee)) {
        return 'Required';
      }
    },

    fileSizeValidator: (file, allowedSize) => {
      if (file.size > filesizeParser(allowedSize)) {
        return `${file.name} is too large. Max file size is ${allowedSize}`;
      }
    },

    fileTypeValidator: (file, allowedTypes) => {
      if (!includes(allowedTypes, file.type)) {
        return 'Invalid file type';
      }
    },

    isFilled: (field = '') => {
      if (isEmpty(String(field))) {
        return 'Required';
      }
    },

    isLongerThan: (n, field, fieldName) => {
      if (field.length < n) {
        return `${fieldName} should contain at least ${n} characters`;
      }
    },

    isMatching: (password = '', passwordConfirmation = '') => {
      if (password != passwordConfirmation) {
        return 'Passwords does not match';
      }
    },

    isReserved: (field = '') => {
      if (includes(RESERVED_NAMES, field)) {
        return `'${field}' is a reserved word. Please use something else`;
      }
    },

    isUploaded: (field = null) => {
      if (field === null || isEmpty(String(field))) {
        return 'Required';
      }
    },

    isUrl: (email) => {
      if (!isURL(email, { require_protocol: true })) {
        return 'Must be a valid link with protocol';
      }
    },

    minAmountValidator: (amount) => {
      if (isNaN(amount) || amount < 0.5) {
        return 'Minimal donation - 0.5$';
      }
    },

    moreThan: (field, number) => {
      if (field > number) {
        return 'No more than ' + number;
      }
    },

    moreThanHundred: (field) => {
      if (field > 100) {
        return 'No more than 100';
      }
    },

    passwordRegex: (password = '') => {
      if (!/^\S+$/.test(password)) {
        return 'Password must not contain whitespaces';
      }
    },

    promptsValidator: (prompts) => {
      if (!filter(prompts, 'text').length) {
        return 'All 3 prompts should be selected';
      }
    },

    spaceValidator: (field = '', message) => {
      if (/\s/.test(field)) {
        return message;
      }
    },

    usernameRegex: (username = '') => {
      if (!/^[A-Za-z0-9_-]{3,32}$/.test(username)) {
        return 'Please choose a username with just letters (a-zA-Z), numbers or an underscore';
      }
    },

    weeksValidator: (weeks, isEnabled) => {
      if (isEnabled && isEmpty(weeks)) {
        return 'Required';
      }
    },

    zeroCheck: (number) => {
      if (number == 0) {
        return 'Please enter a positive value';
      }
    }
  }
}

export default new Validator();
