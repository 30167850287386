import React from 'react';
import PropTypes from 'prop-types';
import { Container, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { routes } from '../../../lib';
import { VisibilityIcon } from '../../icons';
import { Heading, Panel } from '../../layout/pages';
import { BackButton } from '../../buttons';
import { OAuthWindow } from '../../oauth';
import { Button } from '../../forms';
import SettingsOption from '../../settings/menuOptionsSection';

class SettingsHomePage extends React.PureComponent {
  static propTypes = {
    fetchUser:     PropTypes.func.isRequired,
    instructor:    PropTypes.object.isRequired,
    oAuthLogout:   PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired,
    user:          PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (props.instructor.homeConfig.id && !state.isMounted) {
      const {
        id,
        showImageWithTextSection,
        showInstagramGallerySection,
        showTestimonialsSection,
        showUpcomingClassesSection
      } = props.instructor.homeConfig;

      return {
        id,
        isMounted: true,
        showImageWithTextSection,
        showInstagramGallerySection,
        showTestimonialsSection,
        showUpcomingClassesSection
      };
    }

    return state;
  }

  state = {
    isMounted:                   false,
    showImageWithTextSection:    false,
    showInstagramGallerySection: false,
    showTestimonialsSection:     false,
    showUpcomingClassesSection:  false
  };

  handleToggle = (setting) => () => {
    this.setState((prevState) => ({
      [setting]: !prevState[setting]
    }), () => {
      this.handleUpdate(setting);
    });
  };

  handleInstagramLogout = () => {
    this.props.oAuthLogout('instagram');
  }

  handleUpdate = (setting) => {
    this.props.updateProfile({
      instructorHomeConfigAttributes: { [setting]: this.state[setting] }
    });
  };

  handleInstagramSuccess = () => {
    this.props.fetchUser();
  };

  render() {
    const { user } = this.props;
    const hasInstagram = !!user.oauth.instagram;

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Home</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container
              className='settings-page'
              fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Home' />
            <Panel className='settings-menu'>
              <span className='menu-title'>Sections</span>

              <SettingsOption
                  route={routes.SETTINGS_HOME_LANDING}
                  title='Landing Section' />

              <SettingsOption
                  onToggle={this.handleToggle('showUpcomingClassesSection')}
                  route={routes.SETTINGS_HOME_CLASSES}
                  title='Upcoming Classes'
                  toggleState={this.state.showUpcomingClassesSection} />

              <SettingsOption
                  onToggle={this.handleToggle('showImageWithTextSection')}
                  route={routes.SETTINGS_HOME_IMAGE_TEXT}
                  title='Image with Text'
                  toggleState={this.state.showImageWithTextSection} />

              <SettingsOption
                  onToggle={this.handleToggle('showTestimonialsSection')}
                  route={routes.SETTINGS_HOME_TESTIMONIALS}
                  title='Testimonials'
                  toggleState={this.state.showTestimonialsSection} />

              <Media className={'menu-link'}>
                <Media left>
                  <Icon
                      fixedWidth
                      icon='pen' />
                </Media>
                <Media body>
                  Instagram Gallery
                </Media>
                <Media
                    className='d-flex'
                    right>
                  { this.handleToggle('showInstagramGallerySection') &&
                    <Button
                        className={`settings-option-toggle ${this.state.showInstagramGallerySection}`}
                        color='link'
                        onClick={this.handleToggle('showInstagramGallerySection')}>
                      <VisibilityIcon
                          id={routes.SETTINGS_HOME_INSTAGRAM}
                          isActive={this.state.showInstagramGallerySection}
                          size={20}
                          title='section' />
                    </Button>
                  }

                  { !hasInstagram && 
                    <div style={{ minWidth: '127px' }}>
                      <OAuthWindow
                          onSuccess={this.handleInstagramSuccess}
                          url={routes.INSTAGRAM_AUTH}>
                        <Button
                            color='primary'
                            isBlock
                            onClick={this.handleInstagramClick}
                            size='sm'>
                          Connect
                        </Button>
                      </OAuthWindow>
                    </div>
                  }

                  { hasInstagram &&
                    <div style={{ minWidth: '127px' }}>
                      <Button
                          color='danger'
                          isBlock
                          onClick={this.handleInstagramLogout}
                          size='sm'>
                        Disconnect
                      </Button>
                    </div>
                  }
                </Media>
              </Media>
              
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SettingsHomePage;
