import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { PurchasedClassesActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions, PurchasedClassesActions } from '../actions';

function* watchFetch() {
  yield takeLatest(PurchasedClassesActionTypes.INSTRUCTORS_FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.purchasedClass.fetch, payload);

      yield put(PurchasedClassesActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PurchasedClassesActions.INSTRUCTORS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(PurchasedClassesActionTypes.INSTRUCTORS_FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(PurchasedClassesActionTypes.INSTRUCTORS_FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchGet() {
  yield takeLatest(PurchasedClassesActionTypes.USERS_GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('purchasedClass'));
      const response = yield call(api.user.purchasedClass.get, payload);

      yield put(PurchasedClassesActions.USERS_GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PurchasedClassesActions.USERS_GET_FAILURE());
      toastr.error('Video Access Expired');
    }
  });
}

function* watchGetSuccess() {
  yield takeLatest(PurchasedClassesActionTypes.USERS_GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('purchasedClass'));
  });
}

function* watchGetFailure() {
  yield takeLatest(PurchasedClassesActionTypes.USERS_GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('purchasedClass'));
  });
}

function* watchUserFetch() {
  yield takeLatest(PurchasedClassesActionTypes.USER_FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.user.purchasedClass.fetch, payload);

      yield put(PurchasedClassesActions.USER_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PurchasedClassesActions.USER_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUserFetchSuccess() {
  yield takeLatest(PurchasedClassesActionTypes.USER_FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUserFetchFailure() {
  yield takeLatest(PurchasedClassesActionTypes.USER_FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchUserUpdate() {
  yield takeLatest(PurchasedClassesActionTypes.USER_UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.user.purchasedClass.update, payload);

      yield put(PurchasedClassesActions.USER_UPDATE_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PurchasedClassesActions.USER_UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* donationClassesSaga() {
  yield all([
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),

    watchGet(),
    watchGetSuccess(),
    watchGetFailure(),

    watchUserFetch(),
    watchUserFetchSuccess(),
    watchUserFetchFailure(),

    watchUserUpdate()
  ]);
}
