import { PrivateGroupClassesActionTypes } from '../constants/actionTypes';

const initialState = {
  data:           [],
  instructorData: [],
  lastAction:     ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case PrivateGroupClassesActionTypes.CREATE:
      return newState;

    case PrivateGroupClassesActionTypes.CREATE_SUCCESS:
      return { ...newState, data: [...state.data, action.payload] };

    case PrivateGroupClassesActionTypes.CREATE_FAILURE:
      return newState;


    case PrivateGroupClassesActionTypes.DELETE:
      return newState;

    case PrivateGroupClassesActionTypes.DELETE_SUCCESS:
      return { ...newState, data: action.payload };

    case PrivateGroupClassesActionTypes.DELETE_FAILURE:
      return newState;


    case PrivateGroupClassesActionTypes.FETCH:
      return newState;

    case PrivateGroupClassesActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case PrivateGroupClassesActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case PrivateGroupClassesActionTypes.INSTRUCTORS_FETCH:
      return newState;

    case PrivateGroupClassesActionTypes.INSTRUCTORS_FETCH_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case PrivateGroupClassesActionTypes.INSTRUCTORS_FETCH_FAILURE:
      return { ...newState, instructorData: initialState.instructorData };


    case PrivateGroupClassesActionTypes.INSTRUCTORS_GET:
      return newState;

    case PrivateGroupClassesActionTypes.INSTRUCTORS_GET_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case PrivateGroupClassesActionTypes.INSTRUCTORS_GET_FAILURE:
      return { ...newState, instructorData: initialState.instructorData };


    case PrivateGroupClassesActionTypes.INSTRUCTORS_UPDATE:
      return newState;

    case PrivateGroupClassesActionTypes.INSTRUCTORS_UPDATE_SUCCESS:
      return { ...newState, instructorsData: action.payload };

    case PrivateGroupClassesActionTypes.INSTRUCTORS_UPDATE_FAILURE:
      return newState;


    case PrivateGroupClassesActionTypes.RECURRING_CREATE:
      return newState;

    case PrivateGroupClassesActionTypes.RECURRING_CREATE_SUCCESS:
      return { ...newState, data: [...state.data, ...action.payload] };

    case PrivateGroupClassesActionTypes.RECURRING_CREATE_FAILURE:
      return newState;


    case PrivateGroupClassesActionTypes.RECURRING_DELETE:
      return newState;

    case PrivateGroupClassesActionTypes.RECURRING_DELETE_SUCCESS:
      return { ...newState, data: action.payload };

    case PrivateGroupClassesActionTypes.RECURRING_DELETE_FAILURE:
      return newState;


    case PrivateGroupClassesActionTypes.UPDATE:
      return newState;

    case PrivateGroupClassesActionTypes.UPDATE_SUCCESS:
      return { ...newState, data: action.payload };

    case PrivateGroupClassesActionTypes.UPDATE_FAILURE:
      return newState;


    case PrivateGroupClassesActionTypes.RECURRING_UPDATE:
      return newState;

    case PrivateGroupClassesActionTypes.RECURRING_UPDATE_SUCCESS:
      return { ...newState, data: action.payload };

    case PrivateGroupClassesActionTypes.RECURRING_UPDATE_FAILURE:
      return newState;

    default: return state;
  }
};
