import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import className from 'classnames';
import { isMobile } from 'react-device-detect';

import { Moment } from '../../lib';
import { Button } from '../forms';
import { routes } from '../../lib';
import { BackButton } from '../buttons';
import { AvailabilityModal } from '../modals/instructor';
import { Heading, Panel } from '../layout/pages';

class AvailabilityPage extends React.PureComponent {
  static propTypes = {
    instructor:         PropTypes.object.isRequired,
    updateAvailability: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const { instructor } = props;

    return {
      ...state,
      availability: instructor.availability || state.availability
    };
  }

  state = {
    availability: {},
    isModalOpen:  false,
    isMounted:    false,
    weekday:      ''
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen
    }));
  };

  handleSelectDay = (weekday) => () => {
    this.setState((prevState) => ({
      ...prevState,
      weekday
    }), ( this.handleToggle ));
  };

  renderWeekday = (weekday) => {
    const subject = weekday.toLowerCase();

    const availability = this.state.availability[subject] || [];

    const availableTime = () => {
      const from = new Moment(availability[0], 'hh:mm');
      const to = new Moment(availability[1], 'hh:mm');

      const datesValid = from.isValid() && to.isValid();

      return (
        <div className={className('time', { 'disabled': !datesValid })}>
          <Icon icon={['far', 'clock']} />
          { datesValid ? `${from.format('hh:mm A')} - ${to.format('hh:mm A')}` : 'Not Available' }
        </div>
      );
    };

    return (
      <div
          className='list-item'
          key={weekday}>
        <div className='weekday-info'>
          <span className='name'>{weekday}</span>

          { availableTime() }
        </div>

        <Button
            onClick={this.handleSelectDay(weekday)}
            size='sm'>
          Edit
        </Button>
      </div>
    );
  };

  render() {
    const { isModalOpen, weekday, availability } = this.state;

    const week = Moment.weekdays();
    const tweakedWeek = [...week.slice(1), week[0]];

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS_WORKOUT} />}

        <Helmet>
          <title>Weekly Availability</title>
        </Helmet>
        <div className='inner-wrapper portal availability'>
          <Container
              className='availability-page'
              fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Weekly Availability' />
            <Panel className='portal-list weekdays-list'>
              {tweakedWeek.map(this.renderWeekday)}
            </Panel>
          </Container>
        </div>

        <AvailabilityModal
            availability={availability}
            isOpen={isModalOpen}
            onToggle={this.handleToggle}
            selectedDay={weekday}
            updateAvailability={this.props.updateAvailability} />
      </React.Fragment>
    );
  }
}

export default AvailabilityPage;
