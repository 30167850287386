import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'reactstrap';
import { eq } from 'lodash';

import { Button } from '../../forms';
import { StripeHelper } from '../../../lib';

class Plan extends React.PureComponent {
  static propTypes = {
    createSubscription: PropTypes.func.isRequired,
    plan:               PropTypes.object.isRequired,
    stripe:             PropTypes.object,
    subscription:       PropTypes.object.isRequired
  }

  static defaultProps = {
    stripe: {}
  }

  static getDerivedStateFromProps(props) {
    return {
      isDefault:    props.plan.default,
      isFree:       eq(props.plan.price, 0),
      isOwner:      eq(props.subscription.planId, props.plan.id),
      isUpgradable: props.plan.priority > props.subscription.priority
    };
  }

  state = {
    isOwner:      false,
    isUpgradable: false
  }

  handleSubscribe = () => {
    const { plan, stripe } = this.props;
    const updateState = this.setState.bind(this);

    if (this.state.isFree) {
      this.props.createSubscription({ method: 'free', planId: plan.id });
    } else {
      StripeHelper.createCheckoutSession({ planId: plan.id }, updateState, (session) => (
        stripe.redirectToCheckout({ sessionId: session.id })
      ));
    }
  }

  renderFeatures = () => {
    const renderDefault = () => (
      <React.Fragment>
        <li>Class bookings & payments</li>
        <li>Offer memberships</li>
        <li>Offer class packs</li>
        <li>Sell on-demand content</li>
        <li>Automated emails</li>
      </React.Fragment>
    );

    const renderList = (plan) => (
      <React.Fragment>
        {plan.customDomain     && <li>Custom domain</li>}
        {plan.websiteTemplates && <li>Website templates</li>}
        {plan.customColors     && <li>Customize style</li>}
        {plan.advancedPackages && <li>Advanced packages</li>}
      </React.Fragment>
    );

    return (
      this.state.isDefault
          ? renderDefault()
          : renderList(this.props.plan)
    );
  }

  renderLimits = (plan) => (
    <React.Fragment>
      <li>Unlimited bookings</li>
      <li>Unlimited members</li>
      {plan.isUnlimited && <li>Unlimted on-demand</li>}
      {!plan.isUnlimited && <li>{plan.storageLimit}GB on-demand</li>}
    </React.Fragment>
  )

  renderPrice = () => (
    this.state.isFree
      ? 'FREE'
      : this.props.plan.price.toLocaleString('en-US', { currency: 'USD', style: 'currency' }) + '/mo'
  )

  render() {
    const { plan } = this.props;
    const { isDefault, isOwner, isUpgradable } = this.state;

    return (
      <Card
          body
          className='plan mt-2'>
        <div className='plan__title'>
          {plan.name}

          <div className='plan__price'>
            {this.renderPrice()}
          </div>
        </div>

        <Row className='plan__description mt-3'>
          <Col
              md={6}
              xs={6}>
            <div className='plan__label'>
              {isDefault ? 'Features' : 'Everything in Starter, plus'}
            </div>

            <ul className='plan__list'>
              {this.renderFeatures()}
            </ul>
          </Col>

          <Col
              md={6}
              xs={6}>
            <div className='plan__label'>
              Platform Limits
            </div>

            <ul className='plan__list'>
              {this.renderLimits(plan)}
            </ul>
          </Col>
        </Row>

        <div className='plan__actions mt-3'>
          { isOwner &&
            <Button
                className='plan__action'
                color='white'
                isBlock
                isDisabled
                type='button'>
              Your Plan
            </Button>
          }

          { !isOwner &&
            <Button
                className='plan__action'
                color='blue'
                isBlock
                onClick={this.handleSubscribe}
                type='button'>
              {isUpgradable ? 'Upgrade' : 'Downgrade'}
            </Button>
          }
        </div>
      </Card>
    );
  }
}

export default Plan;
