/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';
import { Collapse } from 'reactstrap';
import { isMobile } from 'react-device-detect';

import { DayTimes, Heading, WeekDays } from '../';
import { Day } from '../selected';
import { Calendr } from '../../../lib';
import { calendarOptions } from '../../../constants';

class WeekView extends React.PureComponent {
  static propTypes = {
    children:         PropTypes.node,
    events:           PropTypes.array.isRequired,
    isCalendarOpen:   PropTypes.bool.isRequired,
    isPlanner:        PropTypes.bool.isRequired,
    onCalendarToggle: PropTypes.func.isRequired,
    onEventClick:     PropTypes.func.isRequired,
    plannerTitle:     PropTypes.string.isRequired,
    selectedDate:     PropTypes.object.isRequired,
    todayDate:        PropTypes.object.isRequired,
    user:             PropTypes.object.isRequired
  };

  static defaultProps = {
    children: null
  };

  scrollRef = React.createRef();

  static getDerivedStateFromProps(props) {
    const range = Calendr.getRange(props.selectedDate, props.user.timeZone, 'week');

    return {
      dayTimes: Calendr.getDayTimes(props.selectedDate, props.user.timeZone),
      events:   Calendr.eventsInRange(props.events, props.user.timeZone, range),
      weekDays: Calendr.getWeekdays(range)
    };
  }

  state = {
    dayTimes:   [],
    events:     [],
    isScrolled: false,
    weekDays:   []
  };

  componentDidMount () {
    const setScrolled = () => {
      this.setState({ isScrolled: true }, () => (
        this.scrollRef.current.onscroll = null
      ));
    };

    this.scrollRef.current.scrollTop = calendarOptions.DAY_VIEW.eventHeight * 7;
    this.scrollRef.current.onscroll = setScrolled;
  }

  componentDidUpdate() {
    // could not solve w/ context
    //
    if (!this.state.isScrolled) {
      isMobile
        ? window.scroll({ behavior: 'smooth', top: calendarOptions.DAY_VIEW.eventHeight * 7 })
        : this.scrollRef.current.scrollTop = calendarOptions.DAY_VIEW.eventHeight * 7;
    }
  }

  render () {
    const { children, isPlanner, isCalendarOpen, onCalendarToggle, plannerTitle, selectedDate } = this.props;
    const { events, weekDays } = this.state;

    return (
      <div className={className('calendar-wrapper', 'week-view', { 'planner': isPlanner })}>
        { isPlanner &&
          <WeekDays
              days={this.state.weekDays}
              hasIcon
              today={this.props.todayDate} />
        }
        {children && React.cloneElement(children, { selectedDate, selectedView: 'week' })}
        <div
            className='scroll-area'
            ref={this.scrollRef}>
          <div className='label'>
            <Collapse isOpen={isCalendarOpen}>
              <DayTimes
                  times={this.state.dayTimes}
                  user={this.props.user} />
            </Collapse>
          </div>
          <div className='content'>
            { !isPlanner &&
            <WeekDays
                days={this.state.weekDays}
                today={this.props.todayDate} />
            }
            { isPlanner &&
            <Heading
                isOpen={isCalendarOpen}
                onClick={onCalendarToggle}
                title={plannerTitle} />
            }
            <Collapse isOpen={isCalendarOpen}>
              <div className='weekdays'>
                { weekDays.map((day) => (
                  <Day
                      date={day}
                      events={events}
                      key={day.format('DD_MM')}
                      onEventClick={this.props.onEventClick}
                      user={this.props.user} />
                ))}
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}

export default WeekView;
