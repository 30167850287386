/* global process */
import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';

import { InstructorMarkers, LocationMarkers } from './';

const { GMAPS_API_KEY } = process.env;

class SearchMap extends React.PureComponent {
  static propTypes = {
    center:      PropTypes.object,
    className:   PropTypes.string,
    id:          PropTypes.string,
    instructors: PropTypes.object.isRequired,
    locations:   PropTypes.object.isRequired,
    zoom:        PropTypes.number
  }

  static defaultProps = {
    center: {
      lat: 37.77,
      lng: -122.43
    },
    className: '',
    id:        '',
    zoom:      8
  };

  static getDerivedStateFromProps(props) {
    return {
      instructors: props.instructors.data,
      locations:   props.locations.data
    };
  }

  state = {
    google:      {},
    instructors: [],
    isApiReady:  false,
    locations:   []
  }

  handleApiLoaded = (google) => {
    this.setState({
      google,
      isApiReady: true
    }, () => {
      google.map.setOptions({
        maxZoom: 14
      });
    });
  }

  renderMarkers = () => {
    const { instructors, locations } = this.state;

    if (instructors.length) {
      return (
        <InstructorMarkers
            google={this.state.google}
            instructors={instructors} />
      );
    }

    if (locations.length) {
      return (
        <LocationMarkers
            google={this.state.google}
            locations={locations} />
      );
    }
  }

  render() {
    return (
      <div
          className={this.props.className}
          id={this.props.id}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: GMAPS_API_KEY }}
            defaultCenter={this.props.center}
            defaultZoom={this.props.zoom}
            onGoogleApiLoaded={this.handleApiLoaded}
            yesIWantToUseGoogleMapApiInternals>
        </GoogleMapReact>
        {this.state.isApiReady && this.renderMarkers()}
      </div>
    );
  }
}

export default SearchMap;
