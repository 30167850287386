import { connect } from 'react-redux';

import { SearchActions } from '../../../../actions';
import SearchBar from './searchBar';

const mapStateToProps = (state) => ({
  search: state.search
});

const mapDispatchToProps = (dispatch) => ({
  performSearch: (data) => dispatch(SearchActions.SEARCH(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);