import { DonationBookingsActionTypes } from '../constants/actionTypes';

const initialState = {
  instructorData: [],
  lastAction:     ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case DonationBookingsActionTypes.INSTRUCTORS_FETCH:
      return newState;

    case DonationBookingsActionTypes.INSTRUCTORS_FETCH_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case DonationBookingsActionTypes.INSTRUCTORS_FETCH_FAILURE:
      return newState;

    default: return state;
  }
};
