import { SessionActionTypes } from '../constants/actionTypes';

export default {
  CLEAR: () => ({
    type: SessionActionTypes.CLEAR
  }),

  JOIN_NOW: (payload) => ({
    payload,
    type: SessionActionTypes.JOIN_NOW
  }),

  LOGOUT: () => ({
    type: SessionActionTypes.LOGOUT
  }),

  LOGOUT_FAILURE: () => ({
    type: SessionActionTypes.LOGOUT_FAILURE
  }),

  LOGOUT_SUCCESS: () => ({
    type: SessionActionTypes.LOGOUT_SUCCESS
  }),

  RESTORE_SESSION: () => ({
    type: SessionActionTypes.RESTORE_SESSION
  }),

  RESTORE_SESSION_FAILURE: (payload) => ({
    payload,
    type: SessionActionTypes.RESTORE_SESSION_FAILURE
  }),

  RESTORE_SESSION_SUCCESS: () => ({
    type: SessionActionTypes.RESTORE_SESSION_SUCCESS
  }),

  SIGN_IN: (payload) => ({
    payload,
    type: SessionActionTypes.SIGN_IN
  }),

  SIGN_IN_FAILURE: () => ({
    type: SessionActionTypes.SIGN_IN_FAILURE
  }),

  SIGN_IN_SUCCESS: (payload) => ({
    payload,
    type: SessionActionTypes.SIGN_IN_SUCCESS
  }),

  SIGN_UP: (payload) => ({
    payload,
    type: SessionActionTypes.SIGN_UP
  }),

  SIGN_UP_FAILURE: () => ({
    type: SessionActionTypes.SIGN_UP_FAILURE
  }),

  SIGN_UP_SUCCESS: (payload) => ({
    payload,
    type: SessionActionTypes.SIGN_UP_SUCCESS
  })
};
