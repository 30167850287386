import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { Container, Label, Media } from 'reactstrap';
import { withRouter } from 'react-router';
import filesizeParser from 'filesize-parser';
import { groupBy, isInteger, map, some } from 'lodash';
import classNames from 'classnames';

import { BackButton } from '../../../components/buttons';
import { Button, Checkbox } from '../../../components/forms';
import { Heading, Panel } from '../../../components/layout/pages';
import { routes } from '../../../lib';
import { EditCollection, RequestedClass, RequestedVideo } from '../../../components/onDemands';
import MainButton from '../../../components/pages/portal/menu/mainButton';
import { requiresProfile } from '../../../helpers';
import { FormatOnDemandModal } from '../../../components/modals';

class Collection extends PureComponent {
  static propTypes = {
    collection:               PropTypes.object.isRequired,
    createFormats:            PropTypes.func.isRequired,
    createRecording:          PropTypes.func.isRequired,
    deleteCollection:         PropTypes.func.isRequired,
    fetchFolders:             PropTypes.func.isRequired,
    fetchFormats:             PropTypes.func.isRequired,
    fetchOnDemands:           PropTypes.func.isRequired,
    fetchPrivateGroupClasses: PropTypes.func.isRequired,
    fetchRecordings:          PropTypes.func.isRequired,
    folders:                  PropTypes.array.isRequired,
    formats:                  PropTypes.array.isRequired,
    getCollection:            PropTypes.func.isRequired,
    getSubscription:          PropTypes.func.isRequired,
    isClassesLoading:         PropTypes.bool,
    isLoading:                PropTypes.bool.isRequired,
    match:                    PropTypes.object.isRequired,
    onDemands:                PropTypes.array.isRequired,
    plan:                     PropTypes.object.isRequired,
    recording:                PropTypes.object.isRequired,
    recordings:               PropTypes.array.isRequired,
    storageLimit:             PropTypes.number,
    subscription:             PropTypes.object.isRequired,
    updateCollection:         PropTypes.func.isRequired,
    usedStorage:              PropTypes.number,
    user:                     PropTypes.object.isRequired
  }

  static defaultProps = {
    isClassesLoading: true,
    storageLimit:     0,
    usedStorage:      0
  }

  static getDerivedStateFromProps(props) {
    const { folders } = props;

    const hasFreeSpace = props.usedStorage < filesizeParser(`${props.storageLimit}GB`);

    const groupByFolder = groupBy(props.onDemands, 'folderId');

    return { folders, groupByFolder, hasFreeSpace };
  }

  state = {
    hasFreeSpace:         true,
    isEditCollectionOpen: false,
    isFormatModalOpen:    false,
    isNewVideoOpen:       false,
    onDemands:            []
  }

  componentDidMount () {
    const { id } = this.props.match.params;

    this.props.getSubscription();
    this.props.getCollection({ id });
    this.props.fetchFolders({ id });
    this.props.fetchOnDemands({ id });
    this.props.fetchPrivateGroupClasses();
    this.props.fetchFormats();

    const formatIds = map(this.props.onDemands, 'formatId');
    const hasBlankFormats = some(formatIds, (formatId) => !isInteger(formatId));

    if (hasBlankFormats) this.handleFormatModal();
  }

  handleFormatModal = () => (
    this.setState((prevState) => ({
      isFormatModalOpen: !prevState.isFormatModalOpen
    }))
  )

  handleUpdate = (data) => {
    const { match } = this.props;
    const { id } = match.params;

    const collection = {
      id: id,
      ...data
    };

    this.props.updateCollection({ collection });
  }

  handleDelete = () => {
    const { match } = this.props;
    const { id } = match.params;

    this.props.deleteCollection({ id });
  }

  handleActive = () => {
    this.handleUpdate({ isActive: !this.props.collection.isActive});
  }

  handleToggle = (modal) => () => {
    this.setState({
      [modal]: !this.state[modal]
    });
  }

  renderEmpty = () => (
    <div className='message-empty bg'>
      <p>You have not yet uploaded any on demand classes. </p>

      <p>
        You can add an on demand class by using
        the <span className='text-primary'>+</span> button below
      </p>
    </div>
  )

  render () {
    const { collection, formats, user, plan, isClassesLoading, onDemands } = this.props;
    const { folders, groupByFolder, isEditCollectionOpen, isNewVideoOpen, hasFreeSpace } = this.state;
    const { timeZone, currency } = user;

    const canAddVideo = hasFreeSpace || plan.isUnlimited;

    const hasFolders = folders.length > 1;

    return (
      <div className='inner-wrapper portal'>
        <Helmet>
          <title>{collection.name}</title>
        </Helmet>

        {isMobile && <BackButton to={routes.ON_DEMAND} />}

        <Container fluid>
          <Heading
              color='secondary'
              subtitle='On Demand Library'
              title={collection.name} />

          <Panel
              className='settings-menu'
              isLoading={isClassesLoading}>
            <div className='collection-edit'>
              <Media className='menu-link d-block border-0'>
                <Media body>
                  <div className='custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='is-active'
                        isChecked={collection.isActive}
                        name='collectionIsActive'
                        onChange={this.handleActive} />
                    <Label
                        className='custom-control-label d-flex'
                        htmlFor='input-is-active'>
                      Make this collection visible
                    </Label>
                  </div>
                </Media>
              </Media>

              <Button
                  isOutline
                  onClick={this.handleToggle('isEditCollectionOpen')}
                  size='sm'>
                Edit Settings
              </Button>
            </div>


            <div className='collection-header'>
              <span className='menu-title mt-0 pl-2'>Videos</span>
            </div>

            <div className='requested-classes'>
              {!onDemands.length && this.renderEmpty()}

              {!!onDemands.length && hasFolders && folders.map((folder, i) => (
                <React.Fragment key={i}>
                  <span
                      className={classNames('w-100 form-collapse-toggle')}
                      href='#'>
                    <span className='text-primary'>
                      {folder.name}
                    </span>
                  </span>

                  {groupByFolder[folder.id] && groupByFolder[folder.id].map((onDemand) => (
                    <RequestedVideo
                        currency={currency}
                        data={onDemand}
                        folders={folders}
                        formats={formats}
                        key={onDemand.id}
                        timeZone={timeZone} />
                  ))}
                </React.Fragment>
              )) }

              {!!onDemands.length && !hasFolders && onDemands.map((onDemand) => (
                <RequestedVideo
                    currency={currency}
                    data={onDemand}
                    formats={formats}
                    key={onDemand.id}
                    timeZone={timeZone} />
                ))}
            </div>
          </Panel>

          <MainButton
              isDisabled={!canAddVideo}
              onClick={this.handleToggle('isNewVideoOpen')} />
        </Container>

        { this.state.isFormatModalOpen &&
          <FormatOnDemandModal
              createFormats={this.props.createFormats}
              formats={this.props.formats}
              isOpen={this.state.isFormatModalOpen}
              onDemands={this.props.onDemands}
              onToggle={this.handleFormatModal} />
        }

        { isEditCollectionOpen &&
          <EditCollection
              collection={collection}
              isOpen={isEditCollectionOpen}
              onDelete={this.handleDelete}
              onSubmit={this.handleUpdate}
              onToggle={this.handleToggle('isEditCollectionOpen')}
              subscription={this.props.subscription} />
        }

        <RequestedClass
            createRecording={this.props.createRecording}
            currency={currency}
            fetchRecordings={this.props.fetchRecordings}
            folders={folders}
            formats={this.props.formats}
            isLoading={this.props.isLoading}
            isOpen={isNewVideoOpen}
            onOpenAlert={this.handleOpenAlert}
            onToggle={this.handleToggle('isNewVideoOpen')}
            recording={this.props.recording}
            recordings={this.props.recordings}
            user={this.props.user} />
      </div>
    );
  }
}

export default requiresProfile(withRouter(Collection));
