/* global process */

/*
 * IMPORTANT!
 *
 * When adding routes, do not forget to add them to 'reservedUsernames' arrays, so users
 * wont be able to take those, since we are using root route for instructor profiles
 * (onpodio.com/:username)
 *
 */

const routes = {
  ADD_PROFILE:                '/profile/create',
  AVAILABILITY:               '/private_sessions/availability',
  CALENDAR:                   '/calendar',
  CLIENTS:                    '/clients',
  CLOSE:                      '/success',
  CONTACT:                    '/contact',
  COUPONS:                    '/coupons',
  DASHBOARD:                  '/dashboard',
  EDIT_ACCOUNT:               '/account/edit',
  EDIT_PROFILE:               '/settings/home/basic',
  FACEBOOK_AUTH:              process.env.FACEBOOK_AUTH_URL,
  FAVORITE_ON_DEMANDS:        '/favorite/on_demand',
  GOOGLE_AUTH:                process.env.GOOGLE_AUTH_URL,
  HELP:                       'https://help.onpodio.com',
  HOME:                       process.env.HOME_URL,
  INSTAGRAM:                  '/profile/instagram',
  INSTAGRAM_AUTH:             process.env.INSTAGRAM_AUTH_URL,
  INSTRUCTOR:                 (username) => `/${username}`,
  INSTRUCTOR_CLASS_PLANNER:   '/planner/instructors',
  INSTRUCTOR_DASHBOARD:       '/profile/dashboard',
  INTEGRATIONS:               '/settings/integrations',
  LOCATION:                   (locationId) => `/studios/${locationId}`,
  LOCATIONS:                  '/settings/home/locations',
  LOCATION_CLASS_PLANNER:     '/planner/studios',
  LOGIN:                      '/login',
  MANAGE_FOLLOWS:             '/follows',
  MARKETING:                  '/marketing',
  NOT_FOUND:                  '/404',
  ON_DEMAND:                  '/on_demand',
  PACKAGES:                   '/settings/packages/set',
  PLANS_AND_BILLING:          '/settings/billing',
  PLAYLISTS:                  '/profile/playlists',
  PRIVACY:                    '/privacy',
  PRIVATE_SESSIONS:           '/private_sessions',
  PROFILE:                    '/profile',
  PURCHASES:                  '/purchases',
  RECOVER_PASSWORD:           '/password/recover',
  REDIRECT:                   '/redirect',
  REQUESTED_CLASSES:          '/settings/classes-on-demand',
  RESET_PASSWORD:             '/password/reset',
  ROOT:                       '/',
  SEARCH:                     '/search',
  SESSION_TYPES:              '/private_sessions/types',
  SETTINGS:                   '/settings',
  SETTINGS_ABOUT:             '/settings/about',
  SETTINGS_CANCELLATION:      '/settings/cancellation-policy',
  SETTINGS_CUSTOMIZATION:     '/settings/customize',
  SETTINGS_DOMAIN:            '/settings/domain',
  SETTINGS_HOME:              '/settings/home',
  SETTINGS_HOME_CLASSES:      '/settings/home/classes',
  SETTINGS_HOME_IMAGE_TEXT:   '/settings/home/image-text',
  SETTINGS_HOME_INSTAGRAM:    '/settings/home/instagram',
  SETTINGS_HOME_LANDING:      '/settings/home/landing',
  SETTINGS_HOME_TESTIMONIALS: '/settings/home/testimonials',
  SETTINGS_NOTIFICATIONS:     '/settings/notifications',
  SETTINGS_PACKAGES:          '/settings/packages',
  SETTINGS_STYLE:             '/settings/style',
  SETTINGS_WIDGETS:           '/settings/widgets',
  SETTINGS_WORKOUT:           '/settings/workout',
  SIGNUP:                     '/signup',
  SPOTIFY_AUTH:               process.env.SPOTIFY_AUTH_URL,
  STORY_CREATE:               '/schedule/story/new',
  STRIPE_AUTH:                process.env.STRIPE_AUTH_URL,
  SUBSCRIBE:                  '/subscribe',
  SUPPORT:                    '/support',
  TERMS:                      '/terms',
  TRANSACTIONS:               '/transactions',
  TYPE_FORM:                  '//onpodio.typeform.com/to/xmIDYx',
  VIDEO_PLAYER:               (uuid) => `/v/${uuid}`,
  WIN_BACK:                   '/marketing/win-back-sequence',
  ZOOM_AUTH:                  process.env.ZOOM_AUTH_URL
};

export const instructorRoutes = {
  ABOUT:    { index: 4, path: '/about' },
  HOME:     { index: 0, path: '/' },
  PACKAGES: { index: 2, path: '/packages'},
  STYLE:    { index: 3, path: '/style'},
  WORKOUT:  { index: 1, path: '/workout'}
};

export const onDemandRoutes = {
  COLLECTION: (id) => `/${id}`,
  INDEX:      '/'
};

export const settingsRoutes = {
  HOME:    '/',
  WIDGETS: '/widgets'
};

export const clientsRoutes = {
  CLIENT: (id) => `/${id}`,
  INDEX:  '/'
};

export default routes;
