import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import Select from 'react-select';
import { isMobile } from 'react-device-detect';
import Draggable from 'react-draggable';

import { Autocompleter, ValueContainer } from '../../forms';
import { SearchActionTypes } from '../../../constants/actionTypes';
import { selectFieldStyle } from '../../../constants';
import { AutocompleterContainer } from '../../../containers';

const searchSubjects = {
  INSTRUCTOR: 'instructors',
  LOCATION:   'locations'
};

class Filters extends React.PureComponent {
  static propTypes = {
    activities:  PropTypes.object.isRequired,
    onChange:    PropTypes.func.isRequired,
    profileData: PropTypes.object.isRequired,
    search:      PropTypes.object.isRequired,
    subject:     PropTypes.string.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const activityOptions = props.activities.data.map((activity) => ({
      label: activity.title,
      value: activity.id
    }));

    const subject = props.subject;
    const filterOffset = isMobile && subject === searchSubjects.INSTRUCTOR ? -(window.innerWidth / 3.5) : 0;
    const styleTags = props.profileData.data.styleTags || [];

    const styleTagOptions = styleTags.map((styleTag) => ({
      label: styleTag.name,
      value: styleTag.id
    }));


    if (props.search.lastAction == SearchActionTypes.SEARCH) {
      return {
        ...state,
        activities: [],
        location:   '',
        locations:  [],
        styleTags:  []
      };
    }

    return { activityOptions, filterOffset, styleTagOptions };
  }

  state = {
    activities:      [],
    activityOptions: [],
    filterOffset:    0,
    isOpen:          !isMobile,
    location:        '',
    locations:       [],
    styleTagOptions: [],
    styleTags:       []
  }

  handleActivitySelect = (options) => {
    const activityIds = options ? options.map((option) => option.value) : [];

    this.setState({ activities: options }, () => {
      this.props.onChange(activityIds, 'activityIds');
    });
  }

  handleLocationSelect = (options) => {
    const locationIds = options ? options.map((option) => option.value) : [];

    this.setState({ locations: options }, () => {
      this.props.onChange(locationIds, 'locationIds');
    });
  }

  handleStyleTagSelect = (options) => {
    const styleTagIds = options ? options.map((option) => option.value) : [];

    this.setState({ styleTags: options }, () => {
      this.props.onChange(styleTagIds, 'styleTagIds');
    });
  }

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value });
  }

  handleToggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  renderFields = () => {
    const locationAutocompleter = (
      <AutocompleterContainer>
        <Autocompleter
            className='filter'
            hasInitialValue
            isDisabled
            isMulti
            name='location'
            onChange={this.handleChange}
            onSelect={this.handleLocationSelect}
            placeholder='Studio'
            subject='locations'
            truncate={2}
            value={this.state.location} />
      </AutocompleterContainer>
    );

    const activitySelect = (
      <Select
          className='filter'
          components={{ ValueContainer }}
          isMulti
          onChange={this.handleActivitySelect}
          options={this.state.activityOptions}
          placeholder='Activity'
          styles={selectFieldStyle.default}
          truncate={2}
          value={this.state.activities} />
    );

    const styleSelect = (
      <Select
          className='filter'
          components={{ ValueContainer }}
          isMulti
          onChange={this.handleStyleTagSelect}
          options={this.state.styleTagOptions}
          placeholder='Instructor style'
          styles={selectFieldStyle.default}
          truncate={2}
          value={this.state.styleTags} />
    );


    if (this.props.subject == searchSubjects.LOCATION) {
      return (
        <Row>
          <Col xs='6'>
            {locationAutocompleter}
          </Col>

          <Col xs='6'>
            {activitySelect}
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col xs='4'>
            {locationAutocompleter}
          </Col>

          <Col xs='4'>
            {activitySelect}
          </Col>

          <Col xs='4'>
            {styleSelect}
          </Col>
        </Row>
      );
    }
  }

  render() {
    return (
      <div className={'results-filters ' + this.props.subject}>
        <Draggable
            axis="x"
            bounds={{ left: this.state.filterOffset, right: 0 }}
            cancel='.css-1hwfws3'
            defaultPosition={{ x: 0, y: 0 }}
            grid={[1, 1]}
            handle=".filters-form">
          <form
              action="#"
              className='filters-form' >
            {this.renderFields()}
          </form>
        </Draggable>
      </div>
    );
  }
}

export default Filters;
