import { push } from 'connected-react-router';
import { connect } from 'react-redux';

import {
  ApplicationActions,
  AvailabilityActions,
  BookingsActions,
  ChallengesActions,
  ClassPacksActions,
  DiscountActions,
  FavoriteOnDemandsActions,
  InstructorActions,
  MembershipsActions,
  MembershipsDiscountActions,
  PrivateBookingsActions,
  PrivateGroupClassesActions,
  PrivateSessionsActions,
  ProfileDataActions,
  RequestedClassesActions,
  SubscriptionsActions
} from '../../actions';
import InstructorPage from './instructorPage';

const mapStateToProps = (state) => ({
  availability:        state.availability,
  challenges:          state.challenges.data,
  classPacks:          state.classPacks.data,
  discount:            state.discount.data,
  error:               state.error,
  groupClasses:        state.groupClasses.instructorData,
  hasCustomDomain:     state.app.hasCustomDomain,
  instructor:          state.instructor.data,
  memberships:         state.memberships.instructorData,
  membershipsDiscount: state.membershipsDiscount.data,
  privateGroupClasses: state.privateGroupClasses.instructorData,
  profile:             state.profile,
  profileData:         state.profileData,
  session:             state.session,
  subscriptions:       state.subscriptions.data,
  tracks:              state.tracks,
  user:                state.user.data,
  userChallenges:      state.challenges.userData,
  userClassPacks:      state.classPacks.userData,
  username:            state.app.currentUsername
});

const mapDispatchToProps = (dispatch) => ({
  claimInstructor:           (data) =>  dispatch(InstructorActions.CLAIM(data)),
  createBooking:             (data) =>  dispatch(BookingsActions.CREATE(data)),
  createChallenge:           (data) =>  dispatch(ChallengesActions.USERS_CREATE(data)),
  createClassPack:           (data) =>  dispatch(ClassPacksActions.USERS_CREATE(data)),
  createDiscount:            (data) =>  dispatch(DiscountActions.CREATE(data)),
  createMembershipDiscount:  (data) =>  dispatch(MembershipsDiscountActions.CREATE(data)),
  createPrivateBooking:      (data) =>  dispatch(PrivateBookingsActions.CREATE(data)),
  createPrivateSession:      (data) =>  dispatch(PrivateSessionsActions.CREATE(data)),
  createSubscription:        (data) =>  dispatch(SubscriptionsActions.CREATE_SUCCESS(data)),
  fetchChallenges:           (data) =>  dispatch(ChallengesActions.FETCH(data)),
  fetchClassPacks:           (data) =>  dispatch(ClassPacksActions.FETCH(data)),
  fetchFavoriteOnDemands:    ()     =>  dispatch(FavoriteOnDemandsActions.FETCH()),
  fetchMemberships:          (data) =>  dispatch(MembershipsActions.INSTRUCTORS_FETCH(data)),
  fetchRequestedClasses:     (data) =>  dispatch(RequestedClassesActions.INSTRUCTORS_FETCH(data)),
  fetchSubscriptions:        () =>      dispatch(SubscriptionsActions.FETCH()),
  fetchUserChallenges:       () =>      dispatch(ChallengesActions.USERS_FETCH()),
  fetchUserClassPacks:       () =>      dispatch(ClassPacksActions.USERS_FETCH()),
  follow:                    () =>      dispatch(InstructorActions.FOLLOW()),
  getAvailability:           (data) =>  dispatch(AvailabilityActions.GET(data)),
  getInstructor:             (data) =>  dispatch(InstructorActions.GET(data)),
  getPrivateGroupClasses:    (data) =>  dispatch(PrivateGroupClassesActions.INSTRUCTORS_GET(data)),
  getProfileData:            () =>      dispatch(ProfileDataActions.GET()),
  goTo:                      (route) => dispatch(push(route)),
  hideSidebar:               () =>      dispatch(ApplicationActions.SIDEBAR_HIDE()),
  restoreDiscount:           () =>      dispatch(DiscountActions.RESTORE()),
  restoreMembershipDiscount: () =>      dispatch(MembershipsDiscountActions.RESTORE()),
  setInstructor:             (data) =>  dispatch(ApplicationActions.SET_INSTRUCTOR(data)),
  subtractCredit:            (data) => dispatch(ClassPacksActions.SUBTRACT(data)),
  unFollow:                  () =>      dispatch(InstructorActions.UNFOLLOW())
});

export default connect(mapStateToProps, mapDispatchToProps)(InstructorPage);
