import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import Header from '../components/headerUrban';

class AboutElegant extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired
  }

  render() {
    const { instructor } = this.props;

    return (
      <React.Fragment>
        <Header
            instructor={instructor}
            subtitle='about' />

        <div className='about template-elegant'>
          <div className='about-bio'>{instructor.bio}</div>

          <div className='about-main'>
            { !isEmpty(instructor.experiences) &&
              <div className='about-work'>
                <h3 className="page-title">work experience</h3>

                { instructor.experiences.map((experience) => (
                  <div
                      className='about-block'
                      key={experience.id}>
                    <div className='about-block-heading'>
                      {experience.company}
                    </div>
                    <div className='about-block-subheading'>
                      {experience.from} - {experience.isCurrent ? 'Current' : experience.to}
                    </div>
                  </div>
                ))}
              </div>
            }

            { !isEmpty(instructor.educations) &&
            <div className='about-education'>
              <h3 className="page-title">education</h3>

              { instructor.educations.map((education) => (
                <div
                    className='about-block'
                    key={education.id}>
                  <div className="about-block-heading">
                    {education.name}
                  </div>
                </div>
                  ))}
            </div>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default AboutElegant;
