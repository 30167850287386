import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label} from 'reactstrap';

import { Checkbox, ColorSwitch, Select } from '../forms';
import { storyMakerOptions } from '../../constants';

class FontTypes extends React.PureComponent {
  static propTypes = {
    color:    PropTypes.string.isRequired,
    font:     PropTypes.string.isRequired,
    isBold:   PropTypes.bool.isRequired,
    isItalic: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired
  }

  state = {
    bold:   false,
    color:  '#000000',
    font:   'Poppins',
    italic: false
  }

  componentDidMount() {
    const { color, font, isBold, isItalic } = this.props;

    this.setState({ bold: isBold, color, font, italic: isItalic });
  }

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value }, () => {
      this.props.onChange({ [inputName]: value });
    });
  }

  render() {
    const formatOptionLabel = ({ value, label }) => (
      <span style={{ fontFamily: value }}>{label}</span>
    );

    return (
      <div className='story-form'>
        <FormGroup
            className='font-style align-items-center'
            row>
          <Label xs={2}>Font</Label>
          <Col
              className='d-flex justify-content-end'
              xs={10}>
            <Select
                formatOptionLabel={formatOptionLabel}
                isSearchable={false}
                menuPlacement='top'
                name='font'
                onChange={this.handleChange}
                options={storyMakerOptions.FONT_FAMILIES.map((font) => ({ label: font, value: font }))}
                styles={storyMakerOptions.FONT_SELECT_STYLE}
                value={this.state.font} />
            <FormGroup
                check
                className='cell'
                inline>
              <Checkbox
                  id='bold'
                  isChecked={this.state.bold}
                  name='bold'
                  onChange={this.handleChange} />
              <Label
                  check
                  className='font-weight-bold'
                  htmlFor='input-bold'>B</Label>
            </FormGroup>
            <FormGroup
                check
                className='cell mr-0'
                inline>
              <Checkbox
                  id='italic'
                  isChecked={this.state.italic}
                  name='italic'
                  onChange={this.handleChange} />
              <Label
                  check
                  className='font-italic'
                  htmlFor='input-italic'>i</Label>
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup
            className='font-color align-items-center mt-3'
            row>
          <Label xs={2}>Color</Label>
          <Col
              className='d-flex justify-content-end'
              xs={10}>
            <ColorSwitch
                colors={storyMakerOptions.COLORS}
                colorsCount={9}
                name='color'
                onChange={this.handleChange}
                value={this.state.color} />
          </Col>
        </FormGroup>
      </div>
    );
  }
}

export default FontTypes;
