import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { findIndex } from 'lodash';

import { Tags } from '../';

class Activities extends React.PureComponent {
  static propTypes = {
    activities: PropTypes.object.isRequired,
    children:   PropTypes.node.isRequired,
    className:  PropTypes.string.isRequired,
    onChange:   PropTypes.func.isRequired,
    profile:    PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      ids: props.profile.activities.map((activity) => activity.id)
    };
  }

  state = {
    ids: []
  }

  handleChange = (option, name) => {
    const { activities } = this.props.profile;

    const index = findIndex(activities, ['id', option.id]);
    if (index > -1) {
      this.props.onChange(
        [...activities.slice(0, index), ...activities.slice(index + 1, activities.length)],
        name
      );
    } else {
      this.props.onChange(
        [...activities, option],
        name
      );
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.children}

        <Row>
          <Col>
            <Tags
                name='activities'
                onChange={this.handleChange}
                options={this.props.activities.data}
                selectedIds={this.state.ids} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default Activities;
