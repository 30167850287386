import { MeetingActionTypes } from '../constants/actionTypes';

export default {
  DELETE: (payload) => ({
    payload, type: MeetingActionTypes.DELETE
  }),

  DELETE_FAILURE: () => ({
    type: MeetingActionTypes.DELETE_FAILURE
  }),

  DELETE_SUCCESS: () => ({
    type: MeetingActionTypes.DELETE_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: MeetingActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: MeetingActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: () => ({
    type: MeetingActionTypes.UPDATE_SUCCESS
  })
};
