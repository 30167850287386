import { toastr } from 'react-redux-toastr';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter, includes, map, reject } from 'lodash';

import { api, extractError, toCamelCase } from '../lib';
import { GroupClassesActionTypes } from '../constants/actionTypes';
import { ErrorActions, GroupClassesActions, LoaderActions } from '../actions';

function* watchCreate() {
  yield takeLatest(GroupClassesActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.groupClasses.create, payload);

      yield put(GroupClassesActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Event added');
    } catch (error) {
      yield put(GroupClassesActions.CREATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(GroupClassesActionTypes.CREATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(GroupClassesActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDelete() {
  yield takeLatest(GroupClassesActionTypes.DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.groupClasses.delete, payload);

      const groupClasses = yield select((state) => state.groupClasses);
      const data = filter(groupClasses.data, (groupClass) => groupClass.id != payload.groupClass.id);

      yield put(GroupClassesActions.DELETE_SUCCESS(data));
      toastr.success('Event deleted');
    } catch (error) {
      yield put(GroupClassesActions.DELETE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDeleteSuccess() {
  yield takeLatest(GroupClassesActionTypes.DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDeleteFailure() {
  yield takeLatest(GroupClassesActionTypes.DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetch() {
  yield takeLatest(GroupClassesActionTypes.FETCH, function* () {
    try {
      const response = yield call(api.groupClasses.fetch);

      yield put(GroupClassesActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(GroupClassesActions.FETCH_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsFetch() {
  yield takeLatest(GroupClassesActionTypes.INSTRUCTORS_FETCH, function* () {
    try {
      const response = yield call(api.instructor.groupClasses.fetch);

      yield put(GroupClassesActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(GroupClassesActions.INSTRUCTORS_FETCH_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsGet() {
  yield takeLatest(GroupClassesActionTypes.INSTRUCTORS_GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('groupClasses'));
      const response = yield call(api.instructor.groupClasses.get, payload);

      yield put(GroupClassesActions.INSTRUCTORS_GET_SUCCESS(toCamelCase(response.data)));
      yield put(LoaderActions.FINISH_FIELD_LOADING('groupClasses'));
    } catch (error) {
      yield put(GroupClassesActions.INSTRUCTORS_GET_FAILURE());
    }
  });
}

function* watchLocationsFetch() {
  yield takeLatest(GroupClassesActionTypes.LOCATIONS_FETCH, function* () {
    try {
      const response = yield call(api.location.groupClasses.fetch);

      yield put(GroupClassesActions.LOCATIONS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(GroupClassesActions.LOCATIONS_FETCH_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchLocationsGet() {
  yield takeLatest(GroupClassesActionTypes.LOCATIONS_GET, function* ({ payload }) {
    try {
      const response = yield call(api.location.groupClasses.get, payload);

      yield put(GroupClassesActions.LOCATIONS_GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(GroupClassesActions.LOCATIONS_GET_FAILURE());
    }
  });
}

function* watchRecurringCreate() {
  yield takeLatest(GroupClassesActionTypes.RECURRING_CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.groupClasses.recurring.create, payload);

      yield put(GroupClassesActions.RECURRING_CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Event added');
    } catch (error) {
      yield put(GroupClassesActions.RECURRING_CREATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchRecurringCreateSuccess() {
  yield takeLatest(GroupClassesActionTypes.RECURRING_CREATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchRecurringCreateFailure() {
  yield takeLatest(GroupClassesActionTypes.RECURRING_CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchRecurringDelete() {
  yield takeLatest(GroupClassesActionTypes.RECURRING_DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.groupClasses.recurring.delete, payload);

      const groupClasses = yield select((state) => state.groupClasses);
      const deletedIds = map(response.data, 'id');
      const data = reject(groupClasses.data, (groupClass) => includes(deletedIds, groupClass.id));

      yield put(GroupClassesActions.DELETE_SUCCESS(data));
      toastr.success('Event deleted');
    } catch (error) {
      yield put(GroupClassesActions.DELETE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchRecurringDeleteSuccess() {
  yield takeLatest(GroupClassesActionTypes.RECURRING_DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchRecurringDeleteFailure() {
  yield takeLatest(GroupClassesActionTypes.DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdate() {
  yield takeLatest(GroupClassesActionTypes.UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.groupClasses.update, payload);

      const groupClasses = yield select((state) => state.groupClasses.data);
      const data = map(groupClasses, (groupClass) =>
        (groupClass.id == payload.groupClass.id ? toCamelCase(response.data) : groupClass));

      yield put(GroupClassesActions.UPDATE_SUCCESS(data));
      toastr.success('Event updated');
    } catch (error) {
      yield put(GroupClassesActions.UPDATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(GroupClassesActionTypes.UPDATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateFailure() {
  yield takeLatest(GroupClassesActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* groupClassesSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),
    watchDelete(),
    watchDeleteSuccess(),
    watchDeleteFailure(),
    watchFetch(),
    watchInstructorsFetch(),
    watchInstructorsGet(),
    watchLocationsFetch(),
    watchLocationsGet(),
    watchRecurringCreate(),
    watchRecurringCreateSuccess(),
    watchRecurringCreateFailure(),
    watchRecurringDelete(),
    watchRecurringDeleteSuccess(),
    watchRecurringDeleteFailure(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure()
  ]);
}
