import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../components/forms';

class SessionType extends React.PureComponent {
  static propTypes = {
    onEdit:      PropTypes.func.isRequired,
    sessionType: PropTypes.object.isRequired,
    user:        PropTypes.object.isRequired
  }

  render() {
    const { sessionType, user } = this.props;
    const { currency } = user;

    return (
      <div className='row'>
        <span className='column'>
          Name:
          <span className='value'>
            {sessionType.name}
          </span>
        </span>

        <span className='column'>
          Price:
          <span className='coupon-code'>
            <span className='class-pack-name'>
              {sessionType.price.toLocaleString('en-GB', { currency, style: 'currency' })}
            </span>
          </span>
        </span>

        <span className='column'>
          Length:
          <span className='value'>
            {sessionType.duration} mins
          </span>
        </span>

        <div className='column button-wrapper'>
          <Button
              className='button'
              color='primary'
              isOutline
              onClick={this.props.onEdit(sessionType.id)}
              size='sm'>
            Edit Type
          </Button>
        </div>
      </div>
    );
  }
}

export default SessionType;
