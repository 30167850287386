import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { AdditionalInformationTypes } from '../constants/actionTypes';
import { AdditionalInformationActions, ErrorActions, LoaderActions, SegmentActions } from '../actions';

function* watchCreate() {
  yield takeLatest(AdditionalInformationTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.additionalInformation.create, payload);

      yield put(AdditionalInformationActions.CREATE_SUCCESS(toCamelCase(response.data)));
      yield put(SegmentActions.ADDED_ADDITIONAL_INFO(toCamelCase(response.data)));
      toastr.success('Additional Information added');
    } catch (error) {
      yield put(AdditionalInformationActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(AdditionalInformationTypes.CREATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(AdditionalInformationTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* additionalInfoSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure()
  ]);
}
