import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

class IconButton extends React.PureComponent {
  static propTypes = {
    className:     PropTypes.string,
    icon:          PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    isDisabled:    PropTypes.bool,
    isFontAwesome: PropTypes.bool,
    onClick:       PropTypes.func,
    size:          PropTypes.number
  }

  static defaultProps = {
    className:     '',
    isDisabled:    false,
    isFontAwesome: false,
    onClick:       () => {},
    size:          18
  }

  render () {
    const { isFontAwesome, icon } = this.props;

    return (
      <button
          className={classNames('btn-icon-kit', this.props.className)}
          disabled={this.props.isDisabled}
          onClick={this.props.onClick}>
        { isFontAwesome 
          ? <FontAwesomeIcon icon={icon} />
          : <Icon
              icon={this.props.icon}
              size={this.props.size} /> }
      </button>
    );
  }
}

export default IconButton;
