import { connect } from 'react-redux';

import {
  BookingsActions,
  ClassPacksActions,
  DiscountActions,
  GroupClassesActions,
  MembershipsActions,
  PrivateBookingsActions,
  PrivateGroupClassesActions
} from '../../actions';
import { UpcomingClasses } from '../../components/pages/profile/instructor';

const mapStateToProps = (state) => ({
  challenges:          state.challenges.userData,
  classPacks:          state.classPacks.userData,
  discount:            state.discount.data,
  groupClasses:        state.groupClasses.instructorData,
  instructor:          state.instructor.data,
  instructorPacks:     state.classPacks.data,
  loader:              state.loader,
  memberships:         state.memberships.instructorData,
  privateBookings:     state.privateBookings.data,
  privateGroupClasses: state.privateGroupClasses.instructorData,
  subscriptions:       state.subscriptions.data,
  user:                state.user.data,
  username:            state.app.currentUsername
});

const mapDispatchToProps = (dispatch) => ({
  createBooking:          (data) => dispatch(BookingsActions.CREATE(data)),
  createDiscount:         (data) => dispatch(DiscountActions.CREATE(data)),
  createPrivateBooking:   (data) => dispatch(PrivateBookingsActions.CREATE(data)),
  fetchClassPacks:        (data) => dispatch(ClassPacksActions.FETCH(data)),
  fetchMemberships:       (data) => dispatch(MembershipsActions.INSTRUCTORS_FETCH(data)),
  fetchPrivateBookings:   ()     => dispatch(PrivateBookingsActions.FETCH()),
  getGroupClasses:        (data) => dispatch(GroupClassesActions.INSTRUCTORS_GET(data)),
  getPrivateGroupClasses: (data) => dispatch(PrivateGroupClassesActions.INSTRUCTORS_GET(data)),
  restoreDiscount:        ()     => dispatch(DiscountActions.RESTORE())
});

export default connect(mapStateToProps, mapDispatchToProps)(UpcomingClasses);
