import { ValidationActionTypes } from '../constants/actionTypes';

const initialState = {
  coupon:     { error: '', valid: false },
  instructor: { error: '', valid: true },
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case ValidationActionTypes.VALIDATE:
      return { ...newState };

    case ValidationActionTypes.VALIDATE_SUCCESS:
      return { ...newState, [action.meta.subject]: { error: '', valid: true } };

    case ValidationActionTypes.VALIDATE_FAILURE:
      return { ...newState, [action.meta.subject]: { error: action.payload.message[0], valid: false } };

    case ValidationActionTypes.RESET:
      return { ...newState, [action.meta.subject]: { error: '', valid: true } };

    default: return state;
  }
};
