import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { InstructorDashboardPage } from '../components/pages';
import { requiresProfile } from '../helpers';

import {
  BookingRequestsActions,
  BookingsActions,
  ClassPacksActions,
  CollectionActions,
  FormatsActions,
  GroupClassesActions,
  MembershipsActions,
  MilestonesActions,
  PlaylistActions,
  PrivateBookingsActions,
  PrivateGroupClassesActions,
  PrivateSessionsActions,
  ProfileActions,
  SegmentActions,
  TimeBlocksActions,
  TransactionsActions,
  UserActions
} from '../actions';

class InstructorDashboardContainer extends React.PureComponent {
  static propTypes = {
    fetchBookingRequests:     PropTypes.func.isRequired,
    fetchBookings:            PropTypes.func.isRequired,
    fetchClassPack:           PropTypes.func.isRequired,
    fetchDeletedMemberships:  PropTypes.func.isRequired,
    fetchGroupClasses:        PropTypes.func.isRequired,
    fetchMemberships:         PropTypes.func.isRequired,
    fetchMilestones:          PropTypes.func.isRequired,
    fetchOnDemands:           PropTypes.func.isRequired,
    fetchPrivateBookings:     PropTypes.func.isRequired,
    fetchPrivateGroupClasses: PropTypes.func.isRequired,
    fetchPrivateSessions:     PropTypes.func.isRequired,
    fetchSummary:             PropTypes.func.isRequired,
    fetchTimeBlocks:          PropTypes.func.isRequired,
    instructor:               PropTypes.object.isRequired,
    user:                     PropTypes.object.isRequired
  }

  componentDidMount() {
    const { username } = this.props.instructor;

    this.props.fetchBookingRequests();
    this.props.fetchBookings();
    this.props.fetchGroupClasses();
    this.props.fetchMemberships();
    this.props.fetchClassPack();
    this.props.fetchOnDemands();
    this.props.fetchPrivateGroupClasses();
    this.props.fetchPrivateSessions();
    this.props.fetchDeletedMemberships();
    this.props.fetchTimeBlocks({ username });
    this.props.fetchPrivateBookings();
    this.props.fetchMilestones();
    this.props.fetchSummary();
  }

  render() {
    return <InstructorDashboardPage {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  bookingRequests:    state.bookingRequests.instructorData,
  bookings:           state.bookings.instructorData,
  classPacks:         state.classPacks.instructorData,
  deletedMemberships: state.memberships.deletedData,
  folders:            state.collections.folders,
  formats:            state.formats.data,
  groupClasses:       state.groupClasses.data,
  instructor:         state.profile.data,

  isLoading: {
    milestones: state.loader.milestones.isLoading,
    summary:    state.loader.summary.isLoading
  },

  memberships:         state.memberships.data,
  milestones:          state.milestones.data,
  playlists:           state.playlists.data,
  privateBookings:     state.privateBookings.instructorData,
  privateGroupClasses: state.privateGroupClasses.data,
  privateSessions:     state.privateSessions.instructorData,
  requestedClasses:    state.collections.allOnDemands,
  summary:             state.transactions.summary,
  timeBlocks:          state.timeBlocks.data,
  user:                state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  acceptBookingRequest:             (data) => dispatch(BookingRequestsActions.ACCEPT(data)),
  createPrivateBookings:            (data) => dispatch(PrivateBookingsActions.INSTRUCTORS_CREATE(data)),
  declineBookingRequest:            (data) => dispatch(BookingRequestsActions.DECLINE(data)),
  deleteGroupClass:                 (data) => dispatch(GroupClassesActions.DELETE(data)),
  deletePrivateBooking:             (data) => dispatch(PrivateBookingsActions.INSTRUCTORS_DELETE(data)),
  deletePrivateGroupClass:          (data) => dispatch(PrivateGroupClassesActions.DELETE(data)),
  deletePrivateSession:             (data) => dispatch(PrivateSessionsActions.INSTRUCTORS_DELETE(data)),
  deleteRecurringGroupClass:        (data) => dispatch(GroupClassesActions.RECURRING_DELETE(data)),
  deleteRecurringPrivateGroupClass: (data) => dispatch(PrivateGroupClassesActions.RECURRING_DELETE(data)),
  deleteRecurringPrivateSession:    (data) => dispatch(PrivateSessionsActions.RECURRING_DELETE(data)),
  fetchBookingRequests:             () =>     dispatch(BookingRequestsActions.INSTRUCTORS_FETCH()),
  fetchBookings:                    () =>     dispatch(BookingsActions.INSTRUCTORS_FETCH()),
  fetchClassPack:                   () =>     dispatch(ClassPacksActions.INSTRUCTORS_FETCH()),
  fetchDeletedMemberships:          () =>     dispatch(MembershipsActions.DELETED_FETCH()),
  fetchFolders:                     (data) => dispatch(CollectionActions.FOLDER_FETCH(data)),
  fetchFormats:                     ()     => dispatch(FormatsActions.FETCH()),
  fetchGroupClasses:                () =>     dispatch(GroupClassesActions.FETCH()),
  fetchMemberships:                 () =>     dispatch(MembershipsActions.FETCH()),
  fetchMilestones:                  () =>     dispatch(MilestonesActions.FETCH()),
  fetchOnDemands:                   () =>     dispatch(CollectionActions.ON_DEMANDS_FETCH()),
  fetchPlaylists:                   () =>     dispatch(PlaylistActions.FETCH()),
  fetchPrivateBookings:             () =>     dispatch(PrivateBookingsActions.INSTRUCTORS_FETCH()),
  fetchPrivateGroupClasses:         () =>     dispatch(PrivateGroupClassesActions.FETCH()),
  fetchPrivateSessions:             () =>     dispatch(PrivateSessionsActions.INSTRUCTORS_FETCH()),
  fetchSummary:                     () =>     dispatch(TransactionsActions.SUMMARY_FETCH()),
  fetchTimeBlocks:                  (data) => dispatch(TimeBlocksActions.FETCH(data)),
  fetchUser:                        () =>     dispatch(UserActions.FETCH()),
  segmentSiteShare:                 () =>     dispatch(SegmentActions.SITE_SHARE()),
  segmentStripe:                    (data) => dispatch(SegmentActions.STRIPE_CONNECT(data)),
  segmentZoom:                      (data) => dispatch(SegmentActions.ZOOM_CONNECT(data)),
  updateGroupClass:                 (data) => dispatch(GroupClassesActions.UPDATE(data)),
  updatePrivateSession:             (data) => dispatch(BookingRequestsActions.INSTRUCTORS_UPDATE(data)),
  updateProfile:                    (data) => dispatch(ProfileActions.UPDATE(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(InstructorDashboardContainer);
