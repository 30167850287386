import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../helpers';
import { SessionTypesPage } from '../components/pages';
import { PrivateSessionTypesActions } from '../actions';

class SessionTypesContainer extends React.PureComponent {
  static propTypes = {
    fetchSessionTypes: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.fetchSessionTypes();
  }

  render() {
    return (
      <SessionTypesPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  sessionTypes: state.privateSessionTypes.data,
  user:         state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchSessionTypes: ()     => dispatch(PrivateSessionTypesActions.FETCH()),
  getSessionType:    (data) => dispatch(PrivateSessionTypesActions.GET(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(SessionTypesContainer);
