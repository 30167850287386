import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { chunk } from 'lodash';
import { ic_first_page } from 'react-icons-kit/md/ic_first_page';
import { ic_chevron_left } from 'react-icons-kit/md/ic_chevron_left';
import { ic_chevron_right } from 'react-icons-kit/md/ic_chevron_right';
import { ic_last_page } from 'react-icons-kit/md/ic_last_page';

import { Playlist } from '../pages/profile';
import { IconButton } from '../buttons';

class SelectPlaylistModal extends React.PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
    isOpen:    PropTypes.bool.isRequired,
    onToggle:  PropTypes.func.isRequired,
    playlists: PropTypes.array,
    update:    PropTypes.func.isRequired
  };

  static defaultProps = {
    isLoading: false,
    playlists: []
  };

  static getDerivedStateFromProps(props) {
    const chuckSize = isMobile ? 6 : 10;

    return {
      isLoading: props.isLoading,
      playlists: chunk(props.playlists, chuckSize)
    };
  }

  state = {
    playlists:    [],
    selectedPage: 1
  };

  handleChangePage = (value) => () => {
    this.setState((prevState) => ({
      selectedPage: prevState.selectedPage + value
    }));
  }

  handleFirst = () => {
    this.setState({
      selectedPage: 1
    });
  }

  handleLast = () => {
    this.setState({
      selectedPage: this.state.playlists.length
    });
  }

  handleUpdate = (id, name) => () => {
    const playlist = { name: name, playlistId: id };
    this.props.update({ playlist });
    this.props.onToggle();
  };
  
  renderPlaylists = () => {
    const { playlists, selectedPage } = this.state;
    if (playlists.length) {
      return playlists[selectedPage - 1].map((playlist) => (
        <Playlist
            data={playlist}
            key={playlist.id}
            onClick={this.handleUpdate(playlist.id, playlist.name)} />
      ));
    } else {
      return (
        <div className='m-3'>
          It appears you don&apos;t have any <strong>public</strong> playlists.
          Make sure your playlists are public in order to select and connect it to your profile.
        </div>
      );
    }
  }

  render () {
    const { isOpen, onToggle } = this.props;

    return (
      <Modal
          className='modal-playlist-select'
          isOpen={isOpen}
          toggle={onToggle}>
        <ModalHeader toggle={onToggle}>
          Select playlist
        </ModalHeader>

        <ModalBody>
          <div className='playlists-wrapper'>
            {this.renderPlaylists()}
          </div>
          { this.state.playlists.length > 1 &&
            <div className='pagination'>
              <IconButton
                  icon={ic_first_page}
                  isDisabled={this.state.selectedPage === 1}
                  onClick={this.handleFirst}
                  size={32} />
              <IconButton
                  icon={ic_chevron_left}
                  isDisabled={this.state.selectedPage === 1}
                  onClick={this.handleChangePage(-1)}
                  size={32} />
              <div className='pages'>
                {this.state.selectedPage} / {this.state.playlists.length}
              </div>
              <IconButton
                  icon={ic_chevron_right}
                  isDisabled={this.state.selectedPage === this.state.playlists.length}
                  onClick={this.handleChangePage(1)}
                  size={32} />
              <IconButton
                  icon={ic_last_page}
                  isDisabled={this.state.selectedPage === this.state.playlists.length}
                  onClick={this.handleLast}
                  size={32} />
            </div>
          }
        </ModalBody>
      </Modal>
    );
  }
}

export default SelectPlaylistModal;