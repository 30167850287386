/* eslint-disable immutable/no-let */
import { orderBy } from 'lodash';

import { Moment, getTimeZone } from './';

class DateHelper {
  getDate = (value, timeZone) => (
    Moment.utc(value, 'YYYY-MM-DDhh:mm:ssUTC').tz(getTimeZone(timeZone))
  )

  getDateFormatLL = (value) => (
    new Moment(value, 'YYYY-MM-DDhh:mm:ss').format('dddd | DD MMM ')
  )

  checkDST = (field, timeZone) => (
    new Moment.tz(field, 'YYYY-MM-DDhh:mm:ss', getTimeZone(timeZone)).isDST()
  )

 summerTimeCheck = (event, timeZone) => {
    if (!event) return '';

    const createdAt = this.checkDST(event.createdAt, timeZone);
    const startsAt = this.checkDST(event.startsAt, timeZone);
    const recurring = this.checkDST(event.recurrenceStartsAt, timeZone);

    if (event.recurring && createdAt && !startsAt) {
      return new Moment.utc(event.startsAt, 'YYYY-MM-DDhh:mm:ss').format();
    } else if (event.recurring && !createdAt && startsAt && !recurring) {
      return new Moment.utc(event.startsAt, 'YYYY-MM-DDhh:mm:ss').format();
    } else if (event.recurring && startsAt && recurring) {
      return new Moment.utc(event.startsAt, 'YYYY-MM-DDhh:mm:ss').format();
    } else {
      return  Moment.utc(event.startsAt, 'YYYY-MM-DDhh:mm:ss').format();
    }
  }

  initialTime = (event, timeZone) => {
    const createdAt = this.checkDST(event.createdAt, timeZone);
    const startsAt = this.checkDST(event.startsAt, timeZone);

    let recurring;
    if (event.recurring && event.recurrence) {
      const firstEvent = orderBy(event.recurrence.events, (recurring) => (
        new Moment(recurring.startsAt, 'YYYY-MM-DDhh:mm:ss'))
      );
      recurring = this.checkDST(firstEvent.startsAt, timeZone);
    }

    if (event.recurring && createdAt && !startsAt) {
      return new Moment.utc(event.startsAt, 'YYYY-MM-DDhh:mm:ss').format();
    } else if (event.recurring && !createdAt && startsAt && !recurring) {
      return new Moment.utc(event.startsAt, 'YYYY-MM-DDhh:mm:ss').format('YYYY-MM-DDhh:mm:ssZ');
    } else if (event.recurring && startsAt && recurring) {
      return new Moment.utc(event.startsAt, 'YYYY-MM-DDhh:mm:ss').format();
    } else {
      return  Moment.utc(event.startsAt, 'YYYY-MM-DDhh:mm:ss').format();
    }
  }
}

export default new DateHelper;
