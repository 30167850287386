import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Link } from '../../inputs';
import { routes } from '../../../lib';
import { LogoWhite } from '../../../images';

class Footer extends React.PureComponent {
  render() {
    return (
      <footer>
        <Container>
          <Row>
            <Col
                lg={2}
                sm={12}
                xl={3}>
              <Link
                  className='footer-logo'
                  to={routes.HOME}>
                <img
                    alt='onPodio'
                    className='img-fluid'
                    src={LogoWhite} />
              </Link>
            </Col>
            <Col
                lg={3}
                sm={6}
                xl={3}>
              <div className='footer-block'>
                <h6>Company</h6>
                <ul>
                  <li>
                    <Link to={routes.TERMS}>Terms of Use</Link>
                  </li>
                  <li>
                    <Link to={routes.PRIVACY}>Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col
                lg={3}
                sm={6}
                xl={2}>
              <div className='footer-block'>
                <h6>Support</h6>
                <ul>
                  <li>
                    <Link to={routes.CONTACT}>Contact</Link>
                  </li>
                  <li>
                    <a href={routes.HELP}>Help Center</a>
                  </li>
                </ul>
              </div>
            </Col>
            <Col
                lg={4}
                sm={12}
                xl={4}>
              <div className='footer-block'>
                <ul className='social-links mt-3'>
                  <li>
                    <a
                        href='//linkedin.com/company/onpodio'
                        rel='noopener noreferrer'
                        target='_blank'>
                      <Icon
                          fixedWidth
                          icon={['fab', 'linkedin-in']}
                          size='2x' />
                    </a>
                  </li>
                  <li>
                    <a
                        href='//facebook.com/onpodio'
                        rel='noopener noreferrer'
                        target='_blank'>
                      <Icon
                          fixedWidth
                          icon={['fab', 'facebook-f']}
                          size='2x' />
                    </a>
                  </li>
                  <li>
                    <a
                        href='//instagram.com/onpodio'
                        rel='noopener noreferrer'
                        target='_blank'>
                      <Icon
                          fixedWidth
                          icon={['fab', 'instagram']}
                          size='2x' />
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
