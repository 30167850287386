import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';
import { capitalize, padStart } from 'lodash';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';

import { StripeBadge } from '../../badges';
import { Input } from '../../inputs';
import { Button, ChargeForm, Checkbox, PaymentIcon, Radio } from '../../forms';
import { StripeHelper, api, routes, toCamelCase } from '../../../lib';
import { CLASS_PACK_STEPS, FEES } from '../../../constants';


class Payment extends React.PureComponent {
  static propTypes = {
    classPack:       PropTypes.object.isRequired,
    createClassPack: PropTypes.func.isRequired,
    createDiscount:  PropTypes.func.isRequired,
    discount:        PropTypes.object,
    instructor:      PropTypes.object.isRequired,
    isLoading:       PropTypes.bool.isRequired,
    onChangeStep:    PropTypes.func.isRequired,
    onLoading:       PropTypes.func.isRequired,
    user:            PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    return {
      amount:     props.classPack.cost,
      clientName: props.user.firstName + ' ' + props.user.lastName,

      data: {
        ...state.data,
        canExpire:             props.classPack.canExpire,
        expiry:                props.classPack.expiry,
        instructorClassPackId: props.classPack.id,
        instructorId:          props.instructor.id
      },
      fee: FEES.charge(props.classPack.cost)
    };
  }

  static defaultProps = {
    discount: {}
  }

  state = {
    amount:         '',
    cards:          [],
    clientName:     '',
    code:           '',
    data:           {},
    error:          {},
    openInput:      false,
    selectedCard:   '',
    shouldSaveCard: false
  }

  componentDidMount() {
    this.props.onLoading(true, () => (
      api.card.get({ username: this.props.instructor.username })
        .then((response) => (
          this.setState({
            cards:        response.data,
            selectedCard: response.data[0] || 'new'
          }, () => this.props.onLoading(false))
        ))
        .catch(() => (
          this.setState({ selectedCard: 'new' }, () => this.props.onLoading(false))
        ))
    ));
  }

  handleApply = () => {
    const { code } = this.state;
    const { user, instructor } = this.props;

    this.props.createDiscount({ discount: {
      clientId:     user.id,
      code,
      instructorId: instructor.id,
      target:       'classPack'
    }});

    this.setState({ code: '', openInput: false });
  }

  handleChange = (value) => (
    this.setState({ shouldSaveCard: value })
  )

  handleOption = (card) => () => (
    this.setState({ selectedCard: card })
  )

  handleChangeInput = (value) => (
    this.setState({ code: value })
  )

  handleToggle = () => (
    this.setState((prevState) => ({ openInput: !prevState.openInput }))
  )

  handleSubmit = (elements, stripe) => async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const { discount } = this.props;

    const isDiscounted = discount.id;
    const { coupon } = discount;

    isDiscounted && coupon.discountType == 'free' ? this.freeSystem() : this.paidSystem(elements, stripe);
  }

  paidSystem = (elements, stripe) => {
    const { clientName, selectedCard } = this.state;
    const { classPack, discount, instructor, onLoading, user } = this.props;

    const description = `${clientName} purchased class pack ${classPack.name}`;

    const params = {
      chargable:   'Instructor::ClassPack',
      chargableId: classPack.id,
      charges:     'cost',
      description,
      discountId:  discount.id,
      hold:        false,
      recipientId: instructor.id
    };

    const card = elements.getElement(CardElement);

    if (this.state.selectedCard == 'new') {
      if (this.state.cards.length || !this.state.shouldSaveCard) {
        StripeHelper.createPaymentIntent(stripe, card, params, 'default', onLoading, this.setState.bind(this), () => {
          const { data } = this.state;

          StripeHelper.confirmPayment(stripe, card, data, user, onLoading, this.setState.bind(this), () => {
            this.props.createClassPack(toCamelCase(this.state.data));
            this.props.onChangeStep(CLASS_PACK_STEPS.success);
          });
        });
      } else {
        StripeHelper.createPaymentIntent(stripe, card, params, 'token', onLoading, this.setState.bind(this), () => {
          const { data } = this.state;

          StripeHelper.confirmPayment(stripe, card, data, user, onLoading, this.setState.bind(this), () => {
            this.props.createClassPack(toCamelCase(this.state.data));
            this.props.onChangeStep(CLASS_PACK_STEPS.success);
          });
        });
      }
    } else {
      const chosenCard = selectedCard;

      StripeHelper.createPaymentIntent(stripe, chosenCard, params, 'card', onLoading, this.setState.bind(this), () => {
        this.props.createClassPack(toCamelCase(this.state.data));
        this.props.onChangeStep(CLASS_PACK_STEPS.success);
      });
    }
  }

  freeSystem = () => {
    const { discount, instructor, classPack, onLoading } = this.props;

    onLoading(true, () => (
      api.charge.create({ charge: {
        chargable:   'Instructor::ClassPack',
        chargableId: classPack.id,
        charges:     'cost',
        discountId:  discount.id,
        method:      'default',
        recipientId: instructor.id
      }}).then((response) => (
        this.props.createClassPack(toCamelCase({ ...this.state.data, chargeId: response.data.charge_id })),
        this.props.onChangeStep(CLASS_PACK_STEPS.success)
      ))
    ));
  }

  renderOptions = () => (
    <React.Fragment>
      <div className='text-muted text-left mb-2 modal__default-text'>
        I&apos;d like to pay with
      </div>

      <div className='text-left'>
        { this.state.cards.map((card) => (
          <div
              className='custom-control custom-radio'
              key={card.id}>
            <Radio
                className='custom-control-input'
                id={card.id}
                isChecked={this.state.selectedCard.id == card.id}
                name='selectedCard'
                onChange={this.handleOption(card)}
                value={false} />
            <Label
                className='custom-control-label pb-1'
                htmlFor={'input-' + card.id}>
              <span className='mr-3'>
                <PaymentIcon icon={card.card.brand} />
              </span>

              <span className='mr-3'>
                {capitalize(card.card.brand)} ****{card.card.last4}&nbsp;
              </span>

              {padStart(card.card.exp_month)}/{String(card.card.exp_year).slice(-2)}
            </Label>
          </div>
        ))}

        { !!this.state.cards.length &&
          <div className='custom-control custom-radio'>
            <Radio
                className='custom-control-input'
                id='new'
                isChecked={this.state.selectedCard == 'new'}
                name='selectedCard'
                onChange={this.handleOption('new')}
                value={false} />
            <Label
                className='custom-control-label pb-1 modal__default-text'
                htmlFor='input-new'>
              Another card
            </Label>
          </div>
        }

        {this.state.selectedCard != 'new' && <StripeBadge />}
      </div>
    </React.Fragment>
  )

  renderCost = () => {
    const { amount, fee } = this.state;
    const { discount } = this.props;
    const { currency } = this.props.instructor;
    const { coupon } = discount;

    const isDiscounted = discount.id;
    const isFree = isDiscounted && coupon.discountType == 'free';

    const discountPercent = isDiscounted && coupon.percent * 0.01;
    const discountAmount = amount * discountPercent;

    const amountWithDiscount = amount - discountAmount;


    const discountFee = isDiscounted ? amountWithDiscount * 0.03 + 0.49 : fee;
    const price = isDiscounted ? amountWithDiscount : amount;

    const renderPrice = () => (
      <span>
        {parseFloat(price + discountFee, 10).toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
        <span className='modal__event-fee text-muted'>
          (incl. {parseFloat(discountFee, 10).toLocaleString('en-GB', { currency, style: 'currency' })} fee)
        </span>
      </span>
    );

    return (
      <div
          className='modal__event-cost
                     modal__default-text
                     modal__default-text_blue
                     modal__default-text_bold
                     d-inline'>
        Total Cost:&nbsp;
        <React.Fragment>
          {!isDiscounted && renderPrice()}

          { isDiscounted &&
            <span>
              &nbsp;
              {isFree && 'Free'}
              {!isFree && renderPrice()}
            </span>
          }
        </React.Fragment>
      </div>
    );
  }

  render() {
    const { classPack, discount, instructor, isLoading, user } = this.props;
    const { cards } = this.state;
    const { coupon } = discount;

    const isDiscounted = discount.id;
    const isFree = isDiscounted && coupon.discountType == 'free';

    return (
      <ElementsConsumer>
        {({elements, stripe}) => (
          <React.Fragment>
            <div className='mb-1'>
              <div
                  className='modal__event-cost
                            modal__default-text_blue
                            modal__default-text
                            m-0 pb-1 pointer'
                  onClick={this.handleToggle}
                  onKeyPress={this.handleToggle}
                  role='button'
                  tabIndex={0}>
                Apply a Coupon Code?
              </div>
              {this.state.openInput &&
                <React.Fragment>
                  <div className='d-flex'>
                    <Input
                        className='modal__code-input'
                        id='name'
                        name='name'
                        onChange={this.handleChangeInput}
                        placeholder='Code'
                        type='text'
                        value={this.state.code} />

                    <Button
                        className='modal__apply-button'
                        color='success'
                        onClick={this.handleApply}>
                      Apply
                    </Button>
                  </div>

                  {this.state.couponError && <div className='text-danger text-sm'>{this.state.couponError}</div>}
                </React.Fragment>
              }
            </div>
            <div className='mt-3'>
              {this.renderCost()}

              { !isFree && this.state.selectedCard == 'new' &&
                <ChargeForm
                    elements={elements}
                    error={this.state.error.message}
                    stripe={stripe}>
                  { !isFree || !cards.length && user.id &&
                    <div className='save-card'>
                      <div className='custom-control custom-checkbox checkbox-lg'>
                        <Checkbox
                            className='custom-control-input'
                            id='shouldSaveCard'
                            isChecked={this.state.shouldSaveCard}
                            name='shouldSaveCard'
                            onChange={this.handleChange} />
                        <Label
                            className='custom-control-label text-left pt-2'
                            for='input-shouldSaveCard'>
                          Save card to account for future use
                        </Label>
                      </div>
                    </div>
                   }
                </ChargeForm>
              }

              {!isFree && !!this.state.cards.length && this.renderOptions()}
            </div>

            <div className='mt-5'>
              {!classPack.sample && isEmpty(instructor.oauth.stripe) &&
                <div className='modal__default-text text-danger mt-4 mb-3'>
                  Instructor has disabled stripe payments. For more information contact 
                  <a href={`mailto:${instructor.email}`}>&nbsp;{instructor.email}</a>
                </div>
              }

              {!classPack.sample &&
                <Button
                    color='blue'
                    isBlock
                    isDisabled={!stripe || isEmpty(instructor.oauth.stripe)}
                    isLoading={isLoading}
                    onClick={this.handleSubmit(elements, stripe)}
                    size='lg'>
                  Pay Now
                </Button>
              }

              {classPack.sample && isEmpty(instructor.oauth.stripe) &&
                <Link to={routes.INTEGRATIONS}>
                  <Button
                      color='blue'
                      isBlock
                      size='lg'>
                    Connect Stripe
                  </Button>
                </Link>
              }

              {classPack.sample && !isEmpty(instructor.oauth.stripe) &&
                <Button
                    color='blue'
                    isBlock
                    isDisabled={!stripe}
                    isLoading={isLoading}
                    onClick={this.handleSubmit(elements, stripe)}
                    size='lg'>
                  Pay Now
                </Button>
              }
            </div>
          </React.Fragment>
        )}
      </ElementsConsumer>
    );
  }
}

export default Payment;
