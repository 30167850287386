import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { find } from 'lodash';

import { Button, Checkbox } from '../';
import { Input, MonthRangePicker } from '../../inputs';

class Experiences extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    errors:   PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    profile:  PropTypes.object.isRequired
  }

  state = {
    experiences: []
  }

  componentDidMount() {
    if (this.props.profile.experiences.length) {
      this.setState({
        experiences: this.props.profile.experiences
      });
    }
  }

  handleAdd = () => {
    this.setState((prevState) => ({
        experiences: [...prevState.experiences, { company: '', delete: false, from: '', isCurrent: false, to: '' }]
    }), () => {
      this.props.onChange(this.state.experiences, 'experiences');
    });
  }

  handleChange = (index) => (value, inputName) => {
    this.setState((prevState) => {
      const newExperiences = Array.from(prevState.experiences);

      newExperiences.splice(index, 1, { ...newExperiences[index], [inputName]: value });
      return {
        experiences: newExperiences
      };
    }, () => {
      this.props.onChange(this.state.experiences, 'experiences');
    });
  }

  handleRemove = (index) => (e) => {
    e.preventDefault();
    this.setState((prevState) => {
      const newExperiences = Array.from(prevState.experiences);
      const targetExp = newExperiences[index];

      targetExp.id ? newExperiences.splice(index, 1, {...targetExp, delete: true}) : newExperiences.splice(index, 1);

      return {
        experiences: newExperiences
      };
    }, () => {
      this.props.onChange(this.state.experiences, 'experiences');
    });
  }

  render() {
    const { experiences } = this.state;
    const lastIndex = this.state.experiences.length - 1;

    const findError = (index) => (
      find(this.props.errors.workExperiences, ['index', index]) || {}
    );

    return (
      <div className='text-block'>
        {this.props.children}

        {experiences.map((experience, index) => {
          const error = findError(index);
          const rowClassname = classNames({'d-none': experience.delete});

          return (
            <Row
                className={rowClassname}
                key={index}>
              <Col
                  lg={5}
                  xs={9}>
                <FormGroup>
                  <MonthRangePicker
                      isInvalid={{ from: error.from, to: error.to }}
                      isRanged={!experience.isCurrent}
                      onChange={this.handleChange(index)}
                      value={{ from: experience.from, to: experience.to }} />
                </FormGroup>
              </Col>

              <Col
                  className='d-flex align-items-center'
                  lg={2}
                  xs={3}>
                <div className='custom-control custom-checkbox'>
                  <Checkbox
                      className='custom-control-input'
                      id={'isCurrent-' + index}
                      isChecked={experience.isCurrent}
                      name='isCurrent'
                      onChange={this.handleChange(index)} />
                  <Label
                      className='custom-control-label'
                      for={'input-isCurrent-' + index}>
                    Current
                  </Label>
                </div>
              </Col>

              <Col
                  lg={4}
                  xs={10}>
                <FormGroup>
                  <Input
                      isInvalid={!!error.company}
                      name='company'
                      onChange={this.handleChange(index)}
                      placeholder='Company Name'
                      type='text'
                      value={experience.company} />
                  <FormFeedback tooltip>{error.company}</FormFeedback>
                </FormGroup>
              </Col>

              <Col
                  lg={1}
                  xs={2}>
                { index === lastIndex &&
                  <Button
                      color='success'
                      onClick={this.handleAdd}
                      onKeyPress={this.handleAdd}
                      role='button'
                      size='icon'
                      tabIndex={0}>
                    <Icon
                        fixedWidth
                        icon={['fas', 'plus']} />
                  </Button>
                }

                { index !== lastIndex &&
                  <Button
                      color='danger'
                      onClick={this.handleRemove(index)}
                      onKeyPress={this.handleRemove(index)}
                      role='button'
                      size='icon'
                      tabIndex={0}>
                    <Icon
                        fixedWidth
                        icon={['fas', 'times']} />
                  </Button>
                }
              </Col>
            </Row>
          );
        })}
      </div>
    );
  }
}

export default Experiences;
