import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { FollowsPage } from '../components/pages';

import {
  BookingsActions,
  CommentsActions,
  FollowsActions,
  ProfileDataActions,
  ReviewsActions
} from '../actions';


class FollowsContainer extends React.PureComponent {
  static propTypes = {
    fetchInstructors: PropTypes.func.isRequired,
    fetchStudios:     PropTypes.func.isRequired,
    getProfileData:   PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.fetchInstructors();
    this.props.fetchStudios();
    this.props.getProfileData();
  }

  render() {
    return <FollowsPage {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  follows:     state.follows,
  profileData: state.profileData
});

const mapDispatchToProps = (dispatch) => ({
  createBooking:      (data) =>  dispatch(BookingsActions.CREATE(data)),
  createComment:      (data) =>  dispatch(CommentsActions.CREATE(data)),
  createReview:       (data) =>  dispatch(ReviewsActions.CREATE(data)),
  fetchInstructors:   () =>      dispatch(FollowsActions.FETCH_INSTRUCTORS()),
  fetchStudios:       () =>      dispatch(FollowsActions.FETCH_STUDIOS()),
  followInstructor:   (data) =>  dispatch(FollowsActions.FOLLOW_INSTRUCTOR(data)),
  followStudio:       (data) =>  dispatch(FollowsActions.FOLLOW_STUDIO(data)),
  getProfileData:     () =>      dispatch(ProfileDataActions.GET()),
  unfollowInstructor: (data) =>  dispatch(FollowsActions.UNFOLLOW_INSTRUCTOR(data)),
  unfollowStudio:     (data) =>  dispatch(FollowsActions.UNFOLLOW_STUDIO(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FollowsContainer);
