import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Form, FormGroup, Label, Row } from 'reactstrap';

import { Button } from '../forms';
import { Input, Link } from '../inputs';
import { Logo } from '../../images';
import { routes } from '../../lib';

class RecoverPasswordPage extends React.PureComponent {
  static propTypes = {
    recoverPassword: PropTypes.func.isRequired
  }

  state = {
    email: ''
  }

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.recoverPassword(this.state);
  }

  handleTrimSpaces = (e) => {
    e.persist();
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [e.target.name]: e.target.value.replace(/\s/g, '')
      }
    }));
  }

  render() {
    return (
      <Container
          className='px-3'
          fluid>
        <Row className='min-vh-100'>
          <Col
              className='d-flex align-items-center'
              lg='6'
              md='8'
              xl='5'>
            <div className='w-100 py-5 px-md-5 px-xl-6 position-relative'>

              <Link
                  className='mb-4'
                  to={routes.HOME}>
                <img
                    alt="Logo"
                    className="img-fluid auth-logo"
                    src={Logo} />
              </Link>

              <div id="form-container">
                <p className='text-muted'>
                  To receive instructions to reset your password please enter
                  your details and click reset password
                </p>

                <Form
                    className='form-validate'
                    onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label for='email'>E-mail address</Label>
                    <Input
                        autoComplete='email'
                        name='email'
                        onBlur={this.handleTrimSpaces}
                        onChange={this.handleChange}
                        placeholder='demo@onpodio.com'
                        type='email'
                        value={this.state.email} />
                  </FormGroup>

                  <Button
                      className='mt-3'
                      color='primary'
                      isBlock
                      size='lg'
                      type='submit'>
                    RESET PASSWORD
                  </Button>
                </Form>

                <Link
                    className="close-absolute mr-md-5 mr-xl-6 pt-5"
                    to={routes.LOGIN}>
                  <svg className="svg-icon w-3rem h-3rem">
                    <path
                        d="M41.999 20.002l-22 22m22 0L20 20"
                        data-name="layer1"
                        fill="none"
                        stroke="#202020"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        style={{stroke: 'var(--layer1, #202020)'}} />
                  </svg>
                </Link>
              </div>
            </div>
          </Col>

          <Col
              className='d-none d-md-block'
              lg='6'
              md='4'
              xl='7'>
            <div
                className='bg-cover h-100 mr-n3'
                id='auth-bg' />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default RecoverPasswordPage;
