import { ErrorActionTypes } from '../constants/actionTypes';

export default {
  CLEAR: () => ({
    type: ErrorActionTypes.CLEAR
  }),

  CLEAR_API: (payload) => ({
    payload,
    type: ErrorActionTypes.CLEAR_API
  }),

  NEW: (payload) => ({
    payload,
    type: ErrorActionTypes.NEW
  }),

  NEW_API: (payload) => ({
    payload,
    type: ErrorActionTypes.NEW_API
  })
};
