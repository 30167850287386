import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class PreviewGenerator extends PureComponent {
  static propTypes = {
    atTime:   PropTypes.number,
    onLoad:   PropTypes.func,
    videoUrl: PropTypes.string.isRequired
  }

  static defaultProps = {
    atTime: 8,
    onLoad: () => {}
  }

  wrapperEl = React.createRef();
  videoEl = React.createRef();
  canvasEl = React.createRef();
  imageEl = React.createRef();
  
  state = {
    dataLoaded:         false,
    isPreviewGenerated: false,          
    isSuspended:        false,        
    metadataLoaded:     false,
    seeked:             false
  }

  componentDidUpdate() {
    const { metadataLoaded, dataLoaded, isSuspended, seeked, isPreviewGenerated } = this.state;
    const { atTime } = this.props;
    
    if (!isPreviewGenerated) {
      if (metadataLoaded && dataLoaded && isSuspended) {
        if (!this.videoEl.current.currentTime || this.videoEl.current.currentTime < atTime) {
          this.videoEl.current.currentTime = atTime;
        }

        if (seeked && !isPreviewGenerated) {
          this.getPreview();
        }
      }
    }
  }

  getPreview = () => {
    const video = this.videoEl.current;
    const canvas = this.canvasEl.current;
    canvas.height = video.videoHeight;
    canvas.width = video.videoWidth;

    const image = new Image();
    image.crossOrigin = 'Anonymous';

    canvas.getContext('2d').drawImage(video, 0, 0);

    canvas.toBlob((blob) => {
      video.src = '';
      const blobUrl = URL.createObjectURL(blob);

      this.setState({
        isPreviewGenerated: true
      }, () => {
        image.alt = 'Video preview';

        image.onload = () => {
          this.props.onLoad(blob);
          URL.revokeObjectURL(blobUrl);
        };
        image.src = blobUrl;

        this.canvasEl.current.remove();
        this.videoEl.current.remove();

        this.wrapperEl.current.appendChild(image);
      });

    },'image/jpeg');
  }

  render () {
    const { videoUrl } = this.props;
    
    return (
      <div
          className='preview-generator'
          ref={this.wrapperEl}>
        <canvas ref={this.canvasEl} />
        <video
            crossOrigin='Anonymous'
            muted
            onLoadedData={() => this.setState({ dataLoaded: true })}
            onLoadedMetadata={() => this.setState({ metadataLoaded: true })}
            onSeeked={() => this.setState({ seeked: true })}
            onSuspend={() => this.setState({ isSuspended: true })}
            ref={this.videoEl}
            src={videoUrl} />
      </div>
    );
  }
}

export default PreviewGenerator;