import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { InstagramActions } from '../../actions';
import { InstagramFeed } from '../../components/pages/profile/instructor';

class InstagramContainer extends React.PureComponent {
  static propTypes = {
    fetchInstagram: PropTypes.func.isRequired,
    instructor:     PropTypes.object.isRequired
  };

  componentDidMount () {
    const { username } = this.props.instructor.data ;

    this.props.fetchInstagram({ username });
  }

  render () {
    return (
      <InstagramFeed {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  error:      state.error.api,
  instagram:  state.instagram,
  instructor: state.instructor,
  loader:     state.loader,
  user:       state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchInstagram: (data) =>  dispatch(InstagramActions.FETCH(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(InstagramContainer);