export default {
  CREATE:         '@@studio/CREATE',
  CREATE_CLEAN:   '@@studio/CREATE_CLEAN',
  CREATE_FAILURE: '@@studio/CREATE_FAILURE',
  CREATE_SUCCESS: '@@studio/CREATE_SUCCESS',

  FETCH:         '@@studio/FETCH',
  FETCH_FAILURE: '@@studio/FETCH_FAILURE',
  FETCH_SUCCESS: '@@studio/FETCH_SUCCESS'
};
