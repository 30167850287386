import { CommentsActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: CommentsActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: CommentsActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: CommentsActionTypes.CREATE_SUCCESS
  }),


  FETCH: (payload) => ({
    payload, type: CommentsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: CommentsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: CommentsActionTypes.FETCH_SUCCESS
  })
};
