import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import filesizeParser from 'filesize-parser';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { routes } from '../../../lib';

class StorageLimit extends PureComponent {
  static propTypes = {
    getSubscription: PropTypes.func.isRequired,
    plan:            PropTypes.object.isRequired,
    storageLimit:    PropTypes.number,
    usedStorage:     PropTypes.number
  }

  static defaultProps = {
    storageLimit: 0,
    usedStorage:  0
  }

  static getDerivedStateFromProps(props) {
    const limitInBytes = filesizeParser(`${props.storageLimit}GB`);
    const usedPercents = limitInBytes && Math.round(props.usedStorage / limitInBytes * 100);

    return {
      limit: `${props.storageLimit}GB`,
      usedPercents
    };
  }

  componentDidMount () {
    this.props.getSubscription();
  }

  state = {
    limit:        '',
    usedPercents: 0
  }

  render () {
    const { limit, usedPercents } = this.state;
    const { plan } = this.props;

    const isLimitReached = this.state.usedPercents >= 100;
    const className = classNames('storage-limit',
      {
        'bg-info-light': !isLimitReached
      },
      {
        'bg-danger-light': isLimitReached
      });

    if (plan.isUnlimited) return null;

    return (
      <div className={className}>
        <span>
          { isLimitReached ?
            `You’ve reached your ${limit} hosted limit.` :
            `You have used ${usedPercents}% of your ${limit} hosted limit.`
          }
          <br />
          <Link to={routes.PLANS_AND_BILLING}>Upgrade your plan</Link>
          { isLimitReached ?
            ' or remove a video.' : ''
          }
        </span>
      </div>
    );
  }
}

export default StorageLimit;
