export default {
  FOLLOW:         '@@location/FOLLOW',
  FOLLOW_FAILURE: '@@location/FOLLOW_FAILURE',
  FOLLOW_SUCCESS: '@@location/FOLLOW_SUCCESS',

  GET:         '@@location/GET',
  GET_FAILURE: '@@location/GET_FAILURE',
  GET_SUCCESS: '@@location/GET_SUCCESS',

  UNFOLLOW:         '@@location/UNFOLLOW',
  UNFOLLOW_FAILURE: '@@location/UNFOLLOW_FAILURE',
  UNFOLLOW_SUCCESS: '@@location/UNFOLLOW_SUCCESS',

  UPDATE_SUCCESS: '@@location/UPDATE_SUCCESS'
};
