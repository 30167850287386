import { TransactionsActionTypes } from '../constants/actionTypes';

const initialState = {
  challenges:      [],
  classPacks:      [],
  isLoading:       false,
  lastAction:      '',
  memberships:     [],
  purchases:       [],
  summary:         {},
  сlientPurchases: []
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case TransactionsActionTypes.PURCHASES_FETCH:
      return { ...newState, isLoading: true };

    case TransactionsActionTypes.PURCHASES_FETCH_SUCCESS:
      return { ...newState, isLoading: false, purchases: action.payload };

    case TransactionsActionTypes.PURCHASES_FETCH_FAILURE:
      return { ...newState, isLoading: false };


    case TransactionsActionTypes.CLIENT_PURCHASES_FETCH:
      return { ...newState, isLoading: true, сlientPurchases: [] };

    case TransactionsActionTypes.CLIENT_PURCHASES_FETCH_SUCCESS:
      return { ...newState, isLoading: false, сlientPurchases: action.payload };

    case TransactionsActionTypes.CLIENT_PURCHASES_FETCH_FAILURE:
      return { ...newState, isLoading: false };


    case TransactionsActionTypes.PURCHASE_UPDATE:
      return { ...newState, isLoading: true };

    case TransactionsActionTypes.PURCHASE_UPDATE_SUCCESS:
      return { ...newState, isLoading: false, purchases: action.payload };

    case TransactionsActionTypes.PURCHASE_UPDATE_FAILURE:
      return { ...newState, isLoading: false };


    case TransactionsActionTypes.MEMBERSHIPS_FETCH:
      return { ...newState, isLoading: true };

    case TransactionsActionTypes.MEMBERSHIPS_FETCH_SUCCESS:
      return { ...newState, isLoading: false, memberships: action.payload };

    case TransactionsActionTypes.MEMBERSHIPS_FETCH_FAILURE:
      return { ...newState, isLoading: false };


    case TransactionsActionTypes.MEMBERSHIP_UPDATE:
      return { ...newState, isLoading: true };

    case TransactionsActionTypes.MEMBERSHIP_UPDATE_SUCCESS:
      return { ...newState, isLoading: false, memberships: action.payload };

    case TransactionsActionTypes.MEMBERSHIP_UPDATE_FAILURE:
      return { ...newState, isLoading: false };


    case TransactionsActionTypes.CLASS_PACKS_FETCH:
      return { ...newState, isLoading: true };

    case TransactionsActionTypes.CLASS_PACKS_FETCH_SUCCESS:
      return { ...newState, classPacks: action.payload, isLoading: false };

    case TransactionsActionTypes.CLASS_PACKS_FETCH_FAILURE:
      return { ...newState, isLoading: false };


    case TransactionsActionTypes.CLASS_PACK_UPDATE:
      return { ...newState, isLoading: true };

    case TransactionsActionTypes.CLASS_PACK_UPDATE_SUCCESS:
      return { ...newState, classPacks: action.payload, isLoading: false };

    case TransactionsActionTypes.CLASS_PACK_UPDATE_FAILURE:
      return { ...newState, isLoading: false };

    case TransactionsActionTypes.CHALLENGES_FETCH:
      return { ...newState, isLoading: true };

    case TransactionsActionTypes.CHALLENGES_FETCH_SUCCESS:
      return { ...newState, challenges: action.payload, isLoading: false };

    case TransactionsActionTypes.CHALLENGES_FETCH_FAILURE:
      return { ...newState, isLoading: false };


    case TransactionsActionTypes.CHALLENGE_UPDATE:
      return { ...newState, isLoading: true };

    case TransactionsActionTypes.CHALLENGE_UPDATE_SUCCESS:
      return { ...newState, challenges: action.payload, isLoading: false };

    case TransactionsActionTypes.CHALLENGE_UPDATE_FAILURE:
      return { ...newState, isLoading: false };


    case TransactionsActionTypes.SUMMARY_FETCH:
      return newState;

    case TransactionsActionTypes.SUMMARY_FETCH_SUCCESS:
      return { ...newState, summary: action.payload };

    case TransactionsActionTypes.SUMMARY_FETCH_FAILURE:
      return newState;

    default: return state;
  }
};
