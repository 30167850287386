import { CollectionActionTypes  } from '../constants/actionTypes';

export default {
  COLLECTION_CREATE: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_CREATE
  }),

  COLLECTION_CREATE_FAILURE: () => ({
    type: CollectionActionTypes.COLLECTION_CREATE_FAILURE
  }),

  COLLECTION_CREATE_SUCCESS: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_CREATE_SUCCESS
  }),


  COLLECTION_DELETE: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_DELETE
  }),

  COLLECTION_DELETE_FAILURE: () => ({
    type: CollectionActionTypes.COLLECTION_DELETE_FAILURE
  }),

  COLLECTION_DELETE_SUCCESS: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_DELETE_SUCCESS
  }),


  COLLECTION_FETCH: () => ({
    type: CollectionActionTypes.COLLECTION_FETCH
  }),

  COLLECTION_FETCH_FAILURE: () => ({
    type: CollectionActionTypes.COLLECTION_FETCH_FAILURE
  }),

  COLLECTION_FETCH_SUCCESS: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_FETCH_SUCCESS
  }),


  COLLECTION_GET: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_GET
  }),

  COLLECTION_GET_FAILURE: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_GET_FAILURE
  }),

  COLLECTION_GET_SUCCESS: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_GET_SUCCESS
  }),


  COLLECTION_ON_DEMANDS_FETCH: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_ON_DEMANDS_FETCH
  }),

  COLLECTION_ON_DEMANDS_FETCH_FAILURE: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_ON_DEMANDS_FETCH_FAILURE
  }),

  COLLECTION_ON_DEMANDS_FETCH_SUCCESS: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_ON_DEMANDS_FETCH_SUCCESS
  }),


  COLLECTION_UPDATE: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_UPDATE
  }),

  COLLECTION_UPDATE_FAILURE: () => ({
    type: CollectionActionTypes.COLLECTION_UPDATE_FAILURE
  }),

  COLLECTION_UPDATE_SUCCESS: (payload) => ({
    payload,
    type: CollectionActionTypes.COLLECTION_UPDATE_SUCCESS
  }),


  FOLDER_FETCH: (payload) => ({
    payload,
    type: CollectionActionTypes.FOLDER_FETCH
  }),

  FOLDER_FETCH_FAILURE: (payload) => ({
    payload,
    type: CollectionActionTypes.FOLDER_FETCH_FAILURE
  }),

  FOLDER_FETCH_SUCCESS: (payload) => ({
    payload,
    type: CollectionActionTypes.FOLDER_FETCH_SUCCESS
  }),


  ON_DEMANDS_CREATE_SUCCESS: (payload) => ({
    payload,
    type: CollectionActionTypes.ON_DEMANDS_CREATE_SUCCESS
  }),


  ON_DEMANDS_FETCH: () => ({
    type: CollectionActionTypes.ON_DEMANDS_FETCH
  }),

  ON_DEMANDS_FETCH_FAILURE: (payload) => ({
    payload,
    type: CollectionActionTypes.ON_DEMANDS_FETCH_FAILURE
  }),

  ON_DEMANDS_FETCH_SUCCESS: (payload) => ({
    payload,
    type: CollectionActionTypes.ON_DEMANDS_FETCH_SUCCESS
  }),


  ON_DEMAND_CREATE: (payload) => ({
    payload,
    type: CollectionActionTypes.ON_DEMAND_CREATE
  }),

  ON_DEMAND_CREATE_FAILURE: (payload) => ({
    payload,
    type: CollectionActionTypes.ON_DEMAND_CREATE_FAILURE
  }),

  ON_DEMAND_CREATE_SUCCESS: (payload) => ({
    payload,
    type: CollectionActionTypes.ON_DEMAND_CREATE_SUCCESS
  }),


  ON_DEMAND_DELETE: (payload) => ({
    payload,
    type: CollectionActionTypes.ON_DEMAND_DELETE
  }),

  ON_DEMAND_DELETE_FAILURE: (payload) => ({
    payload,
    type: CollectionActionTypes.ON_DEMAND_DELETE_FAILURE
  }),

  ON_DEMAND_DELETE_SUCCESS: (payload) => ({
    payload,
    type: CollectionActionTypes.ON_DEMAND_DELETE_SUCCESS
  }),


  ON_DEMAND_UPDATE: (payload) => ({
    payload,
    type: CollectionActionTypes.ON_DEMAND_UPDATE
  }),

  ON_DEMAND_UPDATE_FAILURE: (payload) => ({
    payload,
    type: CollectionActionTypes.ON_DEMAND_UPDATE_FAILURE
  }),

  ON_DEMAND_UPDATE_SUCCESS: (payload) => ({
    payload,
    type: CollectionActionTypes.ON_DEMAND_UPDATE_SUCCESS
  }),


  SEND_EMAIL: (payload) => ({
    payload, type: CollectionActionTypes.SEND_EMAIL
  }),

  SEND_EMAIL_FAILURE: (payload) => ({
    payload, type: CollectionActionTypes.SEND_EMAIL_FAILURE
  }),

  SEND_EMAIL_SUCCESS: () => ({
    type: CollectionActionTypes.SEND_EMAIL_SUCCESS
  })
};
