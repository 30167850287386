import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../forms';

class InsrtuctorHideMembership extends React.PureComponent {
  static propTypes = {
    isVisible:        PropTypes.bool.isRequired,
    membership:       PropTypes.object.isRequired,
    onClose:          PropTypes.func.isRequired,
    onHideMembership: PropTypes.func.isRequired,
    onSuccess:        PropTypes.func.isRequired
  };

  handleKeep = () => {
    this.props.onClose();
  };

  handleHide = () => {
    this.props.onHideMembership();

    this.props.onClose();
    this.props.onSuccess();
  };

  render() {
    const { isVisible } = this.props;
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            <span>
              Are you sure you want to <span className='text-muted'>{isVisible ? 'hide' : 'show'}</span> your<br />
              <span className='text-primary'> {this.props.membership.name} membership?</span>
            </span>
          </CardText>

          <div className='actions'>
            <Button
                color='muted'
                isOutline
                onClick={this.handleHide}>
              Yes-
              {isVisible ? 'Hide' : 'Show'}
            </Button>

            <Button
                color='primary'
                onClick={this.handleKeep}>
              No-Keep
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default InsrtuctorHideMembership;
