import { all } from 'redux-saga/effects';

import {
  activitySaga,
  additionalInformation,
  autocompleterSaga,
  availabilitySaga,
  bookingRequestsSaga,
  bookingsSaga,
  cardsSaga,
  challengesSaga,
  chargesSaga,
  classPacksSaga,
  clientsSaga,
  collectionsSaga,
  commentsSaga,
  couponsSaga,
  customizationSaga,
  discountSaga,
  domainSaga,
  donationBookingsSaga,
  donationClassesSaga,
  donationTargetsSaga,
  emailSettingsSaga,
  emailTemplatesSaga,
  errorSaga,
  favoriteOnDemandsSaga,
  feedbackSaga,
  filterSaga,
  followsSaga,
  formatsSaga,
  groupClassesSaga,
  instagramSaga,
  instructorSaga,
  invitesSaga,
  joinSaga,
  locationSaga,
  locationsSaga,
  meetingSaga,
  membershipsCouponsSaga,
  membershipsDiscountSaga,
  membershipsSaga,
  milestonesSaga,
  oauthSaga,
  partnersSaga,
  passwordSaga,
  plansSaga,
  playlistSaga,
  privateBookingsSaga,
  privateGroupClassesSaga,
  privateSessionTypesSaga,
  privateSessionsSaga,
  profileDataSaga,
  profileSaga,
  providersSaga,
  purchasedClassesSaga,
  recordingsSaga,
  requestedClassesSaga,
  reviewsSaga,
  routerSaga,
  samplesSaga,
  searchSaga,
  segmentSaga,
  sessionSaga,
  strategySaga,
  studioSaga,
  styleTagsSaga,
  subscriptionsSaga,
  synchronizationSaga,
  timeBlocksSaga,
  transactionsSaga,
  userClassPacksSaga,
  userSaga,
  validationSaga
} from '../sagas';

export default function* sagas() {
  yield all([
    activitySaga(),
    additionalInformation(),
    autocompleterSaga(),
    availabilitySaga(),
    bookingRequestsSaga(),
    bookingsSaga(),
    cardsSaga(),
    challengesSaga(),
    chargesSaga(),
    classPacksSaga(),
    clientsSaga(),
    collectionsSaga(),
    commentsSaga(),
    couponsSaga(),
    customizationSaga(),
    discountSaga(),
    domainSaga(),
    donationTargetsSaga(),
    donationClassesSaga(),
    donationBookingsSaga(),
    emailSettingsSaga(),
    emailTemplatesSaga(),
    errorSaga(),
    favoriteOnDemandsSaga(),
    feedbackSaga(),
    filterSaga(),
    followsSaga(),
    formatsSaga(),
    groupClassesSaga(),
    instagramSaga(),
    instructorSaga(),
    invitesSaga(),
    locationSaga(),
    locationsSaga(),
    meetingSaga(),
    membershipsDiscountSaga(),
    membershipsCouponsSaga(),
    membershipsSaga(),
    milestonesSaga(),
    oauthSaga(),
    joinSaga(),
    partnersSaga(),
    passwordSaga(),
    plansSaga(),
    playlistSaga(),
    playlistSaga(),
    privateBookingsSaga(),
    privateGroupClassesSaga(),
    privateSessionTypesSaga(),
    privateSessionsSaga(),
    profileDataSaga(),
    profileSaga(),
    providersSaga(),
    purchasedClassesSaga(),
    recordingsSaga(),
    requestedClassesSaga(),
    reviewsSaga(),
    routerSaga(),
    samplesSaga(),
    searchSaga(),
    sessionSaga(),
    strategySaga(),
    studioSaga(),
    subscriptionsSaga(),
    synchronizationSaga(),
    styleTagsSaga(),
    timeBlocksSaga(),
    transactionsSaga(),
    validationSaga(),
    userClassPacksSaga(),
    userSaga(),
    segmentSaga()
  ]);
}
