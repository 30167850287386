class SemVer {
  greaterThan = (versionA, versionB) => {
    const versionsA = versionA ? versionA.split(/\./g) : [];
    const versionsB = versionB ? versionB.split(/\./g) : [];

    // eslint-disable-next-line no-loops/no-loops
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());

      const b = Number(versionsB.shift());
      if (a === b) continue;
      return a > b || isNaN(b);
    }
    return false;
  };
}

export default new SemVer();
