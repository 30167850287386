import React from 'react';
import PropTypes from 'prop-types';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Autocompleter, Button } from '../forms';
import { Input } from '../inputs';
import { SEARCH_TYPES } from '../../constants';
import { Persistor } from '../../lib';
import { AutocompleterContainer } from '../../containers';

class SearchBar extends React.PureComponent {
  static propTypes = {
    performSearch: PropTypes.func.isRequired
  }

  persistor = new Persistor();

  state = {
    isOpen: false,
    search: {
      location: this.persistor._load('lastLocation') || '',
      model:    SEARCH_TYPES[0],
      query:    this.persistor._load('lastQuery') || ''
    }
  }

  componentWillUnmount() {
    const { location, query } = this.state.search;

    this.persistor._save('lastLocation', location);
    this.persistor._save('lastQuery',    query);
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        [inputName]: value
      }
    }));
  }

  handleChangeSearchType = (model) => () => {
    this.setState((prevState) => ({
      search: {
        ...prevState.search,
        model
      }
    }));
  }

  handleSearch = (e) => {
    e.preventDefault();

    this.props.performSearch(this.state);
  }

  handleDropdown = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen
    }));
  }

  render() {
    const dropdownText = this.state.search.model === 'instructor' ? 'FIND AN INSTRUCTOR' : 'FIND A STUDIO';
    const placeholder = this.state.search.model === 'instructor'
      ? 'KEYWORD | Search by instructor name or class'
      : 'KEYWORD | Search by studio name or class';
    return (
      <div className='search-bar search-bar-home p-3 p-lg-1'>
        <form
            action="#"
            onSubmit={this.handleSearch}>
          <Row className='p-lg-3'>
            <Col lg={12}>
              <Dropdown
                  className='h-100'
                  isOpen={this.state.isOpen}
                  /* eslint-disable-next-line react/jsx-handler-names */
                  toggle={this.handleDropdown}>
                <DropdownToggle
                    caret
                    className='rounded-xl btn-block h-100'
                    color='primary'>
                  {dropdownText}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                      active={this.state.search.model == SEARCH_TYPES[0]}
                      className='dropdown-text'
                      onClick={this.handleChangeSearchType(SEARCH_TYPES[0])}>
                    <FontAwesomeIcon
                        className='mr-2'
                        fixedWidth
                        icon='users' />
                    FIND AN INSTRUCTOR
                  </DropdownItem>
                  <DropdownItem
                      active={this.state.search.model == SEARCH_TYPES[1]}
                      className='dropdown-text'
                      onClick={this.handleChangeSearchType(SEARCH_TYPES[1])}>
                    <FontAwesomeIcon
                        className='mr-2'
                        fixedWidth
                        icon='building' />
                    FIND A STUDIO
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Col>
            <FormGroup className='col-lg-12 d-flex align-items-center no-divider'>
              <Input
                  className='border-0 shadow-0 swith-search-bar-home'
                  name='query'
                  onChange={this.handleChange}
                  placeholder={placeholder}
                  type='text'
                  value={this.state.search.query} />
            </FormGroup>
            <div className='border-search-bar-home' />
            <FormGroup className='col-lg-12 d-flex align-items-center no-divider'>
              <div
                  className='input-label-absolute input-label-absolute-right
                    w-100 d-flex align-items-center px-3 py-1'>
                <FontAwesomeIcon
                    icon='map-marker-alt'
                    size='lg' />
                <AutocompleterContainer>
                  <Autocompleter
                      className=''
                      components={{ DropdownIndicator: null }}
                      isBordered={false}
                      isPersisted
                      name='location'
                      onChange={this.handleChange}
                      onSelect={this.handleSelect}
                      placeholder='WHERE | City, neighborhood'
                      subject='geocodeds'
                      value={this.state.search.location} />
                </AutocompleterContainer>
              </div>
            </FormGroup>
            <Col lg='12'>
              <Button
                  className='h-100 rounded-xl'
                  color='primary'
                  isBlock
                  type='submit'>
                <FontAwesomeIcon
                    icon='search'
                    size='lg' />
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    );
  }
}

export default SearchBar;
