import { FeedbackActionTypes } from '../constants/actionTypes';

const initialState = {
  created:    false,
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case FeedbackActionTypes.CREATE:
      return newState;

    case FeedbackActionTypes.CREATE_SUCCESS:
      return { ...newState, created: true };

    case FeedbackActionTypes.CREATE_FAILURE:
      return newState;

    default: return state;
  }
};