import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Input } from '../../inputs';
import { Button, MaskedInput } from '../../forms';
import { EMAIL_TEMPLATES } from '../../../constants';
import { dayMask } from '../../../constants/masks';
import { Validator } from '../../../lib';

class WinBackModal extends React.PureComponent {
  static propTypes = {
    createEmailTemplate: PropTypes.func.isRequired,
    emailTemplate:       PropTypes.object.isRequired,
    isLoading:           PropTypes.bool,
    isNew:               PropTypes.bool,
    isOpen:              PropTypes.bool,
    onToggle:            PropTypes.func,
    updateEmailTemplate: PropTypes.func.isRequired
  }

  static defaultProps = {
    isLoading: false,
    isNew:     true,
    isOpen:    false,
    onToggle:  () => {}
  }

  state = {
    emailTemplate: {
      body:     '',
      deliver:  true,
      name:     EMAIL_TEMPLATES.winBackSequence,
      sequence: 0,
      subject:  ''
    },
    errors: {}
  }

  componentDidMount() {
    const { emailTemplate } = this.props;

    this.setState({ emailTemplate: { ...emailTemplate, name: EMAIL_TEMPLATES.winBackSequence} });
  }

  handleChange = (value, inputName) => (
    this.setState((prevState) => ({
      emailTemplate: {
        ...prevState.emailTemplate,
        [inputName]: value
      }
    }))
  )

  handleSubmit = () => {
    this.handleValidate().then(() => {
      return (
        this.props.isNew
            ? this.props.createEmailTemplate(this.state)
            : this.props.updateEmailTemplate(this.state),
        this.props.onToggle()
      );
    }).catch((errors) => (
      this.setState({ errors })
    ));
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { emailTemplate } = this.state;

    const errors = Validator.validate([
      ['body',     Validator.concepts.isFilled, [emailTemplate.body]],
      ['subject',  Validator.concepts.isFilled, [emailTemplate.subject]],
      ['sequence', Validator.concepts.isFilled, [emailTemplate.sequence]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  render() {
    const { isNew } = this.props;
    const { emailTemplate, errors } = this.state;

    return (
      <Modal
          className='modal-refer'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          {isNew ? 'Create' : 'Update'}&nbsp;
          <span className='text-primary'>
            Win-Back Email
          </span>
        </ModalHeader>

        <ModalBody>
          <div className='description mb-2'>
            <small>
              Set the # of days from the last class booking to wait before sending an email.
              The email will then be automically sent to clients
            </small>
          </div>


          <Form className='form-default'>
            <FormGroup>
              <Label
                  className='required'
                  for='sequence'>
                # of Days since Last Booking
              </Label>

              <MaskedInput
                  id='sequence'
                  inputMode='number'
                  isInvalid={!!errors.sequence}
                  mask={dayMask}
                  name='sequence'
                  onChange={this.handleChange}
                  placeholder='14 days'
                  type='number'
                  value={String(emailTemplate.sequence)} />
            </FormGroup>

            <div className='text-primary mb-2'>Email Template</div>

            <FormGroup>
              <Label
                  className='required'
                  for='subject'>
                Subject Line
              </Label>

              <Input
                  id='subject'
                  isInvalid={!!errors.subject}
                  name='subject'
                  onChange={this.handleChange}
                  placeholder='Subject...'
                  value={emailTemplate.subject} />
            </FormGroup>

            <FormGroup>
              <Label
                  className='required'
                  for='body'>
                Email Body
              </Label>

              <Input
                  id='body'
                  isInvalid={!!errors.body}
                  name='body'
                  onChange={this.handleChange}
                  placeholder='Hey {{name}},&#10;I’ve been missing you...'
                  rows='4'
                  type='textarea'
                  value={emailTemplate.body} />
            </FormGroup>
          </Form>

          <div className='text-small text-muted'>
            Insert {'{{name}}'} to include the client’s name
          </div>
        </ModalBody>

        <ModalFooter>
          <Button
              className='mt-4'
              color='primary'
              isBlock
              isLoading={this.props.isLoading}
              onClick={this.handleSubmit}
              size='lg'
              type='button'>
            {isNew ? 'Create' : 'Update'} Email
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default WinBackModal;
