/* eslint-disable immutable/no-mutation, immutable/no-let */
import { compact, filter, groupBy, map, sortBy, uniqBy } from 'lodash';
import pluralize from 'pluralize';

import { calendarOptions } from '../constants';
import { DateHelper, Moment, getTimeZone } from './';

class Calendr {
  setTimeDisplayFormat = (isMobile) => {
    const timeFormats = calendarOptions.FORMATS.time;

    return isMobile ? timeFormats.mobile : timeFormats.desktop;
  };

  setDatePickerFormat = (selectedDate, selectedView, isMobile) => {
    const formats = calendarOptions.FORMATS.datePicker;

    const format = isMobile ? formats[selectedView].mobile : formats[selectedView].desktop;

    const week = selectedDate.range('week');

    switch (selectedView) {
      case 'day':
        return selectedDate.format(format);

      case 'week':
        return [week.start.format(format[0]), week.end.format(format[1])].join(' - ');

      case 'month':
        return selectedDate.format(format);

      default:
        return null;
    }
  };

  getInstructors = (groupClasses = {}, privateGroupClasses = {}) => (
    uniqBy(compact(
      [
        ...map(groupClasses, 'instructor'),
        ...map(privateGroupClasses, (event) => ({ ...event.instructor, fullName: event.instructor.name }))
      ]
    ), 'id')
  )

  getLocations = (groupClasses = {}, privateGroupClasses = {}) => (
    uniqBy(compact(
      [
        ...map(groupClasses, 'location'),
        ...map(privateGroupClasses, 'location')
      ]
    ), 'id')
  )

  getWeekDayLabels = (isMobile) => {
    return isMobile ? Moment.weekdaysMin() : Moment.weekdaysShort();
  };

  getMonthArray = (selectedDate, daysCount=35) => {
    const offset = new Moment(selectedDate).startOf('month').startOf('week');
    const days = Moment.rangeFromInterval('day', daysCount, offset);

    return Array.from(days.by('day',{ excludeEnd: true }));
  };

  getDayRange = (selectedDate) => {
    const localDate = selectedDate.format();

    return Moment.rangeFromInterval('hour', 24, new Moment(localDate).startOf('day'));
  };

  getDayTimes = (selectedDate, timeZone) => {
    const range = this.getDayRange(selectedDate, timeZone);

    return Array.from(range.by('hour', { excludeEnd: true }));
  };

  getWeekdays = (range) => {
    return Array.from(range.by('days', { excludeEnd: true }));
  };

  dateToLocal = (date, timeZone) => (
    new Moment.utc(date, 'YYYY-MM-DDhh:mm:ss').tz(getTimeZone(timeZone))
  )

  filter = (collection, timeZone, range, isBookings = false, includeEnd = false) => {
    const filteredCollection = filter(collection, (object) => {

      const event = isBookings ? object.event : object;
      const startsAt = DateHelper.summerTimeCheck(event, timeZone);

      const duration = isBookings ? object.event.duration : object.duration;
      const endsAt = this.dateToLocal(startsAt, timeZone).add(duration, 'minutes');

      const eventRange = Moment.range(this.dateToLocal(startsAt), endsAt);

      return includeEnd ? eventRange.overlaps(range) : eventRange.start.within(range);
    });

    return sortBy(filteredCollection, 'startsAt');
  };

  isToday = (selectedDate, today) => {
    return Moment(selectedDate).isSame(Moment(today), 'day');
  };

  currentMonth = (date, selectedDate) => {
    return Moment(date).isSame(Moment(selectedDate), 'month');
  };

  eventsGroupBy = (collection, timeZone, format) => (
    groupBy(sortBy(collection, 'startsAt'), (object) => {
      const date = this.dateToLocal(object.startsAt, timeZone).format('YYYY-MM-DD');

      return new Moment(date, 'YYYY-MM-DDhh:mm:ss').format(format);
    }
  ))

  bookingsGroupBy = (collection, timeZone, format) => (
    groupBy(sortBy(collection, 'event.startsAt'), (object) => {
      const date = this.dateToLocal(object.event.startsAt, timeZone).format('YYYY-MM-DD');

      return new Moment(date, 'YYYY-MM-DDhh:mm:ss').format(format);
    }
  ))

  combineClasses = (events = {}) => {
    const classTypes = Object.keys(events);

    return classTypes.flatMap((type) => (
      events[type].map((event) => ({ ...event, type: pluralize.singular(type) }))
    ));
  };

  combineBookings = (bookings, privateBookings=[], privateSessions=[], purchasedClasses=[]) => {
    const groupClassBookings = bookings.map((booking) => ({
      client:    booking.client,
      createdAt: booking.createdAt,
      event:     { ...booking.groupClass, bookingId: booking.id, type: 'groupClass' },
      id:        booking.id
    }));

    const privateGroupClassBookings = privateBookings.map((booking) => ({
      charge:    booking.charge,
      client:    booking.client,
      createdAt: booking.createdAt,
      event:     {
        ...booking.privateGroupClass,
        bookingId:   booking.id,
        bookingType: booking.bookingType,
        type:        'privateGroupClass'
      },
      id:              booking.id,
      viaClassPack:    booking.viaClassPack,
      viaSubscription: booking.viaSubscription
    }));

    const privateSessionBookings = privateSessions.map((session) => ({
      createdAt: session.createdAt,
      event:     { ...session, type: 'privateSession' },
      id:        session.id
    }));

    const purchasedClassesBookings = purchasedClasses.map((purchasedClass) => ({
      charge:          purchasedClass.charge,
      client:          purchasedClass.client,
      createdAt:       purchasedClass.createdAt,
      event:           { ...purchasedClass.requestedClass, feeType: 'Paid', type: 'onDemandClass' },
      id:              purchasedClass.id,
      uuid:            purchasedClass.uuid,
      viaClassPack:    purchasedClass.viaClassPack,
      viaSubscription: purchasedClass.viaSubscription
    }));

    return [...groupClassBookings,
            ...privateGroupClassBookings,
            ...privateSessionBookings,
            ...purchasedClassesBookings];
  };

  getRange = (selectedDate, timeZone, type) => {
    const localDate = this.dateToLocal(selectedDate, getTimeZone(timeZone));

    return Moment.rangeFromInterval(type, 1, localDate.startOf(type));
  };

  eventsInRange = (events, timeZone, range, includeEnd = false) => (
    this.filter(events, timeZone, range, includeEnd)
  );

  bookingsInRange = (bookings, timeZone, range, includeEnd = false) => (
    this.filter(bookings, timeZone, range, true, includeEnd)
  );

  getAvailableEvents = (events, timeZone) => (
    filter(events, (event) => {
      const date = DateHelper.summerTimeCheck(event, timeZone);

      const diff = new Moment.utc().diff(new Moment.utc(date, 'YYYY-MM-DDhh:mm:ss'), 'minutes');

      return diff < event.duration;
    })
  );
}


export default new Calendr();
