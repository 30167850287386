import { ProfileActionTypes, SessionActionTypes } from '../constants/actionTypes';

const initialState = {
  authenticated: false,
  email:         '',
  isInstructor:  false,
  isJoining:     false,
  lastAction:    '',
  restored:      false
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case SessionActionTypes.CLEAR:
      return { ...newState, email: initialState.email, isJoining: false };

    case SessionActionTypes.JOIN_NOW:
      return { ...newState, email: action.payload, isJoining: true };

    case SessionActionTypes.SIGN_IN:
      return { ...newState, restored: false };

    case SessionActionTypes.SIGN_IN_SUCCESS:
      return { ...newState, authenticated: true };

    case SessionActionTypes.SIGN_IN_FAILURE:
      return { ...newState, authenticated: false };

    case SessionActionTypes.SIGN_UP:
      return newState;

    case SessionActionTypes.SIGN_UP_SUCCESS:
      return newState;

    case SessionActionTypes.SIGN_UP_FAILURE:
      return newState;


    case SessionActionTypes.RESTORE_SESSION:
      return newState;

    case SessionActionTypes.RESTORE_SESSION_SUCCESS:
      return { ...newState, authenticated: true, restored: true };

    case SessionActionTypes.RESTORE_SESSION_FAILURE:
      return { ...newState, authenticated: false, isInstructor: false, restored: true };


    case SessionActionTypes.LOGOUT:
      return newState;

    case SessionActionTypes.LOGOUT_SUCCESS:
      return { ...newState, authenticated: false, isInstructor: false, restored: true };

    case SessionActionTypes.LOGOUT_FAILURE:
      return { ...newState, authenticated: false, isInstructor: false, restored: false };


    case ProfileActionTypes.FETCH_SUCCESS:
      return { ...newState, isInstructor: true };

    case ProfileActionTypes.FETCH_FAILURE:
      return { ...newState, isInstructor: false };

    default: return state;
  }
};
