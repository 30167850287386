import { PasswordActionTypes } from '../constants/actionTypes';

const initialState = {
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case PasswordActionTypes.RECOVER:
      return newState;

    case PasswordActionTypes.RECOVER_SUCCESS:
      return newState;

    case PasswordActionTypes.RECOVER_FAILURE:
      return newState;


    case PasswordActionTypes.RESET:
      return newState;

    case PasswordActionTypes.RESET_SUCCESS:
      return newState;

    case PasswordActionTypes.RESET_FAILURE:
      return newState;


    default: return state;
  }
};
