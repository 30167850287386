import React from 'react';
import PropTypes from 'prop-types';

import { ProfileBasicBg } from '../../../../../images';
import { BasicForm } from '../../../../forms/profile/create';
import { PanelImage } from '../../../../layout/pages';

class BasicStep extends React.PureComponent {
  static propTypes = {
    activities:       PropTypes.array.isRequired,
    nextStep:         PropTypes.func,
    onChange:         PropTypes.func.isRequired,
    onCreate:         PropTypes.func.isRequired,
    profile:          PropTypes.object.isRequired,
    validateUsername: PropTypes.func.isRequired,
    validation:       PropTypes.object.isRequired
  }

  static defaultProps = {
    nextStep: () => {}
  }

  handleCreate = () => {
    this.props.onCreate();
    setTimeout(() => {
      this.props.nextStep();
    }, 600);
  }

  render () {
    return (
      <PanelImage
          id='profile-basic-info'
          imageSrc={ProfileBasicBg}>
        <BasicForm
            activities={this.props.activities}
            onChange={this.props.onChange}
            onSubmit={this.handleCreate}
            profile={this.props.profile}
            validateUsername={this.props.validateUsername}
            validation={this.props.validation} />
      </PanelImage>
    );
  }
}

export default BasicStep;
