import React from 'react';
import PropTypes from 'prop-types';

import { Moment, getTimeZone } from '../../../../lib';

class TimeZoneInfo extends React.PureComponent {
  static propTypes = {
    timeZone: PropTypes.string
  }

  static defaultProps = {
    timeZone: ''
  }

  render() {
    const { timeZone } = this.props;
    return (
      <div className='modal__event-timezone modal__small-text'>
        <span>
          {new Moment.tz(getTimeZone(timeZone)).format('z')} (GMT
          {new Moment.tz(getTimeZone(timeZone)).format('Z')} {getTimeZone(timeZone)})
        </span>
      </div>
    );
  }
}

export default TimeZoneInfo;
