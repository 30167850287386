import { api, extractError } from './';

class StripeHelper {
  confirmPayment = (stripe, card, data, user, onLoading, updateState, _callback) => (
    stripe.confirmCardPayment(data.secret, {
      payment_method: {
        billing_details: {
          email: user.email,
          name:  user.firstName + ' ' + user.lastName
        },
        card
      }
    })
    .then((result) => {
      if (result.error) {
        return (
          api.charge.destroy({ id: data.charge_id }),
          onLoading(false, () => updateState({ error: result.error }))
        );
      } else {
        return _callback();
      }
    })
  )

  createPaymentIntent = (stripe, card, params, method, onLoading, updateState, _callback) => (
    onLoading(true, () => (
      api.charge.create({ charge: { ...params, method } })
        .then((response) => (
          updateState((prevState) => ({ data: { ...prevState.data, ...response.data }}), _callback)
        ))
        .catch((error) => (
          onLoading(false, () => updateState({ error: extractError(error) }))
        ))
    ))
  )

  createCustomer = (instructor, onLoading, updateState, _callback) => (
    onLoading(true, () => (
      api.customer.create({ customer: { instructorId: instructor.id }})
        .then((response) => (
          _callback(response.data)
        ))
        .catch((error) => (
          updateState({ error: extractError(error) }, () => onLoading(false))
        ))
    ))
  )

  createPaymentMethod = (stripe, card, user, onLoading, updateState, _callback) => (
    stripe.createPaymentMethod({
      billing_details: {
        email: user.email,
        name:  user.firstName + ' ' + user.lastName
      },
      card,
      type: 'card'
    })
    .then((response) => {
      if (response.error) {
        return onLoading(false, () => updateState({ error: response.error }));
      } else {
        return _callback(response.paymentMethod);
      }
    })
  )

  createSubscription = (params, onLoading, updateState, _callback) => (
    onLoading(true, () => (
      api.subscription.create({ subscription: { ...params } })
        .then((response) => (
          updateState({ data: response.data }, _callback)
        ))
        .catch((error) => (
          updateState({ error: extractError(error) }, () => onLoading(false))
        ))
    ))
  )

  createCheckoutSession = (params, updateState, _callback) => (
    updateState({ isLoading: true }, () => (
      api.checkout.create({ checkout: { ...params } })
        .then((response) => (
          _callback(response.data[0])
        ))
        .catch((error) => (
          updateState({ error: extractError(error), isLoading: false })
        ))
    ))
  )
}

export default new StripeHelper;
