import { OAuthActionTypes } from '../constants/actionTypes';

export default {
  LOGIN_FAILURE: () => ({
    type: OAuthActionTypes.LOGIN_FAILURE
  }),
  
  LOGIN_SUCCESS: () => ({
    type: OAuthActionTypes.LOGIN_SUCCESS
  }),


  LOGOUT: (payload) => ({
    payload,
    type: OAuthActionTypes.LOGOUT
  }),
  
  LOGOUT_FAILURE: () => ({
    type: OAuthActionTypes.LOGOUT_FAILURE
  }),
  
  LOGOUT_SUCCESS: () => ({
    type: OAuthActionTypes.LOGOUT_SUCCESS
  })
};