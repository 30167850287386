import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { compact, map, reject } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { PrivateGroupClassesActionTypes } from '../constants/actionTypes';
import {
  ErrorActions,
  LoaderActions,
  MeetingActions,
  PrivateGroupClassesActions,
  SegmentActions
} from '../actions';


function* watchCreate() {
  yield takeLatest(PrivateGroupClassesActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.privateGroupClasses.create, payload);
      const data = toCamelCase(response.data);

      yield put(PrivateGroupClassesActions.CREATE_SUCCESS(data));
      yield put(SegmentActions.CREATE_CLASS(data));

      if (data.meetingId) {
        yield put(MeetingActions.UPDATE({ id: data.meetingId }));
      }
    } catch (error) {
      yield put(PrivateGroupClassesActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(PrivateGroupClassesActionTypes.CREATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
    toastr.success('Event added');
  });
}

function* watchCreateFailure() {
  yield takeLatest(PrivateGroupClassesActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchDelete() {
  yield takeLatest(PrivateGroupClassesActionTypes.DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.privateGroupClasses.delete, payload);

      const privateGroupClasses = yield select((state) => state.privateGroupClasses.data);

      const data = reject(privateGroupClasses, ['id', payload.privateGroupClass.id]);
      yield put(PrivateGroupClassesActions.DELETE_SUCCESS(data));

      const meetingId = payload.privateGroupClass.meetingId;
      if (!payload.privateGroupClass.recurring && meetingId) {
        yield put(MeetingActions.DELETE({ id: payload.privateGroupClass.meetingId }));
      }
    } catch (error) {
      yield put(PrivateGroupClassesActions.DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDeleteSuccess() {
  yield takeLatest(PrivateGroupClassesActionTypes.DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
    toastr.success('Event deleted');
  });
}

function* watchDeleteFailure() {
  yield takeLatest(PrivateGroupClassesActionTypes.DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchFetch() {
  yield takeLatest(PrivateGroupClassesActionTypes.FETCH, function* () {
    try {
      const profile = yield select((state) => state.profile.data);
      const response = yield call(api.privateGroupClasses.fetch, { username: profile.username });

      yield put(PrivateGroupClassesActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PrivateGroupClassesActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsFetch() {
  yield takeLatest(PrivateGroupClassesActionTypes.INSTRUCTORS_FETCH, function* () {
    try {
      const response = yield call(api.instructor.privateGroupClasses.fetch);

      yield put(PrivateGroupClassesActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PrivateGroupClassesActions.INSTRUCTORS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsGet() {
  yield takeLatest(PrivateGroupClassesActionTypes.INSTRUCTORS_GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('privateGroupClasses'));
      const response = yield call(api.instructor.privateGroupClasses.get, payload);

      yield put(PrivateGroupClassesActions.INSTRUCTORS_GET_SUCCESS(toCamelCase(response.data)));
      yield put(LoaderActions.FINISH_FIELD_LOADING('privateGroupClasses'));
    } catch (error) {
      yield put(PrivateGroupClassesActions.INSTRUCTORS_GET_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchRecurringCreate() {
  yield takeLatest(PrivateGroupClassesActionTypes.RECURRING_CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.recurrences.create, payload);

      yield put(PrivateGroupClassesActions.RECURRING_CREATE_SUCCESS(toCamelCase(response.data.recurrings)));
      yield put(SegmentActions.CREATE_RECURRING_CLASS(toCamelCase(response.data)));

      const meetingId = payload.recurrence.recurringParams.meetingId;
      if (meetingId) {
        yield put(MeetingActions.UPDATE({ id: meetingId }));
      }
    } catch (error) {
      yield put(PrivateGroupClassesActions.RECURRING_CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchRecurringCreateSuccess() {
  yield takeLatest(PrivateGroupClassesActionTypes.RECURRING_CREATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
    toastr.success('Event added');
  });
}

function* watchRecurringCreateFailure() {
  yield takeLatest(PrivateGroupClassesActionTypes.RECURRING_CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchRecurringDelete() {
  yield takeLatest(PrivateGroupClassesActionTypes.RECURRING_DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.recurrences.destroy, payload.privateGroupClass);

      const privateGroupClasses = yield select((state) => state.privateGroupClasses.data);
      const data = reject(privateGroupClasses, ['recurrenceId', payload.privateGroupClass.recurrenceId]);
      const recurrings = toCamelCase(compact(response.data));

      yield put(PrivateGroupClassesActions.RECURRING_DELETE_SUCCESS([...data, ...recurrings]));

      const meetingId = payload.privateGroupClass.meetingId;
      if (meetingId) {
        yield put(MeetingActions.DELETE({ id: meetingId }));
      }
    } catch (error) {
      yield put(PrivateGroupClassesActions.RECURRING_DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchRecurringDeleteSuccess() {
  yield takeLatest(PrivateGroupClassesActionTypes.RECURRING_DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
    toastr.success('Events deleted');
  });
}

function* watchRecurringDeleteFailure() {
  yield takeLatest(PrivateGroupClassesActionTypes.RECURRING_DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdate() {
  yield takeLatest(PrivateGroupClassesActionTypes.UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.privateGroupClasses.update, payload);
      const privateGroupClasses = yield select((state) => state.privateGroupClasses.data);
      const model = toCamelCase(response.data);

      const data = map(privateGroupClasses, (privateGroupClass) =>
        (privateGroupClass.id === payload.privateGroupClass.id ? model : privateGroupClass));

      yield put(PrivateGroupClassesActions.UPDATE_SUCCESS(data));

      if (model.meetingId) {
        yield put(MeetingActions.UPDATE({ id: model.meetingId }));
      }
      toastr.success('Event updated');
    } catch (error) {
      yield put(PrivateGroupClassesActions.UPDATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(PrivateGroupClassesActionTypes.UPDATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateFailure() {
  yield takeLatest(PrivateGroupClassesActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchRecurringUpdate() {
  yield takeLatest(PrivateGroupClassesActionTypes.RECURRING_UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.recurrences.update, payload);
      const privateGroupClasses = yield select((state) => state.privateGroupClasses.data);

      const data = reject(privateGroupClasses, ['recurrenceId', response.data.id]);
      const recurrings = toCamelCase(compact(response.data.recurrings));

      yield put(PrivateGroupClassesActions.RECURRING_UPDATE_SUCCESS([...data, ...recurrings]));
    } catch (error) {
      yield put(PrivateGroupClassesActions.RECURRING_UPDATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchRecurringUpdateSuccess() {
  yield takeLatest(PrivateGroupClassesActionTypes.RECURRING_UPDATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
    toastr.success('Recurring events updated');
  });
}

function* watchRecurringUpdateFailure() {
  yield takeLatest(PrivateGroupClassesActionTypes.RECURRING_UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


export default function* privateGroupClassesSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),
    watchDelete(),
    watchDeleteSuccess(),
    watchDeleteFailure(),
    watchFetch(),
    watchInstructorsFetch(),
    watchInstructorsGet(),
    watchRecurringCreate(),
    watchRecurringCreateSuccess(),
    watchRecurringCreateFailure(),
    watchRecurringDelete(),
    watchRecurringDeleteSuccess(),
    watchRecurringDeleteFailure(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure(),
    watchRecurringUpdate(),
    watchRecurringUpdateSuccess(),
    watchRecurringUpdateFailure()
  ]);
}
