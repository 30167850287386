import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, map } from 'lodash';

class Errors extends React.PureComponent {
  static propTypes = {
    errors: PropTypes.object
  }

  static defaultProps = {
    errors: {}
  }

  renderErrors = () => {
    return map(this.props.errors, (error, key) => (
      <div
          className='error ml-3'
          key={key}>
        {error}
      </div>
    ));
  }

  render() {
    if (!isEmpty(this.props.errors)) {
      return <div className='errors mb-3'>{this.renderErrors()}</div>;
    } else {
      return null;
    }
  }
}

export default Errors;
