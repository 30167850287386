import { connect } from 'react-redux';

import { ClientsActions } from '../../actions';
import { ClientModal } from '../../components/modals/instructor';

const mapStateToProps = (state) => ({
  client:    state.clients.form,
  isLoading: state.loader.clients.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  createClient: (data) => dispatch(ClientsActions.CREATE(data)),
  deleteClient: (data) => dispatch(ClientsActions.DELETE(data)),
  updateClient: (data) => dispatch(ClientsActions.UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientModal);