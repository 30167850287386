import React from 'react';
import PropTypes from 'prop-types';

class Tagline extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    line:      PropTypes.string.isRequired
  }

  static defaultProps = {
    className: ''
  }

  render() {
    return (
      <blockquote className={'profile-tagline ' + this.props.className}>
        <span>&quot;{this.props.line}&quot;</span>
      </blockquote>
    );
  }
}

export default Tagline;