import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { some } from 'lodash';

import { Button } from '../../forms';
import { PurchaseClassModal } from '../../modals';
import { Donation, ElegantHeart, ElegantWallet, Free, Like, Paid, PlayIcon, UnLike } from '../../../images';
import { Moment, getTimeZone } from '../../../lib';

class RequestedClassElegant extends React.PureComponent {
  static propTypes = {
    challenges:       PropTypes.array.isRequired,
    classPacks:       PropTypes.array.isRequired,
    createDiscount:   PropTypes.func.isRequired,
    discount:         PropTypes.object.isRequired,
    favorites:        PropTypes.array.isRequired,
    fetchClassPacks:  PropTypes.func.isRequired,
    fetchMemberships: PropTypes.func.isRequired,
    instructor:       PropTypes.object.isRequired,
    instructorPacks:  PropTypes.array.isRequired,
    like:             PropTypes.func.isRequired,
    loader:           PropTypes.object.isRequired,
    memberships:      PropTypes.array.isRequired,
    requestedClass:   PropTypes.object.isRequired,
    restoreDiscount:  PropTypes.func.isRequired,
    subscriptions:    PropTypes.array.isRequired,
    subtractCredit:   PropTypes.func.isRequired,
    unLike:           PropTypes.func.isRequired,
    user:             PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    const isLiked = some(props.favorites, (favorite) => (
      favorite.id == props.requestedClass.id
    ));

    return { isLiked };
  }

  state = {
    isModalOpen: false
  }

  handleToggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  renderIcon = (type) => {
    if (!this.props.instructor.customization) return null;

    const { customization } = this.props.instructor;
    const { secondaryButtonsBackgroundColor } = customization;

    switch (type) {
      case 'free':
        return <Free fill={secondaryButtonsBackgroundColor} />;

      case 'donation':
        return <Donation fill={secondaryButtonsBackgroundColor} />;

      case 'paid':
        return <Paid fill={secondaryButtonsBackgroundColor} />;

      default:
        return null;
    }
  }

  handleLike = () => {
    const { id } = this.props.requestedClass;

    this.state.isLiked
      ? this.props.unLike({ id })
      : this.props.like({ instructorOnDemandId: id });
  }

  render() {
    const { instructor, requestedClass, user } = this.props;
    const { customization } = instructor;
    const { primaryButtonsBackgroundColor } = customization;

    const createdAt = new Moment.utc(requestedClass.createdAt, 'YYYY-MM-DDhh:mm:ssUTC')
      .tz(getTimeZone(user.timeZone));

    const src = requestedClass.poster;

    return (
      <React.Fragment>
        <Card
            className='card-event card-requested-class body-class__event'
            key={1}>
          <div className='body-class__wrapper'>
            <div
                className='body-class__content photo'
                style={{ backgroundImage: `url(${src})`, backgroundSize: 'cover' }}>
              { user.id &&
                <React.Fragment>
                  <div
                      className='like-icon'
                      onClick={this.handleLike}
                      onKeyPress={this.handleLike}
                      role='button'
                      tabIndex={0}>
                    <img
                        alt="Icon"
                        src={this.state.isLiked ? UnLike : Like} />
                  </div>

                  <div
                      className='play-icon'
                      onClick={this.handleToggle}
                      onKeyPress={this.handleToggle}
                      role='button'
                      tabIndex={0}>
                    <img
                        alt="Icon"
                        src={PlayIcon} />
                  </div>
                </React.Fragment>
              }
            </div>
            <div className='body-class__info'>
              <div className="body-class-icon">
                { requestedClass.cost !== 0
                  ? <ElegantWallet color={primaryButtonsBackgroundColor} />
                  : <ElegantHeart color={primaryButtonsBackgroundColor} />
                }
                {requestedClass.cost === 0 && 'Free'}
              </div>
              <div className='body-class__requestedName'>
                {requestedClass.name}
              </div>
              <div className='body-class__requestedTime'>
                Uploaded {createdAt.format('MMM, DD, YYYY')}
              </div>
            </div>
            <Button
                color='link'
                customization={customization}
                onClick={this.handleToggle}>
              Watch
            </Button>
            {/*<div className='body-class__description'>
              <p className='text'>{requestedClass.description}</p>

              { requestedClass.canExpire &&
                <span className='font-weight-bold'>Access expires {requestedClass.expiry} days after purchase</span>
              }
            </div>*/}
          </div>
        </Card>

        { this.state.isModalOpen &&
          <PurchaseClassModal
              challenges={this.props.challenges}
              classPacks={this.props.classPacks}
              createDiscount={this.props.createDiscount}
              discount={this.props.discount}
              fetchClassPacks={this.props.fetchClassPacks}
              fetchMemberships={this.props.fetchMemberships}
              instructor={this.props.instructor}
              instructorPacks={this.props.instructorPacks}
              isOpen={this.state.isModalOpen}
              loader={this.props.loader}
              memberships={this.props.memberships}
              onToggle={this.handleToggle}
              requestedClass={requestedClass}
              restoreDiscount={this.props.restoreDiscount}
              subscriptions={this.props.subscriptions}
              subtractCredit={this.props.subtractCredit}
              user={this.props.user} />
        }
      </React.Fragment>
    );
  }
}

export default RequestedClassElegant;
