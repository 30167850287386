import { DomainActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload,
    type: DomainActionTypes.CREATE
  }),

  CREATE_FAILURE: (payload) => ({
    payload,
    type: DomainActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload,
    type: DomainActionTypes.CREATE_SUCCESS
  }),
  
  
  DELETE: (payload) => ({
    payload,
    type: DomainActionTypes.DELETE
  }),
  
  DELETE_FAILURE: () => ({
    type: DomainActionTypes.DELETE_FAILURE
  }),
  
  DELETE_SUCCESS: () => ({
    type: DomainActionTypes.DELETE_SUCCESS
  }),


  FETCH: () => ({
    type: DomainActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: DomainActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload,
    type: DomainActionTypes.FETCH_SUCCESS
  }),


  GET: () => ({
    type: DomainActionTypes.GET
  }),

  GET_FAILURE: (payload) => ({
    payload,
    type: DomainActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload,
    type: DomainActionTypes.GET_SUCCESS
  })
};