import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { PurchasesPage } from '../components/pages';
import { PrivateBookingsActions, PurchasedClassesActions } from '../actions';

class PurchasesContainer extends React.PureComponent {
  static propTypes = {
    fetchPrivateBookings:  PropTypes.func.isRequired,
    fetchPurchasesClasses: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.fetchPrivateBookings();
    this.props.fetchPurchasesClasses();
  }

  render() {
    return <PurchasesPage {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  privateBookings:  state.privateBookings.data,
  purchasedClasses: state.purchasedClasses.userData,
  user:             state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  deletePrivateBooking:  (data) => dispatch(PrivateBookingsActions.DELETE(data)),
  fetchPrivateBookings:  () => dispatch(PrivateBookingsActions.FETCH()),
  fetchPurchasesClasses: () => dispatch(PurchasedClassesActions.USER_FETCH())
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchasesContainer);
