export default {
  COLORS: [
    '#000000',
    '#ffffff',
    '#F44336',
    '#FF9800',
    '#FFC107',
    '#87C341',
    '#03A9F4',
    '#363EF4',
    '#9C27B0',
    '#D73F88'
  ],
  DEFAULT_VALUES: {
    bold: false,

    color:      '#FFFFFF',
    dimensions: {
      deltaHeight: 768 * .1,
      deltaWidth:  432 * .1,
      height:      768,
      width:       432
    },

    font:        'Poppins',
    hasLocation: true,
    hasName:     true,

    italic: false,

    position: {
      x: '10%',
      y: '10%'
    },

    schedule: {
      friday:    true,
      monday:    true,
      saturday:  true,
      sunday:    true,
      thursday:  true,
      tuesday:   true,
      wednesday: true
    },
    size: 13
  },
  FONT_FAMILIES: [
    'Poppins',
    'Courier',
    'Prototype',
    'Learning Curve Pro'
  ],
  FONT_SELECT_STYLE: {
    container: (provided) => ({
      ...provided,
      marginRight: 10,
      maxHeight:   28,
      width:       160
    }),
    control: (provided) => ({
      ...provided,
      borderRadius: 8,
      maxHeight:    28,
      minHeight:    28
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      display: 'none'
    }),
    input: (provided) => ({
      ...provided,
      height:        '100%',
      paddingBottom: 0,
      paddingTop:    0
    }),
    option: (provided, state) => ({
      ...provided,
      color:    state.isSelected ? '#fff' : '#000',
      fontSize: 13,
      padding:  '5px 10px'
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize:   14,
      lineHeight: '18px',
      textAlign:  'center',
      width:      '100%'
    }),
    valueContainer: (provided) => ({
      ...provided,
      maxHeight: 28,
      padding:   '0'
    })
  },
  FONT_SIZES: [4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32],
  ZOOM:       [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
};
