import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError, toCamelCase } from '../lib';
import { ErrorActions, PartnerActions } from '../actions';
import { PartnerActionTypes } from '../constants/actionTypes';

function* watchFetch() {
  yield takeLatest(PartnerActionTypes.FETCH, function* () {
    try {
      const response = yield call(api.partner.fetch);

      yield put(PartnerActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PartnerActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* partnersSaga() {
  yield all([
    watchFetch()
  ]);
}