import React from 'react';
import PropTypes from 'prop-types';
import { Col, Label, ModalBody, ModalFooter, Row, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { chunk, filter, includes } from 'lodash';

import { PURCHASE_STEPS, PURCHASE_TYPES } from '../../../constants';
import { Button, Checkbox } from '../../forms';
import { UserModalAvatar } from '../layouts';
import { Moment, getTimeZone, instructorRoutes, routes } from '../../../lib';
import { BookingTermsModal } from '..';

class Info extends React.PureComponent {
  static propTypes = {
    classPack:            PropTypes.object,
    fetchClassPacks:      PropTypes.func.isRequired,
    fetchMemberships:     PropTypes.func.isRequired,
    instructor:           PropTypes.object.isRequired,
    instructorPacks:      PropTypes.array.isRequired,
    isChallengeAvailable: PropTypes.bool.isRequired,
    isClassPackAvailable: PropTypes.bool.isRequired,
    isLoading:            PropTypes.bool.isRequired,
    isSubscribed:         PropTypes.bool.isRequired,
    loader:               PropTypes.object.isRequired,
    memberships:          PropTypes.array.isRequired,
    onCharge:             PropTypes.func,
    onLoading:            PropTypes.func.isRequired,
    onNext:               PropTypes.func,
    requestedClass:       PropTypes.object.isRequired,
    user:                 PropTypes.object
  }

  static defaultProps = {
    classPack: {},
    onCharge:  () => {},
    onNext:    () => {},
    user:      {}
  }

  componentDidMount() {
    const isPackagesOnly = PURCHASE_TYPES[this.props.requestedClass.purchaseTypes] == PURCHASE_TYPES.package;
    const { isClassPackAvailable, isSubscribed, instructor } = this.props;
    const { username } = instructor;

    if (isPackagesOnly && !isClassPackAvailable && !isSubscribed) {
      this.props.fetchClassPacks({ username });
      this.props.fetchMemberships({ username });
    }
  }

  state = {
    isBookingTermsOpen: false,
    isTermsChecked:     false
  }

  _calcStep = () => {
    const { isChallengeAvailable, isClassPackAvailable, isSubscribed, user } = this.props;

    if (!user.id) return PURCHASE_STEPS.guest;

    if (isChallengeAvailable || isClassPackAvailable || isSubscribed) {
      return PURCHASE_STEPS.success;
    } else {
      return PURCHASE_STEPS.charge;
    }
  }

  handleChange = (value, inputName) => (
    this.setState({ [inputName]: value })
  )

  handleModal = (modal) => (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ [modal]: !prevState[modal] }));
  }

  handleNext = () => {
    const nextStep = this._calcStep();

    this.props.onLoading(true, () => {
      if (nextStep == PURCHASE_STEPS.success) {
        this.props.onCharge();
      } else {
        this.props.onNext(nextStep);
      }
    });
  }

  renderPriceTag = () => {
    const { instructor, isClassPackAvailable, isChallengeAvailable, isSubscribed, requestedClass } = this.props;
    const { currency } = instructor;

    return (
      <div
          className={classNames({ 'text-strikethrough': isClassPackAvailable ||
                                                        isSubscribed ||
                                                        isChallengeAvailable })}>
          Cost:&nbsp;<b>{requestedClass.cost.toLocaleString('en-GB', { currency, style: 'currency' })}</b>
      </div>
    );
  }

  renderPackageOnly = () => {
    const { loader, instructor, isChallengeAvailable, isClassPackAvailable, isSubscribed } = this.props;

    const instructorPacks = filter(this.props.instructorPacks, (classPack) => (
      classPack.premium.onDemand == 'true' &&
      !includes(this.props.requestedClass.classPackIds, classPack.id)
    ));

    const memberships = filter(this.props.memberships, (membership) => (
      membership.premium.onDemand == 'true' &&
      !includes(this.props.requestedClass.membershipIds, membership.id)
    ));

    const isLoading = loader.memberships.isLoading || loader.classPacks.isLoading;

    return (
      <div>
        <span className='modal__default-text modal__default-text_blue modal__default-text_bold'>
          Package Access Only
        </span>

        { !isChallengeAvailable && !isClassPackAvailable && !isSubscribed &&
          <div>
            <small>
              You must first purchase one of these packages to access this class:
            </small>

            { isLoading && this.renderSpinner()}

            { !isLoading &&
              <React.Fragment>
                {chunk(instructorPacks, 2).map((packsChunk, index) => (
                  <Row key={index}>
                    {packsChunk.map((classPack) => (
                      <Col
                          key={classPack.id}
                          xs={6}>
                        <Link to={routes.INSTRUCTOR(instructor.username + instructorRoutes.PACKAGES.path)}>
                          <small className='pl-3 text-primary'>- {classPack.name}</small>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                ))}

                {chunk(memberships, 2).map((membershipChunk, index) => (
                  <Row key={index}>
                    {membershipChunk.map((membership) => (
                      <Col
                          key={membership.id}
                          xs={6}>
                        <Link to={routes.INSTRUCTOR(instructor.username + instructorRoutes.PACKAGES.path)}>
                          <small className='pl-3 text-primary'>- {membership.name}</small>
                        </Link>
                      </Col>
                    ))}
                  </Row>
                ))}
              </React.Fragment>
            }

          </div>
        }
      </div>
    );
  }

  renderSpinner = () => (
    <div className='d-flex justify-content-center mt-2'>
      <Spinner color='muted' />
    </div>
  )

  render() {
    const { classPack, instructor, requestedClass, user,
            isChallengeAvailable, isClassPackAvailable, isSubscribed } = this.props;

    const createdAt = new Moment.utc(requestedClass.createdAt, 'YYYY-MM-DDhh:mm:ssUTC')
                                .tz(getTimeZone(user.timeZone));

    const isPackagesOnly = PURCHASE_TYPES[requestedClass.purchaseType] == PURCHASE_TYPES.package;
    const isOwn = !window.location.origin.includes('localhost') &&
                  this.props.instructor.isOwner;
    const isDisabled = !this.state.isTermsChecked ||
                       (isPackagesOnly &&  !isChallengeAvailable && !isClassPackAvailable && !isSubscribed) || isOwn;

    return (
      <div className='new-modal'>
        <ModalBody>
          { instructor &&
            <div className='modal__header'>
              <UserModalAvatar
                  alt={instructor.name}
                  firstName={instructor.name}
                  src={instructor.photo.sm} />
              <div className='modal__instructor-name'>
                <span className='modal__big-text'>{instructor.name}</span>
              </div>
            </div>
          }

          <div className='modal__event-name'>
            <span className='modal__big-text modal__big-text_black'>{requestedClass.name}</span>
          </div>

          <div>
            <span className='modal__default-text modal__default-text_bold'>Uploaded | </span>
            {createdAt.format('MMM, DD, YYYY')} at {createdAt.format('hh:mma')}
          </div>

          <div className='modal__event-timezone modal__small-text'>
            {new Moment.tz(getTimeZone(user.timeZone)).format('z')} (GMT
            {new Moment.tz(getTimeZone(user.timeZone)).format('Z')} {getTimeZone(user.timeZone)})
          </div>

          <div className='modal__event-location modal__small-text'>
            Virtual
          </div>

          { requestedClass.description &&
            <div className='event-description'>
              <span className='modal__small-text'>About this class</span>
              <p className='modal__small-text modal__small-text_grey'>{requestedClass.description}</p>
            </div>
          }

          { requestedClass.canExpire &&
            <div className='modal__default-text modal__default-text_bold mb-3'>
              Note: Video access will expire after {requestedClass.expiry} days
            </div>
          }

          <div className='modal__event-cost modal__default-text modal__default-text_blue'>
            {isPackagesOnly && this.renderPackageOnly()}

            {requestedClass.purchaseType == 'paid' && this.renderPriceTag()}

            { isSubscribed && !isChallengeAvailable &&
              <span className='modal__default-text modal__default-text_blue modal__default-text_bold'>
                Included in Membership
              </span>
            }

            { isClassPackAvailable && !isSubscribed && !isChallengeAvailable &&
              <span className='modal__default-text modal__default-text_blue modal__default-text-bold'>
                Use one of your remaining {classPack.onDemand + classPack.combined} credits
              </span>
            }

            { isChallengeAvailable &&
              <span className='modal__default-text modal__default-text_blue modal__default-text-bold'>
                Included in Challenge
              </span>
            }
          </div>
        </ModalBody>

        <ModalFooter>
          <div className='event-terms'>
            <div className='custom-control custom-checkbox checkbox-md'>
              <Checkbox
                  className='custom-control-input'
                  id='isTermsChecked'
                  isChecked={this.state.isTermsChecked}
                  name='isTermsChecked'
                  onChange={this.handleChange} />
              <Label
                  className='custom-control-label text-left'
                  for='input-isTermsChecked'>
                I have read and agree to the terms of the&nbsp;
                <Link
                    className='text-primary'
                    onClick={this.handleModal('isBookingTermsOpen')}
                    to='#'>
                  Waiver & Release
                </Link>
              </Label>
            </div>
          </div>

          <Button
              color='blue'
              isBlock
              isDisabled={isDisabled}
              isLoading={this.props.isLoading}
              onClick={this.handleNext}
              size='lg'>
            Access on Demand
          </Button>
        </ModalFooter>

        <BookingTermsModal
            instructor={instructor}
            isOpen={this.state.isBookingTermsOpen}
            onToggle={this.handleModal('isBookingTermsOpen')} />
      </div>
    );
  }
}

export default Info;
