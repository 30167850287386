import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError, toCamelCase } from '../lib';
import { MilestonesActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions, MilestonesActions } from '../actions';

function* watchFetch() {
  yield takeLatest(MilestonesActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('milestones'));
      const response = yield call(api.instructor.milestone.fetch);

      yield put(MilestonesActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(MilestonesActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(MilestonesActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('milestones'));
  });
}

function* watchFetchFailure() {
  yield takeLatest(MilestonesActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('milestones'));
  });
}

export default function* milestonesSaga() {
  yield all([
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure()
  ]);
}
