import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { CommentsActionTypes } from '../constants/actionTypes';

import { CommentsActions, ErrorActions,LoaderActions } from '../actions';

function* watchCreate() {
  yield takeLatest(CommentsActionTypes.CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.instructor.comments.create, payload);

      yield put(CommentsActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Comment added!');
    } catch (error) {
      yield put(CommentsActions.CREATE_FAILURE());

      /* error */
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetch() {
  yield takeLatest(CommentsActionTypes.FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('comments'));
      const response = yield call(api.instructor.comments.fetch, payload);

      yield put(CommentsActions.FETCH_SUCCESS(toCamelCase(response.data)));
      yield put(LoaderActions.FINISH_FIELD_LOADING('comments'));
    } catch (error) {
      yield put(CommentsActions.FETCH_FAILURE());
      yield put(LoaderActions.FINISH_FIELD_LOADING('comments'));
    }
  });
}

export default function* commentsSaga() {
  yield all([
    watchCreate(),
    watchFetch()
  ]);
}
