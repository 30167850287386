import { SegmentActionTypes } from '../constants/actionTypes';

export default {
  ADDED_ADDITIONAL_INFO: (payload) => ({
    payload, type: SegmentActionTypes.ADDED_ADDITIONAL_INFO
  }),

  CREATE_CLASS: (payload) => ({
    payload, type: SegmentActionTypes.CREATE_CLASS
  }),

  CREATE_PROFILE: (payload) => ({
    payload, type: SegmentActionTypes.CREATE_PROFILE
  }),

  CREATE_RECURRING_CLASS: (payload) => ({
    payload, type: SegmentActionTypes.CREATE_RECURRING_CLASS
  }),

  CREATE_TIME_BLOCK: (payload) => ({
    payload, type: SegmentActionTypes.CREATE_TIME_BLOCK
  }),

  IDENTIFY: (payload) => ({
    payload, type: SegmentActionTypes.IDENTIFY
  }),

  NEW_CLASS_BOOK: (payload) => ({
    payload, type: SegmentActionTypes.NEW_CLASS_BOOK
  }),

  SIGN_IN: (payload) => ({
    payload, type: SegmentActionTypes.SIGN_IN
  }),

  SIGN_UP: (payload) => ({
    payload, type: SegmentActionTypes.SIGN_UP
  }),

  SITE_SHARE: () => ({
    type: SegmentActionTypes.SITE_SHARE
  }),

  STRIPE_CONNECT: (payload) => ({
    payload, type: SegmentActionTypes.STRIPE_CONNECT
  }),

  ZOOM_CONNECT: (payload) => ({
    payload, type: SegmentActionTypes.ZOOM_CONNECT
  })
};
