import React from 'react';
import { connect } from 'react-redux';

import { AddClientModal } from '../../components/modals';

class AddClientModalContainer extends React.PureComponent {
  render() {
    return <AddClientModal {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  instructor: state.profile.data
});

export default connect(mapStateToProps, null)(AddClientModalContainer);
