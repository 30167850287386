/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, CustomInput, FormGroup, FormText, Label } from 'reactstrap';

import { StoryMaker as Painter } from '../../lib';
import { Alert } from '../errors';

const DEGREES = {
  1: 0,
  3: 180,
  6: 90,
  8: 270
};

class ImageUploader extends React.PureComponent {
  static propTypes = {
    events:   PropTypes.array.isRequired,
    onUpload: PropTypes.func
  }

  static defaultProps = {
    onUpload: () => {}
  }

  state = {
    base64: ''
  }

  handleUpload = (groupClass) => {
    const file = groupClass.target.files[0];

    const image = new Image();
    image.onload = () => {
      Painter._orientation(file, (base64, rotation) => {
        const degrees = DEGREES[rotation] || 0;

        if (degrees > 0) {
          Painter._rotateBase64(base64, degrees, (base64) => {
            this.setState({ base64 }, () => {
              this.props.onUpload(this.state.base64, image.width, image.height);
            });
          });
        } else {
          this.setState({ base64 }, () => {
            this.props.onUpload(this.state.base64, image.width, image.height);
          });
        }
      });
    };

    image.src = URL.createObjectURL(file);
  }

  render() {
    return (
      <Container
          className='px-3'
          fluid>
        <h3 className='text-primary text-uppercase text-center my-4'>
          Insta Story<br />Schedule Generator
        </h3>

        <p className='text-muted'>
          Instantly create your epic IG story class schedule post with out 1-Click generator!
        </p>

        <p className='text-muted'>
          Customize it how you like to promote your class schedule and fill those openings!
        </p>

        <p className='text-muted'>
          Just upload a background image and your schedule will auto-populate...
        </p>

        <FormGroup className='my-3'>
          { !!this.props.events.length &&
            <React.Fragment>
              <Label for='image'>
                <strong>Upload Background Image</strong>
              </Label>

              <CustomInput
                  disabled={!this.props.events.length}
                  id='image'
                  name='image'
                  onChange={this.handleUpload}
                  type='file' />
              <FormText>Recommended image ratios 9:16 or 9:18</FormText>
            </React.Fragment>
          }
          { !this.props.events.length &&
            <Alert
                color='danger'
                icon='calendar-times'>You don&apos;t have any classes. Please add classes and try again.</Alert>
          }
        </FormGroup>
      </Container>
    );
  }
}

export default ImageUploader;
