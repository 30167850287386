import React from 'react';
import PropTypes from 'prop-types';

import { ProfileBadge } from '../badges';

class Services extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired
  }

  render () {
    const { instructor } = this.props;

    return (
      <div className='instructor-services'>
        <ul>
          { instructor.services.map((service) => (
            <li key={service.id}>
              <ProfileBadge
                  title={service.title} />
            </li>
            ))
          }
        </ul>
      </div>
    );
  }
}

export default Services;
