import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Moment, routes } from '../../lib';
import { UserModalAvatar } from './layouts';

class UserClassPackModal extends React.PureComponent {
  static propTypes = {
    classPack: PropTypes.object.isRequired,
    isOpen:    PropTypes.bool,
    onToggle:  PropTypes.func
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  renderFooter() {
    const { instructor } = this.props.classPack;

    return (
      <React.Fragment>
        <ModalFooter className='text-center'>
          <small className='text-muted mt-3'>
            To book a class with your class credits&nbsp;
            <Link to={routes.INSTRUCTOR(instructor.username)}>
              click here
            </Link>
          </small>
        </ModalFooter>
      </React.Fragment>
    );
  }

  render() {
    const { classPack } = this.props;

    const { instructor } = classPack;

    const expiresAt = new Moment(classPack.createdAt, 'YYYY-MM-DDhh:mm:ss').add(classPack.expiry, 'days');

    return (
      <Modal
          className='modal-booking'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle} />

        <div className='new-modal'>
          <ModalBody>
            { instructor &&
              <div className='modal__header'>
                <UserModalAvatar
                    alt={instructor.firstName}
                    firstName={instructor.firstName}
                    src={instructor.photo} />
                <div className='modal__instructor-name'>
                  <span className='modal__big-text'>{instructor.firstName}&nbsp;{instructor.lastName}</span>
                </div>
              </div>
            }

            <div className='modal__event-name'>
              <span className='modal__big-text modal__big-text_black'>Class Credits</span>
            </div>

            <div className='event-description'>
              <span className='modal__small-text mb-2'>Your class credits can be used for</span>
            </div>

            <div className='d-flex'>
              <div className='d-flex flex-column col-md-6'>
                <span className='modal__small-text modal__small-text_grey mb-1'>
                  <strong><u>Credits</u></strong>
                </span>

                <span className='modal__small-text modal__small-text_grey mb-1'>
                  Live Classes Only: <span className='text-success'>{classPack.live}</span>
                </span>

                <span className='modal__small-text modal__small-text_grey mb-1'>
                  On Demand Only: <span className='text-success'>{classPack.onDemand}</span>
                </span>

                <span className='modal__small-text modal__small-text_grey line pt-1'>
                  Combined: <span className='text-success'>{classPack.combined}</span>
                </span>

                <span className='modal__small-text modal__small-text_grey mt-3 mb-1'>
                  Private Sessions Only: <span className='text-success'>{classPack.privateSession}</span>
                </span>
              </div>

              { classPack.canExpire &&
                <div className='d-flex flex-column col-md-6'>
                  <span className='modal__small-text modal__small-text_grey mb-1'>
                    <strong><u>Expiry</u></strong>
                  </span>

                  <span className='modal__small-text modal__small-text_bottom modal__small-text_grey pt-1 line w-100'>
                    Expires on {expiresAt.format('MMM D, YYYY')}
                  </span>
                </div>
              }
            </div>
          </ModalBody>
          {this.renderFooter()}
        </div>
      </Modal>
    );
  }
}

export default UserClassPackModal;
