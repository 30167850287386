import { PurchasedClassesActionTypes } from '../constants/actionTypes';

export default {
  INSTRUCTORS_FETCH: (payload) => ({
    payload, type: PurchasedClassesActionTypes.INSTRUCTORS_FETCH
  }),

  INSTRUCTORS_FETCH_FAILURE: () => ({
    type: PurchasedClassesActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_FETCH_SUCCESS: (payload) => ({
    payload, type: PurchasedClassesActionTypes.INSTRUCTORS_FETCH_SUCCESS
  }),


  USERS_GET: (payload) => ({
    payload, type: PurchasedClassesActionTypes.USERS_GET
  }),

  USERS_GET_FAILURE: () => ({
    type: PurchasedClassesActionTypes.USERS_GET_FAILURE
  }),

  USERS_GET_SUCCESS: (payload) => ({
    payload, type: PurchasedClassesActionTypes.USERS_GET_SUCCESS
  }),


  USER_FETCH: (payload) => ({
    payload, type: PurchasedClassesActionTypes.USER_FETCH
  }),

  USER_FETCH_FAILURE: () => ({
    type: PurchasedClassesActionTypes.USER_FETCH_FAILURE
  }),

  USER_FETCH_SUCCESS: (payload) => ({
    payload, type: PurchasedClassesActionTypes.USER_FETCH_SUCCESS
  }),

  USER_UPDATE: (payload) => ({
    payload, type: PurchasedClassesActionTypes.USER_UPDATE
  }),

  USER_UPDATE_FAILURE: () => ({
    type: PurchasedClassesActionTypes.USER_UPDATE_FAILURE
  }),

  USER_UPDATE_SUCCESS: (payload) => ({
    payload, type: PurchasedClassesActionTypes.USER_UPDATE_SUCCESS
  })
};
