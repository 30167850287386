import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { BookingModal } from './';

class BookingScheduleModal extends React.PureComponent {
  static propTypes = {
    children:      PropTypes.node,
    createBooking: PropTypes.func.isRequired,
    isOpen:        PropTypes.bool.isRequired,
    onToggle:      PropTypes.func.isRequired
  }

  static defaultProps = {
    children:     null,
    groupClasses: [],
    hasLocation:  false
  }

  state = {
    groupClass:  {},
    isModalOpen: false
  }

  handleModal = (groupClass = {}) => {
    this.setState((prevState) => ({
      groupClass,
      isModalOpen: !prevState.isModalOpen
    }));
  }

  render() {
    return (
      <Modal
          centered
          className='booking-schedule-modal'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>

        <ModalHeader toggle={this.props.onToggle}>
          {this.props.children}
        </ModalHeader>

        <ModalBody>
          <div className='modals'>
            <BookingModal
                createBooking={this.props.createBooking}
                groupClass={this.state.groupClass}
                isOpen={this.state.isModalOpen}
                onToggle={this.handleModal} />
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default BookingScheduleModal;
