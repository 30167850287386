/* eslint-disable immutable/no-mutation */
class PaymentMethod {
  constructor(isClassPackAvailable, isSubscribed, isChallengeAvailable) {
    this.isClassPackAvailable = isClassPackAvailable;
    this.isSubscribed = isSubscribed;
    this.isChallengeAvailable = isChallengeAvailable;
  }

  get = () => {
    switch (true) {
      case this.isChallengeAvailable:
        return 'challenge';

      case this.isSubscribed:
        return 'subscription';

      case this.isClassPackAvailable:
        return 'class_pack';

      default: return 'charge';
    }
  }
}

export default PaymentMethod;
