import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import { routes } from '../../lib';


class RequirementsModal extends React.PureComponent {
  static propTypes = {
    availability:        PropTypes.object.isRequired,
    isOpen:              PropTypes.bool,
    locations:           PropTypes.array.isRequired,
    onToggle:            PropTypes.func,
    privateSessionTypes: PropTypes.array.isRequired

  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  render() {
    const { availability, locations, privateSessionTypes } = this.props;

    return (
      <Modal
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          To enable clients to request a Private Session from your page you must add your:
        </ModalHeader>

        <ModalBody>
          <div className='d-flex'>
            { !!locations.length &&
              <Icon
                  className='text-success'
                  icon={['far', 'check-circle']}
                  size='2x' />
            }

            { !locations.length &&
              <Icon
                  className='text-muted'
                  icon={['far', 'circle']}
                  size='2x' />
            }

            <div className='mt-auto mb-auto ml-3'>
              <Link to={routes.LOCATIONS}>
                Add a Private Location(s)
              </Link>
            </div>
          </div>

          <div className='d-flex mt-3'>
            { !isEmpty(availability) &&
              <Icon
                  className='text-success'
                  icon={['far', 'check-circle']}
                  size='2x' />
            }

            { isEmpty(availability) &&
              <Icon
                  className='text-muted'
                  icon={['far', 'circle']}
                  size='2x' />
            }

            <div className='mt-auto mb-auto ml-3'>
              <Link to={routes.AVAILABILITY}>
                Set Private Session Availability
              </Link>
            </div>
          </div>

          <div className='d-flex mt-3'>
            { !!privateSessionTypes.length &&
              <Icon
                  className='text-success'
                  icon={['far', 'check-circle']}
                  size='2x' />
            }

            { !privateSessionTypes.length &&
              <Icon
                  className='text-muted'
                  icon={['far', 'circle']}
                  size='2x' />
            }

            <div className='mt-auto mb-auto ml-3'>
              <Link to={routes.SESSION_TYPES}>
                Add a Private Session Type(s)
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default RequirementsModal;
