class Scroller {
  _scroll = (behaviour) => {
    window.scrollTo({
      behaviour,
      left: 0,
      top:  0
    });
  };
}

export default new Scroller;
