import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { templates } from '../../../../constants';
import Default from './default';
import Urban from './urban';
import Elegant from './elegant';

class AboutContainer extends React.PureComponent {
  static propTypes = {
    instructor:   PropTypes.object.isRequired,
    siteTemplate: PropTypes.string.isRequired
  }

  renderTemplate = () => {
    const { siteTemplate } = this.props;

    switch (siteTemplate) {
      case templates.ELEGANT: return <Elegant {...this.props} />;

      case templates.URBAN: return <Urban {...this.props} />;

      default: return <Default {...this.props} />;
    }
  }

  render() {
    return (
      <div className='inner-wrapper instructor-profile'>
        {this.renderTemplate()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  instructor: state.instructor.data
});

export default connect(mapStateToProps)(AboutContainer);
