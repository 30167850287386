import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { CSVLink } from 'react-csv';

import { tableOptions } from '../../../../constants/transactions';
import { ExportTransactions } from '../../../../lib';

class PurchasesTable extends PureComponent {
  static propTypes = {
    coupons:        PropTypes.array.isRequired,
    fetchCoupons:   PropTypes.func.isRequired,
    fetchPurchases: PropTypes.func.isRequired,
    isLoading:      PropTypes.bool.isRequired,
    transactions:   PropTypes.array.isRequired,
    updatePurchase: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props) {
    const coupons = props.coupons.map((coupon) => {
      return { label: coupon.code, value: coupon.code };
    });

    return {
      coupons,
      data: props.transactions
    };
  }

  componentDidMount() {
    this.props.fetchPurchases();
    this.props.fetchCoupons();
  }

  state = {
    coupons: [],
    data:    []
  }

  handleSave = (_oldValue, note, row) => {
    const id = parseInt(row.id.replace( /^\D+/g, ''));
    const type = row.purchaseType;

    this.props.updatePurchase({ id, note, type });
  }

  render () {
    const { isLoading } = this.props;
    const { data, coupons } = this.state;
    const options = tableOptions(coupons);

    const defaultSorted = [{
      dataField: 'createdAt',
      order:     'desc'
    }];

    const cellEdit = cellEditFactory({
      beforeSaveCell: this.handleSave,
      blurToSave:     true,
      mode:           'click'
    });

    return (
      <React.Fragment>
        <CSVLink
            className='mb-2 text-decoration-none'
            data={ExportTransactions.getPurchases(data)}
            filename='my-purchases.csv'>
          Export
        </CSVLink>

        <BootstrapTable
            bootstrap4i
            cellEdit={cellEdit}
            columns={options.PURCHASES}
            data={data}
            defaultSorted={defaultSorted}
            filter={filterFactory()}
            filterPosition="top"
            hover
            keyField='id'
            loading={isLoading}
            pagination={paginationFactory(options.PAGINATION)}
            wrapperClasses='table-responsive' />
      </React.Fragment>
    );
  }
}

export default PurchasesTable;
