import React from 'react';
import PropTypes from 'prop-types';
import { chunk } from 'lodash';
import { ButtonNext, CarouselProvider, Image, Slide, Slider } from 'pure-react-carousel';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { InstructorNoPhotoWide } from '../../../../images';
import { routes } from '../../../../lib';
import { profileCarouselOptions } from '../../../../constants';

class LocationInstructors extends React.PureComponent {
  static propTypes = {
    instructors:         PropTypes.array.isRequired,
    scrapperInstructors: PropTypes.array
  }

  static defaultProps = {
    scrapperInstructors: []
  }

  static getDerivedStateFromProps(props) {
    return {
      instructors: chunk(props.instructors.concat(props.scrapperInstructors), 10)
    };
  }

  state = {
    instructors: []
  }

  renderInstructor = (instructor) => {
    if (instructor.username) {
      return (
        <Link
            className='instructor-container'
            key={instructor.id}
            to={routes.INSTRUCTOR(instructor.username)}>
          <Image
              className='instructor-photo'
              isBgImage
              src={instructor.photo || InstructorNoPhotoWide}
              tag='div' />
          <div className='instructor-overlay'>
            <h6 className='instructor-name'>{instructor.fullName}</h6>
            <span className='instructor-followers'>
              <Icon
                  className='mr-2'
                  icon={['fas', 'heart']} />
              {instructor.followersCount}
            </span>
          </div>
        </Link>
      );
    } else {
      return (
        <div
            className='instructor-container opened'
            key={instructor.id}>
          <Image
              className='instructor-photo'
              isBgImage
              src={InstructorNoPhotoWide}
              tag='div' />
          <div className='instructor-overlay'>
            <h6 className='instructor-name'>{instructor.fullName}</h6>
          </div>
        </div>
      );
    }
  }

  render() {
    const { instructors } = this.state;

    return (
      <div className='studio-instructors'>
        <CarouselProvider
            dragEnabled
            movethreshold={profileCarouselOptions.DRAG_THRESHOLD}
            naturalSlideHeight={profileCarouselOptions.INSTRUCTORS_HEIGHT}
            naturalSlideWidth={profileCarouselOptions.INSTRUCTORS_WIDTH}
            totalSlides={instructors.length}
            visibleSlides={1}>
          <Slider>
            {instructors.map((instructors, index) => (
              <Slide
                  index={index}
                  innerClassName='instructors-slide'
                  key={index}>
                {instructors.map((instructor) => (this.renderInstructor(instructor)))}
              </Slide>
              ))}
          </Slider>
          <ButtonNext className='btn-carousel-sm next'>
            <Icon
                fixedWidth
                icon={['fas', profileCarouselOptions.BTN_ICON_RIGHT]} />
          </ButtonNext>
        </CarouselProvider>
      </div>
    );
  }
}

export default LocationInstructors;
