import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { PlansActions } from '../../actions';
import { requiresProfile } from '../../helpers';
import { SettingsBillingPage } from '../../components/pages';

class BillingContainer extends React.PureComponent {
  static propTypes = {
    fetchPlans:      PropTypes.func.isRequired,
    getSubscription: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.getSubscription();
    this.props.fetchPlans();
  }

  render() {
    return <SettingsBillingPage {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  plans:        state.plans.data,
  subscription: state.plans.userData
});

const mapDispatchToProps = (dispatch) => ({
  createSubscription: (data) => dispatch(PlansActions.SUBSCRIPTION_CREATE(data)),
  fetchPlans:         ()     => dispatch(PlansActions.FETCH()),
  getSubscription:    ()     => dispatch(PlansActions.SUBSCRIPTION_GET())
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(BillingContainer);
