import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { filter, map } from 'lodash';

import { api, extractError, toCamelCase } from '../lib';
import { SubscriptionsActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions, SubscriptionsActions } from '../actions';

function* watchActivate() {
  yield takeLatest(SubscriptionsActionTypes.ACTIVATE, function* ({ payload }) {
    try {
      const subscriptions = yield select((state) => state.subscriptions.data);

      const data = map(subscriptions, (subscription) => (
        subscription.id == payload.id
            ? { ...subscription, endsAt: payload.ends_at, status: payload.status }
            : subscription
      ));

      yield put(SubscriptionsActions.ACTIVATE_SUCCESS(data));
    } catch (error) {
      yield put(SubscriptionsActions.ACTIVATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}


function* watchCreate() {
  yield takeLatest(SubscriptionsActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.subscription.create, payload);

      yield put(SubscriptionsActions.CREATE_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(SubscriptionsActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(SubscriptionsActionTypes.CREATE_SUCCESS, function* () {
    toastr.success('Transaction Complete');
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(SubscriptionsActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchDelete() {
  yield takeLatest(SubscriptionsActionTypes.DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.subscription.destroy, payload);

      const subscriptions = yield select((state) => state.subscriptions.data);
      const data = filter(subscriptions, (subscription) => (subscription.id != payload.subscription.id));

      yield put(SubscriptionsActions.DELETE_SUCCESS(data));
      toastr.success('Subscription canceled');
    } catch (error) {
      yield put(SubscriptionsActions.DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDeleteSuccess() {
  yield takeLatest(SubscriptionsActionTypes.DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDeleteFailure() {
  yield takeLatest(SubscriptionsActionTypes.DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchFetch() {
  yield takeLatest(SubscriptionsActionTypes.FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.subscription.fetch, payload);

      yield put(SubscriptionsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(SubscriptionsActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(SubscriptionsActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(SubscriptionsActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchInstructorDelete() {
  yield takeLatest(SubscriptionsActionTypes.INSTRUCTOR_DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.instructor.subscription.destroy, payload);

      const subscriptions = yield select((state) => state.subscriptions.data);
      const data = filter(subscriptions, (subscription) => (subscription.id != payload.subscription.id));

      yield put(SubscriptionsActions.INSTRUCTOR_DELETE_SUCCESS(data));
      toastr.success('Subscription canceled');
    } catch (error) {
      yield put(SubscriptionsActions.INSTRUCTOR_DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorDeleteSuccess() {
  yield takeLatest(SubscriptionsActionTypes.INSTRUCTOR_DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorDeleteFailure() {
  yield takeLatest(SubscriptionsActionTypes.INSTRUCTOR_DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* subscriptionSaga() {
  yield all([
    watchActivate(),
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),
    watchDelete(),
    watchDeleteSuccess(),
    watchDeleteFailure(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchInstructorDelete(),
    watchInstructorDeleteSuccess(),
    watchInstructorDeleteFailure()
  ]);
}
