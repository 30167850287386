import { AutocompleterActionTypes } from '../constants/actionTypes';

export default {
  AUTOCOMPLETE: (payload) => ({
    payload, type: AutocompleterActionTypes.AUTOCOMPLETE
  }),

  AUTOCOMPLETE_FAILURE: () => ({
    type: AutocompleterActionTypes.AUTOCOMPLETE_FAILURE
  }),

  AUTOCOMPLETE_SUCCESS: (payload) => ({
    payload, type: AutocompleterActionTypes.AUTOCOMPLETE_SUCCESS
  })
};
