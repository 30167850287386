import { BookingRequestsActionTypes } from '../constants/actionTypes';

export default {
  ACCEPT: (payload) => ({
    payload, type: BookingRequestsActionTypes.ACCEPT
  }),

  ACCEPT_FAILURE: () => ({
    type: BookingRequestsActionTypes.ACCEPT_FAILURE
  }),

  ACCEPT_SUCCESS: (payload) => ({
    payload, type: BookingRequestsActionTypes.ACCEPT_SUCCESS
  }),


  CANCEL: (payload) => ({
    payload, type: BookingRequestsActionTypes.CANCEL
  }),

  CANCEL_FAILURE: () => ({
    type: BookingRequestsActionTypes.CANCEL_FAILURE
  }),

  CANCEL_SUCCESS: (payload) => ({
    payload, type: BookingRequestsActionTypes.CANCEL_SUCCESS
  }),


  DECLINE: (payload) => ({
    payload, type: BookingRequestsActionTypes.DECLINE
  }),

  DECLINE_FAILURE: () => ({
    type: BookingRequestsActionTypes.DECLINE_FAILURE
  }),

  DECLINE_SUCCESS: (payload) => ({
    payload, type: BookingRequestsActionTypes.DECLINE_SUCCESS
  }),


  FETCH: () => ({
    type: BookingRequestsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: BookingRequestsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: BookingRequestsActionTypes.FETCH_SUCCESS
  }),


  INSTRUCTORS_FETCH: () => ({
    type: BookingRequestsActionTypes.INSTRUCTORS_FETCH
  }),

  INSTRUCTORS_FETCH_FAILURE: () => ({
    type: BookingRequestsActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_FETCH_SUCCESS: (payload) => ({
    payload, type: BookingRequestsActionTypes.INSTRUCTORS_FETCH_SUCCESS
  }),

  INSTRUCTORS_UPDATE: (payload) => ({
    payload, type: BookingRequestsActionTypes.INSTRUCTORS_UPDATE
  }),

  INSTRUCTORS_UPDATE_FAILURE: () => ({
    type: BookingRequestsActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_UPDATE_SUCCESS: (payload) => ({
    payload, type: BookingRequestsActionTypes.INSTRUCTORS_UPDATE_SUCCESS
  })
};
