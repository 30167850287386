import React from 'react';
import PropTypes from 'prop-types';
import { toLower } from 'lodash';
import { isMobile } from 'react-device-detect';
import { Icon } from 'react-icons-kit';

import { ccVisa } from 'react-icons-kit/fa/ccVisa';
import { ccMastercard } from 'react-icons-kit/fa/ccMastercard';
import { ccDiscover } from 'react-icons-kit/fa/ccDiscover';
import { ccAmex } from 'react-icons-kit/fa/ccAmex';
import { ccPaypal } from 'react-icons-kit/fa/ccPaypal';
import { ccStripe } from 'react-icons-kit/fa/ccStripe';

class PaymentIcon extends React.PureComponent {
  static propTypes = {
    icon: PropTypes.string.isRequired
  }

  render() {
    const size = isMobile ? 24 : 32;

    switch (toLower(this.props.icon)) {
      case 'visa':
        return (
          <Icon
              icon={ccVisa}
              size={size} />
        );

      case 'mastercard':
        return (
          <Icon
              icon={ccMastercard}
              size={size} />
        );

      case 'discover':
        return (
          <Icon
              icon={ccDiscover}
              size={size} />
        );

      case 'amex':
        return (
          <Icon
              icon={ccAmex}
              size={size} />
        );

      case 'paypal':
        return (
          <Icon
              icon={ccPaypal}
              size={size} />
        );

      case 'stripe':
        return (
          <Icon
              icon={ccStripe}
              size={size} />
        );

      default: return null;
    }
  }
}

export default PaymentIcon;
