export default {
  CREATE:         '@@locations/CREATE',
  CREATE_FAILURE: '@@locations/CREATE_FAILURE',
  CREATE_SUCCESS: '@@locations/CREATE_SUCCESS',

  DELETE:         '@@locations/DELETE',
  DELETE_FAILURE: '@@locations/DELETE_FAILURE',
  DELETE_SUCCESS: '@@locations/DELETE_SUCCESS',

  FETCH:         '@@locations/FETCH',
  FETCH_FAILURE: '@@locations/FETCH_FAILURE',
  FETCH_SUCCESS: '@@locations/FETCH_SUCCESS',

  GET:         '@@locations/GET',
  GET_FAILURE: '@@locations/GET_FAILURE',
  GET_SUCCESS: '@@locations/GET_SUCCESS',

  UPDATE:         '@@locations/UPDATE',
  UPDATE_FAILURE: '@@locations/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@locations/UPDATE_SUCCESS'
};
