import React from 'react';
import PropTypes from 'prop-types';

class MenuItem extends React.PureComponent {
  static propTypes = {
    name:    PropTypes.string.isRequired,
    onClick: PropTypes.func
  };
  
  static defaultProps = {
    onClick: () => {}
  };

  render () {
    return (
      <button
          className='menu-item'
          onClick={this.props.onClick}>
        {this.props.name}
      </button>
    );
  }
}

export default MenuItem;