import React from 'react';
import PropTypes from 'prop-types';
import { find, groupBy, isEmpty, uniqBy } from 'lodash';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

import { UserChallengeModal, UserClassPackModal, UserGroupClassModal,
        UserPrivateGroupClassModal, UserPrivateSessionModal, UserSubscriptionModal } from '../modals';
import { InstructorFragment } from './';

class InstructorsGroup extends React.PureComponent {
  static propTypes = {
    bookings:             PropTypes.array.isRequired,
    cards:                PropTypes.array.isRequired,
    challenges:           PropTypes.array.isRequired,
    classPacks:           PropTypes.array.isRequired,
    deleteBooking:        PropTypes.func.isRequired,
    deletePrivateBooking: PropTypes.func.isRequired,
    deletePrivateSession: PropTypes.func.isRequired,
    deleteSubscription:   PropTypes.func.isRequired,
    follows:              PropTypes.array.isRequired,
    privateSessions:      PropTypes.array.isRequired,
    providers:            PropTypes.array.isRequired,
    purchasedClasses:     PropTypes.array.isRequired,
    subscriptions:        PropTypes.array.isRequired,
    updateCard:           PropTypes.func.isRequired,
    user:                 PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {

    const classPackInstructors        = props.classPacks.map((classPack) => classPack.instructor);
    const challengesInstructors       = props.challenges.map((challenge) => challenge.instructor);
    const membershipInstructors       = props.subscriptions.map((subscription) => subscription.membership.instructor);
    const onDemandsInstructors        = props.purchasedClasses.map((onDemand) => onDemand.requestedClass.instructor);
    const privateSessionsInstructors  = props.privateSessions.map((privateSession) => privateSession.instructor);

    const instructors = [...props.providers,
                         ...props.follows,
                         ...classPackInstructors,
                         ...challengesInstructors,
                         ...membershipInstructors,
                         ...onDemandsInstructors,
                         ...privateSessionsInstructors];

    return {
      bookingsByInstructor:      groupBy(props.bookings, 'event.instructor.id'),
      challengesByInstructor:    groupBy(props.challenges, 'instructor.id'),
      classPacksByInstructor:    groupBy(props.classPacks, 'instructor.id'),
      instructors:               uniqBy(instructors, 'id'),
      onDemandsByInstructor:     groupBy(props.purchasedClasses, 'requestedClass.instructor.id'),
      subscriptionsByInstructor: groupBy(props.subscriptions, 'membership.instructor.id')
    };
  }

  state = {
    bookingsByInstructor:   [],
    challengesByInstructor: [],
    classPacksByInstructor: [],

    modals: {
      challenge:         false,
      classPack:         false,
      groupClass:        false,
      privateGroupClass: false,
      privateSession:    false,
      subscription:      false
    },
    subscriptionsByInstructor: []
  }

  handleToggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  handleModal = (value = {}) => {
    const name = value.type || value.event.type;

    this.setState((prevState) => ({
      modals: {
        ...prevState.modals,
        [name]: !prevState.modals[name]
      },
      [name]: value
    }));
  }

  handleCloseModal = (type) => () => (
    this.setState((prevState) => ({ modals: { ...prevState.modals, [type]: false } }))
  )

  renderInstructors = () => (
    <React.Fragment>
      {this.state.instructors.map((instructor) => (
        <InstructorFragment
            bookings={this.state.bookingsByInstructor[instructor.id]}
            challenges={this.state.challengesByInstructor[instructor.id]}
            classPacks={this.state.classPacksByInstructor[instructor.id]}
            instructor={instructor}
            key={instructor.id}
            onModal={this.handleModal}
            paymentMethod={find(this.props.cards, ['instructorId', instructor.id])}
            purchasedClasses={this.state.onDemandsByInstructor[instructor.id]}
            subscriptions={this.state.subscriptionsByInstructor[instructor.id]}
            updateCard={this.props.updateCard}
            user={this.props.user} />
      ))}

      {this.props.subscriptions.length > 0 &&
        <small className={classNames('text-muted font-italic d-block', { 'mr-1 ml-2': isMobile })}>
          To view or cancel your membership, click orange button above
        </small>
      }

    </React.Fragment>
  );

  renderEmptyInstructors = () => (
    <div className='message-empty d-flex'>
      <p className='font-weight-normal'>
        To book your first class, go to your favorite fitness professionals onPodio hosted website
      </p>
    </div>
  );

  render() {
    const { instructors } = this.state;

    return (
      <React.Fragment>

        {isEmpty(instructors) ? this.renderEmptyInstructors() : this.renderInstructors()}

        { this.state.modals.groupClass &&
          <UserGroupClassModal
              deleteBooking={this.props.deleteBooking}
              groupClass={this.state.groupClass}
              isOpen={this.state.modals.groupClass}
              onToggle={this.handleCloseModal('groupClass')}
              user={this.props.user} />
          }

        { this.state.modals.privateGroupClass &&
          <UserPrivateGroupClassModal
              booking={this.state.privateGroupClass}
              deletePrivateBooking={this.props.deletePrivateBooking}
              isOpen={this.state.modals.privateGroupClass}
              onToggle={this.handleCloseModal('privateGroupClass')}
              user={this.props.user} />
        }

        { this.state.modals.privateSession &&
          <UserPrivateSessionModal
              deletePrivateSession={this.props.deletePrivateSession}
              isDashboard
              isOpen={this.state.modals.privateSession}
              onToggle={this.handleCloseModal('privateSession')}
              privateSession={this.state.privateSession}
              user={this.props.user} />
        }

        { this.state.modals.subscription &&
          <UserSubscriptionModal
              deleteSubscription={this.props.deleteSubscription}
              isOpen={this.state.modals.subscription}
              onToggle={this.handleCloseModal('subscription')}
              subscription={this.state.subscription} />
        }

        { this.state.modals.classPack &&
          <UserClassPackModal
              classPack={this.state.classPack}
              isOpen={this.state.modals.classPack}
              onToggle={this.handleCloseModal('classPack')} />
        }

        { this.state.modals.challenge &&
          <UserChallengeModal
              challenge={this.state.challenge}
              isOpen={this.state.modals.challenge}
              onToggle={this.handleCloseModal('challenge')}
              user={this.props.user} />
        }
      </React.Fragment>
    );
  }
}

export default InstructorsGroup;
