import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { map } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { CardsActionTypes } from '../constants/actionTypes';
import { CardsActions, ErrorActions, LoaderActions } from '../actions';

function* watchFetch() {
  yield takeLatest(CardsActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.card.fetch);

      yield put(CardsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(CardsActions.FETCH_FAILURE());
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(CardsActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(CardsActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdate() {
  yield takeLatest(CardsActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.card.update, payload);

      const nextCard = toCamelCase(response.data[0]);

      const cards = yield select((state) => state.cards.data);
      const data = map(cards, (card) => (
        card.instructorId == nextCard.instructorId ? nextCard : card
      ));

      yield put(CardsActions.UPDATE_SUCCESS(data));
      toastr.success('Card updated');
    } catch (error) {
      yield put(CardsActions.UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(CardsActionTypes.UPDATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateFailure() {
  yield takeLatest(CardsActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* cardsSaga() {
  yield all([
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure()
  ]);
}
