import { ApplicationActionTypes } from '../constants/actionTypes';

export default {
  SET_DOMAIN: (payload) => ({
    payload,
    type: ApplicationActionTypes.SET_DOMAIN
  }),

  SET_INSTRUCTOR: (payload) => ({
    payload,
    type: ApplicationActionTypes.SET_INSTRUCTOR
  }),

  SIDEBAR_HIDE: () => ({
    type: ApplicationActionTypes.SIDEBAR_HIDE
  }),

  SIDEBAR_TOGGLE: () => ({
    type: ApplicationActionTypes.SIDEBAR_TOGGLE
  })
};