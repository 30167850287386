import React from 'react';
import PropTypes from 'prop-types';
import { Button, Container, Label, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';

import { getDiff, routes } from '../../../lib';
import { Heading, Panel } from '../../layout/pages';
import { BackButton } from '../../buttons';
import { Checkbox } from '../../forms';

class HomeClassesPage extends React.PureComponent {
  static propTypes = {
    instructor:    PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    if (props.instructor.homeConfig.id && !state.isMounted) {
      const {
        showLiveClasses,
        showOndemandClasses,
        showUpcomingClassesSection
      } = props.instructor.homeConfig;

      return {
        isMounted: true,
        showLiveClasses,
        showOndemandClasses,
        showUpcomingClassesSection
      };
    }

    return state;
  }

  state = {
    isMounted:                  false,
    showLiveClasses:            true,
    showOndemandClasses:        true,
    showUpcomingClassesSection: false
  }

  handleToggle = (setting) => () => {
    this.setState((prevState) => ({
      [setting]: !prevState[setting]
    }));
  };

  handleUpdate = () => {
    const {
    showLiveClasses,
      showOndemandClasses
    } = this.state;

    const dataToUpdate = {
      showLiveClasses,
      showOndemandClasses
    };

    this.props.updateProfile({
      instructorHomeConfigAttributes: getDiff(dataToUpdate, this.props.instructor.homeConfig)
    });
  }

  render() {
    const {
      showLiveClasses,
      showOndemandClasses
    } = this.state;

    const dataToUpdate = {
      showLiveClasses,
      showOndemandClasses
    };

    const buttonIsDisabled = !getDiff(dataToUpdate, this.props.instructor.homeConfig);

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS_HOME} />}

        <Helmet>
          <title>Home Upcoming Classes</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container
              className='settings-page'
              fluid>
            <Heading
                color='secondary'
                subtitle='Home'
                title='Upcoming Classes' />
            <Panel className='settings-menu'>

              <Media className='menu-link d-block'>
                <Media body>
                  <div className='custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='live-classes'
                        isChecked={this.state.showLiveClasses}
                        isDisabled={!this.state.showUpcomingClassesSection}
                        name='live-classes'
                        onChange={this.handleToggle('showLiveClasses')} />
                    <Label
                        className='custom-control-label'
                        htmlFor='input-live-classes'>
                      Show your next 3 upcoming Live Classes
                    </Label>
                  </div>

                  <div className='custom-control custom-checkbox mt-3'>
                    <Checkbox
                        className='custom-control-input'
                        id='on-demand-classes'
                        isChecked={this.state.showOndemandClasses}
                        isDisabled={!this.state.showUpcomingClassesSection}
                        name='on-demand-classes'
                        onChange={this.handleToggle('showOndemandClasses')} />
                    <Label
                        className='custom-control-label'
                        htmlFor='input-on-demand-classes'>
                      Show your 3 latest On Demand Classes
                    </Label>
                  </div>
                </Media>
              </Media>

              <div>
                <Button
                    className='mx-3 mt-3'
                    color='success'
                    disabled={!this.state.showUpcomingClassesSection || buttonIsDisabled}
                    onClick={this.handleUpdate}>
                  Save Changes
                </Button>
              </div>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default HomeClassesPage;
