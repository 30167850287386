import React from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';

import { Tags } from '../forms';

class SelectActivities extends React.PureComponent {
  static propTypes = {
    activities: PropTypes.array.isRequired,
    isInvalid:  PropTypes.bool,
    onChange:   PropTypes.func.isRequired,
    profile:    PropTypes.object.isRequired
  }
  
  static defaultProps = {
    isInvalid: false
  }

  static getDerivedStateFromProps(props) {
    return {
      ids: props.profile.activities.map((activity) => activity.id)
    };
  }

  state = {
    ids: []
  }

  handleChange = (option, name) => {
    const { activities } = this.props.profile;

    const index = findIndex(activities, ['id', option.id]);
    if (index > -1) {
      this.props.onChange(
        [...activities.slice(0, index), ...activities.slice(index + 1, activities.length)],
        name
      );
    } else {
      this.props.onChange(
        [...activities, option],
        name
      );
    }
  }
  
  render () {
    return (
      <Tags
          color='primary'
          isInvalid={this.props.isInvalid}
          name='activities'
          onChange={this.handleChange}
          options={this.props.activities}
          selectedIds={this.state.ids} />
    );
  }
}

export default SelectActivities;