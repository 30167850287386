import { EmailSettingsActionTypes } from '../constants/actionTypes';

export default {
  GET: () => ({
    type: EmailSettingsActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: EmailSettingsActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload, type: EmailSettingsActionTypes.GET_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: EmailSettingsActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: EmailSettingsActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: EmailSettingsActionTypes.UPDATE_SUCCESS
  })
};
