import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'reactstrap';

import { Moment } from '../../lib';
import { UserGroupClassModal, UserPrivateGroupClassModal, UserPrivateSessionModal } from '../modals';
import { DailyView, WeeklyView } from './views/bookings';
import { Heading } from './';

class Bookings extends React.PureComponent {
  static propTypes = {
    bookings:             PropTypes.array.isRequired,
    deleteBooking:        PropTypes.func.isRequired,
    deletePrivateBooking: PropTypes.func.isRequired,
    deletePrivateSession: PropTypes.func.isRequired,
    isOpen:               PropTypes.bool.isRequired,
    onToggle:             PropTypes.func.isRequired,
    selectedDate:         PropTypes.object,
    selectedView:         PropTypes.string,
    user:                 PropTypes.object.isRequired
  };

  static defaultProps = {
    selectedDate: new Moment(),
    selectedView: 'week'
  };

  state = {
    modals: {
      groupClass:        false,
      privateGroupClass: false,
      privateSessions:   false
    }
  }

  handleModal = (event) => {
    this.handleToggle(event.type, event)();
  }

  handleToggle = (model, event = {}) => () => {
    this.setState((prevState) => ({
      modals: {
        [model]: !prevState.modals[model]
      },
      [model]: event
    }));
  }

  renderView = () => {
    switch (this.props.selectedView) {
      case 'day':
        return (
          <DailyView
              bookings={this.props.bookings}
              onEventClick={this.handleModal}
              selectedDate={this.props.selectedDate}
              user={this.props.user} />
        );

      case 'week':
        return (
          <WeeklyView
              bookings={this.props.bookings}
              onEventClick={this.handleModal}
              selectedDate={this.props.selectedDate}
              user={this.props.user} />
        );
    }
  };

  render () {
    const { isOpen, onToggle, user } = this.props;

    return (
      <React.Fragment>
        <div className='bookings'>
          <div className='label' />
          <div className='content'>
            <Heading
                isOpen={isOpen}
                onClick={onToggle}
                title='My bookings' />

            <Collapse isOpen={isOpen}>
              {this.renderView()}
            </Collapse>
          </div>
        </div>

        <div className='modals'>
          { this.state.modals.groupClass &&
            <UserGroupClassModal
                deleteBooking={this.props.deleteBooking}
                groupClass={this.state.groupClass}
                isOpen={this.state.modals.groupClass}
                onToggle={this.handleToggle('groupClass')}
                user={user} />
          }

          { this.state.modals.privateGroupClass &&
            <UserPrivateGroupClassModal
                deletePrivateBooking={this.props.deletePrivateBooking}
                isOpen={this.state.modals.privateGroupClass}
                onToggle={this.handleToggle('privateGroupClass')}
                privateGroupClass={this.state.privateGroupClass}
                user={user} />
          }

          { this.state.modals.privateSession &&
            <UserPrivateSessionModal
                deletePrivateSession={this.props.deletePrivateSession}
                isOpen={this.state.modals.privateSession}
                onToggle={this.handleToggle('privateSession')}
                privateSession={this.state.privateSession}
                user={user} />
          }
        </div>
      </React.Fragment>
    );
  }
}

export default Bookings;
