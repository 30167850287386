export default {
  CREATE:         '@@timeBlock/CREATE',
  CREATE_FAILURE: '@@timeBlock/CREATE_FAILURE',
  CREATE_SUCCESS: '@@timeBlock/CREATE_SUCCESS',

  DELETE:         '@@timeBlock/DELETE',
  DELETE_FAILURE: '@@timeBlock/DELETE_FAILURE',
  DELETE_SUCCESS: '@@timeBlock/DELETE_SUCCESS',
  
  FETCH:         '@@timeBlock/FETCH',
  FETCH_FAILURE: '@@timeBlock/FETCH_FAILURE',
  FETCH_SUCCESS: '@@timeBlock/FETCH_SUCCESS',
  
  GET:         '@@timeBlock/GET',
  GET_FAILURE: '@@timeBlock/GET_FAILURE',
  GET_SUCCESS: '@@timeBlock/GET_SUCCESS',
  
  RECURRING_CREATE:         '@@timeBlock/CREATE_RECURRING',
  RECURRING_CREATE_FAILURE: '@@timeBlock/CREATE_RECURRING_FAILURE',
  RECURRING_CREATE_SUCCESS: '@@timeBlock/CREATE_RECURRING_SUCCESS',

  RECURRING_DELETE:         '@@timeBlock/RECURRING_DELETE',
  RECURRING_DELETE_FAILURE: '@@timeBlock/RECURRING_DELETE_FAILURE',
  RECURRING_DELETE_SUCCESS: '@@timeBlock/RECURRING_DELETE_SUCCESS',

  UPDATE:         '@@timeBlock/UPDATE',
  UPDATE_FAILURE: '@@timeBlock/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@timeBlock/UPDATE_SUCCESS'
};