import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { trim } from 'lodash';

import { Input } from '../inputs';

class Username extends React.PureComponent {
  static propTypes = {
    error:      PropTypes.string,
    isInvalid:  PropTypes.bool,
    onChange:   PropTypes.func,
    validation: PropTypes.object,
    value:      PropTypes.string
  }

  static defaultProps = {
    error:      '',
    isInvalid:  false,
    onChange:   () => {},
    validation: { valid: true },
    value:      ''
  }

  handleChange = (value, inputName) => (
    this.props.onChange(trim(value), inputName)
  )

  render () {
    const { value, error, validation } = this.props;

    const isInvalid = this.props.isInvalid ||
      !validation.instructor ||
      (validation.instructor && !validation.instructor.valid);

    return (
      <InputGroup className='input-group-username'>
        <InputGroupAddon addonType='prepend'>
          <InputGroupText>onPodio.com/</InputGroupText>
        </InputGroupAddon>
        <Input
            id='username'
            isInvalid={isInvalid}
            name='username'
            onChange={this.handleChange}
            type='text'
            value={value} />
        { error &&
          <FormFeedback tooltip>{error}</FormFeedback>
        }

        { !validation.instructor || (validation.instructor && !validation.instructor.valid) && !error &&
          <FormFeedback tooltip>Username has already been taken</FormFeedback>
        }
      </InputGroup>
    );
  }
}

export default Username;
