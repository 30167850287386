/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { isEmpty, omit } from 'lodash';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { selectFieldStyle } from '../../constants';
import { Moment, getTimeZone, routes } from '../../lib';
import { Autocompleter, Button, Checkbox, Form, MaskedInput, Radio } from '../forms';
import { AsyncSelect, DatePicker, FileUploader, Input, PartnersSelect, TimePicker } from '../inputs';
import { Validator } from '../../lib';
import { AutocompleterContainer } from '../../containers';
import { minuteMask, weekMask } from '../../constants/masks';

class AddGroupClassModal extends React.PureComponent {
  static propTypes = {
    createGroupClass:          PropTypes.func.isRequired,
    createRecurringGroupClass: PropTypes.func.isRequired,
    fetchPlaylists:            PropTypes.func,
    instructor:                PropTypes.object.isRequired,
    isOpen:                    PropTypes.bool.isRequired,
    onToggle:                  PropTypes.func.isRequired,
    playlists:                 PropTypes.array.isRequired,
    user:                      PropTypes.object.isRequired
  };

  static defaultProps = {
    fetchPlaylists: () => {}
  };

  state = {
    errors: {},

    groupClass: {
      date:          '',
      digitalLink:   '',
      duration:      '',
      imageType:     'photo',
      locationId:    '',
      name:          '',
      numberOfWeeks: '',
      partnerId:     '',
      recurring:     false,
      time:          ''
    },
    isImageSectionOpen: false,

    uploads: {}
  };

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      errors:     omit(this.state.errors, inputName),
      groupClass: {
        ...prevState.groupClass,
        [inputName]: value
      }
    }));
  };

  handleSelect = (inputName) => (option) => {
    this.setState((prevState) => ({
      groupClass: {
        ...prevState.groupClass,
        [inputName]: option.value
      }
    }));
  };

  handleSelectPlaylist = (value) => {
    this.setState((prevState) => ({
      groupClass: {
        ...prevState.groupClass,
        playlistAttributes: {
          name:       value.name,
          playlistId: value.id
        }
      }
    }));
  }

  handleSubmit = () => {
    const { groupClass } = this.state;

    this.handleValidate().then(() => {
      const stamp = [groupClass.date, groupClass.time].join(' ');

      const startsAt = new Moment.tz(stamp, getTimeZone(this.props.user.timeZone)).format();

      if (this.state.groupClass.recurring) {
        this.props.createRecurringGroupClass({ groupClass: { ...groupClass, startsAt } });
      } else {
        this.props.createGroupClass({ groupClass: { ...groupClass, startsAt } });
      }

      return this.setState({ groupClass: {} }, this.handleToggle);
    }).catch((errors) => {
      return this.setState({ errors });
    });
  };

  handleToggle = () => {
    this.setState({ errors: {} }, () => {
      Validator.clear();
      this.props.onToggle();
    });
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const { groupClass } = this.state;

    const errors = Validator.validate([
      ['date',     Validator.concepts.isFilled, [groupClass.date]],
      ['duration', Validator.concepts.isFilled, [groupClass.duration]],
      ['name',     Validator.concepts.isFilled, [groupClass.name]],
      ['time',     Validator.concepts.isFilled, [groupClass.time]],
      ['location', Validator.concepts.isFilled, [groupClass.locationId]],
      ['numberOfWeeks', Validator.concepts.weeksValidator, [groupClass.numberOfWeeks, groupClass.recurring]],
      ['partnerId', Validator.concepts.isFilled, [groupClass.partnerId], groupClass.imageType !== 'partner']
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  })

  loadPlaylists = () => {
    const { playlists } = this.props;

    if (isEmpty(playlists)) {
      this.props.fetchPlaylists();
    }

    return playlists;
  }

  handleImageSwitch = (value, inputName) => {
    this.setState((prevState) => ({
      groupClass: {
        ...prevState.groupClass,
        [inputName]: value
      }
    }));
  }

  handleOpenImage = (e) => {
    e.preventDefault();

    this.setState({
      isImageSectionOpen: !this.state.isImageSectionOpen
    });
  }

  handleDeleteImage = () => {
    this.setState((prevState) => (
      {
        ...prevState,
        uploads: {
          ...prevState.uploads,
          image: {}
        }
      }
    ));
  }

  render() {
    const { user } = this.props;
    const { groupClass, uploads } = this.state;

    const isImageUploaded = !isEmpty(uploads.image) || !!groupClass.image;

    const formatPlaylistOption = ({ name }) => (
      <React.Fragment>
        <Icon
            className='mr-2'
            fixedWidth
            icon={['fab', 'spotify']}
            style={{ color: '#1DB954' }} />
        {name}
      </React.Fragment>
    );

    return (
      <Modal
          className='event-modal'
          isOpen={this.props.isOpen}
          toggle={this.handleToggle}>

        <ModalHeader toggle={this.handleToggle}>
          New Studio Group Class
        </ModalHeader>

        <ModalBody>
          <Form className='form-default'>
            <FormGroup>
              <Label
                  className='required'
                  for='location'>
                Location
              </Label>

              <AutocompleterContainer>
                <Autocompleter
                    hasInitialValue
                    instructor={this.props.instructor}
                    isInvalid={!!this.state.errors.location}
                    name='location'
                    onChange={this.handleChange}
                    onSelect={this.handleSelect('locationId')}
                    placeholder='Select studio location'
                    ref={(ref) => this.locationInput = ref}
                    subject='locations'
                    value={this.state.groupClass.location}>
                  { this.locationInput && !this.locationInput.state.options.length &&
                    <small className='text-danger'>
                        You do not have any Locations. Go to&nbsp;
                      <Link
                          className='text-primary'
                          to={routes.LOCATIONS}>
                        My Locations
                      </Link>
                    </small>
                  }
                </Autocompleter>
              </AutocompleterContainer>
            </FormGroup>

            <FormGroup>
              <Label
                  className='required'
                  for='input-name'>
                Class Name
              </Label>

              <Input
                  id='name'
                  isInvalid={!!this.state.errors.name}
                  maxLength={60}
                  name='name'
                  onChange={this.handleChange}
                  value={this.state.groupClass.name} />
            </FormGroup>

            <FormGroup>
              <Label
                  className='required'
                  for='input-date'>
                Date
              </Label>

              <DatePicker
                  id='date'
                  isInvalid={!!this.state.errors.date}
                  name='date'
                  onChange={this.handleChange}
                  value={this.state.groupClass.date} />
            </FormGroup>

            <Row form>
              <Col>
                <FormGroup>
                  <Label
                      className='required'
                      for='input-time'>
                    Start Time
                  </Label>

                  <TimePicker
                      id='time'
                      isInvalid={!!this.state.errors.time}
                      name='time'
                      onChange={this.handleChange}
                      value={this.state.groupClass.time} />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label
                      className='required'
                      for='input-duration'>
                    Duration
                  </Label>

                  <MaskedInput
                      id='duration'
                      inputMode='number'
                      isInvalid={!!this.state.errors.duration}
                      mask={minuteMask}
                      name='duration'
                      onChange={this.handleChange}
                      placeholder=' mins'
                      type='number'
                      value={String(this.state.groupClass.duration)} />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for='input-recurring'>
                Recur Class Weekly
              </Label>
              <div className='d-flex'>
                <div className='custom-control custom-checkbox checkbox-lg'>
                  <Checkbox
                      className='custom-control-input'
                      id='recurring'
                      isChecked={this.state.groupClass.recurring}
                      name='recurring'
                      onChange={this.handleChange} />
                  <Label
                      className='custom-control-label'
                      htmlFor='input-recurring' />
                </div>

                <MaskedInput
                    className='ml-2'
                    id='numberOfWeeks'
                    inputMode='number'
                    isDisabled={!this.state.groupClass.recurring}
                    isInvalid={!!this.state.errors.numberOfWeeks}
                    mask={weekMask}
                    name='numberOfWeeks'
                    onChange={this.handleChange}
                    placeholder=' weeks'
                    type='number'
                    value={String(this.state.groupClass.numberOfWeeks)} />
              </div>
            </FormGroup>

            <FormGroup>
              <Label for='input-digitalLink'>
                Booking Link
              </Label>

              <Input
                  id='digitalLink'
                  name='digitalLink'
                  onChange={this.handleChange}
                  value={this.state.groupClass.digitalLink} />
            </FormGroup>

            <FormGroup>
              <Label
                  for='input-playlist'>
                Music Playlist
              </Label>

              <AsyncSelect
                  formatOptionLabel={formatPlaylistOption}
                  hasDefaultOptions
                  isDisabled={!user.oauth.spotify}
                  labelName='name'
                  loadOptions={this.loadPlaylists}
                  name='playlist'
                  onChange={this.handleSelectPlaylist}
                  styles={selectFieldStyle.playlists}
                  value={groupClass.playlistAttributes}
                  valueName='id' />
            </FormGroup>

            <a
                className='form-collapse-toggle dropdown-toggle'
                href='#'
                onClick={this.handleOpenImage}>
              Add Class Image
            </a>
            <Collapse isOpen={this.state.isImageSectionOpen}>
              <FormGroup>
                <Row form>
                  <Col xs={5}>
                    <div className='custom-control custom-radio mt-2'>
                      <Radio
                          className='custom-control-input'
                          id='photo'
                          isChecked={this.state.groupClass.imageType === 'photo'}
                          name='imageType'
                          onChange={this.handleImageSwitch}
                          value='photo' />
                      <Label
                          className='custom-control-label'
                          for='input-photo'>
                        Use Profile Pic
                      </Label>
                    </div>

                    <div className='custom-control custom-radio mt-2'>
                      <Radio
                          className='custom-control-input'
                          id='image'
                          isChecked={this.state.groupClass.imageType === 'image'}
                          name='imageType'
                          onChange={this.handleImageSwitch}
                          value='image' />
                      <Label
                          className='custom-control-label'
                          for='input-image'>
                        Upload image
                      </Label>
                    </div>

                    <div className='custom-control custom-radio mt-2'>
                      <Radio
                          className='custom-control-input'
                          id='partner'
                          isChecked={this.state.groupClass.imageType === 'partner'}
                          name='imageType'
                          onChange={this.handleImageSwitch}
                          value='partner' />
                      <Label
                          className='custom-control-label'
                          for='input-partner'>
                        Select Partner Program
                      </Label>
                    </div>

                    <div className='custom-control custom-radio mt-2'>
                      <Radio
                          className='custom-control-input'
                          id='none'
                          isChecked={this.state.groupClass.imageType === 'none'}
                          name='imageType'
                          onChange={this.handleImageSwitch}
                          value='none' />
                      <Label
                          className='custom-control-label'
                          for='input-none'>
                        No image
                      </Label>
                    </div>
                  </Col>
                  <Col xs={7}>
                    <div className='preview image-preview'>
                      { this.state.groupClass.imageType === 'photo' &&
                        <img
                            alt={this.props.instructor.firstName}
                            src={this.props.instructor.photo.lg} />
                      }
                      { this.state.groupClass.imageType === 'image' &&
                        <FileUploader
                            className='mb-4'
                            error={this.state.errors.image}
                            fileTypes={['image/jpeg', 'image/png']}
                            hasCropper
                            isInvalid={!!this.state.errors.image}
                            maxFileSize='5MB'
                            name='image'
                            onChange={this.handleChange}
                            onDelete={this.handleDeleteImage}
                            placeholder='Drop image'
                            size='sm' />
                      }
                      { this.state.groupClass.imageType === 'partner' &&
                        <PartnersSelect
                            hasPreview
                            isInvalid={!!this.state.errors.partnerId}
                            name='partnerId'
                            onChange={this.handleChange}
                            value={groupClass.partnerId} />
                      }
                    </div>
                  </Col>
                </Row>
              </FormGroup>
            </Collapse>
            
            <Button
                className='mt-4'
                color='primary'
                isBlock
                isDisabled={groupClass.imageType === 'image' && !isImageUploaded}
                onClick={this.handleSubmit}
                size='lg'
                type='button'>
              Create
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export default AddGroupClassModal;
