import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

class FollowButton extends React.PureComponent {
  static propTypes = {
    followersCount: PropTypes.number,
    isDisabled:     PropTypes.bool,
    isFollowing:    PropTypes.bool,
    onClick:        PropTypes.func
  }

  static defaultProps = {
    followersCount: 0,
    isDisabled:     false,
    isFollowing:    false,
    onClick:        () => {}
  }

  render () {
    const style = this.props.isFollowing ? 'fas' : 'far';

    return (
      <button
          className='btn-follow'
          disabled={this.props.isDisabled}
          onClick={this.props.onClick}>
        <Icon
            icon={[style, 'heart']} />
        <span className='followers'>{this.props.followersCount}</span>
      </button>
    );
  }
}

export default FollowButton;