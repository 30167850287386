import { connect } from 'react-redux';

import { PrivateSessionTypesActions } from '../../actions';
import { SessionTypeModal } from '../../components/modals/instructor';

const mapStateToProps = (state) => ({
  isLoading:   state.loader.sessionTypes.isLoading,
  sessionType: state.privateSessionTypes.form,
  user:        state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  clearSessionType:  ()     => dispatch(PrivateSessionTypesActions.CLEAR()),
  createSessionType: (data) => dispatch(PrivateSessionTypesActions.CREATE(data)),
  deleteSessionType: (data) => dispatch(PrivateSessionTypesActions.DELETE(data)),
  updateSessionType: (data) => dispatch(PrivateSessionTypesActions.UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SessionTypeModal);
