import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';
import { sortBy } from 'lodash';

import { Calendr, Moment } from '../../lib';
import { InstructorsGroup, OutcomingBookingRequests } from '../dashboard';
import { Heading, Panel } from '../layout/pages';

class DashboardPage extends React.PureComponent {
  static propTypes = {
    bookingRequests:      PropTypes.array.isRequired,
    bookings:             PropTypes.array.isRequired,
    cancelBookingRequest: PropTypes.func.isRequired,
    cards:                PropTypes.array.isRequired,
    challenges:           PropTypes.array.isRequired,
    classPacks:           PropTypes.array.isRequired,
    deleteBooking:        PropTypes.func.isRequired,
    deletePrivateBooking: PropTypes.func.isRequired,
    deletePrivateSession: PropTypes.func.isRequired,
    deleteSubscription:   PropTypes.func.isRequired,
    follows:              PropTypes.array.isRequired,
    privateBookings:      PropTypes.array.isRequired,
    privateSessions:      PropTypes.array.isRequired,
    providers:            PropTypes.array.isRequired,
    purchasedClasses:     PropTypes.array.isRequired,
    subscriptions:        PropTypes.array.isRequired,
    updateCard:           PropTypes.func.isRequired,
    user:                 PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props) {
    const combineBookings = Calendr.combineBookings(props.bookings, props.privateBookings, props.privateSessions);
    const range = Moment.range(new Moment());

    const bookings = Calendr.bookingsInRange(combineBookings, props.user.timeZone, range, true).slice(0, 5);
    const sortBookings = sortBy(bookings, 'event.startsAt');

    return { bookings: sortBookings };
  }

  state = {
    bookings: [],
    section:  ''
  }

  changeSection = (section) => {
    this.setState((prevState) => {
      if (prevState.section !== section) {
        return { section };
      } else {
        return { section: '' };
      }
    });
  }


  render() {
    const { user } = this.props;

    return (
      <div className='inner-wrapper portal dashboard'>
        <Helmet>
          <title>Dashboard</title>
        </Helmet>

        <Container fluid>
          <Heading
              subtitle='Client Portal'
              title='Dashboard' />
          <Panel>
            <div className='dashboard'>
              <h5 className='title-greeting'>
                Howdy, {user.firstName}!
              </h5>
              <InstructorsGroup
                  bookings={this.state.bookings}
                  cards={this.props.cards}
                  challenges={this.props.challenges}
                  changeSection={this.changeSection}
                  classPacks={this.props.classPacks}
                  deleteBooking={this.props.deleteBooking}
                  deletePrivateBooking={this.props.deletePrivateBooking}
                  deletePrivateSession={this.props.deletePrivateSession}
                  deleteSubscription={this.props.deleteSubscription}
                  follows={this.props.follows}
                  privateSessions={this.props.privateSessions}
                  providers={this.props.providers}
                  purchasedClasses={this.props.purchasedClasses}
                  section={this.state.section}
                  subscriptions={this.props.subscriptions}
                  updateCard={this.props.updateCard}
                  user={this.props.user} />

              <OutcomingBookingRequests
                  bookingRequests={this.props.bookingRequests}
                  cancelBookingRequest={this.props.cancelBookingRequest}
                  changeSection={this.changeSection}
                  section={this.state.section}
                  user={this.props.user} />
            </div>
          </Panel>
        </Container>
      </div>
    );
  }
}

export default DashboardPage;
