import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../helpers';
import { EditProfilePage } from '../components/pages';
import {
  ActivityActions,
  OAuthActions,
  ProfileActions,
  ProfileDataActions,
  UserActions,
  ValidationActions
} from '../actions';

class EditProfileContainer extends React.PureComponent {
  static propTypes = {
    getActivities:  PropTypes.func.isRequired,
    getProfileData: PropTypes.func.isRequired,
    history:        PropTypes.object.isRequired,
    instructor:     PropTypes.object.isRequired,
    location:       PropTypes.object.isRequired,
    updateProfile:  PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.getProfileData();
    this.props.getActivities();
  }

  render() {
    return <EditProfilePage {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  activities:  state.activities,
  instructor:  state.profile.data,
  location:    state.location,
  profileData: state.profileData,
  user:        state.user.data,
  validation:  state.validation
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser:        () =>     dispatch(UserActions.FETCH()),
  getActivities:    () =>     dispatch(ActivityActions.GET_ALL()),
  getProfileData:   () =>     dispatch(ProfileDataActions.GET()),
  oAuthLogout:      (data) => dispatch(OAuthActions.LOGOUT(data)),
  resetValidation:  () =>     dispatch(ValidationActions.RESET({ subject: 'instructor' })),
  updateProfile:    (data) => dispatch(ProfileActions.UPDATE(data)),
  validateUsername: (data) => dispatch(ValidationActions.VALIDATE(data, { subject: 'instructor' }))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(EditProfileContainer);
