import { connect } from 'react-redux';

import { ChallengesActions, SamplesActions } from '../../actions';
import { ChallengeModal } from '../../components/modals/instructor';

const mapStateToProps = (state) => ({
  challenge: state.challenges.form,
  isLoading: state.loader.challenges.isLoading,
  user:      state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  cancelChallenge: (data) => dispatch(ChallengesActions.INSTRUCTORS_DELETE(data)),
  createChallenge: (data) => dispatch(ChallengesActions.INSTRUCTORS_CREATE(data)),
  deleteSample:    (data) => dispatch(SamplesActions.DELETE_CHALLENGE(data)),
  updateChallenge: (data) => dispatch(ChallengesActions.INSTRUCTORS_UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChallengeModal);
