import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError, toCamelCase } from '../lib';
import { ProfileDataActionTypes } from '../constants/actionTypes';
import { ErrorActions, ProfileDataActions } from '../actions';

export function* watchGet() {
  yield takeLatest(ProfileDataActionTypes.GET, function* () {
    try {
      const response = yield call(api.instructor.profileData.get);

      yield put(ProfileDataActions.GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ProfileDataActions.GET_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* profileDataSaga() {
  yield all([
    watchGet()
  ]);
}
