import { connect } from 'react-redux';
import { TransactionsActions } from '../../../../actions';

import MembershipsTable from './membershipsTable';

const mapStateToProps = (state) => ({
  isLoading:    state.transactions.isLoading,
  transactions: state.transactions.memberships
});

const mapDispatchToProps = (dispatch) => ({
  fetchMemberships: ()     => dispatch(TransactionsActions.MEMBERSHIPS_FETCH()),
  updateMembership: (data) => dispatch(TransactionsActions.MEMBERSHIP_UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(MembershipsTable);
