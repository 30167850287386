import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError, toCamelCase } from '../lib';
import { ActivityActionTypes } from '../constants/actionTypes';
import { ActivityActions, ErrorActions } from '../actions';

export function* watchGetAll() {
  yield takeLatest(ActivityActionTypes.GET_ALL, function* () {
    try {
      const response = yield call(api.activity.get);

      yield put(ActivityActions.GET_ALL_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ActivityActions.GET_ALL_FAILURE());

      /* error */
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* activitySaga() {
  yield all([
    watchGetAll()
  ]);
}
