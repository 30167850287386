import { BookingRequestsActionTypes } from '../constants/actionTypes';

const initialState = {
  data:           [],
  instructorData: [],
  lastAction:     ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case BookingRequestsActionTypes.ACCEPT:
      return newState;

    case BookingRequestsActionTypes.ACCEPT_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case BookingRequestsActionTypes.ACCEPT_FAILURE:
      return newState;


    case BookingRequestsActionTypes.DECLINE:
      return newState;

    case BookingRequestsActionTypes.DECLINE_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case BookingRequestsActionTypes.DECLINE_FAILURE:
      return newState;


    case BookingRequestsActionTypes.CANCEL:
      return newState;

    case BookingRequestsActionTypes.CANCEL_SUCCESS:
      return { ...newState, data: action.payload };

    case BookingRequestsActionTypes.CANCEL_FAILURE:
      return newState;


    case BookingRequestsActionTypes.FETCH:
      return newState;

    case BookingRequestsActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case BookingRequestsActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case BookingRequestsActionTypes.INSTRUCTORS_FETCH:
      return newState;

    case BookingRequestsActionTypes.INSTRUCTORS_FETCH_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case BookingRequestsActionTypes.INSTRUCTORS_FETCH_FAILURE:
      return { ...newState, instructorData: initialState.data };

      case BookingRequestsActionTypes.INSTRUCTORS_UPDATE:
        return newState;

      case BookingRequestsActionTypes.INSTRUCTORS_UPDATE_SUCCESS:
        return { ...newState, instructorData: action.payload };

      case BookingRequestsActionTypes.INSTRUCTORS_UPDATE_FAILURE:
        return { ...newState, instructorData: initialState.data };

    default: return state;
  }
};
