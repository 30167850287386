import { FollowsActionTypes } from '../constants/actionTypes';

const initialState = {
  instructors: [],
  lastAction:  '',
  studios:     []
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case FollowsActionTypes.FETCH_INSTRUCTORS:
      return newState;

    case FollowsActionTypes.FETCH_INSTRUCTORS_SUCCESS:
      return { ...newState, instructors: action.payload };

    case FollowsActionTypes.FETCH_INSTRUCTORS_FAILURE:
      return { ...newState, instructors: initialState.instructors };


    case FollowsActionTypes.FETCH_STUDIOS:
      return newState;

    case FollowsActionTypes.FETCH_STUDIOS_SUCCESS:
      return { ...newState, studios: action.payload };

    case FollowsActionTypes.FETCH_STUDIOS_FAILURE:
      return { ...newState, studios: initialState.studios };


    case FollowsActionTypes.FOLLOW_INSTRUCTOR:
      return newState;

    case FollowsActionTypes.FOLLOW_INSTRUCTOR_SUCCESS:
      return { ...newState, instructors: action.payload };

    case FollowsActionTypes.FOLLOW_INSTRUCTOR_FAILURE:
      return newState;


    case FollowsActionTypes.FOLLOW_STUDIO:
      return newState;

    case FollowsActionTypes.FOLLOW_STUDIO_SUCCESS:
      return { ...newState, studios: action.payload };

    case FollowsActionTypes.FOLLOW_STUDIO_FAILURE:
      return newState;


    case FollowsActionTypes.UNFOLLOW_INSTRUCTOR:
      return newState;

    case FollowsActionTypes.UNFOLLOW_INSTRUCTOR_SUCCESS:
      return { ...newState, instructors: action.payload };

    case FollowsActionTypes.UNFOLLOW_INSTRUCTOR_FAILURE:
      return newState;


    case FollowsActionTypes.UNFOLLOW_STUDIO:
      return newState;

    case FollowsActionTypes.UNFOLLOW_STUDIO_SUCCESS:
      return { ...newState, studios: action.payload };

    case FollowsActionTypes.UNFOLLOW_STUDIO_FAILURE:
      return newState;

    default: return state;
  }
};
