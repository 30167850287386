/* global process */
/* eslint-disable immutable/no-mutation */
import loadGoogleMapsApi from 'load-google-maps-api';

import { api } from './';
import { toCamelCase } from './';

const { GMAPS_API_KEY } = process.env;

class PlacesAutocomplete {
  constructor (restrictType, responseFormat) {
    this.googleMaps = {};
    this.predictions = [];

    this.responseFormat = responseFormat;

    this.options = {
      types: [restrictType]
    };

    this.loadApi()
      .then((googleApi) => {
        return this.googleMaps = googleApi;
      })
      .catch((error) => {
        throw error;
      });
  }

  async loadApi () {
    return await loadGoogleMapsApi({
      key:       GMAPS_API_KEY,
      language:  'en',
      libraries: ['places']
    });
  }

  getPredictions = (inputValue) => {
    const autocomplete = new this.googleMaps.places.AutocompleteService();

    autocomplete.getPlacePredictions({ input: inputValue, types: this.options.types }, (response) => {
      this.predictions = response;
    });
    return this.predictions;
  }

  getFormattedOptions = (inputValue) => {
    this.getPredictions(inputValue);

    if (!this.predictions) return null;

    switch (this.responseFormat) {
      case 'addresses':
        return this.toAddresses(this.predictions);

      case 'cities':
        return this.toCities(this.predictions);

      default:
        return null;
    }
  }

  toAddresses = (response) => {
    return response.map((place) => ({
      city:  place.structured_formatting.secondary_text,
      label: place.structured_formatting.main_text,
      value: place.place_id
    }));
  }

  toCities = (response) => {
    return response.map((place) => ({
      label: place.description,
      value: place.description
    }));
  }

  async geocodeRequest (query, placesApi = true) {
    const payload = {
      subject: 'geocoded'
    };
    Object.assign(payload, placesApi ? { place_id: query } : { query: query } );

    const response = await api.autocompleter.fetch(payload);

    return toCamelCase(response);
  }
}

export default PlacesAutocomplete;
