/* global analytics */
/* eslint-disable require-yield */

import { all, takeLatest } from 'redux-saga/effects';
import { isEmpty } from 'lodash';

import { FEES } from '../constants';
import { SegmentActionTypes } from '../constants/actionTypes';

function* addedAdditionalInfo() {
  yield takeLatest(SegmentActionTypes.ADDED_ADDITIONAL_INFO, function* ({ payload }) {
    analytics.track('Added Additional Information', {
      classesPerWeek:  payload.classesPerWeek,
      expectedClasses: payload.expectedClasses,
      heardFrom:       payload.heardFrom,
      phone:           payload.phone,
      refferal:        payload.refferal
    });
  });
}

function* identify() {
  yield takeLatest(SegmentActionTypes.IDENTIFY, function* ({ payload }) {
    analytics.identify(payload.id, {
      bookings: !isEmpty(payload.instructor.additionalInformation)
                ? payload.instructor.additionalInformation.expectedClasses : '',
      email: payload.email,
      name:  [payload.firstName, payload.lastName].join(' '),
      phone: !isEmpty(payload.instructor.additionalInformation)
             ? payload.instructor.additionalInformation.phone : '',
      type:     payload.instructor ? 'instructor' : 'user',
      username: payload.instructor && payload.instructor.username
    });
  });
}

function* createProfile() {
  yield takeLatest(SegmentActionTypes.CREATE_PROFILE, function* ({ payload }) {
    analytics.track('Created Profile', {
      firstName:      payload.firstName,
      fitnessTagLine: payload.tagline,
      lastName:       payload.lastName,
      photo:          payload.photo.sm,
      type:           'user',
      username:       payload.username
    });

    analytics.identify(payload.id, {
      email: payload.email,
      name:  [payload.firstName, payload.lastName].join(' '),
      type:  'instructor'
    });
  });
}

function* createClass() {
  yield takeLatest(SegmentActionTypes.CREATE_CLASS, function* ({ payload }) {
    analytics.track('Created Class', {
      name:  payload.name,
      price: payload.feee ? payload.fee : 'free',
      time:  payload.startsAt,
      type:  'instructor'
    });
  });
}

function* createRecurringClass() {
  yield takeLatest(SegmentActionTypes.CREATE_RECURRING_CLASS, function* ({ payload }) {
    const privateGroupClass = payload.recurrings[0];

    analytics.track('Created Recurring Class', {
      interval: payload.interval,
      name:     payload.name,
      price:    privateGroupClass.fee ? privateGroupClass.fee : 'free',
      time:     payload.startsAt,
      times:    payload.total,
      type:     'instructor'
    });
  });
}

function* newClassBook() {
  yield takeLatest(SegmentActionTypes.NEW_CLASS_BOOK, function* ({ payload }) {
    analytics.track('New Class Booked', {
      classType:  payload.type,
      instructor: {
        id:   payload.instructor.id,
        name: payload.instructor.name
      },
      name:    payload.name,
      price:   payload.fee == '' ? 0 : payload.fee,
      revenue: payload.fee == '' ? 0 : FEES.charge(payload.fee),
      time:    payload.startsAt,
      type:    payload.user.instructor ? 'instructor' : 'user'
    });
  });
}

function* stripeConnect() {
  yield takeLatest(SegmentActionTypes.STRIPE_CONNECT, function* ({ payload }) {
    analytics.track('Stripe connected', {
      type:   'instructor',
      userId: payload.id
    });
  });
}

function* zoomConnect() {
  yield takeLatest(SegmentActionTypes.ZOOM_CONNECT, function* ({ payload }) {
    analytics.track('Zoom connected', {
      type:   'instructor',
      userId: payload.id
    });
  });
}

function* createTimeBlock() {
  yield takeLatest(SegmentActionTypes.CREATE_TIME_BLOCK, function* ({ payload }) {
    analytics.track('Created Time Block', {
      duration:  payload.duration,
      name:      payload.name,
      recurring: payload.recurring,
      startsAt:  payload.startsAt,
      type:      'instructor'
    });
  });
}

function* signIn() {
  yield takeLatest(SegmentActionTypes.SIGN_IN, function* ({ payload }) {
    analytics.track('Signed In', {
      email: payload.email,
      type:  payload.instructor ? 'instructor' : 'user'
    });
  });
}

function* signUp() {
  yield takeLatest(SegmentActionTypes.SIGN_UP, function* ({ payload }) {
    analytics.track('Signed Up', {
      email:     payload.email,
      firstName: payload.firstName,
      lastName:  payload.lastName,
      type:      'user'
    });
  });
}

function* siteShare() {
  yield takeLatest(SegmentActionTypes.SITE_SHARE, function* () {
    analytics.track('Site Share', {
      type: 'instructor'
    });
  });
}

export default function* segmentSaga() {
  yield all([
    addedAdditionalInfo(),
    identify(),
    createProfile(),
    createClass(),
    createRecurringClass(),
    newClassBook(),
    stripeConnect(),
    zoomConnect(),
    createTimeBlock(),
    signIn(),
    signUp(),
    siteShare()
  ]);
}
