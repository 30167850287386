import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';

import { Button } from '../forms';
import { BackButton } from '../buttons';
import { routes } from '../../lib';
import { LocationModalContainer } from '../../containers/portal';
import { Heading, Panel } from '../layout/pages';
import MainButton from './portal/menu/mainButton';

class LocationsPage extends React.PureComponent {
  static propTypes = {
    getLocation: PropTypes.func.isRequired,
    locations:   PropTypes.array.isRequired
  };

  state = {
    isModalOpen: false,
    isNewRecord: true
  };

  handleToggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  handleNew = () => {
    this.setState({ isNewRecord: true }, this.handleToggle);
  };

  handleEdit = (id) => () => {
    this.props.getLocation({ id });
    this.setState({ isNewRecord: false }, this.handleToggle);
  };

  renderLocation = (location) => (
    <div
        className='list-item'
        key={location.id}>
      <div className='location-info'>
        <span className='name'>
          {location.name}
        </span>

        <span className='address'>
          {location.address}
        </span>
      </div>

      <Button
          onClick={this.handleEdit(location.id)}
          size='sm'>
        Edit
      </Button>
    </div>
  );

  renderEmpty = () => (
    <div className='message-empty'>
      <p>Create your first Location.</p>

      <p>You can add a Location by using the <span className='text-primary'>+</span> button below.</p>
    </div>
  );

  render() {
    const { locations } = this.props;

    return (
      <div className='inner-wrapper portal locations'>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Edit My Locations</title>
        </Helmet>

        <Container fluid>
          <Heading
              color='secondary'
              subtitle='Creator Portal'
              title='My Locations' />
          <Panel className='portal-list locations-list'>
            {isEmpty(locations) ? this.renderEmpty() : locations.map(this.renderLocation)}
          </Panel>

          <MainButton onClick={this.handleNew} />
        </Container>

        <LocationModalContainer
            isNewRecord={this.state.isNewRecord}
            isOpen={this.state.isModalOpen}
            onToggle={this.handleToggle} />
      </div>
    );
  }
}

export default LocationsPage;
