/* eslint-disable switch-case/no-case-curly */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Label, Spinner } from 'reactstrap';
import { isEmpty } from 'lodash';

import { Button } from '../forms';
import { currencyFormat } from '../../lib';

class Transactions extends React.PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    summary:   PropTypes.object.isRequired,
    user:      PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const { user, summary } = props;
    const { dropIn, memberships, classPacks } = summary;
    const { period } = state;

    const currency = user.currency;

    if (!isEmpty(summary)) {
      const totalSales = dropIn[period].total.amount + memberships[period].amount + classPacks[period].amount;

      return {
        currency,
        summary,
        totalSales
      };
    }

    return state;
  }

  state = {
    period:     'allTime',
    summary:    {},
    totalSales: 0
  }

  changePeriod = (period) => () => (
    this.setState({ period })
  )

  renderSpinner = () => (
    <div className='d-flex justify-content-center'>
      <Spinner color='muted' />
    </div>
  )

  render () {
    const { currency, period, summary, totalSales } = this.state;

    if (this.props.isLoading) return this.renderSpinner();

    if (!isEmpty(summary)) return (
      <div className='transactions mb-2'>
        <div className='navbar-transaction-menu mb-2'>
          <Button
              className={classNames('button', { 'primary': period === 'allTime' })}
              onClick={this.changePeriod('allTime')}>
            All time
          </Button>

          <Button
              className={classNames('button', { 'primary': period === 'today' })}
              onClick={this.changePeriod('today')}>
            Today
          </Button>

          <Button
              className={classNames('button', { 'primary': period === 'lastWeek' })}
              onClick={this.changePeriod('lastWeek')}>
            Last 7 days
          </Button>

          <Button
              className={classNames('button', { 'primary': period === 'lastMonth' })}
              onClick={this.changePeriod('lastMonth')}>
            Last 30 days
          </Button>
        </div>

        <div className='d-flex ml-2 mr-2'>
          <div className='section'>
            <span>Sales</span>

            <Label
                className='label text-muted mb-0'
                htmlFor='total-sales'>
              Total Sales
            </Label>

            <div
                className='count-block green'
                id='total-sales'>
              {currency && currencyFormat(totalSales, currency)}
            </div>

          </div>

          <div className='section border-section'>
            <span>Purchases</span>

            <Label
                className='label text-muted mb-0'
                htmlFor='total-sales'>
              # Live Class
            </Label>

            <div
                className='count-block orange mb-2'
                id='total-sales'>
              {summary.dropIn[period].live.count}
            </div>

            <Label
                className='label text-muted mb-0'
                htmlFor='total-sales'>
              # On-Demand
            </Label>

            <div
                className='count-block orange'
                id='total-sales'>
              {summary.dropIn[period].onDemand.count}
            </div>

          </div>

          <div className='section'>
            <span>Packages</span>

            <Label
                className='label text-muted mb-0'
                htmlFor='total-sales'>
              Members
            </Label>

            <div
                className='count-block pink mb-2'
                id='total-sales'>
              {summary.memberships[period].members}
            </div>

            <Label
                className='label text-muted mb-0'
                htmlFor='total-sales'>
              Class Packs
            </Label>

            <div
                className='count-block pink'
                id='total-sales'>
              {summary.classPacks[period].count}
            </div>
          </div>
        </div>
      </div>
    );

    return null;
  }
}

export default Transactions;
