import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Collapse, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { confirmAlert } from 'react-confirm-alert';
import { filter } from 'lodash';

import { DateHelper, Moment, getTimeZone } from '../../lib';
import { Button } from '../forms';
import { Input } from '../inputs';
import { DeletePrivateGroupClassAlert, InstructorCancelUserBookingAlert } from '../alerts';
import { AddClientModalContainer } from '../../containers/portal';
import { Covid19 } from '../../images';

class InstructorPrivateGroupClassModal extends React.PureComponent {
  static propTypes = {
    deletePrivateGroupClass:          PropTypes.func.isRequired,
    deleteRecurringPrivateGroupClass: PropTypes.func.isRequired,
    isOpen:                           PropTypes.bool,
    onHandleAddClient:                PropTypes.func.isRequired,
    onHandleCancelClientBooking:      PropTypes.func.isRequired,
    onToggle:                         PropTypes.func,
    onUpdateClick:                    PropTypes.func,
    privateGroupClass:                PropTypes.object.isRequired,
    user:                             PropTypes.object.isRequired
  };

  static defaultProps = {
    isOpen:        false,
    onToggle:      () => {},
    onUpdateClick: () => {}
  };

  state = {
    addClientModalOpen:    false,
    clientsEmails:         '',
    date:                  '',
    isBookingsOpen:        false,
    isCancel:              false,
    isVirtualBookingsOpen: false
  };

  static getDerivedStateFromProps(props) {
    const date = DateHelper.summerTimeCheck(props.privateGroupClass, props.user.timeZone);
    const bookings = filter(props.privateGroupClass.bookings, (booking) => (
      booking.deletedAt === ''
    ));

    const inPersonBookings = filter(bookings, (booking) => (booking.bookingType == 'offline' || '' ));
    const isHybridClass = props.privateGroupClass.eventType == 'hybrid';

    return {
      bookings:      isHybridClass ? inPersonBookings : bookings,
      clientsEmails: props.privateGroupClass.bookings.map((client) => (client.email)),
      date:          new Moment.tz(date, 'YYYY-MM-DDhh:mm:ssUTC')
                               .tz(getTimeZone(props.user.timeZone)),
      virtualBookings: filter(bookings, ['bookingType', 'online'])
    };
  }

  handleModal = () => {
    this.setState((prevState) => ({ addClientModalOpen: !prevState.addClientModalOpen }));
  }

  handleCancel = () => {
    this.setState((prevState) => ({
      isCancel: !prevState.isCancel
    }));
  }

  handleUpdateClick = () => {
    this.props.onUpdateClick();
    setTimeout(() => {
      this.props.onToggle();
    }, 1500);
  }

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value });
  };

  handleDelete = () => {
    const { privateGroupClass } = this.props;
    const { note } = this.state;

    const deleteOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => {
        return (
          <DeletePrivateGroupClassAlert
              deletePrivateGroupClass={this.props.deletePrivateGroupClass}
              deleteRecurringPrivateGroupClass={this.props.deleteRecurringPrivateGroupClass}
              note={note}
              onClose={onClose}
              onSuccess={this.props.onToggle}
              onToggle={this.handleDeleteModal}
              privateGroupClass={privateGroupClass} />
        );
      }
    };

    if (privateGroupClass.recurring) {
      confirmAlert(deleteOptions);
    } else {
      this.props.deletePrivateGroupClass({ note, privateGroupClass });
      this.props.onToggle();
    }
  };

  handleToggle = (fieldName) => () => (
    this.setState((prevState) => ({ [fieldName]: !prevState[fieldName] }))
  );

  renderClients = (bookings) => (
    bookings.map((client) => (
      client.deletedAt === '' &&
        <div
            className='client'
            key={client.id}>
          <div>
            <div className='name'>{client.name}</div>
            <div className='email'>{client.email}</div>
          </div>
          <div>
            {client.vaccinated &&
              <img
                  alt='covid19'
                  className='covid19-icon'
                  src={Covid19} />
            }
            <Icon
                className='icon'
                icon='times'
                onClick={() => this.handleOpenAlertClientBooking(client)} />
          </div>
        </div>
    ))
  )

  handleOpenAlertClientBooking = (client) => {
    const cancelOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <InstructorCancelUserBookingAlert
            client={client}
            onClose={onClose}
            onHandleCancelClientBooking={this.props.onHandleCancelClientBooking}
            onSuccess={this.props.onToggle}
            privateGroupClass={this.props.privateGroupClass} />
        )
      };

    confirmAlert(cancelOptions);
  }

  renderNote = () => (
    <React.Fragment>
      <div className='text-danger text-sm mt-4 mb-4'>
        Cancelling the session will remove the event from your and
        attendees will be immediately informed. To confirm click below.
      </div>

      <Form className='form-default'>
        <FormGroup>
          <Label
              for='input-note'>
            Add special note to attendee email
          </Label>

          <Input
              id='note'
              name='note'
              onChange={this.handleChange}
              placeholder='Type your information here...'
              rows='4'
              type='textarea'
              value={this.state.note} />
        </FormGroup>
      </Form>
    </React.Fragment>
  );

  render() {
    const { privateGroupClass } = this.props;
    const { bookings, date, clientsEmails, virtualBookings } = this.state;
    const isHybridClass = privateGroupClass.eventType == 'hybrid';

    return (
      <React.Fragment>
        <Modal
            className='modal-event'
            isOpen={this.props.isOpen}
            toggle={this.props.onToggle}>
          <ModalHeader toggle={this.props.onToggle}>
            View Private Group Class
          </ModalHeader>

          <ModalBody>
            <h6 className='title-details'>
              Confirmed Details
            </h6>

            <div className='event-details'>
              <div className='name'>
                {privateGroupClass.name}
                { privateGroupClass.location &&
                  <span className='location'>&nbsp;({privateGroupClass.location.name})</span>
                }
              </div>

              <div className='date'>
                {date.format('dddd, MMMM D YYYY')}
              </div>
              <div className='time'>
                {date.format('hh:mm A')} -&nbsp;
                {privateGroupClass.duration} minutes
              </div>

              { privateGroupClass.digitalLink &&
                <a
                    className='link d-block text-truncate'
                    href={privateGroupClass.digitalLink}
                    rel='noopener noreferrer'
                    target='_blank'>
                  {privateGroupClass.digitalLink}
                </a>
              }

              { (privateGroupClass.meetingUid || privateGroupClass.passcode) &&
                <div className='meettingId'>
                  { privateGroupClass.meetingUid &&
                    <span>
                      Meeting Id: {privateGroupClass.meetingUid}
                    </span>
                  }

                  {privateGroupClass.meetingUid && privateGroupClass.passcode &&
                    <span>&nbsp;|&nbsp;</span>
                  }

                  { privateGroupClass.passcode &&
                    <span>
                      Passcode: {privateGroupClass.passcode}
                    </span>
                  }
                </div>
              }

              <div
                  className='addClient'
                  onClick={this.handleModal}
                  onKeyPress={this.handleModal}
                  role='button'
                  tabIndex={0}>
                <Icon
                    icon='plus' />
                &nbsp;Add Client Manually
              </div>


              <div className='sendEmail mt-4 mb-4'>
                <Icon
                    className='mr-2'
                    icon='envelope'
                    size='2x' />
                <a
                    href={`mailto:?bcc=${clientsEmails}`}
                    rel='noopener noreferrer'
                    target='_blank'>
                  <span>
                    Send an
                    <span className='emailText'>&nbsp;Email&nbsp;</span>
                    to all clients
                  </span>
                </a>
              </div>
            </div>

            <div className='list-collapse clients-list'>
              { !isHybridClass &&
                <React.Fragment>
                  <div
                      className={classNames('list-header', {'opened': this.state.isBookingsOpen})}
                      onClick={this.handleToggle('isBookingsOpen')}
                      onKeyPress={this.handleToggle('isBookingsOpen')}
                      role='button'
                      tabIndex={0}>
                    <span>
                      {bookings.length} / {privateGroupClass.spots} Spots Reserved
                    </span>

                    <Icon icon='chevron-right' />
                  </div>

                  <Collapse isOpen={this.state.isBookingsOpen}>
                    {this.renderClients(bookings)}
                  </Collapse>
                </React.Fragment>
              }

              { isHybridClass &&
                <React.Fragment>
                  <div
                      className={classNames('list-header', {'opened': this.state.isBookingsOpen})}
                      onClick={this.handleToggle('isBookingsOpen')}
                      onKeyPress={this.handleToggle('isBookingsOpen')}
                      role='button'
                      tabIndex={0}>
                    <span>
                      {bookings.length} / {privateGroupClass.spots} In-Person Reservations
                    </span>

                    <Icon icon='chevron-right' />
                  </div>

                  <Collapse isOpen={this.state.isBookingsOpen}>
                    {this.renderClients(bookings)}
                  </Collapse>
                </React.Fragment>
              }

              { isHybridClass &&
                <React.Fragment>
                  <div
                      className={classNames('mt-1 list-header', {'opened': this.state.isVirtualBookingsOpen})}
                      onClick={this.handleToggle('isVirtualBookingsOpen')}
                      onKeyPress={this.handleToggle('isVirtualBookingsOpen')}
                      role='button'
                      tabIndex={0}>
                    <span>
                      {virtualBookings.length} / {privateGroupClass.virtualSpots} Virtual Reservations
                    </span>

                    <Icon icon='chevron-right' />
                  </div>

                  <Collapse isOpen={this.state.isVirtualBookingsOpen}>
                    {this.renderClients(virtualBookings)}
                  </Collapse>
                </React.Fragment>
              }

              {this.state.isCancel && this.renderNote()}
            </div>
          </ModalBody>

          <ModalFooter>
            <Row>
              <Col
                  lg={6}
                  md={12}>
                { this.state.isCancel &&
                  <Button
                      color='danger'
                      isBlock
                      isOutline
                      onClick={this.handleDelete}
                      size='lg'>
                    Confirm
                  </Button>
                }

                { !this.state.isCancel &&
                  <Button
                      color='danger'
                      isBlock
                      isOutline
                      onClick={this.handleCancel}
                      size='lg'>
                    Cancel Class
                  </Button>
                }
              </Col>

              <Col
                  lg={6}
                  md={12}>
                <Button
                    color='primary'
                    isBlock
                    isOutline
                    onClick={this.handleUpdateClick}
                    size='lg'>
                  Update class
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
        { this.state.addClientModalOpen &&
          <AddClientModalContainer
              isOpen={this.state.addClientModalOpen}
              onHandleAddClient={this.props.onHandleAddClient}
              onToggle={this.handleModal}
              privateGroupClass={this.props.privateGroupClass} />
        }
      </React.Fragment>
    );
  }
}

export default InstructorPrivateGroupClassModal;
