import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';

import { routes } from '../../lib';
import { Link } from '../inputs';
import { Button, Form } from '../forms';
import { Input } from '../inputs';
import { Validator } from '../../lib';

class ContactPage extends React.PureComponent {
  static propTypes = {
    sendFeedback: PropTypes.func.isRequired
  }

  state = {
    errors: {},

    feedback: {
      email:     '',
      firstName: '',
      lastName:  '',
      message:   ''
    }
  }

  handleChange = (value, inputName) => {
    this.setState({ errors: {} }, () => {
      this.setState((prevState) => ({
        feedback: {
          ...prevState.feedback,
          [inputName]: value
        }
      }));
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.handleValidate().then(() => {
      return this.setState({ errors: {} }, () => {
        this.props.sendFeedback({ feedback: this.state.feedback });
        this.setState({ feedback: {} });
      });
    }).catch((errors) => {
      this.setState({ errors });
    });
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { feedback } = this.state;

    const errors = Validator.validate([
      ['firstName', Validator.concepts.isFilled, [feedback.firstName]],
      ['lastName',  Validator.concepts.isFilled, [feedback.lastName]],
      ['email',     Validator.concepts.emailRegex, [feedback.email]],
      ['message',   Validator.concepts.isFilled, [feedback.message]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  })

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Contact</title>
        </Helmet>
        <section className='hero py-6 py-lg-7 dark-overlay'>
          <Container className='overlay-content'>
            <ol className='breadcrumb justify-content-center no-border mb-0'>
              <li className='breadcrumb-item'>
                <Link to={routes.HOME}>Home</Link>
              </li>
              <li className='breadcrumb-item active'>
                Contact
              </li>
            </ol>
            <h1 className='hero-heading'>We are here to help!</h1>
            <p className='text-muted pl-5 pr-5'>
              The easiest way to talk to us is to send us a support request using the form below.
              However, if you prefer, you can also send us an email at info@onpodio.com. We will make sure to
              respond to you within 24 hours!
            </p>
          </Container>
        </section>
        <section className='py-6 bg-gray-100'>
          <Container>
            <h2 className='h4 mb-5 text-center'>Contact form</h2>
            <Row className='justify-content-center'>
              <Col
                  className='mb-5 mb-md-0'
                  md={5}>
                <Form
                    className='form'
                    id='contact-form'
                    onSubmit={this.handleSubmit}>
                  <div className='controls'>
                    <Row>
                      <Col sm={6}>
                        <FormGroup>
                          <Label
                              className='form-label'
                              for='firstName'>Your firstname *</Label>
                          <Input
                              id='firstName'
                              isInvalid={!!this.state.errors.firstName}
                              name='firstName'
                              onChange={this.handleChange}
                              placeholder='Enter your first name'
                              type='text'
                              value={this.state.feedback.firstName} />
                          <FormFeedback invalid>{this.state.errors.firstName}</FormFeedback>
                        </FormGroup>
                      </Col>
                      <Col sm={6}>
                        <FormGroup>
                          <Label
                              className='form-label'
                              for='lastName'>Your lastname *</Label>
                          <Input
                              id='lastName'
                              isInvalid={this.state.errors.lastName}
                              name='lastName'
                              onChange={this.handleChange}
                              placeholder='Enter your last name'
                              type='text'
                              value={this.state.feedback.lastName} />
                          <FormFeedback invalid>{this.state.errors.lastName}</FormFeedback>
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup>
                      <Label
                          className='form-label'
                          for='email'>Your email *</Label>
                      <Input
                          id='email'
                          isInvalid={!!this.state.errors.email}
                          name='email'
                          onChange={this.handleChange}
                          placeholder='Enter your email'
                          type='email'
                          value={this.state.feedback.email} />
                      <FormFeedback invalid>{this.state.errors.email}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                      <Label
                          className='form-label'
                          for='message'>Your message for us *</Label>
                      <Input
                          id='message'
                          isInvalid={this.state.errors.message}
                          name='message'
                          onChange={this.handleChange}
                          placeholder='Enter your message'
                          rows='4'
                          type='textarea'
                          value={this.state.feedback.message} />
                      <FormFeedback invalid>{this.state.errors.message}</FormFeedback>
                    </FormGroup>
                    <Button
                        color='primary'
                        isOutline
                        type='submit'>
                      Send message
                    </Button>
                  </div>
                </Form>
              </Col>
              <Col
                  className='d-none'
                  md={3}>
                <div className='pl-lg-4'>
                  <div className='social'>
                    <ul className='list-inline'>
                      <li className='list-inline-item'>
                        <a
                            href='//linkedin.com/company/onpodio'
                            rel='noopener noreferrer'
                            target='_blank'>
                          <Icon icon={['fab', 'linkedin-in']} />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                            href='//facebook.com/onpodio'
                            rel='noopener noreferrer'
                            target='_blank'>
                          <Icon icon={['fab', 'facebook']} />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                            href='//instagram.com/onpodio'
                            rel='noopener noreferrer'
                            target='_blank'>
                          <Icon icon={['fab', 'instagram']} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default ContactPage;
