import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { Moment } from '../../../lib';

import { Calendr } from '../../../lib';

class WeekDays extends React.PureComponent {
  static propTypes = {
    days:    PropTypes.array,
    hasDays: PropTypes.bool,
    hasIcon: PropTypes.bool,
    today:   PropTypes.object.isRequired
  };

  static defaultProps = {
    days:    Calendr.getWeekDayLabels(isMobile),
    hasDays: true,
    hasIcon: false
  };

  renderDay = (day, i) => {
    const isToday = Moment(day).isSame(Moment(this.props.today), 'day');
    const labelClass = classNames('day-label', day.format('dddd').toLowerCase(), { 'today': isToday });

    return (
      <div
          className={labelClass}
          key={i}>
        <span className='day-number'>
          {day.format('D')}
        </span>
        <span className='week-day-name'>
          {day.format('dddd')}
        </span>
      </div>
    );
  };

  renderDayLabel = (day, i) => {
    const labelClass = classNames('day-label', day.toLowerCase());

    return (
      <div
          className={labelClass}
          key={i}>
        <span className='week-day-name'>
          {day}
        </span>
      </div>
    );
  };

  render () {
    return (
      <div className='week-days-labels'>
        { this.props.hasIcon &&
          <div className='icon'>
            <Icon icon='calendar-week' />
          </div>
        }
        <div className='days'>
          { this.props.days.map((day, index) => (
            this.props.hasDays ? this.renderDay(day, index) : this.renderDayLabel(day, index)
          ))}
        </div>
      </div>
    );
  }
}

export default WeekDays;
