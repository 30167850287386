import { RouterActionTypes } from '../constants/actionTypes';

export default (state = [], action) => {
  switch (action.type) {
    case RouterActionTypes.LOCATION_CHANGE:
      return [action.payload, ...state].slice(0, 10);

    default:
      return state;
  }
};
