/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';

class Canvas extends React.PureComponent {
  static propTypes = {
    bounds: PropTypes.shape({
      height: PropTypes.number,
      width:  PropTypes.number
    }).isRequired,

    children: PropTypes.node.isRequired,
    onRef:    PropTypes.func.isRequired
  }

  handleRef = (canvas) => {
    this.props.onRef(canvas);
  }

  render() {
    return (
      <div className='story-workspace'>
        <div className='story-overlay'>
          <svg
              height={this.props.bounds.height}
              id='svgRef'
              ref={this.handleRef}
              width={this.props.bounds.width}
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink">
            <g>
              {this.props.children}
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

export default Canvas;
