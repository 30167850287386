export default {
  ADDED_ADDITIONAL_INFO:  '@@segment/ADDED_ADDITIONAL_INFO',
  CREATE_CLASS:           '@@segment/CREATE_CLASS',
  CREATE_PROFILE:         '@@segment/CREATE_PROFILE',
  CREATE_RECURRING_CLASS: '@@segment/CREATE_RECURRING_CLASS',
  CREATE_TIME_BLOCK:      '@@segment/CREATE_TIME_BLOCK',
  IDENTIFY:               '@@segmnet/IDENTIFY',
  NEW_CLASS_BOOK:         '@@segment/NEW_CLASS_BOOK',
  SIGN_IN:                '@@segment/SIGN_IN',
  SIGN_UP:                '@@segment/SIGN_UP',
  SITE_SHARE:             '@@segment/SITE_SHARE',
  STRIPE_CONNECT:         '@@segment/STRIPE_CONNECT',
  ZOOM_CONNECT:           '@@segment/ZOOM_CONNECT'
};
