import { PrivateSessionTypeActionTypes } from '../constants/actionTypes';

export default {
  CLEAR: () => ({
    type: PrivateSessionTypeActionTypes.CLEAR
  }),


  GET: (payload) => ({
    payload, type: PrivateSessionTypeActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: PrivateSessionTypeActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload, type: PrivateSessionTypeActionTypes.GET_SUCCESS
  }),


  UPDATE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionTypeActionTypes.UPDATE_SUCCESS
  })
};
