import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

class FollowButtonElegant extends React.PureComponent {
  static propTypes = {
    followersCount: PropTypes.number,
    isDisabled:     PropTypes.bool,
    isFollowing:    PropTypes.bool,
    onClick:        PropTypes.func,
    stroke:         PropTypes.string
  }

  static defaultProps = {
    followersCount: 0,
    isDisabled:     false,
    isFollowing:    false,
    onClick:        () => {},
    stroke:         null
  }

  render () {
    const { isFollowing, followersCount, isDisabled, onClick, stroke } = this.props;
    const style = isFollowing ? (stroke || '#112233') : 'none';

    return (
      <button
          className='btn-follow'
          disabled={isDisabled}
          onClick={onClick}
          type='button'>
        <svg
            style={{ fill: style }}
            viewBox='-2 -2 37 36'
            xmlns='http://www.w3.org/2000/svg'>
          <mask
              fill='white'
              id='path-1-inside-1'>
            <path
                d="M24.1856 0C25.3703 0.00493965 26.5425 0.237154 27.6352 0.683385C28.7278 1.12962 29.7195 1.78112
            30.5537 2.60071C31.3879 3.4203 32.0481 4.39191 32.4968 5.46007C32.9455 6.52824 33.1738 7.67203 33.1688
            8.82615C33.1688 15.6275 27.7484 22.3925 17.3708 29.3797C17.1399 29.5351 16.8658 29.6184 16.5851
            29.6184C16.3044 29.6184 16.0304 29.5351 15.7994 29.3797C5.4204 22.3925 8.33518e-05 15.6275 8.33518e-05
            8.82615C-0.00500929 7.67203 0.223309 6.52824 0.672002 5.46007C1.12069 4.39191 1.78098 3.4203 2.61514
            2.60071C3.44931 1.78112 4.44103 1.12962 5.53367 0.683385C6.62632 0.237154 7.79849 0.00493965 8.98326
            0C10.4456 0.0146874 11.8863 0.346047 13.201 0.970074C14.5157 1.5941 15.6714 2.49517 16.5844 3.60801C17.4975
            2.49517 18.6532 1.5941 19.9679 0.970074C21.2825 0.346047 22.7232 0.0146874 24.1856 0V0Z" />
          </mask>
          <path
              d='M24.1856 0C25.3703 0.00493965 26.5425 0.237154 27.6352 0.683385C28.7278 1.12962 29.7195 1.78112 30.5537
               2.60071C31.3879 3.4203 32.0481 4.39191 32.4968 5.46007C32.9455 6.52824 33.1738 7.67203 33.1688
               8.82615C33.1688 15.6275 27.7484 22.3925 17.3708 29.3797C17.1399 29.5351 16.8658 29.6184 16.5851
               29.6184C16.3044 29.6184 16.0304 29.5351 15.7994 29.3797C5.4204 22.3925 8.33518e-05 15.6275 8.33518e-05
               8.82615C-0.00500929 7.67203 0.223309 6.52824 0.672002 5.46007C1.12069 4.39191 1.78098 3.4203 2.61514
               2.60071C3.44931 1.78112 4.44103 1.12962 5.53367 0.683385C6.62632 0.237154 7.79849 0.00493965 8.98326
               0C10.4456 0.0146874 11.8863 0.346047 13.201 0.970074C14.5157 1.5941 15.6714 2.49517 16.5844
               3.60801C17.4975 2.49517 18.6532 1.5941 19.9679 0.970074C21.2825 0.346047 22.7232 0.0146874 24.1856 0V0Z'
              mask='url(#path-1-inside-1)'
              stroke={stroke || '#112233'}
              strokeWidth='4' />
        </svg>

        <span
            className='followers'
            style={{ color: stroke }}>
          {pluralize('like', followersCount, true)}
        </span>
      </button>
    );
  }
}

export default FollowButtonElegant;
