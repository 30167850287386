import React from 'react';
import PropTypes from 'prop-types';
import { FormFeedback, FormGroup } from 'reactstrap';

import { Input } from '../../inputs';

class Tagline extends React.PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    errors:   PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    profile:  PropTypes.object.isRequired
  }

  render() {
    return (
      <div className='text-block'>
        {this.props.children}

        <FormGroup>
          <Input
              isInvalid={!!this.props.errors.tagline}
              maxLength={60}
              name='tagline'
              onChange={this.props.onChange}
              placeholder='Work hard in silence. Let success be your noise.'
              type='text'
              value={this.props.profile.tagline} />
          <FormFeedback>{this.props.errors.tagline}</FormFeedback>
        </FormGroup>
      </div>
    );
  }
}

export default Tagline;
