/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Collapse, FormFeedback, FormGroup, FormText, Label, Row } from 'reactstrap';
import classNames from 'classnames';
import { isEmpty, map, omit, orderBy } from 'lodash';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { EVENT_TYPES, FEES, FEE_TYPES, INTENSITY_TYPES, currencies, selectFieldStyle } from '../../../constants';
import { currencyMask, minuteMask, weekMask } from '../../../constants/masks';
import { DateHelper, Moment, Validator, api, getTimeZone, routes } from '../../../lib';
import { AsyncSelect, ChallengesSelect, ClassPacksSelect, DatePicker, FileUploader, Input,
         MembershipsSelect, PartnersSelect, TimePicker } from '../../inputs';
import { ProBadge } from '../../badges';
import { Button, Checkbox, Form, LabelHelp, MaskedInput, Radio, Select } from '../';

class PrivateGroupClassForm extends React.PureComponent {
  static propTypes = {
    data:         PropTypes.object.isRequired,
    formats:      PropTypes.array.isRequired,
    instructor:   PropTypes.object.isRequired,
    onSubmit:     PropTypes.func,
    playlists:    PropTypes.array.isRequired,
    subscription: PropTypes.object.isRequired,
    user:         PropTypes.object
  }

  static defaultProps = {
    fetchPlaylists: () => {},
    onSubmit:       () => {},
    user:           {}
  }

  static getDerivedStateFromProps(props, state) {
    const { data, user } = props;

    const date = DateHelper.summerTimeCheck(data, props.user.timeZone);

    const privateGroupClass = data.id ? {
      askAboutVaccination: data.askAboutVaccination,
      challengeIds:        data.challengeIds,
      classPackIds:        data.classPackIds,
      date:                new Moment.utc(date, 'YYYY-MM-DDhh:mm:ssaUTC').tz(getTimeZone(user.timeZone))
                                                                         .format('YYYY-MM-DD'),

      description:        data.description,
      digitalLink:        data.digitalLink,
      donationRequired:   data.feeType === 'donation' ? data.donationRequired : false,
      duration:           data.duration,
      eventType:          data.eventType ? EVENT_TYPES[data.eventType] : 1,
      fee:                String(data.feeType !== FEE_TYPES.free.value ? data.fee : 0),
      feeType:            data.feeType ? FEE_TYPES[data.feeType].value : 0,
      format:             data.formatName,
      formatId:           data.formatId,
      id:                 data.id,
      imageType:          data.imageType,
      intensityType:      data.intensityType ? INTENSITY_TYPES[data.intensityType].value : 0,
      locationId:         data.eventType === 'offline' ? data.location.id : '',
      meetingUid:         data.meetingUid,
      membershipIds:      data.membershipIds,
      name:               data.name,
      numberOfWeeks:      String(data.recurring ? data.recurrence.numberOfWeeks : ''),
      partnerId:          data.partnerId,
      passcode:           data.passcode,
      playlistAttributes: data.playlist,
      recurring:          data.recurring,
      spots:              data.spots,
      time:               new Moment.utc(date, 'YYYY-MM-DDhh:mm:ssUTC').tz(getTimeZone(user.timeZone))
                            .format('HH:mm'),

      virtualSpots: data.virtualSpots
    } : data;

    if (state.privateGroupClass) {
      return state;
    }

    return {
      errors:  state.errors,
      formats: map(props.formats, (format) => (
        { label: format.title, value: format.id }
      )),
      hasAdvancedPackages: props.subscription.advancedPackages,
      isLoading:           state.isLoading,
      isUpdate:            !!data.id,
      privateGroupClass:   privateGroupClass,

      uploads: {
        image: {
          ...props.data.image && props.data.image
        }
      }
    };
  }

  state = {
    errors:              {},
    generateLink:        true,
    hasAdvancedPackages: false,
    isImageSectionOpen:  false,
    isLoading:           false,

    privateGroupClass: '',
    uploads:           {}
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      errors:            omit(this.state.errors, inputName),
      privateGroupClass: {
        ...prevState.privateGroupClass,
        [inputName]: value
      }
    }));
  };

  handleSelect = (inputName) => (option) => (
    this.setState((prevState) => ({
      privateGroupClass: {
        ...prevState.privateGroupClass,
        [inputName]: option
      }
    }))
  )

  handleMultiSelect = (inputName) => (option) => (
    this.setState((prevState) => ({
      privateGroupClass: {
        ...prevState.privateGroupClass,
        [inputName]: option.id
      }
    }))
  )

  handleDeleteImage = () => {
    this.setState((prevState) => (
      {
        ...prevState,
        uploads: {
          ...prevState.uploads,
          image: {}
        }
      }
    ));
  }

  handleCreateMeeting = (e) => {
    e.preventDefault();

    this.setState({ isLoading: true }, () => {
      return api.meeting.create()
        .then((response) => (
          this.setState((prevState) => ({
            isLoading: false,

            privateGroupClass: {
              ...prevState.privateGroupClass,
              digitalLink: response.data.join_url,
              meetingId:   response.data.id,
              meetingUid:  response.data.uid,
              passcode:    response.data.passcode
            }
          }))
        ));
    });
  }

  handleEventSwitch = (eventType) => () => {
    const toggle = () => (
      this.setState((prevState) => ({
        privateGroupClass: {
          ...prevState.privateGroupClass,
          eventType,
          virtualSpots: 0
        }
      }))
    );

    if (eventType === 1) {
      this.setState((prevState) => ({
        privateGroupClass: {
          ...prevState.privateGroupClass,
          locationId:   '',
          virtualSpots: 0
        }
      }), toggle);
    } else {
      toggle();
    }
  }

  handleGenerateLinkSwith = (value) => () => (
    this.setState({ generateLink: value })
  )

  handleFeeSwitch = (feeType) => () => {
    this.setState((prevState) => ({
      privateGroupClass: {
        ...prevState.privateGroupClass,
        feeType
      }
    }));

    if (feeType === 2) {
      this.setState((prevState) => ({
        privateGroupClass: {
          ...prevState.privateGroupClass,
          donationRequired: false
        }
      }));
    }
  }

  handleImageSwitch = (value, inputName) => {
    this.setState((prevState) => ({
      privateGroupClass: {
        ...prevState.privateGroupClass,
        [inputName]: value
      }
    }));
  }

  handleSubmit = () => {
    const { privateGroupClass } = this.state;
    const { user } =  this.props;

    const sign = currencies[user.currency].symbol;

    this.setState({ isLoading: true }, () => {
      this.handleValidate().then(() => {
        const stamp = [privateGroupClass.date, privateGroupClass.time].join(' ');

        const startsAt = new Moment.tz(stamp, 'YYYY-MM-DDhh:mm:ssUTC', getTimeZone(user.timeZone)).format();

        this.setState({ isLoading: false });

        return this.props.onSubmit({ privateGroupClass: {
          ...privateGroupClass,
          canPermit: true,
          fee:       parseFloat(String(privateGroupClass.fee).replace(sign, '')),
          startsAt
        }});
      }).catch((errors) => (
        this.setState({ errors, isLoading: false })
      ));
    });
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const { currency } = this.props.user;
    const { privateGroupClass } = this.state;

    const { fee } =  privateGroupClass;
    const sign = currencies[currency].symbol;

    const errors = Validator.validate([
      ['date',       Validator.concepts.isFilled, [privateGroupClass.date]],
      ['time',       Validator.concepts.isFilled, [privateGroupClass.time]],
      ['locationId', Validator.concepts.isFilled, [privateGroupClass.locationId], privateGroupClass.eventType !== 0],
      ['duration',   Validator.concepts.isFilled, [privateGroupClass.duration]],
      ['name',       Validator.concepts.isFilled, [privateGroupClass.name]],
      ['spots',      Validator.concepts.isFilled, [privateGroupClass.spots]],
      ['format',     Validator.concepts.isFilled, [privateGroupClass.formatId]],
      [
        'fee',
        Validator.concepts.zeroCheck, [fee && fee.toString().replace(sign, '')],
        privateGroupClass.feeType !== 1
      ],
      [
        'partnerId',
        Validator.concepts.isFilled,
        [privateGroupClass.partnerId], privateGroupClass.imageType !== 'partner'
      ],
      [
        'numberOfWeeks',
        Validator.concepts.weeksValidator,
        [privateGroupClass.numberOfWeeks, privateGroupClass.recurring]
      ],
      [
        'numberOfWeeks',
        Validator.concepts.moreThan,
        [parseInt(privateGroupClass.numberOfWeeks), 24], !privateGroupClass.recurring
      ],
      [
        'fee',
        Validator.concepts.feeValidator,
        [privateGroupClass.fee, privateGroupClass.feeType]
      ]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  loadPlaylists = () => {
    const { playlists } = this.props;

    return playlists;
  }

  handleSelectPlaylist = (value) => {
    this.setState((prevState) => ({
      privateGroupClass: {
        ...prevState.privateGroupClass,
        playlistAttributes: {
          name:       value.name,
          playlistId: value.id
        }
      }
    }));
  }

  handleToggle = (section) => (e) => {
    e.preventDefault();

    this.setState((prevState) => ({
      isImageSectionOpen:   false,
      isPackageSectionOpen: false,

      [section]: !prevState[section]
    }));
  }


  render () {
    const { data, user, instructor } = this.props;
    const { privateGroupClass, errors, isLoading, uploads, isImageSectionOpen, isPackageSectionOpen,
            hasAdvancedPackages } = this.state;
    const { currency } = user;

    const sign = currencies[currency].symbol;

    const amount = parseFloat(String(privateGroupClass.fee).replace(sign, '')) || 0;
    const fee = FEES.charge(amount);

    const feeSelectOptions = orderBy(Object.values(FEE_TYPES), ['value'], ['asc']);
    const intensitySelectOptions = orderBy(Object.values(INTENSITY_TYPES), ['value'], ['asc']);

    const isHybrid = privateGroupClass.eventType == 2;

    const formatPlaylistOption = ({ name }) => (
      <React.Fragment>
        <Icon
            className='mr-2'
            fixedWidth
            icon={['fab', 'spotify']}
            style={{ color: '#1DB954' }} />
        {name}
      </React.Fragment>
    );

    const isImageUploaded = !isEmpty(uploads.image) || !!privateGroupClass.image;

    return (
      <Form className='form-default'>
        <FormGroup>
          <Label
              className='required mb-2'
              for='input-fee'>
            Type of Class
          </Label>

          <div className='d-flex'>
            <div className='custom-control custom-radio w-50'>
              <Radio
                  className='custom-control-input'
                  id='online'
                  isChecked={privateGroupClass.eventType === 1}
                  isDisabled={!!data.id}
                  name='online'
                  onChange={this.handleEventSwitch(1)}
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-online'>
                Virtual
              </Label>
            </div>

            <div className='custom-control custom-radio w-50'>
              <Radio
                  className='custom-control-input'
                  id='offline'
                  isChecked={privateGroupClass.eventType === 0}
                  isDisabled={!!data.id}
                  name='offline'
                  onChange={this.handleEventSwitch(0)}
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-offline'>
                In Person
              </Label>
            </div>

            <div className='custom-control custom-radio w-50'>
              <Radio
                  className='custom-control-input'
                  id='hybrid'
                  isChecked={privateGroupClass.eventType === 2}
                  isDisabled={!!data.id}
                  name='hybrid'
                  onChange={this.handleEventSwitch(2)}
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-hybrid'>
                Hybrid
              </Label>
            </div>
          </div>
        </FormGroup>

        { privateGroupClass.eventType !== 1 &&
          <React.Fragment>
            <div className='streak'>
              <span>In-Person Information</span>
            </div>
            <FormGroup>
              <Label
                  className='required'
                  for='input-session-type'>
                Location
              </Label>

              <Select
                  emptyMessage='None. You need to first add a location via Settings'
                  isInvalid={!!errors.locationId}
                  labelName='name'
                  name='locationId'
                  onChange={this.handleSelect('locationId')}
                  options={instructor.locations}
                  placeholder='Select location'
                  value={privateGroupClass.locationId}
                  valueName='id' />
            </FormGroup>

            <Row className='justify-content-between'>
              <Col sm={3}>
                <FormGroup>
                  <Label
                      className='required'
                      for='input-spots'>
                    # Spots
                  </Label>

                  <Input
                      hasCharCount={false}
                      id='spots'
                      isInvalid={!!errors.spots}
                      max={999}
                      name='spots'
                      onChange={this.handleChange}
                      type='number'
                      value={privateGroupClass.spots} />
                </FormGroup>
              </Col>

              <Col sm={8}>
                <Label for='input-askAboutVaccination'>
                  Ask if COVID-19 vaccinated?
                </Label>
                <div className='custom-control custom-checkbox checkbox-lg mb-0'>
                  <Checkbox
                      className='custom-control-input'
                      id='askAboutVaccination'
                      isChecked={privateGroupClass.askAboutVaccination}
                      name='askAboutVaccination'
                      onChange={this.handleChange} />
                  <Label
                      className='custom-control-label'
                      htmlFor='input-askAboutVaccination' />
                </div>
              </Col>
            </Row>
          </React.Fragment>
        }

        { privateGroupClass.eventType !== 0 &&
          <React.Fragment>
            <div className='streak'>
              <span>Virtual Information</span>
            </div>

            <FormGroup>
              <a
                  className={classNames('text-sm float-right', {
                    'disabled':     !user.oauth.zoom,
                    'text-muted':   !user.oauth.zoom,
                    'text-primary': user.oauth.zoom
                  })}
                  href='#'
                  onClick={this.handleCreateMeeting}>
                Create Zoom Link
              </a>
              <Label
                  className='required'
                  for='input-digitalLink'>
                Digital Link
              </Label>

              <Input
                  id='digitalLink'
                  isInvalid={!!errors.digitalLink}
                  name='digitalLink'
                  onChange={this.handleChange}
                  value={privateGroupClass.digitalLink} />
            </FormGroup>

            <Row form>
              <Col>
                <FormGroup>
                  <Label for='input-meetingUid'>Meeting Id</Label>

                  <Input
                      id='meetingUid'
                      name='meetingUid'
                      onChange={this.handleChange}
                      value={privateGroupClass.meetingUid} />
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label for='input-duration'>Passcode</Label>

                  <Input
                      id='passcode'
                      name='passcode'
                      onChange={this.handleChange}
                      value={privateGroupClass.passcode} />

                </FormGroup>
              </Col>

              <FormText
                  className='ml-1'
                  color='info'>
                Digital link, meeting ID and passcode will be shared with clients via email after reservation
              </FormText>
            </Row>


            <Col sm={3}>
              <FormGroup>
                <Label
                    className='required'
                    for='input-spots'>
                  # Spots
                </Label>

                <Input
                    hasCharCount={false}
                    id='spots'
                    isInvalid={!!errors.spots}
                    max={999}
                    name={isHybrid ? 'virtualSpots' : 'spots'}
                    onChange={this.handleChange}
                    type='number'
                    value={isHybrid ? privateGroupClass.virtualSpots : privateGroupClass.spots} />
              </FormGroup>
            </Col>
          </React.Fragment>
        }

        <div className='streak'>
          <span>Class Information</span>
        </div>

        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Class Name
          </Label>

          <Input
              id='name'
              isInvalid={!!errors.name}
              maxLength={60}
              name='name'
              onChange={this.handleChange}
              value={privateGroupClass.name} />
        </FormGroup>

        <FormGroup>
          <Label
              className='required'
              for='input-format'>
            Class Format
          </Label>

          <Select
              hasInitialValue
              id='format'
              isInvalid={!!errors.format}
              name='formatId'
              onChange={this.handleChange}
              onSelect={this.handleChange}
              options={this.state.formats}
              placeholder='Select class format'
              value={privateGroupClass.formatId} />

          { !!errors.format &&
            <FormFeedback
                className='upload-tooltip'
                tooltip>
              {errors.format}
            </FormFeedback>
          }
        </FormGroup>

        <Row form>
          <Col xs={7}>
            <FormGroup>
              <Label
                  className='required'
                  for='input-date'>Date</Label>

              <DatePicker
                  id='date'
                  isInvalid={!!errors.date}
                  name='date'
                  onChange={this.handleChange}
                  value={privateGroupClass.date} />
            </FormGroup>
          </Col>
          <Col xs={5}>
            <FormGroup>
              <Label
                  className='required'
                  for='input-time'>Start Time</Label>

              <TimePicker
                  id='time'
                  isInvalid={!!errors.time}
                  name='time'
                  onChange={this.handleChange}
                  value={privateGroupClass.time} />
            </FormGroup>
          </Col>
        </Row>

        <Row form>
          <Col>
            <FormGroup>
              <Label
                  className='required'
                  for='input-duration'>Duration</Label>

              <MaskedInput
                  id='duration'
                  inputMode='number'
                  isInvalid={!!errors.duration}
                  mask={minuteMask}
                  name='duration'
                  onChange={this.handleChange}
                  placeholder=' mins'
                  type='number'
                  value={String(privateGroupClass.duration)} />
            </FormGroup>
          </Col>

          <Col>
            <FormGroup>
              <Label for='input-recurring'>Recur Class Weekly?</Label>

              <div className='d-flex'>
                <div className='custom-control custom-checkbox checkbox-lg'>
                  <Checkbox
                      className='custom-control-input'
                      id='recurring'
                      isChecked={privateGroupClass.recurring}
                      name='recurring'
                      onChange={this.handleChange} />
                  <Label
                      className='custom-control-label'
                      htmlFor='input-recurring' />
                </div>

                <MaskedInput
                    className='ml-2'
                    id='numberOfWeeks'
                    inputMode='number'
                    isDisabled={!privateGroupClass.recurring}
                    isInvalid={!!errors.numberOfWeeks}
                    mask={weekMask}
                    name='numberOfWeeks'
                    onChange={this.handleChange}
                    placeholder=' weeks'
                    type='number'
                    value={String(privateGroupClass.numberOfWeeks)} />

                <FormFeedback tooltip>
                  {errors.numberOfWeeks}
                </FormFeedback>
              </div>
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label
              className='required'
              for='input-playlist'>
            Intensity
          </Label>

          <Select
              id='intensity'
              name='intensityType'
              onChange={this.handleChange}
              options={intensitySelectOptions}
              type='text'
              value={privateGroupClass.intensityType} />
        </FormGroup>

        <FormGroup>
          <Label for='input-playlist'>
            Music Playlist
          </Label>

          <AsyncSelect
              formatOptionLabel={formatPlaylistOption}
              hasDefaultOptions
              isDisabled={!user.oauth.spotify}
              labelName='name'
              loadOptions={this.loadPlaylists}
              name='playlist'
              onChange={this.handleSelectPlaylist}
              styles={selectFieldStyle.playlists}
              value={privateGroupClass.playlistAttributes}
              valueName='id' />
        </FormGroup>

        <FormGroup>
          <Label for='input-description'>Description</Label>

          <Input
              id='description'
              name='description'
              onChange={this.handleChange}
              rows='3'
              type='textarea'
              value={privateGroupClass.description} />
        </FormGroup>

        <FormGroup>
          <Label
              className='required d-block'
              for='input-fee'>
            Cost of Class
          </Label>

          <div className='d-flex justify-content-between'>
            <Select
                className='w-50'
                disabledOptionType='value'
                disabledOptionValues={user.oauth.stripe ? [] : [1, 2, 3]}
                name='feeType'
                onChange={this.handleChange}
                options={feeSelectOptions}
                value={privateGroupClass.feeType} />

            <div
                className='d-flex align-items-center justify-content-end'
                style={{ height: 43 }}>
              { privateGroupClass.feeType === 1 &&
                <MaskedInput
                    className='w-75'
                    id='fee'
                    isDisabled={privateGroupClass.feeType !== 1}
                    isInvalid={!!errors.fee}
                    mask={currencyMask(sign)}
                    name='fee'
                    onChange={this.handleChange}
                    placeholder={sign}
                    value={privateGroupClass.fee} />
              }

              { privateGroupClass.feeType === 2 &&
                <div className='custom-control custom-checkbox checkbox-md mb-0'>
                  <Checkbox
                      className='custom-control-input'
                      id='donationRequired'
                      isChecked={privateGroupClass.donationRequired}
                      name='donationRequired'
                      onChange={this.handleChange} />
                  <LabelHelp
                      className='custom-control-label'
                      for='input-donationRequired'
                      tooltipText='Make donations required to reserve a spot (i.e. clients cannot skip donation)'>
                    Require a Donation
                  </LabelHelp>
                </div>
              }
            </div>
          </div>

          { privateGroupClass.feeType == 1 &&
            <small className='text-small text-muted mt-2'>
              Client pays {parseFloat(amount + fee, 10).toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
              (incl. {parseFloat(fee, 10).toLocaleString('en-GB', { currency, style: 'currency' })} fee)
            </small>
          }

          { privateGroupClass.feeType == 3 &&
            <small className='text-small text-muted mt-2'>
              This will limit access to clients with a package (i.e. no drop-ins)
            </small>
          }
        </FormGroup>

        { !user.hasStripe &&
          <div className='text-warning text-sm'>
            Note: Your Stripe account is not connected, in order to add Paid or Donation classes connect
            your Stripe account by going to Instructor Settings &gt;&nbsp;
            <Link to={routes.INTEGRATIONS}>
              Integrations
            </Link>
          </div>
        }

        <a
            className='form-collapse-toggle dropdown-toggle'
            href='#'
            onClick={this.handleToggle('isImageSectionOpen')}>
          Add class image
        </a>

        <Collapse isOpen={isImageSectionOpen}>
          <FormGroup>
            <Row form>
              <Col xs={5}>
                <div className='custom-control custom-radio mt-2'>
                  <Radio
                      className='custom-control-input'
                      id='photo'
                      isChecked={privateGroupClass.imageType === 'photo'}
                      name='imageType'
                      onChange={this.handleImageSwitch}
                      value='photo' />
                  <Label
                      className='custom-control-label'
                      for='input-photo'>
                    Use Profile Pic
                  </Label>
                </div>

                <div className='custom-control custom-radio mt-2'>
                  <Radio
                      className='custom-control-input'
                      id='image'
                      isChecked={privateGroupClass.imageType === 'image'}
                      name='imageType'
                      onChange={this.handleImageSwitch}
                      value='image' />
                  <Label
                      className='custom-control-label'
                      for='input-image'>
                    Upload image
                  </Label>
                </div>

                <div className='custom-control custom-radio mt-2'>
                  <Radio
                      className='custom-control-input'
                      id='partner'
                      isChecked={privateGroupClass.imageType === 'partner'}
                      name='imageType'
                      onChange={this.handleImageSwitch}
                      value='partner' />
                  <Label
                      className='custom-control-label'
                      for='input-partner'>
                    Select Partner Program
                  </Label>
                </div>
              </Col>

              <Col xs={7}>
                <div className='preview image-preview'>
                  { privateGroupClass.imageType === 'photo' &&
                    <img
                        alt={instructor.firstName}
                        src={instructor.photo.lg} />
                  }
                  { privateGroupClass.imageType === 'image' &&
                    <FileUploader
                        className='mb-4'
                        error={errors.image}
                        fileTypes={['image/jpeg', 'image/png']}
                        hasCropper
                        isInvalid={!!errors.image}
                        maxFileSize='5MB'
                        name='image'
                        onChange={this.handleChange}
                        onDelete={this.handleDeleteImage}
                        placeholder='Drop image'
                        size='sm'
                        value={uploads.image} />
                  }
                  { privateGroupClass.imageType === 'partner' &&
                    <PartnersSelect
                        hasPreview
                        isInvalid={!!errors.partnerId}
                        name='partnerId'
                        onChange={this.handleChange}
                        value={privateGroupClass.partnerId} />
                  }
                </div>
              </Col>
            </Row>
          </FormGroup>
        </Collapse>

        <a
            className={classNames('form-collapse-toggle dropdown-toggle', {
                                  'collapse-disabled': !hasAdvancedPackages })}
            href='#'
            onClick={this.handleToggle('isPackageSectionOpen')}>
          Package settings {!hasAdvancedPackages && <ProBadge />}
        </a>

        <Collapse
            isOpen={isPackageSectionOpen}>
          <FormGroup>
            <div className='text-small text-muted pb-3'>
              To limit a packages access to this class, remove those memberships or class packs from the list below.
              Excluding a package will mean that clients with those packages will pay the drop-in rate.
            </div>

            <Row>
              <Col
                  md='6'
                  xs='12'>
                <Label
                    className='d-block'
                    for='input-memberships'>
                  Selected Membership Access
                </Label>

                <MembershipsSelect
                    for='live'
                    id='memberships'
                    isDisabled={!hasAdvancedPackages}
                    isUpdate={this.state.isUpdate}
                    name='membershipIds'
                    onChange={this.handleChange}
                    selectedIds={privateGroupClass.membershipIds} />
              </Col>

              <Col
                  md='6'
                  xs='12'>
                <Label
                    className='d-block'
                    for='input-class-packs'>
                  Selected Class Pack Access
                </Label>

                <ClassPacksSelect
                    for='live'
                    id='class-packs'
                    isDisabled={!hasAdvancedPackages}
                    isUpdate={this.state.isUpdate}
                    name='classPackIds'
                    onChange={this.handleChange}
                    selectedIds={privateGroupClass.classPackIds} />
              </Col>
            </Row>

            <Row className='mt-2'>
              <Col
                  md='6'
                  xs='12'>
                <Label
                    className='d-block'
                    for='input-challenges'>
                  Challenges Access
                </Label>

                <ChallengesSelect
                    for='live'
                    id='challenges'
                    isDisabled={!hasAdvancedPackages}
                    isUpdate={this.state.isUpdate}
                    name='challengeIds'
                    onChange={this.handleChange}
                    selectedIds={privateGroupClass.challengeIds} />
              </Col>
            </Row>
          </FormGroup>
        </Collapse>

        <Button
            className='mt-4'
            color='primary'
            isBlock
            isDisabled={privateGroupClass.imageType === 'image' && !isImageUploaded}
            isLoading={isLoading}
            onClick={this.handleSubmit}
            size='lg'
            type='button'>
          {this.props.data.id ? 'Update' : 'Create' }
        </Button>
      </Form>
    );
  }
}

export default PrivateGroupClassForm;
