import { ApplicationActionTypes } from '../constants/actionTypes';

const initialState = {
  currentUsername: '',
  hasCustomDomain: false,
  isSidebarOpen:   false,
  lastAction:      ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case ApplicationActionTypes.SIDEBAR_TOGGLE:
      return { ...newState, isSidebarOpen: !newState.isSidebarOpen };

    case ApplicationActionTypes.SIDEBAR_HIDE:
      return { ...newState, isSidebarOpen: false };

    case ApplicationActionTypes.SET_DOMAIN:
      return { ...newState, hasCustomDomain: action.payload };

    case ApplicationActionTypes.SET_INSTRUCTOR:
      return { ...newState, currentUsername: action.payload };

    default: return state;
  }
};