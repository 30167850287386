import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { eyeDisabled } from 'react-icons-kit/ionicons/eyeDisabled';

import { Button } from '../../../components/forms';
import { Moment } from '../../../lib';

class Challenge extends React.PureComponent {
  static propTypes = {
    challenge: PropTypes.object.isRequired,
    onEdit:    PropTypes.func.isRequired,
    user:      PropTypes.object.isRequired
  }

  render() {
    const { challenge, user } = this.props;
    const { currency } = user;

    const isDateExpire = challenge.expireType == 'date';
    const expiryDate = new Moment.utc(challenge.expiryDate, 'YYYY-MM-DDhh:mm:ssaUTC')
                                 .tz(user.timeZone).format('DD, MMM, YYYY');
    return (
      <div className='row'>
        <span className='column column__name'>
          Name:
          <span className='value'>
            {challenge.name}
          </span>
        </span>

        <span className='column column__cost'>
          Price:
          <span className='value'>
            {challenge.cost.toLocaleString('en-GB', { currency, style: 'currency' })}
          </span>
        </span>

        <span className='column column__expiry'>
          Expiry:
          <span className='value'>
            {isDateExpire ? expiryDate: `${challenge.expiryDays} days`}
          </span>
        </span>

        <span className='column column__sold'>
          # Sold:
          <span className='value'>
            {challenge.soldCount} challenges
          </span>
        </span>

        <div className='column button-wrapper column__button'>

          { !challenge.visible &&
            <Icon
                className='text-muted mr-2'
                icon={eyeDisabled}
                size={24} />
            }
          <Button
              className='button'
              color='primary'
              isOutline
              onClick={this.props.onEdit(challenge.id)}
              size='sm'>
            Edit Challenge
          </Button>
        </div>
      </div>
    );
  }
}

export default Challenge;
