import React from 'react';
import PropTypes from 'prop-types';

import { ZoomLogoCreate } from '../../../../images';
import { routes } from '../../../../lib';
import { OAuthWindow } from '../../../oauth';
import { Button } from '../../index';

class ZoomConnect extends React.PureComponent {
  static propTypes = {
    isConnected: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    onLogout:    PropTypes.func,
    onSuccess:   PropTypes.func
  }

  static defaultProps = {
    isConnected: false,
    onLogout:    () => {},
    onSuccess:   () => {}
  }

  handleLogout = () => {
    this.props.onLogout('zoom');
  }

  render () {
    const { isConnected } = this.props;

    return (
      <div className='profile-connect'>
        <div className='logo'>
          <img
              alt='Zoom'
              className='img-fluid'
              src={ZoomLogoCreate} />
        </div>
        <div className='action'>
          { !isConnected &&
            <OAuthWindow
                onSuccess={this.props.onSuccess}
                url={routes.ZOOM_AUTH}>
              <Button size='sm'>Connect</Button>
            </OAuthWindow>
          }
          { isConnected &&
            <Button
                color='danger'
                isDisabled
                onClick={this.handleLogout}
                size='sm'>
              Remove
            </Button>
          }
        </div>
      </div>
    );
  }
}

export default ZoomConnect;
