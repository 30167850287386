import React from 'react';
import PropTypes from 'prop-types';
import { Container, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { AppearanceIcon, HomeIcon, UserIcon } from '../../icons';

import {
  IntegrationsIcon,
  MembershipIcon,
  NotificationsIcon,
  PolicyIcon,
  StyleIcon,
  WidgetIcon,
  WorkoutIcon
} from '../../../images';
import { DomainIcon } from '../../../images/icons';

import { routes } from '../../../lib';
import { Button } from '../../forms';
import { ProBadge } from '../../badges';
import { Heading, Panel } from '../../layout/pages';
import { tutorialLinks } from '../../../constants';

class SettingsPage extends React.PureComponent {
  static propTypes = {
    subscription: PropTypes.object.isRequired
  }

  render() {
    const { subscription } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>Settings</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container
              className='settings-page'
              fluid>
            <Heading
                color='secondary'
                hasTutorial
                link={tutorialLinks.SETTINGS}
                subtitle='Creator Portal'
                title='My Settings' />
            <Panel className='settings-menu'>
              {!isMobile && <h4 className='menu-title'>Edit your onPodio Website</h4>}

              <Media className='menu-link'>
                <Media left>
                  <HomeIcon
                      className='icon ml-1'
                      size={24} />
                </Media>

                <Media body>
                  Home
                </Media>

                <Media right>
                  <Link to={routes.SETTINGS_HOME}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media left>
                  <img
                      alt='Icon'
                      className='icon ml-1'
                      src={WorkoutIcon} />
                </Media>

                <Media body>
                  Workout
                </Media>

                <Media right>
                  <Link to={routes.SETTINGS_WORKOUT}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media left>
                  <img
                      alt='Icon'
                      className='icon ml-1'
                      src={MembershipIcon} />
                </Media>

                <Media body>
                  Packages
                </Media>

                <Media right>
                  <Link to={routes.SETTINGS_PACKAGES}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media left>
                  <img
                      alt='Icon'
                      className='icon ml-1'
                      src={StyleIcon} />
                </Media>

                <Media body>
                  Style
                </Media>

                <Media right>
                  <Link to={routes.SETTINGS_STYLE}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media left>
                  <UserIcon
                      className='icon ml-1'
                      size={24} />
                </Media>

                <Media body>
                  About
                </Media>

                <Media right>
                  <Link to={routes.SETTINGS_ABOUT}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

              <h4 className={classNames('menu-title', { 'text-secondary': isMobile})}>
                Add onPodio to Existing Website
              </h4>

              <Media className='menu-link'>
                <Media left>
                  <img
                      alt='Icon'
                      className='icon ml-1'
                      src={WidgetIcon} />
                </Media>

                <Media body>
                  Embed onPodio Widget
                </Media>

                <Media right>
                  <Link to={routes.SETTINGS_WIDGETS}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

              <h4 className={classNames('menu-title', { 'text-secondary': isMobile})}>Customizations</h4>

              <Media className='menu-link'>
                <Media left>
                  <AppearanceIcon
                      className='icon ml-1'
                      size={24} />
                </Media>

                <Media body>
                  Customize Theme
                  {!subscription.webisteTemplates && !subscription.customColors && <ProBadge className='ml-3' />}
                </Media>

                <Media right>
                  <Link to={routes.SETTINGS_CUSTOMIZATION}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media left>
                  <img
                      alt='Icon'
                      className='icon ml-1'
                      src={DomainIcon} />
                </Media>

                <Media body>
                  Custom Domain
                  {!subscription.customDomain && <ProBadge className='ml-3' />}
                </Media>

                <Media right>
                  <Link to={routes.SETTINGS_DOMAIN}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

              <h4 className={classNames('menu-title', { 'text-secondary': isMobile})}>Other Settings</h4>

              <Media className='menu-link'>
                <Media left>
                  <img
                      alt='Icon'
                      className='icon ml-1'
                      src={IntegrationsIcon} />
                </Media>

                <Media body>
                  My Stripe / Zoom Integration
                </Media>

                <Media right>
                  <Link to={routes.INTEGRATIONS}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media left>
                  <img
                      alt='Icon'
                      className='icon ml-1'
                      src={NotificationsIcon} />
                </Media>

                <Media body>
                  My Email Notifications
                </Media>

                <Media right>
                  <Link to={routes.SETTINGS_NOTIFICATIONS}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media left>
                  <img
                      alt='Icon'
                      className='icon ml-1'
                      src={PolicyIcon} />
                </Media>

                <Media body>
                  My Cancellation Policy
                </Media>

                <Media right>
                  <Link to={routes.SETTINGS_CANCELLATION}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media left>
                  <Icon
                      fixedWidth
                      icon='map-marker-alt' />
                </Media>

                <Media body>
                  My Physical Locations
                </Media>

                <Media right>
                  <Link to={routes.LOCATIONS}>
                    <Button
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SettingsPage;
