import { default as COLORS } from './templateColors';

const { BIG_STONE, BLACK, CONCRETE, DINGLEY, FRENCH_PASS, GREEN, RANGOON_GREEN, WHITE, DEFAULT } = COLORS;

export const templates = {
  DEFAULT: 'default',
  ELEGANT: 'elegant'
  //URBAN:   'urban'
};

export const templateDefaultValues = {
  [templates.DEFAULT]: {
    bodyFontFamily:                  'Poppins, sans-serif',
    buttonsBackgroundColor:          FRENCH_PASS,
    buttonsFontColor:                BIG_STONE,
    headingsFontFamily:              'Poppins, sans-serif',
    pageBackgroundColor:             DEFAULT,
    primaryAccentsBackgroundColor:   BIG_STONE,
    primaryAccentsFontColor:         WHITE,
    secondaryAccentsBackgroundColor: CONCRETE,
    secondaryAccentsFontColor:       BIG_STONE
  },
  [templates.ELEGANT]: {
    bodyFontFamily:                  'Inter, sans-serif',
    buttonsBackgroundColor:          CONCRETE,
    buttonsFontColor:                BLACK,
    headingsFontFamily:              'Inter, sans-serif',
    pageBackgroundColor:             WHITE,
    primaryAccentsBackgroundColor:   DINGLEY,
    primaryAccentsFontColor:         WHITE,
    secondaryAccentsBackgroundColor: CONCRETE,
    secondaryAccentsFontColor:       BLACK
  },
  [templates.URBAN]: {
    bodyFontFamily:                  'Poppins, sans-serif',
    buttonsBackgroundColor:          RANGOON_GREEN,
    buttonsFontColor:                WHITE,
    headingsFontFamily:              'Bebas Neue, sans-serif',
    pageBackgroundColor:             WHITE,
    primaryAccentsBackgroundColor:   GREEN,
    primaryAccentsFontColor:         RANGOON_GREEN,
    secondaryAccentsBackgroundColor: RANGOON_GREEN,
    secondaryAccentsFontColor:       WHITE
  }
};
