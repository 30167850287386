import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

class InstructorName extends React.PureComponent {
  static propTypes = {
    hasIcon:     PropTypes.bool,
    hasLocation: PropTypes.bool,
    instructor:  PropTypes.object.isRequired,
    subtitle:    PropTypes.string
  }

  static defaultProps = {
    hasIcon:     true,
    hasLocation: false,
    subtitle:    ''
  }

  render () {
    const { instructor, hasIcon, hasLocation, subtitle } = this.props;

    const location = !isEmpty(instructor.location)
        ? [instructor.location.city, instructor.location.state].join(', ')
        : '';

    return (
      <div className='instructor-name'>
        { subtitle &&
          <span className='instructor-subtitle'>{subtitle}</span>
        }
        { (instructor.firstName && instructor.lastName) &&
          <h1 className='full-name'>
            {`${instructor.firstName} ${instructor.lastName}`}
          </h1>
        }
        { hasLocation && location &&
          <span className='location'>
            { hasIcon &&
              <Icon
                  className='mr-2'
                  icon={['fas', 'map-marker-alt']} />
            }
            {location}
          </span>
        }
      </div>
    );
  }
}

export default InstructorName;
