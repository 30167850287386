import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Label, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';

import { DonationTarget } from '../../modals';
import { routes } from '../../../lib';
import { Button, Checkbox } from '../../forms';
import { Heading, Panel } from '../../layout/pages';
import { BackButton } from '../../buttons';
import SettingsOption from '../../settings/menuOptionsSection';

class SettingsHomeLandingPage extends React.PureComponent {
  static propTypes = {
    createDonationTarget: PropTypes.func.isRequired,
    donationClasses:      PropTypes.array.isRequired,
    donationTargets:      PropTypes.object.isRequired,
    instructor:           PropTypes.object.isRequired,
    updateDonationTarget: PropTypes.func.isRequired,
    updateProfile:        PropTypes.func.isRequired,
    user:                 PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const { instructor } = props;

    if (instructor.id && !state.isMounted) {
      return {
        donationsEnabled: instructor.donationsEnabled,
        isMounted:        true
      };
    }

    return state;
  }

  state = {
    donationsEnabled:          false,
    isDonationTargetModalOpen: false,
    isMounted:                 false
  };

  handleChange = (setting) => () => {
    this.setState((prevState) => ({
      [setting]: !prevState[setting]
    }), () => {
      this.handleUpdate(setting);
    });
  };

  handleModal = (modal) => (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ [modal]: !prevState[modal] }));
  }

  handleUpdate = (setting) => {
    this.props.updateProfile({ [setting]: this.state[setting] });
  };

  render() {
    const { donationsEnabled } = this.state;

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS_HOME} />}

        <Helmet>
          <title>Home Landing</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container
              className='settings-page'
              fluid>
            <Heading
                color='secondary'
                subtitle='Home'
                title='Landing Section' />
            <Panel className='settings-menu'>
              <span className='menu-title'>General Information</span>

              <SettingsOption
                  route={routes.EDIT_PROFILE}
                  title='My Basic Information' />

              <SettingsOption
                  icon={
                    <Icon
                        fixedWidth
                        icon='map-marker-alt' />
                  }
                  route={routes.LOCATIONS}
                  title='Edit My Location' />

              <span className='menu-title'>Donation Target Widget</span>

              <Media className='menu-link d-block'>
                <Media body>
                  <div className='custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='donation'
                        isChecked={donationsEnabled}
                        name='donation'
                        onChange={this.handleChange('donationsEnabled')} />
                    <Label
                        className='custom-control-label'
                        htmlFor='input-donation'>
                      Show Donation Target widget on your page
                    </Label>
                  </div>
                </Media>
              </Media>

              <Media className={classNames('menu-link', { 'is-disabled': !donationsEnabled })}>
                <Media left>
                  <Icon
                      fixedWidth
                      icon='donate' />
                </Media>
                <Media body>
                  Set Donation Target
                </Media>
                <Media right>
                  <Button
                      isDisabled={!donationsEnabled}
                      onClick={this.handleModal('isDonationTargetModalOpen')}
                      size='sm'>
                    Edit
                  </Button>
                </Media>
              </Media>
            </Panel>
          </Container>
        </div>
        <div className='modals'>
          { this.state.isDonationTargetModalOpen &&
            <DonationTarget
                createDonationTarget={this.props.createDonationTarget}
                donationClasses={this.props.donationClasses}
                donationTargets={this.props.donationTargets}
                isOpen={this.state.isDonationTargetModalOpen}
                onToggle={this.handleModal('isDonationTargetModalOpen')}
                updateDonationTarget={this.props.updateDonationTarget}
                user={this.props.user} />
          }
        </div>
      </React.Fragment>
    );
  }
}

export default SettingsHomeLandingPage;
