import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { gift } from 'react-icons-kit/icomoon/gift';
import { Moment, clientsRoutes, createMilestones, getTimeZone, routes } from '../../lib';
import { Link } from '../../components/inputs';

class UpcomingMilestones extends React.PureComponent {
  static propTypes = {
    isLoading:  PropTypes.bool.isRequired,
    milestones: PropTypes.array.isRequired,
    user:       PropTypes.object.isRequired
  };


  static getDerivedStateFromProps(props) {
    const milestones = createMilestones(props.milestones, getTimeZone(props.user.timeZone));

    return { milestones };
  }

  state = {
    milestones: []
  }

  renderBirthdayMilestone = (milestone, index) => {
    const date = new Moment(milestone.date, 'YYYY-MM-DDhh:mm:ss').format('dddd, MMM DD');

    return (
      milestone.clientName && (
        <div
            className='d-flex milestone mb-1'
            key={index}>
          <div className='birthday-block mr-2'>
            <Icon
                className='icon'
                icon={gift} />
          </div>
          <span className='text'>
            <Link
                className='text-decoration-none'
                to={routes.CLIENTS + clientsRoutes.CLIENT(milestone.clientId)}>
              {milestone.clientName}&#39;s
            </Link>&nbsp;
            <span className='font-weight-bold'>birthday</span>
            &nbsp;is on {date}
          </span>
        </div>
    ));
  }

  renderBookingMilestone = (milestone, index) => {
    const order = milestone.count == 1 ? `${milestone.count}st` : `${milestone.count}th`;
    const date = new Moment(milestone.date, 'YYYY-MM-DDhh:mm:ss').tz(this.props.user.timeZone)
                                                                 .format('dddd, MMM DD');

    return (
      milestone.clientName && (
        <div
            className='d-flex milestone mb-1'
            key={index}>
          <div className={`booking-block booking-${milestone.count}th mr-2`}>
            {milestone.count}
          </div>
          <span className='text'>
            <Link
                className='text-decoration-none'
                to={routes.CLIENTS + clientsRoutes.CLIENT(milestone.clientId)}>
              {milestone.clientName}&#39;s&nbsp;
            </Link>
            <span className='font-weight-bold'>{order} live class</span>
            &nbsp;is on {date}
          </span>
        </div>
    ));
  }

  renderMilestones = () => {
    const { milestones } = this.state;

    return (
      <div>
        {milestones.map((milestone, index) => (
          milestone.type === 'birthday'
          ? this.renderBirthdayMilestone(milestone, index)
          : this.renderBookingMilestone(milestone, index)
        ))}
        <p className='note ml-4'>
          Note: Milestone class #s include Live Classes only
        </p>
      </div>
    );
  }

  renderEmpty = () => (
    <p className='small text-muted ml-4'>No upcoming milestones in the next 7 days</p>
  )

  renderSpinner = () => (
    <div className='d-flex justify-content-center'>
      <Spinner color='muted' />
    </div>
  )

  render () {
    const { milestones } = this.state;

    return (
      <div className='mb-2'>
        <span className='title'>
          <span
              aria-label="donut"
              role="img" >
            &#127873;
          </span>&nbsp;
          Upcoming Milestones
        </span>

        <div className='mt-2'>
          { this.props.isLoading
            ? this.renderSpinner()
            : (milestones.length > 0 ? this.renderMilestones() : this.renderEmpty())
          }
        </div>
      </div>
    );
  }
}

export default UpcomingMilestones;
