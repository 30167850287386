import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Media } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Button } from '../forms';
import { OAuthWindow } from './';

class OAuthAccount extends React.PureComponent {
  static propTypes = {
    data:      PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
    onLogout:  PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    provider:  PropTypes.oneOf(['facebook', 'google', 'instagram', 'spotify']).isRequired,
    route:     PropTypes.string.isRequired,
    user:      PropTypes.object.isRequired
  };

  static defaultProps = {
    onSuccess: () => {}
  }

  shouldComponentUpdate(nextProps) {
    return this.props.user.id === nextProps.data.user_id;
  }

  renderIcon = () => {
    switch (this.props.provider) {
      case 'facebook':
        return ['fab', 'facebook-f'];

      case 'google':
        return ['fab', 'google'];

      case 'instagram':
        return ['fab', 'instagram'];

      case 'spotify':
        return ['fab', 'spotify'];

      default:
        return null;
    }
  };

  handleLogout = () => {
    const { provider } = this.props;

    this.props.onLogout(provider);
  };

  handleSuccess = () => {
    this.props.onSuccess();
  }

  render () {
    const { data, provider, route } = this.props;
    const className = classNames('oauth-account', provider, { 'connected': !!data.name });

    return (
      <Media className={className}>
        <Media left>
          <Icon
              fixedWidth
              icon={this.renderIcon()}
              size='3x' />
        </Media>
        <Media body>
          { !data.name &&
            <OAuthWindow
                onSuccess={this.handleSuccess}
                url={route}>
              <Button
                  isBlock
                  isOutline
                  size='sm'>Connect</Button>
            </OAuthWindow>
          }
          { data.name &&
            <React.Fragment>
              <span className='account-name'>{data.name}</span>
              <Button
                  color='danger'
                  isBlock
                  isOutline
                  onClick={this.handleLogout}
                  size='sm'>Disconnect</Button>
            </React.Fragment>
          }
        </Media>
      </Media>
    );
  }
}

export default OAuthAccount;
