export default {
  CLEAR: '@@profile/CLEAR',

  CREATE:         '@@profile/CREATE',
  CREATE_FAILURE: '@@profile/CREATE_FAILURE',
  CREATE_FINISH:  '@@profile/CREATE_FINISH',
  CREATE_SUCCESS: '@@profile/CREATE_SUCCESS',

  EDIT:         '@@profile/EDIT',
  EDIT_FAILURE: '@@profile/EDIT_FAILURE',
  EDIT_SUCCESS: '@@profile/EDIT_SUCCESS',

  FETCH:         '@@profile/FETCH',
  FETCH_FAILURE: '@@profile/FETCH_FAILURE',
  FETCH_SUCCESS: '@@profile/FETCH_SUCCESS',

  UPDATE:              '@@profile/UPDATE',
  UPDATE_AVAILABILITY: '@@profile/AVAILABILITY/UPDATE',
  UPDATE_FAILURE:      '@@profile/UPDATE_FAILURE',
  UPDATE_SUCCESS:      '@@profile/UPDATE_SUCCESS'
};
