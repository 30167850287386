/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import { flatten } from 'lodash';

class InstructorMarkers extends React.PureComponent {
  static propTypes = {
    google:      PropTypes.object.isRequired,
    instructors: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  static getDerivedStateFromProps(props) {
    /* filter out markers without coordinates */
    const instructors = props.instructors.filter((instructor) => {
      const locations = instructor.locations.filter((location) => (
        location.latitude && location.longitude
      ));

      return !!locations.length;
    });

    return {
      instructors,

      map:  props.google.map,
      maps: props.google.maps
    };
  }

  state = {
    instructors: [],
    map:         {},
    maps:        {}
  }

  markers = [];

  componentWillUnmount() {
    this.clearMarkers();
  }

  clearMarkers = () => {
    flatten(this.markers).forEach((marker) => {
      marker.setMap(null);
    });

    this.markers = [];
  }

  drawMarkers = () => {
    const { instructors, maps, map } = this.state;

    const bounds = new maps.LatLngBounds();

    this.clearMarkers();
    this.markers = instructors.map((instructor) => {
      const locations = instructor.locations.filter((location) => (
        location.latitude && location.longitude
      ));

      const marker = locations.map((location) => {
        const point = new maps.LatLng({
          lat: parseFloat(location.latitude),
          lng: parseFloat(location.longitude)
        });

        bounds.extend(point);

        const infoWindow = new maps.InfoWindow({
          content: location.name
        });

        const marker = new maps.Marker({
          map,
          position: point,
          title:    location.name
        });

        /* add lsiteners for marker interactions */
        maps.event.addListener(marker, 'mouseover', function() {
          infoWindow.open(map, this);
        });

        maps.event.addListener(marker, 'mouseout', function() {
          infoWindow.close();
        });

        return marker;
      });

      return marker;
    });

    setTimeout(() => {
      map.fitBounds(bounds);
    }, 200);
  }

  render() {
    this.drawMarkers();

    return null;
  }
}

export default InstructorMarkers;
