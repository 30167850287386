import React from 'react';
import PropTypes from 'prop-types';
import Async from 'react-select/async';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { PlacesAutocomplete } from '../../lib';
import { selectFieldStyle } from '../../constants';

class LocationSelect extends React.PureComponent {
  static propTypes = {
    isDisabled:  PropTypes.bool,
    isInvalid:   PropTypes.bool,
    name:        PropTypes.string,
    onSelect:    PropTypes.func,
    placeholder: PropTypes.string
  }

  static defaultProps = {
    isDisabled:  false,
    isInvalid:   false,
    name:        '',
    onChange:    () => {},
    onSelect:    () => {},
    placeholder: 'Search...'
  }

  state = {
    geocode:        {},
    menuIsOpen:     false,
    options:        [],
    query:          '',
    selectedOption: ''
  }

  placesAutocomplete = new PlacesAutocomplete('address', 'addresses');

  handleInputChange = (option, { action }) => {
    if (action !== 'input-blur' && action !== 'menu-close') {
      this.setState({
        menuIsOpen: true,
        query:      option
      });
    }
  }

  loadOptions = (inputValue, callback) => {
    setTimeout(() => {
      const options = this.placesAutocomplete.getFormattedOptions(inputValue);
      this.setState({
        options: options
      }, () => {
        callback(this.state.options);
      });
    }, 1000);
  }

  handleSelect = (option, { action }) => {
    if (action === 'clear') {
      this.setState({
        query:          '',
        selectedOption: ''
      });
    }

    const geocode = this.placesAutocomplete.geocodeRequest(option.value);

    geocode.then((response) => {
      return this.setState({
        geocode:        response.data.geocoders[0],
        menuIsOpen:     false,
        selectedOption: option
      }, () => {
        this.props.onSelect(this.state.geocode);
      });
    }).catch((e) => { throw e; });
  }

  render() {
    const { menuIsOpen, query, options, selectedOption } = this.state;
    const { isDisabled, placeholder, isInvalid, name } = this.props;

    const formatOptionLabel = ({ label, city }) => (
      <div className='d-flex align-items-center'>
        <Icon
            className='mr-2'
            fixedWidth
            icon='map-marker-alt' />
        <span style={{whiteSpace: 'nowrap'}}>{label}</span>
        <small
            className='ml-2'
            style={{fontSize: '11px', lineHeight: '14px', whiteSpace: 'nowrap'}}>
          {city}
        </small>
      </div>
    );

    return (
      <Async
          blurInputOnSelect
          cacheOptions
          className={classNames('custom-input-group', { 'is-invalid': isInvalid })}
          closeMenuOnSelect={false}
          defaultOptions={options}
          formatOptionLabel={formatOptionLabel}
          inputValue={query}
          isClearable
          isDisabled={isDisabled}
          loadOptions={this.loadOptions}
          menuIsOpen={menuIsOpen}
          name={name}
          onChange={this.handleSelect}
          onInputChange={this.handleInputChange}
          placeholder={placeholder}
          styles={selectFieldStyle.location}
          value={selectedOption} />
    );
  }
}

export default LocationSelect;