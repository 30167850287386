import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { findIndex } from 'lodash';

import { Tags } from '../';

class InstructorServices extends React.PureComponent {
  static propTypes = {
    children:    PropTypes.node.isRequired,
    onChange:    PropTypes.func.isRequired,
    profile:     PropTypes.object.isRequired,
    profileData: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      ids: props.profile.services.map((service) => service.id)
    };
  }

  state = {
    ids: []
  }

  handleChange = (option, name) => {
    const { services } = this.props.profile;

    const index = findIndex(services, ['id', option.id]);
    if (index > -1) {
      this.props.onChange(
        [...services.slice(0, index), ...services.slice(index + 1, services.length)],
        name
      );
    } else {
      this.props.onChange(
        [...services, option],
        name
      );
    }
  }

  render() {
    return (
      <div className='text-block'>
        {this.props.children}

        <Row>
          <Col>
            <Tags
                color='warning'
                name='services'
                onChange={this.handleChange}
                options={this.props.profileData.data.services}
                selectedIds={this.state.ids} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default InstructorServices;
