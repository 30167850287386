import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import { toastr } from 'react-redux-toastr';
import { filter, head, includes, isEmpty, some, sumBy } from 'lodash';

import { PURCHASE_STEPS } from '../../constants';
import { Moment, PaymentMethod, api, extractError, toCamelCase } from '../../lib';
import { Charge, Guest, Info, Success } from './purchaseClass';

class PurchaseClassModal extends React.PureComponent {
  static propTypes = {
    challenges:       PropTypes.array.isRequired,
    classPacks:       PropTypes.array.isRequired,
    createDiscount:   PropTypes.func.isRequired,
    discount:         PropTypes.object.isRequired,
    fetchClassPacks:  PropTypes.func.isRequired,
    fetchMemberships: PropTypes.func.isRequired,
    instructor:       PropTypes.object.isRequired,
    instructorPacks:  PropTypes.array.isRequired,
    isOpen:           PropTypes.bool,
    loader:           PropTypes.object.isRequired,
    memberships:      PropTypes.array.isRequired,
    onToggle:         PropTypes.func,
    requestedClass:   PropTypes.object.isRequired,
    restoreDiscount:  PropTypes.func.isRequired,
    subscriptions:    PropTypes.array.isRequired,
    subtractCredit:   PropTypes.func.isRequired,
    user:             PropTypes.object.isRequired
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  static getDerivedStateFromProps(props) {
    const filteredPacks = filter(props.classPacks, (classPack) => (
      classPack.onDemand > 0 || classPack.combined > 0 &&
        !includes(props.requestedClass.classPackIds, classPack.classPack.id))
    );

    const filteredChallenges = filter(props.challenges, (challenge) => (
      !includes(props.requestedClass.challengeIds, challenge.instructorChallengeId)
    ));

    const combined = sumBy(filteredPacks, 'combined');
    const onDemand = sumBy(filteredPacks, 'onDemand');

    const classPack = head(filteredPacks);
    const challenge = head(filteredChallenges);

    const isSubscribed = some(props.subscriptions, (subscription) => {
      const endsAt = new Moment.utc(subscription.endsAt, 'YYYY-MM-DDhh:mm:ssUTC');

      return subscription.membership &&
             subscription.status != 'inactive' &&
             subscription.membership.premium.onDemand == 'true' &&
             subscription.membership.instructor.id == props.instructor.id &&
             !includes(props.requestedClass.membershipIds, subscription.membership.id) &&
             endsAt.isAfter(new Moment.now());
    });

    const isClassPackAvailable = !!(onDemand + combined);
    const isChallengeAvailable = !isEmpty(challenge);

    return {
      challenge: {
        id: challenge && challenge.id
      },
      classPack: {
        combined,
        id: classPack && classPack.id,
        onDemand
      },
      isChallengeAvailable,
      isClassPackAvailable,
      isSubscribed
    };
  }

  state = {
    classPack:            {},
    guest:                {},
    isChallengeAvailable: false,
    isClassPackAvailable: false,
    isLoading:            false,
    isSubscribed:         false,
    purchasedClass:       {},
    step:                 PURCHASE_STEPS.info
  }

  handleChange = (value, inputName, callback) => (
    this.setState({ [inputName]: value }, callback)
  )

  handleLoading = (isLoading, callback) => (
    this.setState({ isLoading }, callback)
  )

  handleNext = (step) => (
    this.handleLoading(true, () => (
      this.setState({ step }, () => this.handleLoading(false))
    ))
  )

  handlePurchase = (data = {}) => (
    this.handleLoading(true, () => (
      api.purchasedClass.create({
        onDemand: {
          ...data,
          challengeId:          this.state.challenge.id,
          classPackId:          this.state.classPack.id,
          clientId:             this.state.guest.id,
          instructorOnDemandId: this.props.requestedClass.id,
          method:               new PaymentMethod(
                                  this.state.isClassPackAvailable,
                                  this.state.isSubscribed,
                                  this.state.isChallengeAvailable).get()
        }
      })
      .then((response) => (
        this.setState({ purchasedClass: toCamelCase(response && response.data) }, () => {
          this.props.subtractCredit({ creditType: 'onDemand', id: this.state.classPack.id });
          toastr.success('Transaction Complete');
          return this.handleNext(PURCHASE_STEPS.success);
        })
      ))
      .catch((exception) => {
        const error = extractError(exception);
        toastr.error(error.message);
        this.handleLoading(false);
      })
    ))
  )

  handleToggle = () => {
    if (this.state.isLoading) return;

    this.props.onToggle();
    this.props.discount && this.props.restoreDiscount();
  }

  renderContent = () => {
    switch (this.state.step) {
      case PURCHASE_STEPS.info:
        return (
          <Info
              classPack={this.state.classPack}
              date={this.state.date}
              fetchClassPacks={this.props.fetchClassPacks}
              fetchMemberships={this.props.fetchMemberships}
              instructor={this.props.instructor}
              instructorPacks={this.props.instructorPacks}
              isChallengeAvailable={this.state.isChallengeAvailable}
              isClassPackAvailable={this.state.isClassPackAvailable}
              isLoading={this.state.isLoading}
              isSubscribed={this.state.isSubscribed}
              loader={this.props.loader}
              memberships={this.props.memberships}
              onCharge={this.handlePurchase}
              onLoading={this.handleLoading}
              onNext={this.handleNext}
              requestedClass={this.props.requestedClass}
              user={this.props.user} />
        );

      case PURCHASE_STEPS.guest:
        return (
          <Guest
              instructor={this.props.instructor}
              isLoading={this.state.isLoading}
              onChange={this.handleChange}
              onLoading={this.handleLoading}
              onNext={this.handleNext}
              requestedClass={this.props.requestedClass} />
        );

      case PURCHASE_STEPS.charge:
        return (
          <Charge
              client={this.state.guest}
              createDiscount={this.props.createDiscount}
              discount={this.props.discount}
              instructor={this.props.instructor}
              isLoading={this.state.isLoading}
              onCharge={this.handlePurchase}
              onLoading={this.handleLoading}
              requestedClass={this.props.requestedClass}
              user={this.props.user} />
        );

      case PURCHASE_STEPS.success:
        return (
          <Success
              instructor={this.props.instructor}
              purchasedClass={this.state.purchasedClass}
              user={this.props.user} />
        );

      default: return null;
    }
  }

  render() {
    return (
      <Modal
          className='modal-booking'
          isOpen={this.props.isOpen}
          toggle={this.handleToggle}>
        <ModalHeader toggle={this.handleToggle} />

        {this.renderContent()}
      </Modal>
    );
  }
}

export default PurchaseClassModal;
