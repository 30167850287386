import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';

import { Link } from '../../../../components/inputs';
import {
  Contacts,
  Fundraiser,
  InstructorName,
  Photo
} from '../../../../components/instructor';
import { instructorRoutes } from '../../../../lib';
import OptionalSections from './optionalSections';

class HomeElegant extends React.PureComponent {
  static propTypes = {
    donationBookings: PropTypes.array.isRequired,
    donationTargets:  PropTypes.object.isRequired,
    follow:           PropTypes.func.isRequired,
    hasCustomDomain:  PropTypes.bool.isRequired,
    instructor:       PropTypes.object.isRequired,
    match:            PropTypes.object.isRequired,
    siteTemplate:     PropTypes.string,
    unFollow:         PropTypes.func.isRequired
  }

  static defaultProps = {
    siteTemplate: 'default'
  }

  handleFollow = () => {
    this.props.instructor.isFollowing ? this.props.unFollow() : this.props.follow();
  };

  render() {
    const { donationTargets, instructor, donationBookings, match, siteTemplate, hasCustomDomain } = this.props;

    const combineRoute = (route) => {
      return hasCustomDomain ? route : match.params.username + route;
    };

    const desktopPhoto = instructor.photo ? instructor.photo.lg : '';
    const mobilePhoto = instructor.mobilePhoto ? instructor.mobilePhoto.photo : '';
    const photo = isMobile && mobilePhoto ? mobilePhoto : desktopPhoto;

    return (
      <div className='profile-section template-elegant'>
        <div className='profile-hero'>
          <Photo src={photo} />

          <div className='profile-hero-content'>
            <InstructorName
                hasIcon={false}
                hasLocation
                instructor={instructor}
                isHome />
            <Link
                className='btn btn-secondary'
                to={combineRoute(instructorRoutes.WORKOUT.path)}>
              Book Now
            </Link>
            <div className='instructor-tagline'>
              <span>{instructor.tagline}</span>
            </div>

            { instructor.donationsEnabled && !isEmpty(donationTargets.name) &&
              <Fundraiser
                  donationBookings={donationBookings}
                  donationTargets={donationTargets}
                  instructor={instructor} />
            }
          </div>
        </div>
        <Contacts
            instructor={instructor}
            siteTemplate={siteTemplate} />
        <OptionalSections
            homeConfig={instructor.homeConfig}
            instructor={instructor}
            match={match} />
      </div>
    );
  }
}

export default HomeElegant;
