export default {
  RECOVER:         '@@password/RECOVER',
  RECOVER_FAILURE: '@@password/RECOVER_FAILURE',
  RECOVER_SUCCESS: '@@password/RECOVER_SUCCESS',

  RESET:         '@@password/RESET',
  RESET_FAILURE: '@@password/RESET_FAILURE',
  RESET_SUCCESS: '@@password/RESET_SUCCESS'
};

