import { all, call, put, takeLatest } from 'redux-saga/effects';
import { pick } from 'lodash';
import { push } from 'connected-react-router';

import { api, extractError, routes, toCamelCase } from '../lib';
import { SearchActionTypes } from '../constants/actionTypes';
import {
  ErrorActions,
  InstructorsActions,
  LoaderActions,
  SearchActions,
  StudiosActions
} from '../actions';

function* watchSearch() {
  yield takeLatest(SearchActionTypes.SEARCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.search.create, payload);

      yield put(SearchActions.SEARCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(SearchActions.SEARCH_FAILURE());

      /* error */
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchSearchSuccess() {
  yield takeLatest(SearchActionTypes.SEARCH_SUCCESS, function* ({ payload }) {

    yield put(push(routes.SEARCH));

    /* DISPATCH RESULTS */
    yield put(InstructorsActions.GET_INSTRUCTORS(
      payload.instructors || [],
      pick(payload, ['perPage', 'subject', 'totalPages', 'totalRecords', 'totalIds'])
    ));

    yield put(StudiosActions.GET_STUDIOS(
      payload.locations || [],
      pick(payload, ['perPage', 'subject', 'totalPages', 'totalRecords', 'totalIds'])
    ));

    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchSearchFailure() {
  yield takeLatest(SearchActionTypes.SEARCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* searchSaga() {
  yield all([
    watchSearch(),
    watchSearchSuccess(),
    watchSearchFailure()
  ]);
}
