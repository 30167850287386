import { requestManager } from './';

/* please keep these name singular       *
 * i.e 'card' instead of 'cards', etc... */

export default {
  activity: {
    get: () =>
      requestManager.get('/api/activities')
  },

  attachments: {
    delete: (params) =>
      requestManager.delete('/api/attachments/' + params.id)
  },

  autocompleter: {
    fetch: (params) =>
      requestManager.get('/api/autocomplete', params)
  },

  billingPortal: {
    get: () =>
      requestManager.get('/api/billing_portal')
  },

  bookingRequests: {
    delete: (params) =>
      requestManager.delete('/api/private_sessions/requests/' + params.bookingRequest.id),
    fetch: () =>
      requestManager.get('/api/private_sessions/requests')
  },

  card: {
    delete: (params) =>
      requestManager.delete('/api/cards/' + params.cardId),
    fetch: () =>
      requestManager.get('/api/cards'),
    get: (params) =>
      requestManager.get('/api/' + params.username + '/cards'),
    update: (params) =>
      requestManager.put('/api/' + params.username + '/cards/' + params.cardId, params)
  },

  challenge: {
    fetch: (params) =>
      requestManager.get('/api/' + params.username + '/challenges')
  },

  charge: {
    create: (params) =>
      requestManager.post('/api/charges', params),
    destroy: (params) =>
      requestManager.delete('api/charges/' + params.id),
    fetch: () =>
      requestManager.get('/api/charges')
  },

  checkout: {
    create: (params) =>
      requestManager.post('/api/checkout', params)
  },

  classPack: {
    fetch: (params) =>
      requestManager.get('/api/' + params.username + '/class_packs')
  },

  client: {
    create: (params) =>
      requestManager.post('/api/clients', params)
  },

  collection: {
    create: (params) =>
      requestManager.post('/api/instructors/collections', params),
    delete: (params) =>
      requestManager.delete('/api/instructors/collections/' + params.id),
    fetch: () =>
      requestManager.get('/api/instructors/collections'),
    
    folder: {
      fetch: (params) =>
        requestManager.get('/api/instructors/collections/' + params.id + '/folders')
    },
    
    get: (params) =>
      requestManager.get('/api/instructors/collections/' + params.id),
    
    onDemand: {
      fetch: (params) =>
        requestManager.get('/api/instructors/collections/' + params.id + '/on_demands')
    },
    
    update: (params) =>
      requestManager.put('/api/instructors/collections/' + params.collection.id, params)

  },

  coupon: {
    create: (params) =>
      requestManager.post('/api/coupons', params),
    delete: (params) =>
      requestManager.delete('/api/coupons/' + params.coupon.id, params),
    fetch: () =>
      requestManager.get('/api/coupons'),
    get: (params) =>
      requestManager.get('/api/coupons/' + params.id),
    update: (params) =>
      requestManager.put('/api/coupons/' + params.coupon.id, params)
  },

  customer: {
    create: (params) =>
      requestManager.post('/api/customers', params)
  },

  customization: {
    get: () =>
      requestManager.get('/api/customization/'),
    update: (params) =>
      requestManager.put('/api/customization', params)
  },

  deleted: {
    memberships: () => requestManager.get('/api/deleted/memberships')
  },

  discount: {
    create: (params) =>
      requestManager.post('/api/discounts', params)
  },

  domains: {
    fetch: () =>
      requestManager.get('/api/domains')
  },

  donationTarget: {
    fetch: () =>
      requestManager.get('/api/donation_targets')
  },

  email: {
    setting: {
      get: () =>
        requestManager.get('/api/emails/settings'),
      update: (params) =>
        requestManager.put('/api/emails/settings', params)
    },

    template: {
      create: (params) =>
        requestManager.post('/api/emails/templates', params),
      fetch: () =>
        requestManager.get('/api/emails/templates'),
      update: (params) =>
        requestManager.put('/api/emails/templates/' + params.emailTemplate.name, params)
    }
  },

  favoriteOnDemand: {
    create: (params) =>
      requestManager.post('/api/on_demands/favorites', params),
    delete: (params) => (
      requestManager.delete('api/on_demands/favorites/' + params.id)),
    fetch: () =>
      requestManager.get('/api/on_demands/favorites')
  },

  feedback: {
    create: (params) =>
      requestManager.post('/api/feedbacks', params)
  },

  filter: {
    create: (params) =>
      requestManager.post('/api/filter', params)
  },

  formats: {
    fetch: () =>
      requestManager.get('api/formats')
  },

  groupClasses: {
    bookings: {
      create: (params) =>
        requestManager.post('/api/group_classes/bookings', params),
      delete: (params) =>
        requestManager.delete('/api/group_classes/bookings/' + params.groupClass.bookingId),
      fetch: () =>
        requestManager.get('/api/group_classes/bookings')
    },

    create: (params) =>
      requestManager.post('/api/group_classes', params),
    delete: (params) =>
      requestManager.delete('/api/group_classes/' + params.groupClass.id),
    fetch: () =>
      requestManager.get('/api/group_classes'),

    recurring: {
      create: (params) =>
        requestManager.post('/api/group_classes/recurring', params),
      delete: (params) =>
        requestManager.delete('/api/group_classes/recurring/' + params.groupClass.recurringId)
    },

    update: (params) =>
      requestManager.put('/api/group_classes', params)
  },

  identity: {
    destroy: (params) => requestManager.delete('/auth/' + params.provider)
  },

  instagram: {
    destroy: () => requestManager.delete('/auth/instagram/')
  },

  instructor: {
    additionalInformation: {
      create: (params) =>
        requestManager.post('api/instructors/additional_information', params)
    },

    autocompleter: {
      fetch: (params) =>
        requestManager.get('/api/instructors/' + params.instructorUsername + '/autocomplete', params)
    },

    availability: {
      get: (params) =>
        requestManager.get('/api/instructors/' + params.username + '/availability', params),
      update: (params) =>
        requestManager.put('/api/instructors/availability', params)
    },

    bookingRequests: {
      delete: (params) =>
        requestManager.delete('/api/instructors/private_sessions/requests/' + params.bookingRequest.id,
                              { note: params.note }),
      fetch: () =>
        requestManager.get('/api/instructors/private_sessions/requests'),
      update: (params) =>
        requestManager.put('/api/instructors/private_sessions/requests/' + params.bookingRequest.id)
    },

    challenge: {
      create: (params) =>
        requestManager.post('/api/instructors/challenges', params),
      delete: (params) =>
        requestManager.delete('/api/instructors/challenges/' + params.challenge.id),
      fetch: () =>
        requestManager.get('/api/instructors/challenges'),
      get: (params) =>
        requestManager.get('/api/instructors/challenges/' + params.id),
      update: (params) =>
        requestManager.put('/api/instructors/challenges/' + params.challenge.id, params)
    },


    claims: {
      update: (params) =>
        requestManager.put('/api/instructor/claims/' + params.id)
    },

    classPack: {
      create: (params) =>
        requestManager.post('/api/instructors/class_packs', params),
      delete: (params) =>
        requestManager.delete('/api/instructors/class_packs/' + params.classPack.id),
      fetch: () =>
        requestManager.get('/api/instructors/class_packs'),
      get: (params) =>
        requestManager.get('/api/instructors/class_packs/' + params.id),
      update: (params) =>
        requestManager.put('/api/instructors/class_packs/' + params.classPack.id, params)
    },

    client: {
      create: (params) =>
        requestManager.post('/api/instructors/clients', params),
      delete: (params) =>
        requestManager.delete('/api/instructors/clients/' + params.client.id),
      fetch: () =>
        requestManager.get('/api/instructors/clients'),
      get: (params) =>
        requestManager.get('/api/instructors/clients/' + params.id),

      transactions: {
        fetch: (params) =>
          requestManager.get('/api/instructors/client/' + params.id + '/transactions')
      },

      update: (params) =>
        requestManager.put('/api/instructors/clients/' + params.client.id, params)
    },

    comments: {
      create: (params) =>
        requestManager.post('/api/instructors/' + params.username + '/comments', params),
      fetch: (params) =>
        requestManager.get('/api/instructors/' + params.username + '/comments')
    },

    domain: {
      create: (params) =>
        requestManager.post('/api/instructors/domain', params),
      destroy: () =>
        requestManager.delete('/api/instructors/domain'),
      get: () =>
        requestManager.get('/api/instructors/domain')
    },

    donationBookings: {
      fetch: (params) =>
        requestManager.get('/api/instructors/' + params.username + '/donation_bookings')
    },

    donationClasses: {
      fetch: () =>
        requestManager.get('/api/instructors/donation_classes')
    },

    donationTargets: {
      create: (params) =>
        requestManager.post('/api/instructors/donation_targets', params),
      fetch: (params) =>
        requestManager.get('/api/instructors/' + params.username + '/donation_targets'),
      update: (params) =>
        requestManager.put('/api/instructors/donation_targets/' + params.donationTarget.id, params)
    },

    follow: {
      create: (params) =>
        requestManager.post('/api/instructors/' + params.username + '/follow'),
      destroy: (params) =>
        requestManager.delete('/api/instructors/' + params.username + '/follow'),
      fetch: () =>
        requestManager.get('/api/instructors/follows')
    },

    get: (params) =>
      requestManager.get('/api/instructors/' + params.username),

    groupClasses: {
      bookings: {
        fetch: () =>
          requestManager.get('/api/instructors/group_classes/bookings')
      },

      fetch: () =>
        requestManager.get('/api/instructors/group_classes'),
      get: (params) =>
        requestManager.get('/api/instructors/' + params.username + '/group_classes')
    },

    instagram: {
      get: (params) =>
        requestManager.get('/api/instructors/' + params.username + '/instagram')
    },

    membership: {
      create: (params) =>
        requestManager.post('/api/instructors/memberships', params),
      delete: (params) =>
        requestManager.delete('/api/instructors/memberships/' + params.membership.id),
      fetch: (params) =>
        requestManager.get('/api/instructors/' + params.username + '/memberships'),
      get: (params) =>
        requestManager.get('/api/instructors/memberships/' + params.id),
      update: (params) =>
        requestManager.put('/api/instructors/memberships/' + params.membership.id, params)
    },

    milestone: {
      fetch: () =>
        requestManager.get('/api/instructors/milestones')
    },

    playlist: {
      get: (params) =>
        requestManager.get('/api/instructors/' + params.username + '/playlist')
    },

    privateGroupClasses: {
      bookings: {
        create: (params) =>
          requestManager.post('/api/instructors/private_group_classes/bookings/', params),
        destroy: (params) =>
          requestManager.delete('/api/instructors/private_group_classes/bookings/' + params.id),
        fetch: () =>
          requestManager.get('/api/instructors/private_group_classes/bookings')
      },

      fetch: () =>
        requestManager.get('/api/instructors/private_group_classes'),

      get: (params) =>
        requestManager.get('/api/instructors/' + params.username + '/private_group_classes', params)
    },

    privateSessions: {
      create: (params) =>
        requestManager.post('/api/instructors/private_sessions', params),
      delete: (params) =>
        requestManager.delete('/api/instructors/private_sessions/' + params.privateSession.id, { note: params.note }),
      fetch: (params) =>
        requestManager.get('/api/instructors/' + params.username + '/private_sessions'),
      update: (params) =>
        requestManager.put('/api/instructors/private_sessions/' + params.privateSession.id, params)
    },

    profileData: {
      get: () =>
        requestManager.get('/api/instructors/data')
    },

    purchasedClass: {
      fetch: (params) =>
        requestManager.get('/api/users/on_demands', params)
    },

    requestedClass: {
      create: (params) =>
        requestManager.post('/api/instructors/on_demands', params),
      delete: (params) =>
        requestManager.delete('/api/instructors/on_demands/' + params.id),
      fetch: (params) =>
        requestManager.get('/api/' + params.username + '/on_demands'),

      formats: {
        create: (params) =>
          requestManager.post('api/instructors/on_demands/formats', params)
      },

      get: (params) =>
        requestManager.get('/api/instructors/on_demands/' + params.id),
      update: (params) =>
        requestManager.put('/api/instructors/on_demands/' + params.id, params)
    },

    strategy: {
      get: () =>
        requestManager.get('api/instructors/strategy'),
      update: (params) =>
        requestManager.put('api/instructors/strategy', params)
    },

    subscription: {
      destroy: (params) =>
        requestManager.delete('/api/instructors/subscriptions/' + params.subscription.id)
    },

    timeBlocks: {
      create: (params) =>
        requestManager.post('/api/instructors/time_blocks', params),
      destroy: (params) =>
        requestManager.delete('/api/instructors/time_blocks/' + params.id),
      fetch: (params) =>
        requestManager.get('/api/instructors/' + params.username + '/time_blocks'),
      get: (params) =>
        requestManager.get('/api/instructors/time_blocks/' + params.id),
      update: (params) =>
        requestManager.put('/api/instructors/time_blocks/' + params.id, params)
    },

    userClassPack: {
      create: (params) =>
        requestManager.post('/api/instructors/user_class_packs', params)
    }
  },

  invites: {
    create: (params) =>
      requestManager.post('/api/invites', params)
  },

  item: {
    create: (params) =>
      requestManager.post('/api/items', params)
  },

  location: {
    autocompleter: {
      fetch: (params) =>
        requestManager.get('/api/locations/' + params.locationId + '/autocomplete', params)
    },

    create: (params) =>
      requestManager.post('/api/locations', params),

    delete: (params) =>
      requestManager.delete('/api/locations/' + params.location.id),

    fetch: () =>
      requestManager.get('/api/locations'),

    follow: {
      create: (params) =>
        requestManager.post('/api/locations/' + params.id + '/follow'),
      destroy: (params) =>
        requestManager.delete('/api/locations/' + params.id + '/follow'),
      fetch: () =>
        requestManager.get('/api/locations/follows')
    },

    get: (params) =>
      requestManager.get('/api/locations/' + params.id),

    groupClasses: {
      fetch: () =>
        requestManager.get('/api/locations/group_classes'),
      get: (params) =>
        requestManager.get('/api/locations/' + params.id + '/group_classes')
    },

    reviews: {
      create: (params) =>
        requestManager.post('/api/locations/' + params.review.locationId + '/reviews', params),
      fetch: (params) =>
        requestManager.get('/api/locations/' + params.id + '/reviews')
    },

    update: (params) =>
      requestManager.put('/api/locations/' + params.location.id, params)
  },

  meeting: {
    create: (params) =>
      requestManager.post('/api/meetings', params),
    delete: (params) =>
      requestManager.delete('/api/meetings/' + params.id),
    update: (params) =>
      requestManager.put('/api/meetings/' + params.id)
  },

  membership: {
    coupon: {
      create: (params) =>
        requestManager.post('/api/memberships/coupons', params),
      delete: (params) =>
        requestManager.delete('/api/memberships/coupons/' + params.coupon.id),
      fetch: () =>
        requestManager.get('/api/memberships/coupons'),
      get: (params) =>
        requestManager.get('/api/memberships/coupons/' + params.id)
    },

    discount: {
      create: (params) =>
        requestManager.post('/api/memberships/discounts', params)
    },

    fetch: () =>
      requestManager.get('/api/memberships')
  },

  oauth: {
    destroy: (params) =>
      requestManager.delete('/auth/' + params, params)
  },

  partner: {
    fetch: () =>
      requestManager.get('/api/instructors/partners')
  },

  password: {
    get: (params) =>
      requestManager.get('/api/password', params),
    update: (params) =>
      requestManager.put('/api/password', params)
  },

  plan: {
    fetch: () =>
      requestManager.get('/api/plans')
  },

  playlist: {
    fetch: () =>
      requestManager.get('/api/playlists'),
    update: (params) =>
      requestManager.put('/api/playlist', params)
  },

  privateGroupClasses: {
    bookings: {
      create: (params) =>
        requestManager.post('/api/private_group_classes/bookings', params),
      delete: (params) =>
        requestManager.delete('/api/private_group_classes/bookings/' + params.booking.id,
                              { note: params.note }),
      fetch: () =>
        requestManager.get('/api/private_group_classes/bookings')
    },

    create: (params) =>
      requestManager.post('/api/private_group_classes', params),
    delete: (params) =>
      requestManager.delete('/api/private_group_classes/' + params.privateGroupClass.id, { note: params.note }),
    fetch: () =>
      requestManager.get('/api/private_group_classes'),

    formats: {
      create: (params) =>
        requestManager.post('/api/private_group_classes/formats', params)
    },

    recurring: {
      create: (params) =>
        requestManager.post('/api/private_group_classes/recurring', params),
      delete: (params) =>
        requestManager.delete('/api/private_group_classes/recurring/' + params.privateGroupClass.recurringId)
    },

    update: (params) =>
      requestManager.put('/api/private_group_classes/' + params.privateGroupClass.id, params)
  },

  privateSession: {
    create: (params) =>
      requestManager.post('/api/private_sessions', params),
    delete: (params) =>
      requestManager.delete('/api/private_sessions/' + params.privateSession.id, { note: params.note }),
    fetch: () =>
      requestManager.get('/api/private_sessions'),

    recurring: {
      create: (params) =>
        requestManager.post('/api/private_sessions/recurring', params),
      delete: (params) =>
        requestManager.delete('/api/private_sessions/recurring/' + params.privateSession.recurringId)
    },

    update: (params) =>
      requestManager.put('/api/private_sessions/' + params.privateSession.id, params)
  },

  privateSessionTypes: {
    create: (params) =>
      requestManager.post('/api/private_session_types', params),
    delete: (params) =>
      requestManager.delete('/api/private_session_types/' + params.privateSessionType.id),
    fetch: () =>
      requestManager.get('/api/private_session_types'),
    get: (params) =>
      requestManager.get('/api/private_session_types/' + params.id),
    update: (params) =>
      requestManager.put('/api/private_session_types/' + params.privateSessionType.id, params)
  },

  profile: {
    create: (params) =>
      requestManager.post('/api/profile', params),
    edit: () =>
      requestManager.get('/api/profile/edit'),
    get: () =>
      requestManager.get('/api/profile'),
    update: (params) =>
      requestManager.put('/api/profile', params)
  },

  provider: {
    fetch: () =>
      requestManager.get('/api/providers')
  },

  purchasedClass: {
    create: (params) =>
      requestManager.post('/api/users/on_demands', params)
  },

  recording: {
    create: (params) =>
      requestManager.post('/api/instructors/recordings', params),
    fetch: () =>
      requestManager.get('/api/instructors/recordings')
  },

  recurrences: {
    create: (params) =>
      requestManager.post('/api/recurrences', params),
    destroy: (params) =>
      requestManager.delete('/api/recurrences/' + params.recurrence.id, { privateGroupClassId: params.id }),
    update: (params) =>
      requestManager.put('/api/recurrences/' + params.recurrence.id, params)
  },

  requestedClass: {
    fetch: () =>
      requestManager.get('/api/instructors/on_demands')
  },

  samples: {
    challenges: {
      fetch: () =>
        requestManager.get('/api/samples/challenges')
    },

    classPacks: {
      fetch: () =>
        requestManager.get('/api/samples/class_packs')
    },

    memberships: {
      delete: (params) =>
        requestManager.delete('api/samples/memberships/' + params.membership.id),
      fetch: () =>
        requestManager.get('/api/samples/memberships')
    },

    onDemands: {
      fetch: () =>
        requestManager.get('/api/samples/on_demands')
    },

    privateGroupClasses: {
      fetch: () =>
        requestManager.get('/api/samples/private_group_classes')
    }
  },

  search: {
    create: (params) =>
      requestManager.post('/api/search', params)
  },

  session: {
    create: (params) =>
      requestManager.post('/api/session', params),
    destroy: (params) =>
      requestManager.delete('/api/session', params),
    get: () =>
      requestManager.get('/api/session')
  },

  spotify: {
    destroy: (params) =>
      requestManager.delete('/api/spotify', params),
    put: (params) =>
      requestManager.put('/api/spotify', params)
  },

  studio: {
    create: (params) =>
      requestManager.post('/api/studios', params),
    get: (params) =>
      requestManager.get('/api/studios/' + params.id, params)
  },

  styleTags: {
    fetch: () =>
      requestManager.get('/api/options/style_tags')
  },

  subscription: {
    create: (params) =>
      requestManager.post('/api/subscriptions', params),
    destroy: (params) =>
        requestManager.delete('/api/subscriptions/' + params.subscription.id),
    fetch: () =>
      requestManager.get('/api/subscriptions')
  },

  synchronization: {
    create: (params) =>
      requestManager.post('/api/profile/synchronizations', params),
    fetch: () =>
      requestManager.get('/api/profile/synchronizations')
  },

  transactions: {
    challenge: {
      fetch: () =>
        requestManager.get('/api/instructors/sales/challenges'),
      update: (params) =>
        requestManager.put('/api/instructors/sales/challenges', params)
    },
    classPack: {
      fetch: () =>
        requestManager.get('/api/instructors/sales/class_packs'),
      update: (params) =>
        requestManager.put('/api/instructors/sales/class_packs', params)
    },

    membership: {
      fetch: () =>
        requestManager.get('/api/instructors/sales/subscriptions'),
      update: (params) =>
        requestManager.put('/api/instructors/sales/subscriptions', params)
    },

    purchase: {
      fetch: () =>
        requestManager.get('/api/instructors/sales/purchases'),
      update: (params) =>
        requestManager.put('/api/instructors/sales/purchases', params)
    },

    summary: {
      fetch: () =>
        requestManager.get('/api/instructors/sales')
    }
  },

  user: {
    challenge: {
      create: (params) =>
        requestManager.post('/api/users/challenges', params),
      fetch: () =>
        requestManager.get('/api/users/challenges')
    },

    classPack: {
      create: (params) =>
        requestManager.post('/api/users/class_packs', params),
      delete: (params) =>
        requestManager.delete('/api/users/class_packs/' + params.classPack.id),
      fetch: () =>
        requestManager.get('/api/users/class_packs'),
      update: (params) =>
        requestManager.put('/api/users/class_packs/' + params.classPack.id, params)
    },

    create: (params) =>
      requestManager.post('/api/users', params),

    onDemands: {
      bulk: {
        create: (params) =>
          requestManager.post('/api/users/on_demands/bulk', params)
      }
    },

    plan: {
      create: (params) =>
        requestManager.post('/api/users/plans', params),
      get: () =>
        requestManager.get('api/users/plans')
    },

    purchasedClass: {
      fetch: () =>
        requestManager.get('api/users/on_demands'),
      get: (params) =>
        requestManager.get('/api/users/on_demands/' + params.uuid),
      update: (params) =>
        requestManager.put('/api/users/on_demands/' + params.uuid, params)
    },


    update: (params) =>
      requestManager.put('/api/users/' + params.user.id, params)
  },

  validation: {
    create: (params, meta) =>
      requestManager.post('/api/' + meta.subject + '/validations', params)
  }
};
