import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';

import { Button } from '../../../../forms';

class GroupClassItemUrban extends React.PureComponent {
  static propTypes = {
    currency:           PropTypes.string.isRequired,
    customization:      PropTypes.object.isRequired,
    groupClass:         PropTypes.object.isRequired,
    onHandleToggle:     PropTypes.func.isRequired,
    renderIcon:         PropTypes.func.isRequired,
    renderLocationInfo: PropTypes.func.isRequired,
    time:               PropTypes.string.isRequired
  };

  render() {
    const { currency, customization, groupClass, onHandleToggle, renderIcon, renderLocationInfo, time } = this.props;

    return (
      <Card className="card-event body-class__event">
        <div className="body-class__time">
          <div className="time">{time}</div>
          <div className="duration"> {groupClass.duration + ' min'}</div>
        </div>
        <div className="body-class__wrapper">
          <div className="body-class__content">
            <div className="body-class__main">
              <div className="body-class__name">{groupClass.name}</div>
              { !!groupClass.description.length &&
                <div className="body-class__description">{groupClass.description}</div>
              }
            </div>
            {renderLocationInfo(groupClass)}
            {groupClass.feeType && renderIcon(groupClass.feeType)}
          </div>
          <div className="body-class__buttons">
            {groupClass.fee && groupClass.fee.toLocaleString('en-US', { currency, style: 'currency' })}
            <Button
                className="mb-2"
                customization={customization}
                onClick={onHandleToggle(groupClass)}
                size="sm"
                style="secondary">
              Reserve
            </Button>
          </div>
        </div>
      </Card>
    );
  }
}

export default GroupClassItemUrban;
