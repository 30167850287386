import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../helpers';
import { PackagesPage } from '../components/pages';
import { ChallengesActions, ClassPacksActions, MembershipsActions, SamplesActions } from '../actions';

class PackagesContainer extends React.PureComponent {
  static propTypes = {
    fetchChallanges:        PropTypes.func.isRequired,
    fetchClassPack:         PropTypes.func.isRequired,
    fetchMemberships:       PropTypes.func.isRequired,
    fetchSampleChallenges:  PropTypes.func.isRequired,
    fetchSampleClassPacks:  PropTypes.func.isRequired,
    fetchSampleMemberships: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.fetchChallanges();
    this.props.fetchClassPack();
    this.props.fetchMemberships();
    this.props.fetchSampleChallenges();
    this.props.fetchSampleClassPacks();
    this.props.fetchSampleMemberships();
  }

  render() {
    return (
      <PackagesPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  challenges:        state.challenges.instructorData,
  classPacks:        state.classPacks.instructorData,
  memberships:       state.memberships.data,
  sampleChallenges:  state.samples.challenges,
  sampleClassPacks:  state.samples.classPacks,
  sampleMemberships: state.samples.memberships,
  user:              state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchChallanges:        ()     => dispatch(ChallengesActions.INSTRUCTORS_FETCH()),
  fetchClassPack:         ()     => dispatch(ClassPacksActions.INSTRUCTORS_FETCH()),
  fetchMemberships:       ()     => dispatch(MembershipsActions.FETCH()),
  fetchSampleChallenges:  ()     => dispatch(SamplesActions.FETCH_CHALLENGES()),
  fetchSampleClassPacks:  ()     => dispatch(SamplesActions.FETCH_CLASS_PACKS()),
  fetchSampleMemberships: ()     => dispatch(SamplesActions.FETCH_MEMBERSHIPS()),
  getChallenge:           (data) => dispatch(ChallengesActions.INSTRUCTORS_GET(data)),
  getClassPack:           (data) => dispatch(ClassPacksActions.INSTRUCTORS_GET(data)),
  getMembership:          (data) => dispatch(MembershipsActions.INSTRUCTORS_GET(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(PackagesContainer);
