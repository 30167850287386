import { SubscriptionsActionTypes } from '../constants/actionTypes';

export default {
  ACTIVATE: (payload) => ({
    payload, type: SubscriptionsActionTypes.ACTIVATE
  }),

  ACTIVATE_FAILURE: () => ({
    type: SubscriptionsActionTypes.ACTIVATE_FAILURE
  }),

  ACTIVATE_SUCCESS: (payload) => ({
    payload, type: SubscriptionsActionTypes.ACTIVATE_SUCCESS
  }),


  CLEAR: () => ({
   type: SubscriptionsActionTypes.CLEAR
  }),


  CREATE: (payload) => ({
    payload, type: SubscriptionsActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: SubscriptionsActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: SubscriptionsActionTypes.CREATE_SUCCESS
  }),


  DELETE: (payload) => ({
    payload, type: SubscriptionsActionTypes.DELETE
  }),

  DELETE_FAILURE: () => ({
    type: SubscriptionsActionTypes.DELETE_FAILURE
  }),

  DELETE_SUCCESS: (payload) => ({
    payload, type: SubscriptionsActionTypes.DELETE_SUCCESS
  }),


  FETCH: () => ({
    type: SubscriptionsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: SubscriptionsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: SubscriptionsActionTypes.FETCH_SUCCESS
  }),


  INSTRUCTOR_DELETE: (payload) => ({
    payload, type: SubscriptionsActionTypes.INSTRUCTOR_DELETE
  }),

  INSTRUCTOR_DELETE_FAILURE: () => ({
    type: SubscriptionsActionTypes.INSTRUCTOR_DELETE_FAILURE
  }),

  INSTRUCTOR_DELETE_SUCCESS: (payload) => ({
    payload, type: SubscriptionsActionTypes.INSTRUCTOR_DELETE_SUCCESS
  })
};
