import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

class ColorSwitch extends React.PureComponent {
  static propTypes = {
    colors:      PropTypes.array.isRequired,
    colorsCount: PropTypes.number,
    hasSelector: PropTypes.bool,
    id:          PropTypes.string,
    name:        PropTypes.string,
    onChange:    PropTypes.func,
    value:       PropTypes.string
  }

  static defaultProps = {
    colorsCount: 10,
    hasSelector: true,
    id:          null,
    name:        '',
    onChange:    () => {},
    value:       null
  }

  static getDerivedStateFromProps(props) {
    return {
      selectedColor: props.value
    };
  }
  
  state = {
    selectedColor: ''
  }

  handleChange = (e) => {
    this.props.onChange(e.target.value, this.props.name);
  }
  
  render () {
    const { selectedColor } = this.state;
    const { colorsCount, hasSelector } = this.props;

    return (
      <div className='custom-color-switch'>
        { hasSelector &&
          <input
              className='form-color-select'
              id={this.props.id}
              onChange={this.handleChange}
              type='color'
              value={this.props.value} />
        }
        <div className='colors-list'>
          { this.props.colors.slice(0, colorsCount).map((color) => (
            <button
                className={classNames('btn-color', { 'active': selectedColor === color })}
                key={color}
                onClick={this.handleChange}
                style={{backgroundColor: color, borderColor: color}}
                value={color} />
            ))
          }
        </div>
      </div>
    );
  }
}

export default ColorSwitch;