import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { Link } from 'react-router-dom';

import { routes } from '../../lib';
import { MembershipIconWhite } from '../../images';
import { UserAvatar } from '../users';
import { ListCollapse } from '../lists';
import { dashboardSection } from '../../constants';
import { UserSubscriptionModal } from '../modals';

class MyMemberships extends React.PureComponent {
  static propTypes = {
    changeSection:      PropTypes.func.isRequired,
    deleteSubscription: PropTypes.func.isRequired,
    section:            PropTypes.string.isRequired,
    subscriptions:      PropTypes.array.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      subscriptions: props.subscriptions
    };
  }

  state = {
    isModalOpen:   false,
    subscription:  {},
    subscriptions: []
  }

  handleToggle = (subscription = {}) => () => (
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      subscription
    }))
  );

  renderSubscriptions = () => (
    this.state.subscriptions.map((subscription) => {
      const fullName = subscription.membership.instructor.firstName + ' ' + subscription.membership.instructor.lastName;
      const { currency } = subscription.membership.instructor;
      const membership =  subscription.membership;

      return (
        <div
            className='membership-content'
            key={subscription.id}>
          <div className='info'>
            <UserAvatar
                className='avatar-border-white ml-2 mr-2'
                size='sm'
                src={membership.instructor.photo} />
            <div className='membership-info'>
              <span className='name'>{fullName}</span>
              <span className='price'>
                {membership.cost.toLocaleString('en-GB', { currency, style: 'currency' })} per {membership.period}
              </span>
              <span
                  className='details'
                  onClick={this.handleToggle(subscription)}
                  onKeyPress={this.handleToggle(subscription)}
                  role='button'
                  tabIndex={0}>
                Click to view details
              </span>
            </div>
          </div>

          <span className='bookClass mr-2'>
            <Link
                to={routes.INSTRUCTOR(membership.instructor.username)}>
              Book a class
            </Link>
          </span>
        </div>
      );
    })
  );

  renderTitle = (length) => (
    <React.Fragment>
      <span>Memberships</span>
      <small>{length} {pluralize('memberships', length)}</small>
    </React.Fragment>
  )

  render() {
    const { subscriptions } = this.state;

    const IconCal = <img
        alt="Icon"
        src={MembershipIconWhite} />;

    return (
      <React.Fragment>
        <ListCollapse
            color='success'
            icon={IconCal}
            isOpen={this.props.section == dashboardSection.MEMBERSHIPS}
            onClick={() => this.props.changeSection(dashboardSection.MEMBERSHIPS)}
            title={this.renderTitle(subscriptions.length)}>
          {this.renderSubscriptions()}
        </ListCollapse>
        {this.state.isModalOpen &&
          <UserSubscriptionModal
              deleteSubscription={this.props.deleteSubscription}
              isOpen={this.state.isModalOpen}
              onToggle={this.handleToggle()}
              subscription={this.state.subscription} />
        }
      </React.Fragment>
    );
  }
}

export default MyMemberships;
