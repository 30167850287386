import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresAuth } from '../helpers';
import { EditAccountPage } from '../components/pages';
import { OAuthActions, UserActions } from '../actions';

const mapStateToProps = (state) => ({
  user: state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser:   ()     => dispatch(UserActions.FETCH()),
  oAuthLogout: (data) => dispatch(OAuthActions.LOGOUT(data)),
  updateUser:  (data) => dispatch(UserActions.UPDATE(data))
});

export default compose(
  requiresAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(EditAccountPage);
