import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import { InstructorName, Photo } from '../../../../components/instructor';

class TemplatePageHeader extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired,
    subtitle:   PropTypes.string
  }

  static defaultProps = {
    subtitle: ''
  }

  static getDerivedStateFromProps(props) {
    const { customization } = props.instructor;

    return { siteTemplate: customization && customization.siteTemplate };
  }

  state = {
    siteTemplate: ''
  }

  render() {
    const { instructor, subtitle } = this.props;
    const { siteTemplate } = this.state;

    const desktopPhoto = instructor.photo ? instructor.photo.lg : null;
    const mobilePhoto = instructor.mobilePhoto ? instructor.mobilePhoto.photo : null;
    const photo = isMobile && mobilePhoto ? mobilePhoto : desktopPhoto;

    return (
      <div className={`template-${siteTemplate}-page-header`}>
        <Photo src={photo} />
        <div className={`template-${siteTemplate}-page-header-content`}>
          <InstructorName
              instructor={instructor}
              subtitle={subtitle} />
        </div>
      </div>
    );
  }
}

export default TemplatePageHeader;
