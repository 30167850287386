import React from 'react';
import { connect } from 'react-redux';

import { SearchMap } from '../components/maps';

class SearchMapContainer extends React.PureComponent {
  render() {
    return (
      <SearchMap
          {...this.props}
          className='map-full shadow-left'
          id='categorySideMap' />
    );
  }
}

const mapStateToProps = (state) => ({
  instructors: state.instructors,
  locations:   state.studios
});

export default connect(mapStateToProps)(SearchMapContainer);
