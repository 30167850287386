import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { routes } from '../../lib';
import { restoreSession } from '../../helpers';
import Layout from '../layout';

import {
  AddProfileContainer,
  AuthenticationContainer,
  AvailabilityContainer,
  CalendarContainer,
  CancellationContainer,
  CloseContainer,
  ContactContainer,
  CouponsContainer,
  DashboardContainer,
  EditAccountContainer,
  EditProfileContainer,
  FavoriteOnDemandsContainer,
  HomeContainer,
  InstructorDashboardContainer,
  InstructorScheduleContainer,
  LocationContainer,
  LocationScheduleContainer,
  LocationsContainer,
  MarketingContainer,
  NotFoundContainer,
  PackagesContainer,
  PaymentsContainer,
  PrivacyContainer,
  PrivateSessionsContainer,
  ProfileContainer,
  PurchasesContainer,
  RecoverPasswordContainer,
  RedirectContainer,
  ResetPasswordContainer,
  SearchContainer,
  SessionTypesContainer,
  SettingsAboutContainer,
  SettingsBillingContainer,
  SettingsContainer,
  SettingsCustomizationContainer,
  SettingsDomainContainer,
  SettingsHomeClassesContainer,
  SettingsHomeContainer,
  SettingsHomeImageWithTextContainer,
  SettingsHomeInstagramContainer,
  SettingsHomeLandingContainer,
  SettingsHomeTestimonialsContainer,
  SettingsMembershipContainer,
  SettingsNotificationsContainer,
  SettingsStyleContainer,
  SettingsWorkoutContainer,
  StoryCreatorContainer,
  SubscriptionContainer,
  TermsContainer,
  VideoPlayerContainer,
  WidgetsContainer,
  WinBackSequenceContainer
} from '../../containers';
import { SupportPage } from '../pages';

import { Clients, InstructorPage, OnDemand, Transactions } from '../../views';

class Router extends React.PureComponent {
  render() {
    return (
      <Switch>
        <Redirect
            from="/:url*(/+)"
            to={location.pathname.slice(0, -1)} />

        <Route
            exact
            path={routes.HOME} >
          <Layout
              content={<HomeContainer />}
              hasFooter
              hasSidebar={false} />
        </Route>

        <Route
            exact
            path={routes.LOGIN}>
          <Layout
              content={<AuthenticationContainer />}
              hasHeader={false}
              hasSidebar={false} />
        </Route>

        <Route
            exact
            path={routes.SIGNUP}>
          <Layout
              content={<AuthenticationContainer />}
              hasHeader={false}
              hasSidebar={false} />
        </Route>

        <Route
            exact
            path={routes.RECOVER_PASSWORD}>
          <Layout
              content={<RecoverPasswordContainer />}
              hasHeader={false}
              hasSidebar={false} />
        </Route>

        <Route
            exact
            path={routes.RESET_PASSWORD}>
          <Layout
              content={<ResetPasswordContainer />}
              hasHeader={false}
              hasSidebar={false} />
        </Route>

        <Route
            exact
            path={routes.ADD_PROFILE}>
          <Layout
              content={<AddProfileContainer />}
              hasSidebar={false} />
        </Route>

        <Route
            component={SearchContainer}
            exact
            path={routes.SEARCH} />

        <Route
            component={ProfileContainer}
            exact
            path={routes.PROFILE} />

        <Route
            component={LocationContainer}
            exact
            path={routes.LOCATION(':locationId')} />

        <Route
            exact
            path={routes.COUPONS}>
          <Layout content={<CouponsContainer />} />
        </Route>

        <Route
            component={CloseContainer}
            exact
            path={routes.CLOSE} />

        <Route
            exact
            path={routes.EDIT_ACCOUNT}>
          <Layout content={<EditAccountContainer />} />
        </Route>

        <Route
            exact
            path={routes.EDIT_PROFILE}>
          <Layout content={<EditProfileContainer />} />
        </Route>

        <Route
            exact
            path={routes.INSTRUCTOR_CLASS_PLANNER}>
          <Layout content={<InstructorScheduleContainer />} />
        </Route>

        <Route
            component={LocationScheduleContainer}
            exact
            path={routes.LOCATION_CLASS_PLANNER} />

        <Route
            exact
            path={routes.DASHBOARD}>
          <Layout content={<DashboardContainer />} />
        </Route>

        <Route
            exact
            path={routes.CALENDAR}>
          <Layout content={<CalendarContainer />} />
        </Route>

        <Route
            exact
            path={routes.PRIVACY}>
          <Layout
              content={<PrivacyContainer />}
              hasFooter
              hasSidebar={false} />
        </Route>

        <Route
            component={TermsContainer}
            exact
            path={routes.TERMS}>
          <Layout
              content={<TermsContainer />}
              hasFooter
              hasSidebar={false} />
        </Route>

        <Route
            exact
            path={routes.CONTACT}>
          <Layout
              content={<ContactContainer />}
              hasFooter
              hasSidebar={false} />
        </Route>

        <Route
            component={RedirectContainer}
            exact
            path={routes.REDIRECT} />

        <Route
            component={StoryCreatorContainer}
            exact
            path={routes.STORY_CREATE}>
          <Layout
              content={<StoryCreatorContainer />}
              hasSidebar={false} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS}>
          <Layout content={<SettingsContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_HOME}>
          <Layout content={<SettingsHomeContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_HOME_LANDING}>
          <Layout content={<SettingsHomeLandingContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_HOME_CLASSES}>
          <Layout content={<SettingsHomeClassesContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_HOME_IMAGE_TEXT}>
          <Layout content={<SettingsHomeImageWithTextContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_HOME_TESTIMONIALS}>
          <Layout content={<SettingsHomeTestimonialsContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_HOME_TESTIMONIALS}>
          <Layout content={<SettingsHomeInstagramContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_HOME_INSTAGRAM}>
          <Layout content={<SettingsHomeContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_ABOUT}>
          <Layout content={<SettingsAboutContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_WORKOUT}>
          <Layout content={<SettingsWorkoutContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_PACKAGES}>
          <Layout content={<SettingsMembershipContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_NOTIFICATIONS}>
          <Layout content={<SettingsNotificationsContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_STYLE}>
          <Layout content={<SettingsStyleContainer />} />
        </Route>

        <Route
            exact
            path={routes.AVAILABILITY}>
          <Layout content={<AvailabilityContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_CUSTOMIZATION}>
          <Layout content={<SettingsCustomizationContainer />} />
        </Route>

        <Route
            exact
            path={routes.SESSION_TYPES}>
          <Layout content={<SessionTypesContainer />} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_DOMAIN}>
          <Layout content={<SettingsDomainContainer />} />
        </Route>

        <Route
            exact
            path={routes.LOCATIONS}>
          <Layout content={<LocationsContainer />} />
        </Route>

        <Route
            component={Clients}
            path={routes.CLIENTS}>
        </Route>

        <Route
            component={OnDemand}
            path={routes.ON_DEMAND} />

        <Route
            path={routes.INSTRUCTOR_DASHBOARD}>
          <Layout content={<InstructorDashboardContainer />} />
        </Route>

        <Route
            exact
            path={routes.INTEGRATIONS}>
          <Layout content={<PaymentsContainer />} />
        </Route>

        <Route
            exact
            path={routes.TRANSACTIONS}>
          <Layout content={<Transactions />} />
        </Route>

        <Route
            exact
            path={routes.MARKETING}>
          <Layout content={<MarketingContainer />} />
        </Route>

        <Route
            exact
            path={routes.PACKAGES}>
          <Layout content={<PackagesContainer />} />
        </Route>

        <Route
            exact
            path={routes.VIDEO_PLAYER(':uuid')}>
          <Layout
              content={<VideoPlayerContainer />}
              hasSidebar={false} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_WIDGETS}>
          <Layout
              content={<WidgetsContainer />}
              hasSidebar />
        </Route>

        <Route
            exact
            path={routes.SUPPORT}>
          <Layout
              content={<SupportPage />}
              hasSidebar />
        </Route>

        <Route
            exact
            path={routes.PURCHASES}>
          <Layout
              content={<PurchasesContainer />}
              hasSidebar />
        </Route>

        <Route
            exact
            path={routes.FAVORITE_ON_DEMANDS}>
          <Layout
              content={<FavoriteOnDemandsContainer />}
              hasSidebar />
        </Route>

        <Route
            exact
            path={routes.PLANS_AND_BILLING}>
          <Layout
              content={<SettingsBillingContainer />}
              hasSidebar />
        </Route>

        <Route
            exact
            path={routes.PRIVATE_SESSIONS}>
          <Layout
              content={<PrivateSessionsContainer />}
              hasSidebar />
        </Route>

        <Route
            exact
            path={routes.SUBSCRIBE}>
          <Layout
              content={<SubscriptionContainer />}
              hasHeader={false}
              hasSidebar={false} />
        </Route>

        <Route
            exact
            path={routes.SETTINGS_CANCELLATION}>
          <Layout content={<CancellationContainer />} />
        </Route>

        <Route
            exact
            path={routes.WIN_BACK}>
          <Layout content={<WinBackSequenceContainer />} />
        </Route>


        {/* IMPORTANT: these routes should always be at bottom because of routes priority */}

        <Route
            component={InstructorPage}
            path={routes.INSTRUCTOR(':username')} />

        <Route path='*'>
          <NotFoundContainer />
        </Route>
      </Switch>
    );
  }
}

export default restoreSession(Router);
