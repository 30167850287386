/* eslint-disable max-len, react/prop-types */
import React from 'react';

const Free = (props) => (
  <svg
      fill="none"
      height="21"
      viewBox="0 0 28 21"
      width="28"
      xmlns="http://www.w3.org/2000/svg">
    <path
        clipRule="evenodd"
        d="M26.25 1.61538H1.75C1.71555 1.61538 1.68357 1.62775 1.65566 1.65566C1.62775 1.68357 1.61538 1.71555 1.61538 1.75V19.25C1.61538 19.3228 1.67685 19.3846 1.75 19.3846H26.25C26.3238 19.3846 26.3846 19.3238 26.3846 19.25V1.75C26.3846 1.67685 26.3228 1.61538 26.25 1.61538ZM1.75 0H26.25C27.216 0 28 0.78575 28 1.75V19.25C28 20.216 27.216 21 26.25 21H1.75C0.78575 21 0 20.216 0 19.25V1.75C0 0.78575 0.78575 0 1.75 0ZM6.125 7.875C6.608 7.875 7 7.483 7 7C7 6.517 6.608 6.125 6.125 6.125H4.375C3.892 6.125 3.5 6.517 3.5 7V14C3.5 14.483 3.892 14.875 4.375 14.875C4.858 14.875 5.25 14.483 5.25 14V11.375H6.125C6.608 11.375 7 10.983 7 10.5C7 10.017 6.608 9.625 6.125 9.625H5.25V7.875H6.125ZM19.25 7C19.25 7.483 18.858 7.875 18.375 7.875H17.5V9.625H18.375C18.858 9.625 19.25 10.017 19.25 10.5C19.25 10.983 18.858 11.375 18.375 11.375H17.5V13.125H18.375C18.858 13.125 19.25 13.517 19.25 14C19.25 14.483 18.858 14.875 18.375 14.875H16.625C16.142 14.875 15.75 14.483 15.75 14V7C15.75 6.517 16.142 6.125 16.625 6.125H18.375C18.858 6.125 19.25 6.517 19.25 7ZM23.625 11.375C24.108 11.375 24.5 10.983 24.5 10.5C24.5 10.017 24.108 9.625 23.625 9.625H22.75V7.875H23.625C24.108 7.875 24.5 7.483 24.5 7C24.5 6.517 24.108 6.125 23.625 6.125H21.875C21.392 6.125 21 6.517 21 7V14C21 14.483 21.392 14.875 21.875 14.875H23.625C24.108 14.875 24.5 14.483 24.5 14C24.5 13.517 24.108 13.125 23.625 13.125H22.75V11.375H23.625ZM11.375 6.125C12.8223 6.125 14 7.30275 14 8.75C14 9.926 13.2178 10.9113 12.152 11.2438L13.8233 13.475C14.1138 13.8618 14.035 14.4095 13.6482 14.7C13.4908 14.819 13.307 14.875 13.125 14.875C12.859 14.875 12.5965 14.7543 12.425 14.525L10.5 11.9578V14C10.5 14.483 10.108 14.875 9.625 14.875C9.142 14.875 8.75 14.483 8.75 14V7C8.75 6.517 9.142 6.125 9.625 6.125H11.375ZM10.5 9.625H11.375C11.858 9.625 12.25 9.233 12.25 8.75C12.25 8.267 11.858 7.875 11.375 7.875H10.5V9.625Z"
        fill={props.fill || '#BCDCFF'}
        fillRule="evenodd"
    />
  </svg>
);

export default Free;
