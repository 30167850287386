import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Form, FormText, Label, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { BackButton } from '../../buttons';

import { instructorRoutes, routes } from '../../../lib';
import { Button, Checkbox } from '../../forms';
import { Heading, Panel } from '../../layout/pages';
import { MembershipReasonInput } from '../../forms/profile';

class SettingsMembershipPage extends React.PureComponent {
  static propTypes = {
    instructor:    PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const { instructor } = props;

    if (instructor.id && !state.isMounted) {
      return {
        instructor,
        isMounted:          true,
        membershipsEnabled: instructor.membershipsEnabled
      };
    }

    return state;
  }

  state = {
    instructor: {},
    isMounted:  false
  };

  handleModal = (modal) => (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ [modal]: !prevState[modal] }));
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      instructor: {
        ...prevState.instructor,
        [inputName]: value
      }
    }));
  }

  handleUpdateProfile = () => {
    const { instructor } =  this.state;

    this.props.updateProfile(instructor);
  }

  render() {
    const { instructor } = this.state;

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Workout</title>
        </Helmet>
        <div className='inner-wrapper instructor-settings'>
          <Container
              className='settings-page'
              fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Packages' />
            <Panel className='settings-menu'>
              <Media className='menu-link d-block'>
                <Media body>
                  <div className='custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='memberships'
                        isChecked={instructor.membershipsEnabled}
                        name='membershipsEnabled'
                        onChange={this.handleChange} />
                    <Label
                        className='custom-control-label d-flex'
                        htmlFor='input-memberships'>
                      Show the&nbsp;
                      <Link to={routes.INSTRUCTOR(instructor.username) + instructorRoutes.PACKAGES.path}>
                        <div className='text-primary'>Packages</div>
                      </Link>
                      &nbsp;page on your site
                    </Label>
                  </div>
                </Media>
              </Media>

              <h4 className='menu-title'>Packages Description</h4>

              <Media className='menu-link d-block'>
                <Form className='form-default'>
                  <FormText>
                    Let members know why they should join...
                  </FormText>
                  <fieldset>
                    <MembershipReasonInput
                        index={0}
                        onChange={this.handleChange}
                        placeholder='Consistency is key. Join my member...'
                        profile={instructor} />

                    <MembershipReasonInput
                        index={1}
                        onChange={this.handleChange}
                        placeholder='This is an amazing community...'
                        profile={instructor} />

                    <MembershipReasonInput
                        index={2}
                        onChange={this.handleChange}
                        placeholder='I&apos;m excited for you to begin this journey...'
                        profile={instructor} />
                  </fieldset>
                </Form>

                <Button
                    className='save-changes-btn'
                    color='success'
                    isBlock={isMobile}
                    onClick={this.handleUpdateProfile}
                    onKeyPress={this.handleUpdateProfile}>
                  Save Changes
                </Button>
              </Media>

              <h4 className='menu-title'>Packages</h4>

              <Media className={classNames('menu-link', { 'is-disabled': !instructor.membershipsEnabled })}>
                <Media left>
                  <Icon
                      fixedWidth
                      icon='credit-card' />
                </Media>
                <Media body>
                  Set Your Packages
                </Media>
                <Media right>
                  <Link to={routes.PACKAGES}>
                    <Button
                        isDisabled={!instructor.membershipsEnabled}
                        isOutline
                        size='sm'>
                      Edit
                    </Button>
                  </Link>
                </Media>
              </Media>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SettingsMembershipPage;
