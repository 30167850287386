/* eslint-disable react/jsx-handler-names */
import React from 'react';
import PropTypes from 'prop-types';
import { FormText, Input as InputBootstrap } from 'reactstrap';

import { INPUT_TYPES } from '../../constants';

const MAX_CHARACTERS = 524288;

class Input extends React.PureComponent {
  static propTypes = {
    autoComplete: PropTypes.string,
    className:    PropTypes.string,
    defaultValue: PropTypes.string,
    id:           PropTypes.string,
    isDisabled:   PropTypes.bool,
    isInvalid:    PropTypes.bool,
    max:          PropTypes.number,
    maxLength:    PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    min:          PropTypes.number,
    name:         PropTypes.string,
    onBlur:       PropTypes.func,
    onChange:     PropTypes.func,
    onFocus:      PropTypes.func,
    onKeyDown:    PropTypes.func,
    onRef:        PropTypes.func,
    placeholder:  PropTypes.string,
    rows:         PropTypes.string,
    size:         PropTypes.string,
    style:        PropTypes.object,
    type:         PropTypes.oneOf(INPUT_TYPES),
    value:        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }

  static defaultProps = {
    autoComplete: '',
    className:    '',
    defaultValue: '',
    id:           null,
    isDisabled:   false,
    isInvalid:    false,
    label:        '',
    max:          0,
    maxLength:    false,
    min:          0,
    name:         '',
    onBlur:       () => {},
    onChange:     () => {},
    onFocus:      () => {},
    onKeyDown:    () => {},
    onRef:        () => {},
    placeholder:  '',
    rows:         '4',
    size:         'md',
    style:        {},
    type:         'text',
    value:        ''
  }

  static getDerivedStateFromProps(props) {
    return {
      value: props.value
    };
  }

  state = {
    value: ''
  }

  componentDidMount() {
    this.setState({ value: this.props.defaultValue });
  }


  handleChange = (e) => {
    e.preventDefault();
    e.persist();

    const { maxLength } = this.props;

    if (this.props.type !== 'file' && maxLength && String(e.target.value).length > maxLength) return;

    if (this.props.type === 'file') {
      this.props.onChange(e, this.props.name);
    } else {
      this.props.onChange(e.target.value, this.props.name);
    }
    this.setState({ value: e.target.value });
  }

  renderCharactersLeft = () => {
    const charsLeft = this.props.maxLength - this.state.value.length;

    return (
      <FormText
          className='input-length'
          color={ charsLeft < 4 ? 'danger' : 'muted' }>
        {charsLeft}/{this.props.maxLength}
      </FormText>
    );
  };

  render() {
    const { max, min, rows, type, autoComplete, maxLength } = this.props;

    const id = 'input-' + this.props.id;

    return (
      <React.Fragment>
        <InputBootstrap
            bsSize={this.props.size}
            className={this.props.className}
            disabled={this.props.isDisabled}
            id={id}
            {...autoComplete && { autoComplete }}
            innerRef={this.props.onRef}
            invalid={this.props.isInvalid}
            {...type === 'number' && { max }}
            {...maxLength && { maxLength: maxLength }}
            {...type === 'number' && { min }}
            name={this.props.name}
            onBlur={this.props.onBlur}
            onChange={this.handleChange}
            onFocus={this.props.onFocus}
            onKeyDown={this.props.onKeyDown}
            placeholder={this.props.placeholder}
            {...type === 'textarea' && { rows }}
            style={this.props.style}
            type={type}
            value={this.state.value} />
        {maxLength && maxLength !== MAX_CHARACTERS && this.renderCharactersLeft()}
      </React.Fragment>
    );
  }
}

export default Input;
