import React from 'react';
import PropTypes from 'prop-types';
import { ActionCableConsumer } from 'react-actioncable-provider';

class Cable extends React.PureComponent {
  static propTypes = {
    channel: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string
    ]),

    onReceived: PropTypes.func
  }

  static defaultProps = {
    channel:    '',
    onReceived: () => {}
  }

  render() {
    return (
      <ActionCableConsumer
          channel={this.props.channel}
          onReceived={this.props.onReceived} />
    );
  }
}

export default Cable;
