import { ErrorActionTypes } from '../constants/actionTypes';

const initialState = {
  api: {
    instagram: {},
    spotify:   {}
  },
  lastAction: '',
  message:    '',
  status:     ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case ErrorActionTypes.NEW:
      return { ...newState, message: action.payload.message, status: action.payload.status };

    case ErrorActionTypes.NEW_API:
      return { ...newState, api: { ...newState.api, [action.payload.name]: action.payload.error }};

    case ErrorActionTypes.CLEAR:
      return { ...newState, message: '', status: '' };

    case ErrorActionTypes.CLEAR_API:
      return { ...newState, api: { ...newState.api, [action.payload]: {} }};

    default: return state;
  }
};
