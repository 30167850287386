import { LoaderActionTypes } from '../constants/actionTypes';

const initialState = {
  challenges:          { isLoading: false },
  classPacks:          { isLoading: false },
  clients:             { isLoading: false },
  comments:            { isLoading: false },
  coupons:             { isLoading: false },
  emailSettings:       { isLoading: false },
  emailTemplates:      { isLoading: false },
  groupClasses:        { isLoading: true },
  instagram:           { isLoading: true },
  isLoading:           false,
  locations:           { isLoading: false },
  memberships:         { isLoading: false },
  membershipsCoupons:  { isLoading: false },
  milestones:          { isLoading: false },
  playlist:            { isLoading: true },
  playlists:           { isLoading: false },
  privateGroupClasses: { isLoading: true },
  profile:             { isLoading: true },
  purchasedClass:      { isLoading: false },
  recording:           { isLoading: false },
  recordings:          { isLoading: false },
  requestedClasses:    { isLoading: false },
  reviews:             { isLoading: false },
  sessionTypes:        { isLoading: false },
  signUp:              { isLoading: false },
  strategy:            { isLoading: false },
  summary:             { isLoading: false }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LoaderActionTypes.LOADING_STARTED:
      return {
        ...state,
        isLoading: true
      };

    case LoaderActionTypes.LOADING_FINISHED:
      return {
        ...state,
        isLoading: false
      };

    case LoaderActionTypes.LOADING_FIELD_STARTED:
      return {
        ...state,
        [action.payload]: { isLoading: true }
      };

    case LoaderActionTypes.LOADING_FIELD_FINISHED:
      return {
        ...state,
        [action.payload]: { isLoading: false }
      };

    default:
      return state;
  }
};
