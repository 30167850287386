/* eslint-disable sort-keys-fix/sort-keys-fix */

import { Moment } from './';

class ExportTransactions {
  getPurchases = (data) => (
    data.map((obj) => ({
      id:          obj.id,
      type:        obj.purchaseType,
      bookingDate: new Moment(obj.createdAt, 'YYYY-MM-DDhh:mm:ssUTC').format('MMM DD, YYYY  hh:mm A'),
      clientName:  obj.clientName,
      clientEmail: obj.client && obj.client.email,
      name:        obj.bookable.name,
      classDate:   new Moment(obj.bookable.startsAt, 'YYYY-MM-DDhh:mm:ssUTC').format('MMM DD, YYYY  hh:mm A'),
      feeType:     obj.bookable.feeType,
      coupon:      obj.charge && obj.charge.discount && obj.charge.discount.code,
      amount:      obj.charge && obj.charge.amount,
      stripe:      obj.stripeUrl
    }))
  )

  getMemberships = (data) => (
    data.map((obj) => ({
      id:          obj.id,
      joined:      new Moment(obj.createdAt, 'YYYY-MM-DDhh:mm:ssUTC').format('MMM DD, YYYY  hh:mm A'),
      client:      obj.user.fullName,
      package:     obj.name,
      amount:      obj.membership && obj.membership.amount,
      clientEmail: obj.user && obj.user.email,
      status:      obj.status,
      nextRenewal: new Moment(obj.endsAt, 'YYYY-MM-DDhh:mm:ssUTC').format('MMM DD, YYYY  hh:mm A'),
      note:        obj.note,
      stripe:      obj.stripeUrl
    }))
  )

  getClassPacks = (data) => (
    data.map((obj) => ({
      id:            obj.id,
      purchaseDate:  new Moment(obj.createdAt, 'YYYY-MM-DDhh:mm:ssUTC').format('MMM DD, YYYY  hh:mm A'),
      client:        obj.user && obj.user.fullName,
      package:       obj.name,
      clientEmail:   obj.user.email,
      coupon:        obj.charge && obj.charge.discount && obj.charge.discount.code,
      amount:        obj.charge.amount,
      unusedCredits: obj.credits,
      note:          obj.note,
      stripe:        obj.stripeUrl,
      expiryDate:    obj.expiryDate
    }))
  )

  getChallenges = (data) => (
    data.map((obj) => ({
      id:          obj.id,
      client:      obj.user && obj.user.fullName,
      package:     obj.name,
      clientEmail: obj.user.email,
      coupon:      obj.charge && obj.charge.discount && obj.charge.discount.code,
      amount:      obj.charge.amount,
      endsAt:      obj.endsAt,
      note:        obj.note,
      stripe:      obj.stripeUrl
    }))
  )
}

export default new ExportTransactions();
