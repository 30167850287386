import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Calendr, Moment } from '../../../lib';

class MonthDaySelect extends React.PureComponent {
  static propTypes = {
    isOpen:       PropTypes.bool.isRequired,
    onChange:     PropTypes.func.isRequired,
    onToggle:     PropTypes.func.isRequired,
    selectedDate: PropTypes.object.isRequired,
    today:        PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props) {
    return {
      dayLabels: Moment.weekdaysMin(),
      monthDays: Calendr.getMonthArray(props.selectedDate)
    };
  }

  state = {
    dayLabels: [],
    monthDays: []
  };

  handleSelect = (day) => () => {
    this.props.onChange(new Moment(day), 'selectedDate');
    this.props.onToggle();
  };

  renderDay = (day, i) => {
    const { today, selectedDate } = this.props;

    const className = classNames({
      'today': Calendr.isToday(day, today)
    }, {
      'selected': Moment(day).isSame(selectedDate, 'day')
    }, {
      'disabled': !Calendr.currentMonth(day, selectedDate)
    });

    return (
      <div
          className='day'
          key={i}
          onClick={this.handleSelect(day)}
          onKeyPress={this.handleSelect(day)}
          role='button'
          tabIndex={0}>
        <span className={className}>
          {day.format('D')}
        </span>
      </div>
    );
  };

  render() {
    const { dayLabels, monthDays } = this.state;

    return (
      <div className={classNames('day-select', { 'open': this.props.isOpen })}>
        <div className='labels'>
          {dayLabels.map((day, i) => (
            <div
                className='day'
                key={i}>
              <span>{day}</span>
            </div>
          ))}
        </div>
        <div className='days'>
          {monthDays.map((day, i) => (
            this.renderDay(day, i)
          ))}
        </div>
      </div>
    );
  }
}

export default MonthDaySelect;
