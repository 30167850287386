import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { ZoomLogo } from '../../../../images';
import { routes } from '../../../../lib';
import { OAuthWindow } from '../../../oauth';
import { Button } from '../../index';

class ZoomConnect extends React.PureComponent {
  static propTypes = {
    isConnected: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    onLogout:    PropTypes.func,
    onSuccess:   PropTypes.func
  }

  static defaultProps = {
    isConnected: false,
    onLogout:    () => {},
    onSuccess:   () => {}
  }

  handleLogout = () => {
    this.props.onLogout('zoom');
  }

  render () {
    const { isConnected } = this.props;

    return (
      <div className='form-third-party'>
        <div className='service-block'>
          <div className='service'>
            <img
                alt='Instagram'
                className='mr-2'
                src={ZoomLogo} />
            <span className='service-name'>Zoom Video Conferencing</span>
          </div>

          <div className='action'>
            { !isConnected &&
              <React.Fragment>
                <div className='small d-flex text-muted mb-1 mt-1'>
                  Connect to Zoom in order to create meetings in 1-click within the onPodio platform
                </div>
                <OAuthWindow
                    onSuccess={this.props.onSuccess}
                    url={routes.ZOOM_AUTH}>
                  <Button
                      className='save-changes-btn'
                      color='primary'>
                        Connect
                  </Button>
                </OAuthWindow>
              </React.Fragment>
            }

            { isConnected &&
              <React.Fragment>
                <div className='small d-flex text-muted font-italic mb-1 mt-1'>
                  Connected (click here to&nbsp;
                  <Link onClick={this.handleLogout}>
                    <div className='text-secondary'>disconnect</div>
                  </Link>
                  )
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ZoomConnect;
