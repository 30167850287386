/* eslint-disable immutable/no-let */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';
import { filter, map } from 'lodash';

import { api, extractError, routes, toCamelCase } from '../lib';
import { ClassPacksActionTypes } from '../constants/actionTypes';
import { ClassPacksActions, ErrorActions, LoaderActions } from '../actions';


/* regular sagas */
function* watchFetch() {
  yield takeLatest(ClassPacksActionTypes.FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('classPacks'));
      const response = yield call(api.classPack.fetch, payload);

      yield put(ClassPacksActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ClassPacksActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(ClassPacksActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('classPacks'));
  });
}

function* watchFetchFailure() {
  yield takeLatest(ClassPacksActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING('classPacks'));
  });
}

function* watchSubtract() {
  yield takeLatest(ClassPacksActionTypes.SUBTRACT, function* ({ payload }) {
    const classPacks = yield select((state) => state.classPacks.userData);
    const data = map(classPacks, (classPack) => (
      classPack.id === payload.id
          ? { ...classPack, [payload.creditType]: classPack[payload.creditType] - 1 }
          : classPack
    ));

    yield put(ClassPacksActions.SUBTRACT_SUCCESS(data));
  });
}


/* instructors sagas */
function* watchInstructorsCreate() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.instructor.classPack.create, payload);

      yield put(ClassPacksActions.INSTRUCTORS_CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Class Pack added');
    } catch (error) {
      yield put(ClassPacksActions.INSTRUCTORS_CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsCreateSuccess() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_CREATE_SUCCESS, function* () {
    yield put(push(routes.SETTINGS_PACKAGESS));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsCreateFailure() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchInstructorsDelete() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.instructor.classPack.delete, payload);

      const classPacks = yield select((state) => state.classPacks.instructorData);
      const data = filter(classPacks, (classPack) => (classPack.id !== payload.classPack.id));

      yield put(ClassPacksActions.INSTRUCTORS_DELETE_SUCCESS(data));
      toastr.success('Class Pack canceled');
    } catch (error) {
      yield put(ClassPacksActions.INSTRUCTORS_DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsDeleteSuccess() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsDeleteFailure() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchInstructorsFetch() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('classPacks'));
      const response = yield call(api.instructor.classPack.fetch, payload);

      yield put(ClassPacksActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ClassPacksActions.INSTRUCTORS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsFetchSuccess() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('classPacks'));
  });
}

function* watchInstructorsFetchFailure() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('classPacks'));
  });
}


function* watchInstructorsGet() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('classPacks'));
      const response = yield call(api.instructor.classPack.get, payload);

      yield put(ClassPacksActions.INSTRUCTORS_GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ClassPacksActions.INSTRUCTORS_GET_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsGetSuccess() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('classPacks'));
  });
}

function* watchInstructorsGetFailure() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('classPacks'));
  });
}


function* watchInstructorsUpdate() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.classPack.update, payload);
      const classPacks = yield select((state) => state.classPacks.instructorData);
      const data = map(classPacks, (classPack) => (
        classPack.id === payload.classPack.id ? toCamelCase(response.data) : classPack
      ));

      yield put(ClassPacksActions.INSTRUCTORS_UPDATE_SUCCESS(data));
      toastr.success('Class Pack updated');
    } catch (error) {
      yield put(ClassPacksActions.INSTRUCTORS_UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsUpdateSuccess() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_UPDATE_SUCCESS, function* () {
    yield put(push(routes.SETTINGS_PACKAGESS));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsUpdateFailure() {
  yield takeLatest(ClassPacksActionTypes.INSTRUCTORS_UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('classPacks'));
  });
}


/* users saga */
function* watchUsersCreate() {
  yield takeLatest(ClassPacksActionTypes.USERS_CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.user.classPack.create, payload);
      const data = toCamelCase(response.data);

      yield put(ClassPacksActions.USERS_CREATE_SUCCESS(data));
      toastr.success('Class Pack purchased');
    } catch (error) {
      yield put(ClassPacksActions.USERS_CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUsersDelete() {
  yield takeLatest(ClassPacksActionTypes.USERS_DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.user.classPack.delete, payload);

      const classPacks = yield select((state) => state.classPacks.userData);
      const data = filter(classPacks, (classPack) => (classPack.id !== payload.classPack.id));

      yield put(ClassPacksActions.USERS_DELETE_SUCCESS(data));
      toastr.success('Class Pack canceled');
    } catch (error) {
      yield put(ClassPacksActions.USERS_DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUsersDeleteSuccess() {
  yield takeLatest(ClassPacksActionTypes.USERS_DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUsersDeleteFailure() {
  yield takeLatest(ClassPacksActionTypes.USERS_DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchUsersFetch() {
  yield takeLatest(ClassPacksActionTypes.USERS_FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.user.classPack.fetch, payload);

      yield put(ClassPacksActions.USERS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ClassPacksActions.USERS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUsersFetchSuccess() {
  yield takeLatest(ClassPacksActionTypes.USERS_FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUsersFetchFailure() {
  yield takeLatest(ClassPacksActionTypes.USERS_FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUserUpdate() {
  yield takeLatest(ClassPacksActionTypes.USERS_UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.user.classPack.update, payload);

      yield put(ClassPacksActions.USERS_UPDATE_SUCCESS());
      toastr.success('Class Pack updated');
    } catch (error) {
      yield put(ClassPacksActions.USERS_UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUserUpdateSuccess() {
  yield takeLatest(ClassPacksActionTypes.USERS_UPDATE_SUCCESS, function* () {
    yield put(push(routes.COUPON));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUserUpdateFailure() {
  yield takeLatest(ClassPacksActionTypes.USERS_UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


export default function* classPacksSaga() {
  yield all([
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchSubtract(),
    watchInstructorsDelete(),
    watchInstructorsDeleteSuccess(),
    watchInstructorsDeleteFailure(),
    watchInstructorsCreate(),
    watchInstructorsCreateSuccess(),
    watchInstructorsCreateFailure(),
    watchInstructorsFetch(),
    watchInstructorsFetchSuccess(),
    watchInstructorsFetchFailure(),
    watchInstructorsGet(),
    watchInstructorsGetSuccess(),
    watchInstructorsGetFailure(),
    watchInstructorsUpdate(),
    watchInstructorsUpdateSuccess(),
    watchInstructorsUpdateFailure(),
    watchUsersCreate(),
    watchUsersDelete(),
    watchUsersDeleteSuccess(),
    watchUsersDeleteFailure(),
    watchUsersFetch(),
    watchUsersFetchSuccess(),
    watchUsersFetchFailure(),
    watchUserUpdate(),
    watchUserUpdateSuccess(),
    watchUserUpdateFailure()
  ]);
}
