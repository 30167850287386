import { CustomizationActionTypes } from '../constants/actionTypes';

export default {
  GET_CUSTOMIZATION: () => ({
    type: CustomizationActionTypes.GET_CUSTOMIZATION
  }),

  GET_CUSTOMIZATION_FAILURE: () => ({
    type: CustomizationActionTypes.GET_CUSTOMIZATION_FAILURE
  }),

  GET_CUSTOMIZATION_SUCCESS: (payload) => ({
    payload, type: CustomizationActionTypes.GET_CUSTOMIZATION_SUCCESS
  }),

  UPDATE_CUSTOMIZATION: (payload) => ({
    payload, type: CustomizationActionTypes.UPDATE_CUSTOMIZATION
  }),

  UPDATE_CUSTOMIZATION_FAILURE: () => ({
    type: CustomizationActionTypes.UPDATE_CUSTOMIZATION_FAILURE
  }),

  UPDATE_CUSTOMIZATION_SUCCESS: (payload) => ({
    payload, type: CustomizationActionTypes.UPDATE_CUSTOMIZATION_SUCCESS
  })
};
