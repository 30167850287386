import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

import { Button, Checkbox, Form, MaskedInput, Select } from '../';
import { Input } from '../../inputs';
import { currencyMask, dayMask } from '../../../constants/masks';
import { FEES, MEMBERSHIP_PERIODS, currencies } from '../../../constants';
import { Scroller, Validator, routes } from '../../../lib';
import { InstructorCancelMembership, InstructorHideMembership } from '../../alerts';


class MembershipForm extends React.PureComponent {
  static propTypes = {
    cancelMembership: PropTypes.func.isRequired,
    data:             PropTypes.object,
    deleteSample:     PropTypes.func.isRequired,
    isLoading:        PropTypes.bool,
    isNewRecord:      PropTypes.bool,
    onSubmit:         PropTypes.func,
    onToggle:         PropTypes.func.isRequired,
    user:             PropTypes.object.isRequired
  }

  static defaultProps = {
    data: {
      cost:        '',
      description: '',
      name:        '',
      period:      'week',
      premium:     {
        live:     true,
        onDemand: true
      },
      trial:     false,
      trialDays: 0
    },
    isLoading:   true,
    isNewRecord: true,
    onSubmit:    () => {}
  };

  static getDerivedStateFromProps(props, state) {
    const { isNewRecord, isLoading } = props;

    if (!isEmpty(state.membership)) return state;

    if (!isLoading) {
      const membership = isNewRecord
        ? MembershipForm.defaultProps.data
        : {
          ...props.data,
          premium: {
            live:     props.data.premium.live     == 'true',
            onDemand: props.data.premium.onDemand == 'true'
          }
        };


      return {
        isNewRecord,
        membership,
        visible: props.data.visible
      };
    }

    return null;
  }

  state = {
    errors:     {},
    membership: {}
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      membership: {
        ...prevState.membership,
        [inputName]: value
      }
    }));
  };

  handleToggle = (value, inputName) => {
    this.setState((prevState) => ({
      membership: {
        ...prevState.membership,
        premium: {
          ...prevState.membership.premium,
          [inputName]: value
        }
      }
    }));
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { membership } = this.state;

    const errors = Validator.validate([
      ['name',   Validator.concepts.isFilled, [membership.name]],
      ['cost',   Validator.concepts.isFilled, [membership.cost]],
      ['period', Validator.concepts.isFilled, [membership.period]],
      ['description', Validator.concepts.isFilled, [membership.description]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  })

  handleSubmit = () => {
    const { membership } = this.state;
    const { isNewRecord, user } = this.props;
    const sign = currencies[user.currency].symbol;
    const data = isNewRecord
                ? { ...membership, cost: parseFloat(membership.cost.toString().replace(sign, '')) }
                : membership;

    return this.handleValidate().then(() => {
      this.props.onSubmit(data);
      return this.props.onToggle();
    });
  }

  handleCancel = () => {
    const deleteOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <InstructorCancelMembership
            cancelMembership={this.props.cancelMembership}
            deleteSample={this.props.deleteSample}
            membership={this.state.membership}
            onClose={onClose}
            onSuccess={this.props.onToggle} />
        )
      };

    confirmAlert(deleteOptions);
  }

  handleHide = () => {
    const deleteOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <InstructorHideMembership
            isVisible={this.state.visible}
            membership={this.state.membership}
            onClose={onClose}
            onHideMembership={this.handleSubmit}
            onSuccess={this.props.onToggle} />
        )
      };
    this.setState((prevState) => ({
      membership: {...prevState.membership, visible: !prevState.membership.visible}
    }), () => {
      confirmAlert(deleteOptions);
    });
  }

  render() {
    const { isNewRecord, user } = this.props;
    const { visible, membership, errors } = this.state;
    const { currency } = user;

    const descPlaceholder = '3x HIIT Classes per week\n' +
                            '2x Yoga Classes per week\n' +
                            'All virtual on Zoom\n';

    const sign = currencies[currency].symbol;
    const amount = membership.cost && parseFloat(membership.cost.toString().replace(sign, '')) || 0;
    const fee = FEES.membership(amount);

    return (
      <Form className='form-default'>
        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Membership Name
          </Label>
          <Input
              id='name'
              isInvalid={!!errors.name}
              name='name'
              onChange={this.handleChange}
              placeholder='e.g. KJ Collective'
              type='text'
              value={membership.name} />
          <FormFeedback tooltip>
            {errors.name}
          </FormFeedback>
        </FormGroup>

        <Row form>
          <Col>
            <FormGroup>
              <Label
                  className='required'
                  for='input-cost'>
                Cost
              </Label>
              <MaskedInput
                  id='cost'
                  inputMode='number'
                  isDisabled={!isNewRecord}
                  isInvalid={!!errors.cost}
                  mask={currencyMask(sign)}
                  name='cost'
                  onChange={this.handleChange}
                  placeholder={sign + 10}
                  type='number'
                  value={membership.cost} />
              <FormFeedback tooltip>
                {errors.cost}
              </FormFeedback>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label for='input-period'>Per</Label>
              <Select
                  className='select-view'
                  id='period'
                  isDisabled={!isNewRecord}
                  isInvalid={!!errors.period}
                  isOptionDisabled={(option) => option.disabled}
                  name='period'
                  onChange={this.handleChange}
                  options={MEMBERSHIP_PERIODS}
                  type='text'
                  value={membership.period} />
            </FormGroup>
          </Col>
        </Row>

        <small className='text-small text-muted'>
          Client pays {parseFloat(amount + fee, 10).toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
          (incl. {parseFloat(fee, 10).toLocaleString('en-GB', { currency, style: 'currency' })} fee)
        </small>

        <Row>
          <Col>
            <FormGroup>
              <Label for='input-can-expire'>
                Offer a Free Trial
              </Label>

              <div className='d-flex'>
                <div className='custom-control custom-checkbox checkbox-lg'>
                  <Checkbox
                      className='custom-control-input'
                      id='trial'
                      isChecked={membership.trial}
                      name='trial'
                      onChange={this.handleChange} />
                  <Label
                      className='custom-control-label'
                      htmlFor='input-trial' />
                </div>

                <MaskedInput
                    className='ml-2 w-50'
                    id='trialDays'
                    inputMode='number'
                    isDisabled={!membership.trial}
                    isInvalid={!!errors.trial}
                    mask={dayMask}
                    name='trialDays'
                    onChange={this.handleChange}
                    placeholder='days'
                    type='number'
                    value={String(membership.trialDays)} />
              </div>
            </FormGroup>
          </Col>
        </Row>

        <div className='text-small text-muted'>
          Offering a free trial will mean clients will not be
          charged for X days after commencing the membership
        </div>

        <FormGroup>
          <Label
              className='required'
              for='input-description'>
            Membership Description
          </Label>
          <Input
              id='description'
              isInvalid={!!errors.description}
              name='description'
              onChange={this.handleChange}
              placeholder={descPlaceholder}
              rows='4'
              type='textarea'
              value={membership.description} />
          <FormFeedback tooltip>
            {errors.description}
          </FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label>
            Membership gives access to
          </Label>

          <div className='custom-control custom-checkbox checkbox-md'>
            <Checkbox
                className='custom-control-input'
                id='premium-live'
                isChecked={membership.premium && membership.premium.live}
                name='live'
                onChange={this.handleToggle} />
            <Label
                className='custom-control-label'
                htmlFor='input-premium-live'>
              Live Classes
            </Label>
          </div>
        </FormGroup>

        <FormGroup>
          <div className='custom-control custom-checkbox checkbox-md'>
            <Checkbox
                className='custom-control-input'
                id='premium-on-demand'
                isChecked={membership.premium && membership.premium.onDemand}
                name='onDemand'
                onChange={this.handleToggle} />
            <Label
                className='custom-control-label'
                htmlFor='input-premium-on-demand'>
              On Demand Classes
            </Label>
          </div>
        </FormGroup>

        <div className='text-small text-muted pb-3'>
          Check the box to allow this membership to be used for live and / or on-demand class.
        </div>

        <div className='text-small text-muted'>
          <strong>
            If checked, this membership will by default be able to access all of those classes.&nbsp;
          </strong>
          To limit access from an individual live class or on-demand collection you can do
          this on the class or collection settings via&nbsp;
          <Link to={routes.CALENDAR}>
            My Class Schedule
          </Link>
          &nbsp;and&nbsp;
          <Link to={routes.ON_DEMAND}>
            On-Demand
          </Link>
          &nbsp;pages respectively.
        </div>

        { !user.hasStripe &&
          <div className='text-warning text-sm'>
            Note: Your Stripe account is not connected, in order to create a Membership connect
            your Stripe account by going to Instructor Settings &gt;&nbsp;
            <Link to={routes.INTEGRATIONS}>
              Integrations
            </Link>
          </div>
        }

        { isNewRecord && user.hasStripe && !user.stripeUpgraded &&
          <div className='text-warning text-sm'>
            Note to use the Membership feature you will need to reauthorize your Stripe account.
            Please contact us at&nbsp;
            <a href='mailto:kal@onpodio.com'>kal@onpodio.com</a>&nbsp;
            to assist you with this.
          </div>
        }

        <Row className={isNewRecord ? 'mt-4' : 'mt-2'}>
          { isNewRecord &&
            <Col>
              <Button
                  isBlock
                  isDisabled={!user.hasStripe || (user.hasStripe && !user.stripeUpgraded)}
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Create & Add
              </Button>
            </Col>
          }

          { !isNewRecord &&
            <React.Fragment>
              <Button
                  isBlock
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Update Membership
              </Button>
              <div className='mt-3'>
                <p className='text-small text-muted'>
                  Hide a membership to remove the package from your website. This will
                  prevent new clients purchasing the pack <strong>BUT WILL NOT</strong> cancel
                  the membership for current members.
                </p>
                <Button
                    color='muted'
                    isBlock
                    isOutline
                    onClick={this.handleHide}
                    size='lg'
                    type='button'>
                  { visible ? 'Hide' : 'Show' }&nbsp;
                  Membership
                </Button>
              </div>

              <div className='w-100 mt-3'>
                <p className='text-small text-danger'>
                  Cancel a membership to remove the package from your website. This will
                  prevent new clients purchasing the pack <strong>AND WILL</strong> stop
                  all future charges for current members.                  
                </p>
                <Button
                    color='danger'
                    isBlock
                    isOutline
                    onClick={this.handleCancel}
                    size='lg'
                    type='button'>
                  Cancel Membership
                </Button>
              </div>
            </React.Fragment>
          }
        </Row>
      </Form>
    );
  }
}

export default MembershipForm;
