import { connect } from 'react-redux';

import { CollectionActions, FormatsActions, PlansActions, PrivateGroupClassesActions,
         RecordingsActions } from '../../actions';

import OnDemandsPage from './onDemandsPage';

const mapStateToProps = (state) => ({
  collection:          state.collections.collection,
  collections:         state.collections.data,
  folders:             state.collections.folders,
  formats:             state.formats.data,
  instructor:          state.profile.data,
  isClassesLoading:    state.collections.onDemands.isLoading,
  isCollectionLoading: state.collections.isLoading,
  isLoading:           state.loader.recordings.isLoading,
  onDemands:           state.collections.onDemands,
  plan:                state.plans.userData,
  recording:           state.recordings.form,
  recordings:          state.recordings.data,
  storageLimit:        state.plans.userData.storageLimit,
  subscription:        state.plans.userData,
  usedStorage:         state.profile.data.storageUsed,
  user:                state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createCollection:         (data) => dispatch(CollectionActions.COLLECTION_CREATE(data)),
  createFormats:            (data) => dispatch(FormatsActions.CREATE_ON_DEMAND_FORMAT(data)),
  createRecording:          (data) => dispatch(RecordingsActions.CREATE(data)),
  deleteCollection:         (data) => dispatch(CollectionActions.COLLECTION_DELETE(data)),
  fetchCollections:         ()     => dispatch(CollectionActions.COLLECTION_FETCH()),
  fetchFolders:             (data) => dispatch(CollectionActions.FOLDER_FETCH(data)),
  fetchFormats:             ()     => dispatch(FormatsActions.FETCH()),
  fetchOnDemands:           (data) => dispatch(CollectionActions.COLLECTION_ON_DEMANDS_FETCH(data)),
  fetchPrivateGroupClasses: ()     => dispatch(PrivateGroupClassesActions.FETCH()),
  fetchRecordings:          ()     => dispatch(RecordingsActions.FETCH()),
  getCollection:            (data) => dispatch(CollectionActions.COLLECTION_GET(data)),
  getSubscription:          ()     => dispatch(PlansActions.SUBSCRIPTION_GET()),
  updateCollection:         (data) => dispatch(CollectionActions.COLLECTION_UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(OnDemandsPage);
