import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';

import { Button } from '../../forms';
import { Input } from '../../inputs';
import { Validator } from '../../../lib';

class JoinNow extends React.PureComponent {
  static propTypes = {
    id:      PropTypes.string.isRequired,
    joinNow: PropTypes.func.isRequired,
    name:    PropTypes.string.isRequired
  };

  state = {
    email: ''
  }

  handleChange = (value) => {
    this.setState({ email: value });
  }

  handleJoin = () => {
    this.props.joinNow(this.state.email);
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const errors = Validator.validate([
      ['email', Validator.concepts.emailRegex, [this.state.email]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  render() {
    return (
      <div className='input-group-wrapper'>
        <InputGroup
            className='input-group-home'
            size={isMobile ? '' : 'lg'}>
          <Input
              id={this.props.id}
              name={this.props.name}
              onChange={this.handleChange}
              placeholder='fitpro@thebest.com'
              type='email'
              value={this.state.email} />
          <InputGroupAddon addonType='append'>
            <Button
                color='primary'
                id={this.props.id + '-btn'}
                onClick={this.handleJoin}>
              Join now
            </Button>
          </InputGroupAddon>
        </InputGroup>
      </div>
    );
  }
}

export default JoinNow;
