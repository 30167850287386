import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import ReactCrop from 'react-image-crop';
import { isMobile } from 'react-device-detect';
import replaceExt from 'replace-ext';
import isBase64 from 'is-base64';

import { Button } from '../forms';

class Cropper extends PureComponent {
  static propTypes = {
    aspectRatio: PropTypes.number,
    isOpen:      PropTypes.bool.isRequired,
    onSave:      PropTypes.func,
    onToggle:    PropTypes.func.isRequired,
    src:         PropTypes.instanceOf(File)
  }

  static defaultProps = {
    aspectRatio: 16 / 9,
    onSave:      () => {},
    src:         null
  }

  static getDerivedStateFromProps(props, state) {

    return {
      crop: {
        ...state.crop,
        aspect: props.aspectRatio
      }
    };
  }

  imageRef = React.createRef();

  state = {
    base64: '',
    crop:   {
      aspect: 16 / 9,
      unit:   '%',
      width:  100
    }
  }

  handleImageLoaded = (image) => {
    this.imageRef = image;
  }

  handleChange = (crop) => {
    this.setState({ crop });
  }

  handleComplete = (crop) => {
    this.makeCrop(crop);
  }

  async makeCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      await this.getCroppedImg(this.imageRef, crop);
    }
  }

  getCroppedImg(image, crop) {
    const newImage = this.imageRef;
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(crop.width*scaleX);
    canvas.height = Math.ceil(crop.height*scaleY);
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      newImage,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width*scaleX,
      crop.height*scaleY,
    );

    const base64 = canvas.toDataURL('image/jpeg', 0.8);

    if (isBase64(base64, {mimeRequired: true})) {
      this.setState({ base64 });
    }
  }

  handleSave = () => {
    fetch(this.state.base64)
      .then((res) => {
        return res.arrayBuffer();
      })
      .then((buf) => {
        const filename = replaceExt(this.props.src.name, '.jpeg');
        const file = new File([buf], filename,{ type: 'image/jpeg' });

        return this.props.onSave(file);
      })
      .catch((error) => {
        return console.error(error);
      });
  }


  render () {
    const { crop } = this.state;
    const { isOpen, onToggle, src } = this.props;

    const imageUrl = src && URL.createObjectURL(src);

    return (
      <Modal
          centered
          isOpen={isOpen}
          size='lg'
          toggle={onToggle}>
        <ModalHeader toggle={onToggle}>
          Crop a photo
        </ModalHeader>

        <ModalBody>
          <ReactCrop
              crop={crop}
              onChange={this.handleChange}
              onComplete={this.handleComplete}
              onImageLoaded={this.handleImageLoaded}
              ruleOfThirds
              src={imageUrl} />
          <Button
              className='save-changes-btn mt-4'
              color='success'
              isBlock={isMobile}
              onClick={this.handleSave}>
            Save
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}

export default Cropper;
