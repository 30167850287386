import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty, keys, map } from 'lodash';
import { isMobile } from 'react-device-detect';
import { CropModal, MobileCropModal } from '../../../modals';
import { currencies } from '../../../../constants';


import { Scroller, Validator } from '../../../../lib';
import { Button, Form, Select } from '../../';
import { DesktopFileUploader, Input, MobileFileUploader, SelectActivities, Username } from '../../../inputs';

const selectOptions = map(keys(currencies), (currency) => ({ label: currency, value: currency }));

class BasicForm extends React.PureComponent {
  static propTypes = {
    activities:       PropTypes.array.isRequired,
    onChange:         PropTypes.func.isRequired,
    onSubmit:         PropTypes.func.isRequired,
    profile:          PropTypes.object.isRequired,
    validateUsername: PropTypes.func.isRequired,
    validation:       PropTypes.object.isRequired
  }

  state = {
    errors:    {},
    photoName: '',
    src:       ''
  }

  handleChange = (value, inputName) => {
    if (inputName === 'username' && value.length >= 3) {
      this.props.validateUsername({ instructor: { username: value }});
    }

    this.props.onChange(value, inputName);
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { profile } = this.props;

    const errors = Validator.validate([
      ['username',   Validator.concepts.isReserved,    [profile.username]],
      ['username',   Validator.concepts.usernameRegex, [profile.username]],
      ['username',   Validator.concepts.isFilled,      [profile.username]],
      ['username',   Validator.concepts.isLongerThan,  [3, profile.username, 'Username']],
      ['firstName',  Validator.concepts.isFilled,      [profile.firstName]],
      ['lastName',   Validator.concepts.isFilled,      [profile.lastName]],
      ['tagline',    Validator.concepts.isFilled,      [profile.tagline]],
      ['activities', Validator.concepts.isFilled,      [profile.activities]],
      ['currency',   Validator.concepts.isFilled,      [profile.currency]],
      ['photo',      Validator.concepts.isUploaded,    [profile.photo]]
    ]);

    Validator.clear();
    if (isEmpty(errors)) {
      resolve();
    } else {
      this.setState({ errors }, () => reject(errors));
    }
  })

  handleSubmit = () => {
    return this.handleValidate().then(() => {
      const { validation } = this.props;

      if (validation.instructor && validation.instructor.valid) {
        this.props.onSubmit();
      } else {
        Scroller._scroll('smooth');
      }
      return;
    });
  }

  handleModal = () => {
    this.setState((prevState) => ({ cropModalOpen: !prevState.cropModalOpen }));
  }

  handleMobileModal = () => {
    this.setState((prevState) => ({ mobileCropModalOpen: !prevState.mobileCropModalOpen }));
  }

  handleSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ photoName: e.target.files[0].name });
      this.handleModal();
    }
  };

  handleMobileSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
      this.setState({ photoName: e.target.files[0].name });
      this.handleMobileModal();
    }
  };

  render () {
    const { profile, activities } = this.props;
    const { errors } = this.state;

    return (
      <Form className='form-default'>
        <FormGroup className='mb-5'>
          <Username
              error={errors.username}
              isInvalid={!!errors.username}
              onChange={this.handleChange}
              validation={this.props.validation}
              value={profile.username} />
        </FormGroup>

        <Row
            className='align-items-stretch'
            form>
          <Col sm={6}>
            <FormGroup>
              <Label
                  className='required'
                  htmlFor='firstName'>
                First name
              </Label>
              <Input
                  id='firstName'
                  isInvalid={!!errors.firstName}
                  name='firstName'
                  onChange={this.handleChange}
                  type='text'
                  value={profile.firstName} />
              <FormFeedback tooltip>{errors.firstName}</FormFeedback>
            </FormGroup>
          </Col>

          <Col sm={6}>
            <FormGroup>
              <Label
                  className='required'
                  htmlFor='lastName'>
                Last name
              </Label>
              <Input
                  id='lastName'
                  isInvalid={!!this.state.errors.lastName}
                  name='lastName'
                  onChange={this.handleChange}
                  type='text'
                  value={profile.lastName} />
              <FormFeedback tooltip>{errors.lastName}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>

        <Row
            className='align-items-stretch mb-2'
            form>
          <Col sm={6}>
            <Label
                className='required'
                htmlFor='firstName'>
              Add your profile picture
            </Label>
            <DesktopFileUploader
                error={errors.photo}
                fileTypes={['image/png', 'image/jpeg']}
                isInvalid={!!errors.photo}
                maxFileSize='5MB'
                name='photo'
                onChange={this.props.onChange}
                onSelectFile={this.handleSelectFile}
                profile={this.props.profile}
                size='lg' />
          </Col>

          <Col sm={6}>
            <MobileFileUploader
                error={errors.mobilePhoto}
                fileTypes={['image/png', 'image/jpeg']}
                isInvalid={!!errors.photo}
                maxFileSize='5MB'
                name='mobile_photo'
                onChange={this.props.onChange}
                onSelectFile={this.handleMobileSelectFile}
                photoName={this.state.photoName}
                profile={this.props.profile}
                size='lg' />
          </Col>
        </Row>

        <FormGroup>
          <Label
              className='required'
              htmlFor='tagline'>
            Add your fitness tag line
          </Label>
          <Input
              id='tagline'
              isInvalid={!!errors.tagline}
              name='tagline'
              onChange={this.handleChange}
              type='text'
              value={profile.tagline} />
          <FormFeedback tooltip>{errors.tagline}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label className='required'>
            Select your class types
          </Label>
          <SelectActivities
              activities={activities}
              isInvalid={!!errors.activities}
              onChange={this.handleChange}
              profile={profile} />
          <FormFeedback tooltip>{errors.activities}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label className='required'>
            Currency
          </Label>

          <Select
              id='currency'
              isInvalid={!!this.state.errors.currency}
              name='currency'
              onChange={this.handleChange}
              options={selectOptions} 
              placeholder='Select currency' />

          { !!errors.currency &&
            <FormFeedback
                className='upload-tooltip'
                tooltip>
              {errors.currency}
            </FormFeedback>
          }
        </FormGroup>

        <div className='actions justify-content-end'>
          <Button
              className='mt-5'
              isBlock={isMobile}
              onClick={this.handleSubmit}
              onKeyPress={this.handleSubmit}
              size='lg'
              type='button'>
            Create
          </Button>
        </div>

        {this.state.cropModalOpen && this.state.src &&
          <CropModal
              isMobileCrop
              isOpen={this.state.cropModalOpen}
              onChange={this.props.onChange}
              onToggle={this.handleModal}
              photoName={this.state.photoName}
              profile={this.props.profile}
              src={this.state.src}
          />
        }

        {this.state.mobileCropModalOpen && this.state.src &&
          <MobileCropModal
              isMobileCrop
              isOpen={this.state.mobileCropModalOpen}
              onChange={this.props.onChange}
              onToggle={this.handleMobileModal}
              photoName={this.state.photoName}
              profile={this.props.profile}
              src={this.state.src}
          />
        }
      </Form>
    );
  }
}

export default BasicForm;
