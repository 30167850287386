import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { filter, isEmpty } from 'lodash';

class Contact extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired
  }

  render() {
    const { instructor } = this.props;

    return (
      <div className='profile-contacts'>
        <ul>
          { instructor.phone &&
            <li className='mb-lg-4'>
              <h5>Phone</h5>
              <a
                  className='text-decoration-none d-inline-block text-truncate'
                  href={'tel:' + instructor.phone}>
                {instructor.phone}
              </a>
            </li>
          }

          { instructor.email &&
            <li className='email mb-lg-4'>
              <h5>Email</h5>
              <a
                  className='text-decoration-none d-inline-block text-truncate'
                  href={'mailto:' + instructor.email}>
                {instructor.email}
              </a>
            </li>
          }

          { instructor.website &&
            <li className='website mb-lg-4'>
              <h5>Website</h5>
              <a
                  className='text-decoration-none d-inline-block text-truncate'
                  href={`//${instructor.website}`}
                  rel='noopener noreferrer'
                  target='_blank'>
                {instructor.website}
              </a>
            </li>
          }

          { !isEmpty(filter(Object.values(instructor.socialProfiles), String)) &&
            <li className='social-links mb-lg-4'>
              <h5>Social profiles</h5>
              <div className='links-wrapper'>
                { instructor.socialProfiles.facebook &&
                <a
                    className='text-decoration-none'
                    href={`https://facebook.com/${instructor.socialProfiles.facebook}`}
                    rel='noopener noreferrer'
                    target='_blank'>
                  <Icon
                      className='mr-1 text-muted'
                      fixedWidth
                      icon={['fab', 'facebook']}
                      size='lg'  />
                </a>
                }

                { instructor.socialProfiles.twitter &&
                <a
                    className='text-decoration-none'
                    href={`https://twitter.com/${instructor.socialProfiles.twitter}`}
                    rel='noopener noreferrer'
                    target='_blank'>
                  <Icon
                      className='mr-1 text-muted'
                      fixedWidth
                      icon={['fab', 'twitter']}
                      size='lg' />
                </a>
                }

                { instructor.socialProfiles.instagram &&
                <a
                    className='text-decoration-none'
                    href={`https://instagram.com/${instructor.socialProfiles.instagram}`}
                    rel='noopener noreferrer'
                    target='_blank'>
                  <Icon
                      className='mr-1 text-muted'
                      fixedWidth
                      icon={['fab', 'instagram']}
                      size='lg' />
                </a>
                }

                { instructor.socialProfiles.linkedin &&
                <a
                    className='text-decoration-none'
                    href={`https://linkedin.com/in/${instructor.socialProfiles.linkedin }`}
                    rel='noopener noreferrer'
                    target='_blank'>
                  <Icon
                      className='mr-1 text-muted'
                      fixedWidth
                      icon={['fab', 'linkedin']}
                      size='lg' />
                </a>
                }
              </div>
            </li>
          }
        </ul>
      </div>
    );
  }
}

export default Contact;