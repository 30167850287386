import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../../helpers';
import { SettingsHomePage } from '../../components/pages';
import { OAuthActions, ProfileActions, UserActions } from '../../actions';

class SettingsHomeContainer extends React.PureComponent {
  render() {
    return (
      <SettingsHomePage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  instructor: state.profile.data,
  user:       state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser:     ()     => dispatch(UserActions.FETCH()),
  oAuthLogout:   (data) => dispatch(OAuthActions.LOGOUT(data)),
  updateProfile: (data) => dispatch(ProfileActions.UPDATE(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(SettingsHomeContainer);
