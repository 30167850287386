import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { compose } from 'redux';

import { requiresAuth } from '../helpers';
import { StoryCreatorPage } from '../components/pages';
import { GroupClassesActions, PrivateGroupClassesActions } from '../actions';

class StoryCreatorContainer extends React.PureComponent {
  static propTypes = {
    fetchGroupClasses:        PropTypes.func.isRequired,
    fetchPrivateGroupClasses: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.fetchGroupClasses();
    this.props.fetchPrivateGroupClasses();
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Insta Story Schedule Generator</title>
        </Helmet>

        <StoryCreatorPage {...this.props} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  groupClasses:        state.groupClasses.data,
  privateGroupClasses: state.privateGroupClasses.data,
  user:                state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchGroupClasses:        () => dispatch(GroupClassesActions.FETCH()),
  fetchPrivateGroupClasses: () => dispatch(PrivateGroupClassesActions.FETCH())
});

export default compose(
  requiresAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(StoryCreatorContainer);
