import { AutocompleterActionTypes } from '../constants/actionTypes';

const initialState = {
  data:        [],
  lastActions: '',
  query:       {}
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };


  switch (action.type) {
    case AutocompleterActionTypes.AUTOCOMPLETE:
      return { ...newState, query: action.payload };

    case AutocompleterActionTypes.AUTOCOMPLETE_SUCCESS:
      return {
        ...newState,
        data: {
          ...state.data,
          [action.payload.subject]: action.payload[action.payload.subject]
        }
      };

    case AutocompleterActionTypes.AUTOCOMPLETE_FAILURE:
      return { ...newState, data: initialState.data };

    default: return state;
  }
};
