import React from 'react';
import PropTypes from 'prop-types';
import { Badge, ButtonGroup, Card, CardTitle, Col, Row, TabContent, TabPane } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { reject } from 'lodash';

import { instructorTabs } from '../../../../constants';
import { Button } from '../../../forms';
import { LocationBadge } from '../../../badges';
import { InstagramContainer, PlaylistContainer } from '../../../../containers';
import { Contact } from './';

class About extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired
  }

  state = {
    activeTab: instructorTabs.STYLE
  }

  _ButtonStyle = (tab) => {
    return this.state.activeTab === tab ? 'primary' : 'link';
  }

  handleTabs = (tab) => () => {
    this.setState({
      activeTab: tab
    });
  }

  renderLocations = () => (
    reject(this.props.instructor.locations, ['tier', 'tier_3']).map((location) => (
      <LocationBadge
          className='mb-3'
          key={location.id}
          location={location} />
    ))
  )

  renderServices = () => (
    <React.Fragment>
      { !!this.props.instructor.services.length &&
        <h5>Coaching</h5>
      }
      { this.props.instructor.services.map((service) => (
        <Badge
            className='badge-service mb-3'
            color='light'
            key={service.id}
            pill>
          {service.title}
        </Badge>
        ))
      }
    </React.Fragment>
  )

  renderStyleTags = () => (
    <div className='profile-style-tags'>
      <h5>Style Tags</h5>
      {/* TODO: pass id to key */}
      { this.props.instructor.styleTags.map((tag, index) => (
        <Badge
            className='px-3 py-2 mr-2 badge-style-tag mb-3'
            color='light'
            key={index}
            pill>
          {tag}
        </Badge>
        ))
      }
    </div>
  )

  renderPrompts = () => (
    this.props.instructor.prompts.map((prompt) => (
      <div
          className='profile-prompt'
          key={prompt.id}>
        <span className='prompt-title'>
          {prompt.text}
        </span>
      </div>
    ))
  )


  render () {
    const { instructor } = this.props;

    return (
      <section className='profile-about no-gutters'>
        <Col
            className='profile-deets'
            lg={6}
            md={12}
            xl={5}>
          <div className='section-header'>
            <h3>My Info</h3>
          </div>
          <div className='section-content'>
            <div className='profile-studios'>
              <h5>Locations</h5>
              {this.renderLocations()}
              {this.renderServices()}
            </div>
            <Contact instructor={instructor} />
          </div>
        </Col>
        <Col
            lg={6}
            md={12}
            xl={7}>
          <div className='section-header'>
            <ButtonGroup size={isMobile ? 'md' : 'lg'}>
              <Button
                  color={this._ButtonStyle(instructorTabs.STYLE)}
                  onClick={this.handleTabs(instructorTabs.STYLE)}>
                My style
              </Button>
              <Button
                  color={this._ButtonStyle(instructorTabs.BIO)}
                  onClick={this.handleTabs(instructorTabs.BIO)}>
                My bio
              </Button>
            </ButtonGroup>
          </div>
          <div className='section-content'>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId={instructorTabs.STYLE}>
                <Row className='justify-content-lg-between no-gutters'>
                  <Col
                      lg={7}
                      md={12}
                      xl={7}>
                    <LazyLoadComponent threshold={100}>
                      <InstagramContainer />
                    </LazyLoadComponent>
                    <LazyLoadComponent threshold={100}>
                      <PlaylistContainer />
                    </LazyLoadComponent>
                  </Col>
                  <Col
                      lg={5}
                      md={12}>
                    {instructor.styleTags && this.renderStyleTags()}
                    <div className='profile-prompts'>
                      {instructor.prompts && this.renderPrompts()}
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId={instructorTabs.BIO}>
                <Row>
                  <Col
                      lg={7}
                      md={12}
                      xl={7}>
                    <div className='profile-bio'>
                      <p>{instructor.bio}</p>
                    </div>
                  </Col>
                  <Col
                      className='profile-experiences'
                      lg={5}
                      md={12}>
                    { !!instructor.experiences.length &&
                      <Card
                          body
                          className='card-experience mb-3'>
                        <CardTitle>Work Experience</CardTitle>
                        <ul>
                          { instructor.experiences.map((exp) => (
                            <li key={exp.id}>
                              {exp.from} - {exp.isCurrent ? 'Current' : exp.to}, {exp.company}
                            </li>
                          )) }
                        </ul>
                      </Card>
                    }
                    { !!instructor.educations.length &&
                      <Card
                          body
                          className='card-experience mb-3'>
                        <CardTitle>Education</CardTitle>
                        <ul>
                          { instructor.educations.map((edu) => (
                            <li key={edu.id}>{edu.name}</li>
                          )) }
                        </ul>
                      </Card>
                    }
                    { !!instructor.certifications.length &&
                      <Card
                          body
                          className='card-experience mb-3'>
                        <CardTitle>ACCREDITATION</CardTitle>
                        <ul>
                          <li>
                            { instructor.certifications.map((cert) => cert.title).join(', ') }
                          </li>
                        </ul>
                      </Card>
                    }
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </Col>
      </section>
    );
  }
}

export default About;
