import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Moment, routes } from '../../lib';
import { Calendr } from '../../lib';
import { Event } from '../calendar';


class UpcomingEvents extends React.PureComponent {
  static propTypes = {
    events:       PropTypes.array.isRequired,
    onEventClick: PropTypes.func.isRequired,
    user:         PropTypes.object.isRequired
  };

  static defaultProps = {
    isMobile: false
  }

  static getDerivedStateFromProps(props) {
    const range = Moment.range(new Moment());

    return {
      events: Calendr.eventsInRange(props.events, props.user.timeZone, range).slice(0, 5)
    };
  }

  state = {
    events: []
  }

  renderEvents = () => (
    this.state.events.map((event) => (
      <Event
          event={event}
          isBooking
          isDashboard
          key={event.type + event.id}
          layout='instructorDashboard'
          onClick={this.props.onEventClick}
          user={this.props.user} />
    ))
  )

  renderEmpty = () => (
    <p className='small text-muted ml-4'>
      No upcoming classes. <Link to={routes.CALENDAR}>Click here</Link> to add a class to your schdule.
    </p>
  )

  render () {
    const { events } = this.state;

    return (
      <React.Fragment>
        <span className='title mb-4'>
          <span
              aria-label="donut"
              role="img" >
            &#128197;
          </span>&nbsp;
           Upcoming Events
        </span>
        <div className='mt-2'>
          {events.length > 0 ? this.renderEvents() : this.renderEmpty()}
        </div>
      </React.Fragment>
    );
  }
}

export default UpcomingEvents;
