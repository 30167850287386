import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { UserNoAvatar } from '../../images';

class UserAvatar extends React.PureComponent {
  static propTypes = {
    alt:        PropTypes.string,
    className:  PropTypes.string,
    effect:     PropTypes.string,
    hasPadding: PropTypes.bool,
    size:       PropTypes.string,
    src:        PropTypes.string
  };

  static defaultProps = {
    alt:        '',
    className:  '',
    effect:     'black-and-white',
    hasPadding: false,
    size:       'md',
    src:        null
  };

  render() {
    const className = classNames('avatar', this.props.className,
      {[`avatar-${this.props.size}`]: this.props.size},
      { 'p-2': this.props.hasPadding });

    if (!this.props.src) {
      return (
        <img
            alt={this.props.alt}
            className={classNames('avatar', this.props.className,
            {[`avatar-${this.props.size}`]: this.props.size
            }, { 'p-2': this.props.hasPadding })}
            src={this.props.src || UserNoAvatar} />
      );
    } else {
      return (
        <LazyLoadImage
            alt={this.props.alt}
            className='img-fluid'
            effect={this.props.effect}
            placeholderSrc={UserNoAvatar}
            src={this.props.src}
            wrapperClassName={className} />
      );
    }

  }
}

export default UserAvatar;