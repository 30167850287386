import { FeedbackActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload,
    type: FeedbackActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: FeedbackActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: () => ({
    type: FeedbackActionTypes.CREATE_SUCCESS
  })
};