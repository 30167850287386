import React from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'reactstrap';
import { eq } from 'lodash';

import { Moment, api } from '../../../lib';

class Subscription extends React.PureComponent {
  static propTypes = {
    plan: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      isFree: eq(props.plan.price, 0)
    };
  }

  state = {
    isFree: false
  }

  handleBillingPortal = (e) => {
    e.preventDefault();

    const _redirect = (response) => (
      window.open(response.data[0].url, '_blank')
    );

    api.billingPortal.get()
        .then(_redirect)
        .catch(console.error);
  }

  renderPrice = () => (
    this.state.isFree
      ? 'FREE'
      : this.props.plan.price.toLocaleString('en-US', { currency: 'USD', style: 'currency' }) + '/mo'
  );

  render() {
    const { plan } = this.props;

    const nextBilling = Moment.utc(plan.endsAt, 'YYYY-MM-DDhh:mm:ssUTC').format('MMM DD, YYYY');

    return (
      <Card
          body
          className='subscription mt-1 mt-lg-2 pb-2 pb-lg-2'>
        <div className='subscription__title'>
          {plan.name}
        </div>

        <div className='subscription__text mt-3'>
          {this.renderPrice()}
        </div>

        <Row className='mt-3'>
          <Col
              md={6}
              xs={6}>
            <div className='subscription__label-gray'>
              Next bill date
            </div>

            <div className='subscription__text'>
              {plan.endsAt ? nextBilling : 'n/a'}
            </div>
          </Col>

          <Col
              md={6}
              xs={6}>
            <div className='subscription__label-gray'>
              Card on File
            </div>

            <div className='subscription__text'>
              {plan.digits ? '***' + plan.digits : 'n/a'}
            </div>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col md='6'>
            <div className='subscription__text'>
              { !this.state.isFree &&
                <a
                    href='#billing'
                    onClick={this.handleBillingPortal}>
                  Manage Subscription
                </a>
              }
            </div>
          </Col>

          <Col md='6'>
            <div className='subscription__text'>
              <span className='plan__text-muted'>
                Questions?&nbsp;
              </span>

              <a href='mailto:info@onpodio.com'>
                Email us!
              </a>
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default Subscription;
