/* eslint-disable max-len */
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

class Privacy extends React.PureComponent {
  render() {
    return (
      <section className='py-4'>
        <Container>
          <Row>
            <Col
                className='mx-auto'
                lg={10}
                md={12}
                xl={8}>
              <div className='text-content'>
                <h1 className='text-center'>Privacy Policy</h1>
                <h6>Effective Date: August 31, 2019</h6>
                <p>This Privacy Policy describes how RhythmicX, Inc. d/b/a onPodio (referred to in this Privacy Policy as “onPodio” “we,” “us,” or “our”), as the operator of the website <a href='https://onpodio.com'>www.onpodio.com</a> (the “Site”), and the services provided through the Site (the Site and these services constituting the “Service”), collects information when you visit or use the Service. It also explains how we use and disclose the information we collect from you, and your choices in relation to that information. By accessing, visiting or using the Service, you expressly consent to our collection, storage, use and disclosure of your information as described in this Privacy Policy. Please note that if you disagree with anything in this Privacy Policy, you must not use the Service. This Privacy Policy is part of, and is governed by, the terms and conditions set forth in our Terms of Use located at <a href='https://onpodio.com/terms'>onpodio.com/terms</a>. This Privacy Policy describes how we collect, use, and share your personal information.</p>

                <h3>1. Information Collected through the Service</h3>
                <h5>A. Information You Directly and Voluntarily Provide to Us</h5>
                <p>If you are a visitor to the Service, we may collect information that you provide to us when you communicate with any of our departments such as customer service or technical services. You may also choose to provide your name and email address to us if you would like to receive additional information about the Service and the products and services that onPodio offers. If you sign up to become a registered user of the Service, you will be required to provide certain information as part of the registration process. This information will include your first and last name, and email address. We will also collect information about you at other points on our Services that state that your information is being collected, including without limitation, when you elect to participate in a sweepstakes, contest, or giveaway offered on our Services.</p>

                <h5>B. Information Automatically Collected Through the Service</h5>
                <p>We may automatically collect information about you when you use the Service. For example, if you access the Service through a computer, we will automatically collect information such as your browser type and version, computer and connection information, IP address and standard web log information. We may automatically collect information from you when you use the Service using “cookies” and other similar technologies, such as web beacons. Cookies are small amounts of data that are stored within your computer’s Internet browser and that are accessed and recorded by the websites that you visit so that they can recognize the same browser navigating online at a later time.</p>
                <p>Information that may be collected by cookies when you use the Service may include, without limitation:</p>
                <ul>
                  <li>the pages you visit within the Service;</li>
                  <li>the date and time of your visit to the Service;</li>
                  <li>the amount of time you spend using the Service;</li>
                  <li>the Internet Protocol (IP) address used to connect your computer to the Internet; and/or</li>
                  <li>your computer and connection information such as your browser type and version, operating system and platform.</li>
                </ul>
                <p>You can set your browser to reject cookies or to notify you when you are sent a cookie. However, if you disable cookies you may not be able to log in to the Service or to use certain features of the Service. To find out more about cookies, including how to see what cookies have been set on your device and how to manage and delete them, visit <a href='http://www.allaboutcookies.org'>www.allaboutcookies.org</a>.</p>

                <h5>C. Information Collected by Third Parties through Third Party Links and Content</h5>
                <p>The Service may include links to other websites and other content from third party businesses. These third party businesses may use cookies, web beacons or other similar technology to collect information about you. onPodio does not have access to or control over these third parties or the cookies, web beacons or other technology that these third parties may use. We are not responsible for the privacy practices of these third parties or the content on any third party website. You are encouraged to review the privacy policies of the different websites you visit and of the advertisers whose ads you may choose to click on while using the Service (see Section 3 below for additional information about Online Advertising).</p>

                <h5>D. Information Collected by Third Party Analytics Services</h5>
                <p>We may work with third party analytics services to help us understand how the Service is being used, such as tracking the frequency and duration of use of the Service. We may use Google Analytics, a web analytics service provided by Google, Inc. (“Google”), to collect information about your use of the Service. Google and these analytics services may use cookies to collect information about the content you view, what websites you visit immediately prior to and after visiting the Service, and your system information and geographic information. The information generated by these cookies about your use of the Service will be transmitted to and stored by the applicable analytics services. The information collected by these analytics services allows us to analyze your use of the Service.</p>

                <h5>E. Information You Share on Third Party Websites or through Social Media Services</h5>
                <p>The Service may include links to third party websites and social media services where you will be able to post comments, stories, reviews or other information. Your use of these third party websites and social media services may result in the collection or sharing of information about you by these third party websites and social media services. We encourage you to review the privacy policies and settings on the third party websites and social media services with which you interact to make sure you understand the information that may be collected, used, and shared by those third party websites and social media services.</p>

                <h5>F. Information Collected from Third Party Companies</h5>
                <p>We may receive information about you from third party companies with whom we enter into a business relationship. We may add this information to the other information we have collected about you.</p>

                <h3>2. How We Use the Information & Data Retention</h3>
                <p>We use the information we gather through the Service to help us better understand how the Service and our products and services are being used. By identifying patterns and trends in usage, we are able to better design the Service and our products and services to improve your experience. We may also use this information to contact and communicate with you and to respond to your communications and requests. If you have provided your email address to us, we may email you about products, services, and promotional campaigns that we believe may be of interest to you. If you wish to opt-out of receiving these emails from us, please follow the instructions contained in each email you receive from us which will allow you to opt-out of receiving further email marketing communications from us. We may also use your information in other ways which we identify at the time you provide your information to us.</p>

                <p>How long we retain your Personal Data depends on the type of data and the purpose for which we process the data. We will retain your Personal Information for the period necessary to fulfill the purposes outlined in this Privacy Notice unless a longer retention period is required or permitted by law.</p>

                <p>Zoom Integration - If you link your Zoom account, you may request to unlink your account and we will delete the associated data.</p>

                <h3>3. Online Advertising; Tracking</h3>
                <p>onPodio and third party businesses may use the information collected through the Service using cookies, web beacons, and other similar technologies to help manage online advertising programs. This information may enable us, as well as third-party advertising services and other third-party businesses to track the actions of users online over time and across different web sites or platforms to measure statistics relating to marketing efforts, and to deliver electronic advertisements that may be more relevant to individual consumers and that will improve the consumer experience. For information about how tracking works for online advertising purposes, and what happens when you elect a do-not-track option, visit <a href='http://www.aboutads.info/choices'>aboutads.info</a>. In addition, some third party businesses may provide a mechanism to opt-out of their technology. For more information about the opt-out process, you may visit the Network Advertising Initiative website, available at: <a href='http://www.networkadvertising.org/managing/opt_out.asp'>networkadvertising.org</a>.</p>
                <p><strong>Do Not Track:</strong> Your browser may allow you to adjust your browser settings so that “do not track” requests are sent to the websites that you visit. However, onPodio will not disable tracking technology that may be active on the Service in response to any “do not track” requests that we receive from your browser.</p>

                <h3>4. How We Share Your Information with Third Parties.</h3>
                <p>onPodio shares your information with third parties in some circumstances as follows:</p>

                <h5>A. Employees, Third-Party Processors and Third-Party Service Providers</h5>
                <p>We will disclose your information to our employees, contractors, affiliates, distributors, dealers, vendors and suppliers (“Service Providers”) who provide certain services to us or on our behalf, such as operating and supporting the Service, analyzing data, or performing marketing or consulting services.</p>

                <h5>B. Response to Subpoenas or Court Orders or to Protect Rights and to Comply with Our Policies</h5>
                <p>To the extent permitted by law, we will disclose your information to government authorities or third parties if: (i) required to do so by law, or in response to a subpoena or court order; (ii) we believe in our sole discretion that disclosure is reasonably necessary to protect against fraud, to protect the property, safety or other rights of us or other users, third parties or the public at large; or (iii) we believe that you have abused the Service by using it to attack other systems or to gain unauthorized access to any other system, to engage in spamming or otherwise to violate applicable laws. You should be aware that, following disclosure to any third party, your information may be accessible by others to the extent permitted or required by applicable law.</p>

                <h5>C. Business Transfers; Bankruptcy</h5>
                <p>In the event of a merger, acquisition, bankruptcy or other sale of all or a portion of our assets, any user information owned or controlled by us may be one of the assets transferred to third parties. We reserve the right, as part of this type of transaction, to transfer or assign your information and other information we have collected from users of the Service to third parties. Other than to the extent ordered by a bankruptcy or other court, the use and disclosure of all transferred user information will be subject to this Privacy Policy. However, any information you submit or that is collected after this type of transfer may be subject to a new privacy policy adopted by the successor entity.</p>

                <h5>D. Aggregate Information</h5>
                <p>We may share information relating to users of the Service with affiliated or unaffiliated third parties on an anonymous, aggregate basis. While this information will not identify you personally, in some instances these third parties may be able to combine this aggregate information with other data they have about you, or that they receive from third parties, in a manner that allows them to identify you personally.</p>

                <h5>E. Our Affiliates</h5>
                <p>We may share some or all of your information with our parent company, subsidiaries and corporate affiliates, joint venturers, or other companies under common control with us. If you opt-in to receive marketing communications from third parties, we may permit these entities to use your information for their own marketing purposes. If you would like us to stop providing your information to our affiliates for their own marketing purposes, you may opt-out by emailing us at info@onpodio.com.</p>

                <h5>F. Disclosure to Third Party Companies Involved in Promotions</h5>
                <p>When you enter a sweepstakes, contest, or giveaway on our Services, we may share your information with any co-sponsors, prize providers, or other third parties involved in the promotion that we identify in the rules or entry materials. We don’t control these third parties’ privacy practices, and our Privacy Policy does not apply to their collection and use of your information. We may also share some of your entry information with third parties or the public in connection with the administration of the promotion, such as winner selection and prize fulfillment, and as permitted by the promotion’s official rules, such as on a winners’ list.</p>

                <h3>5. Children Under Age 13</h3>
                <p>The Service is not intended for children under age 13, or for anyone under age 18 or the applicable age of majority without involvement of a parent or guardian. We do not knowingly collect or distribute personal information from or about children under the age of 13. If you believe that we may have any personally identifiable information about a child under 13, please contact us at info@onpodio.com.</p>

                <h3>6. Security</h3>
                <p>We implement a number of technical and organizational security measures designed to protect personal information in our control. Please be aware that risk is inherent in Internet technologies. As a result, onPodio cannot guarantee or warrant the security of any information you transmit on or through the Service and you do so at your own risk.</p>

                <h3>7. Amendments to this Privacy Policy</h3>
                <p>We will notify you of material changes to this Privacy Policy by posting the amended terms on the Service. If you do not agree to the changes, you should discontinue your use of the Service prior to the time the modified privacy policy takes effect. If you continue using the Service after the modified privacy policy takes effect, you will be bound by the modified privacy policy.</p>

                <h3>8. Use of Information Outside Your Country of Residence</h3>
                <p>If you choose to use the Service or provide your information to us, your information may be transferred to, processed and maintained on servers or databases located outside of the country or jurisdiction where you are located. Your agreement to this Privacy Policy followed by your submission of information through the Service represents your consent and agreement to these practices. If you do not want your information transferred to or processed or maintained outside of the country or jurisdiction where you are located, you should not use the Service.</p>

                <h3>9. Accessing, Changing and Managing Your Information</h3>
                <p>You may request changes to the user information you have provided to us by contacting us at info@onpodio.com. Following receipt of a request from you, we will use commercially reasonable efforts to honor your request. We may retain an archived copy of your information as required by law or for legitimate business purposes.</p>

                <h3>10. Special Information for California Residents</h3>
                <p>Under California Civil Code sections 1798.83-1798.84, also known as the “Shine the Light Law,” any users of the Service who are California residents are entitled to request and receive once a year, free of charge, a notice from us describing what categories of personal customer information (if any) we shared with third parties, including our corporate affiliates, for their direct marketing purposes during the preceding calendar year. If applicable, the notice will identify the categories of information that we shared, and the names and addresses of the third parties and affiliates with which we shared your information. If you are a California resident and would like to request a copy of this notice, please submit a written request to the following address: 149 New Montgomery Street, San Francisco, CA 94105 or by email at <a href='mailto:info@onpodio.com'>info@onpodio.com</a>. In your request, please specify that you want a “California Privacy Rights Notice.” Please allow at least thirty (30) days for a response.</p>

                <h3>11. How to Contact Us</h3>
                <p>Please also feel free to contact us at <a href='mailto:info@onpodio.com'>info@onpodio.com</a> if you have any questions about this Privacy Policy.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Privacy;
