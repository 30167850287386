/* eslint-disable immutable/no-mutation, immutable/no-let, no-loops/no-loops */
import { storyMakerOptions } from '../constants';

const aspect = require('fit-aspect-ratio');

class StoryMaker {
  constructor() {
    this.canvas = {};
    this.offset = {};
  }

  _zoom = (prevState, zoomValue) => {
    let width;
    let deltaWidth = prevState.deltaWidth;

    let height;
    let deltaHeight = prevState.deltaHeight;

    let originalWidth = deltaWidth  * 10;
    let originalHeight = deltaHeight * 10;

    switch (zoomValue === 0) {
      case true:
        width = originalWidth;
        height = originalHeight;
        break;

      case false:
        width = originalWidth + (deltaWidth * zoomValue);
        height = originalHeight + (deltaHeight * zoomValue);
        break;

      default:
        break;
    }

    if (height > 0 && width > 0) {
      return { deltaHeight, deltaWidth, height, width };
    } else {
      return prevState;
    }
  }

  _fitRatio = (height, width, ratio = '9:16') => {
    return aspect({ height, ratio, width });
  }

  _getBounds = () => {
    const height = window.innerHeight - 176;
    const width  = window.innerWidth -  32;

    return this._fitRatio(height, width);
  }

  _getInitialState = () => {
    const bounds = this._getBounds();

    const defaultValues = storyMakerOptions.DEFAULT_VALUES;

    return {
      bounds,
      ...defaultValues
    };
  }

  /* ASYNC */

  _arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);

    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  _orientation = (file, callback = () => {}) => {
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      const base64 = 'data:' + file.type + ';base64,' + this._arrayBufferToBase64(fileReader.result);
      const scanner = new DataView(fileReader.result);

      let index = 0;
      if (fileReader.result.length < 2 || scanner.getUint16(index) != 0xFFD8) {
        callback(base64, 1);
        return;
      }

      index += 2;
      let maxBytes = scanner.byteLength;
      let value;

      while (index < (maxBytes - 2)) {
        const uint16 = scanner.getUint16(index);

        index += 2;
        switch (uint16) {
          case 0xFFE1:
            maxBytes = scanner.getUint16(index) - index;
            index += 2;
            break;

          case 0x0112:
            value = scanner.getUint16(index + 6, false);
            maxBytes = 0;
            break;
        }
      }

      callback(base64, value);
    };

    fileReader.readAsArrayBuffer(file);
  };

  _rotateBase64 = (base64, degrees, callback) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const image = new Image();
    image.setAttribute('src', base64);

    image.onload = () => {
      const width = image.width;
      const height = image.height;

      const radians = degrees * Math.PI / 180;

      let cos = Math.cos(radians);
      let sin = Math.sin(radians);

      if (sin < 0) { sin = -sin; }
      if (cos < 0) { cos = -cos; }

      canvas.width = height * sin + width * cos;
      canvas.height = height * cos + width * sin;

      ctx.translate(canvas.width / 2, canvas.height / 2);
      ctx.rotate(radians);
      ctx.drawImage(image, -image.width / 2, -image.height / 2);

      callback(canvas.toDataURL());
      canvas.remove();
    };
  }

  _toBinary = (dataURI) => {
    const base64 = dataURI.split(',')[1];
    const raw = window.atob(base64);
    const rawLength = raw.length;
    const array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }
}

export default new StoryMaker();
