import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';

import { DateHelper, Moment, Validator, getTimeZone } from '../../../lib';
import { Button, Checkbox, Form, MaskedInput } from '../';
import { Input } from '../../inputs';
import { minuteMask, weekMask } from '../../../constants/masks';
import { DatePicker, TimePicker } from '../../inputs';

class TimeBlockForm extends React.PureComponent {
  static propTypes = {
    data:        PropTypes.object.isRequired,
    isNewRecord: PropTypes.bool.isRequired,
    onCreate:    PropTypes.func,
    onDestroy:   PropTypes.func,
    onUpdate:    PropTypes.func,
    user:        PropTypes.object.isRequired
  };

  static defaultProps = {
    onCreate:  () => {},
    onDestroy: () => {},
    onUpdate:  () => {}
  };

  static getDerivedStateFromProps(props, state) {
    const { user } = props;
    const date = DateHelper.summerTimeCheck(props.data, props.user.timeZone);

    const timeZone = getTimeZone(user.timeZone);
    const startsAt = props.isNewRecord ?
      new Moment().tz(timeZone) : new Moment.utc(date, 'YYYY-MM-DDhh:mm:ssUTC').tz(timeZone);

    const timeBlock = {
      date:      new Moment(startsAt).format('YYYY-MM-DD'),
      duration:  props.data.duration,
      name:      props.data.name,
      recurring: !!props.data.recurring,
      time:      startsAt.format('HH:mm'),
      weeks:     props.data.recurrence ? String(props.data.recurrence.total) : String(2)
    };

    if (state.timeBlock) {
      return state;
    }

    return props.isNewRecord ? { timeBlock } : { timeBlock: { ...timeBlock, id: props.data.id } };
  }

  state = {
    errors: {},

    timeBlock: ''
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      ...prevState,
      timeBlock: {
        ...prevState.timeBlock,
        [inputName]: value
      }
    }));
  };

  handleSelect = (option, inputName) => {
    this.setState((prevState) => ({
      ...prevState,
      timeBlock: {
        ...prevState.timeBlock,
        [inputName]: option.value
      }
    }));
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const { timeBlock } = this.state;

    const errors = Validator.validate([
      ['date',                 Validator.concepts.isFilled, [timeBlock.date]],
      ['time',                 Validator.concepts.isFilled, [timeBlock.time]],
      ['duration',             Validator.concepts.isFilled, [timeBlock.duration]],
      ['name',                 Validator.concepts.isFilled, [timeBlock.name]],
      ['weeks', Validator.concepts.weeksValidator, [timeBlock.weeks, timeBlock.recurring]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  handleSubmit = () => {
    const { timeBlock } = this.state;

    this.handleValidate().then(() => {
      return this.props.onCreate(timeBlock);
    }).catch((errors) => {
      return this.setState({ errors });
    });
  };

  handleUpdate = () => {
    this.props.onUpdate(this.state.timeBlock);
  };

  handleDelete = () => {
    this.props.onDestroy();
  };
2
  render () {
    const { timeBlock } = this.state;

    return (
      <Form className='form-default'>
        <FormGroup>
          <Label
              className='required'
              for='input-name'>Name</Label>
          <Input
              id='name'
              isInvalid={!!this.state.errors.name}
              name='name'
              onChange={this.handleChange}
              placeholder='Name your block'
              type='text'
              value={timeBlock.name} />
        </FormGroup>
        <FormGroup>
          <Label
              className='required'
              for='input-date'>Date</Label>
          <DatePicker
              isInvalid={!!this.state.errors.date}
              name='date'
              onChange={this.handleChange}
              value={timeBlock.date} />
        </FormGroup>
        <Row form>
          <Col md={6}>
            <FormGroup>
              <Label
                  className='required'
                  for='input-time'>Time</Label>

              <TimePicker
                  isInvalid={!!this.state.errors.time}
                  name='time'
                  onChange={this.handleChange}
                  value={timeBlock.time} />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label
                  className='required'
                  for='input-duration'>Duration</Label>
              <MaskedInput
                  isInvalid={!!this.state.errors.duration}
                  mask={minuteMask}
                  name='duration'
                  onChange={this.handleChange}
                  placeholder=' mins'
                  type='number'
                  value={String(timeBlock.duration)} />
            </FormGroup>
          </Col>
        </Row>

        <FormGroup>
          <Label>Recur Class Weekly?</Label>
          <div className='d-flex'>
            <div className='custom-control custom-checkbox checkbox-lg'>
              <Checkbox
                  className='custom-control-input'
                  id='recurring'
                  isChecked={timeBlock.recurring}
                  name='recurring'
                  onChange={this.handleChange} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-recurring' />
            </div>

            <MaskedInput
                className='ml-2'
                id='weeks'
                isDisabled={!timeBlock.recurring}
                isInvalid={!!this.state.errors.weeks}
                mask={weekMask}
                name='weeks'
                onChange={this.handleChange}
                placeholder=' weeks'
                type='number'
                value={String(timeBlock.weeks)} />
          </div>
        </FormGroup>

        { this.props.isNewRecord &&
          <Button
              className='mt-4'
              isBlock
              onClick={this.handleSubmit}
              onKeyPress={this.handleSubmit}
              size='lg'
              type='button'>
            Create
          </Button>
        }
        { !this.props.isNewRecord &&
          <Row className='mt-4'>
            <Col
                lg={6}
                md={12}>
              <Button
                  color='danger'
                  isBlock
                  isOutline
                  onClick={this.handleDelete}
                  onKeyPress={this.handleDelete}
                  size='lg'
                  type='button'>
                Delete
              </Button>
            </Col>

            <Col
                lg={6}
                md={12}>
              <Button
                  isBlock
                  onClick={this.handleUpdate}
                  onKeyPress={this.handleUpdate}
                  size='lg'
                  type='button'>
                Update
              </Button>
            </Col>
          </Row>
        }
      </Form>
    );
  }
}

export default TimeBlockForm;
