import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { InstructorPage } from '../components/pages';
import { Loader } from '../components/loader';

import {
  BookingsActions,
  CommentsActions,
  GroupClassesActions,
  InstagramActions,
  ProfileDataActions
} from '../actions';

class ProfileContainer extends React.PureComponent {
  static propTypes = {
    fetchComments:   PropTypes.func.isRequired,
    fetchInstagram:  PropTypes.func.isRequired,
    fetchTracks:     PropTypes.func.isRequired,
    getGroupClasses: PropTypes.func.isRequired,
    getProfileData:  PropTypes.func.isRequired,
    history:         PropTypes.object.isRequired,
    instructor:      PropTypes.object.isRequired,
    profileData:     PropTypes.object.isRequired
  }

  componentDidMount() {
    const { username } = this.props.instructor.data;

    this.props.getProfileData();
    this.props.fetchTracks({ username });
    this.props.fetchInstagram({ username });
    this.props.fetchComments({ username });
    this.props.getGroupClasses({ username });
  }

  componentDidUpdate(nextProps) {
    if (!Object.keys(nextProps.instructor.data).length) {
      nextProps.history.goBack();
    }
  }

  render() {
    if (Object.keys(this.props.instructor.data).length) {
      return <InstructorPage {...this.props} />;
    } else {
      return <Loader isLoading />;
    }
  }
}

const mapStateToProps = (state) => ({
  /* note that we are mapping 'profile' to 'instructor' */
  comments:     state.comments,
  groupClasses: state.groupClasses,
  instagram:    state.instagram,
  instructor:   state.profile,
  profileData:  state.profileData,
  tracks:       state.tracks
});

const mapDispatchToProps = (dispatch) => ({
  createBooking:   (data) => dispatch(BookingsActions.CREATE(data)),
  createComment:   (data) => dispatch(CommentsActions.CREATE(data)),
  fetchComments:   (data) => dispatch(CommentsActions.FETCH(data)),
  fetchInstagram:  (data) => dispatch(InstagramActions.FETCH(data)),
  getGroupClasses: (data) => dispatch(GroupClassesActions.GET_INSTRUCTORS(data)),
  getProfileData:  ()     => dispatch(ProfileDataActions.GET())
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileContainer);

