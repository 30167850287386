import React from 'react';
import PropTypes from 'prop-types';
import { findIndex } from 'lodash';

import { Tags } from '../';

class Certifications extends React.PureComponent {
  static propTypes = {
    children:    PropTypes.node.isRequired,
    className:   PropTypes.string.isRequired,
    onChange:    PropTypes.func.isRequired,
    profile:     PropTypes.object.isRequired,
    profileData: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      ids: props.profile.certifications.map((certificate) => certificate.id)
    };
  }

  state = {
    ids: []
  }

  handleChange = (option, name) => {
    const { certifications } = this.props.profile;

    const index = findIndex(certifications, ['id', option.id]);
    if (index > -1) {
      this.props.onChange(
        [...certifications.slice(0, index), ...certifications.slice(index + 1, certifications.length)],
        name
      );
    } else {
      this.props.onChange(
        [...certifications, option],
        name
      );
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.children}

        <Tags
            name='certifications'
            onChange={this.handleChange}
            options={this.props.profileData.data.certifications}
            selectedIds={this.state.ids} />
      </div>
    );
  }
}

export default Certifications;
