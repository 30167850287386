import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError, toCamelCase } from '../lib';
import { StyleTagsActionTypes } from '../constants/actionTypes';
import { ErrorActions, StyleTagsActions } from '../actions';

export function* watchFetch() {
  yield takeLatest(StyleTagsActionTypes.FETCH, function* () {
    try {
      const response = yield call(api.styleTags.fetch);

      yield put(StyleTagsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(StyleTagsActions.FETCH_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* styleTagsSaga() {
  yield all([
    watchFetch()
  ]);
}