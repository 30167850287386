import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';

class FundraiserDefault extends React.PureComponent {
  static propTypes = {
    currency:        PropTypes.string.isRequired,
    donationTargets: PropTypes.object.isRequired,
    progressValue:   PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    totalDonations:  PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
  };

  render() {
    const { donationTargets, currency, totalDonations, progressValue } = this.props;

    return (
      <div className='fundraiser'>
        <h6>{donationTargets.name}</h6>
        <Progress
            color='success'
            value={progressValue} />
        <div className='goal'>
          <span className='raised'>{totalDonations.toLocaleString('en-US',
            { currency, maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency' })}
          </span>
          &nbsp;raised of {donationTargets.target.toLocaleString('en-US',
            { currency,  maximumFractionDigits: 0, minimumFractionDigits: 0, style: 'currency' })}
          &nbsp;goal
        </div>
      </div>
    );
  }
}

export default FundraiserDefault;
