export default (error) => {
  if (error.response) {
    const errorMessage = error.response.data.message ? error.response.data.message : error.response.statusText;

    return {
      message: errorMessage,
      status:  error.response.status
    };
  } else {
    console.error(error);
    return error.message;
  }
};