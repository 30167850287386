import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../forms';

class StripeDisconnect extends PureComponent {
  static propTypes = {
    onCancel:     PropTypes.func.isRequired,
    onDisconnect: PropTypes.func.isRequired
  };

  handleDisconnect = () => {
    this.props.onDisconnect('stripe');
    this.props.onCancel();
  }

  render() {
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            <span>
              After disconnecting from Stripe, your customers 
              <span className='text-danger'> will not be able to buy your paid classes and packages</span>
            </span>
          </CardText>

          <div className='actions'>
            <Button
                color='danger'
                isOutline
                onClick={this.props.onCancel}
                size='md'>
              No, cancel
            </Button>

            <Button
                color='danger'
                onClick={this.handleDisconnect}
                size='md'>
              Yes, disconnect
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default StripeDisconnect;
