import { SamplesActionTypes } from '../constants/actionTypes';

export default {
  DELETE_CHALLENGE: (payload) => ({
    payload, type: SamplesActionTypes.DELETE_CHALLENGE
  }),

  DELETE_CHALLENGE_FAILURE: () => ({
    type: SamplesActionTypes.DELETE_CHALLENGE_FAILURE
  }),

  DELETE_CHALLENGE_SUCCESS: (payload) => ({
    payload, type: SamplesActionTypes.DELETE_CHALLENGE_SUCCESS
  }),

  DELETE_CLASS_PACK: (payload) => ({
    payload, type: SamplesActionTypes.DELETE_CLASS_PACK
  }),

  DELETE_CLASS_PACK_FAILURE: () => ({
    type: SamplesActionTypes.DELETE_CLASS_PACK_FAILURE
  }),

  DELETE_CLASS_PACK_SUCCESS: (payload) => ({
    payload, type: SamplesActionTypes.DELETE_CLASS_PACK_SUCCESS
  }),


  DELETE_MEMBERSHIP: (payload) => ({
    payload, type: SamplesActionTypes.DELETE_MEMBERSHIP
  }),

  DELETE_MEMBERSHIP_FAILURE: () => ({
    type: SamplesActionTypes.DELETE_MEMBERSHIPS_FAILURE
  }),

  DELETE_MEMBERSHIP_SUCCESS: (payload) => ({
    payload, type: SamplesActionTypes.DELETE_MEMBERSHIP_SUCCESS
  }),


  FETCH_CHALLENGES: () => ({
    type: SamplesActionTypes.FETCH_CHALLENGES
  }),
  FETCH_CHALLENGES_FAILURE: () => ({
    type: SamplesActionTypes.FETCH_CHALLENGES_FAILURE
  }),

  FETCH_CHALLENGES_SUCCESS: (payload) => ({
    payload, type: SamplesActionTypes.FETCH_CHALLENGES_SUCCESS
  }),


  FETCH_CLASS_PACKS: () => ({
    type: SamplesActionTypes.FETCH_CLASS_PACKS
  }),
  FETCH_CLASS_PACKS_FAILURE: () => ({
    type: SamplesActionTypes.FETCH_CLASS_PACKS_FAILURE
  }),

  FETCH_CLASS_PACKS_SUCCESS: (payload) => ({
    payload, type: SamplesActionTypes.FETCH_CLASS_PACKS_SUCCESS
  }),


  FETCH_MEMBERSHIPS: () => ({
    type: SamplesActionTypes.FETCH_MEMBERSHIPS
  }),
  FETCH_MEMBERSHIPS_FAILURE: () => ({
    type: SamplesActionTypes.FETCH_MEMBERSHIPS_FAILURE
  }),

  FETCH_MEMBERSHIPS_SUCCESS: (payload) => ({
    payload, type: SamplesActionTypes.FETCH_MEMBERSHIPS_SUCCESS
  }),


  FETCH_ON_DEMANDS: () => ({
    type: SamplesActionTypes.FETCH_ON_DEMANDS
  }),
  FETCH_ON_DEMANDS_FAILURE: () => ({
    type: SamplesActionTypes.FETCH_ON_DEMANDS_FAILURE
  }),

  FETCH_ON_DEMANDS_SUCCESS: (payload) => ({
    payload, type: SamplesActionTypes.FETCH_ON_DEMANDS_SUCCESS
  }),


  FETCH_PRIVATE_GROUP_CLASSES: () => ({
    type: SamplesActionTypes.FETCH_PRIVATE_GROUP_CLASSES
  }),
  FETCH_PRIVATE_GROUP_CLASSES_FAILURE: () => ({
    type: SamplesActionTypes.FETCH_PRIVATE_GROUP_CLASSES_FAILURE
  }),

  FETCH_PRIVATE_GROUP_CLASSES_SUCCESS: (payload) => ({
    payload, type: SamplesActionTypes.FETCH_PRIVATE_GROUP_CLASSES_SUCCESS
  })
};
