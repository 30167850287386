/* global process */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import {  } from 'lodash';
import { filter, head, isEmpty, noop, sumBy } from 'lodash';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { PRIVATE_SESSION_STEPS } from '../../constants';
import { Details, Guest, Info, Payment } from './privateSession';

class RequestPrivateSessionModal extends React.PureComponent {
  static propTypes = {
    availabilities:       PropTypes.object.isRequired,
    challenges:           PropTypes.array.isRequired,
    classPacks:           PropTypes.array.isRequired,
    createDiscount:       PropTypes.func.isRequired,
    createPrivateSession: PropTypes.func.isRequired,
    discount:             PropTypes.object,
    getAvailability:      PropTypes.func.isRequired,
    instructor:           PropTypes.object.isRequired,
    isLoading:            PropTypes.bool,
    isOpen:               PropTypes.bool,
    onDateChange:         PropTypes.func.isRequired,
    onToggle:             PropTypes.func,
    selectedDate:         PropTypes.object.isRequired,
    subtractCredit:       PropTypes.func.isRequired,
    user:                 PropTypes.object.isRequired
  };

  static defaultProps = {
    discount:  {},
    isLoading: false,
    isOpen:    false,
    onToggle:  () => {}
  };

  state = {
    guest:          {},
    isLoading:      false,
    privateSession: {
      locationType: 0
    },
    step: PRIVATE_SESSION_STEPS.info
  }

  static getDerivedStateFromProps(props, state) {
    const filteredPacks = filter(props.classPacks, (classPack) => (
      classPack.privateSession > 0
    ));
    const filteredChallenge = filter(props.challenges, {'instructorId': props.instructor.id, 'privateSession': true});

    const privateSession = sumBy(filteredPacks, 'privateSession');

    const classPack = head(filteredPacks);
    const challenge = head(filteredChallenge);

    const isClassPackAvailable = !!privateSession;
    const isChallengeAvailable = !isEmpty(challenge);

    return {
      ...state,
      challenge,
      classPack: {
        id: classPack && classPack.id,
        privateSession
      },
      isChallengeAvailable,
      isClassPackAvailable
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedDate } = this.props;
    const { privateSessionTypeId } = this.state.privateSession;

    if (this.state.privateSession.privateSessionTypeId !== prevState.privateSession.privateSessionTypeId) {
      this.props.getAvailability({ privateSessionTypeId, selectedDate });
    }

    if (this.props.selectedDate !== prevProps.selectedDate) {
      this.props.getAvailability({ privateSessionTypeId, selectedDate });
    }
  }

  handleChange = (value, inputName, callback = noop) => {
    if (!inputName) return;

    this.setState((prevState) => ({
      privateSession: {
        ...prevState.privateSession,
        [inputName]: value
      }
    }), callback);
  };

  handleChangeState = (value, inputName, callback = noop) => {
    this.setState({ [inputName]: value }, callback);
  }

  handleNext = (step) => {
    this.setState({ step });
  }

  handleSelect = (inputName, isName) => (option) => {
    const { duration, typeName, price } = option;

    this.setState((prevState) => ({
      privateSession: {
        ...prevState.privateSession,
        duration:    duration || prevState.privateSession.duration,
        [inputName]: option.id,
        name:        isName ? typeName : prevState.privateSession.name,
        price:       price === 0 ? price : price || prevState.privateSession.price
      }
    }));
  };

  handleToggle = () => {
    const { selectedDate } = this.props;

    this.props.getAvailability({ selectedDate });
    this.props.onToggle();
  };

  handleLoading = (isLoading, callback) => (
    this.setState({ isLoading }, callback)
  )

  renderContent() {
    const { instructor } = this.props;

    const stripePromise = () => (
      loadStripe(process.env.STRIPE_API_KEY, { stripeAccount: instructor.stripeId })
    );

    switch (this.state.step) {
      case PRIVATE_SESSION_STEPS.info:
        return (
          <Info
              availabilities={this.props.availabilities}
              instructor={this.props.instructor}
              isLoading={this.props.isLoading}
              onChange={this.handleChange}
              onDateChange={this.props.onDateChange}
              onNext={this.handleNext}
              onSelect={this.handleSelect}
              privateSession={this.state.privateSession}
              selectedDate={this.props.selectedDate}
              user={this.props.user} />
        );

      case PRIVATE_SESSION_STEPS.guest:
        return (
          <Guest
              instructor={this.props.instructor}
              onChange={this.handleChangeState}
              onNext={this.handleNext} />

        );

      case PRIVATE_SESSION_STEPS.details:
        return (
          <Details
              challenge={this.state.challenge}
              classPack={this.state.classPack}
              createPrivateSession={this.props.createPrivateSession}
              guest={this.state.guest}
              instructor={this.props.instructor}
              isChallengeAvailable={this.state.isChallengeAvailable}
              isClassPackAvailable={this.state.isClassPackAvailable}
              onChange={this.handleChange}
              onLoading={this.handleLoading}
              onNext={this.handleNext}
              onToggle={this.handleToggle}
              privateSession={this.state.privateSession}
              subtractCredit={this.props.subtractCredit}
              user={this.props.user} />
        );

      case PRIVATE_SESSION_STEPS.payment:
        return (
          <Elements stripe={stripePromise()}>
            <Payment
                createDiscount={this.props.createDiscount}
                createPrivateSession={this.props.createPrivateSession}
                discount={this.props.discount}
                guest={this.state.guest}
                instructor={this.props.instructor}
                isLoading={this.state.isLoading}
                onChange={this.handleChange}
                onLoading={this.handleLoading}
                onNext={this.handleNext}
                onToggle={this.handleToggle}
                privateSession={this.state.privateSession}
                user={this.props.user} />
          </Elements>
        );

      default: return null;
    }
  }

  render() {
    return (
      <Modal
          className='modal-private-session'
          isOpen={this.props.isOpen}
          toggle={this.handleToggle}>
        <ModalHeader toggle={this.handleToggle} />

        {this.renderContent()}
      </Modal>
    );
  }
}

export default RequestPrivateSessionModal;
