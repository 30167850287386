import React from 'react';
import PropTypes from 'prop-types';

import { UserModalAvatar } from '../layouts';

class Success extends React.PureComponent {
  static propTypes = {
    classPack:  PropTypes.object.isRequired,
    instructor: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    const { premium } = props.classPack;

    return {
      live:     premium.live == 'true',
      onDemand: premium.onDemand == 'true'
    };
  }

  render() {
    const { classPack, instructor } = this.props;
    const { live, onDemand } = this.state;
    const { currency } = instructor;

    const isForPrivateSession = classPack.creditsType == 'privateSession';

    return (
      <div>
        <div>
          <span className='modal__default-text modal__default-text_blue modal__default-text_bold'>
            CLASS PACK PURCHASED
          </span>
        </div>

        <div className='modal__success-text'>
          <span className='modal__small-text'>
            You’re all set! You will receive an email with the details below shortly!
          </span>
        </div>

        { instructor &&
          <div className='modal__header'>
            <UserModalAvatar
                alt={instructor.name}
                firstName={instructor.name}
                src={instructor.photo.sm} />
            <div className='modal__instructor-name'>
              <span className='modal__big-text'>{instructor.name}</span>
            </div>
          </div>
        }

        <div className='modal-event-name'>
          <span className='modal__big-text modal__big-text_black'>{classPack.name}</span>
        </div>

        <div className='text-muted'>
          {classPack.cost.toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
          for {classPack.number} classes
        </div>

        { classPack.description &&
          <div className='event-description'>
            <span className='modal__small-text'>About this class pack</span>
            <p className='modal__small-text modal__small-text_grey'>{classPack.description}</p>
          </div>
        }

        { classPack.canExpire &&
          <div className='modal__small-text modal__small-text_grey mb-2'>
            Expires: <u>{classPack.expiry} days after purchase</u>
          </div>
        }

        <div>
          <span className='modal__small-text modal__small-text_grey'>
            { onDemand && live &&
              'Valid with Live Stream and On-Demand classes.'
            }

            { onDemand && !live &&
              'Valid with On-Demand classes.'
            }

            { live && !onDemand &&
              'Valid with Live Stream classes.'
            }

            { isForPrivateSession &&
              'Valid with Private Sessions.'
            }
          </span>
        </div>
      </div>
    );
  }
}

export default Success;
