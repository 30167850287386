import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';

import { routes } from '../../lib';
import { PrivateSessionModal } from '../modals';
import { ProfileMapContainer } from '../../containers';
import { profileMapTypes } from '../../constants';
import { Footer, Header } from './profile';
import { About } from './profile/instructor';

class InstructorPage extends React.PureComponent {
  static propTypes = {
    availability:         PropTypes.object.isRequired,
    claimInstructor:      PropTypes.func.isRequired,
    createBooking:        PropTypes.func,
    createPrivateBooking: PropTypes.func,
    createPrivateSession: PropTypes.func,
    createSubscription:   PropTypes.func,
    followInstructor:     PropTypes.func,
    getAvailability:      PropTypes.func.isRequired,
    goTo:                 PropTypes.func,
    groupClasses:         PropTypes.array.isRequired,
    hasProfile:           PropTypes.bool,
    instructor:           PropTypes.object.isRequired,
    memberships:          PropTypes.array.isRequired,
    privateGroupClasses:  PropTypes.array.isRequired,
    session:              PropTypes.object,
    unfollowInstructor:   PropTypes.func,
    user:                 PropTypes.object
  }

  static defaultProps = {
    createBooking:        () => {},
    createComment:        () => {},
    createPrivateBooking: () => {},
    createPrivateSession: () => {},
    createSubscription:   () => {},
    followInstructor:     () => {},
    goTo:                 () => {},
    hasProfile:           false,
    session:              {},
    unfollowInstructor:   () => {},
    user:                 {}
  }

  static getDerivedStateFromProps(props) {
    return {
      instructor: props.instructor.data
    };
  }

  state = {
    instructor:         {},
    isPrivateModalOpen: false
  }

  handlePrivateToggle = () => {
    if (this.props.session.authenticated) {
      this.setState((prevState) => ({
        isPrivateModalOpen: !prevState.isPrivateModalOpen
      }));
    } else {
      this.props.goTo(routes.LOGIN);
    }
  }

  render() {
    const { instructor } = this.state;

    return (
      <div className='inner-wrapper profile-page'>
        <Helmet>
          <title>{[instructor.firstName, instructor.lastName].join(' ')}</title>
        </Helmet>

        <Header
            availability={this.props.availability}
            claimInstructor={this.props.claimInstructor}
            createBooking={this.props.createBooking}
            createPrivateBooking={this.props.createPrivateBooking}
            createPrivateSession={this.props.createPrivateSession}
            createSubscription={this.props.createSubscription}
            follow={this.props.followInstructor}
            getAvailability={this.props.getAvailability}
            goTo={this.props.goTo}
            groupClasses={this.props.groupClasses}
            hasProfile={this.props.hasProfile}
            instructor={instructor}
            memberships={this.props.memberships}
            onPrivateClick={this.handlePrivateToggle}
            privateGroupClasses={this.props.privateGroupClasses}
            session={this.props.session}
            type='instructor'
            unFollow={this.props.unfollowInstructor}
            user={this.props.user} />
        <Container>
          <About instructor={instructor} />
          <Footer type='instructor'>
            <ProfileMapContainer type={profileMapTypes.INSTRUCTOR} />
          </Footer>
        </Container>

        { instructor.services && instructor.services.length &&
          <PrivateSessionModal
              createPrivateSession={this.props.createPrivateSession}
              instructor={instructor}
              isOpen={this.state.isPrivateModalOpen}
              onToggle={this.handlePrivateToggle}
              user={this.props.user} />
        }
      </div>
    );
  }
}

export default InstructorPage;
