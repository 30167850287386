import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { SelectPlaylistModal } from '../components/modals';
import { PlaylistActions } from '../actions';

class SelectPlaylistContainer extends React.PureComponent {
  static propTypes = {
    fetch:    PropTypes.func.isRequired,
    isOpen:   PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.fetch();
  }

  render() {
    return <SelectPlaylistModal {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.loader.playlists.isLoading,
  playlists: state.playlists.data
});

const mapDispatchToProps = (dispatch) => ({
  fetch:  () => dispatch(PlaylistActions.FETCH()),
  update: (data) => dispatch(PlaylistActions.UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectPlaylistContainer);
