import { PrivateSessionsActionTypes } from '../constants/actionTypes';

const initialState = {
  data:           [],
  instructorData: [],
  lastAction:     ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case PrivateSessionsActionTypes.CREATE:
      return newState;

    case PrivateSessionsActionTypes.CREATE_SUCCESS:
      return newState;

    case PrivateSessionsActionTypes.CREATE_FAILURE:
      return newState;


    case PrivateSessionsActionTypes.FETCH:
      return newState;

    case PrivateSessionsActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case PrivateSessionsActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case PrivateSessionsActionTypes.DELETE:
      return newState;

    case PrivateSessionsActionTypes.DELETE_SUCCESS:
      return newState;

    case PrivateSessionsActionTypes.DELETE_FAILURE:
      return newState;


    case PrivateSessionsActionTypes.INSTRUCTORS_CREATE:
      return newState;

    case PrivateSessionsActionTypes.INSTRUCTORS_CREATE_SUCCESS:
      return { ...newState, instructorData: [...state.instructorData, action.payload] };

    case PrivateSessionsActionTypes.INSTRUCTORS_CREATE_FAILURE:
      return newState;


    case PrivateSessionsActionTypes.INSTRUCTORS_DELETE:
      return newState;

    case PrivateSessionsActionTypes.INSTRUCTORS_DELETE_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case PrivateSessionsActionTypes.INSTRUCTORS_DELETE_FAILURE:
      return newState;


    case PrivateSessionsActionTypes.INSTRUCTORS_FETCH:
      return newState;

    case PrivateSessionsActionTypes.INSTRUCTORS_FETCH_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case PrivateSessionsActionTypes.INSTRUCTORS_FETCH_FAILURE:
      return { ...newState, instructorData: initialState.instructorData };


    case PrivateSessionsActionTypes.INSTRUCTORS_UPDATE:
      return newState;

    case PrivateSessionsActionTypes.INSTRUCTORS_UPDATE_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case PrivateSessionsActionTypes.INSTRUCTORS_UPDATE_FAILURE:
      return newState;


    case PrivateSessionsActionTypes.RECURRING_CREATE:
      return newState;

    case PrivateSessionsActionTypes.RECURRING_CREATE_SUCCESS:
      return { ...newState, instructorData: [...state.instructorData, ...action.payload] };

    case PrivateSessionsActionTypes.RECURRING_CREATE_FAILURE:
      return newState;


    case PrivateSessionsActionTypes.RECURRING_DELETE:
      return newState;

    case PrivateSessionsActionTypes.RECURRING_DELETE_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case PrivateSessionsActionTypes.RECURRING_DELETE_FAILURE:
      return newState;


    case PrivateSessionsActionTypes.UPDATE:
      return newState;

    case PrivateSessionsActionTypes.UPDATE_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case PrivateSessionsActionTypes.UPDATE_FAILURE:
      return newState;


    case PrivateSessionsActionTypes.RECURRING_UPDATE:
      return newState;

    case PrivateSessionsActionTypes.RECURRING_UPDATE_SUCCESS:
      return { ...newState, instructorData: action.payload };

    case PrivateSessionsActionTypes.RECURRING_UPDATE_FAILURE:
      return newState;


    default: return state;
  }
};
