import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { paintbrush } from 'react-icons-kit/ionicons/paintbrush';

const AppearanceIcon = ({ className, size }) => (
  <Icon
      className={className}
      icon={paintbrush}
      size={size} />
);

AppearanceIcon.propTypes = {
  className: PropTypes.string,
  size:      PropTypes.number
};

AppearanceIcon.defaultProps = {
  className: '',
  size:      32
};

export default AppearanceIcon;