export default {
  CLEAR: '@@privateBookings/CLEAR',

  CREATE:         '@@privateBookings/CREATE',
  CREATE_FAILURE: '@@privateBookings/CREATE_FAILURE',
  CREATE_SUCCESS: '@@privateBookings/CREATE_SUCCESS',

  DELETE:         '@@privateBookings/DELETE',
  DELETE_FAILURE: '@@privateBookings/DELETE_FAILURE',
  DELETE_SUCCESS: '@@privateBookings/DELETE_SUCCESS',

  FETCH:         '@@privateBookings/FETCH',
  FETCH_FAILURE: '@@privateBookings/FETCH_FAILURE',
  FETCH_SUCCESS: '@@privateBookings/FETCH_SUCCESS',

  INSTRUCTORS_CREATE:         '@@privateBookings/INSTRUCTORS/INSTRUCTORS_CREATE',
  INSTRUCTORS_CREATE_FAILURE: '@@privateBookings/INSTRUCTORS/INSTRUCTORS_CREATE_FAILURE',
  INSTRUCTORS_CREATE_SUCCESS: '@@privateBookings/INSTRUCTORS/INSTRUCTORS_CREATE_SUCCESS',

  INSTRUCTORS_DELETE:         '@@privateBookings/INSTRUCTORS/INSTRUCTORS_DELETE',
  INSTRUCTORS_DELETE_FAILURE: '@@privateBookings/INSTRUCTORS/INSTRUCTORS_DELETE_FAILURE',
  INSTRUCTORS_DELETE_SUCCESS: '@@privateBookings/INSTRUCTORS/INSTRUCTORS_DELETE_SUCCESS',

  INSTRUCTORS_FETCH:         '@@privateBookings/INSTRUCTORS/FETCH',
  INSTRUCTORS_FETCH_FAILURE: '@@privateBookings/INSTRUCTORS/FETCH_FAILURE',
  INSTRUCTORS_FETCH_SUCCESS: '@@privateBookings/INSTRUCTORS/FETCH_SUCCESS'
};
