/* global process */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { Col, Container, Media, Row } from 'reactstrap';
import { chunk } from 'lodash';

import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { routes } from '../../../lib';
import { BackButton } from '../../buttons';
import { Heading, Panel } from '../../layout/pages';
import { BillingPlan, BillingSubscription } from '../billing';

class BillingPage extends React.PureComponent {
  static propTypes = {
    createSubscription: PropTypes.func.isRequired,
    plans:              PropTypes.array.isRequired,
    subscription:       PropTypes.object.isRequired
  }

  render() {
    const { plans, subscription } = this.props;

    const stripePromise = () => (
      loadStripe(process.env.STRIPE_API_KEY)
    );

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Plans & Billing</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings billing'>
          <Container fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Plans & Billing' />

            <Panel className='settings-menu'>
              <Media className='menu-link d-block border-0'>
                <Media body>
                  <Elements
                      key='elements'
                      stripe={stripePromise()}>
                    <ElementsConsumer>
                      {({elements, stripe}) => (

                        <React.Fragment>
                          { subscription.id &&
                            <Row>
                              <Col
                                  lg={6}
                                  sm={12}>
                                <span className='font-weight-bold'>Your Subscription</span>
                                <BillingSubscription plan={subscription} />
                              </Col>
                            </Row>
                          }

                          <div className='mt-4'>
                            <b>All Plans</b>

                            {chunk(plans, 2).map((plansChunk, index) => (
                              <Row key={index}>
                                {plansChunk.map((plan) => (
                                  <Col
                                      key={plan.id}
                                      md='6'>
                                    <BillingPlan
                                        createSubscription={this.props.createSubscription}
                                        elements={elements}
                                        plan={plan}
                                        stripe={stripe}
                                        subscription={subscription} />
                                  </Col>
                                ))}
                              </Row>
                            ))}
                          </div>
                        </React.Fragment>
                      )}
                    </ElementsConsumer>
                  </Elements>
                </Media>
              </Media>
            </Panel>
          </Container>
        </div>

      </React.Fragment>
    );
  }
}

export default BillingPage;
