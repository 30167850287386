import React from 'react';
import { connect } from 'react-redux';

import { PasswordActions } from '../actions';
import { RecoverPasswordPage } from '../components/pages';

class RecoverPasswordContainer extends React.PureComponent {
  render() {
    return <RecoverPasswordPage {...this.props} />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  recoverPassword: (data) => dispatch(PasswordActions.RECOVER(data))
});

export default connect(null, mapDispatchToProps)(RecoverPasswordContainer);
