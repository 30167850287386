/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';

class Clipboard extends React.PureComponent {
  static propTypes = {
    children: PropTypes.string.isRequired,
    value:    PropTypes.string
  }

  static defaultProps = {
    value: ''
  }

  clipboard = {}

  handleCopy = (e) => {
    e.preventDefault();

    this.clipboard.focus();
    this.clipboard.select();
    this.clipboard.setSelectionRange(0, 99999);

    document.execCommand('copy');
    toastr.success('Copied to clipboard!');
  }

  render() {
    return (
      <span className='clipboard'>
        <Link
            onClick={this.handleCopy}
            to='#'>
          {this.props.children}
        </Link>

        <input
            defaultValue={this.props.value || this.props.children}
            ref={(clipboard) => this.clipboard = clipboard}
            type='text' />
      </span>
    );
  }
}

export default Clipboard;
