import { InstructorsActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       [],
  lastAction: '',
  meta:       {}
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case InstructorsActionTypes.GET_INSTRUCTORS:
      return { ...newState, data: action.payload, meta: action.meta };

    default: return state;
  }
};
