import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import { some } from 'lodash';

import { Button } from '../../forms';
import { PurchaseClassModal } from '../../modals';
import { Like, Paid, PlayIcon, UnLike } from '../../../images';
import { Moment, getTimeZone } from '../../../lib';

class RequestedClass extends React.PureComponent {
  static propTypes = {
    challenges:       PropTypes.array.isRequired,
    classPacks:       PropTypes.array.isRequired,
    createDiscount:   PropTypes.func.isRequired,
    discount:         PropTypes.object.isRequired,
    favorites:        PropTypes.array.isRequired,
    fetchClassPacks:  PropTypes.func.isRequired,
    fetchMemberships: PropTypes.func.isRequired,
    instructor:       PropTypes.object.isRequired,
    instructorPacks:  PropTypes.array.isRequired,
    like:             PropTypes.func.isRequired,
    loader:           PropTypes.object.isRequired,
    memberships:      PropTypes.array.isRequired,
    requestedClass:   PropTypes.object.isRequired,
    restoreDiscount:  PropTypes.func.isRequired,
    subscriptions:    PropTypes.array.isRequired,
    subtractCredit:   PropTypes.func.isRequired,
    unLike:           PropTypes.func.isRequired,
    user:             PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    const isLiked = some(props.favorites, (favorite) => (
      favorite.id == props.requestedClass.id
    ));

    return { isLiked };
  }

  state = {
    isLiked:     false,
    isModalOpen: false
  }

  handleToggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  handleLike = () => {
    const { id } = this.props.requestedClass;

    this.state.isLiked
      ? this.props.unLike({ id })
      : this.props.like({ instructorOnDemandId: id });
  }

  render() {
    const { instructor, requestedClass, user } = this.props;
    const { customization } = instructor;

    const createdAt = new Moment.utc(requestedClass.createdAt, 'YYYY-MM-DDhh:mm:ssUTC')
                                .tz(getTimeZone(user.timeZone));

    const src = requestedClass.poster;

    return (
      <React.Fragment>
        <Card
            className='card-event requested-class body-class__event'
            key={1}>
          <div
              className='image'
              style={{ backgroundImage: `url(${src})`, backgroundSize: 'cover' }}>
            { user.id &&
              <React.Fragment>
                <div
                    className='like-icon'
                    onClick={this.handleLike}
                    onKeyPress={this.handleLike}
                    role='button'
                    tabIndex={0}>
                  <img
                      alt='Icon'
                      className='pointer'
                      src={this.state.isLiked ? UnLike : Like} />
                </div>

                <div
                    className='play-icon'
                    onClick={this.handleToggle}
                    onKeyPress={this.handleToggle}
                    role='button'
                    tabIndex={0}>
                  <img
                      alt='Icon'
                      className='pointer'
                      src={PlayIcon} />
                </div>
              </React.Fragment>
            }
          </div>

          <div className='info-block'>
            <div className='info'>
              <p className='name'>{requestedClass.name}</p>
              <p className='uploaded'>Uploaded: {createdAt.format('MMM, DD, YYYY')}</p>
            </div>

            <div>
              <Paid fill={customization.secondaryButtonsBackgroundColor} />
              <Button
                  className='ml-2'
                  customization={customization}
                  onClick={this.handleToggle}
                  size='sm'>
                Watch
              </Button>
            </div>
          </div>

          <div className='description-block'>
            <p className='text'>{requestedClass.description}</p>
          </div>

        </Card>

        { this.state.isModalOpen &&
          <PurchaseClassModal
              challenges={this.props.challenges}
              classPacks={this.props.classPacks}
              createDiscount={this.props.createDiscount}
              discount={this.props.discount}
              fetchClassPacks={this.props.fetchClassPacks}
              fetchMemberships={this.props.fetchMemberships}
              instructor={this.props.instructor}
              instructorPacks={this.props.instructorPacks}
              isOpen={this.state.isModalOpen}
              loader={this.props.loader}
              memberships={this.props.memberships}
              onToggle={this.handleToggle}
              requestedClass={requestedClass}
              restoreDiscount={this.props.restoreDiscount}
              subscriptions={this.props.subscriptions}
              subtractCredit={this.props.subtractCredit}
              user={this.props.user} />
        }
      </React.Fragment>
    );
  }
}

export default RequestedClass;
