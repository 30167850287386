import { CouponsActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       [],
  form:       {},
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case CouponsActionTypes.CREATE:
      return newState;

    case CouponsActionTypes.CREATE_SUCCESS:
      return { ...newState, data: [...state.data, action.payload] };

    case CouponsActionTypes.CREATE_FAILURE:
      return newState;


    case CouponsActionTypes.DELETE:
      return newState;

    case CouponsActionTypes.DELETE_SUCCESS:
      return { ...newState, data: action.payload, form: {} };

    case CouponsActionTypes.DELETE_FAILURE:
      return newState;


    case CouponsActionTypes.FETCH:
      return newState;

    case CouponsActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case CouponsActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case CouponsActionTypes.UPDATE:
      return newState;

    case CouponsActionTypes.UPDATE_SUCCESS:
      return { ...newState, data: action.payload, form: {} };

    case CouponsActionTypes.UPDATE_FAILURE:
      return newState;


    case CouponsActionTypes.GET:
      return newState;

    case CouponsActionTypes.GET_SUCCESS:
      return { ...newState, form: action.payload };

    case CouponsActionTypes.GET_FAILURE:
      return newState;


    default: return state;
  }
};
