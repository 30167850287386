import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';

import { BookingScheduleModal } from '../modals';
import { ProfileMapContainer } from '../../containers';
import { profileMapTypes } from '../../constants';
import { Footer, Header } from './profile';
import { About } from './profile/location';

class LocationPage extends React.PureComponent {
  static propTypes = {
    createBooking:    PropTypes.func.isRequired,
    followLocation:   PropTypes.func.isRequired,
    goTo:             PropTypes.func.isRequired,
    groupClasses:     PropTypes.object.isRequired,
    location:         PropTypes.object.isRequired,
    session:          PropTypes.object.isRequired,
    unfollowLocation: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      location: props.location.data
    };
  }

  state = {
    isBookingModalOpen: false,
    isReviewModalOpen:  false,
    location:           {}
  };

  handleBookingToggle = () => {
    this.setState((prevState) => ({
      isBookingModalOpen: !prevState.isBookingModalOpen
    }));
  }

  handleReviewToggle = () => {
    this.setState((prevState) => ({
      isReviewModalOpen: !prevState.isReviewModalOpen
    }));
  }

  render() {
    const { location } = this.state;

    return (
      <div className='inner-wrapper location-page'>
        <Helmet>
          <title>{location.name}</title>
        </Helmet>

        <Header
            createBooking={this.props.createBooking}
            follow={this.props.followLocation}
            goTo={this.props.goTo}
            groupClasses={this.props.groupClasses.locationData}
            location={location}
            onBookingClick={this.handleBookingToggle}
            onCommentClick={this.handleReviewToggle}
            session={this.props.session}
            type='location'
            unFollow={this.props.unfollowLocation} />
        <Container className='container-profile'>
          <About location={location} />
          <Footer type='location'>
            <ProfileMapContainer type={profileMapTypes.LOCATION} />
          </Footer>
        </Container>

        <BookingScheduleModal
            createBooking={this.props.createBooking}
            groupClasses={this.props.groupClasses.locationData}
            hasLocation
            isOpen={this.state.isBookingModalOpen}
            onToggle={this.handleBookingToggle}>
          {location.name}&apos;s Schedule
        </BookingScheduleModal>
      </div>
    );
  }
}

export default LocationPage;
