import React from 'react';
import { Nav, NavItem } from 'reactstrap';
import { connect } from 'react-redux';

import { routes } from '../../../../lib';
import { Link } from '../../../inputs';

class SignMenu extends React.PureComponent {
  render () {
    return (
      <Nav navbar>
        <NavItem>
          <Link
              className='nav-link'
              to={routes.LOGIN}>
            Log In
          </Link>
        </NavItem>

        <NavItem>
          <Link
              className='nav-link'
              to={routes.SIGNUP}>
            Sign Up
          </Link>
        </NavItem>
      </Nav>
    );
  }
}

const mapStateToProps = (state) => ({
  hasCustomDomain: state.app.hasCustomDomain
});

export default connect(mapStateToProps)(SignMenu);