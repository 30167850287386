import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';

import { routes } from '../../lib';
import { Link } from '../inputs';

class PoweredByBadge extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string
  }

  static defaultProps = {
    className: ''
  }

  render () {
    return (
      <div className={this.props.className}>
        <Link to={routes.HOME}>
          <Badge
              className='badge-powered'
              color='light' >
            Powered by Recess.tv
          </Badge>
        </Link>
      </div>
    );
  }
}

export default PoweredByBadge;
