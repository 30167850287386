import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';

import { TimeZone } from '../';
import { CollectionCollapse } from '../../../onDemands';

class RequestedClassesCarousel extends React.PureComponent {
  static propTypes = {
    challenges:       PropTypes.array.isRequired,
    classPacks:       PropTypes.array.isRequired,
    createDiscount:   PropTypes.func.isRequired,
    discount:         PropTypes.object.isRequired,
    favorites:        PropTypes.array.isRequired,
    fetchClassPacks:  PropTypes.func.isRequired,
    fetchMemberships: PropTypes.func.isRequired,
    instructor:       PropTypes.object.isRequired,
    instructorPacks:  PropTypes.array.isRequired,
    like:             PropTypes.func.isRequired,
    loader:           PropTypes.object.isRequired,
    memberships:      PropTypes.array.isRequired,
    requestedClasses: PropTypes.array.isRequired,
    restoreDiscount:  PropTypes.func.isRequired,
    subscriptions:    PropTypes.array.isRequired,
    subtractCredit:   PropTypes.func.isRequired,
    theme:            PropTypes.string,
    unLike:           PropTypes.func.isRequired,
    user:             PropTypes.object.isRequired
  };

  static defaultProps = {
    theme: 'default'
  }

  static getDerivedStateFromProps(props) {
    const instructorPacks = filter(props.classPacks, ['instructor.id', props.instructor.id]);
    const classPacks = filter(instructorPacks, (classPack) => (
      classPack.onDemand > 0 || classPack.combined > 0
    ));

    const challenges = filter(props.challenges, {'instructorId': props.instructor.id, 'onDemand': true});

    return { challenges, classPacks };
  }

  state = {
    challenges: [],
    classPacks: []
  }

  render() {
    return (
      <div className='group__classes'>
        <TimeZone timeZone={this.props.user.timeZone} />

        { this.props.requestedClasses.map((collection) => (
          <CollectionCollapse
              challenges={this.state.challenges}
              classPacks={this.state.classPacks}
              collection={collection}
              createDiscount={this.props.createDiscount}
              discount={this.props.discount}
              favorites={this.props.favorites}
              fetchClassPacks={this.props.fetchClassPacks}
              fetchMemberships={this.props.fetchMemberships}
              instructor={this.props.instructor}
              instructorPacks={this.props.instructorPacks}
              key={collection.position}
              like={this.props.like}
              loader={this.props.loader}
              memberships={this.props.memberships}
              restoreDiscount={this.props.restoreDiscount}
              subscriptions={this.props.subscriptions}
              subtractCredit={this.props.subtractCredit}
              template={this.props.theme}
              unLike={this.props.unLike}
              user={this.props.user} />
        ))}
      </div>
    );
  }
}

export default RequestedClassesCarousel;
