import { connect } from 'react-redux';
import { DomainActions, SessionActions } from '../../../../../actions';

import UserMenu from './userMenu';

const mapStateToProps = (state) => ({
  domain:          state.domain.data,
  hasCustomDomain: state.app.hasCustomDomain,
  user:            state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  getDomain: () => dispatch(DomainActions.GET()),
  logOut:    () => dispatch(SessionActions.LOGOUT())
});

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);