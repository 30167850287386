import { connect } from 'react-redux';
import { TransactionsActions } from '../../../../actions';

import ClassPacksTable from './classPacksTable';

const mapStateToProps = (state) => ({
  coupons:      state.coupons.data,
  isLoading:    state.transactions.isLoading,
  transactions: state.transactions.classPacks
});

const mapDispatchToProps = (dispatch) => ({
  fetchClassPacks: ()     => dispatch(TransactionsActions.CLASS_PACKS_FETCH()),
  updateClassPack: (data) => dispatch(TransactionsActions.CLASS_PACK_UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassPacksTable);
