import { SamplesActionTypes } from '../constants/actionTypes';

const initialState = {
  challenges:          [],
  classPacks:          [],
  memberships:         [],
  onDemands:           [],
  privateGroupClasses: []
};

export default (state = initialState, action) => {
  const newState = {
    ...state
  };

  switch (action.type) {
    case SamplesActionTypes.DELETE_MEMBERSHIP:
      return newState;

    case SamplesActionTypes.DELETE_MEMBERSHIP_SUCCESS:
      return { ...newState, memberships: action.payload };

    case SamplesActionTypes.DELETE_MEMBERSHIP_FAILURE:
      return newState;

    case SamplesActionTypes.DELETE_CHALLENGE:
      return newState;

    case SamplesActionTypes.DELETE_CHALLENGE_SUCCESS:
      return { ...newState, challenges: action.payload };

    case SamplesActionTypes.DELETE_CHALLENGE_FAILURE:
      return newState;

    case SamplesActionTypes.DELETE_CLASS_PACK:
      return newState;

    case SamplesActionTypes.DELETE_CLASS_PACK_SUCCESS:
      return { ...newState, classPacks: action.payload };

    case SamplesActionTypes.DELETE_CLASS_PACK_FAILURE:
      return newState;

    case SamplesActionTypes.FETCH_PRIVATE_GROUP_CLASSES:
      return newState;

    case SamplesActionTypes.FETCH_PRIVATE_GROUP_CLASSES_SUCCESS:
      return { ...newState, privateGroupClasses: action.payload };

    case SamplesActionTypes.FETCH_PRIVATE_GROUP_CLASSES_FAILURE:
      return newState;

    case SamplesActionTypes.FETCH_CLASS_PACKS:
      return newState;

    case SamplesActionTypes.FETCH_CLASS_PACKS_SUCCESS:
      return { ...newState, classPacks: action.payload };

    case SamplesActionTypes.FETCH_CLASS_PACKS_FAILURE:
      return newState;

    case SamplesActionTypes.FETCH_CHALLENGES:
      return newState;

    case SamplesActionTypes.FETCH_CHALLENGES_SUCCESS:
      return { ...newState,  challenges: action.payload };

    case SamplesActionTypes.FETCH_CHALLENGES_FAILURE:
      return newState;

    case SamplesActionTypes.FETCH_ON_DEMANDS:
      return newState;

    case SamplesActionTypes.FETCH_ON_DEMANDS_SUCCESS:
      return { ...newState, onDemands: action.payload };

    case SamplesActionTypes.FETCH_ON_DEMANDS_FAILURE:
      return newState;

    case SamplesActionTypes.FETCH_MEMBERSHIPS:
      return newState;

    case SamplesActionTypes.FETCH_MEMBERSHIPS_SUCCESS:
      return { ...newState, memberships: action.payload };

    case SamplesActionTypes.FETCH_MEMBERSHIPS_FAILURE:
      return newState;

    default: return state;
  }
};
