import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { find, isEmpty, sortBy } from 'lodash';

import { PartnerActions } from '../../actions';
import { Select } from '../forms';
import { selectFieldStyle } from '../../constants';

class PartnersSelect extends PureComponent {
  static propTypes = {
    fetchPartners: PropTypes.func.isRequired,
    hasPreview:    PropTypes.bool,
    isInvalid:     PropTypes.bool,
    isLoading:     PropTypes.bool.isRequired,
    name:          PropTypes.string,
    onChange:      PropTypes.func,
    partners:      PropTypes.array.isRequired,
    value:         PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }

  static defaultProps = {
    hasPreview: false,
    isInvalid:  false,
    name:       '',
    onChange:   () => {},
    value:      ''
  }

  static getDerivedStateFromProps(props) {
    const partners = sortBy(props.partners, 'name');

    const options = partners.map((partner) => (
      { label: partner.name, value: partner.id }
    ));

    if (props.value) {
      const selectedPartner = find(props.partners, { id: props.value });

      return { options, selectedPartner };
    }

    return { options };
  }

  componentDidMount () {
    this.props.fetchPartners();
  }

  handleChange = (value, inputName) => {
    const selectedPartner = find(this.props.partners, { id: value });

    this.setState({ selectedPartner }, () => {
      this.props.onChange(value, inputName);
    });
  }

  state = {
    options:         [],
    selectedPartner: {}
  }

  render () {
    const { hasPreview, isLoading, name, value, isInvalid } = this.props;
    const { options, selectedPartner } = this.state;

    return (
      <div className='select-partner'>
        <Select
            isInvalid={isInvalid}
            isLoading={isLoading}
            name={name}
            onChange={this.handleChange}
            options={options}
            styles={selectFieldStyle.small}
            value={value} />
        { hasPreview && !isEmpty(selectedPartner) &&
          <div className='partner-logo'>
            <img
                alt={selectedPartner.name}
                className='img-fluid'
                src={selectedPartner.logo} />
          </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.partners.isLoading,
  partners:  state.partners.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchPartners: () => dispatch(PartnerActions.FETCH())
});

export default connect(mapStateToProps, mapDispatchToProps)(PartnersSelect);
