export default (error) => {
  if (error.response) {
    if (error.response.data.message) {
      return { message: error.response.data.message, status: error.response.status };
    } else {
      return { message: error.response.statusText, status: error.response.status };
    }
  } else {
    return { message: error.message, status: error.status };
  }
};
