import { RecordingsActionTypes } from '../constants/actionTypes';

export default {
  CREATE:         (payload) => ({ payload, type: RecordingsActionTypes.CREATE }),
  CREATE_FAILURE: ()        => ({ type: RecordingsActionTypes.CREATE_FAILURE }),
  CREATE_SUCCESS: (payload) => ({ payload, type: RecordingsActionTypes.CREATE_SUCCESS }),

  FETCH:         ()        => ({ type: RecordingsActionTypes.FETCH }),
  FETCH_FAILURE: ()        => ({ type: RecordingsActionTypes.FETCH_FAILURE }),
  FETCH_SUCCESS: (payload) => ({ payload, type: RecordingsActionTypes.FETCH_SUCCESS })
};
