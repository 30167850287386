import React from 'react';
import PropTypes from 'prop-types';
import { Label, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { some } from 'lodash';

import { Moment } from '../../../lib';
import { Button, Checkbox } from '../../forms';
import { BookingTermsModal } from '..';

class SignUp extends React.PureComponent {
  static propTypes = {
    instructor:    PropTypes.object.isRequired,
    isLoading:     PropTypes.bool,
    membership:    PropTypes.object.isRequired,
    onClick:       PropTypes.func.isRequired,
    subscriptions: PropTypes.array.isRequired
  }

  static defaultProps = {
    isLoading: false
  }

  state = {
    isBookingTermsOpen: false,
    isTermsChecked:     false
  }

  handleChange = (value, inputName) => (
    this.setState({ [inputName]: value })
  )

  handleModal = (modal) => (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ [modal]: !prevState[modal] }));
  }

  render() {
    const { subscriptions, membership } = this.props;

    const isSubscribed = some(subscriptions, (subscription) => (
      subscription.membership.id == membership.id &&
      subscription.status == 'active' &&
      new Moment() < new Moment.utc(subscription.endsAt, 'YYYY-MM-DDhh:mm:ssUTC')
    ));

    const isOwn = !window.location.origin.includes('localhost') &&
                  this.props.instructor.isOwner;

    return (
      <React.Fragment>
        <ModalFooter>
          <div className='event-terms'>
            <div className='custom-control custom-checkbox checkbox-md'>
              <Checkbox
                  className='custom-control-input'
                  id='isTermsChecked'
                  isChecked={this.state.isTermsChecked}
                  name='isTermsChecked'
                  onChange={this.handleChange} />
              <Label
                  className='custom-control-label text-left'
                  for='input-isTermsChecked'>
                I have read and agree to the terms of the&nbsp;
                <Link
                    className='text-primary'
                    onClick={this.handleModal('isBookingTermsOpen')}
                    to='#'>
                  Waiver & Release
                </Link>
              </Label>
            </div>
          </div>
          { !isSubscribed &&
            <Button
                color='blue'
                isBlock
                isDisabled={!this.state.isTermsChecked || isOwn}
                isLoading={this.props.isLoading}
                onClick={this.props.onClick}
                size='lg'>
              {membership.trial ? 'Start your trial' : 'Join membership'}
            </Button>
          }

          { isSubscribed &&
            <Button
                color='dark'
                isBlock
                isDisabled
                size='lg'>
              Already a member
            </Button>
          }
        </ModalFooter>

        <div className='modals'>
          { this.state.isBookingTermsOpen &&
            <BookingTermsModal
                instructor={this.props.instructor}
                isOpen={this.state.isBookingTermsOpen}
                onToggle={this.handleModal('isBookingTermsOpen')} />
          }
        </div>
      </React.Fragment>
    );
  }
}

export default SignUp;
