import { SearchActionTypes } from '../constants/actionTypes';

export default {
  SEARCH: (payload) => ({
    payload,
    type: SearchActionTypes.SEARCH
  }),

  SEARCH_FAILURE: () => ({
    type: SearchActionTypes.SEARCH_FAILURE
  }),

  SEARCH_SUCCESS: (payload) => ({
    payload,
    type: SearchActionTypes.SEARCH_SUCCESS
  })
};
