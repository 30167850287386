import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames';

import {
  AddGroupClassModal,
  AdvancedPrivateSessionModal
} from '../../../modals';
import { PrivateGroupClassModal, TimeBlockModal } from '../../../../containers/events';
import MainButton from './mainButton';
import MenuItem from './menuItem';

class FloatMenu extends React.PureComponent {
  static propTypes = {
    createGroupClass:              PropTypes.func.isRequired,
    createPrivateSession:          PropTypes.func.isRequired,
    createRecurringGroupClass:     PropTypes.func.isRequired,
    createRecurringPrivateSession: PropTypes.func.isRequired,
    fetchPlaylists:                PropTypes.func.isRequired,
    formats:                       PropTypes.array.isRequired,
    instructor:                    PropTypes.object.isRequired,
    playlists:                     PropTypes.array.isRequired,
    user:                          PropTypes.object.isRequired
  };

  state = {
    isGroupClassModalOpen:        false,
    isMenuOpen:                   false,
    isPrivateGroupClassModalOpen: false,
    isPrivateSessionModalOpen:    false,
    isTimeBlockModalOpen:         false
  }

  handleToggle = (modalName) => () => {
    this.setState((prevState) => ({
      [modalName]: !prevState[modalName]
    }));

    this.handleClose();
  };

  handleOpen = () => {
    this.setState({
      isMenuOpen: true
    });
  };

  handleClose = () => {
    this.setState({
      isMenuOpen: false
    });
  };

  render () {
    const { isMenuOpen } = this.state;

    return (
      <React.Fragment>
        <div className={className('float-menu', { 'open': isMenuOpen })}>
          <MenuItem
              name='Block off time'
              onClick={this.handleToggle('isTimeBlockModalOpen')} />

          <MenuItem
              name='New Private Session'
              onClick={this.handleToggle('isPrivateSessionModalOpen')} />

          <MenuItem
              name='New Studio Group Class'
              onClick={this.handleToggle('isGroupClassModalOpen')} />

          <MenuItem
              name='New In-Person / Virtual Group Class'
              onClick={this.handleToggle('isPrivateGroupClassModalOpen')} />

          <MainButton onClick={isMenuOpen ? this.handleClose : this.handleOpen} />
        </div>

        <div className='modals'>
          { this.state.isGroupClassModalOpen &&
            <AddGroupClassModal
                createGroupClass={this.props.createGroupClass}
                createRecurringGroupClass={this.props.createRecurringGroupClass}
                fetchPlaylists={this.props.fetchPlaylists}
                instructor={this.props.instructor}
                isOpen={this.state.isGroupClassModalOpen}
                onToggle={this.handleToggle('isGroupClassModalOpen')}
                playlists={this.props.playlists}
                user={this.props.user} />
          }


          { this.state.isPrivateSessionModalOpen &&
            <AdvancedPrivateSessionModal
                createPrivateSession={this.props.createPrivateSession}
                createRecurringPrivateSession={this.props.createRecurringPrivateSession}
                instructor={this.props.instructor}
                isNewRecord
                isOpen={this.state.isPrivateSessionModalOpen}
                onToggle={this.handleToggle('isPrivateSessionModalOpen')}
                user={this.props.user} />
          }

          { this.state.isPrivateGroupClassModalOpen &&
            <PrivateGroupClassModal
                formats={this.props.formats}
                isOpen={this.state.isPrivateGroupClassModalOpen}
                onToggle={this.handleToggle('isPrivateGroupClassModalOpen')} />
          }

          { this.state.isTimeBlockModalOpen &&
            <TimeBlockModal
                isOpen={this.state.isTimeBlockModalOpen}
                onToggle={this.handleToggle('isTimeBlockModalOpen')} />
          }
        </div>
      </React.Fragment>
    );
  }
}

export default FloatMenu;
