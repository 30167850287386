/* global process */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable immutable/no-mutation */
import './stylesheets/styles';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, ReactReduxContext } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ActionCableProvider } from 'react-actioncable-provider';
import { CookiesProvider } from 'react-cookie';
import moment from 'moment';
import { extendMoment } from 'moment-range';

import { configureStore } from './store';
import { App } from './components';
import './lib/icons';

const { persistor, store } = configureStore();


const { WS_URL } = process.env;
const { HOST } = process.env;

console.warn('Use window.persistor.purge() to clear state in case of redux errors');
window.persistor = persistor;
window.moment = extendMoment(moment);

class Application extends React.Component {
  render() {
    const hasCustomDomain = window.location.host !== HOST;

    return (
      <ActionCableProvider url={hasCustomDomain ? `wss://${window.location.host}/cable` : WS_URL}>
        <CookiesProvider>
          <Provider
              context={ReactReduxContext}
              store={store}>
            <PersistGate persistor={persistor}>
              {(bootstraped) => (
                <App isBootstraped={bootstraped} />
              )}
            </PersistGate>
          </Provider>
        </CookiesProvider>
      </ActionCableProvider>
    );
  }
}

ReactDOM.render(
  <Application />,
  document.getElementById('app')
);
