/* eslint-disable react/jsx-handler-names */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Label, Tooltip } from 'reactstrap';

class LabelHelp extends React.PureComponent {
  static propTypes = {
    children:    PropTypes.node.isRequired,
    className:   PropTypes.string,
    for:         PropTypes.string,
    tooltipText: PropTypes.string.isRequired
  }

  static defaultProps = {
    className: '',
    for:       ''
  }

  state = {
    tooltipOpen: false
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      tooltipOpen: !prevState.tooltipOpen
    }));
  }

  render () {
    return (
      <React.Fragment>
        <Label
            className={classNames(this.props.className, 'help-tooltip')}
            htmlFor={this.props.for}
            id='labelTooltip'>
          {this.props.children}
        </Label>
        <Tooltip
            isOpen={this.state.tooltipOpen}
            placement='top-end'
            target='labelTooltip'
            toggle={this.handleToggle}
            trigger='hover'>
          {this.props.tooltipText}
        </Tooltip>
      </React.Fragment>
    );
  }
}

export default LabelHelp;