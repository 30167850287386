import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';

import { LocationInstructors } from '../index';
import { InstructorsMobile } from './';

class About extends React.PureComponent {
  static propTypes = {
    location: PropTypes.object.isRequired
  }

  render() {
    const { location } = this.props;

    return (
      <section className='profile-about no-gutters'>
        <Col
            className='profile-contacts studio'
            lg={5}
            md={12}>
          <div className='section-header'>
            <h3>Contact Details</h3>
          </div>
          <div className='section-content'>
            <Row>
              <Col
                  className='studio-contacts d-flex'
                  lg={9}>
                <ul className='pl-0'>
                  { location.address &&
                    <li className='mb-lg-5'>
                      <h5>Address</h5>
                      <address className='text-muted'>
                        <span>{location.address}</span>
                        <span>{location.position}</span>
                      </address>
                    </li>
                  }
                  { location.phone &&
                    <li className='mb-lg-5'>
                      <h5>Phone</h5>
                      <a
                          className='text-gray-00 text-decoration-none instructor-btn-color'
                          href={'tel:' + location.phone}>
                        <span className='text-muted'>{location.phone}</span>
                      </a>
                    </li>
                  }
                  { location.email &&
                    <li className='mb-lg-5'>
                      <h5>E-mail</h5>
                      <a
                          className='text-gray-00 text-decoration-none instructor-btn-color'
                          href={'mailto:' + location.email}>
                        <span className='text-muted'>{location.email}</span>
                      </a>
                    </li>
                  }
                </ul>
                <ul className='pl-2'>
                  { location.website &&
                    <li className='mb-lg-5'>
                      <h5>Website</h5>
                      <a
                          className='text-gray-00 text-decoration-none instructor-btn-color'
                          href={location.website}
                          rel='noopener noreferrer'
                          target='_blank'>
                        <span className='text-muted'>
                          Visit website
                          <Icon
                              className='ml-1'
                              icon={['fas', 'external-link-alt']}
                              size='1x' />
                        </span>
                      </a>
                    </li>
                  }
                  <li className='social-links mb-lg-5'>
                    <h5>Social profiles</h5>
                    <div className='d-flex'>
                      { location.socialProfiles.facebook &&
                        <a
                            className='text-blue text-md text-decoration-none instructor-btn-color'
                            href={location.socialProfiles.facebook}
                            rel='noopener noreferrer'
                            target='_blank'>
                          <Icon
                              className='mr-1 text-muted'
                              fixedWidth
                              icon={['fab', 'facebook']}
                              size='1x' />
                        </a>
                      }

                      { location.socialProfiles.twitter &&
                        <a
                            className='text-md text-decoration-none instructor-btn-color'
                            href={location.socialProfiles.twitter}
                            rel='noopener noreferrer'
                            target='_blank'>
                          <Icon
                              className='mr-1 text-muted'
                              fixedWidth
                              icon={['fab', 'twitter']}
                              size='1x' />
                        </a>
                      }

                      { location.socialProfiles.instagram &&
                        <a
                            className='text-md text-decoration-none instructor-btn-color'
                            href={location.socialProfiles.instagram}
                            rel='noopener noreferrer'
                            target='_blank'>
                          <Icon
                              className='mr-1 text-muted'
                              fixedWidth
                              icon={['fab', 'instagram']}
                              size='1x' />
                        </a>
                      }

                      { location.socialProfiles.linkedin &&
                        <a
                            className='text-md text-decoration-none instructor-btn-color'
                            href={location.socialProfiles.linkedin}
                            rel='noopener noreferrer'
                            target='_blank'>
                          <Icon
                              className='mr-1 text-muted'
                              fixedWidth
                              icon={['fab', 'linkedin']}
                              size='1x' />
                        </a>
                      }
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Col>
        <Col
            className='profile-instructors'
            lg={7}
            md={12}>
          <div className='section-header flex-column'>
            <h3>Instructors</h3>
            <p className='section-subtitle'>Can&apos;t find your favorite instructors? Let them know</p>
          </div>
          <div className='section-content'>
            { !isMobile &&
              <LocationInstructors
                  instructors={location.instructors}
                  scrapperInstructors={location.scrapperInstructors} />
            }
            { isMobile &&
              <InstructorsMobile
                  instructors={location.instructors}
                  scrapperInstructors={location.scrapperInstructors} />
            }
          </div>
        </Col>
      </section>
    );
  }
}

export default About;
