import { connect } from 'react-redux';

import { CollectionActions, RecordingsActions } from '../../../actions';
import RequestedClass from './requestedClass';

const mapStateToProps = (state) => ({
  collection:     state.collections.collection,
  collections:    state.collections.data,
  instructor:     state.profile.data,
  isLoading:      state.loader.recordings.isLoading,
  recording:      state.recordings.form,
  recordings:     state.recordings.data,
  requestedClass: state.requestedClasses.form,
  user:           state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createRecordings:     (data) => dispatch(RecordingsActions.CREATE(data)),
  createRequestedClass: (data) => dispatch(CollectionActions.ON_DEMAND_CREATE(data)),
  deleteRequestedClass: (data) => dispatch(CollectionActions.ON_DEMAND_DELETE(data)),
  fetchRecordings:      ()     => dispatch(RecordingsActions.FETCH()),
  updateRequestedClass: (data) => dispatch(CollectionActions.ON_DEMAND_UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestedClass);
