import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { filter, map } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { api, extractError, routes, toCamelCase } from '../lib';
import { PrivateSessionTypesActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions, PrivateSessionTypeActions, PrivateSessionTypesActions } from '../actions';

function* watchCreate() {
  yield takeLatest(PrivateSessionTypesActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.privateSessionTypes.create, payload);
      yield put(PrivateSessionTypesActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Session created');
    } catch (error) {
      yield put(PrivateSessionTypesActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(PrivateSessionTypesActionTypes.CREATE_SUCCESS, function* () {
    yield put(push(routes.SESSION_TYPES));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(PrivateSessionTypesActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDelete() {
  yield takeLatest(PrivateSessionTypesActionTypes.DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.privateSessionTypes.delete, payload);

      const privateSessionTypes = yield select((state) => state.privateSessionTypes.data);
      const data = filter(privateSessionTypes, (privateSessionType) => (
        privateSessionType.id != payload.privateSessionType.id
      ));

      yield put(PrivateSessionTypesActions.DELETE_SUCCESS(data));
      toastr.success('Session deleted');
    } catch (error) {
      yield put(PrivateSessionTypesActions.DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDeleteSuccess() {
  yield takeLatest(PrivateSessionTypesActionTypes.DELETE_SUCCESS, function* () {
    yield put(push(routes.SESSION_TYPES));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDeleteFailure() {
  yield takeLatest(PrivateSessionTypesActionTypes.DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetch() {
  yield takeLatest(PrivateSessionTypesActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.privateSessionTypes.fetch);

      yield put(PrivateSessionTypesActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PrivateSessionTypesActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(PrivateSessionTypesActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(PrivateSessionTypesActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdate() {
  yield takeLatest(PrivateSessionTypesActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.privateSessionTypes.update, payload);
      const privateSessionTypes = yield select((state) => state.privateSessionTypes.data);
      const data = map(privateSessionTypes, (privateSessionType) => (
        privateSessionType.id == payload.privateSessionType.id ? toCamelCase(response.data) : privateSessionType
      ));

      yield put(PrivateSessionTypesActions.UPDATE_SUCCESS(data));
      yield put(PrivateSessionTypeActions.UPDATE_SUCCESS(payload));
      toastr.success('Session updated');
    } catch (error) {
      yield put(PrivateSessionTypesActions.UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(PrivateSessionTypesActionTypes.UPDATE_SUCCESS, function* () {
    yield put(push(routes.SESSION_TYPES));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateFailure() {
  yield takeLatest(PrivateSessionTypesActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchGet() {
  yield takeLatest(PrivateSessionTypesActionTypes.GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('sessionTypes'));
      const response = yield call(api.privateSessionTypes.get, payload);

      yield put(PrivateSessionTypesActions.GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PrivateSessionTypesActions.GET_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchGetSuccess() {
  yield takeLatest(PrivateSessionTypesActionTypes.GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('sessionTypes'));
  });
}

function* watchGetFailure() {
  yield takeLatest(PrivateSessionTypesActionTypes.GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('sessionTypes'));
  });
}

export default function* privateSessionTypesSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),
    watchDelete(),
    watchDeleteSuccess(),
    watchDeleteFailure(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure(),
    watchGet(),
    watchGetSuccess(),
    watchGetFailure()
  ]);
}
