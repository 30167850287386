import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, errorWithStatus, toCamelCase } from '../lib';
import { InstagramActionTypes } from '../constants/actionTypes';
import { ErrorActions, InstagramActions, InstructorActions, LoaderActions } from '../actions';

export function* watchFetch() {
  yield takeLatest(InstagramActionTypes.FETCH, function* ({ payload }) {
    try {
      yield put(ErrorActions.CLEAR_API('instagram'));
      yield put(LoaderActions.START_FIELD_LOADING('instagram'));

      const response = yield call(api.instructor.instagram.get, payload);

      yield put(InstagramActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(InstagramActions.FETCH_FAILURE());
      yield put(InstructorActions.DISCONNECT_INSTAGRAM());
      yield put(ErrorActions.NEW_API({ error: errorWithStatus(error), name: 'instagram' }));
    }
  });
}

export function* watchFetchSuccess() {
  yield takeLatest(InstagramActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('instagram'));
  });
}

export function* watchFetchFailure() {
  yield takeLatest(InstagramActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('instagram'));
  });
}

export default function* instagramSaga() {
  yield all([
    watchFetch(),
    watchFetchFailure(),
    watchFetchSuccess()
  ]);
}
