import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

class Loader extends React.PureComponent {
  static propTypes = {
    animationName:    PropTypes.string,
    animationTimeout: PropTypes.number,
    color:            PropTypes.string,
    hasOpacity:       PropTypes.bool,
    isFullscreen:     PropTypes.bool,
    isLoading:        PropTypes.bool,
    isTransparent:    PropTypes.bool,
    size:             PropTypes.string,
    type:             PropTypes.string
  }

  static defaultProps = {
    animationName:    'loader',
    animationTimeout: 700,
    color:            'primary',
    hasOpacity:       true,
    isFullscreen:     false,
    isLoading:        false,
    isTransparent:    false,
    size:             '6.5rem',
    type:             'grow'
  }

  render() {
    const size = {
      height: this.props.size,
      width:  this.props.size
    };

    const { animationName, animationTimeout, isFullscreen, hasOpacity, isLoading, isTransparent } = this.props;

    const loaderClass = classNames('loader', {
      'loader-overlay': !hasOpacity
    }, {
      'background-transparent': isTransparent
    }, {
      'loader-overlay-opacity': hasOpacity
    }, {
      'fullscreen': isFullscreen
    });

    return (
      <CSSTransition
          classNames={animationName}
          in={isLoading}
          timeout={animationTimeout}
          unmountOnExit>
        <div className={loaderClass}>
          <Spinner
              color={this.props.color}
              style={size}
              type={this.props.type} />
        </div>
      </CSSTransition>
    );
  }
}

export default Loader;
