import React from 'react';
import PropTypes from 'prop-types';
import { Container, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { filter, map, sortBy } from 'lodash';

import { BackButton } from '../buttons';
import { Button } from '../forms';
import { Heading, Panel } from '../layout/pages';
import { routes } from '../../lib';
import { EMAIL_TEMPLATES } from '../../constants';
import { WinBackModal } from '../modals/emails';
import MainButton from '../pages/portal/menu/mainButton';


class WinBackSequencePage extends React.PureComponent {
  static propTypes = {
    createEmailTemplate: PropTypes.func.isRequired,
    emailTemplates:      PropTypes.array.isRequired,
    isLoading:           PropTypes.bool.isRequired,
    updateEmailTemplate: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props) {
    const emailTemplates = filter(props.emailTemplates, ['name', EMAIL_TEMPLATES.winBackSequence]);

    return { winBackTemplates: sortBy(emailTemplates, 'sequence') };
  }

  state = {
    selectedTemplate: {},
    winBackModal:     false,
    winBackTemplates: []
  }

  handleModal = (isNew, selectedTemplate = {}) => () =>  (
    this.setState((prevState) => ({
      isNew,
      selectedTemplate,
      winBackModal: !prevState.winBackModal
    }))
  );

  renderTemplate = (template) => (
    <Media className='menu-link'>
      <Media body>
        <div className='menu-title'>
          {template.subject}
        </div>

        <div className='menu-description text-muted'>
          Send after <u>{template.sequence} days</u> without booking
        </div>
      </Media>

      <Media right>
        <Button
            isOutline
            onClick={this.handleModal(false, template)}
            size='sm'>
          Edit
        </Button>
      </Media>
    </Media>
  );

  render() {
    return (
      <React.Fragment>
        <Helmet>
          Win-Back Emails
        </Helmet>

        {isMobile && <BackButton to={routes.MARKETING} />}

        <div className='inner-wrapper portal marketing'>
          <Container fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Win-Back Emails' />

            <Panel className='setting-menu'>
              <div className='description text-muted'>
                Automatically send “win-back” emails to clients
                if they haven’t booked a class after a certain period of time.
                You could give them a simple reminder, nudge or even include a coupon code!
              </div>

              <h4 className='menu-title'>
                Email Sequence
              </h4>

              {map(this.state.winBackTemplates, this.renderTemplate)}
            </Panel>

            <MainButton onClick={this.handleModal(true)} />
          </Container>
        </div>

        { this.state.winBackModal &&
          <WinBackModal
              createEmailTemplate={this.props.createEmailTemplate}
              emailTemplate={this.state.selectedTemplate}
              isLoading={this.props.isLoading}
              isNew={this.state.isNew}
              isOpen={this.state.winBackModal}
              onToggle={this.handleModal('winBackModal')}
              updateEmailTemplate={this.props.updateEmailTemplate} />
        }
      </React.Fragment>
    );
  }
}

export default WinBackSequencePage;
