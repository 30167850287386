export default {
  CREATE:         '@@privateGroupClasses/CREATE',
  CREATE_FAILURE: '@@privateGroupClasses/CREATE_FAILURE',
  CREATE_SUCCESS: '@@privateGroupClasses/CREATE_SUCCESS',

  DELETE:         '@@privateGroupClasses/DELETE',
  DELETE_FAILURE: '@@privateGroupClasses/DELETE_FAILURE',
  DELETE_SUCCESS: '@@privateGroupClasses/DELETE_SUCCESS',

  FETCH:         '@@privateGroupClasses/FETCH',
  FETCH_FAILURE: '@@privateGroupClasses/FETCH_FAILURE',
  FETCH_SUCCESS: '@@privateGroupClasses/FETCH_SUCCESS',

  INSTRUCTORS_FETCH:         '@@privateGroupClasses/INSTRUCTORS/FETCH',
  INSTRUCTORS_FETCH_FAILURE: '@@privateGroupClasses/INSTRUCTORS/FETCH_FAILURE',
  INSTRUCTORS_FETCH_SUCCESS: '@@privateGroupClasses/INSTRUCTORS/FETCH_SUCCESS',

  INSTRUCTORS_GET:         '@@privateGroupClasses/INSTRUCTORS/GET',
  INSTRUCTORS_GET_FAILURE: '@@privateGroupClasses/INSTRUCTORS/GET_FAILURE',
  INSTRUCTORS_GET_SUCCESS: '@@privateGroupClasses/INSTRUCTORS/GET_SUCCESS',

  INSTRUCTORS_UPDATE:         '@@privateGroupClasses/INSTRUCTORS_UPDATE',
  INSTRUCTORS_UPDATE_FAILURE: '@@privateGroupClasses/INSTRUCTORS_UPDATE_FAILURE',
  INSTRUCTORS_UPDATE_SUCCESS: '@@privateGroupClasses/INSTRUCTORS_UPDATE_SUCCESS',

  RECURRING_CREATE:         '@@privateGroupClasses/RECURRING/CREATE',
  RECURRING_CREATE_FAILURE: '@@privateGroupClasses/RECURRING/CREATE_FAILURE',
  RECURRING_CREATE_SUCCESS: '@@privateGroupClasses/RECURRING/CREATE_SUCCESS',

  RECURRING_DELETE:         '@@privateGroupClasses/RECURRING/DELETE',
  RECURRING_DELETE_FAILURE: '@@privateGroupClasses/RECURRING/DELETE_FAILURE',
  RECURRING_DELETE_SUCCESS: '@@privateGroupClasses/RECURRING/DELETE_SUCCESS',

  RECURRING_UPDATE:         '@@privateGroupClasses/RECURRING/UPDATE',
  RECURRING_UPDATE_FAILURE: '@@privateGroupClasses/RECURRING/UPDATE_FAILURE',
  RECURRING_UPDATE_SUCCESS: '@@privateGroupClasses/RECURRING/UPDATE_SUCCESS',

  UPDATE:         '@@privateGroupClasses/UPDATE',
  UPDATE_FAILURE: '@@privateGroupClasses/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@privateGroupClasses/UPDATE_SUCCESS'
};
