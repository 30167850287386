import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { isNumber } from 'lodash';

import { api, toCamelCase } from '../../../lib';
import { currencies } from '../../../constants';
import { RequestedClassForm } from '../';

class RequestedClass extends React.PureComponent {
  static propTypes = {
    classId:              PropTypes.number,
    collection:           PropTypes.object.isRequired,
    collections:          PropTypes.array.isRequired,
    createRecording:      PropTypes.func,
    createRequestedClass: PropTypes.func.isRequired,
    currency:             PropTypes.string.isRequired,
    deleteRequestedClass: PropTypes.func.isRequired,
    fetchRecordings:      PropTypes.func.isRequired,
    folders:              PropTypes.array.isRequired,
    formats:              PropTypes.array.isRequired,
    instructor:           PropTypes.object.isRequired,
    isLoading:            PropTypes.bool.isRequired,
    isNewRecord:          PropTypes.bool,
    isOpen:               PropTypes.bool,
    onToggle:             PropTypes.func,
    recording:            PropTypes.object.isRequired,
    recordings:           PropTypes.array.isRequired,
    updateRequestedClass: PropTypes.func.isRequired,
    user:                 PropTypes.object.isRequired
  };

  static defaultProps = {
    classId:         0,
    createRecording: () => {},
    isNewRecord:     true,
    isOpen:          false,
    onToggle:        () => {}
  };


  componentDidMount () {
    if (!this.props.isNewRecord) {
      this.setState({ isLoading: true }, () => (
        api.instructor.requestedClass.get({ id: this.props.classId })
          .then((response) => (
            this.setState({
              isLoading:      false,
              requestedClass: toCamelCase(response.data)
            })
          ))
      ));
    }
  }

  state = {
    isLoading:      false,
    requestedClass: {}
  }

  handleSubmit = (requestedClass) => {
    const { id } = this.state.requestedClass;
    const sign = currencies[this.props.currency].symbol;

    const cost = isNumber(requestedClass.cost) || requestedClass.purchaseType != 0
        ? requestedClass.cost
        : parseFloat(requestedClass.cost.toString().replace(sign, ''));

    this.props.isNewRecord
        ? this.props.createRequestedClass({ ...requestedClass, cost })
        : this.props.updateRequestedClass({ ...requestedClass, cost, id });

    this.props.onToggle();
  }

  handleDelete = () => {
    const { id } = this.state.requestedClass;

    this.props.deleteRequestedClass({ id });
    this.props.onToggle();
  }

  render() {
    const { isNewRecord, isOpen, onToggle, currency, collection, collections, instructor, folders } = this.props;
    const { requestedClass } = this.state;

    const title = `${isNewRecord ? 'Add' : 'Edit'} On Demand Class`;

    return (
      <Modal
          backdrop='static'
          centered
          isOpen={isOpen}
          toggle={onToggle}
          unmountOnClose>
        <ModalHeader toggle={onToggle}>
          {title}
        </ModalHeader>

        <ModalBody>
          <RequestedClassForm
              collectionId={collection.id}
              collections={collections}
              createRecording={this.props.createRecording}
              currency={currency}
              data={requestedClass}
              fetchRecordings={this.props.fetchRecordings}
              folders={folders}
              formats={this.props.formats}
              instructor={instructor}
              isLoading={{
                recordings:       this.props.isLoading,
                requestedClasses: this.state.isLoading
              }}
              isNewRecord={isNewRecord}
              onDelete={this.handleDelete}
              onSubmit={this.handleSubmit}
              onToggle={onToggle}
              recording={this.props.recording}
              recordings={this.props.recordings}
              user={this.props.user} />
        </ModalBody>
      </Modal>
    );
  }
}

export default RequestedClass;
