import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader } from 'reactstrap';
import { filter, find, head, includes, isEmpty, sortBy, sumBy } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { DateHelper, ModalSaver, Moment, PaymentMethod, api, extractError, getTimeZone,
         toCamelCase } from '../../../lib';
import { BOOKING_STEPS, currencies } from '../../../constants';
import { Charge, Donation, Guest, Info, Success } from './privateBooking';

class PrivateBookingModal extends React.PureComponent {
  static propTypes = {
    challenges:        PropTypes.array.isRequired,
    classPacks:        PropTypes.array.isRequired,
    createBooking:     PropTypes.func,
    createDiscount:    PropTypes.func.isRequired,
    discount:          PropTypes.object,
    fetchClassPacks:   PropTypes.func.isRequired,
    fetchMemberships:  PropTypes.func.isRequired,
    instructor:        PropTypes.object.isRequired,
    instructorPacks:   PropTypes.array.isRequired,
    isOpen:            PropTypes.bool.isRequired,
    loader:            PropTypes.object.isRequired,
    memberships:       PropTypes.array.isRequired,
    onToggle:          PropTypes.func.isRequired,
    privateBookings:   PropTypes.array.isRequired,
    privateGroupClass: PropTypes.object.isRequired,
    restoreDiscount:   PropTypes.func.isRequired,
    subscriptions:     PropTypes.array.isRequired,
    user:              PropTypes.object.isRequired
  };

  static defaultProps = {
    createBooking: () => {},
    discount:      {}
  };


  static getDerivedStateFromProps(props, state) {
    const booking = find(props.privateBookings, (booking) => (
      booking.privateGroupClass.id === props.privateGroupClass.id
    ));

    const filteredPacks = filter(props.classPacks, (classPack) => (
      classPack.live > 0 || classPack.combined > 0 &&
        !includes(props.privateGroupClass.classPackIds, classPack.classPack.id))
    );

    const filteredChallenges = filter(props.challenges, (challenge) => (
      !includes(props.privateGroupClass.challengeIds, challenge.instructorChallengeId)
    ));

    const combined = sumBy(filteredPacks, 'combined');
    const live     = sumBy(filteredPacks, 'live');

    const classPack = head(filteredPacks);
    const challenge = head(filteredChallenges);

    // sort subscriptions so active ones are in priority
    const sortedSubscriptions = sortBy(props.subscriptions, ['status', 'active']);

    const subscription = find(sortedSubscriptions, (subscription) => {
      const endsAt = new Moment.utc(subscription.endsAt, 'YYYY-MM-DDhh:mm:ssUTC');
      const isUsable = new Moment() < endsAt;

      return subscription.membership &&
             (subscription.status == 'active' || (subscription.status == 'canceled' && isUsable))  &&
             subscription.membership.premium.live == 'true' &&
             !includes(props.privateGroupClass.membershipIds, subscription.membership.id) &&
             subscription.membership.instructor.id == props.instructor.id;
    });

    const isClassPackAvailable = !!(live + combined);
    const isChallengeAvailable = !isEmpty(challenge);

    const date = DateHelper.summerTimeCheck(props.privateGroupClass, props.user.timeZone);

    const endsAt =  !isEmpty(subscription) && new Moment.utc(subscription.endsAt, 'YYYY-MM-DDhh:mm:ssUTC');
    const startsAt = !isEmpty(subscription) &&
                    new Moment.utc(props.privateGroupClass.startsAt, 'YYYY-MM-DDhh:mm:ssUTC');

      return {
        booking,
        bookingType: state.isMounted ? state.bookingType : props.privateGroupClass.eventType,
        challenge:   {
          id: challenge && challenge.id
        },
        classPack: {
          combined,
          id: classPack && classPack.id,
          live
        },
        date:         new Moment.utc(date, 'YYYY-MM-DDhh:mm:ss').tz(getTimeZone(props.user.timeZone)),
        isChallengeAvailable,
        isClassPackAvailable,
        isMounted:    true,
        isPeriodEnds: !isEmpty(subscription) && startsAt.isAfter(endsAt),
        isSubscribed: !isEmpty(subscription) && endsAt.isAfter(startsAt),
        step:         booking && booking.id && state.hasBooked ? BOOKING_STEPS.success : state.step,
        subscription
      };
  }

  componentDidMount() {
    ModalSaver._append(this.props.privateGroupClass.uuid);
  }

  componentWillUnmount() {
    ModalSaver._detach();
  }

  state = {
    booking:              {},
    bookingType:          'offline',
    classPack:            {},
    date:                 '',
    guest:                {},
    hasBooked:            false,
    isClassPackAvailable: false,
    isLoading:            false,
    isMounted:            false,
    isPeriodEnds:         false,
    isSubscribed:         false,
    step:                 BOOKING_STEPS.info,
    subscription:         {},
    vaccinated:           false
  }

  handleBook = (data = null) => {
    const { privateGroupClass, user } = this.props;
    const {
      bookingType,
      challenge,
      classPack,
      isChallengeAvailable,
      isClassPackAvailable,
      isSubscribed,
      vaccinated
    } = this.state;

    this.setState({ hasBooked: true }, () => (
      this.handleLoading(true, () => (
        api.privateGroupClasses.bookings.create({
          booking: {
            ...privateGroupClass,
            ...data,
            bookableId:   privateGroupClass.id,
            bookableType: 'PrivateGroupClass',
            bookingType,
            challengeId:  challenge.id,
            classPackId:  classPack.id,
            clientId:     this.state.guest.id,
            method:       new PaymentMethod(isClassPackAvailable, isSubscribed, isChallengeAvailable).get(),
            timeZone:     getTimeZone(user.timeZone),
            vaccinated
          }
        })
        .then((response) => (
          this.props.createBooking({ booking: toCamelCase(response.data), classPack }),
          this.handleNext(BOOKING_STEPS.success)
        ))
        .catch((exception) => {
          const error = extractError(exception);
          toastr.error(error.message);
          this.handleLoading(false);
        })
      ))
    ));
  }

  handleChange = (value, inputName, callback) => {
    this.setState({ [inputName]: value }, callback);
  }

  handleLoading = (isLoading, callback) => (
    this.setState({ isLoading }, callback)
  )

  handleNext = (step) => (
    this.handleLoading(true, () => (
      this.setState({ step }, () => this.handleLoading(false))
    ))
  )

  handleToggle = () => {
    if (this.state.isLoading) return;

    this.setState({ date: '' });
    this.props.onToggle();
    this.props.discount && this.props.restoreDiscount();
  }

  handleSwitch = (fieldName, value) => () => this.setState({ [fieldName]: value })

  renderContent = () => {
    const sign = currencies[this.props.instructor.currency].symbol;

    switch (this.state.step) {
      case BOOKING_STEPS.info:
        return (
          <Info
              booking={this.state.booking}
              bookingType={this.state.bookingType}
              classPack={this.state.classPack}
              date={this.state.date}
              fetchClassPacks={this.props.fetchClassPacks}
              fetchMemberships={this.props.fetchMemberships}
              handleSwitch={this.handleSwitch}
              instructor={this.props.instructor}
              instructorPacks={this.props.instructorPacks}
              isChallengeAvailable={this.state.isChallengeAvailable}
              isClassPackAvailable={this.state.isClassPackAvailable}
              isLoading={this.state.isLoading}
              isPeriodEnds={this.state.isPeriodEnds}
              isSubscribed={this.state.isSubscribed}
              isVaccinated={this.state.vaccinated}
              loader={this.props.loader}
              memberships={this.props.memberships}
              onBook={this.handleBook}
              onChange={this.handleChange}
              onLoading={this.handleLoading}
              onNext={this.handleNext}
              privateGroupClass={this.props.privateGroupClass}
              subscription={this.state.subscription}
              user={this.props.user} />
        );

      case BOOKING_STEPS.guest:
        return (
          <Guest
              instructor={this.props.instructor}
              isLoading={this.state.isLoading}
              onBook={this.handleBook}
              onChange={this.handleChange}
              onLoading={this.handleLoading}
              onNext={this.handleNext}
              privateGroupClass={this.props.privateGroupClass} />
        );

      case BOOKING_STEPS.donation:
        return (
          <Donation
              date={this.state.date}
              isLoading={this.state.isLoading}
              onBook={this.handleBook}
              onChange={this.handleChange}
              onLoading={this.handleLoading}
              onNext={this.handleNext}
              privateGroupClass={this.props.privateGroupClass}
              sign={sign}
              user={this.props.user} />
        );

      case BOOKING_STEPS.charge:
        return (
          <Charge
              amount={this.state.amount}
              client={this.state.guest}
              createDiscount={this.props.createDiscount}
              date={this.state.date}
              discount={this.props.discount}
              instructor={this.props.instructor}
              isLoading={this.state.isLoading}
              onBook={this.handleBook}
              onChange={this.handleChange}
              onLoading={this.handleLoading}
              onNext={this.handleNext}
              privateGroupClass={this.props.privateGroupClass}
              user={this.props.user} />
        );

      case BOOKING_STEPS.success:
        return (
          <Success
              booking={this.state.booking}
              bookingType={this.state.bookingType}
              date={this.state.date}
              user={this.props.user} />
        );

      default: return null;
    }
  }

  render() {
    return (
      <Modal
          className='modal-booking'
          isOpen={this.props.isOpen}
          toggle={this.handleToggle}>
        <ModalHeader toggle={this.handleToggle} />

        {this.renderContent()}
      </Modal>
    );
  }
}

export default PrivateBookingModal;
