import React from 'react';
import PropTypes from 'prop-types';
import { Navbar } from 'reactstrap';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { FollowsActions } from '../../../../actions';
import { InstructorMenu, SignMenu, UserMenu } from '../../../../components/layout/header/menu';
import FollowButton from '../components/followButtonElegant';
import { Contacts } from '../../../../components/instructor';

class NavigationUrban extends React.PureComponent {
  static propTypes = {
    followInstructor:   PropTypes.func.isRequired,
    getDomain:          PropTypes.func.isRequired,
    instructor:         PropTypes.object.isRequired,
    logOut:             PropTypes.func.isRequired,
    profile:            PropTypes.object.isRequired,
    session:            PropTypes.object.isRequired,
    unfollowInstructor: PropTypes.func.isRequired,
    user:               PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      hasProfile:      !isEmpty(props.profile),
      isAuthenticated: props.session.authenticated,
      user:            props.user
    };
  }

  state = {
    isNavbarOpen:   false,
    isUserMenuOpen: false
  };

  componentDidMount() {
    const { profile } = this.props;

    this.props.getDomain({ username: profile.username });
  }

  handleUserMenu = () => {
    this.setState((prevState) => ({
      isUserMenuOpen: !prevState.isUserMenuOpen
    }));
  };

  handleNavbar = () => {
    this.setState((prevState) => ({
      isNavbarOpen: !prevState.isNavbarOpen
    }));
  }

  handleFollow = () => {
    const { instructor, followInstructor, unfollowInstructor } = this.props;

    instructor.isFollowing ? unfollowInstructor(instructor) : followInstructor(instructor);
  };

  _renderContent = () => {
    const { instructor } = this.props;
    const { hasProfile, isAuthenticated, isNavbarOpen, user, isUserMenuOpen } = this.state;
    const contactsInstructor = {
      customization:  instructor.customization,
      socialProfiles: instructor.socialProfiles
    };

    if (isMobile) {
      return (
        <React.Fragment>
          <button
              className='navbar-toggler'
              onClick={this.handleNavbar}>
            <span className='navbar-toggler-icon'></span>
          </button>
          <FollowButton
              followersCount={instructor.followersCount}
              isDisabled={!!instructor.isOwner}
              isFollowing={!!instructor.isFollowing}
              onClick={this.handleFollow}
              stroke='white' />

          <div className={classNames('mobile-menu', { 'show': isNavbarOpen })}>
            <div className='mobile-menu-header'>
              <button
                  className='navbar-toggler close'
                  onClick={this.handleNavbar}>
                &times;
              </button>

              { isAuthenticated
                ? <UserMenu
                    direction='right'
                    hasProfile={hasProfile}
                    isOpen={isUserMenuOpen}
                    logOut={this.props.logOut}
                    onToggle={this.handleUserMenu}
                    user={user} />

                : <SignMenu />
              }
            </div>
            <InstructorMenu
                instructor={instructor}
                onLinkClick={this.handleNavbar} />
            <Contacts
                hasTitle={false}
                instructor={contactsInstructor} />
          </div>

          { isNavbarOpen &&
            <div
                className='mobile-menu-overlay'
                onClick={this.handleNavbar} />
          }
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <InstructorMenu instructor={instructor} />
          <div className='d-flex template-elegant-navbar-right'>
            <FollowButton
                followersCount={instructor.followersCount}
                isDisabled={!!instructor.isOwner}
                isFollowing={!!instructor.isFollowing}
                onClick={this.handleFollow}
                stroke='white' />

            { isAuthenticated
              ? <UserMenu
                  direction='right'
                  hasProfile={hasProfile}
                  isOpen={isUserMenuOpen}
                  logOut={this.props.logOut}
                  onToggle={this.handleUserMenu}
                  user={user} />

              : <SignMenu />
            }
          </div>
        </React.Fragment>
      );
    }
  }


  render() {
    return (
      <Navbar className="template-elegant-navbar">
        {this._renderContent()}
      </Navbar>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  followInstructor:   (data) =>  dispatch(FollowsActions.FOLLOW_INSTRUCTOR(data)),
  unfollowInstructor: (data) =>  dispatch(FollowsActions.UNFOLLOW_INSTRUCTOR(data))
});

export default connect(null, mapDispatchToProps)(NavigationUrban);
