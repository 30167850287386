import React from 'react';
import PropTypes from 'prop-types';

import { BookingModal, PrivateBookingModal } from '../../modals';
import GroupItem from './components/groupClassItem';

class GroupClasses extends React.PureComponent {
  static propTypes = {
    challenges:           PropTypes.array.isRequired,
    classPacks:           PropTypes.array.isRequired,
    createBooking:        PropTypes.func.isRequired,
    createDiscount:       PropTypes.func.isRequired,
    createPrivateBooking: PropTypes.func.isRequired,
    discount:             PropTypes.object.isRequired,
    fetchClassPacks:      PropTypes.func.isRequired,
    fetchMemberships:     PropTypes.func.isRequired,
    instructor:           PropTypes.object.isRequired,
    instructorPacks:      PropTypes.array.isRequired,
    isModalOpen:          PropTypes.bool,
    loader:               PropTypes.object.isRequired,
    memberships:          PropTypes.array.isRequired,
    nearestClasses:       PropTypes.array,
    privateBookings:      PropTypes.array.isRequired,
    privateGroupClass:    PropTypes.object,
    restoreDiscount:      PropTypes.func.isRequired,
    subscriptions:        PropTypes.array.isRequired,
    user:                 PropTypes.object.isRequired
  }

  static defaultProps = {
    isModalOpen:       false,
    nearestClasses:    [],
    privateGroupClass: {}
  }

  static getDerivedStateFromProps(props, state) {
    if (props.isModalOpen && !state.isMounted) {
      return {
        groupClass:                   props.privateGroupClass,
        isMounted:                    true,
        isPrivateGroupClassModalOpen: true
      };
    }
  }

  state = {
    groupClass:                   {},
    isGroupClassModalOpen:        false,
    isMounted:                    false,
    isPrivateGroupClassModalOpen: false
  };

  handleBookingModal = () => (
    this.setState((prevState) => ({ isPrivateGroupClassModalOpen: !prevState.isPrivateGroupClassModalOpen }))
  )

  handleToggle = (groupClass) => () => (
    this.setState({ groupClass }, () => {
      if (groupClass.type == 'privateGroupClass') {
        this.setState((prevState) => ({ isPrivateGroupClassModalOpen: !prevState.isPrivateGroupClassModalOpen }));
      } else {
        this.setState((prevState) => ({ isGroupClassModalOpen: !prevState.isGroupClassModalOpen }));
      }
    })
  )

  render() {
    const { nearestClasses } = this.props;

    return (
      <div className='group-class__body body-class flex-column'>
        { nearestClasses.map((groupClass) => {
          return (
            <GroupItem
                groupClass={groupClass}
                instructor={this.props.instructor}
                key={groupClass.id}
                onHandleToggle={this.handleToggle}
                user={this.props.user} />
          );
        }) }
        { this.state.isGroupClassModalOpen &&
          <BookingModal
              createBooking={this.props.createBooking}
              groupClass={this.state.groupClass}
              isOpen={this.state.isGroupClassModalOpen}
              onToggle={this.handleToggle(this.state.groupClass)}
              user={this.props.user} />
        }

        { this.state.isPrivateGroupClassModalOpen &&
          <PrivateBookingModal
              challenges={this.props.challenges}
              classPacks={this.props.classPacks}
              createBooking={this.props.createPrivateBooking}
              createDiscount={this.props.createDiscount}
              discount={this.props.discount}
              fetchClassPacks={this.props.fetchClassPacks}
              fetchMemberships={this.props.fetchMemberships}
              instructor={this.props.instructor}
              instructorPacks={this.props.instructorPacks}
              isOpen={this.state.isPrivateGroupClassModalOpen}
              loader={this.props.loader}
              memberships={this.props.memberships}
              onBook={this.handleBook}
              onToggle={this.handleBookingModal}
              privateBookings={this.props.privateBookings}
              privateGroupClass={this.state.groupClass}
              restoreDiscount={this.props.restoreDiscount}
              subscriptions={this.props.subscriptions}
              user={this.props.user} />
        }
      </div>
    );
  }
}

export default GroupClasses;
