import PropTypes from 'prop-types';
import React from 'react';
import { isEmpty } from 'lodash';
import Header from '../components/headerUrban';

import { Challenges, ClassPacks, Memberships } from '../../../../components/pages/profile/instructor';

class PackagesElegant extends React.PureComponent {

  static propTypes = {
    challenges:                PropTypes.array.isRequired,
    classPacks:                PropTypes.array.isRequired,
    createChallenge:           PropTypes.func.isRequired,
    createClassPack:           PropTypes.func.isRequired,
    createDiscount:            PropTypes.func.isRequired,
    createMembershipDiscount:  PropTypes.func.isRequired,
    createSubscription:        PropTypes.func.isRequired,
    discount:                  PropTypes.object.isRequired,
    follow:                    PropTypes.func.isRequired,
    instructor:                PropTypes.object.isRequired,
    memberships:               PropTypes.array.isRequired,
    membershipsDiscount:       PropTypes.object.isRequired,
    restoreDiscount:           PropTypes.func.isRequired,
    restoreMembershipDiscount: PropTypes.func.isRequired,
    subscriptions:             PropTypes.array.isRequired,
    unFollow:                  PropTypes.func.isRequired,
    user:                      PropTypes.object.isRequired
  }

  handleFollow = () => {
    this.props.instructor.isFollowing ? this.props.unFollow() : this.props.follow();
  };

  renderMembershipReason = (reason) => {
    if (!isEmpty(reason.text)) {
      return (
        <li
            className='reason mb-1 mt-1'
            key={reason.id}>
          {reason.text}
        </li>
      );
    }
  }

  render() {
    const { challenges, classPacks, instructor, user, memberships, subscriptions } = this.props;

    return (
      <div className='profile-section'>
        <Header
            instructor={instructor}
            subtitle='Packages' />

        <div className='memberships template-elegant'>
          <div className='content'>
            <div className='mb-2'>
              <h3 className='page-title'>Purchase a package!</h3>
              { !isEmpty(instructor.membershipReasons) &&
                <ul className='mb-4 mt-2 reasons'>
                  {instructor.membershipReasons.map(this.renderMembershipReason)}
                </ul>
              }
            </div>

            { !isEmpty(instructor) && !!challenges.length &&
              <React.Fragment>
                <Challenges
                    challenges={challenges}
                    createChallenge={this.props.createChallenge}
                    createDiscount={this.props.createDiscount}
                    discount={this.props.discount}
                    instructor={instructor}
                    restoreDiscount={this.props.restoreDiscount}
                    theme='elegant'
                    user={user} />
              </React.Fragment>
            }

            { !isEmpty(instructor) && !!memberships.length &&
              <div className='mt-4'>
                <Memberships
                    createDiscount={this.props.createMembershipDiscount}
                    createSubscription={this.props.createSubscription}
                    discount={this.props.membershipsDiscount}
                    instructor={instructor}
                    memberships={memberships}
                    restoreDiscount={this.props.restoreMembershipDiscount}
                    subscriptions={subscriptions}
                    theme='elegant'
                    user={user} />
              </div>
            }

            { !isEmpty(instructor) && !!classPacks.length &&
              <div className='mt-4'>
                <ClassPacks
                    classPacks={classPacks}
                    createClassPack={this.props.createClassPack}
                    createDiscount={this.props.createDiscount}
                    discount={this.props.discount}
                    instructor={instructor}
                    restoreDiscount={this.props.restoreDiscount}
                    theme='elegant'
                    user={user} />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default PackagesElegant;
