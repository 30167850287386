import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, Label } from 'reactstrap';

import { storyMakerOptions } from '../../constants';
import { CustomRange } from '../forms';

class SizeInputs extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onZoom:   PropTypes.func.isRequired
  }
  
  state = {
    fontSize:  '14',
    zoomValue: '0'
  }

  handleZoom = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      zoomValue: value
    }), () => {
      this.props.onZoom(Number(value));
    });
  };

  handleSize = (value) => {
    this.setState((prevState) => ({
      ...prevState,
      fontSize: value
    }), () => {
      this.props.onChange({size: Number(value)});
    });
  };

  render () {
    return (
      <Form className='story-size-inputs'>
        <FormGroup
            className='mb-4 align-items-center'
            row>
          <Label xs={2}>Image</Label>
          <Col xs={10}>
            <CustomRange
                id='zoom'
                name='fontSize'
                onChange={this.handleZoom}
                options={storyMakerOptions.ZOOM}
                step={1}
                value={this.state.zoomValue} />
          </Col>
        </FormGroup>
        <FormGroup
            className='mb-0 align-items-center'
            row>
          <Label xs={2}>Font</Label>
          <Col xs={10}>
            <CustomRange
                id='font-size'
                name='font-size'
                onChange={this.handleSize}
                options={storyMakerOptions.FONT_SIZES}
                step={1}
                value={this.state.fontSize} />
          </Col>
        </FormGroup>
      </Form>
    );
  }
}

export default SizeInputs;
