import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Cookies, withCookies } from 'react-cookie';

class RedirectContainer extends React.PureComponent {
  static propTypes = {
    cookies: PropTypes.instanceOf(Cookies).isRequired
  };

  _getCookie = () => {
    return null;
  }

  componentDidMount () {
    const { cookies } = this.props;
    const params = new URL(window.location).searchParams;
    const code = params.get('oauth');

    if (code === 'success') {
      cookies.set('oauth', code, {expires: 0, path: '/'});
      window.close();
    }
  }

  render() {
    return this._getCookie();
  }
}

const mapStateToProps = (_state, ownProps) => ({
  cookies: ownProps.cookies
});

export default compose(
  withCookies,
  connect(mapStateToProps)
)(RedirectContainer);
