export default {
  FETCH:         '@@playlist/FETCH',
  FETCH_FAILURE: '@@playlist/FETCH_FAILURE',
  FETCH_SUCCESS: '@@playlist/FETCH_SUCCESS',

  GET:         '@@playlist/GET',
  GET_FAILURE: '@@playlist/GET_FAILURE',
  GET_SUCCESS: '@@playlist/GET_SUCCESS',

  UPDATE:         '@@playlist/UPDATE',
  UPDATE_FAILURE: '@@playlist/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@playlist/UPDATE_SUCCESS'
};
