/* eslint-disable max-len, immutable/no-let */

// Function for changing color hue/brightness as simple as posible
// Accepts only hex color values with/without pound (#) e.g. #eb0c0c

const MAX_COLOR_VALUE = 255;
const FALLBACK_VALUE = 50;

export const changeColor = (color, amount) => {
  let usePound = false;

  if (color[0] == '#') {
    color = color.slice(1);
    usePound = true;
  }

  let numericalValue = parseInt(color, 16);

  let red = (numericalValue >> 16) + amount;

  if (red > MAX_COLOR_VALUE) red = MAX_COLOR_VALUE - FALLBACK_VALUE;
  else if (red < 0) red = FALLBACK_VALUE;

  let blue = ((numericalValue >> 8) & 0x00ff) + amount;

  if (blue > MAX_COLOR_VALUE) blue = MAX_COLOR_VALUE - FALLBACK_VALUE;
  else if (blue < 0) blue = FALLBACK_VALUE;

  let green = (numericalValue & 0x0000ff) + amount;

  if (green > MAX_COLOR_VALUE) green = MAX_COLOR_VALUE - FALLBACK_VALUE;
  else if (green < 0) green = FALLBACK_VALUE;

  return (usePound ? '#' : '') + (green | (blue << 8) | (red << 16)).toString(16);
};

export const invertColor = (color) => {
  // Simple function to revert pounded hex color e.g. #eb0c0c
  // It won't work on simple colors e.g. #abc
  const hex = color.slice(1);
  return `#${(Number(`0x1${hex}`) ^ 0xFFFFFF).toString(16).substr(1).toUpperCase()}`;
};
