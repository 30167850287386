/* eslint-disable immutable/no-let */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { UserClassPacksActionTypes } from '../constants/actionTypes';
import { ClientsActions, ErrorActions, LoaderActions, UserClassPacksActions } from '../actions';

function* watchInstructorsCreate() {
  yield takeLatest(UserClassPacksActionTypes.INSTRUCTORS_CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.userClassPack.create, payload);
      const client = yield select((state) => state.clients.form);

      const data = {...client, userClassPacks: [...client.userClassPacks, toCamelCase(response.data)] };

      yield put(UserClassPacksActions.INSTRUCTORS_CREATE_SUCCESS());
      yield put(ClientsActions.GET_SUCCESS(data));

      toastr.success('Class Pack added');
    } catch (error) {
      yield put(UserClassPacksActions.INSTRUCTORS_CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsCreateSuccess() {
  yield takeLatest(UserClassPacksActionTypes.INSTRUCTORS_CREATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsCreateFailure() {
  yield takeLatest(UserClassPacksActionTypes.INSTRUCTORS_CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* classPacksSaga() {
  yield all([
    watchInstructorsCreate(),
    watchInstructorsCreateSuccess(),
    watchInstructorsCreateFailure()
  ]);
}
