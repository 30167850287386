import React from 'react';
import { Container, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import classNames from 'classnames';

import { tutorialLinks } from '../../constants';
import { Heading, Panel } from '../layout/pages';
import { Button } from '../forms';

class SupportPage extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Help & Support</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container
              className='settings-page'
              fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Help & Support' />
            <Panel className='settings-menu'>

              <h4 className={classNames('menu-title', { 'text-secondary': isMobile})}>Read the FAQ</h4>
              <Media className='menu-link'>
                <Media body>
                  <a
                      href={'https://recesstv.zendesk.com/hc/en-gb'}
                      rel='noopener noreferrer'
                      target="_blank">
                    Click here&nbsp;
                  </a>
                  to visit our FAQ
                </Media>
              </Media>

              <h4 className={classNames('menu-title', { 'text-secondary': isMobile})}>How to Video Tutorials?</h4>

              <Media className='menu-link'>
                <Media body>
                  Adding live classes
                </Media>
                <Media right>
                  <a
                      href={tutorialLinks.SCHEDULE}
                      rel='noopener noreferrer'
                      target="_blank">
                    <Button
                        isOutline
                        size='sm'>
                      Watch
                    </Button>
                  </a>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media body>
                  Adding on-demand videos
                </Media>
                <Media right>
                  <a
                      href={tutorialLinks.ON_DEMAND}
                      rel='noopener noreferrer'
                      target="_blank">
                    <Button
                        isOutline
                        size='sm'>
                      Watch
                    </Button>
                  </a>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media body>
                  Create a membership & class pack
                </Media>
                <Media right>
                  <a
                      href={tutorialLinks.PACKAGES}
                      rel='noopener noreferrer'
                      target="_blank">
                    <Button
                        isOutline
                        size='sm'>
                      Watch
                    </Button>
                  </a>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media body>
                  Create a coupon code
                </Media>
                <Media right>
                  <a
                      href={tutorialLinks.COUPONS}
                      rel='noopener noreferrer'
                      target="_blank">
                    <Button
                        isOutline
                        size='sm'>
                      Watch
                    </Button>
                  </a>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media body>
                  Editing your website
                </Media>
                <Media right>
                  <a
                      href={tutorialLinks.SETTINGS}
                      rel='noopener noreferrer'
                      target="_blank">
                    <Button
                        isOutline
                        size='sm'>
                      Watch
                    </Button>
                  </a>
                </Media>
              </Media>

              <Media className='menu-link'>
                <Media body>
                  How to embed onPodio into an existing site
                </Media>
                <Media right>
                  <a
                      href={tutorialLinks.WIDGETS}
                      rel='noopener noreferrer'
                      target="_blank">
                    <Button
                        isOutline
                        size='sm'>
                      Watch
                    </Button>
                  </a>
                </Media>
              </Media>

              <h4 className={classNames('menu-title', { 'text-secondary': isMobile})}>Contact Us</h4>
              
              <Media className='menu-link'>
                <Media body>
                  If you require any assitance, drop us a line at&nbsp;
                  <a
                      href={'mailto:info@onpodio.com'}
                      rel='noopener noreferrer'
                      target="_blank">
                    info@onpodio.com
                  </a>
                </Media>
              </Media>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SupportPage;
