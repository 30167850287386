import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { find, isEmpty } from 'lodash';

import { Input } from '../../inputs';
import { Button, Checkbox } from '../../forms';
import { EMAIL_TEMPLATES } from '../../../constants';
import { Validator } from '../../../lib';

class BookingConfirmationModal extends React.PureComponent {
  static propTypes = {
    createEmailTemplate: PropTypes.func.isRequired,
    emailTemplates:      PropTypes.array.isRequired,
    isLoading:           PropTypes.bool,
    isOpen:              PropTypes.bool,
    onToggle:            PropTypes.func,
    updateEmailTemplate: PropTypes.func.isRequired
  }

  static defaultProps = {
    isLoading: false,
    isOpen:    false,
    onToggle:  () => {}
  }

  componentDidMount() {
    const emailTemplate = find(this.props.emailTemplates, ['name', EMAIL_TEMPLATES.bookingConfirmation]);

    if (emailTemplate) {
      this.setState({ emailTemplate: { ...emailTemplate, name: EMAIL_TEMPLATES.bookingConfirmation }, isNew: false });
    }
  }

  state = {
    emailTemplate: {
      body:    '',
      deliver: true,
      name:    EMAIL_TEMPLATES.bookingConfirmation
    },
    errors: {},
    isNew:  true
  }

  handleChange = (value, inputName) => (
    this.setState((prevState) => ({
      emailTemplate: {
        ...prevState.emailTemplate,
        [inputName]: value
      }
    }))
  )

  handleSubmit = () => {
    this.handleValidate().then(() => {
      return (
        this.state.isNew
            ? this.props.createEmailTemplate(this.state)
            : this.props.updateEmailTemplate(this.state),
        this.props.onToggle()
      );
    }).catch((errors) => (
      this.setState({ errors })
    ));
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const errors = Validator.validate([
      ['body', Validator.concepts.isFilled, [this.state.emailTemplate.body]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  render() {
    return (
      <Modal
          className='modal-refer'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          Update <span className='text-primary'>Booking Confirmation</span>
        </ModalHeader>

        <ModalBody>
          <div className='description mb-2'>
            <small>
              Add a custom message to the client below the class information in a booking confirmation email.
            </small>
          </div>

          <FormGroup>
            <div className='custom-control custom-checkbox checkbox-md'>
              <Checkbox
                  className='custom-control-input'
                  id='deliver'
                  isChecked={this.state.emailTemplate.deliver}
                  name='deliver'
                  onChange={this.handleChange} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-deliver'>
                Add Custom Message
              </Label>
            </div>
          </FormGroup>

          <div className='text-primary mb-2'>Custom Message</div>

          <Form className='form-default'>
            <FormGroup>
              <Label
                  className='required'
                  for='body'>
                Message
              </Label>

              <Input
                  id='body'
                  isDisabled={!this.state.emailTemplate.deliver}
                  isInvalid={!!this.state.errors.body}
                  name='body'
                  onChange={this.handleChange}
                  placeholder='Thank you for joining me...'
                  rows='4'
                  type='textarea'
                  value={this.state.emailTemplate.body} />
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button
              className='mt-4'
              color='primary'
              isBlock
              isLoading={this.props.isLoading}
              onClick={this.handleSubmit}
              size='lg'
              type='button'>
            Update Email
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default BookingConfirmationModal;
