import React from 'react';
import PropTypes from 'prop-types';
import { keys, pickBy, size } from 'lodash';

class Info extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired,
    membership: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    if (state.membership.id) return state;

    const membership = {
      ...props.membership,
      premium: {
        live:     props.membership.premium.live     == 'true',
        onDemand: props.membership.premium.onDemand == 'true'
      }
    };

    return { membership };
  }

  state = {
    membership: {}
  }

  render() {
    const { currency } = this.props.instructor;
    const { membership } = this.state;

    const premiumFeaturesCount = size(keys(pickBy(membership.premium)));

    return (
      <div>
        <div>
          <span className='modal__small-text modal__small-text_grey'>
            {premiumFeaturesCount === 2 && 'Valid with Live Stream and On-Demand classes.'}
            { premiumFeaturesCount === 1 &&
                (membership.premium.onDemand ?
                  'Valid with On-Demand classes.' :
                  'Valid with Live Stream classes.')
            }
          </span>
        </div>
        { membership.trial &&
          <span className='d-flex modal__default-text font-weight-bold mb-2 mt-2'>
            Free trial for {membership.trialDays} days
          </span>
        }
        <span className='modal__default-text modal__default-text_blue modal__default-text_bold'>
          Cost {membership.cost.toLocaleString('en-GB', { currency, style: 'currency' })} per {membership.period}
        </span>
      </div>
    );
  }
}

export default Info;
