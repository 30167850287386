import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { currencyFormat } from '../../../../lib';

class AllTimeSummary extends PureComponent {
  static propTypes = {
    fetchSummary: PropTypes.func.isRequired,
    summary:      PropTypes.object,
    user:         PropTypes.object.isRequired
  }

  static defaultProps = {
    summary: {}
  }

  static getDerivedStateFromProps(props) {
    return {
      currency: props.user.currency,
      summary:  props.summary
    };
  }

  componentDidMount () {
    this.props.fetchSummary();
  }

  state = {
    currency: 'USD',
    summary:  {}
  }

  render () {
    const { summary, currency } = this.state;

    return (
      <div className='transactions-all-time'>
        <ul>
          <li>
            <span className='label'>All Time Platform Sales</span>
            <div className='count-block green'>
              {!isEmpty(summary) && currencyFormat(summary.total, currency)}
            </div>
          </li>
          <li>
            <span className='label'>$ Drop-in</span>
            <span className='amount'>
              {!isEmpty(summary) && currencyFormat(summary.dropIn, currency)}
            </span>
          </li>
          <li>
            <span className='label'>$ Memberships</span>
            <span className='amount'>
              {!isEmpty(summary) && currencyFormat(summary.memberships, currency)}
            </span>
          </li>
          <li>
            <span className='label'>$ Class Packs</span>
            <span className='amount'>
              {!isEmpty(summary) && currencyFormat(summary.classPacks, currency)}
            </span>
          </li>
        </ul>
      </div>
    );
  }
}

export default AllTimeSummary;
