/* eslint-disable immutable/no-let */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';
import { filter, map } from 'lodash';

import { api, extractError, routes, toCamelCase } from '../lib';
import { ChallengesActionTypes } from '../constants/actionTypes';
import { ChallengesActions, ErrorActions, LoaderActions } from '../actions';


/* regular sagas */
function* watchFetch() {
  yield takeLatest(ChallengesActionTypes.FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('challenges'));
      const response = yield call(api.challenge.fetch, payload);

      yield put(ChallengesActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ChallengesActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(ChallengesActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('challenges'));
  });
}

function* watchFetchFailure() {
  yield takeLatest(ChallengesActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('challenges'));
  });
}



/* instructors sagas */
function* watchInstructorsCreate() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.instructor.challenge.create, payload);

      yield put(ChallengesActions.INSTRUCTORS_CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Challenge added');
    } catch (error) {
      yield put(ChallengesActions.INSTRUCTORS_CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsCreateSuccess() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_CREATE_SUCCESS, function* () {
    yield put(push(routes.SETTINGS_PACKAGESS));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsCreateFailure() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchInstructorsDelete() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.instructor.challenge.delete, payload);

      const challenges = yield select((state) => state.challenges.instructorData);
      const data = filter(challenges, (challenge) => (challenge.id !== payload.challenge.id));

      yield put(ChallengesActions.INSTRUCTORS_DELETE_SUCCESS(data));
      toastr.success('Challenge canceled');
    } catch (error) {
      yield put(ChallengesActions.INSTRUCTORS_DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsDeleteSuccess() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsDeleteFailure() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchInstructorsFetch() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('challenges'));
      const response = yield call(api.instructor.challenge.fetch, payload);

      yield put(ChallengesActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ChallengesActions.INSTRUCTORS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsFetchSuccess() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('challenges'));
  });
}

function* watchInstructorsFetchFailure() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('challenges'));
  });
}


function* watchInstructorsGet() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('challenges'));
      const response = yield call(api.instructor.challenge.get, payload);

      yield put(ChallengesActions.INSTRUCTORS_GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ChallengesActions.INSTRUCTORS_GET_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsGetSuccess() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('challenges'));
  });
}

function* watchInstructorsGetFailure() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('challenges'));
  });
}


function* watchInstructorsUpdate() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING('challenges'));
      const response = yield call(api.instructor.challenge.update, payload);
      const challenges = yield select((state) => state.challenges.instructorData);
      const data = map(challenges, (challenge) => (
        challenge.id === payload.challenge.id ? toCamelCase(response.data) : challenge
      ));

      yield put(ChallengesActions.INSTRUCTORS_UPDATE_SUCCESS(data));
      toastr.success('Challenge updated');
    } catch (error) {
      yield put(ChallengesActions.INSTRUCTORS_UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsUpdateSuccess() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_UPDATE_SUCCESS, function* () {
    yield put(push(routes.SETTINGS_PACKAGESS));
    yield put(LoaderActions.FINISH_LOADING('challenges'));
  });
}

function* watchInstructorsUpdateFailure() {
  yield takeLatest(ChallengesActionTypes.INSTRUCTORS_UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('challenges'));
  });
}


/* users saga */
function* watchUsersCreate() {
  yield takeLatest(ChallengesActionTypes.USERS_CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.user.challenge.create, payload);
      const data = toCamelCase(response.data);

      yield put(ChallengesActions.USERS_CREATE_SUCCESS(data));
      toastr.success('Challenge purchased');
    } catch (error) {
      yield put(ChallengesActions.USERS_CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUsersDelete() {
  yield takeLatest(ChallengesActionTypes.USERS_DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.user.challenge.delete, payload);

      const challenges = yield select((state) => state.challenges.userData);
      const data = filter(challenges, (challenge) => (challenge.id !== payload.challenge.id));

      yield put(ChallengesActions.USERS_DELETE_SUCCESS(data));
      toastr.success('Class Pack canceled');
    } catch (error) {
      yield put(ChallengesActions.USERS_DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUsersDeleteSuccess() {
  yield takeLatest(ChallengesActionTypes.USERS_DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUsersDeleteFailure() {
  yield takeLatest(ChallengesActionTypes.USERS_DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


function* watchUsersFetch() {
  yield takeLatest(ChallengesActionTypes.USERS_FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.user.challenge.fetch, payload);

      yield put(ChallengesActions.USERS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ChallengesActions.USERS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUsersFetchSuccess() {
  yield takeLatest(ChallengesActionTypes.USERS_FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUsersFetchFailure() {
  yield takeLatest(ChallengesActionTypes.USERS_FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUserUpdate() {
  yield takeLatest(ChallengesActionTypes.USERS_UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.user.challenge.update, payload);

      yield put(ChallengesActions.USERS_UPDATE_SUCCESS());
      toastr.success('Challenge updated');
    } catch (error) {
      yield put(ChallengesActions.USERS_UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUserUpdateSuccess() {
  yield takeLatest(ChallengesActionTypes.USERS_UPDATE_SUCCESS, function* () {
    yield put(push(routes.COUPON));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUserUpdateFailure() {
  yield takeLatest(ChallengesActionTypes.USERS_UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}


export default function* challengesSaga() {
  yield all([
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchInstructorsDelete(),
    watchInstructorsDeleteSuccess(),
    watchInstructorsDeleteFailure(),
    watchInstructorsCreate(),
    watchInstructorsCreateSuccess(),
    watchInstructorsCreateFailure(),
    watchInstructorsFetch(),
    watchInstructorsFetchSuccess(),
    watchInstructorsFetchFailure(),
    watchInstructorsGet(),
    watchInstructorsGetSuccess(),
    watchInstructorsGetFailure(),
    watchInstructorsUpdate(),
    watchInstructorsUpdateSuccess(),
    watchInstructorsUpdateFailure(),
    watchUsersCreate(),
    watchUsersDelete(),
    watchUsersDeleteSuccess(),
    watchUsersDeleteFailure(),
    watchUsersFetch(),
    watchUsersFetchSuccess(),
    watchUsersFetchFailure(),
    watchUserUpdate(),
    watchUserUpdateSuccess(),
    watchUserUpdateFailure()
  ]);
}
