import React from 'react';
import PropTypes from 'prop-types';

import { Alert } from './';

class InvalidNameservers extends React.PureComponent {
  static propTypes = {
    nameservers: PropTypes.array
  }

  static defaultProps = {
    nameservers: ['', '']
  }

  render () {
    const { nameservers } = this.props;

    return (
      <Alert
          className='mt-3'
          color='danger'
          heading='Invalid nameservers'>
        In order to work domain needs to have proper nameservers. Your current NS are:
        <ul className='m-0'>
          { nameservers.map((ns) => (
            <li key={ns}>{ns}</li>
          )) }
        </ul>
        Change them in your domain&apos;s provider control panel to:
        <ul className='m-0'>
          <li><b>ns1.onpodio.com</b></li>
          <li><b>ns2.onpodio.com</b></li>
        </ul>
      </Alert>
    );
  }
}

export default InvalidNameservers;