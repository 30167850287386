import { all, call, put, takeLatest } from 'redux-saga/effects';
import { api, extractError, toCamelCase } from '../lib';

import { ProvidersActionTypes } from '../constants/actionTypes';
import { ErrorActions, ProvidersActions } from '../actions';

function* watchFetch() {
  yield takeLatest(ProvidersActionTypes.FETCH, function* () {
    try {
      const response = yield call(api.provider.fetch);

      yield put(ProvidersActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ProvidersActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}


export default function* providersSaga() {
  yield all([
    watchFetch()
  ]);
}
