import { AvailabilityActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       {},
  isLoading:  false,
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case AvailabilityActionTypes.GET:
     return { ...newState, isLoading: true };

    case AvailabilityActionTypes.GET_FAILURE:
      return { ...newState, data: initialState.data, isLoading: false };

    case AvailabilityActionTypes.GET_SUCCESS:
      return { ...newState, data: action.payload, isLoading: false };

    default: return state;
  }
};
