import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../forms';

class Zoom extends React.PureComponent {
  static propTypes = {
    onZoom: PropTypes.func
  }

  static defaultProps = {
    onZoom: () => {}
  }

  handleZoom = (isIncreased) => () => {
    this.props.onZoom(isIncreased);
  }

  render() {
    return (
      <React.Fragment>
        <Button
            className='zoom-button out'
            onClick={this.handleZoom(false)}
            size='lg'>
          -
        </Button>

        <Button
            className='zoom-button in'
            onClick={this.handleZoom(true)}
            size='lg'>
          +
        </Button>
      </React.Fragment>
    );
  }
}

export default Zoom;
