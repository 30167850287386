/* eslint-disable immutable/no-let */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { isEmpty } from 'lodash';

import { Button } from '../forms';
import { Input } from '../inputs';
import { Error } from '../errors';
import { UserAvatar } from '../users';
import { Validator } from '../../lib';
import { InstructorNoAvatar } from '../../images';

class AddCommentModal extends React.PureComponent {
  static propTypes = {
    createComment: PropTypes.func.isRequired,
    instructor:    PropTypes.object.isRequired,
    isOpen:        PropTypes.bool,
    onToggle:      PropTypes.func,
    styleTags:     PropTypes.array
  };

  static defaultProps = {
    isOpen:    false,
    onToggle:  () => {},
    styleTags: []
  };

  initialComment = {
    activity:             '',
    activityId:           '',
    instructorStyleTagId: '',
    location:             '',
    locationId:           '',
    privateComment:       '',
    publicComment:        ''
  };

  state = {
    comment: this.initialComment,

    errors:                    {},
    isPrivateCommentCollapsed: true
  };

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      comment: {
        ...prevState.comment,
        [inputName]: value
      }
    }));
  };

  handleClick = (id) => (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      comment: {
        ...prevState.comment,
        instructorStyleTagId: id
      }
    }));
  };

  handleSelect = (inputName) => (option) => {
    this.setState((prevState) => ({
      comment: {
        ...prevState.comment,
        [inputName]: option.value
      }
    }));
  };

  handleSubmit = () => {
    const { comment } = this.state;
    const { username } = this.props.instructor;

    this.handleValidate().then(() => {
      if (!this.state.isPrivateCommentCollapsed) {
        this.props.createComment({ comment: {
            body:                 comment.privateComment,
            instructorStyleTagId: comment.instructorStyleTagId,
            private:              true
          }, username });
      }

      this.props.createComment({ comment: {
          body:                 comment.publicComment,
          instructorStyleTagId: comment.instructorStyleTagId,
          private:              false
        }, username });

      return this.handleToggle();
    }).catch((errors) => {
      return this.setState({ errors });
    });
  };

  handleToggle = () => {
    this.setState({ errors: {} }, () => {
      Validator.clear();
      this.props.onToggle();
    });
  };

  handleTogglePrivateComment = () => {
    this.setState((prevState) => ({
      isPrivateCommentCollapsed: !prevState.isPrivateCommentCollapsed
    }));
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const { comment } = this.state;

    let fields = [
      ['instructorStyleTag', Validator.concepts.isFilled, [comment.instructorStyleTagId]],
      ['publicComment',      Validator.concepts.isFilled, [comment.publicComment]]
    ];

    if (!this.state.isPrivateCommentCollapsed) {
      fields = [
        ...fields,
        ['privateComment', Validator.concepts.isFilled, [comment.privateComment]]
      ];
    }

    const errors = Validator.validate(fields);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });


  renderCarret = () => {
    return (
      <span className={classNames('caret', { 'rotate': this.state.isPrivateCommentCollapsed })}>
        &#8963;
      </span>
    );
  }

  render() {
    const { instructor } = this.props;
    const photo = instructor.photo ? instructor.photo.sm : InstructorNoAvatar;
    const selectedTag = this.state.comment.instructorStyleTagId;

    return (
      <Modal
          centered
          isOpen={this.props.isOpen}
          toggle={this.handleToggle}>
        <ModalHeader toggle={this.handleToggle}>
            Write a&nbsp;<span className='text-primary'>Testimonial</span>
        </ModalHeader>
        <ModalBody>
          <Container
              className='testimonials'
              fluid>
            <div className='testimonials__avatar'>
              <UserAvatar
                  alt={instructor.firstName + ' ' + instructor.lastName}
                  className='mb-3'
                  effect='blur'
                  size='lg'
                  src={photo} />
              <h4 className='mt-2 testimonials__full-name'>{instructor.firstName + ' ' + instructor.lastName}</h4>
            </div>
            <div className='errors'>
              { !!this.state.errors.instructorStyleTag &&
                <Error>Please select instructor style</Error>
              }
            </div>
            <Form className='testimonials__form'>
              <FormGroup>
                <Label
                    className='testimonials__label-text'
                    for='styleTags'>
                  Select {instructor.firstName}&apos;s #1 quality
                </Label>

                <div className='testimonials__tags'>
                  { this.props.styleTags && this.props.styleTags.map((tag) => (
                    <Button
                        className='btn-tag mr-1 mb-1 testimonials__tag'
                        color='testimonial-color'
                        isOutline={selectedTag !== tag.id}
                        key={tag.id}
                        onClick={this.handleClick(tag.id)}
                        onKeyPress={this.handleClick(tag.id)}
                        size='sm'>
                      {tag.name}
                    </Button>
                  ))}
                </div>
              </FormGroup>

              <FormGroup>
                <Label
                    className='testimonials__label-text'
                    for='publicComment'>
                  Write your testimonial
                </Label>
                <Input
                    id='publicComment'
                    isInvalid={!!this.state.errors.publicComment}
                    maxLength={250}
                    name='publicComment'
                    onChange={this.handleChange}
                    placeholder='Type here'
                    rows='5'
                    type='textarea'
                    value={this.state.comment.publicComment} />
              </FormGroup>

              <FormGroup className='d-none'>
                <Label
                    for='privateComment'
                    onClick={this.handleTogglePrivateComment}>
                  <strong>
                    Or send {instructor.firstName} a direct private message {this.renderCarret()}
                  </strong>
                </Label>

                { !this.state.isPrivateCommentCollapsed &&
                  <Input
                      id='privateComment'
                      isInvalid={!!this.state.errors.privateComment}
                      maxLength={250}
                      name='privateComment'
                      onChange={this.handleChange}
                      placeholder='Type your private note here...'
                      rows='5'
                      type='textarea'
                      value={this.state.comment.privateComment} />
                }
              </FormGroup>
            </Form>
          </Container>
        </ModalBody>
        <ModalFooter>
          <Button
              color='primary'
              isBlock
              onClick={this.handleSubmit}
              size='lg'>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddCommentModal;
