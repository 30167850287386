import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../../forms';

class DeletePrivateGroupClass extends React.PureComponent {
  static propTypes = {
    deletePrivateGroupClass:          PropTypes.func.isRequired,
    deleteRecurringPrivateGroupClass: PropTypes.func.isRequired,
    note:                             PropTypes.string,
    onClose:                          PropTypes.func.isRequired,
    onSuccess:                        PropTypes.func.isRequired,
    privateGroupClass:                PropTypes.object.isRequired
  };

  static defaultProps = {
    note: ''
  }

  handleDeleteAll = () => {
    const { privateGroupClass } = this.props;

    this.props.deleteRecurringPrivateGroupClass({ privateGroupClass });
    this.props.onClose();
    this.props.onSuccess();
  };

  handleDeleteThis = () => {
    const { note, privateGroupClass } = this.props;

    this.props.deletePrivateGroupClass({ note, privateGroupClass });
    this.props.onClose();
    this.props.onSuccess();
  };

  render() {
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            <span>
              This is part of a recurring event series, would you like to  <span className='text-danger'>delete
              </span>&nbsp;just <span className='text-danger'>this instance</span> of the event or&nbsp;
              <span className='text-danger'>this instance and all future</span> events in the series?
            </span>
          </CardText>

          <div className='actions'>
            <Button
                color='danger'
                isOutline
                onClick={this.handleDeleteThis}>
              This Event
            </Button>

            <Button
                color='danger'
                onClick={this.handleDeleteAll}>
              This & Future Events
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default DeletePrivateGroupClass;
