/* eslint-disable immutable/no-let */
import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormFeedback, FormGroup, FormText, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { isEmpty } from 'lodash';
import { Scroller, Validator } from '../../lib';

import { Autocompleter, Button } from '../forms';
import { Input } from '../inputs';
import { LocationSelect } from '../inputs';
import { AutocompleterContainer } from '../../containers';

class AddLocationModal extends React.PureComponent {
  static propTypes = {
    isOpen:          PropTypes.bool.isRequired,
    isProfileCreate: PropTypes.bool,
    location:        PropTypes.object.isRequired,
    onAddressChange: PropTypes.func.isRequired,
    onChange:        PropTypes.func.isRequired,
    onStudioSelect:  PropTypes.func.isRequired,
    onSubmit:        PropTypes.func.isRequired,
    onToggle:        PropTypes.func.isRequired
  };

  static defaultProps = {
    isProfileCreate: false
  };

  state = {
    errors: {},

    query: {
      address: '',
      studio:  ''
    }
  };

  handleChange = (inputName) => (value) => {
    this.setState({
      query: {
        [inputName]: value
      }
    });
  };

  handleAddressSelect = (option) => {
    const { address, country, city, state, zipCode } = option;
    this.setState((prevState) => ({
      query: {
        ...prevState.query,
        address: option.name
      }
    }));
    this.props.onAddressChange({ address, city, country, state, zipCode });
  };

  handleStudioSelect = (option) => {
    this.props.onStudioSelect(option.id, 'studioId');
    if (this.props.isProfileCreate) {
      this.props.onStudioSelect(option.label, 'studioName');
    }
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const { location } = this.props;

    const errors = Validator.validate([
      ['name',  Validator.concepts.isFilled, [location.name]],
      ['address', Validator.concepts.isFilled, [location.address]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  });

  handleSubmit = () => {
    return this.handleValidate().then(() => {
      return this.props.onSubmit();
    });
  };

  render() {
    const { location } = this.props;

    return (
      <Modal
          className='modal-location-create'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>

        <ModalHeader toggle={this.props.onToggle}>
          Add new studio/gym
        </ModalHeader>

        <ModalBody>
          <Form className='form-default'>
            <FormGroup>
              <Label
                  className='required'
                  for='input-name'>
                Location name
              </Label>
              <Input
                  id='name'
                  isInvalid={!!this.state.errors.name}
                  name='name'
                  onChange={this.props.onChange}
                  placeholder='14th Street'
                  type='text'
                  value={location.name} />
              <FormFeedback>{this.state.errors.name}</FormFeedback>
              <FormText>
                You can either type &#34;My Gym&#34; if it is a single location gym, or &#34;14th Street&#34; if it is
                part of a studio brand like SoulCycle
              </FormText>
            </FormGroup>

            <FormGroup>
              <Label for='input-studio'>
                Add location to studio / gym brand
              </Label>
              <AutocompleterContainer>
                <Autocompleter
                    name='studio'
                    onChange={this.handleChange('studio')}
                    onSelect={this.handleStudioSelect}
                    subject='studios'
                    value={this.state.query.studio} />
              </AutocompleterContainer>
              <FormText>
                You can leave it blank
              </FormText>
            </FormGroup>

            <FormGroup>
              <Label
                  className='required'
                  for='input-address'>
                Address
              </Label>
              <LocationSelect
                  isInvalid={!!this.state.errors.address}
                  name='address'
                  onSelect={this.handleAddressSelect}
                  placeholder='Begin search...' />
              <FormFeedback>{this.state.errors.address}</FormFeedback>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Button
              isBlock
              onClick={this.handleSubmit}
              size='lg'
              type='submit'>
            Add studio/gym
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddLocationModal;
