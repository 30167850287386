import { InvitesActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: InvitesActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: InvitesActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: () => ({
    type: InvitesActionTypes.CREATE_SUCCESS
  })
};
