import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { LocationActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions, LocationActions } from '../actions';

function* watchFollow() {
  yield takeLatest(LocationActionTypes.FOLLOW, function* () {
    try {
      yield put(LoaderActions.START_LOADING());

      const location = yield select((state) => state.location.data);
      yield call(api.location.follow.create, { id: location.id });

      yield put(LocationActions.FOLLOW_SUCCESS());
      toastr.success('Studio favorited!');
    } catch (error) {
      yield put(LocationActions.FOLLOW_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFollowSuccess() {
  yield takeLatest(LocationActionTypes.FOLLOW_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFollowFailure() {
  yield takeLatest(LocationActionTypes.FOLLOW_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchGet() {
  yield takeLatest(LocationActionTypes.GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.location.get, payload);

      yield put(LocationActions.GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(LocationActions.GET_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchGetSuccess() {
  yield takeLatest(LocationActionTypes.GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchGetFailure() {
  yield takeLatest(LocationActionTypes.GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUnfollow() {
  yield takeLatest(LocationActionTypes.UNFOLLOW, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const location = yield select((state) => state.location.data);
      yield call(api.location.follow.destroy, { id: location.id });

      yield put(LocationActions.UNFOLLOW_SUCCESS());
    } catch (error) {
      yield put(LocationActions.UNFOLLOW_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUnfollowSuccess() {
  yield takeLatest(LocationActionTypes.UNFOLLOW_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUnfollowFailure() {
  yield takeLatest(LocationActionTypes.UNFOLLOW_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* locationSaga() {
  yield all([
    watchFollow(),
    watchFollowSuccess(),
    watchFollowFailure(),
    watchGet(),
    watchGetSuccess(),
    watchGetFailure(),
    watchUnfollow(),
    watchUnfollowSuccess(),
    watchUnfollowFailure()
  ]);
}
