import React from 'react';
import PropTypes from 'prop-types';
import { Nav, NavItem } from 'reactstrap';
import { NavLink, withRouter } from 'react-router-dom';
import { find } from 'lodash';

import { instructorRoutes } from '../../../../lib';

class InstructorMenu extends React.PureComponent {
  static propTypes = {
    instructor:  PropTypes.object.isRequired,
    location:    PropTypes.object.isRequired,
    match:       PropTypes.object.isRequired,
    onLinkClick: PropTypes.func
  }

  static defaultProps = {
    onLinkClick: () => {}
  }

  render () {
    const { instructor, match, location } = this.props;

    const rootPath = match.url === '/';
    const path = rootPath ? location.pathname : location.pathname.replace(match.url, '');
    const currentRoute = find(Object.values(instructorRoutes), ['path', path]);
    const currentSection = currentRoute ? currentRoute.index : 0;

    const pagePath = (route) => {
      return rootPath ? route : match.url + route;
    };

    return (
      <div className='navbar-instructor-menu'>
        <Nav navbar>
          <NavItem>
            <NavLink
                className='nav-link'
                exact
                to={{
                  pathname: `${match.url}`,
                  state:    { previousSection: currentSection }
                }}>
              Home
            </NavLink>
          </NavItem>

          { instructor.workoutPageEnabled &&
            <NavItem>
              <NavLink
                  className='nav-link'
                  to={{
                    pathname: pagePath(instructorRoutes.WORKOUT.path),
                    state:    { previousSection: currentSection }
                  }}>
                Workout
              </NavLink>
            </NavItem>
          }

          { instructor.membershipsEnabled &&
            <NavItem onClick={this.props.onLinkClick}>
              <NavLink
                  className='nav-link'
                  to={{
                    pathname: pagePath(instructorRoutes.PACKAGES.path),
                    state:    { previousSection: currentSection }
                  }}>
                Packages
              </NavLink>
            </NavItem>
          }

          { instructor.stylePageEnabled &&
            <NavItem>
              <NavLink
                  className='nav-link'
                  to={{
                    pathname: pagePath(instructorRoutes.STYLE.path),
                    state:    { previousSection: currentSection }
                  }}>
                Style
              </NavLink>
            </NavItem>
          }

          { instructor.aboutPageEnabled &&
            <NavItem>
              <NavLink
                  className='nav-link'
                  to={{
                    pathname: pagePath(instructorRoutes.ABOUT.path),
                    state:    { previousSection: currentSection }
                  }}>
                About
              </NavLink>
            </NavItem>
          }
        </Nav>
      </div>
    );
  }
}

export default withRouter(InstructorMenu);
