/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { eq } from 'lodash';
import { Button as ButtonBootstrap } from 'reactstrap';
import { Spinner } from 'reactstrap';

class Button extends React.PureComponent {
  static propTypes = {
    children:   PropTypes.node,
    className:  PropTypes.string,
    color:      PropTypes.string,
    href:       PropTypes.string,
    id:         PropTypes.string,
    innerRef:   PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
    isActive:   PropTypes.bool,
    isBlock:    PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLoading:  PropTypes.bool,
    isOutline:  PropTypes.bool,
    onClick:    PropTypes.func,
    size:       PropTypes.string,
    style:      PropTypes.object,
    tag:        PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string,
      PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func }),
      PropTypes.arrayOf(PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
        PropTypes.shape({ $$typeof: PropTypes.symbol, render: PropTypes.func })
      ]))
    ]),
    to:   PropTypes.string,
    type: PropTypes.oneOf([
      'button',
      'submit',
      'reset'
    ])
  }

  static defaultProps = {
    children:   null,
    className:  '',
    color:      'primary',
    href:       '',
    id:         null,
    innerRef:   null,
    isActive:   false,
    isBlock:    false,
    isDisabled: false,
    isLoading:  false,
    isOutline:  false,
    onClick:    () => {},
    size:       '',
    style:      null,
    tag:        'button',
    to:         '',
    type:       null
  }

  render() {
    return (
      <ButtonBootstrap
          {...this.props.tag === 'a' && { href: this.props.href }}
          active={this.props.isActive}
          block={this.props.isBlock}
          className={this.props.className}
          color={this.props.color}
          disabled={this.props.isDisabled || this.props.isLoading}
          id={this.props.id}
          innerRef={this.props.innerRef}
          onClick={this.props.onClick}
          outline={this.props.isOutline}
          size={this.props.size}
          tag={this.props.tag}
          {...this.props.to && { to: this.props.to }}
          style={this.props.style}
          type={this.props.type}>
        { this.props.isLoading &&
          <Spinner
              className={classNames({ 'm-1': eq(this.props.size, 'lg') })}
              size='sm' />
        }
        {this.props.children}
      </ButtonBootstrap>
    );
  }
}

export default Button;
