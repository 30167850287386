import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'reactstrap';
import { Link } from 'react-router-dom';

import { routes } from '../../lib';

class ProBadge extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string
  }

  static defaultProps = {
    className: ''
  }

  render() {
    return (
      <Link to={routes.PLANS_AND_BILLING}>
        <Badge
            className={this.props.className + ' badge-pro'}
            color='primary'>
          PRO
        </Badge>
      </Link>
    );
  }
}

export default ProBadge;
