import React from 'react';
import PropTypes from 'prop-types';

class Info extends React.PureComponent {
  static propTypes = {
    challenge:  PropTypes.object.isRequired,
    instructor: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    if (state.challenge.id) return state;

    const challenge = {
      ...props.challenge,
      premium: {
        live:     props.challenge.premium.live     == 'true',
        onDemand: props.challenge.premium.onDemand == 'true'
      }
    };

    return { challenge };
  }

  state = {
    challenge: {}
  }

  render() {
    const { currency } = this.props.instructor;
    const { challenge } = this.state;

    const isForPrivateSession = challenge.challengeFor == 'privateSession';

    return (
      <div>
        <div>
          <span className='modal__small-text modal__small-text_grey'>
            { challenge.premium.onDemand && challenge.premium.live &&
              'Valid with Live Stream and On-Demand classes'
            }

            { challenge.premium.onDemand && !challenge.premium.live &&
              'Valid with On-Demand classes.'
            }

            { !challenge.premium.onDemand && challenge.premium.live  &&
              'Valid with Live Stream classes.'
            }

            { isForPrivateSession &&
              'Valid with Private Sessions.'
            }
          </span>
        </div>

        <span className='modal__default-text modal__default-text_blue modal__default-text_bold'>
          Cost {challenge.cost.toLocaleString('en-GB', { currency, style: 'currency' })}
        </span>
      </div>
    );
  }
}

export default Info;
