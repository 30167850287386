import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { Moment } from '../../lib';
import { Button } from '../forms';
import { Input } from '../inputs';

class DeclineBookingRequestModal extends React.PureComponent {
  static propTypes = {
    bookingRequest: PropTypes.object.isRequired,
    isOpen:         PropTypes.bool,
    onDecline:      PropTypes.func.isRequired,
    onToggle:       PropTypes.func
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  state = {
    note: ''
  }

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value });
  }

  handleDecline = () => {
    this.props.onDecline(this.props.bookingRequest, this.state.note);
    this.props.onToggle();
  }

  render() {
    const { bookingRequest } = this.props;


    return (
      <Modal
          className='modal-event'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          Decline Booking Request
        </ModalHeader>

        <ModalBody>
          <h6 className='title-details'>
            Booking Details
          </h6>

          <div className='event-details'>
            <div className='name'>{bookingRequest.client.name}</div>

            <div className='date'>
              {new Moment(bookingRequest.startsAt, 'YYYY-MM-DDhh:mm:ss').format('ddd, MMM D YYYY')}
            </div>

            <div className='time'>
              {new Moment(bookingRequest.startsAt, 'YYYY-MM-DDhh:mm:ss').format('h:mm A')} - &nbsp;
              {bookingRequest.privateSessionType.duration}m ({bookingRequest.privateSessionType.name})
            </div>
          </div>

          <Form className='form-default'>
            <FormGroup>
              <Label
                  className='text-muted'
                  for='input-note'>
                Edit Reply
              </Label>

              <Input
                  id='note'
                  name='note'
                  onChange={this.handleChange}
                  placeholder='Type your information here'
                  rows='4'
                  type='textarea'
                  value={this.state.note} />
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <Row>
            <Col
                lg={6}
                md={12}>
              <Button
                  color='danger'
                  isBlock
                  isOutline
                  onClick={this.handleDecline}
                  size='lg'>
                Decline Booking
              </Button>
            </Col>
            <Col
                lg={6}
                md={12}>
              <Button
                  className='mt-2 mt-lg-0'
                  color='primary'
                  isBlock
                  onClick={this.props.onToggle}
                  size='lg'>
                Close Window
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default DeclineBookingRequestModal;
