/* global process */
/* eslint-disable immutable/no-let */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

import { Button, Creatable, Form } from '../';
import { AddLocationModal } from '../../modals';
import { Scroller, Validator } from '../../../lib';
import { AutocompleterContainer } from '../../../containers';
import { Alert } from '../../errors';

class LocationForm extends React.PureComponent {
  static propTypes = {
    data:        PropTypes.object,
    isLoading:   PropTypes.bool,
    isNewRecord: PropTypes.bool,
    onDelete:    PropTypes.func,
    onSubmit:    PropTypes.func
  };

  static defaultProps = {
    data: {
      address:       '',
      isOwner:       true,
      location:      '',
      locationId:    '',
      name:          '',
      secondaryName: ''
    },
    isLoading:   true,
    isNewRecord: true,
    onDelete:    () => {},
    onSubmit:    () => {}
  };

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(state.location)) {
      return state;
    }

    if (!props.isLoading) {
      return {
        isNewRecord: props.isNewRecord,
        isOwner:     props.isNewRecord ? true : props.data.isOwner,
        isPrivate:   props.isNewRecord ? true : props.data.isOwner,
        location:    props.data
      };
    }

    return null;
  }

  state = {
    errors: {},

    isCreateModalOpen: false,
    isNewRecord:       true,
    isOwner:           true,
    isPrivate:         true,
    location:          {},
    selectedLocation:  {}
  };

  handleToggle = (value) => () => {
    this.setState((prevState) => ({
      ...prevState,

      location: {
        address:          '',
        name:             '',
        secondaryAddress: ''
      }
    }), () => {
      this.setState((prevState) => ({
        ...prevState,
        isPrivate: value
      }));
    });
  };

  handleSelect = (object) => (
    this.setState((prevState) => ({
      location: {
        ...prevState.location,
        name:    object.label,
        placeId: object.value.place_id
      },
      selectedValue: object
    }))
  )


  handleChange = (query, meta) => {
    if (meta.action == 'input-change') {
      this.setState((prevState) => ({
        location: {
          ...prevState.location,
          name: query
      }}));
    }
  };

  handleValidate = () => new Promise((resolve, reject) => {
    const { location, isPrivate } = this.state;

    let errors;
    if (isPrivate) {
      errors = Validator.validate([['name', Validator.concepts.isFilled, [location.name]]]);
    } else {
      errors = Validator.validate([['location', Validator.concepts.isFilled, [location.locationId]]]);
    }
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  });

  handleSubmit = () => {
    const { location } = this.state;

    return this.handleValidate().then(() => (
      this.props.onSubmit({ location })
    ));
  };

  handleDelete = () => {
    const params = { location: { id: this.state.location.id } };
    this.props.onDelete(params);
  };

  handleCreateModal = (value) => {
    this.setState(() => ({
      isCreateModalOpen: !this.state.isCreateModalOpen,
      location:          {
        name: value.label
      }
    }));
  };

  handleAddressSelect = (value) => {
    this.setState((prevState) => ({
      location: {
        ...prevState.location,
        ...value
      }
    }));
  };

  handlePublicSubmit = () => {
    const { location } = this.state;


    this.props.onSubmit({ ...location, createdByUser: true });
  };

  render () {
    const { isPrivate, isNewRecord, location, errors, isOwner, isCreateModalOpen } = this.state;

    return (
      <Form className='form-default'>
        { !isNewRecord && !isOwner &&
          <Alert color='danger'>
            You cannot edit the details of public fitness locations.
            Please contact us if the information needs updating.
          </Alert>
        }

        { ((isPrivate && !isNewRecord) || (!isOwner && !isPrivate && !isNewRecord) || (isPrivate && isNewRecord)) &&
          <React.Fragment>
            <FormGroup>
              <Label
                  className='required'
                  htmlFor='input-name'>
                Location name
              </Label>

              <GooglePlacesAutocomplete
                  apiKey={process.env.GMAPS_API_KEY}
                  selectProps={{
                    blurInputOnSelect: true,
                    className:         'autocompleter',
                    inputValue:        location.name,
                    isDisabled:        !!errors.name,
                    isLoading:         this.props.isLoading,
                    onChange:          this.handleSelect,
                    onInputChange:     this.handleChange,
                    placeholder:       'Start typing...',
                    styles:            { input: (styles) => ({ ...styles, input: { opacity: '1 !important' } }) },
                    value:             this.state.selectedValue
                  }} />
            </FormGroup>
          </React.Fragment>
        }

        { isNewRecord && !isPrivate &&
          <FormGroup className='mt-5'>
            <Label
                className='required'
                htmlFor='input-location'>
              Search onPodio Database
            </Label>
            <AutocompleterContainer>
              <Creatable
                  isInvalid={!!this.state.errors.location}
                  name='location'
                  onCreate={this.handleCreateModal}
                  onSelect={this.handleSelect}
                  subject='locations'
                  value={this.state.selectedLocation} />
            </AutocompleterContainer>
          </FormGroup>
        }

        { isNewRecord &&
          <Button
              className='mt-lg-5 mt-3'
              isBlock
              onClick={this.handleSubmit}
              size='lg'
              type='button'>
            {isPrivate ? 'Create & Add' : 'Add'}
          </Button>
        }

        { !isNewRecord &&
          <Row className='mt-lg-5 mt-3'>
            <Col>
              <Button
                  color='danger'
                  isBlock
                  isOutline
                  onClick={this.handleDelete}
                  size='lg'
                  type='button'>
                Delete
              </Button>
            </Col>
            <Col>
              <Button
                  isBlock
                  isDisabled={!isOwner}
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Update
              </Button>
            </Col>
          </Row>
        }
        <AddLocationModal
            isOpen={isCreateModalOpen}
            location={location}
            onAddressChange={this.handleAddressSelect}
            onChange={this.handleChange}
            onStudioSelect={this.handleChange}
            onSubmit={this.handlePublicSubmit}
            onToggle={this.handleCreateModal} />
      </Form>
    );
  }
}

export default LocationForm;
