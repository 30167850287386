import React from 'react';
import PropTypes  from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../helpers';
import { PaymentsPage } from '../components/pages';
import { OAuthActions, SegmentActions, UserActions } from '../actions';

class PaymentsContainer extends React.PureComponent {
  static propTypes = {
    fetchUser: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.fetchUser();
  }

  render() {
    return (
      <PaymentsPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchUser:     ()     => dispatch(UserActions.FETCH()),
  oAuthLogout:   (data) => dispatch(OAuthActions.LOGOUT(data)),
  segmentStripe: (data) => dispatch(SegmentActions.STRIPE_CONNECT(data)),
  segmentZoom:   (data) => dispatch(SegmentActions.ZOOM_CONNECT(data)),
  updateUser:    (data) => dispatch(UserActions.UPDATE(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(PaymentsContainer);
