import { filter, forEach, sortBy } from 'lodash';
import { Moment } from '../lib';

const BOOKINGS_COUNTER = [1, 10, 50, 100];

export default (clients, timeZone) => {
  const currentDate = new Moment().tz(timeZone).format('YYYY-MM-DD');
  const currentYear =  new Moment().format('YYYY');
  const weekRange = Moment.rangeFromInterval('week', 1, currentDate);

  const birthdayDate = (date) => new Moment(date + currentYear, 'DD-MMMM-YYYY');

  const birthdays = filter(clients, (object) => {
    if (!object.birthday || !object.birthday.trim()) return false;

    const date = birthdayDate(object.birthday);

    return weekRange.contains(new Moment(date, 'YYYY-MM-DD').tz(timeZone));
  });

  const bookingMilestones = [];

  forEach(clients, (client) => {
    const sortBookings = sortBy(client.bookings, 'privateGroupClass.startsAt');

    const bookingMilestone = (client, index) => ({
      clientId:   client.id,
      clientName: client.name,
      count:      index + 1,
      date:       new Moment.utc(sortBookings[index].privateGroupClass.startsAt, 'YYYY-MM-DDhh:mm:ssUTC'),
      type:       'booking'
    });

    return filter(sortBookings, (object, index) => (
      BOOKINGS_COUNTER.includes(index + 1) &&
      object.privateGroupClass &&
      weekRange.contains(new Moment(object.privateGroupClass.startsAt, 'YYYY-MM-DD').tz(timeZone)) &&
      bookingMilestones.push(bookingMilestone(client, index))
    ));
  });

  const milestones = () => {
    const birthdayMilestones = birthdays.map((client) => ({
      clientId:   client.id,
      clientName: client.name,
      date:       birthdayDate(client.birthday).format('YYYY-MM-DD'),
      type:       'birthday'
    }));

    const sortMilestones = sortBy([...bookingMilestones, ...birthdayMilestones], 'date');

    return sortMilestones;
  };

  return milestones();
};
