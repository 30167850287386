import React from 'react';
import PropTypes from 'prop-types';
import { Input as InputBootstrap } from 'reactstrap';

class Checkbox extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    id:        PropTypes.string.isRequired,
    innerRef:  PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.func
    ]),
    isChecked:  PropTypes.bool,
    isDisabled: PropTypes.bool,
    name:       PropTypes.string,
    onChange:   PropTypes.func
  }

  static defaultProps = {
    className:  '',
    innerRef:   () => {},
    isChecked:  false,
    isDisabled: false,
    name:       null,
    onChange:   () => {}
  }

  handleChange = () => {
    this.props.onChange(!this.props.isChecked, this.props.name);
  }

  render() {
    const id = 'input-' + this.props.id;

    return (
      <InputBootstrap
          checked={this.props.isChecked}
          className={this.props.className}
          disabled={this.props.isDisabled}
          id={id}
          innerRef={this.props.innerRef}
          name={this.props.name}
          onChange={this.handleChange}
          type='checkbox' />

    );
  }
}

export default Checkbox;
