/* eslint-disable immutable/no-mutation */
/* eslint-disable promise/no-nesting */

import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { Autocompleter, Button, Checkbox, Form, MaskedInput, Radio } from '../forms';
import { DatePicker, Input, TimePicker } from '../inputs';
import { Moment, Validator, api, getTimeZone, routes } from '../../lib';
import { AutocompleterContainer } from '../../containers';
import { weekMask } from '../../constants/masks';
import { Alert } from '../errors';

class AdvancedPrivateSessionModal extends React.PureComponent {
  static propTypes = {
    createPrivateSession:          PropTypes.func,
    createRecurringPrivateSession: PropTypes.func,
    data:                          PropTypes.object,
    instructor:                    PropTypes.object.isRequired,
    isNewRecord:                   PropTypes.bool,
    isOpen:                        PropTypes.bool,
    onApprove:                     PropTypes.func,
    onToggle:                      PropTypes.func,
    user:                          PropTypes.object.isRequired
  }

  static defaultProps = {
    createPrivateSession:          () => {},
    createRecurringPrivateSession: () => {},
    data:                          {},
    isNewRecord:                   false,
    isOpen:                        false,
    onApprove:                     () => {},
    onToggle:                      () => {}
  }

  static getDerivedStateFromProps(props, state) {
    const { data, isNewRecord, user } = props;

    const startsAt = !isNewRecord && new Moment.utc(data.startsAt, 'YYYY-MM-DDhh:mm:ssUTC').tz(user.timeZone);

    const privateSession = !props.isNewRecord ? {
      ...props.data,
      client:               data.client.name,
      clientId:             data.client.id,
      date:                 startsAt.format('YYYY-MM-DD'),
      privateSessionType:   data.privateSessionType.name,
      privateSessionTypeId: data.privateSessionType.id,
      time:                 startsAt.format('hh:mm:ss')
    } : state.privateSession;

    if (!state.isMounted) {
      return {
        ...state,
        isMounted: true,
        privateSession
      };
    }
  }

  state = {
    errors:    {},
    isLoading: false,

    privateSession: {
      client:               '',
      clientId:             '',
      date:                 '',
      digitalLink:          '',
      location:             '',
      locationId:           '',
      locationType:         0,
      meetingId:            '',
      meetingUid:           '',
      numberOfWeeks:        '',
      passcode:             '',
      privateSessionType:   '',
      privateSessionTypeId: '',
      recurring:            false,
      time:                 ''
    }
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      privateSession: {
        ...prevState.privateSession,
        [inputName]: value
      }
    }));
  }

  handleSelect = (inputName) => (option) => {
    this.setState((prevState) => ({
      privateSession: {
        ...prevState.privateSession,
        [inputName]: option.id
      }
    }));
  }

  handleSubmit = () => {
    const { privateSession } = this.state;

    this.handleValidate().then(() => {
      const stamp = [privateSession.date, privateSession.time].join(' ');

      const startsAt = new Moment.tz(stamp, getTimeZone(this.props.user.timeZone)).format();

      if (this.state.privateSession.recurring) {
        this.props.createRecurringPrivateSession({ privateSession: { ...privateSession, startsAt } });
      } else {
        this.props.createPrivateSession({ privateSession: { ...privateSession, startsAt } });
      }

      return this.setState({ privateSession: {} }, this.handleToggle);
    }).catch((errors) => {
      return this.setState({ errors });
    });
  }

  handleToggle = () => {
    this.setState({ errors: {} }, () => {
      Validator.clear();
      this.props.onToggle();
    });
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { privateSession } = this.state;

    const errors = Validator.validate([
      ['date',                 Validator.concepts.isFilled, [privateSession.date]],
      ['time',                 Validator.concepts.isFilled, [privateSession.time]],
      ['client',               Validator.concepts.isFilled, [privateSession.clientId]],
      [
        'location',
        Validator.concepts.isFilled,
        [privateSession.locationId],
        privateSession.locationType != 'offline'
      ],
      [
        'digitalLink',
        Validator.concepts.isFilled,
        [privateSession.digitalLink],
        privateSession.locationType != 'online'
      ],
      ['privateSessionType',   Validator.concepts.isFilled, [privateSession.privateSessionTypeId]],
      ['numberOfWeeks', Validator.concepts.weeksValidator, [privateSession.numberOfWeeks, privateSession.recurring]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  handleCreateMeeting = (e) => {
    e.preventDefault();

    this.setState({ isLoading: true }, () => {
      return api.meeting.create()
        .then((response) => (
          this.setState((prevState) => ({
            isLoading: false,

            privateSession: {
              ...prevState.privateSession,
              digitalLink: response.data.join_url,
              meetingId:   response.data.id,
              meetingUid:  response.data.uid,
              passcode:    response.data.passcode
            }
          }))
        ));
    });
  }

  handleApprove = () => {
    const { privateSession } = this.state;

    return this.handleValidate().then(() => {
      const stamp = [privateSession.date, privateSession.time].join(' ');

      const startsAt = new Moment.tz(stamp, getTimeZone(this.props.user.timeZone)).format();
      return api.instructor.privateSessions.update({ privateSession: { ...privateSession, startsAt } })
        .then(() => (
          this.props.onApprove({ ...privateSession, startsAt })(),
          this.props.onToggle()
        ))
        .catch((errors) => {
          return toastr.error(errors.response.data.message[0]);
        });
    });
  }

  render() {
    const { instructor, isNewRecord, user } = this.props;
    const { privateSession, errors, isLoading } = this.state;

    const isOnline = privateSession.locationType === 'online';

    return (
      <Modal
          className='modal-private-group-class'
          isOpen={this.props.isOpen}
          toggle={this.handleToggle}>
        <ModalHeader toggle={this.handleToggle}>
          {isNewRecord ? 'New' : 'Update'} New Private Session
        </ModalHeader>

        <ModalBody>
          <Form className='form-default'>
            { !instructor.privateSessionsEnabled &&
              <p className='text-muted text-small'>
                Private Sessions are sessions you teach independently.
                These sessions <strong>will not</strong> be added to your fitness page,
                but to allow clients to request a session directly enable it via My Settings.
              </p>
            }

            <FormGroup>
              <Label
                  className='required'
                  for='input-session-type'>Session Type</Label>

              <AutocompleterContainer>
                <Autocompleter
                    hasInitialValue
                    instructor={instructor}
                    isDisabled={!isNewRecord}
                    isInvalid={!!errors.privateSessionType}
                    name='privateSessionType'
                    onChange={this.handleChange}
                    onSelect={this.handleSelect('privateSessionTypeId')}
                    placeholder='Select Session Type'
                    ref={(ref) => this.sessionTypeInput = ref}
                    subject='privateSessionTypes'
                    value={privateSession.privateSessionType}>
                  { this.sessionTypeInput && !this.sessionTypeInput.state.options.length &&
                    <small className='text-danger'>
                      Create your first Private Session Type. Go to&nbsp;
                      <Link
                          className='text-primary'
                          to={routes.SETTINGS_WORKOUT}>
                        Workout Page Settings
                      </Link>
                    </small>
                  }
                </Autocompleter>
              </AutocompleterContainer>
            </FormGroup>

            <FormGroup>
              <Label
                  className='required'
                  for='input-client'>Client</Label>

              <AutocompleterContainer>
                <Autocompleter
                    hasInitialValue
                    id='client'
                    instructor={instructor}
                    isDisabled={!isNewRecord}
                    isInvalid={!!errors.client}
                    name='client'
                    onChange={this.handleChange}
                    onSelect={this.handleSelect('clientId')}
                    placeholder='Start typing client name'
                    ref={(ref) => this.clientInput = ref}
                    subject='clients'
                    value={privateSession.client}>
                  { this.clientInput && !this.clientInput.state.options.length &&
                    <small className='text-danger'>
                      You do not have any Clients. Go to&nbsp;
                      <Link
                          className='text-primary'
                          to={routes.CLIENTS}>
                        My Clients
                      </Link>
                    </small>
                  }
                </Autocompleter>
              </AutocompleterContainer>
            </FormGroup>

            <FormGroup>
              <Label
                  className='required'
                  for='input-fee'>
                Location Type
              </Label>

              <div className='d-flex'>
                <div className='custom-control custom-radio w-50'>
                  <Radio
                      className='custom-control-input'
                      id='offline'
                      isChecked={!isOnline}
                      isDisabled={!isNewRecord}
                      name='locationType'
                      onChange={this.handleChange}
                      value='offline' />
                  <Label
                      className='custom-control-label'
                      htmlFor='input-offline'>
                    In Person
                  </Label>
                </div>

                <div className='custom-control custom-radio w-50'>
                  <Radio
                      className='custom-control-input'
                      id='online'
                      isChecked={isOnline}
                      isDisabled={!isNewRecord}
                      name='locationType'
                      onChange={this.handleChange}
                      value='online' />
                  <Label
                      className='custom-control-label'
                      htmlFor='input-online'>
                    Virtual
                  </Label>
                </div>
              </div>
            </FormGroup>

            { !isOnline &&
              <FormGroup>
                <Label
                    className='required'
                    for='input-location'>Location</Label>

                <AutocompleterContainer>
                  <Autocompleter
                      hasInitialValue
                      instructor={instructor}
                      isInvalid={!!errors.location}
                      name='location'
                      onChange={this.handleChange}
                      onSelect={this.handleSelect('locationId')}
                      placeholder='Select studio location'
                      ref={(ref) => this.locationInput = ref}
                      subject='locations'
                      value={privateSession.location}>
                    { this.locationInput && !this.locationInput.state.options.length &&
                      <small className='text-danger'>
                        You do not have any Locations. Go to&nbsp;
                        <Link
                            className='text-primary'
                            to={routes.LOCATIONS}>
                          My Locations
                        </Link>
                      </small>
                    }
                  </Autocompleter>
                </AutocompleterContainer>
              </FormGroup>
            }

            { !isNewRecord &&
              <Alert color='info'>
                <p className='m-0 text-center'>
                  Set virtual link, please!
                </p>
              </Alert>
            }

            { isOnline &&
              <React.Fragment>
                <FormGroup>
                  <a
                      className={classNames('text-sm float-right', {
                        'disabled':     !user.oauth.zoom,
                        'text-muted':   !user.oauth.zoom,
                        'text-primary': user.oauth.zoom
                      })}
                      href='#'
                      onClick={this.handleCreateMeeting}>
                    Create Zoom Link
                  </a>
                  <Label
                      className='required'
                      for='input-digitalLink'>
                    Digital Link
                  </Label>

                  <Input
                      id='digitalLink'
                      isInvalid={!!errors.digitalLink}
                      name='digitalLink'
                      onChange={this.handleChange}
                      value={privateSession.digitalLink} />
                </FormGroup>

                <Row form>
                  <Col>
                    <FormGroup>
                      <Label for='input-meetingUid'>Meeting Id</Label>

                      <Input
                          id='meetingUid'
                          name='meetingUid'
                          onChange={this.handleChange}
                          value={privateSession.meetingUid} />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label for='input-duration'>Passcode</Label>

                      <Input
                          id='passcode'
                          name='passcode'
                          onChange={this.handleChange}
                          value={privateSession.passcode} />

                    </FormGroup>
                  </Col>
                </Row>
              </React.Fragment>
            }

            <Row form>
              <Col sm={8}>
                <FormGroup>
                  <Label
                      className='required'
                      for='input-date'>Date</Label>

                  <DatePicker
                      id='date'
                      isDisabled={!isNewRecord}
                      isInvalid={!!errors.date}
                      name='date'
                      onChange={this.handleChange}
                      value={privateSession.date} />
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup>
                  <Label
                      className='required'
                      for='input-time'>Start Time</Label>

                  <TimePicker
                      id='time'
                      isDisabled={!isNewRecord}
                      isInvalid={!!errors.time}
                      name='time'
                      onChange={this.handleChange}
                      value={privateSession.time} />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label>Recur Class Weekly?</Label>

              <div className='d-flex'>
                <div className='custom-control custom-checkbox checkbox-lg'>
                  <Checkbox
                      className='custom-control-input'
                      id='recurring'
                      isChecked={privateSession.recurring}
                      isDisabled={!isNewRecord}
                      name='recurring'
                      onChange={this.handleChange} />
                  <Label
                      className='custom-control-label'
                      htmlFor='input-recurring' />
                </div>

                <MaskedInput
                    className='ml-2'
                    id='numberOfWeeks'
                    inputMode='numeric'
                    isDisabled={!privateSession.recurring}
                    isInvalid={!!errors.numberOfWeeks}
                    mask={weekMask}
                    name='numberOfWeeks'
                    onChange={this.handleChange}
                    placeholder=' weeks'
                    type='number'
                    value={String(privateSession.numberOfWeeks)} />
              </div>
            </FormGroup>

            { isNewRecord &&
              <Button
                  className='mt-lg-4 mt-3'
                  color='primary'
                  isBlock
                  isLoading={isLoading}
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Create
              </Button>
            }

            { !isNewRecord &&
              <Button
                  className='mt-lg-4 mt-3'
                  color='success'
                  isBlock
                  isLoading={isLoading}
                  onClick={this.handleApprove}
                  size='lg'
                  type='button'>
                Approve
              </Button>
            }
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export default AdvancedPrivateSessionModal;
