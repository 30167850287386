export default {
  DAY_VIEW: {
    eventHeight: 72,
    groupFormat: 'hhA'
  },
  DEFAULTS: {
    dayPicker: {
      step: {
        next: 1,
        prev: -1
      },
      type: {
        day:   'days',
        month: 'months',
        week:  'weeks'
      }
    },
    views: {
      day: {
        rowHeight: 72
      }
    }
  },
  EVENTS: {
    duration: [30, 45, 60, 80, 120]
  },
  FORMATS: {
    datePicker: {
      day: {
        desktop: 'dddd | MMM D, YYYY',
        mobile:  'dddd | MMM D, YYYY'
      },
      month: {
        desktop: 'MMMM YYYY',
        mobile:  'MMM YYYY'
      },
      week: {
        desktop: ['MMM D', 'MMM D, YYYY'],
        mobile:  ['MMM D', 'MMM D, YYYY']
      }
    },
    day: {
      dayTimes: {
        group: 'hhA',
        label: 'h A'
      }
    },
    month: {
      days: {
        group: '',
        label: ''
      },
      weekDays: {
        group: '',
        label: ''
      }
    },
    time: {
      desktop: 'LT',
      mobile:  'h A'
    },
    week: {
      dayTimes: {
        group: 'hhA',
        label: 'h A'
      },
      weekDays: {
        group: '',
        label: ''
      }
    }
  },
  SELECT_STYLE: {
    container: (provided) => ({
      ...provided,
      maxHeight: 33
    }),
    control: (provided) => ({
      ...provided,
      maxHeight: 33,
      minHeight: 33
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: 6
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 10
    })
  },
  VIEWS: [
    { icon: 'calendar-day', label: 'Day', value: 'day' },
    { icon: 'calendar-week', label: 'Week', value: 'week' }
    /*{ icon: 'calendar-alt', label: 'Month', value: 'month' }*/
  ]
};
