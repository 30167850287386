import React from 'react';
import PropTypes from 'prop-types';
import { chain, isEmpty } from 'lodash';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

class ContactsDefault extends React.PureComponent {
  static propTypes = {
    instructor: PropTypes.object.isRequired
  }

  render() {
    const { instructor } = this.props;

    const isSocialEmpty = chain(instructor.socialProfiles).filter((value) => !isEmpty(value)).isEmpty().value();

    return (
      <div className='instructor-contacts'>
        <ul className='contacts-list'>
          { instructor.phone &&
            <li>
              <span>Phone</span>
              <a
                  className='text-decoration-none d-inline-block text-truncate'
                  href={'tel:' + instructor.phone}>
                {instructor.phone}
              </a>
            </li>
          }

          { instructor.email &&
            <li>
              <span>Email</span>
              <a
                  className='text-decoration-none d-inline-block text-truncate'
                  href={'mailto:' + instructor.email}>
                {instructor.email}
              </a>
            </li>
          }

          { instructor.website &&
            <li>
              <span>Website</span>
              <a
                  className='text-decoration-none d-inline-block text-truncate'
                  href={`//${instructor.website}`}
                  rel='noopener noreferrer'
                  target='_blank'>
                {instructor.website}
              </a>
            </li>
          }

          { !isSocialEmpty &&
            <li>
              <span>Social</span>
              <div className='links-wrapper'>
                { instructor.socialProfiles.facebook &&
                  <a
                      href={`https://facebook.com/${instructor.socialProfiles.facebook}`}
                      rel='noopener noreferrer'
                      target='_blank'>
                    <Icon
                        className='mr-3'
                        fixedWidth
                        icon={['fab', 'facebook']}
                        size='lg'  />
                  </a>
                }

                { instructor.socialProfiles.twitter &&
                  <a
                      href={`https://twitter.com/${instructor.socialProfiles.twitter}`}
                      rel='noopener noreferrer'
                      target='_blank'>
                    <Icon
                        className='mr-3'
                        fixedWidth
                        icon={['fab', 'twitter']}
                        size='lg' />
                  </a>
                }

                { instructor.socialProfiles.instagram &&
                  <a
                      href={`https://instagram.com/${instructor.socialProfiles.instagram}`}
                      rel='noopener noreferrer'
                      target='_blank'>
                    <Icon
                        className='mr-3'
                        fixedWidth
                        icon={['fab', 'instagram']}
                        size='lg' />
                  </a>
                }

                { instructor.socialProfiles.linkedin &&
                  <a
                      href={`https://linkedin.com/in/${instructor.socialProfiles.linkedin }`}
                      rel='noopener noreferrer'
                      target='_blank'>
                    <Icon
                        className='mr-3'
                        fixedWidth
                        icon={['fab', 'linkedin']}
                        size='lg' />
                  </a>
                }
              </div>
            </li>
          }
        </ul>
      </div>
    );
  }
}

export default ContactsDefault;
