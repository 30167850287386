import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../../../components/forms';
import { AddClassPackModalContainer } from '../../../containers/portal';
import { ClassPack } from './';

class ClassPacks extends React.PureComponent {
  static propTypes = {
    classPacks:      PropTypes.array,
    updateClassPack: PropTypes.func.isRequired
  }

  static defaultProps = {
    classPacks: []
  }

  static getDerivedStateFromProps(props, state) {
    const { classPacks } = props;

    if (!state.isMounted) {
      return { classPacks };
    }
  }

  state = {
    isModalOpen: false,
    isMounted:   false
  }

  handleOpenModal = () => (
    this.setState((prevState) => ({
      ...prevState,
      isModalOpen: !prevState.isModalOpen
    }))
  )

  renderEmpty = () => (
    <div className='empty'>
      <p>No active class packs.</p>
    </div>
  );

  render() {
    const { classPacks } = this.state;

    return (
      <React.Fragment>
        <div className='main-row'>
          <span className='title'>Class Packages</span>

          <Button
              className='button'
              color='primary'
              isOutline
              onClick={this.handleOpenModal}
              size='sm'>
            <span>Add Class Pack</span>
          </Button>
        </div>

        {!!classPacks.length && classPacks.map((classPack) => (
          <ClassPack
              classPack={classPack}
              key={classPack.id}
              updateClassPack={this.props.updateClassPack} />
        ))}

        {!classPacks.length && this.renderEmpty()}

        <AddClassPackModalContainer
            isOpen={this.state.isModalOpen}
            onToggle={this.handleOpenModal} />
      </React.Fragment>
    );
  }
 }

export default ClassPacks;
