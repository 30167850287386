import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../../helpers';
import { SettingsNotificationsPage } from '../../components/pages';
import { ProfileActions } from '../../actions';

const mapStateToProps = (state) => ({
  instructor: state.profile.data
});

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (data) => dispatch(ProfileActions.UPDATE(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(SettingsNotificationsPage);
