import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { mapValues } from 'lodash';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';

import { Cable } from '../cable';
import { getTimeZone, routes } from '../../lib';
import { OAuthAccount } from '../oauth';
import { Heading, Panel } from '../layout/pages';
import { EditAccountForm } from './account';

class EditAccountPage extends React.PureComponent {
  static propTypes = {
    fetchUser:   PropTypes.func.isRequired,
    oAuthLogout: PropTypes.func.isRequired,
    updateUser:  PropTypes.func.isRequired,
    user:        PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    if (!Object.keys(state.user).length) {
      const userData = mapValues(props.user, (v) => v === null ? '' : v);

      return {
        oauth:          props.user.oauth,
        updatePassword: {
          id:                   userData.id,
          password:             '',
          passwordConfirmation: ''
        },
        user: {
          ...userData,
          day:      userData.birthday ? parseInt(userData.birthday.split(' ')[0]) : '',
          month:    userData.birthday ? userData.birthday.split(' ')[1] : '',
          photo:    '',
          timeZone: userData.timeZone || getTimeZone(null)
        }
      };
    }
    return null;
  }

  state = {
    oauth:          {},
    updatePassword: {},
    user:           {}
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [inputName]: value
      }
    }));
  }

  handlePhotoChange = (value) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        photo: value
      }
    }));
  }

  handlePasswordChange = (value, inputName) => {
    this.setState((prevState) => ({
      updatePassword: {
        ...prevState.updatePassword,
        [inputName]: value
      }
    }));
  }

  handleUpdate = () => {
    this.props.updateUser({ user: {
      ...this.state.user,
      birthday: this.state.user.day + ' ' + this.state.user.month
    }});
  }

  handlePasswordUpdate = () => {
    this.props.updateUser({ user: { ...this.state.updatePassword, isPassword: true } });
  }

  handleReceive = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      oauth: {
        ...prevState.oauth,
        ...data
      }
    }));
  }

  handleOAuthSuccess = () => {
    this.props.fetchUser();
  }

  handleOAuthLogout = (provider) => {
    this.props.oAuthLogout(provider);
  }

  render() {
    const { user, oauth } = this.state;

    return (
      <div className='inner-wrapper portal account'>
        <Helmet>
          <title>Update account info</title>
        </Helmet>
        <Cable
            channel='IntegrationsChannel'
            onReceived={this.handleReceive} />

        <Container fluid>
          <Heading
              subtitle='Account'
              title='Let&apos;s take a look at your settings' />
          <Panel className='pr-0'>
            <Row
                className='justify-content-between'
                noGutters={!isMobile}>
              <Col
                  lg={7}
                  md={12}
                  xl={7}>
                <EditAccountForm
                    onChange={this.handleChange}
                    onPasswordChange={this.handlePasswordChange}
                    onPasswordUpdate={this.handlePasswordUpdate}
                    onPhotoChange={this.handlePhotoChange}
                    onSubmit={this.handleUpdate}
                    updatePassword={this.state.updatePassword}
                    user={user} />
              </Col>

              <Col
                  lg={5}
                  md={12}
                  xl={3}>
                { oauth &&
                  <div className='social-accounts'>
                    <OAuthAccount
                        data={oauth.facebook}
                        onLogout={this.handleOAuthLogout}
                        onSuccess={this.handleOAuthSuccess}
                        provider='facebook'
                        route={routes.FACEBOOK_AUTH}
                        user={user} />

                    <OAuthAccount
                        data={oauth.google}
                        onLogout={this.handleOAuthLogout}
                        onSuccess={this.handleOAuthSuccess}
                        provider='google'
                        route={routes.GOOGLE_AUTH}
                        user={user} />
                  </div>
                }
              </Col>
            </Row>
          </Panel>
        </Container>
      </div>
    );
  }
}

export default EditAccountPage;
