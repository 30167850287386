import React from 'react';
import PropTypes from 'prop-types';
import { filter } from 'lodash';
import { BrowserView, MobileView } from 'react-device-detect';
import { Redirect } from 'react-router';

import { ImageDownload, ImageUploader, StoryMaker } from '../storyMaker';
import { Calendr, Moment, getTimeZone, routes } from '../../lib';

class StoryCreatorPage extends React.PureComponent {
  static propTypes = {
    groupClasses:        PropTypes.array.isRequired,
    privateGroupClasses: PropTypes.array.isRequired,
    user:                PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    const timeZone = getTimeZone(props.user.timeZone);

    const startDate = Moment.tz(timeZone).startOf('isoWeek');
    const range = Moment.range(startDate, Moment.tz(startDate, timeZone).add(7, 'days'));
    const { groupClasses, privateGroupClasses } = props;

    const combinedEvents = Calendr.combineClasses({ groupClasses, privateGroupClasses });
    const events = filter(combinedEvents, (event) => (
      event.recurring || range.contains(Moment.utc(event.startsAt, 'YYYY-MM-DDhh:mm:ss').tz(timeZone))
    ));

    return { events };
  }


  state = {
    base64: '',
    events: [],
    step:   1
  }

  handleUndo = () => {
    this.setState({ step: 1 });
  }

  handleFinish = (base64) => {
    this.setState({ base64, step: 3 });
  }

  handleUpload = (base64, width, height) => {
    this.setState({ base64, height, step: 2, width });
  }

  renderStep = () => {
    switch (this.state.step) {
      case 1:
        return (
          <ImageUploader
              events={this.state.events}
              onUpload={this.handleUpload} />
        );

      case 2:
        return (
          <StoryMaker
              base64={this.state.base64}
              events={this.state.events}
              onFinish={this.handleFinish}
              originalSize={{ height: this.state.height, width: this.state.width }}
              user={this.props.user} />
        );

      case 3:
        return (
          <ImageDownload
              base64={this.state.base64}
              onBack={this.handleUndo} />
        );

      default:
        return null;
    }
  }

  render() {
    return (
      <React.Fragment>
        <BrowserView>
          <Redirect to={routes.NOT_FOUND} />
        </BrowserView>

        <MobileView viewClassName='story-mobile-view'>
          {this.renderStep()}
        </MobileView>
      </React.Fragment>
    );
  }
}

export default StoryCreatorPage;
