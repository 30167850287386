import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SearchPage } from '../components/pages';
import { ActivityActions, FilterActions, ProfileDataActions } from '../actions';

class SearchContainer extends React.PureComponent {
  static propTypes = {
    getActivities:  PropTypes.func.isRequired,
    getProfileData: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.props.getProfileData();
    this.props.getActivities();
  }

  render() {
    return <SearchPage {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  activities:  state.activities,
  filter:      state.filter,
  instructors: state.instructors,
  locations:   state.studios,
  profileData: state.profileData,
  search:      state.search
});

const mapDispatchToProps = (dispatch) => ({
  getActivities:  () =>      dispatch(ActivityActions.GET_ALL()),
  getProfileData: () =>      dispatch(ProfileDataActions.GET()),
  performFilter:  (data) =>  dispatch(FilterActions.FILTER(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchContainer);
