import React from 'react';
import PropTypes from 'prop-types';
import { SelectPlaylistContainer } from '../../../../containers';
import { SpotifyLogoCreate } from '../../../../images';
import { Button } from '../../index';
import { OAuthWindow } from '../../../oauth';
import { routes } from '../../../../lib';

class SpotifyConnect extends React.PureComponent {
  static propTypes = {
    isConnected: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    onLogout:    PropTypes.func,
    onSuccess:   PropTypes.func
  }

  static defaultProps = {
    isConnected: false,
    onLogout:    () => {},
    onSuccess:   () => {}
  }

  state = {
    isPlaylistsOpen: false
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      ...prevState,
      isPlaylistsOpen: !prevState.isPlaylistsOpen
    }));
  }

  handleSuccess = () => {
    this.handleToggle();
    this.props.onSuccess();
  }

  handleLogout = () => {
    this.props.onLogout('spotify');
  }

  render () {
    const { isConnected } = this.props;

    return (
      <div className='profile-connect'>
        <div className='logo'>
          <img
              alt='Spotify'
              className='img-fluid'
              src={SpotifyLogoCreate} />
        </div>
        <div className='action'>
          { !isConnected &&
            <OAuthWindow
                onSuccess={this.handleSuccess}
                url={routes.SPOTIFY_AUTH}>
              <Button size='sm'>Connect</Button>
            </OAuthWindow>
          }
          { isConnected &&
            <React.Fragment>
              <Button
                  className='mr-1'
                  color='spotify'
                  onClick={this.handleToggle}
                  size='sm'>Update</Button>
              <Button
                  color='danger'
                  onClick={this.handleLogout}
                  size='sm'>Remove</Button>
            </React.Fragment>
          }
        </div>

        { isConnected &&
          <SelectPlaylistContainer
              isOpen={this.state.isPlaylistsOpen}
              onToggle={this.handleToggle} />
        }
      </div>
    );
  }
}

export default SpotifyConnect;
