import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { routes } from '../../lib';
import { Button, Clipboard } from '../forms';

class ShareProfileModal extends React.PureComponent {
  static propTypes = {
    isOpen:   PropTypes.bool.isRequired,
    onToggle: PropTypes.func.isRequired,
    profile:  PropTypes.object.isRequired
  }

  render() {
    return (
      <Modal
          className='finish-profile-modal'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader>
          Sweet! Your profile is all set!
        </ModalHeader>

        <ModalBody>
          <div className='text-info mb-5 text-center'>
            Copy your profile link:&nbsp;
            <Clipboard>
              {'onpodio.com/' + this.props.profile.username}
            </Clipboard>
          </div>

          <div className='mb-5 text-center'>
            <strong>
              Here&apos;s how best to get started:
            </strong>

            <div className='mt-3 text-center'>
              <p className='text-center'>
                <span
                    aria-label="world"
                    role="img">
                   🌎
                </span>
                &ensp;Use your profile as your IG bio link!
              </p>
            </div>

            <div className='text-center'>
              <p className='text-center'>
                <span
                    aria-label="watermelon"
                    role="img">
                   🍉
                </span>
                &ensp;Keep your profile updated & fresh!
              </p>
            </div>

            <div className='text-center'>
              <p className='text-center'>
                <span
                    aria-label="comments"
                    role="img">
                   💬
                </span>
                &ensp;Build your brand with comments!
              </p>
            </div>

            <div className='text-center'>
              <p className='text-center'>
                <span
                    aria-label="calendar"
                    role="img">
                   📆
                </span>
               &ensp;Add your sched to drive bookings!
              </p>
            </div>
          </div>

          <div className='mb-4 text-center text-muted'>

            <Link to={routes.EDIT_PROFILE}>
              <Button className='col-md-6 w-100'>
                Finish!
              </Button>
            </Link>
          </div>

        </ModalBody>


      </Modal>
    );
  }
}

export default ShareProfileModal;
