import { all, put, takeLatest } from 'redux-saga/effects';
import { includes } from 'lodash';

import { RouterActionTypes } from '../constants/actionTypes';
import { SessionActions } from '../actions';
import { routes } from '../lib';

function* watchLocationChange() {
  yield takeLatest(RouterActionTypes.LOCATION_CHANGE, function* ({ payload }) {
    window.scrollTo(0, 0);

    const blacklist = [routes.LOGIN, routes.SIGNUP];
    if (!includes(blacklist, payload.location.pathname)) {
      yield put(SessionActions.CLEAR());
    }
  });
}

export default function* routerSaga() {
  yield all([
    watchLocationChange()
  ]);
}
