import { StudioActionTypes } from '../constants/actionTypes';

const initialState = {
  create: {
    data:    {},
    loading: false
  },
  data:       {},
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case StudioActionTypes.CREATE:
      return { ...newState, create: { loading: true } };

    case StudioActionTypes.CREATE_SUCCESS:
      return { ...newState, create: { data: action.payload } };

    case StudioActionTypes.CREATE_FAILURE:
      return { ...newState, create: initialState.create };

    case StudioActionTypes.CREATE_CLEAN:
      return { ...newState, create: initialState.create };

    case StudioActionTypes.FETCH:
      return newState;

    case StudioActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case StudioActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };

    default: return state;
  }
};
