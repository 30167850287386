import React from 'react';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { eq } from 'lodash';
import { Redirect } from 'react-router';

import { Loader } from '../loader';
import { routes } from '../../lib';

class SubscriptionPage extends React.PureComponent {
  static propTypes = {
    createSubscription: PropTypes.func.isRequired,
    subscription:       PropTypes.object.isRequired
  }

  state = {
    isCompleted: false,
    sessionId:   '',
    success:     ''
  }

  componentDidUpdate(prevProps) {
    if (!eq(prevProps.subscription.id, this.props.subscription.id)) {
      this.setState({ isCompleted: true });
    }
  }

  componentWillMount() {
    const urlParams = new URLSearchParams(window.location.search);

    this.setState({
      sessionId: urlParams.get('session_id'),
      success:   urlParams.get('success')
    }, this._handleSubscribe);
  }

  _handleSubscribe = () => {
    const { sessionId, success } = this.state;

    if (success) {
      this.props.createSubscription({ method: 'paid', sessionId });
    } else {
      toastr.error('Stripe callback error');
    }
  }

  render() {
    if (this.state.isCompleted) {
      return <Redirect to={routes.PLANS_AND_BILLING} />;
    }

    return <Loader isLoading />;
  }
}

export default SubscriptionPage;
