import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Button } from '../forms';

class InstagramPhotoModal extends React.PureComponent {
  static propTypes = {
    currentPhoto:   PropTypes.number.isRequired,
    images:         PropTypes.array.isRequired,
    instructorName: PropTypes.string.isRequired,
    isOpen:         PropTypes.bool.isRequired,
    onNext:         PropTypes.func.isRequired,
    onPrev:         PropTypes.func.isRequired,
    onToggle:       PropTypes.func.isRequired,
    username:       PropTypes.string.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      currentImage: props.images[props.currentPhoto],
      lastIndex:    props.images.length - 1
    };
  }

  state = {
    currentImage: {},
    lastIndex:    0
  };

  renderImage = (image) => {
    return (
      <img
          alt={image.id}
          className='img-fluid'
          src={image.url} />
    );
  }

  renderVideo = (video) => {
    return (
      <video
          className='img-fluid'
          controls
          poster={video.url}
          src={video.videoUrl} />
    );
  }

  render () {
    const { currentImage, lastIndex } = this.state;
    return (
      <Modal
          centered
          className='instagram-modal'
          isOpen={this.props.isOpen}
          size='sm'
          toggle={this.props.onToggle()}>
        <ModalHeader toggle={this.props.onToggle()}>
          {this.props.instructorName}&apos;s Instagram
        </ModalHeader>

        <ModalBody className='mt-3'>
          <Container fluid>
            <Row className='align-items-center justify-content-center'>
              <Col xs={1}>
                <Button
                    className='border-0 p-1'
                    color='link'
                    id='btn-instagram-prev'
                    isBlock
                    isDisabled={this.props.currentPhoto === 0}
                    onClick={this.props.onPrev}>
                  <Icon
                      icon='caret-left'
                      size='3x' />
                </Button>
              </Col>
              <Col
                  className='instagram-media'
                  xs={10}>
                <div className='photo'>
                  { currentImage.type === 'VIDEO' ? this.renderVideo(currentImage) : this.renderImage(currentImage)}
                </div>
                <div className='info'>
                  <div className='external-link'>
                    <a
                        href={currentImage.externalLink}
                        rel='noopener noreferrer'
                        target='_blank'>
                      Open in Instagram
                      <Icon
                          className='ml-1'
                          icon='external-link-alt' />
                    </a>
                  </div>
                </div>
                { currentImage.caption &&
                  <div className='caption'>
                    <p><b>{this.props.username}</b> {currentImage.caption}</p>
                  </div>
                }
              </Col>
              <Col xs={1}>
                <Button
                    className='border-0 p-1'
                    color='link'
                    id='btn-instagram-next'
                    isBlock
                    isDisabled={this.props.currentPhoto === lastIndex}
                    onClick={this.props.onNext}>
                  <Icon
                      icon='caret-right'
                      size='3x' />
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  }
}

export default InstagramPhotoModal;