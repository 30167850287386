import React from 'react';
import PropTypes from 'prop-types';
import { Label, ModalBody, ModalFooter } from 'reactstrap';
import { capitalize, isEmpty, padStart } from 'lodash';

import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';

import { Button, ChargeForm, Checkbox, PaymentIcon, Radio } from '../../forms';
import { Input } from '../../inputs';
import { StripeBadge } from '../../badges';
import { InstructorNoAvatar } from '../../../images';
import { UserModalAvatar } from '../layouts';
import { FEES, PRIVATE_SESSION_STEPS } from '../../../constants';
import { Moment, StripeHelper, api, getTimeZone } from '../../../lib';

class Payment extends React.PureComponent {
  static propTypes = {
    createDiscount:       PropTypes.func.isRequired,
    createPrivateSession: PropTypes.func.isRequired,
    discount:             PropTypes.object,
    guest:                PropTypes.object,
    instructor:           PropTypes.object.isRequired,
    isLoading:            PropTypes.bool.isRequired,
    onLoading:            PropTypes.func.isRequired,
    onNext:               PropTypes.func,
    onToggle:             PropTypes.func.isRequired,
    privateSession:       PropTypes.object.isRequired,
    user:                 PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      amount: props.privateSession.price,
      fee:    FEES.charge(props.privateSession.price)
    };
  }

  static defaultProps = {
    discount: {},
    guest:    {},
    onNext:   () => {}
  }

  state = {
    amount:       '',
    cards:        [],
    code:         '',
    error:        {},
    fee:          '',
    isModalOpen:  false,
    openInput:    false,
    selectedCard: ''
  }

  componentDidMount() {
    this.props.onLoading(true, () => (
      api.card.get({ username: this.props.instructor.username })
        .then((response) => (
          this.setState({
            cards:        response.data,
            selectedCard: response.data[0] || 'new'
          }, () => this.props.onLoading(false))
        ))
        .catch(() => (
          this.setState({ selectedCard: 'new' }, () => this.props.onLoading(false))
        ))
    ));
  }

  handleBack = () => {
    this.props.onNext(PRIVATE_SESSION_STEPS.details);
  }

  handleChange = (value, inputName) => (
    this.setState({ [inputName]: value })
  )

  handleModal = () => (
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }))
  )

  createPrivateSession = () => {
    this.props.createPrivateSession({ privateSession: {
      ...this.props.privateSession,
      chargeId:     this.state.data.charge_id,
      clientId:     this.props.guest.id,
      instructorId: this.props.instructor.id,
      method:       'charge',
      timeZone:     getTimeZone(this.props.user.timeZone)
    }});

    this.props.onToggle();
  };

  handleOption = (card) => () => (
    this.setState({ selectedCard: card })
  )


  renderOptions = () => (
    <React.Fragment>
      <div className='text-muted text-left mb-2 modal__default-text'>
        I&apos;d like to pay with
      </div>

      <div className='text-left'>
        { this.state.cards.map((card) => (
          <div
              className='custom-control custom-radio'
              key={card.id}>
            <Radio
                className='custom-control-input'
                id={card.id}
                isChecked={this.state.selectedCard.id == card.id}
                name='selectedCard'
                onChange={this.handleOption(card)}
                value={false} />
            <Label
                className='custom-control-label pb-1'
                htmlFor={'input-' + card.id}>
              <span className='mr-3'>
                <PaymentIcon icon={card.card.brand} />
              </span>

              <span className='mr-3'>
                {capitalize(card.card.brand)} ****{card.card.last4}&nbsp;
              </span>

              {padStart(card.card.exp_month)}/{String(card.card.exp_year).slice(-2)}
            </Label>
          </div>
        ))}

        { !!this.state.cards.length &&
          <div className='custom-control custom-radio'>
            <Radio
                className='custom-control-input'
                id='new'
                isChecked={this.state.selectedCard == 'new'}
                name='selectedCard'
                onChange={this.handleOption('new')}
                value={false} />
            <Label
                className='custom-control-label pb-1 modal__default-text'
                htmlFor='input-new'>
              Another card
            </Label>
          </div>
        }

        {this.state.selectedCard != 'new' && <StripeBadge />}
      </div>
    </React.Fragment>
  )

  handleApply = () => {
    const { code } = this.state;
    const { guest, instructor } = this.props;

    this.props.createDiscount({ discount: {
      clientId:     guest.id,
      code,
      instructorId: instructor.id,
      target:       'private_session'
    }});

    this.setState({ code: '', openInput: false });
  }

  handleChangeInput = (value) => (
    this.setState({ code: value })
  )

  handleToggle = () => (
    this.setState((prevState) => ({ openInput: !prevState.openInput }))
  )

  paidSystem = (elements, stripe) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const { selectedCard } = this.state;
    const { discount, privateSession, instructor, guest, onLoading, user } = this.props;
    const fullName = guest.name || user.firstName +  ' ' + user.lastName;

    const description = `${fullName} purchased ${privateSession.name}`;

    const params = {
      chargable:   'PrivateSessionType',
      chargableId: privateSession.privateSessionTypeId,
      charges:     'price',
      description,
      discountId:  discount.id,
      hold:        true,
      recipientId: instructor.id
    };

    const card = elements.getElement(CardElement);

    if (this.state.selectedCard == 'new') {
      if (this.state.cards.length || !this.state.shouldSaveCard) {
        StripeHelper.createPaymentIntent(stripe, card, params, 'default', onLoading, this.setState.bind(this), () => {
          const { data } = this.state;

          StripeHelper.confirmPayment(stripe, card, data, user, onLoading, this.setState.bind(this), () => {
            this.createPrivateSession();
          });
        });
      } else {
        StripeHelper.createPaymentIntent(stripe, card, params, 'token', onLoading, this.setState.bind(this), () => {
          const { data } = this.state;

          StripeHelper.confirmPayment(stripe, card, data, user, onLoading, this.setState.bind(this), () => {
            this.createPrivateSession();
          });
        });
      }
    } else {
      const chosenCard = selectedCard;

      StripeHelper.createPaymentIntent(stripe, chosenCard, params, 'card', onLoading, this.setState.bind(this), () => {
        this.createPrivateSession();
      });
    }
  }

  freeSystem = () => {
    const { discount, instructor, privateSession, onLoading } = this.props;

    onLoading(true, () => (
      api.charge.create({ charge: {
        chargable:   'PrivateSessionType',
        chargableId: privateSession.privateSessionTypeId,
        charges:     'price',
        discountId:  discount.id,
        hold:        false,
        method:      'default',
        recipientId: instructor.id
      }}).then((response) => (
        this.setState({ data: response.data }, () => this.createPrivateSession())
      ))
    ));
  }

  renderCost = () => {
    const { amount, fee } = this.state;
    const { discount } = this.props;
    const { currency } = this.props.instructor;
    const { coupon } = discount;

    const isDiscounted = discount.id;

    const isFree = isDiscounted && coupon.discountType == 'free';

    const discountPercent = isDiscounted && coupon.percent * 0.01;
    const discountAmount = amount * discountPercent;

    const amountWithDiscount = amount - discountAmount;


    const discountFee = isDiscounted ? amountWithDiscount * 0.03 + 0.49 : fee;
    const price = isDiscounted ? amountWithDiscount : amount;

    const renderPrice = () => (
      <span>
        {parseFloat(price + discountFee, 10).toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
        <span className='modal__event-fee text-muted'>
          (incl. {parseFloat(discountFee, 10).toLocaleString('en-GB', { currency, style: 'currency' })} fee)
        </span>
      </span>
    );

    return (
      <div
          className='modal__event-cost
                     modal__default-text
                     modal__default-text_blue
                     modal__default-text_bold
                     d-inline'>
        Total Cost:&nbsp;
        <React.Fragment>
          {!isDiscounted && amount !== 0 && renderPrice()}

          { amount == 0 && 'Free'}

          { isDiscounted &&
            <span>
              &nbsp;
              {isFree && 'Free'}
              {!isFree && renderPrice()}
            </span>
          }
        </React.Fragment>
      </div>
    );
  }

  handleSubmit = (elements, stripe) => async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const { amount } = this.state;
    const { discount } = this.props;

    const isDiscounted = discount.id;
    const { coupon } = discount;

    isDiscounted && coupon.discountType == 'free' || amount == 0 ?
      this.freeSystem() :
      this.paidSystem(elements, stripe);
  }

  render() {
    const { amount, cards } = this.state;
    const { discount, instructor, privateSession, isLoading, user } = this.props;
    const photo = instructor.photo ? instructor.photo.sm : InstructorNoAvatar;
    const { coupon } = discount;

    const isDiscounted = discount.id;
    const isFree = isDiscounted && coupon.discountType == 'free' || amount == 0;

    return (
      <ElementsConsumer>
        {({elements, stripe}) => (
          <div className='new-modal'>
            <ModalBody>
              { instructor &&
                <div className='modal__header'>
                  <UserModalAvatar
                      alt={instructor.name}
                      firstName={instructor.name}
                      src={photo} />
                  <div className='modal__instructor-name'>
                    <span className='modal__big-text'>{instructor.name}</span>
                  </div>
                </div>
              }

              <div className='modal__event-name'>
                <span className='modal__big-text modal__big-text_black'>
                  Private Session Request
                </span>
              </div>

              <div className='modal__time'>
                <div className='modal__default-text modal__default-text_bold font-weight-bold'>
                  {privateSession.startsAt.format('ddd, MMMM DD')}
                </div>

                <div className='modal__default-text'>
                  &nbsp;|&nbsp;{privateSession.startsAt.format('hh:mmA')} - {privateSession.duration} minutes
                </div>
              </div>

              <div className='modal__event-timezone modal__small-text'>
                {new Moment.tz(getTimeZone(user.timeZone)).format('z')} (GMT
                {new Moment.tz(getTimeZone(user.timeZone)).format('Z')} {getTimeZone(user.timeZone)})
              </div>

              <div className='text-muted modal__small-text'>
                Type: {privateSession.name}
              </div>

              <div className='mt-1 mb-2 text-muted modal__small-text'>
                Length: {privateSession.duration} mins
              </div>

              { privateSession.note &&
                <div className='event-description'>
                  <span className='modal__small-text'>Your Note to {instructor.name}</span>
                  <p className='modal__small-text modal__small-text_grey'>{privateSession.note}</p>
                </div>
              }
              
              { amount != 0 &&
                <div className='mb-1'>
                  <div
                      className='modal__event-cost
                                modal__default-text_blue
                                modal__default-text
                                m-0 pb-1 pointer'
                      onClick={this.handleToggle}
                      onKeyPress={this.handleToggle}
                      role='button'
                      tabIndex={0}>
                    Apply a Coupon Code?
                  </div>
                  {this.state.openInput &&
                    <React.Fragment>
                      <div className='d-flex'>
                        <Input
                            className='modal__code-input'
                            id='name'
                            name='name'
                            onChange={this.handleChangeInput}
                            placeholder='Code'
                            type='text'
                            value={this.state.code} />

                        <Button
                            className='modal__apply-button'
                            color='success'
                            onClick={this.handleApply}>
                          Apply
                        </Button>
                      </div>

                      {this.state.couponError && <div className='text-danger text-sm'>{this.state.couponError}</div>}
                    </React.Fragment>
                  }
                </div>
              }

              {this.renderCost()}

              <div className='save-card mt-3'>
                { !isFree && this.state.selectedCard == 'new' &&
                  <ChargeForm
                      elements={elements}
                      error={this.state.error.message}
                      stripe={stripe}>
                    { !isFree && !cards.length && user.id &&
                      <div className='save-card'>
                        <div className='custom-control custom-checkbox checkbox-lg'>
                          <Checkbox
                              className='custom-control-input'
                              id='shouldSaveCard'
                              isChecked={this.state.shouldSaveCard}
                              name='shouldSaveCard'
                              onChange={this.handleChange} />
                          <Label
                              className='custom-control-label text-left pt-2'
                              for='input-shouldSaveCard'>
                            Save card to account for future use
                          </Label>
                        </div>
                      </div>
                     }
                  </ChargeForm>
                }

                {!isFree && !!this.state.cards.length && this.renderOptions()}
              </div>

              { !isFree &&
                <div className='w-100 d-flex text-small text-muted d-inline pt-3 pl-1'>
                  <span>
                    Your card &nbsp;<span className='font-weight-bold'>will not be charged</span>
                    &nbsp;until {instructor.name} accepts your session request.
                  </span>
                </div>
              }
            </ModalBody>

            <ModalFooter className='d-block'>
              {isEmpty(instructor.oauth.stripe) &&
                <div className='modal__default-text text-danger mb-3'>
                  Instructor has disabled stripe payments. For more information contact 
                  <a href={`mailto:${instructor.email}`}>&nbsp;{instructor.email}</a>
                </div>
              }
              <Button
                  color='blue'
                  isBlock
                  isDisabled={!stripe || isEmpty(instructor.oauth.stripe)}
                  isLoading={isLoading}
                  onClick={this.handleSubmit(elements, stripe)}
                  size='lg'>
                Send Request
              </Button>
            </ModalFooter>
          </div>
        )}
      </ElementsConsumer>
    );
  }
}

export default Payment;
