import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { SamplesActionTypes } from '../constants/actionTypes';
import {
  ErrorActions,
  SamplesActions
} from '../actions';


function* watchPrivateGroupClassFetch() {
  yield takeLatest(SamplesActionTypes.FETCH_PRIVATE_GROUP_CLASSES, function* () {
    try {
      const response = yield call(api.samples.privateGroupClasses.fetch);

      yield put(SamplesActions.FETCH_PRIVATE_GROUP_CLASSES_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(SamplesActions.FETCH_PRIVATE_GROUP_CLASSES_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchChallengesFetch() {
  yield takeLatest(SamplesActionTypes.FETCH_CHALLENGES, function* () {
    try {
      const response = yield call(api.samples.challenges.fetch);

      yield put(SamplesActions.FETCH_CHALLENGES_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(SamplesActions.FETCH_CHALLENGES_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}


function* watchClassPacksFetch() {
  yield takeLatest(SamplesActionTypes.FETCH_CLASS_PACKS, function* () {
    try {
      const response = yield call(api.samples.classPacks.fetch);

      yield put(SamplesActions.FETCH_CLASS_PACKS_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(SamplesActions.FETCH_CLASS_PACKS_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchMembershipsFetch() {
  yield takeLatest(SamplesActionTypes.FETCH_MEMBERSHIPS, function* () {
    try {
      const response = yield call(api.samples.memberships.fetch);

      yield put(SamplesActions.FETCH_MEMBERSHIPS_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(SamplesActions.FETCH_MEMBERSHIPS_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchMembershipDelete() {
  yield takeLatest(SamplesActionTypes.DELETE_MEMBERSHIP, function* ({ payload }) {
    try {
      yield call(api.instructor.membership.delete, payload);

      const memberships = yield select((state) => state.samples.memberships);
      const data = filter(memberships, (membership) => (membership.id != payload.membership.id));

      yield put(SamplesActions.DELETE_MEMBERSHIP_SUCCESS(data));
      toastr.success('Membership canceled');
    } catch (error) {
      yield put(SamplesActions.DELETE_MEMBERSHIP_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchClassPackDelete() {
  yield takeLatest(SamplesActionTypes.DELETE_CLASS_PACK, function* ({ payload }) {
    try {
      yield call(api.instructor.classPack.delete, payload);

      const classPacks = yield select((state) => state.samples.classPacks);
      const data = filter(classPacks, (classPack) => (classPack.id != payload.classPack.id));

      yield put(SamplesActions.DELETE_CLASS_PACK_SUCCESS(data));
      toastr.success('Class Pack canceled');
    } catch (error) {
      yield put(SamplesActions.DELETE_CLASS_PACK_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchChallengeDelete() {
  yield takeLatest(SamplesActionTypes.DELETE_CHALLENGE, function* ({ payload }) {
    try {
      yield call(api.instructor.challenge.delete, payload);

      const challenges = yield select((state) => state.samples.challenges);
      const data = filter(challenges, (challenge) => (challenge.id != payload.challenge.id));

      yield put(SamplesActions.DELETE_CHALLENGE_SUCCESS(data));
      toastr.success('Challenge canceled');
    } catch (error) {
      yield put(SamplesActions.DELETE_CHALLENGE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchOnDemandsFetch() {
  yield takeLatest(SamplesActionTypes.FETCH_ON_DEMANDS, function* () {
    try {
      const response = yield call(api.samples.onDemands.fetch);

      yield put(SamplesActions.FETCH_ON_DEMANDS_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(SamplesActions.FETCH_ON_DEMANDS_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* privateGroupClassesSaga() {
  yield all([
    watchPrivateGroupClassFetch(),
    watchChallengesFetch(),
    watchChallengeDelete(),
    watchClassPacksFetch(),
    watchClassPackDelete(),
    watchMembershipsFetch(),
    watchMembershipDelete(),
    watchOnDemandsFetch()
  ]);
}
