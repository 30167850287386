import React from 'react';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Button } from '../forms';
import { AddCommentModal, BookingScheduleModal } from '../modals';
import { routes } from '../../lib';

class InstructorCard extends React.PureComponent {
  static propTypes = {
    createBooking:      PropTypes.func.isRequired,
    createComment:      PropTypes.func.isRequired,
    followInstructor:   PropTypes.func.isRequired,
    instructor:         PropTypes.object.isRequired,
    profileData:        PropTypes.object.isRequired,
    unfollowInstructor: PropTypes.func.isRequired
  }

  state = {
    isCommentModalOpen:  false,
    isScheduleModalOpen: false
  }

  handleFollow = () => {
    this.props.followInstructor(this.props.instructor);
  }

  handleUnfollow = () => {
    this.props.unfollowInstructor(this.props.instructor);
  }

  handleScheduleModal = () => {
    this.setState((prevState) => ({
      isScheduleModalOpen: !prevState.isScheduleModalOpen
    }));
  }

  handleCommentModal = () => {
    this.setState((prevState) => ({
      isCommentModalOpen: !prevState.isCommentModalOpen
    }));
  }

  handleCreate = (data) => {
    this.props.createComment(data);
  }

  render() {
    const { instructor } = this.props;

    return (
      <Card
          body
          className='card-result  mb-2 p-2'>
        <Link to={routes.INSTRUCTOR(instructor.username)}>
          <div className='card-photo rounded placeholder'>
            <img
                alt={instructor.firstName}
                src={instructor.photoUrl} />
          </div>
        </Link>

        <div className='card-content'>
          <div className='card-info'>
            <Link
                className='card-title'
                to={routes.INSTRUCTOR(instructor.username)}>
              {instructor.firstName} {instructor.lastName}
            </Link>

            <span className='card-bio text-muted'>
              <TextTruncate
                  element="span"
                  lines={4}
                  text={instructor.bio}
                  truncateText='...' />
            </span>
          </div>
        </div>

        <div className='card-actions mr-2'>
          <Button
              className='m-1 w-100'
              onClick={this.handleCommentModal}
              size='sm'>
            Leave Comment
          </Button>

          <Button
              className='m-1 w-100'
              onClick={this.handleScheduleModal}
              size='sm'>
            Book Class
          </Button>

          { instructor.isUnfollowed &&
            <Button
                className='m-1 w-100'
                onClick={this.handleFollow}
                size='sm'>
              Follow
            </Button>
          }

          { !instructor.isUnfollowed &&
            <Button
                className='m-1 w-100'
                onClick={this.handleUnfollow}
                size='sm'>
              Unfollow
            </Button>
          }

          <div className='modals'>
            <AddCommentModal
                isOpen={this.state.isCommentModalOpen}
                object={instructor}
                onCreate={this.handleCreate}
                onToggle={this.handleCommentModal}
                styleTags={this.props.profileData.data.styleTags}
                type='instructor' />

            <BookingScheduleModal
                createBooking={this.props.createBooking}
                groupClasses={instructor.groupClasses}
                isOpen={this.state.isScheduleModalOpen}
                onGroupClassClick={this.handleBookingModal}
                onToggle={this.handleScheduleModal}>
              {instructor.firstName} {instructor.lastName}&apos;s Schedule
            </BookingScheduleModal>
          </div>
        </div>
      </Card>
    );
  }
}

export default InstructorCard;
