import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Cable } from '../components/cable';
import { SubscriptionsActions } from '../actions';

class SubscriptionsChannel extends React.PureComponent {
  static propTypes = {
    activateSubscription: PropTypes.func.isRequired
  }

  handleReceive = (subscription) => {
    this.props.activateSubscription(subscription);
  }

  render() {
    return (
      <Cable
          channel='SubscriptionsChannel'
          onReceived={this.handleReceive} />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  activateSubscription: (data) => dispatch(SubscriptionsActions.ACTIVATE(data))
});

export default connect(null, mapDispatchToProps)(SubscriptionsChannel);
