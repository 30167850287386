import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Nav, NavItem, NavLink } from 'reactstrap';

import { summaryTabs } from '../../../../constants/transactions';

class PurchasesSummary extends PureComponent {
  static propTypes = {
    summary: PropTypes.object.isRequired,
    user:    PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      currency: props.user.currency,
      summary:  props.summary
    };
  }

  state = {
    currency:     'USD',
    selectedView: summaryTabs.ALL_TIME,
    summary:      {}
  }

  handleClick = (view) => () => {
    this.setState({
      selectedView: view
    });
  }

  render () {
    const { summary, currency, selectedView } = this.state;

    const amountFormatter = (amount) => {
      const options = {
        currency:    currency,
        style:       'currency',
        useGrouping: true
      };

      const formattedValue = new Intl.NumberFormat('en-US', options).formatToParts(amount / 100);

      return formattedValue.map((numPart, i) => {
        if (i === 0) {
          return numPart.value + ' ';
        }
        return numPart.value;
      }).join('');
    };

    const navActive = (view) => {
      return selectedView === view;
    };

    if (!isEmpty(summary)) return (
      <Fragment>
        <Nav
            className='nav-summary'
            justified>
          <NavItem active={navActive(summaryTabs.ALL_TIME)}>
            <NavLink onClick={this.handleClick(summaryTabs.ALL_TIME)}>
              All Time
            </NavLink>
          </NavItem>
          <NavItem active={navActive(summaryTabs.TODAY)}>
            <NavLink onClick={this.handleClick(summaryTabs.TODAY)}>
              Today
            </NavLink>
          </NavItem>
          <NavItem active={navActive(summaryTabs.LAST_WEEK)}>
            <NavLink onClick={this.handleClick(summaryTabs.LAST_WEEK)}>
              Last 7 days
            </NavLink>
          </NavItem>
          <NavItem active={navActive(summaryTabs.LAST_MONTH)}>
            <NavLink onClick={this.handleClick(summaryTabs.LAST_MONTH)}>
              Last 30 days
            </NavLink>
          </NavItem>
        </Nav>
        <div className='summary class-packs'>
          <div className='number'>
            <span className='title'>Total</span>

            <span className='label'># Purchases</span>
            <div className='count-block orange'>
              {summary[selectedView].total.count}
            </div>
            <span className='label'>$ Sales</span>
            <div className='count-block green'>
              {amountFormatter(summary[selectedView].total.amount)}
            </div>
          </div>
          <div className='number'>
            <span className='title'>Live Class</span>

            <span className='label'># Purchases</span>
            <div className='count-block orange'>
              {summary[selectedView].live.count}
            </div>
            <span className='label'>$ Sales</span>
            <div className='count-block green'>
              {amountFormatter(summary[selectedView].live.amount)}
            </div>
          </div>
          <div className='number'>
            <span className='title'>On-Demand</span>

            <span className='label'># Purchases</span>
            <div className='count-block orange'>
              {summary[selectedView].onDemand.count}
            </div>
            <span className='label'>$ Sales</span>
            <div className='count-block green'>
              {amountFormatter(summary[selectedView].onDemand.amount)}
            </div>
          </div>
        </div>
      </Fragment>
    );

    return null;
  }
}

export default PurchasesSummary;