export default {
  CREATE:         '@@favoriteOnDemands/CREATE',
  CREATE_FAILURE: '@@favoriteOnDemands/CREATE_FAILURE',
  CREATE_SUCCESS: '@@favoriteOnDemands/CREATE_SUCCESS',

  DELETE:         '@@favoriteOnDemands/DELETE',
  DELETE_FAILURE: '@@favoriteOnDemands/DELETE_FAILURE',
  DELETE_SUCCESS: '@@favoriteOnDemands/DELETE_SUCCESS',

  FETCH:         '@@favoriteOnDemands/FETCH',
  FETCH_FAILURE: '@@favoriteOnDemands/FETCH_FAILURE',
  FETCH_SUCCESS: '@@favoriteOnDemands/FETCH_SUCCESS'
};
