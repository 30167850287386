import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'reactstrap';

import { Input } from '../../inputs';

class PromptInput extends React.PureComponent {
  static propTypes = {
    index:       PropTypes.number.isRequired,
    onChange:    PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    profile:     PropTypes.object.isRequired
  }

  static defaultProps = {
    placeholder: ''
  }

  static getDerivedStateFromProps(props) {
    return {
      prompt: props.profile.prompts[props.index] || { text: '' }
    };
  }

  state = {
    prompt: { text: '' }
  }

  handleChange = (value, name) => {
    const { index, profile } = this.props;

    const prompt = profile.prompts[index];

    this.props.onChange(
      Object.assign([], profile.prompts, { [index]: { ...prompt, text: value } }),
      name
    );
  }

  render() {
    const { prompt } = this.state;

    return (
      <FormGroup>
        <Input
            maxLength={100}
            name='prompts'
            onChange={this.handleChange}
            placeholder={this.props.placeholder}
            value={prompt.text} />
      </FormGroup>
    );
  }
}

export default PromptInput;
