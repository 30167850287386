import { CouponsActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: CouponsActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: CouponsActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: CouponsActionTypes.CREATE_SUCCESS
  }),


  DELETE: (payload) => ({
    payload, type: CouponsActionTypes.DELETE
  }),

  DELETE_FAILURE: () => ({
    type: CouponsActionTypes.DELETE_FAILURE
  }),

  DELETE_SUCCESS: (payload) => ({
    payload, type: CouponsActionTypes.DELETE_SUCCESS
  }),


  FETCH: () => ({
    type: CouponsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: CouponsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: CouponsActionTypes.FETCH_SUCCESS
  }),


  GET: (payload) => ({
    payload, type: CouponsActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: CouponsActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload, type: CouponsActionTypes.GET_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: CouponsActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: CouponsActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: CouponsActionTypes.UPDATE_SUCCESS
  })
};
