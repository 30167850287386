import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Row } from 'reactstrap';

import { Button, Form } from '../../forms';
import { Input } from '../../inputs';

class EditAddressForm extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    onChange:  PropTypes.func.isRequired,
    user:      PropTypes.object.isRequired
  }

  static defaultProps = {
    className: ''
  }

  render() {
    return (
      <Form className={this.props.className}>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <Label for='address'>Address</Label>
              <Input
                  id='address'
                  name='address'
                  onChange={this.props.onChange}
                  type='text'
                  value={this.props.user.address} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <Label for='secondaryAddress'>Address (2nd line)</Label>
              <Input
                  id='secondaryAddress'
                  name='secondaryAddress'
                  onChange={this.props.onChange}
                  type='text'
                  value={this.props.user.secondaryAddress} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col
              md={6}
              sm={12}>
            <FormGroup>
              <Label for='city'>City</Label>
              <Input
                  id='city'
                  name='city'
                  onChange={this.props.onChange}
                  type='text'
                  value={this.props.user.city} />
            </FormGroup>
          </Col>
          <Col
              md={6}
              sm={12}>
            <FormGroup>
              <Label for='state'>State</Label>
              <Input
                  id='state'
                  name='state'
                  onChange={this.props.onChange}
                  type='text'
                  value={this.props.user.state} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col
              md={6}
              sm={12}>
            <FormGroup>
              <Label for='zipCode'>Postal code/Zip code</Label>
              <Input
                  id='zipCode'
                  name='zipCode'
                  onChange={this.props.onChange}
                  type='text'
                  value={this.props.user.zipCode} />
            </FormGroup>
          </Col>
          <Col
              md={6}
              sm={12}>
            <FormGroup>
              <Label for='country'>Country</Label>
              <Input
                  id='country'
                  name='country'
                  onChange={this.props.onChange}
                  type='text'
                  value={this.props.user.country} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col
              md={6}
              sm={12}>
            <FormGroup>
              <Label for='phone'>Contact phone</Label>
              <Input
                  id='phone'
                  name='phone'
                  type='text' />
            </FormGroup>
          </Col>
        </Row>
        <Button>Save changes</Button>
      </Form>
    );
  }
}

export default EditAddressForm;