import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { isMobile } from 'react-device-detect';

import {
  Activities,
  Contacts,
  Fundraiser,
  InstructorName,
  Locations,
  Photo,
  Services
} from '../../../../components/instructor';
import { FollowButton } from '../../../../components/buttons';
import OptionalSections from './optionalSections';

class HomeDefault extends React.PureComponent {
  static propTypes = {
    donationBookings: PropTypes.array.isRequired,
    donationTargets:  PropTypes.object.isRequired,
    follow:           PropTypes.func.isRequired,
    instructor:       PropTypes.object.isRequired,
    match:            PropTypes.object.isRequired,
    siteTemplate:     PropTypes.string,
    unFollow:         PropTypes.func.isRequired
  }

  static defaultProps = {
    siteTemplate: 'default'
  }

  handleFollow = () => {
    this.props.instructor.isFollowing ? this.props.unFollow() : this.props.follow();
  };

  render() {
    const { donationTargets, instructor, donationBookings, match, siteTemplate } = this.props;

    const desktopPhoto = instructor.photo ? instructor.photo.lg : '';
    const mobilePhoto = instructor.mobilePhoto ? instructor.mobilePhoto.photo: '';
    const photo = isMobile && mobilePhoto ? mobilePhoto : desktopPhoto;

    return (
      <div className='profile-section'>
        <div className='profile-hero'>
          <Photo src={photo} />

          <div className='hero-content'>
            <div className='content'>
              <div className='profile-header home__header'>
                <InstructorName
                    hasLocation
                    instructor={instructor} />
                <FollowButton
                    followersCount={instructor.followersCount}
                    isDisabled={!!instructor.isOwner}
                    isFollowing={!!instructor.isFollowing}
                    onClick={this.handleFollow} />
              </div>

              <div className='profile-footer'>
                <div className='instructor-tag-line'>
                  <span>{instructor.tagline}</span>
                </div>
                { instructor.donationsEnabled && !isEmpty(donationTargets.name) &&
                  <Fundraiser
                      donationBookings={donationBookings}
                      donationTargets={donationTargets}
                      instructor={instructor} />
                }
              </div>
            </div>
          </div>
        </div>

        <div className='profile-info'>
          <div className='content'>
            { instructor.activities &&
              <Activities instructor={instructor} />
            }
            <div className='instructor-info'>
              {!isEmpty(instructor.socialProfiles) &&
                <Contacts
                    instructor={instructor}
                    siteTemplate={siteTemplate} />
              }

              {!isEmpty(instructor.services) && <Services instructor={instructor} />}

              { !isEmpty(instructor.locations) &&
                <Locations
                    instructor={instructor}
                    type='locations' />
              }
            </div>
          </div>
        </div>
        <OptionalSections
            homeConfig={instructor.homeConfig}
            instructor={instructor}
            match={match} />
      </div>
    );
  }
}

export default HomeDefault;
