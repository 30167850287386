/* eslint-disable react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { find } from 'lodash';

import { selectFieldStyle } from '../../constants';

class SelectField extends React.PureComponent {
  static propTypes = {
    children:             PropTypes.node,
    className:            PropTypes.string,
    disabledOptionType:   PropTypes.string,
    disabledOptionValues: PropTypes.array,
    emptyMessage:         PropTypes.string,
    formatOptionLabel:    PropTypes.func,
    isDisabled:           PropTypes.bool,
    isInvalid:            PropTypes.bool,
    isLoading:            PropTypes.bool,
    isOptionSelected:     PropTypes.func,
    isSearchable:         PropTypes.bool,
    labelName:            PropTypes.string,
    menuPlacement:        PropTypes.string,
    name:                 PropTypes.string,
    onChange:             PropTypes.func,
    options:              PropTypes.arrayOf(PropTypes.object),
    placeholder:          PropTypes.string,
    styles:               PropTypes.object,
    value:                PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
    valueName:            PropTypes.string
  }

  static defaultProps = {
    children:             null,
    className:            '',
    disabledOptionType:   null,
    disabledOptionValues: [],
    emptyMessage:         '',
    formatOptionLabel:    null,
    isDisabled:           false,
    isInvalid:            false,
    isLoading:            false,
    isOptionSelected:     () => {},
    isSearchable:         false,
    labelName:            'label',
    menuPlacement:        'auto',
    name:                 '',
    onChange:             () => {},
    options:              [],
    placeholder:          'Select...',
    styles:               selectFieldStyle.default,
    value:                '',
    valueName:            'value'
  }

  static getDerivedStateFromProps(props) {
    return {
      options:       props.options,
      selectedValue: find(props.options, [props.valueName, props.value])
    };
  }

  state = {
    options:       [],
    selectedValue: {}
  }

  handleSelect = (option) => {
    this.setState((prevState) => ({
      ...prevState,
      selectedValue: option
    }), () => {
      this.props.onChange(option[this.props.valueName], this.props.name);
    });
  }

  handleDisabledOption = (attrName, attrValues) => (option) => {
    return attrValues.includes(option[attrName]);
  };

  renderEmptyMessage = () => this.props.emptyMessage || 'No options'

  render() {
    const { isInvalid,
            disabledOptionType,
            disabledOptionValues,
            labelName,
            valueName,
            isOptionSelected,
            className,
            formatOptionLabel,
            isDisabled,
            isSearchable,
            menuPlacement,
            name,
            placeholder,
            styles,
            children,
            isLoading
    } = this.props;

    const getOptionLabel = (option) => option[labelName];

    const getOptionValue = (option) => option[valueName];

    return (
      <Select
          className={className}
          classNamePrefix='form-select'
          closeMenuOnSelect
          formatOptionLabel={formatOptionLabel}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          isLoading={isLoading}
          isOptionDisabled={this.handleDisabledOption(disabledOptionType, disabledOptionValues)}
          isOptionSelected={isOptionSelected}
          isSearchable={isSearchable}
          menuPlacement={menuPlacement}
          name={name}
          noOptionsMessage={this.renderEmptyMessage}
          onChange={this.handleSelect}
          options={this.state.options}
          placeholder={placeholder}
          styles={styles}
          value={this.state.selectedValue}>
        {children}
      </Select>
    );
  }
}

export default SelectField;
