export default {
  borderless: {
    control: (provided, state) => ({
      ...provided,
      '&:hover': {
        border: state.isFocused ? 0 : 0
      },

      backgroundColor: 'transparent',
      border:          state.isFocused ? 0 : 0,
      boxShadow:       state.isFocused ? 0 : 0,
      height:          '100%',
      minHeight:       'auto'
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '.85rem',
      margin:   0,
      padding:  0
    }),
    placeholder: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap'
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0'
    })
  },
  default: {
    control: (provided) => ({
      ...provided,
      borderRadius: '8px',
      height:       'calc(1.5em + 1.25rem - 1px)'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#868E96'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'transparent'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: '0 8px'
    }),
    input: (provider) => ({
      ...provider,
      fontSize:   '.875rem',
      lineHeight: '1.5',
      margin:     0,
      position:   'absolute',
      top:        '22%'
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: '3'
    }),
    option: (provider) => ({
      ...provider,
      fontSize:   '.875rem',
      overflowX:  'hidden',
      padding:    '12px 1.25rem',
      whiteSpace: 'nowrap'
    }),
    placeholder: (provided) => ({
      ...provided,
      color:         '#868e96',
      fontSize:      '.875rem',
      fontStyle:     'italic',
      letterSpacing: '0.01em',
      lineHeight:    '1.5',
      marginLeft:    0,
      marginRight:   0,
      whiteSpace:    'nowrap'
    }),
    singleValue: (provider) => ({
      ...provider,
      color:       '#495057',
      fontSize:    '.875rem',
      lineHeight:  '1.5',
      marginLeft:  0,
      marginRight: 0
    }),
    valueContainer: (provider) => ({
      ...provider,
      height:  '100%',
      padding: '0.625rem 1.25rem'
    })
  },
  location: {
    control: (provided) => ({
      ...provided,
      borderColor:  '#ced4da',
      borderRadius: '8px',
      height:       'calc(1.5em + 1.25rem - 1px)'
    }),
    dropdownIndicator: (provider) => ({
      ...provider,
      display: 'none'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'transparent'
    }),
    input: (provider) => ({
      ...provider,
      fontSize:   '.875rem',
      lineHeight: '1.5',
      margin:     0,
      position:   'absolute',
      top:        '22%'
    }),
    menuList: (provider) => ({
      ...provider,
      overflowX: 'hidden'
    }),
    option: (provider) => ({
      ...provider,
      fontSize: '.875rem',
      padding:  '12px 1.25rem'
    }),
    placeholder: (provided) => ({
      ...provided,
      color:         '#868e96',
      letterSpacing: '0.01em',
      lineHeight:    '1.5',
      marginLeft:    0,
      marginRight:   0,
      whiteSpace:    'nowrap'
    }),
    singleValue: (provider) => ({
      ...provider,
      color:       '#495057',
      fontSize:    '.875rem',
      lineHeight:  '1.5',
      marginLeft:  0,
      marginRight: 0
    }),
    valueContainer: (provider) => ({
      ...provider,
      height:  '100%',
      padding: '0.625rem 1.25rem'
    })
  },
  playlists: {
    option: (provider, state) => ({
      ...provider,
      backgroundColor: state.isSelected ? '#0b9939' : (state.isFocused ? '#e4ffe1' : 'transparent'),
      color:           state.isSelected ? '#fff' : '#1DB954',
      overflowX:       'hidden',
      whiteSpace:      'nowrap'
    })
  },
  small: {
    control: (provided,  { selectProps: { isInvalid }}) => ({
      ...provided,
      borderColor:  isInvalid ? '#fd5f60' : '#ced4da',
      borderRadius: '8px',
      height:       'calc(1.5em + 0.5rem + 2px)',
      minHeight:    '31px'
    }),
    dropdownIndicator: (provided,  { selectProps: { isInvalid }}) => ({
      ...provided,
      color:   isInvalid ? '#fd5f60' : '#868E96',
      padding: '6px'
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      backgroundColor: 'transparent'
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      padding: '0 8px'
    }),
    input: (provider) => ({
      ...provider,
      fontSize:   '.875rem',
      lineHeight: '1.5',
      margin:     0,
      position:   'absolute',
      top:        '22%'
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: '3'
    }),
    option: (provider) => ({
      ...provider,
      fontSize:   '.875rem',
      overflowX:  'hidden',
      padding:    '12px 1.25rem',
      whiteSpace: 'nowrap'
    }),
    placeholder: (provided) => ({
      ...provided,
      color:         '#868e96',
      fontSize:      '.875rem',
      fontStyle:     'italic',
      letterSpacing: '0.01em',
      lineHeight:    '1.5',
      marginLeft:    0,
      marginRight:   0,
      whiteSpace:    'nowrap'
    }),
    singleValue: (provider) => ({
      ...provider,
      color:       '#495057',
      fontSize:    '.875rem',
      lineHeight:  '1.5',
      marginLeft:  0,
      marginRight: 0
    }),
    valueContainer: (provider) => ({
      ...provider,
      height:  '100%',
      padding: '0.25rem 0.5rem'
    })
  },
  withoutIndicator: {
    dropdownIndicator: (provider) => ({
      ...provider,
      display: 'none'
    })
  }
};