import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'reactstrap';
import { isMobile } from 'react-device-detect';

import { ElegantHeart, ElegantWallet } from '../../../../../images';

class GroupClassItemElegant extends React.PureComponent {
  static propTypes = {
    customization:      PropTypes.object.isRequired,
    groupClass:         PropTypes.object.isRequired,
    onHandleToggle:     PropTypes.func.isRequired,
    renderLocationInfo: PropTypes.func.isRequired,
    time:               PropTypes.string.isRequired
  };

  render() {
    const { groupClass, onHandleToggle, renderLocationInfo, time } = this.props;
    const { primaryButtonsBackgroundColor } = this.props.customization;

    return (
      <Card className="card-event body-class__event">
        { groupClass.imageType !== 'none' &&
          <div
              className='body-class__content photo mr-4 img-thumbnail rounded-0'
              style={{
                backgroundImage: `url(${groupClass.preview})`,
                backgroundSize:  'cover',
                display:         isMobile ? 'none' : 'block'
          }} />
        }
        <div className='body-class-main'>
          <div className="body-class__name">{groupClass.name}</div>
          <div className="body-class-content">
            <div className="time">{time}</div>
            <div className="duration">&nbsp;- {groupClass.duration} mins</div>
            {renderLocationInfo(groupClass)}
          </div>
          <Button
              color='link'
              onClick={onHandleToggle(groupClass)}
              size="sm">
            Reserve
          </Button>
        </div>

        <div className="body-class-icon">
          { groupClass.feeType === 'paid'
            ? <ElegantWallet color={primaryButtonsBackgroundColor} />
            : <ElegantHeart color={primaryButtonsBackgroundColor} />
          }
          {groupClass.feeType !== 'paid' && groupClass.feeType}
        </div>
      </Card>
    );
  }
}

export default GroupClassItemElegant;
