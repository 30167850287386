import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { Button, Form } from '../forms';
import { Input } from '../inputs';

class UpdatePasswordModal extends React.PureComponent {
  static propTypes = {
    isOpen:         PropTypes.bool,
    onChange:       PropTypes.func.isRequired,
    onSubmit:       PropTypes.func.isRequired,
    onToggle:       PropTypes.func,
    updatePassword: PropTypes.object.isRequired
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  handleSubmit = () => {
    this.props.onSubmit();
    this.props.onToggle();
  }

  render() {
    return (
      <Modal
          centered
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle}>
          Change password
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for='password'>New password</Label>
              <Input
                  id='password'
                  name='password'
                  onChange={this.props.onChange}
                  type='password'
                  value={this.props.updatePassword.password} />
            </FormGroup>
            <FormGroup>
              <Label for='passwordConfirmation'>Confirm new password</Label>
              <Input
                  id='passwordConfirmation'
                  name='passwordConfirmation'
                  onChange={this.props.onChange}
                  type='password'
                  value={this.props.updatePassword.passwordConfirmation} />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
              color='primary'
              onClick={this.handleSubmit}>
            Update
          </Button>
          <Button
              className='ml-1'
              color='secondary'
              onClick={this.props.onToggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default UpdatePasswordModal;
