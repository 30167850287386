import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { InstagramLogo, SpotifyLogo } from '../../../images';
import { Button } from '../../forms';
import { routes } from '../../../lib';
import { Cable } from '../../cable';
import { OAuthWindow } from '../../oauth';
import { SelectPlaylistContainer } from '../../../containers';

class Integrations extends React.PureComponent {
  static propTypes = {
    fetchUser:       PropTypes.func.isRequired,
    isOnlyInstagram: PropTypes.bool,
    logout:          PropTypes.func,
    oauth:           PropTypes.object,
    playlist:        PropTypes.object
  }

  static defaultProps = {
    isOnlyInstagram: false,
    logout:          () => null,
    oauth:           {
      instagram: false,
      spotify:   false
    },
    playlist: {}
  }

  static getDerivedStateFromProps(props) {
    return {
      oauth:    props.oauth,
      playlist: props.playlist
    };
  }

  state = {
    isInstagramLoading: false,
    isPlaylistsOpen:    false,
    oauth:              {},
    playlist:           {}
  };

  handleSpotifyDestroy = () => {
    this.props.logout('spotify');
  };

  handleInstagramDestroy = () => {
    this.props.logout('instagram');
  };

  handlePlaylists = () => {
    this.setState((prevState) => ({
      ...prevState,
      isPlaylistsOpen: !prevState.isPlaylistsOpen
    }));
  };

  handleActivate = (data) => {
    this.setState((prevState) => ({
      oauth: {...prevState.oauth, ...data}
    }));
  };

  handleSpotifySuccess = () => {
    this.handlePlaylists();
    this.props.fetchUser();
  };

  handleInstagramSuccess = () => {
    this.props.fetchUser();
  };

  handleInstagramClick = () => {
    this.setState({
      isInstagramLoading: true
    });
  }

  render() {
    const { oauth, isPlaylistsOpen, playlist } = this.state;

    return (
      <React.Fragment>
        <Cable
            channel='IntegrationsChannel'
            onReceived={this.handleActivate} />
        <div className='form-third-party'>

          <div className='service-block'>
            <div className='service'>
              <img
                  alt='Instagram'
                  className='mr-2'
                  src={InstagramLogo} />
              <span className='service-name'>Instagram</span>
            </div>

            <div className='service-actions d-block'>
              { !oauth.instagram &&
                <React.Fragment>
                  <div className='small d-flex text-muted mb-1 mt-1'>
                    Connect up your Instagram to add your timeline to your page
                  </div>

                  <OAuthWindow
                      onSuccess={this.handleInstagramSuccess}
                      url={routes.INSTAGRAM_AUTH}>
                    <Button
                        className='save-changes-btn'
                        color='primary'
                        isBlock
                        isLoading={this.state.isInstagramLoading}
                        onClick={this.handleInstagramClick}>
                      Connect
                    </Button>
                  </OAuthWindow>
                </React.Fragment>
              }

              { oauth.instagram &&
                <React.Fragment>
                  <div className='small d-flex text-muted font-italic mb-1 mt-1'>
                    Connected to
                    <span>&nbsp;@{oauth.instagram.name}&nbsp;</span>(click here to&nbsp;
                    <Link onClick={this.handleInstagramDestroy}>
                      <div className='text-secondary'>disconnect</div>
                    </Link>
                    )
                  </div>
                </React.Fragment>
              }
            </div>
          </div>

          { !this.props.isOnlyInstagram &&
            <div className='service-block'>
              <div className='service'>
                <img
                    alt='Spotify'
                    className='mr-2'
                    src={SpotifyLogo} />
                <span className='service-name'>Spotify</span>
              </div>

              <div className='service-actions'>
                { !oauth.spotify &&
                  <OAuthWindow
                      onSuccess={this.handleSpotifySuccess}
                      url={routes.SPOTIFY_AUTH}>
                    <div className='small d-flex text-muted mb-1 mt-1'>
                      Connect up your Spotify a playlist to your page AND to each classes
                    </div>
                    <Button
                        className='save-changes-btn'
                        color='primary'
                        isBlock>
                      Connect
                    </Button>
                  </OAuthWindow>
                }

                { oauth.spotify &&
                  <React.Fragment>
                    <div className='small d-flex text-muted font-italic mb-1 mt-1'>
                      Connected to
                      <span>&nbsp;{oauth.spotify.name}&nbsp;</span>(click here to&nbsp;
                      <Link onClick={this.handleSpotifyDestroy}>
                        <div className='text-secondary'>disconnect</div>
                      </Link>
                      )
                    </div>
                    <div className='small d-flex text-muted font-italic mb-1'>
                      Selected Playlist:<span className='text-success'>&nbsp;{playlist.name}</span>
                    </div>
                    <Button
                        className='save-changes-btn'
                        color='success'
                        onClick={this.handlePlaylists}>
                      Update playlist
                    </Button>
                  </React.Fragment>
                }
              </div>
            </div>
          }
        </div>
        <SelectPlaylistContainer
            isOpen={isPlaylistsOpen}
            onToggle={this.handlePlaylists} />
      </React.Fragment>
    );
  }
}

export default Integrations;
