import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresAuth } from '../helpers';
import { FavoriteOnDemandsPage } from '../components/pages';
import {
  ClassPacksActions,
  DiscountActions,
  FavoriteOnDemandsActions,
  MembershipsActions,
  SubscriptionsActions
} from '../actions';

class FavoriteOnDemandsContainer extends React.PureComponent {
  static propTypes = {
    fetchFavoriteOnDemands: PropTypes.func.isRequired,
    fetchSubscriptions:     PropTypes.func.isRequired,
    fetchUserClassPacks:    PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.fetchFavoriteOnDemands();
    this.props.fetchUserClassPacks();
    this.props.fetchSubscriptions();
  }

  render() {
    return (
      <FavoriteOnDemandsPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  classPacks:        state.classPacks.userData,
  discount:          state.discount.data,
  favoriteOnDemands: state.favoriteOnDemands.data,
  instructor:        state.instructor.data,
  instructorPacks:   state.classPacks.data,
  loader:            state.loader,
  memberships:       state.memberships.instructorData,
  subscriptions:     state.subscriptions.data,
  user:              state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createDiscount:         (data) => dispatch(DiscountActions.CREATE(data)),
  fetchClassPacks:        (data) => dispatch(ClassPacksActions.FETCH(data)),
  fetchFavoriteOnDemands: ()     => dispatch(FavoriteOnDemandsActions.FETCH()),
  fetchMemberships:       (data) => dispatch(MembershipsActions.INSTRUCTORS_FETCH(data)),
  fetchSubscriptions:     ()     => dispatch(SubscriptionsActions.FETCH()),
  fetchUserClassPacks:    ()     => dispatch(ClassPacksActions.USERS_FETCH()),
  restoreDiscount:        ()     => dispatch(DiscountActions.RESTORE()),
  subtractCredit:         (data) => dispatch(ClassPacksActions.SUBTRACT(data)),
  unLike:                 (data) => dispatch(FavoriteOnDemandsActions.DELETE(data))
});

export default compose(
  requiresAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(FavoriteOnDemandsContainer);
