import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { Col, Container, Media, Row } from 'reactstrap';

import { domainHelpLinks } from '../../../constants';
import { routes } from '../../../lib';
import { BackButton } from '../../buttons';
import { Heading, Panel } from '../../layout/pages';
import { DomainForm } from '../../forms/settings';
import { Alert } from '../../errors';

class SettingsDomainPage extends React.PureComponent {
  static propTypes = {
    createDomain: PropTypes.func.isRequired,
    deleteDomain: PropTypes.func.isRequired,
    domain:       PropTypes.object.isRequired,
    getDomain:    PropTypes.func.isRequired,
    instructor:   PropTypes.object.isRequired,
    isLoading:    PropTypes.bool.isRequired,
    subscription: PropTypes.object.isRequired
  }

  componentDidMount () {
    this.props.getDomain();
  }

  handleCreate = (host) => {
    this.props.createDomain({
      instructorDomain:   { host },
      instructorUsername: this.props.instructor.username
    });
  }

  handleDelete = () => {
    this.props.deleteDomain({
      instructorUsername: this.props.instructor.username
    });
  }

  render () {
    const { domain, isLoading, subscription } = this.props;

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Edit custom domain</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Custom domain' />

            <Panel className='settings-menu'>
              { subscription.customDomain &&
                <Row>
                  <Col
                      className='pl-3'
                      lg={6}
                      xs={12}>
                    <Alert
                        color='info'
                        heading='How to add domain to onPodio'>
                      <p>
                        <strong>First</strong>, to use a custom domain name with your onPodio website,&nbsp;
                        you need to first purchase a domain name
                        from a <strong>domain registrant</strong> (e.g. GoDaddy).
                      </p>
                      <p>
                        <strong>Second</strong>, once you have a domain name purchased you will need to&nbsp;
                        <strong>change your default nameservers to
                          onPodio’s nameservers.</strong> Allow at least 24 to 48 hours for your changes to take effect.
                      </p>
                      <ul>
                        <li><strong>ns1.onpodio.com</strong></li>
                        <li><strong>ns2.onpodio.com</strong></li>
                      </ul>
                      <p>
                        This process shouldn&apos;t take up more than 5 minutes of your time and below are links
                        to popular hosting platforms, along with guides for changing your nameservers.
                      </p>
                      <p>
                        If you don&apos;t see yours below, searching your domain registrar along with the keyword
                        “<i>change nameserver</i>” should point you in the right direction (e.g. Google search “Godaddy
                        change nameserver”)
                      </p>
                      <ul>
                        <li>
                          <a
                              className='alert-link'
                              href={domainHelpLinks.DOMAINS101}
                              rel='noopener noreferrer'
                              target='_blank'>
                            101 Domains
                          </a>
                        </li>
                        <li>
                          <a
                              className='alert-link'
                              href={domainHelpLinks.GODADDY}
                              rel='noopener noreferrer'
                              target='_blank'>
                            GoDaddy
                          </a>
                        </li>
                        <li>
                          <a
                              className='alert-link'
                              href={domainHelpLinks.GDOMAINS}
                              rel='noopener noreferrer'
                              target='_blank'>
                            Google Domains
                          </a>
                        </li>
                        <li>
                          <a
                              className='alert-link'
                              href={domainHelpLinks.HOSTGATOR}
                              rel='noopener noreferrer'
                              target='_blank'>
                            Hostgator
                          </a>
                        </li>
                        <li>
                          <a
                              className='alert-link'
                              href={domainHelpLinks.NAMECHEAP}
                              rel='noopener noreferrer'
                              target='_blank'>
                            Namecheap
                          </a>
                        </li>
                        <li>
                          <a
                              className='alert-link'
                              href={domainHelpLinks.SITEGROUP}
                              rel='noopener noreferrer'
                              target='_blank'>
                            SiteGround
                          </a>
                        </li>
                      </ul>
                    </Alert>
                  </Col>
                </Row>
              }

              { !subscription.customDomain &&
                <Row className='justify-content-center'>
                  <Col
                      lg={3}
                      xs={12}>
                    <Alert
                        color='info'
                        textClassName='text-center'>
                      This is only available on the Pro Plan.<br />
                      Upgrade your plan
                    </Alert>
                  </Col>
                </Row>
              }

              <Media className='menu-link d-block border-0'>
                <Media body>
                  <DomainForm
                      data={domain}
                      hasProPlan={subscription.customDomain}
                      isLoading={isLoading}
                      onCreate={this.handleCreate}
                      onDelete={this.handleDelete} />
                </Media>
              </Media>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SettingsDomainPage;
