import { FilterActionTypes } from '../constants/actionTypes';

export default {
  FILTER: (payload) => ({
    payload,
    type: FilterActionTypes.FILTER
  }),

  FILTER_FAILURE: () => ({
    type: FilterActionTypes.FILTER_FAILURE
  }),

  FILTER_SUCCESS: (payload)  => ({
    payload,
    type: FilterActionTypes.FILTER_SUCCESS
  })
};

