import { BookingsActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: BookingsActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: BookingsActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: BookingsActionTypes.CREATE_SUCCESS
  }),


  DELETE: (payload) => ({
    payload, type: BookingsActionTypes.DELETE
  }),

  DELETE_FAILURE: () => ({
    type: BookingsActionTypes.DELETE_FAILURE
  }),

  DELETE_SUCCESS: (payload) => ({
    payload, type: BookingsActionTypes.DELETE_SUCCESS
  }),


  FETCH: () => ({
    type: BookingsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: BookingsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: BookingsActionTypes.FETCH_SUCCESS
  }),


  INSTRUCTORS_FETCH: () => ({
    type: BookingsActionTypes.INSTRUCTORS_FETCH
  }),

  INSTRUCTORS_FETCH_FAILURE: () => ({
    type: BookingsActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_FETCH_SUCCESS: (payload) => ({
    payload, type: BookingsActionTypes.INSTRUCTORS_FETCH_SUCCESS
  })
};

