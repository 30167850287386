import { TimeBlocksActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: TimeBlocksActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.CREATE_SUCCESS
  }),


  DELETE: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.DELETE
  }),

  DELETE_FAILURE: () => ({
    type: TimeBlocksActionTypes.DELETE_FAILURE
  }),

  DELETE_SUCCESS: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.DELETE_SUCCESS
  }),


  FETCH: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: TimeBlocksActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.FETCH_SUCCESS
  }),


  GET: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: TimeBlocksActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.GET_SUCCESS
  }),


  RECURRING_CREATE: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.RECURRING_CREATE
  }),

  RECURRING_CREATE_FAILURE: () => ({
    type: TimeBlocksActionTypes.RECURRING_CREATE_FAILURE
  }),

  RECURRING_CREATE_SUCCESS: () => ({
    type: TimeBlocksActionTypes.RECURRING_CREATE_SUCCESS
  }),


  RECURRING_DELETE: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.RECURRING_DELETE
  }),

  RECURRING_DELETE_FAILURE: () => ({
    type: TimeBlocksActionTypes.RECURRING_DELETE_FAILURE
  }),

  RECURRING_DELETE_SUCCESS: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.RECURRING_DELETE_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: TimeBlocksActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload,
    type: TimeBlocksActionTypes.UPDATE_SUCCESS
  })
};
