import React from 'react';
import PropTypes from 'prop-types';

import { Calendr } from '../../../../lib';
import { Day } from '../../selected';

class WeeklyView extends React.PureComponent {
  static propTypes = {
    bookings:     PropTypes.array.isRequired,
    onEventClick: PropTypes.func.isRequired,
    selectedDate: PropTypes.object.isRequired,
    user:         PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props) {
    const range = Calendr.getRange(props.selectedDate, props.user.timeZone, 'week');

    return {
      bookings: Calendr.bookingsInRange(props.bookings, props.user.timeZone, range),
      weekDays: Calendr.getWeekdays(range)
    };
  }

  state = {
    bookings: [],
    weekDays: []
  };

  render () {
    const { weekDays, bookings} = this.state;

    return (
      <div className='weekdays'>
        {!!weekDays.length && weekDays.map((day) => (
          <Day
              bookings={bookings}
              date={day}
              hasBookings
              key={day.format('DD_MM')}
              onEventClick={this.props.onEventClick}
              user={this.props.user} />
        ))}
      </div>
    );
  }
}

export default WeeklyView;
