/* eslint-disable promise/no-nesting */

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { Autocompleter, Button, Radio } from '../forms';
import { Input } from '../inputs';
import { Validator, api } from '../../lib';
import { AutocompleterContainer } from '../../containers';

class AddClientModal extends React.PureComponent {
  static propTypes = {
    instructor:        PropTypes.object.isRequired,
    isOpen:            PropTypes.bool.isRequired,
    onHandleAddClient: PropTypes.func.isRequired,
    onToggle:          PropTypes.func.isRequired,
    privateGroupClass: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const initialBookingType = props.privateGroupClass.eventType == 'online' ? 'online' : 'offline';

    return {
      bookingType:         state.isMounted ?  state.bookingType : initialBookingType,
      isMounted:           true,
      privateGroupClassId: props.privateGroupClass.id
    };
  }

  state = {
    bookingType: '',
    client:      {
      email: '',
      name:  ''
    },
    clientId:            '',
    errors:              {},
    isExisting:          true,
    privateGroupClassId: null,
    sendEmail:           true
  }

  handleSwitch = (fieldName, value) => () => (
    this.setState({ [fieldName]: value })
  )

  addWithNewClient = () => {
    const { client, privateGroupClassId, sendEmail } = this.state;

    return this.handleValidateNewClient().then(() => {
      return api.instructor.client.create({ client })
      .then((response) => (
          api.instructor.privateGroupClasses.bookings.create({
            booking: {
              client_id: response.data.id,
              privateGroupClassId,
              sendEmail
            }
          })
          .then((response) =>(
            this.props.onHandleAddClient({
              email: response.data.client.email,
              id:    response.data.id,
              name:  response.data.client.name,
              privateGroupClassId
            })
          )),
          this.props.onToggle(),
          toastr.success('Client added')
        ))
        .catch((errors) => {
          return toastr.error(errors.response.data.message[0]);
        });
    });

  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      client: {
        ...prevState.client,
        [inputName]: value
      }
    }));
  };

  handleSelect = (inputName) => (option) => (
    this.setState({ [inputName]: option.id })
  )

  addWithExistClient = () => {
    const { clientId, privateGroupClassId, sendEmail, bookingType } = this.state;

    return this.handleValidateExistClient().then(() => {
      return api.instructor.privateGroupClasses.bookings.create({
        booking: { bookingType, clientId, privateGroupClassId, sendEmail }
      })
      .then((response) =>(
        this.props.onHandleAddClient({
          bookingType: response.data.booking_type,
          email:       response.data.client.email,
          id:          response.data.id,
          name:        response.data.client.name,
          privateGroupClassId
        }),
        toastr.success('Client added')
      ));
    });
  }

  handleCreateClient = (sendEmail) => async () => {
    const { isExisting } = this.state;

   this.setState(() => ({ sendEmail }), () => {
      isExisting ? this.addWithExistClient() : this.addWithNewClient();
    });
  }

  handleValidateNewClient = () => new Promise((resolve, reject) => {
    const { client } = this.state;

    const errors = Validator.validate([
      ['email', Validator.concepts.emailRegex, [client.email]],
      ['name',  Validator.concepts.isFilled,   [client.name]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      this.setState({ errors }, () => reject(errors));
    }
  });

  handleValidateExistClient = () => new Promise((resolve, reject) => {
    const { clientId } = this.state;

    const errors = Validator.validate([
      ['clientId', Validator.concepts.isFilled, [clientId]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      this.props.onToggle();
      resolve();
    } else {
      this.setState({ errors }, () => reject(errors));
    }
  });

  render() {
    const { privateGroupClass, instructor } = this.props;
    const { bookingType, client, errors } = this.state;

    return (
      <React.Fragment>
        <Modal
            centered
            className='modal-event'
            isOpen={this.props.isOpen}
            toggle={this.props.onToggle}>
          <ModalHeader toggle={this.props.onToggle}>
            Add Client Manually
          </ModalHeader>

          <ModalBody>
            <Form className='form-default'>
              <Label className='required'>
                Is this an Existing or New client?
              </Label>
              <FormGroup>
                <div className='d-flex mb-0'>
                  <div className='custom-control custom-radio w-50 d-flex align-items-center m-0'>
                    <Radio
                        className='custom-control-input'
                        id='existing'
                        isChecked={this.state.isExisting}
                        name='existing'
                        onChange={this.handleSwitch('isExisting', true)}
                        value={false} />
                    <Label
                        className='custom-control-label'
                        htmlFor='input-existing'>
                      Existing
                    </Label>
                  </div>

                  <div className='custom-control custom-radio w-50 d-flex align-items-center m-0'>
                    <Radio
                        className='custom-control-input'
                        id='new'
                        isChecked={!this.state.isExisting}
                        name='new'
                        onChange={this.handleSwitch('isExisting', false)}
                        value={false} />
                    <Label
                        className='custom-control-label'
                        htmlFor='input-new'>
                      New
                    </Label>
                  </div>
                </div>
              </FormGroup>

              { this.state.isExisting &&
                <FormGroup>
                  <Label
                      className='small text-muted'
                      for='clients-autocompleter'>
                    Select from My Clients
                  </Label>

                  <AutocompleterContainer>
                    <Autocompleter
                        hasInitialValue
                        id='clients-autocompleter'
                        instructor={instructor}
                        isInvalid={!!errors.clientId}
                        name='clients'
                        onChange={this.handleChange}
                        onSelect={this.handleSelect('clientId')}
                        placeholder='Start typing client name'
                        subject='clients'
                        value={this.state.clientId} />
                  </AutocompleterContainer>
                </FormGroup>
              }

              {!this.state.isExisting &&
                <React.Fragment>
                  <FormGroup>
                    <Label
                        className='required'
                        for='input-name'>
                      Full Name
                    </Label>

                    <Input
                        id='name'
                        isInvalid={!!errors.name}
                        name='name'
                        onChange={this.handleChange}
                        value={client.name} />
                  </FormGroup>

                  <FormGroup>
                    <Label
                        className='required'
                        for='input-name'>
                      Email
                    </Label>

                    <Input
                        id='email'
                        isInvalid={!!errors.email}
                        name='email'
                        onChange={this.handleChange}
                        value={client.email} />
                  </FormGroup>
                </React.Fragment>
              }

              { privateGroupClass.eventType == 'hybrid' &&
                <React.Fragment>
                  <Label className='required'>
                    Choose type of spot
                  </Label>
                  <FormGroup>
                    <div className='d-flex mb-0'>
                      <div className='custom-control custom-radio w-50 d-flex align-items-center m-0'>
                        <Radio
                            className='custom-control-input'
                            id='offline'
                            isChecked={bookingType == 'offline'}
                            name='offline'
                            onChange={this.handleSwitch('bookingType', 'offline')} />
                        <Label
                            className='custom-control-label'
                            htmlFor='input-offline'>
                          In-Person
                        </Label>
                      </div>

                      <div className='custom-control custom-radio w-50 d-flex align-items-center m-0'>
                        <Radio
                            className='custom-control-input'
                            id='online'
                            isChecked={bookingType == 'online'}
                            name='online'
                            onChange={this.handleSwitch('bookingType', 'online')} />
                        <Label
                            className='custom-control-label'
                            htmlFor='input-online'>
                          Virtual
                        </Label>
                      </div>
                    </div>
                  </FormGroup>
                </React.Fragment>
              }

              <div className='d-flex text-muted small'>
                Note that by adding a client to this class manually
                they will not be charged for the class
              </div>
            </Form>
          </ModalBody>

          <ModalFooter>
            <Row>
              <Col
                  lg={6}
                  md={12}>
                <Button
                    color='muted'
                    isBlock
                    isOutline
                    onClick={this.handleCreateClient(false)}
                    size='lg'>
                  Add Only
                </Button>
              </Col>
              <Col
                  lg={6}
                  md={12}>
                <Button
                    className='mt-2 mt-lg-0'
                    color='primary'
                    isBlock
                    onClick={this.handleCreateClient(true)}
                    size='lg'>
                  Add & Send Email
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default AddClientModal;
