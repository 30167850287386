/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';

import { Button, MaskedInput } from '../../../forms';
import { BOOKING_STEPS } from '../../../../constants';
import { currencyMask } from '../../../../constants/masks';
import { Form } from '../../../forms';
import { UserModalAvatar } from '../../layouts/';
import { Validator } from '../../../../lib';
import { Errors } from '../../../errors';
import { TimeZoneInfo } from './';


class Donation extends React.PureComponent {
  static propTypes = {
    date:              PropTypes.object.isRequired,
    isLoading:         PropTypes.bool.isRequired,
    onBook:            PropTypes.func,
    onChange:          PropTypes.func,
    onLoading:         PropTypes.func.isRequired,
    onNext:            PropTypes.func,
    privateGroupClass: PropTypes.object.isRequired,
    sign:              PropTypes.string,
    user:              PropTypes.object.isRequired
  }

  static defaultProps = {
    onBook:   () => {},
    onChange: () => {},
    onNext:   () => {},
    sign:     '$'
  }

  state = {
    amount: '7.5',
    errors: {}
  }

  componentDidMount() {
    this.amount.inputElement.focus();
  }

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value });
  }

  handleValidate = (value) => new Promise((resolve, reject) => {
    const errors = Validator.validate([
      ['minAmount', Validator.concepts.minAmountValidator, [value]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });


  handleNext = () => {
    const { amount } = this.state;
    const { sign } = this.props;

    const value = parseFloat(amount.toString().replace(sign, ''));

    this.handleValidate(value).then(() => (
      this.setState({ errors: {} }, () => {
        this.props.onChange(value, 'amount', () => {
          this.props.onNext(BOOKING_STEPS.charge);
        });
      })
    )).catch((errors) => this.setState({ errors }));
  }

  handleSkip = () => (
    this.props.onLoading(true, this.props.onBook)
  )

  render() {
    const { date, isLoading, privateGroupClass, sign, user } = this.props;
    const { instructor, location, description, playlist } = privateGroupClass;

    return (
      <div className='new-modal'>
        <ModalBody>
          { instructor &&
            <div className='modal__header'>
              <UserModalAvatar
                  alt={instructor.name}
                  firstName={instructor.name}
                  src={instructor.photo} />
              <div className='modal__instructor-name'>
                <span className='modal__big-text'>{instructor.name}</span>
              </div>
            </div>
          }

          <div className='modal__event-name'>
            <span className='modal__big-text modal__big-text_black'>{privateGroupClass.name}</span>
          </div>

          <div className='modal__time'>
            <div className='modal__default-text modal__default-text_bold'>
              {date.format('dddd, MMMM DD')}
            </div>

            <div className='modal__default-text'>
              &nbsp;|&nbsp;{date.format('hh:mmA')} - {privateGroupClass.duration} Minutes
            </div>
          </div>

          <TimeZoneInfo timeZone={user.timeZone} />

          <div className='modal__event-spots modal__small-text'>
            {privateGroupClass.spots - privateGroupClass.bookingsCount} spots available
          </div>

          { location &&
            <div className='modal__event-location modal__small-text'>
              {location.name}
            </div>
          }

          { description &&
            <div className='event-description'>
              <span className='modal__small-text'>About this class</span>
              <p className='modal__small-text modal__small-text_grey'>{description}</p>
            </div>
          }

          { playlist &&
            <div className='modal__event-playlist'>
              <a
                  className='playlist-link'
                  href={`https://open.spotify.com/playlist/${playlist.playlistId}`}
                  rel='noopener noreferrer'
                  target='_blank'>
                <Icon
                    className='mr-2'
                    fixedWidth
                    icon={['fab', 'spotify']}
                    size='lg' />
                <span>{playlist.name}</span>
              </a>
            </div>
          }

          <div className='modal__donation-text'>
            <span className='modal__default-text modal__default-text_blue modal__default-text_bold'>
              Donation Based
            </span>
          </div>

          <Form className='form-default'>
            <FormGroup>
              <Label
                  className='modal__form-label'
                  htmlFor='input-amount'>
                I’d like to donate
              </Label>

              <MaskedInput
                  className='modal__donation-form'
                  id='amount'
                  inputMode='number'
                  mask={currencyMask(sign)}
                  name='amount'
                  onChange={this.handleChange}
                  onRef={(ref) => this.amount = ref}
                  placeholder={sign}
                  type='number'
                  value={String(this.state.amount)} />
              <div className='mt-3'>
                <Errors errors={this.state.errors} />
              </div>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter>
          <div className='modal__btn-group'>
            <Button
                color='blue'
                isBlock
                isLoading={isLoading}
                onClick={this.handleNext}
                size='lg'>
              Donate Now
            </Button>

            { !privateGroupClass.donationRequired &&
              <Button
                  className='modal__btn'
                  isBlock
                  onClick={this.handleSkip}
                  size='lg'>
                Skip Donation
              </Button>
            }
          </div>
        </ModalFooter>
      </div>
    );
  }
}

export default Donation;
