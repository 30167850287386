import React from 'react';
import PropTypes from 'prop-types';

import { Calendr } from '../../../../lib';
import { Day } from '../../selected';

class DailyView extends React.PureComponent {
  static propTypes = {
    bookings:     PropTypes.array.isRequired,
    onEventClick: PropTypes.func.isRequired,
    selectedDate: PropTypes.object.isRequired,
    user:         PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props) {
    const range = Calendr.getRange(props.selectedDate, props.user.timeZone, 'day');

    return {
      bookings: Calendr.bookingsInRange(props.bookings, props.user.timeZone, range)
    };
  }

  state = {
    bookings: []
  };

  render () {
    const { selectedDate, user } = this.props;
    const { bookings } = this.state;

    return (
      <Day
          bookings={bookings}
          date={selectedDate}
          hasBookings
          onEventClick={this.props.onEventClick}
          user={user} />
    );
  }
}

export default DailyView;
