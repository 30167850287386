/* eslint-disable react/jsx-handler-names */
import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { VideoFallback } from '../../images';
import { Uploader } from '../../lib';
import { Loader } from '../loader';
import { PreviewGenerator } from './';

class VideoPreview extends PureComponent {
  static propTypes = {
    fileName:        PropTypes.string,
    isPreviewLoaded: PropTypes.bool,
    isVideoUploaded: PropTypes.bool,
    onChange:        PropTypes.func,
    onLoad:          PropTypes.func,
    preview:         PropTypes.object,
    videoUrl:        PropTypes.string
  }

  static defaultProps = {
    fileName:        '',
    isPreviewLoaded: false,
    isVideoUploaded: false,
    onChange:        () => {},
    onLoad:          () => {},
    preview:         {},
    videoUrl:        ''
  }

  static getDerivedStateFromProps(props) {
    return {
      hasPreview: !isEmpty(props.preview)
    };
  }

  state = {
    hasPreview: false,
    previewUrl: ''
  }

  handleUpload = async (file) => {
    await file.start();
  }

  handleUploaderCallback = (fileUpload) => {
    if (fileUpload.state === 'finished') {
      this.props.onChange(fileUpload.signedId, 'image');
      this.props.onLoad();
    }
  }

  handlePreview = (thumbnail) => {
    this.setState({
      previewUrl: thumbnail
    }, () => {
      const filename = this.props.fileName.substr(0, this.props.fileName.lastIndexOf('.')) + '_preview.jpeg';
      const file = new File([this.state.previewUrl], filename,{ type: 'image/jpeg' });

      const upload = new Uploader(file, { onChangeFile: this.handleUploaderCallback });

      return this.handleUpload(upload);
    });
  }
  
  render () {
    const { videoUrl, preview, isVideoUploaded, isPreviewLoaded } = this.props;
    const { hasPreview } = this.state;

    if (!hasPreview) return (
      <Fragment>
        { !isVideoUploaded &&
          <img
              alt='Video preview'
              src={VideoFallback} />
        }
        { isVideoUploaded &&
          <PreviewGenerator
              atTime={8}
              onLoad={this.handlePreview}
              videoUrl={videoUrl} />
        }
        <Loader
            hasOpacity={false}
            isLoading={!isPreviewLoaded && isVideoUploaded} />
      </Fragment>
    );

    return (
      <img
          alt={preview.name}
          className='img-fluid'
          src={preview.url} />
    );
  }
}

export default VideoPreview;