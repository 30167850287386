import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonToolbar } from 'reactstrap';
import { compact, isEmpty } from 'lodash';

import { RequestedClassesCarouselContainer, UpcomingClassesContainer } from '../../../../containers';
import { PrivateSessionsCarousel } from '../../../../components/pages/profile';
import Header from '../components/headerUrban';

const SCHEDULES = {
  groupClasses:     'GROUP_CLASSES',
  privateSessions:  'PRIVATE_SESSIONS',
  requestedClasses: 'REQUESTED_CLASSES'
};

class Workout extends React.PureComponent {
  static propTypes = {
    availability:         PropTypes.object.isRequired,
    createDiscount:       PropTypes.func.isRequired,
    createPrivateSession: PropTypes.func.isRequired,
    discount:             PropTypes.object.isRequired,
    follow:               PropTypes.func.isRequired,
    getAvailability:      PropTypes.func,
    instructor:           PropTypes.object.isRequired,
    subtractCredit:       PropTypes.func.isRequired,
    unFollow:             PropTypes.func.isRequired,
    user:                 PropTypes.object.isRequired,
    userChallenges:       PropTypes.array.isRequired,
    userClassPacks:       PropTypes.array.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const { instructor } = props;
    const { groupClassesEnabled, requestedClassesEnabled, privateSessionsEnabled } = instructor;

    const toggleArray = compact([groupClassesEnabled, privateSessionsEnabled, requestedClassesEnabled]);
    const checkRequestedClasses = requestedClassesEnabled ? SCHEDULES.requestedClasses : SCHEDULES.privateSessions;

    if (!state.isMounted && !isEmpty(instructor)) {
      return {
        selectedCarousel: instructor.groupClassesEnabled ? SCHEDULES.groupClasses : checkRequestedClasses,
        toggleArray
      };
    }

    return state;
  }

  static defaultProps = {
    getAvailability: () => {}
  };

  state = {
    isMounted:        false,
    selectedCarousel: '',
    toggleArray:      []
  }

  handleFollow = () => {
    this.props.instructor.isFollowing ? this.props.unFollow() : this.props.follow();
  };

  renderCarousel = () => {
    switch (this.state.selectedCarousel) {
      case SCHEDULES.groupClasses:
        return <UpcomingClassesContainer />;

      case SCHEDULES.requestedClasses:
        return <RequestedClassesCarouselContainer theme='elegant' />;

      case SCHEDULES.privateSessions:
        return (
          <PrivateSessionsCarousel
              availability={this.props.availability}
              challenges={this.props.userChallenges}
              classPacks={this.props.userClassPacks}
              createDiscount={this.props.createDiscount}
              createPrivateSession={this.props.createPrivateSession}
              discount={this.props.discount}
              getAvailability={this.props.getAvailability}
              instructor={this.props.instructor}
              subtractCredit={this.props.subtractCredit}
              user={this.props.user} />
        );

      default:
        return null;
    }
  };

  handleToggle = (carousel) => () => {
    this.setState({ isMounted: true, selectedCarousel: carousel });
  };

  render() {
    const { instructor } = this.props;
    const { selectedCarousel } = this.state;

    return (
      <React.Fragment>
        <Header
            instructor={instructor}
            subtitle='Workout' />

        <div className='workout template-elegant'>
          <span className='workout__reason'>{instructor.workoutReason}</span>
          <div className='workout__body'>
            { this.state.toggleArray.length > 1 &&
              <ButtonToolbar>
                { instructor.groupClassesEnabled &&
                  <Button
                      className='px-lg-4'
                      color={selectedCarousel !== SCHEDULES.groupClasses ? 'secondary' : 'primary'}
                      customization={instructor.customization}
                      onClick={this.handleToggle(SCHEDULES.groupClasses)}>
                    Live Classes
                  </Button>
                }

                { instructor.requestedClassesEnabled &&
                  <Button
                      className='px-lg-4'
                      color={selectedCarousel !== SCHEDULES.requestedClasses ? 'secondary' : 'primary'}
                      customization={instructor.customization}
                      onClick={this.handleToggle(SCHEDULES.requestedClasses)}>
                    On Demand
                  </Button>
                }

                { instructor.privateSessionsEnabled &&
                  <Button
                      className='px-lg-4'
                      color={selectedCarousel !== SCHEDULES.privateSessions ? 'secondary' : 'primary'}
                      customization={instructor.customization}
                      onClick={this.handleToggle(SCHEDULES.privateSessions)}>
                    Private Session
                  </Button>
                }
              </ButtonToolbar>
            }
          </div>

          {this.state.toggleArray.length >= 1 && this.renderCarousel()}
        </div>
      </React.Fragment>
    );
  }
}

export default Workout;
