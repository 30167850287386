/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { find, sortBy } from 'lodash';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import DraggableItem from './draggableItem';

class Collections extends React.PureComponent {
  static propTypes = {
    collections:      PropTypes.array.isRequired,
    updateCollection: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    if (!state.isMounted || props.collections.length !== state.collections.length) {
      return {
        collections: sortBy(props.collections, 'position'),
        isMounted:   true
      };
    }
    return state;
  }

  state = {
    collections: [],
    isMounted:   false
  }

  _reorder = (startIndex, endIndex) => {
    const { collections } = this.state; 
    const [removed] = collections.splice(startIndex, 1);

    return collections.splice(endIndex, 0, removed);
  }

  dragUpdate = (param) => {
    const { collections } = this.state;
    const collectionId = parseInt(param.draggableId.split('-')[1]);

    this._reorder(param.source.index, param.destination.index);

    const prevCollection = find(collections, ['id', collectionId]);
    const position = param.destination.index + 1;

    const collection = { ...prevCollection, position: position };

    param.source.index !== param.destination.index && this.props.updateCollection({ collection });
  };

  render() {
    const { collections } = this.state;

    return (
      <div className='table-grid'>
        <DragDropContext onDragEnd={ (param) => (this.dragUpdate(param)) }>
          <div className='table-grid__head'>
            <div className='block-item font-weight-bold'>
              Name
            </div>
            <div className='text-center block-item font-weight-bold'>
              # Videos
            </div>
            <div className='block-item' />
          </div>
          <Droppable droppableId="droppable-1">
            {(provided) => (
              <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}>
                { collections.map((collection, index) => (
                  <DraggableItem
                      collection={collection}
                      index={index}
                      key={collection.id} />
                )) }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    );
  }
}

export default Collections;
