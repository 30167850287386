import React from 'react';
import PropTypes from 'prop-types';

class StepsProgress extends React.PureComponent {
  static propTypes = {
    currentStep: PropTypes.number,
    totalSteps:  PropTypes.number
  }

  static defaultProps = {
    currentStep: 1,
    totalSteps:  1
  }

  render() {
    const progress = 100 / this.props.totalSteps * this.props.currentStep;

    return (
      <div
          className='progress progress-steps'>
        <div
            className='progress-bar'
            role='progressbar'
            style={{ width: progress + '%'}} />
      </div>
    );
  }
}

export default StepsProgress;
