/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';

import { OnDemandCollectionDelete } from '../alerts';
import { Button } from '../forms';
import { ChallengesSelect, ClassPacksSelect, Input, MembershipsSelect } from '../inputs';
import { ProBadge } from '../badges';
import { Validator } from '../../lib';

class EditCollection extends PureComponent {
  static propTypes = {
    collection:   PropTypes.object.isRequired,
    isOpen:       PropTypes.bool.isRequired,
    onDelete:     PropTypes.func,
    onSubmit:     PropTypes.func,
    onToggle:     PropTypes.func.isRequired,
    subscription: PropTypes.object.isRequired
  }

  static defaultProps = {
    onDelete: () => {},
    onSubmit: () => {}
  }

  static getDerivedStateFromProps(props, state) {
    const newState = {
      ...state,
      hasAdvancedPackages: props.subscription.advancedPackages
    };

    if (props.collection.id !== state.collection.id) {
      return {
        ...newState,

        collection: {
          challengeIds:  props.collection.challengeIds,
          classPackIds:  props.collection.classPackIds,
          id:            props.collection.id,
          membershipIds: props.collection.membershipIds,
          name:          props.collection.name
        }
      };
    }

    return newState;
  }

  state = {
    collection: {
      challengeIds:  [],
      classPackIds:  [],
      id:            '',
      membershipIds: [],
      name:          ''
    },
    errors:              {},
    formats:             [],
    hasAdvancedPackages: false
  }


  handleChange = (value, inputName) => (
    this.setState((prevState) => ({
      collection: {
        ...prevState.collection,
        [inputName]: value
      }
    }))
  );

  handleValidate = () => new Promise((resolve, reject) => {
    const { collection } = this.state;

    const errors = Validator.validate([
      ['name', Validator.concepts.isFilled, [collection.name]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      this.setState({errors}, () => reject(errors));
    }
  });

  handleSubmit = () => (
    this.handleValidate().then(() => {
      const { collection } = this.state;
      this.props.onSubmit({ ...collection, canPermit: true });

      return this.props.onToggle();
    })
  )

  handleDelete = () => {
    const deleteOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <OnDemandCollectionDelete
            onCancel={onClose}
            onDelete={this.props.onDelete} />
      )};
    confirmAlert(deleteOptions);
  }

  render () {
    const { isOpen, onToggle } = this.props;
    const { collection, errors, hasAdvancedPackages } = this.state;

    return (
      <Modal
          isOpen={isOpen}
          toggle={onToggle}>
        <ModalHeader toggle={onToggle}>
          Update <span className='text-primary'>Collection</span>
        </ModalHeader>
        <ModalBody>
          <Form className='form-default'>
            <FormGroup>
              <Label
                  className='required'
                  for='input-name'>
                Collection Name
              </Label>

              <Input
                  id='input-name'
                  isInvalid={!!errors.name}
                  name='name'
                  onChange={this.handleChange}
                  type='text'
                  value={collection.name} />
            </FormGroup>

            <a
                className={classNames('form-collapse-toggle dropdown-toggle', {
                                      'collapse-disabled': !hasAdvancedPackages })}
                href='#'>
              Package settings {!hasAdvancedPackages && <ProBadge />}
            </a>

            <FormGroup>
              <div className='text-small text-muted pb-3'>
                To limit a packages access to this collection, remove those memberships or
                class packs from the list below. Excluding a package will mean that clients with those packages will
                pay the drop-in rate.
              </div>

              <Row>
                <Col
                    md='6'
                    xs='12'>
                  <Label
                      className='d-block'
                      for='input-memberships'>
                    Membership Access
                  </Label>

                  <MembershipsSelect
                      for='onDemand'
                      id='memberships'
                      isDisabled={!hasAdvancedPackages}
                      isUpdate
                      name='membershipIds'
                      onChange={this.handleChange}
                      selectedIds={collection.membershipIds} />
                </Col>

                <Col
                    md='6'
                    xs='12'>
                  <Label
                      className='d-block'
                      for='input-class-packs'>
                    Class Pack Access
                  </Label>

                  <ClassPacksSelect
                      for='onDemand'
                      id='class-packs'
                      isDisabled={!hasAdvancedPackages}
                      isUpdate
                      name='classPackIds'
                      onChange={this.handleChange}
                      selectedIds={collection.classPackIds} />
                </Col>
              </Row>

              <Row className='mt-2'>
                <Col
                    md='6'
                    xs='12'>
                  <Label
                      className='d-block'
                      for='input-challenges'>
                    Challenges Access
                  </Label>

                  <ChallengesSelect
                      for='onDemand'
                      id='challenges'
                      isDisabled={!hasAdvancedPackages}
                      isUpdate
                      name='challengeIds'
                      onChange={this.handleChange}
                      selectedIds={collection.challengeIds} />
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </ModalBody>

        <ModalFooter className='d-block'>
          <Row>
            <Col
                lg={6}
                xs={12}>
              <Button
                  color='danger'
                  isBlock
                  isOutline
                  onClick={this.handleDelete}
                  size='lg'>
                Delete
              </Button>
            </Col>
            <Col
                lg={6}
                xs={12}>
              <Button
                  isBlock
                  onClick={this.handleSubmit}
                  size='lg'>
                Update
              </Button>
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    );
  }
}

export default EditCollection;
