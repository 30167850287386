import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter, includes, map, reject } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { PrivateSessionsActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions, PrivateSessionsActions } from '../actions';

function* watchCreate() {
  yield takeLatest(PrivateSessionsActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.privateSession.create, payload);

      yield put(PrivateSessionsActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Request Sent');
    } catch (error) {
      yield put(PrivateSessionsActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(PrivateSessionsActionTypes.CREATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(PrivateSessionsActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDelete() {
  yield takeLatest(PrivateSessionsActionTypes.DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.privateSession.delete, payload);

      yield put(PrivateSessionsActions.DELETE_SUCCESS());
      toastr.success('Event deleted');
    } catch (error) {
      yield put(PrivateSessionsActions.DELETE_FAILURE());
    }
  });
}

function* watchDeleteSuccess() {
  yield takeLatest(PrivateSessionsActionTypes.DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDeleteFailure() {
  yield takeLatest(PrivateSessionsActionTypes.DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetch() {
  yield takeLatest(PrivateSessionsActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.privateSession.fetch);

      yield put(PrivateSessionsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PrivateSessionsActions.FETCH_FAILURE());
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(PrivateSessionsActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(PrivateSessionsActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsCreate() {
  yield takeLatest(PrivateSessionsActionTypes.INSTRUCTORS_CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.privateSessions.create, payload);

      yield put(PrivateSessionsActions.INSTRUCTORS_CREATE_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PrivateSessionsActions.INSTRUCTORS_CREATE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsCreateSuccess() {
  yield takeLatest(PrivateSessionsActionTypes.INSTRUCTORS_CREATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsCreateFailure() {
  yield takeLatest(PrivateSessionsActionTypes.INSTRUCTORS_CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsDelete() {
  yield takeLatest(PrivateSessionsActionTypes.INSTRUCTORS_DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.instructor.privateSessions.delete, payload);

      const privateSessions = yield select((state) => state.privateSessions.instructorData);
      const data = filter(privateSessions, (privateSession) => privateSession.id != payload.privateSession.id);

      yield put(PrivateSessionsActions.INSTRUCTORS_DELETE_SUCCESS(data));
    } catch (error) {
      yield put(PrivateSessionsActions.INSTRUCTORS_DELETE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsDeleteSuccess() {
  yield takeLatest(PrivateSessionsActionTypes.INSTRUCTORS_DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsDeleteFailure() {
  yield takeLatest(PrivateSessionsActionTypes.INSTRUCTORS_DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsFetch() {
  yield takeLatest(PrivateSessionsActionTypes.INSTRUCTORS_FETCH, function* () {
    try {
      const profile = yield select((state) => state.profile.data);
      const response = yield call(api.instructor.privateSessions.fetch, { username: profile.username });

      yield put(PrivateSessionsActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PrivateSessionsActions.INSTRUCTORS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsUpdate() {
  yield takeLatest(PrivateSessionsActionTypes.INSTRUCTORS_UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.instructor.privateSessions.update, payload);

      const privateSessions = yield select((state) => state.privateSessions.data);
      const data = map(privateSessions, (privateSession) =>
        (privateSession.id == payload.privateSession.id ? toCamelCase(response.data) : privateSession)
      );

      yield put(PrivateSessionsActions.INSTRUCTORS_UPDATE_SUCCESS(data));
    } catch (error) {
      yield put(PrivateSessionsActions.INSTRUCTORS_UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }

  });
}

function* watchInstructorsUpdateSuccess() {
  yield takeLatest(PrivateSessionsActionTypes.INSTRUCTORS_UPDATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsUpdateFailure() {
  yield takeLatest(PrivateSessionsActionTypes.INSTRUCTORS_UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchRecurringCreate() {
  yield takeLatest(PrivateSessionsActionTypes.RECURRING_CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.privateSession.recurring.create, payload);

      yield put(PrivateSessionsActions.RECURRING_CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Event added');
    } catch (error) {
      yield put(PrivateSessionsActions.RECURRING_CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}


function* watchRecurringCreateSuccess() {
  yield takeLatest(PrivateSessionsActionTypes.RECURRING_CREATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchRecurringCreateFailure() {
  yield takeLatest(PrivateSessionsActionTypes.RECURRING_CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchRecurringDelete() {
  yield takeLatest(PrivateSessionsActionTypes.RECURRING_DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.privateSession.recurring.delete, payload);

      const privateSessions = yield select((state) => state.privateSessions.instructorData);
      const deletedIds = map(response.data, 'id');
      const data = reject(privateSessions, (privateSession) => includes(deletedIds, privateSession.id));

      yield put(PrivateSessionsActions.RECURRING_DELETE_SUCCESS(data));
      toastr.success('Event deleted');
    } catch (error) {
      yield put(PrivateSessionsActions.RECURRING_DELETE_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchRecurringDeleteSuccess() {
  yield takeLatest(PrivateSessionsActionTypes.RECURRING_DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchRecurringDeleteFailure() {
  yield takeLatest(PrivateSessionsActionTypes.RECURRING_DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* privateSessionSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),
    watchDelete(),
    watchDeleteSuccess(),
    watchDeleteFailure(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchInstructorsCreate(),
    watchInstructorsCreateSuccess(),
    watchInstructorsCreateFailure(),
    watchInstructorsDelete(),
    watchInstructorsDeleteSuccess(),
    watchInstructorsDeleteFailure(),
    watchInstructorsFetch(),
    watchInstructorsUpdate(),
    watchInstructorsUpdateSuccess(),
    watchInstructorsUpdateFailure(),
    watchRecurringCreate(),
    watchRecurringCreateSuccess(),
    watchRecurringCreateFailure(),
    watchRecurringDelete(),
    watchRecurringDeleteSuccess(),
    watchRecurringDeleteFailure()
  ]);
}
