import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Loader } from '../components/loader';

class LoaderContainer extends React.PureComponent {
  static propTypes = {
    loader: PropTypes.shape({
      isLoading: PropTypes.bool.isRequired
    }).isRequired
  }

  render() {
    return (
      <Loader
          isFullscreen
          isLoading={this.props.loader.isLoading} />
    );
  }
}

const mapStateToProps = (state) => ({
  loader: state.loader
});

export default connect(mapStateToProps)(LoaderContainer);
