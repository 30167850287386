import React from 'react';
import { connect } from 'react-redux';

import { PasswordActions } from '../actions';
import { ResetPasswordPage } from '../components/pages';

class ResetPasswordContainer extends React.PureComponent {
  state = {
    token: ''
  }

  componentWillMount() {
    const urlParams = new URLSearchParams(window.location.search);

    this.setState({ token: urlParams.get('token') });
  }

  render() {
    return (
      <ResetPasswordPage
          {...this.props}
          token={this.state.token} />
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (data) => dispatch(PasswordActions.RESET(data))
});

export default connect(null, mapDispatchToProps)(ResetPasswordContainer);
