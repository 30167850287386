import React from 'react';
import PropTypes from 'prop-types';
import { BrowserView } from 'react-device-detect';

import { DateSelect, SelectView } from './';

class Controls extends React.PureComponent {
  static propTypes = {
    hasViewSelect: PropTypes.bool.isRequired,
    isMobile:      PropTypes.bool,
    onDateChange:  PropTypes.func.isRequired,
    onViewChange:  PropTypes.func.isRequired,
    selectedDate:  PropTypes.object.isRequired,
    selectedView:  PropTypes.string.isRequired,
    today:         PropTypes.object.isRequired
  };

  static defaultProps = {
    isMobile: false
  };


  render () {
    const { selectedView, selectedDate, onDateChange, onViewChange, today, isMobile, hasViewSelect } = this.props;

    return (
      <div className='calendar-controls'>
        <DateSelect
            hasDaySelect={isMobile}
            onChange={onDateChange}
            selectedDate={selectedDate}
            selectedView={selectedView}
            today={today} />
        { hasViewSelect &&
          <BrowserView renderWithFragment>
            <SelectView
                onSelect={onViewChange}
                selectedView={selectedView} />
          </BrowserView>
        }
      </div>
    );
  }
}

export default Controls;