import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Link } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { Button } from '../forms';
import { OAuthWindow } from '../oauth';
import { routes } from '../../lib';
import { PrivateGroupClassModal } from '../../containers/events';
import { RequestedClass } from '../onDemands';

import {
  ClassPackModalContainer,
  MembershipModalContainer  
} from '../../containers/portal';


class Introduction extends React.PureComponent {
  static propTypes = {
    classPacks:          PropTypes.array.isRequired,
    fetchUser:           PropTypes.func.isRequired,
    folders:             PropTypes.array.isRequired,
    formats:             PropTypes.array.isRequired,
    instructor:          PropTypes.object.isRequired,
    memberships:         PropTypes.array.isRequired,
    oAuth:               PropTypes.object.isRequired,
    privateGroupClasses: PropTypes.array.isRequired,
    profile:             PropTypes.object.isRequired,
    requestedClasses:    PropTypes.array.isRequired,
    segmentSiteShare:    PropTypes.func.isRequired,
    segmentStripe:       PropTypes.func.isRequired,
    segmentZoom:         PropTypes.func.isRequired,
    updateProfile:       PropTypes.func.isRequired,
    user:                PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const { classPacks, oAuth, requestedClasses, privateGroupClasses, memberships, profile } = props;

    if (!state.isMounted && requestedClasses.length > 0) {
      const useOnDemand = requestedClasses.length > 0 ? 'yes' : '';
   
      return {
        hasOnDemand: requestedClasses.length > 0,
        isMounted:   true,
        useOnDemand: useOnDemand

      };
    }
    
    return {
      hasClassPack:     classPacks.length > 0,
      hasFirstClass:    privateGroupClasses.length > 0,
      hasMembership:    memberships.length > 0,
      packagesEnable:   profile.membershipsEnabled,
      stripeConnection: !isEmpty(oAuth.stripe),
      textToCopy:       `onpodio.com/${profile.username}`,
      zoomConnection:   oAuth.zoom
    };
  }

  state = {
    copied:                    false,
    hasFirstClass:             false,
    hasOnDemand:               false,
    isClassPacksModalOpen:     false,
    isMembershipModalOpen:     false,
    isModalOpen:               false,
    isMounted:                 false,
    isPrivateSessionModalOpen: false,
    isRequestedClassModalOpen: false,
    packagesEnable:            false,
    useOnDemand:               '',
    usePackages:               '',
    useZoom:                   ''
  }

  handleSwitch = (field, value) => () => this.setState({ [field]: value })

  handleOpenModal = (field) => () => this.setState((prevState) => (
     {[field]: !prevState[field]}
   )
  );


  handleCopy = () => {
    toastr.success('Copied');
    this.props.segmentSiteShare();
    this.setState({ copied: true });
  }

  handleZoomSuccess = () => (
    this.props.fetchUser(),
    this.props.segmentZoom(this.props.user)
  )

  handleStripeSuccess = () => (
    this.props.fetchUser(),
    this.props.segmentStripe(this.props.user)
  );

  renderIcon = (value) => {
    const iconName = value ? ['far', 'check-circle'] : ['far', 'circle'];
      return (
        <Icon
            className={classNames('icon' , { 'text-success': value })}
            icon={iconName}
            size='1x' />
      );
  }

  handleUpdateProfile = (field) => () => {
    const { profile } = this.props;

    if (field == 'requestedClassesEnabled') {
      this.handleSwitch('useOnDemand', 'yes')();
    }

    if (field == 'membershipsEnabled') {
      this.handleSwitch('usePackages', 'yes')();
    }

    this.props.updateProfile({ ...profile, [field]: true });
  }


  render() {
    const {
      copied,
      hasFirstClass,
      hasClassPack,
      hasMembership,
      hasOnDemand,
      zoomConnection,
      stripeConnection,
      useZoom,
      useOnDemand,
      packagesEnable,
      usePackages } = this.state;

    const { folders, formats, instructor, profile, user } = this.props;
    const { currency } = user;

    return (
      <div className='introduction-step'>
        <div className='greeting-container'>
          <h4 className='greeting'>Great Work!</h4>

          <span className='greeting-text'>
            You are off to a great start in building your virtual fitness business.
            Let’s keep that momentum going!
          </span>
        </div>

        <div className='mt-2'>
          <div className='checkpoint'>
            {this.renderIcon(true)}

            <div className='info flex-column'>
              <span className='checkpoint-title text-muted'>You created your account</span>
              <span className='description text-muted'>Your account and instructor portal has been setup</span>
            </div>
          </div>

          <div className='checkpoint'>
            {this.renderIcon(true)}

            <div className='info flex-column'>
              <span className='checkpoint-title text-muted'>You created your website</span>
              <span className='description text-muted'>
                Preview <Link to={routes.INSTRUCTOR(profile.username)}>your website here</Link>
                &nbsp;and continue customizing it via&nbsp;
                <Link to={routes.SETTINGS}>Settings</Link>
              </span>
            </div>
          </div>

          <div className='checkpoint'>
            {this.renderIcon(stripeConnection)}

            <div className='info'>
              <div className='d-flex flex-column w-50'>
                <span className={classNames('checkpoint-title', {'text-muted': stripeConnection })}>
                  <span
                      aria-label='money'
                      className='mr-1'
                      role='img' >
                    💰
                  </span>
                  Connect Stripe to get paid
                </span>
                <span className={classNames('description', {'text-muted': stripeConnection })}>
                  Start accepting payments for your classes, memberships and class packs
                </span>
              </div>

              { !stripeConnection &&
                <OAuthWindow
                    className='oauth-window'
                    onSuccess={this.handleStripeSuccess}
                    url={routes.STRIPE_AUTH}>
                  <Button
                      className='button w-100'
                      size='sm'>Connect
                  </Button>
                </OAuthWindow>
              }
            </div>
          </div>

          { !zoomConnection && isEmpty(useZoom) &&
            <div className='checkpoint'>
              {this.renderIcon(false)}

              <div className='info'>
                <span className='checkpoint-title mb-4'>
                  <span
                      aria-label='camera'
                      className='mr-1'
                      role='img' >
                    🎥
                  </span>
                  Do you use Zoom for live streaming?
                </span>
                <div className='d-flex flex-column'>
                  <Button
                      className='yes-button mb-1'
                      onClick={this.handleSwitch('useZoom', 'yes')}
                      size='sm'>
                    Yes
                  </Button>
                  <Button
                      className='no-button'
                      onClick={this.handleSwitch('useZoom', 'no')}
                      size='sm'>
                    No
                  </Button>
                </div>
              </div>
            </div>
          }

          { useZoom == 'yes' &&
            <div className='checkpoint'>
              {this.renderIcon(zoomConnection)}

              <div className='info'>
                <div className='d-flex flex-column w-60'>
                  <span className={classNames('checkpoint-title', {'text-muted': zoomConnection })}>
                    <span
                        aria-label='camera'
                        className='mr-1'
                        role='img' >
                      🎥
                    </span>
                    Connect your Zoom account
                  </span>
                  <span className={classNames('description', {'text-muted': zoomConnection })}>
                    Create a Zoom class in just 1-click
                  </span>
                </div>

                { !zoomConnection &&
                  <OAuthWindow
                      className='oauth-window'
                      onSuccess={this.handleZoomSuccess}
                      url={routes.ZOOM_AUTH}>
                    <Button
                        className='button w-100'
                        size='sm'>Connect
                    </Button>
                  </OAuthWindow>
                }
              </div>
            </div>
          }


          <div className='checkpoint'>
            {this.renderIcon(hasFirstClass)}

            <div className='info'>
              <div className='d-flex flex-column'>
                <span className={classNames('checkpoint-title', {'text-muted': hasFirstClass })}>
                  <span
                      aria-label='coupons'
                      className='mr-1'
                      role='img' >
                    🎟
                  </span>
                  Add your 1st class
                </span>
                <span className={classNames('description', {'text-muted': hasFirstClass })}>
                  You will never look back after your first booking
                </span>
              </div>
              { !hasFirstClass &&
                <Button
                    className='button'
                    onClick={this.handleOpenModal('isPrivateGroupClassModalOpen')}
                    size='sm'>
                  Add Class
                </Button>
              }
            </div>
          </div>

          { isEmpty(useOnDemand) &&
            <div className='checkpoint'>
              {this.renderIcon(false)}

              <div className='info'>
                <span className='checkpoint-title mb-4'>
                  <span
                      aria-label='tv'
                      className='mr-1'
                      role='img' >
                    📺
                  </span>
                  Do you want to offer on demand videos?
                </span>
                <div className='d-flex flex-column'>
                  <Button
                      className='yes-button mb-1'
                      onClick={this.handleUpdateProfile('requestedClassesEnabled')}
                      size='sm'>
                    Yes
                  </Button>
                  <Button
                      className='no-button'
                      onClick={this.handleSwitch('useOnDemand', 'no')}
                      size='sm'>
                    No
                  </Button>
                </div>
              </div>
            </div>
          }

          { useOnDemand == 'yes' &&
            <div className='checkpoint'>
              {this.renderIcon(hasOnDemand)}

              <div className='info'>
                <div className='d-flex flex-column'>
                  <span className={classNames('checkpoint-title', {'text-muted': hasOnDemand })}>
                    <span
                        aria-label='tv'
                        className='mr-1'
                        role='img' >
                      📺
                    </span>
                    Upload your 1st on demand video
                  </span>
                  <span className={classNames('description', {'text-muted': hasOnDemand })}>
                    Build your content library today
                  </span>
                </div>
                { !hasOnDemand &&
                  <Button
                      className='button'
                      onClick={this.handleOpenModal('isRequestedClassModalOpen')}
                      size='sm'>
                    Upload
                  </Button>
                }
              </div>
            </div>
          }

          { !packagesEnable && isEmpty(usePackages) &&
            <div className='checkpoint'>
              {this.renderIcon(false)}

              <div className='info'>
                <span className='checkpoint-title mb-4'>
                  <span
                      aria-label='package'
                      className='mr-1'
                      role='img' >
                    📦
                  </span>
                  Do you want to offer memberships or class packs?
                </span>
                <div className='d-flex flex-column'>
                  <Button
                      className='yes-button mb-1'
                      onClick={this.handleUpdateProfile('membershipsEnabled')}
                      size='sm'>
                    Yes
                  </Button>
                  <Button
                      className='no-button'
                      onClick={this.handleSwitch('usePackages', 'no')}
                      size='sm'>
                    No
                  </Button>
                </div>
              </div>
            </div>
          }

          { (usePackages == 'yes' || packagesEnable)  &&
            <div className='checkpoint'>
              {this.renderIcon(hasMembership && hasClassPack)}

              <div className='info'>
                <div className='d-flex flex-column'>
                  <span className={classNames('checkpoint-title', {'text-muted': hasMembership && hasClassPack })}>
                    <span
                        aria-label='package'
                        className='mr-1'
                        role='img' >
                      📦
                    </span>
                    Create your package options
                  </span>
                  <span className={classNames('description', {'text-muted': hasMembership && hasClassPack })}>
                    Reward loyalty with package discounts
                  </span>
                </div>
                <div className='buttons-section'>
                  { !hasMembership &&
                    <Button
                        className='button w-100'
                        onClick={this.handleOpenModal('isMembershipModalOpen')}
                        size='sm'>
                      Membership
                    </Button>
                  }

                  { !hasClassPack &&
                    <Button
                        className='button w-100 mt-1'
                        onClick={this.handleOpenModal('isClassPacksModalOpen')}
                        size='sm'>
                      Class Pack
                    </Button>
                  }
                </div>
              </div>
            </div>
          }


          <div className='checkpoint'>
            {this.renderIcon(copied)}

            <div className='info'>
              <div className='d-flex flex-column'>
                <span className={classNames('checkpoint-title', {'text-muted': copied })}>
                  <span
                      aria-label='stars'
                      className='mr-1'
                      role='img' >
                    ✨
                  </span>
                  Share your website
                </span>
                <span className={classNames('description', {'text-muted': copied })}>
                  Start getting bookings by sharing your website &nbsp;
                  <Link to={routes.INSTRUCTOR(profile.username)}>onpodio.com/{profile.username}</Link>
                </span>
              </div>
              {!copied &&
                <CopyToClipboard
                    onCopy={this.handleCopy}
                    text={this.state.textToCopy}>
                  <Button
                      className='button'
                      onClick={this.handleCopy}
                      size='sm'>
                    Copy link
                  </Button>
                </CopyToClipboard>
              }
            </div>
          </div>
        </div>

        <Button
            className='w-100 mt-2'
            color='muted'
            isOutline
            onClick={this.handleUpdateProfile('skipIntroduction')}
            size='sm'>
          Close this introduction
        </Button>

        { this.state.isPrivateGroupClassModalOpen &&
          <PrivateGroupClassModal
              isOpen={this.state.isPrivateGroupClassModalOpen}
              onToggle={this.handleOpenModal('isPrivateGroupClassModalOpen')} />
        }

        { this.state.isRequestedClassModalOpen &&
          <RequestedClass
              currency={currency}
              folders={folders}
              formats={formats}
              instructor={instructor}
              isOpen={this.state.isRequestedClassModalOpen}
              onToggle={this.handleOpenModal('isRequestedClassModalOpen')}
              profile={profile}
              user={user} />
        }

        { this.state.isMembershipModalOpen &&
          <MembershipModalContainer
              isNewRecord
              isOpen={this.state.isMembershipModalOpen}
              onToggle={this.handleOpenModal('isMembershipModalOpen')}
              profile={this.props.profile} />
        }

        { this.state.isClassPacksModalOpen &&
          <ClassPackModalContainer
              isNewRecord
              isOpen={this.state.isClassPacksModalOpen}
              onToggle={this.handleOpenModal('isClassPacksModalOpen')}
              profile={this.props.profile} />
        }
      </div>
    );
  }
}

export default Introduction;
