import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Button } from '../forms';
import { ClassInfo, FontTypes } from './';

class Controls extends React.PureComponent {
  static propTypes = {
    color:       PropTypes.string.isRequired,
    font:        PropTypes.string.isRequired,
    hasLocation: PropTypes.bool.isRequired,
    hasName:     PropTypes.bool.isRequired,
    isBold:      PropTypes.bool.isRequired,
    isItalic:    PropTypes.bool.isRequired,
    onChange:    PropTypes.func,
    onDownload:  PropTypes.func,
    schedule:    PropTypes.object.isRequired,
    size:        PropTypes.number.isRequired
  }

  static defaultProps = {
    onChange:   () => {},
    onDownload: () => {}
  }

  state = {
    activeIndex: -1
  }

  handleClick = (index) => () => {
    if (this.state.activeIndex === index) {
      this.setState({ activeIndex: -1 });
    } else {
      this.setState({ activeIndex: index });
    }
  }

  handleDownload = () => {
    this.props.onDownload();
  }

  renderForm = () => {
    switch (this.state.activeIndex) {
      case -1:
        return null;

      case 0:
        return (
          <ClassInfo
              hasLocation={this.props.hasLocation}
              hasName={this.props.hasName}
              onChange={this.props.onChange}
              schedule={this.props.schedule} />
        );

      case 1:
        return (
          <FontTypes
              color={this.props.color}
              font={this.props.font}
              isBold={this.props.isBold}
              isItalic={this.props.isItalic}
              onChange={this.props.onChange}
              size={this.props.size} />
        );

      default:
        return null;
    }
  }

  render() {
    const { activeIndex } = this.state;

    return (
      <React.Fragment>
        {this.renderForm()}

        <div className='story-controls'>
          <Button
              color='story-maker'
              isActive={activeIndex === 0}
              onClick={this.handleClick(0)}
              onKeyPress={this.handleClick(0)}>
            Class Info
          </Button>
          <Button
              color='story-maker'
              isActive={activeIndex === 1}
              onClick={this.handleClick(1)}
              onKeyPress={this.handleClick(1)}>
            Font Type
          </Button>
          <Button
              className='btn-download'
              color='story-maker'
              onClick={this.handleDownload}
              onKeyPress={this.handleDownload}>
            <Icon
                fixedWidth
                icon={['fa', 'download']}
                size='lg' />
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default Controls;
