export default {
  ACTIVATE:         '@@subscriptions/ACTIVATE',
  ACTIVATE_FAILURE: '@@subscriptions/ACTIVATE_FAILURE',
  ACTIVATE_SUCCESS: '@@subscriptions/ACTIVATE_SUCCESS',

  CLEAR: '@@subscriptions/CLEAR',

  CREATE:         '@@subscriptions/CREATE',
  CREATE_FAILURE: '@@subscriptions/CREATE_FAILURE',
  CREATE_SUCCESS: '@@subscriptions/CREATE_SUCCESS',

  DELETE:         '@@subscriptions/DELETE',
  DELETE_FAILURE: '@@subscriptions/DELETE_FAILURE',
  DELETE_SUCCESS: '@@subscriptions/DELETE_SUCCESS',

  FETCH:         '@@subscriptions/FETCH',
  FETCH_FAILURE: '@@subscriptions/FETCH_FAILURE',
  FETCH_SUCCESS: '@@subscriptions/FETCH_SUCCESS',

  INSTRUCTOR_DELETE:         '@@subscriptions/INSTRUCTOR_DELETE',
  INSTRUCTOR_DELETE_FAILURE: '@@subscriptions/INSTRUCTOR_DELETE_FAILURE',
  INSTRUCTOR_DELETE_SUCCESS: '@@subscriptions/INSTRUCTOR_DELETE_SUCCESS'
};
