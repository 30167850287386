import React from 'react';
import PropTypes from 'prop-types';
import TextTruncate from 'react-text-truncate';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Button } from '../forms';
import { AddReviewModal, BookingScheduleModal } from '../modals';
import { routes } from '../../lib';

class StudioCard extends React.PureComponent {
  static propTypes = {
    createBooking:  PropTypes.func.isRequired,
    createReview:   PropTypes.func.isRequired,
    followStudio:   PropTypes.func.isRequired,
    studio:         PropTypes.object.isRequired,
    unfollowStudio: PropTypes.func.isRequired
  }

  state = {
    isBookingModalOpen: false,
    isReviewModalOpen:  false
  }

  handleFollow = () => {
    this.props.followStudio(this.props.studio);
  }

  handleUnfollow = () => {
    this.props.unfollowStudio(this.props.studio);
  }

  handleBookingModal = () => {
    this.setState((prevState) => ({
      isBookingModalOpen: !prevState.isBookingModalOpen
    }));
  }

  handleReviewModal = () => {
    this.setState((prevState) => ({
      isReviewModalOpen: !prevState.isReviewModalOpen
    }));
  }

  render() {
    const { studio } = this.props;

    return (
      <Card
          body
          className='card-result following mb-2 p-2'>
        <Link to={routes.STUDIO(studio.id)}>
          <div className='card-photo rounded placeholder' />
        </Link>

        <div className='card-content'>
          <div className='card-info'>
            <Link
                className='card-title'
                to={routes.STUDIO(studio.id)}>
              {studio.name}
            </Link>

            <span className='card-bio text-muted'>
              <TextTruncate
                  element='span'
                  lines={4}
                  text={studio.bio}
                  truncateText='...' />
            </span>
          </div>
        </div>

        <div className='card-actions mr-2'>
          <Button
              className='m-1 w-100'
              onClick={this.handleReviewModal}
              size='sm'>
            Leave a Review
          </Button>

          <Button
              className='m-1 w-100'
              onClick={this.handleBookingModal}
              size='sm'>
            Book a Class
          </Button>

          { studio.isUnfollowed &&
            <Button
                className='m-1 w-100'
                onClick={this.handleFollow}
                size='sm'>
              Follow
            </Button>
          }

          { !studio.isUnfollowed &&
            <Button
                className='m-1 w-100'
                onClick={this.handleUnfollow}
                size='sm'>
              Unfollow
            </Button>
          }
        </div>

        <div className='modals'>
          <AddReviewModal
              createReview={this.props.createReview}
              isOpen={this.state.isReviewModalOpen}
              onToggle={this.handleReviewModal}
              studio={studio} />

          <BookingScheduleModal
              createBooking={this.props.createBooking}
              groupClasses={studio.groupClasses}
              isOpen={this.state.isBookingModalOpen}
              onToggle={this.handleBookingModal}>
            {studio.name}&apos; Schedule
          </BookingScheduleModal>
        </div>
      </Card>
    );
  }
}

export default StudioCard;
