/* eslint-disable immutable/no-mutation, immutable/no-let, no-loops/no-loops */
import { isEmpty } from 'lodash';

const getDiff = (target, base) => {
  let result = {};
  for (let key in target) {
    if (target[key] !== base[key]) {
      result[key] = target[key];
    }
  }

  return isEmpty(result) ? null : result;
};

export default getDiff;
