import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

class FloatLink extends React.PureComponent {
  static propTypes = {
    to: PropTypes.string.isRequired
  };

  render () {
    return (
      <Link
          className='btn-float'
          to={this.props.to}>
        <Icon
            fixedWidth
            icon='plus' />
      </Link>
    );
  }
}

export default FloatLink;