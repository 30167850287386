import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError, toCamelCase } from '../lib';
import { ChargesActionTypes } from '../constants/actionTypes';
import { ChargesActions, ErrorActions, LoaderActions } from '../actions';

function* watchFetch() {
  yield takeLatest(ChargesActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.charge.fetch);

      yield put(ChargesActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(ChargesActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(ChargesActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(ChargesActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* chargesSaga() {
  yield all([
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure()
  ]);
}
