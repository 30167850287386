import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../helpers';
import { PrivateSessionsPage } from '../components/pages';
import { LocationsActions, PrivateSessionTypesActions, ProfileActions } from '../actions';

class PrivateSessionsContainer extends React.PureComponent {
  static propTypes = {
    fetchLocations:    PropTypes.func.isRequired,
    fetchSessionTypes: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.props.fetchLocations();
    this.props.fetchSessionTypes();
  }

  render() {
    return (
      <PrivateSessionsPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  instructor:          state.profile.data,
  locations:           state.locations.data,
  privateSessionTypes: state.privateSessionTypes.data
});

const mapDispatchToProps = (dispatch) => ({
  fetchLocations:    ()     => dispatch(LocationsActions.FETCH()),
  fetchSessionTypes: ()     => dispatch(PrivateSessionTypesActions.FETCH()),
  updateProfile:     (data) => dispatch(ProfileActions.UPDATE(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(PrivateSessionsContainer);
