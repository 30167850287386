import { ProfileActionTypes, SessionActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       {},
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case ProfileActionTypes.CLEAR:
      return { ...newState, data: initialState.data };


    case ProfileActionTypes.CREATE:
      return newState;

    case ProfileActionTypes.CREATE_SUCCESS:
      return { ...newState, data: action.payload };

    case ProfileActionTypes.CREATE_FAILURE:
      return { ...newState, data: initialState.data };

    case ProfileActionTypes.CREATE_FINISH:
      return newState;


    case ProfileActionTypes.FETCH:
      return newState;

    case ProfileActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case ProfileActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case ProfileActionTypes.UPDATE:
      return newState;

    case ProfileActionTypes.UPDATE_AVAILABILITY:
      return newState;

    case ProfileActionTypes.UPDATE_SUCCESS:
      return { ...newState, data: action.payload };

    case ProfileActionTypes.UPDATE_FAILURE:
      return newState;


    case SessionActionTypes.LOGOUT_SUCCESS:
      return { ...newState, data: initialState.data };

    default: return state;
  }
};
