import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class ClassPacksSummary extends PureComponent {
  static propTypes = {
    summary: PropTypes.object.isRequired,
    user:    PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    return {
      currency: props.user.currency,
      summary:  props.summary
    };
  }

  state = {
    currency: 'USD',
    summary:  {}
  }

  render () {
    const { summary, currency } = this.state;

    const amountFormatter = (amount) => {
      const options = {
        currency:    currency,
        style:       'currency',
        useGrouping: true
      };

      const formattedValue = new Intl.NumberFormat('en-US', options).formatToParts(amount / 100);

      return formattedValue.map((numPart, i) => {
        if (i === 0) {
          return numPart.value + ' ';
        }
        return numPart.value;
      }).join('');
    };

    return (
      <div className='summary class-packs'>
        <div className='number'>
          <span className='label'>$ Sales</span>

          <div className='count-block green'>
            {amountFormatter(summary.allTime.amount)}
          </div>
        </div>
        <div className='number'>
          <span className='label'># Class Packs</span>

          <div className='count-block orange'>
            {summary.allTime.count}
          </div>
        </div>
        <div className='number'>
          <span className='label'># Unused Credits</span>

          <div className='count-block orange'>
            {summary.unusedCredits}
          </div>
        </div>
      </div>
    );
  }
}

export default ClassPacksSummary;