import { UserActionTypes } from '../constants/actionTypes';

export default {
  FETCH: () => ({
    type: UserActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: UserActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: UserActionTypes.FETCH_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: UserActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: UserActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: UserActionTypes.UPDATE_SUCCESS
  })
};
