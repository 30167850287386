import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../forms';

class UserCancelPrivateBooking extends React.PureComponent {
  static propTypes = {
    booking:              PropTypes.object.isRequired,
    deletePrivateBooking: PropTypes.func.isRequired,
    onClose:              PropTypes.func.isRequired,
    onSuccess:            PropTypes.func.isRequired
  }

  handleCancel = () => {
    const { booking } = this.props;

    this.props.deletePrivateBooking({ booking });
    this.props.onClose();
    this.props.onSuccess();
  }

  render() {
    const { instructor } = this.props.booking.event;
    const isRefundable = instructor.strategy.allowRefund;

    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            Are you sure you want to cancel your reservation with {instructor.name}?&nbsp;
            You {isRefundable ? 'will' : 'will not'} receive a refund.
          </CardText>

          <div className='actions'>
            <Button
                color='muted'
                onClick={this.props.onClose}>
              No
            </Button>

            <Button
                color='primary'
                onClick={this.handleCancel}>
              Yes
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default UserCancelPrivateBooking;
