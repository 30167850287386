/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { map } from 'lodash';

import { ClientsTable } from '../tables';
import { Button } from '../../../components/forms';
import { DateHelper, exportToXLS } from '../../../lib';
import { Heading, Panel } from '../../../components/layout/pages';
import { ClientModalContainer } from '../../../containers/portal';
import MainButton from '../../../components/pages/portal/menu/mainButton';

class Clients extends React.PureComponent {
  static propTypes = {
    clients:          PropTypes.array.isRequired,
    fetchClients:     PropTypes.func.isRequired,
    fetchMemberships: PropTypes.func.isRequired,
    profile:          PropTypes.object.isRequired,
    user:             PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props) {
    return { clientsEmails: map(props.clients, 'email') };
  }

  componentDidMount () {
    this.props.fetchClients();
    this.props.fetchMemberships();
  }

  state = {
    clientsEmails: [],
    isModalOpen:   false,
    isNewRecord:   true
  };

  handleNew = () => {
    this.setState({ isNewRecord: true }, this.handleToggle);
  };

  handleExport = () => {
    const { timeZone } = this.props.user;

    const clientData = this.props.clients.map((client) => ({
      name:       client.name,
      email:      client.email,
      birthday:   client.birthday,
      phone:      client.phone,
      lastBooing: client.lastBookingTime && DateHelper.getDate(client.lastBookingTime, timeZone)
                                                      .format('MMMM DD, YYYY')

     }));

    return exportToXLS(clientData, 'clients');
  }

   handleToggle = () => (
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }))
  );

  render() {
    const { clients, profile } = this.props;

    return (
      <div className='inner-wrapper clients'>
        <Helmet>
          <title>My Clients</title>
        </Helmet>

        <Container fluid>
          <Heading
              color='secondary'
              subtitle='Creator Portal'
              title='My Clients' />

          <Panel>
            <div>
              <a
                  href={'mailto:?bcc=' + this.state.clientsEmails}
                  rel='noopener noreferrer'>
                Email All
              </a>

              <Button
                  className='exportButton'
                  onClick={this.handleExport}>
                Export All
              </Button>
            </div>

            <ClientsTable
                clients={clients}
                isLoading={false}
                profile={profile} />
          </Panel>

          <MainButton onClick={this.handleNew} />
        </Container>

        <ClientModalContainer
            isNewRecord={this.state.isNewRecord}
            isOpen={this.state.isModalOpen}
            onToggle={this.handleToggle} />
      </div>
    );
  }
}

export default Clients;
