import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { eyeDisabled } from 'react-icons-kit/ionicons/eyeDisabled';

import { Button } from '../../../components/forms';

class Memberhip extends React.PureComponent {
  static propTypes = {
    membership: PropTypes.object.isRequired,
    onEdit:     PropTypes.func.isRequired,
    user:       PropTypes.object.isRequired
  }

  render() {
    const { membership, user } = this.props;
    const { currency } = user;

    return (
      <div className='row'>
        <span className='column column__name'>
          Name:
          <span className='value'>
            {membership.name}
          </span>
        </span>

        <span className='column column__cost'>
          Price:
          <span className='value'>
            {membership.cost.toLocaleString('en-GB', { currency, style: 'currency' })} per {membership.period}
          </span>
        </span>

        <span className='column column__freeTrial'>
          Free Trial:
          <span className='value'>
            {membership.trial ? `${membership.trialDays} days` : 'None'}
          </span>
        </span>

        <span className='column column__active'>
          # Active:
          <span className='value'>
            {membership.activeSubscriptions} members
          </span>
        </span>

        <div className='column button-wrapper column__button'>
          { !membership.visible &&
            <Icon
                className='text-muted mr-2'
                icon={eyeDisabled}
                size={24} />
            }
          <Button
              className='button'
              color='primary'
              isOutline
              onClick={this.props.onEdit(membership.id)}
              size='sm'>
            Edit Membership
          </Button>
        </div>
      </div>
    );
  }
}

export default Memberhip;
