import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { DateHelper, Moment } from '../../../lib';
import { Button } from '../../../components/forms';

class Subscriptions extends React.PureComponent {
  static propTypes = {
    onCancelSubscription: PropTypes.func.isRequired,
    subscription:         PropTypes.object,
    user:                 PropTypes.object.isRequired
  }

  static defaultProps = {
    subscription: {}
  }
  renderSubscription = () => {
    const { subscription, user } = this.props;

    const isActiveSubscription = subscription
                               && subscription.status == 'active'
                               && new Moment() < new Moment.utc(subscription.endsAt, 'YYYY-MM-DDhh:mm:ssUTC');
    return (
      <React.Fragment>
        <div className='row'>
          <span className='column'>
            Membership:
            <span className={classNames(isActiveSubscription ? 'subscription-badge' : 'value')}>
              <span className='subscription-name'>
                {isActiveSubscription ? subscription.membership.name : 'n/a'}
              </span>
            </span>
          </span>

          <span className='column'>
            Member Since:
            <span className='value'>
              { isActiveSubscription
                ? DateHelper.getDate(subscription.createdAt, user.timeZone).format('MMMM DD, YYYY')
                : 'n/a'
              }
            </span>
          </span>

          <span className='column'>
            Next Renewal:
            <span className='value'>
              { isActiveSubscription
                ? DateHelper.getDate(subscription.endsAt, user.timeZone).format('MMMM DD, YYYY')
                : 'n/a'
              }
            </span>
          </span>

          <div className='column button-wrapper'>
            {isActiveSubscription &&
              <Button
                  className='button'
                  color={isActiveSubscription ? 'primary' : 'muted'}
                  isDisabled={isEmpty(subscription)}
                  isOutline
                  onClick={this.props.onCancelSubscription}
                  size='sm'>
                <span>Cancel</span>
              </Button>
            }
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderEmpty = () => (
    <div className='empty'>
      <p>No active memberships.</p>
    </div>
  );

  render() {
    const { subscription } =  this.props;
    return (
      <React.Fragment>
        <div className='main-row'>
          <span className='title'>Active Memberships</span>
        </div>

        {!isEmpty(subscription) && this.renderSubscription()}

        {isEmpty(subscription) && this.renderEmpty()}
      </React.Fragment>
    );
  }
}

export default Subscriptions;
