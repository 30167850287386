import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import {ic_queue_music} from 'react-icons-kit/md/ic_queue_music';

class Playlist extends React.PureComponent {
  static propTypes = {
    data:    PropTypes.object.isRequired,
    onClick: PropTypes.func
  }
  
  static defaultProps = {
    onClick: () => {}
  }
  
  handleClick = () => {
    this.props.onClick();
  }

  render() {
    const { data } = this.props;

    return (
      <div
          className='spotify-playlist'
          onClick={this.handleClick}
          onKeyPress={this.handleClick}
          role='button'
          tabIndex={0}>
        <div
            className='cover'
            style={{ backgroundImage: `url(${data.image})` }}>
          <Icon
              icon={ic_queue_music}
              size={36} />
        </div>
        <div className='playlist-info'>
          <div className='name'>
            <span title={data.name}>{data.name}</span>
          </div>
          <div className='total'>
            <span>Total: {data.total}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default Playlist;
