export default {
  CREATE:         '@@coupons/CREATE',
  CREATE_FAILURE: '@@coupons/CREATE_FAILURE',
  CREATE_SUCCESS: '@@coupons/CREATE_SUCCESS',

  DELETE:         '@@coupons/DELETE',
  DELETE_FAILURE: '@@coupons/DELETE_FAILURE',
  DELETE_SUCCESS: '@@coupons/DELETE_SUCCESS',

  FETCH:         '@@coupons/FETCH',
  FETCH_FAILURE: '@@coupons/FETCH_FAILURE',
  FETCH_SUCCESS: '@@coupons/FETCH_SUCCESS',

  GET:         '@@coupons/GET',
  GET_FAILURE: '@@coupons/GET_FAILURE',
  GET_SUCCESS: '@@coupons/GET_SUCCESS',

  UPDATE:         '@@coupons/UPDATE',
  UPDATE_FAILURE: '@@coupons/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@coupons/UPDATE_SUCCESS'
};
