/* global process */
/* eslint-disable react/jsx-handler-names */
import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, NavItem } from 'reactstrap';
import { isEmpty } from 'lodash';

import { Link } from '../../../../inputs';
import { routes } from '../../../../../lib';
import { Button } from '../../../../forms';
import { UserAvatar } from '../../../../users';

const { ORIGIN_URL } = process.env;

class UserMenu extends React.PureComponent {
  static propTypes = {
    domain:          PropTypes.object.isRequired,
    getDomain:       PropTypes.func.isRequired,
    hasCustomDomain: PropTypes.bool.isRequired,
    hasProfile:      PropTypes.bool.isRequired,
    isOpen:          PropTypes.bool,
    logOut:          PropTypes.func.isRequired,
    onToggle:        PropTypes.func.isRequired,
    user:            PropTypes.object.isRequired
  }

  static defaultProps = {
    isOpen: false
  }

  componentDidMount () {
    this.props.getDomain();
  }

  handleLogOut = () => {
    this.props.logOut();
  }

  render() {
    const { hasCustomDomain, user, isOpen, onToggle, hasProfile, domain } = this.props;

    const combineRoute = (route) => {
      return hasCustomDomain ? ORIGIN_URL + route : route;
    };

    const instructorUrl = user.instructor && isEmpty(domain) ?
      combineRoute(routes.INSTRUCTOR(user.instructor.username)) :
      `https://${domain.host}`;

    return (
      <React.Fragment>
        <NavItem className='nav mt-3 mt-lg-0 mr-lg-3 d-lg-none d-xl-inline-block btn-portal'>
          <Button
              color='primary'
              isOutline
              tag={Link}
              to={combineRoute(routes.DASHBOARD)}>
            Client Portal
          </Button>
        </NavItem>

        <Dropdown
            className='ml-lg-3 text-center'
            inNavbar
            isOpen={isOpen}
            toggle={onToggle}>

          <DropdownToggle
              caret
              tag='a'>
            <UserAvatar
                className='avatar-border-white mr-2'
                size='sm'
                src={user.photo} />
          </DropdownToggle>

          <DropdownMenu right>
            { hasProfile && user.instructor &&
              <React.Fragment>
                <DropdownItem
                    tag={Link}
                    to={instructorUrl}>
                  View My Website
                </DropdownItem>
                <DropdownItem
                    tag={Link}
                    to={combineRoute(routes.INSTRUCTOR_DASHBOARD)}>
                  Creator Dashboard
                </DropdownItem>

                <DropdownItem
                    tag={Link}
                    to={combineRoute(routes.CALENDAR)}>
                  My Class Schedule
                </DropdownItem>

                <DropdownItem
                    tag={Link}
                    to={combineRoute(routes.ON_DEMAND)}>
                  On Demand
                </DropdownItem>

                <DropdownItem
                    tag={Link}
                    to={combineRoute(routes.PACKAGES)}>
                  Packages
                </DropdownItem>

                <DropdownItem
                    tag={Link}
                    to={combineRoute(routes.COUPONS)}>
                  Coupons
                </DropdownItem>

                <DropdownItem
                    tag={Link}
                    to={combineRoute(routes.TRANSACTIONS)}>
                  Transactions
                </DropdownItem>

                <DropdownItem
                    tag={Link}
                    to={combineRoute(routes.CLIENTS)}>
                  Clients
                </DropdownItem>

                <DropdownItem
                    tag={Link}
                    to={combineRoute(routes.SETTINGS)}>
                  Settings
                </DropdownItem>

                <DropdownItem divider />
              </React.Fragment>
            }

            <DropdownItem
                tag={Link}
                to={combineRoute(routes.DASHBOARD)}>
              Client Dashboard
            </DropdownItem>

            <DropdownItem divider />

            <DropdownItem
                tag={Link}
                to={combineRoute(routes.EDIT_ACCOUNT)}>
              User Settings
            </DropdownItem>

            <DropdownItem onClick={this.handleLogOut}>
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

export default UserMenu;
