import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError, toCamelCase } from '../lib';
import { DonationBookingsActionTypes } from '../constants/actionTypes';
import { DonationBookingsActions, ErrorActions, LoaderActions } from '../actions';

function* watchFetch() {
  yield takeLatest(DonationBookingsActionTypes.INSTRUCTORS_FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.donationBookings.fetch, payload);

      yield put(DonationBookingsActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(DonationBookingsActions.INSTRUCTORS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(DonationBookingsActionTypes.INSTRUCTORS_FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(DonationBookingsActionTypes.INSTRUCTORS_FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* donationClassesSaga() {
  yield all([
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure()
  ]);
}
