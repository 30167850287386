import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { sortBy } from 'lodash';

import { Calendr, DateHelper,  Moment, routes } from '../../lib';
import { Heading, Panel } from '../layout/pages';
import { UserPrivateGroupClassModal } from '../modals';

class PurchasesPage extends React.PureComponent {
  static propTypes = {
    deletePrivateBooking: PropTypes.func.isRequired,
    privateBookings:      PropTypes.array,
    purchasedClasses:     PropTypes.array,
    user:                 PropTypes.object.isRequired
  }

  static defaultProps = {
    privateBookings:  [],
    purchasedClasses: []
  }

  static getDerivedStateFromProps(props) {
    const { privateBookings, purchasedClasses } = props;

    const purchases = Calendr.combineBookings([], privateBookings, [], purchasedClasses);
    return {
      purchases: sortBy(purchases, 'createdAt').reverse()
    };
  }

  state = {
    privateBookingModal: false,
    privateGroupClass:   {},
    purchases:           []
  }

  handleModal = (privateGroupClass) => () => (
    this.setState((prevState) => ({
      privateBookingModal: !prevState.privateBookingModal,
      privateGroupClass
    }))
  )

  renderPurchase = (purchase) =>  {
    const { user } = this.props;
    const onDemand = purchase.event.type === 'onDemandClass';
    const createdAt = new Moment(purchase.createdAt, 'YYYY-MM-DDhh:mm:ssUTC').tz(user.timeZone);

    const date = DateHelper.summerTimeCheck(purchase.event, user.timeZone);
    const startsAt = new Moment.utc(date, 'YYYY-MM-DDhh:mm:ssUTC').tz(user.timeZone);

    const purchaseTime = onDemand ?  createdAt : startsAt;

    return (
      <div
          className='purchase'
          key={purchase.createdAt}>
        <div className='info'>
          <span className='date'>{createdAt.format('DD/MM/YYYY')}</span>
          <span className='name'>{purchase.event.name}</span>
          <span className='description'>
            {onDemand ? 'Uploaded' : 'Schedule'}:&nbsp;
            {purchaseTime.format('ddd, MMM DD')}
            { !onDemand &&
              <span>
                &nbsp;| {startsAt.format('hh:mmA')} - {purchase.event.duration} Minutes
              </span>
            }
          </span>
        </div>

        {!onDemand &&
          <div
              className='button'
              onClick={this.handleModal(purchase)}
              onKeyPress={this.handleModal(purchase)}
              role='button'
              tabIndex={0}>
            View Class
          </div>
        }

        {onDemand &&
          <a
              className='button'
              href={purchase.event.url || routes.VIDEO_PLAYER(purchase.uuid)}
              rel='noopener noreferrer'
              target='_blank'>
            Watch video
          </a>
        }
      </div>
    );
  }

  renderEmpty = () => (
    <div className='message-empty'>
      <p>You have not yet made any bookings.</p>
    </div>
  );

  render() {
    const { purchases } = this.state;

    return (
      <div className='inner-wrapper'>
        <Container fluid>
          <Helmet>
            <title>Bookings</title>
          </Helmet>

          <Heading
              color='primary'
              subtitle='Client Portal'
              title='Bookings' />
          <Panel>
            <h5 className='title-greeting'>
              Summary of Bookings
            </h5>

            {!purchases.length && this.renderEmpty()}

            {!!purchases.length && purchases.map(this.renderPurchase)}
          </Panel>
        </Container>

        { this.state.privateBookingModal &&
          <UserPrivateGroupClassModal
              booking={this.state.privateGroupClass}
              deletePrivateBooking={this.props.deletePrivateBooking}
              isOpen={this.state.privateBookingModal}
              onToggle={this.handleModal()}
              user={this.props.user} />
        }
      </div>
    );
  }
}

export default PurchasesPage;
