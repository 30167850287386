import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { getTimeZone, routes } from '../../lib';
import { Settings } from '../../images';

class TimeZone extends React.PureComponent {
  static propTypes = {
    timeZone: PropTypes.string.isRequired
  }

  render() {
    const { timeZone } = this.props;
    return (
      <div className='calendar-timezone pt-2 pb-2'>
        <div className='calendar-timezone-span'>
          (GMT {moment.tz(getTimeZone(timeZone)).format('Z')}) {getTimeZone(timeZone)}
        </div>
        <Link to={routes.EDIT_ACCOUNT}>
          <img
              alt='Icon'
              className='pl-2'
              src={Settings}
          />
        </Link>
      </div>
    );
  }
}

export default TimeZone;
