import { ClientsActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: ClientsActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: ClientsActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: ClientsActionTypes.CREATE_SUCCESS
  }),


  DELETE: (payload) => ({
    payload, type: ClientsActionTypes.DELETE
  }),

  DELETE_FAILURE: () => ({
    type: ClientsActionTypes.DELETE_FAILURE
  }),

  DELETE_SUCCESS: (payload) => ({
    payload, type: ClientsActionTypes.DELETE_SUCCESS
  }),


  FETCH: () => ({
    type: ClientsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: ClientsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: ClientsActionTypes.FETCH_SUCCESS
  }),


  GET: (payload) => ({
    payload, type: ClientsActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: ClientsActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload, type: ClientsActionTypes.GET_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: ClientsActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: ClientsActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: ClientsActionTypes.UPDATE_SUCCESS
  })
};
