import { StrategyActionTypes } from '../constants/actionTypes';

export default {
  GET: () => ({
    type: StrategyActionTypes.GET
  }),

  GET_FAILURE: () => ({
    type: StrategyActionTypes.GET_FAILURE
  }),

  GET_SUCCESS: (payload) => ({
    payload, type: StrategyActionTypes.GET_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: StrategyActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: StrategyActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: StrategyActionTypes.UPDATE_SUCCESS
  })
};
