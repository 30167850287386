import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { Moment, Validator } from '../../../lib';
import { Button } from '../../../components/forms';
import { Input } from '../../../components/inputs';

class ClassPack extends React.PureComponent {
  static propTypes = {
    classPack:       PropTypes.object.isRequired,
    updateClassPack: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const { classPack } = props;

    if (!state.isMounted) {
      return { classPack };
    }
  }

  state = {
    classPack:     {},
    errors:        [],
    isMounted:     false,
    updateCredits: false
  }

  handleToggle = () => this.setState((prevState) => ({ updateCredits: !prevState.updateCredits }));

  handleChange = (value, inputName) => (
    this.setState((prevState) => ({
      ...prevState,
      classPack: {
        ...prevState.classPack,
        [inputName]: value
      },
      isMounted: true
    }))
  )

  handleUpdateClassPack = () => {
    const { classPack } = this.state;

    return this.handleValidate().then(() => {
      this.props.updateClassPack({ classPack });
      return this.handleToggle();
    });
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { classPack } = this.state;

    const errors = Validator.validate([
      ['combined',       Validator.concepts.isFilled, [classPack.combined]],
      ['live',           Validator.concepts.isFilled, [classPack.live]],
      ['onDemand',       Validator.concepts.isFilled, [classPack.onDemand]],
      ['privateSession', Validator.concepts.isFilled, [classPack.privateSession]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      this.setState({ errors }, () => reject(errors));
    }
  })

  render () {
    const { classPack, updateCredits, errors } = this.state;
    const creditsCount = parseInt(classPack.live) +
                         parseInt(classPack.combined) +
                         parseInt(classPack.onDemand) +
                         parseInt(classPack.privateSession);

    const expiresAt = new Moment(classPack.createdAt, 'YYYY-MM-DDhh:mm:ss').add(classPack.expiry, 'days');
    const isSessionType = classPack.classPack && classPack.classPack.creditsType == 'privateSession';

    return (
      <div className='row'>
        <span className='column'>
          Class Package:
          <span className='credits-badge'>
            <span className='class-pack-name'>
              {classPack && classPack.classPack && classPack.classPack.name}
            </span>
          </span>
        </span>

        { !updateCredits &&
          <span className='column'>
            Credits Unused:
            <span className='value'>
              {`${creditsCount} class credits`}
            </span>
          </span>
        }

        { updateCredits &&
          <div className='input-column'>
            <span>Credits Unused:
              <div className='credits'>
                <Input
                    className='input mb-1'
                    id='live'
                    isDisabled={isSessionType}
                    isInvalid={!!errors.live}
                    max={999}
                    name='live'
                    onChange={this.handleChange}
                    type='number'
                    value={classPack.live} />
                <span className='value'>
                  Live Credit Only
                </span>
              </div>

              <div className='credits'>
                <Input
                    className='input mb-1'
                    id='onDemand'
                    isDisabled={isSessionType}
                    isInvalid={!!errors.onDemand}
                    max={999}
                    name='onDemand'
                    onChange={this.handleChange}
                    type='number'
                    value={classPack.onDemand} />
                <span className='value'>
                  On Demand Credit Only
                </span>
              </div>

              <div className='credits'>
                <Input
                    className='input'
                    id='combined'
                    isDisabled={isSessionType}
                    isInvalid={!!errors.combined}
                    max={999}
                    name='combined'
                    onChange={this.handleChange}
                    type='number'
                    value={classPack.combined} />
                <span className='value'>
                  Combined Credit
                </span>
              </div>

              <div className='credits'>
                <Input
                    className='input mt-1 mb-1'
                    id='privateSession'
                    isDisabled={!isSessionType}
                    isInvalid={!!errors.privateSession}
                    max={999}
                    name='privateSession'
                    onChange={this.handleChange}
                    type='number'
                    value={classPack.privateSession} />
                <span className='value'>
                  Private Session Credit Only
                </span>
              </div>
            </span>
          </div>
        }

        <span className='column'>
          Credits Expiry:
          <span className='value'>
            {classPack.canExpire ? expiresAt.format('MMM D, YYYY') : 'n/a'}
          </span>
        </span>

        { updateCredits &&
          <div className='d-flex flex-direction-row'>
            <Button
                className='adjust-button'
                onClick={this.handleUpdateClassPack}
                size='sm'>
              <span>Change</span>
            </Button>

            <Button
                className='adjust-button ml-1'
                color='muted'
                isOutline
                onClick={this.handleToggle}
                size='sm'>
              <span>Cancel</span>
            </Button>
          </div>
        }

        { !updateCredits &&
          <div className='column button-wrapper'>
            <Button
                className='button'
                color='primary'
                isOutline
                onClick={this.handleToggle}
                size='sm'>
              <span>Adjust Credits</span>
            </Button>
          </div>
        }
      </div>
    );
  }
}

export default ClassPack;
