export default {
  CREATE_ON_DEMAND_FORMAT:         '@@formats/CREATE_ON_DEMAND_FORMAT',
  CREATE_ON_DEMAND_FORMAT_FAILURE: '@@formats/CREATE_ON_DEMAND_FORMAT_FAILURE',
  CREATE_ON_DEMAND_FORMAT_SUCCESS: '@@formats/CREATE_ON_DEMAND_FORMAT_SUCCESS',

  CREATE_PGC_FORMAT:        '@@formats/CREATE_PGC_FORMAT',
  CREATE_PGC_FORMATFAILURE: '@@formats/CREATE_PGC_FORMAT_FAILURE',
  CREATE_PGC_FORMATSUCCESS: '@@formats/CREATE_PGC_FORMAT_SUCCESS',

  FETCH:         '@@formats/FETCH',
  FETCH_FAILURE: '@@formats/FETCH_FAILURE',
  FETCH_SUCCESS: '@@formats/FETCH_SUCCESS'
};
