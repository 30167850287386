import React from 'react';

import { Terms } from '../components/legal';

class TermsContainer extends React.PureComponent {
  render() {
    return <Terms />;
  }
}

export default TermsContainer;
