import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../forms';

class InsrtuctorCancelClassPack extends React.PureComponent {
  static propTypes = {
    cancelClassPack: PropTypes.func.isRequired,
    classPack:       PropTypes.object.isRequired,
    deleteSample:    PropTypes.func.isRequired,
    onClose:         PropTypes.func.isRequired,
    onSuccess:       PropTypes.func.isRequired
  };

  handleCancel = () => {
    const { cancelClassPack, deleteSample, classPack } = this.props;
    const params = { classPack: { id: classPack.id }};

    classPack.sample ? deleteSample(params) : cancelClassPack(params);
    this.props.onClose();
    this.props.onSuccess();
  };

  render() {
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            <span>
              Are you sure you want to <span className='text-danger'>cancel</span> your<br />
              <span className='text-primary'> {this.props.classPack.name} class pack?</span>
            </span>

            <div className='mt-4'>
              <small className='text-muted'>
                Note: this will cancel the class pack for all<br />
                active members and stop all future charges.
              </small>
            </div>
          </CardText>

          <div className='actions'>
            <Button
                color='danger'
                isOutline
                onClick={this.handleCancel}>
              Yes-Cancel
            </Button>

            <Button
                color='primary'
                onClick={this.props.onClose}>
              No-Keep
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default InsrtuctorCancelClassPack;
