import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter, map } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { BookingRequestsActionTypes } from '../constants/actionTypes';
import { BookingRequestsActions, ErrorActions, LoaderActions, PrivateSessionsActions } from '../actions';

function* watchAccept() {
  yield takeLatest(BookingRequestsActionTypes.ACCEPT, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.instructor.bookingRequests.update, payload);

      const bookingRequests = yield select((state) => state.bookingRequests);
      const data = filter(bookingRequests.instructorData,
        (bookingRequest) => bookingRequest.id != payload.bookingRequest.id);

      yield put(PrivateSessionsActions.INSTRUCTORS_CREATE_SUCCESS(payload.bookingRequest));
      yield put(BookingRequestsActions.ACCEPT_SUCCESS(data));
      toastr.success('Session approved');
    } catch (error) {
      yield put(BookingRequestsActions.ACCEPT_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchAcceptSuccess() {
  yield takeLatest(BookingRequestsActionTypes.ACCEPT_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchAcceptFailure() {
  yield takeLatest(BookingRequestsActionTypes.ACCEPT_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDecline() {
  yield takeLatest(BookingRequestsActionTypes.DECLINE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.instructor.bookingRequests.delete, payload);

      const bookingRequests = yield select((state) => state.bookingRequests);
      const data = filter(bookingRequests.instructorData,
        (bookingRequest) => bookingRequest.id != payload.bookingRequest.id);

      yield put(BookingRequestsActions.DECLINE_SUCCESS(data));
      toastr.success('Session declined');
    } catch (error) {
      yield put(BookingRequestsActions.DECLINE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDeclineSuccess() {
  yield takeLatest(BookingRequestsActionTypes.DECLINE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDeclineFailure() {
  yield takeLatest(BookingRequestsActionTypes.DECLINE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCancel() {
  yield takeLatest(BookingRequestsActionTypes.CANCEL, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.bookingRequests.delete, payload);

      const bookingRequests = yield select((state) => state.bookingRequests);
      const data = filter(bookingRequests.data,
        (bookingRequest) => bookingRequest.id != payload.bookingRequest.id);

      yield put(BookingRequestsActions.CANCEL_SUCCESS(data));
      toastr.success('Request sent!');
    } catch (error) {
      yield put(BookingRequestsActions.CANCEL_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCancelSuccess() {
  yield takeLatest(BookingRequestsActionTypes.CANCEL_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCancelFailure() {
  yield takeLatest(BookingRequestsActionTypes.CANCEL_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetch() {
  yield takeLatest(BookingRequestsActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.bookingRequests.fetch);

      yield put(BookingRequestsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(BookingRequestsActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(BookingRequestsActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(BookingRequestsActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsFetch() {
  yield takeLatest(BookingRequestsActionTypes.INSTRUCTORS_FETCH, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.bookingRequests.fetch);

      yield put(BookingRequestsActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(BookingRequestsActions.INSTRUCTORS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsFetchSuccess() {
  yield takeLatest(BookingRequestsActionTypes.INSTRUCTORS_FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsFetchFailure() {
  yield takeLatest(BookingRequestsActionTypes.INSTRUCTORS_FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsUpdate() {
  yield takeLatest(BookingRequestsActionTypes.INSTRUCTORS_UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.instructor.privateSessions.update, payload);

      const bookingRequests = yield select((state) => state.bookingRequests.instructorData);
      const data = map(bookingRequests, (bookingRequest) =>
        (bookingRequest.id == payload.privateSession.id ? toCamelCase(response.data) : bookingRequest)
      );

      yield put(BookingRequestsActions.INSTRUCTORS_UPDATE_SUCCESS(data));
    } catch (error) {
      yield put(BookingRequestsActions.INSTRUCTORS_UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsUpdateSuccess() {
  yield takeLatest(BookingRequestsActionTypes.INSTRUCTORS_UPDATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsUpdateFailure() {
  yield takeLatest(BookingRequestsActionTypes.INSTRUCTORS_UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* bookingRequestsSaga() {
  yield all([
    watchAccept(),
    watchAcceptSuccess(),
    watchAcceptFailure(),
    watchDecline(),
    watchDeclineSuccess(),
    watchDeclineFailure(),
    watchCancel(),
    watchCancelSuccess(),
    watchCancelFailure(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchInstructorsFetch(),
    watchInstructorsFetchSuccess(),
    watchInstructorsFetchFailure(),
    watchInstructorsUpdate(),
    watchInstructorsUpdateSuccess(),
    watchInstructorsUpdateFailure()
  ]);
}
