import { compose } from 'redux';
import { connect } from 'react-redux';

import { DomainActions } from '../../actions';
import { requiresProfile } from '../../helpers';
import { SettingsDomainPage } from '../../components/pages';

const mapStateToProps = (state) => ({
  domain:       state.domain.data,
  instructor:   state.profile.data,
  isLoading:    state.domain.isLoading,
  subscription: state.plans.userData
});

const mapDispatchToProps = (dispatch) => ({
  createDomain: (data) => dispatch(DomainActions.CREATE(data)),
  deleteDomain: (data) => dispatch(DomainActions.DELETE(data)),
  getDomain:    (data) => dispatch(DomainActions.GET(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(SettingsDomainPage);
