import { PasswordActionTypes } from '../constants/actionTypes';

export default {
  RECOVER: (payload) => ({
    payload, type: PasswordActionTypes.RECOVER
  }),

  RECOVER_FAILURE: () => ({
    type: PasswordActionTypes.RECOVER_FAILURE
  }),

  RECOVER_SUCCESS: () => ({
    type: PasswordActionTypes.RECOVER_SUCCESS
  }),


  RESET: (payload) => ({
    payload, type: PasswordActionTypes.RESET
  }),

  RESET_FAILURE: () => ({
    type: PasswordActionTypes.RESET_FAILURE
  }),

  RESET_SUCCESS: () => ({
    type: PasswordActionTypes.RESET_SUCCESS
  })
};
