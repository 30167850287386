import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';

import { Checkbox, Tags } from './';

const TIME_OPTIONS = [
  { id: 'Morning',   title: 'Morning' },
  { id: 'Afternoon', title: 'Afternoon' },
  { id: 'Evening',   title: 'Evening' }
];

class Timetable extends React.PureComponent {
  static propTypes = {
    children:     PropTypes.array.isRequired,
    hasTime:      PropTypes.bool,
    onTick:       PropTypes.func.isRequired,
    onTimeChange: PropTypes.func,

    schedule: PropTypes.shape({
      friday:    PropTypes.bool,
      monday:    PropTypes.bool,
      saturday:  PropTypes.bool,
      sunday:    PropTypes.bool,
      thursday:  PropTypes.bool,
      tuesday:   PropTypes.bool,
      wednesday: PropTypes.bool
    }).isRequired,
    selectedTime: PropTypes.string
  }

  static defaultProps = {
    hasTime:      false,
    onTimeChange: () => {},
    selectedTime: ''
  };

  render() {
    const { schedule } = this.props;

    return (
      <div className='timetable'>
        <FormGroup
            className='days align-items-center'
            row>
          <Label
              sm={2}
              xs={3}>
            {this.props.children[0]}
          </Label>
          <Col
              className='cells'
              sm={10}
              xs={9}>
            <div className='cell form-check form-check-inline'>
              <Checkbox
                  id='monday'
                  isChecked={schedule.monday}
                  name='monday'
                  onChange={this.props.onTick('monday')} />
              <Label
                  check
                  htmlFor='input-monday'>
                M
              </Label>
            </div>

            <div className='cell form-check form-check-inline'>
              <Checkbox
                  id='tuesday'
                  isChecked={schedule.tuesday}
                  name='tuesday'
                  onChange={this.props.onTick('tuesday')} />
              <Label
                  check
                  htmlFor='input-tuesday'>T</Label>
            </div>

            <div className='cell form-check form-check-inline'>
              <Checkbox
                  id='wednesday'
                  isChecked={schedule.wednesday}
                  name='wednesday'
                  onChange={this.props.onTick('wednesday')} />
              <Label
                  check
                  htmlFor='input-wednesday'>W</Label>
            </div>

            <div className='cell form-check form-check-inline'>
              <Checkbox
                  id='thursday'
                  isChecked={schedule.thursday}
                  name='thursday'
                  onChange={this.props.onTick('thursday')} />
              <Label
                  check
                  htmlFor='input-thursday'>T</Label>
            </div>

            <div className='cell form-check form-check-inline'>
              <Checkbox
                  id='friday'
                  isChecked={schedule.friday}
                  name='friday'
                  onChange={this.props.onTick('friday')} />
              <Label
                  check
                  htmlFor='input-friday'>F</Label>
            </div>

            <div className='cell form-check form-check-inline'>
              <Checkbox
                  id='saturday'
                  isChecked={schedule.saturday}
                  name='saturday'
                  onChange={this.props.onTick('saturday')} />
              <Label
                  check
                  htmlFor='input-saturday'>S</Label>
            </div>

            <div className='cell form-check form-check-inline'>
              <Checkbox
                  id='sunday'
                  isChecked={schedule.sunday}
                  name='sunday'
                  onChange={this.props.onTick('sunday')} />
              <Label
                  check
                  htmlFor='input-sunday'>S</Label>
            </div>
          </Col>
        </FormGroup>

        { this.props.hasTime &&
          <FormGroup
              className='time'
              row>
            <Label xs={2}>
              {this.props.children[1]}
            </Label>

            <Col
                className='cells pr-4'
                xs='10'>
              <Tags
                  className='w-75'
                  isMulti={false}
                  name='timeOfDay'
                  onChange={this.props.onTimeChange}
                  options={TIME_OPTIONS}
                  selectedIds={[this.props.selectedTime]} />
            </Col>
          </FormGroup>
        }
      </div>
    );
  }
}

export default Timetable;
