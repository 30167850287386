import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';

import { Calendr, Moment } from '../../../lib';
import { MonthDaySelect } from '../';
import { calendarOptions } from '../../../constants';

class DateSelect extends React.PureComponent {
  static propTypes = {
    dateFormat:   PropTypes.string,
    hasDaySelect: PropTypes.bool.isRequired,
    hasPast:      PropTypes.bool,
    isOnProfile:  PropTypes.bool,
    onChange:     PropTypes.func.isRequired,
    selectedDate: PropTypes.object.isRequired,
    selectedView: PropTypes.string.isRequired,
    today:        PropTypes.object
  };

  static defaultProps = {
    dateFormat:  '',
    hasPast:     true,
    isOnProfile: false,
    today:       new Moment()
  };

  state = {
    isDaySelectOpen: false
  };

  handleChange = (amount, type) => () => {
    this.props.onChange(
      new Moment(this.props.selectedDate).add(amount, type),
      'selectedDate'
    );
  };

  handleOpen = () => {
    if (this.props.hasDaySelect) {
      this.setState((prevState) => ({ isDaySelectOpen: !prevState.isDaySelectOpen }));
    }
  };

  render() {
    const { selectedView, selectedDate, dateFormat, hasPast, today, isOnProfile } = this.props;
    const { isDaySelectOpen } = this.state;

    const options = calendarOptions.DEFAULTS.dayPicker;
    const pickerView = isDaySelectOpen ? 'month' : selectedView;

    const yesterday = new Moment(selectedDate).subtract(1, 'days');

    const prevDisabled = Moment(yesterday).isBefore(today.startOf('day'));

    const dateSpan = Moment(selectedDate).format(dateFormat);
    const dateSpanCalendar = Calendr.setDatePickerFormat(selectedDate, selectedView, isMobile);

    return (
      <div>
        { isOnProfile &&
          <div className='workout-section-sessions'>
            <div className='workout__section-header'>
              <h6 className='workout__section-headspan'>Private Sessions</h6>
              <div className='private-sessions__buttons'>
                <button
                    className='private-sessions__arrow private-sessions__arrow_margin'
                    onClick={this.handleChange(options.step.prev, options.type[pickerView])}>&lt;&nbsp;PREV</button>
                <button
                    className='private-sessions__arrow'
                    onClick={this.handleChange(options.step.next, options.type[pickerView])}>NEXT&nbsp;&gt;</button>
              </div>
            </div>
            <div className='workout__date-header'>
              <span
                  className='workout__date'
                  onClick={this.handleOpen}
                  onKeyPress={this.handleOpen}
                  role='button'
                  tabIndex={0}>
                {dateFormat ? dateSpan : dateSpanCalendar}
              </span>
            </div>
          </div>
        }
        { !isOnProfile &&
          <div className='select-date-container'>
            <div className={classNames('date-select', { 'is-active': isDaySelectOpen })}>
              <button
                  className='btn-calendar btn-prev'
                  disabled={!hasPast && prevDisabled}
                  onClick={this.handleChange(options.step.prev, options.type[pickerView])}>
                <Icon
                    icon='chevron-left'
                    size='sm' />
              </button>
              <div className='date'>
                <span
                    onClick={this.handleOpen}
                    onKeyPress={this.handleOpen}
                    role='button'
                    tabIndex={0}>
                  {dateFormat ? dateSpan : dateSpanCalendar}
                </span>
              </div>

              <button
                  className='btn-calendar btn-next'
                  onClick={this.handleChange(options.step.next, options.type[pickerView])}>
                <Icon
                    icon='chevron-right'
                    size='sm' />
              </button>
            </div>

            <MonthDaySelect
                isOpen={isDaySelectOpen}
                onChange={this.props.onChange}
                onToggle={this.handleOpen}
                selectedDate={this.props.selectedDate}
                today={this.props.today} />
          </div>
        }
      </div>
    );
  }
}

export default DateSelect;
