import { MembershipsDiscountActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: MembershipsDiscountActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: MembershipsDiscountActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: MembershipsDiscountActionTypes.CREATE_SUCCESS
  }),

  RESTORE: () => ({
    type: MembershipsDiscountActionTypes.RESTORE
  })
};
