import { PrivateSessionsActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: PrivateSessionsActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: PrivateSessionsActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionsActionTypes.CREATE_SUCCESS
  }),


  DELETE: (payload) => ({
    payload, type: PrivateSessionsActionTypes.DELETE
  }),

  DELETE_FAILURE: () => ({
    type: PrivateSessionsActionTypes.DELETE_FAILURE
  }),

  DELETE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionsActionTypes.DELETE_SUCCESS
  }),


  FETCH: () => ({
    type: PrivateSessionsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: PrivateSessionsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: PrivateSessionsActionTypes.FETCH_SUCCESS
  }),


  INSTRUCTORS_CREATE: (payload) => ({
    payload, type: PrivateSessionsActionTypes.INSTRUCTORS_CREATE
  }),

  INSTRUCTORS_CREATE_FAILURE: () => ({
    type: PrivateSessionsActionTypes.INSTRUCTORS_CREATE_FAILURE
  }),

  INSTRUCTORS_CREATE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionsActionTypes.INSTRUCTORS_CREATE_SUCCESS
  }),


  INSTRUCTORS_DELETE: (payload) => ({
    payload, type: PrivateSessionsActionTypes.INSTRUCTORS_DELETE
  }),

  INSTRUCTORS_DELETE_FAILURE: () => ({
    type: PrivateSessionsActionTypes.INSTRUCTORS_DELETE_FAILURE
  }),

  INSTRUCTORS_DELETE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionsActionTypes.INSTRUCTORS_DELETE_SUCCESS
  }),


  INSTRUCTORS_FETCH: () => ({
    type: PrivateSessionsActionTypes.INSTRUCTORS_FETCH
  }),

  INSTRUCTORS_FETCH_FAILURE: () => ({
    type: PrivateSessionsActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_FETCH_SUCCESS: (payload) => ({
    payload, type: PrivateSessionsActionTypes.INSTRUCTORS_FETCH_SUCCESS
  }),


  INSTRUCTORS_UPDATE: (payload) => ({
    payload, type: PrivateSessionsActionTypes.INSTRUCTORS_UPDATE
  }),

  INSTRUCTORS_UPDATE_FAILURE: () => ({
    type: PrivateSessionsActionTypes.INSTRUCTORS_UPDATE_FAILURE
  }),

  INSTRUCTORS_UPDATE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionsActionTypes.INSTRUCTORS_UPDATE_SUCCESS
  }),


  RECURRING_CREATE: (payload) => ({
    payload, type: PrivateSessionsActionTypes.RECURRING_CREATE
  }),

  RECURRING_CREATE_FAILURE: () => ({
    type: PrivateSessionsActionTypes.RECURRING_CREATE_FAILURE
  }),

  RECURRING_CREATE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionsActionTypes.RECURRING_CREATE_SUCCESS
  }),


  RECURRING_DELETE: (payload) => ({
    payload, type: PrivateSessionsActionTypes.RECURRING_DELETE
  }),

  RECURRING_DELETE_FAILURE: () => ({
    type: PrivateSessionsActionTypes.RECURRING_DELETE_FAILURE
  }),

  RECURRING_DELETE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionsActionTypes.RECURRING_DELETE_SUCCESS
  }),
  

  RECURRING_UPDATE: (payload) => ({
    payload, type: PrivateSessionsActionTypes.RECURRING_UPDATE
  }),

  RECURRING_UPDATE_FAILURE: () => ({
    type: PrivateSessionsActionTypes.RECURRING_UPDATE_FAILURE
  }),

  RECURRING_UPDATE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionsActionTypes.RECURRING_UPDATE_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: PrivateSessionsActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: PrivateSessionsActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: PrivateSessionsActionTypes.UPDATE_SUCCESS
  })
};
