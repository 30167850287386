import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Moment, getTimeZone } from '../../../lib';

class TimeZone extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    timeZone:  PropTypes.string
  }

  static defaultProps = {
    className: '',
    timeZone:  ''
  }

  render() {
    const { className, timeZone } = this.props;

    return (
      <div className={classNames('profile-timezone pb-2', className)}>
        <span className='profile-timezone-span'>
          All times in {new Moment.tz(getTimeZone(timeZone)).format('z')} (GMT
          {new Moment.tz(getTimeZone(timeZone)).format('Z')} {getTimeZone(timeZone)})
        </span>
      </div>
    );
  }
}

export default TimeZone;
