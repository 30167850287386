import { all, call, put, takeLatest } from 'redux-saga/effects';
import { pick } from 'lodash';

import { api, extractError, toCamelCase } from '../lib';
import { FilterActionTypes } from '../constants/actionTypes';

import {
  ErrorActions,
  FilterActions,
  InstructorsActions,
  LoaderActions,
  StudiosActions
} from '../actions';

function* watchFilter() {
  yield takeLatest(FilterActionTypes.FILTER, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.filter.create, payload);

      yield put(FilterActions.FILTER_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(FilterActions.FILTER_FAILURE());

      /* error */
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFilterSuccess() {
  yield takeLatest(FilterActionTypes.FILTER_SUCCESS, function* ({ payload }) {

    /* DISPATCH RESULTS */
    yield put(InstructorsActions.GET_INSTRUCTORS(
      payload.instructors || [],
      pick(payload, ['perPage', 'subject', 'totalPages', 'totalRecords', 'totalIds'])
    ));

    yield put(StudiosActions.GET_STUDIOS(
      payload.locations || [],
      pick(payload, ['perPage', 'subject', 'totalPages', 'totalRecords', 'totalIds'])
    ));

    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFilterFailure() {
  yield takeLatest(FilterActionTypes.FILTER_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* filterSaga() {
  yield all([
    watchFilter(),
    watchFilterSuccess(),
    watchFilterFailure()
  ]);
}
