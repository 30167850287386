import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter } from 'lodash';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';

import { api, extractError, routes, toCamelCase } from '../lib';
import { MembershipsCouponsActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions, MembershipsCouponsActions } from '../actions';

function* watchCreate() {
  yield takeLatest(MembershipsCouponsActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.membership.coupon.create, payload);

      yield put(MembershipsCouponsActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Coupon added');
    } catch (error) {
      yield put(MembershipsCouponsActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(MembershipsCouponsActionTypes.CREATE_SUCCESS, function* () {
    yield put(push(routes.COUPON));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(MembershipsCouponsActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetch() {
  yield takeLatest(MembershipsCouponsActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.membership.coupon.fetch);

      yield put(MembershipsCouponsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(MembershipsCouponsActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchGet() {
  yield takeLatest(MembershipsCouponsActionTypes.GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('membershipsCoupons'));
      const response = yield call(api.membership.coupon.get, payload);

      yield put(MembershipsCouponsActions.GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(MembershipsCouponsActions.GET_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchGetSuccess() {
  yield takeLatest(MembershipsCouponsActionTypes.GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('membershipsCoupons'));
  });
}

function* watchGetFailure() {
  yield takeLatest(MembershipsCouponsActionTypes.GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('membershipsCoupons'));
  });
}

function* watchDelete() {
  yield takeLatest(MembershipsCouponsActionTypes.DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.membership.coupon.delete, payload);

      const coupons = yield select((state) => state.membershipsCoupons.data);
      const data = filter(coupons, (coupon) => (coupon.id != payload.coupon.id));

      yield put(MembershipsCouponsActions.DELETE_SUCCESS(data));
      toastr.success('Coupon deleted');
    } catch (error) {
      yield put(MembershipsCouponsActions.DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDeleteSuccess() {
  yield takeLatest(MembershipsCouponsActionTypes.DELETE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDeleteFailure() {
  yield takeLatest(MembershipsCouponsActionTypes.DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* membershipsCouponsSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),

    watchFetch(),

    watchGet(),
    watchGetSuccess(),
    watchGetFailure(),

    watchDelete(),
    watchDeleteSuccess(),
    watchDeleteFailure()
  ]);
}
