import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Button } from '../../../forms';
import { routes } from '../../../../lib';
import { InstructorNoPhotoWide } from '../../../../images';

class InstructorsMobile extends React.PureComponent {
  static propTypes = {
    instructors:         PropTypes.array.isRequired,
    scrapperInstructors: PropTypes.array
  }

  static defaultProps = {
    scrapperInstructors: []
  }

  static getDerivedStateFromProps(props) {
    return {
      instructors: props.instructors.concat(props.scrapperInstructors)
    };
  }

  state = {
    endIndex:    4,
    instructors: []
  }

  handleShowMore = () => {
    this.setState((prevState) => ({
      endIndex: prevState.endIndex + 4
    }));
  }

  renderInstructor = (instructor) => {
    if (instructor.username) {
      return (
        <Link
            className='instructor-sm'
            key={instructor.id}
            style={{ backgroundImage: `url(${instructor.photo || InstructorNoPhotoWide})` }}
            to={routes.INSTRUCTOR(instructor.username)}>
          <span className='instructor-name'>{instructor.fullName}</span>
          <span className='followers-count'>
            <Icon
                className='mr-2'
                icon={['fas', 'heart']} />
            {instructor.followersCount}
          </span>
        </Link>
      );
    } else {
      return (
        <div
            className='instructor-sm'
            key={instructor.id}
            style={{ backgroundImage: `url(${InstructorNoPhotoWide})` }}>
          <span className='instructor-name'>{instructor.fullName}</span>
        </div>
      );
    }
  }

  render() {
    const { instructors, endIndex } = this.state;
    const allShown = instructors.slice(0, this.state.endIndex).length === instructors.length;

    return (
      <div className='studio-instructors-sm'>
        <div className='content'>
          {instructors.slice(0, endIndex).map((instructor) => (this.renderInstructor(instructor)))}
        </div>
        { !allShown &&
          <div className='actions'>
            <Button
                className='my-2'
                color='link'
                onClick={this.handleShowMore}
                size='lg'>
              SHOW MORE
            </Button>
          </div>
        }
      </div>
    );
  }
}

export default InstructorsMobile;