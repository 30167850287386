import { connect } from 'react-redux';

import { WinBackSequencePage } from '../components/pages';
import { EmailTemplatesActions } from '../actions';

const mapStateToProps = (state) => ({
  emailTemplates: state.emailTemplates.data,
  isLoading:      state.loader.emailTemplates.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  createEmailTemplate: (data) => dispatch(EmailTemplatesActions.CREATE(data)),
  updateEmailTemplate: (data) => dispatch(EmailTemplatesActions.UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(WinBackSequencePage);
