import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormText, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { DateHelper, Moment, getTimeZone, withHttp } from '../../../lib';
import { Button } from '../../forms';
import { UserAvatar } from '../../users';

class BookingModal extends React.PureComponent {
  static propTypes = {
    createBooking: PropTypes.func,
    groupClass:    PropTypes.object.isRequired,
    isInfo:        PropTypes.bool,
    isOpen:        PropTypes.bool.isRequired,
    onToggle:      PropTypes.func.isRequired,
    user:          PropTypes.object.isRequired
  }

  static defaultProps = {
    createBooking: () => {},
    isInfo:        false
  }

  state = {
    reserved: false
  }

  handleBook = () => {
    this.props.createBooking({ booking: { bookableId: this.props.groupClass.id, bookableType: 'GroupClass' } });
    this.handleToggle();
  }

  handleRedirectAndBook = () => {
    const { groupClass } = this.props;

    this.setState({ reserved: true }, () => {
      if (groupClass.bookingUrl) {
        window.open(`//${groupClass.bookingUrl}`, '_blank');
      } else if (groupClass.location && !!groupClass.location.bookingLink) {
        window.open(`${groupClass.location.bookingLink}`, '_blank');
      }
    });
  }

  handleToggle = () => {
    this.props.onToggle();
  }

  render() {
    const { groupClass, user } = this.props;

    const date = DateHelper.summerTimeCheck(groupClass, user.timeZone);

    const hasBookingLink = !!((groupClass.location && groupClass.location.bookingLink) || groupClass.bookingUrl);

    const isOwn = !window.location.origin.includes('localhost') &&
                  groupClass.instructor.id === user.instructor.id;
    return (
      <Modal
          className='modal-booking'
          isOpen={this.props.isOpen}
          toggle={this.handleToggle}>
        <ModalHeader toggle={this.handleToggle} />

        <ModalBody>
          <h1 className='event-name'>{groupClass.name}</h1>

          { groupClass.instructor &&
            <div className='event-instructor'>
              <UserAvatar
                  size='lg'
                  src={groupClass.instructor.photo} />
              <h5>{groupClass.instructor.name}</h5>
            </div>
          }

          <div className='event-date'>
            {DateHelper.getDate(groupClass.startsAt, user.timeZone).format('dddd, MMMM DD')}
          </div>

          <div className='event-time'>
            {DateHelper.getDate(date, user.timeZone).format('hh:mmA')}
            &#8722;
            {groupClass.duration + ' Minutes'}
          </div>

          <div className='modal__event-timezone modal__small-text'>
            {new Moment.tz(getTimeZone(user.timeZone)).format('z')} (GMT
            {new Moment.tz(getTimeZone(user.timeZone)).format('Z')} {getTimeZone(user.timeZone)})
          </div>

          { groupClass.location &&
            <div className='event-location'>
              {groupClass.location.name}
            </div>
          }

          { groupClass.playlist &&
            <div className='event-playlist'>
              <span>Sneak Peek the Playlist?</span>

              <a
                  className='playlist-link'
                  href={`https://open.spotify.com/playlist/${groupClass.playlist.playlistId}`}
                  rel='noopener noreferrer'
                  target='_blank'>
                <Icon
                    className='mr-2'
                    fixedWidth
                    icon={['fab', 'spotify']}
                    size='lg' />
                <span>{groupClass.playlist.name}</span>
              </a>
            </div>
          }

          { groupClass.digitalLink &&
            <a
                className='d-block text-truncate'
                href={withHttp(groupClass.digitalLink)}
                rel='noopener noreferrer external'
                target='_blank'>
              {groupClass.digitalLink}
            </a>
          }
        </ModalBody>
        <ModalFooter>
          { groupClass.location && hasBookingLink &&
            <FormText>
              Clicking reserve will open a new tab to {groupClass.location.name}
              &nbsp; to complete the transaction
            </FormText>
          }
          { !this.props.isInfo &&
          <React.Fragment>
            <Row>
              { !this.state.reserved && hasBookingLink &&
                <Col
                    className='mb-1 mb-lg-0'
                    lg={6}
                    md={12}>
                  <Button
                      color='blue'
                      isBlock
                      onClick={this.handleRedirectAndBook}
                      size='lg'>
                    Reserve spot
                  </Button>
                </Col>
              }

              { !this.state.reserved && !isOwn &&
                <Col
                    lg={6}
                    md={12}>
                  <Button
                      isBlock
                      isOutline
                      onClick={this.handleBook}
                      size='lg'>
                    Add to planner
                  </Button>
                </Col>
              }

              { this.state.reserved &&
                <Col>
                  <Button
                      isBlock
                      isOutline
                      onClick={this.handleBook}
                      size='lg'>
                    Add to your planner
                  </Button>
                </Col>
              }
            </Row>
          </React.Fragment>
          }
        </ModalFooter>
      </Modal>
    );
  }
}

export default BookingModal;
