import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { requiresProfile } from '../../helpers';
import { SettingsAboutPage } from '../../components/pages';
import { ProfileActions } from '../../actions';

class SettingsAboutContainer extends React.PureComponent {
  render() {
    return (
      <SettingsAboutPage {...this.props} />
    );
  }
}

const mapStateToProps = (state) => ({
  activities:  state.activities,
  instructor:  state.profile.data,
  profileData: state.profileData
});

const mapDispatchToProps = (dispatch) => ({
  updateProfile: (data) => dispatch(ProfileActions.UPDATE(data))
});

export default compose(
  requiresProfile,
  connect(mapStateToProps, mapDispatchToProps)
)(SettingsAboutContainer);
