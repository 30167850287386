export default {
  DELETE_CHALLENGE:         '@@samples/DELETE_CHALLENGE',
  DELETE_CHALLENGE_FAILURE: '@@samples/DELETE_CHALLENGE_FAILURE',
  DELETE_CHALLENGE_SUCCESS: '@@samples/DELETE_CHALLENGE_SUCCESS',

  DELETE_CLASS_PACK:         '@@samples/DELETE_CLASS_PACK',
  DELETE_CLASS_PACK_FAILURE: '@@samples/DELETE_CLASS_PACK_FAILURE',
  DELETE_CLASS_PACK_SUCCESS: '@@samples/DELETE_CLASS_PACK_SUCCESS',

  DELETE_MEMBERSHIP:         '@@samples/DELETE_MEMBERSHIP',
  DELETE_MEMBERSHIP_FAILURE: '@@samples/DELETE_MEMBERSHIP_FAILURE',
  DELETE_MEMBERSHIP_SUCCESS: '@@samples/DELETE_MEMBERSHIP_SUCCESS',

  FETCH_CHALLENGES:         '@@samples/FETCH_CHALLENGES',
  FETCH_CHALLENGES_FAILURE: '@@samples/FETCH_CHALLENGES_FAILURE',
  FETCH_CHALLENGES_SUCCESS: '@@samples/FETCH_CHALLENGES_SUCCESS',

  FETCH_CLASS_PACKS:         '@@samples/FETCH_CLASS_PACKS',
  FETCH_CLASS_PACKS_FAILURE: '@@samples/FETCH_CLASS_PACKS_FAILURE',
  FETCH_CLASS_PACKS_SUCCESS: '@@samples/FETCH_CLASS_PACKS_SUCCESS',

  FETCH_MEMBERSHIPS:         '@@samples/FETCH_MEMBERSHIPS',
  FETCH_MEMBERSHIPS_FAILURE: '@@samples/FETCH_MEMBERSHIPS_FAILURE',
  FETCH_MEMBERSHIPS_SUCCESS: '@@samples/FETCH_MEMBERSHIPS_SUCCESS',

  FETCH_ON_DEMANDS:         '@@samples/FETCH_ON_DEMANDS',
  FETCH_ON_DEMANDS_FAILURE: '@@samples/FETCH_ON_DEMANDS_FAILURE',
  FETCH_ON_DEMANDS_SUCCESS: '@@samples/FETCH_ON_DEMANDS_SUCCESS',

  FETCH_PRIVATE_GROUP_CLASSES:         '@@samples/FETCH_PRIVATE_GROUP_CLASSES',
  FETCH_PRIVATE_GROUP_CLASSES_FAILURE: '@@samples/FETCH_PRIVATE_GROUP_CLASSES_FAILURE',
  FETCH_PRIVATE_GROUP_CLASSES_SUCCESS: '@@samples/FETCH_PRIVATE_GROUP_CLASSES_SUCCESS'
};
