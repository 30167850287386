/* global moment */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import pluralize from 'pluralize';
import { compact, filter, map, sortBy } from 'lodash';

import { EditGroupClassModal, InstructorPrivateGroupClassModal, InstructorPrivateSessionModal } from '../modals';
import { GroupClass, PrivateGroupClass, PrivateSession } from './';

class TodayEvents extends React.PureComponent {
  static propTypes = {
    deleteGroupClass:                 PropTypes.func.isRequired,
    deletePrivateGroupClass:          PropTypes.func.isRequired,
    deletePrivateSession:             PropTypes.func.isRequired,
    deleteRecurringGroupClass:        PropTypes.func.isRequired,
    deleteRecurringPrivateGroupClass: PropTypes.func.isRequired,
    deleteRecurringPrivateSession:    PropTypes.func.isRequired,
    groupClasses:                     PropTypes.array.isRequired,
    instructor:                       PropTypes.object.isRequired,
    privateGroupClasses:              PropTypes.array.isRequired,
    privateSessions:                  PropTypes.array.isRequired,
    updateGroupClass:                 PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props) {
    const today = new moment();

    const combinedClasses = filter(sortBy(
      [
        ...map(props.groupClasses, (combinedClass) => ({ ...combinedClass, model: 'GroupClass' })),
        ...map(props.privateGroupClasses, (combinedClass) => ({ ...combinedClass, model: 'PrivateGroupClass' })),
        ...map(props.privateSessions, (combinedClass) => ({ ...combinedClass, model: 'PrivateSession' }))
      ],
      (combinedClass) => combinedClass.startsAt
    ), (combinedClass) => (
      moment(combinedClass.startsAt, 'YYYY-MM-DD hh:mm:ss').isSame(today, 'day')
    ));

    return {
      combinedClasses: compact(combinedClasses)
    };
  }

  state = {
    groupClass: {},

    modals: {
      groupClass:        false,
      privateGroupClass: false,
      privateSession:    false
    }
  }

  handleModal = (name, value = {}) => () => {
    this.setState((prevState) => ({
      modals: {
        ...prevState.modals,
        [name]: !prevState.modals[name]
      },
      [name]: value
    }));
  }

  handleToggle = () => {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  renderCarret = () => (
    <div className='float-right pt-2'>
      <span className={classNames('caret', { 'rotate': this.state.isOpen })}>
        &#8963;
      </span>
    </div>
  );

  renderEvents = () => (
    this.state.combinedClasses.map((combinedClass) => {
      switch (combinedClass.model) {
        case 'GroupClass':
          return (
            <GroupClass
                groupClass={combinedClass}
                key={combinedClass.id}
                onClick={this.handleModal('groupClass', combinedClass)} />
          );

        case 'PrivateGroupClass':
          return (
            <PrivateGroupClass
                key={combinedClass.id}
                onClick={this.handleModal('privateGroupClass', combinedClass)}
                privateGroupClass={combinedClass} />
          );

        case 'PrivateSession':
          return (
            <PrivateSession
                key={combinedClass.id}
                onClick={this.handleModal('privateSession', combinedClass)}
                privateSession={combinedClass} />
          );

        default: return null;
      }
    })
  );

  render() {
    const { combinedClasses } = this.state;

    return (
      <React.Fragment>
        <div className='today-events'>
          <div
              className='header pl-5 p-3'
              onClick={this.handleToggle}
              onKeyPress={this.handleToggle}
              role='button'
              tabIndex={0}>
            <div className='d-inline-block'>
              <strong>Today&apos;s Events</strong>

              <div className='text-muted text-sm'>
                {combinedClasses.length} {pluralize('event', combinedClasses.length)} today
              </div>
            </div>

            {this.renderCarret()}
          </div>

          {this.state.isOpen && this.renderEvents()}
        </div>

        <div className='modals'>
          { this.state.modals.groupClass &&
            <EditGroupClassModal
                deleteGroupClass={this.props.deleteGroupClass}
                deleteRecurringGroupClass={this.props.deleteRecurringGroupClass}
                groupClass={this.state.groupClass}
                instructor={this.props.instructor}
                isOpen={this.state.modals.groupClass}
                onToggle={this.handleModal('groupClass')}
                updateGroupClass={this.props.updateGroupClass} />
          }

          { this.state.modals.privateGroupClass &&
            <InstructorPrivateGroupClassModal
                deletePrivateGroupClass={this.props.deletePrivateGroupClass}
                deleteRecurringGroupClass={this.props.deleteRecurringPrivateGroupClass}
                isOpen={this.state.modals.privateGroupClass}
                onToggle={this.handleModal('privateGroupClass')}
                privateGroupClass={this.state.privateGroupClass} />
          }

          { this.state.modals.privateSession &&
            <InstructorPrivateSessionModal
                deletePrivateSession={this.props.deletePrivateSession}
                deleteRecurringPrivateSession={this.props.deleteRecurringPrivateSession}
                isOpen={this.state.modals.privateSession}
                onToggle={this.handleModal('privateSession')}
                privateSession={this.state.privateSession} />
          }

        </div>
      </React.Fragment>
    );
  }
}

export default TodayEvents;
