import { ValidationActionTypes } from '../constants/actionTypes';

export default {
  RESET: (meta) => ({
    meta, type: ValidationActionTypes.RESET
  }),

  VALIDATE: (payload, meta) => ({
    meta, payload, type: ValidationActionTypes.VALIDATE
  }),

  VALIDATE_FAILURE: (payload, meta) => ({
    meta, payload, type: ValidationActionTypes.VALIDATE_FAILURE
  }),

  VALIDATE_SUCCESS: (meta) => ({
    meta, type: ValidationActionTypes.VALIDATE_SUCCESS
  })
};
