export default {
  FETCH:         '@@plans/FETCH',
  FETCH_FAILURE: '@@plans/FETCH_FAILURE',
  FETCH_SUCCESS: '@@plans/FETCH_SUCCESS',

  SUBSCRIPTION_CREATE:         '@@plans/SUBSCRIPTION/CREATE',
  SUBSCRIPTION_CREATE_FAILURE: '@@plans/SUBSCRIPTION/CREATE_FAILURE',
  SUBSCRIPTION_CREATE_SUCCESS: '@@plans/SUBSCRIPTION/CREATE_SUCCESS',

  SUBSCRIPTION_GET:         '@@plans/SUBSCRIPTION/GET',
  SUBSCRIPTION_GET_FAILURE: '@@plans/SUBSCRIPTION/GET_FAILURE',
  SUBSCRIPTION_GET_SUCCESS: '@@plans/SUBSCRIPTION/GET_SUCCESS'
};
