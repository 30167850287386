export default {
  CLAIM:         '@@instructor/CLAIM',
  CLAIM_FAILURE: '@@instructor/CLAIM_FAILURE',
  CLAIM_SUCCESS: '@@instructor/CLAIM_SUCCESS',

  DISCONNECT_INSTAGRAM: '@@profile/INSTAGRAM/DISCONNECT',

  FOLLOW:         '@@instructor/FOLLOW',
  FOLLOW_FAILURE: '@@instructor/FOLLOW_FAILURE',
  FOLLOW_SUCCESS: '@@instructor/FOLLOW_SUCCESS',

  GET:         '@@instructor/GET',
  GET_FAILURE: '@@instructor/GET_FAILURE',
  GET_SUCCESS: '@@instructor/GET_SUCCESS',

  UNFOLLOW:         '@@instructor/UNFOLLOW',
  UNFOLLOW_FAILURE: '@@instructor/UNFOLLOW_FAILURE',
  UNFOLLOW_SUCCESS: '@@instructor/UNFOLLOW_SUCCESS'
};
