import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Moment, routes } from '../../lib';
import { UserModalAvatar } from './layouts';

class UserChallengeModal extends React.PureComponent {
  static propTypes = {
    challenge: PropTypes.object.isRequired,
    isOpen:    PropTypes.bool,
    onToggle:  PropTypes.func,
    user:      PropTypes.object.isRequired
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  renderFooter() {
    const { instructor } = this.props.challenge;

    return (
      <ModalFooter className='text-center'>
        <small className='text-muted mt-3'>
          To book a class with your challenge&nbsp;
          <Link to={routes.INSTRUCTOR(instructor.username)}>
            click here
          </Link>
        </small>
      </ModalFooter>
    );
  }

  render() {
    const { challenge, user } = this.props;
    const { instructor } = challenge;
    const instructorChallenge = challenge.challenge;

    const isDateExpire = instructorChallenge.expireType == 'date';
    const expiryDate = new Moment.utc(instructorChallenge.expiryDate, 'YYYY-MM-DDhh:mm:ssaUTC')
                                 .tz(user.timeZone).format('DD, MMM, YYYY');

    return (
      <Modal
          className='modal-booking'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle} />

        <div className='new-modal'>
          <ModalBody>
            { instructor &&
              <div className='modal__header'>
                <UserModalAvatar
                    alt={instructor.firstName}
                    firstName={instructor.firstName}
                    src={instructor.photo} />
                <div className='modal__instructor-name'>
                  <span className='modal__big-text'>
                    {instructor.firstName} {instructor.lastName}
                  </span>
                </div>
              </div>
            }

            <div className='modal__event-name'>
              <span className='modal__big-text modal__big-text_black'>{instructorChallenge.name}</span>
            </div>

            <div className='modal-description'>
              <span className='modal__small-text'>About this package</span>

              <p className='modal__small-text modal__small-text_grey'>{instructorChallenge.description}</p>
            </div>

            <div className='modal__small-text modal__small-text_grey mb-2'>
              Expires: <u>{isDateExpire ? expiryDate : `${instructorChallenge.expiryDays} days after purchase`}</u>
            </div>

            <div>
              <span className='modal__small-text modal__small-text_grey'>
                { instructorChallenge.premium.onDemand && instructorChallenge.premium.live &&
                  'Valid with Live Stream and On-Demand classes'
                }

                { instructorChallenge.premium.onDemand && !instructorChallenge.premium.live &&
                  'Valid with On-Demand classes.'
                }

                { !instructorChallenge.premium.onDemand && instructorChallenge.premium.live  &&
                  'Valid with Live Stream classes.'
                }

                {instructorChallenge.challengeFor == 'privateSession' && 'Valid with Private Sessions.'}
              </span>
            </div>
          </ModalBody>

          {this.renderFooter()};
        </div>
      </Modal>
    );
  }
}

export default UserChallengeModal;
