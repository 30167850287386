import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Card, CardText, CardTitle } from 'reactstrap';

import { Button } from '../../../forms';
import { SubscriptionModal } from '../../../modals';

class Memberships extends React.PureComponent {
  static propTypes = {
    createDiscount:     PropTypes.func.isRequired,
    createSubscription: PropTypes.func.isRequired,
    discount:           PropTypes.object.isRequired,
    instructor:         PropTypes.object.isRequired,
    memberships:        PropTypes.array.isRequired,
    restoreDiscount:    PropTypes.func.isRequired,
    subscriptions:      PropTypes.array.isRequired,
    theme:              PropTypes.string,
    user:               PropTypes.object.isRequired
  }

  static defaultProps = {
    theme: 'default'
  }

  state = {
    isModalOpen: false,
    membership:  {}
  }

  handleToggle = (membership = {}) => () => (
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      membership
    })),
    this.props.discount && this.props.restoreDiscount()
  )

  renderMembership = (membership) => {
    if (!this.props.instructor.customization) return null;

    const { currency, customization } =  this.props.instructor;

    return (
      <Card
          body
          className={classNames('card-package', { 'elegant': this.props.theme === 'elegant'})}
          key={membership.id}>
        <div className='card-top'>
          <div className='card-info'>
            <CardTitle>{membership.name}</CardTitle>

            <CardText>
              { membership.trial &&
                <span className='d-flex trial'>
                  <span
                      aria-label='star'
                      className='mr-1'
                      role='img' >
                  ⭐
                  </span>
                Free trial for {membership.trialDays} days
                </span>
              }
            </CardText>
          </div>
          { this.props.theme === 'elegant' &&
            <div className='card-price'>
              <span className='price'>
                {membership.cost.toLocaleString('en-GB', { currency, style: 'currency' })}
              </span>
              per {membership.period}
            </div>
          }
        </div>

        <CardText className='mb-3'>
          {membership.description}
        </CardText>

        {this.props.theme === 'default' &&
          <div className='card-price'>
            <span className='price'>
              {membership.cost.toLocaleString('en-GB', { currency, style: 'currency' })}
            </span>
            per {membership.period}
          </div>
        }

        <Button
            customization={customization}
            onClick={this.handleToggle(membership)}>
          {membership.trial ? 'Free Trial' : 'Sign Up'}
        </Button>
      </Card>
    );
  }
  render () {
    return (
      <React.Fragment>
        <h4 className='page-title pb-2'>Memberships</h4>

        <div className='profile-packages'>
          {this.props.memberships.map(this.renderMembership)}
        </div>

        { this.state.isModalOpen &&
          <SubscriptionModal
              createDiscount={this.props.createDiscount}
              createSubscription={this.props.createSubscription}
              discount={this.props.discount}
              instructor={this.props.instructor}
              isOpen={this.state.isModalOpen}
              membership={this.state.membership}
              onToggle={this.handleToggle()}
              subscriptions={this.props.subscriptions}
              user={this.props.user} />
        }
      </React.Fragment>
    );
  }
}

export default withRouter(Memberships);
