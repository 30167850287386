import { RequestedClassesActionTypes } from '../constants/actionTypes';

const initialState = {
  instructorData: [],
  isLoading:      true,
  lastAction:     ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case RequestedClassesActionTypes.INSTRUCTORS_FETCH:
      return { ...newState, isLoading: true };

    case RequestedClassesActionTypes.INSTRUCTORS_FETCH_SUCCESS:
      return { ...newState, instructorData: action.payload, isLoading: false };

    case RequestedClassesActionTypes.INSTRUCTORS_FETCH_FAILURE:
      return { ...newState, instructorData: initialState.instructorData, isLoading: false };

    default: return state;
  }
};
