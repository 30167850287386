import { ReviewsActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: ReviewsActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: ReviewsActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: ReviewsActionTypes.CREATE_SUCCESS
  }),


  FETCH: (payload) => ({
    payload, type: ReviewsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: ReviewsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: ReviewsActionTypes.FETCH_SUCCESS
  })
};
