import React from 'react';
import PropTypes from 'prop-types';
import { Container, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';

import { Button } from '../forms';
import { BackButton } from '../buttons';
import { routes } from '../../lib';
import { InfoForm } from '../forms/profile/update';
import { Scroller, Validator } from '../../lib';
import { profileTabs } from '../../constants';
import { Heading, Panel } from '../layout/pages';

class EditProfilePage extends React.PureComponent {
  static propTypes = {
    activities:    PropTypes.object.isRequired,
    instructor:    PropTypes.object.isRequired,
    location:      PropTypes.object.isRequired,
    profileData:   PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired,
    user:          PropTypes.object.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const { instructor, user } = props;

    delete instructor.locations;

    const initialExperience = { company: '', delete: false, from: '', isCurrent: false, to: '' };
    const initialEducation =  { delete: false, name: '' };

    if (instructor.id && !state.isMounted) {
      return {
        instructor: {
          ...instructor,
          educations:  [...instructor.educations, initialEducation],
          experiences: [...instructor.experiences, initialExperience],
          mobilePhoto: '',
          photo:       ''
        },
        integrations: user.oauth,
        isMounted:    true
      };
    }

    return {
      ...state,
      integrations: user.oauth
    };
  }

  state = {
    activeTab:    profileTabs.BIO,
    errors:       {},
    instructor:   {},
    integrations: {},
    isMounted:    false
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      instructor: {
        ...prevState.instructor,
        [inputName]: value
      }
    }));
  }

  handleChangeTab = (tab) => {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
  }

  handleSubmit = () => (
    this.handleValidate().then(() => (
      this.setState((prevState) => ({
        ...prevState,
        errors: {}
      }), () => {
        this.props.updateProfile(this.state.instructor);
      })
    ))
  )

  handleValidate = () => new Promise((resolve, reject) => {
    const { instructor } = this.state;

    const errors = Validator.validate([
      ['username',        Validator.concepts.isReserved,           [instructor.username]],
      ['username',        Validator.concepts.usernameRegex,        [instructor.username]],
      ['username',        Validator.concepts.isFilled,             [instructor.username]],
      ['username',        Validator.concepts.isLongerThan,         [3, instructor.username, 'Username']],
      ['firstName',       Validator.concepts.isFilled,             [instructor.firstName]],
      ['lastName',        Validator.concepts.isFilled,             [instructor.lastName]],
      ['tagline',         Validator.concepts.isFilled,             [instructor.tagline]],
      ['workExperiences', Validator.concepts.experiencesValidator, [instructor.experiences]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  })

  componentDidUpdate(prevProps) {
    const initialExperience = { company: '', delete: false, from: '', isCurrent: false, to: '' };
    const initialEducation =  { delete: false, name: '' };
    const instructor = {
      ...this.props.instructor,
      educations:  [...this.props.instructor.educations, initialEducation],
      experiences: [...this.props.instructor.experiences, initialExperience]
    };

    if (this.props.instructor !== prevProps.instructor) {
      this.setState((prevState) => ({
        ...prevState,
        instructor: instructor
      }));
    }
  }

  render() {
    const { instructor } = this.state;

    return (
      <div className='inner-wrapper portal profile-form'>
        {isMobile && <BackButton to={routes.SETTINGS_HOME} />}

        <Helmet>
          <title>Edit your profile</title>
        </Helmet>

        <Container fluid>
          <Heading
              color='secondary'
              subtitle='Creator Portal'
              title='Edit instructor profile' />
          <Panel className='settings-menu'>
            <Media className='menu-link d-block'>

              <InfoForm
                  activities={this.props.activities}
                  errors={this.state.errors}
                  isEditable
                  location={this.props.location}
                  onChange={this.handleChange}
                  onUpdateProfile={this.handleSubmit}
                  profile={instructor}
                  profileData={this.props.profileData} />
              <Button
                  className='save-changes-btn mt-4'
                  color='success'
                  isBlock={isMobile}
                  onClick={this.handleSubmit}
                  onKeyPress={this.handleSubmit}>
                Update
              </Button>
            </Media>
          </Panel>
        </Container>
      </div>
    );
  }
}

export default EditProfilePage;
