import React from 'react';
import PropTypes from 'prop-types';
import { Media } from 'reactstrap';
import { isMobile } from 'react-device-detect';

import { UserAvatar } from '../../../users';

class Review extends React.PureComponent {
  static propTypes = {
    review: PropTypes.object.isRequired
  }

  render() {
    const { review } = this.props;

    return (
      <Media className='mb-5'>
        <Media left>
          <UserAvatar
              alt={review.author.fullName}
              className='mr-4'
              size={isMobile ? 'md' : 'lg'}
              src={review.author.photo} />
        </Media>
        <Media body>
          <Media
              className='mt-1'
              heading>
            <span className='comment-author'>{review.author.fullName}</span>
            <span className='comment-date ml-3'>
              {review.createdAt}
            </span>
          </Media>
          <div className='comment-body'>
            <p className='mb-2'>{review.body}</p>
          </div>
        </Media>
      </Media>
    );
  }
}

export default Review;