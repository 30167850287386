import React from 'react';
import PropTypes from 'prop-types';
import { slice } from 'lodash';
import { Col, Row } from 'reactstrap';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';

import { SearchMapContainer } from '../../containers';
import { SearchActionTypes } from '../../constants/actionTypes';
import { Filters, Paginator, Results } from './search';

class SearchPage extends React.PureComponent {
  static propTypes = {
    activities:    PropTypes.object.isRequired,
    instructors:   PropTypes.object.isRequired,
    locations:     PropTypes.object.isRequired,
    performFilter: PropTypes.func.isRequired,
    profileData:   PropTypes.object.isRequired,
    search:        PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const subject = props.instructors.meta.subject || '';

    if (props.search.lastAction === SearchActionTypes.SEARCH) {
      return {
        filter: {
          activityIds: [],
          direction:   'desc',
          locationIds: [],
          page:        1,
          selectedIds: [],
          sortBy:      'follows_count'
        },

        subject
      };
    }

    return {
      filter: {
        ...state.filter,
        selectedIds: props['instructors'] && props['instructors'].meta.totalIds
      },

      subject
    };
  }

  state = {
    /* TODO: move filters to reducer */
    filter: {
      activityIds:    [],
      direction:      'desc',
      locationIds:    '',
      page:           1,
      scrollPosition: 0,
      selectedIds:    [],
      sortBy:         'follows_count'
    },

    /* IN PLURAL */
    subject: ''
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        [inputName]: value
      }
    }), () => {
      const model = slice(this.state.subject, 0, this.state.subject.length - 1).join('');
      this.props.performFilter({ ...this.state.filter, model });
    });
  }

  handleSearchChange = (state) => {
    this.setState((prevState) => ({
      filter: {
        ...prevState.filter,
        ...state
      }
    }), () => {
      const model = slice(this.state.subject, 0, this.state.subject.length - 1).join('');
      this.props.performFilter({ ...this.state.filter, model });
    });
  }

  render() {
    const totalPages = this.props.instructors.meta.totalPages;
    const resultsContainerClass = classNames('results-container', { 'with-paginator': totalPages > 1 });
    const searchLocation = this.props.search.data.search.location;

    return (
      <div className='inner-wrapper search'>
        <Helmet>
          <title>{'Discover ' + this.state.subject + ' ' + (searchLocation && 'in ' + searchLocation)}</title>
        </Helmet>

        <Row noGutters>
          <Col
              className={resultsContainerClass}
              lg={6}
              md={12}>
            <Filters
                activities={this.props.activities}
                filter={this.state.filter}
                onChange={this.handleChange}
                performFilter={this.props.performFilter}
                profileData={this.props.profileData}
                search={this.props.search}
                subject={this.state.subject} />
            <div className='results'>
              <Results
                  currentPage={this.state.filter.page}
                  instructors={this.props.instructors}
                  locations={this.props.locations}
                  onChange={this.handleSearchChange}
                  subject={this.state.subject} />
            </div>
            <Paginator
                currentPage={this.state.filter.page}
                onChange={this.handleChange}
                totalPages={totalPages} />
          </Col>
          <Col
              className='map-side-lg px-lg-0'
              lg={6}
              md={12}>
            <SearchMapContainer />
          </Col>
        </Row>
      </div>
    );
  }
}

export default SearchPage;
