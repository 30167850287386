export default {
  CREATE:         '@@membershipsCoupons/CREATE',
  CREATE_FAILURE: '@@membershipsCoupons/CREATE_FAILURE',
  CREATE_SUCCESS: '@@membershipsCoupons/CREATE_SUCCESS',

  DELETE:         '@@membershipsCoupons/DELETE',
  DELETE_FAILURE: '@@membershipsCoupons/DELETE_FAILURE',
  DELETE_SUCCESS: '@@membershipsCoupons/DELETE_SUCCESS',

  FETCH:         '@@membershipsCoupons/FETCH',
  FETCH_FAILURE: '@@membershipsCoupons/FETCH_FAILURE',
  FETCH_SUCCESS: '@@membershipsCoupons/FETCH_SUCCESS',

  GET:         '@@membershipsCoupons/GET',
  GET_FAILURE: '@@membershipsCoupons/GET_FAILURE',
  GET_SUCCESS: '@@membershipsCoupons/GET_SUCCESS',

  UPDATE:         '@@membershipsCoupons/UPDATE',
  UPDATE_FAILURE: '@@membershipsCoupons/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@membershipsCoupons/UPDATE_SUCCESS'
};
