import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../forms';

class UserCancelMembership extends React.PureComponent {
  static propTypes = {
    deleteSubscription: PropTypes.func.isRequired,
    onClose:            PropTypes.func.isRequired,
    onSuccess:          PropTypes.func.isRequired,
    subscription:       PropTypes.object.isRequired
  };

  handleKeep = () => {
    this.props.onClose();
  };

  handleCancel = () => {
    const { subscription } = this.props;

    this.props.deleteSubscription({ subscription });
    this.props.onClose();
    this.props.onSuccess();
  };

  render() {
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            <span>
              Are you sure you want to <span className='text-danger'>cancel</span> your<br />
              <span className='text-primary'> {this.props.subscription.membership.name} membership?</span>
            </span>
          </CardText>

          <div className='actions'>
            <Button
                color='muted'
                onClick={this.handleCancel}>
              Yes-Cancel
            </Button>

            <Button
                color='primary'
                onClick={this.handleKeep}>
              No-Keep
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default UserCancelMembership;
