import { MembershipsDiscountActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       {},
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case MembershipsDiscountActionTypes.CREATE:
      return newState;

    case MembershipsDiscountActionTypes.CREATE_SUCCESS:
      return { ...newState, data: action.payload };

    case MembershipsDiscountActionTypes.CREATE_FAILURE:
      return newState;

  case MembershipsDiscountActionTypes.RESTORE:
    return initialState;

    default: return state;
  }
};
