import { ChargesActionTypes } from '../constants/actionTypes';

export default {
  FETCH: () => ({
    type: ChargesActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: ChargesActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: ChargesActionTypes.FETCH_SUCCESS
  })
};
