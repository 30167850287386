import React from 'react';
import PropTypes from 'prop-types';
import CreatableSelect from 'react-select/creatable';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { singularize } from '../../lib';

class Creatable extends React.PureComponent {
  static propTypes = {
    autocompleter:  PropTypes.object,
    children:       PropTypes.node,
    className:      PropTypes.string,
    components:     PropTypes.object,
    doAutocomplete: PropTypes.func,
    isInvalid:      PropTypes.bool,
    isMulti:        PropTypes.bool,
    name:           PropTypes.string,
    onCreate:       PropTypes.func,
    onSelect:       PropTypes.func,
    placeholder:    PropTypes.string,
    style:          PropTypes.object,
    subject:        PropTypes.string.isRequired,
    value:          PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
  }

  static defaultProps = {
    autocompleter:  {},
    children:       null,
    className:      '',
    components:     {},
    doAutocomplete: () => {},
    isInvalid:      false,
    isMulti:        false,
    name:           '',
    onCreate:       () => {},
    onSelect:       () => {},
    placeholder:    'Select...',
    style:          {}
  }

  static getDerivedStateFromProps(props, state) {
    const options = props.autocompleter.data[props.subject] || [];
    const subject = singularize(props.subject);

    return {
      options: options.map((option) => ({
        label: option.name, value: option.id, ...option
      })),

      params: {
        ...state.params,
        subject
      }
    };
  }

  state = {
    options: [],

    params: {
      query:   '',
      subject: ''
    }
  }

  doAutocomplete = debounce(this.props.doAutocomplete, 500);

  handleSelect = (options, meta) => {
    if (meta.action == 'remove-value')  this.props.onSelect(options || [], this.props.name);
    if (meta.action == 'create-option') this.props.onCreate(options, this.props.name);
    if (meta.action == 'select-option') this.props.onSelect(options, this.props.name);
  }

  handleChange = (query, meta) => {
    if (meta.action == 'input-change') {
      this.setState((prevState) => ({
        params: {
          ...prevState.params,
          query
        }
      }), () => {
        this.doAutocomplete(this.state.params);
      });
    }
  }

  render() {
    return (
      <div className={classNames('custom-input-group', { 'is-invalid': this.props.isInvalid })}>
        <CreatableSelect
            className={'autocompleter ' + this.props.className}
            components={this.props.components}
            isMulti={this.props.isMulti}
            name={this.props.name}
            noOptionsMessage={() => 'Start typing...'}
            onChange={this.handleSelect}
            onInputChange={this.handleChange}
            options={this.state.options}
            placeholder={this.props.placeholder}
            style={this.props.style}
            value={this.props.value} />
        {this.props.children}
      </div>
    );
  }
}

export default Creatable;
