import PropTypes from 'prop-types';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { ButtonGroup, ButtonToolbar } from 'reactstrap';
import { compact, isEmpty } from 'lodash';

import { FollowButton } from '../../../../components/buttons';
import { InstructorName } from '../../../../components/instructor';
import { Button } from '../../../../components/forms';
import { RequestedClassesCarouselContainer, UpcomingClassesContainer } from '../../../../containers';
import { PrivateSessionsCarousel } from '../../../../components/pages/profile';

const SCHEDULES = {
  groupClasses:     'GROUP_CLASSES',
  privateSessions:  'PRIVATE_SESSIONS',
  requestedClasses: 'REQUESTED_CLASSES'
};

class WorkoutDefault extends React.PureComponent {
  static propTypes = {
    availability:         PropTypes.object.isRequired,
    createDiscount:       PropTypes.func.isRequired,
    createPrivateSession: PropTypes.func.isRequired,
    discount:             PropTypes.object,
    follow:               PropTypes.func.isRequired,
    getAvailability:      PropTypes.func,
    instructor:           PropTypes.object.isRequired,
    subtractCredit:       PropTypes.func.isRequired,
    unFollow:             PropTypes.func.isRequired,
    user:                 PropTypes.object.isRequired,
    userClassPacks:       PropTypes.array.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const { instructor } = props;
    const { groupClassesEnabled, requestedClassesEnabled, privateSessionsEnabled } = instructor;

    const toggleArray = compact([groupClassesEnabled, privateSessionsEnabled, requestedClassesEnabled]);

    const checkRequestedClasses = requestedClassesEnabled ? SCHEDULES.requestedClasses : SCHEDULES.privateSessions;

    if (!state.isMounted && !isEmpty(instructor)) {
      return {
        selectedCarousel: instructor.groupClassesEnabled ? SCHEDULES.groupClasses : checkRequestedClasses,
        toggleArray
      };
    }

    return state;
  }

  static defaultProps = {
    discount:        {},
    getAvailability: () => {}
  };

  state = {
    isMounted:        false,
    selectedCarousel: '',
    toggleArray:      []
  }

  handleFollow = () => {
    this.props.instructor.isFollowing ? this.props.unFollow() : this.props.follow();
  };

  renderCarousel = () => {
    switch (this.state.selectedCarousel) {
      case SCHEDULES.groupClasses:
        return <UpcomingClassesContainer />;

      case SCHEDULES.requestedClasses:
        return <RequestedClassesCarouselContainer />;

      case SCHEDULES.privateSessions:
        return (
          <PrivateSessionsCarousel
              availability={this.props.availability}
              classPacks={this.props.userClassPacks}
              createDiscount={this.props.createDiscount}
              createPrivateSession={this.props.createPrivateSession}
              discount={this.props.discount}
              getAvailability={this.props.getAvailability}
              instructor={this.props.instructor}
              subtractCredit={this.props.subtractCredit}
              user={this.props.user} />
        );

      default:
        return null;
    }
  };

  handleToggle = (carousel) => () => {
    this.setState({ isMounted: true, selectedCarousel: carousel });
  };

  render() {
    const { selectedCarousel } = this.state;
    const { instructor } = this.props;

    return (
      <div className='workout'>
        <div className='workout__header'>
          <InstructorName
              instructor={instructor}
              primary
              subtitle='Workout' />
          <FollowButton
              followersCount={instructor.followersCount}
              isDisabled={!!instructor.isOwner}
              isFollowing={!!instructor.isFollowing}
              onClick={this.handleFollow} />
        </div>
        <span className='workout__reason'>{instructor.workoutReason}</span>
        <div className='workout__body'>
          { this.state.toggleArray.length > 1 &&
            <ButtonToolbar>
              <ButtonGroup
                  className='workout__buttons'
                  size={isMobile ? 'sm' : 'lg'}>
                { instructor.groupClassesEnabled &&
                  <Button
                      className='px-lg-4'
                      color={selectedCarousel !== SCHEDULES.groupClasses ? 'secondary' : 'primary'}
                      customization={instructor.customization}
                      onClick={this.handleToggle(SCHEDULES.groupClasses)}>
                    Live Classes
                  </Button>
                }

                { instructor.requestedClassesEnabled &&
                  <Button
                      className='px-lg-4'
                      color={selectedCarousel !== SCHEDULES.requestedClasses ? 'secondary' : 'primary'}
                      customization={instructor.customization}
                      onClick={this.handleToggle(SCHEDULES.requestedClasses)}>
                    On Demand
                  </Button>
                }

                { instructor.privateSessionsEnabled &&
                  <Button
                      className='px-lg-4'
                      color={selectedCarousel !== SCHEDULES.privateSessions ? 'secondary' : 'primary'}
                      customization={instructor.customization}
                      onClick={this.handleToggle(SCHEDULES.privateSessions)}>
                    Private Session
                  </Button>
                }
              </ButtonGroup>
            </ButtonToolbar>
          }
        </div>

        { this.state.toggleArray.length >= 1 && this.renderCarousel() }
      </div>
    );
  }
}

export default WorkoutDefault;
