import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { Badge, ButtonGroup, ButtonToolbar, Col, Container } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import { routes } from '../../../lib';
import { Button } from '../../forms';
import { ConfirmationModal } from '../../modals';
import { UpcomingClassesContainer } from '../../../containers';
import { Memberships } from './instructor';
import { FollowButton, Photo, PrivateSessionsCarousel, Tagline } from './';

const SCHEDULES = {
  groupClasses:    'GROUP_CLASSES',
  memberships:     'MEMBERSHIPS',
  privateSessions: 'PRIVATE_SESSIONS'
};

class Header extends React.PureComponent {
  static propTypes = {
    availability:         PropTypes.object.isRequired,
    claimInstructor:      PropTypes.func,
    createPrivateSession: PropTypes.func.isRequired,
    createSubscription:   PropTypes.func.isRequired,
    follow:               PropTypes.func.isRequired,
    getAvailability:      PropTypes.func.isRequired,
    goTo:                 PropTypes.func.isRequired,
    hasProfile:           PropTypes.bool,
    instructor:           PropTypes.object,
    location:             PropTypes.object,
    memberships:          PropTypes.array.isRequired,
    session:              PropTypes.object.isRequired,
    type:                 PropTypes.string.isRequired,
    unFollow:             PropTypes.func.isRequired,
    user:                 PropTypes.object.isRequired
  };

  static defaultProps = {
    claimInstructor: () => {},
    hasProfile:      false,
    instructor:      {},
    location:        {},
    onPrivateClick:  () => {}
  };

  state = {
    isModalOpen:      false,
    selectedCarousel: SCHEDULES.groupClasses
  };

  handleClaim = () => {
    this.props.claimInstructor(this.props.instructor);
  };

  handleFollow = (object) => () => {
    if (object.isFollowing) {
      this.props.unFollow();
    } else {
      this.props.follow();
    }
  };

  handleModal = () => {
    if (this.props.session.authenticated) {
      this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
    } else {
      this.props.goTo(routes.LOGIN);
    }
  }

  handleToggle = (carousel) => () => {
    this.setState({ selectedCarousel: carousel });
  };

  renderActivities = (object) => (
    object.activities.map((activity) => (
      <Badge
          className='badge-activity mr-2 mb-2'
          color='light'
          key={activity.id}
          pill>
        {activity.title}
      </Badge>
    ))
  )

  renderCarousel = () => {
    switch (this.state.selectedCarousel) {
      case SCHEDULES.groupClasses:
        return <UpcomingClassesContainer hasLocation={this.props.type === 'location'} />;

      case SCHEDULES.memberships:
        return (
          <Memberships
              createSubscription={this.props.createSubscription}
              instructor={this.props.instructor}
              memberships={this.props.memberships}
              user={this.props.user.data} />
        );

      case SCHEDULES.privateSessions:
        return (
          <PrivateSessionsCarousel
              availability={this.props.availability}
              createPrivateSession={this.props.createPrivateSession}
              getAvailability={this.props.getAvailability}
              instructor={this.props.instructor}
              user={this.props.user.data} />
        );

      default:
        return null;
    }
  };

  renderName = () => {
    const { location, instructor } = this.props;
    const object = this.props[this.props.type];
    const name = location.name || [instructor.firstName, instructor.lastName].join(' ');

    return (
      <h1 className={classNames('profile-name', this.props.type)}>
        <span>{name}</span>

        <FollowButton
            followersCount={object.followersCount}
            isDisabled={!!object.isOwner}
            isFollowing={!!object.isFollowing}
            onClick={this.handleFollow(object)} />
      </h1>
    );
  };

  renderLocation = () => {
    const object = this.props[this.props.type];

    return (
      <p className={classNames('profile-location', this.props.type)}>
        <Icon
            className='mr-2'
            icon={['fas', 'map-marker-alt']} />
        <span>{ object.fullAddress || [object.location.city, object.location.state].join(', ')}</span>
      </p>
    );
  };

  render() {
    const { type, instructor, location } = this.props;
    const { selectedCarousel } = this.state;
    const object = this.props[this.props.type];

    return (
      <React.Fragment>
        <Container fluid>
          <section className='profile-header no-gutters'>
            <Col
                className='profile-info'
                lg={6}
                md={12}
                xl={5}>
              { type === 'instructor' &&
                <span className='hint'>
                  Follow my profile to add my class<br />schedule to your planner
                </span>
              }

              { type === 'location' &&
                <span className='hint'>
                  Follow this profile to add the class<br />schedule to your fitness planner
                </span>
              }

              {this.renderName()}

              {this.renderLocation()}

              <div className='profile-activities'>
                {object.activities && this.renderActivities(object)}
              </div>

              <div className='profile-actions'>
                <div className='actions-switch classes'>
                  <span>Reserve a spot!</span>

                  <ButtonToolbar>
                    <ButtonGroup size={isMobile ? '' : 'lg'}>
                      <Button
                          className='px-lg-4'
                          color='primary'
                          isOutline={selectedCarousel !== SCHEDULES.groupClasses}
                          onClick={this.handleToggle(SCHEDULES.groupClasses)}>
                        Group Class
                      </Button>
                      <Button
                          className='px-lg-4'
                          color='primary'
                          isDisabled={!object.privateSessionsEnabled}
                          isOutline={selectedCarousel !== SCHEDULES.privateSessions}
                          onClick={this.handleToggle(SCHEDULES.privateSessions)}>
                        Private Session
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
                {object.membershipsEnabled && !!this.props.memberships.length &&
                  <React.Fragment>
                    <div className='divider' />

                    <div className='actions-switch memberships'>
                      <span>Join me!</span>

                      <Button
                          className='px-4'
                          color='secondary'
                          isDisabled={!object.membershipsEnabled}
                          isOutline={selectedCarousel !== SCHEDULES.memberships}
                          onClick={this.handleToggle(SCHEDULES.memberships)}
                          size={isMobile ? '' : 'lg'}>
                        Memberships
                      </Button>
                    </div>
                  </React.Fragment>
                }
              </div>

              {this.renderCarousel()}

              { isMobile && object.membershipsEnabled &&
                <div className='memberships-mobile'>
                  <span>Join me!</span>
                  <Memberships
                      createSubscription={this.props.createSubscription}
                      instructor={this.props.instructor}
                      memberships={this.props.memberships}
                      user={this.props.user.data} />
                </div>
              }
            </Col>
            <Col
                className='profile-photo'
                lg={6}
                md={12}
                xl={7}>
              <Photo src={object.photo ? object.photo.lg : ''} />
              { type === 'instructor' && !object.claimed && !this.props.hasProfile &&
                <Button
                    className='claim-profile m-3 float-right'
                    color='danger'
                    onClick={this.handleModal}
                    size='sm'>
                  Claim Profile
                </Button>
              }
              <Tagline
                  className={this.props.type}
                  line={instructor.tagline || location.bio} />
            </Col>
          </section>
        </Container>

        <div className='modals'>
          <ConfirmationModal
              isOpen={this.state.isModalOpen}
              onConfirm={this.handleClaim}
              onToggle={this.handleModal}>
            <p>
              <strong>
                By claiming this page you are representing that you are the rightful owner of this
                profile page.
              </strong>
            </p>
            <p>We will review all claimed profiles. If we believe the profile has been incorrectly claimed we will
              contact the user immediately and reverse the page claim.</p>
          </ConfirmationModal>
        </div>
      </React.Fragment>
    );
  }
}

export default Header;
