import { ProfileDataActionTypes } from '../constants/actionTypes';

const initialState = {
  data: []
};

export default (state = initialState, action) => {
  const newState = {
    ...state
  };

  switch (action.type) {
    case ProfileDataActionTypes.GET:
      return newState;

    case ProfileDataActionTypes.GET_SUCCESS:
      return { ...newState, data: action.payload };

    case ProfileDataActionTypes.GET_FAILURE:
      return newState;

    default: return state;
  }
};
