/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { drag } from 'react-icons-kit/ionicons/drag';

const DragIcon = (props) => {
  const { className, size, dragProps } = props;
  return (
    <Icon
        className={className}
        {...dragProps}
        icon={drag}
        size={size} />
  );
};

DragIcon.propTypes = {
  className: PropTypes.string,
  dragProps: PropTypes.object,
  size:      PropTypes.number
};

DragIcon.defaultProps = {
  className: '',
  dragProps: {},
  size:      32
};

export default DragIcon;
