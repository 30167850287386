import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../../forms';

class DeletePrivateSessionAlert extends React.PureComponent {
  static propTypes = {
    deletePrivateSession:          PropTypes.func.isRequired,
    deleteRecurringPrivateSession: PropTypes.func.isRequired,
    onClose:                       PropTypes.func.isRequired,
    onSuccess:                     PropTypes.func.isRequired,
    privateSession:                PropTypes.object.isRequired
  }

  handleDeleteAll = () => {
    const { privateSession } = this.props;

    this.props.deleteRecurringPrivateSession({ privateSession });
    this.props.onClose();
    this.props.onSuccess();
  }

  handleDeleteThis = () => {
    const { privateSession } = this.props;

    this.props.deletePrivateSession({ privateSession });
    this.props.onClose();
    this.props.onSuccess();
  }

  render() {
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            <span>
              This is part of a recurring event series, would you like to  <span className='text-danger'>delete
              </span>&nbsp;just <span className='text-danger'>this instance</span> of the event or&nbsp;
              <span className='text-danger'>this instance and all future</span> events in the series?
            </span>
          </CardText>

          <div className='actions'>
            <Button
                color='primary'
                onClick={this.handleDeleteThis}>
              This Event
            </Button>

            <Button
                color='danger'
                onClick={this.handleDeleteAll}>
              This & Future Events
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default DeletePrivateSessionAlert;
