import { SynchronizationActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: SynchronizationActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: SynchronizationActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: SynchronizationActionTypes.CREATE_SUCCESS
  }),

  FETCH: () => ({
    type: SynchronizationActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: SynchronizationActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: SynchronizationActionTypes.FETCH_SUCCESS
  })
};