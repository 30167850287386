import { InstructorActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       {},
  isFetched:  false,
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case InstructorActionTypes.DISCONNECT_INSTAGRAM:
      return { ...newState, data: { ...state.data, oauth: { ...state.data.oauth, instagram: false } } };


    case InstructorActionTypes.GET:
      return { ...newState, data: initialState.data, isFetched: false };

    case InstructorActionTypes.GET_SUCCESS:
      return { ...newState, data: action.payload, isFetched: true };

    case InstructorActionTypes.GET_FAILURE:
      return { ...newState, data: initialState.data, isFetched: true };


    case InstructorActionTypes.FOLLOW:
      return newState;

    case InstructorActionTypes.FOLLOW_SUCCESS:
      return { ...newState, data: {
        ...state.data,
        followersCount: state.data.followersCount + 1,
        isFollowing:    !state.data.isFollowing
      } };

    case InstructorActionTypes.FOLLOW_FAILURE:
      return newState;


    case InstructorActionTypes.UNFOLLOW:
      return newState;

    case InstructorActionTypes.UNFOLLOW_SUCCESS:
      return { ...newState, data: {
        ...state.data,
        followersCount: state.data.followersCount - 1,
        isFollowing:    !state.data.isFollowing
      } };

    case InstructorActionTypes.UNFOLLOW_FAILURE:
      return newState;


    default: return state;
  }
};
