import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { UserActionTypes } from '../constants/actionTypes';

import {
  ErrorActions,
  LoaderActions,
  UserActions
} from '../actions';

function* watchFetch() {
  yield takeLatest(UserActionTypes.FETCH, function* () {
    try {
      const response = yield call(api.session.get);

      yield put(UserActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(UserActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdate() {
  yield takeLatest(UserActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.user.update, payload);

      yield put(UserActions.UPDATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Account updated');
    } catch (error) {
      yield put(UserActions.UPDATE_FAILURE());

      /* error */
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(UserActionTypes.UPDATE_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateFailure() {
  yield takeLatest(UserActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* userSaga() {
  yield all([
    watchFetch(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure()
  ]);
}
