import { connect } from 'react-redux';

import { PlansActions } from '../actions';
import { SubscriptionPage } from '../components/pages';

const mapStateToProps = (state) => ({
  subscription: state.plans.userData
});

const mapDispatchToProps = (dispatch) => ({
  createSubscription: (data) => dispatch(PlansActions.SUBSCRIPTION_CREATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
