import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SessionActions } from '../actions';

export default function (ComposedComponent) {
  class Authenticate extends React.Component {
    static propTypes = {
      history:        PropTypes.object.isRequired,
      restoreSession: PropTypes.func.isRequired,
      session:        PropTypes.object.isRequired
    };

    static getDerivedStateFromProps(props) {
      return {
        shouldRestore: !props.session.restored
      };
    }

    state = {
      shouldRestore: false
    }

    componentDidMount() {
      if (this.state.shouldRestore) this.props.restoreSession();
    }

    render() {
      // If user isn't authenticated he will be redirected via requiresAuth
      return <ComposedComponent {...this.props} />;
    }
  }

  const mapStateToProps = (state) => ({
    session: state.session
  });

  const mapDispatchToProps = (dispatch) => ({
    restoreSession: () => dispatch(SessionActions.RESTORE_SESSION())
  });

  return connect(mapStateToProps, mapDispatchToProps)(Authenticate);
}
