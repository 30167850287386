import React from 'react';
import PropTypes from 'prop-types';
import { alertCircle } from 'react-icons-kit/feather/alertCircle';
import { basic_elaboration_cloud_upload } from 'react-icons-kit/linea/basic_elaboration_cloud_upload';
import filesizeParser from 'filesize-parser';
import { filter, includes } from 'lodash';
import { FormFeedback, Label } from 'reactstrap';
import { isEmpty } from 'lodash';

import { Loader } from '../loader';
import { Uploader } from '../../lib';

class Browser extends React.PureComponent {
  static propTypes = {
    error:       PropTypes.string,
    fileTypes:   PropTypes.array,
    isInvalid:   PropTypes.bool,
    isMultiple:  PropTypes.bool,
    maxFileSize: PropTypes.string,
    name:        PropTypes.string,
    onChange:    PropTypes.func,
    placeholder: PropTypes.string
  }

  static defaultProps = {
    error:       '',
    fileTypes:   ['image/jpeg'],
    isInvalid:   false,
    isMultiple:  false,
    maxFileSize: '5MB',
    name:        '',
    onChange:    () => {},
    placeholder: null,
    size:        'md'
  }

  static getDerivedStateFromProps(props) {
    return {
      isMultiple:       props.isMultiple,
      maxFileSizeBytes: filesizeParser(props.maxFileSize)
    };
  }

  dropRef = React.createRef();
  inputRef = React.createRef();

  state = {
    error:            '',
    isHovering:       false,
    isInvalid:        false,
    isMultiple:       false,
    maxFileSizeBytes: 0,
    uploads:          []
  }

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!this.props.isMultiple) this.handleClearUploads();

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      this.state.isInvalid ? null : this.handleSelectFiles(e.dataTransfer.files);
    }
  }

  handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ isInvalid: false }, () => {
        this.handleValidateFileType(e.dataTransfer.items[0]);
      });
    }
  }

  handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isHovering: false, isInvalid: false });
  }

  handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  handleClick = () => {
    this.inputRef.current.click();
  }

  handleInputChange = (e) => {
    this.handleClearUploads();

    if (e.target.files.length > 0) {
      this.handleSelectFiles(e.target.files);
    }
  }

  handleUploaderCallback = (fileUpload) => {
    this.setState({ uploads: [fileUpload] }, () => {
      if (fileUpload.state === 'finished') {
        this.props.onChange(fileUpload.signedId, this.props.name);
      }
    });
  }

  handleClearUploads = () => {
    this.setState({ uploads: [] });
  }

  handleSelectFiles = (files) => {
    if (this.handleValidateFileType(files[0])) {
      Array.from(files).forEach((file) => {
        const upload = new Uploader(file, { onChangeFile: this.handleUploaderCallback });

        this.setState((prevState) => ({
          uploads: [...prevState.uploads, upload]
        }), () => {
          this.handleValidateSize(upload);
        });
      });
    }
  }

  handleValidateSize = (file) => {
    const { maxFileSizeBytes } = this.state;

    if (file.size > maxFileSizeBytes) {
      this.setState({
        error:     'Image is too big!',
        isInvalid: true
      });
    } else {
      this.setState({
        isHovering: false,
        isInvalid:  false
      }, this.handleStartUploads);
    }
  }

  handleValidateFileType = (dataTransferItem) => {
    const { fileTypes } = this.props;

    const isValid = includes(fileTypes, dataTransferItem.type);

    if (isValid) {
      this.setState({ isHovering: true });
    } else {
      this.setState({
        error:     'Invalid file type!',
        isInvalid: true
      });
    }

    return isValid;
  }

  handleStartUploads = () => {
    const uploads = filter(this.state.uploads, 'start');

    uploads.map((upload) => {
      this.handleUpload(upload);
    });
  }

  handleUpload = async (file) => {
    await file.start();
  }

  renderIcon = () => {
    const { isInvalid } = this.state;

    switch (true) {
      case isInvalid:
        return alertCircle;

      default:
        return basic_elaboration_cloud_upload;
    }
  }

  renderMessage = () => {
    const { isInvalid, isHovering, error } = this.state;

    switch (true) {
      case isHovering:
        return 'Drop file right here!';

      case isInvalid:
        return error;

      default:
        return 'Drop A Photo';
    }
  }

  componentDidMount() {
    const ref = this.dropRef.current;

    ref.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    const ref = this.dropRef.current;

    ref.removeEventListener('click', this.handleClick);
  }

  componentDidUpdate (prevProps) {
    if (this.props.isInvalid !== prevProps.isInvalid) {
      this.setState({
        error:     this.props.error,
        isInvalid: this.props.isInvalid
      });
    }
  }

  render () {
    const { name } = this.props;
    const { isMultiple, uploads } = this.state;

    const isLoading = 'waiting' || 'uploading';
    return (

      <div
          className='custom-file'
          ref={this.dropRef}>
        <input
            id={name}
            multiple={isMultiple}
            name={name}
            onChange={this.handleInputChange}
            ref={this.inputRef}
            type='file' />
        { this.props.error &&
          <FormFeedback tooltip>{this.props.error}</FormFeedback>
        }
        <Label className='custom-file-label'>
          {!isEmpty(uploads) ? uploads[0].name : (this.props.placeholder || 'Select your account photo')}
        </Label>

        {!isEmpty(uploads) && uploads[0].state === isLoading &&
          <Loader
              isFullscreen
              isLoading />
        }
      </div>
    );
  }
}

export default Browser;
