import { all, call, put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { DiscountActionTypes } from '../constants/actionTypes';
import { DiscountActions, ErrorActions } from '../actions';

function* watchCreate() {
  yield takeLatest(DiscountActionTypes.CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.discount.create, payload);

      yield put(DiscountActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Coupon applied');
    } catch (error) {
      yield put(DiscountActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}


export default function* discountSaga() {
  yield all([
    watchCreate()
  ]);
}
