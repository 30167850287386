import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../forms';

class CancelSubscription extends React.PureComponent {
  static propTypes = {
    cancelSubscription: PropTypes.func.isRequired,
    onClose:            PropTypes.func.isRequired,
    subscription:       PropTypes.object.isRequired
  };

  handleReset = () => {
    const { subscription } =  this.props;

    this.props.onClose();
    this.props.cancelSubscription({ subscription });
  };

  render() {
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            <span>
              The membership will be permanently<br />
              canceled and removed from the member.<br />
              Are you sure?
            </span>
          </CardText>

          <div className='text-center'>
            <Button
                color='primary'
                isOutline
                onClick={this.props.onClose}>
              Cancel
            </Button>

            <Button
                className='ml-2'
                color='primary'
                onClick={this.handleReset}>
              OK
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default CancelSubscription;
