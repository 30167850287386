export default {
  COLLECTION_CREATE:         '@@collection/COLLECTION/CREATE',
  COLLECTION_CREATE_FAILURE: '@@collection/COLLECTION/CREATE_FAILURE',
  COLLECTION_CREATE_SUCCESS: '@@collection/COLLECTION/CREATE_SUCCESS',

  COLLECTION_DELETE:         '@@collection/COLLECTION/DELETE',
  COLLECTION_DELETE_FAILURE: '@@collection/COLLECTION/DELETE_FAILURE',
  COLLECTION_DELETE_SUCCESS: '@@collection/COLLECTION/DELETE_SUCCESS',

  COLLECTION_FETCH:         '@@collection/COLLECTION/FETCH',
  COLLECTION_FETCH_FAILURE: '@@collection/COLLECTION/FETCH_FAILURE',
  COLLECTION_FETCH_SUCCESS: '@@collection/COLLECTION/FETCH_SUCCESS',
  
  COLLECTION_GET:         '@@collection/COLLECTION/GET',
  COLLECTION_GET_FAILURE: '@@collection/COLLECTION/GET_FAILURE',
  COLLECTION_GET_SUCCESS: '@@collection/COLLECTION/GET_SUCCESS',

  COLLECTION_ON_DEMANDS_FETCH:         '@@collection/COLLECTION_ON_DEMANDS/FETCH',
  COLLECTION_ON_DEMANDS_FETCH_FAILURE: '@@collection/COLLECTION_ON_DEMANDS/FETCH_FAILURE',
  COLLECTION_ON_DEMANDS_FETCH_SUCCESS: '@@collection/COLLECTION_ON_DEMANDS/FETCH_SUCCESS',

  COLLECTION_UPDATE:         '@@collection/COLLECTION/UPDATE',
  COLLECTION_UPDATE_FAILURE: '@@collection/COLLECTION/UPDATE_FAILURE',
  COLLECTION_UPDATE_SUCCESS: '@@collection/COLLECTION/UPDATE_SUCCESS',

  FOLDER_FETCH:         '@@collection/FOLDER/FETCH',
  FOLDER_FETCH_FAILURE: '@@collection/FOLDER/FETCH_FAILURE',
  FOLDER_FETCH_SUCCESS: '@@collection/FOLDER/FETCH_SUCCESS',
  
  ON_DEMANDS_CREATE_SUCCESS: '@@collection/ON_DEMANDS/CREATE_SUCCESS',

  ON_DEMANDS_FETCH:         '@@collection/ON_DEMANDS/FETCH',
  ON_DEMANDS_FETCH_FAILURE: '@@collection/ON_DEMANDS/FETCH_FAILURE',
  ON_DEMANDS_FETCH_SUCCESS: '@@collection/ON_DEMANDS/FETCH_SUCCESS',

  ON_DEMAND_CREATE:         '@@collection/ON_DEMAND/CREATE',
  ON_DEMAND_CREATE_FAILURE: '@@collection/ON_DEMAND/CREATE_FAILURE',
  ON_DEMAND_CREATE_SUCCESS: '@@collection/ON_DEMAND/CREATE_SUCCESS',

  ON_DEMAND_DELETE:         '@@collection/ON_DEMAND/DELETE',
  ON_DEMAND_DELETE_FAILURE: '@@collection/ON_DEMAND/DELETE_FAILURE',
  ON_DEMAND_DELETE_SUCCESS: '@@collection/ON_DEMAND/DELETE_SUCCESS',

  ON_DEMAND_UPDATE:         '@@collection/ON_DEMAND/UPDATE',
  ON_DEMAND_UPDATE_FAILURE: '@@collection/ON_DEMAND/UPDATE_FAILURE',
  ON_DEMAND_UPDATE_SUCCESS: '@@collection/ON_DEMAND/UPDATE_SUCCESS',

  SEND_EMAIL:         '@@collection/SEND_EMAIL',
  SEND_EMAIL_FAILURE: '@@collection/SEND_EMAIL_FAILURE',
  SEND_EMAIL_SUCCESS: '@@collection/SEND_EMAIL_SUCCESS'
};
