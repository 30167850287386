import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';

import { Button, Form, MaskedInput, MaskedInputInteger, Radio, Select } from '../';
import { MEMBERSHIP_COUPON_DURATION } from '../../../constants';
import { monthMask, procentMask } from '../../../constants/masks';
import { Input } from '../../inputs';
import { Scroller, Validator, routes } from '../../../lib';
import { InstructorDeleteCoupon } from '../../alerts';

class MembershipCouponForm extends React.PureComponent {
  static propTypes = {
    createCoupon: PropTypes.func.isRequired,
    data:         PropTypes.object,
    deleteCoupon: PropTypes.func.isRequired,
    isLoading:    PropTypes.bool,
    isNewRecord:  PropTypes.bool,
    onToggle:     PropTypes.func.isRequired,
    user:         PropTypes.object.isRequired
  }

  static defaultProps = {
    data: {
      code:           '',
      discountType:   'free',
      duration:       'forever',
      name:           '',
      numberOfMonths: '',
      percent:        ''
    },
    isLoading:   true,
    isNewRecord: true
  };

  static getDerivedStateFromProps(props, state) {
    const { isNewRecord, isLoading } = props;

    if (!isLoading) {
      const coupon = isNewRecord
        ? MembershipCouponForm.defaultProps.data
        : props.data;

      if (!state.isMounted) {
        return {
          coupon,
          isMounted: true,
          isNewRecord
        };
      }
    }

    return null;
  }

  state = {
    coupon:    {},
    errors:    {},
    isMounted: false
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      coupon: {
        ...prevState.coupon,
        [inputName]: value
      }
    }));
  };

  handleDiscountTypeSwitch = (discountType) => () => {
    if (this.props.isNewRecord) return (
      this.setState((prevState) => ({
        coupon: {
          ...prevState.coupon,
          discountType,
          percent: ''
        }
      }))
    );
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { coupon } = this.state;
    const isReapiting = coupon.duration == 'repeating';

    const errors = Validator.validate([
      ['name',    Validator.concepts.isFilled,        [coupon.name]],
      ['percent', Validator.concepts.isFilled,        [coupon.percent], coupon.discountType == 'free'],
      ['code',    Validator.concepts.isFilled,        [coupon.code]],
      ['code',    Validator.concepts.spaceValidator,  [coupon.code, 'The coupon code must not have any spaces']],
      ['percent', Validator.concepts.moreThanHundred, [coupon.percent], coupon.discountType == 'free'],
      ['duration', Validator.concepts.isFilled,       [coupon.duration]],
      [
        'numberOfMonths',
        Validator.concepts.isFilled,
        [String(coupon.numberOfMonths).replace('months', '')],
        !isReapiting
      ]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  })

  handleSubmit = () => {
    const { coupon } = this.state;
    const { createCoupon } = this.props;
    const numberOfMonths = parseInt(coupon.numberOfMonths);

    return this.handleValidate().then(() => {
      createCoupon({ coupon: { ...coupon, numberOfMonths }});
      return this.props.onToggle();
    });
  }

  handleDelete = () => {
    const deleteOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <InstructorDeleteCoupon
            coupon={this.state.coupon}
            deleteCoupon={this.props.deleteCoupon}
            onClose={onClose}
            onSuccess={this.props.onToggle} />
    )};
    confirmAlert(deleteOptions);
  }

  render() {
    const { isNewRecord, user } = this.props;
    const { coupon, errors } = this.state;
    const isReapiting = coupon.duration == 'repeating';

    return (
      <Form className='form-default'>
        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Coupon Name
          </Label>
          <Input
              id='name'
              isDisabled={!isNewRecord}
              isInvalid={!!errors.name}
              name='name'
              onChange={this.handleChange}
              placeholder='October 20% Promo'
              type='text'
              value={coupon.name} />
          <FormFeedback tooltip>
            {errors.name}
          </FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label
              className='required'
              for='input-fee'>
            Discount Type
          </Label>

          <div className='d-flex'>
            <div className='custom-control custom-radio w-50'>
              <Radio
                  className='custom-control-input'
                  id='free'
                  isChecked={coupon.discountType === 'free'}
                  name='free'
                  onChange={this.handleDiscountTypeSwitch('free')}
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-free'>
                Free
              </Label>
            </div>

            <div className='custom-control custom-radio w-50'>
              <Radio
                  className='custom-control-input'
                  id='"percentage"'
                  isChecked={coupon.discountType === 'percentage'}
                  name='"percentage"'
                  onChange={this.handleDiscountTypeSwitch('percentage')}
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-"percentage"'>
                Percent Off
              </Label>
            </div>
          </div>
        </FormGroup>

        { coupon.discountType == 'percentage' &&
          <FormGroup>
            <Label
                className='required'
                for='input-name'>
              Discount Percentage
            </Label>

            <MaskedInputInteger
                id='contributionAmount'
                inputMode='number'
                isDisabled={!isNewRecord}
                isInvalid={!!errors.percent}
                mask={procentMask}
                name='percent'
                onChange={this.handleChange}
                placeholder='20%'
                type='contributionAmount'
                value={String(coupon.percent)} />
            <FormFeedback tooltip>
              {errors.code}
            </FormFeedback>
          </FormGroup>
        }

        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Discount Code
          </Label>
          <Input
              id='code'
              isDisabled={!isNewRecord}
              isInvalid={!!errors.code}
              name='code'
              onChange={this.handleChange}
              placeholder='e.g. 20OFF'
              type='text'
              value={coupon.code} />
          <FormFeedback tooltip>
            {errors.code}
          </FormFeedback>
        </FormGroup>

        <Row form>
          <Col>
            <FormGroup>
              <Label
                  className='required'
                  for='input-duration'>
                    Duration
              </Label>
              <Select
                  id='duration'
                  isDisabled={!isNewRecord}
                  isInvalid={!!errors.duration}
                  isOptionDisabled={(option) => option.disabled}
                  name='duration'
                  onChange={this.handleChange}
                  options={MEMBERSHIP_COUPON_DURATION}
                  type='text'
                  value={coupon.duration} />
            </FormGroup>
          </Col>

          <Col>
            { isReapiting &&
              <FormGroup>
                <Label for='input-months'>Number of Months</Label>
                <MaskedInput
                    id='month'
                    inputMode='number'
                    isDisabled={!isNewRecord}
                    isInvalid={!!errors.numberOfMonths}
                    mask={monthMask}
                    name='numberOfMonths'
                    onChange={this.handleChange}
                    placeholder='months'
                    type='number'
                    value={String(coupon.numberOfMonths)} />
                <FormFeedback tooltip>
                  {errors.numberOfMonths}
                </FormFeedback>
              </FormGroup>
            }
          </Col>
        </Row>

        <div className='text-small text-muted pb-3'>
          For memberships, this determines how long this coupon will apply once redeemed.
        </div>

        <div className='d-flex flex-column text-small text-muted'>
          <span><span className='font-weight-bold'>Forever:</span> every payment</span>
          <span><span className='font-weight-bold'>Once:</span> only the first payment </span>
          <span><span className='font-weight-bold'>Multiple Months:</span> every payment within X months</span>
        </div>

        { !user.hasStripe &&
          <div className='text-warning text-sm mt-2'>
            Note: Your Stripe account is not connected, in order to create a Membership Coupon connect
            your Stripe account by going to Instructor Settings &gt;&nbsp;
            <Link to={routes.INTEGRATIONS}>
              Integrations
            </Link>
          </div>
        }


        <Row className={isNewRecord ? 'mt-4' : 'mt-2'}>
          { isNewRecord &&
            <Col>
              <Button
                  isBlock
                  isDisabled={!user.hasStripe || (user.hasStripe && !user.stripeUpgraded)}
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Create Coupon
              </Button>
            </Col>
          }

          { !isNewRecord &&
            <React.Fragment>
              <p className='small text-danger'>
                Deleting a coupon does not affect any customers
                who have already applied the coupon;
                it means that new customers can&#39;t redeem the coupon
              </p>

              <Button
                  color='danger'
                  isBlock
                  isOutline
                  onClick={this.handleDelete}
                  size='lg'
                  type='button'>
                Delete Coupon
              </Button>
            </React.Fragment>
          }
        </Row>
      </Form>
    );
  }
}

export default MembershipCouponForm;
