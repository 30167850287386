import React from 'react';
import PropTypes from 'prop-types';
import { Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { Moment, getTimeZone } from '../../lib';
import { Button } from '../forms';

class UserGroupClassModal extends React.PureComponent {
  static propTypes = {
    deleteBooking: PropTypes.func,
    groupClass:    PropTypes.object.isRequired,
    isOpen:        PropTypes.bool,
    onToggle:      PropTypes.func,
    user:          PropTypes.object.isRequired
  }

  static defaultProps = {
    deleteBooking: () => {},
    isOpen:        false,
    onToggle:      () => {}
  }

  state = {
    date: ''
  }

  static getDerivedStateFromProps(props) {
    return {
      date: new Moment.utc(props.groupClass.startsAt, 'YYYY-MM-DDhh:mm:ssUTC').tz(getTimeZone(props.user.timeZone))
    };
  }

  handleDelete = () => {
    const { groupClass } = this.props;
    this.props.deleteBooking({ groupClass });
    this.props.onToggle();
  }


  render() {
    const { groupClass } = this.props;
    const { date } = this.state;

    return (
      <React.Fragment>
        <Modal
            centered
            className='modal-event'
            isOpen={this.props.isOpen}
            toggle={this.props.onToggle}>
          <ModalHeader toggle={this.props.onToggle}>
            View Studio Group Class
          </ModalHeader>

          <ModalBody>
            <h6 className='title-details'>
              Studio Group Class Details
            </h6>

            <div className='event-details'>
              <div className='name'>
                {groupClass.instructor.name}
              </div>

              <div className='name'>
                {groupClass.name} - {groupClass.location.name}
              </div>

              <div className='date'>
                {date.format('ddd | MMM D YYYY')}
              </div>

              <div className='time'>
                {date.format('h:mm A')} -&nbsp;
                {groupClass.duration} m
              </div>

              <div className='mt-4 mb-4'>
                <strong className='text-danger'>Delete Booking</strong>

                <p className='text-danger text-sm'>
                  The request <strong>will not</strong> cancel the
                  booking until reviewed and approved by the instructor
                </p>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <Row>
              <Col
                  lg={6}
                  md={12}>
                <Button
                    color='danger'
                    isBlock
                    isOutline
                    onClick={this.handleDelete}
                    size='lg'>
                  Delete Class
                </Button>
              </Col>
              <Col
                  lg={6}
                  md={12}>
                <Button
                    className='mt-2 mt-lg-0'
                    color='primary'
                    isBlock
                    onClick={this.props.onToggle}
                    size='lg'>
                  Close
                </Button>
              </Col>
            </Row>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default UserGroupClassModal;
