import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';
import classNames from 'classnames';

import { UserCancelMembership } from '../alerts';
import { Moment } from '../../lib';

import { UserModalAvatar } from './layouts';

class UserSubscriptionModal extends React.PureComponent {
  static propTypes = {
    deleteSubscription: PropTypes.func.isRequired,
    isOpen:             PropTypes.bool,
    onToggle:           PropTypes.func,
    subscription:       PropTypes.object.isRequired
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  state = {
    isBookingTermsOpen: false,
    isTermsChecked:     false
  }

  handleChange = (value, inputName) => (
    this.setState({ [inputName]: value })
  )

  handleModal = (modal) => (e) => {
    e.preventDefault();
    this.setState((prevState) => ({ [modal]: !prevState[modal] }));
  }

  handleCancel = () => {
    const deleteOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <UserCancelMembership
            deleteSubscription={this.props.deleteSubscription}
            onClose={onClose}
            onSuccess={this.props.onToggle}
            subscription={this.props.subscription} />
        )
      };

    confirmAlert(deleteOptions);
  }

  renderContent() {
    const { subscription } = this.props;
    const { currency } = subscription.membership.instructor;

    return (
      <div
          className={classNames('modal__event-cost',
                                'modal__default-text',
                                'modal__default-text_blue',
                                'modal__default-text_bold',
                                { 'text-strikethrough': subscription.status !== 'active' })}>
        Cost:&nbsp;
        {subscription.membership.cost.toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
        per {subscription.membership.period}
      </div>
    );
  }

  renderFooter() {
    return (
      <React.Fragment>
        <ModalFooter className='text-center'>
          <small className='text-muted mt-3'>
            To cancel your membership&nbsp;
            <span
                className='text-primary pointer'
                onClick={this.handleCancel}
                onKeyPress={this.handleCancel}
                role='button'
                tabIndex={0}>
              click here
            </span>
          </small>
        </ModalFooter>
      </React.Fragment>
    );
  }

  render() {
    const { subscription } = this.props;
    const { instructor, description, trial, trialDays } = subscription.membership;

    const isTrial = subscription.invoices.length == 1 && trial;

    const trialPeriod = new Moment.utc().add(trialDays, 'days').format('MMM DD, YYYY');

    const endsAt = new Moment.utc(subscription.endsAt, 'YYYY-MM-DDhh:mm:ssUTC').format('MMM DD, YYYY');

    return (
      <Modal
          className='modal-booking'
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalHeader toggle={this.props.onToggle} />

        <div className='new-modal'>
          <ModalBody>
            { instructor &&
              <div className='modal__header'>
                <UserModalAvatar
                    alt={instructor.firstName}
                    firstName={instructor.firstName}
                    src={instructor.photo} />
                <div className='modal__instructor-name'>
                  <span className='modal__big-text'>{instructor.firstName}&nbsp;{instructor.lastName}</span>
                </div>
              </div>
            }

            <div className='modal__event-name'>
              <span className='modal__big-text modal__big-text_black'>{subscription.membership.name}</span>
            </div>

            { description &&
              <div className='event-description'>
                <span className='modal__small-text'>About this membership</span>
                <p className='modal__small-text modal__small-text_grey'>{description}</p>
              </div>
            }
            <div>
              <span className='modal__small-text d-block'>Your membership details</span>
              <span className='modal__small-text modal__small-text_grey'>Status: </span>
              { subscription.status === 'active' &&
                <React.Fragment>
                  <span className='modal__small-text modal__small-text_green'>Active</span>
                  <div className='mb-2'>
                    <span className='modal__small-text modal__small-text_grey block'>
                      Renews: {isTrial ? trialPeriod : endsAt}
                    </span>
                  </div>
                </React.Fragment>
              }

              { subscription.status === 'canceled' &&
                <React.Fragment>
                  <span className='modal__small-text modal__small-text_red'>Canceled</span>
                  <div className='mb-2'>
                    <span className='modal__small-text modal__small-text_grey'>Expires: {endsAt}</span>
                  </div>
                </React.Fragment>
              }

              { subscription.status === 'inactive' &&
                <React.Fragment>
                  <span className='modal__small-text modal__small-text_red'>Inactive</span>
                </React.Fragment>
              }
            </div>

            {this.renderContent()}
          </ModalBody>
        </div>

        {subscription.status === 'active' && this.renderFooter()}
      </Modal>
    );
  }
}

export default UserSubscriptionModal;
