import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormText } from 'reactstrap';

import { SocialField } from './';

class Social extends React.PureComponent {
  static propTypes = {
    children:  PropTypes.node,
    className: PropTypes.string,
    onChange:  PropTypes.func.isRequired,
    profile:   PropTypes.object.isRequired
  }

  static defaultProps = {
    children:  null,
    className: ''
  }

  handleChange = (value, inputName) => {
    this.props.onChange({
      ...this.props.profile.socialProfiles,
      [inputName]: value
    }, 'socialProfiles');
  }

  render() {
    return (
      <div className={this.props.className}>
        {this.props.children}

        <FormText className='mb-3'>
          Add your handle only
          (e.g. &quot;onpodio&quot; and not &quot;instagram.com/onpodio&quot;)
        </FormText>

        <FormGroup row>
          <SocialField
              icon='facebook-f'
              onChange={this.handleChange}
              placeholder='username'
              profile={this.props.profile}
              subject='facebook' />
        </FormGroup>

        <FormGroup row>
          <SocialField
              icon='instagram'
              onChange={this.handleChange}
              placeholder='username'
              profile={this.props.profile}
              subject='instagram' />
        </FormGroup>

        <FormGroup row>
          <SocialField
              icon='linkedin'
              onChange={this.handleChange}
              placeholder='username'
              profile={this.props.profile}
              subject='linkedin' />
        </FormGroup>

        <FormGroup row>
          <SocialField
              icon='twitter'
              onChange={this.handleChange}
              placeholder='username'
              profile={this.props.profile}
              subject='twitter' />
        </FormGroup>
      </div>
    );
  }
}

export default Social;
