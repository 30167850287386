import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError } from '../lib';
import { MeetingActionTypes } from '../constants/actionTypes';
import { ErrorActions, MeetingActions } from '../actions';

function* watchDelete() {
  yield takeLatest(MeetingActionTypes.DELETE, function* ({ payload }) {
    try {
      yield call(api.meeting.delete, payload);
      yield put(MeetingActions.DELETE_SUCCESS());
    } catch (error) {
      yield put(MeetingActions.DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdate() {
  yield takeLatest(MeetingActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield call(api.meeting.update, payload);
      yield put(MeetingActions.UPDATE_SUCCESS());
    } catch (error) {
      yield put(MeetingActions.UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* meetingSaga() {
  yield all([
    watchDelete(),
    watchUpdate()
  ]);
}
