/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Container, Form, FormGroup, FormText, Label, Media } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import className from 'classnames';

import { instructorRoutes, routes } from '../../../lib';
import { Button, Checkbox } from '../../forms';
import { Input } from '../../inputs';
import { Heading, Panel } from '../../layout/pages';
import { BackButton } from '../../buttons';

class SettingsWorkoutPage extends React.PureComponent {
  static propTypes = {
    instructor:    PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const { instructor } = props;

    if (instructor.id && !state.isMounted) {
      return {
        instructor,
        isMounted: true
      };
    }

    return state;
  }

  state = {
    availability:            {},
    groupClassesEnabled:     false,
    instructor:              {},
    isMounted:               false,
    privateSessionsEnabled:  false,
    requestedClassesEnabled: false
  };

  handleChange = (setting) => () => {
    this.setState((prevState) => ({
      instructor: {
        ...prevState.instructor,
        [setting]: !prevState.instructor[setting]
      },
      [setting]: !prevState[setting]
    }), () => {
      this.props.updateProfile(this.state.instructor);
    });
  };

  handleUpdate = (setting) => {
    this.props.updateProfile({ [setting]: this.state[setting] });
  };

  handleChangeInput = (value, inputName) => {
    this.setState((prevState) => ({
      instructor: {
        ...prevState.instructor,
        [inputName]: value
      }
    }));
  }

  handleUpdateProfile = () => {
    const { instructor } = this.state;

    this.props.updateProfile(instructor);
  }

  render() {
    const { instructor } = this.state;

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Workout</title>
        </Helmet>
        <div className='inner-wrapper instructor-settings'>
          <Container
              className='settings-page'
              fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Workout' />
            <Panel className='settings-menu'>
              <Media className='menu-link d-block'>
                <Media body>
                  <div className='custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='workout'
                        isChecked={instructor.workoutPageEnabled}
                        name='workoutPageEnabled'
                        onChange={this.handleChangeInput} />
                    <Label
                        className='custom-control-label d-flex'
                        htmlFor='input-workout'>
                      Show the&nbsp;
                      <Link to={routes.INSTRUCTOR(instructor.username) + instructorRoutes.WORKOUT.path}>
                        <div className='text-primary'>Workout</div>
                      </Link>
                      &nbsp;page on your site
                    </Label>
                  </div>
                </Media>
              </Media>

              <h4 className='menu-title'>Workout Description</h4>

              <Media className='menu-link d-block'>
                <Form className='form-default'>
                  <FormGroup>
                    <FormText>
                      Let clients know what they’re in for with your workouts...
                    </FormText>
                    <Input
                        id='workout_reason'
                        maxLength={2000}
                        name='workoutReason'
                        onChange={this.handleChangeInput}
                        placeholder='If you&apos;ve ever trained with me you know what I&apos;m all about: form and intensity matched with amazing music...'
                        rows='6'
                        type='textarea'
                        value={instructor.workoutReason} />
                  </FormGroup>
                </Form>

                <Button
                    className='save-changes-btn'
                    color='success'
                    isBlock={isMobile}
                    onClick={this.handleUpdateProfile}>
                  Save Changes
                </Button>
              </Media>

              <h4 className='menu-title'>Live Classes Module</h4>

              <Media className='menu-link d-block'>
                <Media body>
                  <div className='custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='group-classes'
                        isChecked={instructor.groupClassesEnabled}
                        name='group-classes'
                        onChange={this.handleChange('groupClassesEnabled')} />
                    <Label
                        className='custom-control-label'
                        htmlFor='input-group-classes'>
                      Show the Live Classes module on your page
                    </Label>
                  </div>
                </Media>

                <div className='small d-flex text-muted font-italic mt-1'>
                  Add your Live Classes via the&nbsp;
                  <Link to={routes.CALENDAR}>
                    <div className='text-primary'>My Class Schedule page</div>
                  </Link>
                </div>
              </Media>

              <h4 className='menu-title'>On Demand Classes Module</h4>

              <Media className='menu-link d-block'>
                <Media body>
                  <div className='custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='requested-classes'
                        isChecked={instructor.requestedClassesEnabled}
                        name='requested-classes'
                        onChange={this.handleChange('requestedClassesEnabled')} />
                    <Label
                        className='custom-control-label'
                        htmlFor='input-requested-classes'>
                      Show the On Demand Classes module on your page
                    </Label>
                  </div>
                </Media>

                <div className={className('small text-muted font-italic mt-1 d-flex')}>
                  Add your On Demand Classes via the&nbsp;
                  <Link to={routes.ON_DEMAND}>
                    <div className='text-primary'>On Demand page</div>
                  </Link>
                </div>
              </Media>

              <h4 className='menu-title'>Private Session Module</h4>

              <Media className='menu-link d-block'>
                <Media body>
                  <div className='custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='private-session'
                        isChecked={instructor.privateSessionsEnabled}
                        name='private-session'
                        onChange={this.handleChange('privateSessionsEnabled')} />
                    <Label
                        className='custom-control-label'
                        htmlFor='input-private-session'>
                      Show the Private Session module on your page
                    </Label>
                  </div>
                </Media>

                <div className={className('small text-muted font-italic mt-1 d-flex')}>
                  Add your Private Session options via the&nbsp;
                  <Link to={routes.PRIVATE_SESSIONS}>
                    <div className='text-primary'>Private Session page</div>
                  </Link>
                </div>
              </Media>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SettingsWorkoutPage;
