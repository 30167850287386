import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { ButtonToolbar, Container } from 'reactstrap';

import { Heading, Panel } from '../../components/layout/pages';
import { transactionTables } from '../../constants/transactions';
import { ButtonGroup } from '../../components/buttons';
import { Button } from '../../components/forms';
import { requiresProfile } from '../../helpers';
import { AllTimeSummary } from './summaries';
import { ChallengesTable, ClassPacksTable, MembershipsTable,
         PurchasesTable } from './tables';

class Transactions extends PureComponent {
  state = {
    selectedTable: transactionTables.purchases
  }

  handleToggle = (selectedTable) => () => {
    this.setState({ selectedTable });
  }

  renderTable = () => {
    const { selectedTable } = this.state;

    switch (true) {
      case selectedTable === transactionTables.memberships:
        return <MembershipsTable />;

      case selectedTable === transactionTables.classPacks:
        return <ClassPacksTable />;

        case selectedTable === transactionTables.challenges:
          return <ChallengesTable />;

      default:
        return <PurchasesTable />;
    }
  }

  render () {
    const { selectedTable } = this.state;

    const selectedButton = (table) => {
      return selectedTable !== table;
    };

    return (
      <div className='inner-wrapper transactions'>
        <Helmet>
          <title>My Transactions</title>
        </Helmet>

        <Container fluid>
          <Heading
              color='secondary'
              subtitle='Creator Portal'
              title='My Transactions' />

          <Panel className='panel'>
            <AllTimeSummary />

            <ButtonToolbar>
              <ButtonGroup>
                <Button
                    className='w-15'
                    color='primary'
                    isOutline={selectedButton(transactionTables.purchases)}
                    onClick={this.handleToggle(transactionTables.purchases)}>
                  Purchases
                </Button>
                <Button
                    color='primary'
                    isOutline={selectedButton(transactionTables.memberships)}
                    onClick={this.handleToggle(transactionTables.memberships)}>
                  Memberships
                </Button>
                <Button
                    color='primary'
                    isOutline={selectedButton(transactionTables.classPacks)}
                    onClick={this.handleToggle(transactionTables.classPacks)}>
                  Class Packs
                </Button>

                <Button
                    color='primary'
                    isOutline={selectedButton(transactionTables.challenges)}
                    onClick={this.handleToggle(transactionTables.challenges)}>
                  Challenges
                </Button>
              </ButtonGroup>
            </ButtonToolbar>

            <div className='transactions-summary'>
              {this.renderTable()}
            </div>
          </Panel>
        </Container>
      </div>
    );
  }
}

export default requiresProfile(Transactions);
