import { connect } from 'react-redux';

import { SettingsCancellationPage } from '../components/pages';
import { StrategyActions } from '../actions';

const mapStateToProps = (state) => ({
  isLoading: state.loader.strategy.isLoading,
  strategy:  state.strategy.data
});

const mapDispatchToProps = (dispatch) => ({
  getStrategy:    ()     => dispatch(StrategyActions.GET()),
  updateStrategy: (data) => dispatch(StrategyActions.UPDATE(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsCancellationPage);
