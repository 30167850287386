import { EmailTemplatesActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       [],
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case EmailTemplatesActionTypes.CREATE:
      return newState;

    case EmailTemplatesActionTypes.CREATE_SUCCESS:
      return { ...newState, data: [...state.data, action.payload] };

    case EmailTemplatesActionTypes.CREATE_FAILURE:
      return newState;


    case EmailTemplatesActionTypes.FETCH:
      return newState;

    case EmailTemplatesActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload };

    case EmailTemplatesActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data };


    case EmailTemplatesActionTypes.UPDATE:
      return newState;

    case EmailTemplatesActionTypes.UPDATE_SUCCESS:
      return { ...newState, data: action.payload };

    case EmailTemplatesActionTypes.UPDATE_FAILURE:
      return newState;

    default: return state;
  }
};
