import { toastr } from 'react-redux-toastr';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError, toCamelCase } from '../lib';
import { CustomizationActionTypes } from '../constants/actionTypes';

import { CustomizationActions, ErrorActions, LoaderActions } from '../actions';

function* watchGetCustomization() {
  yield takeLatest(CustomizationActionTypes.GET_CUSTOMIZATION, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      yield put(ErrorActions.CLEAR());

      const response = yield call(api.customization.get);

      yield put(CustomizationActions.GET_CUSTOMIZATION_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(CustomizationActions.GET_CUSTOMIZATION_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchGetCustomizationSuccess() {
  yield takeLatest(CustomizationActionTypes.GET_CUSTOMIZATION_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchGetCustomizationFailure() {
  yield takeLatest(CustomizationActionTypes.GET_CUSTOMIZATION_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateCustomization() {
  yield takeLatest(CustomizationActionTypes.UPDATE_CUSTOMIZATION, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.customization.update, payload);

      yield put(CustomizationActions.UPDATE_CUSTOMIZATION_SUCCESS(toCamelCase(response.data)));
      toastr.success('Customization settings successfully updated');
    } catch (error) {
      yield put(CustomizationActions.UPDATE_CUSTOMIZATION_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateCustomizationSuccess() {
  yield takeLatest(CustomizationActionTypes.UPDATE_CUSTOMIZATION_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateCustomizationFailure() {
  yield takeLatest(CustomizationActionTypes.UPDATE_CUSTOMIZATION_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* CustomizationSaga() {
  yield all([
    watchGetCustomization(),
    watchGetCustomizationSuccess(),
    watchGetCustomizationFailure(),
    watchUpdateCustomization(),
    watchUpdateCustomizationSuccess(),
    watchUpdateCustomizationFailure()
  ]);
}
