import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { FavoriteOnDemandsTypes } from '../constants/actionTypes';
import { ErrorActions, FavoriteOnDemandsActions, LoaderActions } from '../actions';

function* watchCreate() {
  yield takeLatest(FavoriteOnDemandsTypes.CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.favoriteOnDemand.create, payload);
      const data = toCamelCase(response.data);

      yield put(FavoriteOnDemandsActions.CREATE_SUCCESS(data.onDemand));
      toastr.success('Video favorited');
    } catch (error) {
      yield put(FavoriteOnDemandsActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDelete() {
  yield takeLatest(FavoriteOnDemandsTypes.DELETE, function* ({ payload }) {
    try {
      yield call(api.favoriteOnDemand.delete, payload);

      const favoriteOnDemands = yield select((state) => state.favoriteOnDemands.data);
      const data = filter(favoriteOnDemands, (favorite) => (favorite.id != payload.id));

      yield put(FavoriteOnDemandsActions.DELETE_SUCCESS(data));
      toastr.success('Video unfavorited');
    } catch (error) {
      yield put(FavoriteOnDemandsActions.DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetch() {
  yield takeLatest(FavoriteOnDemandsTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.favoriteOnDemand.fetch);

      yield put(FavoriteOnDemandsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(FavoriteOnDemandsActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(FavoriteOnDemandsTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(FavoriteOnDemandsTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

export default function* favoriteOnDemandsSaga() {
  yield all([
    watchCreate(),

    watchDelete(),

    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure()
  ]);
}
