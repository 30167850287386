/* eslint-disable sort-keys-fix/sort-keys-fix */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { capitalize, chain, isEmpty } from 'lodash';

import { Autocompleter, Button, Timetable } from '../forms';
import { Input } from '../inputs';
import { Error } from '../errors';
import { UserAvatar } from '../users';
import { Validator } from '../../lib';
import { AutocompleterContainer } from '../../containers';
import { InstructorNoAvatar } from '../../images';

class PrivateSessionModal extends React.PureComponent {
  static propTypes = {
    createPrivateSession: PropTypes.func.isRequired,
    instructor:           PropTypes.object.isRequired,
    isOpen:               PropTypes.bool,
    onToggle:             PropTypes.func,
    user:                 PropTypes.object.isRequired
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  state = {
    errors: {},

    privateSession: {
      email:     '',
      phone:     '',
      service:   '',
      serviceId: '',
      timeOfDay: ''
    },

    /* need specific order */
    schedule: {
      monday:    true,
      tuesday:   true,
      wednesday: true,
      thursday:  true,
      friday:    true,
      saturday:  true,
      sunday:    true
    }
  }

  componentDidMount() {
    const { email, phone } = this.props.user.data;

    this.setState((prevState) => ({
      privateSession: {
        ...prevState.privateSession,
        email,
        phone,
        instructorId: this.props.instructor.id
      }
    }));
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      privateSession: {
        ...prevState.privateSession,
        [inputName]: value
      }
    }));
  }

  handleSelect = (inputName) => (option) => {
    this.setState((prevState) => ({
      privateSession: {
        ...prevState.privateSession,
        [inputName]: option.id
      }
    }));
  }

  handleSubmit = () => {
    const { privateSession, schedule } = this.state;

    this.handleValidate().then(() => {
      const day = chain(schedule).map((isActive, dayOfWeek) => {
        if (isActive) return capitalize(dayOfWeek);
      }).compact().join(' ').value();

      this.props.createPrivateSession({ privateSession: { ...privateSession, day } });

      return this.handleToggle();
    }).catch((errors) => {
      return this.setState({ errors });
    });
  }

  handleTags = (option) => {
    this.setState((prevState) => {
      const timeOfDay = prevState.privateSession.timeOfDay == option.id ? '' : option.id;

      return { privateSession: { ...prevState.privateSession, timeOfDay } };
    });
  }

  handleToggle = () => {
    this.setState({ errors: {} }, () => {
      Validator.clear();
      this.props.onToggle();
    });
  }

  handleTick = (week) => (value) => {
    this.setState((prevState) => ({
      schedule: {
        ...prevState.schedule,
        [week]: value
      }
    }));
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { privateSession } = this.state;

    const errors = Validator.validate([
      ['service', Validator.concepts.isFilled, [privateSession.serviceId]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  })

  render() {
    const { instructor } = this.props;
    const photo = instructor.photo ? instructor.photo.sm : InstructorNoAvatar;
    const fullName = instructor.firstName + ' ' + instructor.lastName;

    return (
      <Modal
          centered
          className='private-modal'
          isOpen={this.props.isOpen}
          toggle={this.handleToggle}>
        <ModalHeader
            className='d-lg-none private-modal-header'
            toggle={this.handleToggle} />

        <ModalBody className='private-modal-body'>
          <Row>
            <Col md='5'>
              <div className='profile-avatar'>
                <UserAvatar
                    alt={fullName}
                    className='mb-3'
                    size='xxl'
                    src={photo} />
              </div>
            </Col>

            <Col md='7'>
              <h5 className='mt-3'>{fullName}</h5>

              <div className='errors'>
                {!!this.state.errors.services &&
                <Error>Please select a service</Error>
                }
              </div>

              <AutocompleterContainer>
                <Autocompleter
                    className='mt-3'
                    hasInitialValue
                    instructor={instructor}
                    isInvalid={!!this.state.errors.service}
                    name='service'
                    onChange={this.handleChange}
                    onSelect={this.handleSelect('serviceId')}
                    placeholder='Select activity/service'
                    subject='services'
                    value={this.state.privateSession.service} />
              </AutocompleterContainer>
            </Col>
          </Row>

          <Timetable
              hasTime
              onTick={this.handleTick}
              onTimeChange={this.handleTags}
              schedule={this.state.schedule}
              selectedTime={this.state.privateSession.timeOfDay}>
            {['Day', 'Time']}
          </Timetable>

          <FormGroup>
            <Label for='input-note'>
              <strong>Leave additional information for {instructor.firstName}</strong>
            </Label>

            <Input
                id='note'
                isInvalid={!!this.state.errors.note}
                maxLength={250}
                name='note'
                onChange={this.handleChange}
                rows='5'
                type='textarea'
                value={this.state.privateSession.note} />
          </FormGroup>

          <FormGroup>
            <Label for='contactInformation'>
              <strong>Your contact information</strong>
            </Label>

            <Row>
              <Col
                  className='pt-1'
                  md="2">
                <Label for='input-email'>Email</Label>
              </Col>

              <Col md='10'>
                <Input
                    className='mb-3'
                    id='email'
                    isInvalid={!!this.state.errors.email}
                    name='email'
                    onChange={this.handleChange}
                    type='email'
                    value={this.state.privateSession.email} />
              </Col>
            </Row>

            <Row>
              <Col
                  className='pt-1'
                  md="2">
                <Label for='input-phone'>Phone</Label>
              </Col>

              <Col md='10'>
                <Input
                    className='mb-3'
                    id='phone'
                    isInvalid={!!this.state.errors.phone}
                    name='phone'
                    onChange={this.handleChange}
                    value={this.state.privateSession.phone} />
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button
              color='primary'
              onClick={this.handleSubmit}>
            Enquire
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default PrivateSessionModal;
