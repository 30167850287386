import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { LocationForm } from '../../forms/instructor';

class LocationModal extends React.PureComponent {
  static propTypes = {
    createLocation: PropTypes.func.isRequired,
    deleteLocation: PropTypes.func.isRequired,
    isLoading:      PropTypes.bool.isRequired,
    isNewRecord:    PropTypes.bool.isRequired,
    isOpen:         PropTypes.bool.isRequired,
    location:       PropTypes.object.isRequired,
    onToggle:       PropTypes.func.isRequired,
    updateLocation: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props) {
    const isNewRecord = props.isNewRecord;
    const isLoading = props.isLoading;
    const editLocation = {
      address:          props.location.address,
      id:               props.location.id,
      isOwner:          props.location.isOwner,
      name:             props.location.name,
      secondaryAddress: props.location.secondaryAddress
    };

    return {
      isLoading:   isLoading,
      isNewRecord: isNewRecord,
      location:    isNewRecord ? {} : editLocation
    };
  }

  state = {
    isLoading:   false,
    isNewRecord: true,
    location:    {}
  };

  handleSubmit = (form) => {
    const params = form;

    this.state.isNewRecord ? this.props.createLocation(params) : this.props.updateLocation(params);
    this.props.onToggle();
  };

  handleDelete = (params) => {
    this.props.deleteLocation(params);
    this.props.onToggle();
  };


  render () {
    const { isOpen, onToggle } = this.props;
    const { location, isNewRecord, isLoading } = this.state;

    const title = `${isNewRecord ? 'Add New' : 'Edit Existing'} Location`;

    return (
      <Modal
          className='modal-location'
          isOpen={isOpen}
          toggle={onToggle}>

        <ModalHeader toggle={onToggle}>
          {title}
        </ModalHeader>

        <ModalBody>
          <LocationForm
              data={location}
              isLoading={isLoading}
              isNewRecord={isNewRecord}
              onDelete={this.handleDelete}
              onSubmit={this.handleSubmit} />
        </ModalBody>
      </Modal>
    );
  }
}

export default LocationModal;
