import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';

import { Button } from '../../forms';
import { PurchaseClassModal } from '../../modals';
import { Paid, PlayIcon, UnLike } from '../../../images';
import { Moment, getTimeZone } from '../../../lib';

class OnDemand extends React.PureComponent {
  static propTypes = {
    classPacks:       PropTypes.array.isRequired,
    createDiscount:   PropTypes.func.isRequired,
    discount:         PropTypes.object.isRequired,
    fetchClassPacks:  PropTypes.func.isRequired,
    fetchMemberships: PropTypes.func.isRequired,
    instructor:       PropTypes.object.isRequired,
    instructorPacks:  PropTypes.array.isRequired,
    loader:           PropTypes.object.isRequired,
    memberships:      PropTypes.array.isRequired,
    requestedClass:   PropTypes.object.isRequired,
    restoreDiscount:  PropTypes.func.isRequired,
    subscriptions:    PropTypes.array.isRequired,
    subtractCredit:   PropTypes.func.isRequired,
    unLike:           PropTypes.func.isRequired,
    user:             PropTypes.object.isRequired
  }

  state = {
    isModalOpen: false
  }

  handleToggle = () => {
    this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
  };

  handleUnLike = () => {
    const { id } = this.props.requestedClass;

    this.props.unLike({ id });
  }

  render() {
    const { requestedClass, user } = this.props;

    const createdAt = new Moment.utc(requestedClass.createdAt, 'YYYY-MM-DDhh:mm:ssUTC')
                                .tz(getTimeZone(user.timeZone));

    const src = requestedClass.poster;

    return (
      <React.Fragment>
        <Card
            className='card-event requested-class body-class__event'
            key={1}>
          <div
              className='image'
              style={{ backgroundImage: `url(${src})`, backgroundSize: 'cover' }}>
            <React.Fragment>
              <div
                  className='like-icon'
                  onClick={this.handleUnLike}
                  onKeyPress={this.handleUnLike}
                  role='button'
                  tabIndex={0}>
                <img
                    alt='Icon'
                    className='pointer'
                    src={UnLike} />
              </div>

              <div
                  className='play-icon'
                  onClick={this.handleToggle}
                  onKeyPress={this.handleToggle}
                  role='button'
                  tabIndex={0}>
                <img
                    alt="Icon"
                    className='pointer'
                    src={PlayIcon} />
              </div>
            </React.Fragment>
          </div>

          <div className='info-block'>
            <div className='info'>
              <p className='name'>{requestedClass.name}</p>
              <p className='uploaded'>Uploaded: {createdAt.format('MMM, DD, YYYY')}</p>
            </div>

            <div>
              <Paid />
              <Button
                  className='ml-2'
                  onClick={this.handleToggle}
                  size='sm'>
                Watch
              </Button>
            </div>
          </div>

          <div className='description-block'>
            <p className='text'>{requestedClass.description}</p>
          </div>

        </Card>

        { this.state.isModalOpen &&
          <PurchaseClassModal
              classPacks={this.props.classPacks}
              createDiscount={this.props.createDiscount}
              discount={this.props.discount}
              fetchClassPacks={this.props.fetchClassPacks}
              fetchMemberships={this.props.fetchMemberships}
              instructor={requestedClass.instructor}
              instructorPacks={this.props.instructorPacks}
              isOpen={this.state.isModalOpen}
              loader={this.props.loader}
              memberships={this.props.memberships}
              onToggle={this.handleToggle}
              requestedClass={requestedClass}
              restoreDiscount={this.props.restoreDiscount}
              subscriptions={this.props.subscriptions}
              subtractCredit={this.props.subtractCredit}
              user={this.props.user} />
        }
      </React.Fragment>
    );
  }
}

export default OnDemand;
