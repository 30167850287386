/* global process */
import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { withRouter } from 'react-router';
import { includes, values } from 'lodash';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { ApplicationActions, SegmentActions } from '../../actions';

import { instructorRoutes, routes } from '../../lib';

import Header from './header';
import Sidebar from './sidebar';
import Footer from './footer';
import Toastr from './toastr';

const { HOST } = process.env;

class Layout extends React.PureComponent {
  static propTypes = {
    content:          PropTypes.node.isRequired,
    hasFooter:        PropTypes.bool,
    hasHeader:        PropTypes.bool,
    hasSidebar:       PropTypes.bool,
    hideSidebar:      PropTypes.func.isRequired,
    location:         PropTypes.object.isRequired,
    segmentSiteShare: PropTypes.func.isRequired
  }

  static defaultProps = {
    hasFooter:  false,
    hasHeader:  true,
    hasSidebar: true
  }

  static getDerivedStateFromProps(props) {
    const { hasFooter, hasHeader } = props;

    const hasSidebar = props.hasSidebar || window.innerWidth < 992;

    return { hasFooter, hasHeader, hasSidebar };
  }

  state = {
    hasFooter:     true,
    hasHeader:     true,
    hasSidebar:    true,
    isSearchOpen:  false,
    isSidebarOpen: !isMobile
  };

  handleSearch = () => {
    this.setState((prevState) => ({
      isSearchOpen: !prevState.isSearchOpen
    }));
  }

  handleSideMenu = () => (
    this.setState((prevState) => ({
      isSidebarOpen: !prevState.isSidebarOpen
    }))
  )

  closeSideMenu = () => {
    this.props.hideSidebar();
  }

  componentDidUpdate (prevProps) {
    if ( this.props.location !== prevProps.location) {
      this.closeSideMenu();
      this.redirectToExternal();
    }
  }

  redirectToExternal = () => {
    const { location } = this.props;
    const hasCustomDomain = window.location.host !== HOST;
    const instructorPaths = values(instructorRoutes).map((v) => { return v.path; });
    const externalRedirect = !includes([...instructorPaths, routes.LOGIN, routes.SIGNUP], location.pathname);

    if (hasCustomDomain && externalRedirect) {
      return window.location.replace(`${window.location.protocol}//${HOST}${location.pathname}`);
    }
  }

  renderLayout = () => {
    const { hasHeader, hasSidebar, hasFooter, isSearchOpen } = this.state;

    return (
      <React.Fragment>
        { hasHeader &&
          <Header
              isSearchOpen={isSearchOpen}
              onMenuToggle={this.handleSideMenu}
              onSearchToggle={this.handleSearch} />
        }
        <div className='wrapper-content'>
          { hasSidebar &&
            <Sidebar
                handleSiteShare={this.props.segmentSiteShare}
                onSearchToggle={this.handleSearch} />
          }
          <main id='content'>
            {React.cloneElement(this.props.content)}
          </main>
        </div>
        { hasFooter && <Footer /> }
      </React.Fragment>
    );
  }

  render () {
    const { hasHeader, hasSidebar } = this.state;
    const wrapperClass = classNames('wrapper', { 'with-sidebar': hasSidebar }, { 'hide-navbar': !hasHeader });

    return (
      <div className={wrapperClass}>
        <Toastr />
        {this.renderLayout()}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  hasCustomDomain: state.app.hasCustomDomain
});

const mapDispatchToProps = (dispatch) => ({
  hideSidebar:      () => dispatch(ApplicationActions.SIDEBAR_HIDE()),
  segmentSiteShare: () => dispatch(SegmentActions.SITE_SHARE())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Layout));

export { Header, Sidebar };
