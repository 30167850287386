import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Label } from 'reactstrap';

import { Radio, Timetable } from '../forms';

class ClassInfo extends React.PureComponent {
  static propTypes = {
    hasLocation: PropTypes.bool.isRequired,
    hasName:     PropTypes.bool.isRequired,
    onChange:    PropTypes.func.isRequired,
    schedule:    PropTypes.object.isRequired
  }

  state = {
    hasLocation: false,
    hasName:     false,

    schedule: {
      friday:    true,
      monday:    true,
      saturday:  true,
      sunday:    true,
      thursday:  true,
      tuesday:   true,
      wednesday: true
    }
  }

  componentDidMount() {
    const { hasLocation, hasName, schedule } = this.props;

    this.setState({ hasLocation, hasName, schedule });
  }

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: JSON.parse(value) }, () => {
      this.props.onChange(this.state);
    });
  }

  handleTick = (week) => (value) => {
    this.setState((prevState) => ({
      schedule: {
        ...prevState.schedule,
        [week]: value
      }
    }), () => {
      this.props.onChange(this.state);
    });
  }

  render() {
    return (
      <div className='story-form'>
        <Timetable
            onTick={this.handleTick}
            schedule={this.state.schedule}>
          {['Day(s)']}
        </Timetable>

        <FormGroup
            className='location-toggle'
            row>
          <Label
              sm={2}
              xs={3}>
            Location
          </Label>
          <Col
              className='d-flex'
              sm={10}
              xs={9}>
            <FormGroup
                check
                className='cell'
                inline>
              <Radio
                  id='hasLocationYes'
                  isChecked={this.state.hasLocation === true}
                  name='hasLocation'
                  onChange={this.handleChange}
                  value />
              <Label
                  check
                  htmlFor='input-hasLocationYes'>
                Yes
              </Label>
            </FormGroup>
            <FormGroup
                check
                className='cell'
                inline>
              <Radio
                  id='hasLocationNo'
                  isChecked={this.state.hasLocation === false}
                  name='hasLocation'
                  onChange={this.handleChange}
                  value={false} />
              <Label
                  check
                  htmlFor='input-hasLocationNo'>
                No
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>

        <FormGroup
            className='location-toggle'
            row>
          <Label
              sm={2}
              xs={3}>
            Name
          </Label>
          <Col
              className='d-flex'
              sm={10}
              xs={9}>
            <FormGroup
                check
                className='cell'
                inline>
              <Radio
                  id='hasNameYes'
                  isChecked={this.state.hasName === true}
                  name='hasName'
                  onChange={this.handleChange}
                  value />
              <Label
                  check
                  htmlFor='input-hasNameYes'>
                Yes
              </Label>
            </FormGroup>
            <FormGroup
                check
                className='cell'
                inline>
              <Radio
                  id='hasNameNo'
                  isChecked={this.state.hasName === false}
                  name='hasName'
                  onChange={this.handleChange}
                  value={false} />
              <Label
                  check
                  htmlFor='input-hasNameNo'>
                No
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
      </div>
    );
  }
}

export default ClassInfo;
