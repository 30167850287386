import { connect } from 'react-redux';

import { SendEmail } from '../../components/onDemands';
import { CollectionActions } from '../../actions';

const mapStateToProps = (state) => ({
  instructor:          state.profile.data,
  privateGroupClasses: state.privateGroupClasses.data,
  user:                state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  sendEmail: (data) => dispatch(CollectionActions.SEND_EMAIL(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(SendEmail);
