/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types'; 
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

import { Button } from '../forms';
import { onDemandRoutes, routes } from '../../lib';
import { DragIcon, VisibilityIcon } from '../icons';

class DraggableItem extends React.PureComponent {
  static propTypes = {
    collection: PropTypes.object.isRequired,
    index:      PropTypes.number.isRequired
  }

  render () {
    const { collection, index } = this.props;

    return (
      <Draggable
          draggableId={'draggable-' + collection.id}
          index={index}
          key={collection.id}>
        {(provided, snapshot) => (
          <div
              className={classNames('table-grid__item', { 'draggable': snapshot.isDragging })}
              ref={provided.innerRef}
              {...provided.draggableProps}
              style={{ ...provided.draggableProps.style }}>
            <div className='text-hidden block-item'>
              <span className={classNames({ 'text-muted': !collection.isActive })}>
                {collection.name}
              </span>
            </div>
            <div className='text-center block-item'>{collection.count}</div>
            <div className='text-right block-item'>
              <VisibilityIcon
                  className='text-muted mr-3'
                  id={collection.id}
                  isActive={collection.isActive}
                  size={22}
                  title='collection' />
              <Button
                  isOutline
                  size='sm'
                  tag={Link}
                  to={routes.ON_DEMAND + onDemandRoutes.COLLECTION(collection.id)}>
                Select
              </Button>
              <DragIcon
                  className='text-muted ml-3'
                  dragProps={provided.dragHandleProps}
                  size={22} />
            </div>
          </div>
        )}
      </Draggable>
    );
  }
}

export default DraggableItem;
