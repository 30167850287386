import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';

import { Button } from '../../../../forms';

class GroupClassItemDefault extends React.PureComponent {
  static propTypes = {
    groupClass:         PropTypes.object.isRequired,
    onHandleToggle:     PropTypes.func.isRequired,
    renderIcon:         PropTypes.func.isRequired,
    renderLocationInfo: PropTypes.func.isRequired,
    time:               PropTypes.string.isRequired
  }

  render() {
    const { groupClass, onHandleToggle, renderIcon, renderLocationInfo, time } = this.props;

    return (
      <Card className="card-event body-class__event">
        <div className="body-class__wrapper">
          { groupClass.imageType !== 'none' &&
            <div
                className="body-class__content photo"
                style={{
                  backgroundImage: `url(${groupClass.preview})`,
                  backgroundSize:  'cover'
                }} />
          }
          <div className="body-class__content">
            <div className="body-class__name">{groupClass.name}</div>
            <div className="body-class__time">
              {time}
              &#8722;
              {groupClass.duration + ' min'}
            </div>
            {renderLocationInfo(groupClass)}
          </div>
          <div className="body-class__buttons">
            <Button
                className="mb-2"
                onClick={onHandleToggle(groupClass)}
                size="sm">
              Reserve
            </Button>
            {groupClass.feeType && renderIcon(groupClass.feeType)}
          </div>
        </div>
      </Card>
    );
  }
}

export default GroupClassItemDefault;
