export default {
  FETCH_INSTRUCTORS:         '@@follows/INSTRUCTORS/FETCH',
  FETCH_INSTRUCTORS_FAILURE: '@@follows/INSTRUCTORS/FETCH_FAILURE',
  FETCH_INSTRUCTORS_SUCCESS: '@@follows/INSTRUCTORS/FETCH_SUCCESS',

  FETCH_STUDIOS:         '@@follows/STUDIOS/FETCH',
  FETCH_STUDIOS_FAILURE: '@@follows/STUDIOS/FETCH_FAILURE',
  FETCH_STUDIOS_SUCCESS: '@@follows/STUDIOS/FETCH_SUCCESS',

  FOLLOW_INSTRUCTOR:         '@@follows/INSTRUCTOR/FOLLOW',
  FOLLOW_INSTRUCTOR_FAILURE: '@@follows/INSTRUCTOR/FOLLOW_FAILURE',
  FOLLOW_INSTRUCTOR_SUCCESS: '@@follows/INSTRUCTOR/FOLLOW_SUCCESS',

  FOLLOW_STUDIO:         '@@follows/STUDIO/FOLLOW',
  FOLLOW_STUDIO_FAILURE: '@@follows/STUDIO/FOLLOW_FAILURE',
  FOLLOW_STUDIO_SUCCESS: '@@follows/STUDIO/FOLLOW_SUCCESS',

  UNFOLLOW_INSTRUCTOR:         '@@follows/INSTRUCTOR/UNFOLLOW',
  UNFOLLOW_INSTRUCTOR_FAILURE: '@@follows/INSTRUCTOR/UNFOLLOW_FAILURE',
  UNFOLLOW_INSTRUCTOR_SUCCESS: '@@follows/INSTRUCTOR/UNFOLLOW_SUCCESS',

  UNFOLLOW_STUDIO:         '@@follows/STUDIO/UNFOLLOW',
  UNFOLLOW_STUDIO_FAILURE: '@@follows/STUDIO/UNFOLLOW_FAILURE',
  UNFOLLOW_STUDIO_SUCCESS: '@@follows/STUDIO/UNFOLLOW_SUCCESS'
};
