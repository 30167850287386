import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';
import { Container, Label, Media } from 'reactstrap';

import { Checkbox } from '../../forms';
import { Heading, Panel } from '../../layout/pages';
import { BackButton } from '../../buttons';
import { routes } from '../../../lib';

class SettingsNotificationsPage extends React.PureComponent {
  static propTypes = {
    instructor:    PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired
  };

  static getDerivedStateFromProps(props, state) {
    const { instructor } = props;

    if (instructor.id && !state.isMounted) {
      return {
        isMounted:             true,
        notificationsEnabled:  instructor.notificationsEnabled,
        scheduleRemindEnabled: instructor.scheduleRemindEnabled
      };
    }

    return state;
  }

  state = {
    isMounted:            false,
    notificationsEnabled: false
  };

  handleChange = (setting) => () => {
    this.setState((prevState) => ({
      [setting]: !prevState[setting]
    }), () => {
      this.handleUpdate(setting);
    });
  };

  handleUpdate = (setting) => {
    this.props.updateProfile({ [setting]: this.state[setting] });
  };

  render() {
    const { notificationsEnabled, scheduleRemindEnabled } = this.state;

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS} />}

        <Helmet>
          <title>Edit your profile</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container fluid>
            <Heading
                color='secondary'
                subtitle='Creator Portal'
                title='Email Notifications' />
            <Panel className='settings-menu'>
              <h4 className='menu-title'>Your Notifications</h4>
              <Media className='menu-link d-block'>
                <Media body>
                  <div className='mb-3 custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='notification'
                        isChecked={notificationsEnabled}
                        name='notificationsEnabled'
                        onChange={this.handleChange('notificationsEnabled')} />
                    <Label
                        className='custom-control-label d-flex'
                        htmlFor='input-notification'>
                      Recelve an email each time a client completes a transaction
                    </Label>
                  </div>
                  <small className='text-muted'>
                    Checking this will mean you will receive an email notifying you for each
                    Live Class booking and On-Demand purchase made by a client
                  </small>
                </Media>
              </Media>

              <Media className='menu-link d-block'>
                <Media body>
                  <div className='mb-3 custom-control custom-checkbox'>
                    <Checkbox
                        className='custom-control-input'
                        id='reminder'
                        isChecked={scheduleRemindEnabled}
                        name='scheduleRemindEnabled'
                        onChange={this.handleChange('scheduleRemindEnabled')} />
                    <Label
                        className='custom-control-label d-flex'
                        htmlFor='input-reminder'>
                      Send a 1-hour email reminder to clients before the class starts
                    </Label>
                  </div>
                </Media>
              </Media>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default SettingsNotificationsPage;
