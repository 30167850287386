import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter, map } from 'lodash';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';

import { ProfileFormatter, api, extractError, routes, toCamelCase } from '../lib';
import { LocationsActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions, LocationsActions, ProfileActions } from '../actions';

function* watchCreate() {
  yield takeLatest(LocationsActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.location.create, payload);
      const profile = yield select((state) => state.profile.data);
      const user = yield(select((state) => state.user.data));
      const data = response.data;

      yield put(LocationsActions.CREATE_SUCCESS(toCamelCase(data)));
      yield put(ProfileActions.UPDATE_SUCCESS(
        ProfileFormatter._toClient({
          ...profile,
          locations: [...profile.locations, { id: data.id, name: data.name }]},
          user
        )
      ));

      toastr.success('Location added');
    } catch (error) {
      yield put(LocationsActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(LocationsActionTypes.CREATE_SUCCESS, function* () {
    yield put(push(routes.LOCATIONS));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(LocationsActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDelete() {
  yield takeLatest(LocationsActionTypes.DELETE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.location.delete, payload);

      const locations = yield select((state) => state.locations.data);
      const data = filter(locations, (location) => (location.id != payload.location.id));

      yield put(LocationsActions.DELETE_SUCCESS(data));
      toastr.success('Location deleted');
    } catch (error) {
      yield put(LocationsActions.DELETE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchDeleteSuccess() {
  yield takeLatest(LocationsActionTypes.DELETE_SUCCESS, function* () {
    yield put(push(routes.LOCATIONS));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchDeleteFailure() {
  yield takeLatest(LocationsActionTypes.DELETE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetch() {
  yield takeLatest(LocationsActionTypes.FETCH, function* () {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.location.fetch);

      yield put(LocationsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(LocationsActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(LocationsActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchFailure() {
  yield takeLatest(LocationsActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdate() {
  yield takeLatest(LocationsActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.location.update, payload);
      const locations = yield select((state) => state.locations.data);
      const data = map(locations, (location) => (
        location.id == payload.location.id ? toCamelCase(response.data) : location
      ));

      yield put(LocationsActions.UPDATE_SUCCESS(data));
      toastr.success('Location updated');
    } catch (error) {
      yield put(LocationsActions.UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(LocationsActionTypes.UPDATE_SUCCESS, function* () {
    yield put(push(routes.LOCATIONS));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateFailure() {
  yield takeLatest(LocationsActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchGet() {
  yield takeLatest(LocationsActionTypes.GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('locations'));
      const response = yield call(api.location.get, payload);

      yield put(LocationsActions.GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(LocationsActions.GET_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchGetSuccess() {
  yield takeLatest(LocationsActionTypes.GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('locations'));
  });
}

function* watchGetFailure() {
  yield takeLatest(LocationsActionTypes.GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('locations'));
  });
}

export default function* locationsSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),
    watchDelete(),
    watchDeleteSuccess(),
    watchDeleteFailure(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure(),
    watchGet(),
    watchGetSuccess(),
    watchGetFailure()
  ]);
}
