import { DonationTargetsActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: DonationTargetsActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: DonationTargetsActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: DonationTargetsActionTypes.CREATE_SUCCESS
  }),

  FETCH: () => ({
    type: DonationTargetsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: DonationTargetsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: DonationTargetsActionTypes.FETCH_SUCCESS
  }),

  INSTRUCTORS_FETCH: (payload) => ({
    payload, type: DonationTargetsActionTypes.INSTRUCTORS_FETCH
  }),

  INSTRUCTORS_FETCH_FAILURE: () => ({
    type: DonationTargetsActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_FETCH_SUCCESS: (payload) => ({
    payload, type: DonationTargetsActionTypes.INSTRUCTORS_FETCH_SUCCESS
  }),

  UPDATE: (payload) => ({
    payload, type: DonationTargetsActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: DonationTargetsActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: DonationTargetsActionTypes.UPDATE_SUCCESS
  })
};
