import { PlansActionTypes } from '../constants/actionTypes';

export default {
  FETCH: () => ({
    type: PlansActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: PlansActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload,
    type: PlansActionTypes.FETCH_SUCCESS
  }),


  SUBSCRIPTION_CREATE: (payload) => ({
    payload,
    type: PlansActionTypes.SUBSCRIPTION_CREATE
  }),

  SUBSCRIPTION_CREATE_FAILURE: () => ({
    type: PlansActionTypes.SUBSCRIPTION_CREATE_FAILURE
  }),

  SUBSCRIPTION_CREATE_SUCCESS: (payload) => ({
    payload,
    type: PlansActionTypes.SUBSCRIPTION_CREATE_SUCCESS
  }),


  SUBSCRIPTION_GET: () => ({
    type: PlansActionTypes.SUBSCRIPTION_GET
  }),

  SUBSCRIPTION_GET_FAILURE: () => ({
    type: PlansActionTypes.SUBSCRIPTION_GET_FAILURE
  }),

  SUBSCRIPTION_GET_SUCCESS: (payload) => ({
    payload,
    type: PlansActionTypes.SUBSCRIPTION_GET_SUCCESS
  })
};
