/* global process */
import React from 'react';
import PropTypes from 'prop-types';
import { Label, ModalBody, ModalFooter } from 'reactstrap';
import { capitalize, isEmpty, padStart } from 'lodash';
import { Link } from 'react-router-dom';

import { CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { StripeBadge } from '../../badges';
import { Button, ChargeForm, Checkbox, PaymentIcon, Radio } from '../../forms';
import { Moment, StripeHelper, api, getTimeZone, routes, toCamelCase } from '../../../lib';
import { FEES } from '../../../constants';
import { Input } from '../../inputs';
import { UserModalAvatar } from '../layouts';

class Charge extends React.PureComponent {
  static propTypes = {
    client:         PropTypes.object,
    createDiscount: PropTypes.func.isRequired,
    discount:       PropTypes.object,
    instructor:     PropTypes.object.isRequired,
    isLoading:      PropTypes.bool.isRequired,
    onCharge:       PropTypes.func.isRequired,
    onLoading:      PropTypes.func.isRequired,
    requestedClass: PropTypes.object.isRequired,
    user:           PropTypes.object.isRequired
  }

  static defaultProps = {
    amount:   0,
    client:   {},
    discount: {},
    onBook:   () => {},
    onNext:   () => {}
  }

  static getDerivedStateFromProps(props, state) {

    if (!state.isMounted) {
      return {
        amount:     props.requestedClass.cost,
        clientName: props.client.name || props.user.firstName +  ' ' + props.user.lastName,
        fee:        FEES.charge(props.requestedClass.cost),
        isMounted:  true
      };
    }
  }

  state = {
    amount:         '',
    cards:          [],
    clientName:     '',
    code:           '',
    data:           {},
    error:          {},
    fee:            '',
    isMounted:      false,
    openInput:      false,
    selectedCard:   '',
    shouldSaveCard: false
  }

  componentDidMount() {
    const { onLoading } = this.props;

    onLoading(true, () => {
      if (this.props.user.id) {
        api.card.get({ username: this.props.instructor.username })
          .then((response) => (
            this.setState({
              cards:        response.data,
              selectedCard: response.data[0] || 'new'
            }, () => onLoading(false))
          ))
          .catch(() => {
            this.setState({ selectedCard: 'new' }, () => onLoading(false));
          });
      } else {
        this.setState({ selectedCard: 'new' }, () => onLoading(false));
      }
    });
  }

  handleApply = () => {
    const { code } = this.state;
    const { client, instructor } = this.props;

    this.props.createDiscount({ discount: {
      clientId:     client.id,
      code,
      instructorId: instructor.id,
      target:       'onDemand'
    }});

    this.setState({ code: '', openInput: false });
  }

  handleChange = (value) => (
    this.setState({ shouldSaveCard: value })
  )

  handleOption = (card) => () => (
    this.setState({ selectedCard: card })
  )

  handleChangeInput = (value) => (
    this.setState({ code: value })
  )

  handleToggle = () => (
    this.setState((prevState) => ({ openInput: !prevState.openInput }))
  )

  handleSubmit = (elements, stripe) => async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const { discount } = this.props;

    const isDiscounted = discount.id;
    const { coupon } = discount;

    isDiscounted && coupon.discountType == 'free' ? this.freeSystem() : this.paidSystem(elements, stripe);
  }

  paidSystem = (elements, stripe) => {
    const { clientName, selectedCard } = this.state;
    const { client, instructor, discount, onLoading, requestedClass, user } = this.props;

    const description = `${clientName} purchased access to ${requestedClass.name} on-demand`;

    const params = {
      chargable:   'Instructor::OnDemand',
      chargableId: requestedClass.id,
      charges:     'cost',
      clientId:    client.id,
      description,
      discountId:  discount.id,
      hold:        false,
      recipientId: instructor.id
    };

    const card = elements.getElement(CardElement);

    if (this.state.selectedCard == 'new') {
      if (this.state.cards.length || !this.state.shouldSaveCard) {
        StripeHelper.createPaymentIntent(stripe, card, params, 'default', onLoading, this.setState.bind(this), () => {
          const { data } = this.state;

          StripeHelper.confirmPayment(stripe, card, data, user, onLoading, this.setState.bind(this), () => (
            this.props.onCharge(toCamelCase(this.state.data))
          ));
        });
      } else {
        StripeHelper.createPaymentIntent(stripe, card, params, 'token', onLoading, this.setState.bind(this), () => {
          const { data } = this.state;

          StripeHelper.confirmPayment(stripe, card, data, user, onLoading, this.setState.bind(this), () => (
            this.props.onCharge(toCamelCase(this.state.data))
          ));
        });
      }
    } else {
      const chosenCard = selectedCard;

      StripeHelper.createPaymentIntent(stripe, chosenCard, params, 'card', onLoading, this.setState.bind(this), () => (
        this.props.onCharge(toCamelCase(this.state.data))
      ));
    }
  }

  freeSystem = () => {
    const { client, discount, instructor, onLoading, requestedClass } = this.props;

    onLoading(true, () => (
      api.charge.create({ charge: {
        chargable:   'Instructor::OnDemand',
        chargableId: requestedClass.id,
        charges:     'cost',
        clientId:    client.id,
        discountId:  discount.id,
        method:      'default',
        recipientId: instructor.id
      }}).then((response) => (
        onLoading(false, () => (
          this.props.onCharge(toCamelCase({ ...this.state.data, chargeId: response.data.charge_id }))
        ))
      ))
    ));
  }

  renderOptions = () => (
    <React.Fragment>
      <div className='text-muted text-left mb-2 modal__default-text'>
        I&apos;d like to pay with
      </div>

      <div className='text-left'>
        {this.state.cards.map((card) => (
          <div
              className='custom-control custom-radio'
              key={card.id}>
            <Radio
                className='custom-control-input'
                id={card.id}
                isChecked={this.state.selectedCard.id == card.id}
                name='selectedCard'
                onChange={this.handleOption(card)}
                value={false} />
            <Label
                className='custom-control-label pb-1 modal__default-text'
                htmlFor={'input-' + card.id}>
              <span className='mr-3'>
                <PaymentIcon icon={card.card.brand} />
              </span>

              <span className='mr-3'>
                {capitalize(card.card.brand)} ****{card.card.last4}&nbsp;
              </span>

              {padStart(card.card.exp_month)}/{String(card.card.exp_year).slice(-2)}
            </Label>
          </div>
        ))}

        { !!this.state.cards.length &&
          <div className='custom-control custom-radio'>
            <Radio
                className='custom-control-input'
                id='new'
                isChecked={this.state.selectedCard == 'new'}
                name='selectedCard'
                onChange={this.handleOption('new')}
                value={false} />
            <Label
                className='custom-control-label pb-1 modal__default-text'
                htmlFor='input-new'>
              Another card
            </Label>
          </div>
        }

        {this.state.selectedCard != 'new' && <StripeBadge />}
      </div>
    </React.Fragment>
  )

  renderCost = () => {
    const { amount, fee } = this.state;
    const { discount } = this.props;
    const { currency } = this.props.instructor;
    const { coupon } = discount;

    const isDiscounted = discount.id;
    const isFree = isDiscounted && coupon.discountType == 'free';

    const discountPercent = isDiscounted && coupon.percent * 0.01;
    const discountAmount = amount * discountPercent;

    const amountWithDiscount = amount - discountAmount;

    const discountFee = isDiscounted ? amountWithDiscount * 0.03 + 0.49 : fee;
    const price = isDiscounted ? amountWithDiscount : amount;

    const renderPrice = () => (
      <span>
        {parseFloat(price + discountFee, 10).toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
        <span className='modal__event-fee text-muted'>
          (incl. {parseFloat(discountFee, 10).toLocaleString('en-GB', { currency, style: 'currency' })} fee)
        </span>
      </span>
    );

    return (
      <div
          className='modal__event-cost
                     modal__default-text
                     modal__default-text_blue
                     modal__default-text_bold
                     d-inline'>
        Total Cost:&nbsp;
        <React.Fragment>
          {!isDiscounted && renderPrice()}

          { isDiscounted &&
            <span>
              &nbsp;
              {isFree && 'Free'}
              {!isFree && renderPrice()}
            </span>
          }
        </React.Fragment>
      </div>
    );
  }

  render() {
    const { discount, instructor, isLoading, requestedClass, user } = this.props;
    const { cards } = this.state;

    const createdAt = new Moment.utc(requestedClass.createdAt, 'YYYY-MM-DDhh:mm:ssUTC')
                                .tz(getTimeZone(user.timeZone));

    const stripePromise = () => (
      loadStripe(process.env.STRIPE_API_KEY, { stripeAccount: instructor.stripeId })
    );

    const { coupon } = discount;

    const isDiscounted = discount.id;
    const isFree = isDiscounted && coupon.discountType == 'free';

    return (
      <Elements
          key='elements'
          stripe={stripePromise()}>
        <ElementsConsumer>
          {({elements, stripe}) => (
            <div className='new-modal'>
              <ModalBody>
                { instructor &&
                  <div className='modal__header'>
                    <UserModalAvatar
                        alt={instructor.name}
                        firstName={instructor.name}
                        src={instructor.photo.sm} />
                    <div className='modal__instructor-name'>
                      <span className='modal__big-text'>{instructor.name}</span>
                    </div>
                  </div>
                }

                <div className='modal__event-name'>
                  <span className='modal__big-text modal__big-text_black'>{requestedClass.name}</span>
                </div>

                <div>
                  <span className='modal__default-text modal__default-text_bold'>Uploaded | </span>
                  {createdAt.format('MMM, DD, YYYY')} at {createdAt.format('hh:mma')}
                </div>

                <div className='modal__event-timezone modal__small-text'>
                  {new Moment.tz(getTimeZone(user.timeZone)).format('z')} (GMT
                  {new Moment.tz(getTimeZone(user.timeZone)).format('Z')} {getTimeZone(user.timeZone)})
                </div>

                <div className='modal__event-location modal__small-text'>
                  Virtual
                </div>

                { requestedClass.canExpire &&
                  <div className='modal__default-text modal__default-text_bold mb-3'>
                    Note: Video access will expire after {requestedClass.expiry} days
                  </div>
                }

                <div className='mb-1'>
                  <div
                      className='modal__event-cost
                                modal__default-text_blue
                                modal__default-text
                                m-0 pb-1 pointer'
                      onClick={this.handleToggle}
                      onKeyPress={this.handleToggle}
                      role='button'
                      tabIndex={0}>
                    Apply a Coupon Code?
                  </div>
                  {this.state.openInput &&
                    <React.Fragment>
                      <div className='d-flex'>
                        <Input
                            className='modal__code-input'
                            id='name'
                            name='name'
                            onChange={this.handleChangeInput}
                            placeholder='Code'
                            type='text'
                            value={this.state.code} />

                        <Button
                            className='modal__apply-button'
                            color='success'
                            onClick={this.handleApply}>
                          Apply
                        </Button>
                      </div>

                      {this.state.couponError && <div className='text-danger text-sm'>{this.state.couponError}</div>}
                    </React.Fragment>
                  }
                </div>

                {this.renderCost()}
                {!isFree && !isLoading && !!cards.length && this.renderOptions()}

                { !isFree && this.state.selectedCard == 'new' &&
                  <ChargeForm
                      elements={elements}
                      error={this.state.error.message}
                      stripe={stripe}>
                    { !cards.length && user.id &&
                      <div className='save-card'>
                        <div className='custom-control custom-checkbox checkbox-lg'>
                          <Checkbox
                              className='custom-control-input'
                              id='shouldSaveCard'
                              isChecked={this.state.shouldSaveCard}
                              name='shouldSaveCard'
                              onChange={this.handleChange} />
                          <Label
                              className='custom-control-label text-left pt-2'
                              for='input-shouldSaveCard'>
                            Save card to account for future use
                          </Label>
                        </div>
                      </div>
                    }
                  </ChargeForm>
                }

                { !user.id &&
                  <small>
                    <div>Have a membership or package?</div>
                    <Link to={routes.LOGIN}>Log-in here</Link>
                  </small>
                }
              </ModalBody>

              <ModalFooter>
                {isEmpty(instructor.oauth.stripe) &&
                  <div className='modal__default-text text-danger mt-4 mb-3'>
                    Instructor has disabled stripe payments. For more information contact 
                    <a href={`mailto:${instructor.email}`}>&nbsp;{instructor.email}</a>
                  </div>
                }
                <Button
                    className='ml-0'
                    color='blue'
                    isBlock
                    isDisabled={!stripe || isEmpty(instructor.oauth.stripe)}
                    isLoading={isLoading}
                    onClick={this.handleSubmit(elements, stripe)}
                    size='lg'>
                  Pay Now
                </Button>
              </ModalFooter>
            </div>
          )}
        </ElementsConsumer>
      </Elements>
    );
  }
}

export default Charge;
