import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { filter, map } from 'lodash';
import { push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';

import { api, extractError, routes, toCamelCase } from '../lib';
import { MembershipsActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions, MembershipsActions } from '../actions';

function* watchCreate() {
  yield takeLatest(MembershipsActionTypes.CREATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.instructor.membership.create, payload);

      yield put(MembershipsActions.CREATE_SUCCESS(toCamelCase(response.data)));
      toastr.success('Membership added');
    } catch (error) {
      yield put(MembershipsActions.CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCreateSuccess() {
  yield takeLatest(MembershipsActionTypes.CREATE_SUCCESS, function* () {
    yield put(push(routes.SETTINGS_PACKAGESS));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCreateFailure() {
  yield takeLatest(MembershipsActionTypes.CREATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCancel() {
  yield takeLatest(MembershipsActionTypes.CANCEL, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield call(api.instructor.membership.delete, payload);

      const memberships = yield select((state) => state.memberships.data);
      const data = filter(memberships, (membership) => (membership.id !== payload.membership.id));

      yield put(MembershipsActions.CANCEL_SUCCESS(data));
      toastr.success('Membership canceled');
    } catch (error) {
      yield put(MembershipsActions.CANCEL_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchCancelSuccess() {
  yield takeLatest(MembershipsActionTypes.CANCEL_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchCancelFailure() {
  yield takeLatest(MembershipsActionTypes.CANCEL_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetch() {
  yield takeLatest(MembershipsActionTypes.FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('memberships'));
      const response = yield call(api.membership.fetch, payload);

      yield put(MembershipsActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(MembershipsActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchSuccess() {
  yield takeLatest(MembershipsActionTypes.FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('memberships'));
  });
}

function* watchFetchFailure() {
  yield takeLatest(MembershipsActionTypes.FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('memberships'));
  });
}

function* watchDeletedFetch() {
  yield takeLatest(MembershipsActionTypes.DELETED_FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.deleted.memberships, payload);

      yield put(MembershipsActions.DELETED_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(MembershipsActions.DELETED_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFetchDeletedSuccess() {
  yield takeLatest(MembershipsActionTypes.DELETED_FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFetchDeletedFailure() {
  yield takeLatest(MembershipsActionTypes.DELETED_FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsFetch() {
  yield takeLatest(MembershipsActionTypes.INSTRUCTORS_FETCH, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('memberships'));
      const response = yield call(api.instructor.membership.fetch, payload);

      yield put(MembershipsActions.INSTRUCTORS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(MembershipsActions.INSTRUCTORS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsFetchSuccess() {
  yield takeLatest(MembershipsActionTypes.INSTRUCTORS_FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('memberships'));
  });
}

function* watchInstructorsFetchFailure() {
  yield takeLatest(MembershipsActionTypes.INSTRUCTORS_FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('memberships'));
  });
}

function* watchUpdate() {
  yield takeLatest(MembershipsActionTypes.UPDATE, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      const response = yield call(api.instructor.membership.update, payload);
      const memberships = yield select((state) => state.memberships.data);
      const data = map(memberships, (membership) => (
        membership.id === payload.membership.id ? toCamelCase(response.data) : membership
      ));

      yield put(MembershipsActions.UPDATE_SUCCESS(data));
      toastr.success('Membership updated');
    } catch (error) {
      yield put(MembershipsActions.UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUpdateSuccess() {
  yield takeLatest(MembershipsActionTypes.UPDATE_SUCCESS, function* () {
    yield put(push(routes.SETTINGS_PACKAGESS));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchUpdateFailure() {
  yield takeLatest(MembershipsActionTypes.UPDATE_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchInstructorsGet() {
  yield takeLatest(MembershipsActionTypes.INSTRUCTORS_GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('memberships'));
      const response = yield call(api.instructor.membership.get, payload);

      yield put(MembershipsActions.INSTRUCTORS_GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(MembershipsActions.INSTRUCTORS_GET_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchInstructorsGetSuccess() {
  yield takeLatest(MembershipsActionTypes.INSTRUCTORS_GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('memberships'));
  });
}

function* watchInstructorsGetFailure() {
  yield takeLatest(MembershipsActionTypes.INSTRUCTORS_GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('memberships'));
  });
}

export default function* membershipsSaga() {
  yield all([
    watchCreate(),
    watchCreateSuccess(),
    watchCreateFailure(),
    watchCancel(),
    watchCancelSuccess(),
    watchCancelFailure(),
    watchFetch(),
    watchFetchSuccess(),
    watchFetchFailure(),
    watchDeletedFetch(),
    watchFetchDeletedSuccess(),
    watchFetchDeletedFailure(),
    watchInstructorsFetch(),
    watchInstructorsFetchSuccess(),
    watchInstructorsFetchFailure(),
    watchUpdate(),
    watchUpdateSuccess(),
    watchUpdateFailure(),
    watchInstructorsGet(),
    watchInstructorsGetSuccess(),
    watchInstructorsGetFailure()
  ]);
}
