import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import { confirmAlert } from 'react-confirm-alert';

import { Button, Checkbox, Form, MaskedInputInteger, Radio } from '../';
import { procentMask } from '../../../constants/masks';
import { Input } from '../../inputs';
import { Scroller, Validator } from '../../../lib';
import { InstructorDeleteCoupon } from '../../alerts';

class CouponForm extends React.PureComponent {
  static propTypes = {
    createCoupon: PropTypes.func.isRequired,
    data:         PropTypes.object,
    deleteCoupon: PropTypes.func.isRequired,
    isLoading:    PropTypes.bool,
    isNewRecord:  PropTypes.bool,
    onToggle:     PropTypes.func.isRequired,
    updateCoupon: PropTypes.func.isRequired
  }

  static defaultProps = {
    data: {
      appliesFor: {
        challenge:      true,
        classPack:      true,
        live:           true,
        onDemand:       true,
        privateSession: true
      },
      code:         '',
      discountType: 'free',
      limited:      true,
      name:         '',
      percent:      ''
    },
    isLoading:   true,
    isNewRecord: true
  };

  static getDerivedStateFromProps(props, state) {
    const { isNewRecord, isLoading, data } = props;

    if (!isLoading) {
      const coupon = isNewRecord
        ? CouponForm.defaultProps.data
        : {
          ...props.data,
          appliesFor: {
            challenge:      data.appliesFor && data.appliesFor.challenge  == 'true',
            classPack:      data.appliesFor && data.appliesFor.classPack  == 'true',
            live:           data.appliesFor && data.appliesFor.live       == 'true',
            onDemand:       data.appliesFor && data.appliesFor.onDemand   == 'true',
            privateSession: data.appliesFor && data.appliesFor.privateSession == 'true'
          }
        };

      if (!state.isMounted) {
        return {
          coupon,
          isMounted: true,
          isNewRecord
        };
      }
    }

    return null;
  }

  state = {
    coupon:    {},
    errors:    {},
    isMounted: false
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      coupon: {
        ...prevState.coupon,
        [inputName]: value
      }
    }));
  };

  handleDiscountTypeSwitch = (discountType) => () => (
    this.setState((prevState) => ({
      coupon: {
        ...prevState.coupon,
        discountType,
        percent: ''
      }
    }))
  )

  handleToggle = (value, inputName) => {
    this.setState((prevState) => ({
      coupon: {
        ...prevState.coupon,
        appliesFor: {
          ...prevState.coupon.appliesFor,
          [inputName]: value
        }
      }
    }));
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { coupon } = this.state;

    const errors = Validator.validate([
      ['name',    Validator.concepts.isFilled,        [coupon.name]],
      ['percent', Validator.concepts.isFilled,        [coupon.percent], coupon.discountType == 'free'],
      ['code',    Validator.concepts.isFilled,        [coupon.code]],
      ['code',    Validator.concepts.spaceValidator,  [coupon.code, 'The coupon code must not have any spaces']],
      ['percent', Validator.concepts.moreThanHundred, [coupon.percent], coupon.discountType == 'free']
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      Scroller._scroll('smooth');
      this.setState({ errors }, () => reject(errors));
    }
  })

  handleSubmit = () => {
    const { coupon } = this.state;
    const { isNewRecord, createCoupon, updateCoupon } = this.props;

    return this.handleValidate().then(() => {
      isNewRecord ? createCoupon({ coupon }) : updateCoupon({ coupon });
      return this.props.onToggle();
    });
  }

  handleDelete = () => {
    const deleteOptions = {
      closeOnClickOutside: false,
      customUI:            ({ onClose }) => (
        <InstructorDeleteCoupon
            coupon={this.state.coupon}
            deleteCoupon={this.props.deleteCoupon}
            onClose={onClose}
            onSuccess={this.props.onToggle} />
    )};
    confirmAlert(deleteOptions);
  }


  render() {
    const { isNewRecord } = this.props;
    const { coupon, errors } = this.state;

    return (
      <Form className='form-default'>
        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Coupon Name
          </Label>
          <Input
              id='name'
              isInvalid={!!errors.name}
              name='name'
              onChange={this.handleChange}
              placeholder='October 20% Promo'
              type='text'
              value={coupon.name} />
          <FormFeedback tooltip>
            {errors.name}
          </FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label
              className='required'
              for='input-fee'>
            Discount Type
          </Label>

          <div className='d-flex'>
            <div className='custom-control custom-radio w-50'>
              <Radio
                  className='custom-control-input'
                  id='free'
                  isChecked={coupon.discountType === 'free'}
                  name='free'
                  onChange={this.handleDiscountTypeSwitch('free')}
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-free'>
                Free
              </Label>
            </div>

            <div className='custom-control custom-radio w-50'>
              <Radio
                  className='custom-control-input'
                  id='"percentage"'
                  isChecked={coupon.discountType === 'percentage'}
                  name='"percentage"'
                  onChange={this.handleDiscountTypeSwitch('percentage')}
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-"percentage"'>
                Percent Off
              </Label>
            </div>
          </div>
        </FormGroup>

        { coupon.discountType == 'percentage' &&
          <FormGroup>
            <Label
                className='required'
                for='input-name'>
              Discount Percentage
            </Label>

            <MaskedInputInteger
                id='contributionAmount'
                inputMode='number'
                isInvalid={!!errors.percent}
                mask={procentMask}
                name='percent'
                onChange={this.handleChange}
                placeholder='20%'
                type='contributionAmount'
                value={String(coupon.percent)} />
            <FormFeedback tooltip>
              {errors.code}
            </FormFeedback>
          </FormGroup>
        }

        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Discount Code
          </Label>
          <Input
              id='code'
              isInvalid={!!errors.code}
              name='code'
              onChange={this.handleChange}
              placeholder='e.g. 20OFF'
              type='text'
              value={coupon.code} />
          <FormFeedback tooltip>
            {errors.code}
          </FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label>
            This coupon can be used for...
          </Label>

          <div className='custom-control custom-checkbox checkbox-md'>
            <Checkbox
                className='custom-control-input'
                id='appliesFor-live'
                isChecked={coupon.appliesFor && coupon.appliesFor.live}
                name='live'
                onChange={this.handleToggle} />
            <Label
                className='custom-control-label'
                htmlFor='input-appliesFor-live'>
              Live Classes
            </Label>
          </div>
        </FormGroup>

        <FormGroup>
          <div className='custom-control custom-checkbox checkbox-md'>
            <Checkbox
                className='custom-control-input'
                id='appliesFor-on-demand'
                isChecked={coupon.appliesFor && coupon.appliesFor.onDemand}
                name='onDemand'
                onChange={this.handleToggle} />
            <Label
                className='custom-control-label'
                htmlFor='input-appliesFor-on-demand'>
              On Demand Classes
            </Label>
          </div>
        </FormGroup>

        <FormGroup>
          <div className='custom-control custom-checkbox checkbox-md'>
            <Checkbox
                className='custom-control-input'
                id='appliesFor-challenge'
                isChecked={coupon.appliesFor && coupon.appliesFor.challenge}
                name='challenge'
                onChange={this.handleToggle} />
            <Label
                className='custom-control-label'
                htmlFor='input-appliesFor-challenge'>
              Challenges
            </Label>
          </div>
        </FormGroup>

        <FormGroup>
          <div className='custom-control custom-checkbox checkbox-md'>
            <Checkbox
                className='custom-control-input'
                id='appliesFor-classPack'
                isChecked={coupon.appliesFor && coupon.appliesFor.classPack}
                name='classPack'
                onChange={this.handleToggle} />
            <Label
                className='custom-control-label'
                htmlFor='input-appliesFor-classPack'>
              Class Packs
            </Label>
          </div>
        </FormGroup>

        <FormGroup>
          <div className='custom-control custom-checkbox checkbox-md'>
            <Checkbox
                className='custom-control-input'
                id='appliesFor-privateSession'
                isChecked={coupon.appliesFor && coupon.appliesFor.privateSession}
                name='privateSession'
                onChange={this.handleToggle} />
            <Label
                className='custom-control-label'
                htmlFor='input-appliesFor-privateSession'>
              Private Sessions
            </Label>
          </div>
        </FormGroup>

        <FormGroup>
          <Label>
            Usage Limit
          </Label>

          <div className='custom-control custom-checkbox checkbox-md'>
            <Checkbox
                className='custom-control-input'
                id='limited'
                isChecked={coupon.limited}
                name='limited'
                onChange={this.handleChange} />
            <Label
                className='custom-control-label'
                htmlFor='input-limited'>
              Limit to once per customer
            </Label>
          </div>
        </FormGroup>
        <Row className={isNewRecord ? 'mt-4' : 'mt-2'}>
          { isNewRecord &&
            <Col>
              <Button
                  isBlock
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Create Coupon
              </Button>
            </Col>
          }

          { !isNewRecord &&
            <React.Fragment>
              <Button
                  isBlock
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Update Coupon
              </Button>

              <Button
                  color='danger'
                  isBlock
                  isOutline
                  onClick={this.handleDelete}
                  size='lg'
                  type='button'>
                Delete Coupon
              </Button>
            </React.Fragment>
          }
        </Row>
      </Form>
    );
  }
}

export default CouponForm;
