import { PartnerActionTypes } from '../constants/actionTypes';

const initialState = {
  data:       [],
  isLoading:  false,
  lastAction: ''
};

export default (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case PartnerActionTypes.FETCH:
      return { ...newState, isLoading: true };

    case PartnerActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload, isLoading: false };

    case PartnerActionTypes.FETCH_FAILURE:
      return { ...newState, isLoading: false };

    default:
      return state;
  }
};