import { PrivateGroupClassesActionTypes } from '../constants/actionTypes';

export default {
  CREATE: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: PrivateGroupClassesActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.CREATE_SUCCESS
  }),


  DELETE: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.DELETE
  }),

  DELETE_FAILURE: () => ({
    type: PrivateGroupClassesActionTypes.DELETE_FAILURE
  }),

  DELETE_SUCCESS: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.DELETE_SUCCESS
  }),


  FETCH: () => ({
    type: PrivateGroupClassesActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: PrivateGroupClassesActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.FETCH_SUCCESS
  }),


  INSTRUCTORS_FETCH: () => ({
    type: PrivateGroupClassesActionTypes.INSTRUCTORS_FETCH
  }),

  INSTRUCTORS_FETCH_FAILURE: () => ({
    type: PrivateGroupClassesActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_FETCH_SUCCESS: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.INSTRUCTORS_FETCH_SUCCESS
  }),


  INSTRUCTORS_GET: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.INSTRUCTORS_GET
  }),

  INSTRUCTORS_GET_FAILURE: () => ({
    type: PrivateGroupClassesActionTypes.INSTRUCTORS_GET_FAILURE
  }),

  INSTRUCTORS_GET_SUCCESS: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.INSTRUCTORS_GET_SUCCESS
  }),


  INSTRUCTORS_UPDATE: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.INSTRUCTORS_UPDATE
  }),

  INSTRUCTORS_UPDATE_FAILURE: () => ({
    type: PrivateGroupClassesActionTypes.INSTRUCTORS_UPDATE_FAILURE
  }),

  INSTRUCTORS_UPDATE_SUCCESS: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.INSTRUCTORS_UPDATE_SUCCESS
  }),


  RECURRING_CREATE: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.RECURRING_CREATE
  }),

  RECURRING_CREATE_FAILURE: () => ({
    type: PrivateGroupClassesActionTypes.RECURRING_CREATE_FAILURE
  }),

  RECURRING_CREATE_SUCCESS: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.RECURRING_CREATE_SUCCESS
  }),


  RECURRING_DELETE: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.RECURRING_DELETE
  }),

  RECURRING_DELETE_FAILURE: () => ({
    type: PrivateGroupClassesActionTypes.RECURRING_DELETE_FAILURE
  }),

  RECURRING_DELETE_SUCCESS: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.RECURRING_DELETE_SUCCESS
  }),


  RECURRING_UPDATE: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.RECURRING_UPDATE
  }),

  RECURRING_UPDATE_FAILURE: () => ({
    type: PrivateGroupClassesActionTypes.RECURRING_UPDATE_FAILURE
  }),

  RECURRING_UPDATE_SUCCESS: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.RECURRING_UPDATE_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: PrivateGroupClassesActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: PrivateGroupClassesActionTypes.UPDATE_SUCCESS
  })
};
