import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';

import { DateHelper, Moment, getTimeZone } from '../../../lib';
import { BookingModal, PrivateBookingModal } from '../../modals';
import { Button } from '../../forms';
import { Donation, Free, Paid } from '../../../images';

class ClassBox extends React.PureComponent {
  static propTypes = {
    challenges:           PropTypes.object.isRequired,
    classPacks:           PropTypes.array.isRequired,
    createBooking:        PropTypes.func.isRequired,
    createDiscount:       PropTypes.func.isRequired,
    createPrivateBooking: PropTypes.func.isRequired,
    discount:             PropTypes.object.isRequired,
    fetchClassPacks:      PropTypes.func.isRequired,
    fetchMemberships:     PropTypes.func.isRequired,
    groupClass:           PropTypes.object,
    hasDate:              PropTypes.bool,
    instructor:           PropTypes.object.isRequired,
    loader:               PropTypes.object.isRequired,
    memberships:          PropTypes.array.isRequired,
    privateBookings:      PropTypes.array.isRequired,
    restoreDiscount:      PropTypes.func.isRequired,
    subscriptions:        PropTypes.array.isRequired,
    user:                 PropTypes.object.isRequired
  }

  static defaultProps = {
    groupClass:  {},
    hasDate:     true,
    hasLocation: false
  }

  static getDerivedStateFromProps(props) {
    const date = DateHelper.summerTimeCheck(props.groupClass, props.user.timeZone);

    return {
      date: Moment.utc(date, 'YYYY-MM-DDhh:mm:ssUTC').tz(getTimeZone(props.user.timeZone))
    };
  }

  state = {
    date:                         '',
    isGroupClassModalOpen:        false,
    isPrivateGroupClassModalOpen: false
  };

  handleBookingModal = () => {
    this.setState((prevState) => ({ isPrivateGroupClassModalOpen: !prevState.isPrivateGroupClassModalOpen }));
  }

  handleToggle = () => {
    if (this.props.groupClass.type == 'privateGroupClass') {
      this.setState((prevState) => ({
        isPrivateGroupClassModalOpen: !prevState.isPrivateGroupClassModalOpen
      }));
    } else {
      this.setState((prevState) => ({
        isGroupClassModalOpen: !prevState.isGroupClassModalOpen
      }));
    }
  };

  renderLocationInfo = (groupClass) => (
    <div className='event-location'>
      {groupClass.location ? groupClass.location.name : 'No location'}
    </div>
  );

  renderIcon = (type) => {
    if (!this.props.instructor.customization) return null;

    const { customization } = this.props.instructor;
    const { secondaryButtonsBackgroundColor } = customization;

    switch (type) {
      case 'free':
        return <Free fill={secondaryButtonsBackgroundColor} />;

      case 'donation':
        return <Donation fill={secondaryButtonsBackgroundColor} />;

      case 'paid':
        return <Paid fill={secondaryButtonsBackgroundColor} />;

      default:
        return null;
    }
  }

  render() {
    const { groupClass, hasDate, instructor } = this.props;
    const { date } = this.state;

    if (!Object.values(groupClass).length) {
      return <Card className='card-body' />;
    } else {
      return (
        <React.Fragment>
          <Card
              body
              className='card-event'>
            { hasDate &&
              <div className='card-event-date'>
                <span className='event-day'>{date.format('D')}</span>
                <span className='event-weekday'>
                  {date.format('MMM').toUpperCase()}&nbsp;
                  {date.format('dddd')}
                </span>
              </div>
            }

            <div className='event-name'>
              {groupClass.name}
            </div>

            <div className='card-event-time'>
              {date.format('h:mmA')} - {groupClass.duration + ' min'}
            </div>

            {this.renderLocationInfo(groupClass)}

            <div className='event-reserve'>
              <Button
                  customization={instructor.customization}
                  onClick={this.handleToggle}
                  size='sm'>
                Reserve
              </Button>
              { groupClass.feeType &&
                <div className='pt-lg-0 pt-2 pt-sx-2 pl-2'>
                  {this.renderIcon(groupClass.feeType)}
                </div>
              }
            </div>
          </Card>
          { this.state.isGroupClassModalOpen &&
            <BookingModal
                createBooking={this.props.createBooking}
                groupClass={groupClass}
                isOpen={this.state.isGroupClassModalOpen}
                onToggle={this.handleToggle}
                user={this.props.user} />
          }

          { this.state.isPrivateGroupClassModalOpen &&
            <PrivateBookingModal
                challenges={this.props.challenges}
                classPacks={this.props.classPacks}
                createBooking={this.props.createPrivateBooking}
                createDiscount={this.props.createDiscount}
                discount={this.props.discount}
                fetchClassPacks={this.props.fetchClassPacks}
                fetchMemberships={this.props.fetchMemberships}
                instructor={instructor}
                isOpen={this.state.isPrivateGroupClassModalOpen}
                loader={this.props.loader}
                memberships={this.props.memberships}
                onBook={this.handleBook}
                onToggle={this.handleBookingModal}
                privateBookings={this.props.privateBookings}
                privateGroupClass={groupClass}
                restoreDiscount={this.props.restoreDiscount}
                subscriptions={this.props.subscriptions}
                user={this.props.user} />
          }
        </React.Fragment>
      );
    }
  }
}

export default ClassBox;
