import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';
import { InputGroup, InputGroupAddon } from 'reactstrap';

import { Button } from '../forms';
import { Input } from './';

class ColorPicker extends PureComponent {
  static propTypes = {
    colorType:  PropTypes.string,
    isDisabled: PropTypes.bool,
    name:       PropTypes.string.isRequired,
    onChange:   PropTypes.func.isRequired,
    value:      PropTypes.string.isRequired
  };

  static defaultProps = {
    colorType:  'hex',
    isDisabled: false
  }

  state = {
    isPickerShown: false
  };

  handleToggle = () => {
    if (this.props.isDisabled) return;

    this.setState((prevState) => ({ isPickerShown: !prevState.isPickerShown }));
  };

  handleChange = (color) => {
    this.props.onChange(color[this.props.colorType], this.props.name);
  }

  render() {
    const { value, name, isDisabled } = this.props;
    const { isPickerShown } = this.state;

    const pickerStyle = {
      backgroundColor: value,
      width:           '31px'
    };

    return (
      <InputGroup
          className='input-color-picker'
          size='sm'>
        <InputGroupAddon addonType='prepend'>
          <Button
              className='picker-preview'
              color='none'
              onClick={this.handleToggle}
              style={pickerStyle} />
          { isPickerShown &&
            <div className='picker-popover'>
              <div
                  aria-hidden='true'
                  className='picker-cover'
                  onClick={this.handleToggle}
                  role='button' />
              <ChromePicker
                  color={value}
                  onChange={this.handleChange} />
            </div>
          }
        </InputGroupAddon>
        <Input
            id='color'
            isDisabled={isDisabled}
            name={name}
            value={value} />
      </InputGroup>
    );
  }
}

export default ColorPicker;
