/* eslint-disable immutable/no-mutation */
import React from 'react';
import PropTypes from 'prop-types';

class LocationMarkers extends React.PureComponent {
  static propTypes = {
    google:    PropTypes.object.isRequired,
    locations: PropTypes.arrayOf(PropTypes.object).isRequired
  }

  static getDerivedStateFromProps(props) {
    /* filter out markers without coordinates */
    const locations = props.locations.filter((location) => (
      location.latitude && location.longitude
    ));

    return {
      locations,
      map:  props.google.map,
      maps: props.google.maps
    };
  }

  state = {
    locations: [],
    map:       {},
    maps:      {}
  }

  markers = [];

  componentWillUnmount() {
    this.clearMarkers();
  }

  clearMarkers = () => {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });

    this.markers = [];
  }

  drawMarkers = () => {
    const { maps, map, locations } = this.state;

    const bounds = new maps.LatLngBounds();

    this.clearMarkers();
    this.markers = locations.map((location) => {
      const point = new maps.LatLng({
        lat: parseFloat(location.latitude),
        lng: parseFloat(location.longitude)
      });

      bounds.extend(point);

      const infoWindow = new maps.InfoWindow({
        content: location.name
      });

      const marker = new maps.Marker({
        map,
        position: point,
        title:    location.name
      });

      /* add lsiteners for marker interactions */
      maps.event.addListener(marker, 'mouseover', function() {
        infoWindow.open(map, this);
      });

      maps.event.addListener(marker, 'mouseout', function() {
        infoWindow.close();
      });

      return marker;
    });

    /* fit after map is loaded                                     */
    /* listener 'tilesloaded' won't work because it look too jumpy */
    setTimeout(() => {
      map.fitBounds(bounds);
    }, 200);
  }

  render() {
    this.drawMarkers();

    return null;
  }
}

export default LocationMarkers;
