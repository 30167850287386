export default {
  0: (state) => ({
    ...state,
    instructor: {
      ...state.instructor,
      data: {
        ...state.instructor.data,
        locations: [] // add locations to instructor reducer
      }
    },
    instructors: {
      ...state.instructors,
      data: state.instructors.data.map((instructor) => ({ ...instructor, locations: [] }))
    }
  }),
  1: (state) => ({
    ...state,
    samples: {
      ...state.samples,
      classPacks:          [],
      memberships:         [],
      onDemands:           [],
      privateGroupClasses: []
    }
  }),
  2: (state) => ({
    ...state,
    membershipsDiscount: {
      ...state.membershipsDiscount,
      data: {}
    }
  }),
  3: (state) => ({
    ...state,
    samples: {
      ...state.samples,
      challenges: []
    }
  }),
  4: (state) => ({
    ...state,
    collections: {
      ...state.collections,
      data:      [],
      folders:   [],
      form:      {},
      onDemands: []
    }
  })
};
