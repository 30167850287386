import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

class ContactsElegant extends React.PureComponent {
  static propTypes = {
    hasTitle:   PropTypes.bool,
    instructor: PropTypes.object.isRequired
  }

  static defaultProps = {
    hasTitle: true
  }

  render() {
    const { hasTitle, instructor } = this.props;


    return (
      <div className='profile-contacts-footer'>
        { instructor.phone &&
          <div className='profile-contact-wrap'>
            <p className='profile-contact-heading'>Phone</p>
            <a
                className='profile-contact'
                href={`tel:${instructor.phone}`}>{instructor.phone}</a>
          </div>
        }
        { instructor.email &&
          <div className='profile-contact-wrap'>
            <p className='profile-contact-heading'>Email</p>
            <a
                className='profile-contact'
                href={`mailto:${instructor.email}`}>{instructor.email}</a>
          </div>
        }
        { instructor.website &&
          <div className='profile-contact-wrap'>
            <p className='profile-contact-heading'>Website</p>
            <a
                className='profile-contact'
                href={`//${instructor.website}`}>
              {instructor.website}
            </a>
          </div>
        }
        { !isEmpty(instructor.socialProfiles) &&
          <div className='profile-contact-wrap social-links'>
            {hasTitle && <p className='profile-contact-heading'>Social</p>}
            <div className='d-flex'>
              { instructor.socialProfiles.facebook &&
                <a
                    className='profile-contact'
                    href={`https://facebook.com/${instructor.socialProfiles.facebook}`}
                    rel='noopener noreferrer'
                    target='_blank'>
                  <div className='profile-contact-icon'>
                    <Icon
                        color='white'
                        fixedWidth
                        icon={['fab', 'facebook-f']}
                        size='lg' />
                  </div>
                </a>
              }

              { instructor.socialProfiles.instagram &&
                <a
                    className='profile-contact'
                    href={`https://instagram.com/${instructor.socialProfiles.instagram}`}
                    rel='noopener noreferrer'
                    target='_blank'>
                  <div className='profile-contact-icon'>
                    <Icon
                        fixedWidth
                        icon={['fab', 'instagram']}
                        size='lg' />
                  </div>
                </a>
              }

              { instructor.socialProfiles.twitter &&
                <a
                    className='profile-contact'
                    href={`https://twitter.com/${instructor.socialProfiles.twitter}`}
                    rel='noopener noreferrer'
                    target='_blank'>
                  <div className='profile-contact-icon'>
                    <Icon
                        fixedWidth
                        icon={['fab', 'twitter']}
                        size='lg' />
                  </div>
                </a>
              }

              { instructor.socialProfiles.linkedin &&
                <a
                    className='profile-contact'
                    href={`https://linkedin.com/in/${instructor.socialProfiles.linkedin }`}
                    rel='noopener noreferrer'
                    target='_blank'>
                  <div className='profile-contact-icon'>
                    <Icon
                        fixedWidth
                        icon={['fab', 'linkedin-in']}
                        size='lg' />
                  </div>
                </a>
              }
            </div>
          </div>
        }
      </div>
    );
  }
}

export default ContactsElegant;
