import React from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

import { Instructors, Locations } from './';

class LocationFilters extends React.PureComponent {
  static propTypes = {
    instructors:     PropTypes.array.isRequired,
    locations:       PropTypes.array.isRequired,
    onFiltersChange: PropTypes.func.isRequired,
    onFiltersReset:  PropTypes.func.isRequired
  }

  componentWillUnmount() {
    this.props.onFiltersReset();
  }

  render() {
    return (
      <div className='scheduler-filters pl-3 pt-2'>
        { !!this.props.locations.length &&
          <Locations
              hasOne
              locations={this.props.locations}
              onChange={this.props.onFiltersChange}>
            <Label>Studios</Label>
          </Locations>
        }

        { !!this.props.instructors.length &&
          <Instructors
              instructors={this.props.instructors}
              onChange={this.props.onFiltersChange}>
            <Label>Instructors</Label>
          </Instructors>
        }
      </div>
    );
  }
}

export default LocationFilters;