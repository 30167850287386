import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { eq, filter, find, includes, map, noop, reject, uniq } from 'lodash';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';

import { Select } from '../forms';
import { ClassPacksActions } from '../../actions';

class ClassPacksSelect extends React.PureComponent {
  static propTypes = {
    classPacks:      PropTypes.array.isRequired,
    fetchClassPacks: PropTypes.func.isRequired,
    for:             PropTypes.string.isRequired,
    id:              PropTypes.string,
    isDisabled:      PropTypes.bool.isRequired,
    isLoading:       PropTypes.bool.isRequired,
    isUpdate:        PropTypes.bool.isRequired,
    name:            PropTypes.string,
    onChange:        PropTypes.func.isRequired,
    selectedIds:     PropTypes.array
  }

  static defaultProps = {
    id:          '',
    name:        '',
    options:     [],
    selectedIds: []
  }

  static getDerivedStateFromProps(props, state) {
    const classPacks = filter(props.classPacks, (classPack) => (
      eq(classPack.premium[props.for], 'true')
    ));

    const allOptions = map(classPacks, (classPack) => (
      { label: classPack.name, value: classPack.id }
    ));

    const options = reject(allOptions, (option) => (
      includes(state.selectedIds, option.value)
    ));

    return { classPacks, options };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading != this.props.isLoading && !this.props.isLoading) {
      this._updateSelectedIds();
    }
  }

  componentDidMount() {
    this.props.fetchClassPacks();
    this._updateSelectedIds();
  }

  _updateSelectedIds = () => {
    const selectedIds = this.props.isUpdate
        ? this.props.selectedIds
        : map(this.state.classPacks, 'id');

    this.setState({ selectedIds }, () => (
      this.props.onChange(map(this.state.options, 'value'), this.props.name)
    ));
  }

  state = {
    classPacks:  [],
    options:     [],
    selectedIds: []
  }

  handleSelect = (classPackId) => (
    this.setState((prevState) => ({
      selectedIds: uniq([...prevState.selectedIds, classPackId])
    }), () => (
      this.props.onChange(map(this.state.options, 'value'), this.props.name)
    ))
  )

  renderSelectedOption = (classPackId) => {
    const classPack = find(this.props.classPacks, ['id', classPackId]);

    if (!classPack) return;

    const handleRemove = (classPackId) => () => (
      this.setState((prevState) => ({
        selectedIds: reject(prevState.selectedIds, (id) => eq(id, classPackId))
      }), () => (
        this.props.onChange(map(this.state.options, 'value'), this.props.name)
      ))
    );

    const { isDisabled } = this.props;

    return (
      <Row key={classPackId}>
        <Col
            md={1}
            xs={1}>
          <Icon
              className={classNames({ 'pointer': !isDisabled })}
              color={isDisabled ? 'gray' : 'red'}
              fixedWidth
              icon={['fas', 'times']}
              onClick={isDisabled ? noop : handleRemove(classPackId)} />
        </Col>

        <Col
            md={11}
            xs={11}>
          {classPack.name}
        </Col>
      </Row>
    );
  }

  render() {
    const { id, isDisabled, isLoading, name } = this.props;
    const { options, selectedIds } = this.state;

    return (
      <React.Fragment>
        <div className='d-flex justify-content-between'>
          <Select
              className='w-100'
              id={id}
              isDisabled={isDisabled}
              isLoading={isLoading}
              name={name}
              onChange={this.handleSelect}
              options={options}
              placeholder='Add class pack' />
        </div>

        <div className='packages-pro selected-ids mt-2'>
          {map(selectedIds, this.renderSelectedOption)}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  classPacks: state.classPacks.instructorData,
  isLoading:  state.loader.classPacks.isLoading
});

const mapDispatchToProps = (dispatch) => ({
  fetchClassPacks: () => dispatch(ClassPacksActions.INSTRUCTORS_FETCH())
});

export default connect(mapStateToProps, mapDispatchToProps)(ClassPacksSelect);
