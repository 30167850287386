import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

class Heading extends React.PureComponent {
  static propTypes = {
    className:   PropTypes.string,
    color:       PropTypes.string,
    hasTutorial: PropTypes.bool,
    link:        PropTypes.string,
    subtitle:    PropTypes.string,
    title:       PropTypes.string.isRequired
  };

  static defaultProps = {
    className:   '',
    color:       'primary',
    hasTutorial: false,
    link:        '',
    subtitle:    ''
  };

  render () {
    const { link, hasTutorial, className, color, subtitle, title } = this.props;

    return (
      <div className={'page-heading ' + className}>
        <div>
          <p className={`subtitle text-${color}`}>
            {subtitle}
          </p>
          <h4 className='hero-heading'>
            {title}
          </h4>
        </div>
        { !isMobile && hasTutorial &&
          <div className='text-right'>
            <p className='m-0'>
              <small>Want a tutorial of the page?</small>
            </p>
            <a
                href={link}
                rel='noopener noreferrer'
                target='_blank'>
              <small>Watch a video</small>
            </a>
          </div>
        }
      </div>
    );
  }
}

export default Heading;
