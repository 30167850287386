import { DonationClassesActionTypes } from '../constants/actionTypes';

export default {
  FETCH: (payload) => ({
    payload, type: DonationClassesActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: DonationClassesActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: DonationClassesActionTypes.FETCH_SUCCESS
  })
};
