/* eslint-disable max-len, react/prop-types */
import React from 'react';

const ElegantHeart = (props) => (
  <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.4">
      <path
          d="M18.6666 35.3335H18.9999C12.771 27.9496 13.2047 17.0331 19.9999 10.1668L19.1666 9.33346C17.4643 7.50725 15.0799 6.47021 12.5833 6.47021C10.0867 6.47021 7.70227 7.50725 5.99994 9.33346C2.52671 13.0993 2.52671 18.9009 5.99994 22.6668L18.3333 35.0001L18.6666 35.3335Z"
          fill={props.color || '#5E7D46'}
      />
    </g>
    <path
        d="M20.5001 9.66644L20.0001 9.99977C13.2796 16.7889 12.7065 27.8676 18.6668 35.3331C19.3828 36.0613 20.5324 36.1332 21.3334 35.4998L34.0001 22.8331C35.8319 20.9188 36.7977 18.3366 36.6717 15.69C36.5457 13.0435 35.3389 10.5647 33.3335 8.8331C29.4893 5.73309 23.9111 6.09531 20.5001 9.66644Z"
        fill={props.color || '#5E7D46'}
    />
  </svg>
);

export default ElegantHeart;
