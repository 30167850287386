/* eslint-disable sort-keys-fix/sort-keys-fix */

import { MembershipsActionTypes } from '../constants/actionTypes';

export default {
  CANCEL: (payload) => ({
    payload, type: MembershipsActionTypes.CANCEL
  }),

  CANCEL_FAILURE: () => ({
    type: MembershipsActionTypes.CANCEL_FAILURE
  }),

  CANCEL_SUCCESS: (payload) => ({
    payload, type: MembershipsActionTypes.CANCEL_SUCCESS
  }),


  CREATE: (payload) => ({
    payload, type: MembershipsActionTypes.CREATE
  }),

  CREATE_FAILURE: () => ({
    type: MembershipsActionTypes.CREATE_FAILURE
  }),

  CREATE_SUCCESS: (payload) => ({
    payload, type: MembershipsActionTypes.CREATE_SUCCESS
  }),

  FETCH: () => ({
    type: MembershipsActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: MembershipsActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: (payload) => ({
    payload, type: MembershipsActionTypes.FETCH_SUCCESS
  }),


  DELETED_FETCH: () => ({
    type: MembershipsActionTypes.DELETED_FETCH
  }),

  DELETED_FETCH_FAILURE: () => ({
    type: MembershipsActionTypes.DELETED_FETCH_FAILURE
  }),

  DELETED_FETCH_SUCCESS: (payload) => ({
    payload, type: MembershipsActionTypes.DELETED_FETCH_SUCCESS
  }),


  INSTRUCTORS_FETCH: (payload) => ({
    payload, type: MembershipsActionTypes.INSTRUCTORS_FETCH
  }),

  INSTRUCTORS_FETCH_FAILURE: () => ({
    type: MembershipsActionTypes.INSTRUCTORS_FETCH_FAILURE
  }),

  INSTRUCTORS_FETCH_SUCCESS: (payload) => ({
    payload, type: MembershipsActionTypes.INSTRUCTORS_FETCH_SUCCESS
  }),


  INSTRUCTORS_GET: (payload) => ({
    payload, type: MembershipsActionTypes.INSTRUCTORS_GET
  }),

  INSTRUCTORS_GET_FAILURE: () => ({
    type: MembershipsActionTypes.INSTRUCTORS_GET_FAILURE
  }),

  INSTRUCTORS_GET_SUCCESS: (payload) => ({
    payload, type: MembershipsActionTypes.INSTRUCTORS_GET_SUCCESS
  }),


  UPDATE: (payload) => ({
    payload, type: MembershipsActionTypes.UPDATE
  }),

  UPDATE_FAILURE: () => ({
    type: MembershipsActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: (payload) => ({
    payload, type: MembershipsActionTypes.UPDATE_SUCCESS
  })
};
