import { toastr } from 'react-redux-toastr';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { api, extractError, routes, toCamelCase } from '../lib';
import { InstructorActionTypes } from '../constants/actionTypes';

import {
  ErrorActions,
  InstructorActions,
  LoaderActions,
  ProfileActions
} from '../actions';

function* watchClaim() {
  yield takeLatest(InstructorActionTypes.CLAIM, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());

      const response = yield call(api.instructor.claims.update, payload);

      yield put(InstructorActions.CLAIM_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(InstructorActions.CLAIM_FAILURE());
    }
  });
}

function* watchClaimSuccess() {
  yield takeLatest(InstructorActionTypes.CLAIM_SUCCESS, function* ({ payload }) {
    yield put(ProfileActions.FETCH_SUCCESS(payload));
    yield put(push(routes.EDIT_PROFILE));
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchClaimFailure() {
  yield takeLatest(InstructorActionTypes.CLAIM_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchGet() {
  yield takeLatest(InstructorActionTypes.GET, function* ({ payload }) {
    try {
      yield put(LoaderActions.START_LOADING());
      yield put(ErrorActions.CLEAR());

      const response = yield call(api.instructor.get, payload);

      yield put(InstructorActions.GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(InstructorActions.GET_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchGetSuccess() {
  yield takeLatest(InstructorActionTypes.GET_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchGetFailure() {
  yield takeLatest(InstructorActionTypes.GET_FAILURE, function* () {
    yield put(LoaderActions.FINISH_LOADING());
  });
}

function* watchFollow() {
  yield takeLatest(InstructorActionTypes.FOLLOW, function* () {
    try {
      const instructor = yield select((state) => state.instructor.data);
      yield call(api.instructor.follow.create, { username: instructor.username });

      yield put(InstructorActions.FOLLOW_SUCCESS());
    } catch (error) {
      yield put(InstructorActions.FOLLOW_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchFollowSuccess() {
  yield takeLatest(InstructorActionTypes.FOLLOW_SUCCESS, function* () {
    yield (toastr.success('Instructor favourited!'));
  });
}

function* watchFollowFailure() {
  yield takeLatest(InstructorActionTypes.FOLLOW_FAILURE, function* () {
    //TODO: Need to add some error action
  });
}

function* watchUnFollow() {
  yield takeLatest(InstructorActionTypes.UNFOLLOW, function* () {
    try {
      const instructor = yield select((state) => state.instructor.data );
      yield call(api.instructor.follow.destroy, { username: instructor.username });

      yield put(InstructorActions.UNFOLLOW_SUCCESS());
    } catch (error) {
      yield put(InstructorActions.UNFOLLOW_FAILURE());

      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchUnFollowSuccess() {
  yield takeLatest(InstructorActionTypes.UNFOLLOW_SUCCESS, function* () {
    //TODO: Need to add some success action
  });
}

function* watchUnFollowFailure() {
  yield takeLatest(InstructorActionTypes.UNFOLLOW_FAILURE, function* () {
    //TODO: Need to add some error action
  });
}

export default function* instructorSaga() {
  yield all([
    watchClaim(),
    watchClaimSuccess(),
    watchClaimFailure(),
    watchGet(),
    watchGetSuccess(),
    watchGetFailure(),
    watchFollow(),
    watchFollowSuccess(),
    watchFollowFailure(),
    watchUnFollow(),
    watchUnFollowSuccess(),
    watchUnFollowFailure()
  ]);
}
