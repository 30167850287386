import React from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Form, FormFeedback, FormGroup, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';

import { Button } from '../forms';
import { Input, Link } from '../inputs';
import { Logo } from '../../images';
import { Validator, routes } from '../../lib';
import { Errors } from '../errors';

class ResetPasswordPage extends React.PureComponent {
  static propTypes = {
    resetPassword: PropTypes.func.isRequired,
    token:         PropTypes.string
  }

  static defaultProps = {
    token: ''
  }

  static getDerivedStateFromProps(props, state) {
    return {
      user: {
        ...state.user,
        token: props.token
      }
    };
  }

  state = {
    errors: {},

    user: {
      password:             '',
      passwordConfirmation: '',
      token:                ''
    }
  }

  handleChange = (value, inputName) => {
    this.setState((prevState) => ({
      user: {
        ...prevState.user,
        [inputName]: value
      }
    }));
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.handleValidate().then(() => {
      return this.setState({ errors: {} }, () => {
        this.props.resetPassword({ user: this.state.user });
      });
    }).catch((errors) => {
      return this.setState({ errors });
    });
  }

  handleValidate = () => new Promise((resolve, reject) => {
    const { user } = this.state;

    const errors = Validator.validate([
      ['password', Validator.concepts.isLongerThan, [8, user.password, 'Password']],
      ['password', Validator.concepts.passwordRegex, [user.password]],
      ['password', Validator.concepts.isMatching, [user.password, user.passwordConfirmation]]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  render() {
    return (
      <Container
          className='px-3'
          fluid>
        <Row className='min-vh-100'>
          <Col
              className='d-flex align-items-center'
              lg='6'
              md='8'
              xl='5'>
            <div className='w-100 py-5 px-md-5 px-xl-6 position-relative'>

              <Link
                  className='mb-4'
                  to={routes.HOME}>
                <img
                    alt='Logo'
                    className='img-fluid auth-logo'
                    src={Logo} />
              </Link>

              <div id='form-container'>
                <p className='text-muted'>
                  Reset your password
                </p>

                <Form
                    className='form-validate'
                    onSubmit={this.handleSubmit}>
                  <Errors errors={this.state.errors} />


                  <FormGroup>
                    <Label for='password'>Password</Label>
                    <Input
                        isInvalid={!!this.state.errors.password}
                        name='password'
                        onChange={this.handleChange}
                        type='password'
                        value={this.state.user.password} />
                    <FormFeedback>{this.state.errors.password}</FormFeedback>
                  </FormGroup>

                  <FormGroup>
                    <Label for='passwordConfirmation'>Confirm Password</Label>
                    <Input
                        isInvalid={!!this.state.errors.passwordConfirmation}
                        name='passwordConfirmation'
                        onChange={this.handleChange}
                        type='password'
                        value={this.state.user.passwordConfirmation} />
                    <FormFeedback>{this.state.errors.passwordConfirmation}</FormFeedback>
                  </FormGroup>

                  <Button
                      className='mt-3'
                      color='primary'
                      isBlock
                      size='lg'
                      type='submit'>
                    RESET PASSWORD
                  </Button>

                  <Link
                      className='mt-2'
                      to={routes.LOGIN}>
                    Go back to Login
                  </Link>
                </Form>

                <Link
                    className='close-absolute mr-md-5 mr-xl-6 pt-5'
                    to={routes.HOME}>
                  <svg className='svg-icon w-3rem h-3rem'>
                    <path
                        d="M41.999 20.002l-22 22m22 0L20 20"
                        data-name="layer1"
                        fill="none"
                        stroke="#202020"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit="10"
                        style={{stroke: 'var(--layer1, #202020)'}} />
                  </svg>
                </Link>
              </div>
            </div>
          </Col>

          <Col
              className='d-none d-md-block'
              lg='6'
              md='4'
              xl='7'>
            <div
                className='bg-cover h-100 mr-n3'
                id='auth-bg' />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ResetPasswordPage;
