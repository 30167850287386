import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';

import { Button } from '../forms';

class ConfirmationModal extends React.PureComponent {
  static propTypes = {
    children:  PropTypes.node.isRequired,
    isOpen:    PropTypes.bool,
    onCancel:  PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
    onToggle:  PropTypes.func
  }

  static defaultProps = {
    isOpen:   false,
    onCancel: () => {},
    onToggle: () => {}
  }

  handleConfirm = () => {
    this.props.onConfirm();
    this.props.onToggle();
  }

  handleCancel = () => {
    this.props.onCancel();
    this.props.onToggle();
  }

  render() {
    return (
      <Modal
          centered
          isOpen={this.props.isOpen}
          toggle={this.props.onToggle}>
        <ModalBody>
          <p>{this.props.children}</p>
        </ModalBody>

        <ModalFooter>
          <Button
              color='primary'
              onClick={this.handleConfirm}>
            Confirm
          </Button>

          <Button
              className='ml-1'
              color='secondary'
              onClick={this.handleCancel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ConfirmationModal;
