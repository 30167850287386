import React from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { isEmpty } from 'lodash';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { Badge, ButtonGroup, ButtonToolbar } from 'reactstrap';
import { Icon } from 'react-icons-kit';
import { record } from 'react-icons-kit/ionicons/record';

import { CommentsContainer, InstagramContainer, PlaylistContainer } from '../../../../containers';
import { Button } from '../../../../components/forms';
import { InstructorName } from '../../../../components/instructor';
import { FollowButton } from '../../../../components/buttons';

const SCHEDULES = {
  sneakPeek:    'SNEAK_PEEK',
  testimonials: 'TESTIMONIALS'
};

class StyleDefault extends React.PureComponent {
  static propTypes = {
    follow:     PropTypes.func.isRequired,
    instructor: PropTypes.object.isRequired,
    playlist:   PropTypes.object.isRequired,
    unFollow:   PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    const { instructor, playlist } = props;

    if (!state.isMounted && instructor.oauth) {
      return {
        integrations:     instructor.oauth,
        isMounted:        true,
        playlist,
        selectedCarousel: instructor.oauth.instagram || instructor.oauth.spotify ?
                          SCHEDULES.sneakPeek : SCHEDULES.testimonials
      };
    }

    return null;
  }

  componentDidUpdate(nextProps) {
    const { instructor } = nextProps;

    if (instructor.id && this.state.integrations.instagram != instructor.oauth.instagram) {
      this.setState({ integrations: instructor.oauth });
    }
  }

  state = {
    integrations:     {},
    isMounted:        false,
    playlist:         {},
    selectedCarousel: ''
  }

  handleToggle = (carousel) => () => {
    this.setState({ selectedCarousel: carousel });
  };

  renderCarousel = () => {
    switch (this.state.selectedCarousel) {
      case SCHEDULES.sneakPeek:
        return (
          <div className='style__social'>
            <LazyLoadComponent threshold={100}>
              <InstagramContainer />
            </LazyLoadComponent>
            <LazyLoadComponent threshold={100}>
              <PlaylistContainer />
            </LazyLoadComponent>
          </div>
        );

      case SCHEDULES.testimonials:
        return (
          <div className='style__comments'>
            <LazyLoadComponent threshold={100}>
              <CommentsContainer />
            </LazyLoadComponent>
          </div>
        );

      default:
        return null;
    }
  };

  renderTags = () => (
    this.props.instructor.styleTags.map((tag, index) => (
      <Badge
          className='style__tag'
          color='light'
          key={index}
          pill>
        {tag}
      </Badge>
    ))
  )

  renderPrompts = () => (
    this.props.instructor.prompts.map((prompt) => {
      return !isEmpty(prompt.text) &&
      <div
          className='style-prompt'
          key={prompt.id}>
        <Icon
            className='style-prompt__icon'
            icon={record}
            size={isMobile? '12' : '16'} />
        <span className='style-prompt__title'>{prompt.text}</span>
      </div>;
    })
  )

  handleFollow = () => {
    this.props.instructor.isFollowing ? this.props.unFollow() : this.props.follow();
  };

  render() {
    const { selectedCarousel, integrations, playlist } = this.state;
    const { instructor } = this.props;
    const { prompts, styleTags } = instructor;
    const isLinkedSocial = integrations.instagram || integrations.spotify;

    return (
      <div className='style'>
        <div className='style__header'>
          <InstructorName
              instructor={instructor}
              subtitle='Style' />
          <FollowButton
              followersCount={instructor.followersCount}
              isDisabled={!!instructor.isOwner}
              isFollowing={!!instructor.isFollowing}
              onClick={this.handleFollow} />
        </div>
        <div className='style__body'>

          <div className='style__tags'>
            {styleTags && this.renderTags()}
          </div>

          <div className='style__prompts'>
            {prompts && this.renderPrompts()}
          </div>

          <div className='style-body__info'>
            { isMobile && isLinkedSocial &&
              <ButtonToolbar>
                <ButtonGroup
                    className='style__buttons'
                    size={isMobile ? '' : 'lg'}>
                  <Button
                      className='px-lg-4'
                      color='orange'
                      isOutline={selectedCarousel !== SCHEDULES.sneakPeek}
                      onClick={this.handleToggle(SCHEDULES.sneakPeek)}>
                    Sneak Peek
                  </Button>
                  <Button
                      className='px-lg-4'
                      color='orange'
                      isOutline={selectedCarousel !== SCHEDULES.testimonials}
                      onClick={this.handleToggle(SCHEDULES.testimonials)}>
                    Testimonials
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            }

            {isMobile && !isEmpty(instructor) && this.renderCarousel()}

            { !isMobile && !isEmpty(instructor) && playlist &&
              <React.Fragment>
                { isLinkedSocial &&
                  <div className='style__social'>
                    { integrations.instagram &&
                      <LazyLoadComponent threshold={100}>
                        <InstagramContainer />
                      </LazyLoadComponent>
                    }

                    { integrations.spotify &&
                      <LazyLoadComponent threshold={100}>
                        <PlaylistContainer />
                      </LazyLoadComponent>
                    }
                  </div>
                }
                <div className='style__comments'>
                  <LazyLoadComponent threshold={100}>
                    <CommentsContainer />
                  </LazyLoadComponent>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default StyleDefault;
