import PropTypes from 'prop-types';
import React from 'react';
import { Icon } from 'react-icons-kit';
import { isMobile } from 'react-device-detect';
import { record } from 'react-icons-kit/ionicons/record';
import { isEmpty } from 'lodash';

import { FollowButton } from '../../../../components/buttons';
import { InstructorName } from '../../../../components/instructor';
import { Challenges, ClassPacks, Memberships } from '../../../../components/pages/profile/instructor';

class PackagesDefault extends React.PureComponent {

  static propTypes = {
    challenges:                PropTypes.array.isRequired,
    classPacks:                PropTypes.array.isRequired,
    createChallenge:           PropTypes.func.isRequired,
    createClassPack:           PropTypes.func.isRequired,
    createDiscount:            PropTypes.func.isRequired,
    createMembershipDiscount:  PropTypes.func.isRequired,
    createSubscription:        PropTypes.func.isRequired,
    discount:                  PropTypes.object.isRequired,
    follow:                    PropTypes.func.isRequired,
    instructor:                PropTypes.object.isRequired,
    memberships:               PropTypes.array.isRequired,
    membershipsDiscount:       PropTypes.object.isRequired,
    restoreDiscount:           PropTypes.func.isRequired,
    restoreMembershipDiscount: PropTypes.func.isRequired,
    subscriptions:             PropTypes.array.isRequired,
    unFollow:                  PropTypes.func.isRequired,
    user:                      PropTypes.object.isRequired
  }

  handleFollow = () => {
    this.props.instructor.isFollowing ? this.props.unFollow() : this.props.follow();
  };

  renderMembershipReason = (reason) => {
    if (!isEmpty(reason.text)) {
      return (
        <div
            className='reason mb-1 mt-1'
            key={reason.id}>
          <Icon
              className='mr-2'
              icon={record}
              size={isMobile? '12' : '16'} />
          <span className='membership-reason'>{reason.text}</span>
        </div>
      );
    }
  }

  render() {
    const { challenges, classPacks, instructor, user, memberships, subscriptions } = this.props;

    return (
      <div className='profile-section'>
        <div className='memberships'>
          <div className='content'>
            <div className='profile-name membership__name'>
              <InstructorName
                  instructor={instructor}
                  subtitle='Packages' />
              <FollowButton
                  followersCount={instructor.followersCount}
                  isDisabled={!!instructor.isOwner}
                  isFollowing={!!instructor.isFollowing}
                  onClick={this.handleFollow} />
            </div>
            <div className='mb-2'>
              <span className='page-title'>Purchase a package!</span>
              { !isEmpty(instructor.membershipReasons) &&
                <div className='mb-4 mt-2'>
                  {instructor.membershipReasons.map(this.renderMembershipReason)}
                </div>
              }
            </div>

            { !isEmpty(instructor) && !!challenges.length &&
              <React.Fragment>
                <Challenges
                    challenges={challenges}
                    createChallenge={this.props.createChallenge}
                    createDiscount={this.props.createDiscount}
                    discount={this.props.discount}
                    instructor={instructor}
                    restoreDiscount={this.props.restoreDiscount}
                    theme='elegant'
                    user={user} />
              </React.Fragment>
            }

            { !isEmpty(instructor) && !!memberships.length &&
              <div className='mt-4'>
                <Memberships
                    createDiscount={this.props.createMembershipDiscount}
                    createSubscription={this.props.createSubscription}
                    discount={this.props.membershipsDiscount}
                    instructor={instructor}
                    memberships={memberships}
                    restoreDiscount={this.props.restoreMembershipDiscount}
                    subscriptions={subscriptions}
                    user={user} />
              </div>
            }

            { !isEmpty(instructor) && !!classPacks.length &&
              <div className='mt-4'>
                <ClassPacks
                    classPacks={classPacks}
                    createClassPack={this.props.createClassPack}
                    createDiscount={this.props.createDiscount}
                    discount={this.props.discount}
                    instructor={instructor}
                    restoreDiscount={this.props.restoreDiscount}
                    user={user} />
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default PackagesDefault;
