export default {
  CANCEL:         '@@memberships/CANCEL',
  CANCEL_FAILURE: '@@memberships/CANCEL_FAILURE',
  CANCEL_SUCCESS: '@@memberships/CANCEL_SUCCESS',

  CREATE:         '@@memberships/CREATE',
  CREATE_FAILURE: '@@memberships/CREATE_FAILURE',
  CREATE_SUCCESS: '@@memberships/CREATE_SUCCESS',
  
  DELETED_FETCH:         '@@memberships/DELETED_FETCH',
  DELETED_FETCH_FAILURE: '@@memberships/DELETED_FETCH_FAILURE',
  DELETED_FETCH_SUCCESS: '@@memberships/DELETED_FETCH_SUCCESS',

  FETCH:         '@@memberships/FETCH',
  FETCH_FAILURE: '@@memberships/FETCH_FAILURE',
  FETCH_SUCCESS: '@@memberships/FETCH_SUCCESS',


  INSTRUCTORS_FETCH:         '@@memberships/INSTRUCTORS/FETCH',
  INSTRUCTORS_FETCH_FAILURE: '@@memberships/INSTRUCTORS/FETCH_FAILURE',
  INSTRUCTORS_FETCH_SUCCESS: '@@memberships/INSTRUCTORS/FETCH_SUCCESS',

  INSTRUCTORS_GET:         '@@memberships/INSTRUCTORS/GET',
  INSTRUCTORS_GET_FAILURE: '@@memberships/INSTRUCTORS/GET_FAILURE',
  INSTRUCTORS_GET_SUCCESS: '@@memberships/INSTRUCTORS/GET_SUCCESS',

  UPDATE:         '@@memberships/UPDATE',
  UPDATE_FAILURE: '@@memberships/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@memberships/UPDATE_SUCCESS'
};
