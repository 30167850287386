import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { filter, isInteger, map, some } from 'lodash';

import { Calendar } from '../calendar';
import { tutorialLinks } from '../../constants';
import {
  EditGroupClassModal,
  FormatPrivateGroupClassModal,
  InstructorPrivateGroupClassModal,
  InstructorPrivateSessionModal } from '../modals';
import { PrivateGroupClassModal, TimeBlockModal } from '../../containers/events';
import { Calendr } from '../../lib';
import { Heading } from '../layout/pages';
import { FloatMenu } from './portal';

class CalendarPage extends React.PureComponent {
  static propTypes = {
    createFormats:                    PropTypes.func.isRequired,
    createGroupClass:                 PropTypes.func.isRequired,
    createPrivateBookings:            PropTypes.func.isRequired,
    createPrivateGroupClass:          PropTypes.func.isRequired,
    createPrivateSession:             PropTypes.func.isRequired,
    createRecurringGroupClass:        PropTypes.func.isRequired,
    createRecurringPrivateGroupClass: PropTypes.func.isRequired,
    createRecurringPrivateSession:    PropTypes.func.isRequired,
    createRecurringTimeBlock:         PropTypes.func.isRequired,
    createTimeBlock:                  PropTypes.func.isRequired,
    deleteGroupClass:                 PropTypes.func.isRequired,
    deleteMeeting:                    PropTypes.func.isRequired,
    deletePrivateBooking:             PropTypes.func.isRequired,
    deletePrivateGroupClass:          PropTypes.func.isRequired,
    deletePrivateSession:             PropTypes.func.isRequired,
    deleteRecurringGroupClass:        PropTypes.func.isRequired,
    deleteRecurringPrivateGroupClass: PropTypes.func.isRequired,
    deleteRecurringPrivateSession:    PropTypes.func.isRequired,
    fetchPlaylists:                   PropTypes.func.isRequired,
    formats:                          PropTypes.array.isRequired,
    groupClasses:                     PropTypes.array.isRequired,
    instructor:                       PropTypes.object.isRequired,
    locations:                        PropTypes.array.isRequired,
    playlists:                        PropTypes.array.isRequired,
    privateGroupClasses:              PropTypes.array.isRequired,
    privateSessions:                  PropTypes.array.isRequired,
    sampleClasses:                    PropTypes.array.isRequired,
    timeBlocks:                       PropTypes.array.isRequired,
    updateGroupClass:                 PropTypes.func.isRequired,
    user:                             PropTypes.object.isRequired
  }

  static getDerivedStateFromProps(props) {
    const { groupClasses, privateSessions, timeBlocks } = props;

    const privateGroupClasses = [ ...props.privateGroupClasses, ...props.sampleClasses ];

    const events = { groupClasses, privateGroupClasses, privateSessions, timeBlocks};

    return { events: Calendr.combineClasses(events) };
  }

  state = {
    events:                           [],
    groupClass:                       {},
    isFormatModalOpen:                false,
    isGroupClassModalOpen:            false,
    isPrivateGroupClassModalOpen:     false,
    isPrivateGroupClassViewModalOpen: false,
    isPrivateSessionModalOpen:        false,
    isTimeBlockModalOpen:             false,
    privateGroupClass:                {},
    privateSession:                   {},
    timeBlock:                        {}
  };

  componentDidMount() {
    const formatIds = map(this.props.privateGroupClasses, 'formatId');
    const hasBlankFormats = some(formatIds, (formatId) => !isInteger(formatId));

    if (hasBlankFormats) this.handleFormatModal();
  }

  handleFormatModal = () => (
    this.setState((prevState) => ({
      isFormatModalOpen: !prevState.isFormatModalOpen
    }))
  )

  handleGroupClassClick = (groupClass = {}) => {
    this.setState((prevState) => ({
      groupClass,
      isGroupClassModalOpen: !prevState.isGroupClassModalOpen
    }));
  }

  handlePrivateGroupClassViewClick = (privateGroupClass = {}) => {
    this.setState((prevState) => ({
      isPrivateGroupClassViewModalOpen: !prevState.isPrivateGroupClassViewModalOpen,
      privateGroupClass
    }));
  }

  handlePrivateGroupClassClick = () => {
    this.setState((prevState) => ({
      isPrivateGroupClassModalOpen: !prevState.isPrivateGroupClassModalOpen
    }));
  }

  handlePrivateSessionClick = (privateSession = {}) => {
    this.setState((prevState) => ({
      isPrivateSessionModalOpen: !prevState.isPrivateSessionModalOpen,
      privateSession
    }));
  }

  handleTimeBlockClick = (timeBlock = {}) => {
    this.setState((prevState) => ({
      isTimeBlockModalOpen: !prevState.isTimeBlockModalOpen,
      timeBlock
    }));
  }

  handleCancelClientBooking = (booking) => {
    this.props.deletePrivateBooking(booking);
    this.setState((prevState) => ({
      privateGroupClass: {
        ...prevState.privateGroupClass,
        bookings:      filter(prevState.privateGroupClass.bookings, (booked) => booked.id != booking.id),
        bookingsCount: prevState.privateGroupClass.bookingsCount - 1
      }
    }));
  }

  handleAddClient  = (booking) => {
    this.setState((prevState) => ({
      privateGroupClass: {
        ...prevState.privateGroupClass,
        bookings:      [...prevState.privateGroupClass.bookings, {...booking, deletedAt: ''}],
        bookingsCount: prevState.privateGroupClass.bookingsCount + 1
      }
    }));
    this.props.createPrivateBookings(booking);
  }

  handleEventClick = (event = {}) => {
    switch (event.type) {
      case 'groupClass':
        return this.handleGroupClassClick(event);

      case 'privateGroupClass':
        return this.handlePrivateGroupClassViewClick(event);

      case 'privateSession':
        return this.handlePrivateSessionClick(event);

      case 'timeBlock':
        return this.handleTimeBlockClick(event);

      default:
        null;
    }
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>My Schedule</title>
        </Helmet>

        <div className='inner-wrapper portal calendar-page'>
          <Container fluid>
            <Heading
                color='secondary'
                hasTutorial
                link={tutorialLinks.SCHEDULE}
                subtitle='Creator Portal'
                title='My Schedule' />
            <Calendar
                events={this.state.events}
                instructor={this.props.instructor}
                onEventClick={this.handleEventClick}
                user={this.props.user} />

            <FloatMenu
                createGroupClass={this.props.createGroupClass}
                createPrivateGroupClass={this.props.createPrivateGroupClass}
                createPrivateSession={this.props.createPrivateSession}
                createRecurringGroupClass={this.props.createRecurringGroupClass}
                createRecurringPrivateGroupClass={this.props.createRecurringPrivateGroupClass}
                createRecurringPrivateSession={this.props.createRecurringPrivateSession}
                createRecurringTimeBlock={this.props.createRecurringTimeBlock}
                createTimeBlock={this.props.createTimeBlock}
                deleteMeeting={this.props.deleteMeeting}
                fetchPlaylists={this.props.fetchPlaylists}
                formats={this.props.formats}
                instructor={this.props.instructor}
                playlists={this.props.playlists}
                user={this.props.user} />
          </Container>
        </div>

        { this.state.isGroupClassModalOpen &&
          <EditGroupClassModal
              deleteGroupClass={this.props.deleteGroupClass}
              deleteRecurringGroupClass={this.props.deleteRecurringGroupClass}
              fetchPlaylists={this.props.fetchPlaylists}
              groupClass={this.state.groupClass}
              instructor={this.props.instructor}
              isOpen={this.state.isGroupClassModalOpen}
              locations={this.props.locations}
              onToggle={this.handleGroupClassClick}
              playlists={this.props.playlists}
              updateGroupClass={this.props.updateGroupClass}
              user={this.props.user} />
        }

        { this.state.isPrivateSessionModalOpen &&
          <InstructorPrivateSessionModal
              deletePrivateSession={this.props.deletePrivateSession}
              deleteRecurringPrivateSession={this.props.deleteRecurringPrivateSession}
              isOpen={this.state.isPrivateSessionModalOpen}
              onToggle={this.handlePrivateSessionClick}
              privateSession={this.state.privateSession}
              user={this.props.user} />
        }

        { this.state.isPrivateGroupClassViewModalOpen &&
          <InstructorPrivateGroupClassModal
              deletePrivateGroupClass={this.props.deletePrivateGroupClass}
              deleteRecurringPrivateGroupClass={this.props.deleteRecurringPrivateGroupClass}
              isOpen={this.state.isPrivateGroupClassViewModalOpen}
              // TODO: wtf is this name
              onHandleAddClient={this.handleAddClient}
              onHandleCancelClientBooking={this.handleCancelClientBooking}
              onToggle={this.handlePrivateGroupClassViewClick}
              onUpdateClick={this.handlePrivateGroupClassClick}
              privateGroupClass={this.state.privateGroupClass}
              user={this.props.user} />
        }

        { this.state.isTimeBlockModalOpen &&
          <TimeBlockModal
              isOpen={this.state.isTimeBlockModalOpen}
              onToggle={this.handleTimeBlockClick}
              timeBlock={this.state.timeBlock} />
        }

        { this.state.isPrivateGroupClassModalOpen &&
          <PrivateGroupClassModal
              formats={this.props.formats}
              isOpen={this.state.isPrivateGroupClassModalOpen}
              onToggle={this.handlePrivateGroupClassClick}
              privateGroupClass={this.state.privateGroupClass} />
        }

        { this.state.isFormatModalOpen &&
          <FormatPrivateGroupClassModal
              createFormats={this.props.createFormats}
              formats={this.props.formats}
              isOpen={this.state.isFormatModalOpen}
              onToggle={this.handleFormatModal}
              privateGroupClasses={this.props.privateGroupClasses} />
        }
      </React.Fragment>
    );
  }
}

export default CalendarPage;
