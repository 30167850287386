import React from 'react';
import PropTypes from 'prop-types';
import { trim } from 'lodash';

import { Button } from '../forms';
import { Username } from '../inputs';

class StartPage extends React.PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func
  }

  static defaultProps = {
    onSubmit: () => {}
  }

  state = {
    value: ''
  }

  handleChange = (value) => {
    this.setState({ value });
  }

  handleSubmit = () => {
    this.props.onSubmit(trim(this.state.value));
  }

  render () {
    const { value } = this.state;

    return (
      <div className='username'>
        <Username
            onChange={this.handleChange}
            value={value} />
        <Button onClick={this.handleSubmit}>Start my page</Button>
        <small className='text-muted'>Takes less than a minute</small>
      </div>
    );
  }
}

export default StartPage;
