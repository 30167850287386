import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import { AvailabilityForm } from '../../forms/instructor';

class AvailabilityModal extends React.PureComponent {
  static propTypes = {
    availability:       PropTypes.object.isRequired,
    isOpen:             PropTypes.bool.isRequired,
    onToggle:           PropTypes.func.isRequired,
    selectedDay:        PropTypes.string.isRequired,
    updateAvailability: PropTypes.func.isRequired
  };

  handleUpdate = (dailyAvailability) => {
    const availability = {
      ...this.props.availability,
      [this.props.selectedDay.toLowerCase()]: dailyAvailability
    };

    this.props.updateAvailability({ availability });
    this.props.onToggle();
  };

  render () {
    const { isOpen, onToggle, selectedDay, availability } = this.props;

    return (
      <Modal
          centered
          className='client-modal'
          isOpen={isOpen}
          toggle={onToggle}>

        <ModalHeader toggle={onToggle}>
          Set <span className='text-info'>{selectedDay}</span> Availability
        </ModalHeader>

        <ModalBody>
          <AvailabilityForm
              data={availability[selectedDay.toLowerCase()]}
              onSubmit={this.handleUpdate} />
        </ModalBody>
      </Modal>
    );
  }
}

export default AvailabilityModal;
