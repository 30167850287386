import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '../forms';
import { MobileIcon } from '../../images';

class MobileFileUploader extends React.PureComponent {
  static propTypes = {
    isMultiple:   PropTypes.bool,
    name:         PropTypes.string,
    onSelectFile: PropTypes.func.isRequired,
    profile:      PropTypes.object.isRequired
  }

  static defaultProps = {
    error:       '',
    fileTypes:   ['image/jpeg'],
    isInvalid:   false,
    isMultiple:  false,
    maxFileSize: '5MB',
    name:        '',
    onChange:    () => {},
    size:        'md'
  }

  dropRef = React.createRef();
  inputRef = React.createRef();

  handleClick = () => {
    this.inputRef.current.click();
  }

  componentDidMount() {
    const ref = this.dropRef.current;

    ref.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    const ref = this.dropRef.current;

    ref.removeEventListener('click', this.handleClick);
  }

  render () {
    const { isMultiple, name } = this.props;

    return (
      <div
          className='mt-4'
          ref={this.dropRef}>
        <span>
          <img
              alt='Icon'
              className='iconScreen mr-2'
              src={MobileIcon} />
          Mobile Optimized
        </span>
        <span className='small d-flex text-muted mb-1 mt-1'>
          Add a portrait orientated image
        </span>
        <input
            className='d-none'
            id={name}
            multiple={isMultiple}
            name={name}
            onChange={this.props.onSelectFile}
            ref={this.inputRef}
            type='file' />


        { this.props.profile.mobilePhoto &&
          <Button
              className='upload-btn'
              color='success'
              isDisabled
              onClick={this.props.onSelectFile}>
            Uploaded
          </Button>
        }

        { !this.props.profile.mobilePhoto &&
          <Button
              className='upload-btn'
              color='primary'
              onClick={(e) => ( e.preventDefault() )}>
            Upload
          </Button>
        }
      </div>
    );
  }
}

export default MobileFileUploader;
