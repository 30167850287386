import React from 'react';
import PropTypes from 'prop-types';
import { ButtonGroup as BsButtonGroup } from 'reactstrap';
import { isMobile } from 'react-device-detect';

const ButtonGroup = ({ children, isVertical, className, size }) => {
  const buttonSize = isMobile ? 'sm' : 'lg';

  return (
    <BsButtonGroup
        className={className}
        size={size ? size : buttonSize}
        vertical={isVertical}>
      {children}
    </BsButtonGroup>
  );
};

ButtonGroup.propTypes = {
  children:   PropTypes.node.isRequired,
  className:  PropTypes.string,
  isVertical: PropTypes.bool,
  size:       PropTypes.string
};

ButtonGroup.defaultProps = {
  className:  '',
  isVertical: false,
  size:       ''
};

export default ButtonGroup;