import React from 'react';
import PropTypes from 'prop-types';
import { Input as BSInput, Button, Col, Container, Form, FormGroup, Label, Media, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { isMobile } from 'react-device-detect';

import { routes } from '../../../lib';
import { Heading, Panel } from '../../layout/pages';
import { BackButton } from '../../buttons';
import { FileUploader, Input } from '../../inputs';
import { getDiff } from '../../../lib';


class HomeImageWithTextPagePage extends React.PureComponent {
  static propTypes = {
    instructor:    PropTypes.object.isRequired,
    updateProfile: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props, state) {
    if (props.instructor.homeConfig.id && !state.isMounted) {
      const {
        iwtSectionHeader,
        iwtSectionImage,
        iwtSectionText,
        showImageWithTextSection,
        showBookButton
      } = props.instructor.homeConfig;

      return {
        isMounted: true,
        iwtSectionHeader,
        iwtSectionImage,
        iwtSectionText,
        showBookButton,
        showImageWithTextSection
      };
    }

    return state;
  }

  state = {
    isMounted:                false,
    iwtSectionHeader:         '',
    iwtSectionImage:          '',
    iwtSectionText:           '',
    showBookButton:           true,
    showImageWithTextSection: false,
    showOndemandButton:       true
  }

  handleToggle = (setting) => () => {
    this.setState((prevState) => ({
      [setting]: !prevState[setting]
    }));
  };

  handleUpdate = () => {
    const {
      iwtSectionHeader,
      iwtSectionImage,
      iwtSectionText,
      showBookButton,
      showOndemandButton
    } = this.state;

    const dataToUpdate = {
        iwtSectionHeader,
        iwtSectionImage,
        iwtSectionText,
        showBookButton,
        showOndemandButton
      };

    this.props.updateProfile({
      instructorHomeConfigAttributes: getDiff(dataToUpdate, this.props.instructor.homeConfig)
    });
  }

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value });
  }

  handleDeleteImage = () => {
    this.setState({ iwtSectionImage: '' });
  }

  render() {
    const {
      iwtSectionHeader,
      iwtSectionImage,
      iwtSectionText,
      showBookButton
    } = this.state;

    const updatedData = {
      iwtSectionHeader,
      iwtSectionImage,
      iwtSectionText,
      showBookButton
    };
    const buttonIsDisabled = !getDiff(updatedData, this.props.instructor.homeConfig);

    return (
      <React.Fragment>
        {isMobile && <BackButton to={routes.SETTINGS_HOME} />}

        <Helmet>
          <title>Image with Text</title>
        </Helmet>

        <div className='inner-wrapper instructor-settings'>
          <Container
              className='settings-page'
              fluid>
            <Heading
                color='secondary'
                subtitle='Home'
                title='Image with Text' />

            <Panel className='settings-menu'>
              <Media className='menu-link d-block'>
                <Form className='form-default'>
                  <Row
                      className='align-items-stretch'
                      form>
                    <Col xs={8}>
                      <FormGroup>
                        <Label htmlFor='heading'>
                          Heading
                        </Label>
                        <Input
                            id='heading'
                            isDisabled={!this.state.showImageWithTextSection}
                            name='iwtSectionHeader'
                            onChange={this.handleChange}
                            placeholder=''
                            value={this.state.iwtSectionHeader} />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor='body-text'>
                          Body Text
                        </Label>
                        <Input
                            id='body-text'
                            isDisabled={!this.state.showImageWithTextSection}
                            name='iwtSectionText'
                            onChange={this.handleChange}
                            placeholder=''
                            rows='10'
                            type='textarea'
                            value={this.state.iwtSectionText} />
                      </FormGroup>
                    </Col>
                    <Col
                        xs={4}>
                      <FormGroup className='h-100 pt-4 pb-2'>
                        <FileUploader
                            aspectRatio={1 / 1.1}
                            className='flex-fill'
                            fileTypes={['image/jpeg', 'image/png']}
                            hasCropper
                            maxFileSize='5MB'
                            name='iwtSectionImage'
                            onChange={this.handleChange}
                            onDelete={this.handleDeleteImage}
                            placeholder='Drop image here'
                            size='lg' />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
                <Media body>
                  <div className='custom-control custom-checkbox'>
                    <BSInput
                        checked={this.state.showBookButton}
                        className='custom-control-input'
                        disabled={!this.state.showImageWithTextSection}
                        id='book-class-button'
                        name='book-class-button'
                        onChange={this.handleToggle('showBookButton')}
                        type='checkbox' />
                    <Label
                        className='custom-control-label'
                        htmlFor='book-class-button'>
                      Show &quot;Book Class&quot; button
                    </Label>
                  </div>
                  <div className='mt-3'>
                    <Button
                        color='success'
                        disabled={!this.state.showImageWithTextSection || buttonIsDisabled}
                        onClick={this.handleUpdate}>
                      Save Changes
                    </Button>
                  </div>
                </Media>
              </Media>
            </Panel>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

export default HomeImageWithTextPagePage;
