import { isEmpty } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Collapse, Container, Nav, Navbar, NavbarToggler } from 'reactstrap';
import { Link } from '../../../../components/inputs';

import { ApplicationActions, DomainActions, SessionActions } from '../../../../actions';
import { SignMenu, UserMenu } from '../../../../components/layout/header/menu';
import { templates } from '../../../../constants';
import { Logo } from '../../../../images';
import { routes } from '../../../../lib';
import { InstructorMenu } from '../../../../components/layout/header/menu';
import NavigationUrban from './urban';
import NavgiationElegant from './elegant';

class CustomNavigationContainer extends React.PureComponent {
  static propTypes = {
    instructor:    PropTypes.object.isRequired,
    logOut:        PropTypes.func.isRequired,
    profile:       PropTypes.object.isRequired,
    session:       PropTypes.object.isRequired,
    template:      PropTypes.string,
    toggleSidebar: PropTypes.func.isRequired,
    user:          PropTypes.object.isRequired
  }

  static defaultProps = {
    template: 'default'
  }

  static getDerivedStateFromProps(props) {
    return {
      hasProfile:      !isEmpty(props.profile),
      isAuthenticated: props.session.authenticated
    };
  }

  state = {
    hasProfile:      false,
    isAuthenticated: false,
    isMenuOpen:      false,
    isSidebarOpen:   false,
    isUserMenuOpen:  false
  };

  handleSideMenu = () => {
    this.setState((prevState) => ({
      isSidebarOpen: !prevState.isSidebarOpen
    }));
  }

  handleUserMenu = () => {
    this.setState((prevState) => ({
      isUserMenuOpen: !prevState.isUserMenuOpen
    }));
  };

  handleMenuToggle = () => {
    if (this.state.isAuthenticated) {
      /*this.props.onMenuToggle();*/
      this.props.toggleSidebar();
    } else {
      this.setState((prevState) => ({
        isMenuOpen: !prevState.isMenuOpen
      }));
    }
  };

  renderDefaultNavbar = () => {
    const { instructor } = this.props;
    const { isAuthenticated, isUserMenuOpen, hasProfile } = this.state;

    return (
      <header
          className='header'
          style={{ position: 'sticky', top: 0 }}>
        <Navbar
            expand='lg'
            light>
          <Container>
            <NavbarToggler onClick={this.handleMenuToggle} />
            <React.Fragment>
              { !isMobile &&
                <Link
                    className='navbar-brand brand-hidden'
                    to={routes.HOME}>
                  <img
                      alt='onPodio'
                      src={Logo} />
                </Link>
              }
              <InstructorMenu instructor={instructor} />
            </React.Fragment>

            <Collapse
                className='navbar-user-menu'
                isOpen={this.state.isMenuOpen}
                navbar>
              <Nav
                  className='ml-auto align-items-lg-center'
                  navbar>
                { isAuthenticated ?
                  <UserMenu
                      hasProfile={hasProfile}
                      isOpen={isUserMenuOpen}
                      onToggle={this.handleUserMenu} /> : <SignMenu />
                }
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </header>
    );
  }

  render() {
    switch (this.props.template) {
      case templates.ELEGANT: return <NavgiationElegant {...this.props} />;

      case templates.URBAN: return (
        isMobile
        ? this.renderDefaultNavbar()
        : <NavigationUrban {...this.props} />
      );

      default: return this.renderDefaultNavbar();
    }
  }
}

const mapStateToProps = (state) => ({
  instructor:      state.instructor.data,
  isAuthenticated: state.session.authenticated,
  profile:         state.profile.data,
  session:         state.session,
  user:            state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  getDomain:     (data) => dispatch(DomainActions.GET(data)),
  logOut:        ()     => dispatch(SessionActions.LOGOUT()),
  toggleSidebar: () => dispatch(ApplicationActions.SIDEBAR_TOGGLE())
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomNavigationContainer);
