import routes, { clientsRoutes, onDemandRoutes } from '../lib/routes';

export default {
  HIDE_CREATE_BTN: [routes.ADD_PROFILE],
  HIDE_HEADER:     [routes.STORY_CREATE],
  HIDE_LAYOUT:     [routes.LOGIN, routes.SIGNUP, routes.RECOVER_PASSWORD],
  HIDE_SEARCH:     [routes.ADD_PROFILE, routes.AVAILABILITY, routes.CALENDAR, routes.CLIENTS, routes.HOME],
  SETTINGS_GROUP:  [
    routes.SETTINGS,
    routes.SETTINGS_HOME,
    routes.SETTINGS_HOME_INSTAGRAM,
    routes.SETTINGS_HOME_LANDING,
    routes.SETTINGS_HOME_TESTIMONIALS,
    routes.SETTINGS_NOTIFICATIONS,
    routes.SETTINGS_PACKAGES,
    routes.SETTINGS_WORKOUT,
    routes.SETTINGS_STYLE,
    routes.SETTINGS_WIDGETS,
    routes.EDIT_PROFILE,
    routes.SETTINGS_HOME_CLASSES,
    routes.SETTINGS_HOME_IMAGE_TEXT,
    routes.SETTINGS_ABOUT,
    routes.SETTINGS_CUSTOMIZATION,
    routes.SETTINGS_DOMAIN,
    routes.INTEGRATIONS,
    routes.SETTINGS_CANCELLATION,
    routes.LOCATIONS
  ],
  SHOW_ADD_MENU: [routes.CALENDAR],
  SHOW_FOOTER:   [routes.HOME, routes.CONTACT, routes.HELP],
  SHOW_SIDEBAR:  [
    routes.CALENDAR,
    routes.CLIENTS,
    routes.COUPONS,
    routes.AVAILABILITY,
    routes.DASHBOARD,
    routes.INSTRUCTOR_DASHBOARD,
    routes.SETTINGS,
    routes.EDIT_PROFILE,
    routes.EDIT_ACCOUNT,
    routes.LOCATIONS,
    routes.INTEGRATIONS,
    routes.PACKAGES,
    routes.SESSION_TYPES,
    routes.TRANSACTIONS,
    routes.INSTRUCTOR_CLASS_PLANNER,
    routes.SETTINGS_HOME,
    routes.SETTINGS_HOME_CLASSES,
    routes.SETTINGS_HOME_IMAGE_TEXT,
    routes.SETTINGS_HOME_INSTAGRAM,
    routes.SETTINGS_HOME_LANDING,
    routes.SETTINGS_HOME_TESTIMONIALS,
    routes.SETTINGS_ABOUT,
    routes.SETTINGS_WORKOUT,
    routes.SETTINGS_STYLE,
    routes.SETTINGS_MEMBERSHIP,
    routes.SETTINGS_NOTIFICATIONS,
    routes.SETTINGS_PACKAGES,
    routes.REQUESTED_CLASSES,
    routes.SETTINGS_CUSTOMIZATION,
    routes.SETTINGS_DOMAIN,
    routes.ON_DEMAND,
    routes.MARKETING,
    routes.WIN_BACK
  ],

  SIDEBAR_GROUPS: {
    ACCOUNT:        'account',
    CLIENT_PORTAL:  'client_portal',
    CREATOR_PORTAL: 'creator_portal'
  },

  SIDEBAR_GROUP_ROUTES: {
    ACCOUNT:       [routes.EDIT_ACCOUNT],
    CLIENT_PORTAL: [
      routes.DASHBOARD,
      routes.PURCHASES,
      routes.FAVORITE_ON_DEMANDS,
      routes.INSTRUCTOR_CLASS_PLANNER
    ],
    CREATOR_PORTAL: [
      routes.INSTRUCTOR_DASHBOARD,
      routes.CALENDAR,
      routes.CLIENTS + clientsRoutes.INDEX,
      routes.CLIENTS + clientsRoutes.CLIENT(':id'),
      routes.COUPONS,
      routes.ON_DEMAND + onDemandRoutes.INDEX,
      routes.ON_DEMAND + onDemandRoutes.COLLECTION(':id'),
      routes.REQUESTED_CLASSES,
      routes.TRANSACTIONS,
      routes.SUPPORT,
      routes.LOCATIONS,
      routes.SETTINGS,
      routes.EDIT_PROFILE,
      routes.SETTINGS_HOME_CLASSES,
      routes.SETTINGS_HOME_IMAGE_TEXT,
      routes.SETTINGS_ABOUT,
      routes.SETTINGS_CUSTOMIZATION,
      routes.SETTINGS_DOMAIN,
      routes.SETTINGS_CANCELLATION,
      routes.STORY_CREATE,
      routes.SETTINGS_HOME,
      routes.SETTINGS_HOME_INSTAGRAM,
      routes.SETTINGS_HOME_LANDING,
      routes.SETTINGS_HOME_TESTIMONIALS,
      routes.SETTINGS_NOTIFICATIONS,
      routes.SETTINGS_PACKAGES,
      routes.SETTINGS_WORKOUT,
      routes.SETTINGS_STYLE,
      routes.SETTINGS_WIDGETS,
      routes.PACKAGES,
      routes.INTEGRATIONS,
      routes.PLANS_AND_BILLING,
      routes.PRIVATE_SESSIONS,
      routes.SESSION_TYPES,
      routes.AVAILABILITY,
      routes.MARKETING,
      routes.WIN_BACK
    ]
  }
};
