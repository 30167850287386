import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import { filter, flatten, orderBy, take } from 'lodash';

import { Link } from '../../../../../components/inputs';
import { BookingModal, PrivateBookingModal, PurchaseClassModal } from '../../../../../components/modals';
import { Calendr, DateHelper, Moment } from '../../../../../lib';
import {
  BookingsActions,
  ClassPacksActions,
  CommentsActions,
  DiscountActions,
  MembershipsActions,
  PrivateBookingsActions
} from '../../../../../actions';
import { InstagramContainer } from '../../../../../containers';
import { instructorRoutes } from '../../../../../lib';
import TestimonialsCarousel from './testimonialsCarousels';

class HomeOptionalSectionsDefault extends React.PureComponent {
  static propTypes = {
    challenges:           PropTypes.array,
    classPacks:           PropTypes.array,
    comments:             PropTypes.array,
    createBooking:        PropTypes.func,
    createDiscount:       PropTypes.func,
    createPrivateBooking: PropTypes.func,
    discount:             PropTypes.object,
    fetchClassPacks:      PropTypes.func.isRequired,
    fetchComments:        PropTypes.func,
    fetchMemberships:     PropTypes.func.isRequired,
    fetchPrivateBookings: PropTypes.func,
    groupClasses:         PropTypes.array,
    hasCustomDomain:      PropTypes.bool.isRequired,
    homeConfig:           PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    instructor:           PropTypes.object,
    instructorPacks:      PropTypes.array.isRequired,
    loader:               PropTypes.object.isRequired,
    match:                PropTypes.object.isRequired,
    memberships:          PropTypes.array.isRequired,
    privateBookings:      PropTypes.array,
    privateGroupClasses:  PropTypes.array,
    requestedClasses:     PropTypes.array,
    restoreDiscount:      PropTypes.func,
    siteTemplate:         PropTypes.string,
    subscriptions:        PropTypes.array,
    subtractCredit:       PropTypes.func,
    user:                 PropTypes.object
  }

  static defaultProps = {
    challenges:           [],
    classPacks:           [],
    comments:             [],
    createBooking:        () => null,
    createDiscount:       () => null,
    createPrivateBooking: () => null,
    discount:             {},
    fetchComments:        () => null,
    fetchPrivateBookings: () => null,
    groupClasses:         [],
    homeConfig:           '',
    instructor:           {},
    privateBookings:      [],
    privateGroupClasses:  [],
    requestedClasses:     [],
    restoreDiscount:      () => null,
    siteTemplate:         'default',
    subscriptions:        [],
    subtractCredit:       () => null,
    user:                 {}
  }

  static getDerivedStateFromProps(props) {
    const allClasses = flatten(props.requestedClasses.map((collection) => (
      collection.requestedClasses.map((requestedClass) => (
        {
          ...requestedClass,
          challengeIds:  collection.challengeIds,
          classPackIds:  collection.classPackIds,
          membershipIds: collection.membershipIds
         }
      ))
    )));
    const filterDeleted = filter(allClasses, ['deletedAt', '']);
    const requestedClasses = take(orderBy(filterDeleted, ['createdAt'], ['desc']), 3);

    return { requestedClasses };
  }

  state = {
    groupClass:                   {},
    isGroupClassModalOpen:        false,
    isOndemandModalOpen:          false,
    isPrivateGroupClassModalOpen: false,
    onDemandClass:                {},
    requestedClasses:             []
  }

  componentDidMount() {
    if (this.props.homeConfig.showTestimonialsSection) {
      const { username } = this.props.instructor;
      this.props.fetchComments({ username });
    }

    if (this.props.homeConfig.showUpcomingClassesSection && this.props.user.id) this.props.fetchPrivateBookings();
  }

  renderTestimonials = () => {
    const { comments, hasCustomDomain, match } = this.props;
    const { testimonialsOverride, testimonialText, testimonialsName } = this.props.homeConfig;
    const override = { author: { fullName: testimonialsName }, body: testimonialText, id: 'override' };

    const testimonials = testimonialsOverride ? [override] : comments.slice(0, 5);

    const combineRoute = (route) => {
      return hasCustomDomain ? route : match.params.username + route;
    };

    return (
      <section className='testimonials-section'>
        <div className="testimonials-container">
          <div className="testimonials-heading-wrapper">
            <h2 className="testimonials-heading">Never taken a class? Hear what they say</h2>
            <Link
                className='btn btn-primary'
                to={combineRoute(instructorRoutes.STYLE.path)}>
              &rarr;
            </Link>
          </div>

          <TestimonialsCarousel items={testimonials} />
        </div>
      </section>
    );
  }

  renderLiveClasses = () => {
    const { groupClasses, privateGroupClasses, user } = this.props;
    const events = Calendr.combineClasses({ groupClasses, privateGroupClasses });
    const range = Moment.range(new Moment());
    const classes = Calendr.eventsInRange(events, user.timeZone, range).slice(0, 3);

    return classes.map((liveClass) => {
      const rawDate = DateHelper.summerTimeCheck(liveClass, user.timeZone);
      const date = DateHelper.getDate(rawDate, user.timeZone);

      return (
        <div
            className="live-class"
            key={liveClass.id}>
          <div className="live-class-content">
            <div className="live-class-left">
              <h4 className="class-name">{liveClass.name}</h4>
              <p className="class-date">{date.format('Do MMM | dddd')}</p>
            </div>
            <div className="live-class-right">
              <h4 className="class-time">{date.format('h:mmA')}</h4>
              <p className="class-duration">{liveClass.duration} min</p>
            </div>
          </div>
          { liveClass.imageType !== 'none' &&
            <div className='live-class-image'>
              <img
                  alt={liveClass.name}
                  src={liveClass.preview} />
            </div>
          }
          <Button onClick={this.handleToggle(liveClass)}>Reserve</Button>
        </div>
      );
    });
  }

  renderOnDemandClasses = () => {
    const { requestedClasses } = this.state;

    return requestedClasses.map((onDemandClass) => {
      return (
        <div
            className="ondemand-class"
            key={onDemandClass.id}>
          <h4 className="class-name">{onDemandClass.name}</h4>
          <div
              className="class-photo"
              style={{ backgroundImage: `url(${onDemandClass.poster})`}} />
          <Button onClick={() => this.handleOnDemandToggle(onDemandClass)}>Purchase</Button>
        </div>
      );
    });
  }

  handleBookingModal = () => {
    this.setState((prevState) => ({ isPrivateGroupClassModalOpen: !prevState.isPrivateGroupClassModalOpen }));
  }

  handleToggle = (groupClass) => () => {
    this.setState({ groupClass });

    if (groupClass.type == 'privateGroupClass') {
      this.setState((prevState) => ({ isPrivateGroupClassModalOpen: !prevState.isPrivateGroupClassModalOpen }));
    } else {
      this.setState((prevState) => ({ isGroupClassModalOpen: !prevState.isGroupClassModalOpen }));
    }
  };

  handleOnDemandToggle = (onDemandClass={}) => {
    this.setState((prevState) => ({
      isOndemandModalOpen: !prevState.isOndemandModalOpen,
      onDemandClass
    }));
  };

  render() {
    const {
      iwtSectionHeader,
      iwtSectionImage,
      iwtSectionText,
      showBookButton,
      showImageWithTextSection,
      showInstagramGallerySection,
      showLiveClasses,
      showOndemandClasses,
      showTestimonialsSection,
      showUpcomingClassesSection
    } = this.props.homeConfig;

    const { siteTemplate, hasCustomDomain, match } = this.props;

    const combineRoute = (route) => {
      return hasCustomDomain ? route : match.params.username + route;
    };

    return (
      <div className={`optional-sections template-${siteTemplate}`}>
        { showUpcomingClassesSection && (showOndemandClasses || showLiveClasses) &&
          <section className='upcoming-classes-section'>
            <h2 className='section-heading'>READY TO EXPERIENCE A CLASS?</h2>
            { showLiveClasses &&
              <div className="classes-container">
                <h3 className='sub-section-heading'>Upcoming Live Classes</h3>
                <div className='classes'>
                  {this.renderLiveClasses()}
                </div>
                <Link
                    className="btn btn-primary"
                    to={combineRoute(instructorRoutes.WORKOUT.path)}>
                  View My Class Schedule
                </Link>
              </div>
            }

            { showOndemandClasses &&
              <div className="classes-container">
                <h3 className='sub-section-heading'>Latest On-Demand Videos</h3>
                <div className='classes'>
                  {this.renderOnDemandClasses()}
                </div>
                <Link
                    className="btn btn-primary"
                    to={combineRoute(instructorRoutes.WORKOUT.path)}>
                  View My On-Demand Library
                </Link>
              </div>
            }
          </section>
        }

        { showImageWithTextSection &&
          <section className="image-with-text-section">
            <div className="iwt-container">
              <div className="iwt-content">
                <h2 className='section-heading'>{iwtSectionHeader}</h2>
                <p className='iwt-text'>{iwtSectionText}</p>
                <div className='iwt-section-buttons'>
                  { showBookButton &&
                    <Button
                        color='primary'
                        tag={Link}
                        to={combineRoute(instructorRoutes.WORKOUT.path)}>
                      Book Now
                    </Button>
                  }
                </div>
              </div>
            </div>
            <img
                alt=""
                className='iwt-section-image'
                onDragStart={(e) => e.preventDefault()}
                src={iwtSectionImage} />
          </section>
        }

        {showTestimonialsSection && this.renderTestimonials()}

        { showInstagramGallerySection &&
          <section className='instagram-gallery-section'>
            <div className="ig-gallery-container">
              <LazyLoadComponent threshold={100}>
                <InstagramContainer
                    hasTitle={false}
                    imagesCount={8} />
              </LazyLoadComponent>
            </div>
            <a
                className="btn btn-primary"
                href={`https://www.instagram.com/${this.props.instructor.socialProfiles.instagram}`}
                rel="noopener noreferrer"
                target="_blank">
              Follow Me
            </a>
          </section>
        }

        { this.state.isGroupClassModalOpen &&
          <BookingModal
              createBooking={this.props.createBooking}
              groupClass={this.state.groupClass}
              isOpen={this.state.isGroupClassModalOpen}
              onToggle={this.handleToggle(this.state.groupClass)}
              user={this.props.user} />
        }

        { this.state.isPrivateGroupClassModalOpen &&
          <PrivateBookingModal
              challenges={this.props.challenges}
              classPacks={this.props.classPacks}
              createBooking={this.props.createPrivateBooking}
              createDiscount={this.props.createDiscount}
              discount={this.props.discount}
              fetchClassPacks={this.props.fetchClassPacks}
              fetchMemberships={this.props.fetchMemberships}
              instructor={this.props.instructor}
              instructorPacks={this.props.instructorPacks}
              isOpen={this.state.isPrivateGroupClassModalOpen}
              loader={this.props.loader}
              memberships={this.props.memberships}
              onBook={this.handleBook}
              onToggle={this.handleBookingModal}
              privateBookings={this.props.privateBookings}
              privateGroupClass={this.state.groupClass}
              restoreDiscount={this.props.restoreDiscount}
              subscriptions={this.props.subscriptions}
              user={this.props.user} />
        }

        { this.state.isOndemandModalOpen &&
          <PurchaseClassModal
              challenges={this.props.challenges}
              classPacks={this.props.classPacks}
              createDiscount={this.props.createDiscount}
              discount={this.props.discount}
              fetchClassPacks={this.props.fetchClassPacks}
              fetchMemberships={this.props.fetchMemberships}
              instructor={this.props.instructor}
              instructorPacks={this.props.instructorPacks}
              isOpen={this.state.isOndemandModalOpen}
              loader={this.props.loader}
              memberships={this.props.memberships}
              onToggle={this.handleOnDemandToggle}
              requestedClass={this.state.onDemandClass}
              restoreDiscount={this.props.restoreDiscount}
              subscriptions={this.props.subscriptions}
              subtractCredit={this.props.subtractCredit}
              user={this.props.user} />
          }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  challenges:          state.challenges.userData,
  classPacks:          state.classPacks.userData,
  comments:            state.comments.data,
  discount:            state.discount.data,
  groupClasses:        state.groupClasses.instructorData,
  hasCustomDomain:     state.app.hasCustomDomain,
  instructorPacks:     state.classPacks.data,
  loader:              state.loader,
  memberships:         state.memberships.instructorData,
  privateBookings:     state.privateBookings.data,
  privateGroupClasses: state.privateGroupClasses.instructorData,
  requestedClasses:    state.requestedClasses.instructorData,
  subscriptions:       state.subscriptions.data,
  user:                state.user.data
});

const mapDispatchToProps = (dispatch) => ({
  createBooking:        (data) => dispatch(BookingsActions.CREATE(data)),
  createDiscount:       (data) => dispatch(DiscountActions.CREATE(data)),
  createPrivateBooking: (data) => dispatch(PrivateBookingsActions.CREATE(data)),
  fetchClassPacks:      (data) => dispatch(ClassPacksActions.FETCH(data)),
  fetchComments:        (data) => dispatch(CommentsActions.FETCH(data)),
  fetchMemberships:     (data) => dispatch(MembershipsActions.INSTRUCTORS_FETCH(data)),
  fetchPrivateBookings: ()     => dispatch(PrivateBookingsActions.FETCH()),
  restoreDiscount:      ()     => dispatch(DiscountActions.RESTORE()),
  subtractCredit:       (data) => dispatch(ClassPacksActions.SUBTRACT(data))

});

export default connect(mapStateToProps, mapDispatchToProps)(HomeOptionalSectionsDefault);
