import React from 'react';
import PropTypes from 'prop-types';

import { Calendr } from '../../../lib';

class DayTimes extends React.PureComponent {
  static propTypes = {
    isMobile: PropTypes.bool,
    times:    PropTypes.array.isRequired
  };

  static defaultProps = {
    isMobile: false
  };

  state = {
    displayFormat: Calendr.setTimeDisplayFormat(this.props.isMobile)
  };

  renderLabel = (time) => {
    const timeLabel = time.format(this.state.displayFormat);
    const timeId = time.format('hhA');

    return (
      <div
          className='hour-label'
          id={timeId}
          key={timeId}>
        <span>{timeLabel}</span>
      </div>
    );
  };

  render() {
    return (
      <div className='day-time-labels'>
        {this.props.times.map(this.renderLabel)}
      </div>
    );
  }
}

export default DayTimes;
