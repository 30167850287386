import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardText } from 'reactstrap';

import { Button } from '../forms';

class InstructorCancelUserBookingAlert extends React.PureComponent {
  static propTypes = {
    client:                      PropTypes.object.isRequired,
    onClose:                     PropTypes.func.isRequired,
    onHandleCancelClientBooking: PropTypes.func.isRequired,
    privateGroupClass:           PropTypes.func.isRequired
  };

  handleKeep = () => {
    this.props.onClose();
  };

  handleCancel = () => {
    const { client, privateGroupClass } = this.props;

    this.props.onHandleCancelClientBooking({ id: client.id, privateGroupClassId: privateGroupClass.id  });
    this.props.onClose();
  };

  render() {
    return (
      <div className='confirmation confirmation-recurring'>
        <Card body>
          <CardText>
            <span>
              Are you sure you want to remove<br />
              <span className='text-primary'> {this.props.client.name}</span>?
            </span>
          </CardText>

          <div className='actions'>
            <Button
                color='danger'
                isOutline
                onClick={this.handleCancel}>
              Yes-Remove
            </Button>

            <Button
                color='primary'
                onClick={this.handleKeep}>
              No-Keep
            </Button>
          </div>
        </Card>
      </div>
    );
  }
}

export default InstructorCancelUserBookingAlert;
