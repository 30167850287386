import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { Moment, getTimeZone } from '../../lib';
import { Button } from '../forms';

class BookingRequest extends React.PureComponent {
  static propTypes = {
    bookingRequest: PropTypes.object.isRequired,
    onApprove:      PropTypes.func,
    onDecline:      PropTypes.func,
    onUpdateLink:   PropTypes.func,
    user:           PropTypes.object.isRequired
  }

  static defaultProps = {
    onApprove:    () => {},
    onDecline:    () => {},
    onUpdateLink: () => {}
  }

  state = {
    date: ''
  }

  static getDerivedStateFromProps(props) {
    return {
      date: new Moment.utc(props.bookingRequest.startsAt, 'YYYY-MM-DDhh:mm:ss').tz(getTimeZone(props.user.timeZone))
    };
  }

  render() {
    const { bookingRequest } = this.props;

    const isOnline = bookingRequest.locationType == 'online';
    const isLinkEmpty = isEmpty(bookingRequest.digitalLink);

    return (
      <div className='request'>
        <div className='request-info'>
          <span className='name'>{bookingRequest.client.name}</span>

          <span className='date'>
            {this.state.date.format('ddd, MMM D YYYY')}
          </span>

          <span className='time'>
            {this.state.date.format('h:mm A')} -&nbsp;
            {bookingRequest.privateSessionType.duration}m ({bookingRequest.privateSessionType.name})
          </span>

          <span className='location'>
            {!isOnline && bookingRequest.location.name}
          </span>

          <span className='link'>
            { !isLinkEmpty &&
              <a
                  className='link d-block text-truncate'
                  href={bookingRequest.digitalLink}
                  rel='noopener noreferrer'
                  target='_blank'>
                {bookingRequest.digitalLink}
              </a>
            }
          </span>
        </div>

        <div className='float-right h-100 d-flex flex-column justify-content-center'>
          { isOnline && isLinkEmpty &&
            <Button
                color='success'
                onClick={this.props.onUpdateLink}
                size='sm'>
              Approve
            </Button>
          }

          { (!isLinkEmpty || !isOnline) &&
            <Button
                color='success'
                onClick={this.props.onApprove}
                size='sm'>
              Approve
            </Button>
          }

          <Button
              className='mt-1'
              color='danger'
              isOutline
              onClick={this.props.onDecline}
              size='sm'>
            Decline
          </Button>
        </div>
      </div>
    );
  }
}

export default BookingRequest;
