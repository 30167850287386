import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { map } from 'lodash';
import { toastr } from 'react-redux-toastr';

import { api, extractError, toCamelCase } from '../lib';
import { TransactionsActionTypes } from '../constants/actionTypes';
import { ErrorActions, LoaderActions, TransactionsActions } from '../actions';

function* watchPurchasesFetch() {
  yield takeLatest(TransactionsActionTypes.PURCHASES_FETCH, function* () {
    try {
      const response = yield call(api.transactions.purchase.fetch);

      yield put(TransactionsActions.PURCHASES_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(TransactionsActions.PURCHASES_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchClientPurchasesFetch() {
  yield takeLatest(TransactionsActionTypes.CLIENT_PURCHASES_FETCH, function* ({ payload }) {
    try {
      const response = yield call(api.instructor.client.transactions.fetch, payload);

      yield put(TransactionsActions.CLIENT_PURCHASES_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(TransactionsActions.CLIENT_PURCHASES_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchPurchaseUpdate() {
  yield takeLatest(TransactionsActionTypes.PURCHASE_UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.transactions.purchase.update, payload);
      const purchases = yield select((state) => state.transactions.purchases);

      const context = toCamelCase(response.data);
      const data = map(purchases, (purchase) => (
        purchase.id === context.id ? context : purchase
      ));

      yield put(TransactionsActions.PURCHASE_UPDATE_SUCCESS(data));
      toastr.success('Transaction updated');
    } catch (error) {
      yield put(TransactionsActions.PURCHASE_UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchMembershipsFetch() {
  yield takeLatest(TransactionsActionTypes.MEMBERSHIPS_FETCH, function* () {
    try {
      const response = yield call(api.transactions.membership.fetch);

      yield put(TransactionsActions.MEMBERSHIPS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(TransactionsActions.MEMBERSHIPS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchMembershipUpdate() {
  yield takeLatest(TransactionsActionTypes.MEMBERSHIP_UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.transactions.membership.update, payload);
      const memberships = yield select((state) => state.transactions.memberships);

      const context = toCamelCase(response.data);
      const data = map(memberships, (membership) => (
        membership.id === context.id ? context : membership
      ));

      yield put(TransactionsActions.MEMBERSHIP_UPDATE_SUCCESS(data));
      toastr.success('Transaction updated');
    } catch (error) {
      yield put(TransactionsActions.MEMBERSHIP_UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchClassPacksFetch() {
  yield takeLatest(TransactionsActionTypes.CLASS_PACKS_FETCH, function* () {
    try {
      const response = yield call(api.transactions.classPack.fetch);

      yield put(TransactionsActions.CLASS_PACKS_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(TransactionsActions.CLASS_PACKS_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}


function* watchClassPackUpdate() {
  yield takeLatest(TransactionsActionTypes.CLASS_PACK_UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.transactions.classPack.update, payload);
      const classPacks = yield select((state) => state.transactions.classPacks);

      const context = toCamelCase(response.data);
      const data = map(classPacks, (classPack) => (
        classPack.id === context.id ? context : classPack
      ));

      yield put(TransactionsActions.CLASS_PACK_UPDATE_SUCCESS(data));
      toastr.success('Transaction updated');
    } catch (error) {
      yield put(TransactionsActions.CLASS_PACK_UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchChallengesFetch() {
  yield takeLatest(TransactionsActionTypes.CHALLENGES_FETCH, function* () {
    try {
      const response = yield call(api.transactions.challenge.fetch);

      yield put(TransactionsActions.CHALLENGES_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(TransactionsActions.CHALLENGES_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}


function* watchChallengeUpdate() {
  yield takeLatest(TransactionsActionTypes.CHALLENGE_UPDATE, function* ({ payload }) {
    try {
      const response = yield call(api.transactions.challenge.update, payload);
      const challenges = yield select((state) => state.transactions.challenges);

      const context = toCamelCase(response.data);
      const data = map(challenges, (challenge) => (
        challenge.id === context.id ? context : challenge
      ));

      yield put(TransactionsActions.CHALLENGE_UPDATE_SUCCESS(data));
      toastr.success('Transaction updated');
    } catch (error) {
      yield put(TransactionsActions.CHALLENGE_UPDATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchSummaryFetch() {
  yield takeLatest(TransactionsActionTypes.SUMMARY_FETCH, function* () {
    try {
      yield put(LoaderActions.START_FIELD_LOADING('summary'));
      const response = yield call(api.transactions.summary.fetch);

      yield put(TransactionsActions.SUMMARY_FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(TransactionsActions.SUMMARY_FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchSummaryFetchSuccess() {
  yield takeLatest(TransactionsActionTypes.SUMMARY_FETCH_SUCCESS, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('summary'));
  });
}

function* watchSummaryFetchFailure() {
  yield takeLatest(TransactionsActionTypes.SUMMARY_FETCH_FAILURE, function* () {
    yield put(LoaderActions.FINISH_FIELD_LOADING('summary'));
  });
}


export default function* transactionsSaga() {
  yield all([
    watchPurchasesFetch(),
    watchPurchaseUpdate(),
    watchMembershipsFetch(),
    watchMembershipUpdate(),
    watchChallengesFetch(),
    watchChallengeUpdate(),
    watchClassPacksFetch(),
    watchClassPackUpdate(),
    watchSummaryFetch(),
    watchSummaryFetchSuccess(),
    watchSummaryFetchFailure(),

    watchClientPurchasesFetch()
  ]);
}
