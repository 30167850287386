import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { CSVLink } from 'react-csv';

import { tableOptions } from '../../../../constants/transactions';
import { ExportTransactions } from '../../../../lib';

class ClassPacksTable extends PureComponent {
  static propTypes = {
    coupons:         PropTypes.array.isRequired,
    fetchClassPacks: PropTypes.func.isRequired,
    isLoading:       PropTypes.bool.isRequired,
    transactions:    PropTypes.array.isRequired,
    updateClassPack: PropTypes.func.isRequired
  }

  static getDerivedStateFromProps(props) {
    const coupons = props.coupons.map((coupon) => {
      return { label: coupon.code, value: coupon.code };
    });

    return {
      coupons: coupons,
      data:    props.transactions
    };
  }

  componentDidMount () {
    this.props.fetchClassPacks();
  }

  state = {
    coupons: [],
    data:    []
  }

  handleSave = (_, note, row) => {
    const id = parseInt(row.id.replace( /^\D+/g, ''));

    this.props.updateClassPack({ id, note });
  }

  render () {
    const { isLoading } = this.props;
    const { data, coupons } = this.state;

    const options = tableOptions(coupons);

    const defaultSorted = [{
      dataField: 'createdAt',
      order:     'desc'
    }];

    const cellEdit = cellEditFactory({
      beforeSaveCell: this.handleSave,
      blurToSave:     true,
      mode:           'click'
    });

    return (
      <React.Fragment>
        <CSVLink
            className='mb-2 text-decoration-none'
            data={ExportTransactions.getClassPacks(data)}
            filename='my-class-packs-sales.csv'>
          Export
        </CSVLink>

        <BootstrapTable
            bootstrap4
            cellEdit={cellEdit}
            columns={options.CLASS_PACKS}
            data={data}
            defaultSorted={defaultSorted}
            filter={filterFactory()}
            filterPosition='top'
            hover
            keyField='id'
            loading={isLoading}
            pagination={paginationFactory(options.PAGINATION)}
            wrapperClasses='table-responsive' />
      </React.Fragment>
    );
  }
}

export default ClassPacksTable;
