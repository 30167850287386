import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { eyeDisabled } from 'react-icons-kit/ionicons/eyeDisabled';

import { Button } from '../../../components/forms';

class ClassPack extends React.PureComponent {
  static propTypes = {
    classPack: PropTypes.object.isRequired,
    onEdit:    PropTypes.func.isRequired,
    user:      PropTypes.object.isRequired
  }

  render() {
    const { classPack, user } = this.props;
    const { currency } = user;

    return (
      <div className='row'>
        <span className='column column__name'>
          Name:
          <span className='value'>
            {classPack.name}
          </span>
        </span>

        <span className='column column__cost'>
          Price:
          <span className='value'>
            {classPack.cost.toLocaleString('en-GB', { currency, style: 'currency' })} per {classPack.number} credits
          </span>
        </span>

        <span className='column column__expiry'>
          Expiry:
          <span className='value'>
            {classPack.canExpire ? `${classPack.expiry} days` : 'None'}
          </span>
        </span>

        <span className='column column__sold'>
          # Sold:
          <span className='value'>
            {classPack.purchases ? classPack.purchases.length : 0} packs
          </span>
        </span>

        <div className='column button-wrapper column__button'>
          { !classPack.visible &&
            <Icon
                className='text-muted mr-2'
                icon={eyeDisabled}
                size={24} />
          }
          <Button
              className='button'
              color='primary'
              isOutline
              onClick={this.props.onEdit(classPack.id)}
              size='sm'>
            Edit Class Pack
          </Button>
        </div>
      </div>
    );
  }
}

export default ClassPack;
