import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'react-icons-kit';
import { androidClose } from 'react-icons-kit/ionicons/androidClose';

const RemoveIcon = ({ size }) => (
  <Icon
      icon={androidClose}
      size={size} />
);

RemoveIcon.propTypes = {
  size: PropTypes.number
};

RemoveIcon.defaultProps = {
  size: 32
};

export default RemoveIcon;