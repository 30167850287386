/* global process */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Redirect } from 'react-router';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { SUBSCRIPTION_STEPS } from '../../constants';
import { routes } from '../../lib';
import { UserModalAvatar } from './layouts';
import { Info, Payment, SignUp, Success } from './subscription';

class SubscriptionModal extends React.PureComponent {
  static propTypes = {
    createDiscount:     PropTypes.func.isRequired,
    createSubscription: PropTypes.func.isRequired,
    discount:           PropTypes.object.isRequired,
    instructor:         PropTypes.object.isRequired,
    isOpen:             PropTypes.bool,
    membership:         PropTypes.object.isRequired,
    onToggle:           PropTypes.func,
    subscriptions:      PropTypes.array.isRequired,
    user:               PropTypes.object.isRequired
  }

  static defaultProps = {
    isOpen:   false,
    onToggle: () => {}
  }

  state = {
    customer:  {},
    isLoading: false,
    step:      SUBSCRIPTION_STEPS.info
  }

  handleLoading = (isLoading, callback) => (
    this.setState({ isLoading }, callback)
  )

  handleStep = (step) => (
    this.handleLoading(true, () => (
      this.setState({ step }, () => this.handleLoading(false))
    ))
  )

  handleSignUp = () => (
    this.handleStep(SUBSCRIPTION_STEPS.payment)
  )

  handleToggle = () => {
    if (this.state.isLoading) return;
    this.props.onToggle();
  }

  renderContent() {
    const { instructor } = this.props;

    const stripePromise = () => (
      loadStripe(process.env.STRIPE_API_KEY, { stripeAccount: instructor.stripeId })
    );

    switch (this.state.step) {
      case SUBSCRIPTION_STEPS.info:
        return (
          <Info
              instructor={instructor}
              membership={this.props.membership} />
        );

      case SUBSCRIPTION_STEPS.payment:
        return (
          <Elements stripe={stripePromise()}>
            <Payment
                createDiscount={this.props.createDiscount}
                createSubscription={this.props.createSubscription}
                customer={this.state.customer}
                discount={this.props.discount}
                instructor={instructor}
                isLoading={this.state.isLoading}
                membership={this.props.membership}
                onChangeStep={this.handleStep}
                onLoading={this.handleLoading}
                user={this.props.user} />
          </Elements>
        );

      case SUBSCRIPTION_STEPS.success:
        return (
          <Success
              instructor={instructor}
              membership={this.props.membership} />
        );

      default: return null;
    }
  }

  renderFooter() {
    switch (this.state.step) {
      case SUBSCRIPTION_STEPS.info:
        return (
          <SignUp
              instructor={this.props.instructor}
              isLoading={this.state.isLoading}
              membership={this.props.membership}
              onClick={this.handleSignUp}
              subscriptions={this.props.subscriptions} />
        );

      default: return null;

    }
  }

  render() {
    const { instructor, membership, user } = this.props;

    if (!user.id) {
      return <Redirect to={routes.LOGIN} />;
    }

    return (
      <Modal
          className='modal-booking'
          isOpen={this.props.isOpen}
          toggle={this.handleToggle}>
        <ModalHeader toggle={this.handleToggle} />
        <div className='new-modal'>
          <ModalBody>
            { this.state.step != SUBSCRIPTION_STEPS.success &&
              <React.Fragment>
                { instructor &&
                  <div className='modal__header'>
                    <UserModalAvatar
                        alt={instructor.name}
                        firstName={instructor.name}
                        src={instructor.photo.sm} />
                    <div className='modal__instructor-name'>
                      <span className='modal__big-text'>{instructor.name}</span>
                    </div>
                  </div>
                }

                <div className='modal__event-name'>
                  <span className='modal__big-text modal__big-text_black'>{membership.name}</span>
                </div>

                <div className='event-description'>
                  <span className='modal__small-text'>About this membership</span>
                  <p className='modal__small-text modal__small-text_grey'>{membership.description}</p>
                </div>
              </React.Fragment>
            }
            {this.renderContent()}
          </ModalBody>
        </div>
        {this.renderFooter()}
      </Modal>
    );
  }
}

export default SubscriptionModal;
