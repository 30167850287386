import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { chain, filter, find, isEmpty, keys, map } from 'lodash';

import { Calendr, ModalSaver, Moment, getTemplate } from '../../../../lib';
import { Loader } from '../../../loader';
import { GroupClasses, TimeZone } from '../';
import { GroupClassesScheduleModal } from '../../../modals';

class UpcomingClasses extends React.PureComponent {
  static propTypes = {
    challenges:             PropTypes.array.isRequired,
    classPacks:             PropTypes.array.isRequired,
    createBooking:          PropTypes.func.isRequired,
    createDiscount:         PropTypes.func.isRequired,
    createPrivateBooking:   PropTypes.func.isRequired,
    discount:               PropTypes.object.isRequired,
    fetchClassPacks:        PropTypes.func.isRequired,
    fetchMemberships:       PropTypes.func.isRequired,
    fetchPrivateBookings:   PropTypes.func.isRequired,
    getGroupClasses:        PropTypes.func.isRequired,
    getPrivateGroupClasses: PropTypes.func.isRequired,
    groupClasses:           PropTypes.array.isRequired,
    instructor:             PropTypes.object.isRequired,
    instructorPacks:        PropTypes.array.isRequired,
    loader:                 PropTypes.object.isRequired,
    memberships:            PropTypes.array.isRequired,
    privateBookings:        PropTypes.array.isRequired,
    privateGroupClasses:    PropTypes.array.isRequired,
    restoreDiscount:        PropTypes.func.isRequired,
    subscriptions:          PropTypes.array.isRequired,
    user:                   PropTypes.object.isRequired,
    username:               PropTypes.string.isRequired
  }

  static getDerivedStateFromProps(props) {
    const { groupClasses, privateGroupClasses, user } = props;
    const events = Calendr.combineClasses({ groupClasses, privateGroupClasses });

    const instructorPacks = filter(props.classPacks, ['instructor.id', props.instructor.id]);
    const classPacks = filter(instructorPacks, (classPack) => (
      classPack.live > 0 || classPack.combined > 0
    ));
    const challenges = filter(props.challenges, {'instructorId': props.instructor.id, 'live': true });

    return {
      challenges,
      classPacks,
      classes: Calendr.getAvailableEvents(events, user.timeZone)
    };
  }

  componentDidMount() {
    const { username } = this.props;

    if (this.props.user.id) this.props.fetchPrivateBookings();
    this.props.getGroupClasses({ username });

    const uuid = ModalSaver._read();
    const privateGroupClass = find(this.props.privateGroupClasses, ['uuid', uuid]);

    if (privateGroupClass) this.setState({ isModalOpen: true, privateGroupClass });
  }

  state = {
    classPacks:     [],
    classes:        [],
    isScheduleOpen: false
  }

  handleToggle = (e) => {
    e.preventDefault();
    this.setState((prevState) => ({
      isScheduleOpen: !prevState.isScheduleOpen
    }));
  }

  setDateStyle = (index) => {
    const siteTemplate = getTemplate(this.props.instructor);

    if (siteTemplate !== 'elegant') return new Moment(index, 'YYYY-MM-DDhh:mm:ss').format('dddd | DD MMM');
    else return new Moment(index, 'YYYY-MM-DDhh:mm:ss').format('DD MMM, dddd');
  }

  render () {
    const { instructor, user, loader } = this.props;
    const { isScheduleOpen } = this.state;

    const groupedClasses = Calendr.eventsGroupBy(this.state.classes, user.timeZone, 'YYYY-MM-DD');
    const nearestClasses = chain(groupedClasses).toPairs().sortBy(0).take(10).fromPairs().value();
    const leadIndex = keys(nearestClasses)[0];
    const isLoading = loader.groupClasses.isLoading || loader.privateGroupClasses.isLoading;

    return (
      <div className='group__clasess'>
        <TimeZone timeZone={user.timeZone} />
        <div className='group-classes'>
          {isLoading && <Loader isLoading />}

          <div className='workout__section-header'>
            <h6 className='workout__section-headspan'>Group Classes</h6>
            <a
                className='group-classes__view-all'
                href='#!'
                onClick={this.handleToggle}>View all</a>
          </div>
          <div>
            { !isEmpty(nearestClasses) && map(nearestClasses, (dayGroup, index) => {
              const date = this.setDateStyle(index);
              /* a trick to load modal only once when reading from URL */
              const isLead = index == leadIndex;

              return (
                <div
                    className='group-class'
                    key={index}>
                  <div className='workout__date-header'>
                    <span className='workout__date'>{date}</span>
                  </div>
                  <GroupClasses
                      challenges={this.state.challenges}
                      classPacks={this.state.classPacks}
                      createBooking={this.props.createBooking}
                      createDiscount={this.props.createDiscount}
                      createPrivateBooking={this.props.createPrivateBooking}
                      discount={this.props.discount}
                      fetchClassPacks={this.props.fetchClassPacks}
                      fetchMemberships={this.props.fetchMemberships}
                      instructor={instructor}
                      instructorPacks={this.props.instructorPacks}
                      isModalOpen={isLead && this.state.isModalOpen}
                      isScheduleOpen={this.state.isScheduleOpen}
                      loader={loader}
                      memberships={this.props.memberships}
                      nearestClasses={dayGroup}
                      privateBookings={this.props.privateBookings}
                      privateGroupClass={this.state.privateGroupClass}
                      restoreDiscount={this.props.restoreDiscount}
                      subscriptions={this.props.subscriptions}
                      user={user} />
                </div>
              );
            })}
            { isEmpty(nearestClasses) &&
              <div className='group-classes__empty'>
                <span className='group-classes__emptyspan'>No upcoming classes</span>
              </div>
            }
          </div>

          { isScheduleOpen &&
            <GroupClassesScheduleModal
                challenges={this.state.challenges}
                classPacks={this.state.classPacks}
                classes={this.state.classes}
                createBooking={this.props.createBooking}
                createDiscount={this.props.createDiscount}
                createPrivateBooking={this.props.createPrivateBooking}
                discount={this.props.discount}
                fetchClassPacks={this.props.fetchClassPacks}
                fetchMemberships={this.props.fetchMemberships}
                getPrivateGroupClasses={this.props.getPrivateGroupClasses}
                instructor={instructor}
                instructorPacks={this.props.instructorPacks}
                isOpen={isScheduleOpen}
                loader={loader}
                memberships={this.props.memberships}
                onToggle={this.handleToggle}
                privateBookings={this.props.privateBookings}
                restoreDiscount={this.props.restoreDiscount}
                subscriptions={this.props.subscriptions}
                user={user}
                username={this.props.username} />
          }
        </div>
      </div>
    );
  }
}

export default withRouter(UpcomingClasses);
