export default {
  CREATE:         '@@privateSessions/CREATE',
  CREATE_FAILURE: '@@privateSessions/CREATE_FAILURE',
  CREATE_SUCCESS: '@@privateSessions/CREATE_SUCCESS',

  DELETE:         '@@privateSessions/DELETE',
  DELETE_FAILURE: '@@privateSessions/DELETE_FAILURE',
  DELETE_SUCCESS: '@@privateSessions/DELETE_SUCCESS',

  FETCH:         '@@privateSessions/FETCH',
  FETCH_FAILURE: '@@privateSessions/FETCH_FAILURE',
  FETCH_SUCCESS: '@@privateSessions/FETCH_SUCCESS',

  INSTRUCTORS_CREATE:         '@@privateSessions/INSTRUCTORS/CREATE',
  INSTRUCTORS_CREATE_FAILURE: '@@privateSessions/INSTRUCTORS/CREATE_FAILURE',
  INSTRUCTORS_CREATE_SUCCESS: '@@privateSessions/INSTRUCTORS/CREATE_SUCCESS',

  INSTRUCTORS_DELETE:         '@@privateSessions/INSTRUCTORS/DELETE',
  INSTRUCTORS_DELETE_FAILURE: '@@privateSessions/INSTRUCTORS/DELETE_FAILURE',
  INSTRUCTORS_DELETE_SUCCESS: '@@privateSessions/INSTRUCTORS/DELETE_SUCCESS',

  INSTRUCTORS_FETCH:         '@@privateSessions/INSTRUCTORS/FETCH',
  INSTRUCTORS_FETCH_FAILURE: '@@privateSessions/INSTRUCTORS/FETCH_FAILURE',
  INSTRUCTORS_FETCH_SUCCESS: '@@privateSessions/INSTRUCTORS/FETCH_SUCCESS',

  INSTRUCTORS_UPDATE:         '@@privateSessions/INSTRUCTORS/UPDATE',
  INSTRUCTORS_UPDATE_FAILURE: '@@privateSessions/INSTRUCTORS/UPDATE_FAILURE',
  INSTRUCTORS_UPDATE_SUCCESS: '@@privateSessions/INSTRUCTORS/UPDATE_SUCCESS',

  RECURRING_CREATE:         '@@privateSessions/RECURRING/CREATE',
  RECURRING_CREATE_FAILURE: '@@privateSessions/RECURRING/CREATE_FAILURE',
  RECURRING_CREATE_SUCCESS: '@@privateSessions/RECURRING/CREATE_SUCCESS',

  RECURRING_DELETE:         '@@privateSessions/RECURRING/DELETE',
  RECURRING_DELETE_FAILURE: '@@privateSessions/RECURRING/DELETE_FAILURE',
  RECURRING_DELETE_SUCCESS: '@@privateSessions/RECURRING/DELETE_SUCCESS',

  RECURRING_UPDATE:         '@@privateSessions/RECURRING/UPDATE',
  RECURRING_UPDATE_FAILURE: '@@privateSessions/RECURRING/UPDATE_FAILURE',
  RECURRING_UPDATE_SUCCESS: '@@privateSessions/RECURRING/UPDATE_SUCCESS',

  UPDATE:         '@@privateSessions/UPDATE',
  UPDATE_FAILURE: '@@privateSessions/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@privateSessions/UPDATE_SUCCESS'
};
