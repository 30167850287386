import React from 'react';
import PropTypes from 'prop-types';

import { ProfileInfoPicture } from '../../../../../images';
import { AdditionalInfoForm } from '../../../../forms/profile/create';
import { PanelImage } from '../../../../layout/pages';

class AdditionalInfoStep extends React.PureComponent {
  static propTypes = {
    createAdditionalnfo: PropTypes.func.isRequired,
    nextStep:            PropTypes.func,
    refferal:            PropTypes.string.isRequired
  }

  static defaultProps = {
    nextStep: () => {}
  }

  handleCreate = (data) => {
    this.props.createAdditionalnfo(data);
    setTimeout(() => {
      this.props.nextStep();
    }, 600);
  }

  render () {
    return (
      <PanelImage
          id='profile-basic-info'
          imageSrc={ProfileInfoPicture}>
        <AdditionalInfoForm
            onCreate={this.handleCreate}
            refferal={this.props.refferal} />
      </PanelImage>
    );
  }
}

export default AdditionalInfoStep;
