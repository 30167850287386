/* global moment */
import React from 'react';
import PropTypes from 'prop-types';

class GroupClass extends React.PureComponent {
  static propTypes = {
    groupClass: PropTypes.object.isRequired,
    onClick:    PropTypes.func.isRequired
  }

  handleClick = () => {
    this.props.onClick(this.props.groupClass);
  }

  render() {
    const { groupClass } = this.props;

    return (
      <div
          className='group-class-info p-3 m-1'
          onClick={this.handleClick}
          onKeyPress={this.handleClick}
          role='button'
          tabIndex={0}>
        <div className='d-inline-block'>
          <strong>{groupClass.name}</strong>
          <div className='text-muted text-sm'>
            {groupClass.location.name}
          </div>
        </div>

        <div className='float-right'>
          <strong>
            {new moment(groupClass.startsAt, 'YYYY-MM-DDhh:mm:ss').format('h:mm A')}
          </strong>
          <div className='text-muted text-sm'>
            {groupClass.duration}m
          </div>
        </div>
      </div>
    );
  }
}

export default GroupClass;
