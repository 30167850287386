import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'react-avatar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isMobile } from 'react-device-detect';

class UserModalAvatar extends React.PureComponent {
  static propTypes = {
    alt:       PropTypes.string,
    firstName: PropTypes.string,
    src:       PropTypes.string
  };

  static defaultProps = {
    alt:       '',
    firstName: '',
    src:       null
  };

  render() {
      if (!this.props.src) {
        return (
          <Avatar
              color='#BCDCFF'
              name={this.props.firstName.charAt(0)}
              round={isMobile ? '26px' : '40px'}
              size={isMobile ? '56' : '80'} />
        );
      } else {
        return (
          <LazyLoadImage
              alt={this.props.alt}
              className='modal__user-avatar'
              src={this.props.src} />
        );
      }
    }
}

export default UserModalAvatar;
