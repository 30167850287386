import React, { Component } from 'react';
import PropTypes from 'prop-types';

const HeaderButtonContext = React.createContext();

class HeaderButtonProvider extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired
  }

  state = {
    hideButton: false
  }

  changeButtonState = (value) => {
    this.setState({ hideButton: value });
  }

  render() {
    const { children } = this.props;
    const { hideButton } = this.state;
    const { changeButtonState } = this;

    return (
      <HeaderButtonContext.Provider value={{ changeButtonState, hideButton }}>
        {children}
      </HeaderButtonContext.Provider>
    );
  }
}

export default HeaderButtonContext;

export { HeaderButtonProvider };
