/* global moment */
import React from 'react';
import PropTypes from 'prop-types';

import { Col, Container, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';

import { getTimeZone } from '../../lib';
import { Button } from '../forms';
import { Input } from '../inputs';

class UserPrivateSessionModal extends React.PureComponent {
  static propTypes = {
    deletePrivateSession: PropTypes.func.isRequired,
    isDashboard:          PropTypes.bool,
    isOpen:               PropTypes.bool,
    onToggle:             PropTypes.func,
    privateSession:       PropTypes.object.isRequired,
    user:                 PropTypes.object.isRequired

  }

  static defaultProps = {
    isDashboard: false,
    isOpen:      false,
    onToggle:    () => {}
  }

  state = {
    date:           '',
    isCancel:       false,
    note:           '',
    privateSession: {}
  }

  static getDerivedStateFromProps(props) {
    const privateSession = props.isDashboard ? props.privateSession.event : props.privateSession;

    return {
      date: new moment.utc(privateSession.startsAt, 'YYYY-MM-DDhh:mm:ssUTC').tz(getTimeZone(props.user.TimeZone)),
      privateSession
    };
  }

  handleDelete = () => {
    const { privateSession } = this.state;
    const { note } = this.state;

    this.props.deletePrivateSession({ note, privateSession });
    this.props.onToggle();
  }

  handleChange = (value, inputName) => {
    this.setState({ [inputName]: value });
  }

  handleCancel = () => {
    this.setState((prevState) => ({ isCancel: !prevState.isCancel }));
  }

  renderNote = () => (
    <React.Fragment>
      <div className='mt-4 mb-4'>
        <strong className='text-danger'>Request To Cancel</strong>

        <p className='text-sm'>
          The request <strong>will not</strong> cancel the
          booking until reviewed and approved by the instructor
        </p>
      </div>

      <Form>
        <FormGroup>
          <Label
              className='text-muted text-sm'
              for='input-note'>
            Edit Reply
          </Label>

          <Input
              id='note'
              name='note'
              onChange={this.handleChange}
              placeholder='Type your information here...'
              rows='4'
              type='textarea'
              value={this.state.note} />
        </FormGroup>
      </Form>
    </React.Fragment>
  );

  render() {
    const { privateSession } = this.state;
    const { date } = this.state;
    const isVirtual = privateSession.locationType === 'online';

    return (
      <Modal
          className='event-modal'
          isOpen={this.props.isOpen}
          toggle={this.handleToggle}>
        <ModalHeader>View Private Session</ModalHeader>

        <ModalBody>
          <Container fluid>
            <Row>
              <Col>
                <h6 className='text-primary'>
                  Private Session Details
                </h6>

                <div className='d-block mt-3 mb-4'>
                  <strong>{privateSession.instructor.name}</strong>

                  <strong>
                    {privateSession.client.name} ({privateSession.location.name})
                  </strong>

                  <div className='text-muted text-sm'>
                    {date.format('ddd, MMM D YYYY')}
                  </div>

                  <div className='text-muted text-sm'>
                    {date.format('h:mm A')} -&nbsp;
                    {privateSession.privateSessionType.duration}m ({privateSession.privateSessionType.name})
                  </div>

                  { isVirtual &&
                    <>
                      { privateSession.digitalLink &&
                        <div className='link'>
                          <a
                              className='d-block text-truncate'
                              href={privateSession.digitalLink}
                              rel='noopener noreferrer external'
                              target='_blank'>
                            {privateSession.digitalLink}
                          </a>
                        </div>
                      }
                      
                      <div className='meettingId'>
                        { privateSession.meetingUid &&
                          <div className=''>
                            <strong>MeetingId:</strong> {privateSession.meetingUid}
                          </div>
                        }

                        { privateSession.passcode &&
                          <div>
                            <strong>Passcode:</strong> {privateSession.passcode}
                          </div>
                        }
                      </div>
                    </>
                  }

                  {this.state.isCancel && this.renderNote()}
                </div>
              </Col>
            </Row>
          </Container>
        </ModalBody>

        <ModalFooter>
          <Container>
            <Row>
              { this.state.isCancel &&
                <Button
                    className='w-100'
                    color='outline-danger'
                    onClick={this.handleDelete}>
                  Send Request
                </Button>
              }

              { !this.state.isCancel &&
                <React.Fragment>
                  <p className='text-sm'>
                    To send a request to cancel your private
                    session booking click below
                  </p>

                  <Button
                      className='w-100'
                      color='outline-danger'
                      onClick={this.handleCancel}>
                    Request To Cancel
                  </Button>
                </React.Fragment>
              }
            </Row>

            <Row>
              <Button
                  className='w-100 mt-3'
                  color='primary'
                  onClick={this.props.onToggle}>
                Close
              </Button>
            </Row>
          </Container>
        </ModalFooter>
      </Modal>
    );
  }
}

export default UserPrivateSessionModal;
