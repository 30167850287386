/* eslint-disable react/no-unused-state */

import React from 'react';
import PropTypes from 'prop-types';
import { Col, FormFeedback, FormGroup, FormText, Label, Row } from 'reactstrap';
import { isEmpty } from 'lodash';
import classNames from 'classnames';

import { Button, Checkbox, Form, MaskedInput, Radio } from '../';
import { Input, Upload } from '../../inputs';
import { Scroller, Validator } from '../../../lib';
import { FEES, currencies } from '../../../constants';
import { currencyMask, dayMask } from '../../../constants/masks';
import { CropModal } from '../../modals';

class RequestedClassForm extends React.PureComponent {
  static propTypes = {
    data:        PropTypes.object,
    isLoading:   PropTypes.bool,
    isNewRecord: PropTypes.bool,
    onDelete:    PropTypes.func,
    onSubmit:    PropTypes.func,
    user:        PropTypes.object.isRequired
  }

  static defaultProps = {
    data: {
      cost:        '',
      description: '',
      image:       '',
      link:        '',
      name:        '',
      posterType:  'preview'
    },
    isLoading:   true,
    isNewRecord: true,
    onDelete:    () => {},
    onSubmit:    () => {}
  };

  static getDerivedStateFromProps(props, state) {
    if (!isEmpty(state.requestedClass)) return state;
    const contentType =  !isEmpty(props.data.item) ? 'upload' : 'link';

    const { useProfilePhoto } = props.data;

    if (!props.isLoading) {
      return {
        contentType:    props.isNewRecord ? '' : contentType,
        requestedClass: {
          ...props.data,
          isProfilePhoto: props.isNewRecord ? true : useProfilePhoto
        },
        uploads: { video: {...props.data.item }}
      };
    }

    return state;
  }

  state = {
    contentType:    '',
    cropModalOpen:  false,
    errors:         {},
    isInit:         false,
    progress:       {},
    requestedClass: {},
    src:            '',
    uploads:        {}
  }

  inputRef = React.createRef();
  videoRef = React.createRef();

  handleChange = (value, inputName) => (
    this.setState((prevState) => ({
      requestedClass: {
        ...prevState.requestedClass,
        [inputName]: value
      }
    }))
  );

  handleValidate = () => new Promise((resolve, reject) => {
    const { contentType, requestedClass, uploads } = this.state;

    const errors = Validator.validate([
      ['name',        Validator.concepts.isFilled, [requestedClass.name]],
      ['itemId',      Validator.concepts.isFilled, [uploads.video.id], contentType == 'link'],
      ['link',        Validator.concepts.isFilled, [requestedClass.link], contentType == 'upload'],
      ['description', Validator.concepts.isFilled, [requestedClass.description]],
      ['cost',        Validator.concepts.isFilled, [requestedClass.cost]],
      ['expiry',      Validator.concepts.isFilled, [requestedClass.expiry], !requestedClass.canExpire]
    ]);
    Validator.clear();

    if (isEmpty(errors)) {
      resolve();
    } else {
      reject(errors);
    }
  });

  handleSubmit = () => {
    const { requestedClass, uploads, contentType } = this.state;
    const canExpire = contentType == 'link' ? false : requestedClass.canExpire;

    return (
      this.handleValidate()
        .then(() => (
          this.props.onSubmit({
            ...requestedClass,
            canExpire,
            expiry:          canExpire ? parseInt(String(requestedClass.expiry)) : 0,
            link:            contentType == 'link' ? requestedClass.link : '',
            useProfilePhoto: requestedClass.isProfilePhoto,
            video:           contentType == 'upload' && uploads.video && uploads.video.signedId
          })
        ))
      .catch((errors) => (
        this.setState({ errors }, () => Scroller._scroll('smooth'))
      ))
    );
  }

  handleClick = () => {
    if (this.state.requestedClass.posterType === 'image') this.inputRef.current.click();
  }

  handleDelete = () => (
    this.props.onDelete({ id: this.state.requestedClass.id })
  )

  handleProgress = (progress, name) => {
    this.setState((prevState) => ({
      isInit:   false,
      progress: { ...prevState.progress, [name]: progress },
      uploads:  { ...prevState.uploads, [name]: {} }
    }));
  }

  handlePhotoSwitch =  (posterType) => () => (
    this.setState((prevState) => ({
      requestedClass: {
        ...prevState.requestedClass,
        posterType
      }
    }))
  )

  handleUpload  = (item, name) => this.setState((prevState) => ({ uploads: { ...prevState.uploads, [name]: item } }))

  handleVideoSwitch = (contentType) => (e) => {
    e.preventDefault();
    this.setState({ contentType }, () => {
      if (this.state.contentType == 'upload') this.videoRef.current.click();
    });
  }

  handleSelectFile = (e) => {
   if (e.target.files && e.target.files.length > 0) {
     const reader = new FileReader();
     reader.addEventListener('load', () =>
       this.setState({ src: reader.result })
     );
     reader.readAsDataURL(e.target.files[0]);
     this.setState({ photoName: e.target.files[0].name });
     this.handleModal();
   }
 };

  handleStart = () => {
    this.setState({ isInit: true });
  }

 handleModal = () => {
  this.setState((prevState) => ({ cropModalOpen: !prevState.cropModalOpen }));
}

  renderUpload = () => {
    const { contentType, progress, errors, requestedClass, uploads } = this.state;

    const isUpload = contentType === 'upload';
    const isLink   = contentType === 'link';

    return (
      <React.Fragment>
        <Label
            className='required'
            for='input-link'>
          Content
        </Label>
        <div className='upload-choice text-uppercase'>
          <Button
              color='primary'
              isOutline={!isUpload}
              onClick={this.handleVideoSwitch('upload')}>
            Upload
          </Button>

          <Button
              className='ml-3'
              color='primary'
              isOutline={!isLink}
              onClick={this.handleVideoSwitch('link')}>
            Add Url
          </Button>
        </div>

        { this.state.isInit &&
          <small className='pt-1'>
            <span className='text-primary'>Initializing...</span>
          </small>
        }

        { !!progress.video && !!uploads.video && !uploads.video.id &&
          <small className='pt-1'>
            <span className='text-muted'>Progress:&nbsp;</span>
            <span className='text-primary'>{progress.video}%</span>
          </small>
        }

        { !!uploads.video && uploads.video.id && contentType == 'upload' &&
          <small className='pt-1'>
            <span className='text-muted'>Uploaded:&nbsp;</span>
            <span className='text-primary'>{uploads.video.name}</span>
          </small>
        }

        { errors.itemId && !uploads.video.id &&
          <small className='d-flex'>
            <span className='text-danger'>Required</span>
          </small>
        }

        <Upload
            allowedTypes={['video/mp4', 'video/webm', 'video/quicktime']}
            forwardRef={this.videoRef}
            isCrop={false}
            maxSizeBytes={4e+9} // 4GB
            name='video'
            onChange={this.handleChange}
            onFinish={this.handleUpload}
            onProgress={this.handleProgress}
            onStart={this.handleStart} />

        { contentType == 'upload' && !!uploads.video && uploads.video.id &&
          <FormGroup>
            <Label for='input-can-expire'>Expire client&apos;s access X days after purchase?</Label>

            <div className='d-flex'>
              <div className='custom-control custom-checkbox checkbox-lg'>
                <Checkbox
                    className='custom-control-input'
                    id='can-expire'
                    isChecked={requestedClass.canExpire}
                    name='canExpire'
                    onChange={this.handleChange} />
                <Label
                    className='custom-control-label'
                    htmlFor='input-can-expire' />
              </div>

              <MaskedInput
                  className='ml-2 w-25'
                  id='expiry'
                  inputMode='number'
                  isDisabled={!requestedClass.canExpire}
                  isInvalid={!!errors.expiry}
                  mask={dayMask}
                  name='expiry'
                  onChange={this.handleChange}
                  placeholder=' days'
                  type='number'
                  value={String(requestedClass.expiry)} />
            </div>
          </FormGroup>
        }


        { isLink &&
          <FormGroup>
            <Label
                className='required'
                for='input-link'>
              Digital Link
            </Label>
            <Input
                id='link'
                isInvalid={!!errors.link}
                name='link'
                onChange={this.handleChange}
                placeholder='Add URL to Zoom, YouTube, etc.'
                type='text'
                value={requestedClass.link} />
            <FormFeedback tooltip>{errors.link}</FormFeedback>

            <FormText
                className={classNames({ 'float-left w-75': !!errors.link })}
                color='info'>
              URL will be shared with clients via email after purchasing
            </FormText>
          </FormGroup>
        }
      </React.Fragment>
    );
  }

  render() {
    const { isNewRecord, user } = this.props;
    const { progress, requestedClass, errors, uploads, contentType } = this.state;
    const { currency } = user;

    const sign = currencies[currency].symbol;
    const amount = requestedClass.cost && parseFloat(requestedClass.cost.toString().replace(sign, '')) || 0;
    const fee = FEES.charge(amount);

    return (
      <Form className='form-default'>
        <FormGroup>
          <Label
              className='required'
              for='input-name'>
            Class Name
          </Label>

          <Input
              id='name'
              isInvalid={!!errors.name}
              name='name'
              onChange={this.handleChange}
              placeholder='Upper Body Workout'
              type='text'
              value={requestedClass.name} />
          <FormFeedback tooltip>{errors.name}</FormFeedback>
        </FormGroup>

        {this.renderUpload()}

        <FormGroup>
          <Label for='input-fee'>
            Class Thumbnail
          </Label>

          { contentType === 'upload' &&
            <div
                className='mt-2 custom-control custom-radio w-100'
                onClick={this.handlePhotoSwitch('preview')}
                onKeyPress={this.handlePhotoSwitch('preview')}
                role='button'
                tabIndex={0}>
              <Radio
                  className='custom-control-input'
                  id='preview_video'
                  isChecked={requestedClass.posterType === 'preview'}
                  name='preview'
                  value={false} />
              <Label
                  className='custom-control-label'
                  htmlFor='input-online'>
                Auto-generate image from video
              </Label>
            </div>
          }

          <div
              className='mt-2 custom-control custom-radio w-100'
              onClick={this.handlePhotoSwitch('photo')}
              onKeyPress={this.handlePhotoSwitch('photo')}
              role='button'
              tabIndex={0}>
            <Radio
                className='custom-control-input'
                id='profile_photo'
                isChecked={requestedClass.posterType === 'photo'}
                name='photo'
                value={false} />
            <Label
                className='custom-control-label'
                htmlFor='input-online'>
              Use my profile picture
            </Label>
          </div>

          <div
              className='custom-control custom-radio w-10'
              onClick={this.handlePhotoSwitch('image')}
              onKeyPress={this.handlePhotoSwitch('image')}
              role='button'
              tabIndex={0}>
            <Radio
                className='custom-control-input'
                id='upload_image'
                isChecked={requestedClass.posterType === 'image'}
                name='image'
                value={false} />
            <Label
                className='custom-control-label'
                htmlFor='upload_image'>
              Upload a new image
            </Label>
          </div>
        </FormGroup>
        <div>
          <input
              className='d-none'
              multiple={false}
              name='image'
              onChange={this.handleSelectFile}
              ref={this.inputRef}
              type='file' />
          <div
              className={classNames('upload-btn', { 'opacity-1': !(requestedClass.posterType === 'image') })}
              color='primary'
              onClick={this.handleClick}
              onKeyPress={this.handleClick}
              role='button'
              tabIndex={0}>
            Upload
          </div>
          { this.state.photoName &&
            <small className='text-small pt-1'>
              <span className='text-primary'>{this.state.photoName}</span>
            </small>
          }

          { !!progress.photo && !!uploads.photo && !uploads.photo.id &&
            <small className='text-small pt-1'>
              <span className='text-muted'>Progress:&nbsp;</span>
              <span className='text-primary'>{progress.photo}%</span>
            </small>
          }

          { !!uploads.photo && uploads.photo.id &&
            <small className='text-small pt-1'>
              <span className='text-muted'>Uploaded:&nbsp;</span>
              <span className='text-primary'>{uploads.photo.name}</span>
            </small>
          }
        </div>

        <FormGroup>
          <Label
              className='required'
              for='input-description'>
            Description
          </Label>

          <Input
              id='description'
              isInvalid={!!errors.description}
              name='description'
              onChange={this.handleChange}
              rows='3'
              type='textarea'
              value={requestedClass.description} />
          <FormFeedback tooltip>{errors.description}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label
              className='required'
              for='input-cost'>
            Cost
          </Label>

          <MaskedInput
              id='cost'
              inputMode='number'
              isInvalid={!!errors.cost}
              mask={currencyMask(sign)}
              name='cost'
              onChange={this.handleChange}
              placeholder={sign + 10}
              type='number'
              value={requestedClass.cost} />
          <FormFeedback tooltip>{errors.cost}</FormFeedback>

          <small className='text-small text-muted mt-2'>
            Client pays {parseFloat(amount + fee, 10).toLocaleString('en-GB', { currency, style: 'currency' })}&nbsp;
            (incl. {parseFloat(fee, 10).toLocaleString('en-GB', { currency, style: 'currency' })} fee)
          </small>
        </FormGroup>

        { isNewRecord &&
          <Button
              className='mt-5'
              isBlock
              onClick={this.handleSubmit}
              size='lg'
              type='button'>
            Add Class
          </Button>
        }

        { !isNewRecord &&
          <Row className='mt-5'>
            <Col className='mb-2'>
              <Button
                  color='danger'
                  isBlock
                  isOutline
                  onClick={this.handleDelete}
                  size='lg'
                  type='button'>
                Delete
              </Button>
            </Col>

            <Col>
              <Button
                  isBlock
                  onClick={this.handleSubmit}
                  size='lg'
                  type='button'>
                Update
              </Button>
            </Col>
          </Row>
        }

        <CropModal
            fieldName='image'
            isOpen={this.state.cropModalOpen}
            onChange={this.handleChange}
            onToggle={this.handleModal}
            photoName={this.state.photoName}
            src={this.state.src} />

      </Form>
    );
  }
}

export default RequestedClassForm;
