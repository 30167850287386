import { all, call, put, takeLatest } from 'redux-saga/effects';

import { api, extractError, toCamelCase } from '../lib';
import { PlansActionTypes } from '../constants/actionTypes';
import { ErrorActions, PlansActions } from '../actions';

function* watchFetch() {
  yield takeLatest(PlansActionTypes.FETCH, function* () {
    try {
      const response = yield call(api.plan.fetch);

      yield put(PlansActions.FETCH_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PlansActions.FETCH_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchSubscriptionCreate() {
  yield takeLatest(PlansActionTypes.SUBSCRIPTION_CREATE, function* ({ payload }) {
    try {
      const response = yield call(api.user.plan.create, payload);

      yield put(PlansActions.SUBSCRIPTION_CREATE_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PlansActions.SUBSCRIPTION_CREATE_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

function* watchSubscriptionGet() {
  yield takeLatest(PlansActionTypes.SUBSCRIPTION_GET, function* () {
    try {
      const response = yield call(api.user.plan.get);

      yield put(PlansActions.SUBSCRIPTION_GET_SUCCESS(toCamelCase(response.data)));
    } catch (error) {
      yield put(PlansActions.SUBSCRIPTION_GET_FAILURE());
      yield put(ErrorActions.NEW(extractError(error)));
    }
  });
}

export default function* plansSaga() {
  yield all([
    watchFetch(),
    watchSubscriptionCreate(),
    watchSubscriptionGet()
  ]);
}
